<h2 rds-dialog-header>{{data.title}}</h2>
<div rds-dialog-content>
  <div class="row" [formGroup]="form">
    <div class="col mt-9">
      <div class="row">
        <div class="col-12">
          <rds-form-field>
            <rds-control-label>{{data.controlLabel}}</rds-control-label>
            <input formControlName="rename" rdsInput/>
            <rds-control-hint class="right">{{form.get('rename').value?.length || 0}}/{{maxLength}}</rds-control-hint>
            <rds-control-error *ngIf="form.get('rename').hasError('required') && form.get('rename').touched">This field is required</rds-control-error>
            <rds-control-error *ngIf="form.get('rename').hasError('maxlength') && form.get('rename').touched">Maximum number of characters exceeded</rds-control-error>
            <rds-control-error *ngIf="(form.get('rename').hasError('required') || form.get('rename').hasError('maxlength')) && form.get('rename').touched" class="right">{{form.get('rename').value?.length || 0}}/{{maxLength}}</rds-control-error>
          </rds-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
<div rds-dialog-actions-container align="end">
    <button
        rds-secondary-button
        [rds-dialog-close]="null"
        size="m"
    >{{data.cancelButtonLabel || 'Cancel'}}</button>
    <button
        rds-primary-button
        [disabled]="form.get('rename').valid === false"
        [rds-dialog-close]="form.get('rename').value"
        size="m"
    >{{data.confirmButtonLabel || 'Rename'}} </button>
</div>