import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as fromHeader from '@app/layout/header/store/header';
import * as fromSuggestions from '@app/root-store/suggestions';
import { Store } from '@ngrx/store';
import { RdsDrawerComponent } from '@rds/angular-components';
import { Observable, debounceTime, distinctUntilChanged, filter } from 'rxjs';
import { SubSink } from 'subsink';
import {
  DateFilterDefinition,
  DictionaryTypeEnum,
  FilterDefinition,
  FilterTypeEnum,
  SuggestionTypeEnum
} from '../filters-model';
import { FiltersUtilsService } from '../filters.utils';

@Component({
  selector: 'rh-advanced-filters',
  templateUrl: './advanced-filters.component.html',
  styleUrls: ['./advanced-filters.component.scss']
})
export class AdvancedFiltersComponent implements OnInit, OnDestroy {
  @ViewChild(RdsDrawerComponent, {static: false}) drawer: RdsDrawerComponent;
  subs: SubSink = new SubSink();

  FilterTypeEnum = FilterTypeEnum;
  DictionaryTypeEnum = DictionaryTypeEnum;
  SuggestionTypeEnum = SuggestionTypeEnum;

  private _activeFiltersKeys: Array<string> = [];

  private set activeFiltersKeys(keys: Array<string>) {
    this._activeFiltersKeys = keys;
  }

  public get activeFiltersKeys() {
    return this._activeFiltersKeys
  }

  get activeFiltersCount() {
    return this.activeFiltersKeys.length;
  }

  dictionaries: { [key: string]: { dict: Observable<any>, model: any } } = {};
  suggestions: { [key: string]: Observable<any> } = {};

  filters: FormGroup = new FormGroup({});
  predefinedFilters: FormGroup = new FormGroup([]);
  @Input() defaultFilters: { [prop: string]: any };
  _advancedFilters: { [prop: string]: any };

  @Input() set advancedFilters(filters: { [key: string]: any }) {
    this._advancedFilters = filters;
    this.dictionaries = this.filtersUtils.initializeDictionaries(filters);
    this.filtersUtils.initializeSuggestions(filters);
    this.filtersUtils.formifyFilters(this.filters, filters);
    Object
    .keys(filters)
    .filter(k => (this.filters.get(k) as FormGroup).controls.type.value === FilterTypeEnum.PREDEFINED_TOGGLE)
    .forEach(k => {
      if (!this.predefinedFilters.get(k)) {
        this.predefinedFilters.addControl(k, this.filters.get(k));
        this.subs.sink = this.filters.get(k).valueChanges
        .pipe(
          distinctUntilChanged((prev, next) => {
            return prev.value === next.value
          }),
          filter(filter => filter.value === true),
          debounceTime(50))
        .subscribe(res => {
          Object.keys(res.predefinedValue).map(k => {
            (this.filters.get(k) as FormGroup).controls.value.patchValue(res.predefinedValue[k])
          })
        })
      }
    });
    this.activeFiltersKeys = this.getActiveFilters(this.filters.value);
  }

  @Input() title = 'Advanced filters';


  get advancedFilters() {
    return this._advancedFilters;
  }

  @Output() filtersChanged: EventEmitter<{ [prop: string]: FilterDefinition }> = new EventEmitter();

  ngOnInit(): void {
    this.subs.sink = this.filters.valueChanges
    .pipe(
      distinctUntilChanged((prev, next) => {
        const prevValue = Object.keys(prev).map((key) => prev[key].value);
        const nextValue = Object.keys(next).map((key) => next[key].value);
        return JSON.stringify(prevValue) === JSON.stringify(nextValue)
      }),
      debounceTime(50))
    .subscribe(filters => {
      this.activeFiltersKeys = this.getActiveFilters(filters);
      this.filtersChanged.emit({
        ...filters,
      });
    });
  }

  ngOnDestroy(): void {
    this.store$.dispatch(fromHeader.forceClose({close: false}));
  }
  
  clearAll() {
    this.filters.patchValue(this.defaultFilters);
  }

  showFiltersInOrder() {
    return 0
  }

  isApplied(filter) {
    return this.activeFiltersKeys.includes(filter);
  }

  getActiveFilters(filters: { [key: string]: FilterDefinition }) {
    return Object.keys(filters).filter(k => {
      const dateRangeCondition = (filters[k].type === FilterTypeEnum.DATE && (filters[k] as DateFilterDefinition).range && !!filters[k].value.start && !!filters[k].value.end)
      const restTypesCondition = (!!filters[k].value && !!filters[k].value.length && filters[k].value.length > 0)
      return filters[k].changable && (dateRangeCondition || restTypesCondition)
    });
  }

  getAutocompletes(type, prop, event) {
    this.store$.dispatch(fromSuggestions.loadSuggestion({suggestionType: type, prop, phrase: event}))
  }

  openAdvancedFilters() {
    this.drawer.open();
    this.store$.dispatch(fromHeader.forceClose({close: true}));
  }

  closeAdvancedFilters() {
    this.drawer.close();
    this.store$.dispatch(fromHeader.forceClose({close: false}));
  }

  clearRange(formGroup, picker) {
    formGroup.setValue({start: null, end: null})
    picker.close();
  }

  trackByFn(index, item): void {
    return item.key;
  }

  ensureRangeValues(formGroup) {
    const start = formGroup.controls.start;
    const end = formGroup.controls.end;
    if (start.value && !end.value) {
      end.setValue(start.value)
    }
  }
  constructor(private store$: Store<any>, private filtersUtils: FiltersUtilsService) {
  }


}
