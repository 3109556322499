import { ContentItem, Pagination, UserChannel } from '@app/core/models';
import { createReducer, on, Action } from '@ngrx/store';
import * as fromActions from '@home/store/home-page/home-page.actions';

export const feature = 'homePage';

export interface State {
  allChannels: Array<UserChannel>;
  visibleChannels: Array<UserChannel>;
  channelResults: Array<ContentItem>;
  pagination: Pagination
}

export const initialState: State = {
  allChannels: [],
  visibleChannels: [],
  channelResults: [],
  pagination: {
    ...Pagination,
    pageSize: 12
  }
};

export const homePageReducer = createReducer(
  initialState,
  on(fromActions.loadChannels, fromActions.updateChannels, (state, { channels }) => ({
    ...state,
    allChannels: channels,
    visibleChannels: [channels[0]]
  })),
  on(fromActions.renderNextChannel, (state) => ({
    ...state,
    visibleChannels: ( state.visibleChannels.length < state.allChannels.length)
    ? [...state.visibleChannels, state.allChannels[state.visibleChannels.length]]
    : state.visibleChannels
  })),
  on(fromActions.resetChannels, (state) => ({
    ...state,
    visibleChannels: [],
    allChannels: [],
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return homePageReducer(state, action);
}

export const getAllChannels = (state: State) => state.allChannels;
export const getVisibleChannels = (state: State) => state.visibleChannels;
