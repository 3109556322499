import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromActions from './timezones.actions';
import * as fromReducer from './timezones.reducer';
import { Store } from '@ngrx/store';
import { TimeZoneService } from '@app/core/services/timezone.service';

@Injectable()
export class TimeZonesEffects {

  public loadTimeZones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadTimeZones),
      switchMap(({defaultTimeZone}) => [
        fromActions.getTimeZonesRequest({timeZone: defaultTimeZone})
      ])
    ), { dispatch: true }
  );

  public getTimeZonesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getTimeZonesRequest),
      mergeMap(({timeZone}) =>
        this.timezoneService.getTimeZones(timeZone).pipe(
          map(({ timeZones, defaultTimeZone }) => fromActions.getTimeZonesSuccess({ defaultTimeZone, timeZones})),
          catchError(({ message }) => of(fromActions.getTimeZonesFailure({ error: message })))
        )
      )
    ), { dispatch: true }
  );

  public getTimeZonesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getTimeZonesSuccess),
      mergeMap(({ timeZones, defaultTimeZone }) => [
        fromActions.timeZonesCollectionAddMany({ timeZones, defaultTimeZone }),
      ]),
    ), { dispatch: true }
  );

  private isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private timezoneService: TimeZoneService,
  ) {}
}
