import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, catchError, mergeMap, debounceTime } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { EmailService } from '../services/email.service';
import * as fromActions from '../store/email.actions';
import { State } from '../store/email.reducer';
import { WorkdayService } from '@app/core/services/workday.service';

@Injectable()
export class EmailEffects {

  public getEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getUserEmail),
      debounceTime(300),
      mergeMap(({ login }) => this.emailService.getEmail(login).pipe(
        map(email => fromActions.getUserEmailSuccess({ email, login })),
        catchError(() => of(fromActions.getUserEmailFailure()))
      ))
    )
  );

  public getEmailSearchRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.getEmailSearchRequest),
    mergeMap(({phrase}) =>
      this.workdayService.getUserSearch({controlValue: phrase}).pipe(
        map((usersSearched) => fromActions.getEmailSearchSuccess({usersSearched})),
        catchError(({message}) => of(fromActions.getEmailSearchFailure({error: message})))
      )
    )
  ), {dispatch: true}
);

  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private emailService: EmailService,
    private workdayService: WorkdayService

  ) {}
}
