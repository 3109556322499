import { on, Action, createReducer } from '@ngrx/store';
import * as fromActions from './user-information.actions';
import { UserWorkdayInformation } from '@app/core/models/user-workday.model';

export interface State {
  workdayData: UserWorkdayInformation;
}

export const initialState: State = {
  workdayData: null,
};

export const userInformationReducer = createReducer(
  initialState,
  on(fromActions.getUserByLoginSuccess, (state, { user}) => ({
    ...state,
    workdayData: user
  })),
  on(fromActions.clearUserInformation, state => ({
    ...state,
    workdayData: null
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return userInformationReducer(state, action);
}
export const getUserInformation = (state: State) => state.workdayData;
