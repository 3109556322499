import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {EndpointsService} from '@app/core/services/endpoints/endpoints.service';
import {ApiService} from '@app/core/services/api/api.service';

import {map} from 'rxjs/operators';
import {GetTickersListResponse, Pagination, TickerNewsListModel, TickerNewsModel} from '@core/models';
import { Sort } from '@rds/angular-components';
import { format } from 'date-fns';

@Injectable()
export class TickerService {

  public getTickersList(
    {sort, filters, pageIndex, pageSize}: {
      sort: Sort,
      filters: { [key: string]: any },
      pageIndex: number,
      pageSize: number
    }
    ): Observable<{
    data: Array<TickerNewsListModel>, pagination: Pagination
  }> {
    let url = this.endpoints.ENDPOINT.TICKER.PUT_FILTER;
    url = this.endpoints.addParams(url, {pageIndex, pageSize});
    const params = {
      order: `${sort.active} ${sort.direction}`,
      type: filters.type,
      locations: filters.locations,
      departments: filters.departments,
      functions: filters.functions,
      expireDate: filters.expireDate ? format(filters.expireDate,'yyy-MM-dd') : null,
      publishDate: filters.publishDate ? format(filters.publishDate,'yyy-MM-dd') : null,
      message: filters.search
    };
    if (filters && filters.message) {
      params.message = filters && filters.message;
    }
    return this.http
      .put(url, params).pipe(
        map((res) => ({
          data: res.data,
          pagination: {
            isFirst: res.isFirst,
            isLast: res.isLast,
            pageCount: res.pageCount,
            pageIndex: res.pageIndex,
            pageSize: res.pageSize,
            totalCount: res.totalCount,
          }
        }))
      );

  }

  public getFeed(index = 0,
                 size = 8,
  ): Observable<GetTickersListResponse> {
    const url = this.endpoints.ENDPOINT.TICKER.GET_FEED;
    const params = {pageIndex: index, pageSize: size};

    return this.http
      .get(this.endpoints.addParams(url, params)).pipe(
        map(({data, pageSize, pageIndex, ...response}) => ({
          data,
          ...response,
          pageSize: +pageSize,
          pageIndex: +pageIndex,
        })),
      );
  }

  public getById(id: number,
  ): Observable<TickerNewsModel> {
    const url = this.endpoints.ENDPOINT.TICKER.GET_BY_ID.replace('{id}', '' + id);


    return this.http
      .get(url).pipe(
        map(({data, ...response}) => ({
          ...response,
          locations: response.locations.map(t => t.id),
          departments: response.departments.map(t => t.id),
          functions: response.functions.map(t => t.id),
        })),
      );
  }

  public removeFeed(id: number,
  ): Observable<TickerNewsModel> {
    const url = this.endpoints.ENDPOINT.TICKER.MARK_AS_READ.replace('{id}', '' + id);

    return this.http
      .put(url).pipe(
        map(({data, ...response}) => ({
          data,
          ...response,
        })),
      );
  }

  public sendTicker(data: TickerNewsModel,
  ): Observable<any> {
    const url = this.endpoints.ENDPOINT.TICKER.POST;
    return this.http
      .post(url, data);
  }

  public updateTicker(id: number, data: TickerNewsModel,
    ): Observable<any> {
      const url = this.endpoints.ENDPOINT.TICKER.POST;
      return this.http
        .put(url, data);
    }

  public deleteTicker(id: number,
  ): Observable<any> {
    const url = this.endpoints.ENDPOINT.TICKER.GET_BY_ID.replace('{id}', '' + id);
    return this.http
      .delete(url);
  }

  constructor(
    private http: ApiService,
    private endpoints: EndpointsService,
  ) {
  }

}
