import { Pipe, PipeTransform } from '@angular/core';
import * as fromSuggestions from '@app/root-store/suggestions';
import { AutocompleteOptions } from '@app/root-store/suggestions';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Pipe({
  name: 'autocompletes'
})
export class AutocompletesPipe<T> implements PipeTransform {
  transform(prop): Observable<AutocompleteOptions<T>> {
    return this.store$.pipe(
      select(fromSuggestions.selectAutocompletes(prop)),
      map((autocompletes) => autocompletes as AutocompleteOptions<T>),  
      distinctUntilChanged()
      );
  }

  constructor(
    private store$: Store<any>) {
  }
}
