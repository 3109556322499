export enum CAMPAIGN_STATUS {
	ACTIVE = 0,
	ARCHIVED = 1,
	DELETED = 2,
}

export const CAMPAIGN_STATUS_TO_LABEL = {
	0: 'ACTIVE',
	1: 'ARCHIVED',
};

export interface RhCampaignForm {
	id: number;
	name: string;
	description: string;
	permissions: {
		owners: any[];
		editors: any[];
	};
	campaignUserRoles?: any[];
	recipientLists: any[];
	allowToSubscribe: boolean;
	allowToUnsubscribe: boolean;
	subscribed: any[];
	unsubscribed: any[];
	status?: CAMPAIGN_STATUS;
	createdAt?: Date;
	newsletterTemplateId: number;
	newsletterTemplate?: { id: number; imagePreviewUrl: string; title: string };
}

export const EMPTY_CAMPAIGN_FORM = {
	id: null,
	name: null,
	description: null,
	permissions: {
		owners: [],
		editors: [],
	},
	campaignUserRoles: [],
	recipientLists: [],
	allowToSubscribe: false,
	allowToUnsubscribe: false,
	subscribed: [],
	unsubscribed: [],
	newsletterTemplateId: null,
};
