import { Injectable } from '@angular/core';
import * as fromContentList from '@app/content-list/store';
import { ContentService } from '@app/core/services/content.service';
import { SubscriptionsService } from '@app/core/services/subscriptions.service';
import { WorkdayService } from '@app/core/services/workday.service';
import { ConfirmDialogComponent } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import * as fromContentItem from '@core/core-store/content-items';
import { ManagingChannelsService } from '@core/services/managing-channels.service';
import * as fromUser from '@core/user/store/user.selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { RdsDialogService } from '@rds/angular-components';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  withLatestFrom
} from 'rxjs';
import * as fromHome from '../home.reducer';
import * as fromActions from './subscriptions.actions';
import { Subscription, SubscriptionType } from './subscriptions.reducer';
import * as fromSelectors from './subscriptions.selectors';

@Injectable()
export class SubscriptionsEffects {

  public initSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.initSubscriptions,
      ),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectAllUserChannels))),
      first(([action, subscriptions]) => subscriptions.length === 0),
      map(() => fromActions.getAllSubscriptions())
    ), {dispatch: true}
  );

  public getSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.getAllSubscriptions,
      ),
      switchMap(() => this.subscriptionsService.getUserSubscriptions().pipe(
        map((subscriptions) => fromActions.getAllSubscriptionsSuccess({
          channels: subscriptions.channels,
          authors: subscriptions.authors,
          topics: subscriptions.topics,
        }))
      ))
    ));

  public getAllSubscriptionsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.getAllSubscriptionsSuccess,
      ),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectCurrentType))),
      map(([action, type]) => fromActions.getNewsForSubscription({subType: type}))
    ));

  public loadChannelNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.getNewsForSubscription,
        fromActions.setSelectedSubscriptions,
        fromActions.loadMoreNews,
      ),
      filter(({type, subType}) => subType === SubscriptionType.CHANNELS),
      switchMap(({type, subType}) => this.store$.pipe(
        select(fromUser.selectNewsLanguage),
        take(1),
        map((language) => ({type, subType, language}))
      )),
      switchMap(({type, subType, language}) => this.store$.pipe(
        select(fromSelectors.selectChannelsPageIndex),
        take(1),
        map((pageIndex) => ({type, subType, language, pageIndex}))
      )),
      switchMap(({type, subType, language, pageIndex}) => this.store$.pipe(
        select(fromSelectors.selectChannelsPageSize),
        take(1),
        map((pageSize) => ({type, subType, language, pageIndex, pageSize}))
      )),
      switchMap(({type, subType, language, pageIndex, pageSize}) => this.store$.pipe(
        select(fromSelectors.selectSelectedChannels),
        take(1),
        filter(channels => type !== '[HOME - SUBSCRIPTIONS] Load more News' || !!(channels && channels.length)),
        map((channels) => ({type, subType, language, pageIndex, pageSize, channels}))
      )),
      switchMap(({type, subType, language, pageIndex, pageSize, channels}) => this.store$.pipe(
        select(fromSelectors.selectSelectedSortTypeForChannels),
        take(1),
        map((sortType) => ({type, subType, language, pageIndex, pageSize, channels, sortType}))
      )),
      distinctUntilChanged((prev, curr) => {
        return JSON.stringify({
          subType: prev.subType,
          language: prev.language,
          pageIndex: prev.pageIndex,
          pageSize: prev.pageSize,
          channels: prev.channels,
          sortType: prev.sortType
        }) === JSON.stringify({
          subType: curr.subType,
          language: curr.language,
          pageIndex: curr.pageIndex,
          pageSize: curr.pageSize,
          channels: curr.channels,
          sortType: curr.sortType
        })
      }),
      map(({type, subType, language, pageIndex, pageSize, channels, sortType}) => {
        if (!channels.length) {
          return fromActions.displayEmpty({subType})
        }
        switch (type) {
          case '[HOME - SUBSCRIPTIONS] Load more News':
            return fromActions.loadMoreNewsRequest({
              subType,
              language,
              pageIndex,
              pageSize,
              subscriptions: channels,
              sortType,
            });
          default:
            return fromActions.getNewsForSubscriptionRequest({
              subType,
              language,
              pageIndex,
              pageSize,
              subscriptions: channels,
              sortType,
            })
        }
      })
    ), {dispatch: true}
  );

  public getNewsForChannelsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.getNewsForSubscriptionRequest,
      ),
      filter(({subType}) => subType === SubscriptionType.CHANNELS),
      switchMap(({
                   language,
                   pageIndex,
                   pageSize,
                   subscriptions,
                   sortType
                 }) => this.contentService.getNewsFromChannelSubscription(language.code, pageSize, pageIndex, subscriptions.map(c => c.id), sortType).pipe(
        map((response) => fromActions.getNewsSuccess({
          pagination: response.pagination,
          local: {
            news: response.local.news,
            pagination: response.local.pagination,
          },
          global: {
            news: response.global.news,
            pagination: response.global.pagination
          },
          subType: SubscriptionType.CHANNELS
        }))
      ))
    ));

  public loadMoreChannelsNewsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.loadMoreNewsRequest,
      ),
      filter(({subType}) => subType === SubscriptionType.CHANNELS),
      switchMap(({
                   language,
                   pageIndex,
                   pageSize,
                   subscriptions,
                   sortType
                 }) => this.contentService.getNewsFromChannelSubscription(language.code, pageSize, pageIndex, subscriptions.map(c => c.id), sortType).pipe(
        map((response) => fromActions.loadMoreNewsSuccess({
          pagination: response.pagination,
          local: {
            news: response.local.news,
            pagination: response.local.pagination,
          },
          global: {
            news: response.global.news,
            pagination: response.global.pagination
          },
          subType: SubscriptionType.CHANNELS
        }))
      ))
    ));

  public loadAuthorNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.getNewsForSubscription,
        fromActions.setSelectedSubscriptions,
        fromActions.loadMoreNews,
      ),
      filter(({type, subType}) => subType === SubscriptionType.AUTHORS),
      switchMap(({type, subType}) => this.store$.pipe(
        select(fromUser.selectNewsLanguage),
        take(1),
        map((language) => ({type, subType, language}))
      )),
      switchMap(({type, subType, language}) => this.store$.pipe(
        select(fromSelectors.selectAuthorsPageIndex),
        take(1),
        map((pageIndex) => ({type, subType, language, pageIndex}))
      )),
      switchMap(({type, subType, language, pageIndex}) => this.store$.pipe(
        select(fromSelectors.selectAuthorsPageSize),
        take(1),
        map((pageSize) => ({type, subType, language, pageIndex, pageSize}))
      )),
      switchMap(({type, subType, language, pageIndex, pageSize}) => this.store$.pipe(
        select(fromSelectors.selectSelectedAuthors),
        take(1),
        filter(authors => type !== '[HOME - SUBSCRIPTIONS] Load more News' || !!(authors && authors.length)),
        map((authors) => ({type, subType, language, pageIndex, pageSize, authors}))
      )),
      switchMap(({type, subType, language, pageIndex, pageSize, authors}) => this.store$.pipe(
        select(fromSelectors.selectSelectedSortTypeForAuthors),
        take(1),
        map((sortType) => ({type, subType, language, pageIndex, pageSize, authors, sortType}))
      )),
      distinctUntilChanged((prev, curr) => {
        return JSON.stringify({
          subType: prev.subType,
          language: prev.language,
          pageIndex: prev.pageIndex,
          pageSize: prev.pageSize,
          authors: prev.authors,
          sortType: prev.sortType
        }) === JSON.stringify({
          subType: curr.subType,
          language: curr.language,
          pageIndex: curr.pageIndex,
          pageSize: curr.pageSize,
          authors: curr.authors,
          sortType: curr.sortType
        })
      }),
      map(({type, subType, language, pageIndex, pageSize, authors, sortType}) => {
        if (!authors.length) {
          return fromActions.displayEmpty({subType})
        }
        switch (type) {
          case '[HOME - SUBSCRIPTIONS] Load more News':
            return fromActions.loadMoreNewsRequest({
              subType,
              language,
              pageIndex,
              pageSize,
              subscriptions: authors,
              sortType,
            });
          default:
            return fromActions.getNewsForSubscriptionRequest({
              subType,
              language,
              pageIndex,
              pageSize,
              subscriptions: authors,
              sortType,
            })
        }
      })
    ), {dispatch: true}
  );

  public getNewsForAuthorsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.getNewsForSubscriptionRequest,
      ),
      filter(({subType}) => subType === SubscriptionType.AUTHORS),
      switchMap(({
                   language,
                   pageIndex,
                   pageSize,
                   subscriptions,
                   sortType
                 }) => this.contentService.getNewsFromAuthorSubscription(language.code, pageSize, pageIndex, subscriptions.map(c => c.id), sortType).pipe(
        map((response) => fromActions.getNewsSuccess({
          pagination: response.pagination,
          local: {
            news: response.local.news,
            pagination: response.local.pagination,
          },
          global: {
            news: response.global.news,
            pagination: response.global.pagination
          },
          subType: SubscriptionType.AUTHORS
        }))
      ))
    ));

  public loadMoreAuthorsNewsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.loadMoreNewsRequest,
      ),
      filter(({subType}) => subType === SubscriptionType.AUTHORS),
      switchMap(({
                   language,
                   pageIndex,
                   pageSize,
                   subscriptions,
                   sortType
                 }) => this.contentService.getNewsFromAuthorSubscription(language.code, pageSize, pageIndex, subscriptions.map(c => c.id), sortType).pipe(
        map((response) => fromActions.loadMoreNewsSuccess({
          pagination: response.pagination,
          local: {
            news: response.local.news,
            pagination: response.local.pagination
          },
          global: {
            news: response.global.news,
            pagination: response.global.pagination
          },
          subType: SubscriptionType.AUTHORS
        }))
      ))
    ));

  public loadTopicNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.getNewsForSubscription,
        fromActions.setSelectedSubscriptions,
        fromActions.loadMoreNews,
      ),
      filter(({type, subType}) => subType === SubscriptionType.TOPICS),
      switchMap(({type, subType}) => this.store$.pipe(
        select(fromUser.selectNewsLanguage),
        take(1),
        map((language) => ({type, subType, language}))
      )),
      switchMap(({type, subType, language}) => this.store$.pipe(
        select(fromSelectors.selectTopicsPageIndex),
        take(1),
        map((pageIndex) => ({type, subType, language, pageIndex}))
      )),
      switchMap(({type, subType, language, pageIndex}) => this.store$.pipe(
        select(fromSelectors.selectTopicsPageSize),
        take(1),
        map((pageSize) => ({type, subType, language, pageIndex, pageSize}))
      )),
      switchMap(({type, subType, language, pageIndex, pageSize}) => this.store$.pipe(
        select(fromSelectors.selectSelectedTopics),
        take(1),
        filter(topics => type !== '[HOME - SUBSCRIPTIONS] Load more News' || !!(topics && topics.length)),
        map((topics) => ({type, subType, language, pageIndex, pageSize, topics}))
      )),
      switchMap(({type, subType, language, pageIndex, pageSize, topics}) => this.store$.pipe(
        select(fromSelectors.selectSelectedSortTypeForTopics),
        take(1),
        map((sortType) => ({type, subType, language, pageIndex, pageSize, topics, sortType}))
      )),
      distinctUntilChanged((prev, curr) => {
        return JSON.stringify({
          subType: prev.subType,
          language: prev.language,
          pageIndex: prev.pageIndex,
          pageSize: prev.pageSize,
          topics: prev.topics,
          sortType: prev.sortType
        }) === JSON.stringify({
          subType: curr.subType,
          language: curr.language,
          pageIndex: curr.pageIndex,
          pageSize: curr.pageSize,
          topics: curr.topics,
          sortType: curr.sortType
        })
      }),
      map(({type, subType, language, pageIndex, pageSize, topics, sortType}) => {
        if (!topics.length) {
          return fromActions.displayEmpty({subType})
        }
        switch (type) {
          case '[HOME - SUBSCRIPTIONS] Load more News':
            return fromActions.loadMoreNewsRequest({
              subType,
              language,
              pageIndex,
              pageSize,
              subscriptions: topics,
              sortType,
            });
          default:
            return fromActions.getNewsForSubscriptionRequest({
              subType,
              language,
              pageIndex,
              pageSize,
              subscriptions: topics,
              sortType,
            })
        }
      })
    ), {dispatch: true}
  );

  public getNewsForTopicsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.getNewsForSubscriptionRequest,
      ),
      filter(({subType}) => subType === SubscriptionType.TOPICS),
      switchMap(({
                   language,
                   pageIndex,
                   pageSize,
                   subscriptions,
                   sortType
                 }) => this.contentService.getNewsFromTopicSubscription(language.code, pageSize, pageIndex, subscriptions.map(c => c.id), sortType).pipe(
        map((response) => fromActions.getNewsSuccess({
          pagination: response.pagination,
          local: {
            news: response.local.news,
            pagination: response.local.pagination
          },
          global: {
            news: response.global.news,
            pagination: response.global.pagination
          },
          subType: SubscriptionType.TOPICS
        }))
      ))
    ));

  public loadMoreTopicsNewsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.loadMoreNewsRequest,
      ),
      filter(({subType}) => subType === SubscriptionType.TOPICS),
      switchMap(({
                   language,
                   pageIndex,
                   pageSize,
                   subscriptions,
                   sortType
                 }) => this.contentService.getNewsFromTopicSubscription(language.code, pageSize, pageIndex, subscriptions.map(c => c.id), sortType).pipe(
        map((response) => fromActions.loadMoreNewsSuccess({
          pagination: response.pagination,
          local: {
            news: response.local.news,
            pagination: response.local.pagination
          },
          global: {
            news: response.global.news,
            pagination: response.global.pagination
          },
          subType: SubscriptionType.TOPICS
        }))
      ))
    ));

  public addChannelByIdsSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addSubscriptionByIdsRequest),
      filter(({subType}) => subType === SubscriptionType.CHANNELS),
      mergeMap(({ids, subType}) => this.subscriptionsService.postUserChannelsByIds(ids).pipe(
        map(({updated}) => fromActions.addSubscriptionByIdsSuccess({updated, subType, ids})),
        catchError(({error}) => of(fromActions.addSubscriptionByIdsFailure({error, subType})))
      ))
    ), {dispatch: true}
  );

  public addAuthorByIdsSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addSubscriptionByIdsRequest),
      filter(({subType}) => subType === SubscriptionType.AUTHORS),
      mergeMap(({ids, subType}) => this.subscriptionsService.postUserAuthorsByIds(ids).pipe(
        map(({updated}) => fromActions.addSubscriptionByIdsSuccess({updated, subType, ids})),
        catchError(({error}) => of(fromActions.addSubscriptionByIdsFailure({error, subType})))
      ))
    ), {dispatch: true}
  );

  public addTopicByIdsSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addSubscriptionByIdsRequest),
      filter(({subType}) => subType === SubscriptionType.TOPICS),
      mergeMap(({ids, subType}) => this.subscriptionsService.postUserTopicsByIds(ids).pipe(
        map(({updated}) => fromActions.addSubscriptionByIdsSuccess({updated, subType, ids})),
        catchError(({error}) => of(fromActions.addSubscriptionByIdsFailure({error, subType})))
      ))
    ), {dispatch: true}
  );

  public removeChannelByIdRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSubscriptionByIdsRequest),
      filter(({subType}) => subType === SubscriptionType.CHANNELS),
      mergeMap(({ids, subType}) => this.subscriptionsService.deleteUserChannelsByIds(ids).pipe(
        map(({updated}) => fromActions.deleteSubscriptionByIdsSuccess({
          updated,
          subType,
          ids
        })),
        catchError(({message}) => of(fromActions.deleteSubscriptionByIdsFailure({error: message, subType})))
      ))
    ), {dispatch: true}
  );

  public removeAuthorByIdRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSubscriptionByIdsRequest),
      filter(({subType}) => subType === SubscriptionType.AUTHORS),
      mergeMap(({ids, subType}) => this.subscriptionsService.deleteUserAuthorsByIds(ids).pipe(
        map(({updated}) => fromActions.deleteSubscriptionByIdsSuccess({
          updated,
          subType,
          ids
        })),
        catchError(({message}) => of(fromActions.deleteSubscriptionByIdsFailure({error: message, subType})))
      ))
    ), {dispatch: true}
  );

  public removeTopicByIdRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSubscriptionByIdsRequest),
      filter(({subType}) => subType === SubscriptionType.TOPICS),
      mergeMap(({ids, subType}) => this.subscriptionsService.deleteUserTopicsByIds(ids).pipe(
        map(({updated}) => fromActions.deleteSubscriptionByIdsSuccess({
          updated,
          subType,
          ids
        })),
        catchError(({message}) => of(fromActions.deleteSubscriptionByIdsFailure({error: message, subType})))
      ))
    ), {dispatch: true}
  );

  public addLike$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addLike),
      mergeMap(({id}) => this.contentService.putLikeByContentId({contentId: id}).pipe(
        map(() => fromActions.addLikeSuccess({id})),
        catchError(() => of(fromActions.addLikeFailure()))
      ))
    ), {dispatch: true}
  );

  public removeLike$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeLike),
      mergeMap(({id}) => this.contentService.removeLikeByContentId({contentId: id}).pipe(
        map(() => fromActions.removeLikeSuccess({id})),
        catchError(() => of(fromActions.removeLikeFailure()))
      ))
    ), {dispatch: true}
  );

  public initAuthorsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initAuthorsFilters),
      switchMap(() => this.store$.pipe(
          select(fromSelectors.selectAuthorsFiltersForRequest),
          debounceTime(300),
          distinctUntilChanged((prev, next) => {
            return JSON.stringify(prev) === JSON.stringify(next)
          }),
          filter(({name}: any) => (name.length) >  1),
          map((filters) => fromActions.getAllAuthorsRequest({phrase: filters.name}))
        )
      ),
    ), { dispatch: true }
  );

  public clearAuthorsFilters$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.initAuthorsFilters),
    switchMap(() => this.store$.pipe(
        select(fromSelectors.selectAuthorsFiltersForRequest),
        debounceTime(300),
        distinctUntilChanged((prev, next) => {
          return JSON.stringify(prev) === JSON.stringify(next)
        }),
        filter(({name}: any) => (name.length) <= 1),
        map(() => fromActions.clearFilteredAuthors())
      )
    ),
  ), { dispatch: true }
);

  public getAllAuthorsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllAuthorsRequest),
      mergeMap(({phrase}) =>
        this.workdayService.getUserSearch({controlValue: phrase}).pipe(
          map((usersSearched) => fromActions.getAllAuthorsSuccess({
            allAuthors: usersSearched.map((user): Subscription => ({
              id: user.login,
              type: SubscriptionType.AUTHORS,
              name: `${user.firstName} ${user.lastName}`,
              url: `users/${user.login}`,
              isMandatory: false
            }))
          })),
          catchError(({message}) => of(fromActions.getAllAuthorFailure({error: message})))
        )
      )
    ), {dispatch: true}
  );

  
  public initChannelsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initChannelsFilters),
      switchMap(() => this.store$.pipe(
          select(fromSelectors.selectChannelsFiltersForRequest),
          debounceTime(300),
          distinctUntilChanged((prev, next) => {
            return JSON.stringify(prev) === JSON.stringify(next)
          }),
          filter(({
            name,
            departments,
            functions,
            locations}: any) => (name.length + locations.length + departments.length + functions.length) >  0),
          map((filters) => fromActions.getAllChannelsRequest({filters}))
        )
      ),
    ), { dispatch: true }
  );

  public clearChannelsFilters$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.initChannelsFilters),
    switchMap(() => this.store$.pipe(
        select(fromSelectors.selectChannelsFiltersForRequest),
        debounceTime(300),
        distinctUntilChanged((prev, next) => {
          return JSON.stringify(prev) === JSON.stringify(next)
        }),
        filter(({
          name,
          departments,
          functions,
          locations}: any) => (name.length + locations.length + departments.length + functions.length) ===  0),
        map(() => fromActions.clearFilteredChannels())
      )
    ),
  ), { dispatch: true }
);

  
  public getAllChannelsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllChannelsRequest),
      switchMap(({filters}) =>
        this.contentService.putFilterAllChannels(filters).pipe(
          map((allChannels) => fromActions.getAllChannelsSuccess({ allChannels })),
          catchError(({ message }) => of(fromActions.getAllChannelsFailure({ error: message })))
        )
      )

    ), { dispatch: true}
  );

  
  public initTopicsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initTopicsFilters),
      switchMap(() => this.store$.pipe(
          select(fromSelectors.selectTopicsFiltersForRequest),
          debounceTime(300),
          distinctUntilChanged((prev, next) => {
            return JSON.stringify(prev) === JSON.stringify(next)
          }),
          filter(({name}: any) => (name.length) >  1),
          map((filters) => fromActions.getAllTopicsRequest({phrase: filters.name}))
        )
      ),
    ), { dispatch: true }
  );

  public clearTopicsFilters$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.initTopicsFilters),
    switchMap(() => this.store$.pipe(
        select(fromSelectors.selectTopicsFiltersForRequest),
        debounceTime(300),
        distinctUntilChanged((prev, next) => {
          return JSON.stringify(prev) === JSON.stringify(next)
        }),
        filter(({name}: any) => (name.length) <= 1),
        map(() => fromActions.clearFilteredTopics())
      )
    ),
  ), { dispatch: true }
);

  public getAllTopicsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllTopicsRequest),
      mergeMap(({phrase}) =>
        this.subscriptionsService.searchTopics(phrase).pipe(
          map((allTopics) => fromActions.getAllTopicsSuccess({allTopics})),
          catchError(({message}) => of(fromActions.getAllTopicsFailure({error: message})))
        )
      )
    ), {dispatch: true}
  );

  public changeActiveSubscriptionTypeToAuthors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.changeActiveSubscriptionType,
      ),
      filter(({subType}) => subType === SubscriptionType.AUTHORS),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectAllUserAuthors)),
      ),
      map(([{subType}]) => fromActions.getNewsForSubscription({subType}))
    ), {dispatch: true}
  );

  public changeActiveSubscriptionTypeToChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.changeActiveSubscriptionType,
      ),
      filter(({subType}) => subType === SubscriptionType.CHANNELS),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectAllUserChannels)),
      ),
      map(([{subType}]) => fromActions.getNewsForSubscription({subType}))
    ), {dispatch: true}
  );

  public changeActiveSubscriptionTypeToTopics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.changeActiveSubscriptionType,
      ),
      filter(({subType}) => subType === SubscriptionType.TOPICS),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectAllUserTopics)),
      ),
      map(([{subType}]) => fromActions.getNewsForSubscription({subType}))
    ), {dispatch: true}
  );

  public markRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromContentItem.markReadSuccess,
      ),
      map(({contentId}) => fromActions.markRead({id: contentId}))
    ), {dispatch: true}
  );

  public addBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addBookmark),
      mergeMap(({id}) => this.contentService.addBookmark({contentId: id}).pipe(
        map(() => fromActions.addBookmarkSuccess({id})),
        catchError(() => of(fromActions.addBookmarkFailure()))
      ))
    ), {dispatch: true}
  );

  public removeBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeBookmark),
      mergeMap(({id}) => this.contentService.removeBookmark({contentId: id}).pipe(
        map(() => fromActions.removeBookmarkSuccess({id})),
        catchError(() => of(fromActions.removeBookmarkFailure()))
      ))
    ), {dispatch: true}
  );

  public modifySubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.changeSubscriptionsEditMode),
      filter(({opened}) => opened === false),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectCurrentType))),
      switchMap(([action, type]) => [fromActions.getNewsForSubscription({subType: type}), fromActions.setLocalFilter({localSearch: null})])
    ), {dispatch: true}
  );

  public openConfirmAddDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openConfirmAddDialog),

      switchMap(({id, subType}) => {
          switch (subType) {
            case SubscriptionType.TOPICS:
              return this.store$.pipe(
                select(fromSelectors.selectAllUserTopics),
                first((subscriptions) => subscriptions.length > 0),
                map((subscriptions) => ({
                  id,
                  subType,
                  isInSubscription: subscriptions.findIndex(subscription => subscription.id === id) > -1,
                }))
              )
            case SubscriptionType.CHANNELS:
              return this.store$.pipe(
                select(fromSelectors.selectAllUserChannels),
                first((subscriptions) => subscriptions.length > 0),
                map((subscriptions) => ({
                  id,
                  subType,
                  isInSubscription: subscriptions.findIndex(subscription => {
                    return subscription.id === id
                  }) > -1
                }))
              )
            case SubscriptionType.TOPICS:
              return this.store$.pipe(
                select(fromSelectors.selectAllUserTopics),
                first((subscriptions) => subscriptions.length > 0),
                map((subscriptions) => ({
                  id,
                  subType,
                  isInSubscription: subscriptions.findIndex(subscription => subscription.id === id) > -1
                }))
              )
          }
        }
      ),
      filter(({isInSubscription}) => !isInSubscription),
      switchMap(({id, subType}) =>
        this.store$.pipe(
          select(fromContentList.selectName),
          first((contentName) => !!contentName),
          map((contentName) => ({
            id,
            subType,
            contentName
          }))
        )),
        switchMap(({id, subType, contentName}) => this.dialogService.open(ConfirmDialogComponent, {
          data: {
              title: `Are you sure you want to add "${contentName}" to ${subType} list?`,
              messages: [
                  `To remove it, click unsubscribe button`,
              ],
              confirmButtonType: 'primary',
              confirmButtonLabel: `Yes, add`
          },
          size: 'm'
      }).afterClosed().pipe(
          filter(data => !!data),
          map(() => fromActions.addSubscriptionByIdsRequest({ids: [id], subType}))
      ))
    ));

  public getAllNewsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.getNewsSuccess,
      ),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectCurrentType))),
      switchMap(([action, type]) => [fromActions.getNewsForSubscription({subType: type})])
    ));

  public setSortType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.setSortType,
      ),
      switchMap(({sortType}) => [fromContentList.setSortType({sortType})])
    ), {dispatch: true});

  constructor(
    private actions$: Actions,
    private store$: Store<fromHome.State>,
    private contentService: ContentService,
    private managingChannelsService: ManagingChannelsService,
    private subscriptionsService: SubscriptionsService,
    private workdayService: WorkdayService,
    private dialogService: RdsDialogService
  ) {
  }
}
