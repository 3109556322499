import {Component, Inject} from '@angular/core';
import {select, Store} from '@ngrx/store';

import * as fromUiReducer from '@app/root-store/ui/ui.reducer';
import * as fromQuickLinkActions from '@app/layout/header/store/quick-link/quick-link.actions';
import * as fromUiScreen from '@app/root-store/ui/screen';
import {Observable} from 'rxjs';
import {moveItemInArray} from '@angular/cdk/drag-drop';
import {QuickLink} from '@app/layout/header/models';
import {RDS_DIALOG_DATA, RdsDialogRef} from '@rds/angular-components';

export interface ReorganizeQuickLinksDialogData {
  quickLinks: Array<QuickLink>;
}

@Component({
  selector: 'rh-reorganize-quicklinks-dialog.component',
  templateUrl: './reorganize-quicklinks-dialog.component.html',
  styleUrls: ['./reorganize-quicklinks-dialog.component.scss']
})
export class ReorganizeQuickLinksDialogComponent {

  quickLinks: Array<QuickLink>;

  public isMobile$: Observable<boolean> = this.store$.pipe(
    select(fromUiScreen.selectIsMobile));

  public closeDialog() {
    this.dialog.close(this.quickLinks.map((ql) => ql.quickLinkId))
  }

  drop(event) {
    moveItemInArray(this.quickLinks, event.previousIndex, event.currentIndex);
    this.store$.dispatch(fromQuickLinkActions.setTemporaryFavouriteQuickLinks({quickLinks: [...this.quickLinks]}));
  }

  constructor(
    @Inject(RDS_DIALOG_DATA) public data: ReorganizeQuickLinksDialogData,
    private dialog: RdsDialogRef<ReorganizeQuickLinksDialogComponent>,
    private store$: Store<fromUiReducer.State>
  ) {
    if (!!data) {
      this.quickLinks = [...data.quickLinks];
    }
  }
}
