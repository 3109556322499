<div class="row">
  <div class="col-12 mb-10" [formGroup]="commentForm">
    <div class="row row-centered">
      <div class="col-auto pe-2">
        <span class="ui-label-m-bold">
          Comments ({{numComments}})
        </span>
      </div>
      <div  *ngIf="!commentsEnabled" class="col-auto ps-2">
        <rds-icon rdsTooltip="Comments have been disabled for this Article" icon="info"  class="text-icon" namespace="outlined"></rds-icon>
      </div>
      <div *ngIf="isNewsboardAdmin" class="col-auto offset-auto mb-3">
        <button *ngIf="!commentsEnabled" (click)="enableCommenting()" rds-secondary-button size="s">Enable commenting</button>
        <button *ngIf="commentsEnabled" (click)="disableCommenting()" rds-secondary-button size="s">Disable commenting</button>
      </div>
    </div>

    <ng-container *ngIf="commentsEnabled">
      <rh-comment-input class="main-textarea" [label]="'Commenting as '+ userFullName" [mentionAutocomplete]="mentionAutocomplete" (getAutocomplete)="onGetAutocomplete($event)" (clearAutocomplete)="onClearAutocomplete()" [error]="(commentForm.get('comment').value | countText) > 1200" formControlName="comment"></rh-comment-input>
      <div class="comment-hints mt-3">
        <rds-control-hint>Please read the <a rds-link href="https://sites.google.com/roche.com/intranet-policies/rh-community-guidelines" target="_blank">Community Guidelines</a> and adhere to them</rds-control-hint>
        <rds-control-hint class="right"><span [class.error]="(commentForm.get('comment').value | countText) > 1200">{{commentForm.get('comment').value | countText}}</span>/1200</rds-control-hint>
      </div>
      <div class="actions mt-5">
        <button rds-text-button (click)="postComment()" size="m" [disabled]="(commentForm.get('comment').value | countText) === 0 || (commentForm.get('comment').value | countText) > 1200">
          Post comment
      </button>
      </div>
    </ng-container>
  </div>
</div>
<div [ngClass]="{
  'hidden-comment': commentsHidden,
  'rds-shadow-1': commentsHidden,
  'p-7': commentsHidden,

}">
  <div *ngIf="commentsHidden && isNewsboardAdmin" class="row row-centered">
    <div class="col-auto mb-7">
      <p class="ui-label-l-bold">
        <rds-icon icon="hide" namespace="outlined"></rds-icon>
        All comments were hidden
      </p>

    </div>
  </div>
  <ng-container *ngFor="let comment of (visibleComments | keyvalue:compareComments); let i = index;">
    <ng-container *ngIf="commentsExpanded || (!commentsExpanded && i < 3) || commentsHidden">
      <ng-container *ngTemplateOutlet="commentTemplate; context:{ $implicit: comment.value, isReply: false }" ></ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!commentsExpanded && !commentsHidden && (comments | keyvalue:compareComments).length > 3">
    <div class="row">
      <div class="col-4 offset-4">
        <button rds-secondary-button class="show-all" size="m" (click)="commentsExpanded = true">
          Show all comments
        </button>
      </div>
    </div>
  </ng-container>
</div>


<ng-template #commentTemplate let-comment let-isReply="isReply" let-isParentHidden="isParentHidden" let-parentId="parentId">
  <div [ngClass]="{
    'hidden-comment': comment.status === CommentStatus.HIDDEN && !isParentHidden && !commentsHidden,
    'rds-shadow-1': comment.status === CommentStatus.HIDDEN && !isParentHidden && !commentsHidden,
    'p-7': comment.status === CommentStatus.HIDDEN && !isParentHidden && !commentsHidden,
    'mb-7': !isReply,
    'mt-7': isReply
  }">
    <div *ngIf="comment.status === CommentStatus.HIDDEN && !isParentHidden && !commentsHidden" class="row row-centered">
      <div class="col-auto mb-7">
        <p class="ui-label-l-bold">
          <rds-icon icon="hide" namespace="outlined"></rds-icon>
          <span *ngIf="isNewsboardAdmin">
            Hidden comment from "{{comment.author.name}}"
          </span>
          <span *ngIf="comment.canEdit && !isNewsboardAdmin">
            Comment hidden due to not adhering to the <a rds-link href="https://sites.google.com/roche.com/intranet-policies/rh-community-guidelines" target="_blank">Community Guidelines</a>
          </span>
        </p>

      </div>
      <div *ngIf="isNewsboardAdmin" class="col-auto offset-auto mb-7">
        <button class="me-5" (click)="deleteComment(comment.id+'', parentId)" #hideBtn rds-text-button>Delete</button>
        <button (click)="revealComment(comment.id+'', parentId)" #revealBtn rds-text-button>Reveal</button>
      </div>
    </div>
    <div class="row" [formGroup]="commentForm">
      <div class="col-12">
        <div [id]="'comment-' + comment.id" class="comment-card" [ngClass]="{
          'comment-card--hidden': comment.status === CommentStatus.HIDDEN || isParentHidden || commentsHidden,
          'rds-shadow-1': comment.status === CommentStatus.HIDDEN && !isParentHidden && !commentsHidden,
          'py-7': comment.status === CommentStatus.HIDDEN && !isParentHidden && !commentsHidden && isReply,
          'reply': isReply
        }">
          <div class="comment-card__comment">
            <div class="comment-card__comment--body">
              <rh-user-single
                class="comment-card__comment--avatar"
                size="xl"
                [user]="{login: comment.author.login, name: comment.author.name}"
              >
              </rh-user-single>
              <div class="comment-card__comment--content">
                <p class="ui-label-m-bold mb-2">
                  {{comment.author.name}}
                </p>
                <div class="comment-card__comment--actions mb-3">
                  <p class="ui-component-text-m mb-0">
                    {{(comment.modifiedDate || comment.createdDate) | timeAgo}}
                  </p>
                  <ng-container *ngIf="comment.status !== CommentStatus.HIDDEN && !isParentHidden && !commentsHidden">
                    <ng-container *ngIf="isEditing(comment.id) || isReplying(comment.id)" >
                      <rds-divider vertical></rds-divider>
                      <button #cancelBtn (click)="cancel()" rds-text-button>Cancel</button>
                    </ng-container>
                    <ng-container *ngIf="commentsEnabled">
                      <ng-container *ngIf="comment.canEdit && !isEditing(comment.id)">
                        <rds-divider vertical></rds-divider>
                        <button (click)="edit(comment.id, comment.text)" #editBtn rds-text-button>Edit</button>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="(canDeleteComments || comment.canEdit)" >
                      <rds-divider vertical></rds-divider>
                      <button (click)="deleteComment(comment.id+'', parentId)" #deleteBtn rds-text-button>Delete</button>
                    </ng-container>
                    <ng-container *ngIf="commentsEnabled">
                      <ng-container *ngIf="!isReply && !isReplying(comment.id)">
                        <rds-divider vertical></rds-divider>
                        <button (click)="reply(comment.id)" #replyBtn rds-text-button>Reply</button>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="isNewsboardAdmin && comment.status !== CommentStatus.HIDDEN">
                      <rds-divider vertical></rds-divider>
                      <button (click)="hideComment(comment.id+'', parentId)" #hideBtn rds-text-button>Hide</button>
                    </ng-container>
                  </ng-container>
                </div>

                <p *ngIf="!isEditing(comment.id)" class="ui-body-m mb-3" [innerHTML]="urlify(comment.text) | SafeHtml"></p>
                <ng-container *ngIf="isEditing(comment.id)">
                  <rh-comment-input [label]="'Editing as '+ userFullName" [mentionAutocomplete]="mentionAutocomplete" (getAutocomplete)="onGetAutocomplete($event)"  (clearAutocomplete)="onClearAutocomplete()" [error]="(commentForm.get(comment.id+'').value | countText) > 1200" [formControl]="commentForm.get(comment.id+'')"></rh-comment-input>
                  <div class="comment-hints mt-3 editing">
                    <rds-control-hint>Please read the <a rds-link href="https://sites.google.com/roche.com/intranet-policies/rh-community-guidelines" target="_blank">Community Guidelines</a> and adhere to them</rds-control-hint>
                    <rds-control-hint class="right"><span [class.error]="(commentForm.get(comment.id+'').value | countText) > 1200">{{commentForm.get(comment.id+'').value | countText}}</span>/1200</rds-control-hint>
                  </div>
                  <div class="actions mt-5">
                    <button rds-text-button (click)="saveEdit(comment.id+'', parentId)" size="s" [disabled]="commentForm.get(comment.id+'').value.length === 0 || (commentForm.get(comment.id+'').value | countText) > 1200">
                      Save changes
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="row" *ngIf="isReplying(comment.id)">
              <div class="col-12">
                <span class="ui-label-m-bold">
                  Comment
                </span>
                <rh-comment-input [mentionAutocomplete]="mentionAutocomplete" [label]="'Replying as '+ userFullName" (getAutocomplete)="onGetAutocomplete($event)"  (clearAutocomplete)="onClearAutocomplete()" [error]="(commentForm.get('reply-'+comment.id).value | countText) > 1200" [formControl]="commentForm.get('reply-'+comment.id)"></rh-comment-input>
                <div class="comment-hints mt-3">
                  <rds-control-hint>Please read the <a rds-link href="https://sites.google.com/roche.com/intranet-policies/rh-community-guidelines" target="_blank">Community Guidelines</a> and adhere to them</rds-control-hint>
                  <rds-control-hint class="right"><span [class.error]="(commentForm.get('reply-'+comment.id).value | countText) > 1200">{{commentForm.get('reply-'+comment.id).value | countText}}</span>/1200</rds-control-hint>
                </div>
                <div class="actions mt-5">
                  <button rds-text-button (click)="sendReply('reply-'+comment.id)" size="s" [disabled]="commentForm.get('reply-'+comment.id).value?.length === 0 || (commentForm.get('reply-'+comment.id).value | countText) > 1200">
                    Post reply
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngFor="let response of (comment.responses | keyvalue); trackBy: trackByFn; let last = last;">
            <ng-container *ngTemplateOutlet="commentTemplate; context:{ $implicit: response.value, isReply: true, isParentHidden: comment.status === CommentStatus.HIDDEN, parentId: comment.id+'' }" ></ng-container>
            <rds-divider class="mt-7" *ngIf="!last"></rds-divider>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

</ng-template>
