<div class="row">
  <div class="col lmb-3">
    <div class="row row-centered">
      <div class="col">
        <div class="row">
          <div class="col">
            <h1 class="ui-heading-1-bold me-auto">
              <ng-content select="[title]"></ng-content>
            </h1>
            <h3 class="ui-heading-3 me-auto">
              <ng-content select="[subtitle]"></ng-content>
            </h3>
            <ng-content select="[description]"></ng-content>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <div class="actions">
          <ng-content select="
          [rds-icon-button],
          [rds-warning-button],
          [rds-text-button],
          [rds-secondary-button],
          [rds-primary-button],
          [rds-dropdown-toggle-primary-button],
          ng-container,
          rh-add-news-button,
          rh-create-content-button"></ng-content>
          <rh-add-news-button *ngIf="!hideAddNews" [breadcrumbRoutes]="breadcrumbRoutes"></rh-add-news-button>
        </div>
      </div>
    </div>
  </div>
</div>