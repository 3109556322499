import {createSelector} from '@ngrx/store';
import * as fromUser from './user.reducer';
import * as fromUserChannels from '@core/core-store/user-channels';

export const selectUserProfile = createSelector(
  fromUser.selectState,
  fromUser.getUserProfile,
);

export const selectLogin = createSelector(
  selectUserProfile,
  fromUser.getLogin,
);

export const selectEmail = createSelector(
  selectUserProfile,
  fromUser.getEmail,
);

export const selectWasWelcomePageDisplayed = createSelector(
  selectUserProfile,
  fromUser.getWasWelcomePageDisplayed,
);

export const selectWasUserSettingsDisplayed = createSelector(
  selectUserProfile,
  fromUser.getWasUserSettingsDisplayed,
);

export const selectWasQuickLinksPageDisplayed = createSelector(
  selectUserProfile,
  fromUser.getWasQuickLinksPageDisplayed,
);

export const selectWasCatalogPageDisplayed = createSelector(
  selectUserProfile,
  fromUser.getWasCatalogPageDisplayed,
);

export const selectWasSearchPageDisplayed = createSelector(
  selectUserProfile,
  fromUser.getWasSearchPageDisplayed,
);

export const selectWasMyContentPageDisplayed = createSelector(
  selectUserProfile,
  fromUser.getWasMyContentPageDisplayed,
);

export const selectWasLayoutSettingsPageDisplayed = createSelector(
  selectUserProfile,
  fromUser.getWasLayoutSettingsPageDisplayed,
);

export const selectWasMenuDisplayed = createSelector(
  selectUserProfile,
  fromUser.getWasMenuDisplayed,
);

export const selectFirstName = createSelector(
  selectUserProfile,
  fromUser.getFirstName,
);

export const selectLastName = createSelector(
  selectUserProfile,
  fromUser.getLastName,
);

export const selectFullName = createSelector(
  selectFirstName,
  selectLastName,
  fromUser.getFullName
);

export const selectChannelIds = createSelector(
  fromUser.selectState,
  fromUser.getChannelIds,
);

export const selectWorkdayData = createSelector(
  fromUser.selectState,
  fromUser.getWorkdayData,
);

export const selectChannels = createSelector(
  selectChannelIds,
  fromUserChannels.selectEntities,
  fromUser.getChannels
)

export const selectNewsLanguage = createSelector(
  selectUserProfile,
  fromUser.getNewsLaguage
);

export const selectNewsLanguageCode = createSelector(
  selectUserProfile,
  fromUser.getNewsLaguageCode
);


export const selectNewsBoardUser = createSelector(
  fromUser.selectState,
  fromUser.getNewsBoardUser
);

export const selectInitialRoles = createSelector(
  fromUser.selectState,
  fromUser.getInitialRoles
);

export const selectRoles = createSelector(
  fromUser.selectState,
  fromUser.getRoles
);

export const selectAllRoles = createSelector(
  selectNewsBoardUser,
  selectRoles,
  (user, roles) => roles ? (user?.role ? [...roles, user.role] : [roles]) : []
)

export const selectIsOnlyAudienceManager = createSelector(
  selectRoles,
  fromUser.getIsOnlyAudienceManager
);

export const selectIsAudienceManager = createSelector(
  selectRoles,
  fromUser.getIsAudienceManager
);

export const selectIsAdmin = createSelector(
  selectRoles,
  fromUser.getIsAdmin
);

export const selectIsNewsboardAdmin = createSelector(
  selectRoles,
  fromUser.getIsNewsboardAdmin
);

export const selectIsNewsboardSuperAdmin = createSelector(
  selectRoles,
  fromUser.getIsNewsboardSuperAdmin
);

export const selectManagedTaxonomy = createSelector(
  fromUser.selectState,
  fromUser.getManagedTaxonomy
);

export const selectDataForManageSets = createSelector(
  selectIsAudienceManager,
  selectIsOnlyAudienceManager,
  selectManagedTaxonomy,
  (isAudienceManager, isOnlyAudienceManager, managedTaxonomy) => ({isAudienceManager, isOnlyAudienceManager, managedTaxonomy})
)

export const selectInitialManagedTaxonomy = createSelector(
  fromUser.selectState,
  fromUser.getInitialManagedTaxonomy
);

export const selectPersonalizationPreferences = createSelector(
  fromUser.selectState,
  fromUser.getPersonalizationPreferences
);

export const selectPersonalizationPreferencesLocations = createSelector(
  selectPersonalizationPreferences,
  fromUser.getPersonalizationPreferencesLocations
);

export const selectPersonalizationPreferencesDepartments = createSelector(
  selectPersonalizationPreferences,
  fromUser.getPersonalizationPreferencesDepartments
);

export const selectPersonalizationPreferencesFunctions = createSelector(
  selectPersonalizationPreferences,
  fromUser.getPersonalizationPreferencesFunctions
);

export const selectFailure = createSelector(
  fromUser.selectState,
  fromUser.getFailure,
);
