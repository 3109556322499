<div class="rhd__dropdown" #optionsOrigin="cdkOverlayOrigin" #dropdownElement cdkOverlayOrigin
     (rhClickOutside)="floatingOptions? {} : onOutsideClick()"
     [ngClass]="{'rhd__dropdown__disabled': disabled, 'rhd__dropdown__transparent':transparent}">
  <div
    #contentTrigger
    class="rhd__dropdown--custom-trigger"
    [class.hidden]="noCustomTrigger"
    (click)="toggleDropdown()"
  >
    <ng-content select="[trigger]"></ng-content>
  </div>
  <div class="trigger__wrapper" *ngIf="noCustomTrigger && !alwaysOpened">
    <div *ngIf="!isMultiple && !showChips" class="floating-label__wrapper">
      <div
        class="floating-label__text"
        [class.floating-label__text--float]="opened || chipsList.length > 0"
        [requireAsterisk]="required && !disabled"
      >
        {{placeholder}}
      </div>
    </div>
    <button rds-dropdown-toggle-button [class.error]="control.touched && !!control.errors" [isOpen]="opened" size="l"
            (click)="toggleDropdown()" *ngIf="noCustomTrigger && !alwaysOpened">
      <ng-container *ngIf="placeholder?.length; else labelTemplate">
        <ng-container *ngIf="isMultiple || showChips">
          {{placeholder}}
        </ng-container>
        <ng-container *ngIf="!isMultiple && !showChips && chipsList.length > 0">
        <span class="floating-label text-ellipsis">
            <span
              *ngIf="chipsList[0] | dropdownKey:optionsModel.parentNameKey ?? ''">{{(chipsList[0] | dropdownKey:optionsModel.parentNameKey ?? '')}}
              :&nbsp;</span>
            <span>{{chipsList[0] | dropdownKey:optionsModel.nameKey ?? ''}}</span>
        </span>

        </ng-container>
      </ng-container>
      <ng-template #labelTemplate>
        <ng-content select="[label]"></ng-content>
      </ng-template>
    </button>
  </div>


  <ng-container *ngIf="alwaysOpened || opened && !floatingOptions">
    <ng-container *ngTemplateOutlet="elementsBody"></ng-container>
  </ng-container>
  <div
    *ngIf="showChips"
    [ngClass]="{
      'rhd__dropdown__chosen-elements': true,
      'rhd__dropdown__chosen-elements--small': (size) === 'small'
    }"
  >
    <ng-container *ngIf="chipsList">
      <div class="rhd__dropdown__chosen-elements--chips-container" atrOverflowingContent overflowingContent="86"
           #overflowing="overflowingContent">
        <rds-chip-list size="s" type="filter">
          <rds-chip *ngIf="chipsList.length === 0" [disabled]="true">{{chipPlaceholder}}</rds-chip>
          <rds-chip *ngFor="let chip of chipsList" rdsChipInput (closeChip)="remove(chip)"
                    [disabled]="disabled">
                              <span class="text-ellipsis">
                                <span
                                  *ngIf="chip | dropdownKey:optionsModel.parentNameKey ?? ''">{{(chip | dropdownKey:optionsModel.parentNameKey ?? '')}}
                                  :&nbsp;</span>
                                <span>{{chip | dropdownKey:optionsModel.nameKey ?? ''}}</span>
                              </span>
          </rds-chip>
        </rds-chip-list>
      </div>
      <div *ngIf="overflowing.isOverflowing" class="rhd__dropdown__chosen-elements--actions-container">
        <button rds-text-button
                (click)="overflowing.isExpanded ? overflowing.collapse() : overflowing.expand()">
          Show {{overflowing.isExpanded ? 'less' : 'all'}}
          <rds-icon [icon]="overflowing.isExpanded ? 'chevron_up' : 'chevron_down'"></rds-icon>
        </button>
      </div>
    </ng-container>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayMinWidth]="dropdownElement.offsetWidth"
  [cdkConnectedOverlayOrigin]="optionsOrigin"
  [cdkConnectedOverlayOpen]="opened && floatingOptions"
  [cdkConnectedOverlayOffsetX]="0"
  [cdkConnectedOverlayOffsetY]="0"
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="rhd__options--backdrop"
  (backdropClick)="toggleDropdown()"
  (overlayKeydown)="onKeydown($event)"
>
  <ng-container *ngTemplateOutlet="elementsBody"></ng-container>
</ng-template>

<ng-template #elementsBody>
  <div
    [class.rhd__dropdown__options--floating]="floatingOptions"
    [class.rds-shadow-2]="floatingOptions"
    [class.rhd__dropdown__options--opened]="alwaysOpened || opened"
    [class.rhd__dropdown__options--closed]="!alwaysOpened && !opened"
    class="rhd__dropdown__options"
    [class.rhd__dropdown__transparent]="transparent"
  >
    <form *ngIf="(showSearch) === true" [formGroup]="searchDropdownForm">
      <rds-select-search-input (changed)="searchChanged($event)"
                               [ngClass]="{'rds-select__option--search-input-transparent': transparent}"></rds-select-search-input>
      <rds-select-option-separator></rds-select-option-separator>
    </form>
    <div class="rhd__dropdown__options__elements">
      <cdk-virtual-scroll-viewport #scrollViewport [appendOnly]="true"
                                   class="rhd__dropdown__options__elements--scroll-viewport"
                                   [style.height]="40 * (visibleElementsNumber > visibleNodes.length? visibleNodes.length : visibleElementsNumber) + 'px'"
                                   [itemSize]="40" [minBufferPx]="10 * 40" [maxBufferPx]="20 * 40">
        <ng-container
          *cdkVirtualFor="let node of visibleNodes; let i = index; trackBy: trackByFn; templateCacheSize: 0">
          <rh-dropdown-node
            [nodeScope]="{
                                    hasChild: hasChild(node),
                                    isAnyChildSelected: isAnyChildSelected(node),
                                    areAllChildsSelected: areAllChildsSelected(node),
                                    isSelected: selection.isSelected(node),
                                    searchPhrase: searchPhrase,
                                    isExpanded: treeControl.isExpanded(node),
                                    isAnyChildMatchingPhrase: isAnyChildMatchingPhrase(node, searchPhrase),
                                    node: node,
                                    hasSelectionValue: selection.hasValue(),
                                    isMultiple: isMultiple,
                                    size: size,
                                    optionsModel: optionsModel,
                                    selectParentWhenAllSelected: selectParentWhenAllSelected
                                  }"
            (toggle)="treeControl.toggle($event)"
            (checkboxChange)="toggleNodeChckbx($event)"
            (nameClick)="select(node)"
            tabindex="0" (click)="isMultiple? setActiveItem(node) : {}"></rh-dropdown-node>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</ng-template>
