import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from '@app/home/store/latest-section/latest-section.actions';
import { Status, STATUS_FAILURE, STATUS_LOADING, STATUS_NOT_ASKED, STATUS_SUCCESS } from '@app/core/models/status.model';
import { ContentItem } from '@app/core/models';
import * as fromContentItemActions from '@core/core-store/content-items/content-items.actions';

interface LatestNewsSection {
  latestNews: Array<ContentItem>;
}

export interface State extends LatestNewsSection, Status {
}

export const initialState: State = {
  ...STATUS_NOT_ASKED,
  latestNews: [],
};

export const latestNewsReducer = createReducer(
  initialState,
  on(fromActions.getLatestNews, (state) => ({
    ...state,
    ...STATUS_LOADING,
    latestNews: [],
  })),
  on(fromActions.getLatestNewsSuccess, (state, {latestNews}) => ({
    ...state,
    latestNews,
    ...STATUS_SUCCESS,
  })),
  on(fromActions.getLatestNewsFailed, (state) => ({
    ...state,
    ...STATUS_FAILURE,
  })),
  on(fromContentItemActions.putBookmarkByContentIdSuccess, (state, {contentId}) => ({
      ...state,
      latestNews: [...state.latestNews.map((item) => {
        if (item.id === contentId) {
          return { ...item, hasBookmarked: true };
        }
        return item;
      })]
    })),
  on(fromContentItemActions.removeBookmarkByContentIdSuccess, (state, {contentId}) => ({
    ...state,
    latestNews: [...state.latestNews.map((item) => {
      if (item.id === contentId) {
        return { ...item, hasBookmarked: false };
      }
      return item;
    })]
  })),
  on(fromContentItemActions.putLikeByContentIdSuccess, (state, {contentId, numLikes}) => {
      return {
        ...state,
        latestNews: [ ...state.latestNews.map((item) => {
          if (item.id === contentId) {
            return {...item, hasLiked: true, numLikes };
          }
          return item;
        }) ]
      };
    }),
  on(fromContentItemActions.removeLikeByContentIdSuccess, (state, {contentId, numLikes}) => {
    return {
      ...state,
      latestNews: [...state.latestNews.map((item) => {
        if (item.id === contentId) {
          return { ...item, hasLiked: false, numLikes };
        }
        return item;
      })]
    };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return latestNewsReducer(state, action);
}

export const getData = (state: State) => state.latestNews;
export const getLoading = (state: State) => state.loading;
export const getSuccess = (state: State) => state.success;
export const getFailure = (state: State) => state.failure;
