import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Audience, taxonomyOptionsModel} from '@app/core/models';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

import * as fromDepartmentsFlat from '@app/core/core-store/departments-flat';
import * as fromFunctionsFlat from '@app/core/core-store/functions-flat';
import * as fromLocationsFlat from '@app/core/core-store/locations-flat';
import * as fromSearchingPreferences from '../../store/searching-preferences/';

import {SubSink} from 'subsink';


@Component({
  selector: 'rh-searching-preferences',
  templateUrl: './searching-preferences.container.html',
  styleUrls: ['./searching-preferences.container.scss']
})
export class SearchingPreferencesContainer implements OnInit, OnDestroy {
  private subs = new SubSink();

  form: FormGroup = new FormGroup({
    locations: new FormControl([]),
    departments: new FormControl([]),
    functions: new FormControl([]),
  });

  initialFormValue;

  get nothingChanged() {
    return JSON.stringify(this.initialFormValue) === JSON.stringify(this.form.value);
  }

  public taxonomyOptModel = taxonomyOptionsModel;

  public departmentsDict$ = this.store$.pipe(select(fromDepartmentsFlat.selectAllActive));
  public functionsDict$ = this.store$.pipe(select(fromFunctionsFlat.selectAllActive));
  public locationsDict$ = this.store$.pipe(select(fromLocationsFlat.selectAllActive));


  public form$: Observable<Audience> = this.store$.pipe(
    select(fromSearchingPreferences.selectForm));

  public initialForm$: Observable<Audience> = this.store$.pipe(
    select(fromSearchingPreferences.selectInitialForm));

  ngOnInit() {
    this.store$.dispatch(fromSearchingPreferences.initForm());

    this.store$.dispatch(fromDepartmentsFlat.loadDepartmentsFlat());
    this.store$.dispatch(fromFunctionsFlat.loadFunctionsFlat());
    this.store$.dispatch(fromLocationsFlat.loadLocationsFlat());

    this.subs.sink = this.form$.pipe(
      distinctUntilChanged()
    ).subscribe(form => {
      this.form.patchValue(form, {emitEvent: false});
    });

    this.subs.sink = this.initialForm$.pipe(
      distinctUntilChanged(),
      debounceTime(50)
    ).subscribe(form => {
      this.initialFormValue = form;
      this.cdr.markForCheck();
    });

    this.subs.sink = this.form.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(50)
    ).subscribe((form) => {
      this.store$.dispatch(fromSearchingPreferences.setFormValue({form}))
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  reset() {
    this.store$.dispatch(fromSearchingPreferences.openResetPersonalizationPreferencesConfirmDialog());
  }

  cancel() {
    this.form.patchValue(this.initialFormValue);
  }

  save() {
    this.store$.dispatch(fromSearchingPreferences.savePersonalizationPreferencesRequest());
  }

  constructor(
    private store$: Store<fromSearchingPreferences.State>,
    private cdr: ChangeDetectorRef
  ) {
  }
}
