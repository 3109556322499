import { createFeatureSelector, combineReducers, Action } from '@ngrx/store';
import * as fromChannelsSection from './channels-section/channels-section.reducer';
import * as fromLatestNewsSection from './latest-section/latest-section.reducer';
import * as fromHomePage from './home-page/home-page.reducer';
import * as fromHomeTicker from './ticker/ticker.reducer';
import * as fromHomeAlert from './alert/alert.reducer';
import * as fromHomeNewsfeed from './newsfeed/newsfeed.reducer';
import * as fromEvent from './event/event.reducer';
import * as fromHeroSection from './hero-section/hero-section.reducer';
import * as fromHSubscriptions from './subscriptions/subscriptions.reducer';

export const feature = 'home';

export interface State {
  channelsSection: fromChannelsSection.State;
  latestNewsSection: fromLatestNewsSection.State;
  homePage: fromHomePage.State;
  ticker: fromHomeTicker.State;
  subscriptions: fromHSubscriptions.State;
  newsFeed: fromHomeNewsfeed.State;
  heroSection: fromHeroSection.State;
  event: fromEvent.State;
	alert: fromHomeAlert.State
}

export function reducer(state: State | undefined, action: Action) {
  return combineReducers({
    channelsSection: fromChannelsSection.reducer,
    latestNewsSection: fromLatestNewsSection.reducer,
    homePage: fromHomePage.reducer,
    ticker: fromHomeTicker.reducer,
    subscriptions: fromHSubscriptions.reducer,
    newsFeed: fromHomeNewsfeed.reducer,
    heroSection: fromHeroSection.reducer,
    event: fromEvent.reducer,
		alert: fromHomeAlert.reducer
  })(state, action);
}

export const selectState = createFeatureSelector<State>(feature);

export const getChannelsSection = (state: State) => state.channelsSection;
export const getLatestNewsSection = (state: State) => state.latestNewsSection;
export const getHomePage = (state: State) => state.homePage;
export const getHomeTicker = (state: State) => state.ticker;
export const getHomeAlert = (state: State) => state.alert;
export const getSubscriptions = (state: State) => state.subscriptions;
export const getNewsFeed = (state: State) => state.newsFeed;
export const getHeroSection = (state: State) => state.heroSection;
export const getEvent = (state: State) => state.event;
