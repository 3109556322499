import { Component, Inject, ViewChild } from '@angular/core';
import { AlertForm } from '@app/core/models/alerts.model';
import { BackButton } from '@app/root-store/ui/back-button';
import { RDS_DIALOG_DATA, RdsDialogRef, RdsDialogService, RdsTabsComponent } from '@rds/angular-components';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog.component';
import { AlertRecievedDialogComponent, AlertRecievedDialogData } from '../alert-recieved-dialog/alert-recieved-dialog.component';

export interface PreviewButtonState {
  visible: boolean;
  disabled: boolean;
}

export interface AlertPreviewButtons {
  canSaveAlert?: PreviewButtonState
}

export interface AlertPreviewDialogData  {
  alert: AlertForm;
  context: 'Editor';
  buttons?: AlertPreviewButtons
  backButton?: BackButton;
}

@Component({
  selector: 'rh-alert-preview-dialog',
  templateUrl: './alert-preview-dialog.component.html',
  styleUrls: ['./alert-preview-dialog.component.scss'],
})
export class AlertPreviewDialogComponent {
  @ViewChild('tabs') tabs: RdsTabsComponent;

  iosImageUrl = './assets/images/alerts/ios-preview.png';
  androidImageUrl = './assets/images/alerts/android-preview.png';
  webImageUrl = './assets/images/alerts/web-preview.png';

  context: 'Editor';
  backButton: BackButton;
  buttons: AlertPreviewButtons;
  alert: AlertForm;
  confirmData: ConfirmDialogData;

  constructor(
    private dialogService: RdsDialogService,
    private dialogRef: RdsDialogRef<AlertPreviewDialogComponent>,
    @Inject(RDS_DIALOG_DATA) data: AlertPreviewDialogData
  ) {
    this.alert = data.alert;
    this.confirmData = {
      title: this.alert.title.length > 0 ? this.alert.title : 'Alert Title',
      messages: [this.alert.message.length > 0 ? this.alert.message : 'The alert message should be short and include only the most important information.'],
      confirmButtonLabel: 'Read more',
      confirmButtonType: 'warning'
    }
    this.context = data?.context;
    this.backButton = data?.backButton;
    this.buttons = data?.buttons;
  }

  close(status?): void {
    this.dialogRef.close({status});
  }

  openDialogPreview() {
    const data: AlertRecievedDialogData = {
      alert: {
        id: null,
        message: this.alert.message,
        title: this.alert.title,
        url: this.alert.url
      }
    }
    this.dialogService.open(AlertRecievedDialogComponent, {
      data,
      size: 'm',
      height: 'auto',
      maxHeight: '90%'
    })
  }

  gotoLink(): void {
    window.open(this.alert.url, '_blank');
  }

}
