<div class="warning-container row row-centered">
  <div class="col-auto offset-both">
    <rds-empty-state alignment="center" size="m">
      <img rds-empty-state-image src="../../../../assets/images/desktop-only.png"/>
      <rds-empty-state-title class="mb-3">This page is optimized for desktop use.</rds-empty-state-title>
      <rds-empty-state-subtitle class="mb-3">Please maximize your browser window and set display scaling to 150% or
        lower. For insctructions <a rds-link target="_blank"
                                    href="https://sites.google.com/roche.com/newsboardguide/faq#h.zf393g2czzs4">click
          here</a>.
      </rds-empty-state-subtitle>
      <button class="m-auto mt-4 mb-6" rds-primary-button rds-empty-state-button [routerLink]="''">Back to RocheHome</button>
    </rds-empty-state>
  </div>
</div>
