import { AnimationTriggerMetadata, animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input } from '@angular/core';


export const subscriptAnimations: {
  readonly transitionMessages: AnimationTriggerMetadata;
} = {
  /** Animation that transitions the form field's error and hint messages. */
  transitionMessages: trigger('transitionMessages', [
    state('enter', style({ opacity: 1, transform: 'translateY(0%)' })),
    transition('void => enter', [
      style({ opacity: 0, transform: 'translateY(-100%)' }),
      animate('200ms cubic-bezier(0.55, 0, 0.55, 0.2)'),
    ]),
  ]),
};

@Component({
  selector: 'rh-subscript-wrapper',
  templateUrl: './subscript-wrapper.component.html',
  styleUrls: [ './subscript-wrapper.component.scss' ],
  animations: [subscriptAnimations.transitionMessages],
})
export class SubscriptWrapperComponent {
  subscriptAnimationState: '' | 'enter' = '';

  @Input() hasError: boolean;
  
  @Input()
  @HostBinding('class.disabled') 
  disabled: boolean;

}
