import { Injectable } from '@angular/core';
import * as fromBackButton from '@app/root-store/ui/back-button';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import * as fromActions from './form.actions';
import * as fromReducer from './form.reducer';
import * as fromSelectors from './form.selectors';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EventsService } from '@app/core/services/managing-events.service';
import { WorkdayService } from '@app/core/services/workday.service';
import { EMPTY_EVENT_FORM, RhEvent, RhEventForm, RhEventType } from '@app/events/models/event';
import { ConfirmDialogComponent, ConfirmDialogData } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { getPublishEventDialog } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.models';
import { CustomValidators, validateTwoDates } from '@app/shared/form-controls/validators/validator.function';
import { FormStepStatus } from '@app/shared/form-status-badge/form-status-badge.component';
import { RdsDialogService } from '@rds/angular-components';
import { catchError, filter, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import { EventPreviewDialogComponent, EventPreviewDialogData } from '@app/shared/dialogs/previews/event-preview-dialog/event-preview-dialog.component';

@Injectable()
export class FormEffects {

	public defaultTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

	public initAddForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.initAddForm),
			filter(({id}) => !id),
			map(({user}): Partial<RhEventForm> => {
				const date = new Date();
				const startTime = `${(date.getHours() + 1).toString().padStart(2, '0')}:00`;
				const endTime = `${(date.getHours() + 2).toString().padStart(2, '0')}:00`;
				return ({
					...EMPTY_EVENT_FORM,
					date,
					startTime,
					endTime,
					timeZone: this.defaultTimeZone,
					eventOwners: [user],
				})
			}),
			mergeMap((form) => [
				fromActions.setFormValue({form: {...form}}),
				fromActions.setInitialFormValue({form: {...form}}),
				fromActions.setFormChecked()
			])
		), {dispatch: true}
	);

	public initEditForm$ = createEffect(() =>
		this.actions$.pipe(
		ofType(fromActions.initAddForm),
		filter(({id}) => !!id),
		map(({id}) => fromActions.getEventDetailsRequest({id}))
		), { dispatch: true}
	);

	public getEventDetails$ = createEffect(() =>
	this.actions$.pipe(
		ofType(fromActions.getEventDetailsRequest),
		withLatestFrom(
			this.store$.pipe(select(fromSelectors.selectFillFormStartedAt))
		),
		switchMap(([{id}, fillFormStartedAt]) => this.eventsService.getById(id).pipe(
			mergeMap(event => {
				const general: FormGroup = new FormGroup({
					id: new FormControl(event.id),
					title: new FormControl(event.title, [Validators.required]),
					allDay: new FormControl(event.allDay),
					date: new FormControl(event.date, [Validators.required]),
					startTime: new FormControl(this.getTime(new Date(event.startTime)), event.allDay ? [] : [Validators.required]),
					endTime: new FormControl(this.getTime(new Date(event.endTime)), event.allDay ? [] : [Validators.required]),
					timeZone: new FormControl(event.timeZone, [Validators.required]),
					type: new FormControl(event.type, [Validators.required]),
					location: new FormControl(event.location, event.type === RhEventType.ON_SITE || event.type === RhEventType.OFF_SITE || event.type === RhEventType.HYBRID ? [Validators.required] : []),
					meetingUrl: new FormControl(event.meetingUrl, event.type === RhEventType.VIRTUAL || event.type === RhEventType.HYBRID ? [Validators.required] : []),
					registrationUrl: new FormControl(event.registrationUrl, event.registrationUrl ? [Validators.required] : []),
					registration: new FormControl(!!event.registrationUrl, []),
					eventUrl: new FormControl(event.eventUrl, []),
					topEvent: new FormControl(event.topEvent),
					eventOwners: new FormControl(event.eventOwners, [Validators.required])
				},[validateTwoDates({
					startDate: 'date',
					startTime: 'startTime',
					endDate: 'date',
					endTime: 'endTime',
          timeZone: 'timeZone'
				  }, fillFormStartedAt, !!event.startDate ? event.startDate : new Date(new Date().setHours(0,0)))]);
				general.updateValueAndValidity();

				const content: FormGroup = new FormGroup({
					title: new FormControl(event.title, [Validators.required]),
					description: new FormControl(event.description, [Validators.required]),
					contact: new FormControl(event.contact),
					host: new FormControl(event.host),
					topics: new FormControl(event.topics, [Validators.required]),
					content: new FormControl(event.content, [Validators.required]),
					image: new FormControl(event.imageUrl ? {
						id: '',
						name: event.imageName,
						size: event.imageSize,
						url: event.imageUrl
					} : null)
				});
				content.updateValueAndValidity();

				const audience: FormGroup = new FormGroup({
					locations: new FormControl(event.locations),
					departments: new FormControl(event.departments),
					functions: new FormControl(event.functions),
				}, CustomValidators.validateAtLeastOneRequired([
					'locations', 'departments', 'functions',
				]));
				audience.updateValueAndValidity();
				return [
					fromActions.setGeneralFormStatus({status: general.status}),
					fromActions.setGeneralStepStatus({status: general.status === 'VALID' ? FormStepStatus.COMPLETED : FormStepStatus.INCOMPLETE}),
					fromActions.setContentFormStatus({status: content.status}),
					fromActions.setContentStepStatus({status: content.status === 'VALID' ? FormStepStatus.COMPLETED : FormStepStatus.INCOMPLETE}),
					fromActions.setAudienceFormStatus({status: audience.status}),
					fromActions.setAudienceStepStatus({status: audience.status === 'VALID' ? FormStepStatus.COMPLETED : FormStepStatus.INCOMPLETE}),
					fromActions.getEventDetailsSuccess({
						event: {
							...general.value,
							...content.value,
							...audience.value,
						},
						status: event.status
					}),
					fromActions.setFormChecked()
				]
			}),
			catchError(({message}) => of(fromActions.getEventDetailsFailure({error: message})))
		)),
	), {dispatch: true}
);

public getEventDetailsSuccess$ = createEffect(() =>
	this.actions$.pipe(
		ofType(fromActions.getEventDetailsSuccess),
		mergeMap(({event}) => [
			fromActions.setFormValue({form: event}),
		]),
	), {dispatch: true}
);

	public saveAsDraft$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.saveAsDraftRequest),
			withLatestFrom(this.store$.pipe(select(fromSelectors.selectFormForRequest(true)))),
			switchMap(([action, form]) => this.eventsService.saveAsDraft(form).pipe(
				map(form => fromActions.saveAsDraftSuccess()),
				catchError(({message}) => of(fromActions.saveAsDraftFailure({error: message})))
			)),
		), {dispatch: true}
	);

	public updateAsDraft$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.updateAsDraftRequest),
			withLatestFrom(this.store$.pipe(select(fromSelectors.selectFormForRequest(true)))),
			switchMap(([{id}, form]) => this.eventsService.updateAsDraft(form, id).pipe(
				map(form => fromActions.updateAsDraftSuccess()),
				catchError(({message}) => of(fromActions.updateAsDraftFailure({error: message})))
			)),
		), {dispatch: true}
	);


	public saveAsPublish$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.saveAsPublishRequest),
			withLatestFrom(this.store$.pipe(select(fromSelectors.selectFormForRequest(false)))),
			switchMap(([action, form]) => this.eventsService.saveAsPublish(form).pipe(
				map(form => fromActions.saveAsPublishSuccess()),
				catchError(({message}) => of(fromActions.saveAsPublishFailure({error: message})))
			)),
		), {dispatch: true}
	);

	public openPublishChangesConfirmDialog$ = createEffect(() =>
	this.actions$.pipe(
	  ofType(fromActions.openPublishChangesConfirmDialog),
	  map(({id}) =>{
		const data: ConfirmDialogData = getPublishEventDialog([id]);
  
		const dialog = this.dialogService.open(ConfirmDialogComponent, {
		  data
		});
		return ({dialog, id})
	  }),
	  switchMap(({dialog, id}) => dialog.afterClosed().pipe(
		filter(data => !!data),
		map(() => fromActions.updateAsPublishRequest({id}))
	  )),
	), {dispatch: true});

	public updateAsPublish$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.updateAsPublishRequest),
			withLatestFrom(this.store$.pipe(select(fromSelectors.selectFormForRequest(false)))),
			switchMap(([{id}, form]) => this.eventsService.updateAsPublish(form, id).pipe(
				map(form => fromActions.updateAsPublishSuccess()),
				catchError(({message}) => of(fromActions.updateAsPublishFailure({error: message})))
			)),
		), {dispatch: true}
	);

	public saveSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				fromActions.saveAsDraftSuccess,
				fromActions.updateAsDraftSuccess,
				fromActions.saveAsPublishSuccess,
				fromActions.updateAsPublishSuccess
			),
			mergeMap(() => [
				fromActions.setFormTouched({touched: false}),
				fromBackButton.back({defaultLabel: 'My Events', defaultRoute: 'managing-events/my'})
			]),
		), {dispatch: true}
	);

	public openLeavePageDialog$ = createEffect(() =>
	this.actions$.pipe(
	  ofType(fromActions.openLeavePageDialog),
	  withLatestFrom(
		this.store$.pipe(select(fromSelectors.selectChangesMade))
	  ),
	  filter(([{url}, changesMade]) => !!changesMade),
	  map(([{url}, changesMade]) =>{
		const data: ConfirmDialogData = {
		  ids: [],
		  messages: [],
		  title: 'Do you want to discard changes?',
		  confirmButtonLabel: 'Yes, discard',
		  confirmButtonType: 'primary'
		}
		const dialog = this.dialogService.open(ConfirmDialogComponent, {
		  data
		});
		return ({dialog, url})
	  }),
	  switchMap(({dialog, url}) => dialog.afterClosed().pipe(
		filter(data => !!data),
		map((data: ConfirmDialogData) => {
		  return ({url})
		})
	  )),
	  map(({url}) => fromBackButton.back({defaultLabel: 'My Events', defaultRoute: 'managing-events/my'}))
	), {dispatch: true});
	
  
	public closeWithoutDialog$ = createEffect(() =>
	this.actions$.pipe(
	  ofType(fromActions.openLeavePageDialog),
	  withLatestFrom(
		this.store$.pipe(select(fromSelectors.selectChangesMade))
	  ),
	  filter(([{url}, changesMade]) => !changesMade),
	  map(([{url}]) => fromBackButton.back({defaultLabel: 'My Events', defaultRoute: 'managing-events/my'}))
	), {dispatch: true})
  
	public openPreviewDialog$ = createEffect(() =>
	this.actions$.pipe(
	  ofType(fromActions.openPreviewDialog),
	  withLatestFrom(
		this.store$.pipe(select(fromSelectors.selectFormForRequest(true))),
	  	this.store$.pipe(select(fromSelectors.canSaveAsDraft)),
	  ),
	  map(([action, form,canSaveAsDraft]) => {
		const event: Partial<RhEvent>  = {
			...form,
		  }
		const data: EventPreviewDialogData = {
			event,
			context: 'Editor',
			buttons: {
				canSaveEvent: {
					disabled: !canSaveAsDraft,
					visible: true
				}
			}
		}
		const dialog = this.dialogService.open(EventPreviewDialogComponent, {
		  size: 'xl',
		  height: '100%',
		  maxHeight: '100%',
		  data
		}
		)
		return ({dialog, form})
	  }),
	  switchMap(({dialog, form}) => dialog.afterClosed().pipe(
		filter(data => !!data),
		map((data: EventPreviewDialogData) => {
		  return ({id: form.id})
		})
	  )),
	  map(({id}) => id ? fromActions.updateAsDraftRequest({id}) : fromActions.saveAsDraftRequest())
	), {dispatch: true});

	constructor(
		private actions$: Actions,
		private store$: Store<fromReducer.State>,
		private eventsService: EventsService,
		private dialogService: RdsDialogService,
		private userService: WorkdayService
	) {
	}

	getTime(date: Date) {
		return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
	}
}
