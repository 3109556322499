/* tslint:disable */
export const icons = {
  'Travel & Transportation':
    `<svg width="67px" height="32px" viewBox="0 0 67 32" version="1.1">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-271.000000, -316.000000)" stroke-width="2.24">
          <g transform="translate(272.000000, 318.000000)">
            <path d="M1.019368,14.7952 L0.560168,14.9912 L1.019368,14.7952 Z" id="Stroke-1" stroke="#0066CC"></path>
            <line x1="0.56" y1="23.514568" x2="0.56" y2="26.081048" id="Stroke-3" stroke="#0066CC"></line>
            <path d="M0.56,14.84 L0.56,23.5144 C1.05224,18.64464 5.67952,14.99288 10.88024,16.12128 C14.68096,16.94616 17.4048,20.39464 17.45464,24.28328 C17.45688,24.48712 17.45184,24.68088 17.4384,24.8752 L43.8816,24.8752 C43.8676,24.67304 43.86256,24.46976 43.86592,24.2564 C43.9264,20.496 46.46992,17.12648 50.11496,16.198 C55.75024,14.76328 60.8048,18.98288 60.8048,24.3824 C60.8048,24.5504 60.7992,24.7128 60.788,24.8752 L64.4,24.8752 L64.4,23.7888 C64.4,18.45088 61.22088,13.62592 56.31584,11.51976 L46.6648,7.3752 L43.9656,4.676 C40.9752,1.68 36.9152,0 32.6872,0 L15.4,0 L0.56,14.84 Z" id="Stroke-5" stroke="#0066CC"></path>
            <path d="M5.06324,24.3852 C5.06324,26.55016 6.81828,28.3052 8.98324,28.3052 C11.1482,28.3052 12.90324,26.55016 12.90324,24.3852 C12.90324,22.22024 11.1482,20.4652 8.98324,20.4652 C6.81828,20.4652 5.06324,22.22024 5.06324,24.3852 Z" id="Stroke-7" stroke="#FFC72C"></path>
            <path d="M13.647816,11.77176 L24.593016,11.77176 L24.593016,4.42792 L17.699416,4.42792 L12.624696,9.18512 C11.635736,10.11248 12.292056,11.77176 13.647816,11.77176 Z" id="Stroke-9" stroke="#FFC72C"></path>
            <path d="M40.968312,11.77176 L29.399832,11.77176 L29.399832,4.42792 L31.033352,4.42792 C35.054152,4.42792 38.416952,5.66048 41.207992,8.45096 L41.990872,9.18512 C42.979832,10.11248 42.323512,11.77176 40.968312,11.77176 Z" id="Stroke-11" stroke="#FFC72C"></path>
            <path d="M48.41676,24.3852 C48.41676,26.55016 50.1718,28.3052 52.33676,28.3052 C54.50172,28.3052 56.25676,26.55016 56.25676,24.3852 C56.25676,22.22024 54.50172,20.4652 52.33676,20.4652 C50.1718,20.4652 48.41676,22.22024 48.41676,24.3852 Z" id="Stroke-13" stroke="#FFC72C"></path>
          </g>
        </g>
      </g>
    </svg>`,

  'Food & Workplace services':
    `<svg width="38px" height="47px" viewBox="0 0 38 47" version="1.1">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
        <g transform="translate(-500.000000, -301.000000)" stroke-width="2.304">
          <g transform="translate(502.000000, 303.000000)">
            <path d="M27.648,22.6401984 L27.648,38.7681984 C27.648,41.3141184 25.58592,43.3761984 23.04,43.3761984 L4.608,43.3761984 C2.06208,43.3761984 0,41.3141184 0,38.7681984 L0,22.6401984 L27.648,22.6401984 Z" id="Stroke-1" stroke="#0066CC"></path>
            <polyline id="Stroke-3" stroke="#0066CC" points="27.5636736 22.6401984 34.4756736 22.6401984 34.4756736 29.5521984 28.1396736 35.8881984"></polyline>
            <path d="M7.1471232,0.3904704 C8.0030592,0.8121024 8.7449472,1.4871744 9.2477952,2.3822784 C10.5731712,4.7386944 10.3070592,7.2039744 7.5457152,8.7568704 C4.7843712,10.3091904 4.3523712,13.0670784 5.6777472,15.4240704 C6.1805952,16.3185984 6.9224832,16.9942464 7.7789952,17.4153024" id="Stroke-5" stroke="#FFC72C"></path>
            <path d="M19.8191232,0.3904704 C20.6750592,0.8121024 21.4169472,1.4871744 21.9197952,2.3822784 C23.2451712,4.7386944 22.9790592,7.2039744 20.2177152,8.7568704 C17.4563712,10.3091904 17.0243712,13.0670784 18.3497472,15.4240704 C18.8525952,16.3185984 19.5944832,16.9942464 20.4509952,17.4153024" id="Stroke-7" stroke="#FFC72C"></path>
            <path d="M13.4831232,0.3904704 C14.3390592,0.8121024 15.0809472,1.4871744 15.5837952,2.3822784 C16.9091712,4.7386944 16.6430592,7.2039744 13.8817152,8.7568704 C11.1203712,10.3091904 10.6883712,13.0670784 12.0137472,15.4240704 C12.5165952,16.3185984 13.2584832,16.9942464 14.1149952,17.4153024" id="Stroke-9" stroke="#FFC72C"></path>
          </g>
        </g>
      </g>
    </svg>`,

  'Help & Support':
    `<svg width="45px" height="39px" viewBox="0 0 45 39" version="1.1">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g transform="translate(-710.000000, -309.000000)" stroke-width="2.3">
          <g transform="translate(712.000000, 311.000000)">
            <path d="M2.6396928,17.28 C2.6353728,17.270928 2.6267328,17.26704 2.6180928,17.257968 C0.9894528,15.42672 0.0001728,13.011408 0.0001728,10.368 C0.0001728,4.63968 4.6441728,0 10.3681728,0 C15.5046528,0 19.7728128,3.736368 20.5936128,8.64 L20.8787328,8.64 C21.7038528,3.736368 25.9676928,0 31.1041728,0 C36.8324928,0 41.4721728,4.63968 41.4721728,10.368 C41.4721728,13.011408 40.4828928,15.42672 38.8542528,17.257968 C38.8456128,17.26704 38.8369728,17.270928 38.8326528,17.28" id="Stroke-1" stroke="#0066CC"></path>
            <path d="M30,4 C33.81888,4 36.912,7.09312 36.912,10.912" id="Stroke-3" stroke="#FFC72C"></path>
            <line x1="2.592" y1="17.28" x2="20.736" y2="35.424" id="Stroke-5" stroke="#0066CC"></line>
            <line x1="38.88" y1="17.28" x2="20.736" y2="35.424" id="Stroke-7" stroke="#0066CC"></line>
          </g>
        </g>
      </g>
    </svg>`,

  'People & Culture':
    `<svg width="27px" height="50px" viewBox="0 0 27 50" version="1.1">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-933.000000, -298.000000)">
          <g transform="translate(935.000000, 300.000000)">
            <polygon stroke="#0066CC" stroke-width="2.3" stroke-linecap="round" points="9.6 27.84 13.44 27.84 13.44 23.04 9.6 23.04"></polygon>
            <g id="Group-10">
              <polygon stroke="#0066CC" stroke-width="2.3" stroke-linecap="round" points="7.680096 29.985888 9.600096 27.839808 13.440096 27.839808 15.360096 29.985888 15.360096 46.079808 7.680096 46.079808"></polygon>
              <g id="Group-7" transform="translate(11.520000, 3.840000)">
                <path d="M9.6e-05,-4.8e-05 C1.965696,-4.8e-05 3.930816,0.749712 5.430336,2.249712" id="Fill-4" fill="#FFFFFF"></path>
                <path d="M9.6e-05,-4.8e-05 C1.965696,-4.8e-05 3.930816,0.749712 5.430336,2.249712" id="Stroke-6" stroke="#FFC72C" stroke-width="2.3" stroke-linecap="round"></path>
              </g>
              <path d="M19.666032,3.374016 C24.165072,7.873056 24.165072,15.166656 19.666032,19.665696 C17.446032,21.886176 14.544432,23.010816 11.634672,23.039136 C8.648112,23.068896 5.652912,21.944256 3.374352,19.665696 C-1.124688,15.166656 -1.124688,7.873056 3.374352,3.374016 C7.872912,-1.125024 15.166992,-1.125024 19.666032,3.374016 Z" id="Stroke-8" stroke="#0066CC" stroke-width="2.3"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>`,

  'IT Tools & Services':
    `<svg width="59px" height="39px" viewBox="0 0 59 39" version="1.1">
      <defs>
        <polygon id="path-it" points="0 38.88 58.32 38.88 58.32 0 0 0"></polygon>
      </defs>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-1137.000000, -308.000000)">
          <g transform="translate(1137.000000, 308.000000)">
            <polygon id="Stroke-1" stroke="#0066CC" stroke-width="2.16" stroke-linecap="round" points="5.4 33.479892 52.92 33.479892 52.92 1.079892 5.4 1.079892"></polygon>
            <line x1="20.52" y1="25.92" x2="30.24" y2="16.2" id="Stroke-3" stroke="#FFC72C" stroke-width="2.16" stroke-linecap="round" stroke-linejoin="round"></line>
            <line x1="9.719946" y1="9.72" x2="48.599946" y2="9.72" id="Stroke-4" stroke="#FFC72C" stroke-width="2.16" stroke-linecap="round" stroke-linejoin="round"></line>
            <line x1="27" y1="25.92" x2="36.72" y2="16.2" id="Stroke-5" stroke="#FFC72C" stroke-width="2.16" stroke-linecap="round" stroke-linejoin="round"></line>
            <g transform="translate(0.000000, -0.000108)">
              <path d="M48.6,4.320108 C49.19616,4.320108 49.68,4.803408 49.68,5.400108 C49.68,5.996268 49.19616,6.480108 48.6,6.480108 C48.00384,6.480108 47.52,5.996268 47.52,5.400108 C47.52,4.803408 48.00384,4.320108 48.6,4.320108 Z" id="Fill-6" fill="#FFC72C"></path>
              <path d="M44.28,4.320108 C44.87616,4.320108 45.36,4.803408 45.36,5.400108 C45.36,5.996268 44.87616,6.480108 44.28,6.480108 C43.68384,6.480108 43.2,5.996268 43.2,5.400108 C43.2,4.803408 43.68384,4.320108 44.28,4.320108 Z" id="Fill-8" fill="#FFC72C"></path>
              <path d="M39.96,4.320108 C40.55616,4.320108 41.04,4.803408 41.04,5.400108 C41.04,5.996268 40.55616,6.480108 39.96,6.480108 C39.36384,6.480108 38.88,5.996268 38.88,5.400108 C38.88,4.803408 39.36384,4.320108 39.96,4.320108 Z" id="Fill-10" fill="#FFC72C"></path>
              <mask id="mask-it" fill="white">
                <use xlink:href="#path-it"/>
              </mask>
              <g></g>
              <polygon id="Stroke-12" stroke="#FFC72C" stroke-width="2.16" stroke-linecap="round" mask="url(#mask-it)" points="1.08 37.8 57.24 37.8 57.24 33.48 1.08 33.48"></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>`,

  'Knowledge & Resources':
    `<svg width="48px" height="57px" viewBox="0 0 48 57" version="1.1">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
        <g transform="translate(-1353.000000, -292.000000)" stroke-width="2.4">
          <g transform="translate(1355.000000, 294.000000)">
            <path d="M35.94318,20.92968 C36.45798,26.68788 33.43158,31.79568 28.80018,34.31808 L28.80018,49.19988 L24.60018,52.79988 L19.80018,52.79988 L15.60018,49.19988 L15.60018,34.31808 C11.31198,31.98288 8.40018,27.43128 8.40018,22.19988 C8.40018,14.06148 15.45018,7.56588 23.77458,8.48748 C30.22158,9.20148 35.36538,14.46888 35.94318,20.92968 Z" id="Stroke-1" stroke="#0066CC"></path>
            <path d="M22.2,13.2 C27.1704,13.2 31.2,17.2296 31.2,22.2" id="Stroke-3" stroke="#FFC72C"></path>
            <line x1="15.6" y1="48" x2="28.8" y2="48" id="Stroke-5" stroke="#0066CC"></line>
            <line x1="15.6" y1="43.2" x2="28.8" y2="43.2" id="Stroke-7" stroke="#0066CC"></line>
            <line x1="15.6" y1="38.4" x2="28.8" y2="38.4" id="Stroke-9" stroke="#0066CC"></line>
            <line x1="3.6" y1="22.2" x2="0" y2="22.2" id="Stroke-11" stroke="#FFC72C"></line>
            <line x1="22.2" y1="0" x2="22.2" y2="3.6" id="Stroke-13" stroke="#FFC72C"></line>
            <line x1="7.8" y1="10.8" x2="5.4" y2="8.4" id="Stroke-15" stroke="#FFC72C"></line>
            <line x1="36.6" y1="10.8" x2="39" y2="8.4" id="Stroke-17" stroke="#FFC72C"></line>
            <line x1="44.4" y1="22.2" x2="40.8" y2="22.2" id="Stroke-19" stroke="#FFC72C"></line>
          </g>
        </g>
      </g>
    </svg>`,

    'Guidelines & Policies':
      `<svg width="59px" height="42px" viewBox="0 0 59 42" version="1.1">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-275.000000, -519.000000)">
            <g transform="translate(277.000000, 521.000000)">
              <g>
                <polygon stroke="#0066CC" stroke-width="2.4402" stroke-linecap="round" points="0.0198 38.6214 55.2198 38.6214 55.2198 11.6214 0.0198 11.6214"></polygon>
                <path d="M48.62004,2.96382 L48.62004,30.56382 L43.69404,28.52382 C39.15204,26.64582 34.23384,28.01442 30.45804,31.15782 C29.42004,32.02182 28.22004,34.42182 28.22004,34.42182 L27.02004,34.42182 C27.02004,34.42182 25.77984,32.06802 24.78204,31.15782 C21.15324,27.84582 16.08804,26.64582 11.54604,28.52382 L6.62004,30.56382 L6.62004,2.96382 L11.54604,0.92382 C16.08804,-0.95418 21.30804,0.08382 24.78204,3.55782 L27.62004,6.38982 L30.45804,3.55782 C33.93204,0.08382 39.15204,-0.95418 43.69404,0.92382 L48.62004,2.96382 Z" id="Fill-3" fill="#FFFFFF"></path>
                <path d="M48.62004,2.96382 L48.62004,30.56382 L43.69404,28.52382 C39.15204,26.64582 34.23384,28.01442 30.45804,31.15782 C29.42004,32.02182 28.22004,34.42182 28.22004,34.42182 L27.02004,34.42182 C27.02004,34.42182 25.77984,32.06802 24.78204,31.15782 C21.15324,27.84582 16.08804,26.64582 11.54604,28.52382 L6.62004,30.56382 L6.62004,2.96382 L11.54604,0.92382 C16.08804,-0.95418 21.30804,0.08382 24.78204,3.55782 L27.62004,6.38982 L30.45804,3.55782 C33.93204,0.08382 39.15204,-0.95418 43.69404,0.92382 L48.62004,2.96382 Z" id="Stroke-4" stroke="#0066CC" stroke-width="2.4" stroke-linecap="round"></path>
                <g transform="translate(27.000000, 6.241800)">
                  <line x1="0.62004" y1="0.25956" x2="0.62004" y2="27.57996" id="Fill-5" fill="#FFFFFF"></line>
                  <line x1="0.62004" y1="0.25956" x2="0.62004" y2="27.57996" id="Stroke-6" stroke="#0066CC" stroke-width="2.4" stroke-linecap="round"></line>
                </g>
                <line x1="33.02004" y1="11.02158" x2="43.22004" y2="11.02158" id="Fill-8" fill="#FFFFFF"></line>
                <line x1="33.02004" y1="11.02158" x2="43.22004" y2="11.02158" id="Stroke-9" stroke="#FFC72C" stroke-width="2.4" stroke-linecap="round"></line>
                <line x1="33.02004" y1="15.82158" x2="43.22004" y2="15.82158" id="Fill-11" fill="#FFFFFF"></line>
                <line x1="33.02004" y1="15.82158" x2="43.22004" y2="15.82158" id="Stroke-12" stroke="#FFC72C" stroke-width="2.4" stroke-linecap="round"></line>
                <line x1="33.02004" y1="20.62158" x2="43.22004" y2="20.62158" id="Fill-14" fill="#FFFFFF"></line>
                <line x1="33.02004" y1="20.62158" x2="43.22004" y2="20.62158" id="Stroke-15" stroke="#FFC72C" stroke-width="2.4" stroke-linecap="round"></line>
                <line x1="12.02004" y1="11.02158" x2="22.22004" y2="11.02158" id="Fill-17" fill="#FFFFFF"></line>
                <line x1="12.02004" y1="11.02158" x2="22.22004" y2="11.02158" id="Stroke-18" stroke="#FFC72C" stroke-width="2.4" stroke-linecap="round"></line>
                <line x1="12.02004" y1="15.82158" x2="22.22004" y2="15.82158" id="Fill-20" fill="#FFFFFF"></line>
                <line x1="12.02004" y1="15.82158" x2="22.22004" y2="15.82158" id="Stroke-21" stroke="#FFC72C" stroke-width="2.4" stroke-linecap="round"></line>
                <line x1="12.02004" y1="20.62158" x2="22.22004" y2="20.62158" id="Fill-23" fill="#FFFFFF"></line>
                <line x1="12.02004" y1="20.62158" x2="22.22004" y2="20.62158" id="Stroke-24" stroke="#FFC72C" stroke-width="2.4" stroke-linecap="round"></line>
              </g>
            </g>
          </g>
        </g>
      </svg>`,

  'Finance & Procurement':
    `<svg width="34px" height="45px" viewBox="0 0 34 45" version="1.1">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-504.000000, -516.000000)">
          <g transform="translate(505.000000, 517.000000)">
            <g stroke="#0066CC" stroke-linecap="round" stroke-width="1.96">
              <polygon points="0 10.78 31.36 10.78 31.36 2.94 0 2.94"></polygon>
              <line x1="4.9" y1="5.886223" x2="4.9" y2="-0.000147" id="Stroke-3"></line>
              <line x1="26.46" y1="5.886223" x2="26.46" y2="-0.000147" id="Stroke-4"></line>
            </g>
            <line x1="13.72" y1="16.66" x2="13.72" y2="14.7" id="Stroke-6" stroke="#FFC72C" stroke-width="1.88307"></line>
            <line x1="17.64" y1="16.66" x2="17.64" y2="14.7" id="Stroke-7" stroke="#FFC72C" stroke-width="1.88307"></line>
            <polyline id="Stroke-8" stroke="#FFC72C" stroke-width="1.96" stroke-linecap="round" points="9.8 31.27817 12.74 28.33817 15.68 31.27817 18.62 28.33817 21.56 31.27817"></polyline>
            <polygon id="Stroke-9" stroke="#0066CC" stroke-width="1.96" stroke-linecap="square" points="5.88 37.24 25.48 37.24 25.48 22.54 5.88 22.54"></polygon>
            <line x1="13.72" y1="22.54" x2="13.72" y2="18.62" id="Stroke-10" stroke="#0066CC" stroke-width="1.96"></line>
            <line x1="17.64" y1="22.54" x2="17.64" y2="18.62" id="Stroke-11" stroke="#0066CC" stroke-width="1.96"></line>
            <polygon id="Stroke-12" stroke="#0066CC" stroke-width="1.96" stroke-linecap="round" points="0 42.14 31.36 42.14 31.36 2.94 0 2.94"></polygon>
          </g>
        </g>
      </g>
    </svg>`,

  'Media, Market & Competitors':
    `<svg width="56px" height="35px" viewBox="0 0 56 35" version="1.1">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-705.000000, -526.000000)">
          <g transform="translate(707.000000, 528.000000)">
            <polygon stroke="#0066CC" stroke-width="2.268" points="52.1025372 31.752 -0.0002268 31.752 -0.0002268 0 52.1144442 0"></polygon>
            <polyline stroke="#FFC72C" stroke-width="2.268" stroke-linecap="round" points="6.754104 22.68 14.692104 14.742 23.764104 22.68 38.506104 7.938 45.310104 14.742"></polyline>
            <g transform="translate(3.969000, 5.670000)">
              <path d="M8.455104,9.072 C8.455104,7.819497 9.470601,6.804 10.723104,6.804 C11.975607,6.804 12.991104,7.819497 12.991104,9.072 C12.991104,10.324503 11.975607,11.34 10.723104,11.34 C9.470601,11.34 8.455104,10.324503 8.455104,9.072" id="Fill-4" fill="#FFFFFF"></path>
              <path d="M8.455104,9.072 C8.455104,7.819497 9.470601,6.804 10.723104,6.804 C11.975607,6.804 12.991104,7.819497 12.991104,9.072 C12.991104,10.324503 11.975607,11.34 10.723104,11.34 C9.470601,11.34 8.455104,10.324503 8.455104,9.072 Z" id="Stroke-6" stroke="#FFC72C" stroke-width="2.268"></path>
              <path d="M17.5271607,17.01 C17.5271607,15.757497 18.5426577,14.742 19.7951607,14.742 C21.0476637,14.742 22.0631607,15.757497 22.0631607,17.01 C22.0631607,18.262503 21.0476637,19.278 19.7951607,19.278 C18.5426577,19.278 17.5271607,18.262503 17.5271607,17.01" id="Fill-8" fill="#FFFFFF"></path>
              <path d="M17.5271607,17.01 C17.5271607,15.757497 18.5426577,14.742 19.7951607,14.742 C21.0476637,14.742 22.0631607,15.757497 22.0631607,17.01 C22.0631607,18.262503 21.0476637,19.278 19.7951607,19.278 C18.5426577,19.278 17.5271607,18.262503 17.5271607,17.01 Z" id="Stroke-10" stroke="#FFC72C" stroke-width="2.268"></path>
              <path d="M32.2691607,2.268 C32.2691607,1.015497 33.2846577,0 34.5371607,0 C35.7896637,0 36.8051607,1.015497 36.8051607,2.268 C36.8051607,3.520503 35.7896637,4.536 34.5371607,4.536 C33.2846577,4.536 32.2691607,3.520503 32.2691607,2.268" id="Fill-12" fill="#FFFFFF"></path>
              <path d="M32.2691607,2.268 C32.2691607,1.015497 33.2846577,0 34.5371607,0 C35.7896637,0 36.8051607,1.015497 36.8051607,2.268 C36.8051607,3.520503 35.7896637,4.536 34.5371607,4.536 C33.2846577,4.536 32.2691607,3.520503 32.2691607,2.268 Z" id="Stroke-14" stroke="#FFC72C" stroke-width="2.268"></path>
              <path d="M39.0731607,9.072 C39.0731607,7.819497 40.0886577,6.804 41.3411607,6.804 C42.5936637,6.804 43.6091607,7.819497 43.6091607,9.072 C43.6091607,10.324503 42.5936637,11.34 41.3411607,11.34 C40.0886577,11.34 39.0731607,10.324503 39.0731607,9.072" id="Fill-16" fill="#FFFFFF"></path>
              <path d="M39.0731607,9.072 C39.0731607,7.819497 40.0886577,6.804 41.3411607,6.804 C42.5936637,6.804 43.6091607,7.819497 43.6091607,9.072 C43.6091607,10.324503 42.5936637,11.34 41.3411607,11.34 C40.0886577,11.34 39.0731607,10.324503 39.0731607,9.072 Z" id="Stroke-18" stroke="#FFC72C" stroke-width="2.268"></path>
              <path d="M0.517104,17.01 C0.517104,15.757497 1.532601,14.742 2.785104,14.742 C4.037607,14.742 5.053104,15.757497 5.053104,17.01 C5.053104,18.262503 4.037607,19.278 2.785104,19.278 C1.532601,19.278 0.517104,18.262503 0.517104,17.01" id="Fill-20" fill="#FFFFFF"></path>
              <path d="M0.517104,17.01 C0.517104,15.757497 1.532601,14.742 2.785104,14.742 C4.037607,14.742 5.053104,15.757497 5.053104,17.01 C5.053104,18.262503 4.037607,19.278 2.785104,19.278 C1.532601,19.278 0.517104,18.262503 0.517104,17.01 Z" id="Stroke-22" stroke="#FFC72C" stroke-width="2.268"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>`,

  'Learning & Development':
    `<svg width="33px" height="63px" viewBox="0 0 33 63" version="1.1">
      <defs>
        <polygon id="path-learn" points="0 62.208 32.076 62.208 32.076 0 0 0"></polygon>
      </defs>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-930.000000, -497.000000)">
          <g transform="translate(930.000000, 497.000000)">
            <polygon stroke="#0066CC" stroke-width="1.944" stroke-linecap="square" points="21.384 32.076 25.272 32.076 25.272 26.244 21.384 26.244"></polygon>
            <g>
              <path d="M17.496,42.768 L17.496,55.404 L31.104,55.404 L31.104,61.236 L1.944,61.236 L1.944,55.404 L9.72,47.628 L9.72,46.656 C3.988116,44.062704 0.972,38.776482 0.972,32.076 C0.972,22.949892 8.370378,15.552 17.496,15.552 L19.44,15.552 L19.44,9.72 L27.216,9.72 L27.216,26.244 L19.44,26.244 L19.44,21.384 L17.496,21.384 C11.5911,21.384 6.804,26.1711 6.804,32.076 C6.804,36.230814 9.174222,39.832074 12.636,41.602086 C12.9762,41.775588 13.354308,41.796 13.608,41.796" id="Stroke-2" stroke="#0066CC" stroke-width="1.944" stroke-linecap="square"></path>
              <mask id="mask-learn" fill="white">
                <use xlink:href="#path-learn"></use>
              </mask>
              <g></g>
              <polygon id="Stroke-4" stroke="#0066CC" stroke-width="1.944" stroke-linecap="square" mask="url(#mask-learn)" points="21.384 9.72 25.272 9.72 25.272 0.972 21.384 0.972"></polygon>
              <polygon id="Stroke-6" stroke="#FFC72C" stroke-width="1.944" stroke-linecap="square" mask="url(#mask-learn)" points="13.608 42.768 31.104 42.768 31.104 38.88 13.608 38.88"></polygon>
              <path d="M15.552,18.468 C15.552,16.857882 16.857882,15.552 18.468,15.552 C20.078604,15.552 21.384,16.857882 21.384,18.468 C21.384,20.078118 20.078604,21.384 18.468,21.384 C16.857882,21.384 15.552,20.078118 15.552,18.468 Z" id="Stroke-7" stroke="#FFC72C" stroke-width="1.944" stroke-linecap="square" mask="url(#mask-learn)"></path>
              <path d="M8.748,55.404 C8.748,54.330426 9.618426,53.46 10.692,53.46 C11.765574,53.46 12.636,54.330426 12.636,55.404 C12.636,56.477574 11.765574,57.348 10.692,57.348 C9.618426,57.348 8.748,56.477574 8.748,55.404 Z" id="Stroke-8" stroke="#FFC72C" stroke-width="1.944" stroke-linecap="square" mask="url(#mask-learn)"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>`,

  'For Managers':
    `<svg width="65px" height="44px" viewBox="0 0 65 44" version="1.1">
      <defs>
        <polygon id="path-manager" points="0 43.223112 64.79406 43.223112 64.79406 -0.000108 0 -0.000108"></polygon>
      </defs>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-1129.000000, -516.000000)">
          <g transform="translate(1129.000000, 516.000000)">
            <path d="M63.71379,13.769946 L63.71379,5.004666 C63.71379,2.846286 61.94745,1.079946 59.78907,1.079946 L21.46905,1.079946 C19.31013,1.079946 17.32347,2.846286 17.32347,5.004666 L17.32347,13.769946" id="Stroke-1" stroke="#0066CC" stroke-width="2.16"></path>
            <path d="M17.323362,13.751748 L5.353182,13.751748 L5.353182,11.558808 C5.353182,9.557568 6.991002,7.919748 8.992242,7.919748 L17.323362,7.919748 L17.323362,13.751748 Z" id="Stroke-3" stroke="#FFC72C" stroke-width="2.16"></path>
            <line x1="17.323362" y1="13.499946" x2="17.323362" y2="41.849946" id="Stroke-5" stroke="#0066CC" stroke-width="2.16"></line>
            <line x1="27.043362" y1="13.769946" x2="27.043362" y2="41.579946" id="Stroke-7" stroke="#FFC72C" stroke-width="2.16"></line>
            <mask id="mask-manager" fill="white">
              <use xlink:href="#path-manager"/>
            </mask>
            <g></g>
            <polygon stroke="#FFC72C" stroke-width="2.16" mask="url(#mask-manager)" points="21.46986 13.746672 33.43302 13.746672 33.43302 5.219532 21.46986 5.219532"></polygon>
            <line x1="36.936594" y1="10.025802" x2="45.061434" y2="10.025802" id="Stroke-11" stroke="#FFC72C" stroke-width="2.16" stroke-linecap="round" mask="url(#mask-manager)"></line>
            <line x1="52.963362" y1="20.221704" x2="63.713682" y2="20.221704" id="Stroke-12" stroke="#FFC72C" stroke-width="2.16" mask="url(#mask-manager)"></line>
            <line x1="52.963362" y1="13.746726" x2="52.963362" y2="42.119946" id="Stroke-13" stroke="#FFC72C" stroke-width="2.16" mask="url(#mask-manager)"></line>
            <polygon stroke="#0066CC" stroke-width="2.16" mask="url(#mask-manager)" points="1.08 42.143112 63.71406 42.143112 63.71406 13.746672 1.08 13.746672"></polygon>
          </g>
        </g>
      </g>
    </svg>`,

  'Projects':
    `<svg width="59px" height="48px" viewBox="0 0 59 48" version="1.1">
      <defs>
        <polygon id="path-project" points="0 48 58.8 48 58.8 0 0 0"></polygon>
      </defs>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-1348.000000, -512.000000)">
          <g transform="translate(1348.000000, 512.000000)">
            <polygon stroke="#0066CC" stroke-width="2.4" points="26.4 18 32.4 18 32.4 12 26.4 12"></polygon>
            <polygon stroke="#0066CC" stroke-width="2.4" points="9.6 33.6 15.6 33.6 15.6 12.015 9.6 12.015"></polygon>
            <polygon stroke="#0066CC" stroke-width="2.4" points="43.2 24 49.2 24 49.2 12.0126 43.2 12.0126"></polygon>
            <g>
              <path d="M15.6,33.6 L9.6,33.6 L9.6006,38.9766 C9.6006,40.6326 10.9428,41.9748 12.5988,41.9748 C14.2542,41.9748 15.5964,40.6326 15.5964,38.9766 L15.6,33.6 Z" id="Stroke-4" stroke="#FFC72C" stroke-width="2.4"></path>
              <path d="M49.2,24 L43.2,24 L43.2006,38.9766 C43.2006,40.6326 44.5428,41.9748 46.1988,41.9748 C47.8542,41.9748 49.1964,40.6326 49.1964,38.9766 L49.2,24 Z" id="Stroke-6" stroke="#FFC72C" stroke-width="2.4"></path>
              <path d="M32.4,18 L26.4,18 L26.4006,38.9766 C26.4006,40.6326 27.7428,41.9748 29.3988,41.9748 C31.0542,41.9748 32.3964,40.6326 32.3964,38.9766 L32.4,18 Z" id="Stroke-8" stroke="#FFC72C" stroke-width="2.4"></path>
              <path d="M8.4,3.6 L8.4,12 L16.8,12 L16.8,3.6 L16.2354,3.0354 C16.2354,3.0354 14.9904,1.7898 14.7636,1.5636 L14.4,1.2 L10.8,1.2 L8.4,3.6 Z" id="Stroke-10" stroke="#0066CC" stroke-width="2.4"></path>
              <path d="M25.2,3.6 L25.2,12 L33.6,12 L33.6,3.6 L33.0354,3.0354 C33.0354,3.0354 31.7904,1.7898 31.5636,1.5636 L31.2,1.2 L27.6,1.2 L25.2,3.6 Z" id="Stroke-12" stroke="#0066CC" stroke-width="2.4"></path>
              <path d="M42,3.6 L42,12 L50.4,12 L50.4,3.6 L49.8354,3.0354 C49.8354,3.0354 48.5904,1.7898 48.3636,1.5636 L48,1.2 L44.4,1.2 L42,3.6 Z" id="Stroke-14" stroke="#0066CC" stroke-width="2.4"></path>
              <mask id="mask-project" fill="white">
                <use xlink:href="#path-project"></use>
              </mask>
              <g></g>
              <polygon stroke="#0066CC" stroke-width="2.4" mask="url(#mask-project)" points="1.2 46.8 57.6 46.8 57.6 28.8 1.2 28.8"></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>`,

  'Organisations':
    `<svg width="64px" height="42px" viewBox="0 0 64 42" version="1.1">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-272.000000, -732.000000)">
          <g transform="translate(274.000000, 734.000000)">
            <polygon stroke="#0066CC" stroke-width="2.4" points="60 17.61246 60 38.61246 36.3192 38.61246 0 38.61246 0 17.61246 42.6 0.21246"></polygon>
            <path d="M42.59994,38.61258 L42.59994,0.81258 L42.59994,38.61258 Z" id="Fill-3" fill="#FFFFFF"></path>
            <line x1="42.6" y1="0.81246" x2="42.6" y2="38.61246" id="Stroke-4" stroke="#0066CC" stroke-width="2.4"></line>
            <line x1="47.4" y1="12.21246" x2="55.2" y2="20.01246" id="Stroke-6" stroke="#FFC72C" stroke-width="2.4" stroke-linecap="round"></line>
            <line x1="47.4" y1="19.41246" x2="55.2" y2="27.21246" id="Stroke-7" stroke="#FFC72C" stroke-width="2.4" stroke-linecap="round"></line>
            <line x1="47.4" y1="26.61246" x2="55.2" y2="34.41246" id="Stroke-8" stroke="#FFC72C" stroke-width="2.4" stroke-linecap="round"></line>
            <line x1="4.8" y1="21.81246" x2="37.8" y2="8.01246" id="Stroke-9" stroke="#FFC72C" stroke-width="2.4" stroke-linecap="round"></line>
            <line x1="4.8" y1="27.81246" x2="37.8" y2="14.01246" id="Stroke-10" stroke="#FFC72C" stroke-width="2.4" stroke-linecap="round"></line>
            <line x1="4.8" y1="33.81246" x2="37.8" y2="20.01246" id="Stroke-11" stroke="#FFC72C" stroke-width="2.4" stroke-linecap="round"></line>
          </g>
        </g>
      </g>
    </svg>`,

  'Medical activities':
    `<svg width="43px" height="67px" viewBox="0 0 43 67" version="1.1">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
        <g transform="translate(-282.000000, -710.000000)" stroke-width="2.33333333">
          <g transform="translate(210.000000, 689.000000)">
            <g transform="translate(74.000000, 23.000000)">
              <path d="M29.1666667,29.1666667 L29.1666667,35 L25.6666667,38.5 L22.1666667,35 L22.1666667,29.1666667 M25.6666667,38.5 L25.6666667,56 C25.6666667,59.8659932 28.8006734,63 32.6666667,63 C36.5326599,63 39.6666667,59.8659932 39.6666667,56 L39.6666667,51.3333333 L35,46.6666667 L9.33333333,46.6666667 M19.8333333,0 L14,5.83333333 L14,17.5 C14,23.9433221 19.2233446,29.1666667 25.6666667,29.1666667 C32.1099887,29.1666667 37.3333333,23.9433221 37.3333333,17.5 L37.3333333,5.83333333 L31.5,0" stroke="#0066CC"></path>
              <circle stroke="#FFC72C" stroke-linejoin="round" cx="4.66666667" cy="46.6666667" r="4.66666667"></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>`,

  'R&D activities':
    `<svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
        <g transform="translate(-492.000000, -723.000000)" stroke-width="2.17391304">
          <g transform="translate(425.000000, 689.000000)">
            <g transform="translate(69.000000, 36.000000)">
              <circle stroke="#0066CC" fill="#FFFFFF" fill-rule="nonzero" cx="21.7391304" cy="28.2608696" r="8.15217391"></circle>
              <circle stroke="#0066CC" fill="#FFFFFF" fill-rule="nonzero" cx="6.52173913" cy="13.0434783" r="6.52173913"></circle>
              <circle stroke="#0066CC" fill="#FFFFFF" fill-rule="nonzero" cx="6.52173913" cy="43.4782609" r="6.52173913"></circle>
              <circle stroke="#0066CC" fill="#FFFFFF" fill-rule="nonzero" cx="43.4782609" cy="28.2608696" r="6.52173913"></circle>
              <circle stroke="#0066CC" fill="#FFFFFF" fill-rule="nonzero" cx="43.4782609" cy="6.52173913" r="6.52173913"></circle>
              <line x1="15.7608696" y1="22.2826087" x2="11.1413043" y2="17.6630435" stroke="#0066CC"></line>
              <line x1="15.7608696" y1="34.2391304" x2="11.1413043" y2="38.8586957" stroke="#0066CC"></line>
              <line x1="38.8586957" y1="11.1413043" x2="27.6684783" y2="22.3315217" stroke="#0066CC"></line>
              <line x1="29.8913043" y1="28.2608696" x2="36.9565217" y2="28.2608696" stroke="#0066CC"></line>
              <path d="M21.7391304,24.4565217 C23.8402137,24.4565217 25.5434783,26.1597863 25.5434783,28.2608696" stroke="#FFC72C"></path>
              <path d="M8.05978261,11.5163043 C8.46799357,11.9240614 8.6973623,12.4773712 8.6973623,13.0543478 C8.6973623,13.6313245 8.46799357,14.1846342 8.05978261,14.5923913" stroke="#FFC72C"></path>
              <path d="M8.05978261,41.951087 C8.46799357,42.358844 8.6973623,42.9121538 8.6973623,43.4891304 C8.6973623,44.0661071 8.46799357,44.6194168 8.05978261,45.0271739"stroke="#FFC72C"></path>
              <path d="M45.0163043,26.7336957 C45.4245153,27.1414527 45.653884,27.6947625 45.653884,28.2717391 C45.653884,28.8487158 45.4245153,29.4020255 45.0163043,29.8097826" stroke="#FFC72C"></path>
              <path d="M45.0163043,4.99456522 C45.4245153,5.4023223 45.653884,5.95563207 45.653884,6.5326087 C45.653884,7.10958532 45.4245153,7.66289509 45.0163043,8.07065217" stroke="#FFC72C"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>`,

  'Commercial activities':
    `<svg width="34px" height="42px" viewBox="0 0 34 42" version="1.1">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-717.000000, -734.000000)" stroke-width="2">
          <g transform="translate(718.000000, 735.000000)">
            <polyline stroke="#FFC72C" stroke-linecap="round" points="6.0095 11.09135 8.655 13.70485 13.4165 9.00035"></polyline>
            <polyline stroke="#FFC72C" stroke-linecap="round" points="6.0095 20.09135 8.655 22.70485 13.4165 18.00035"></polyline>
            <polyline stroke="#FFC72C" stroke-linecap="round" points="6.0095 29.09135 8.655 31.70485 13.4165 27.00035"></polyline>
            <line x1="18.02345" y1="31.00055" x2="25.99995" y2="31.00055" stroke="#0066CC" stroke-linecap="round"></line>
            <line x1="18.02345" y1="22.00055" x2="25.99995" y2="22.00055" stroke="#0066CC" stroke-linecap="round"></line>
            <line x1="18.02345" y1="13.00055" x2="25.99995" y2="13.00055" stroke="#0066CC" stroke-linecap="round"></line>
            <g transform="translate(0.000000, 0.000550)" stroke="#0066CC">
              <path d="M31.21045,6.20995 L25.61695,0.61645 C25.38945,0.38895 24.99995,0.55045 24.99995,0.87245 L24.99995,6.99995 L30.88345,6.99995 C31.29545,6.99995 31.50195,6.50145 31.21045,6.20995 Z"></path>
              <polygon points="25 -0.0005 0 0.0025 0 40 32 40 32 6.9995"></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>`,

  'Field force':
    `<svg width="50px" height="62px" viewBox="0 0 50 62" version="1.1">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-924.000000, -714.000000)">
          <g transform="translate(855.000000, 689.000000)">
            <g transform="translate(70.000000, 26.000000)">
              <g transform="translate(25.645161, 0.000000)">
                <circle stroke="#0066CC" stroke-width="2" cx="10.8096785" cy="5.32258065" r="5.32258065"></circle>
                <line x1="4.27742044" y1="34.9209677" x2="17.3419366" y2="34.9209677" id="Path" stroke="#FFC72C" stroke-width="2" stroke-linecap="round"></line>
                <polygon fill="#FFC72C" fill-rule="nonzero" points="9.84193657 15.2419355 8.87419463 25.7225806 10.8096785 28.7903226 12.7451624 25.8870968 11.7774204 15.2419355"></polygon>
                <path d="M21.6241946,27.0145161 L21.6241946,37.1758065 L17.4725817,42.0145161 L17.4725817,59.8258065 L4.4080656,59.8258065 L4.4080656,42.0387097 L-1.1001952e-13,37.2 L-1.1001952e-13,25.3887097 C-0.00128259642,22.5209667 1.13702414,19.7702397 3.16437105,17.7419853 C5.19171795,15.7137309 7.94193525,14.5741935 10.8096785,14.5741935 C16.785485,14.5741935 21.6241946,19.4129032 21.6241946,27.0145161 Z" stroke="#0066CC" stroke-width="2"></path>
              </g>
              <g id="Group-6" stroke-width="2">
                <path d="M15.7064527,15.566129 C14.8999885,17.5370321 12.9964061,18.8375495 10.8671725,18.8722996 C8.73793891,18.9070497 6.7929314,17.6693431 5.92258173,15.7258065" id="Path" stroke="#FFC72C"></path>
                <circle id="Oval" stroke="#0066CC" cx="10.8145172" cy="5.32258065" r="5.32258065"></circle>
                <line x1="4.28225915" y1="34.9209677" x2="17.3467753" y2="34.9209677" stroke="#FFC72C" stroke-linecap="round"></line>
                <path d="M21.6241946,27.0145161 L21.6241946,37.1758065 L17.4725817,42.0145161 L17.4725817,59.8258065 L4.4080656,59.8258065 L4.4080656,42.0387097 L-1.1001952e-13,37.2 L-1.1001952e-13,25.3887097 C-0.00128259642,22.5209667 1.13702414,19.7702397 3.16437105,17.7419853 C5.19171795,15.7137309 7.94193525,14.5741935 10.8096785,14.5741935 C16.785485,14.5741935 21.6241946,19.4129032 21.6241946,27.0145161 Z" stroke="#0066CC"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>`,

  'Science & Products':
    `<svg width="54px" height="49px" viewBox="0 0 54 49" version="1.1">
      <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-1136.000000, -727.000000)">
          <g transform="translate(1070.000000, 689.000000)">
            <g transform="translate(68.000000, 38.000000)">
              <rect fill="#FFFFFF" fill-rule="nonzero" x="26.2736842" y="1.01052632" width="24.2526316" height="8.08421053"></rect>
              <rect stroke="#0066CC" stroke-width="2.02105263" stroke-linecap="square" x="26.2736842" y="1.01052632" width="24.2526316" height="8.08421053"></rect>
              <path d="M46.4842105,9.09473684 L46.4842105,13.1368421 L50.5263158,17.1789474 L50.5263158,43.4526316 C50.5263158,45.6850247 48.7166036,47.4947368 46.4842105,47.4947368 L30.3157895,47.4947368 C28.0833964,47.4947368 26.2736842,45.6850247 26.2736842,43.4526316 L26.2736842,17.1789474 L30.3157895,13.1368421 L30.3157895,9.09473684 L46.4842105,9.09473684 Z" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M46.4842105,9.09473684 L46.4842105,13.1368421 L50.5263158,17.1789474 L50.5263158,43.4526316 C50.5263158,45.6850247 48.7166036,47.4947368 46.4842105,47.4947368 L30.3157895,47.4947368 C28.0833964,47.4947368 26.2736842,45.6850247 26.2736842,43.4526316 L26.2736842,17.1789474 L30.3157895,13.1368421 L30.3157895,9.09473684 L46.4842105,9.09473684 Z" stroke="#0066CC" stroke-width="2.02105263" stroke-linecap="square"></path>
              <rect stroke="#0066CC" stroke-width="2.02105263" stroke-linecap="square" x="26.2736842" y="1.01052632" width="24.2526316" height="8.08421053"></rect>
              <rect stroke="#FFC72C" stroke-width="2.02105263" fill="#FFFFFF" fill-rule="nonzero" stroke-linecap="round" x="34.3578947" y="27.2842105" width="16.1684211" height="13.1368421"></rect>
              <rect  stroke="#FFC72C" stroke-width="2.02105263" fill="#FFFFFF" fill-rule="nonzero" stroke-linecap="square" x="26.2736842" y="1.01052632" width="24.2526316" height="4.04210526"></rect>
              <rect stroke="#FFC72C" stroke-width="2.02105263" fill="#FFFFFF" fill-rule="nonzero" stroke-linecap="square" x="34.3578947" y="22.2315789" width="16.1684211" height="5.05263158"></rect>
              <path d="M18.1894737,0 L18.1894737,11.2926316 L30.2753684,40.4210526 L30.3157895,41.4315789 C30.3157895,44.7801686 27.6012212,47.4947368 24.2526316,47.4947368 L6.06315789,47.4947368 C2.71456825,47.4947368 0,44.7801686 0,41.4315789 L0,40.4210526 L12.1263158,11.2572632 L12.1263158,0" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M18.1894737,1.01052632 L18.1894737,11.2926316 L30.2753684,40.4210526 L30.3157895,41.4315789 C30.3157895,44.7801686 27.6012212,47.4947368 24.2526316,47.4947368 L6.06315789,47.4947368 C2.71456825,47.4947368 0,44.7801686 0,41.4315789 L0,40.4210526 L12.1263158,11.2572632 L12.1263158,1.01052632" stroke="#0066CC" stroke-width="2.02105263"></path>
              <line x1="20.2105263" y1="1.01052632" x2="10.1052632" y2="1.01052632" stroke="#0066CC" stroke-width="2.02105263" fill="#FFFFFF" fill-rule="nonzero" stroke-linecap="square"></line>
              <line x1="17.1789474" y1="22.2315789" x2="13.1368421" y2="22.2315789" stroke="#FFC72C" stroke-width="2.02105263" fill="#FFFFFF" fill-rule="nonzero" stroke-linecap="round" stroke-linejoin="round"></line>
              <line x1="15.1578947" y1="27.2842105" x2="11.1157895" y2="27.2842105" stroke="#FFC72C" stroke-width="2.02105263" fill="#FFFFFF" fill-rule="nonzero" stroke-linecap="round" stroke-linejoin="round"></line>
              <line x1="13.1368421" y1="32.3368421" x2="9.09473684" y2="32.3368421" stroke="#FFC72C" stroke-width="2.02105263" fill="#FFFFFF" fill-rule="nonzero" stroke-linecap="round" stroke-linejoin="round"></line>
              <line x1="11.1157895" y1="37.3894737" x2="7.07368421" y2="37.3894737" stroke="#FFC72C" stroke-width="2.02105263" fill="#FFFFFF" fill-rule="nonzero" stroke-linecap="round" stroke-linejoin="round"></line>
            </g>
          </g>
        </g>
      </g>
    </svg>`,

  'Safety, Security, Health and Environment':
    `<svg width="62px" height="56px" viewBox="0 0 62 56">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-272.000000, -935.000000)">
          <g transform="translate(210.000000, 904.000000)">
            <g transform="translate(64.000000, 33.000000)">
              <path d="M37.3241902,41.9382453 L42.9555626,41.9382453 L53.0803032,31.902204 L53.0786114,31.8999681 C53.5968601,31.3465716 54.3378598,31 55.160065,31 C56.7289091,31 58,32.2599553 58,33.8144964 C58,34.3002555 57.8759361,34.7569474 57.6571325,35.1560637 L57.6176577,35.2304089 L40.7077506,52 L40.7004196,52 L19.1725165,52 L18.1506812,52 L11.3835604,45.2921635 L8,45.2921635 L8,32.9944634 L21.5342416,32.9944634 L24.917802,36.3483816 L37.8881169,36.3483816 C39.4451187,36.3483816 40.6998556,37.0404067 40.6998556,38.5843271 L40.7077506,41.9382453" stroke="#0066CC" stroke-width="2.2" stroke-linecap="round"></path>
              <line x1="38" y1="41.5" x2="25" y2="41.5" stroke="#0066CC" stroke-width="2.2" stroke-linecap="round"></line>
              <polygon stroke="#FFC72C" stroke-width="2.2" points="0 48 8 48 8 29 0 29"></polygon>
              <path d="M36,0 L36,2.48403084 L33.6527278,2.48403084 C33.2312379,2.48403084 32.8900582,2.82323789 32.8900582,3.24229075 C32.8900582,3.66024229 32.5488784,4 32.1279424,4 L26,4" stroke="#FFC72C" stroke-width="2.2" stroke-linecap="round"></path>
              <path d="M28,8 C28,8.553 27.552,9 27,9 C26.448,9 26,8.553 26,8 C26,7.448 26.448,7 27,7 C27.552,7 28,7.448 28,8" fill="#FFC72C"></path>
              <path d="M31,8 C31,8.553 30.553,9 30,9 C29.448,9 29,8.553 29,8 C29,7.448 29.448,7 30,7 C30.553,7 31,7.448 31,8" fill="#FFC72C"></path>
              <path d="M44,13 C44,13.553 43.552,14 43,14 C42.448,14 42,13.553 42,13 C42,12.448 42.448,12 43,12 C43.552,12 44,12.448 44,13" fill="#FFC72C"></path>
              <path d="M40,22 C40,22.552 39.552,23 39,23 C38.448,23 38,22.552 38,22 C38,21.447 38.448,21 39,21 C39.552,21 40,21.447 40,22" fill="#FFC72C"></path>
              <path d="M35,19.1345391 L32.7171415,21.4694955 C32.3718823,21.8229469 32.1779542,22.3018536 32.1779542,22.801383 L32.1779542,23.6767053 C32.1779542,24.4076681 31.598412,25 30.8826716,25 C30.3574965,25 29.8844465,24.676337 29.6849136,24.1796718 L27.0971509,17.7367732 L27.0041102,17.5053398 C26.8028958,17.0040918 26.3248015,16.6769917 25.794582,16.6769917 L24.1966371,16.6769917 C24.1882298,16.667826 24.1798225,16.659806 24.1714152,16.6506404 C23.4483886,15.9437375 23,14.9441057 23,13.8384958 C23,12.7323131 23.4483886,11.7326814 24.1714152,11.0257785 C24.1798225,11.0166128 24.1882298,11.00802 24.1966371,11 L30.3731901,11 L32.5955161,13.2713695 L33.1386268,13.2713695 C34.1665577,13.2713695 35,14.1232047 35,15.1738205 L35,19.1345391 Z" stroke="#FFC72C" stroke-width="2.2"></path>
              <line x1="24" y1="11" x2="22.5" y2="9.5" stroke="#FFC72C" stroke-width="2.2" stroke-linecap="round"></line>
              <path d="M50,13 L48.7276154,13 C48.306,13 47.9021538,12.8197386 47.6043846,12.4997892 L45.3186154,10.0387291 C44.9449231,9.63647381 44.4382308,9.41100221 43.91,9.41100221 L42.601,9.41100221 L40.816,11.3318579 C40.2511538,11.9404574 39.4843846,12.2824323 38.6847692,12.2824323 L38.4112308,12.2824323 C37.0796154,12.2824323 36,11.1202972 36,9.686321 L36,8.64474655 L38.422,7.59215934 L38.422,6.41727263 C38.422,5.15602276 39.1203846,4.01591316 40.1978462,3.51975972 L43.4948462,2" stroke="#FFC72C" stroke-width="2.2"></path>
              <path d="M47,23 L44.2232908,23 C43.1112083,23 42.0445882,23.4 41.2577374,24.1118734 L39.6951099,25.5271768 C38.2065046,25.5271768 37,26.6195251 37,27.9672823 L37,29" stroke="#FFC72C" stroke-width="2.2"></path>
              <path d="M49.5371154,10.8409808 C47.9142308,4.60598077 42.2425,0 35.5,0 C31.4957692,0 27.8707692,1.62288462 25.2440385,4.24348077 C22.6228846,6.87076923 21,10.4957692 21,14.5 C21,21.27875 25.6567308,26.9722308 31.9447115,28.5616538 C33.0824038,28.8460769 34.2702885,29 35.5,29 C43.5084615,29 50,22.5079038 50,14.5 C50,13.2334808 49.8382692,12.0099038 49.5371154,10.8409808 Z" stroke="#0066CC" stroke-width="2.2"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>`,
  'Transformation':
    `<svg width="60px" height="65px" viewBox="0 0 60 65">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-489.000000, -924.000000)">
              <g transform="translate(425.000000, 904.000000)">
                <g transform="translate(64.000000, 20.000000)">
                  <path d="M19.8229102,36.9951354 C19.8229102,30.7254722 24.9314649,25.6431842 31.2335315,25.6431842 C34.4098297,25.6431842 37.2829496,26.9341155 39.3516363,29.0173006" id="Stroke-1" stroke="#0066CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <polyline id="Stroke-3" stroke="#0066CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" points="15.8772465 34.4772161 19.7559078 38.4616093 23.831635 34.5405562"></polyline>
                  <path d="M42.7444038,37.2342191 C42.7444038,43.5038824 37.6358492,48.5861704 31.3337825,48.5861704 C28.1574843,48.5861704 25.2843644,47.2952391 23.2156777,45.2110486" id="Stroke-5" stroke="#0066CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <polyline id="Stroke-7" stroke="#0066CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" points="46.5197824 39.5827288 42.8119115 35.768248 38.8049048 39.6189231"></polyline>
                  <path d="M39.8685554,62.598908 C37.15612,63.5037664 34.2526823,63.9944007 31.2340368,63.9944007 C26.5428576,63.9944007 22.1306025,62.8100416 18.2812484,60.7268565" id="Stroke-9" stroke="#0066CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M42.3358205,12.351116 C51.7889205,16.5727831 58.372943,26.0184993 58.372943,36.995437 C58.372943,39.1057679 58.1283792,41.1597964 57.6685587,43.1313823" id="Stroke-11" stroke="#0066CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M4.43610517,41.2906993 C4.21175321,39.891185 4.09452426,38.4574871 4.09452426,36.9956381 C4.09452426,26.0187004 10.6785468,16.5729842 20.1316468,12.3513171" id="Stroke-13" stroke="#0066CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <polygon id="Fill-15" fill="#FFFFFF" points="31.5419649 1.00549875 23.8321403 14.2908308 39.2517896 14.2908308"></polygon>
                  <polygon id="Stroke-17" stroke="#FFC72C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" points="31.5419649 1.00549875 23.8321403 14.2908308 39.2517896 14.2908308"></polygon>
                  <g id="Clip-20"></g>
                  <polygon id="Fill-19" fill="#FFFFFF" mask="url(#mask-2)" points="44.8026814 61.5404247 58.9894056 61.5404247 58.9894056 47.4266446 44.8026814 47.4266446"></polygon>
                  <polygon id="Stroke-21" stroke="#FFC72C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" mask="url(#mask-2)" points="44.8026814 61.5404247 58.9894056 61.5404247 58.9894056 47.4266446 44.8026814 47.4266446"></polygon>
                  <path d="M17.0473127,52.335803 C17.0473127,56.741458 13.4576814,60.3126324 9.02925671,60.3126324 C4.60083206,60.3126324 1.01019016,56.741458 1.01019016,52.335803 C1.01019016,47.930148 4.60083206,44.3579682 9.02925671,44.3579682 C13.4576814,44.3579682 17.0473127,47.930148 17.0473127,52.335803" id="Fill-22" fill="#FFFFFF" mask="url(#mask-2)"></path>
                  <path d="M17.0473127,52.335803 C17.0473127,56.741458 13.4576814,60.3126324 9.02925671,60.3126324 C4.60083206,60.3126324 1.01019016,56.741458 1.01019016,52.335803 C1.01019016,47.930148 4.60083206,44.3579682 9.02925671,44.3579682 C13.4576814,44.3579682 17.0473127,47.930148 17.0473127,52.335803 Z" id="Stroke-23" stroke="#FFC72C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" mask="url(#mask-2)"></path>
                </g>
              </g>
            </g>
          </g>
        </svg>`
}
