import { createReducer, on} from '@ngrx/store';
import {Dictionary, EntityAdapter, EntityState, createEntityAdapter} from '@ngrx/entity';
import * as fromActions from './sitemap.actions';
import { INITIAL_SITEMAP_FILTERS, SiteMapTableFilters } from '@app/sitemap/models/sitemap-filters';

export type SiteMapRouteStatus = 'success' | 'error' | 'in_progress' | 'not_set';
export interface SiteMapRoute {
  url: string;
  title: string;
  module: string;
  status: SiteMapRouteStatus;
  notes: Array<string>;
}

export const adapter: EntityAdapter<SiteMapRoute> =
 createEntityAdapter<SiteMapRoute>({
  selectId: (e) => e.url,
  sortComparer: (a,b) => (a.url.localeCompare(b.url))
 });
 
export interface State {
  routes: EntityState<SiteMapRoute>;
  filters: SiteMapTableFilters;
  defaultFilters: SiteMapTableFilters;
  loading: boolean;
}

export const initialState: State = {
  routes: adapter.getInitialState(),
  filters: INITIAL_SITEMAP_FILTERS,
  defaultFilters: INITIAL_SITEMAP_FILTERS,
  loading: false
};

export const reducer = createReducer(
  initialState,
  on(fromActions.initReadingRoutes, (state, {forceRefresh}) => ({
    ...state,
    filters: forceRefresh ? INITIAL_SITEMAP_FILTERS : {
      ...INITIAL_SITEMAP_FILTERS,
      module: {
        ...INITIAL_SITEMAP_FILTERS.module,
        options: state.routes.ids.map(id => state.routes.entities[id]).reduce((acc, item) => {
          if (acc.findIndex(c => c.label === item.module) < 0) {
            acc.push({label: item.module, value: item.module})
          }
          return acc
        }, []).sort((a,b) => a.label.localeCompare(b.label))
      }
    },
    defaultFilters: forceRefresh ? INITIAL_SITEMAP_FILTERS : {
      ...INITIAL_SITEMAP_FILTERS,
      module: {
        ...INITIAL_SITEMAP_FILTERS.module,
        options: state.routes.ids.map(id => state.routes.entities[id]).reduce((acc, item) => {
          if (acc.findIndex(c => c.label === item.module) < 0) {
            acc.push({label: item.module, value: item.module})
          }
          return acc
        }, []).sort((a,b) => a.label.localeCompare(b.label))
      }
    },
    loading: forceRefresh,
    routes: forceRefresh ? adapter.removeAll(state.routes) : state.routes,
  })),
  on(fromActions.loadRoutesFromFile, (state, {routes}) => ({
    ...state,
    filters: {
      ...INITIAL_SITEMAP_FILTERS,
      module: {
        ...INITIAL_SITEMAP_FILTERS.module,
        options: routes.reduce((acc, item) => {
          if (acc.findIndex(c => c.label === item.module) < 0) {
            acc.push({label: item.module, value: item.module})
          }
          return acc
        }, []).sort((a,b) => a.label.localeCompare(b.label))
      }
    },
    defaultFilters: {
      ...INITIAL_SITEMAP_FILTERS,
      module: {
        ...INITIAL_SITEMAP_FILTERS.module,
        options: routes.reduce((acc, item) => {
          if (acc.findIndex(c => c.label === item.module) < 0) {
            acc.push({label: item.module, value: item.module})
          }
          return acc
        }, []).sort((a,b) => a.label.localeCompare(b.label))
      }
    },
    routes: adapter.upsertMany(routes, state.routes),
  })),
  on(fromActions.updateFilters, (state, {filters}) => ({
    ...state,
    filters
  })),
  on(fromActions.readRoute, (state) => ({
    ...state,
    loading: true
  })),
  on(fromActions.routesLoaded, (state) => ({
    ...state,
    loading: false
  })),
  on(fromActions.addRoute, (state, {route}) => {
    return {
      ...state,
      routes: adapter.upsertOne(route, state.routes),
      filters: {
        ...state.filters,
        module: {
          ...state.filters.module,
          options: state.filters.module.options.findIndex(o => o.label === route.module) > -1 ? 
            [
              ...state.filters.module.options
            ] : 
            [
              ...state.filters.module.options,
              {label: route.module, value: route.module} 
            ].sort((a,b) => a.label.localeCompare(b.label))
        }
      },
      defaultFilters: {
        ...state.defaultFilters,
        module: {
          ...state.defaultFilters.module,
          options: state.defaultFilters.module.options.findIndex(o => o.label === route.module) > -1 ? 
            [
              ...state.defaultFilters.module.options
            ] : 
            [
              ...state.defaultFilters.module.options,
              {label: route.module, value: route.module} 
            ].sort((a,b) => a.label.localeCompare(b.label))
        }
      }
    }
  }),
  on(fromActions.updateStatus, (state, {url, status}) => ({
    ...state,
    routes: adapter.updateOne({id: url, changes: {status}}, state.routes)
  })),
  on(fromActions.addNote, (state, {url, note}) => ({
    ...state,
    routes: adapter.mapOne({id: url, map: (route) => ({
      ...route,
      notes: [
        ...route.notes,
        note
      ]

    })}, state.routes)
  })),
  on(fromActions.updateNotes, (state, {url, notes}) => ({
    ...state,
    routes: adapter.updateOne({id: url, changes: {notes}}, state.routes)
  })),
  on(fromActions.resetAll, (state) => {
    return ({
      ...state,
      routes: adapter.map(r => ({
        ...r,
        status: 'not_set',
        notes: [],
      }), state.routes)
    })
  }),
);


export const getRoutesData = (state: State) => adapter.getSelectors().selectAll(state.routes);

export const getEntitiesByIds = (entities: Dictionary<SiteMapRoute>, ids: string[]): SiteMapRoute[] => ids.map(id => entities[id]);

export const getLoading = (state: State) => state.loading;

export const getFilters = (state: State) => state.filters;
export const getDefaultFilters = (state: State) => state.defaultFilters;