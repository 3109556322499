import { Pipe, PipeTransform } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromChannels from '@core/core-store/channels/channels.selectors';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Pipe({
  name: 'channelName'
})
export class ChannelNamePipe implements PipeTransform {
  transform(channelId: string): Observable<string> {
    return this.store$.pipe(
      select(fromChannels.selectEntityById, { id: channelId}),
      distinctUntilChanged(),
      map((channel) => channel.name)
    );
  }

  constructor(
    private store$: Store<any>) {
  }
}
