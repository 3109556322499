import {APP_INITIALIZER, Inject, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from '@core/core.module';
import {SharedModule} from '@shared/shared.module';
import {LayoutModule} from '@app/layout/layout.module';
import {AppComponent} from './app.component';
import {RootStoreModule} from '@app/root-store/root-store.module';
import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Store} from '@ngrx/store';
import {first} from 'rxjs/operators';
import * as fromAuth from '@core/auth/store';
import {MessagingModule} from '@angular/fire/messaging';
import {DatabaseModule} from '@angular/fire/database';
import {AuthModule} from '@angular/fire/auth';
import {environment} from '@env/environment';
import {GoogleAuthComponent} from './google-auth/google-auth.component';
import {RdsToastModule} from '@rds/angular-components';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ErrorInterceptor} from '@core/auth/interceptors/error.interceptors';
import { LottieModule } from 'ngx-lottie';

export function initApplication(store: Store<fromAuth.State>) {
  return () => new Promise(resolve => {
    store.dispatch(fromAuth.startAppInitializer());
    store.dispatch(fromAuth.initAuth());
    store.select(fromAuth.selectIsAuthenticated)
      .pipe(first(completed => completed || window.location.pathname === '/google-auth'))
      .subscribe(() => {
        store.dispatch(fromAuth.finishAppInitializer());
        resolve(true);
      });
  });
}

const PROVIDERS = [
  {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true}
];

@NgModule({
  declarations: [AppComponent, GoogleAuthComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    RootStoreModule.forRoot(),
    CoreModule.forRoot(),
    LayoutModule.forRoot(),
    SharedModule.forRoot(),
    DatabaseModule,
    AuthModule,
    MessagingModule,
    RdsToastModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    HammerModule,
    LottieModule.forRoot({player: () => import('lottie-web')})
  ],
  bootstrap: [AppComponent],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: initApplication,
    multi: true,
    deps: [[new Inject(Store)]],
    ...PROVIDERS
  }
  ]
})
export class AppModule {
}
