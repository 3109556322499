<div rds-dialog-content class="p-7">
  <p class="ui-label-l-bold">Saving...</p>
  <rds-progress-bar class="mb-3 mt-5"></rds-progress-bar>
  <p class="ui-body-m mb-5">It may take up to 60 seconds</p>
  <div class="preview__wrapper">
    <div #image class="preview__content">
      <rh-newsletter-preview-read-only *ngIf="templateId === 'NewsletterTemplateInlineEditor'" [sections]="sections | async" [form]="form | async"></rh-newsletter-preview-read-only>
      <rh-newsletter-preview *ngIf="templateId === 'NewsletterTemplateWithSections' || templateId === 'NewsletterTemplateWithoutSections'" [newsletter]="formNewsletter | async"></rh-newsletter-preview>
    </div>
  </div>
</div>