import { createAction, props } from '@ngrx/store';

import { CatalogTable } from '@core/models/catalog.model';

export const catalogsCollectionAddMany = createAction(
  '[STORED CATALOGS - ADD MANY] Catalogs collection add many.',
  props<{ catalogs: CatalogTable[] }>()
);

export const catalogsCollectionAddOne = createAction(
  '[STORED CATALOGS - ADD ONE] Catalogs collection add one.',
  props<{ catalog: CatalogTable }>()
);

export const catalogsCollectionUpsertOne = createAction(
  '[STORED CATALOGS - UPSERT ONE] Catalogs collection upsert one.',
  props<{ catalog: CatalogTable }>()
);

export const catalogsCollectionRemoveOne = createAction(
  '[STORED CATALOGS - REMOVE ONE] Catalogs collection remove one.',
  props<{ catalog: CatalogTable }>()
);
