import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {UserSearch} from '@app/core/models/user-search.model';
import {environment} from '@env/environment';
import {RdsMenuComponent, RdsMenuTriggerDirective} from '@rds/angular-components';

@Component({
  selector: 'rh-comment-input',
  templateUrl: './comment-input.component.html',
  styleUrls: ['./comment-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CommentInputComponent),
      multi: true,
    },
  ]
})
export class CommentInputComponent implements ControlValueAccessor, OnDestroy {
  _mentionAutocomplete: { data: Array<UserSearch>, loading: boolean };
  get mentionAutocomplete(): { data: Array<UserSearch>, loading: boolean } {
    return this._mentionAutocomplete;
  }

  @Input() set mentionAutocomplete(value: { data: Array<UserSearch>, loading: boolean }) {
    this._mentionAutocomplete = value;
    if (!!this.mentionElement) {
      if (!!value && value.data.length > 0) {
        this.trigger.openMenu()
      } else if (this.trigger.menuOpen) {
        this.trigger.closeMenu()
      }
    }

  }

  @Input() error: boolean = false;
  @Input() label: string;
  @Output() getAutocomplete: EventEmitter<string> = new EventEmitter();
  @Output() clearAutocomplete: EventEmitter<any> = new EventEmitter();

  commentValue = '';

  @ViewChild('input', {static: true}) input: ElementRef<HTMLDivElement>;
  @ViewChild('autocomplete', {static: true}) autocomplete: RdsMenuComponent;
  @ViewChild('trigger', {static: true}) trigger: RdsMenuTriggerDirective;
  triggerX: number;
  triggerY: number;
  mentionElement: HTMLSpanElement;
  mentionElementInputListener;
  mentionElementBlurListener;

  get commentLength() {
    return this.input?.nativeElement.innerHTML.length;
  }

  
  onInput(event: InputEvent) {
    if (!!this.input.nativeElement.innerHTML) {
    } else {
      this.trigger.closeMenu();
    }
    if (event.data === '@' && !this.mentionElement) {
      this.insertMention();
    }
    this.onChange(
      CommentInputComponent.processValue(
        this.input.nativeElement.innerHTML,
      ),
    );
  }

  onPaste(event) {
    event.preventDefault();

    var text = (event.originalEvent || event).clipboardData.getData('text/plain');
    document.execCommand("insertHTML", false, text);
  }

  onKeydown(event: KeyboardEvent) {
    switch (event.key) {
      case 'Escape': {
        if (this.trigger.menuOpen) {
          this.trigger.closeMenu();
        }
        break;
      }
      case 'Enter': {
        if (this.autocomplete._allItems.length === 1) {
          this.mentionUser(this.mentionAutocomplete.data[0]);
        }

      }
    }

  }

  insertMention() {
    var selection = window.getSelection(),
      range = selection.getRangeAt(0);

    if (range.startOffset - 2 > 0 && range.startContainer.nodeValue[range.startOffset - 2] !== ' ') {
      return
    }
    this.renderer.setAttribute(
      this.input.nativeElement,
      'contenteditable',
      'false',
    );

    this.mentionElement = document.createElement('a');

    this.renderer.setAttribute(
      this.mentionElement,
      'id',
      'test-mention',
    );

    this.renderer.setStyle(
      this.mentionElement,
      'outline',
      'none'
    );

    this.renderer.setAttribute(
      this.mentionElement,
      'contenteditable',
      'true',
    );
    range.setStart(selection.anchorNode, range.startOffset - 1);
    range.surroundContents(this.mentionElement);
    range.selectNode(this.mentionElement);
    range.setStart(this.mentionElement, 1);
    range.setEnd(this.mentionElement, 1);
    this.mentionElement.focus();
    this.mentionElementInputListener = this.renderer.listen(
      this.mentionElement,
      'input',
      (event) => {
        if (this.mentionElement.textContent.length > 3) {
          this.getAutocomplete.emit(this.mentionElement.textContent.slice(1));
        }
      }
    )
    this.mentionElementBlurListener = this.renderer.listen(
      this.mentionElement,
      'blur',
      (event) => {
        this.renderer.setAttribute(
          this.input.nativeElement,
          'contenteditable',
          'true',
        );
        this.renderer.setAttribute(
          this.mentionElement,
          'contenteditable',
          'false',
        );
        setTimeout(() => {

          let range2 = selection.getRangeAt(0);
          if (!this.mentionElement.classList.contains('mention__added')) {
            range2.selectNode(this.mentionElement);
            range2.setStartBefore(this.mentionElement);
            range2.setEndAfter(this.mentionElement);
            const textNode = document.createTextNode(this.mentionElement.textContent)
            range2.extractContents();
            range2.insertNode(textNode);
            range2.selectNode(this.input.nativeElement)
            const index = Array.from(this.input.nativeElement.childNodes).indexOf(textNode);
            this.input.nativeElement.focus();
            range2 = selection.getRangeAt(0);
            range2.setStart(this.input.nativeElement, index + 1)
            range2.setEnd(this.input.nativeElement, index + 1)
            this.clearAutocomplete.emit();
          } else {
            const index = Array.from(this.input.nativeElement.childNodes).indexOf(this.mentionElement);
            this.input.nativeElement.focus();
            range2 = selection.getRangeAt(0);
            range2.setStart(this.input.nativeElement, index + 2)
            range2.setEnd(this.input.nativeElement, index + 2)
          }
          this.mentionElementInputListener();
          this.mentionElementBlurListener();
          this.mentionElement = null;
        }, 300)

      }
    )
    this.setTriggerXY(this.mentionElement);
  }

  setTriggerXY(span: HTMLSpanElement) {

    var rect = span.getClientRects()[0];
    this.triggerX = rect.x;
    this.triggerY = rect.y;
  }

  private onTouched = () => {
  };

  private onChange: (value: string) => void = () => {
  };

  constructor(
    @Inject(Renderer2) private readonly renderer: Renderer2,
  ) {
  }


  onBlur() {
    this.onTouched();
  }

  mentionUser(user: UserSearch) {
    const now = new Date().getTime();

    this.renderer.setAttribute(
      this.mentionElement,
      'data-login',
      user.login
    );
    this.renderer.setAttribute(
      this.mentionElement,
      'data-email',
      user.email
    );
    this.renderer.setAttribute(
      this.mentionElement,
      'id',
      `${now}-${user.login}`
    );
    this.renderer.setAttribute(
      this.mentionElement,
      'href',
      `${environment.url}/users/${user.login}`
    );
    this.renderer.setAttribute(
      this.mentionElement,
      'target',
      `_blank`
    );
    this.renderer.addClass(
      this.mentionElement,
      'mention__added'
    );
    this.renderer.setProperty(
      this.mentionElement,
      'innerHTML',
      `@${user.firstName} ${user.lastName}`
    );

    const spaceNodeBefore = document.createTextNode('\u00A0');
    const spaceNodeAfter = document.createTextNode('\u00A0');
    const range = window.getSelection().getRangeAt(0);
    range.selectNode(this.mentionElement);
    range.insertNode(spaceNodeBefore)
    range.setStartAfter(this.mentionElement);
    range.setEndAfter(this.mentionElement);
    range.insertNode(spaceNodeAfter);
    this.mentionElement.blur();
    this.onChange(
      CommentInputComponent.processValue(
        this.input.nativeElement.innerHTML,
      ),
    );
    this.clearAutocomplete.emit();
    this.trigger.closeMenu();
  }

  ngOnDestroy(): void {
    if (!!this.mentionElementInputListener) {
      this.mentionElementInputListener();
    }
    if (!!this.mentionElementBlurListener) {
      this.mentionElementBlurListener();
    }
    this.mentionElement = null;
  }

  writeValue(value: string | null) {
    if (!!this.input) {
      this.renderer.setProperty(
        this.input.nativeElement,
        'innerHTML',
        CommentInputComponent.processValue(value),
      );
    }
  }

  registerOnChange(onChange: (value: string) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.renderer.setAttribute(
      this.input.nativeElement,
      'contenteditable',
      "" + !disabled,
    );
  }

  private static processValue(value: string): string {
    const processed: string = value == null ? '' : value;

    return processed.trim() === '<br>' ? '' : processed;
  }
}
