import {Component, OnInit, ViewChild} from '@angular/core';
import {RdsMenuComponent} from '@rds/angular-components';
import {debounceTime, distinctUntilChanged, Observable} from 'rxjs';
import * as fromHeader from '../../store/header';
import * as fromUser from '@app/core/user/store';
import {RoleNames} from '@core/enums/roles.enum';
import {select, Store} from '@ngrx/store';
import * as fromTiles from '@app/dashboard/store/tiles';
import {MENU_ITEMS, MenuItem} from '@core/models/menu';

export interface AppMenuTreeItem {
  link: string;
  icon: string;
  route?: string;
  url?: string;
}

export interface AppMenuTreeNode {
  item: AppMenuTreeItem;
  selected?: boolean;
  children?: Array<AppMenuTreeNode>;
}

export interface AppMenuFlatTreeNode {
  selected: boolean;
  expandable: boolean;
  item: AppMenuTreeItem;
  level: number;
}

@Component({
  selector: 'rh-header-app-menu',
  templateUrl: './header-app-menu.container.html',
  styleUrls: ['./header-app-menu.container.scss'],
})
export class HeaderAppMenuContainer implements OnInit {
  @ViewChild("menu", {static: true}) menu: RdsMenuComponent;

  rhRoles$: Observable<Array<RoleNames>> = this.store$.pipe(select(fromUser.selectRoles));
  menuItems: Array<MenuItem> = [];
  shortcuts$: Observable<Array<{path: string, title: string, icon: string}>> = this.store$.pipe(select(fromHeader.selectShortcuts));

  filterMenuItem(roles) {
    return MENU_ITEMS.filter(tile => (tile.roles.length === 0 || tile.roles.some(r => roles.includes(r))))
  }


  ngOnInit(): void {
    this.rhRoles$.pipe(
      distinctUntilChanged(),
      debounceTime(50)
    ).subscribe(roles => {
      let allRoles = [...roles];
      this.menuItems = this.filterMenuItem(allRoles);
    })
  }

  constructor(private store$: Store<fromTiles.State>,) {
  }
}
