import { UserSearch } from '@app/core/models/user-search.model';
import { createAction, props } from '@ngrx/store';

export const getUserEmail = createAction(
  '[EMAIL API] Get User Email By Login',
  props<{ login: string }>()
);

export const getUserEmailSuccess = createAction(
  '[EMAIL API] Get User Email By Login Success',
  props<{ email: string, login: string }>()
);

export const getUserEmailFailure = createAction(
  '[EMAIL API] Get User Email By Login Failure'
);

export const getEmailSearchRequest = createAction(
  '[EMAIL SUGGESTIONS - EMAIL SEARCH REQUEST] Email Search Request',
  props<{ phrase: string }>()
);

export const getEmailSearchSuccess = createAction(
  '[EMAIL SUGGESTIONS - EMAIL SEARCH SUCCESS] Email Search Success',
  props<{ usersSearched: Array<UserSearch> }>()
);

export const getEmailSearchFailure = createAction(
  '[EMAIL SUGGESTIONS - EMAIL SEARCH ERROR] Email Search Error',
  props<{ error: any }>()
);

export const clearEmailSearch = createAction(
  '[EMAIL SUGGESTIONS - CLEAR EMAIL SEARCH] Clear Email Search'
);
