import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as fromBackButton from '@app/root-store/ui/back-button';
import { Store, select } from '@ngrx/store';
import { RdsDialogService } from '@rds/angular-components';
import { Observable, filter, map } from 'rxjs';
import { SubSink } from 'subsink';
import { ConfirmDialogComponent, ConfirmDialogData } from '../dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'rh-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent {
  private subs: SubSink = new SubSink();

  @Input() leavePageWarning: boolean = false;
  @Input() changesMade: boolean = false;
  @Input() defaultLabel: string = '';
  @Input() defaultRoute: string = "../"
  @Input() preventRedirect: boolean = false;
  @Input() customConfirmLeaveData: ConfirmDialogData;

  @Output() leaveConfirmed: EventEmitter<ConfirmDialogData> = new EventEmitter();

  defaultConfirmLeaveData: ConfirmDialogData = {
    ids: [],
    messages: [],
    title: 'Do you want to discard changes?',
    confirmButtonLabel: 'Yes, discard',
    confirmButtonType: 'primary'
  }

  backConfig$: Observable<any> = this.store$.pipe(
    select(fromBackButton.selectBackConfig),
    map(({label, routeBack, routeCurrent}) => ({
      label: !!label ? label : this.defaultLabel,
      routeCurrent: !!routeBack ? routeBack : this.defaultRoute,
    })));
  constructor(
    private store$: Store<any>,
    private dialogService: RdsDialogService
    ) {

  }

  openLeavePageDialog() {
    if (this.changesMade) {
      const dialog = this.dialogService.open(ConfirmDialogComponent, {
        data: this.customConfirmLeaveData || this.defaultConfirmLeaveData
      });
  
      this.subs.sink = dialog.afterClosed().pipe(
        filter(data => !!data)
      ).subscribe((data: ConfirmDialogData) => {
        if (!!this.customConfirmLeaveData) {
          this.leaveConfirmed.emit(data)
        } else {
          this.store$.dispatch(fromBackButton.back({defaultLabel: this.defaultLabel, defaultRoute: this.defaultRoute})); ;
        }
      });
    } else {
      this.store$.dispatch(fromBackButton.back({defaultLabel: this.defaultLabel, defaultRoute: this.defaultRoute})); ;
    }

  }

  back() {
    if (!this.preventRedirect) {
      this.store$.dispatch(fromBackButton.back({defaultLabel: this.defaultLabel, defaultRoute: this.defaultRoute})); ;
    }
  }
}
