import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import {ImageUtils} from "@ckeditor/ckeditor5-image";
import {determineImageTypeForInsertionAtSelection} from "@ckeditor/ckeditor5-image/src/image/utils";
import {isWidget, toWidget, toWidgetEditable} from '@ckeditor/ckeditor5-widget';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import {enablePlaceholder} from 'ckeditor5/src/engine';
import {first} from 'ckeditor5/src/utils';
import CustomImageAlternativeCommand from "../blocks/custom-image-alternative-command";
import CustomImageUploadCommand from "../blocks/custom-image-upload-command";
import CustomImageDefaultSrcCommand from "./custom-image-src-command";


export default class CustomImageEditing extends Plugin {

    static get requires() {
        return [Widget, ImageUtils];
    }

    _findChild(element, blocks) {
        if (element?.name === 'customImageBox') {
            return element;
        } else {
            const childrens = element ? Array.from(element.getChildren()) : blocks;
            for (let i of childrens) {
                return this._findChild(childrens[i]);
            }
        }
    }

    _findChildFromBlocks(blocks) {

        for (let i in blocks) {
            return this._findChild(blocks[i]);
        }

    }

    init() {
        const editor = this.editor;
        const model = editor.model;
        const imageUtils = editor.plugins.get('ImageUtils');
        editor.commands.add('customImageUpload', new CustomImageUploadCommand(this.editor));
        editor.commands.add('customImageAlternativeText', new CustomImageAlternativeCommand(this.editor));
        editor.commands.add('customImageDefaultSrc', new CustomImageDefaultSrcCommand(this.editor));

        this._defineSchema();
        this._defineConverters();
        const imageTypes = createImageTypeRegExp(this.editor.config.get('image.upload.types'));

        this.listenTo(editor.editing.view.document, 'delete', (evt, data) => {
            const element = data.document.selection.getSelectedElement();
            if (element?.name === 'figure' && element.hasClass('custom-figure')) {
                editor.execute('customImageDefaultSrc');
                evt.stop();
            }
        })

        this.listenTo(editor.editing.view.document, 'clipboardInput', (evt, data) => {
            const images = Array.from(data.dataTransfer.files).filter(file => {
                // See https://github.com/ckeditor/ckeditor5-image/pull/254.
                if (!file) {
                    return false;
                }

                return imageTypes.test(file.type);
            });

            if (!images.length) {
                return;
            }

            evt.stop();

            this.editor.model.change(writer => {
                if (data.targetRanges) {
                    writer.setSelection(data.targetRanges.map(viewRange => editor.editing.mapper.toModelRange(viewRange)));
                }
                // Upload images after the selection has changed in order to ensure the command's state is refreshed.
                this.editor.model.enqueueChange(() => {

                    this.editor.execute('customImageUpload', {file: images});
                });
            });
        }, {priority: 'highest', context: isWidget});
        this.listenTo(editor.plugins.get('ClipboardPipeline'), 'inputTransformation', (evt, data) => {
            const docFragmentChildren = Array.from(data.content.getChildren());
            let modelRange;

            // Make sure only <figure class="image"></figure> elements are dropped or pasted. Otherwise, if there some other HTML
            // mixed up, this should be handled as a regular paste.
            if (!docFragmentChildren.every(imageUtils.isBlockImageView)) {
                return;
            }

            // When drag and dropping, data.targetRanges specifies where to drop because
            // this is usually a different place than the current model selection (the user
            // uses a drop marker to specify the drop location).
            if (data.targetRanges) {
                modelRange = editor.editing.mapper.toModelRange(data.targetRanges[0]);
            }
            // Pasting, however, always occurs at the current model selection.
            else {
                modelRange = model.document.selection.getFirstRange();
            }

            const selection = model.createSelection(modelRange);

            // Convert block images into inline images only when pasting or dropping into non-empty blocks
            // and when the block is not an object (e.g. pasting to replace another widget).
            if (determineImageTypeForInsertionAtSelection(model.schema, selection) === 'customImageBox') {
                const writer = new UpcastWriter(editingView.document);

                // Unwrap <figure class="image"><img .../></figure> -> <img ... />
                // but <figure class="image"><img .../><figcaption>...</figcaption></figure> -> stays the same
                const inlineViewImages = docFragmentChildren.map(blockViewImage => {
                    // If there's just one child, it can be either <img /> or <a><img></a>.
                    // If there are other children than <img>, this means that the block image
                    // has a caption or some other features and this kind of image should be
                    // pasted/dropped without modifications.
                    if (blockViewImage.childCount === 1) {
                        // Pass the attributes which are present only in the <figure> to the <img>
                        // (e.g. the style="width:10%" attribute applied by the ImageResize plugin).
                        Array.from(blockViewImage.getAttributes())
                            .forEach(attribute => writer.setAttribute(
                                ...attribute,
                                imageUtils.findViewImgElement(blockViewImage)
                            ));

                        return blockViewImage.getChild(0);
                    } else {
                        return blockViewImage;
                    }
                });

                data.content = writer.createDocumentFragment(inlineViewImages);
            }
        }, {priority: 'highest', context: isWidget});

    }


    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register('customImageBox', {
            inheritAllFrom: '$blockObject',
            allowIn: ['imagesBox', 'imageAndTextBox', 'imageAndQuoteBox', 'announcementBox', 'imagesAndTextBox', 'sectionColorsBox'],
            allowAttributes: ['src', 'srcset', 'alt'],
            allowChildren: ['caption', 'figcaption']
        });
    }

    _defineConverters() {
        const editor = this.editor;
        const t = editor.t;
        const conversion = editor.conversion;
        const imageUtils = this.editor.plugins.get('ImageUtils');

        conversion.for('upcast')
            .elementToElement({
                model: 'body-rds-m',
                view: 'p'
            })

        conversion.for('dataDowncast')
            .elementToStructure({
                model: 'customImageBox',
                view: (modelElement, {writer}) => createBlockImageViewElement(writer, modelElement)
            });

        conversion.for('editingDowncast')
            .elementToStructure({
                model: 'customImageBox',
                view: (modelElement, {writer}) => toImageWidget(
                    createBlockImageViewElement(writer, modelElement), writer, t('image widget')
                )
            });

        conversion.for('downcast')
            .add(downcastImageAttribute(imageUtils, 'customImageBox', 'src', editor))
            .add(downcastImageAttribute(imageUtils, 'customImageBox', 'alt', editor))


        conversion.for('upcast')
            .elementToElement({
                view: {
                    name: 'img',
                    classes: ['custom-image']
                },
                model: (viewImage, {writer}) => writer.createElement(
                    'customImageBox',
                    viewImage.hasAttribute('src') ? {src: viewImage.getAttribute('src')} : null
                )
            }).add(upcastImageFigure(imageUtils, editor));


        const view = editor.editing.view;
        const imageCaptionUtils = editor.plugins.get('ImageCaptionUtils');

        // View -> model converter for the data pipeline.
        editor.conversion.for('upcast').elementToElement({
            model: 'caption',
            view: element => imageCaptionUtils.custoMatchImageCaptionViewElement(element)
        });
        // Model -> view converter for the data pipeline.
        editor.conversion.for('dataDowncast').elementToElement({
            model: 'caption',
            view: (modelElement, {writer}) => {
                if (!imageUtils.isCustomImageBox(modelElement.parent)) {
                    return null;
                }
                return writer.createContainerElement('figcaption');
            },
            converterPriority: 'higher'
        });
        // Model -> view converter for the editing pipeline.
        editor.conversion.for('editingDowncast').elementToElement({
            model: 'caption',
            view: (modelElement, {writer}) => {
                if (!!imageUtils.isCustomImageBox(modelElement.parent)) {
                    const figcaptionElement = writer.createEditableElement('figcaption');
                    writer.setCustomProperty('imageCaption', true, figcaptionElement);
                    figcaptionElement.placeholder = t('Enter image caption');
                    enablePlaceholder({
                        view,
                        element: figcaptionElement,
                        keepOnFocus: true
                    });
                    const imageAlt = modelElement.parent.getAttribute('alt');
                    const label = imageAlt ? t('Caption for image: %0', [imageAlt]) : t('Caption for the image');
                    return toWidgetEditable(figcaptionElement, writer, {label});
                }
                return null;
            }, converterPriority: 'higher'
        });
    }
}

function createImageTypeRegExp(types) {
    // Sanitize the MIME type name which may include: "+", "-" or ".".
    const regExpSafeNames = types.map(type => type.replace('+', '\\+'));

    return new RegExp(`^image\\/(${ regExpSafeNames.join( '|' ) })$`);
}

export function createBlockImageViewElement(writer, modelElement) {
    const attr = Array.from(modelElement.getAttributes());
    const img = writer.createEmptyElement('img', {class: 'custom-image'}, attr);
    const figure = writer.createContainerElement('figure', {class: 'custom-figure'}, [
        img,
        writer.createSlot()
    ]);

    return figure;
}

export function toImageWidget(viewElement, writer, label) {
    return toWidget(viewElement, writer, {label});
}

export function upcastImageFigure(imageUtils, editor) {
    const converter = (evt, data, conversionApi) => {
        // Do not convert if this is not an "image figure".
        if (!conversionApi.consumable.test(data.viewItem, {name: true, classes: 'custom-figure'})) {
            return;
        }
        // Find an image element inside the figure element.
        const viewImage = findViewImgElement(data.viewItem, editor);
        // Do not convert if image element is absent or was already converted.
        if (!viewImage || !conversionApi.consumable.test(viewImage, {name: true})) {
            return;
        }
        // Consume the figure to prevent other converters from processing it again.
        conversionApi.consumable.consume(data.viewItem, {name: true, classes: 'image'});
        // Convert view image to model image.
        const conversionResult = conversionApi.convertItem(viewImage, data.modelCursor);
        // Get image element from conversion result.
        const modelImage = first(conversionResult.modelRange.getItems());
        // When image wasn't successfully converted then finish conversion.
        if (!modelImage) {
            // Revert consumed figure so other features can convert it.
            conversionApi.consumable.revert(data.viewItem, {name: true, classes: 'image'});
            return;
        }
        // Convert rest of the figure element's children as an image children.
        conversionApi.convertChildren(data.viewItem, modelImage);
        conversionApi.updateConversionResult(modelImage, data);
    };
    return dispatcher => {
        dispatcher.on('element:figure', converter);
    };

}

export function downcastImageAttribute(imageUtils, imageType, attributeKey, editor) {

    return dispatcher => {
        dispatcher.on(`attribute:${attributeKey}:${imageType}`, converter);
    };

    function converter(evt, data, conversionApi) {

        if (!conversionApi.consumable.consume(data.item, evt.name)) {
            return;
        }
        const viewWriter = conversionApi.writer;
        const element = conversionApi.mapper.toViewElement(data.item);
        const img = findViewImgElement(element, editor);
        const value = data.attributeNewValue;
        viewWriter.setAttribute(data.attributeKey, value, img);
    }
}

export function findViewImgElement(figureView, editor) {
    if (isInlineImageView(figureView)) {
        return figureView;
    }

    const editingView = editor.editing.view;

    for (const {item} of editingView.createRangeIn(figureView)) {
        if (isInlineImageView(item)) {
            return item;
        }
    }
    return figureView;
}

export function isInlineImageView(element) {
    return !!element && element.is('element', 'img');
}
