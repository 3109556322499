import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'rh-disable-commenting-dialog',
  templateUrl: './disable-commenting-dialog.component.html',
  styleUrls: ['./disable-commenting-dialog.component.scss']
})
export class DisableCommentingDialogComponent {

  form: FormGroup = new FormGroup({
    hide: new FormControl(false)
  })
}
