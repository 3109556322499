import { ContentDetails } from '@core/models';
import { createAction, props } from '@ngrx/store';

export const initNewsDetailsView = createAction(
  '[NEWS] Init news details view',
  props<{ id: string; language?: string }>()
);

export const getNewsDetailsRequest = createAction(
  '[NEWS] Get details request',
  props<{ id: string; language?: string }>()
);

export const getNewsDetailsSuccess = createAction(
  '[NEWS] Get details success',
  props<{ id: string; news: ContentDetails }>()
);

export const getNewsDetailsFailure = createAction(
  '[NEWS] Get details failure',
  props<{ error: string }>()
);

export const likeRequest = createAction(
  '[NEWS] Like request',
  props<{ newsId: string }>()
);

export const likeSuccess = createAction(
  '[NEWS] Like success',
  props<{ newsId: string, numLikes: number }>()
);

export const likeFailure = createAction(
  '[NEWS] Like failure',
  props<{ newsId: string }>()
);

export const unlikeRequest = createAction(
  '[NEWS] Unlike request',
  props<{ newsId: string }>()
);

export const unlikeSuccess = createAction(
  '[NEWS] Unlike success',
  props<{ newsId: string, numLikes: number }>()
);

export const unlikeFailure = createAction(
  '[NEWS] Unlike failure',
  props<{ newsId: string }>()
);

export const addBookmarkRequest = createAction(
  '[NEWS] Add bookmark request',
  props<{ newsId: string }>()
);

export const addBookmarkSuccess = createAction(
  '[NEWS] Add bookmark success',
  props<{ newsId: string }>()
);

export const addBookmarkFailure = createAction(
  '[NEWS] Add bookmark failure',
  props<{ newsId: string }>()
);

export const removeBookmarkRequest = createAction(
  '[NEWS] Remove bookmark request',
  props<{ newsId: string }>()
);

export const removeBookmarkSuccess = createAction(
  '[NEWS] Remove bookmark success',
  props<{ newsId: string }>()
);

export const removeBookmarkFailure = createAction(
  '[NEWS] Remove bookmark failure',
  props<{ newsId: string }>()
);

export const share = createAction(
  '[NEWS] Share article.',
  props<{ body: string }>()
);

export const copyLink = createAction(
  '[NEWS] Copy link',
  props<{ route: string }>()
);

export const clear = createAction(
  '[NEWS] clear',
);
