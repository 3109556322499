import {
	AbstractControl,
	AsyncValidatorFn,
	FormControl,
	FormGroup,
	UntypedFormControl,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { RoleNames } from '@app/core/enums/roles.enum';
import { GenericAccountsService } from '@app/core/services/generic-accounts.service';
import get from '@lodash-es/get';
import { utcToZonedTime } from 'date-fns-tz';
import { debounceTime, distinctUntilChanged, from, map, Observable, take } from 'rxjs';

export function validateURL(c: AbstractControl) {
	const regExp = RegExp(/^(?:(http|https|ftp)?:\/\/)?/g);
	return regExp.test(c.value) || !c.value
		? null
		: {
				url: {
					valid: false,
				},
			};
}

export function validateIsProtocol(c: AbstractControl) {
	return (c.value &&
		(c.value.startsWith('http://') || c.value.startsWith('https://') || c.value.startsWith('ftp://'))) ||
		!c.value
		? null
		: {
				protocol: {
					valid: false,
				},
			};
}

export function validateUserSelected(c: AbstractControl) {
	return !!c.value
		? null
		: {
				userSelected: {
					valid: false,
				},
			};
	8;
}

export function validateIsFuture(c: AbstractControl) {
	console.log(c.value);
	if (!!c.value) {
		const isFutureDate = new Date().getTime() < new Date(c.value).getTime();
		return isFutureDate ? null : { dateFromPast: true };
	}
	return null;
}

export function validateUnique(isNotUnique: boolean, actualName: string) {
	return (control: AbstractControl) => {
		return control.value && control.value !== actualName && isNotUnique
			? {
					isNotUnique: {
						valid: false,
					},
				}
			: null;
	};
}

export function validateAtLeastOneRequired(rControls): ValidatorFn {
	return (controls: AbstractControl) => {
		return rControls.find(
			(k) => get(controls.value, k) && Array.isArray(get(controls.value, k)) && get(controls.value, k).length
		)
			? null
			: {
					atLeastOneRequired: {
						valid: false,
					},
				};
	};
}

export function validateAtLeastOneMatchRequired(rControls, dict): ValidatorFn {
	return (controls: AbstractControl) => {
		/*
		 * @description One of the controls must include at least one of allowed values (dict)
		 * or all roots of each controls should be selected
		 */
		return rControls.find(
			(k) =>
				get(controls.value, k) &&
				Array.isArray(get(controls.value, k)) &&
				get(controls.value, k).find((val) => dict[k].includes(val))
		)
			? null
			: {
					atLeastOneMatchRequired: {
						valid: false,
					},
				};
	};
}

export function validateAtLeastOneTrue(rControls): ValidatorFn {
	return (controls: AbstractControl) => {
		return Object.keys(controls.value).find((k) => rControls.includes(k) && controls.value[k] === true)
			? null
			: {
					atLeastOneTrue: {
						valid: false,
					},
				};
	};
}

export function validateStep(checkStepNo: number[], component: any, options: { [key: string]: any }): ValidatorFn {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		let errors: { [key: string]: boolean } = {};
		if (checkStepNo.includes(component.currentStep)) {
			const optionKeys = Object.keys(options);
			optionKeys.forEach((key) => {
				switch (key) {
					case 'required': {
						if (
							(typeof control.value === 'string' && control.value.trim() === '') ||
							(control.value instanceof Array && control.value.length === 0) ||
							control.value === null
						) {
							errors = {
								...errors,
								required: true,
							};
						}
						break;
					}
					case 'maxLength': {
						const maxLength = options[key];
						if (!!control.value && control.value.length > maxLength) {
							errors = {
								...errors,
								maxLength: true,
							};
						}
					}
				}
			});
		}
		return !!Object.keys(errors).length ? errors : null;
	};
}

export function validateSentDate(checkStepNo: number[], component: any): ValidatorFn {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		if (component.newsletterForm && checkStepNo.includes(component.currentStep)) {
			const whenToSend = component.newsletterForm.get('whenToSend').value;
			if (
				whenToSend === 1 &&
				((typeof control.value === 'string' && control.value.trim() === '') ||
					(control.value instanceof Array && control.value.length === 0) ||
					control.value === null)
			) {
				return { valid: false };
			}
		}
		return null;
	};
}

export function validateScheduleIsFuture(checkStepNo: number[], component: any): ValidatorFn {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		if (component.newsletterForm && checkStepNo.includes(component.currentStep)) {
			const scheduleSentDate = component.newsletterForm.get('scheduleSentDate').value;
			if (!!scheduleSentDate) {
				const isFutureDate = new Date().getTime() < new Date(scheduleSentDate).getTime();
				return isFutureDate ? null : { dateFromPast: true };
			}
		}
		return null;
	};
}

export function validateTimezone(checkStepNo: number[], component: any): ValidatorFn {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		if (component.newsletterForm && checkStepNo.includes(component.currentStep)) {
			const whenToSend = component.newsletterForm.get('whenToSend').value;
			if (
				(whenToSend && typeof control.value === 'string' && control.value.trim() === '') ||
				(control.value instanceof Array && control.value.length === 0) ||
				control.value === null
			) {
				return { valid: false };
			}
		}
		return null;
	};
}

export function validateTimeAndStep(checkStepNo: number[], component: any) {
	return (control: AbstractControl) => {
		if (checkStepNo.includes(component.currentStep)) {
			const whenToSend = component.newsletterForm.get('whenToSend').value;
			if (
				whenToSend &&
				((control.value && (control.value.hour === null || control.value.minute === null)) || !control.value)
			) {
				return {
					protocol: {
						valid: false,
					},
				};
			}
		}
		return null;
	};
}

export function validateDescriptionNote(checkStepNo: number[], component: any): ValidatorFn {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		if (component.newsletterForm && checkStepNo.includes(component.currentStep)) {
			const descriptionNoteIncluded = component.newsletterForm.get('descriptionNoteIncluded').value;
			if (descriptionNoteIncluded && ((control.value && !control.value.trim()) || !control.value)) {
				return { required: true };
			}
		}
		return null;
	};
}

export function validateContactEmailForFeedback(checkStepNo: number[], component: any): ValidatorFn {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		if (component.newsletterForm && checkStepNo.includes(component.currentStep)) {
			const feedbackIncluded = component.newsletterForm.get('feedbackIncluded').value;
			if (feedbackIncluded && ((control.value && !control.value.trim()) || !control.value)) {
				return { required: true };
			}
		}
		return null;
	};
}

export function validateTheSame(
	checkStepNo: number[],
	component: any,
	compare: {
		from: string;
		to: string;
	}
): ValidatorFn {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		if (component.newsletterForm && checkStepNo.includes(component.currentStep)) {
			const compareCtrl = component.newsletterForm.get(compare.from) as FormControl;
			const compareArr = compareCtrl.value.split(',');
			const compareWithCtrl = component.newsletterForm.get(compare.to) as FormControl;
			const compareWithArr = compareWithCtrl.value.split(',');
			if (
				compareArr.filter((email) => {
					if (compareWithArr.includes(email)) {
						return email;
					}
				}).length
			) {
				compareCtrl.setErrors({ ...compareCtrl.errors, emailDuplicate: true });
				compareWithCtrl.setErrors({ ...compareWithCtrl.errors, emailDuplicate: true });
			} else {
				compareCtrl.hasError('invalidEmails')
					? compareCtrl.setErrors({ invalidEmails: true })
					: compareCtrl.setErrors(null);
				compareWithCtrl.hasError('invalidEmails')
					? compareWithCtrl.setErrors({ invalidEmails: true })
					: compareWithCtrl.setErrors(null);
			}
		}

		return null;
	};
}

export function validateStepBoolean(checkStepNo: number[], component: any): ValidatorFn {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		if (checkStepNo.includes(component.currentStep)) {
			if (typeof control.value !== 'boolean') {
				return { required: true };
			}
		}
		return null;
	};
}

export function validateSectionName(checkStepNo: number[], component: any): ValidatorFn {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		if (checkStepNo.includes(component.currentStep)) {
			const templateId = component.newsletterForm.get('templateId').value || component.templateId;
			if (!control.value.trim() && templateId === 'NewsletterTemplateWithSections') {
				return { required: true };
			}
		}
		return null;
	};
}

export function ValidateDependentRecipientType(checkStepNo: number[], component: any): ValidatorFn {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		if (checkStepNo.includes(component.currentStep)) {
			const recipientType = component.newsletterForm.get('recipientType').value;
			if (
				recipientType === 1 &&
				component.hasOwnProperty('hasRoles') &&
				!component.hasRoles.length &&
				component.usersCount > 200
			) {
				return { recipientType: true };
			}
			if (
				recipientType === 0 &&
				component.newsletterForm.get('to').value === '' &&
				component.newsletterForm.get('toGroups').value === ''
			) {
				return { recipientType: true };
			}
		}
		return null;
	};
}

export function ValidateDependentRecipientTypeInline(
	maxRecipients: number,
	count: number,
	roles: Array<RoleNames>
): ValidatorFn {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		if (!!control) {
			switch (control.value.recipientType) {
				case 0: {
					if (!control.value.to && !control.value.toGroups) {
						return { recipient: true };
					}
					break;
				}
				case 1: {
					if ((roles.length === 0 && count > maxRecipients) || count === 0) {
						return { recipient: true };
					}
					break;
				}
			}
		}
		return null;
	};
}

export function commaSepEmail(checkStepNo?: number[] | any, component?: any): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		if ((component && checkStepNo.includes(component.currentStep)) || !!checkStepNo) {
			if (control.value) {
				const emails: string[] = control.value.split(',').map((e) => e.trim());
				const forbidden = emails.some((email) => {
					if (Validators.email(new UntypedFormControl(email))) {
						return true;
					}
				});
				return forbidden ? { email: true } : null;
			}
		}
		return null;
	};
}

export function commaSepEmailInline(control: AbstractControl) {
	if (control.value) {
		const emails: string[] = control.value.split(',').map((e) => e.trim());
		const forbidden = emails.some((email) => {
			if (Validators.email(new UntypedFormControl(email))) {
				return true;
			}
		});
		return forbidden ? { email: true } : null;
	}
}

export function validateMultipleEmails(control: AbstractControl) {
	if (control.value) {
		const emails: string[] = control.value.split(',').map((e) => e.trim());
		const invalidEmails = emails.filter((email) => Validators.email(new UntypedFormControl(email)) && email.length);
		const validEmails = emails.filter((email) => !Validators.email(new UntypedFormControl(email)) && email.length);
		return validEmails.length < 1
			? {
					email: true,
					invalidEmails,
				}
			: {
					email: false,
					invalidEmails,
					validEmails,
				};
	}
}

export function validatePreventSelf(userEmail: string) {
	return (control: AbstractControl) => {
		if (control.value) {
			const emails: string[] = control.value.split(',').map((e) => e.trim());
			return {
				preventSelf: emails.includes(userEmail),
			};
		}
	};
}

export function validateTime(c: AbstractControl) {
	return c.value && c.value.hour !== null && c.value.minute !== null
		? null
		: {
				protocol: {
					valid: false,
				},
			};
}

export function validateIsTimeAfter(time: string) {
	return (control: AbstractControl) => {
		if (control.value && time) {
			const controlHour = +control.value.split(':')[0];
			const controlMinute = +control.value.split(':')[1];
			const compareHour = +time.split(':')[0];
			const compareMinute = +time.split(':')[1];
			return controlHour < compareHour || (controlHour === compareHour && controlMinute <= compareMinute)
				? {
						isBefore: true,
					}
				: null;
		}
	};
}

export function validateIsTimeBefore(time: string) {
	return (control: AbstractControl) => {
		if (control.value && time) {
			const controlHour = +control.value.split(':')[0];
			const controlMinute = +control.value.split(':')[1];
			const compareHour = +time.split(':')[0];
			const compareMinute = +time.split(':')[1];
			return controlHour > compareHour || (controlHour === compareHour && controlMinute >= compareMinute)
				? {
						isAfter: true,
					}
				: null;
		}
	};
}

export function notEmptyList(control: AbstractControl) {
	return control.value && control.value.length
		? null
		: {
				notEmptyList: {
					valid: false,
				},
			};
}

export class CustomAsyncValidators {
	static genericAccountUsernameIsUnique(service: GenericAccountsService): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors> => {
			console.log(control.status, control.valid);
			return service.getIsUsernameUnique(control.value).pipe(
				map((isUnique) => {
					if (!isUnique) {
						control.markAsTouched();
					}
					return isUnique ? null : { notUnique: true };
				})
			);
		};
	}

	static genericAccountEmailIsUnique(service: GenericAccountsService): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors> => {
			return service.getIsEmailUnique(control.value).pipe(
				map((isUnique) => {
					if (!isUnique) {
						control.markAsTouched();
					}
					return isUnique ? null : { notUnique: true };
				})
			);
		};
	}

	static validateIsUnique(isUnique: Observable<boolean>): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors> => {
			let uniqueOBS = from(isUnique);
			return uniqueOBS.pipe(
				distinctUntilChanged(),
				debounceTime(1),
				take(1),
				map((unique: boolean) => {
					console.log(control.value, unique)
					if (!unique) {
						control.markAsTouched();
					}
					return unique ? null : { notUnique: true };
				})
			);
		};
	}
}

export class CustomValidators {
	static date(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value) {
				return null;
			}
			const parsed = Date.parse(control.value);
			return Number.isNaN(parsed) ? { date: { value: control.value } } : null;
		};
	}

	static isDuplicate(duplicates: Array<{ id: string; groupProp: string }>, id: string = null): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value) {
				return null;
			}
			return duplicates.findIndex((d) => d.id !== id && d.groupProp.toLowerCase() === control.value.toLowerCase()) > -1
				? { duplicate: true }
				: null;
		};
	}

	static url(alwaysAddProtocol: boolean): ValidatorFn {
		return (control: AbstractControl) => {
			if (!control.value) {
				return null;
			}
			let valid = false;
			let urlToCheck = control.value;
			if (alwaysAddProtocol && !urlToCheck.startsWith('https://') && !urlToCheck.startsWith('http://')) {
				urlToCheck = 'https://' + urlToCheck;
			}
			try {
				new URL(urlToCheck);
				valid = urlToCheck.startsWith('https://') || urlToCheck.startsWith('http://');
			} catch (_) {
				return null;
			}
			return !valid ? { url: { value: urlToCheck } } : null;
		};
	}

	static notEmptyList(control: AbstractControl) {
		return control.value && control.value.length
			? null
			: {
					notEmptyList: {
						valid: false,
					},
				};
	}

	static maxSumLengthOfItems(max: number, separator: string = ',') {
		return (c: AbstractControl): { [key: string]: any } => {
			if (c.value.join(separator).length <= max) return null;
			return { maxSumLengthOfItems: { valid: false } };
		};
	}

	static validateTemplatePermissions(control: FormControl) {
		if (control.value.owners.length === 0) {
			return { atLeastOneOwnerRequired: { valid: false } };
		} else {
			return null;
		}
	}

	static validateNewsPermissions(control: FormControl) {
		if (control.value.owners.length === 0) {
			return { atLeastOneOwnerRequired: { valid: false } };
		} else {
			return null;
		}
	}

	static validateChannelPermissions(control: FormControl) {
		if (control.value.owners.length === 0) {
			return { atLeastOneOwnerRequired: { valid: false } };
		} else {
			return null;
		}
	}

	static maxLengthList(max: number) {
		return (c: AbstractControl): { [key: string]: any } => {
			if (c.value.length <= max) return null;
			return { maxLengthList: { valid: false } };
		};
	}

	static minLengthList(min: number) {
		return (c: AbstractControl): { [key: string]: any } => {
			if (c.value.length >= min) return null;
			return { minLengthList: { valid: false } };
		};
	}

	static isMasterControlValid(control: AbstractControl) {
		return (c: AbstractControl): { [key: string]: any } => {
			if (control.valid) return null;
			return { invalid: true };
		};
	}

	static validateWebsiteLink(control: FormGroup) {
		const websiteLinkName = control.controls.websiteLinkName;
		const websiteLinkUrl = control.controls.websiteLinkUrl;
		if (
			(!websiteLinkName.value || websiteLinkName.value.length === 0) &&
			(!websiteLinkUrl.value || websiteLinkUrl.value.length === 0)
		) {
			websiteLinkName.setErrors(null);
			websiteLinkUrl.setErrors(null);
			return null;
		}
		if (websiteLinkUrl.value?.length > 0 && (!websiteLinkName.value || websiteLinkName.value.length === 0)) {
			websiteLinkName.setErrors({ missingLinkName: { valid: false } });
			websiteLinkUrl.setErrors(null);
			return null;
		}
		if (websiteLinkName.value?.length > 0 && (!websiteLinkUrl.value || websiteLinkUrl.value.length === 0)) {
			websiteLinkName.setErrors(null);
			websiteLinkUrl.setErrors({ missingLinkUrl: { valid: false } });
			return null;
		}
	}

	static validateAtLeastOneRequired(rControls: Array<string>): ValidatorFn {
		return (controls: FormGroup) => {
			const selectedValuesCount = rControls.reduce(
				(acc, curr, idx) => (acc = acc + controls.get(curr).value.length),
				0
			);
			if (selectedValuesCount > 0) {
				rControls.map((formControlName) => controls.get(formControlName).setErrors(null));
			} else {
				rControls.map((formControlName) => controls.get(formControlName).setErrors({ atLeastOneRequired: true }));
				const isAnyTouched = rControls.reduce((acc, curr, idx) => (acc = acc || controls.get(curr).touched), false);
				if (isAnyTouched) {
					rControls.map((formControlName) => controls.get(formControlName).markAsTouched());
				}
			}
			return null;
		};
	}

	static validateAtLeastOneMatchRequired(rControls, dict): ValidatorFn {
		return (controls: FormGroup) => {
			/*
			 * @description One of the controls must include at least one of allowed values (dict)
			 * or all roots of each controls should be selected
			 */
			if (
				rControls.find(
					(k) =>
						get(controls.value, k) &&
						Array.isArray(get(controls.value, k)) &&
						get(controls.value, k).find((val) => dict[k].includes(val))
				)
			) {
				rControls.map((formControlName) => controls.get(formControlName).setErrors(null));
			} else {
				rControls.map((formControlName) => controls.get(formControlName).setErrors({ atLeastOneMatchRequired: true }));
			}
			return null;
		};
	}

	static anyControlNotEmpty(control: FormGroup) {
		const controls = Object.keys(control.controls);
		return controls.map((key) => control.get(key).value).some((v) => v.length > 0) ? null : { invalid: true };
	}

	static keepManualErrors() {
		return (c: AbstractControl): { [key: string]: any } => {
			const manualErrors = !!c.errors ? Object.keys(c.errors).filter(key => key.startsWith('manual')) : [];
			const errors = manualErrors.reduce((acc, curr) => ({...acc, [curr]: true}), {})
			return errors;
		};
	}
}

export function isValidUrl(c: AbstractControl) {
	let validUrl = true;
	if (!c.value) return null;

	try {
		new URL(c.value);
	} catch {
		validUrl = false;
	}

	return validUrl ? null : { invalidUrl: true };
}

export function notEmptyListCheck(list: Array<any>) {
	return (control: AbstractControl) => {
		return list && list.length
			? null
			: {
					notEmptyList: {
						valid: false,
					},
				};
	};
}

export function validateTwoDates(
	controlKeys: {
		startDate: string;
		startTime: string;
		endDate: string;
		endTime: string;
		timeZone?: string;
	},
	fillFormStartedAt: Date,
	originalStartDate: Date
) {
	return (group: FormGroup) => {
		const timeZoneControl = group.get(controlKeys.timeZone);
		const timeZone = timeZoneControl?.value;
		const now = timeZone ? utcToZonedTime(fillFormStartedAt, timeZone) : fillFormStartedAt;
		const startDateControl = group.get(controlKeys.startDate) as FormControl;
		const startTimeControl = group.get(controlKeys.startTime) as FormControl;
		const endDateControl = group.get(controlKeys.endDate) as FormControl;
		const endTimeControl = group.get(controlKeys.endTime) as FormControl;
		if (!startDateControl.value || !endDateControl.value || !startTimeControl.value || !endTimeControl.value) {
			return null;
		}
		let orgStartDate = originalStartDate ? new Date(originalStartDate) : now;
		let startDate = new Date(startDateControl.value);
		let endDate = new Date(endDateControl.value);
		const startTime = startTimeControl.value.split(':');
		const endTime = endTimeControl.value.split(':');
		startDate = new Date(startDate.setHours(startTime[0], startTime[1]));
		endDate = new Date(endDate.setHours(endTime[0], endTime[1]));
		const endBeforeStart = endDate < startDate;
		const startDateFromPast = startDate < now && orgStartDate.getTime() !== startDate.getTime();
		const endDateFromPast = endDate < now;

		if (endBeforeStart || startDateFromPast || endDateFromPast) {
			startDateControl.setErrors({ endBeforeStart, startDateFromPast });
			startTimeControl.setErrors({ endBeforeStart, startDateFromPast });
			endDateControl.setErrors({ endBeforeStart, endDateFromPast });
			endTimeControl.setErrors({ endBeforeStart, endDateFromPast });
			startDateControl.markAsTouched();
			startTimeControl.markAsTouched();
			endDateControl.markAsTouched();
			endTimeControl.markAsTouched();
		}
		return null;
	};
}

export function validateAllNeededSelected(options: Array<any>) {
	return (group: FormGroup) => {
		const values = Object.values(group.value);
		return !options.filter((item) => !values.includes(item)).length ? null : { notAllSelected: true };
	};
}

export function returnDateAfterCheckingTimeZone(date, timeZone) {
	return utcToZonedTime(date, timeZone);
}
