
import { Editor, Context, DataApiMixin, ElementApiMixin, attachToForm, secureSourceElement } from 'ckeditor5/src/core';
import { getDataFromElement, CKEditorError } from 'ckeditor5/src/utils';
import { ContextWatchdog, EditorWatchdog } from 'ckeditor5/src/watchdog';
import InlineEditorUI from './inlineeditorui';
import InlineEditorUIView from './inlineeditoruiview';
import { isElement as _isElement } from 'lodash-es';
import { BalloonPanelView, EditorUIView, InlineEditableUIView, ToolbarView } from 'ckeditor5/src/ui';

export default class InlineEditor extends DataApiMixin(ElementApiMixin(Editor)) {
	
	constructor( sourceElementOrData, config = {} ) {
		// If both `config.initialData` and initial data parameter in `create()` are set, then throw.
        if (!isElement(sourceElementOrData) && config.initialData !== undefined) {
            // Documented in core/editor/editorconfig.jsdoc.
            // eslint-disable-next-line ckeditor5-rules/ckeditor-error-message
            throw new CKEditorError('editor-create-initial-data', null);
        }
		super( config );
		if (this.config.get('initialData') === undefined) {
            this.config.set('initialData', getInitialData(sourceElementOrData));
        }
		this.model.document.createRoot();
		if ( isElement( sourceElementOrData ) ) {
			this.sourceElement = sourceElementOrData;
			secureSourceElement( this, sourceElementOrData );
		}
		const shouldToolbarGroupWhenFull = !this.config.get( 'toolbar.shouldNotGroupWhenFull' );
		const view = new InlineEditorUIView( this.locale, this.editing.view, this.sourceElement, {
			shouldToolbarGroupWhenFull
		} );
		this.ui = new InlineEditorUI( this, view );
		attachToForm( this );
	}

	destroy() {
		// Cache the data, then destroy.
		// It's safe to assume that the model->view conversion will not work after super.destroy().
		const data = this.getData();
		this.ui.destroy();
		return super.destroy()
			.then( () => {
				if ( this.sourceElement ) {
					this.updateSourceElement(data)
				}
			} );
	}

	static create( sourceElementOrData, config = {} ) {
		return new Promise( resolve => {

			if ( isElement(sourceElementOrData) && sourceElementOrData.tagName === 'TEXTAREA' ) {
				// Documented in core/editor/editor.js
				// eslint-disable-next-line ckeditor5-rules/ckeditor-error-message
				throw new CKEditorError( 'editor-wrong-element', null );
			}
			const editor = new this( sourceElementOrData, config );

            resolve(editor.initPlugins()
                .then(() => editor.ui.init())
                .then(() => editor.data.init(editor.config.get('initialData')))
                .then(() => editor.fire('ready'))
                .then(() => editor));
		} );
	}
}

InlineEditor.ContextWatchdog = ContextWatchdog;
function getInitialData(sourceElementOrData) {
    return isElement(sourceElementOrData) ? getDataFromElement(sourceElementOrData) : sourceElementOrData;
}
function isElement(value) {
    return _isElement(value);
}
