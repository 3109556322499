<div class="row">
    <div class="col-auto lmy-3">
        <rh-back-button defaultLabel="RocheHome" defaultRoute="/"></rh-back-button>
    </div>
</div>
<div class="row">
    <div class="col-8 offset-2">
        <rh-view-bar
                [hideAddNews]="true"
        >
            <span title>My settings</span>
        </rh-view-bar>
    </div>
</div>
<div class="row">
    <div class="col-8 offset-2">
        <rds-tabs #tabs (click)="tabsClicked(tabs)">
            <rds-tab [active]="activeTab === 'global'">
                <ng-template rds-tab-label>
                    <span>Global Settings</span>
                </ng-template>
            </rds-tab>
            <rds-tab *atrIfViewMode="['DESKTOP']" [active]="activeTab === 'notifications'">
                <ng-template rds-tab-label>
                    <span>Notifications Settings</span>
                </ng-template>
            </rds-tab>
        </rds-tabs>
    </div>
    <router-outlet></router-outlet>
</div>
