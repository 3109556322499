import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'insertLinks'
})
export class InsertLinksPipe implements PipeTransform {
	transform(value: string) {
		const regex = /(([\w+]+:\/\/)?([\w\d-]+\.)*[\w-]+[.:]\w+([/?=&#.]?[\w-]+)*\/?)/gm;
		return value.replace(regex, (replace) => {
			const startsWithProtocol = replace.match(/([\w+]+:\/\/)/gm);
			return `<a target="_blank" href="${!startsWithProtocol ? `https://${replace}` : replace}">${replace}</a>`;
		});
	}
}
