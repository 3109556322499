import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { map, catchError, mergeMap, switchMap, first } from 'rxjs/operators';
import { of } from 'rxjs';
import { TaxonomyService } from '@app/core/services/taxonomy.service';

import * as fromActions from './departments-flat.actions';
import * as fromReducer from './departments-flat.reducer';
import * as fromSlectors from './departments-flat.selectors';

@Injectable()
export class DepartmentsFlatEffects {

  public loadDepartmentsFlat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadDepartmentsFlat),
      switchMap(() => this.store$.pipe(
        select(fromSlectors.selectEntities),
        first(entities => this.isEmpty(entities)),
      )),
      mergeMap(() => [
        fromActions.getDepartmentsFlatRequest()
      ])
    ), { dispatch: true }
  );

  public getDepartmentsFlatRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getDepartmentsFlatRequest),
      mergeMap(() =>
        this.taxonomyService.getDepartmentsFlat().pipe(
          map((departmentsFlat) => fromActions.getDepartmentsFlatSuccess({departmentsFlat})),
          catchError(({ message }) => of(fromActions.getDepartmentsFlatFailure({ error: message })))
        )
      )
    ), { dispatch: true }
  );

  public getDepartmentsFlatSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getDepartmentsFlatSuccess),
      mergeMap(({ departmentsFlat }) => [
        fromActions.departmentsFlatCollectionAddMany({ departmentsFlat }),
      ]),
    ), { dispatch: true }
  );

  private isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private taxonomyService: TaxonomyService,
  ) {}
}
