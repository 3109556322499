import { Pipe, PipeTransform } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromScreen from '@app/root-store/ui/screen';
import { ScreenModeEnum } from '@app/core/models/screen.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'isViewMode'
})
export class IsViewModePipe implements PipeTransform {
  transform(value: ScreenModeEnum | Array<ScreenModeEnum>): Observable<boolean> {
    return this.store$.pipe(
      select(fromScreen.selectScreenMode),
      map(mode => {
        if (value instanceof Array) {
          return value.some(v => v === mode) ? true : false;
        } else {
          return value === mode ? true : false;
        }
      })
    );
  }

  constructor(
    private store$: Store<fromScreen.State>) {
  }
}
