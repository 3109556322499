<div
  [class.template-card-image-header]="!!image"
  [class.template-card-text-header]="!image"
  #header (click)="showScroll()" [class.scrollable]="scrollable && !!image" (mouseleave)="hideScroll()">
<div class="preview bg-neutral-gradient-top-left" [class.no-image]="!image">
  <img *ngIf="image" [src]="image" alt="">
  <ng-container *ngIf="!image">
    <ng-content select="[heading]"></ng-content>
  </ng-container>
</div>
</div>
<div [class.p-5]="size === 'm'" [class.p-4]="size === 's'" class="template-card-body" [class.attention-needed]="attentionNeeded" [class.selected]="selected">
  <p
    [rdsTooltip]="title"
    [rdsTooltipShowDelay]="300"
    [class.ui-heading-4--l]="size === 'm'"
    [class.ui-label-m-bold]="size === 's'"
    class="text-ellipsis"
    >{{title}}</p>
  <p
    class="ui-label-s"
  >
  <span *ngIf="description" [innerHTML]="description"></span>&nbsp;
  </p>
  <div 
    class="actions"
    [class.actions--m]="size ==='m'"
    [class.actions--s]="size ==='s'"
  >
    <ng-content></ng-content>
  </div>
</div>
