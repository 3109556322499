import {Component, ElementRef, Input, ViewChild} from '@angular/core';

@Component({
  selector: 'atr-hero-card-swiper-tablet',
  templateUrl: './hero-card-swiper-tablet.component.html',
  styleUrls: ['./hero-card-swiper-tablet.component.scss']
})
export class HeroCardSwiperTabletComponent {
  @Input() screenMode;
  @ViewChild('tile', { static: true}) tile!: ElementRef<HTMLElement>;
  @ViewChild('imageRef', { static: true}) imageRef!: ElementRef<HTMLElement>;
  @ViewChild('titleRef', { static: true}) titleRef!: ElementRef<HTMLElement>;
  @ViewChild('descriptionRef', { static: true}) descriptionRef!: ElementRef<HTMLElement>;
  @ViewChild('channelRef', { static: true}) channelRef!: ElementRef<HTMLElement>;



  get hasImage() {
    return this.imageRef.nativeElement.childNodes.length > 0;
  }
  get hasTitle() {
    return this.titleRef.nativeElement.childNodes.length > 0;
  }
  get hasDescription() {
    return this.descriptionRef.nativeElement.childNodes.length > 0;
  }
  get hasChannel() {
    return this.channelRef.nativeElement.childNodes.length > 0;
  }

  constructor() { }

}
