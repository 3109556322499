import {createAction, props} from '@ngrx/store';
import {ContentItem} from '@app/core/models/content-item.model';
import {Pagination} from '@app/core/models/pagination.model';

export const init = createAction(
  '[USER NEWS ARTICLES - INIT] Init Section with default values'
);

export const loadMoreItems = createAction(
  '[USER NEWS ARTICLES - LOAD MORE] Load more items click'
);

export const goToContent = createAction(
  '[USER NEWS ARTICLES - GO CONTENT] Navigate to Content LIST',
  props<{ path: string, queryParams }>()
);

export const goOutside = createAction(
  '[USER NEWS ARTICLES - REDIRECT OUT] Redirect outside',
  props<{ url: string }>()
);

export const getItemsSuccess = createAction(
  '[CONTENT API - GET SUCCESS] Get Content for LIST',
  props<{ value: any, contentItems: ContentItem[], pagination: Pagination }>()
);

export const getItemsError = createAction(
  '[CONTENT API - GET ERROR] Get Content for LIST',
  props<{ value: string, error: any }>()
);

export const addLike = createAction(
  '[USER NEWS ARTICLES] Add like',
  props<{ id: string, likeCount: number }>()
);

export const addLikeSuccess = createAction(
  '[USER NEWS ARTICLES] Add like success',
  props<{ id: string, likeCount: number }>()
);

export const addLikeFailure = createAction(
  '[USER NEWS ARTICLES] Add like failure',
  props<{ id: string, likeCount: number }>()
);

export const removeLike = createAction(
  '[USER NEWS ARTICLES] Remove like',
  props<{ id: string, likeCount: number }>()
);

export const removeLikeSuccess = createAction(
  '[USER NEWS ARTICLES] Remove like success',
  props<{ id: string, likeCount: number }>()
);

export const removeLikeFailure = createAction(
  '[USER NEWS ARTICLES] Remove like failure',
  props<{ id: string, likeCount: number }>()
);

export const addBookmark = createAction(
  '[USER NEWS ARTICLES] Add bookmark',
  props<{ id: string }>()
);

export const addBookmarkSuccess = createAction(
  '[USER NEWS ARTICLES] Add bookmark success',
  props<{ id: string }>()
);

export const addBookmarkFailure = createAction(
  '[USER NEWS ARTICLES] Add bookmark failure',
);

export const removeBookmark = createAction(
  '[USER NEWS ARTICLES] Remove bookmark',
  props<{ id: string }>()
);

export const removeBookmarkSuccess = createAction(
  '[USER NEWS ARTICLES] Remove bookmark success',
  props<{ id: string }>()
);

export const removeBookmarkFailure = createAction(
  '[USER NEWS ARTICLES] Remove bookmark failure',
);
export const setSubTypeAndValue = createAction(
  '[USER NEWS ARTICLES] Set subType and value',
  props<{ value: string }>()
);

export const setDataInfo = createAction(
  '[USER NEWS ARTICLES] Set data info',
  props<{ value: string, name: string, isMandatory?: boolean }>()
);
export const getItemsRequest = createAction(
  '[USER NEWS ARTICLES] Get data request',
  props<{ value: any, pageSize: number, pageIndex: number, language: string, sortType: number }>()
);

export const setSortType = createAction(
  '[USER NEWS ARTICLES] Set sort type',
  props<{ sortType: number }>()
)
export const clearStore = createAction(
  '[USER NEWS ARTICLES] Clear store on destroy'
)

export const openUnsubscribeConfirmDialog = createAction(
  '[USER NEWS ARTICLES] open unsubscribe confirm dialog',
  props<{id: string, name: string }>()
);