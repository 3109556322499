import { Injectable } from '@angular/core';
import { createEffect, Actions } from '@ngrx/effects';

import * as fromActions from './screen.actions';
import * as fromReducer from './screen.reducer';

import { map, filter } from 'rxjs/operators';
import { RDSBreakpointsMediaQuery } from '@app/core/models/screen.model';
import { BreakpointObserver } from '@angular/cdk/layout';
import { fromEvent } from 'rxjs';
import { Store } from '@ngrx/store';

@Injectable()
export class ScreenEffects {


  public resize$ = fromEvent(window, 'resize');

  private breakpointS$ = this.breakpointObserver.observe([RDSBreakpointsMediaQuery.S]);
  private breakpointM$ = this.breakpointObserver.observe([RDSBreakpointsMediaQuery.M]);
  private breakpointL$ = this.breakpointObserver.observe([RDSBreakpointsMediaQuery.L]);
  private breakpointXL$ = this.breakpointObserver.observe([RDSBreakpointsMediaQuery.XL]);
  private breakpointXXL$ = this.breakpointObserver.observe([RDSBreakpointsMediaQuery.XXL]);
  private desktopOnly$ = this.breakpointObserver.observe([RDSBreakpointsMediaQuery.DESKTOP_ONLY]);

  public setBreakpointS$ = createEffect(() =>
    this.breakpointS$.pipe(
      filter((value) => value.matches),
      map(() => fromActions.setRdsBreakpoint({ rdsBreakpoint: 's'}))
    ),
  );

  public setBreakpointM$ = createEffect(() =>
    this.breakpointM$.pipe(
      filter((value) => value.matches),
      map(() => fromActions.setRdsBreakpoint({ rdsBreakpoint: 'm'}))
    ),
  );

  public setBreakpointL$ = createEffect(() =>
    this.breakpointL$.pipe(
      filter((value) => value.matches),
      map(() => fromActions.setRdsBreakpoint({ rdsBreakpoint: 'l'}))
    ),
  );

  public setBreakpointXL$ = createEffect(() =>
    this.breakpointXL$.pipe(
      filter((value) => value.matches),
      map(() => fromActions.setRdsBreakpoint({ rdsBreakpoint: 'xl'}))
    ),
  );

  public setBreakpointXXL$ = createEffect(() =>
    this.breakpointXXL$.pipe(
      filter((value) => value.matches),
      map(() => fromActions.setRdsBreakpoint({ rdsBreakpoint: 'xxl'}))
    ),
  );

  public setDesktopOnly$ = createEffect(() =>
    this.desktopOnly$.pipe(
      map((breakpointState) => fromActions.setDesktopOnly({ canShow: breakpointState.matches}))
    ),
  );

  constructor(
    private actions$: Actions,
    private breakpointObserver: BreakpointObserver,
    private store$: Store<fromReducer.State>
  ) {}
}
