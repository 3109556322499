import { SimpleUser } from '@app/core/models/newsboard';
import { Template, TemplateRoles } from '@app/core/models/newsboard/template';
import { createAction, props } from '@ngrx/store';
import { FormStepStatus } from '../../../../shared/form-status-badge/form-status-badge.component';
export const initAddForm = createAction(
  '[TEMPLATE - FORM] Init add form',
  props<{user: SimpleUser, id?: number}>()
);

export const setFormChecked = createAction(
  '[NEWS - FORM] Set form checked'
);

export const initCreateFormFromTemplate = createAction(
  '[TEMPLATE - FORM] Init create form from template',
  props<{template: Template}>()
);

export const getTemplateDetailsRequest = createAction(
  '[TEMPLATE - FORM] Get template details request',
  props<{id: number}>()
);

export const getTemplateDetailsSuccess = createAction(
  '[TEMPLATE - FORM] Get template details success',
  props<{template: Partial<Template>, role: TemplateRoles}>()
);

export const getTemplateDetailsFailure = createAction(
  '[TEMPLATE - FORM] Get template details failure',
  props<{error: string}>()
);

export const setGeneralStepStatus = createAction(
  '[TEMPLATE - FORM] Set general step status',
  props<{status: FormStepStatus}>()
);

export const setGeneralFormStatus = createAction(
  '[TEMPLATE - FORM] Set general form status',
  props<{status: string}>()
);

export const setContentStepStatus = createAction(
  '[TEMPLATE - FORM] Set content step status',
  props<{status: FormStepStatus}>()
);

export const setContentFormStatus = createAction(
  '[TEMPLATE - FORM] Set content form status',
  props<{status: string}>()
);

export const setPermissionsStepStatus = createAction(
  '[TEMPLATE - FORM] Set permissions step status',
  props<{status: FormStepStatus}>()
);

export const setPermissionsFormStatus = createAction(
  '[TEMPLATE - FORM] Set permissions form status',
  props<{status: string}>()
);

export const setFormValue = createAction(
  '[TEMPLATE - FORM] Set form value',
  props<{form: Partial<Template>}>()
);

export const setInitialFormValue = createAction(
  '[TEMPLATE - FORM] Set initial form value',
  props<{form: Partial<Template>}>()
);

export const primaryLanguageChanged = createAction(
  '[TEMPLATE - FORM] Primary language changed',
  props<{lang: string}>()
);

export const updatePrimaryLanguage = createAction(
  '[TEMPLATE - FORM] Update primary language',
  props<{oldLang: string; newLang: string}>()
);

export const setFormTouched = createAction(
  '[TEMPLATE - FORM] Set form touched',
  props<{touched: boolean}>()
);

export const saveTemplateRequest = createAction(
  '[TEMPLATE - FORM] Save template request',
);

export const saveTemplateSuccess = createAction(
  '[TEMPLATE - FORM] Save template success',
);

export const saveTemplateFailure = createAction(
  '[TEMPLATE - FORM] Save template failure',
  props<{error: string}>()
);

export const updateTemplateRequest = createAction(
  '[TEMPLATE - FORM] Update template request',
  props<{id: number}>()
);

export const updateTemplateSuccess = createAction(
  '[TEMPLATE - FORM] Update template success',
);

export const updateTemplateFailure = createAction(
  '[TEMPLATE - FORM] Update template failure',
  props<{error: string}>()
);

export const clearAll = createAction(
  '[TEMPLATE - FORM] Clear form'
);

export const openLeavePageDialog = createAction(
  '[TEMPLATE - FORM] Open Leave Page dialog',
  props<{
    url: string
  }>()
);

export const openSaveTemplateDialog = createAction(
  '[TEMPLATE - FORM] Open Save template dialog',
);

export const closeForm = createAction(
  '[TEMPLATE] Close form'
);

export const openAddLanguageDialog = createAction(
  '[TEMPLATE] Open add language dialog',
);

export const openPreviewDialog = createAction(
  '[TEMPLATE] Open preview dialog'
);

export const addLanguageVersion = createAction(
  '[TEMPLATE] Add language version',
  props<{language: string}>()
);

export const removeLanguageVersion = createAction(
  '[TEMPLATE] Remove language version',
  props<{language: string}>()
);

export const loadSuperAdminsRequest = createAction(
  '[TEMPLATE - FORM] Load superadmins request',
  props<{currentUser: SimpleUser}>()
);

export const loadSuperAdminsSuccess = createAction(
  '[TEMPLATE - FORM] Load superadmins success',
  props<{superAdmins: Array<SimpleUser>}>()
);

export const loadSuperAdminsFailure = createAction(
  '[TEMPLATE - FORM] Load superadmins failure',
  props<{error: string}>()
);

export const imageUploadStarted = createAction(
  '[TEMPLATE - FORM] Image upload Started',
  props<{id: string}>()
);

export const imageUploadEnded = createAction(
  '[TEMPLATE - FORM] Image upload Ended',
  props<{id: string}>()
);

export const imageUploadAborted = createAction(
  '[TEMPLATE - FORM] Image upload Aborted',
  props<{id: string}>()
);

export const imageUploadFailed = createAction(
  '[TEMPLATE - FORM] Image upload Failed',
  props<{id: string}>()
);
