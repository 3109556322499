<ng-container *ngIf="alert">
	<div class="bg-neutral-gradient alert-wrapper mb-2">
		<div class="my-0 mx-auto py-9 alert-content">
			<div class=mb-8>
				<rds-badge size="m" color="red" type="minimal" label="ALERT"></rds-badge>
			</div>
			<p class="article-heading-3-bold mb-7" >{{alert.title.length > 0? alert.title : 'Alert Title'}}</p>
			<p class="ui-label-m-bold mb-8">{{alert.message.length > 0? alert.message : 'The alert message should be short and include only the most important information. '}} <a rds-link standalone [href]="alert.url" target="_blank">Read more</a></p>
			<button rds-secondary-button (click)="buttonCLick.emit(alert.id)" size="l">
				Mark as read and close
			</button>
			<p *ngIf="length > 1" class="mt-6">
				1 / {{length || 1}}
			</p>
		</div>

	</div>
</ng-container>

