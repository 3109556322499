import { createAction, props } from '@ngrx/store';

export const initMessaging = createAction(
  '[NOTIFICATIONS] Init messaging'
);

export const showNotification = createAction(
  '[NOTIFICATIONS] Show Notification',
  props<{
    notification: any
  }>()
);

export const showAlert = createAction(
  '[NOTIFICATIONS] Show Alert',
  props<{
    alert: any
  }>()
);

export const registerTokenSuccess = createAction(
  '[NOTIFICATIONS] Register token success'
);

export const registerTokenFailure = createAction(
  '[NOTIFICATIONS] Register token failure'
);

export const closeAlert = createAction(
  '[NOTIFICATIONS] Close Alert',
  props<{
    alert: any,
    isConfirmed: boolean
  }>()
);
