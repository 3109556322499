import { Component, Input } from '@angular/core';
import { FormStepStatus } from '../form-status-badge/form-status-badge.component';

@Component({
	selector: 'rh-form-step-content',
	templateUrl: './form-step-content.component.html',
	styleUrls: ['./form-step-content.component.scss'],
})
export class FormStepContentComponent {
	@Input() stepNo: number;
	@Input() stepName: string;
	@Input() stepStatus: FormStepStatus;
	@Input() showBottomDivider = true;

	constructor() {}
}
