import { TemplateRoles } from '@app/core/models/newsboard/template';
import { PeopleRolePickerModel } from './people-role-picker.component';
import { NewsRoles } from '@app/core/models/newsboard';

export class RolePickerUtils {
	static getTemplateRoles(role: TemplateRoles): PeopleRolePickerModel {
		return {
			owners: {
				label: 'Owner',
				description: 'Can edit, manage permission and delete the template',
				canEdit: role === 'Owner',
			},
			editors: {
				label: 'Editor',
				description: 'Can edit the template',
				canEdit: role === 'Owner' || role === 'Editor',
			},
			viewers: {
				label: 'Viewer',
				description: 'Can use the template',
				canEdit: role === 'Owner' || role === 'Editor',
			},
		};
	}

	static getNewsRoles(role: NewsRoles): PeopleRolePickerModel {
		return {
			owners: {
				label: 'Owner',
				description: 'Can edit, manage permission and delete the News',
				canEdit: role === 'Owner',
			},
			editors: {
				label: 'Editor',
				description: 'Can edit News',
				canEdit: role === 'Owner' || role === 'Editor',
			},
		};
	}

	static getChannelRoles(role): PeopleRolePickerModel {
		return {
			owners: {
				label: 'Owner',
				description: 'Can assign news to channel, edit/delete channel and manage channel permissions',
				canEdit: role === 'Admin' || role === 'Owner',
			},
			managers: {
				label: 'Manager',
				description: 'Can assign news to channel and edit channel',
				canEdit: role === 'Admin' || role === 'Owner' || role === 'Manager',
			},
			publishers: {
				label: 'Publisher',
				description: 'Can assign news to channel',
				canEdit: role === 'Admin' || role === 'Owner' || role === 'Manager',
			},
		};
	}

	static getCampaignRoles(role, roles): PeopleRolePickerModel {
		return {
			owners: {
				label: 'Owner',
				description: '',
				canEdit: role === 'Admin' || role === 'Owner',
			},
			// managers: {
			//   label: 'Manager',
			//   description: 'Can assign news to channel and edit channel',
			//   canEdit: role === 'Admin' || role === 'Owner' || role === 'Manager',
			// },
			// publishers: {
			//   label: 'Publisher',
			//   description: 'Can assign news to channel',
			//   canEdit: role === 'Admin' || role === 'Owner' || role === 'Manager',
			// }
		};
	}

	static getNewsletterRoles(role, roles): PeopleRolePickerModel {
		return {
			owners: {
				label: 'Owner',
				description: '',
				canEdit: role === 'Admin' || role === 'Owner',
			},
			// managers: {
			//   label: 'Manager',
			//   description: 'Can assign news to channel and edit channel',
			//   canEdit: role === 'Admin' || role === 'Owner' || role === 'Manager',
			// },
			// publishers: {
			//   label: 'Publisher',
			//   description: 'Can assign news to channel',
			//   canEdit: role === 'Admin' || role === 'Owner' || role === 'Manager',
			// }
		};
	}
}
