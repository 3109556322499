import {Component, OnInit, ViewChild} from '@angular/core';
import {RdsMenuComponent} from '@rds/angular-components';
import {debounceTime, distinctUntilChanged, Observable} from 'rxjs';
import * as fromUser from '@app/core/user/store';
import {RoleNames} from '@core/enums/roles.enum';
import {select, Store} from '@ngrx/store';
import * as fromTiles from '@app/dashboard/store/tiles';
import {MENU_ITEMS, MenuItem} from '@core/models/menu';

@Component({
  selector: 'rh-create-content-button',
  templateUrl: './create-content-button.component.html',
  styles: []
})
export class CreateContentButtonComponent implements OnInit {
  @ViewChild("menu", {static: true}) menu: RdsMenuComponent;

  rhRoles$: Observable<Array<RoleNames>> = this.store$.pipe(select(fromUser.selectRoles));
  menuItems: Array<MenuItem> = [];

  filterMenuItem(roles) {
    return MENU_ITEMS.filter(tile => (tile.roles.length === 0 || tile.roles.some(r => roles.includes(r))))
  }


  ngOnInit(): void {
    this.rhRoles$.pipe(
      distinctUntilChanged(),
      debounceTime(50)
    ).subscribe(roles => {
      let allRoles = [...roles];
      this.menuItems = this.filterMenuItem(allRoles);
    })
  }

  constructor(private store$: Store<fromTiles.State>,) {
  }
}
