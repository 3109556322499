<ng-container *ngIf="blockProperties.link?.length">
	<a
		[attr.ses:tags]="blockProperties.photoName | getSesTags: TagTypes.IMAGE"
		[href]="blockProperties.link"
		target="_blank"
	>
		<img
			class="email-block__image"
			style="display: block; width: 100%; border-radius: 4px; margin-bottom: 16px"
			[attr.data-photoname]="blockProperties.photoName"
			[src]="blockProperties.photoUrl"
		/>
	</a>
</ng-container>
<ng-container *ngIf="!blockProperties.link || blockProperties.link.length === 0">
	<img
		class="email-block__image"
		style="display: block; width: 100%; border-radius: 4px; margin-bottom: 16px"
		[src]="blockProperties.photoUrl"
		[attr.data-photoname]="blockProperties.photoName"
	/>
</ng-container>
