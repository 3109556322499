import { Component } from '@angular/core';

@Component({
  selector: 'rh-enable-commenting-dialog',
  templateUrl: './enable-commenting-dialog.component.html',
  styleUrls: ['./enable-commenting-dialog.component.scss']
})
export class EnableCommentingDialogComponent {

  constructor() {
   }

}
