import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApprovedByMeRequest, ContentApprovalsResponse, RejectedByMeRequest, WaitingForApprovalRequest } from '../models';
import { ApiService } from './api/api.service';
import { EndpointsService } from './endpoints/endpoints.service';
import { Sort } from '@rds/angular-components';
import { format } from 'date-fns';

@Injectable()
export class ContentApprovalsService {

  public getWaitingForApproval(
  {sort, filters, pageIndex, pageSize}: {
    sort: Sort,
    filters: { [key: string]: any },
    pageIndex: number,
    pageSize: number
  }
  ): Observable<ContentApprovalsResponse> {
    const url = this.endpoints.ENDPOINT.CONTENT_APPROVALS.WAITING_FOR__MY_APPROVAL;
    const body: WaitingForApprovalRequest = {
      order: `${sort.active} ${sort.direction}`,
      contentManager: filters.contentManager.map(u => u.identifier),
      contentOwner: filters.contentOwner.map(u => u.identifier),
      creationDate: filters.creationDate ? format(filters.creationDate,'yyy-MM-dd') : null,
      locations: filters.locations,
      departments: filters.departments,
      functions: filters.functions,
      isAttentionRequired: filters.isAttentionRequired,
      lastModifiedDate: filters.lastModifiedDate ? format(filters.lastModifiedDate,'yyy-MM-dd') : null,
      name: filters.search?.trim(),
      contentTypes: filters.contentTypes
    };
    return this.http
      .put(this.endpoints.addParams(url, {pageIndex, pageSize}), body)
      .pipe(
        map((res) => ({
          data: res.data,
          pagination: {
            isFirst: res.isFirst,
            isLast: res.isLast,
            pageCount: res.pageCount,
            pageIndex: res.pageIndex,
            pageSize: res.pageSize,
            totalCount: res.totalCount,
          }
        }))
      );
  }

  public getApprovedByMe(
    {sort, filters, pageIndex, pageSize}: {
      sort: Sort,
      filters: { [key: string]: any },
      pageIndex: number,
      pageSize: number
    }
  ): Observable<ContentApprovalsResponse> {
    const url = this.endpoints.ENDPOINT.CONTENT_APPROVALS.APPROVED_BY_ME;
    const body: ApprovedByMeRequest = {
      order: `${sort.active} ${sort.direction}`,
      contentManager: filters.contentManager.map(u => u.identifier),
      contentOwner: filters.contentOwner.map(u => u.identifier),
      approvedDate: filters.approvedDate ? format(filters.approvedDate,'yyy-MM-dd') : null,
      locations: filters.locations,
      departments: filters.departments,
      functions: filters.functions,
      lastModifiedDate: filters.lastModifiedDate ? format(filters.lastModifiedDate,'yyy-MM-dd') : null,
      name: filters.search?.trim(),
      contentTypes: filters.contentTypes
    };

    return this.http
      .put(this.endpoints.addParams(url, {pageIndex, pageSize}), body)
      .pipe(
        map((res) => ({
          data: res.data,
          pagination: {
            isFirst: res.isFirst,
            isLast: res.isLast,
            pageCount: res.pageCount,
            pageIndex: res.pageIndex,
            pageSize: res.pageSize,
            totalCount: res.totalCount,
          }
        }))
      );
  }

  public getRejectedByMe(
    {sort, filters, pageIndex, pageSize}: {
      sort: Sort,
      filters: { [key: string]: any },
      pageIndex: number,
      pageSize: number
    }
  ): Observable<ContentApprovalsResponse> {
    const url = this.endpoints.ENDPOINT.CONTENT_APPROVALS.REJECTED_BY_ME;
    const body: RejectedByMeRequest = {
      order: `${sort.active} ${sort.direction}`,
      contentManager: filters.contentManager.map(u => u.identifier),
      contentOwner: filters.contentOwner.map(u => u.identifier),
      rejectedDate: filters.rejectedDate ? format(filters.rejectedDate,'yyy-MM-dd') : null,
      locations: filters.locations,
      departments: filters.departments,
      functions: filters.functions,
      name: filters.search?.trim(),
      contentTypes: filters.contentTypes
    };

    return this.http
      .put(this.endpoints.addParams(url, {pageIndex, pageSize}), body)
      .pipe(
        map((res) => ({
          data: res.data,
          pagination: {
            isFirst: res.isFirst,
            isLast: res.isLast,
            pageCount: res.pageCount,
            pageIndex: res.pageIndex,
            pageSize: res.pageSize,
            totalCount: res.totalCount,
          }
        }))
      );
  }

  public getContentApprovalsSummary() {
    const url = this.endpoints.ENDPOINT.CONTENT_APPROVALS.CONTENT_APPROVALS_SUMMARY;

    return this.http
      .get(url)
      .pipe(
        catchError((fault) => throwError(`Cannot get count. ${fault} `))
      );
  }

  constructor(
    private readonly http: ApiService,
    private readonly endpoints: EndpointsService,
  ) {}

}
