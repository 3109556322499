
export const SORTABLE_LOADING = [
  'name.loading',
  'name-nolink.loading',
  'creationDate.loading',
  'title.loading',
  'title-nolink.loading',
  'channel.loading',
  'assignedBy.loading',
  'suggestedBy.loading',
  'published.loading',
  'approved.loading',
  'created.loading',
  'requested.loading',
  'archived.loading',
  'suggested.loading',
  'response.loading',
  'apiKey.identifier.loading',
  'apiKey.name.loading',
  'apiKey.email.loading',
  'apiKey.created.loading',
  'admin.identifier.loading',
  'admin.name.loading',
  'admin.role.loading',
  'admin.email.loading',
  'admin.created.loading',
  'catalog.name.loading',
  'catalog.approval-name.loading',
  'catalog.owner.loading',
  'catalog.manager.loading',
  'catalog.created.loading',
  'catalog.modified.loading',
  'catalog.approved.loading',
  'catalog.rejected.loading',
  'catalog.attention.loading',
  'set.quicklinks-name.loading',
  'set.channels-name.loading',
  'intranet-coordinators.login.loading',
  'intranet-coordinators.name.loading',
  'assign-role.login.loading',
  'assign-role.name.loading',
  'ticker.message.loading',
  'ticker.published.loading',
  'ticker.expired.loading',
  'generic-accounts.login.loading',
  'generic-accounts.name.loading',
  'generic-accounts.created.loading',
  'generic-accounts.synchronized.loading',
  'event.title.loading',
  'event.type.loading',
  'event.location.loading',
  'event.date.loading',
  'event.status.loading',
  'event.topEvent.loading',
  'newsletter-urls.linkname.loading',
  'newsletter-urls.numberofclicks.loading',
  'newsletter.title.loading',
  'newsletter.edited.loading',
  'newsletter.published.loading'
]