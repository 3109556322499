import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { commaSepEmail } from '@app/shared/form-controls/validators/validator.function';
import { Store, select } from '@ngrx/store';
import { RDS_DIALOG_DATA } from '@rds/angular-components';
import { SubSink } from 'subsink';
import * as fromSuggestions from '@app/root-store/suggestions';
import { Observable } from 'rxjs';

export interface SendTestDialogData {
  loggedUserEmail: string,
}

@Component({
  selector: 'rh-send-test-dialog',
  templateUrl: './send-test-dialog.component.html',
  styleUrls: ['./send-test-dialog.component.scss']
})
export class SendTestDialogComponent implements OnDestroy, OnInit {
  private subs = new SubSink();
  testRecipientsAutocomplete$: Observable<fromSuggestions.Autocomplete> = this.store$.pipe(select(fromSuggestions.selectAutocomplete('testRecipients')))

  form: FormGroup = new FormGroup({
    to: new FormControl('', [Validators.required, commaSepEmail(true)])
  });

  constructor(
    @Inject(RDS_DIALOG_DATA) public data: SendTestDialogData,
    private store$: Store<any>) {
    if (!!data) {
      this.form.controls.to.patchValue(data.loggedUserEmail);
    }
   }

  ngOnInit(): void {
    this.store$.dispatch(fromSuggestions.initSuggestion({suggestionType: 'user', prop: 'testRecipients'}));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getAutocompletes(prop, event) {
    this.store$.dispatch(fromSuggestions.loadSuggestion({suggestionType: 'user', prop, phrase: event}))
   }
}
