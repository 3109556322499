import { RhEventType } from '@app/events/models/event';
import { DateFilterDefinition, FilterDefinition, FilterTypeEnum, SEARCH_FILTER, SelectFilterDefinition } from '../filters-model';

export interface EventTableFilters {
    type?: SelectFilterDefinition;
    status?: SelectFilterDefinition;
    range?: DateFilterDefinition;
    search?: FilterDefinition;
  }

  export const EVENT_STATUSES = [
    { label: 'Draft', value: 0 },
    { label: 'Published', value: 1 },
  ];

  export const EVENT_TYPES = [
    { label: 'On-Site', value: RhEventType.ON_SITE },
    { label: 'Off-Site', value: RhEventType.OFF_SITE },
    { label: 'Virtual', value: RhEventType.VIRTUAL },
    { label: 'Hybrid', value: RhEventType.HYBRID  },
  ];

  export const INITIAL_EVENTS_FILTERS: EventTableFilters = {
    type: {
      type: FilterTypeEnum.SELECT,
      changable: true,
      value: [],
      label: 'Event type',
      multiple: true,
      options: EVENT_TYPES
    },
    status: {
      type: FilterTypeEnum.SELECT,
      changable: true,
      value: [],
      label: 'Status',
      multiple: true,
      options: EVENT_STATUSES
    },
    range: {
      type: FilterTypeEnum.DATE,
      label: 'Created',
      range: true,
      changable: true,
      value: { start: null, end: null }
    },
    ...SEARCH_FILTER
  }
