import {NewsletterSectionsModel} from '@app/newsletter/models/newsletter-sections';
export interface NewsletterValueModel {
  newsletterId?: number;
  templateId: string;
  title: string;
  languageId: number;
  descriptionNoteIncluded: true | false;
  descriptionNote: string;
  rocheLogoInHeader: true | false;
  disclaimerIncluded: true | false;
  titleIncluded: boolean;
  feedbackIncluded: boolean;
  bannerPhotoUrl: string;
  bannerPhotoName: string;
  bannerPhotoSize: number;
  contactEmailForFeedback: string;
  step: number;
  color: string;
  colorDark: string;
  colorLight: string;
  to: string;
  toGroups: string;
  recipientType: string | number;
  content: NewsletterSectionsModel;
  subject: string;
  statusId?: number;
  replayTo: string;
  replyToName: string;
  replyTo: string;
  fromName: string;
  senderName: string;
  from: string;
  previewText?: string;
  whenToSend: number;
  scheduleSentDate?: string;
  timeZone?: string;
  taxonomyRecipient: {
    locations: string[],
    departments: string[],
    functions: string[]
  };
  sentBy?: string;
  sentByName?: string;
  googleAnalyticsUrl?: string;
  imagePreviewUrl?: string;
  bannerPhotoIncluded?: boolean;
  isContributed?: boolean;
}

export const EMPTY_NEWSLETTER_WITH_SECTIONS: Partial<NewsletterValueModel> = {
  templateId: 'NewsletterTemplateWithSections',
  title: 'Your newsletter title would appear right here',
  rocheLogoInHeader: true,
  bannerPhotoUrl: '/assets/images/newsletter-mock/banner.jpg',
  content: {
    sections: [
      {
        sectionName: 'TITLE OF THE FIRST SECTION',
        isAutomatic: false,
        newsCount: 2,
        news: [
          {
            newsId: ['1'],
            channelId: ['1'],
            imageUrl: '/assets/images/newsletter-mock/news_1.png',
            linkUrl: '',
            title: 'The title of the news - This will automatically be converted into a link to the Roche Home news',
            description: 'The abstract of the news will appear here',
          },
          {
            newsId: ['2'],
            channelId: ['2'],
            imageUrl: '/assets/images/newsletter-mock/news_2.jpg',
            linkUrl: '',
            title: 'The title of the news - This will automatically be converted into a link to the Roche Home news',
            description: 'The abstract of the news will appear here',
          }
        ]
      },
      {
        sectionName: 'TITLE OF THE SECOND SECTION',
        isAutomatic: false,
        newsCount: 2,
        news: [
          {
            newsId: ['1'],
            channelId: ['1'],
            imageUrl: '/assets/images/newsletter-mock/news_3.png',
            linkUrl: '',
            title: 'The title of the news - This will automatically be converted into a link to the Roche Home news',
            description: 'The abstract of the news will appear here',
          },
          {
            newsId: ['2'],
            channelId: ['2'],
            imageUrl: '/assets/images/newsletter-mock/news_4.jpg',
            linkUrl: '',
            title: 'The title of the news - This will automatically be converted into a link to the Roche Home news',
            description: 'The abstract of the news will appear here',
          }
        ]
      }
    ]
  },
  contactEmailForFeedback: 'john@smith.com',
  fromName: 'John Smith',
  disclaimerIncluded: false,
  bannerPhotoIncluded: true,
  titleIncluded: true,
  feedbackIncluded: true,
};

export const EMPTY_NEWSLETTER_WITHOUT_SECTIONS: Partial<NewsletterValueModel> = {
  templateId: 'NewsletterTemplateWithoutSections',
  title: 'Your newsletter title would appear right here',
  rocheLogoInHeader: true,
  bannerPhotoUrl: '/assets/images/newsletter-mock/banner.jpg',
  content: {
    sections: [
      {
        sectionName: '',
        isAutomatic: false,
        newsCount: 2,
        news: [
          {
            newsId: ['1'],
            channelId: ['1'],
            imageUrl: '/assets/images/newsletter-mock/news_1.png',
            linkUrl: '',
            title: 'The title of the news - This will automatically be converted into a link to the Roche Home news',
            description: 'The abstract of the news will appear here',
          },
          {
            newsId: ['2'],
            channelId: ['2'],
            imageUrl: '/assets/images/newsletter-mock/news_2.jpg',
            linkUrl: '',
            title: 'The title of the news - This will automatically be converted into a link to the Roche Home news',
            description: 'The abstract of the news will appear here',
          },
          {
            newsId: ['3'],
            channelId: ['3'],
            imageUrl: '/assets/images/newsletter-mock/news_3.png',
            linkUrl: '',
            title: 'The title of the news - This will automatically be converted into a link to the Roche Home news',
            description: 'The abstract of the news will appear here',
          },
          {
            newsId: ['4'],
            channelId: ['4'],
            imageUrl: '/assets/images/newsletter-mock/news_4.jpg',
            linkUrl: '',
            title: 'The title of the news - This will automatically be converted into a link to the Roche Home news',
            description: 'The abstract of the news will appear here',
          }
        ]
      }
    ]
  },
  contactEmailForFeedback: 'john@smith.com',
  fromName: 'John Smith',
  disclaimerIncluded: false,
  bannerPhotoIncluded: true,
  titleIncluded: true,
  feedbackIncluded: true
};
