// import { createAction, props } from '@ngrx/store';

import { Pagination } from '@app/core/models';
import { Channel } from '@app/core/models/newsboard';
import { ChannelTableFilters } from '@app/shared/filters/filters-model';
import { createAction, props } from '@ngrx/store';
import { SortDirection } from '@rds/angular-components';

export const initMyChannels = createAction(
  '[CHANNEL - MY TABLE] Initialize Channels'
);

export const initAllChannels = createAction(
  '[CHANNEL - ALL TABLE] Initialize Channels'
);

export const clearAll = createAction(
  '[CHANNEL] Clear all'
);

export const getMyChannelsRequest = createAction(
  '[CHANNEL - MY TABLE] Get channels Request',
  props<{
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
    filters: ChannelTableFilters,
  }>()
);

export const getMyChannelsSuccess = createAction(
  '[CHANNEL - MY TABLE] Get channels Success',
  props<{ data: Array<Partial<Channel>>, pagination: Pagination }>()
);

export const getMyChannelsFailure = createAction(
  '[CHANNEL - MY TABLE] Get channels Failure',
  props<{ error: any }>()
);


export const deleteMyChannelsRequest = createAction(
  '[CHANNEL - MY TABLE] Delete channels Request',
  props<{ids: Array<number>}>()
);

export const deleteMyChannelsSuccess = createAction(
  '[CHANNEL - MY TABLE] Delete channels Success',
  props<{count: number}>()
);

export const deleteMyChannelsFailure = createAction(
  '[CHANNEL - MY TABLE] Delete channels Failure',
  props<{ error: any }>()
);

export const changeMyChannelsFilters = createAction(
  '[CHANNEL - MY TABLE] Change channels filters',
  props<{ filters: ChannelTableFilters }>()
);

export const changeMyChannelsSort = createAction(
  '[CHANNEL - MY TABLE] Change channels sort',
  props<{ active: string, direction: SortDirection }>()
);

export const changeMyChannelsPagination = createAction(
  '[CHANNEL - MY TABLE] Change channels pagination',
  props<{ pageIndex: number, pageSize: number }>()
);


export const getAllChannelsRequest = createAction(
  '[CHANNEL - ALL TABLE] Get channels Request',
  props<{
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
    filters: ChannelTableFilters
  }>()
);

export const getAllChannelsSuccess = createAction(
  '[CHANNEL - ALL TABLE] Get channels Success',
  props<{ data: Array<Partial<Channel>>, pagination: Pagination }>()
);

export const getAllChannelsFailure = createAction(
  '[CHANNEL - ALL TABLE] Get channels Failure',
  props<{ error: any }>()
);


export const deleteAllChannelsRequest = createAction(
  '[CHANNEL - ALL TABLE] Delete channels Request',
  props<{ids: Array<number>}>()
);

export const deleteAllChannelsSuccess = createAction(
  '[CHANNEL - ALL TABLE] Delete channels Success',
  props<{count: number}>()
);

export const deleteAllChannelsFailure = createAction(
  '[CHANNEL - ALL TABLE] Delete channels Failure',
  props<{ error: any }>()
);

export const changeAllChannelsFilters = createAction(
  '[CHANNEL - ALL TABLE] Change channels filters',
  props<{ filters: ChannelTableFilters }>()
);

export const changeAllChannelsSort = createAction(
  '[CHANNEL - ALL TABLE] Change channels sort',
  props<{ active: string, direction: SortDirection }>()
);

export const changeAllChannelsPagination = createAction(
  '[CHANNEL - ALL TABLE] Change channels pagination',
  props<{ pageIndex: number, pageSize: number }>()
);