<div *ngIf="width > 0">
  <content-loader 
    [baseUrl]="componentsLoaderService.baseUrl"
    [backgroundColor]="'#EAE8E5'"
    [foregroundColor]="'#DBD6D1'"
    [viewBox]="'0 0 '+ width +' 134'"
    [speed]="3"
    [interval]="0.75"
    [gradientRatio]="2.4">
        <svg:rect x="24" y="24" rx="0" width="153" height="86" />
        <svg:rect x="201" y="28" rx="0" width="442" height="16" />
        <svg:rect x="201" y="58" rx="0" width="442" height="16" />
        <svg:rect x="201" y="94" rx="0" width="85" height="16" />
        <svg:rect [attr.x]="width - 45 - 16" y="94" rx="0" width="45" height="16" />
    </content-loader>
  </div>