import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import * as fromAuth from '@core/auth/store';
import {environment} from '@env/environment';


export function logError(request: HttpRequest<any>, response: any) {
  if (localStorage) {
    const itemsToSlice = 50;
    const newError = {
      body: request?.body,
      urlWithParams: request?.urlWithParams || request?.url,
      method: request?.method,
      status: response?.status,
      message: response?.message,
      date: new Date().getTime()
    }

    let stringObject = localStorage.getItem('errors') || '{}';
    let errorsArray = JSON.parse(stringObject) || [];
    errorsArray.unshift(newError);
    if (errorsArray.length > itemsToSlice) {
      errorsArray = errorsArray.slice(0, itemsToSlice)
    }
    localStorage.setItem('errors', JSON.stringify(errorsArray));
  }
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  isRefreshing = false;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError((response: any) => {
        if (environment && environment.dev && localStorage) {
          logError(request, response)
        }
        if (response instanceof HttpErrorResponse && response.status === 401 && !response.url.includes('auth/api/token')) {
          console.log('Unauthorized!')
          // this.store$.dispatch(fromAuth.clearAndLoginAgain());
        }

        if (response instanceof HttpErrorResponse && response.status === 400) {
          console.log('Bad request!!')
          // this.store$.dispatch(fromRoute.go({ path: '403', queryParams: {}}));
        }

        if (response instanceof HttpErrorResponse && response.status === 404) {
          console.log('Not found!!')
          // this.store$.dispatch(fromRoute.go({ path: '403', queryParams: {}}));
        }

        if (response instanceof HttpErrorResponse && response.status === 403) {
          console.log('Forbidden access!!')
          // this.store$.dispatch(fromRoute.go({ path: '403', queryParams: {}}));
        }

        if (response instanceof HttpErrorResponse && response.status === 500) {
          console.log('Something went wrong!!')
          // this.store$.dispatch(fromRoute.go({ path: '403', queryParams: {}}));
        }

        return throwError(response);
      })
    );
  }

  constructor(private store$: Store<fromAuth.State>) {
  }

}
