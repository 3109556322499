import { Language } from '@app/core/models/language.model';
import { createAction, props } from '@ngrx/store';

export const initForm = createAction(
  '[GLOBAL SETTINGS - FORM] Init form',
);

export const setInitialValue = createAction(
  '[GLOBAL SETTINGS - FORM] Set initial form value',
  props<{form: { newsLanguageId: number}}>()
);

export const setFormValue = createAction(
  '[GLOBAL SETTINGS - FORM] Set form value',
  props<{form: { newsLanguageId: number}}>()
);

export const saveNewsLanguageRequest = createAction(
  '[GLOBAL SETTINGS - FORM] Save news language Request'
);

export const saveNewsLanguageSuccess = createAction(
  '[GLOBAL SETTINGS - FORM] Save news language Success',
  props<{ newsLanguage: Language }>()
);

export const saveNewsLanguageFailure = createAction(
  '[GLOBAL SETTINGS - FORM] Save news language Failed',
  props<{ error: any }>()
);
