import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, Dictionary, EntityAdapter, EntityState} from '@ngrx/entity';

import * as fromActions from '@core/core-store/departments-flat/departments-flat.actions';
import {TaxonomyFlat} from '@app/core/models/taxonomy.model';

export interface State extends EntityState<TaxonomyFlat> {
}

export const adapter: EntityAdapter<TaxonomyFlat> =
    createEntityAdapter<TaxonomyFlat>({
        selectId: department => department.id
    });

export const initialState: State = {
    ...adapter.getInitialState(),
};

const departmentsFlatReducer = createReducer(
    initialState,
    on(fromActions.departmentsFlatCollectionAddMany, (state, {departmentsFlat}) => (
        adapter.addMany(departmentsFlat, state)
    )),
);

export function reducer(state: State | undefined, action: Action) {
    return departmentsFlatReducer(state, action);
}

export const getIds = adapter.getSelectors().selectIds;
export const getEntities = adapter.getSelectors().selectEntities;
export const getAll = adapter.getSelectors().selectAll;
export const getAllActive = (taxonomies: Array<TaxonomyFlat>) => {
    const activeTaxo = taxonomies.filter(t => {
        const parent = taxonomies.find(p => !!t.parentId && p.id === t.parentId);
        return (t.isActive && parent && parent.isActive) || (t.isActive && t.parentId === null);
    });
    return activeTaxo;
};

export const getTotal = adapter.getSelectors().selectTotal;

export const getEntityById = (entities: Dictionary<TaxonomyFlat>, {id}) => entities[id];
export const getEntitiesById = (entities: Dictionary<TaxonomyFlat>, {ids}) => ids.map(id => entities[id]);
export const getRoot = (taxonomies: Array<TaxonomyFlat>) => taxonomies.filter((entity) => entity.parentId === null);
export const getRootId = (taxonomies: Array<TaxonomyFlat>) => taxonomies[0]?.id;
