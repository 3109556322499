import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { EndpointsService } from '@app/core/services/endpoints/endpoints.service';
import { ApiService, HttpError } from '@app/core/services/api/api.service';

import { catchError, map } from 'rxjs/operators';
import { ChannelsSet,
  ChannelsSetRequest,
  PagedChannelsSet } from '../models/channels-set.model';
import { Sort } from '@rds/angular-components';
import { format } from 'date-fns';

@Injectable()
export class ChannelSetService {

  public putFilterChannelsSets(
    {sort, filters, pageIndex, pageSize}: {
      sort: Sort,
      filters: { [key: string]: any },
      pageIndex: number,
      pageSize: number
    }
  ): Observable<PagedChannelsSet>  {
    const url = this.endpoints.ENDPOINT.CONTENT.CHANNEL_SET.FILTER.PUT;
    const body = {
      departments: filters.departments,
      functions: filters.functions,
      locations: filters.locations,
      name: filters.search?.trim(),
      modifiedBy: filters.modifiedBy?.map(u => u.identifier),
      modifiedDate: filters.modifiedDate ? format(filters.modifiedDate,'yyy-MM-dd') : null,
      channels: filters.channels?.map(c => c.id),
      order: `${sort.active} ${sort.direction}`
    };
    return this.http
      .put(this.endpoints.addParams(url, {pageIndex, pageSize}), body)
      .pipe(
        map((res) => ({
          data: res.data.map(cs => ({
            ...cs,
            locations: filters.locations.length ? cs.locations.sort((a, b) => filters.locations.indexOf(b) - filters.locations.indexOf(a)) : cs.locations,
            departments: filters.departments.length ? cs.departments.sort((a, b) => filters.departments.indexOf(b) - filters.departments.indexOf(a)) : cs.departments,
            functions: filters.functions.length ? cs.functions.sort((a, b) => filters.functions.indexOf(b) - filters.functions.indexOf(a)) : cs.functions,
          })),
          pagination: {
            isFirst: res.isFirst,
            isLast: res.isLast,
            pageCount: res.pageCount,
            pageIndex: res.pageIndex,
            pageSize: res.pageSize,
            totalCount: res.totalCount,
          }
        })),
        catchError((error: HttpError) => throwError(`Cannot get quicklinks sets. ${error.message}`))
      );
  }

  public getFullChannelsSet(id: string | number): Observable<ChannelsSet> {
    const url = this.endpoints.replaceUrlTokens(
      this.endpoints.ENDPOINT.CONTENT.CHANNEL_SET.GET, { id }
    );
    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get quicklinks sets. ${error.message}`))
      );
  }

  public putChannelsSet(body: ChannelsSetRequest): Observable<any> {
    const url = this.endpoints.ENDPOINT.CONTENT.CHANNEL_SET.PUT;
    return this.http
      .put(url, body)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get quicklinks sets. ${error.message}`))
      );
  }

  public putChannelsSetSearch(value: string): Observable<any> {
    const url = this.endpoints.ENDPOINT.CONTENT.CHANNEL_SET.SEARCH.PUT + `?searchProperty=name&searchPhrase=` + value;

    return this.http
      .put(url, {})
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get quicklinks sets. ${error.message}`))
      );
  }

  public postChannelsSet(body: ChannelsSetRequest): Observable<any> {
    const url = this.endpoints.ENDPOINT.CONTENT.CHANNEL_SET.POST;
    return this.http
      .post(url, body)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get quicklinks sets. ${error.message}`))
      );
  }

  public getIsNameUnique(name: string): Observable<boolean> {
    const url = this.endpoints.ENDPOINT.CONTENT.CHANNEL_SET.UNIQUE.GET;
    const params = { name };
    return this.http
      .get(this.endpoints.addParams(url, params))
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get quicklinks sets. ${error.message}`))
      );
  }

  public deleteChannelsSet(id: string | number): Observable<boolean> {
    const url = this.endpoints.replaceUrlTokens(
      this.endpoints.ENDPOINT.CONTENT.CHANNEL_SET.DELETE, { id }
    );
    return this.http
      .delete(url)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get quicklinks sets. ${error.message}`))
      );
  }


  constructor(
    private http: ApiService,
    private endpoints: EndpointsService,
  ) {}

}
