import {createAction, props} from '@ngrx/store';
import {NewsletterValueModel} from '@app/newsletter/models/newsletter-value';
import {ChannelNewsletterModel, NewsDynamicLink} from '@app/newsletter/models';

export const init = createAction(
  '[NEWSLETTER - INIT] Init'
);

export const createDraft = createAction(
  '[NEWSLETTER - CREATE DRAFT] Newsletter draft create',
  props<{ formNewsletter: NewsletterValueModel }>()
);

export const createDraftSuccess = createAction(
  '[NEWSLETTER - CREATE DRAFT SUCCESS] Newsletter draft create success',
);

export const createDraftFailure = createAction(
  '[NEWSLETTER - CREATE DRAFT FAILURE] Newsletter draft failure',
  props<{ message: string }>()
);
export const updateDraft = createAction(
  '[NEWSLETTER - UPDATE DRAFT] update request',
  props<{ formNewsletter: NewsletterValueModel }>()
);
export const updateDraftSuccess = createAction(
  '[NEWSLETTER - UPDATE DRAFT] success'
);

export const startSaveAsDraftNewsletterProccess = createAction(
  '[NEWSLETTER - FORM] Start Save as draft newsletter process',
);

export const startSendNewsletterProccess = createAction(
  '[NEWSLETTER - FORM] Start Send newsletter process',
);

export const publishNewsletter = createAction(
  '[NEWSLETTER - PUBLISH] Newsletter publish',
  props<{ formNewsletter: NewsletterValueModel }>()
);

export const publishNewsletterFromDraft = createAction(
  '[NEWSLETTER - PUBLISH] Newsletter publish from draft',
  props<{ formNewsletter: NewsletterValueModel }>()
);

export const publishNewsletterSuccess = createAction(
  '[NEWSLETTER - PUBLISH] success'
);

export const publishFailure = createAction(
  '[NEWSLETTER - PUBLISH FAILURE] Newsletter publish failure',
  props<{ message: string }>()
);

export const sendTestNewsletter = createAction(
  '[NEWSLETTER - SEND TEST NEWSLETTER ] send test newsletter',
  props<{ formNewsletter: NewsletterValueModel }>()
);

export const sendTestNewsletterSuccess = createAction(
  '[NEWSLETTER - SEND TEST NEWSLETTER  SUCCESS] send test newsletter success'
);

export const sendNewsletterToPdf = createAction(
  '[NEWSLETTER - SEND NEWSLETTER TO PDF] Send Newsletter to PDF',
  props<{ formNewsletter: NewsletterValueModel }>()
);

export const sendNewsletterToPdfSuccess = createAction(
  '[NEWSLETTER - SEND NEWSLETTER TO PDF SUCCESS] Send Newsletter to PDF success'
);

export const sendNewsletterToPdfFailure = createAction(
  '[NEWSLETTER - SEND NEWSLETTER TO PDF SUCCESS] Send Newsletter to PDF failure'
);

export const getImagesUserAgreement = createAction(
  '[NEWSLETTER - USER AGREEMENT] GET USER AGREEMENT',
  props<{ newsletterId?: number }>()
);

export const forceCloseGetImagesUserAgreementDialog = createAction(
  '[NEWSLETTER - USER AGREEMENT] FORCE CLOSE GET USER AGREEMENT',
);

export const getImagesUserAgreementSuccess = createAction(
  '[NEWSLETTER - USER AGREEMENT] GET USER AGREEMENT SUCCESS',
  props<{ agreement: boolean }>()
);

export const getImagesUserAgreementFailure = createAction(
  '[NEWSLETTER - USER AGREEMENT] GET USER AGREEMENT FAILURE'
);

export const agreementsAccepted = createAction(
  '[NEWSLETTER - USER AGREEMENT] USER AGREEMENTS ACCEPTED',
  props<{ newsletterId: number }>()
);

export const setImagesUserAgreement = createAction(
  '[NEWSLETTER - USER AGREEMENT] SET USER AGREEMENT',
);

export const setImagesUserAgreementSuccess = createAction(
  '[NEWSLETTER - USER AGREEMENT] SET USER AGREEMENT SUCCESS',
);

export const setImagesUserAgreementFailure = createAction(
  '[NEWSLETTER - USER AGREEMENT] SET USER AGREEMENT FAILURE',
);

export const getChannelsNewsletter = createAction('[NEWSLETTER - GET CHANNELS] get channels');
export const getChannelsNewsletterSuccess = createAction('[NEWSLETTER - GET CHANNELS SUCCESS] get channels success',
  props<{ channels: ChannelNewsletterModel[] }>());

export const getChannelsNewsletterFailed = createAction(
  '[NEWSLETTER - GET CHANNELS FAILED] get channels failed',
  props<{ error: any }>()
);

export const getNewsForChannel = createAction('[NEWSLETTER - GET NEWS] get news by id',
  props<{ id: string, language: string }>());

export const getNewsForChannelSuccess = createAction('[NEWSLETTER - GET NEWS SUCCESS] get news success',
  props<{ id: string, news: any[], totalCount: number }>());

export const getNewsForChannelFailed = createAction(
  '[NEWSLETTER - GET NEWS FAILED] get news failed',
  props<{ error: any }>()
);

export const updateNewsForChannel = createAction('[NEWSLETTER - UPDATE NEWS] get news by id',
  props<{ id: string, language: string }>());

export const updateNewsForChannelSuccess = createAction('[NEWSLETTER - UPDATE NEWS SUCCESS] get news success',
  props<{ id: string, news: any[], totalCount: number }>());

export const updateNewsForChannelFailed = createAction(
  '[NEWSLETTER - UPDATE NEWS FAILED] get news failed',
  props<{ error: any }>()
);

export const shareNewsletterSuccess = createAction(
  '[NEWSLETTER - SHARE NEWSLETTER SUCCESS] share success'
);

export const shareNewsletterFailure = createAction(
  '[NEWSLETTER - SHARE NEWSLETTER FAILURE] share failure',
  props<{ error: any }>()
);

export const scrollToElementSuccess = createAction(
  '[NEWSLETTER - PREVIOUS LIST SCROLL] SCROLL TO ELEMENT SUCCESS',
);
export const clearNewsletter = createAction(
  '[NEWSLETTER - OTHER CLEAR FORM] ON DESTROY',
);

export const newsDynamicLinkUpsertOne = createAction(
  '[NEWSLETTER - ADD DYNAMIC LINK] ADD DYNAMICLINK',
  props<{link: NewsDynamicLink}>()
);

