import { createAction, props } from '@ngrx/store';

import { TimeZone } from '@app/core/models';

export const loadTimeZones = createAction(
  '[CORE TIME ZONES - LOAD TIME ZONES] Load Time zones',
  props<{ defaultTimeZone: string }>()
);

export const getTimeZonesRequest = createAction(
  '[CORE TIME ZONES - GET TIME ZONES REQUEST] Get Time zones Request',
  props<{ timeZone: string }>()
);

export const getTimeZonesSuccess = createAction(
  '[CORE TIME ZONES - GET TIME ZONES SUCCESS] Get Time zones Success',
  props<{ timeZones: TimeZone[]; defaultTimeZone: string }>()
);

export const getTimeZonesFailure = createAction(
  '[CORE TIME ZONES - GET TIME ZONES FAILURE] Get Time zones Failure',
  props<{ error: any }>()
);

export const timeZonesCollectionAddMany = createAction(
  '[STORED TIME ZONES - ADD MANY] Time zones collection add many.',
  props<{ timeZones: TimeZone[], defaultTimeZone: string }>()
);

