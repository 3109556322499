import {Component, Input} from '@angular/core';
import {ComponentsLoaderService} from '../components-loader.service';

@Component({
  selector: 'rh-news-card-loader',
  templateUrl: './news-card-loader.component.html',
  styleUrls: ['./news-card-loader.component.scss']
})
export class NewsCardLoaderComponent {
  @Input() viewBox = '0 0 291 316';

  constructor(public componentsLoaderService: ComponentsLoaderService) {
  }
}
