export function EDITOR_CONFIG_FACTORY(allowCodeBlock) {
    const config = {
        language: 'en',
        toolbar: {
            items: [
                'heading',
                'bold',
                'link',
                'bulletedList',
                'numberedList',
                '|',
                'outdent',
                'indent',
                '|',
                'imageUpload',
                'insertTable',
                'mediaEmbed',
                ...(allowCodeBlock ? ['codeBlock'] : []),
                '|',
                'divider',
                '|',
                'blocks',
                'sectionColorsBox',
                '|',
                'undo',
                'redo'
            ],
            shouldNotGroupWhenFull: true
        },
        image: {
            toolbar: [
                "imageStyle:block",
                "imageStyle:side",
                'imageStyle:inline',
                'imageStyle:wrapText',
                'imageStyle:breakText',
                "|",
                "toggleImageCaption",
                "imageTextAlternative",
                "resizeImage"
            ],
            resizeOptions: [
                {
                    name: 'resizeImage:original',
                    value: null,
                    label: 'Original'
                },
                {
                    name: 'resizeImage:33',
                    value: '33',
                    label: '33%'
                },
                {
                    name: 'resizeImage:50',
                    value: '50',
                    label: '50%'
                },
                {
                    name: 'resizeImage:66',
                    value: '66',
                    label: '66%'
                },
                {
                    name: 'resizeImage:100',
                    value: '100',
                    label: '100%'
                }
            ],
        },
    
        list: {
            properties: {
                styles: true,
                startIndex: true,
                reversed: true
            }
        },
        table: {
            contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
        customImage: {
            options: [
                {
                    name: 'resizeImage:33',
                    value: '33',
                    label: '33%'
                },
                {
                    name: 'resizeImage:50',
                    value: '50',
                    label: '50%'
                },
                {
                    name: 'resizeImage:66',
                    value: '66',
                    label: '66%'
                }
            ]
        },
        sectionColorsBox: {
            contentToolbar: ["sectionColorsBox"],
            options: [
                {
                    model: '',
                },
                {
                    model: 'bg-neutral-dark-colour',
                },
                {
                    model: 'bg-neutral-medium-colour',
                },
                {
                    model: 'bg-neutral-light-colour',
                },
                {
                    model: 'bg-neutral-gradient',
                },
                {
                    model: 'bg-neutral-gradient-bottom-right',
                },
                {
                    model: 'bg-neutral-gradient-top-left',
                },
                {
                    model: 'bg-blue-gradient-top-left',
                },
                {
                    model: 'bg-blue-gradient-bottom-right',
                },
                {
                    model: 'bg-gradient-white-to-grey',
                },
                {
                    model: 'bg-warm-blue-gradient-top-left',
                },
                {
                    model: 'bg-warm-blue-gradient-bottom-right',
                }
            ],
        },
    
        heading: {
            options: [
                {
                    model: 'heading-1-rds',
                    view: {
                        name: 'h1',
                        classes: ['article-heading-1']
                    },
                    title: 'Heading 1',
                    class: 'article-heading-1',
                    converterPriority: 'high'
                },
                {
                    model: 'heading-2-rds',
                    view: {
                        name: 'h2',
                        classes: ['article-heading-2']
                    },
                    title: 'Heading 2',
                    class: 'article-heading-2',
                    converterPriority: 'high'
                },
                {
                    model: 'heading-3-rds',
                    view: {
                        name: 'h3',
                        classes: ['article-heading-3']
                    },
                    title: 'Heading 3',
                    class: 'article-heading-3',
                    converterPriority: 'high'
                },
                {
                    model: 'heading-4-rds',
                    view: {
                        name: 'h4',
                        classes: ['article-heading-4']
                    },
                    title: 'Heading 4',
                    class: 'article-heading-4',
                    converterPriority: 'high'
                },
                {
                    model: 'heading-5-rds',
                    view: {
                        name: 'h5',
                        classes: ['article-heading-5']
                    },
                    title: 'Heading 5',
                    class: 'article-heading-5',
                    converterPriority: 'high'
                },
                {
                    model: 'heading-6-rds',
                    view: {
                        name: 'h6',
                        classes: ['article-heading-6']
                    },
                    title: 'Heading 6',
                    class: 'article-heading-6',
                    converterPriority: 'high'
                },
                {
                    model: 'quote-m',
                    view: {
                        name: 'blockquote',
                        classes: ['article-quote-m']
                    },
                    title: 'Quote M',
                    class: 'article-quote-m',
                    converterPriority: 'high'
                },
                {
                    model: 'quote-s',
                    view: {
                        name: 'blockquote',
                        classes: ['article-quote-s']
                    },
                    title: 'Quote S',
                    class: 'article-quote-s',
                    converterPriority: 'high'
                },
                {
                    model: 'lead-rds',
                    view: {
                        name: 'p',
                        classes: ['article-lead']
                    },
                    title: 'Lead',
                    class: 'article-lead',
                    converterPriority: 'high'
                },
    
                {
                    model: 'body-s-rds',
                    view: {
                        name: 'p',
                        classes: ['article-body-s']
                    },
                    title: 'Body S',
                    class: 'article-body-s'
                },
                {
                    model: 'paragraph',
                    view: {
                        name: 'p',
                        classes: ['article-body-m']
                    },
                    title: 'Body M',
                    class: 'article-body-m'
                }
            ]
        }, 
        link: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
            decorators: [
                {
                    mode: 'automatic',
                    callback: url => /^(https?:)?\/\//.test(url),
                    attributes: {
                        class: 'rds-link'
                    }
                }
            ]
        },
        mediaEmbed: {
            elementName: 'oembed',
            extraProviders: [
                {
                    name: 'streamingMedia',
                    url: [
                        /^(https:\/\/)streamingmedia\.roche\.com\/(.*)/
                    ],
                    html: match => {
                        const data = match[2].includes('?') ? match[2].split('?')[0].split('/') : match[2].split('/');
                        const params = match[2].includes('?') ? match[2].split('?')[1].split('&') : [];
                        let title;
                        let entryId;
                        let src;
                        switch (data[0]) {
                            // ['media', /TITLE/, /ENTRY_ID/]
                            case 'media': {
                                title = data[1].split('+').join(' ');
                                entryId = data[2];
                                src = `https://streamingmedia.roche.com/embed/secure/iframe/entryId/${entryId}/uiConfId/45631991`;
                                break;
                            }
                            case 'playlist': {
                                // ['playlist', 'dedicated', /PBC/, /???/, /ENTRY_ID/]
                                title = '';
                                entryId = data[4];
                                src = `https://streamingmedia.roche.com/embed/secure/iframe/entryId/${entryId}/uiConfId/45631991`;
                                break;
                            }
                            case 'embed': {
                                // ['embed', 'secure', 'iframe', 'entryId', /ENTRY_ID/, 'uiConfId', CONF_ID, 'pbc', /PBC/]
                                title = '';
                                entryId = data[4];
                                src = `https://streamingmedia.roche.com/embed/secure/iframe/entryId/${entryId}/uiConfId/45631991`;
                                break;
                            }
                        }
    
                        if (params.length > 0) {
                            params.forEach(param => {
                                src += `/${param.split('=')[0]}/${param.split('=')[0]}`
                            })
                        } else {
                            src += '/st/0'
                        }
    
                        return (
                            `<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">
                            <iframe
                id="kmsembed-${entryId}"
                src="${src}"
                style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;"
                allowfullscreen webkitallowfullscreen mozAllowFullScreen
                allow="autoplay *;fullscreen *; encrypted-media *" 
                referrerPolicy="no-referrer-when-downgrade"
                sandbox="allow-downloads allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
                frameborder="0"
                title="${title}"></iframe>
                            </div>
                `
                        );
                    }
                }
            ]
        },
        indentBlock: {
            classes: [
                'ms-6'
            ]
        }
    }
    return config;
}