import { createSelector } from '@ngrx/store';
import * as fromAuth from './auth.reducer';

export const selectIsAuthenticated = createSelector(
  fromAuth.selectAuthState,
  fromAuth.getIsAuthenticated
);

export const selectIdToken = createSelector(
  fromAuth.selectAuthState,
  fromAuth.getIdToken
);

export const selectIdTokenValid = createSelector(
  selectIdToken,
  (token) => {
    if (!token) {
      return false;
    }

    const expireTimeJwt = JSON.parse(atob(token.split('.')[1])).exp * 1000;
    return new Date().getTime() - new Date(expireTimeJwt).getTime() <= 0
  }
)

export const selectAuthToken = createSelector(
  fromAuth.selectAuthState,
  fromAuth.getAuthToken
);

export const selectJwtToken = createSelector(
  fromAuth.selectAuthState,
  fromAuth.getJwtToken
);

export const selectScope = createSelector(
  fromAuth.selectAuthState,
  fromAuth.getScope
);

export const selectCalendarScopeGranted = createSelector(
  fromAuth.selectAuthState,
  fromAuth.getScope,
  (state, scope) => !!scope ? scope.split(' ').includes('https://www.googleapis.com/auth/calendar.events') : false
);

export const selectIsDialogOpened = createSelector(
  fromAuth.selectAuthState,
  fromAuth.getIsDialogOpened
);

export const selectIsDialogOpenedUserNotCreated = createSelector(
  fromAuth.selectAuthState,
  fromAuth.getIsDialogOpenedUserNotCreated
);

export const selectAuthTokens = createSelector(
  selectIdToken,
  selectAuthToken,
  selectJwtToken,
  (tokenId, tokenAccess, jwtToken) => {
    return { tokenId, tokenAccess, jwtToken };
  }
);

export const selectIsQueryAPILoaded = createSelector(
  fromAuth.selectAuthState,
  fromAuth.getIsQueryAPILoaded
);

export const selectIsGcalAPILoaded = createSelector(
  fromAuth.selectAuthState,
  fromAuth.getIsGcalAPILoaded
);

export const selectIsGapiLoaded = createSelector(
  fromAuth.selectAuthState,
  fromAuth.getIsGapiLoaded
);

export const selectIsGISLoaded = createSelector(
  fromAuth.selectAuthState,
  fromAuth.getIsGISLoaded
);