import { Pipe, PipeTransform } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map} from 'rxjs/operators';

import * as fromLanguages from '@core/core-store/languages-dict';

@Pipe({
  name: 'languageName'
})
export class LanguageNamePipe implements PipeTransform {
  transform(code: string): Observable<string> {
    return this.store$.pipe(
      select(fromLanguages.selectLanguageByCode(code)),
      first(language => !!language && !!language.englishName),
      map(language => {
          return language.englishName;
      })
    );
  }

  constructor(
    private store$: Store<any>) {
  }
}
