import { createAction, props } from '@ngrx/store';
import { CatalogEntryForm } from '@core/models/catalog.model';
import { SingleAudience, CatalogPermissions, EntryStatus, AudienceApproval } from '@app/core/models';
import { ApprovalDetails } from '@app/core/models/approval-details';

export const initAddForm = createAction(
  '[CATALOG ENTRY - FORM] Init form',
  props<{
    catalog?: CatalogEntryForm,
    contentManagerTaxonomy: SingleAudience,
    catalogPermissions: CatalogPermissions,
    approvalDetails: ApprovalDetails,
    audienceApproval: AudienceApproval
  }>()
);

export const setInvalidManager = createAction(
  '[CATALOG ENTRY - FORM] Set invalid Manager',
  props<{invalid: boolean}>()
);

export const setInvalidOwner = createAction(
  '[CATALOG ENTRY - FORM] Set invalid Owner',
  props<{invalid: boolean}>()
);

export const setFormValue = createAction(
  '[CATALOG ENTRY - FORM] Set form value',
  props<{form: Partial<CatalogEntryForm>}>()
);
export const setFormTouched = createAction(
  '[CATALOG ENTRY - FORM] Set form touched',
  props<{touched: boolean}>()
);

export const openDeleteEntryConfirmDialog = createAction(
  '[CATALOG ENTRY] Open confirm delete dialog',
  props<{id: number, name: string, status: EntryStatus}>()
);

export const openRejectEntryConfirmDialog = createAction(
  '[CATALOG ENTRY] Open confirm reject entry dialog',
  props<{id: number, name: string}>()
);

export const openProlongEntryConfirmDialog = createAction(
  '[CATALOG ENTRY] Open confirm prolong entry dialog',
  props<{id: number, name: string}>()
);

export const getContentManagerTaxonomyRequest = createAction(
  '[CATALOG ENTRY - FORM] Get content manager taxonomy request',
  props<{login: string}>()
);

export const getContentManagerTaxonomySuccess = createAction(
  '[CATALOG ENTRY - FORM] Get content manager taxonomy success',
  props<{contentManagerTaxonomy: SingleAudience}>()
);

export const getContentManagerTaxonomyFailure = createAction(
  '[CATALOG ENTRY - FORM] Get content manager taxonomy failure',
  props<{error: string}>()
);

export const saveAsDraftRequest = createAction(
  '[CATALOG ENTRY - FORM] Save catalog entry as draft request'
);

export const saveAsDraftSuccess = createAction(
  '[CATALOG ENTRY - FORM] Save catalog entry as draft success',
);

export const saveAsDraftFailure = createAction(
  '[CATALOG ENTRY - FORM] Save catalog entry as draft failure',
  props<{error: string}>()
);

export const updateAsDraftRequest = createAction(
  '[CATALOG ENTRY - FORM] Update catalog entry as draft request',
  props<{id: number}>()
);

export const updateAsDraftSuccess = createAction(
  '[CATALOG ENTRY - FORM] Update catalog entry as draft success',
);

export const updateAsDraftFailure = createAction(
  '[CATALOG ENTRY - FORM] Update catalog entry as draft failure',
  props<{error: string}>()
);

export const sendForApprovalRequest = createAction(
  '[CATALOG ENTRY - FORM] Create catalog entry request'
);

export const sendForApprovalSuccess = createAction(
  '[CATALOG ENTRY - FORM] Create catalog entry success',
);

export const sendForApprovalFailure = createAction(
  '[CATALOG ENTRY - FORM] Create catalog entry failure',
  props<{error: string}>()
);

export const updateForApprovalRequest = createAction(
  '[CATALOG ENTRY - FORM] Update catalog entry request',
  props<{id: number, approvalRequired: boolean}>()
);

export const updateForApprovalSuccess = createAction(
  '[CATALOG ENTRY - FORM] Update catalog entry success',
  props<{approvalRequired: boolean}>()
);

export const updateForApprovalFailure = createAction(
  '[CATALOG ENTRY - FORM] Update catalog entry failure',
  props<{error: string}>()
);

export const prolongRequest = createAction(
  '[CATALOG ENTRY - FORM] Prolong catalog entry request',
  props<{id: number}>()
);

export const prolongSuccess = createAction(
  '[CATALOG ENTRY - FORM] Prolong catalog entry success'
);

export const prolongFailure = createAction(
  '[CATALOG ENTRY - FORM] Prolong catalog entry failure',
  props<{error: string}>()
);

export const rejectRequest = createAction(
  '[CATALOG ENTRY - FORM] Reject catalog entry request',
  props<{id: number, comment: string}>()
);

export const rejectSuccess = createAction(
  '[CATALOG ENTRY - FORM] Reject catalog entry success'
);

export const rejectFailure = createAction(
  '[CATALOG ENTRY - FORM] Reject catalog entry failure',
  props<{error: string}>()
);

export const approveRequest = createAction(
  '[CATALOG ENTRY - FORM] Approve catalog entry request',
  props<{id: number}>()
);

export const approveSuccess = createAction(
  '[CATALOG ENTRY - FORM] Approve catalog entry success',
);

export const approveFailure = createAction(
  '[CATALOG ENTRY - FORM] Approve catalog entry failure',
  props<{error: string}>()
);

export const deleteRequest = createAction(
  '[CATALOG ENTRY - FORM] Delete catalog entry request',
  props<{id: number, status: EntryStatus}>()
);

export const deleteSuccess = createAction(
  '[CATALOG ENTRY - FORM] Delete catalog entry success',
  props<{status: EntryStatus}>()
);

export const deleteFailure = createAction(
  '[CATALOG ENTRY - FORM] Delete catalog entry failure',
  props<{error: string}>()
);

export const isNameUniqueRequest = createAction(
  '[CATALOG ENTRY - FORM] Is name unique request',
  props<{name: string, id?: number}>()
);

export const isNameUniqueSuccess = createAction(
  '[CATALOG ENTRY - FORM] Is name unique success',
  props<{isUnique: boolean}>()
);

export const isNameUniqueFailure = createAction(
  '[CATALOG ENTRY - FORM] Is name unique failure',
  props<{error: string}>()
);

export const clearAll = createAction(
  '[CATALOG ENTRY - FORM] Clear all'
);
