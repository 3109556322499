import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType} from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, debounceTime, distinctUntilChanged, map, of, switchMap } from 'rxjs';

import * as fromActions from './api-keys.actions';
import * as fromSelectors from './api-keys.selectors';
import * as fromReducer from './api-keys.reducer';
import { AdminService } from '../../admin.service';


@Injectable()
export class ApiKeysEffects {

  public initApiKeys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initApiKeys),
      switchMap(() => this.store$.pipe(
          select(fromSelectors.selectApiKeysRequestData),
          debounceTime(300),
          distinctUntilChanged((prev, next) => {
            if (prev.resetIndex && !next.resetIndex) {
              delete prev.resetIndex
              delete next.resetIndex
            }
            return JSON.stringify(prev) === JSON.stringify(next)
          }),
          map(({pageIndex, pageSize, sort, resetIndex}) => ({
            sort,
            pageIndex: resetIndex ? 0 : pageIndex,
            pageSize
          }))
        )
      ),
      map((requestData) => fromActions.getApiKeysRequest(requestData))
    ), { dispatch: true}
  );

  public getApiKeysRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getApiKeysRequest),
      switchMap((requestData) => this.adminService.getApiKeys(requestData).pipe(
        map(res => ({
          data: res.data, pagination: res.pagination
        }))
      )),
      map(({data, pagination}) => fromActions.getApiKeysSuccess({data, pagination})),
      catchError(({ message }) => of(fromActions.getApiKeysFailure({ error: message })))

    ), { dispatch: true}
  );

  public deleteApiKeysRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteApiKeysRequest),
      switchMap(({ids}) => this.adminService.removeApiKey(ids[0]).pipe(
        map(() => ({ids}))
      )),
      map(({ids}) => fromActions.deleteApiKeysSuccess({count: ids.length}))
    ), { dispatch: true}
  );

  public addApiKeysRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addApiKeysRequest),
      switchMap(({identifier}) => this.adminService.addApiKey(identifier).pipe(
        map(() => ({identifier}))
      )),
      map(({identifier}) => fromActions.addApiKeysSuccess())
    ), { dispatch: true}
  );
  
  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private adminService: AdminService
  ) {}
}
