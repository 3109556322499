import { ActivatedRouteSnapshot } from '@angular/router';
import * as fromUser from '@app/core/user/store';
import * as fromRouter from '@app/root-store/router';

import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {Injectable} from '@angular/core';
@Injectable()
export class RoleGuard  {

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store$.pipe(
      select(fromUser.selectRoles),
      map((userRoles) => {
        if (route.data.roles && !route.data.roles.some(role => userRoles.includes(role))) {
          this.store$.dispatch(fromRouter.go({ path: '/', queryParams: {} }));
          return false;
        }
        return true;
      }));
  }

  constructor(
    private store$: Store<fromUser.State>
  ) {}
}
