import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, Dictionary, EntityAdapter } from '@ngrx/entity';
import * as fromActions from './campaign-table.actions';
import { flattenFilters, SelectionListState, SelectionStatus, TableState } from '@app/shared/filters/filters-model';
import { Pagination } from '@app/core/models';
import { CampaignTableModel } from '@app/core/models/newsletter.model';
import { CampaignTableFilters, INITIAL_CAMPAIGN_FILTERS } from '@app/shared/filters/models/campaign-table';
import { CAMPAIGN_STATUS } from '@app/newsletter-new/models/campaign';
import { USER_ROLES_CAMPAIGN } from '@app/newsletter-new/models/user-roles.enum';

export interface Sugg {
	name: string;
	tickersMessage: string[];
}

export const adapter: EntityAdapter<CampaignTableModel> = createEntityAdapter<CampaignTableModel>();

export interface State {
	table: TableState<CampaignTableModel, CampaignTableFilters>;
	selectionList: SelectionListState<CampaignTableModel, CampaignTableFilters>;
}

export const initialState: State = {
	table: {
		data: adapter.getInitialState(),
		pagination: {
			...Pagination,
			pageIndex: 0,
			pageSize: 10,
		},
		sort: {
			active: 'name',
			direction: 'desc',
		},
		defaultSort: {
			active: 'name',
			direction: 'desc',
		},
		filters: INITIAL_CAMPAIGN_FILTERS,
		defaultFilters: INITIAL_CAMPAIGN_FILTERS,
		isLoading: false,
		resetIndex: false,
	},
	selectionList: {
		data: adapter.getInitialState(),
		status: SelectionStatus.LOADING,
	},
};

const contentPageReducer = createReducer(
	initialState,
	on(fromActions.table.request, (state) => ({
		...state,
		table: {
			...state.table,
			isLoading: true,
		},
	})),
	on(fromActions.table.success, fromActions.table.failure, (state) => ({
		...state,
		table: {
			...state.table,
			isLoading: false,
		},
	})),
	on(fromActions.table.success, (state, { data, pagination }) => ({
		...state,
		table: {
			...state.table,
			data: adapter.setAll(data, state.table.data),
			pagination,
			resetIndex: false,
		},
	})),
	on(fromActions.getAllCampaign.request, (state) => ({
		...state,
		selectionList: {
			...state.selectionList,
			status: SelectionStatus.LOADING,
		},
	})),
	on(fromActions.getAllCampaign.success, (state, { data }) => ({
		...state,
		selectionList: {
			...state.selectionList,
			data: adapter.addMany(data, state.selectionList.data),
			status: SelectionStatus.LOADED,
		},
	})),
	on(fromActions.table.filter, (state, { filters }) => ({
		...state,
		table: {
			...state.table,
			filters,
			resetIndex: true,
		},
	})),
	on(fromActions.table.sort, (state, { active, direction }) => ({
		...state,
		table: {
			...state.table,
			sort: {
				active,
				direction,
			},
		},
	})),
	on(fromActions.table.paginate, (state, { pageIndex, pageSize }) => ({
		...state,
		table: {
			...state.table,
			pagination: {
				...state.table.pagination,
				pageIndex,
				pageSize,
			},
		},
	})),
	on(fromActions.table.clear, (state) => ({
		...initialState,
	})),
	on(fromActions.archiveCampaign.success, (state, { id }) => ({
		...initialState,
		table: {
			...state.table,
			data: adapter.updateOne({ id: id, changes: { status: CAMPAIGN_STATUS.ARCHIVED } }, state.table.data),
		},
	})),
	on(fromActions.unArchiveCampaign.success, (state, { id }) => ({
		...initialState,
		table: {
			...state.table,
			data: adapter.updateOne({ id: id, changes: { status: CAMPAIGN_STATUS.ACTIVE } }, state.table.data),
		},
	})),
	on(fromActions.sendCampaign.success, (state, { campaign, permissions }) => {
		const users = [
			...permissions.owners.map((i) => {
				return { ...i, userLogin: i.identifier, role: USER_ROLES_CAMPAIGN.OWNER };
			}),
			...permissions.editors.map((i) => {
				return { ...i, userLogin: i.identifier, role: USER_ROLES_CAMPAIGN.EDITOR };
			}),
		];
		return {
			...initialState,
			table: {
				...state.table,
				data: adapter.updateOne({ id: campaign.id, changes: { campaignUserRoles: users } }, state.table.data),
			},
		};
	})
);

export function reducer(state: State | undefined, action: Action) {
	return contentPageReducer(state, action);
}

export const getSelectionListState = (state: State) => state.selectionList;
export const getSelectionListStatus = (list: SelectionListState<any>) => list.status;
export const getSelectionListData = (list) => adapter.getSelectors().selectAll(list.data);

export const getEntityById = (entities: Dictionary<any>, { id }) => entities[id];
// NEW WAY TABLE
export const getTableState = (state: State) => state.table;

export const getPagination = (table: TableState<CampaignTableModel, CampaignTableFilters>) => table.pagination;
export const getSort = (table: TableState<CampaignTableModel, CampaignTableFilters>) => table.sort;
export const getDefaultSort = (table: TableState<CampaignTableModel, CampaignTableFilters>) => table.defaultSort;
export const getFilters = (table: TableState<CampaignTableModel, CampaignTableFilters>) => table.filters;
export const getDefaultFilters = (table: TableState<CampaignTableModel, CampaignTableFilters>) => table.defaultFilters;
export const getIsLoading = (table: TableState<CampaignTableModel, CampaignTableFilters>) => table.isLoading;

export const getRequestData = ({
	pagination,
	sort,
	filters,
	resetIndex,
}: TableState<CampaignTableModel, CampaignTableFilters>) => ({
	pageIndex: pagination.pageIndex,
	pageSize: pagination.pageSize,
	sort,
	filters: flattenFilters(filters),
	resetIndex,
});

export const getTableData = (table: TableState<CampaignTableModel, CampaignTableFilters>) =>
	adapter.getSelectors().selectAll(table.data);

// Pagination fields
export const getPageSize = (pagination: Pagination) => pagination.pageSize;
export const getPageIndex = (pagination: Pagination) => pagination.pageIndex;
export const getTotalCount = (pagination: Pagination) => pagination.totalCount;

// Filters fields
export const getSearch = (filters: CampaignTableFilters) => filters.search.value;

export const getEntitiesByIds = (entities: Dictionary<CampaignTableModel>, ids: string[]): CampaignTableModel[] =>
	ids.map((id) => entities[id]);
