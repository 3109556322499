import { createSelector } from '@ngrx/store';

import * as fromChannel from '../news.reducer';
import * as fromReducer from './templates.reducer';

export const selectState = createSelector(
  fromChannel.selectState,
  fromChannel.getTemplates
);

// PREDEFINED
export const selectPredefinedTemplates = createSelector(
  selectState,
  fromReducer.getPredefinedTemplates
);

export const selectPredefinedTemplatesEntities = createSelector(
  selectState,
  fromReducer.getPredefinedTemplatesEntities
);

export const selectPredefinedTemplateByTitle = (title: string) => createSelector(
  selectPredefinedTemplatesEntities,
  entities => entities[title]
);

export const selectPredefinedPagination = createSelector(
  selectState,
  fromReducer.getPredefinedPagination
);

// MY
export const selectMyTemplates = createSelector(
  selectState,
  fromReducer.getMyTemplates
);

export const selectMyTemplatesEntities = createSelector(
  selectState,
  fromReducer.getMyTemplatesEntities
);

export const selectMyTemplateByTitle = (title: string) => createSelector(
  selectMyTemplatesEntities,
  entities => entities[title]
);

export const selectMyTemplatesPagination = createSelector(
  selectState,
  fromReducer.getMyTemplatesPagination
);

// SHARED
export const selectSharedTemplates = createSelector(
  selectState,
  fromReducer.getSharedTemplates
);

export const selectSharedTemplatesEntities = createSelector(
  selectState,
  fromReducer.getSharedTemplatesEntities
);

export const selectSharedTemplateByTitle = (title: string) => createSelector(
  selectSharedTemplatesEntities,
  entities => entities[title]
);

export const selectSharedTemplatesPagination = createSelector(
  selectState,
  fromReducer.getSharedTemplatesPagination
);

// SHARED
export const selectReadonlyTemplates = createSelector(
  selectState,
  fromReducer.getReadonlyTemplates
);

export const selectReadonlyTemplatesEntities = createSelector(
  selectState,
  fromReducer.getReadonlyTemplatesEntities
);

export const selectReadonlyTemplateByTitle = (title: string) => createSelector(
  selectReadonlyTemplatesEntities,
  entities => entities[title]
);

export const selectReadonlyTemplatesPagination = createSelector(
  selectState,
  fromReducer.getReadonlyTemplatesPagination
);

// SHARED
export const selectSentToMeTemplates = createSelector(
  selectState,
  fromReducer.getSentToMeTemplates
);

export const selectSentToMeTemplatesEntities = createSelector(
  selectState,
  fromReducer.getSentToMeTemplatesEntities
);

export const selectSentToMeTemplateByTitle = (title: string) => createSelector(
  selectSentToMeTemplatesEntities,
  entities => entities[title]
);

export const selectSentToMeTemplatesPagination = createSelector(
  selectState,
  fromReducer.getSentToMeTemplatesPagination
);