import {Action, createFeatureSelector, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import * as fromActions from '@app/events/store/gcal/gcal.actions';
import { RhEvent } from '@app/events/models/event';

export const feature = 'gcal';

export const adapter: EntityAdapter<any> =
  createEntityAdapter<any>();

export interface State {
  incomingMeetings: Array<RhEvent>;
  processingIds: number[]
}

export const initialState: State = {
  incomingMeetings: [],
  processingIds: []
};

export const gcalReducer = createReducer(
  initialState,
  on(fromActions.getIncomingEventsSuccess, (state, {events}) => ({
    ...state,
    incomingMeetings: events
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return gcalReducer(state, action);
}

export const selectState = createFeatureSelector<State>(feature);
export const getIncomingMeetings = (state: State) => state.incomingMeetings;
