import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { UserProfileService } from '@app/core/services/userProfile.service';
import { mergeMap, map, catchError, switchMap, first } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromActions from './languages-dict.actions';
import * as fromReducer from './languages-dict.reducer';
import * as fromSlectors from './languages-dict.selectors';

@Injectable()
export class LanguagesNestedEffects {

  public loadLanguagesDict$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadLanguagesDict),
      switchMap(() => this.store$.pipe(
        select(fromSlectors.selectEntities),
        first(entities => this.isEmpty(entities)),
      )),
      mergeMap(() => [
        fromActions.getLanguagesDictRequest()
      ])
    ), { dispatch: true }
  );

  public getLanguagesDictRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getLanguagesDictRequest),
      mergeMap(() =>
        this.userProfileService.getLanguagesDict().pipe(
          map(languagesDict => fromActions.getLanguagesDictSuccess({ languagesDict })),
          catchError(({ message }) => of(fromActions.getLanguagesDictFailure({ error: message })))
        )
      )
    ), { dispatch: true }
  );

  public getLanguagesDictSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getLanguagesDictSuccess),
      mergeMap(({ languagesDict }) => [
        fromActions.languagesDictCollectionAddMany({ languagesDict })
      ]),
    ), { dispatch: true }
  );

  private isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private userProfileService: UserProfileService,
  ) {}
}
