import {createAction, props} from '@ngrx/store';
import {RhEvent} from '@app/events/models/event';

export const getEventsRequest = createAction(
  '[HOME - EVENTS] get request'
);
export const getEventsSuccess = createAction(
  '[HOME - EVENTS] get success',
  props<{ events: any[] }>());

export const getEventsFailure = createAction(
  '[HOME - EVENTS] get failure',
);

export const addToCalendarRequest = createAction(
  '[HOME - EVENTS] add to calendar request',
  props<{ event: RhEvent }>()
);

export const addToCalendarWithGetDetailsRequest = createAction(
  '[HOME - EVENTS] add to calendar with get details request',
  props<{ eventId: number }>()
);

export const addToCalendarSuccess = createAction(
  '[HOME - EVENTS] add to calendar success',
  props<{ event: RhEvent }>()
);
export const addToCalendarFailure = createAction(
  '[HOME - EVENTS] add to calendar failure',
  props<{ eventId: number }>()
);
