import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { UserProfileService } from '@app/core/services/userProfile.service';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';

import * as fromUser from '@app/core/user/store';
import * as fromUserActions from '@app/core/user/store/user.actions';
import * as fromActions from './searching-preferences.actions';
import * as fromReducer from './searching-preferences.reducer';
import * as fromSelectors from './searching-preferences.selectors';
import { RdsDialogService } from '@rds/angular-components';
import { ConfirmDialogComponent, ConfirmDialogData } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';

@Injectable()
export class SearchingPreferencesEffects {

  public initForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initForm),
      switchMap(() => this.store$.pipe(
        select(fromUser.selectPersonalizationPreferences),
        map((audience) => ({audience})),
      )),
      mergeMap(({audience}) => [
        fromActions.setInitialValue({form: audience})
      ]),
    ), { dispatch: true }
  );


  public savePersonalizationPreferencesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.savePersonalizationPreferencesRequest),
      switchMap(() => this.store$.pipe(
        select(fromSelectors.selectForm),
        distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next)),
        take(1)
      )),
      switchMap((form) => this.userProfileService.putSearchingPreferences(form).pipe(
        mergeMap((personalizationPreferences) => [
          fromActions.savePersonalizationPreferencesSuccess({personalizationPreferences}),
          fromUserActions.setUserPersonalizationPreferences({ personalizationPreferences })
        ]),
        catchError(({ message }) => of(fromActions.savePersonalizationPreferencesFailure({ error: message })))
      )),
    ), { dispatch: true }
  );

  public openResetPersonalizationPreferencesConfirmDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openResetPersonalizationPreferencesConfirmDialog),
      map(() => {
        const data: ConfirmDialogData = {
          title: 'Are you sure you want to reset your searching preferences?',
          messages: [
            'Your searching preferences will be reset to its default state described in Workday'
          ],
          confirmButtonType: 'primary',
          confirmButtonLabel: 'Yes, reset'
        }
        return ({data})
      }),
      switchMap(({data}) => this.dialogService.open(ConfirmDialogComponent, {
        data
      }).afterClosed().pipe(
        filter((closedData) => !!closedData),
        map(() => fromActions.resetPersonalizationPreferencesRequest())
      ))
    )
  )

  public resetPersonalizationPreferencesRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.resetPersonalizationPreferencesRequest),
    switchMap(() => this.userProfileService.postResetSearchingPreferences().pipe(
      mergeMap((personalizationPreferences) => [
        fromActions.resetPersonalizationPreferencesSuccess({personalizationPreferences}),
        fromUserActions.setUserPersonalizationPreferences({ personalizationPreferences })
      ]),
      catchError(({ message }) => of(fromActions.resetPersonalizationPreferencesFailure({ error: message })))
    )),
  ), { dispatch: true }
);

  public reloadChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.savePersonalizationPreferencesSuccess,
        fromActions.resetPersonalizationPreferencesSuccess
      ),
      map(() => fromUserActions.getUserChannels())
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private userProfileService: UserProfileService,
    private dialogService: RdsDialogService
  ) {}
}
