import { Injectable, Injector } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';

import { TickerService } from '@core/services/ticker.service';
import { RdsDialogService } from '@rds/angular-components';
import * as fromReducer from './sitemap.reducer';
import * as fromActions from './sitemap.actions';
import * as fromSelectors from './sitemap.selectors';
import { concatMap, debounceTime, filter, map, mergeMap, withLatestFrom, tap } from 'rxjs';
import { Router, Routes } from '@angular/router';
import { SiteMapRoute } from './sitemap.reducer';

@Injectable()
export class SiteMapEffects {
  public initReadingRoutes$ = createEffect(() =>
  {
      return this.actions$.pipe(
        ofType(fromActions.initReadingRoutes),
        withLatestFrom(
          this.store$.pipe(select(fromSelectors.selectAllRoutes))
        ),
        filter(([{forceRefresh}, routes]) => forceRefresh || routes.length === 0),
        tap(([{forceRefresh}]) => {
          if (forceRefresh) {
            localStorage.removeItem('siteMap');
          }
          this._router.config.forEach(i => {
            this.store$.dispatch(fromActions.readRoute({ route: i, parent: '', module: (this.injector as any).parent.source }));
          });
        })
      );
    }, { dispatch: false}
);
  
  public readChildRoutes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.readRoute),
      filter(({route}) => !route.redirectTo && route.children?.length > 0),
      mergeMap(({route, parent, module}) => {
        const actions = [];
        route.children.forEach(childRoute => {
          actions.push(fromActions.readRoute({route: childRoute, parent:  `${parent}${!!parent&&!!route.path ? '/':''}${route.path}`, module}))
        });
        return [...actions]
      })
    ), { dispatch: true}
  );

  public readLazyRoutes$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.readRoute),
    filter(({route}) => !route.redirectTo && !route.children && route.loadChildren != null),
    concatMap(({route, parent, module}) => (<any>this._router).navigationTransitions.configLoader.loadChildren(this.injector, route).pipe(
      map((lazy) => ({route, routes: (lazy as any).routes, parent, module:  (lazy as any).injector.source || module}))
    )),
    mergeMap(({route, routes, parent, module}) => {
      const actions = [];
      (routes as Routes).forEach(childRoute => {
        actions.push(fromActions.readRoute({route: childRoute, parent:  `${parent}${!!parent&&!!route.path ? '/':''}${route.path}`, module}))
      });
      return [...actions]
    })
  ), { dispatch: true}
);
  
public addRoute$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.readRoute),
  filter(({route}) => !route.redirectTo && !route.children && !route.loadChildren && route.path != null),
  map(({route, parent, module}) => {
    let title: string = route.data?.title || '';
    let fullPath: string;
    if (route.path != null) {
      if (parent) {
        fullPath = `/${parent}/${route.path}`;
      }
      else {
        fullPath = `/${route.path}`
      }
    }
    if (fullPath.endsWith('/') && fullPath.length > 1) {
      fullPath = fullPath.slice(0,fullPath.length -1);
    }
    const siteRoute: SiteMapRoute = {
      url: fullPath,
      title,
      module,
      status: 'not_set',
      notes: []
    }
    return fromActions.addRoute({route: siteRoute})
  })
), { dispatch: true}
);


public setLoaded$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.readRoute),
  debounceTime(5000),
  map(({route, parent, module}) => fromActions.routesLoaded())
), { dispatch: true}
);

  constructor(
    private actions$: Actions,
    private _router: Router,
    private injector: Injector,
    private store$: Store<fromReducer.State>,
    private tickerService: TickerService,
    private dialogService: RdsDialogService
  ) {
  }
}
