export interface ColorModel {
  label: string;
  valueDark: string;
  valueLight: string;
}

export const ColorsPalette: ColorModel[] = [
  {label: 'blue', valueLight: '#0B41CD', valueDark: '#0B41CD'},
  {label: 'purple', valueLight: '#BC36F0', valueDark: '#7D0096'},
  {label: 'red', valueLight: '#C40000', valueDark: '#8C0000'},
  {label: 'orange', valueLight: '#ED4A0D', valueDark: '#B22B0D'}
];
