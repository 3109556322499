import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {  first, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as fromAuth from '../store';
import { select, Store } from '@ngrx/store';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  isRefreshing = false
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.store$.pipe(
        select(fromAuth.selectAuthTokens),
        first(),
      mergeMap(tokens => {
          const authReq = !!tokens ? req.clone({
            setHeaders: {
              'google-auth': tokens.tokenAccess ? tokens.tokenAccess : '',
              'google-id': tokens.tokenId ? tokens.tokenId : '',
              'Authorization': tokens.jwtToken ? `Bearer ${tokens.jwtToken}` : '',
            },
          }) : req;

          // let test401;
          // if (authReq.url.includes('auth/api/Test/GoogleToken')) {
          //   test401 = authReq.clone({
          //     setParams: {
          //       'idToken': tokens.tokenId
          //     }
          //   })
          // }

          // return next.handle(!!test401 ? test401 : authReq).pipe(
            return next.handle(authReq)
          // TODO: Uncomment this when prod will be ready for testing
          //   .pipe(
          //   catchError((response: any) => {
          //     console.log(response)
          //     if (response instanceof HttpErrorResponse && response.status === 401 && response.url.includes('auth/api/Auth/refresh')) {
          //       this.store$.dispatch(fromAuth.authenticateAfterRefresh401())
          //       // this.store$.dispatch(fromAuth.clearAndLoginAgain());
          //     }

          //     if (response instanceof HttpErrorResponse && response.status === 401 && !response.url.includes('auth/api/Auth/refresh')) {
          //       if (!this.isRefreshing) {
          //         this.isRefreshing = true;
          //         return this.authService.refreshToken().pipe(
          //           tap(({idToken, accessToken, scope, jwtToken}) => this.store$.dispatch(fromAuth.refreshTokenSuccess({
          //             tokenId: idToken,
          //             tokenAccess: accessToken,
          //             jwtToken,
          //             scope
          //           }))),
          //           switchMap(({idToken, accessToken, scope, jwtToken}) => {
          //             this.isRefreshing = false;
          //             return next.handle(authReq.clone({
          //               setHeaders: {
          //                 'google-auth': accessToken,
          //                 'google-id': idToken,
          //                 'Authorization': `Bearer ${jwtToken}`,
          //               },
          //               setParams: {
          //                 'idToken': idToken
          //               }
          //             }))
          //           }),
          //           catchError((err) => {
          //             this.isRefreshing = false;
                      
          //             this.store$.dispatch(fromAuth.clearAuth());
          //             return throwError(err);
          //           })
          //         )
          //       }
          //     }
      
          //     if (response instanceof HttpErrorResponse && response.status === 403) {
          //       console.log('Forbidden access!!')
          //       // this.store$.dispatch(fromRoute.go({ path: '403', queryParams: {}}));
          //     }
      
          //     return throwError(response);
          //   })
          // );
        }),
    );
  }

  constructor(private store$: Store<fromAuth.State>) {}
}
