import {Action, createReducer, on} from '@ngrx/store';
import * as fromActions from './newsletter-websocket.actions';
import {createEntityAdapter, Dictionary, EntityAdapter, EntityState} from '@ngrx/entity';

export interface SharedNewsletter {
  newsletterId: number;
  workInProgress: boolean;
  workingUserLogin: string;
  workingUserFullName: string;
}

export interface State {
  isWebSocketConnected: boolean;
  newsletters: EntityState<SharedNewsletter>;
}

export const adapter: EntityAdapter<SharedNewsletter> =
 createEntityAdapter<SharedNewsletter>({
  selectId: newsletter => newsletter.newsletterId
 });

export const initialState: State = {
  isWebSocketConnected: false,
  newsletters: {...adapter.getInitialState()},
};

export const newsletterWebSocketReducer = createReducer(
  initialState,
  on(fromActions.wsSetIsConnected, (state) => ({
    ...state,
    isWebSocketConnected: true
  })),
  on(fromActions.wsDisconnected, (state) => ({
    ...state,
    isWebSocketConnected: false,
    newsletters: {...adapter.getInitialState()}
  })),
  on(fromActions.wsNewsletterInfo, (state, {newsletters}) => ({
    ...state,
    newsletters: adapter.addMany(newsletters, state.newsletters)
  })),
  on(fromActions.wsNewsletterTaken, fromActions.wsNewsletterReleased, (state, {newsletter}) => ({
    ...state,
    newsletters: adapter.upsertOne(newsletter, state.newsletters)
  })),
  )
;

export function reducer(state: State | undefined, action: Action) {
  return newsletterWebSocketReducer(state, action);
}

export const getNewsletters = (state: State) => state.newsletters;
export const getIsWebSocketConnected = (state: State) => state.isWebSocketConnected;
export const getIds  = (state: State) => adapter
.getSelectors()
.selectIds(state.newsletters);

export const getEntities  = (state: State) => adapter
.getSelectors()
.selectEntities(state.newsletters);

export const getAll  = (state: State) => adapter
.getSelectors()
.selectAll(state.newsletters);

export const getTotal  = (state: State) => adapter
.getSelectors()
.selectTotal(state.newsletters);

export const getTaken  = (state: State) => adapter
.getSelectors()
.selectAll(state.newsletters)
.filter(n => n.workInProgress)
.map(n => ({
  id: n.newsletterId,
  workingUserLogin: n.workingUserLogin,
  workingUserFullName: n.workingUserFullName
}));

export const getEntityById = (entities: Dictionary<SharedNewsletter>, {id}) => entities[id];
export const getEntitiesById = (entities: Dictionary<SharedNewsletter>, {ids}) => ids.map(id => entities[id]);
