import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import * as enLabels from '../../../assets/i18n/en.json';

export const enLanguage = 'en';

export const translation = {
  [enLanguage]: enLabels
};

export class TranslateUniversalLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of(translation[lang].default);
  }
}

@NgModule({
  declarations: [],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateUniversalLoader
      }
    })
  ],
  exports: []
})
export class TranslationModule {

  static forRoot(): ModuleWithProviders<TranslationModule> {
    return {
      ngModule: TranslationModule,
      providers: []
    };
  }

  constructor(private translate: TranslateService) {
    this.translate.addLangs([enLanguage]);
    this.translate.setDefaultLang(enLanguage);
    this.translate.use(enLanguage);
  }
}
