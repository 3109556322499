import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService, HttpError } from '@core/services/api/api.service';
import { EndpointsService } from '@core/services/endpoints/endpoints.service';
import { Category, CategoryFlat } from '@core/models/category.model';
import { UtilsService } from './utils.service';

@Injectable()
export class CategoriesService {

  getCategoriesFlat(): Observable<CategoryFlat[]> {
    const url = this.endpoints.ENDPOINT.CATEGORY.GET;
    // TODO
    return this.http
      .get(this.endpoints.addParams(url, {topLevelOnly: false}))
      .pipe(
        map((data) => this.utilsService.addChildrenIdsAndParentNames<CategoryFlat>(
          data,
          'categoryId',
          'parentCategoryId',
          'name',
          'parentCategoryName',
          'childrenIds'
          )),
        catchError((error: HttpError) => throwError(`Cannot get nested categories. ${error.message}`))
      );
  }

  getCategoriesNested(): Observable<Category[]> {
    const url = this.endpoints.ENDPOINT.CATEGORY.NESTED.GET;

    return this.http
      .get(url)
      .pipe(
        map((data) => this.utilsService.markChildWithParentIdName<Category>(data, 'childCategories', 'categoryId', 'name')),
        catchError((error: HttpError) => throwError(`Cannot get nested categories. ${error.message}`))
      );
  }

  constructor(
    private http: ApiService,
    private endpoints: EndpointsService,
    private utilsService: UtilsService,
  ) {}
}
