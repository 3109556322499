import { Injectable } from '@angular/core';
import { NewsboardAuthService } from '@app/core/auth/services/newsboard-auth.service';
import { Pagination } from '@app/core/models';
import { PagedData, SimpleUser } from '@app/core/models/newsboard';
import { Template, TemplateUpdate, TemplatesListType } from '@app/core/models/newsboard/template';
import { ApiClient } from '@app/core/services/api-newsboard/api-client.service';

import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  constructor(private client: ApiClient, private auth: NewsboardAuthService) {

  }

  get(id: number) {
    return this.client.get(`templates/${id}`)
  }

  getTemplatesList(
    { pageSize,
      pageIndex }: Pagination,
      listType: TemplatesListType): Observable<{data: Array<Template>, pagination: Pagination}> {

    const body = {
      offset: pageSize * pageIndex,
      count: pageSize,
      view: listType,
    }
    return this.client.post(`templates/filter`, body).pipe(
      map((res: PagedData<Template>): {data: Array<Template>, pagination: Pagination} => {
        return ({ 
          data: res.data.map((t: any) => ({
            ...t,
            content: [t.content]
          })), 
          pagination: {
            isFirst: res.offset === 0,
            isLast: ((res.offset / res.perPage) + 1) * res.perPage >= res.total,
            pageCount: Math.ceil(res.total / res.perPage),
            pageIndex: res.offset / res.perPage,
            pageSize: res.perPage,
            totalCount: res.total,
          }
        }) ;
      })
    );
  }

  deleteTemplate(id: number) {
    return this.client.delete('templates', [id]);
  }

  createTemplate(templateCreate: Partial<TemplateUpdate>) {
    return this.client.post(`templates`, templateCreate);
  }

  updateTemplate(id: number, templateUpdate: Partial<TemplateUpdate>) {
    return this.client.put(`templates/${id}`, templateUpdate);
  }

  sendTemplateCopy(id: number, users: Array<SimpleUser>) {
    return this.client.post(`shareTemplate/share/${id}`, {users});
  }

  acceptTemplateCopy(id: number) {
    return this.client.put(`shareTemplate/accept/${id}`, null);
  }

  rejectTemplateCopy(id: number) {
    return this.client.put(`shareTemplate/reject/${id}`, null);
  }

  getSentToMeTemplatesList(
    { pageSize,
      pageIndex }: Pagination): Observable<{data: Array<Template>, pagination: Pagination}> {

    const body = {
      offset: pageSize * pageIndex,
      count: pageSize,
    }
    return this.client.post(`shareTemplate/filter`, body).pipe(
      map((res: PagedData<Template>): {data: Array<Template>, pagination: Pagination} => {
        return ({ 
          data: res.data.map((t: any) => ({
            ...t,
            title: `${t.sharedBy.name} send a template to you`,
            content: [t.content],
          })), 
          pagination: {
            isFirst: res.offset === 0,
            isLast: ((res.offset / res.perPage) + 1) * res.perPage >= res.total,
            pageCount: Math.ceil(res.total / res.perPage),
            pageIndex: res.offset / res.perPage,
            pageSize: res.perPage,
            totalCount: res.total,
          }
        }) ;
      })
    );
  }
}
