import { props, createAction } from '@ngrx/store';
import { ContentItem } from '@app/core/models';

export const getLatestNews = createAction(
  '[LATEST NEWS - REQUEST] Get Latest News'
);

export const getLatestNewsSuccess = createAction(
  '[LATEST NEWS - GET SUCCESS] Get Latest News Success',
  props<{ latestNews: ContentItem[] }>()
);

export const getLatestNewsFailed = createAction(
  '[LATEST NEWS - GET FAILED] Get Latest News Failed',
  props<{ error: any }>()
);

export const goToContent = createAction(
  '[LATEST NEWS - GO CONTENT] Navigate to Content Page',
  props<{ path: string, queryParams }>()
);

export const goOutside = createAction(
  '[LATEST NEWS - REDIRECT OUT] Redirect outside',
  props<{ url: string }>()
);

