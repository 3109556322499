import { Component, Host, Input, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'rh-number-select',
	templateUrl: './number-select.component.html',
	styleUrls: ['./number-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: NumberSelectComponent,
		},
	],
})
export class NumberSelectComponent {
	number: number;

	@Input() formControl!: FormControl;
	@Input() formControlName!: string;
	@Input() singular = '';
	@Input() plural = '';
	@Input() min = 1;
	@Input() max = 10;

	onChange = (file) => {};

	onTouched = () => {};

	touched = false;
	disabled = false;

	writeValue(number: number) {
		this.number = number;
	}

	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}

	get control() {
		return this.formControl || this.controlContainer.control?.get(this.formControlName);
	}

	constructor(@Optional() @Host() @SkipSelf() private controlContainer: ControlContainer) {}
}
