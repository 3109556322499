import { Pipe, PipeTransform } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map} from 'rxjs/operators';

import * as fromDepartmentsFlat from '@app/core/core-store/departments-flat';
import * as fromFunctionsFlat from '@app/core/core-store/functions-flat';
import * as fromLocationsFlat from '@app/core/core-store/locations-flat';
import * as fromContentTypes from '@app/core/core-store/content-types';
import * as fromCategories from '@app/core/core-store/categories-flat';

export type DictionaryType = 'location' | 'department' | 'function' | 'contentType' | 'category';

@Pipe({
  name: 'dictionary'
})
export class DictionaryGetPipe implements PipeTransform {
  transform(id: string, dictionaryType: DictionaryType, withParent: boolean = true): Observable<string> {
    switch (dictionaryType) {
      case 'location': {
        return this.store$.pipe(
          select(fromLocationsFlat.selectEntityById, {id}),
          first(taxonomy => !!taxonomy && !!taxonomy.name),
          map(taxonomy => {
            if (withParent) {
              return taxonomy.parentName ? `${taxonomy.parentName}: ${taxonomy.name}` : `${taxonomy.name}`;
            } else {
              return taxonomy.name;
            }
          })
        );
      }
      case 'department': {
        return this.store$.pipe(
          select(fromDepartmentsFlat.selectEntityById, {id}),
          first(taxonomy => !!taxonomy && !!taxonomy.name),
          map(taxonomy => {
            if (withParent) {
              return taxonomy.parentName ? `${taxonomy.parentName}: ${taxonomy.name}` : `${taxonomy.name}`;
            } else {
              return taxonomy.name;
            }
          })
        );
      }
      case 'function': {
        return this.store$.pipe(
          select(fromFunctionsFlat.selectEntityById, {id}),
          first(taxonomy => !!taxonomy && !!taxonomy.name),
          map(taxonomy => {
            if (withParent) {
              return taxonomy.parentName ? `${taxonomy.parentName}: ${taxonomy.name}` : `${taxonomy.name}`;
            } else {
              return taxonomy.name;
            }
          })
        );
      }
      case 'contentType': {
        return this.store$.pipe(
          select(fromContentTypes.selectEntityById, {id}),
          first(contentType => !!contentType && !!contentType.name),
          map(contentType => {
            return contentType.name;
          })
        );
      }
      case 'category': {
        return this.store$.pipe(
          select(fromCategories.selectEntityById, {id}),
          first(category => !!category && !!category.name),
          map(category => {
            if (withParent) {
              return category.parentCategoryName ? `${category.parentCategoryName}: ${category.name}` : `${category.name}`;
            } else {
              return category.name;
            }
          })
        );
      }
    }
  }

  constructor(
    private store$: Store<any>) {
  }
}
