import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NewsDetails } from '@app/core/models/newsboard';

@Component({
  selector: 'rh-template-card',
  templateUrl: './template-card.component.html',
  styleUrls: ['./template-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateCardComponent {

  scrollable = false;

  @Input() attentionNeeded: boolean = false;
  @Input() selected: boolean = false;
  @Input() title: string;
  @Input() image: string;
  @Input() description: string;
  @Input() disabled = false;
  @Input() size: 's' | 'm' = 'm';
  public news: Partial<NewsDetails>;

  @Input() label: string;


  @Output() useTemplate: EventEmitter<any> = new EventEmitter();

  getScale(el: HTMLElement) {
    return el.offsetWidth / 1149;
  }

  showScroll() {
    this.scrollable = true;
  }

  hideScroll() {
    this.scrollable = false;
  }
}
