<div [ngStyle]="{}">
  <content-loader
    [baseUrl]="componentsLoaderService.baseUrl"
    [backgroundColor]="'#EAE8E5'"
    [foregroundColor]="'#DBD6D1'"
    [viewBox]="'0 0 378 830'"
    [speed]="3"
    [interval]="0.75"
    [gradientRatio]="2.4">

        <svg:rect x="0" y="0" rx="0" width="378" height="212" />
        <svg:rect x="16" y="224" rx="0" width="76" height="76" />
        <svg:rect x="124" y="224" rx="0" width="238" height="24" />
        <svg:rect x="16" y="314" rx="0" width="350" height="20" />

    <svg:rect x="16" y="360" rx="0" width="76" height="76" />
    <svg:rect x="124" y="360" rx="0" width="238" height="24" />
    <svg:rect x="16" y="446" rx="0" width="350" height="20" />

    <svg:rect x="16" y="502" rx="0" width="76" height="76" />
    <svg:rect x="124" y="502" rx="0" width="238" height="24" />
    <svg:rect x="16" y="588" rx="0" width="350" height="20" />

    <svg:rect x="16" y="642" rx="0" width="76" height="76" />
    <svg:rect x="124" y="642" rx="0" width="238" height="24" />
    <svg:rect x="16" y="730" rx="0" width="350" height="20" />

    <svg:rect x="136" y="780" rx="0" width="120" height="34" />
    </content-loader>
  </div>
