export interface Category {
  categoryId: number;
  childCategories?: Array<Category>;
  name: string;
  parentCategoryId?: number;
  parentCategoryName?: string;
}
export interface CategoryFlat {
  categoryId: number;
  childrenIds?: number[];
  name: string;
  parentCategoryId?: number;
  parentCategoryName?: string;
}

export const categoriesOptionsModel = {
  idKey: 'categoryId',
  parentIdKey: 'parentCategoryId',
  nestKey: 'childrenIds',
  nameKey: 'name',
  parentNameKey: 'parentCategoryName',
};
