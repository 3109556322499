import {Action, combineReducers, createFeatureSelector} from '@ngrx/store';
import * as fromHeader from '@app/layout/header/store/header/header.reducer';
import * as fromQuickLink from '@app/layout/header/store/quick-link/quick-link.reducer';
import * as fromCatalogs from '@app/layout/header/store/catalogs/catalogs.reducer';

export const feature = 'header';

export interface State {
  header: fromHeader.State;
  quickLink: fromQuickLink.State;
  catalogs: fromCatalogs.State;
}

export function reducer(state: State | undefined, action: Action) {
  return combineReducers({
    header: fromHeader.reducer,
    quickLink: fromQuickLink.reducer,
    catalogs: fromCatalogs.reducer,
  })(state, action);
}

export const selectState = createFeatureSelector<State>(feature);
export const getHeader = (state: State) => state.header;
export const getQuickLink = (state: State) => state.quickLink;
export const getCatalogs = (state: State) => state.catalogs;
