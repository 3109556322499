<h2 rds-dialog-header>{{data.title}}</h2>
<div rds-dialog-content [formGroup]="form">
  <rds-form-field>
    <input rdsInput formControlName="valueToCopy" />
    <rds-control-hint *ngIf="!!data.hint">
      {{data.hint}}
    </rds-control-hint>
  </rds-form-field>
</div>
<div rds-dialog-actions-container align="end">
  <button
      rds-secondary-button
      [rds-dialog-close]="null"
      size="m"
  >Cancel</button>
  <button
      rds-primary-button
      size="m"
      (click)="copyLink()"
  >{{data.confirmButtonLabel || 'Copy to clipboard'}}</button>
</div>