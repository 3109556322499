import { Injectable } from '@angular/core';
import { CatalogEntryRequest, CatalogTable } from '@core/models/catalog.model';
import { ApiService, HttpError } from '@core/services/api/api.service';
import { EndpointsService } from '@core/services/endpoints/endpoints.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CatalogPermissions, EntryStatus } from '../models';
import { ApprovalDetails } from '../models/approval-details';
import { CatalogResult, QuicklinksSetFormCatalogFilters } from '../models/quicklinks-set.model';

@Injectable()
export class CatalogsService {

  getCatalogById(id: number): Observable<CatalogTable> {
    const url = this.endpoints.replaceUrlTokens(
      this.endpoints.ENDPOINT.CATALOG.GET, { id }
    );

    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get catalog with id ${id}. ${error.message}`))
      );
  }

  getCatalogSearch(query): Observable<Array<CatalogTable>> {
    const url = this.endpoints.ENDPOINT.CATALOG.SEARCH;
    const queryParamsUrl = this.endpoints.addParams(url, { query: encodeURIComponent(query), includeQuickLinks: true });

    return this.http
      .get(queryParamsUrl)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get catalogs. ${error.message}`))
      );
  }

  getKeywordSearch(query): Observable<Array<string>> {
    const mainUrl = this.endpoints.ENDPOINT.CATALOG.KEWYWORD_SEARCH;
    const queryParamsUrl = this.endpoints.addParams(mainUrl, {phrase: encodeURIComponent(query)});

    return this.http
      .get(queryParamsUrl)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get catalogs. ${error.message}`))
      );
  }

  getIsNameUnique(name): Observable<boolean> {
    const mainUrl = this.endpoints.ENDPOINT.CATALOG.NAME_UNIQUE;
    const queryParamsUrl = this.endpoints.addParams(mainUrl, {name: encodeURIComponent(name)});

    return this.http
      .get(queryParamsUrl)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get catalogs. ${error.message}`))
      );
  }

  saveAsDraft(catalog: CatalogEntryRequest): Observable<CatalogTable> {
    const url = this.endpoints.ENDPOINT.CATALOG.POST.SAVE_AS_DRAFT;

    return this.http
      .post(url, catalog)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get catalog with id ${error.message}`))
      );
  }

  updateAsDraft(catalog: CatalogEntryRequest): Observable<CatalogTable> {
    const catalogId = catalog.id;
    const url = this.endpoints.replaceUrlTokens(
      this.endpoints.ENDPOINT.CATALOG.PUT.SAVE_AS_DRAFT, { catalogId }
    );

    return this.http
      .put(url, catalog)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get catalog with id ${error.message}`))
      );
  }

  deleteCatalog(catalogId): Observable<CatalogTable> {
    const url = this.endpoints.replaceUrlTokens(
      this.endpoints.ENDPOINT.CATALOG.PUT.DELETE, { catalogId }
    );

    return this.http
      .put(url)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get catalog with id ${error.message}`))
      );
  }

  approveCatalog(catalogId): Observable<CatalogTable> {
    const url = this.endpoints.ENDPOINT.CATALOG.PUT.PUBLISH;
    const req_body = { catalogId };
    return this.http
      .put(url, req_body)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get catalog with id ${error.message}`))
      );
  }

  rejectCatalog(catalogId, comment): Observable<CatalogTable> {
    const url = this.endpoints.ENDPOINT.CATALOG.PUT.NOT_PUBLISH;
    const req_body = { catalogId, comment };
    return this.http
      .put(url, req_body)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get catalog with id ${error.message}`))
      );
  }

  sendForApproval(catalog: CatalogEntryRequest): Observable<CatalogTable> {
    const url = this.endpoints.ENDPOINT.CATALOG.POST.SEND_FOR_APPROVAL;
    const req_body = { ...catalog };

    return this.http
      .post(url, req_body)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get catalog with id ${error.message}`))
      );
  }

  updateForApproval(catalog: CatalogEntryRequest): Observable<CatalogTable> {
    const catalogId = catalog.id;
    const url = this.endpoints.replaceUrlTokens(
      this.endpoints.ENDPOINT.CATALOG.PUT.SEND_FOR_APPROVAL, { catalogId }
    );
    const req_body = {
      ...catalog,
      status: EntryStatus.APPROVAL_REQUIRED
    };

    return this.http
      .put(url, req_body)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get catalog with id ${error.message}`))
      );
  }

  putCatalogPermissionsByCatalogId({ catalogId, permissions}: {catalogId: number, permissions: CatalogPermissions}): Observable<CatalogPermissions> {
    const url = this.endpoints.replaceUrlTokens(this.endpoints.ENDPOINT.CATALOG.PUT.PERMISSIONS, { id: catalogId });

    return this.http
      .put(url, permissions)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot put catalog permissions ${error.message}`))
      );
  }

  putCatalogSearchFiltered(filters: QuicklinksSetFormCatalogFilters): Observable<CatalogResult[]> {
    const url = this.endpoints.ENDPOINT.CATALOG.PUT.SEARCH_FILTERED;

    return this.http
      .put(url, {
        phrase: filters.name,
        locationIds: filters.locations,
        departmentIds: filters.departments,
        functionIds: filters.functions,
        contentTypeIds: filters.contentTypes,
        categoryIds: filters.categories,
      })
      .pipe(
        map((catalogs) => catalogs.map(c => ({id: c.catalogId, name: c.name}))),
        catchError((error: HttpError) => throwError(`Cannot put catalog search filtered ${error.message}`))
      );
  }

  getCatalogByContenTypeIdCategoriesIds({ contentTypeId, categoryIds }): Observable<CatalogResult[]> {
    const url = this.endpoints.ENDPOINT.CATALOG.PUT.FILTER;
    contentTypeId = parseInt(contentTypeId, 10);
    categoryIds = categoryIds.map(id => parseInt(id, 10));
    return this.http
      .put(url, { contentTypeId, categoryIds })
      .pipe(
        map(response => response.map(({catalogId, name}) => ({catalogId, name}))),
        catchError((error: HttpError) => throwError(`Cannot get catalog with provided ids ${error.message}`))
      );
  }

  getCatalogApprovalDetails({ catalogId }): Observable<ApprovalDetails> {
    const url = this.endpoints.replaceUrlTokens(this.endpoints.ENDPOINT.CATALOG.APPROVAL_DETAILS, { catalogId });
    return this.http
      .get(url)
      .pipe(
        map(data => ({...data, catalogId})),
        catchError((error: HttpError) => throwError(`Cannot get catalogs. ${error.message}`))
      );
  }

  prolongCatalog(catalogId): Observable<CatalogTable> {
    const url = this.endpoints.ENDPOINT.CATALOG.PUT.PROLONG;
    return this.http
      .put(url, { catalogId })
      .pipe(
        map(data => ({...data})),
        catchError((error: HttpError) => throwError(`Cannot prolong. ${error.message}`))
      );
  }

  getCatalogDuplicate({ catalogId }): Observable<string> {
    const url = this.endpoints.replaceUrlTokens(this.endpoints.ENDPOINT.CATALOG.DUBLICATE.GET, { catalogId });

    return this.http
      .get(url)
      .pipe(
        map(data => ({...data})),
        catchError((error: HttpError) => throwError(`Cannot duplicate. ${error.message}`))
      );
  }



  constructor(
    private readonly http: ApiService,
    private readonly endpoints: EndpointsService,
    ) {}
}
