import { Injectable } from '@angular/core';
import { Pagination, UserRole, UserRoleResponse } from '@app/core/models';
import { UserSearch } from '@app/core/models/user-search.model';
import { Sort } from '@rds/angular-components';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { EndpointsService } from '../endpoints/endpoints.service';
import { escapeApostrophe } from './odata.utils';

@Injectable()
export class UserProfileODataService {

  getUsersWithRoles(
    {sort, filters, pageIndex, pageSize}: {
      sort: Sort,
      filters: { [key: string]: any },
      pageIndex: number,
      pageSize: number
    }
    ): Observable<{
    data: Array<UserRole>, pagination: Pagination
  }> {
    const mainUrl = this.endpoints.ENDPOINT.ODATA + 'userprofile/odata/UserRole/getwithroles';

    const taxonomies = {
      locations: filters.locations,
      departments: filters.departments,
      functions: filters.functions,
    };

    const queryFilters: {[key: string]: any} = {
      ...filters,
      isAdministrator:filters.roles.indexOf('isAdministrator') !== -1,
      isAudienceManager:filters.roles.indexOf('isAudienceManager') !== -1,
      isContentQualityManager:filters.roles.indexOf('isContentQualityManager') !== -1,
    };
    delete queryFilters.roles;
    delete queryFilters.locations;
    delete queryFilters.departments;
    delete queryFilters.functions;

    const filtersString = this.prepareFilters(queryFilters);
    const sortString = sort.active !== 'name' ? `${sort.active} ${sort.direction}` : `lastName ${sort.direction},firstName ${sort.direction}`;
    const prepParams = {
      filters : filtersString.length > 0 ? `$filter=${filtersString}&` : '',
      top: `$top=${pageSize}`,
      skip: pageSize * pageIndex > 0 ? `&$skip=${pageSize * pageIndex}` : '',
      sort: `&$orderBy=${sortString}`,
    };

    const url = `${mainUrl}?${prepParams.filters}${prepParams.top}${prepParams.skip}${prepParams.sort}&$count=true`;

    return this.http
    .put(url, taxonomies)
    .pipe(
      map((res) => ({
        data: res.value.map(result => ({
          ...result,
          locations: ((result.taxonomies) || []).locations || [],
          departments: ((result.taxonomies) || []).departments || [],
          functions: ((result.taxonomies) || []).functions || [],
        })),
        pagination: {
          isFirst: (pageSize * pageIndex) === 0,
          isLast: (((pageSize * pageIndex) / pageSize) + 1) * pageSize >= res['@odata.count'],
          pageCount: Math.ceil(res['@odata.count'] / pageSize),
          pageIndex: (pageSize * pageIndex) / pageSize,
          pageSize: pageSize,
          totalCount: res['@odata.count'],
        }
      })),
      catchError((fault) => throwError(`Cannot get user with roles. ${fault}`))
    );
  }

  getUserWithRoles({login}): Observable<UserRoleResponse> {
    const mainUrl = this.endpoints.ENDPOINT.ODATA + 'userprofile/odata/UserRole/getwithroles';
    const url = `${mainUrl}?$filter=contains(toLower(login),'${login}')&$top=1`;

    return this.http
    .put(url, {locations: [], departments: [], functions: []})
    .pipe(
      map((res) => res.value[0]),
      catchError((fault) => throwError(`Cannot get user with roles. ${fault}`))
    );
  }

  getNameSuggestions(suggestion): Observable<Array<UserSearch>> {
    const mainUrl = this.endpoints.ENDPOINT.ODATA + 'userprofile/odata/UserRole/getwithroles?$filter=';
    const restUrl = `${this.prepareFilters({name: suggestion})}`;

    return this.http
      .get(mainUrl + restUrl)
      .pipe(
        map(({value}) => value.slice(0, 5)),
        catchError((fault) => throwError(`Cannot get catalogs. ${fault}`))
      );
  }

  getIntranetCoordinators(
    {sort, filters, pageIndex, pageSize}: {
      sort: Sort,
      filters: { [key: string]: any },
      pageIndex: number,
      pageSize: number
    }
    ): Observable<{
    data: Array<UserRole>, pagination: Pagination
  }> {
    const mainUrl = this.endpoints.ENDPOINT.ODATA + 'userprofile/odata/AudienceManager/all';

    const taxonomies = {
      locations: filters.locations,
      departments: filters.departments,
      functions: filters.functions,
    };

    const queryFilters: {[key: string]: any} = {
      ...filters,
    };
    delete queryFilters.roles;
    delete queryFilters.locations;
    delete queryFilters.departments;
    delete queryFilters.functions;

    const filtersString = this.prepareFilters(queryFilters);
    const sortString = sort.active !== 'name' ? `${sort.active} ${sort.direction}` : `lastName ${sort.direction},firstName ${sort.direction}`;
    const prepParams = {
      filters : filtersString.length > 0 ? `$filter=${filtersString}&` : '',
      top: `$top=${pageSize}`,
      skip: pageSize * pageIndex > 0 ? `&$skip=${pageSize * pageIndex}` : '',
      sort: `&$orderBy=${sortString}`,
    };

    const url = `${mainUrl}?${prepParams.filters}${prepParams.top}${prepParams.skip}${prepParams.sort}&$count=true`;

    return this.http
    .put(url, taxonomies)
    .pipe(
      map((res) => ({
        data: res.value.map(result => ({
          ...result,
          locations: ((result.taxonomies) || []).locations || [],
          departments: ((result.taxonomies) || []).departments || [],
          functions: ((result.taxonomies) || []).functions || [],
        })),
        pagination: {
          isFirst: (pageSize * pageIndex) === 0,
          isLast: (((pageSize * pageIndex) / pageSize) + 1) * pageSize >= res['@odata.count'],
          pageCount: Math.ceil(res['@odata.count'] / pageSize),
          pageIndex: (pageSize * pageIndex) / pageSize,
          pageSize: pageSize,
          totalCount: res['@odata.count'],
        }
      })),
      catchError((fault) => throwError(`Cannot get user with roles. ${fault}`))
    );
  }

  getIntranetCoordinatorsNameSuggestions(suggestion): Observable<Array<UserSearch>> {
    const mainUrl = this.endpoints.ENDPOINT.ODATA + 'userprofile/odata/AudienceManager/all?$filter=';
    const restUrl = `${this.prepareFilters({name: suggestion})}`;

    return this.http
      .get(mainUrl + restUrl)
      .pipe(
        map(({value}) => value.slice(0, 5)),
        catchError((fault) => throwError(`Cannot get catalogs. ${fault}`))
      );
  }

  private prepareFilters(filters) {
    let transformedFilters = '';
    let index = 0;
    for (const [key, value] of Object.entries(filters)) {
      if (!!value && (!Array.isArray(value) || value.length)) {
        transformedFilters += this.prepareFilter(key, value, index);
        index++;
      }
    }
    return transformedFilters;
  }

  private prepareFilter(key, value, index) {
    let filter = '';
    if (index) {
      filter += ' and ';
    }
    switch (key) {
      case('isAdministrator'): {
        filter += `${key} eq true`;
        break;
      }
      case('isAudienceManager'): {
        filter += `${key} eq true`;
        break;
      }
      case('isContentQualityManager'): {
        filter += `${key} eq true`;
        break;
      }
      case('name'): {
        value = value.trim().toLowerCase();
        if (value.trim().includes(' ')) {
          const firstLastName = value.split(' ');
          filter += `contains(toLower(firstName), '${escapeApostrophe(firstLastName[0])}') and contains(toLower(lastName),`
          + `'${escapeApostrophe(firstLastName[1])}')`;
        } else {
          filter += `contains(toLower(firstName), '${escapeApostrophe(value)}') or contains(toLower(lastName),`
          + `'${escapeApostrophe(value)}') or contains(login, '${escapeApostrophe(value)}')`;
        }
        break;
      }
      case('search'): {
        value = value.trim().toLowerCase();
        if (value.trim().includes(' ')) {
          const firstLastName = value.split(' ');
          filter += `contains(toLower(firstName), '${escapeApostrophe(firstLastName[0])}') and contains(toLower(lastName),`
          + `'${escapeApostrophe(firstLastName[1])}')`;
        } else {
          filter += `contains(toLower(firstName), '${escapeApostrophe(value)}') or contains(toLower(lastName),`
          + `'${escapeApostrophe(value)}') or contains(login, '${escapeApostrophe(value)}')`;
        }
        break;
      }
      case('locations'): {
        filter += `locations/any(l: l in (${value.map((l) => '\'' + l + '\'').join(',')}))`;
        break;
      }
      case('departments'): {
        filter += `departments/any(d: d in (${value.map((d) => '\'' + d + '\'').join(',')}))`;
        break;
      }
      case('functions'): {
        filter += `functions/any(f: f in (${value.map((f) => '\'' + f + '\'').join(',')}))`;
        break;
      }
      default: {
        value = value.trim();
        filter += `contains(toLower(${key}), '${escapeApostrophe(value.toLowerCase())}')`;
        break;
      }
    }
    return filter;
  }

  constructor(
      private http: ApiService,
      private endpoints: EndpointsService,
    ) {
    }
}
