import { Pipe, PipeTransform } from '@angular/core';
import { RecipientListsTableModel } from '@app/core/models/newsletter.model';

enum FilterTypeEnum {
	ALL = 'all',
	NONE = 'none',
	PERSONALIZED = 'personalized',
	NO_PERSONALIZED = 'no_personalized',
}

@Pipe({
	name: 'recipientListFilter',
})
export class RecipientListFilterPipe implements PipeTransform {
	transform(
		value: Array<RecipientListsTableModel>,
		type: FilterTypeEnum = FilterTypeEnum.ALL
	): Array<RecipientListsTableModel> {
		switch (type) {
			case FilterTypeEnum.ALL:
				return value;
			case FilterTypeEnum.NONE:
				return [];
		}
	}
}
