<div [style.width]="width+'px'" [style.height]="height+'px'">
  <content-loader [baseUrl]="componentsLoaderService.baseUrl"
                  [backgroundColor]="'#EAE8E5'"
                  [foregroundColor]="'#DBD6D1'"
                  [viewBox]="viewBox"
                  [speed]="3"
                  [interval]="0.75"
                  [gradientRatio]="2.4">

    <svg:rect x="0" y="0" rx="0" [attr.width]="width" [attr.height]="height" />
  </content-loader>
</div>
