<div *ngIf="(isLoading$ | async) === false; else loader" class="py-4" [style.width]="width">
  <div *ngFor="let expressLink of expressLinks$ | async"></div>
  <ng-container *ngFor="let expressLink of expressLinks$ | async">
    <div class="ui-label-m py-3 px-5">
      <a rds-link [href]="expressLink.url" target="blank" standalone class="inline-standalone">
        <span [innerHTML]="expressLink.name | highlight : searchValue"></span>
      </a>
      <span *ngIf="expressLink.category && expressLink.category.name">
        in
        <span>{{expressLink.category.name}}</span>
      </span>
    </div>
  </ng-container>
  <rds-divider *ngIf="(expressLinks$ | async).length > 0" size="s" class="my-2"></rds-divider>
  <div class="py-3 px-4">
    <span class="ui-component-text-m text-low-contrast">Suggestions</span>
  </div>
  <ng-container *ngFor="let autocomplete of autocompletes$ | async">
    <div class="autocomplete py-3 px-5 ui-label-m" (click)="search(autocomplete)">
      <rds-icon class="me-5" icon="search"></rds-icon>
      <span [innerHTML]="autocomplete | highlight : searchValue"></span>
    </div>
  </ng-container>
</div>

<ng-template #loader>
  <div [style.width]="width">
    <rds-progress-bar
      mode="indeterminate"
    ></rds-progress-bar>
  </div>
</ng-template>
