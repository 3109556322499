import { NgModule, ModuleWithProviders } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { feature, userReducer } from './store/user.reducer';
import { UserEffects } from './store/user.effects';
import { SERVICES } from './services';

const EFFECTS = [
  UserEffects
];

@NgModule({
  imports: [
      StoreModule.forFeature(feature, userReducer),
      EffectsModule.forFeature(EFFECTS),
  ],
  declarations: [],
  exports: [],
  providers: []
})
export class UserModule {
  static forRoot(): ModuleWithProviders<UserModule> {
      return {
          ngModule: UserModule,
          providers: [
            ...SERVICES,
          ]
      };
  }
}
