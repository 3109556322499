import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';

import {EndpointsService} from '@app/core/services/endpoints/endpoints.service';
import {ApiService, HttpError} from '@app/core/services/api/api.service';

import {catchError, map} from 'rxjs/operators';
import {
  Channel,
  ContentDetails,
  ContentItem,
  ContentType,
  GetContentItemsResponse,
  Pagination,
  UserChannel,
  UserFavouriteSection
} from '../models';

export const ErrorContentConst = {
  cannotLandingPages: 'Cannot fetch landing pages'
}
@Injectable()
export class ContentService {

  getNewsFromChannelSubscription(language, pageSize, pageIndex, channelIds, sortType): Observable<{ local: { news: Array<ContentItem>, pagination: Pagination }, global: { news: Array<ContentItem>, pagination: Pagination }, pagination: Pagination }> {
    const url = this.endpoints.addParams(this.endpoints.ENDPOINT.CONTENT.ALL, {
      language,
      pageSize,
      pageIndex
    });

    return this.http.post(url, {
      channelIds,
      sortType
    }).pipe(
      map(response => {
         return this.prepareLocalAndGlobal(response);
        }
      ),
      catchError((error: HttpError) => throwError(`Cannot get channel news. ${error}`))
    );
  }

  getNewsFromAuthorSubscription(language, pageSize, pageIndex, authorIds, sortType): Observable<{ local: { news: Array<ContentItem>, pagination: Pagination }, global: { news: Array<ContentItem>, pagination: Pagination }, pagination: Pagination }> {
    const url = this.endpoints.addParams(this.endpoints.ENDPOINT.CONTENT.ALL, {
      language,
      pageSize,
      pageIndex
    });

    return this.http.post(url, {
      authorIds,
      sortType
    }).pipe(
      map(response => {
          return this.prepareLocalAndGlobal(response);
        }
      ),
      catchError((error: HttpError) => throwError(`Cannot get author news. ${error}`))
    );
  }

  getNewsFromTopicSubscription(language, pageSize, pageIndex, topics, sortType): Observable<{ local: { news: Array<ContentItem>, pagination: Pagination }, global: { news: Array<ContentItem>, pagination: Pagination }, pagination: Pagination }> {
    const url = this.endpoints.addParams(this.endpoints.ENDPOINT.CONTENT.ALL, {
      language,
      pageSize,
      pageIndex
    });

    return this.http.post(url, {
      topics,
      sortType
    }).pipe(
      map(response => {
          return this.prepareLocalAndGlobal(response);
        }
      ),
      catchError((error: HttpError) => throwError(`Cannot get topic news. ${error}`))
    );
  }


  public getChannelById(id: string): Observable<Channel> {
    const url = this.endpoints.replaceUrlTokens(
      this.endpoints.ENDPOINT.CONTENT.CHANNEL.DETAILS.GET, {id}
    );

    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get channel with id ${id}. ${error}`))
      );
  }

  public getAllChannels(): Observable<Array<Channel>> {
    const url = this.endpoints.ENDPOINT.CHANNEL.GET;

    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get channels. ${error}`))
      );
  }

  public getAllRelated(contentIds: Array<string>): Observable<{data: Array<ContentItem>}> {
    const url = this.endpoints.ENDPOINT.CONTENT.RELATED;
    const body = {
      contentIds
    }
    return this.http
      .put(url, body)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get related articles. ${error}`))
      );
  }

  public getAutoRelated(newsId: number, pagination: Pagination): Observable<{
    data: Array<ContentItem>,
    isFirst: boolean,
    isLast: boolean,
    pageCount: number,
    pageIndex: number,
    pageSize: number,
    totalCount: number}> {
    const url = this.endpoints.ENDPOINT.CONTENT.RELATED;
    const params = {pageIndex: pagination.pageIndex, pageSize: pagination.pageSize};

    const body = {
      newsId,
      contentIds: []
    }
    return this.http
      .put(this.endpoints.addParams(url, params), body)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get related articles. ${error}`))
      );
  }

  public findContentItemsByChannelId(
    channelId: string,
    index = 0,
    size = 4,
    language = ''
  ): Observable<GetContentItemsResponse> {
    const url = this.endpoints.ENDPOINT.CONTENT.GET;
    const params = {channelId, language, pageIndex: index, pageSize: size};

    return this.http
      .get(this.endpoints.addParams(url, params)).pipe(
        map(({data, pageSize, pageIndex, ...response}) => ({
          data,
          ...response,
          pageSize: +pageSize,
          pageIndex: +pageIndex,
        })),
      );
  }

  public getAllNewsForChannel(channel: Channel) {
    const url = this.endpoints.ENDPOINT.CONTENT.GET;
    const params = {channelId: channel.id, pageIndex: 0, pageSize: channel.newsCount};

    return this.http.get(this.endpoints.addParams(url, params)).pipe(
      map(({data}) => ({news: data}))
    );
  }

  getNewsDetails(contentId: string, language: string): Observable<ContentDetails> {
    const url = this.endpoints.ENDPOINT.CONTENT.DETAILS.GET;
    const params = language ? {contentId, language} : { contentId};

    return this.http.get(this.endpoints.addParams(url, params));
  }


  //OLD
  public findContentDetailsById(contentId: string): Observable<ContentDetails> {
    const url = this.endpoints.ENDPOINT.CONTENT.DETAILS.GET;
    const params = {contentId};

    return this.http
      .get(this.endpoints.addParams(url, params));
  }

  //OLD
  public findContentDetailsByIdAndLanguageCode(contentId: string, language: string): Observable<ContentDetails> {
    const url = this.endpoints.ENDPOINT.CONTENT.DETAILS.GET;
    const params = {contentId, language};

    return this.http
      .get(this.endpoints.addParams(url, params));
  }

  public putLikeByContentId({contentId}): Observable<{ result: boolean, numLikes: number }> {
    const url = this.endpoints.ENDPOINT.CONTENT.LIKE.PUT;
    const params = {contentId};

    return this.http
      .put(this.endpoints.addParams(url, params));
  }

  public removeLikeByContentId({contentId}): Observable<{ result: boolean, numLikes: number }> {
    const url = this.endpoints.ENDPOINT.CONTENT.LIKE.DELETE;
    const params = {contentId};

    return this.http
      .delete(this.endpoints.addParams(url, params));
  }

  public addBookmark({contentId}): Observable<ContentDetails> {
    const url = this.endpoints.ENDPOINT.CONTENT.BOOKMARK.PUT;
    const params = {contentId};

    return this.http
      .put(this.endpoints.addParams(url, params));
  }

  public removeBookmark({contentId}): Observable<ContentDetails> {
    const url = this.endpoints.ENDPOINT.CONTENT.BOOKMARK.DELETE;
    const params = {contentId};

    return this.http
      .delete(this.endpoints.addParams(url, params));
  }

  public addBookmarkOnlyById({contentId}): Observable<ContentDetails> {
    const url = this.endpoints.ENDPOINT.CONTENT.BOOKMARK.PUT;
    const params = {contentId: 'news:any:' + contentId.match(/(?:(?!-).)*/gi)[0]};

    return this.http
      .put(this.endpoints.addParams(url, params));
  }

  public removeBookmarkOnlyBydId({contentId}): Observable<ContentDetails> {
    const url = this.endpoints.ENDPOINT.CONTENT.BOOKMARK.DELETE;
    const params = {contentId: 'news:any:' + contentId.match(/(?:(?!-).)*/gi)[0]};

    return this.http
      .delete(this.endpoints.addParams(url, params));
  }

  public markRead(contentId) {
    const url = this.endpoints.ENDPOINT.CONTENT.MARK_READ.PUT;
    const params = {contentId};

    return this.http
      .put(this.endpoints.addParams(url, params));
  }

  public getLatestNews(language = ''): Observable<ContentItem[]> {
    const url = this.endpoints.ENDPOINT.CONTENT.LATEST.GET;

    return this.http.get(this.endpoints.addParams(url, {language}));
  }

  public getLatestNewsForLandingPage(language = '', urlPath: string): Observable<ContentItem[]> {
    const url = this.endpoints.ENDPOINT.CONTENT.LATEST_FOR_LANDING_PAGE.GET;

    return this.http.get(this.endpoints.addParams(url, {urlPath}));
  }

  public getContentTypes(): Observable<ContentType[]> {
    const url = this.endpoints.ENDPOINT.CONTENT.TYPE.GET;

    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get content types. ${error.message}`))
      );
  }

  public getUserChannels(): Observable<UserChannel[]> {
    const url = this.endpoints.ENDPOINT.CONTENT.USER_CHANNEL.GET;

    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpError) => throwError('Cannot get User channels'))
      );
  }

  public putUserChannels(channels: UserChannel[]): Observable<UserChannel[]> {
    const url = this.endpoints.ENDPOINT.CONTENT.USER_CHANNEL.PUT;
    const body = channels;

    return this.http
      .put(url, body)
      .pipe(
        catchError((error: HttpError) => throwError('Cannot update User channels'))
      );
  }

  public putResetChannels(): Observable<UserChannel[]> {
    const url = this.endpoints.ENDPOINT.CONTENT.USER_CHANNEL.RESET.PUT;

    return this.http
      .put(url)
      .pipe(
        catchError((error: HttpError) => throwError('Cannot update User channels'))
      );
  }

  public getUserFavouriteSections(): Observable<UserFavouriteSection[]> {
    const url = this.endpoints.ENDPOINT.CONTENT.USER_CHANNEL.FAVOURITES.GET;

    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpError) => throwError('Cannot get User favourite channels'))
      );
  }

  public putFilterAllChannels(filters: { name: string; departments: string[]; locations: string[]; functions: string[] }) {
    const url = this.endpoints.ENDPOINT.CONTENT.CHANNEL.FILTER_ALL.PUT;

    return this.http
      .put(url, filters)
      .pipe(
        map(((channels: Array<Channel>) => channels.sort((a, b ) => a.name.localeCompare(b.name)) )),
        catchError((error: HttpError) => throwError('Cannot get User faourite channels'))
      );
  }

  public getMandatoryChannels(): Observable<UserChannel[]> {
    const url = this.endpoints.ENDPOINT.CONTENT.MANDATORY_CHANNELS.GET;

    return this.http
      .get(url)
      .pipe(
        map(channels => channels.map((channel, index) => ({
          ...channel,
          isMandatory: true,
          isFavorite: index < 5,
          favoriteOrder: (index < 5) ? index : null,
          order: index,
        }))),
        catchError((errer: HttpError) => throwError('Cannot mandatory channels'))
      );
  }

  private prepareLocalAndGlobal(response) : {pagination: Pagination, local: {news: ContentItem[], pagination: Pagination}, global: {news: ContentItem[], pagination: Pagination}} {
    const localNews = response.local?.data || [];
    const globalNews = response.global?.data || [];
    const localPagination = response.local || {...Pagination, isLast: true};
    const globalPagination = response.global || {...Pagination, isLast: true};
    return {
      pagination: this.preparePaginationLocalGlobal(localPagination,globalPagination),
      local: {news: localNews, pagination: localPagination},
      global: {news: globalNews, pagination: globalPagination}
    }
  }

  private preparePaginationLocalGlobal(localPagination, globalPagination) {
    return {
      isFirst: localPagination.isFirst && globalPagination.isFirst,
      isLast: localPagination.isLast && globalPagination.isLast,
      pageCount: localPagination.pageCount > globalPagination.pageCount ? localPagination.pageCount : globalPagination.pageCount,
      pageIndex: localPagination.pageIndex > globalPagination.pageIndex ? localPagination.pageIndex : globalPagination.pageIndex,
      pageSize: localPagination.pageSize > globalPagination.pageSize ? localPagination.pageSize : globalPagination.pageSize,
      totalCount: localPagination.totalCount > globalPagination.totalCount ? localPagination.totalCount : globalPagination.totalCount
    }
  }

  constructor(
    private readonly http: ApiService,
    private readonly endpoints: EndpointsService,
  ) {
  }

}
