import {createSelector} from '@ngrx/store';
import * as fromReducer from './user-news-articles.reducer';
import * as fromWorkdayUser from '../workday-user.reducer';
import * as fromUser from '@core/user/store/user.selectors';

export const selectUserNewsArticlesState = createSelector(
  fromWorkdayUser.selectState,
  fromWorkdayUser.getUserNewsArticles
);

export const selectId = createSelector(
  selectUserNewsArticlesState,
  fromReducer.getValue
)

export const selectPagination = createSelector(
  selectUserNewsArticlesState,
  ({pageIndex, pageSize}) => {
    return {
      pageIndex, pageSize
    }
  }
)

export const selectNews = createSelector(
  selectUserNewsArticlesState,
  fromReducer.getNews,
);

export const selectData = createSelector(
  selectNews,
  fromReducer.selectAll
);

export const selectIsLoading = createSelector(
  selectUserNewsArticlesState,
  fromReducer.getLoading
)

export const selectIsLast = createSelector(
  selectUserNewsArticlesState,
  fromReducer.getIsLast
)

export const selectName = createSelector(
  selectUserNewsArticlesState,
  fromReducer.getName
)
export const selectSortType = createSelector(
  selectUserNewsArticlesState,
  fromReducer.getSortType
)
export const selectIsMandatory = createSelector(
  selectUserNewsArticlesState,
  fromReducer.getIsMandatory
)

export const selectRequestData = createSelector(
  selectPagination,
  selectSortType,
  fromUser.selectNewsLanguageCode,
  ({pageIndex, pageSize}, sortType, language) => ({
    pageIndex,
    pageSize,
    language,
    sortType
  })
);