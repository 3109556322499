import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Channel, NewsletterNews } from '@app/core/models';
import { DEFAULT_BLOCK_SETTINGS, NewsBlockProperties } from '@app/newsletter-new/models/block-type.enum';
import { BlockTypes } from '@app/newsletter/inline-editor/models/block-type.enum';
import * as fromChannels from '@core/core-store/channels';
import * as fromChannelsNews from '@core/core-store/channels-news-all-language';
import * as fromLanguages from '@core/core-store/languages-dict';
import { Store, select } from '@ngrx/store';
import { RDS_DIALOG_DATA, RdsDialogRef, RdsSingleSelectFilterByFunc, RdsSingleSelectOptionComponent } from '@rds/angular-components';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { SubSink } from 'subsink';

export interface SelectNewsDialogData {
  id: number;
  blockType: BlockTypes;
  addNewAsCard?: boolean;
  settings: NewsBlockProperties;
}

@Component({
  selector: 'rh-select-news-dialog',
  templateUrl: './select-news-dialog.component.html',
  styleUrls: ['./select-news-dialog.component.scss']
})
export class SelectNewsDialogComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  public channels$: Observable<Array<Channel>> = this.store$.pipe(select(fromChannels.selectAllNotEmpty));

  newsForm: FormGroup = new FormGroup({
    numberOfNews: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(10)]),
    insertOption: new FormControl(0),
    news: new FormArray([])
  });

  get newsArray() {
    return (this.newsForm.controls.news as FormArray);
  }

  constructor(
    private dialogRef: RdsDialogRef<SelectNewsDialogComponent>,
    @Inject(RDS_DIALOG_DATA) public data: SelectNewsDialogData,
    private cdr: ChangeDetectorRef,
    private store$: Store<any>
  ) { }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.store$.dispatch(fromLanguages.loadLanguagesDict())
    this.createNewsForm(this.data.settings);
    this.subs.sink = this.newsForm.controls.numberOfNews.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(300),
        filter(() => this.newsForm.controls.numberOfNews.valid),
      ).subscribe(numberOfNews => {
      const currentNewsLength = this.newsArray.length;
      if (numberOfNews < currentNewsLength) {
        for (let i = currentNewsLength; i >= numberOfNews; i--) {
          this.newsArray.removeAt(i);

        }
      }
      if (numberOfNews > currentNewsLength) {
        for (let i = 0; i < numberOfNews - currentNewsLength; i++) {
          const defaultSetting = {
            ...DEFAULT_BLOCK_SETTINGS[BlockTypes.NEWS],
            card: this.data.addNewAsCard
          };
          this.createNewsForm(defaultSetting);
        }
      }
      this.cdr.markForCheck();
    });
  }

  addNews() {
    const defaultSetting = {
      ...DEFAULT_BLOCK_SETTINGS[BlockTypes.NEWS],
      card: this.data.addNewAsCard
    };
    this.createNewsForm(defaultSetting);
  }

  removeNews(i) {
    this.newsArray.removeAt(i);
  }

  channelChange(channelId, index) {
    if (channelId.length) {
    this.store$.dispatch(fromChannelsNews.addChannel({channelId}));
    this.newsArray.at(index).enable()
    this.newsArray.at(index).patchValue({news: null})
    } else {
      this.newsArray.at(index).disable()
    }
  }

  newsChange(news, index) {
    const newsFormGroup = this.newsArray.at(index) as FormGroup;
    newsFormGroup.controls.newsLanguage.setValue(news.primaryLanguage);
    this.cdr.detectChanges();
  }

  submit() {
    this.dialogRef.close({
      ...this.data,
      settings: this.newsForm.controls.news.value.map(e => ({
        ...e.settings,
        news: e.news,
        newsLanguage: e.newsLanguage
      } as NewsBlockProperties)),
      insertOption: this.newsForm.controls.insertOption.value
    });
  }
     
  filterBy: RdsSingleSelectFilterByFunc<string> = (
    text: string | null,
    item: RdsSingleSelectOptionComponent<string>
  ) => {
    if (text === null || text === "") {
      return true;
    } else {
      return item.viewValue.toLowerCase().includes(text.toLowerCase());
    }
  };

  filterByNews: RdsSingleSelectFilterByFunc<NewsletterNews> = (
    text: string | null,
    item: RdsSingleSelectOptionComponent<NewsletterNews>
  ) => {
    if (text === null || text === "") {
      return true;
    } else {
      const primaryAbstract = item.value.abstracts[item.value.primaryLanguage];
      return (primaryAbstract.title).toLowerCase().includes(text.toLowerCase()) || (primaryAbstract.abstract).toLowerCase().includes(text.toLowerCase())
    }
  };
  
  createNewsForm(settings: NewsBlockProperties) {
    this.newsArray.push(
      new FormGroup({
        channelId: new FormControl(settings.news?.channelId, [Validators.required]),
        news: new FormControl({value: settings.news, disabled: !settings.news}, [Validators.required]),
        newsLanguage: new FormControl(settings.newsLanguage),
        setings: new FormControl(settings)
      })
    );
  }


  log(something) {
  }
}
