import { Pagination, UserRole } from '@app/core/models';
import { AssignRoleTableFilters } from '@app/shared/filters/models/assign-role-table';
import { createAction, props } from '@ngrx/store';
import { SortDirection } from '@rds/angular-components';


export const initTable = createAction(
  '[ASSIGN ROLE] Initialize Channels'
);

export const clearAll = createAction(
  '[ASSIGN ROLE] Clear all'
);

export const getTableRequest = createAction(
  '[ASSIGN ROLE] Get channels Request',
  props<{
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
    filters: {[key: string]: any},
  }>()
);

export const getTableSuccess = createAction(
  '[ASSIGN ROLE] Get channels Success',
  props<{ data: Array<UserRole>, pagination: Pagination }>()
);

export const getTableFailure = createAction(
  '[ASSIGN ROLE] Get channels Failure',
  props<{ error: any }>()
);

export const changeTableFilters = createAction(
  '[ASSIGN ROLE] Change channels filters',
  props<{ filters: AssignRoleTableFilters }>()
);

export const changeTableSort = createAction(
  '[ASSIGN ROLE] Change channels sort',
  props<{ active: string, direction: SortDirection }>()
);

export const changeTablePagination = createAction(
  '[ASSIGN ROLE] Change channels pagination',
  props<{ pageIndex: number, pageSize: number }>()
);

export const deleteUserRoleRequest = createAction(
  '[ASSIGN ROLE] Delete User role Request',
  props<{login: string}>()
);

export const deleteUserRoleSuccess = createAction(
  '[ASSIGN ROLE] Delete channels Success',
);

export const deleteUserRoleFailure = createAction(
  '[ASSIGN ROLE] Delete channels Failure',
  props<{ error: any }>()
);