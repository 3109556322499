import { createReducer, Action, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';

import { Category } from '@core/models/category.model';
import * as fromActions from '@core/core-store/categories-nested/categories-nested.actions';

export interface State extends EntityState<Category> {}

export const adapter: EntityAdapter<Category> =
  createEntityAdapter<Category>({
    selectId: category => category.categoryId
});

export const initialState: State = {
  ...adapter.getInitialState(),
};

const categoriesNestedReducer = createReducer(
  initialState,
  on(fromActions.categoriesNestedCollectionAddMany, (state, {categoriesNested}) => (
    adapter.addMany(categoriesNested, state)
  )),
);

export function reducer(state: State | undefined, action: Action) {
  return categoriesNestedReducer(state, action);
}

export const getIds = adapter.getSelectors().selectIds;
export const getEntities = adapter.getSelectors().selectEntities;
export const getAll = adapter.getSelectors().selectAll;
export const getTotal = adapter.getSelectors().selectTotal;

export const getEntityById = (entities: Dictionary<Category>, {id}) => entities[id];
export const getEntitiesById = (entities: Dictionary<Category>, {ids}) => ids.map(id => entities[id]);

