import {Action, createFeatureSelector, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import * as fromActions from '@app/events/store/top/top.actions';
import { LoadingState } from '@app/search/models/rh';

export const feature = 'top';

export const adapter: EntityAdapter<any> =
  createEntityAdapter<any>();

export interface State {
  events: Array<any>,
  loading: LoadingState
}

export const initialState: State = {
  events: [],
  loading: LoadingState.LOADING_ALL
};

export const topReducer = createReducer(
  initialState,
  on(fromActions.getTopEventsRequest, (state) => ({
    ...state,
    loading: LoadingState.LOADING_ALL
  })),
  on(fromActions.getTopEventsSuccess, (state, {events}) => ({
    ...state,
    events,
    loading: LoadingState.FINISHED
  })),
  on(fromActions.getTopEventsFailure, (state) => ({
    ...state,
    loading: LoadingState.FINISHED
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return topReducer(state, action);
}

export const selectState = createFeatureSelector<State>(feature);
export const getEvents = (state: State) => state.events;
export const getLoading = (state: State) => state.loading;
