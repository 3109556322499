import {NgModule} from '@angular/core';
import {SharedModule} from '@shared/shared.module';
import {COMPONENTS} from './components';
import {feature, reducer} from './store/header.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {HeaderService} from '@app/layout/header/services/header.service';
import {OverlayModule} from '@angular/cdk/overlay';
import {HeaderComponents2} from '@app/layout/header/components/header/header.component';

import {HeaderEffects} from '@app/layout/header/store/header/header.effects';
import {HeaderMenuComponent} from './components/header-menu/header-menu.component';
import {HeaderGcsMenuContainer} from './components/header-gcs-menu/header-gcs-menu.container';
import {HeaderAppMenuContainer} from './components/header-app-menu/header-app-menu.container';
import {HeaderSuggestionsComponent} from './components/header-suggestions/header-suggestions.component';
import {QuickLinkComponent} from '@app/layout/header/components/quick-link-component/quick-link-container.component';
import {HeaderContentComponent} from '@app/layout/header/components/header-content/header-content.component';
import {
  QuickLinksPaginatorComponent
} from '@app/layout/header/components/quick-links-paginator/quick-links-paginator.component';
import {CatalogIconsComponent} from '@app/layout/header/components/catalog-icons/catalog-icons/catalog-icons.component';
import {
  HeaderContentDetailsComponent
} from '@app/layout/header/components/header-content-details/header-content-details.component';
import {QuickLinkEffects} from './store/quick-link';
import {CatalogsEffects} from './store/catalogs';
import {CatalogsInCategoryContainer} from './components/catalogs-in-category/catalogs-in-category.container';
import {HeaderMobileComponent} from './components/header-mobile/header-mobile.component';
import {HeaderMobileMenuComponent} from './components/header-mobile-menu/header-mobile-menu.component';
import {HeaderMobileGcsMenuComponent} from './components/header-mobile-gcs-menu/header-mobile-gcs-menu.component';
import {
  HeaderMobileSuggestionsComponent
} from './components/header-mobile-suggestions/header-mobile-suggestions.component';
import {
  ReorganizeQuickLinksDialogComponent
} from './components/reorganize-quicklinks-dialog/reorganize-quicklinks-dialog.component';
import {ResetQuicklinksDialogComponent} from './components/reset-quicklinks-dialog/reset-quicklinks-dialog.component';


@NgModule({
  imports: [
    OverlayModule,
    SharedModule,
    StoreModule.forFeature(feature, reducer),
    EffectsModule.forFeature([HeaderEffects, QuickLinkEffects, CatalogsEffects]),
  ],
  declarations: [
    ...COMPONENTS,
    HeaderMenuComponent,
    HeaderGcsMenuContainer,
    HeaderAppMenuContainer,
    HeaderSuggestionsComponent,
    QuickLinkComponent,
    QuickLinksPaginatorComponent,
    HeaderContentComponent,
    CatalogIconsComponent,
    HeaderContentDetailsComponent,
    CatalogsInCategoryContainer,
    HeaderMobileComponent,
    HeaderMobileMenuComponent,
    HeaderMobileGcsMenuComponent,
    HeaderMobileSuggestionsComponent,
    ReorganizeQuickLinksDialogComponent,
    ResetQuicklinksDialogComponent
  ],
  exports: [
    HeaderComponents2,
    HeaderMobileComponent,
    QuickLinkComponent
  ],
  providers: [
    HeaderService
  ]
})
export class HeaderModule2 {
}
