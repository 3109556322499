import {createSelector} from '@ngrx/store';
import * as fromReducer from './form.reducer';
import * as fromManageEvents from '../news.reducer';
import {NewsCreation, NewsUpdate} from '@app/core/models/newsboard';
import {toDateString} from '@app/core/services/utils.service';

export const selectState = createSelector(
  fromManageEvents.selectState,
  fromManageEvents.getForm
);

export const selectForm = createSelector(
  selectState,
  fromReducer.getForm
);

export const selectFormChecked = createSelector(
  selectState,
  fromReducer.getFormChecked
);

export const selectInitialForm = createSelector(
  selectState,
  fromReducer.getInitialForm
);

export const selectUserRole = createSelector(
  selectState,
  fromReducer.getUserRole
);

export const selectCreateFromTemplate = createSelector(
  selectState,
  fromReducer.getCreateFromTemplate
);

export const selectNewsStatus = createSelector(
  selectState,
  fromReducer.getNewsStatus
);

export const selectFillFormStartedAt = createSelector(
  selectState,
  fromReducer.getFillFormStartedAt
);

export const selectOriginalPublishDate = createSelector(
  selectInitialForm,
  (form) => {
    if (!!form?.publishDate) {
      return new Date(form.publishDate);
    }
    return null
  }
);

export const selectDatesForValidation = createSelector(
  selectFillFormStartedAt,
  selectOriginalPublishDate,
  (fillFormStartedAt, originalPublishDate) => ({fillFormStartedAt, originalPublishDate})
);


export const selectId = createSelector(
  selectForm,
  fromReducer.getId
);

export const selectType = createSelector(
  selectForm,
  fromReducer.getType
);

export const selectLanguages = createSelector(
  selectForm,
  fromReducer.getLanguages
);

export const selectPrimaryLanguageTitle = createSelector(
  selectForm,
  fromReducer.getPrimaryLanguageTitle
);

export const selectPrimaryLanguage = createSelector(
  selectForm,
  fromReducer.getPrimaryLanguage
);

export const selectPrimaryLanguageImage = createSelector(
  selectForm,
  fromReducer.getPrimaryLanguageImage
);

export const selectAssignableChannels = createSelector(
  selectState,
  fromReducer.getAssignableChannels
);

export const selectSuggestableChannels = createSelector(
  selectState,
  fromReducer.getSuggestableChannels
);

export const selectCreateFormForRequest = (isDraft: boolean = false) => createSelector(
  selectForm,
  (form): NewsCreation => ({
    content: form?.content,
    isDraft,
    contentType: form?.type,
    authors: form?.authors,
    topics: form?.topics,
    websiteLinkUrl: form?.websiteLink?.url,
    websiteLinkName: form?.websiteLink?.name,
    byline: form?.byline,
    contact: form?.contact,
    publishDate: (form?.publishTime && form?.publishDate) ? toDateString(new Date(form.publishDate), form.publishTime) : null,
    publishTimeZone: form?.timeZone,
    expiryDate: (form?.expiryTime && form?.expiryDate) ? toDateString(new Date(form.expiryDate), form.expiryTime) : null,
    expiryTimeZone: form?.timeZone,
    language: form?.language,
    commentsEnabled: form?.type === 'Embedded' && form?.commentsEnabled,
    commentsHidden: form?.type === 'Embedded' && form?.commentsHidden,
    assign: form?.assignement?.assign,
    suggest: form?.assignement?.suggest,
    owners: form?.permissions?.owners,
    editors: form?.permissions?.editors,
    relatedNews: form?.related
  })
);

export const selectUpdateFormForRequest = (isDraft: boolean = false) => createSelector(
  selectForm,
  selectInitialForm,
  (form, initialForm): NewsUpdate => {
    const contentDelete = initialForm?.content?.reduce((acc, item) => {
      if (form?.content?.findIndex(c => c.language === item.language) < 0) {
        acc.push(item.language)
      }
      return acc
    }, []);

    const contentCreate = form?.content?.reduce((acc, item) => {
      if (initialForm?.content.findIndex(c => c.language === item.language) < 0) {
        acc.push(item)
      }
      return acc
    }, []);

    const contentUpdate = form?.content?.reduce((acc, item) => {
      const initialContent = initialForm?.content?.find(c => c.language === item.language);

      if (!!initialContent && JSON.stringify(initialContent) !== JSON.stringify(item)) {
        acc.push(item)
      }
      return acc
    }, []);

    return {
      contentDelete,
      contentUpdate,
      contentCreate,
      isDraft,
      authors: form?.authors,
      topics: form?.topics,
      websiteLinkUrl: form?.websiteLink?.url,
      websiteLinkName: form?.websiteLink?.name,
      byline: form?.byline,
      contact: form?.contact,
      publishDate: (form?.publishTime && form?.publishDate) ? toDateString(new Date(form.publishDate), form.publishTime) : null,
      publishTimeZone: form?.timeZone,
      expiryDate: (form?.expiryTime && form?.expiryDate) ? toDateString(new Date(form.expiryDate), form.expiryTime) : null,
      expiryTimeZone: form?.timeZone,
      language: form?.language,
      commentsEnabled: form?.type === 'Embedded' && form?.commentsEnabled,
      commentsHidden: form?.type === 'Embedded' && form?.commentsHidden,
      assign: form?.assignement?.assign,
      suggest: form?.assignement?.suggest,
      owners: form?.permissions?.owners,
      editors: form?.permissions?.editors,
      relatedNews: form?.related,
      updateMetaData: true,
      updateRelatedNews: true
    }
  }
);

export const selectGeneralForm = createSelector(
  selectForm,
  (form) => ({
    id: form?.id,
    content: form?.content.filter(c => c.language === form.language),
    type: form?.type,
    authors: form?.authors,
    byline: form?.byline,
    contact: form?.contact,
    language: form?.language,
    commentsEnabled: form?.commentsEnabled,
    commentsHidden: form?.commentsHidden
  })
);

export const selectContentForm = createSelector(
  selectForm,
  (form) => ({
    type: form?.type,
    content: form?.content,
    websiteLink: form?.websiteLink,
    topics: form?.topics,
    related: form?.related
  })
);

export const selectPublishingForm = createSelector(
  selectForm,
  (form) => ({
    assignement: form?.assignement,
    timeZone: form?.timeZone,
    publishDate: form?.publishDate,
    publishTime: form?.publishTime,
    expiryDate: form?.expiryDate,
    expiryTime: form?.expiryTime
  })
);

export const selectPermissionsForm = createSelector(
  selectForm,
  (form) => ({
    permissions: form?.permissions
  })
);

export const selectGeneralStepStatus = createSelector(
  selectState,
  fromReducer.getGeneralStepStatus
);

export const selectGeneralFormStatus = createSelector(
  selectState,
  fromReducer.getGeneralFormStatus
);

export const selectContentStepStatus = createSelector(
  selectState,
  fromReducer.getContentStepStatus
);

export const selectContentFormStatus = createSelector(
  selectState,
  fromReducer.getContentFormStatus
);

export const selectPublishingStepStatus = createSelector(
  selectState,
  fromReducer.getPublishingStepStatus
);

export const selectPublishingFormStatus = createSelector(
  selectState,
  fromReducer.getPublishingFormStatus
);

export const selectPermissionsStepStatus = createSelector(
  selectState,
  fromReducer.getPermissionsStepStatus
);

export const selectPermissionsFormStatus = createSelector(
  selectState,
  fromReducer.getPermissionsFormStatus
);

export const selectChangesMade = createSelector(
  selectForm,
  selectInitialForm,
  (form, initialForm) => JSON.stringify(form) !== JSON.stringify(initialForm)
);

export const canSaveAsDraft = createSelector(
  selectForm,
  selectChangesMade,
  (form, changesMade) => form?.content?.every(c => !!c.title) && changesMade && form?.permissions?.owners?.length > 0
);

export const canShowPreview = createSelector(
  selectForm,
  (form) => form?.content?.every(c => !!c.title)
);

export const canUnpublish = createSelector(
  selectForm,
  selectNewsStatus,
  (form, status) => form?.content?.every(c => !!c.title) && status === 'Published'
);

export const canSaveAsTemplate = createSelector(
  selectForm,
  (form) => form?.content?.every(c => !!c.title && !!c.abstract && !!c.html) && !!form.title
);

export const canPublish = createSelector(
  selectGeneralFormStatus,
  selectContentFormStatus,
  selectPublishingFormStatus,
  selectPermissionsFormStatus,
  selectNewsStatus,
  selectChangesMade,
  (general, content, publishing, permissions, status, changesMade) => {
    const areFormsValid = general === 'VALID' && content === 'VALID' && publishing === 'VALID' && permissions === 'VALID';
    return areFormsValid && (changesMade || status === 'Draft')
  }
);

export const selectIsTouched = createSelector(
  selectState,
  fromReducer.getTouched
);

export const selectIsRequestSent = createSelector(
  selectState,
  fromReducer.getRequestSent
);
