import { ListType } from '@app/newsletter-new/store/recipients-table';

export interface RhRecipientsListGeneral {
	name: string;
	description: string;
}

export interface RhRecipientsListRecipientsList {
	recipientRada: Array<any>;
	recipientUsers: Array<any>;
}

export interface RhRecipientsListBlock {
	blockRada: Array<any>;
	blockUsers: Array<any>;
}

export interface RhRecipientsListRequestBodyRecipients {
	email: string;
	name: string;
	surname: string;
	included: boolean;
	type: 0 | 1;
}

export interface RhRecipientsListRequestBodyBlocked {
	email: string;
	name: string;
	type: 0 | 1;
	blocked: boolean;
}

export interface RhRecipientsListRequestBody {
	id?: number;
	name: string;
	description: string;
	recipients: RhRecipientsListRequestBodyRecipients[];
	blockedRecipients: RhRecipientsListRequestBodyBlocked[];
	editors: any[];
	campaigns: any[];
}

export interface RhRecipientsListForm
	extends RhRecipientsListGeneral,
		RhRecipientsListRecipientsList,
		RhRecipientsListBlock {
	id: number;
	status: ListType;
}

export const EMPTY_RECIPIENTS_LIST_FORM: Partial<RhRecipientsListForm> = {
	id: null,
	name: null,
	description: null,
	recipientRada: [],
	recipientUsers: [],
	blockRada: [],
	blockUsers: [],
	status: 0,
};

export enum RecipientsListSteps {
	GENERAL_INFORMATION = 'GENERAL_INFORMATION',
	RECIPIENTS_LIST = 'RECIPIENTS_LIST',
	BLOCK_USERS = 'BLOCK_USERS',
}

export enum RECIPIENTS_LIST_TYPE {
	RECIPIENT = 'recipientUsers',
	BLOCKED = 'blockUsers',
}

export enum TYPE_RECIPIENT {
	SINGLE = 0,
	GROUP = 1,
}
