import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'rh-event-header',
  styleUrls: ['./event-header.component.scss'],
  templateUrl: './event-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('main', [
      state('hovered', style({ position: 'relative', overflow: 'hidden', })),
      state('unhovered', style({ position: 'relative', overflow: 'hidden', })),
    ]),
    trigger('rect', [
      state('hovered', style({
        position: 'relative',
        width: '100%',
        maxWidth: 'max-content',
        height: '28px',
        backgroundColor: '#7D0096',
        color: 'white',
        borderRadius: '24px',
        padding:' 0 12px',
        zIndex: 2,
      })),
      state('unhovered', style({
        position: 'relative',
        width: '28px',
        maxWidth: 'max-content',
        height: '28px',
        borderRadius: '24px',
        backgroundColor: '#7D0096',
        color: '#7D0096',
        padding:'0',
        zIndex: 1,
      })),
      transition('hovered => unhovered', [ animate('150ms cubic-bezier(0.4, 0, 0.2, 1)') ]),
      transition('unhovered => hovered', [ animate('150ms 150ms cubic-bezier(0.4, 0, 0.2, 1)') ]),
    ]),
    trigger('square', [
      state('unhovered', style({
        position: 'relative',
        marginTop:'-68px',
        width: '68px',
        height: '68px',
        backgroundColor: '#7D0096',
        color: 'white',
        borderRadius: '4px',
        zIndex: 2,
      })),
      state('hovered', style({
        position: 'relative',
        marginTop:'-68px',
        width: '28px',
        height: '28px',
        borderRadius: '50%',
        backgroundColor: '#7D0096',
        color: '#7D0096',
        zIndex: 1,
      })),
      transition('hovered => unhovered', [ animate('150ms 150ms cubic-bezier(0.4, 0, 0.2, 1)') ]),
      transition('unhovered => hovered', [ animate('150ms cubic-bezier(0.4, 0, 0.2, 1)') ]),
    ]),
    trigger('rectTitle', [
      state('hovered', style({ position: 'relative', zIndex: 0, opacity: 0, transform: 'translateX(-100%)' })),
      state('unhovered', style({ position: 'relative', zIndex: 0, opacity: 1, marginTop:'-28px', marginLeft: '80px', })),
      transition('hovered => unhovered', [ animate('150ms 150ms cubic-bezier(0.4, 0, 0.2, 1)') ]),
      transition('unhovered => hovered', [ animate('150ms cubic-bezier(0.4, 0, 0.2, 1)') ]),
    ]),

    trigger('squareTitle', [
      state('hovered', style({
        position: 'relative',
        zIndex: 0,
        opacity: 1,
        marginTop: '16px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      })),
      state('unhovered', style({
        position: 'relative',
        zIndex: 0,
        opacity: 0,
        transform: 'translateY(-100%)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      })),
      transition('hovered => unhovered', [ animate('150ms cubic-bezier(0.4, 0, 0.2, 1)') ]),
      transition('unhovered => hovered', [ animate('150ms 150ms cubic-bezier(0.4, 0, 0.2, 1)')]),
    ]),
  ],
})
export class EventHeaderComponent {
  @Input() date!: Date;
  @Input() hovered: boolean = false;
  @Input() title!: string;
}
