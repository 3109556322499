<rds-header [class.any-menu-opened]="appMenuOpened || gcsMenuOpened" #header (logoTriggered)="logoClick()" (search)="search($event)" (searchChange)="searchChange($event)">
  <rds-header-actions>
    <rds-icon
    [style]="appMenuOpened ? 'opacity: 0; pointer-events: none' : ''"
    namespace="filled"
    [icon]="gcsMenuOpened ? 'close' : 'app_launcher'"
    [@menu]="gcsMenuOpened ? 'end' : 'start'"
    (click)="gcsMenuOpened? closeGcsMenu() : openGcsMenu()"
></rds-icon>
      <rds-icon
        [style]="gcsMenuOpened ? 'opacity: 0; pointer-events: none' : ''"
        namespace="filled"
        [icon]="appMenuOpened ? 'close' : 'menu'"
        [@menu]="appMenuOpened ? 'end' : 'start'"
        (click)="appMenuOpened? closeAppMenu() : openAppMenu()"
    ></rds-icon>
  </rds-header-actions>
  <!-- <rds-header-navigation-secondary> 
      <ng-container *ngFor="let link of bottomLinks">
        <rds-header-link rds-dropdown-toggle-button
          class="bottom-link"
          [rdsMenuTriggerFor]="panel.menu"
          #bottomTrigger="rdsMenuTrigger"
          [active]="bottomTrigger.menuOpen">
            {{link.link}}
        </rds-header-link>
      <rh-header-menu
        style="display: none"
        #panel
        [links]="link.nodes"
      >
      </rh-header-menu>
    </ng-container>
    <ng-container *ngFor="let link of topLinks">
      <rds-header-link rds-dropdown-toggle-button
        [rdsMenuTriggerFor]="panel.menu"
        #trigger="rdsMenuTrigger"
        [active]="trigger.menuOpen">
          {{link.link}}
      </rds-header-link>
      <rh-header-menu
        style="display: none"
        #panel
        [links]="link.nodes"
      >
      </rh-header-menu>
  </ng-container>
  </rds-header-navigation-secondary> -->
</rds-header>
<rh-header-mobile-menu [isOpen]="appMenuOpened" [links]="allLinks" (shouldClose)="closeAppMenu()">

</rh-header-mobile-menu>
<rh-header-mobile-gcs-menu [isOpen]="gcsMenuOpened">

</rh-header-mobile-gcs-menu>
<rh-header-mobile-suggestions [isOpen]="suggestionsOpened" [searchValue]="header?.searchbar?.searchValue" (closeSuggestions)="closeSuggestions()" >

</rh-header-mobile-suggestions>