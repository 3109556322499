import { TagTypes } from '@app/shared/utils/get-ses-tags';

export interface UserSharedProfile {
	newsletterId: number;
	login: string;
	firstName: string;
	lastName: string;
	email: string;
	photoUrl: string;
}

export interface NewsletterTableModel {
	campaign: string;
	displayName: string;
	edited: Date;
	id: number;
	newsletterContributors: UserSharedProfile[];
	publishingDate: Date;
	status: number;
	title: string;
}

export interface NewsletterListItemModel {
	description: string;
	displayName: string;
	id: number;
	imagePreviewUrl: string;
	lastModifiedDate: string;
	newsletterContributors: UserSharedProfile[];
	lastModifiedBy: UserSharedProfile;
	numberOfClicks: number;
	opened: number;
	scheduleSentDate: string;
	templateId: string;
	title: string;
	statusId?: number;
}

export interface NewsletterRenameResponse {
	description: string;
	displayName: string;
	imagePreviewUrl: string;
	newsletterId: number;
	templateId: string;
}

export interface RecipientListsTableModel {
	id: number;
	name: string;
	lastModifiedDate: Date;
	sharedBy?: string;
	sharedByName?: string;
	status?: number;
	campaigns?: string[];
	description?: string;
	radaGroupsCount?: number;
	recipientsCount?: number;
}

export interface SentRecipientsListModel extends RecipientListsTableModel {}

export interface CampaignTableModel {
	id: number;
	name: string;
	subscription: boolean;
	selfSubscribedCount: number;
	unsubscription: boolean;
	unsubscribedCount: number;
	status: number;
	campaignUserRoles: any[];
}

export interface NewsletterStatisticsResponse extends DeliveryStatisticsModel {
	newsletterId: number;
	links: Array<NewsletterStatisticsLinksResponse>;
}

export interface CampaignStatisticsResponse {
	campaignId: number;
	newsletters: Array<{ newsletterId: number } & DeliveryStatisticsModel>;
}

export interface CampaignPeriodStatisticsResponse {
	period: string;
	campaignStatistics: CampaignStatisticsResponse;
}

export interface NewsletterStatisticsLinksResponse {
	link: string;
	tags: {
		LinkId: string;
		Name: string;
		Type: TagTypes;
	};
	clicksCount: number;
	clickThroughtRate: number;
}

export interface LinkStatisticsTableModel {
	id: number;
	name: string;
	url: string;
	type: TagTypes;
	clicksCount: number;
	clickThroughtRate: number;
}

export enum FeedbackStatisticsEnum {
	PREDEFINED = 0,
	THUMBS = 1,
	STARS = 2,
	OPTIONS = 3,
}

export interface FeedbackOptionModel {
	name: string;
	icon: string;
	count: number;
}

export interface FeedbackStatisticsModel {
	feedbackType: FeedbackStatisticsEnum;
	question?: string;
	options: Array<{
		name: string;
		icon: string;
		count: number;
	}>;
}

export interface DeliveryStatisticsModel {
	uniqueOpened: number;
	clicked: number;
	uniqueClicked: number;
	totalReceivers: number;
	delivered: number;
	bounced: number;
}

export interface CampaignStatisticsModel {
	newsletters: Array<
		{
			newsletterId: number;
			newsletterTitle: string;
			newsletterSentDate: string;
		} & DeliveryStatisticsModel
	>;
}

export interface PeriodStatisticsModel {
	period: string;
	newsletters: Array<
		{
			newsletterId: number;
		} & DeliveryStatisticsModel
	>;
}

export const EMPTY_OVERALL_STATS: DeliveryStatisticsModel = {
	totalReceivers: 0,
	delivered: 0,
	uniqueOpened: 0,
	clicked: 0,
	uniqueClicked: 0,
	bounced: 0,
};

export const EMPTY_CAMPAIGN_STATS: CampaignStatisticsModel = {
	newsletters: [],
};

export type MemberType = 'USER' | 'GROUP';

export interface Member {
	type: MemberType;
	name: string;
	email: string;
	details?: GroupDetails;
}

export interface GroupDetails {
	name: string;
	email: string;
	description: string;
	members: Array<Member>;
}
