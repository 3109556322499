<table
	[attr.data-newsid]="blockProperties.news.newsId"
	[attr.data-newslanguage]="blockProperties.newsLanguage"
	[attr.data-card]="blockProperties.card"
	[attr.data-flipped]="blockProperties.flipped"
	style="width: 100%; margin-bottom: 24px; border-collapse: collapse"
	*ngIf="blockProperties.news | newsAbstractForLanguage: blockProperties.newsLanguage as abstract"
>
	<tr *ngIf="blockProperties.card">
		<td style="vertical-align: top" colspan="3">
			<div
				style="
					width: 100%;
					padding-top: 56.25%;
					background-size: cover;
					margin-bottom: 12px;
					border-bottom: 1px solid #fad6c7;
				"
				[style.backgroundImage]="abstract.imgUrl ? 'url(' + abstract.imgUrl + ')' : 'url(' + placeholderUrl + ')'"
				data-nbimg
			></div>
		</td>
	</tr>
	<tr>
		<td style="vertical-align: top">
			<div
				*ngIf="!blockProperties.card && !blockProperties.flipped"
				@leftImageAnimate
				[@.disabled]="disableAnimation"
				style="flex-shrink: 0; width: 160px; overflow: hidden; margin-right: 16px"
			>
				<div
					style="width: 100%; padding-top: 56.25%; background-size: cover"
					[style.backgroundImage]="abstract.imgUrl ? 'url(' + abstract.imgUrl + ')' : 'url(' + placeholderUrl + ')'"
					data-nbimg
				></div>
			</div>
		</td>
		<td style="width: 100%; vertical-align: top">
			<div style="margin-bottom: 16px; width: 100%; min-height: 74px">
				<a
					[attr.ses:tags]="abstract.title | getSesTags: TagTypes.NEWS"
					style="text-decoration: none"
					target="_blank"
					[href]="blockProperties.news | getLink: 'news-details-for-newsletter' : blockProperties.newsLanguage"
				>
					<h3
						style="
							color: #1e1e1e;
							font-size: 14px;
							font-weight: 600;
							letter-spacing: 0.2px;
							line-height: 21px;
							margin-top: 0;
							margin-bottom: 4px;
						"
					>
						{{ abstract.title }}
					</h3>
				</a>
				<p
					style="color: #1e1e1e; font-size: 14px; font-weight: 400; letter-spacing: 0.2px; line-height: 21px; margin: 0"
				>
					{{ abstract.abstract }}
				</p>
			</div>
		</td>
		<td style="vertical-align: top">
			<div
				*ngIf="!blockProperties.card && blockProperties.flipped"
				@rightImageAnimate
				[@.disabled]="disableAnimation"
				style="flex-shrink: 0; width: 160px; overflow: hidden; margin-left: 16px"
			>
				<div
					style="width: 100%; padding-top: 56.25%; background-size: cover"
					[style.backgroundImage]="abstract.imgUrl ? 'url(' + abstract.imgUrl + ')' : 'url(' + placeholderUrl + ')'"
					data-nbimg
				></div>
			</div>
		</td>
	</tr>
</table>
