import {createAction, props} from '@ngrx/store';
import {ContentItem} from '@app/core/models/content-item.model';
import {Pagination} from '@app/core/models/pagination.model';
import {SubscriptionType} from '@home/store/subscriptions';

export const init = createAction(
  '[LIST - INIT] Init Section with default values'
);

export const loadMoreItems = createAction(
  '[LIST - LOAD MORE] Load more items click'
);

export const goToContent = createAction(
  '[LIST - GO CONTENT] Navigate to Content LIST',
  props<{ path: string, queryParams }>()
);

export const loadChannelIfNotExistRequest = createAction(
  '[LIST - LOAD CHANNEL REQUEST] Load channel if not exist Request',
  props<{ id: string }>()
);


export const goOutside = createAction(
  '[LIST - REDIRECT OUT] Redirect outside',
  props<{ url: string }>()
);

export const getItemsSuccess = createAction(
  '[CONTENT API - GET SUCCESS] Get Content for LIST',
  props<{ value: any, contentItems: ContentItem[], pagination: Pagination }>()
);

export const getItemsError = createAction(
  '[CONTENT API - GET ERROR] Get Content for LIST',
  props<{ value: string, error: any }>()
);

export const addLike = createAction(
  '[LIST] Add like',
  props<{ id: string, likeCount: number }>()
);

export const addLikeSuccess = createAction(
  '[LIST] Add like success',
  props<{ id: string, likeCount: number }>()
);

export const addLikeFailure = createAction(
  '[LIST] Add like failure',
  props<{ id: string, likeCount: number }>()
);

export const removeLike = createAction(
  '[LIST] Remove like',
  props<{ id: string, likeCount: number }>()
);

export const removeLikeSuccess = createAction(
  '[LIST] Remove like success',
  props<{ id: string, likeCount: number }>()
);

export const removeLikeFailure = createAction(
  '[LIST] Remove like failure',
  props<{ id: string, likeCount: number }>()
);

export const addBookmark = createAction(
  '[LIST] Add bookmark',
  props<{ id: string }>()
);

export const addBookmarkSuccess = createAction(
  '[LIST] Add bookmark success',
  props<{ id: string }>()
);

export const addBookmarkFailure = createAction(
  '[LIST] Add bookmark failure',
);

export const removeBookmark = createAction(
  '[LIST] Remove bookmark',
  props<{ id: string }>()
);

export const removeBookmarkSuccess = createAction(
  '[LIST] Remove bookmark success',
  props<{ id: string }>()
);

export const removeBookmarkFailure = createAction(
  '[LIST] Remove bookmark failure',
);
export const setSubTypeAndValue = createAction(
  '[LIST] Set subType and value',
  props<{ value: string, subType: SubscriptionType }>()
);

export const setSubTypeLocalGlobal = createAction(
  '[LIST] Set subType local / global',
  props<{ subType: string }>()
);

export const setDataInfo = createAction(
  '[LIST] Set data info',
  props<{ value: string, subType: SubscriptionType, name: string, isMandatory?: boolean }>()
);
export const setLocalGlobalDataInfo = createAction(
  '[LIST] Set local / global data info',
  props<{ subType: string, name: string }>()
);


export const getItemsRequest = createAction(
  '[LIST] Get data request',
  props<{ value: any, subType: SubscriptionType, pageSize: number, pageIndex: number, language: string, sortType: number }>()
);

export const getChannelRequest = createAction(
  '[List] Get channel request',
  props<{ value: string, subType: SubscriptionType }>()
)

export const setSortType = createAction(
  '[List] Set sort type',
  props<{ sortType: number }>()
)
export const clearStore = createAction(
  '[List] Clear store on destroy'
)
export const getLocationText = createAction(
  '[List] get location text',
  props<{subType: string, ids: string[] }>()
)

export const getPersonalization = createAction(
  '[List] get personalization',
  props<{subType: string }>()
);

export const openUnsubscribeConfirmDialog = createAction(
  '[List] open unsubscribe confirm dialog',
  props<{id: string, name: string, subType: SubscriptionType }>()
);