import { createSelector } from '@ngrx/store';
import * as fromReducer from './searching-preferences.reducer';
import * as fromUserSettings from '../user-settings.reducer';

export const selectSearchingPreferences = createSelector(
  fromUserSettings.selectState,
  fromUserSettings.getSearchingPreferences,
);

export const selectForm = createSelector(
  selectSearchingPreferences,
  fromReducer.getForm
);

export const selectInitialForm = createSelector(
  selectSearchingPreferences,
  fromReducer.getInitialForm
);

