<div *ngIf="catalog"  class="catalog__wrapper" >
  <div class="catalog" [class.catalog--moved]="swiped && allowSwipe" (swipeleft)="allowSwipe? onSwipeLeft($event) : {}" (swiperight)="allowSwipe? onSwipeRight($event) : {}">
    <ng-content select="[dragDrop]"></ng-content>
    <ng-container *ngIf="catalog.isGlobal">
      <ng-container *ngTemplateOutlet="global"></ng-container>
    </ng-container>

    <ng-container *ngIf="!catalog.isGlobal">
      <ng-container *ngTemplateOutlet="icon"></ng-container>
    </ng-container>
    <div class="catalog__body">
      <a [href]="catalog.url" target="_blank">{{catalog.name}}</a>
      <span>
        <rds-badge *ngIf="catalog.isFavourite" size="s" label="TOP" color="warning"></rds-badge>
        {{catalog.category.name}}
      </span>
    </div>
    <div
      class="catalog__actions" 
      [class.catalog__actions--visible]="actionsAlwaysVisible"
      [class.catalog__actions--top]="actionsPosition === 'top'"
      [class.catalog__actions--center]="actionsPosition === 'center'"
      >
      <ng-content select="[actions]"></ng-content>
    </div>

    <div *ngIf="catalog.isVpnRequired" class="catalog__vpn">
      VPN
    </div>

  </div>
  <div class="catalog__wrapper--swipe">
    <div class="catalog__wrapper--swipe__actions">
      <ng-content select="[swipe]"></ng-content>
    </div>
  </div>
  <div class="catalog__wrapper--shadow"></div>
</div>

<ng-template #icon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" class="catalog__icon">
    <g stroke="none" stroke-width="1" fill-rule="evenodd">
      <path
        d="M12.568,8.011 L14.279,9.722 L12.872,11.129 L11.747,10.005 L11.161,10.012 L4.001,17.171 L3.994,17.758 L6.236,20 L6.83,20 L9.722,17.106 L11.129,18.514 L10.029,19.614 L7.657,22 L5.408,22 L2,18.592 L2,16.343 L10.333,8.011 L12.568,8.011 Z M18.592,2 L22,5.408 L22,7.657 L13.667,15.989 L11.432,15.989 L9.721,14.278 L11.128,12.871 L12.253,13.995 L12.839,13.988 L19.999,6.829 L20.006,6.242 L17.764,4 L17.17,4 L14.278,6.894 L12.871,5.486 L13.971,4.386 L16.343,2 L18.592,2 Z"></path>
    </g>
  </svg>
</ng-template>

<ng-template #global>
  <svg width="24px" height="24px" viewBox="0 0 24 24" class="catalog__icon">
    <g stroke="none" stroke-width="1" fill-rule="evenodd">
      <path
        d="M12.568,8.011 L14.279,9.722 L12.872,11.129 L11.747,10.005 L11.161,10.012 L4.001,17.171 L3.994,17.758 L6.0465788,19.8118988 C6.13605607,20.5865853 6.35200828,21.3227491 6.67325663,21.9992115 L5.408,22 L2,18.592 L2,16.343 L10.333,8.011 L12.568,8.011 Z M18.592,2 L22,5.408 L22,7.657 L16.6387704,13.0189009 C16.0267174,12.6457437 15.352895,12.3638913 14.6362283,12.1922688 L19.999,6.829 L20.006,6.242 L17.764,4 L17.17,4 L14.278,6.894 L12.871,5.486 L13.971,4.386 L16.343,2 L18.592,2 Z"></path>
      <path
        d="M13,14 C10.2385763,14 8,16.2385763 8,19 C8,21.7614237 10.2385763,24 13,24 C15.7614237,24 18,21.7614237 18,19 C18,16.2385763 15.7614237,14 13,14 L13,14 Z M16.459,17 L15.2725,17 L14.2605,15.2065 C15.1870943,15.5170106 15.9687204,16.1546481 16.459,17 Z M17,19 C16.9987949,19.337564 16.9546061,19.6736004 16.8685,20 L15.4865,20 L15.498,18 L16.8685,18 C16.9546061,18.3263996 16.9987949,18.662436 17,19 L17,19 Z M11.8615,21 L14.1385,21 L13,22.992 L11.8615,21 Z M9.1315,20 C8.95616519,19.3448617 8.95616519,18.6551383 9.1315,18 L10.5135,18 L10.4999989,20 L9.1315,20 Z M11.5,20 L11.5,18 L14.5,18 L14.5,20 L11.5,20 Z M14.1385,17 L11.8615,17 L13,15.008 L14.1385,17 Z M11.7335,15.209 L10.71,17 L9.541,17 C10.0303108,16.1567462 10.8095627,15.5201942 11.7335,15.209 L11.7335,15.209 Z M9.541,21 L10.7275,21 L11.7395,22.7935 C10.8129057,22.4829894 10.0312796,21.8453519 9.541,21 Z M14.2665,22.791 L15.29,21 L16.459,21 C15.9697953,21.8433442 15.1905047,22.4799277 14.2665,22.791 Z"></path>
    </g>
  </svg>
</ng-template>