import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'columnName'
})
export class ColumnNamePipe implements PipeTransform {

  transform(name: string): any {
    if (name) {
      const splittedName = name.split('.');
      return `${splittedName[0]}`
    }
    return '';
  }
}
