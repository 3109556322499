import { FocusableOption } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { DropdownFlatNode, NestedOptionsModel } from '../models';

@Component({
  selector: 'rh-dropdown-node',
  templateUrl: './dropdown-node.component.html',
  styleUrls: ['./dropdown-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownNodeComponent implements FocusableOption {

  @Input() nodeScope!: {
    hasChild: boolean;
    isAnyChildSelected: boolean;
    areAllChildsSelected: boolean;
    isSelected: boolean;
    searchPhrase: string;
    isExpanded: boolean;
    isAnyChildMatchingPhrase: boolean;
    node: DropdownFlatNode;
    hasSelectionValue: boolean;
    isMultiple: boolean;
    size: 'medium' | 'small',
    optionsModel: Partial<NestedOptionsModel>,
    selectParentWhenAllSelected: boolean
  }

  @Output() toggle: EventEmitter<DropdownFlatNode> = new EventEmitter();
  @Output() checkboxChange: EventEmitter<DropdownFlatNode> = new EventEmitter();
  @Output() nameClick: EventEmitter<DropdownFlatNode> = new EventEmitter();

  constructor(private host: ElementRef) {}
  focus() {
    this.host.nativeElement.focus();
  }
  
  disabled?: boolean;

  getLabel?(): string {
    throw new Error('Method not implemented.');
  }

  toggleCheckbox(node: DropdownFlatNode) {
    this.checkboxChange.emit(node);
  }

  toggleNode(node: DropdownFlatNode) {
    this.toggle.emit(node);
  }

  nameClicked(node: DropdownFlatNode) {
    this.nameClick.emit(node)
  }
}
