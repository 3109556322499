<h2 rds-dialog-header>{{ data.title }}</h2>
<div rds-dialog-content style="overflow-x: hidden" [formGroup]="form">
	<div class="row">
		<div class="col">
			<div class="mt-5">
				<rh-autocomplete
					uniqueKey="identifier"
					displayKey="email"
					label="Enter user name or email"
					[options]="'user' | autocompletes"
					(search)="getAutocompletes('user', 'user', $event)"
					formControlName="user"
					[multi]="true"
					[itemTemplate]="itemTemplate"
					[selectedTemplate]="selectedTemplate"
				>
					<ng-template #itemTemplate let-option="option" let-highlightPhrase="highlightPhrase">
						<rh-user-autocomplete-item
							[option]="option"
							[highlightPhrase]="highlightPhrase"
						></rh-user-autocomplete-item>
					</ng-template>
					<ng-template
						#selectedTemplate
						let-selectedOptions="selectedOptions"
						let-disabled="disabled"
						let-removeFn="removeFn"
						let-checkRemovePrevented="checkRemovePrevented"
					>
						<rh-user-autocomplete-selected
							[selectedOptions]="selectedOptions"
							[disabled]="disabled"
							(removed)="removeFn($event)"
							[checkRemovePrevented]="checkRemovePrevented"
						></rh-user-autocomplete-selected>
					</ng-template>
					<rds-control-error *ngIf="form.controls.user.errors?.userNotExists"
						>Selected User is not valid
					</rds-control-error>
					<rds-control-error *ngIf="form.get('user').hasError('required') && form.get('user').touched">
						This field is required
					</rds-control-error>
				</rh-autocomplete>
			</div>
		</div>
	</div>
</div>
<div rds-dialog-actions-container align="end">
	<button rds-secondary-button [rds-dialog-close]="null" size="m">Cancel</button>
	<button rds-primary-button [disabled]="form.invalid" size="m" [rds-dialog-close]="form.get('user').value">
		{{ data.confirmButtonLabel }}
	</button>
</div>
