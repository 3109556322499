import { createReducer, Action, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';

import { ContentType } from '@core/models/content-type.model';
import * as fromActions from '@core/core-store/content-types/content-types.actions';

export interface State extends EntityState<ContentType> {}

export const adapter: EntityAdapter<ContentType> = createEntityAdapter<ContentType>({});

export const initialState: State = {
  ...adapter.getInitialState(),
};

const contentTypesReducer = createReducer(
  initialState,
  on(fromActions.contentTypesCollectionAddMany, (state, {contentTypes}) => (
    adapter.addMany(contentTypes, state)
  )),
);

export function reducer(state: State | undefined, action: Action) {
  return contentTypesReducer(state, action);
}

export const getIds = adapter.getSelectors().selectIds;
export const getEntities = adapter.getSelectors().selectEntities;
export const getAll = adapter.getSelectors().selectAll;
export const getTotal = adapter.getSelectors().selectTotal;

export const getEntityById = (entities: Dictionary<ContentType>, {id}) => entities[id];
export const getEntitiesById = (entities: Dictionary<ContentType>, {ids}) => ids.map(id => entities[id]);

