import { TaxonomyManagers } from '@app/core/models';
import { createAction, props } from '@ngrx/store';


export const departmentManagersCollectionAddMany = createAction(
  '[STORED DEPARTMENT MANAGERS - ADD MANY] Department Managers collection add many.',
  props<{ departmentsManagers: TaxonomyManagers[] }>()
);

export const departmentManagersCollectionAddOne = createAction(
  '[STORED DEPARTMENT MANAGERS - ADD ONE] Department Managers collection add one.',
  props<{ departmentManagers: TaxonomyManagers }>()
);

export const departmentManagersCollectionUpsertOne = createAction(
  '[STORED DEPARTMENT MANAGERS - UPSERT ONE] Department Managers collection upsert one.',
  props<{ departmentManagers: TaxonomyManagers }>()
);

export const departmentManagersCollectionRemoveOne = createAction(
  '[STORED DEPARTMENT MANAGERS - REMOVE ONE] Department Managers collection remove one.',
  props<{ departmentId: string }>()
);
