<div rds-dialog-content class="p-7">
  <ng-container *ngIf="!data?.template?.isPredefined || (data?.template?.isPredefined && isGenerating)">
    <p class="ui-label-l-bold">Saving...</p>
    <rds-progress-bar class="mb-3 mt-5"></rds-progress-bar>
    <p class="ui-body-m mb-5">It may take up to 60 seconds</p>
  </ng-container>
  <ng-container *ngIf="data?.template?.isPredefined && !isGenerating">
    <p class="ui-label-l-bold">Are you sure you want to update predefined template?</p>
    <p class="ui-body-m mt-5 mb-5">To save changes in the predefined template,
      please enter the following phrase and press the check button. 
      <br/><span class="ui-body-m-bold">"{{phrase}}"</span><br/>
      </p>
      <div class="rds-component-group" [formGroup]="form">
        <rds-form-field labelPosition="none">
          <input
            rdsInput
            formControlName="phrase"
            (paste)="pasteProhibited('Paste', $event)"
            (drop)="pasteProhibited('Drop', $event)"
            [placeholder]="sec > 0 ? 'Input will be enabled in ' + sec + ' seconds...' : 'Type phrase here...'"
          />
        </rds-form-field>
        <button rds-primary-button size="l" [disabled]="sec > 0 && form.controls.phrase.value.length === 0" (click)="checkArray = resetCheckArray(); check(0, form.controls.phrase.value)">Check</button>
      </div>
      <div *ngIf="isChecking" class="bg-black c-white p-4">
        <p class="ui-body-m-bold check-phrase">
          <span *ngFor="let char of (splitPhrase.length + 6 | numToArray); let i = index;" rnbRandomLetter [status]="'NOT_CHECKED'" [originalLetter]="char">{{char}}</span>
        </p>
        <p class="ui-body-m-bold check-phrase">
          <span *ngFor="let char of (splitPhrase.length + 6 | numToArray); let i = index;" rnbRandomLetter [status]="'NOT_CHECKED'" [originalLetter]="char">{{char}}</span>
        </p>
        <p class="ui-body-m-bold check-phrase">
          <span *ngFor="let char of (3 | numToArray); let i = index;" rnbRandomLetter [status]="'NOT_CHECKED'" [originalLetter]="char">{{char}}</span>
          <span *ngFor="let char of splitPhrase; let i = index;" rnbRandomLetter [status]="checkArray[i]" [originalLetter]="char === ' ' ? '&nbsp;' : char"[class.passed]="checkArray[i] === 'PASSED'" [class.failed]="checkArray[i] === 'FAILED'">{{char === ' ' ? '&nbsp;' : char}}</span>
          <span *ngFor="let char of (3 | numToArray); let i = index;" rnbRandomLetter [status]="'NOT_CHECKED'" [originalLetter]="char">{{char}}</span>
        </p>
        <p class="ui-body-m-bold check-phrase">
          <span *ngFor="let char of (splitPhrase.length + 6 | numToArray); let i = index;" rnbRandomLetter [status]="'NOT_CHECKED'" [originalLetter]="char">{{char}}</span>
        </p>
        <p class="ui-body-m-bold check-phrase">
          <span *ngFor="let char of (splitPhrase.length + 6 | numToArray); let i = index;" rnbRandomLetter [status]="'NOT_CHECKED'" [originalLetter]="char">{{char}}</span>
        </p>
      </div>
      <div class="mt-5" align="end">
        <button
            rds-secondary-button
            [rds-dialog-close]="null"
            size="m"
            class="me-4"
        >Cancel</button>
        <button
            rds-primary-button
            [disabled]="!isChecked"
            (click)="createPreview()"
            size="m"
        >Update template</button>
      </div>
  </ng-container>
  <div class="preview__wrapper">
    <div #image class="preview__content bg-neutral-gradient-top-left">
      <rh-news-content-preview
      rnbSkeleton
      (skeletoned)="data?.template?.isPredefined? {} : createPreview()"
      *ngIf="news.content"
      tabindex="0"
      [noBackground]="true"
      [languageName]="'English'"
      [language]="'en'"
      [news]="news"
    >
    </rh-news-content-preview>
    </div>
  </div>
</div>
<div class="media-placeholder">
  <div #imagePlaceholder class="bg-placeholder" >
    <rds-icon icon="image" namespace="outlined" style="width: 80px; height: 80px;"></rds-icon>
  </div>
</div>
<div class="media-placeholder">
  <div #videoPlaceholder class="bg-placeholder" >
    <rds-icon icon="video" namespace="outlined" style="width: 80px; height: 80px;"></rds-icon>
  </div>
</div>
