import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ContentResult} from '@app/search/store/rh-search';

@Component({
  selector: 'rh-content-list-item',
  templateUrl: './content-list-item.component.html',
  styleUrls: ['./content-list-item.component.scss']
})
export class ContentListItemComponent {

  @Input() result!: ContentResult;
  @Input() public isInQuickLinks: boolean;
  @Output() contentOpened: EventEmitter<{ url: string }> = new EventEmitter();
  @Output() quicklinkAdded: EventEmitter<{ catalogId: number }> = new EventEmitter();

  constructor() {
  }

  addQuicklink() {
    this.quicklinkAdded.emit({catalogId: this.result.id})
  }

  openContent() {
    this.contentOpened.emit({url: this.result.url});
  }
}
