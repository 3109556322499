import {Action, createReducer, on} from '@ngrx/store';
import * as fromActions from '@app/home/store/alert/alert.actions';
import {AlertModel} from '@app/core/models';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

export interface State extends EntityState<AlertModel> {}

export const adapter: EntityAdapter<AlertModel> =
  createEntityAdapter<AlertModel>({
    selectId: feed => feed.id
  });

export const initialState: State = {
  ...adapter.getInitialState(),
};

export const alertReducer = createReducer(
  initialState,
  on(fromActions.getFeedSuccess, (state, {data}) => (
    adapter.addMany(data, state))),
	on(fromActions.markAsReadSuccess, (state, {id}) => (
    adapter.removeOne(id, state))),
);

export function reducer(state: State | undefined, action: Action) {
  return alertReducer(state, action);
}

export const getData = adapter.getSelectors().selectAll;
