import {createSelector} from '@ngrx/store';
import * as fromHome from '../home.reducer';
import * as fromHeroSection from './hero-section.reducer';

export const selectHeroSectionState = createSelector(
  fromHome.selectState,
  fromHome.getHeroSection
);

export const selectChannelId = createSelector(
  selectHeroSectionState,
  fromHeroSection.getChannelId
);

export const selectNewsCount = createSelector(
  selectHeroSectionState,
  fromHeroSection.getNewsCount
);

export const selectNews = createSelector(
  selectHeroSectionState,
  fromHeroSection.getNews
);

export const selectIsLoading = createSelector(
  selectHeroSectionState,
  fromHeroSection.getLoading
);

export const selectIsLoaded = createSelector(
  selectHeroSectionState,
  fromHeroSection.getLoaded
);

export const selectIsLikeProcessing = createSelector(
  selectHeroSectionState,
  fromHeroSection.getIsLikeProcessing
);
export const selectIsLikeProcessingIds = createSelector(
  selectHeroSectionState,
  fromHeroSection.getIsLikeProcessingIds
);
