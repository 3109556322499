import {createSelector} from '@ngrx/store';

import * as fromReducer from '@app/newsletter/store/newsletter.reducer';
import * as fromDashboard from '@app/newsletter/store/newsletter-dashboard/newsletter-dashboard.reducer';

export const selectNewsletterDashboard = createSelector(
  fromReducer.selectState,
  fromReducer.getNewsletterDashboard
);

export const selectDraft = createSelector(
  selectNewsletterDashboard,
  fromDashboard.getDraft
);


export const selectTemplates = createSelector(
  selectNewsletterDashboard,
  fromDashboard.getTemplatesList
);

export const selectTemplatesEntities = createSelector(
  selectTemplates,
  fromDashboard.getEntities
);

export const selectTemplatesAll = createSelector(
  selectTemplates,
  fromDashboard.getAll
);

export const selectSelectedTemplate =
  createSelector(
    selectNewsletterDashboard,
    fromDashboard.getSelectedTemplate
  );
export const selectSelectedTemplateId =
  createSelector(
    selectSelectedTemplate,
    (template) => template.templateId
  );

export const selectSelectedColor =
  createSelector(
    selectNewsletterDashboard,
    fromDashboard.getSelectedColor
  );

export const selectTemplateAndColor =
  createSelector(
    selectNewsletterDashboard,
    fromDashboard.getTemplateAndColor
  );

export const selectSelectedTab = createSelector(
  selectNewsletterDashboard,
  fromDashboard.getSelectedTab
);

export const selectRecentDraft = createSelector(
  selectNewsletterDashboard,
  fromDashboard.getMyRecentDraftObj
);

export const selectRecentDraftItemsLength = createSelector(
  selectRecentDraft,
  (recentDraft) => recentDraft.ids.length
);

export const selectRecentDraftTotal = createSelector(
  selectRecentDraft,
  (recentlyScheduled) => recentlyScheduled.totalCount
);

export const selectRecentlyScheduled = createSelector(
  selectNewsletterDashboard,
  fromDashboard.getMyRecentlyScheduledObj
);

export const selectRecentlyScheduledItemsLength = createSelector(
  selectRecentlyScheduled,
  (recentlyScheduled) => recentlyScheduled.ids.length
);

export const selectRecentlyScheduledTotal = createSelector(
  selectRecentlyScheduled,
  (recentlyScheduled) => recentlyScheduled.totalCount
);

export const selectRecentlySent = createSelector(
  selectNewsletterDashboard,
  fromDashboard.getMyRecentlySentObj
);

export const selectRecentlySentItemsLength = createSelector(
  selectRecentlySent,
  (recentlySent) => recentlySent.ids.length
);

export const selectRecentlySentTotal = createSelector(
  selectRecentlySent,
  (recentlySent) => recentlySent.totalCount
);

export const selectRecentDraftData = createSelector(
  selectRecentDraft,
  fromDashboard.getEntitiesDesc
);

export const selectRecentDraftIsLast = createSelector(
  selectRecentDraft,
  (recentDraft) => recentDraft.isLast
);

export const selectRecentDraftIsLoading = createSelector(
  selectRecentDraft,
  (recentDraft) => recentDraft.loading
);

export const selectRecentlyScheduledData = createSelector(
  selectRecentlyScheduled,
  fromDashboard.getEntitiesAsc
);

export const selectRecentlyScheduledIsLast = createSelector(
  selectRecentlyScheduled,
  (recentlyScheduled) => recentlyScheduled.isLast
);

export const selectRecentlyScheduledIsLoading = createSelector(
  selectRecentlyScheduled,
  (recentlyScheduled) => recentlyScheduled.loading
);

export const selectRecentlySentData = createSelector(
  selectRecentlySent,
  fromDashboard.getEntitiesDesc
);

export const selectRecentlySentIsLast = createSelector(
  selectRecentlySent,
  (recentlySent) => recentlySent.isLast
);

export const selectRecentlySentIsLoading = createSelector(
  selectRecentlySent,
  (recentlySent) => recentlySent.loading
);

export const selectSharedRecentDraft = createSelector(
  selectNewsletterDashboard,
  fromDashboard.getSharedRecentDraftObj
);

export const selectSharedRecentDraftItemsLength = createSelector(
  selectSharedRecentDraft,
  (recentDraft) => recentDraft.ids.length
);

export const selectSharedRecentDraftTotal = createSelector(
  selectSharedRecentDraft,
  (recentDraft) => recentDraft.totalCount
);

export const selectSharedRecentlyScheduled = createSelector(
  selectNewsletterDashboard,
  fromDashboard.getSharedRecentlyScheduledObj
);

export const selectSharedRecentlyScheduledItemsLength = createSelector(
  selectSharedRecentlyScheduled,
  (recentlyScheduled) => recentlyScheduled.ids.length
);

export const selectSharedRecentlyScheduledTotal = createSelector(
  selectSharedRecentlyScheduled,
  (recentlyScheduled) => recentlyScheduled.totalCount
);

export const selectSharedRecentlySent = createSelector(
  selectNewsletterDashboard,
  fromDashboard.getSharedRecentlySentObj
);

export const selectSharedRecentlySentItemsLength = createSelector(
  selectSharedRecentlySent,
  (recentlySent) => recentlySent.ids.length
);

export const selectSharedRecentlySentTotal = createSelector(
  selectSharedRecentlySent,
  (recentlySent) => recentlySent.totalCount
);

export const selectSharedRecentDraftData = createSelector(
  selectSharedRecentDraft,
  fromDashboard.getEntitiesDesc
);

export const selectSharedRecentDraftIsLast = createSelector(
  selectSharedRecentDraft,
  (recentDraft) => {
    return recentDraft.isLast;
  }
);

export const selectSharedRecentDraftIsLoading = createSelector(
  selectSharedRecentDraft,
  (recentDraft) => recentDraft.loading
);

export const selectSharedDraftIds = createSelector(
  selectSharedRecentDraftData,
  (drafts) => drafts.map(d => d.id)
);

export const selectSharedRecentlyScheduledData = createSelector(
  selectSharedRecentlyScheduled,
  fromDashboard.getEntitiesAsc
);

export const selectSharedRecentlyScheduledIsLast = createSelector(
  selectSharedRecentlyScheduled,
  (recentlyScheduled) => recentlyScheduled.isLast
);

export const selectSharedRecentlyScheduledIsLoading = createSelector(
  selectSharedRecentlyScheduled,
  (recentlyScheduled) => recentlyScheduled.loading
);

export const selectSharedScheduledIds = createSelector(
  selectSharedRecentlyScheduledData,
  (scheduled) => scheduled.map(d => d.id)
);

export const selectSharedRecentlySentData = createSelector(
  selectSharedRecentlySent,
  fromDashboard.getEntitiesDesc
);

export const selectSharedRecentlySentIsLast = createSelector(
  selectSharedRecentlySent,
  (recentlySent) => recentlySent.isLast
);
export const selectSharedRecentlySentIsLoading = createSelector(
  selectSharedRecentlySent,
  (recentlySent) => recentlySent.loading
);

export const selectSharedSentIds = createSelector(
  selectSharedRecentlySentData,
  (sent) => sent.map(d => d.id)
);


export const selectIsSaveProcessActive = createSelector(
  selectNewsletterDashboard,
  (state) => state.isSaveProcessActive
);





