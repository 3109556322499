import {Directive, EventEmitter, HostBinding, HostListener, Input, Output, OnInit} from '@angular/core';

@Directive({
  selector: '[rhDragDrop]'
})
export class DragDropDirective implements OnInit {

  @Input() rhDragDrop = true;
  
  @Output() fileDropped = new EventEmitter<any>();
  @Output() fileDroppedEvent = new EventEmitter<any>();

  @HostBinding('style.background-color') private background;
  @HostBinding('style.opacity') private opacity;

  /*Dragover listener*/
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.rhDragDrop) {
      this.background = '#9ecbec';
      this.opacity = '0.8';
    }
  }

  /*Dragleave listener*/
  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.rhDragDrop) {
      this.background = '#f5fcff';
      this.opacity = '1';
    }
  }

  /*Drop listener*/
  @HostListener('drop', ['$event'])
  public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.rhDragDrop) {
      this.background = '#f5fcff';
      this.opacity = '1';
    }
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
      this.fileDroppedEvent.emit({target: {files: [files[0]]}});

    }
  }
    
  ngOnInit(): void {
    if (this.rhDragDrop) {
      this.background = '#f5fcff';
      this.opacity = '1';
    }
  }
}
