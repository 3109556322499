import { createFeatureSelector, combineReducers, Action } from '@ngrx/store';
import * as fromTable from './table/table.reducer';
import * as fromForm from './form/form.reducer';

export const feature = 'role-assign';

export interface State {
  table: fromTable.State;
  form: fromForm.State;
}

export function reducer(state: State | undefined, action: Action) {
  return combineReducers({
    table: fromTable.reducer,
    form: fromForm.reducer,
  })(state, action);
}

export const selectState = createFeatureSelector<State>(feature);

export const getTable = (state: State) => state.table;
export const getForm = (state: State) => state.form;
