<rh-list *ngIf="result">
  <div image class="mx-5">
    <rh-user-single [user]="result" size="xl" (click)="onOpenPeople()"></rh-user-single>
  </div>
  <p body-top
     class="ui-label-l-bold text-high-contrast  text-truncate">
    <span
      [rdsTooltip]="nameTemplate"
      rdsTooltipPosition="top-center"
      [rdsTooltipShowDelay]="500" class="cursor-pointer"
      (click)="onOpenPeople()">{{result.firstName + ' ' + result.lastName}}</span>
  </p>
  <p body-middle
     class="ui-label-l-bold text-low-contrast text-truncate">
    <span
      [rdsTooltip]="supervisoryTemplate"
      rdsTooltipPosition="top-center"
      [rdsTooltipShowDelay]="500">
        {{result.supervisory}}
    </span>
  </p>
  <div body-bottom
       style="display: flex; justify-content: space-between;">
    <p
      [rdsTooltip]="emailTemplate"
      rdsTooltipPosition="top-center"
      [rdsTooltipShowDelay]="500" class="ui-component-text-s text-low-contrast">
      <a [href]="'mailto:' + result.email">{{result.email}}</a>
    </p>
    <!-- TODO: Add subscription button -->
  </div>
</rh-list>

<ng-template #nameTemplate>
  <span [innerHTML]="result.firstName + ' ' + result.lastName"></span>
</ng-template>

<ng-template #supervisoryTemplate>
  <span [innerHTML]="result.supervisory"></span>
</ng-template>
<ng-template #emailTemplate>
  {{result.email}}
</ng-template>
