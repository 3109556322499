import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, distinctUntilChanged, take } from 'rxjs/operators';

import * as fromActions from './global-settings.actions';
import * as fromReducer from './global-settings.reducer';
import * as fromUser from '@app/core/user/store';
import * as fromSelectors from './global-settings.selectors';

import * as fromUserActions from '@app/core/user/store';
import { select, Store } from '@ngrx/store';
import { UserProfileService } from '@app/core/services/userProfile.service';

@Injectable()
export class GlobalSettingsEffects {

  public initForm$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.initForm),
    switchMap(() => this.store$.pipe(
      select(fromUser.selectNewsLanguage),
      map((newsLanguage) => ({newsLanguageId: newsLanguage.id})),
    )),
    mergeMap(({newsLanguageId}) => [
      fromActions.setInitialValue({form: {newsLanguageId}})
    ]),
  ), { dispatch: true }
);

  public saveNewsLanguageRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveNewsLanguageRequest),
      switchMap(() => this.store$.pipe(
        select(fromSelectors.selectForm),
        distinctUntilChanged((prev, next) => prev.newsLanguageId === next.newsLanguageId),
        map(({newsLanguageId}) => ({newsLanguageId})),
        take(1)
      )),
      switchMap(({newsLanguageId}) => this.userProfileService.postGlobalSettings({newsLanguageId}).pipe(
        mergeMap((userSettings) => [
          fromActions.saveNewsLanguageSuccess({newsLanguage: userSettings.newsLanguage}),
          fromUserActions.setUserLanguage({ newsLanguage: userSettings.newsLanguage })
        ]),
        catchError(({ message }) => of(fromActions.saveNewsLanguageFailure({ error: message })))
      )),
    ), { dispatch: true }
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private userProfileService: UserProfileService,
  ) {}
}

