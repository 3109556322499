import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import * as fromCatalog from '@app/layout/header/store/catalogs';
import * as fromQuickLink from '@app/layout/header/store/quick-link';
import * as fromRouter from '@app/root-store/router';
import { Store } from '@ngrx/store';
import { RdsTreeControl, RdsTreeDataSource, RdsTreeFlattener } from '@rds/angular-components';
import { HeaderLinkNode } from '../dummy-links';

export interface NewHeaderLinkNode extends HeaderLinkNode {
  icon?: string;
  route?: string
}

export interface MenuTreeNode {
  link: NewHeaderLinkNode;
  selected?: boolean;
  children?: MenuTreeNode[];
}

export interface MenuFlatTreeNode {
  selected: boolean;
  expandable: boolean;
  link: NewHeaderLinkNode;
  level: number;
}

@Component({
  selector: 'rh-header-mobile-menu',
  templateUrl: './header-mobile-menu.component.html',
  styleUrls: ['./header-mobile-menu.component.scss'],
})
export class HeaderMobileMenuComponent {
  @Input() isOpen: boolean = false;

  @Input() set links(items: Array<NewHeaderLinkNode>) {
    const allLinks: Array<MenuTreeNode> = [
      ...items.filter(l => l.link === 'Tools ').map(l => this.mapLink({
        ...l,
      })),
      {
        link: {
          link: 'Quicklinks',
        }
      },
      {
        link: {
          link: 'divider'
        }
      },
      ...items.filter(l => l.link !== 'Tools ').map(l => this.mapLink(l)),
      {
        link: {
          link: 'divider'
        }
      },
      {
        link: {
          link: 'My Bookmarks',
          route: '/bookmarks',
          icon: 'bookmark',
        }
      },
      {
        link: {
          link: 'My Settings',
          route: '/settings',
          icon: 'settings',
        }
      },
    ];

    this.dataSource.data = allLinks;
  };

  @HostBinding('class.opened') get opened() {
    return this.isOpen
  };

  @Input() activeItem;

  @Output() menuItemClicked: EventEmitter<NewHeaderLinkNode> = new EventEmitter();
  @Output() shouldClose: EventEmitter<any> = new EventEmitter();

  treeControl = new RdsTreeControl<MenuFlatTreeNode>(
    node => node.level,
    node => node.expandable,
  );
  treeFlattener = new RdsTreeFlattener<MenuTreeNode, MenuFlatTreeNode>(
    (node, level) => HeaderMobileMenuComponent.nodeTransformer(node, level),
    node => node.level,
    node => node.expandable,
    node => node.children,
  );
  dataSource = new RdsTreeDataSource(this.treeControl, this.treeFlattener);

  private static nodeTransformer(
    node: MenuTreeNode,
    level: number,
  ): MenuFlatTreeNode {
    return {
      selected: node.selected,
      expandable: !!node.children && node.children.length > 0,
      link: node.link,
      level,
    };
  }

  hasChild = (_: number, node: MenuFlatTreeNode) => node.expandable;
  isDivider = (_: number, node: MenuFlatTreeNode) => node.link.link === 'divider';

  select(node: MenuFlatTreeNode) {
    this.menuItemClicked.emit(node.link)
  }

  mapLink(link: NewHeaderLinkNode) {
    return {
      link,
      selected: false,
      children: link.nodes ? [...link.nodes.map(l => this.mapLink(l))] : []
    }
  }

  handleClick(link) {
    if (link.link === 'Quicklinks') {
      this.store$.dispatch(fromQuickLink.toggleHeaderQuickLinks());
    }
    if (link.url) {
      this.store$.dispatch(fromRouter.goOutsideInNewTab({url: link.url, redirect: true}))
    }
    if (link.route) {
      this.store$.dispatch(fromRouter.go({path: link.route, queryParams: {}}))
    }
    if (link.filter) {
      this.store$.dispatch(fromQuickLink.toggleHeaderQuickLinks());
      this.store$.dispatch(fromQuickLink.catalogsTabOpened());
      this.store$.dispatch(fromCatalog.openCategoryAndUpdateFilters({filters: link.filter}));
    }
    this.shouldClose.emit();
  }

  constructor(
    private store$: Store
  ) {
  }

}
