import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './channels-sets-form.actions';

import { Channel, ChannelsSetForm, EMPTY_CHANNEL_SET_FORM } from '@app/core/models';
import { AudienceManagerInfo } from '../manage-sets.reducer';

export const feature = 'channelsSetsForm';

export interface State {
  form: Partial<ChannelsSetForm>;
  initialForm: Partial<ChannelsSetForm>;
  isNameUnique: boolean;
  filteredChannels: Array<Channel>;
  filteredChannelsLoading: boolean;
  audienceManagerInfo: AudienceManagerInfo;
  touched: boolean
}

export const initialState: State = {
  form: EMPTY_CHANNEL_SET_FORM,
  initialForm: EMPTY_CHANNEL_SET_FORM,
  isNameUnique: true,
  filteredChannels: [],
  filteredChannelsLoading: false,
  audienceManagerInfo: {
    isAudienceManager: false,
    isOnlyAudienceManager: false,
    managedTaxonomy: {
      locations: [],
      departments: [],
      functions: []
    }
  },
  touched: false
};

const formReducer = createReducer(
  initialState,
  on(fromActions.initAddForm, (state, { channelsSet, audienceManagerInfo }) => channelsSet? ({
    ...state,
    form: {
      ...state.form,
      id: channelsSet.id,
      name: channelsSet.name,
      channels: {
        ...state.form.channels,
        selected: channelsSet.channels
      },
      audience: {
        locations: channelsSet.locations.map(t => t.id),
        departments: channelsSet.departments.map(t => t.id),
        functions: channelsSet.functions.map(t => t.id),
      }
    },
    initialForm: {
      ...state.form,
      id: channelsSet.id,
      name: channelsSet.name,
      channels: {
        ...state.form.channels,
        selected: channelsSet.channels
      },
      audience: {
        locations: channelsSet.locations.map(t => t.id),
        departments: channelsSet.departments.map(t => t.id),
        functions: channelsSet.functions.map(t => t.id),
      }
    },
    audienceManagerInfo
  }) : ({
    ...state,
    form: {
      ...state.form,
      audience: {
        ...state.form.audience,
        locations: audienceManagerInfo.managedTaxonomy.locations,
        departments: audienceManagerInfo.managedTaxonomy.departments,
        functions: audienceManagerInfo.managedTaxonomy.functions,
      }
    },
    initialForm: {
      ...state.form,
      audience: {
        ...state.form.audience,
        locations: audienceManagerInfo.managedTaxonomy.locations,
        departments: audienceManagerInfo.managedTaxonomy.departments,
        functions: audienceManagerInfo.managedTaxonomy.functions,
      }
    },
    audienceManagerInfo
  })),
  on(fromActions.setFormValue, (state, { form }) => ({
    ...state,
    form: {
      ...state.form,
      ...form
    }
  })),
  on(fromActions.setFormTouched, (state, { touched }) => ({
    ...state,
    touched
  })),
  on(fromActions.isNameUniqueSuccess, (state, {isUnique}) => ({
    ...state,
    isNameUnique: isUnique
  })),
  on(fromActions.getFilteredChannelsRequest, (state) => ({
    ...state,
    filteredChannelsLoading: true
  })),
  on(fromActions.getFilteredChannelsSuccess, fromActions.getFilteredChannelsFailure, (state) => ({
    ...state,
    filteredChannelsLoading: false
  })),
  on(fromActions.getFilteredChannelsSuccess, (state, { channels }) => ({
    ...state,
    filteredChannels: channels
  })),
  on(fromActions.clearFilteredChannels, (state) => ({
    ...state,
    filteredChannels: [],
    filteredChannelsLoading: false
  })),
  on(fromActions.clearAll, (state) => ({
    ...initialState
  })),

);

export function reducer(state: State | undefined, action: Action) {
  return formReducer(state, action);
}

// NEW WAY TABLE
export const getForm = (state: State) => state.form;
export const getInitialForm = (state: State) => state.initialForm;
export const getId = (form: Partial<ChannelsSetForm>) => form.id;
export const getTouched = (state: State) => state.touched;
export const getIsNameUnique = (state: State) => state.isNameUnique;

export const getAudienceManagerInfo = (state: State) => state.audienceManagerInfo;

export const getChannelsFilters = (form: ChannelsSetForm) => form.channels.filters;
export const getSelectedChannels = (form: ChannelsSetForm) => form.channels.selected;
export const getFilteredChannels = (state: State) => state.filteredChannels;
export const getFilteredChannelsLoading = (state: State) => state.filteredChannelsLoading;
