import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'rh-desktop-only-warning',
  templateUrl: './desktop-only-warning.component.html',
  styleUrls: ['./desktop-only-warning.component.scss']
})
export class DesktopOnlyWarningComponent {
  @HostBinding('class.mobile') @Input() mobile: boolean = false;
}
