<div class="" style="display: flex; align-items: center; justify-content: flex-start; gap: 8px">
	<button
		rds-fab-button
		size="s"
		[disabled]="control.value === min || disabled"
		(click)="control.setValue(control.value - 1)"
	>
		<rds-icon icon="minus"></rds-icon>
	</button>
	<button rds-icon-button [disabled]="disabled" [rdsMenuTriggerFor]="itemsNumberMenu" size="m">
		<p ngProjectAs="rds-icon">{{ control.value }}</p>
	</button>
	<rds-menu
		#itemsNumberMenu="rdsMenu"
		class="number-select-menu"
		[class.short-menu]="singular.length + plural.length === 0"
		[xPosition]="'after'"
	>
		<button *ngFor="let number of max | numToArray" rds-menu-item size="m" (click)="control.setValue(number + 1)">
			{{ number + 1
			}}<span *ngIf="singular.length + plural.length > 0">
				{{ number + 1 > 1 ? plural : singular }}
			</span>
		</button>
	</rds-menu>
	<button
		rds-fab-button
		size="s"
		[disabled]="control.value === max || disabled"
		(click)="control.setValue(control.value + 1)"
	>
		<rds-icon icon="plus"></rds-icon>
	</button>
</div>
<rh-subscript-wrapper *ngIf="!!control" [hasError]="!control.valid && control.touched" [disabled]="disabled">
	<ng-content select="rds-control-hint" ngProjectAs="rds-control-hint"></ng-content>
	<ng-content select="rds-control-error" ngProjectAs="rds-control-error"></ng-content>
</rh-subscript-wrapper>
