import { createAction, props } from '@ngrx/store';
import { TickerForm, TickerNewsModel } from '@core/models/ticker.model';

export const initAddForm = createAction(
  '[TICKER - FORM] Init form',
  props<{ticker?: Partial<TickerNewsModel>}>()
);

export const getTickerDetailsRequest = createAction(
  '[TICKER - FORM] Get ticker details request',
  props<{id: number}>()
);

export const getTickerDetailsSuccess = createAction(
  '[TICKER - FORM] Get ticker details success',
  props<{ticker: Partial<TickerForm>}>()
);

export const getTickerDetailsFailure = createAction(
  '[TICKER - FORM] Get ticker details failure',
  props<{error: string}>()
);

export const setFormValue = createAction(
  '[TICKER - FORM] Set form value',
  props<{form: Partial<TickerForm>}>()
);
export const setFormTouched = createAction(
  '[TICKER - FORM] Set form touched',
  props<{touched: boolean}>()
);

export const createTickerRequest = createAction(
  '[TICKER - FORM] Save ticker request',
);

export const createTickerSuccess = createAction(
  '[TICKER - FORM] Save ticker success',
);

export const createTickerFailure = createAction(
  '[TICKER - FORM] Save ticker failure',
  props<{error: string}>()
);

export const updateTickerRequest = createAction(
  '[TICKER - FORM] Update ticker request',
  props<{id: number}>()
);

export const updateTickerSuccess = createAction(
  '[TICKER - FORM] Update ticker success',
);

export const updateTickerFailure = createAction(
  '[TICKER - FORM] Update ticker failure',
  props<{error: string}>()
);

export const clearAll = createAction(
  '[TICKER - FORM] Clear form'
);

export const deleteTickerRequest = createAction(
  '[TICKER - FORM] Delete ticker Request',
  props<{ids: Array<number>}>()
);

export const deleteTickerSuccess = createAction(
  '[TICKER - FORM] Delete ticker Success',
  props<{count: number}>()
);

export const deleteTickerFailure = createAction(
  '[TICKER - FORM] Delete ticker Failure',
  props<{ error: any }>()
);

export const openPreviewDialog = createAction(
  '[TICKER - FORM] Open preview dialog',
  props<{canSave: boolean}>()
);