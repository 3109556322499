
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {Injectable} from '@angular/core';
import * as fromScreen from '@app/root-store/ui/screen';
import * as fromRouter from '@app/root-store/router';
@Injectable()
export class IsDesktopGuard  {

  canActivate(): Observable<boolean> {
    return this.store$.pipe(
      select(fromScreen.selectIsDesktop),
      map((isDesktop) => {
        if (!isDesktop) {
          this.store$.dispatch(fromRouter.back());
          return false;
        }
        return true;
      }));
  }

  constructor(
    private store$: Store<fromScreen.State>
  ) {}
}
