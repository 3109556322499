import {createAction, props} from '@ngrx/store';
import {Audience, UserChannel, UserProfile} from '@app/core/models';
import {Language} from '@app/core/models/language.model';
import {RoleNames} from '@app/core/enums/roles.enum';
import {UserWorkdayInformation} from '@core/models/user-workday.model';
import {UserLogin} from '@app/core/models/newsboard';

export const getUserInfo = createAction(
  '[USER API] Get User Info'
);

export const getUserInfoSuccess = createAction(
  '[USER API] Get User Info Success',
  props<{ userProfile: UserProfile }>()
);

export const getUserInfoFailure = createAction(
  '[USER API] Get User Info Failure',
  props<{ error: any }>()
);

export const getUserDetails = createAction(
  '[USER API] Get User Details',
  props<{ login: string }>()
);

export const getUserDetailsSuccess = createAction(
  '[USER API] Get User Details Success',
  props<{ user: UserWorkdayInformation }>()
);

export const getUserDetailsFailure = createAction(
  '[USER API] Get User Details Failure'
);

export const getUserInfoLoaded = createAction(
  '[USER API] User Info Loaded',
  props<{ userProfile: UserProfile }>()
);

export const getUserInfoCompleted = createAction(
  '[USER API] Get User Info Completed'
);

export const getUserChannels = createAction(
  '[USER API REQUEST] Get User Channels'
);

export const getUserChannelsSuccess = createAction(
  '[USER API SUCCESS] Get User Channels Completed',
  props<{ channels: UserChannel[] }>()
);

export const getUserChannelsError = createAction(
  '[USER API ERROR] Get User Info Failure',
  props<{ error: any }>()
);

export const updateUserChannels = createAction(
  '[USER API UPDATE CHANNEL] Update User Channels',
  props<{ channels: Array<UserChannel> }>()
);

export const getNewsBoardUserRequest = createAction(
  '[USER API ROLES] Get NewsBoard User',
);

export const getNewsBoardUserSuccess = createAction(
  '[USER API ROLES SUCCESS] Get NewsBoard User Success',
  props<{ user: UserLogin }>()
);

export const getNewsBoardUserError = createAction(
  '[USER API ROLES ERROR] Get NewsBoard User Error',
  props<{ error: any }>()
);

export const getUserRoles = createAction(
  '[USER API ROLES] Get User Roles',
);

export const getUserRolesSuccess = createAction(
  '[USER API ROLES SUCCESS] Get User NewsBoard Success',
  props<{ roles: Array<RoleNames> }>()
);

export const getUserRolesError = createAction(
  '[USER API ROLES ERROR] Get User Roles Error',
  props<{ error: any }>()
);

export const setUserRoles = createAction(
  '[USER API ROLES] Set user roles',
  props<{ roles: Array<RoleNames> }>()
);

export const setNewsboardUserRoles = createAction(
  '[USER API ROLES] Set newsboard user roles',
  props<{
    roles: {
      isAdministrator: boolean,
      isSuperAdministrator: boolean
    }
  }>()
);

export const getUserManagedTaxonomies = createAction(
  '[USER API] Get User Managed Taxonomies',
);

export const getUserManagedTaxonomiesSuccess = createAction(
  '[USER API] Get User Managed Taxonomies Success',
  props<{ managedTaxonomy: { locations: Array<string>, departments: Array<string>, functions: Array<string> } }>()
);

export const getUserManagedTaxonomiesError = createAction(
  '[USER API] Get User Managed Taxonomies Error',
  props<{ error: any }>()
);

export const setUserManagedTaxonomies = createAction(
  '[USER API ROLES] Set user Managed Taxonomies',
  props<{ managedTaxonomy: { locations: Array<string>, departments: Array<string>, functions: Array<string> } }>()
);

export const getUserPersonalizationPreferences = createAction(
  '[USER API] Get User Personalization Preferences',
);

export const getUserPersonalizationPreferencesSuccess = createAction(
  '[USER API] Get User Personalization Preferences Success',
  props<{ personalizationPreferences: Audience }>()
);

export const getUserPersonalizationPreferencesError = createAction(
  '[USER API] Get User Personalization Preferences Error',
  props<{ error: any }>()
);

export const setUserPersonalizationPreferences = createAction(
  '[USER API] Set User Personalization Preferences',
  props<{ personalizationPreferences: Audience }>()
);

export const setUserLanguage = createAction(
  '[USER API LANGUAGE] Set User Language',
  props<{ newsLanguage: Language }>()
);

export const openAreaOfInterestDialog = createAction(
  '[USER API OPEN AREA OF INTERES MODAL] Open Area of Interest Dialog'
);

export const openYourFavouritesDialog = createAction(
  '[USER API OPEN YOUR FAVOURITES MODAL] Open Your Favourites Dialog'
);

export const openQuicklinksDialog = createAction(
  '[USER API OPEN QUICKLINKS MODAL] Open Quicklinks Dialog'
);

export const setUserWasLogged = createAction(
  '[USER API] Set That User Was Logged'
);

export const setUserWasLoggedSuccess = createAction(
  '[USER API] Set That User Was Logged Success'
);

export const setUserWasLoggedFailure = createAction(
  '[USER API] Set That User Was Logged Failure'
);

export const setUserSettingsVisited = createAction(
  '[USER API] Set That User Settings Visited'
);

export const setUserSettingsVisitedSuccess = createAction(
  '[USER API] Set That User Settings Visited Success'
);

export const setUserSettingsVisitedFailure = createAction(
  '[USER API] Set That User Settings Visited Failure'
);

export const setQuickLinksPageVisited = createAction(
  '[USER API] Set That User Quicklinks Visited'
);

export const setQuickLinksPageVisitedSuccess = createAction(
  '[USER API] Set That User Quicklinks Visited Success'
);

export const setQuickLinksPageVisitedFailure = createAction(
  '[USER API] Set That User Quicklinks Visited Failure'
);

export const setCatalogPageVisited = createAction(
  '[USER API] Set That User Catalog Visited'
);

export const setCatalogPageVisitedSuccess = createAction(
  '[USER API] Set That User Catalog Visited Success'
);

export const setCatalogPageVisitedFailure = createAction(
  '[USER API] Set That User Catalog Visited Failure'
);

export const setSearchPageVisited = createAction(
  '[USER API] Set That User Search Visited'
);

export const setSearchPageVisitedSuccess = createAction(
  '[USER API] Set That User Search Visited Success'
);

export const setSearchPageVisitedFailure = createAction(
  '[USER API] Set That User Search Visited Failure'
);

export const setMyContentPageVisited = createAction(
  '[USER API] Set That User MyContent Visited'
);

export const setMyContentPageVisitedSuccess = createAction(
  '[USER API] Set That User MyContent Visited Success'
);

export const setMyContentPageVisitedFailure = createAction(
  '[USER API] Set That User MyContent Visited Failure'
);

export const setLayoutSettingsPageVisited = createAction(
  '[USER API] Set That User LayoutSettings Visited'
);

export const setLayoutSettingsPageVisitedSuccess = createAction(
  '[USER API] Set That User LayoutSettings Visited Success'
);

export const setLayoutSettingsPageVisitedFailure = createAction(
  '[USER API] Set That User LayoutSettings Visited Failure'
);

export const setMenuVisited = createAction(
  '[USER API] Set That User Menu Visited'
);

export const setMenuVisitedSuccess = createAction(
  '[USER API] Set That User Menu Visited Success'
);

export const setMenuVisitedFailure = createAction(
  '[USER API] Set That User Menu Visited Failure'
);
