import {createAction, props} from '@ngrx/store';
import {QuickLink} from '@app/layout/header/models';

export const getMyQuickLinks = createAction(
  '[Header2] Get My Quick Links'
);

export const getMyQuickLinksFailure = createAction(
  '[Header2] Get My Quick Links Failure'
);

export const getMyQuickLinksSuccess = createAction(
  '[Header2] Get My Quick Links Success',
  props<{ quickLinks: QuickLink[] }>()
);

export const addToQuickLinksSuccess = createAction(
  '[Header2] Add to Quick Links Success',
  props<{ quickLink: QuickLink }>()
);

export const toggleHeaderQuickLinks = createAction(
  '[Header2] Toggle Quick Links Menu'
);

export const closeHeaderQuickLinks = createAction(
  '[Header2] Close Quick Links Menu'
);

export const resetQuickLinksToDefault = createAction(
  '[Header2] Reset Quick Links to default'
);

export const resetQuickLinksToDefaultSuccess = createAction(
  '[Header2] Reset Quick Links to default success',
  props<{ quickLinks: QuickLink[] }>()
);

export const resetQuickLinksToDefaultFailure = createAction(
  '[Header2] Reset Quick Links to default Failure'
);

export const reorganizeQuickLinks = createAction(
  '[Header2] Reorganize Quick Links'
);

export const reorganizeQuickLinksSuccess = createAction(
  '[Header2] Reorganize Quick Links success',
);

export const reorganizeQuickLinksFailure = createAction(
  '[Header2] Reorganize Quick Links Failure'
);

export const setTemporaryFavouriteQuickLinks = createAction(
  '[Header2] Reorganize set temporary Quick Links',
  props<{ quickLinks: QuickLink[] }>()
);

export const clearTemporaryFavouriteQuickLinks = createAction(
  '[Header2] Reorganize clear temporary Quick Links',
);

export const deleteMultipleQuickLinks = createAction(
  '[Header2] Delete multiple QuickLinks'
);

export const closeDeleteMultipleQuickLinks = createAction(
  '[Header2] Close delete multiple QuickLinks'
);

export const selectQuickLinkToDelete = createAction(
  '[Header2] Select QuickLink to delete',
  props<{ id: number }>()
);

export const deselectQuickLinkToDelete = createAction(
  '[Header2] Deselect QuickLink to delete',
  props<{ id: number }>()
);

export const clearSelectedQuickLinkToDelete = createAction(
  '[Header2] Clear selected QuickLink to delete',
);

export const openDeleteQuickLinksConfirmDialog = createAction(
  '[Header2] Open delete QuickLinks confirm modal'
);

export const deleteQuickLinksRequest = createAction(
  '[Header2] Delete QuickLinks request',
  props<{ ids: Array<number> }>()
);

export const deleteQuickLinksSuccess = createAction(
  '[Header2] Delete QuickLinks success',
);

export const deleteQuickLinksFailure = createAction(
  '[Header2] Delete QuickLinks failure',
);

export const openDeleteQuickLinkConfirmDialog = createAction(
  '[Header2] Open delete QuickLink confirm modal',
  props<{ name: string, id: number }>()
);

export const deleteQuickLinkRequest = createAction(
  '[Header2] Delete QuickLink request',
  props<{ id: number }>()
);

export const deleteQuickLinkSuccess = createAction(
  '[Header2] Delete QuickLink success',
);

export const deleteQuickLinkFailure = createAction(
  '[Header2] Delete QuickLink failure',
);

export const addToTopRequest = createAction(
  '[Header2] Add to Top QuickLinks request',
  props<{ id: number }>()
);

export const addToTopSuccess = createAction(
  '[Header2] Add to Top QuickLinks success',
  props<{ quickLink: QuickLink }>()
);

export const addToTopFailure = createAction(
  '[Header2] Add to Top QuickLinks failure',
);

export const removeFromTopRequest = createAction(
  '[Header2] Remove from Top QuickLinks request',
  props<{ id: number }>()
);

export const removeFromTopSuccess = createAction(
  '[Header2] Remove from Top QuickLinks success',
  props<{ quickLink: QuickLink }>()
);

export const removeFromTopFailure = createAction(
  '[Header2] Remove from Top QuickLinks failure',
);

export const openRenameQuickLinkDialog = createAction(
  '[Header2] Open remove QuickLink Dialog',
  props<{ id: number, name: string }>()
);

export const renameQuickLinkRequest = createAction(
  '[Header2] Rename QuickLink request',
  props<{ id: number, name: string }>()
);

export const renameQuickLinkSuccess = createAction(
  '[Header2] Rename QuickLink success',
  props<{ quickLink: QuickLink }>()
);

export const renameQuickLinkFailure = createAction(
  '[Header2] Rename QuickLink failure',
);

export const openRenameQuickLinkDialogMobile = createAction(
  '[Header2] Open remove QuickLink Dialog Mobile',
  props<{ id: number, name: string }>()
);

export const quicklinksTabOpened = createAction(
  '[Header2] Set QLs tab opened',
);

export const catalogsTabOpened = createAction(
  '[Header2] Set Catalogs tab opened'
);
