import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as fromReducer from './template-form.reducer';
import * as fromActions from './template-form.actions';
import * as fromSelectors from './template-form.selectors';
import * as fromRouter from '@app/root-store/router';
import * as fromBackButton from '@app/root-store/ui/back-button';
import * as fromLanguages from '@core/core-store/languages-dict';
import {catchError, filter, map, mergeMap, of, switchMap, withLatestFrom} from 'rxjs';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {RdsDialogService} from '@rds/angular-components';
import cloneDeep from '@lodash-es/cloneDeep';

import {EMPTY_TEMPLATE_FORM, Template, TemplateForm, TemplateRoles} from '@app/core/models/newsboard/template';
import {CustomValidators} from '@app/shared/form-controls/validators/validator.function';
import {FormStepStatus} from '../../../../shared/form-status-badge/form-status-badge.component';
import {
  SaveTemplateDialogComponent,
  SaveTemplateDialogData
} from '@app/shared/dialogs/save-template-dialog/save-template-dialog.component';
import {SelectDialogComponent, SelectDialogData} from '@app/shared/dialogs/select-dialog/select-dialog.component';
import {ConfirmDialogComponent, ConfirmDialogData} from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import {NewsDetails, SimpleUser} from '@app/core/models/newsboard';
import {ADMIN_ROLES} from '@app/shared/filters/filters-model';
import {TemplatesService} from '../../templates.service';
import {AdminService} from '@app/newsboard/admin/admin.service';
import {
  NewsPreviewDialogComponent,
  NewsPreviewDialogData
} from '@app/shared/dialogs/previews/news-preview-dialog/news-preview-dialog.component';

@Injectable()
export class TemplateFormEffects {

  public defaultTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  public initAddForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initAddForm),
      filter(({id}) => !id),
      map(({user}): Partial<TemplateForm> => {
        const template = cloneDeep(EMPTY_TEMPLATE_FORM);
        return ({
          ...template,
          permissions: {
            ...template.permissions,
            owners: [{
              ...user,
              role: 'owners',
              order: 0,
            }]
          }
        })
      }),
      mergeMap((form) => [
        fromActions.setFormValue({form: {...form}}),
        fromActions.setInitialFormValue({form: {...form}}),
        fromActions.setFormChecked()
      ])
    ), {dispatch: true}
  );

  public initCreateFormFromTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initCreateFormFromTemplate),
      mergeMap(() => [
        fromRouter.go({path: 'news/create/content', queryParams: {}})
      ])
    ), {dispatch: true}
  );

  public initEditForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initAddForm),
      filter(({id}) => !!id),
      map(({id}) => fromActions.getTemplateDetailsRequest({id}))
    ), {dispatch: true}
  );

  public getTemplateDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getTemplateDetailsRequest),
      switchMap(({id}) => this.templatesService.get(id).pipe(
        mergeMap((template: Template) => {
          let role: TemplateRoles = 'Viewer';
          if (template.isEditor) {
            role = 'Editor'
          }
          if (template.isOwner) {
            role = 'Owner'
          }
          const general: FormGroup = new FormGroup({
            id: new FormControl(template.id),
            title: new FormControl(template.title),
            language: new FormControl(template.language)
          });
          general.updateValueAndValidity();

          const languageVersions: FormArray = new FormArray([]);
          const primaryLanguage = template.content.find(c => c.language === template.language);
          template.content.forEach(c => {
            const syncImage = JSON.stringify(primaryLanguage.image) === JSON.stringify(c.image);
            languageVersions.push(new FormGroup({
              language: new FormControl(c.language, [Validators.required]),
              link: new FormControl(c.link),
              title: new FormControl(c.title, [Validators.required]),
              abstract: new FormControl(c.abstract, [Validators.required]),
              image: new FormControl(c.image),
              html: new FormControl(c.html, [Validators.required]),
              syncImage: new FormControl(syncImage),
            }))
          })

          const content: FormGroup = new FormGroup({
            content: languageVersions,
          });
          content.updateValueAndValidity();


          const permissions: FormGroup = new FormGroup({
            permissions: new FormGroup({
              owners: new FormControl(!!template.owners ? template.owners : []),
              editors: new FormControl(!!template.editors ? template.editors : []),
              viewers: new FormControl(!!template.viewers ? template.viewers : []),
            }, CustomValidators.validateTemplatePermissions),
            isPredefined: new FormControl(template.isPredefined)
          });
          if (permissions.controls.isPredefined.value) {
            permissions.controls.permissions.clearValidators();
            permissions.controls.permissions.updateValueAndValidity();
          }
          permissions.updateValueAndValidity();
          return [
            fromActions.setGeneralFormStatus({status: general.status}),
            fromActions.setGeneralStepStatus({status: general.status === 'VALID' ? FormStepStatus.COMPLETED : FormStepStatus.INCOMPLETE}),
            fromActions.setContentFormStatus({status: content.status}),
            fromActions.setContentStepStatus({status: content.status === 'VALID' ? FormStepStatus.COMPLETED : FormStepStatus.INCOMPLETE}),
            fromActions.setPermissionsFormStatus({status: permissions.status}),
            fromActions.setPermissionsStepStatus({status: permissions.status === 'VALID' ? FormStepStatus.COMPLETED : FormStepStatus.INCOMPLETE}),
            fromActions.getTemplateDetailsSuccess({
                template: {
                  ...general.value,
                  ...content.value,
                  ...permissions.value
                  // publishTime: news.publishDate ? this.getTime(new Date(news.publishDate)) : null,
                  // expiryTime: news.expiryDate ? this.getTime(new Date(news.expiryDate)) : null,
                },
                role
              },
            ),
            fromActions.setFormChecked()
          ]
        }),
        catchError(({message}) => of(fromActions.getTemplateDetailsFailure({error: message})))
      )),
    ), {dispatch: true}
  );

  public getNewsDetailsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getTemplateDetailsSuccess),
      mergeMap(({template}) => [
        fromActions.setFormValue({form: template}),
      ]),
    ), {dispatch: true}
  );

  public saveTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveTemplateRequest),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectCreateFormForRequest))),
      switchMap(([action, form]) => this.templatesService.createTemplate(form).pipe(
        map(form => fromActions.saveTemplateSuccess()),
        catchError(({message}) => of(fromActions.saveTemplateFailure({error: message})))
      )),
    ), {dispatch: true}
  );

  public openSaveTemplateDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openSaveTemplateDialog),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectForm))),
      map(([action, form]) => {
        const template: Template = {
          id: form.id,
          content: form.content,
          createdDate: new Date(),
          imagePreview: form.imagePreview,
          editors: form.permissions.editors,
          owners: form.permissions.owners,
          viewers: form.permissions.viewers,
          isPredefined: form.isPredefined,
          language: form.language,
          modifiedDate: new Date(),
          title: form.title,
        }
        const data: SaveTemplateDialogData = {
          template
        }

        const dialog = this.dialogService.open(SaveTemplateDialogComponent, {
          data,
          size: 'm',
          disableClose: true,
          closeOnNavigation: false,
        });
        return ({dialog, template})
      }),
      switchMap(({dialog, template}) => dialog.afterClosed().pipe(
        filter(data => !!data),
        mergeMap(({url, error}) => {
          if (!!url) {
            const saveAction = !!template.id ? fromActions.updateTemplateRequest({id: template.id}) : fromActions.saveTemplateRequest()
            return [
              fromActions.setFormValue({form: {imagePreview: url}}),
              saveAction
            ]
          } else {
            return of(null)
          }
        })
      )),
    ), {dispatch: true})

  public updateTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateTemplateRequest),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectUpdateFormForRequest))),
      switchMap(([{id}, form]) => this.templatesService.updateTemplate(id, form).pipe(
        map(form => fromActions.updateTemplateSuccess()),
        catchError(({message}) => of(fromActions.updateTemplateFailure({error: message})))
      )),
    ), {dispatch: true}
  );

  public saveSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.saveTemplateSuccess,
        fromActions.updateTemplateSuccess,
      ),
      mergeMap(() => [
        fromActions.setFormTouched({touched: false}),
        fromBackButton.back({defaultLabel: 'Add News', defaultRoute: 'news/create'})
      ]),
    ), {dispatch: true}
  );

  public primaryLanguageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.primaryLanguageChanged),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectPrimaryLanguage))),
      map(([{lang}, oldLang]) => fromActions.updatePrimaryLanguage({oldLang, newLang: lang}))
    ), {dispatch: true}
  );

  public openAddLanguageDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openAddLanguageDialog),
      withLatestFrom(
        this.store$.pipe(select(fromLanguages.selectAll)),
        this.store$.pipe(select(fromSelectors.selectLanguages))
      ),
      map(([action, languages, currentLanguages]) => {
        const data: SelectDialogData = {
          title: 'Specify language version you want to create',
          selects: [
            {
              selectType: 'select',
              prop: 'language',
              options: languages.filter(l => !(currentLanguages as Array<string>).includes(l.code)),
              multiple: false,
              required: true,
              entityType: 'language'
            },
          ],
          confirmButtonLabel: 'Add language version'
        }
        const dialog = this.dialogService.open(SelectDialogComponent, {
          size: 'l',
          data
        });
        return ({dialog})
      }),
      switchMap(({dialog}) => dialog.afterClosed().pipe(
        filter(data => !!data),
        map((data: SelectDialogData) => {
          return ({language: data.selects.find(s => s.prop === 'language').value})
        })
      )),
      map(({language}) => fromActions.addLanguageVersion({language}))
    ), {dispatch: true}
  );

  public openLeavePageDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openLeavePageDialog),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectChangesMade))
      ),
      filter(([{url}, changesMade]) => !!changesMade),
      map(([{url}, changesMade]) => {
        const data: ConfirmDialogData = {
          ids: [],
          messages: [],
          title: 'Do you want to discard changes?',
          confirmButtonLabel: 'Yes, discard',
          confirmButtonType: 'primary'
        }
        const dialog = this.dialogService.open(ConfirmDialogComponent, {
          data,
          size: 's',
        });
        return ({dialog, url})
      }),
      switchMap(({dialog, url}) => dialog.afterClosed().pipe(
        filter(data => !!data),
        map((data: SelectDialogData) => {
          return ({url})
        })
      )),
      map(({url}) => fromRouter.go({path: url, queryParams: {}}))
    ), {dispatch: true})

  public closeWithoutDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openLeavePageDialog),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectChangesMade))
      ),
      filter(([{url}, changesMade]) => !changesMade),
      map(([{url}]) => fromRouter.go({path: url, queryParams: {}}))
    ), {dispatch: true})

  public openPreviewDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openPreviewDialog),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectForm)),
        this.store$.pipe(select(fromSelectors.canSaveTemplate)),
      ),
      map(([action, form, canSaveTemplate]) => {
        const news: NewsDetails = {
          content: form.content,
          contentType: 'Embedded',
          editors: form.permissions.editors,
          id: -1,
          isEditor: false,
          isOwner: true,
          language: form.language,
          owners: form.permissions.owners,
          relatedNews: [],
          status: 'Draft',
          authors: [],
          commentsEnabled: true,
          topics: [],
          assignedChannels: [],
          byline: null,
          contact: null,
          suggestedChannels: [],
        }
        const data: NewsPreviewDialogData = {
          news,
          channel: null,
          context: 'TemplateEditor',
          buttons: {
            canSaveTemplate: {
              disabled: !canSaveTemplate,
              visible: true
            }
          }
        }
        const dialog = this.dialogService.open(NewsPreviewDialogComponent, {
            size: 'xl',
            height: '100%',
            maxHeight: '100%',
            data
          }
        )
        return ({dialog, form})
      }),
      switchMap(({dialog, form}) => dialog.afterClosed().pipe(
        filter(data => !!data),
        map((data: SelectDialogData) => {
          return ({id: form.id})
        })
      )),
      map(({id}) => fromActions.openSaveTemplateDialog())
    ), {dispatch: true});

  public loadSuperAdmins$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadSuperAdminsRequest),
      switchMap(({currentUser}) => this.adminService.getAdmins({
          pageIndex: 0,
          pageSize: 100,
          filters: {
            roles: {
              changable: false,
              label: 'Roles',
              multiple: false,
              options: ADMIN_ROLES,
              value: ['SuperAdmin']
            }
          },
          sort: {
            active: 'identifier',
            direction: 'asc'
          }
        }).pipe(
          map(({data}) => ({
              superAdmins: data.map((user): SimpleUser => {
                return {
                  name: user.name,
                  avatar: user.avatar,
                  email: user.email,
                  identifier: user.identifier
                }
              })
            }),
          ),
          map(({superAdmins}) => fromActions.loadSuperAdminsSuccess({superAdmins})),
          catchError(({message}) => of(fromActions.loadSuperAdminsFailure({error: message})))),
      )), {dispatch: true}
  );


  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private templatesService: TemplatesService,
    private dialogService: RdsDialogService,
    private adminService: AdminService,
  ) {
  }

  getTime(date: Date) {
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  }

}
