import {icons} from 'ckeditor5/src/core';
import {
    ButtonView,
    createLabeledInputText,
    FocusCycler,
    LabeledFieldView,
    submitHandler,
    View,
    ViewCollection
} from 'ckeditor5/src/ui';
import {FocusTracker, KeystrokeHandler} from 'ckeditor5/src/utils';

export default class CustomImageAlternativeTextFormView extends View {

    constructor(validators, locale) {
        super(locale);
        this.focusTracker = new FocusTracker();
        this.keystrokes = new KeystrokeHandler();
        this.labeledInput = this._createLabeledInputView();
        this.saveButtonView = this._createButton('Save', icons.check, 'ck-button-save');
        this.saveButtonView.type = 'submit';
        this.cancelButtonView = this._createButton('Cancel', icons.cancel, 'ck-button-cancel', 'cancel');
        this._focusables = new ViewCollection();
        this._focusCycler = new FocusCycler({
            focusables: this._focusables,
            focusTracker: this.focusTracker,
            keystrokeHandler: this.keystrokes,
            actions: {
                // Navigate form fields backwards using the Shift + Tab keystroke.
                focusPrevious: 'shift + tab',
                // Navigate form fields forwards using the Tab key.
                focusNext: 'tab'
            }
        });
        this.setTemplate({
            tag: 'form',
            attributes: {
                class: [
                    'ck',
                    'ck-text-alternative-form',
                    'ck-responsive-form'
                ],
                // https://github.com/ckeditor/ckeditor5-image/issues/40
                tabindex: '-1'
            },
            children: [
                this.labeledInput,
                this.saveButtonView,
                this.cancelButtonView
            ]
        });
    }

    /**
     * @inheritDoc
     */
    render() {
        super.render();
        this.keystrokes.listenTo(this.element);
        submitHandler({view: this});
        [this.labeledInput, this.saveButtonView, this.cancelButtonView]
            .forEach(v => {
                // Register the view as focusable.
                this._focusables.add(v);
                // Register the view in the focus tracker.
                this.focusTracker.add(v.element);
            });
    }

    /**
     * @inheritDoc
     */
    destroy() {
        super.destroy();
        this.focusTracker.destroy();
        this.keystrokes.destroy();
    }

    /**
     * Creates the button view.
     *
     * @param label The button label
     * @param icon The button's icon.
     * @param className The additional button CSS class name.
     * @param eventName The event name that the ButtonView#execute event will be delegated to.
     * @returns The button view instance.
     */
    _createButton(label, icon, className, eventName) {
        const button = new ButtonView(this.locale);
        button.set({
            label,
            icon,
            tooltip: true
        });
        button.extendTemplate({
            attributes: {
                class: className
            }
        });
        if (eventName) {
            button.delegate('execute').to(this, eventName);
        }
        return button;
    }

    /**
     * Creates an input with a label.
     *
     * @returns Labeled field view instance.
     */
    _createLabeledInputView() {
        const labeledInput = new LabeledFieldView(this.locale, createLabeledInputText);
        labeledInput.label = 'Text alternative';
        return labeledInput;
    }
}
