import { createAction, props } from '@ngrx/store';
import { SiteMapRoute, SiteMapRouteStatus } from './sitemap.reducer';
import { Route } from '@angular/router';
import { SiteMapTableFilters } from '@app/sitemap/models/sitemap-filters';

export const initReadingRoutes = createAction(
  '[SITEMAP] Init reading routes',
  props<{forceRefresh: boolean}>()
);

export const loadRoutesFromFile = createAction(
  '[SITEMAP] Load routes from file',
  props<{routes: Array<SiteMapRoute>}>()
)

export const checkRoute = createAction(
  '[SITEMAP] Check route',
  props<{route: Route, parent?: string, module?: string}>()
);

export const readChildrenRoute = createAction(
  '[SITEMAP] Read children route',
  props<{route: Route, parent?: string, module?: string}>()
);

export const readLazyRoute = createAction(
  '[SITEMAP] Read lazy route',
  props<{route: Route, parent?: string, module?: string}>()
);

export const readRoute = createAction(
  '[SITEMAP] Read route',
  props<{route: Route, parent?: string, module?: string}>()
);

export const addRoute = createAction(
  '[SITEMAP] Add route',
  props<{route: SiteMapRoute}>()
);

export const updateStatus = createAction(
  '[SITEMAP] Update status',
  props<{url: string, status: SiteMapRouteStatus}>()
);

export const addNote = createAction(
  '[SITEMAP] Add note',
  props<{url: string, note: string}>()
);

export const updateNotes = createAction(
  '[SITEMAP] Update notes',
  props<{url: string, notes: Array<string>}>()
);

export const resetAll = createAction(
  '[SITEMAP] Reset all',
);

export const routesLoaded = createAction(
  '[SITEMAP] routes loaded'
);

export const updateFilters = createAction(
  '[SITEMAP] Update filters',
  props<{filters: SiteMapTableFilters}>()
);