import {
	DictionaryFilterDefinition,
	DictionaryTypeEnum,
	FilterDefinition,
	FilterTypeEnum,
	SEARCH_FILTER,
} from '../filters-model';

export interface RecipientsTableFilters {
	campaigns?: DictionaryFilterDefinition;
	search?: FilterDefinition;
}

export const INITIAL_RECIPIENTS_FILTERS: RecipientsTableFilters = {
	campaigns: {
		type: FilterTypeEnum.DICTIONARY,
		dictionary: DictionaryTypeEnum.CAMPAIGNS,
		label: 'Campaigns',
		changable: true,
		value: [],
	},
	...SEARCH_FILTER,
};
