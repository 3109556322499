import { on, Action, createReducer } from '@ngrx/store';
import * as fromActions from './user-organisation.actions';
import { UserWorkdayOrganisation } from '@app/core/models/user-workday.model';

export interface State {
  workdayData: UserWorkdayOrganisation;
}

export const initialState: State = {
  workdayData: null,
};

export const userOrganisationReducer = createReducer(
  initialState,
  on(fromActions.getUserOrganisationByLoginSuccess, (state, { organisation}) => ({
    ...state,
    workdayData: organisation
  })),
  on(fromActions.clearUserOrganisation, state => ({
    ...state,
    workdayData: null
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return userOrganisationReducer(state, action);
}
export const getUserOrganisation = (state: State) => state.workdayData;
