import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromAuth from '@core/auth/store'
import * as fromRouter from '@app/root-store/router'
import { environment } from '@env/environment';
@Component({
  selector: 'atr-google-auth',
  templateUrl: './google-auth.component.html',
  styleUrls: ['./google-auth.component.scss']
})
export class GoogleAuthComponent {

  constructor(private store$: Store<any>, private route: ActivatedRoute) {
    const { code, scope} = this.route.snapshot.queryParams;
    if (!!code) {
      this.store$.dispatch(fromAuth.getAuthorizationCodeSuccess({
        code,
        scope,
        redirectUri: `${environment.url}/google-auth`
      })); 
      this.store$.dispatch(fromRouter.go({path: '/', queryParams: {}}))
    }
  }
}
