import { createReducer, Action, on} from '@ngrx/store';
import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import * as fromActions from './related-news.actions';

import { ChannelDetails, News, NewsSuggestion } from '@app/core/models/newsboard';
import { Channel, Pagination } from '@app/core/models';
import { INITIAL_RELATED_NEWS_FILTERS, NewsTableFilters, TableState } from '@app/shared/filters/filters-model';

export const feature = 'related-news';

export const adapter: EntityAdapter<Partial<News>> =
 createEntityAdapter<Partial<News>>({
  selectId: (e) => e.id
 });

 export const suggestionAdapter: EntityAdapter<Partial<NewsSuggestion>> =
 createEntityAdapter<Partial<NewsSuggestion>>({
  selectId: (e) => `c${e.channel.id}n${e.id}`
 });

export interface State {
  newsPreviewDetails: News;
  selected: EntityState<Partial<News>>;
  relatedNews: TableState<Partial<News>, NewsTableFilters>;
}

export const initialState: State = {
  newsPreviewDetails: null,
  selected: adapter.getInitialState(),
  relatedNews: {
    data: adapter.getInitialState(),
    pagination: {
      ...Pagination,
      pageIndex: 0,
      pageSize: 6,
    },
    sort: {
      active: 'createdDate',
      direction: 'desc'
    },
    defaultSort: {
      active: 'createdDate',
      direction: 'desc'
    },
    filters: INITIAL_RELATED_NEWS_FILTERS,
    defaultFilters: INITIAL_RELATED_NEWS_FILTERS,
    isLoading: false,
    resetIndex: false,
  },
};

const tableReducer = createReducer(
  initialState,
  on(fromActions.getRelatedNewsRequest, (state) => ({
    ...state,
    relatedNews: {
      ...state.relatedNews,
      isLoading: true
    }
  })),
  on(fromActions.getRelatedNewsSuccess, fromActions.getRelatedNewsFailure, (state) => ({
    ...state,
    relatedNews: {
      ...state.relatedNews,
      isLoading: false
    }
  })),
  on(fromActions.getRelatedNewsSuccess, (state, {data, pagination}) => ({
    ...state,
    relatedNews: {
      ...state.relatedNews,
      data: adapter.setAll(data, state.relatedNews.data),
      pagination,
      resetIndex: false,
    }
  })),
  on(fromActions.changeRelatedNewsFilters, (state, {filters}) => ({
    ...state,
    relatedNews: {
      ...state.relatedNews,
      filters,
      resetIndex: true
    }
  })),
  on(fromActions.changeRelatedNewsSort, (state, {active, direction}) => ({
    ...state,
    relatedNews: {
      ...state.relatedNews,
      sort: {
        active,
        direction
      }
    }
  })),
  on(fromActions.changeRelatedNewsPagination, (state, {pageIndex, pageSize}) => ({
    ...state,
    relatedNews: {
      ...state.relatedNews,
      pagination: {
        ...state.relatedNews.pagination,
        pageIndex,
        pageSize
      }
    }
  })),
  on(fromActions.newsSelectionChanged, (state, {news}) => ({
    ...state,
    selected: adapter.setAll(news, state.selected)
  })),
  on(fromActions.addRelatedNews, (state, {news}) => ({
    ...state,
    selected: adapter.upsertMany(news, state.selected)
  })),
  on(fromActions.removeRelatedNews, (state, {id}) => ({
    ...state,
    selected: adapter.removeOne(id, state.selected)
  })),
  on(fromActions.clearAll, (state) => ({
    ...initialState
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return tableReducer(state, action);
}

export const getChannelId = (details: ChannelDetails) => details?.id;

export const getRelatedNews = (state: State) => state.relatedNews;

export const getPagination = (table: TableState<Partial<News>, NewsTableFilters>) => table.pagination;
export const getSort = (table: TableState<Partial<News>, NewsTableFilters>) => table.sort;
export const getDefaultSort = (table: TableState<Partial<News>, NewsTableFilters>) => table.defaultSort;
export const getFilters = (table: TableState<Partial<News>, NewsTableFilters>) => table.filters;
export const getDefaultFilters = (table: TableState<Partial<News>, NewsTableFilters>) => table.defaultFilters;
export const getIsLoading = (table: TableState<Partial<News>, NewsTableFilters>) => table.isLoading;

export const getRequestData = ({pagination, sort, filters, resetIndex}: TableState<Partial<News>, NewsTableFilters>) => ({
  pageIndex: pagination.pageIndex,
  pageSize: pagination.pageSize,
  sort,
  filters,
  resetIndex});

export const getNewsData = (table: TableState<Partial<News>, NewsTableFilters>) => adapter.getSelectors().selectAll(table.data);
export const getNewsSuggestionData = (table: TableState<Partial<News>, NewsTableFilters>) => suggestionAdapter.getSelectors().selectAll(table.data);

export const getSelectedNews = (state: State) => adapter.getSelectors().selectAll(state.selected);

// Pagination fields
export const getPageSize = (pagination: Pagination) => pagination.pageSize;
export const getPageIndex = (pagination: Pagination) => pagination.pageIndex;
export const getTotalCount = (pagination: Pagination) => pagination.totalCount;

// Filters fields
export const getSearch = (filters: NewsTableFilters) => filters.search.value;

export const getEntitiesByIds = (entities: Dictionary<Channel>, ids: string[]): Channel[] => ids.map(id => entities[id]);
export { NewsTableFilters };

