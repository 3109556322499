import {Action, createReducer, on} from '@ngrx/store';
import * as fromActions from './newsletter-dashboard.actions';
import {
  ColorModel,
  ColorsPalette,
  DraftModel,
  NewsletterListItemModel,
  NewsletterListModel2,
  SelectedTemplateModel,
  TemplateModel
} from '@app/newsletter/models';
import {createEntityAdapter, Dictionary, EntityAdapter} from '@ngrx/entity';
import {Pagination} from '@core/models/pagination.model';
import {STATUS_LOADING, STATUS_NOT_ASKED, STATUS_SUCCESS} from '@core/models';

export const adapter: EntityAdapter<TemplateModel> =
  createEntityAdapter<TemplateModel>();

export const adapterLastModifiedDate: EntityAdapter<NewsletterListItemModel> =
  createEntityAdapter<NewsletterListItemModel>({
    sortComparer: (a, b) => {
      return a.lastModifiedDate > b.lastModifiedDate ? -1 : 1;
    },
  });
export const adapterScheduleSentDate: EntityAdapter<NewsletterListItemModel> =
  createEntityAdapter<NewsletterListItemModel>({
    sortComparer: (a, b) => a.scheduleSentDate > b.scheduleSentDate ? 1 : -1
  });

export enum previousTabEnum {
  myNewsletters = 0,
  sharedNewsletters = 1
}

export interface State {
  draft: DraftModel;
  selectedTemplate: SelectedTemplateModel | null;
  selectedColor: ColorModel;
  templates: {
    ids: string [] | number[];
    entities: Dictionary<TemplateModel>;
  };
  templateAndColor: { color: string, templateId: string };
  selectedTab: number;
  myRecentlySent: NewsletterListModel2;
  sharedRecentlySent: NewsletterListModel2;
  myRecentDraft: NewsletterListModel2;
  sharedRecentDraft: NewsletterListModel2;
  myRecentlyScheduled: NewsletterListModel2;
  sharedRecentlyScheduled: NewsletterListModel2;
  isSaveProcessActive: boolean;
}

export const initialState: State = {
  draft: null,
  selectedTemplate: null,
  selectedColor: ColorsPalette[0],
  templates: {...adapter.getInitialState()},
  templateAndColor: null,
  selectedTab: previousTabEnum.myNewsletters,
  myRecentlyScheduled: {...adapterScheduleSentDate.getInitialState(), ...Pagination, ...STATUS_NOT_ASKED},
  myRecentlySent: {...adapterLastModifiedDate.getInitialState(), ...Pagination, ...STATUS_NOT_ASKED},
  sharedRecentlyScheduled: {...adapterScheduleSentDate.getInitialState(), ...Pagination, ...STATUS_NOT_ASKED},
  sharedRecentlySent: {...adapterLastModifiedDate.getInitialState(), ...Pagination, ...STATUS_NOT_ASKED},
  isSaveProcessActive: false,
  myRecentDraft: {...adapterLastModifiedDate.getInitialState(), ...Pagination, ...STATUS_NOT_ASKED},
  sharedRecentDraft: {...adapterLastModifiedDate.getInitialState(), ...Pagination, ...STATUS_NOT_ASKED}
};

export const newsletterDashboardReducer = createReducer(
  initialState,
  on(fromActions.getDraftSuccess, (state, {draft}) => ({
    ...state,
    draft
  })),
  on(fromActions.changeTemplate, (state, {selectedTemplate}) => ({
    ...state,
    selectedTemplate
  })),
  on(fromActions.changeColor, (state, {selectedColor}) => ({
    ...state,
    selectedColor,
  })),
  on(fromActions.changeTemplateAndColor, (state, {templateAndColor}) => ({
    ...state,
    templateAndColor
  })),
  on(fromActions.clearLoadedForm, (state, {}) => ({
    ...state,
    templateAndColor: null,
    selectedTemplate: null,
    selectedColor: ColorsPalette[0]
  })),
  on(fromActions.getTemplatesSuccess, (state, {templates}) => ({
    ...state,
    templates: {
      ...adapter.setAll(templates.map((tm) => {
        return {...tm, id: tm.templateId};
      }), state.templates)
    }
  })),
  on(fromActions.getNewslettersSuccess, (state, {newsletters}) => ({
    ...state,
    newsletters
  })),
  on(fromActions.changeTab, (state, {selectedTab}) => ({
    ...state,
    selectedTab
  })),

  on(fromActions.clearMyRecentDraft, (state, {}) => ({
    ...state,
    myRecentDraft: {
      ...adapterLastModifiedDate.removeAll(state.myRecentDraft)
    }
  })),
  on(fromActions.clearMyRecentlySent, (state, {}) => ({
    ...state,
    myRecentlySent: {
      ...adapterLastModifiedDate.removeAll(state.myRecentlySent)
    }
  })),
  on(fromActions.clearMyRecentlyScheduled, (state, {}) => ({
    ...state,
    myRecentlyScheduled: {
      ...adapterScheduleSentDate.removeAll(state.myRecentlyScheduled)
    }
  })),
  on(fromActions.getMyRecentDraft, (state, {}) => ({
    ...state,
    myRecentDraft: {
      ...state.myRecentDraft,
      ...STATUS_LOADING
    }
  })),
  on(fromActions.getMyRecentDraftSuccess, (state, {myRecentDraft, isLoadMore}) => ({
    ...state,
    myRecentDraft: {
      // tslint:disable-next-line:max-line-length
      ...(isLoadMore ? adapterLastModifiedDate.upsertMany(myRecentDraft.data, state.myRecentDraft) : adapterLastModifiedDate.setAll(myRecentDraft.data, state.myRecentDraft)),
      ...myRecentDraft,
      isLast: myRecentDraft.isLast || myRecentDraft.totalCount === state.myRecentDraft.ids.length,
      ...STATUS_SUCCESS
    }
  })),
  on(fromActions.getMyRecentlyScheduled, (state, {}) => ({
    ...state,
    myRecentlyScheduled: {
      ...state.myRecentlyScheduled,
      ...STATUS_LOADING,
    }
  })),
  on(fromActions.getMyRecentlyScheduledSuccess, (state, {myRecentlyScheduled, isLoadMore}) => ({
    ...state,
    myRecentlyScheduled: {
      // tslint:disable-next-line:max-line-length
      ...(isLoadMore ? adapterScheduleSentDate.upsertMany(myRecentlyScheduled.data, state.myRecentlyScheduled) : adapterScheduleSentDate.setAll(myRecentlyScheduled.data, state.myRecentlyScheduled)),
      ...myRecentlyScheduled,
      isLast: myRecentlyScheduled.isLast || myRecentlyScheduled.totalCount === state.myRecentlyScheduled.ids.length,
      ...STATUS_SUCCESS
    }
  })),

  on(fromActions.getMyRecentlySent, (state, {}) => ({
    ...state,
    myRecentlySent: {
      ...state.myRecentlySent,
      ...STATUS_LOADING
    }
  })),
  on(fromActions.getMyRecentlySentSuccess, (state, {myRecentlySent, isLoadMore}) => ({
    ...state,
    myRecentlySent: {
      // tslint:disable-next-line:max-line-length
      ...(isLoadMore ? adapterLastModifiedDate.upsertMany(myRecentlySent.data, state.myRecentlySent) : adapterLastModifiedDate.setAll(myRecentlySent.data, state.myRecentlySent)),
      ...myRecentlySent,
      isLast: myRecentlySent.isLast || myRecentlySent.totalCount === state.myRecentlySent.ids.length,
      ...STATUS_SUCCESS
    }
  })),
  on(fromActions.getSharedRecentDraft, (state, {}) => ({
    ...state,
    sharedRecentDraft: {
      ...state.sharedRecentDraft,
      ...STATUS_LOADING
    }
  })),
  on(fromActions.getSharedRecentDraftSuccess, (state, {sharedRecentDraft, isLoadMore}) => ({
    ...state,
    sharedRecentDraft: {
      // tslint:disable-next-line:max-line-length
      ...(isLoadMore ? adapterLastModifiedDate.upsertMany(sharedRecentDraft.data, state.sharedRecentDraft) : adapterLastModifiedDate.setAll(sharedRecentDraft.data, state.sharedRecentDraft)),
      ...sharedRecentDraft,
      isLast: sharedRecentDraft.isLast || sharedRecentDraft.totalCount === state.sharedRecentDraft.ids.length,
      ...STATUS_SUCCESS
    }
  })),
  on(fromActions.getSharedRecentlyScheduled, (state, {}) => ({
    ...state,
    sharedRecentlyScheduled: {
      ...state.sharedRecentlyScheduled,
      ...STATUS_LOADING,
    }
  })),
  on(fromActions.getSharedRecentlyScheduledSuccess, (state, {sharedRecentlyScheduled, isLoadMore}) => ({
    ...state,

    sharedRecentlyScheduled: {
      // tslint:disable-next-line:max-line-length
      ...(isLoadMore ? adapterScheduleSentDate.upsertMany(sharedRecentlyScheduled.data, state.sharedRecentlyScheduled) : adapterScheduleSentDate.setAll(sharedRecentlyScheduled.data, state.sharedRecentlyScheduled)),
      ...sharedRecentlyScheduled,
      isLast: sharedRecentlyScheduled.isLast || sharedRecentlyScheduled.totalCount === state.sharedRecentlyScheduled.ids.length,
      ...STATUS_SUCCESS
    }
  })),
  on(fromActions.getSharedRecentlySent, (state, {}) => ({
    ...state,
    sharedRecentlySent: {
      ...state.sharedRecentlySent,
      ...STATUS_LOADING
    }
  })),
  on(fromActions.getSharedRecentlySentSuccess, (state, {sharedRecentlySent, isLoadMore}) => ({
    ...state,
    sharedRecentlySent: {
        // tslint:disable-next-line:max-line-length
      ...(isLoadMore ? adapterLastModifiedDate.upsertMany(sharedRecentlySent.data, state.sharedRecentlySent) : adapterLastModifiedDate.setAll(sharedRecentlySent.data, state.sharedRecentlySent)),
      ...sharedRecentlySent,
      isLast: sharedRecentlySent.isLast || sharedRecentlySent.totalCount === state.sharedRecentlySent.ids.length,
      ...STATUS_SUCCESS
    }
  })),
  on(fromActions.deleteNewsletterSuccess,
    fromActions.cancelNewsletterSuccess, (state, {id}) => ({
      ...state,
      myRecentlyScheduled: {
        ...state.myRecentlyScheduled,
        ...adapterScheduleSentDate.removeOne(id, state.myRecentlyScheduled),
        ...STATUS_SUCCESS
      },
      myRecentDraft: {
        ...state.myRecentDraft,
        ...adapterLastModifiedDate.removeOne(id, state.myRecentDraft),
        ...STATUS_SUCCESS
      },
      sharedRecentlyScheduled: {
        ...state.sharedRecentlyScheduled,
        ...adapterScheduleSentDate.removeOne(id, state.sharedRecentlyScheduled),
        ...STATUS_SUCCESS
      },
      sharedRecentDraft: {
        ...state.sharedRecentDraft,
        ...adapterLastModifiedDate.removeOne(id, state.sharedRecentDraft),
        ...STATUS_SUCCESS
      }
    })),
  on(fromActions.setContributorsSuccess, (state, {newsletter}) => {
    if (newsletter.newsletterContributors && newsletter.newsletterContributors.length < 2) {
      return {
        ...state,
        sharedRecentlyScheduled: {
          ...state.sharedRecentlyScheduled,
          ...adapterScheduleSentDate.removeOne(newsletter.id, state.sharedRecentlyScheduled)
        },
        sharedRecentDraft: {
          ...state.sharedRecentDraft,
          ...adapterLastModifiedDate.removeOne(newsletter.id, state.sharedRecentDraft)
        },
        sharedRecentlySent: {
          ...state.sharedRecentlySent,
          ...adapterLastModifiedDate.removeOne(newsletter.id, state.sharedRecentlySent)
        }
      };
    }

    if (newsletter.newsletterContributors && newsletter.newsletterContributors.length > 1) {
      return {
        ...state,
        myRecentDraft: {
          ...state.myRecentDraft,
          ...adapterScheduleSentDate.removeOne(newsletter.id, state.myRecentDraft)
        },
        myRecentlyScheduled: {
          ...state.myRecentlyScheduled,
          ...adapterLastModifiedDate.removeOne(newsletter.id, state.myRecentlyScheduled)
        },

        myRecentlySent: {
          ...state.myRecentlySent,
          ...adapterScheduleSentDate.removeOne(newsletter.id, state.myRecentlySent)
        },
        sharedRecentlyScheduled: {
          ...state.sharedRecentlyScheduled,
          ...adapterScheduleSentDate.updateOne({id: newsletter.id, changes: newsletter}, state.sharedRecentlyScheduled)
        },
        sharedRecentDraft: {
          ...state.sharedRecentDraft,
          ...adapterLastModifiedDate.updateOne({id: newsletter.id, changes: newsletter}, state.sharedRecentDraft)
        },
        sharedRecentlySent: {
          ...state.sharedRecentlySent,
          ...adapterLastModifiedDate.updateOne({id: newsletter.id, changes: newsletter}, state.sharedRecentlySent)
        }
      };
    }

    return {
      ...state,
      myRecentlyScheduled: {
        ...state.myRecentlyScheduled,
        ...adapterScheduleSentDate.updateOne({id: newsletter.id, changes: newsletter}, state.myRecentlyScheduled)
      },
      myRecentDraft: {
        ...state.myRecentDraft,
        ...adapterLastModifiedDate.updateOne({id: newsletter.id, changes: newsletter}, state.myRecentDraft)
      },
      sharedRecentlyScheduled: {
        ...state.sharedRecentlyScheduled,
        ...adapterScheduleSentDate.updateOne({id: newsletter.id, changes: newsletter}, state.sharedRecentlyScheduled)
      },
      sharedRecentDraft: {
        ...state.sharedRecentDraft,
        ...adapterLastModifiedDate.updateOne({id: newsletter.id, changes: newsletter}, state.sharedRecentDraft)
      }
    };
  }),
  on(fromActions.startSaveAsTemplateProccess, (state) => ({
    ...state,
    isSaveProcessActive: true,
  })),
  on(fromActions.saveAsTemplateSuccess, fromActions.saveAsTemplateFailure, (state) => ({
    ...state,
    isSaveProcessActive: false,
  })),
  on(fromActions.renameNewsletterSuccess, (state, {newsletter}) => ({
    ...state,
    myRecentlyScheduled: {
      ...state.myRecentlyScheduled,
      ...adapterScheduleSentDate.updateOne({id: newsletter.newsletterId, changes: newsletter}, state.myRecentlyScheduled)
    },
    myRecentDraft: {
      ...state.myRecentDraft,
      ...adapterLastModifiedDate.updateOne({id: newsletter.newsletterId, changes: newsletter}, state.myRecentDraft)
    },
    sharedRecentlyScheduled: {
      ...state.sharedRecentlyScheduled,
      ...adapterScheduleSentDate.updateOne({id: newsletter.newsletterId, changes: newsletter}, state.sharedRecentlyScheduled)
    },
    sharedRecentDraft: {
      ...state.sharedRecentDraft,
      ...adapterLastModifiedDate.updateOne({id: newsletter.newsletterId, changes: newsletter}, state.sharedRecentDraft)
    },
    myRecentlySent: {
      ...state.myRecentlySent,
      ...adapterScheduleSentDate.updateOne({id: newsletter.newsletterId, changes: newsletter}, state.myRecentlySent)
    },
    sharedRecentlySent: {
      ...state.sharedRecentlySent,
      ...adapterLastModifiedDate.updateOne({id: newsletter.newsletterId, changes: newsletter}, state.sharedRecentlySent)
    }
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return newsletterDashboardReducer(state, action);
}

export const getDraft = (state: State) => state.draft;
export const getTemplatesList = (state: State) => state.templates;
export const getSelectedTemplate = (state: State) => state.selectedTemplate;
export const getSelectedColor = (state: State) => state.selectedColor;
export const getTemplateAndColor = (state: State) => state.templateAndColor;
export const getSelectedTab = (state: State) => state.selectedTab;
export const getMyRecentDraftObj = (state: State) => state.myRecentDraft;
export const getMyRecentlyScheduledObj = (state: State) => state.myRecentlyScheduled;
export const getMyRecentlySentObj = (state: State) => state.myRecentlySent;
export const getSharedRecentDraftObj = (state: State) => state.sharedRecentDraft;
export const getSharedRecentlyScheduledObj = (state: State) => state.sharedRecentlyScheduled;
export const getSharedRecentlySentObj = (state: State) => state.sharedRecentlySent;
export const getEntities = adapter.getSelectors().selectEntities;
export const getAll = adapter.getSelectors().selectAll;

export const getEntitiesDesc = adapterLastModifiedDate.getSelectors().selectAll;
export const getEntitiesAsc = adapterScheduleSentDate.getSelectors().selectAll;
