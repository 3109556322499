import { createSelector } from '@ngrx/store';

import * as fromCore from '@core/core-store/core.reducer';
import * as fromTimeZones from './timezones.reducer';

export const selectState = createSelector(
  fromCore.selectState,
  fromCore.getTimeZones
);

export const selectDefaultTimeZone = createSelector(
  selectState,
  fromTimeZones.getDefaultTimeZone
);

export const selectTimeZones = createSelector(
  selectState,
  fromTimeZones.getTimezones
);

export const selectAll = createSelector(
  selectTimeZones,
  fromTimeZones.getAll
);

export const selectEntities = createSelector(
  selectTimeZones,
  fromTimeZones.getEntities
);

export const selectIds = createSelector(
  selectTimeZones,
  fromTimeZones.getIds
);

export const selectTotal = createSelector(
  selectTimeZones,
  fromTimeZones.getTotal
);

export const selectEntityById = createSelector(
  selectEntities,
  fromTimeZones.getEntityById
);

export const selectEntitiesById = createSelector(
  selectEntities,
  fromTimeZones.getEntitiesById
);
