<div class="row row-centered">
    <div class="col-auto">
      <p class="ui-label-m my-4">{{controlDataSnapshot.length}} items</p>
    </div>
    <div class="col">
      <rds-chip-list (valueChange)="applyFilter($event)">
        <ng-container ngProjectAs="rds-chip-selectable" *ngFor="let filter of filters;">
        <rds-chip-selectable *ngIf="getFilterCount(filter) as count" [value]="filter">
          <rds-icon namespace="outlined" [icon]="filter.icon" [class]="filter.iconColorClass"></rds-icon>
          &nbsp;{{count}} {{count > 1 ? filter.label.plural : filter.label.singular}}
        </rds-chip-selectable>
        </ng-container>

      </rds-chip-list>
    </div>
    <div class="col-3" [formGroup]="searchForm">
        <rds-form-field class="table__filter--search" labelPosition="none" size="m">
            <rds-control-label>Search</rds-control-label>
            <rds-icon
                rds-control-prefix
                namespace="outlined"
                icon="search"
            ></rds-icon>
            <input
                rdsInput
                placeholder="Search in table..."
                formControlName="search"
            />
            <div class="table__filter--search-clear">
              <button *ngIf="searchForm.controls.search.value.length" rds-icon-button rds-control-suffix (click)="clearSearch()">
                <rds-icon
                  rds-control-suffix
                  namespace="outlined"
                  icon="close"
                ></rds-icon>
              </button>
            </div>
        </rds-form-field>
    </div>
  </div>
<rds-table [dataSource]="readOnly ? [] : [1]" [rdsSortDirection]="defaultSortDirection"
[rdsSortActive]="defaultSortActive" multiTemplateDataRows alternation="vertical"
(rdsSortChange)="onSort($event)" rdsSort #headerTableRef density="spacious" size="l" [trackBy]="trackByFn">
    <ng-container rdsColumnDef="delete-checkbox">
        <rds-header-cell *rdsHeaderCellDef>
            <div class="table__delete-row"></div>
        </rds-header-cell>
        <rds-cell *rdsCellDef></rds-cell>
    </ng-container>
    <ng-container rdsColumnDef="delete">
        <rds-header-cell *rdsHeaderCellDef>
            <div class="table__delete-row">
                <ng-content select="[bulkText]"></ng-content>
                <ng-content select="[bulkActions]"></ng-content>
                <button rds-secondary-button (click)="clearAll()">
                    Cancel
                </button>
            </div>
        </rds-header-cell>
        <rds-cell *rdsCellDef></rds-cell>
    </ng-container>

    <ng-container rdsColumnDef="select">
        <rds-header-cell *rdsHeaderCellDef>
            <rds-checkbox tabindex="0" (click)="$event.stopPropagation()" (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected"
                [indeterminate]="selection.hasValue() && isAnySelected  && !isAllSelected"
                [disabled]="disableMasterToggle">
            </rds-checkbox>
        </rds-header-cell>
        <rds-cell *rdsCellDef tabindex="-1">
        </rds-cell>
    </ng-container>

    <ng-container rdsColumnDef="actions">
        <rds-header-cell *rdsHeaderCellDef>
            <!-- <ng-container *ngIf="isAnyInEditState">
                <button rds-icon-button [rdsTooltip]="'Save all (' + editSelection.selected.length + ')'" size="m"
                    (click)="saveAll()">
                    <rds-icon namespace="outlined" icon="checkmark"></rds-icon>
                </button>
                <button rds-icon-button [rdsTooltip]="'Cancel all (' + editSelection.selected.length + ')'" size="m"
                    (click)="cancelAll()">
                    <rds-icon namespace="outlined" icon="close"></rds-icon>
                </button>
            </ng-container> -->
        </rds-header-cell>
        <rds-cell *rdsCellDef>
            <button rds-icon-button size="s" rdsTooltip="Add" (click)="addNew()">
                <rds-icon namespace="outlined" icon="plus"></rds-icon>
            </button>
        </rds-cell>
    </ng-container>

    <ng-container rdsColumnDef="index">
        <rds-header-cell *rdsHeaderCellDef rds-sort-header="index" class="sortable">Index</rds-header-cell>
    </ng-container>

    <ng-container [rdsColumnDef]="column" *ngFor="let column of [
        'email','email.light',
        'name', 'name.light',
        'surname', 'surname.light',
        'included', 'included.light',
        'blocked', 'blocked.light'
    ]">
        <rds-header-cell *rdsHeaderCellDef [rds-sort-header]="column | columnName" class="sortable">
            <ng-container [ngSwitch]="column | columnName">
                <span *ngSwitchCase="'included'">
                    Status
                </span>
                <span *ngSwitchCase="'blocked'">
                    Status
                </span>
                <span *ngSwitchDefault>
                    {{column | columnName | titlecase}}
                </span>
            </ng-container>
        </rds-header-cell>
        <rds-cell *rdsCellDef #cellRef class="sortable" [class.in-edit]="column !== 'status'">
            <ng-container *ngIf="addForm">
                <ng-container [ngSwitch]="column">
                    <ng-container *ngSwitchCase="'email'" [formGroup]="addForm">
                        <rds-form-field labelPosition="none" size="m">
                            <rds-icon *ngIf="!addForm.controls.email.invalid"rds-control-prefix namespace="outlined" icon="user_add"></rds-icon>
                            <rds-icon *ngIf="addForm.controls.email.invalid" rds-control-prefix  class="c-error" [rdsTooltip]="validationTooltip" namespace="outlined" icon="warning_triangle"></rds-icon>
                            <ng-template #validationTooltip>
                                <p *ngIf="addForm.controls.email.errors.required">Missing email</p>
                                <p *ngIf="addForm.controls.email.errors.duplicate">Duplicates not allowed</p>
                                <p *ngIf="addForm.controls.email.errors.email">Valid email required</p>
                            </ng-template>
                            <input
                                rdsInput
                                (focus)="focusAutocomplete(column)"
                                placeholder="Enter user name or email"
                                formControlName="email"/>
                        </rds-form-field>
                    </ng-container>
                    <ng-container *ngSwitchCase="'name'" [formGroup]="addForm">
                        <rds-form-field labelPosition="none" size="m">
                            <rds-icon *ngIf="addForm.controls.name.invalid" rds-control-prefix  class="c-error" [rdsTooltip]="validationTooltip" namespace="outlined" icon="warning_triangle"></rds-icon>
                            <ng-template #validationTooltip>
                                <p *ngIf="addForm.controls.name.errors.required">Missing name</p>
                            </ng-template>
                            <input
                                rdsInput
                                (focus)="focusAutocomplete(column)"
                                placeholder="Enter name"
                                formControlName="name"/>
                        </rds-form-field>
                    </ng-container>
                    <ng-container *ngSwitchCase="'surname'" [formGroup]="addForm">
                        <rds-icon *ngIf="addForm.controls.surname.invalid" rds-control-prefix  class="c-error" [rdsTooltip]="validationTooltip" namespace="outlined" icon="warning_triangle"></rds-icon>
                        <ng-template #validationTooltip>
                            <p *ngIf="addForm.controls.surname.errors.required">Missing surname</p>
                        </ng-template>
                        <rds-form-field labelPosition="none" size="m">
                            <input
                                rdsInput
                                (focus)="focusAutocomplete(column)"
                                placeholder="Enter surname"
                                formControlName="surname"/>
                        </rds-form-field>
                    </ng-container>
                    <span *ngSwitchDefault>
                        &nbsp;
                    </span>
                </ng-container>
                <ng-container *ngIf="autocomplete">
                    <ng-container *ngTemplateOutlet="column !== autocompleteColumn? null : autocompleteTpl; context: {menuWidth: autocompleteWidth, highlightPhrase: addForm.controls.email.value}">
                    </ng-container>
                </ng-container>
            </ng-container>

            </rds-cell>
    </ng-container>

    <ng-container rdsColumnDef="omit-alternation">
        <rds-header-cell *rdsHeaderCellDef tabindex="-1"></rds-header-cell>
        <rds-cell *rdsCellDef tabindex="-1"></rds-cell>
    </ng-container>

    <ng-container rdsColumnDef="scroll-fix">
        <rds-header-cell *rdsHeaderCellDef tabindex="-1" [class.no-scroll]="allRows.length * rowHeight < gridHeight ">&nbsp;</rds-header-cell>
        <rds-cell *rdsCellDef tabindex="-1" [class.no-scroll]="allRows.length * rowHeight < gridHeight ">&nbsp;</rds-cell>
    </ng-container>

    <rds-header-row class="table__header" *rdsHeaderRowDef="headerColumns"></rds-header-row>
    <rds-header-row class="table__bulk" [class.table__hide]="!selection.hasValue()"
        [class.table__show]="selection.hasValue()" *rdsHeaderRowDef="deleteColumns"></rds-header-row>
    <rds-row *rdsRowDef="let row; columns: headerColumns; when: notReadOnly;" class="autocomplete__row"></rds-row>
</rds-table>

<div style="position: relative">
    <cdk-virtual-scroll-viewport #viewport [style.height.px]="gridHeight">
        <rds-table #tableRef [dataSource]="visibleRows" recycleRows alternation="vertical"
             multiTemplateDataRows
            density="spacious" size="l" [trackBy]="trackByFn">

            <ng-container rdsColumnDef="select">
                <rds-cell *rdsCellDef="let element;">
                    <div tabindex="0" (keydown.space)="$event.preventDefault(); selection.toggle({id: element.id})" class="pseudo-checkbox-overlay">
                        <rds-pseudo-checkbox
                        (click)="selection.toggle({id: element.id})"
                        [checked]="element.selected"
                        ></rds-pseudo-checkbox>
                    </div>
                </rds-cell>
            </ng-container>

            <ng-container rdsColumnDef="actions">
                <rds-cell *rdsCellDef="let element">
                    <button rds-icon-button size="s" rdsTooltip="Remove" (click)="deleteRows([{id: element.id}])">
                        <rds-icon namespace="outlined" icon="delete"></rds-icon>
                    </button>
                </rds-cell>
            </ng-container>

            <ng-container rdsColumnDef="index">
                <rds-cell *rdsCellDef="let element" class="sortable">
                    {{element.index}}
                </rds-cell>
            </ng-container>

            <ng-container rdsColumnDef="email.light">
                <rds-cell *rdsCellDef="let element" class="sortable">
                    {{element.email}}
                </rds-cell>
            </ng-container>
            <ng-container rdsColumnDef="name.light">
                <rds-cell *rdsCellDef="let element" class="sortable">
                    {{element.name}}
                </rds-cell>
            </ng-container>
            <ng-container rdsColumnDef="surname.light">
                <rds-cell *rdsCellDef="let element" class="sortable">
                    {{element.surname}}
                </rds-cell>
            </ng-container>
            <ng-container rdsColumnDef="included.light">
                <rds-cell *rdsCellDef="let element" class="sortable">
                    <rds-badge *ngIf="element.form.valid" size="m" label="Accepted" type="minimal" color="green"></rds-badge>
                    <rds-badge *ngIf="!element.form.valid" size="m" label="Excluded" type="minimal" color="red"></rds-badge>
                </rds-cell>
            </ng-container>
            <ng-container rdsColumnDef="blocked.light">
                <rds-cell *rdsCellDef="let element" class="sortable">
                    <rds-badge *ngIf="element.form.valid" size="m" label="Blocked" type="minimal" color="green"></rds-badge>
                    <rds-badge *ngIf="!element.form.valid" size="m" label="Excluded" type="minimal" color="red"></rds-badge>
                </rds-cell>
            </ng-container>

            <ng-container rdsColumnDef="omit-alternation">
                <rds-cell *rdsCellDef tabindex="-1"></rds-cell>
            </ng-container>

            <ng-container rdsColumnDef="email">
                <rds-header-cell *rdsHeaderCellDef rds-sort-header="email" class="sortable">Email</rds-header-cell>
                <rds-cell *rdsCellDef="let element; let index" (click)="element.editing = 'email'" [class.editable]="element.editing !== 'email'" [tabindex]="element.editing === 'email' ? -1 : 0" (focus)="element.editing = 'email'" class="sortable editable" [class.in-edit]="element.editing">
                    <ng-container *ngIf="element.editing !== 'email'">
                        <span  *ngIf="element.form.controls.email.invalid" class="error-indicator c-error" [rdsTooltip]="validationTooltip">
                            <rds-icon icon="warning_triangle" namespace="outlined"></rds-icon>
                        </span>
                        <ng-template #validationTooltip>
                            <p *ngIf="element.form.controls.email.errors.required">Missing email</p>
                            <p *ngIf="element.form.controls.email.errors.duplicate">Duplicates not allowed</p>
                            <p *ngIf="element.form.controls.email.errors.email">Valid email required</p>
                        </ng-template>
                        <div class="table__cell" >
                            <span [rdsTooltip]="element.email" [innerHTML]="element.email | highlight:searchForm.controls.search.value" [rdsTooltipShowDelay]="500">
                            </span>
                            &nbsp;
                            <!-- <span class="edit-indicator">
                                <rds-icon icon="edit" namespace="outlined"></rds-icon>
                            </span> -->
                            <!--<rh-subscript-wrapper *ngIf="element.form.controls.email.invalid" [hasError]="true" class="error-indicator">
                                <rds-control-error *ngIf="element.form.controls.email.errors.required">Missing email</rds-control-error>
                                <rds-control-error *ngIf="element.form.controls.email.errors.duplicate">Duplicates not allowed</rds-control-error>
                                <rds-control-error *ngIf="element.form.controls.email.errors.email">Valid email required</rds-control-error>
                            </rh-subscript-wrapper> -->
                        </div>
                        <span class="edit-indicator">
                            <rds-icon icon="edit" namespace="outlined"></rds-icon>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="element.editing === 'email' && element.form" [formGroup]="element.form">
                        <rds-form-field labelPosition="none" size="m">
                            <rds-icon *ngIf="element.form.controls.email.invalid" rds-control-prefix  class="c-error" [rdsTooltip]="validationTooltip" namespace="outlined" icon="warning_triangle"></rds-icon>
                            <ng-template #validationTooltip>
                                <p *ngIf="element.form.controls.email.errors.required">Missing email</p>
                                <p *ngIf="element.form.controls.email.errors.duplicate">Duplicates not allowed</p>
                                <p *ngIf="element.form.controls.email.errors.email">Valid email required</p>
                            </ng-template>
                            <input
                                rdsInput
                                atrAutoFocus
                                formControlName="email"
                                (keyup.enter)="element.email = element.form.controls.email.value; element.editing = null; saveRow(element)"
                                (blur)="element.email = element.form.controls.email.value; element.editing = null; saveRow(element)"/>
                        </rds-form-field>
                    </ng-container>
                </rds-cell>
            </ng-container>


            <ng-container rdsColumnDef="name">
                <rds-header-cell *rdsHeaderCellDef rds-sort-header="name" class="sortable">First name</rds-header-cell>
                <rds-cell *rdsCellDef="let element" (click)="element.editing = 'name'" [class.editable]="element.editing !== 'name'" [tabindex]="element.editing === 'name' ? -1 : 0" (focus)="element.editing = 'name'" class="sortable editable" [class.in-edit]="element.editing">
                    <ng-container *ngIf="element.editing !== 'name'">
                        <span  *ngIf="element.form.controls.name.invalid" class="error-indicator c-error" [rdsTooltip]="validationTooltip">
                            <rds-icon icon="warning_triangle" namespace="outlined"></rds-icon>
                        </span>
                        <ng-template #validationTooltip>
                            <p *ngIf="element.form.controls.name.errors.required">Missing name</p>
                        </ng-template>
                        <div class="table__cell">
                            <span [rdsTooltip]="element.name" [innerHTML]="element.name | highlight:searchForm.controls.search.value" [rdsTooltipShowDelay]="500">
                            </span>
                            &nbsp;
                            <!-- <span class="edit-indicator">
                                <rds-icon icon="edit" namespace="outlined"></rds-icon>
                            </span> -->
                            <!-- <rh-subscript-wrapper *ngIf="element.form.controls.name.invalid" [hasError]="true" class="error-indicator">
                                <rds-control-error *ngIf="element.form.controls.name.errors.required">Missing name</rds-control-error>
                                <rds-control-error *ngIf="element.form.controls.name.errors.duplicate">Duplicates not allowed</rds-control-error>
                            </rh-subscript-wrapper> -->
                        </div>
                        <span class="edit-indicator">
                            <rds-icon icon="edit" namespace="outlined"></rds-icon>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="element.editing === 'name' && element.form" [formGroup]="element.form">
                        <rds-form-field labelPosition="none" size="m">
                            <rds-icon *ngIf="element.form.controls.name.invalid" rds-control-prefix  class="c-error" [rdsTooltip]="validationTooltip" namespace="outlined" icon="warning_triangle"></rds-icon>
                            <ng-template #validationTooltip>
                                <p *ngIf="element.form.controls.name.errors.required">Missing name</p>
                            </ng-template>
                            <input
                                rdsInput
                                atrAutoFocus
                                formControlName="name"
                                (keyup.enter)="element.name = element.form.controls.name.value; element.editing = null; saveRow(element)"
                                (blur)="element.name = element.form.controls.name.value; element.editing = null; saveRow(element)"/>
                        </rds-form-field>
                    </ng-container>
                </rds-cell>
            </ng-container>

            <ng-container rdsColumnDef="surname">
                <rds-header-cell *rdsHeaderCellDef rds-sort-header="surname" class="sortable">Surname</rds-header-cell>
                <rds-cell *rdsCellDef="let element" (click)="element.editing = 'surname'" [class.editable]="element.editing !== 'surname'" [tabindex]="element.editing === 'surname' ? -1 : 0" (focus)="element.editing = 'surname'" class="sortable" [class.in-edit]="element.editing">
                    <ng-container *ngIf="element.editing !== 'surname'">
                        <span  *ngIf="element.form.controls.surname.invalid" class="error-indicator c-error" [rdsTooltip]="validationTooltip">
                            <rds-icon icon="warning_triangle" namespace="outlined"></rds-icon>
                        </span>
                        <ng-template #validationTooltip>
                            <p *ngIf="element.form.controls.surname.errors.required">Missing surname</p>
                        </ng-template>
                        <div class="table__cell">
                            <span [rdsTooltip]="element.surname" [innerHTML]="element.surname | highlight:searchForm.controls.search.value" [rdsTooltipShowDelay]="500">
                            </span>
                            &nbsp;
                            <!-- <span class="edit-indicator">
                                <rds-icon icon="edit" namespace="outlined"></rds-icon>
                            </span> -->
                            <!-- <rh-subscript-wrapper *ngIf="element.form.controls.surname.invalid" [hasError]="true" class="error-indicator">
                                <rds-control-error *ngIf="element.form.controls.surname.errors.required">Missing surname</rds-control-error>
                                <rds-control-error *ngIf="element.form.controls.surname.errors.duplicate">Duplicates not allowed</rds-control-error>
                            </rh-subscript-wrapper> -->
                        </div>
                        <span class="edit-indicator">
                            <rds-icon icon="edit" namespace="outlined"></rds-icon>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="element.editing === 'surname'" [formGroup]="element.form">
                        <rds-icon *ngIf="element.form.controls.surname.invalid" rds-control-prefix  class="c-error" [rdsTooltip]="validationTooltip" namespace="outlined" icon="warning_triangle"></rds-icon>
                        <ng-template #validationTooltip>
                            <p *ngIf="element.form.controls.surname.errors.required">Missing surname</p>
                        </ng-template>
                        <rds-form-field labelPosition="none" size="m">
                            <input
                                rdsInput
                                atrAutoFocus
                                formControlName="surname"
                                (keyup.enter)="element.surname = element.form.controls.surname.value; element.editing = null; saveRow(element)"
                                (blur)="element.surname = element.form.controls.surname.value; element.editing = null; saveRow(element)"/>
                        </rds-form-field>
                    </ng-container>
                </rds-cell>
            </ng-container>

            <ng-container rdsColumnDef="included" class="sortable">
                <rds-cell *rdsCellDef="let element" tabindex="-1">
                    <rds-badge *ngIf="element.form.valid" size="m" label="Accepted" type="minimal" color="green"></rds-badge>
                    <rds-badge *ngIf="!element.form.valid" size="m" label="Excluded" type="minimal" color="red"></rds-badge>
                </rds-cell>
            </ng-container>
            <ng-container rdsColumnDef="blocked" class="sortable">
                <rds-cell *rdsCellDef="let element" tabindex="-1">
                    <rds-badge *ngIf="element.form.valid" size="m" label="Blocked" type="minimal" color="green"></rds-badge>
                    <rds-badge *ngIf="!element.form.valid" size="m" label="Excluded" type="minimal" color="red"></rds-badge>
                </rds-cell>
            </ng-container>

            <ng-template let-row rdsRowDef [rdsRowDefColumns]="allColumns" cdkVirtualFor [cdkVirtualForTemplateCacheSize]="0" [cdkVirtualForOf]="allRows" [cdkVirtualForTrackBy]="trackByFn">
                <rds-row [id]="row.id" [selected]="row.selected" [class.highlighted]="highlighted === row.id" (animationend)="highlightEnds($event)" (animationcancel)="highlightEnds($event)"></rds-row>
            </ng-template>
            <div *rdsNoDataRow class="table__empty">
                <rds-empty-state alignment="center" size="m">
                    <img rds-empty-state-image src="assets/images/empty-state.png" alt="No data">
                    <rds-empty-state-title>
                        <ng-content select="[emptyTitle]"></ng-content>
                    </rds-empty-state-title>
                    <rds-empty-state-subtitle>
                        <ng-content select="[emptySubtitle]"></ng-content>
                    </rds-empty-state-subtitle>
                    <ng-content select="[rds-empty-state-button]"></ng-content>
                </rds-empty-state>
                <rds-empty-state alignment="center" *ngIf="isLoading === false && filtersApplied" size="m">
                    <img rds-empty-state-image src="/assets/images/microscope.png" alt="">
                    <rds-empty-state-title>No search results.</rds-empty-state-title>
                    <rds-empty-state-subtitle>Please check your spelling or adjust filter settings.</rds-empty-state-subtitle>
                </rds-empty-state>
            </div>
        </rds-table>
    </cdk-virtual-scroll-viewport>
    <!-- <div style="position: absolute; top:0; left:0; width:100%; height:100%; pointer-events:none"></div> -->
</div>

<ng-template #autocompleteTpl let-menuWidth="menuWidth" let-highlightPhrase="highlightPhrase">
    <div rdsDropdown>
        <button rds-dropdown-toggle-button size="l" class="autocomplete__trigger"
          [rdsMenuTriggerFor]="menu" #trigger="rdsMenuTrigger" [isOpen]="trigger.menuOpen">Toggle
        </button>
        <rds-menu #menu="rdsMenu" class="autocomplete__options">
            <ng-container *ngIf="!autocomplete.loading">
                <div *ngIf="autocomplete.suggestions.length === 0" [ngStyle]="{'width.px': menuWidth}"
                class="autocomplete__options--not-found">
                <span>
                  User not found
                </span>
              </div>
              <div *ngFor="let option of autocomplete.suggestions"
                (click)="select(option)"
                [class.is-selected]="option.selected"
                [ngStyle]="{'width.px': menuWidth}" class="autocomplete__options--result">
                <div class="result__cell result__avatar">
                    <rh-user-single [user]="option"  [fetchPhoto]="false"></rh-user-single>
                </div>
                <div class="result__cell result__email text-ellipsis">
                    <span [innerHTML]="option.email | highlight:highlightPhrase"></span>
                </div>
                <div class="result__cell result__name text-ellipsis">
                    <span [innerHTML]="option.name | highlight:highlightPhrase"></span>
                </div>
                <div class="result__cell result__surname text-ellipsis">
                    <span [innerHTML]="option.surname | highlight:highlightPhrase"></span>
                </div>
                <div class="result__cell result__status">

                </div>
                <div class="result__cell result__actions">
                    <rds-icon *ngIf="option.selected" namespace="outlined" icon="checkmark"></rds-icon>
                    <rds-icon *ngIf="!option.selected"namespace="outlined" icon="plus"></rds-icon>
                </div>

              </div>
            </ng-container>
        </rds-menu>
      </div>
</ng-template>
