import {Action, createReducer, on} from '@ngrx/store';
import * as fromActions from '@app/home/store/newsfeed/newsfeed.actions';
import * as fromSubscriptions from '@app/home/store/subscriptions/subscriptions.actions';

export interface State {
  channelIds: string[],
  newsFeeds: {
    [key: string]: {
      data: any[],
      isLoading: boolean
    }
  };
  isLikeProcessing: boolean;
  isLikeProcessingIds: string[];
  downloadedChannelsIds: string[]
}

export const initialState: State = {
  channelIds: [],
  newsFeeds: {},
  isLikeProcessing: false,
  isLikeProcessingIds: [],
  downloadedChannelsIds: [],
};

export const newsfeedReducer = createReducer(
  initialState,
  on(fromActions.setChannelIds, (state, {channelIds}) => ({
    ...state,
    channelIds
  })),
  on(fromActions.getChannelNews, (state, {id}) => ({
    ...state,
    newsFeeds: {
      ...state.newsFeeds,
      [id]: {
        ...state.newsFeeds[id],
        isLoading: true,
        data: []
      }
    }
  })),
  on(fromActions.getChannelNewsSuccess, (state, {id, data}) => ({
    ...state,
    newsFeeds: {
      ...state.newsFeeds,
      [id]: {
        ...state.newsFeeds[id],
        data,
        isLoading: false
      }
    },
    downloadedChannelsIds: [...state.downloadedChannelsIds, id],
  })),
  on(fromSubscriptions.addBookmark, (state, {id}) => {
    const channelId = id.split(':')[0] + ':' + id.split(':')[1];
    if (!state.newsFeeds[channelId]) {
      return state;
    }
    return {
      ...state,
      newsFeeds: {
        ...state.newsFeeds,
        [channelId]: {
          ...state.newsFeeds[channelId],
          data: state.newsFeeds[channelId].data.map((i) => {
            if (i.id === id) {
              return {
                ...i,
                hasBookmarked: true
              }
            }
            return i;
          })
        }
      }
    }
  }),
  on(fromActions.addBookmark, (state, {id, channelId}) => ({
    ...state,
    newsFeeds: {
      ...state.newsFeeds,
      [channelId]: {
        ...state.newsFeeds[channelId],
        data: state.newsFeeds[channelId].data.map((i) => {
          if (i.id === id) {
            return {
              ...i,
              hasBookmarked: true
            }
          }
          return i;
        })
      }
    }
  })),
  on(fromSubscriptions.removeBookmark, (state, {id}) => {
    const channelId = id.split(':')[0] + ':' + id.split(':')[1];
    if (!state.newsFeeds[channelId]) {
      return state;
    }
    return {
      ...state,
      newsFeeds: {
        ...state.newsFeeds,
        [channelId]: {
          ...state.newsFeeds[channelId],
          data: state.newsFeeds[channelId].data.map((i) => {
            if (i.id === id) {
              return {
                ...i,
                hasBookmarked: false
              }
            }
            return i;
          })
        }
      }
    }
  }),
  on(fromActions.removeBookmark, (state, {id, channelId}) => ({
    ...state,
    newsFeeds: {
      ...state.newsFeeds,
      [channelId]: {
        ...state.newsFeeds[channelId],
        data: state.newsFeeds[channelId].data.map((i) => {
          if (i.id === id) {
            return {
              ...i,
              hasBookmarked: false
            }
          }
          return i;
        })
      }
    }
  })),
  on(fromActions.addLike, (state, {id, likesCount}) => {
    const channelId = id.split(':')[0] + ':' + id.split(':')[1];
    if (!state.newsFeeds[channelId]) {
      return state;
    }
    return {
      ...state,
      isLikeProcessing: true,
      isLikeProcessingIds: [...state.isLikeProcessingIds, id],
      newsFeeds: {
        ...state.newsFeeds,
        [channelId]: {
          ...state.newsFeeds[channelId],
          data: state.newsFeeds[channelId].data.map((i) => {
            if (i.id === id) {
              return {
                ...i,
                numLikes: likesCount + 1,
                        hasLiked: true
              }
            }
            return i;
          })
        }
      }
    }
  }),
  on(fromActions.removeLike, (state, {id, likesCount}) => {
    const channelId = id.split(':')[0] + ':' + id.split(':')[1];
    if (!state.newsFeeds[channelId]) {
      return state;
    }
    return ({
      ...state,
      isLikeProcessing: true,
      isLikeProcessingIds: [...state.isLikeProcessingIds, id],
      newsFeeds: {
        ...state.newsFeeds,
        [channelId]: {
          ...state.newsFeeds[channelId],
          data: state.newsFeeds[channelId].data.map((i) => {
            if (i.id === id) {
              return {
                ...i,
                numLikes: likesCount - 1,
                hasLiked: false
              }
            }
            return i;
          })
        }
      }
    })
  }),
  on(fromActions.addLikeSuccess, fromActions.removeLikeSuccess, (state, {id}) => ({
    ...state,
    isLikeProcessingIds: [...state.isLikeProcessingIds].filter(i => i !== id)
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return newsfeedReducer(state, action);
}

export const getChannelIds = (state: State) => state.channelIds;
export const getNewsFeeds = (state: State) => state.newsFeeds;
export const getIsLikeProcessing = (state: State) => state.isLikeProcessing;
export const getIsLikeProcessingIds = (state: State) => state.isLikeProcessingIds;
export const getDownloadedChannelIds = (state: State) => state.downloadedChannelsIds;
