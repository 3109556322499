import {createSelector} from '@ngrx/store';

import * as fromReducer from '@app/newsletter/store/newsletter.reducer';
import * as fromNewsletterSavedTemplates from '@app/newsletter/store/newsletter-saved-templates/newsletter-saved-templates.reducer';

export const selectNewsletterSavedTemplates = createSelector(
  fromReducer.selectState,
  fromReducer.getNewsletterSavedTemplates
);

export const selectAllTemplates = createSelector(
  selectNewsletterSavedTemplates,
  fromNewsletterSavedTemplates.getAll
);

export const selectTemplates = createSelector(
  selectNewsletterSavedTemplates,
  fromNewsletterSavedTemplates.getTemplates
);

export const selectPageIndex = createSelector(
  selectNewsletterSavedTemplates,
  fromNewsletterSavedTemplates.getPageIndex
);

export const selectPageSize = createSelector(
  selectNewsletterSavedTemplates,
  fromNewsletterSavedTemplates.getPageSize
);

export const selectPageIndexSize = createSelector(
  selectNewsletterSavedTemplates,
  fromNewsletterSavedTemplates.getPageIndexSize
);

export const selectPageCount = createSelector(
  selectNewsletterSavedTemplates,
  fromNewsletterSavedTemplates.getPageCount
);

export const selectIsFirst = createSelector(
  selectNewsletterSavedTemplates,
  fromNewsletterSavedTemplates.getIsFirst
);

export const selectIsLast = createSelector(
  selectNewsletterSavedTemplates,
  fromNewsletterSavedTemplates.getIsLast
);

export const selectAllSharedTemplates = createSelector(
  selectNewsletterSavedTemplates,
  fromNewsletterSavedTemplates.getAllShared
);

export const selectSharedTemplates = createSelector(
  selectNewsletterSavedTemplates,
  fromNewsletterSavedTemplates.getSharedTemplates
);

export const selectSharedPageIndex = createSelector(
  selectNewsletterSavedTemplates,
  fromNewsletterSavedTemplates.getSharedPageIndex
);

export const selectSharedPageSize = createSelector(
  selectNewsletterSavedTemplates,
  fromNewsletterSavedTemplates.getSharedPageSize
);

export const selectSharedPageIndexSize = createSelector(
  selectNewsletterSavedTemplates,
  fromNewsletterSavedTemplates.getSharedPageIndexSize
);

export const selectSharedPageCount = createSelector(
  selectNewsletterSavedTemplates,
  fromNewsletterSavedTemplates.getSharedPageCount
);

export const selectSharedIsFirst = createSelector(
  selectNewsletterSavedTemplates,
  fromNewsletterSavedTemplates.getSharedIsFirst
);

export const selectSharedIsLast = createSelector(
  selectNewsletterSavedTemplates,
  fromNewsletterSavedTemplates.getSharedIsLast
);
