import { TagTypes } from '@app/shared/utils/get-ses-tags';
import { FilterDefinition, FilterTypeEnum, SEARCH_FILTER, SelectFilterDefinition } from '../filters-model';

export interface NewsletterLinkStatisticsTableFilters {
	types?: SelectFilterDefinition;
	search?: FilterDefinition;
}

export enum PeriodEnum {
	YEAR = 0,
	MONTH = 1,
	DAY = 2,
	HOUR = 3,
}

export const NEWSLETTER_LINK_TYPES = [
	{ label: 'Button link', value: TagTypes.BUTTON },
	{ label: 'Channel', value: TagTypes.CHANNEL },
	{ label: 'Event', value: TagTypes.EVENT },
	{ label: 'Image', value: TagTypes.IMAGE },
	{ label: 'Text', value: TagTypes.LINK },
	{ label: 'News', value: TagTypes.NEWS },
];

export const INITIAL_NEWSLETTER_LINK_STATISTICS_FILTERS = {
	type: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: [],
		label: 'Type',
		multiple: true,
		options: NEWSLETTER_LINK_TYPES,
	},
	...SEARCH_FILTER,
};
