import { createSelector } from '@ngrx/store';

import * as fromEvent from '../managing-events.reducer';
import * as fromReducer from './table.reducer';

export const selectState = createSelector(
  fromEvent.selectState,
  fromEvent.getTable
);

// [MY EVENTS]
export const selectMyEventsState = createSelector(
  selectState,
  fromReducer.getMyEvents
);

export const selectMyEventsRequestData = createSelector(
  selectMyEventsState,
  fromReducer.getRequestData
);

export const selectMyEventsData = createSelector(
  selectMyEventsState,
  fromReducer.getTableData
);

// [MY EVENTS] Pagination
export const selectMyEventsPagination = createSelector(
  selectMyEventsState,
  fromReducer.getPagination
);

export const selectMyEventsTotal = createSelector(
  selectMyEventsPagination,
  fromReducer.getTotalCount
);

// [MY EVENTS] Sort
export const selectMyEventsSort = createSelector(
  selectMyEventsState,
  fromReducer.getSort
);

export const selectMyEventsDefaultSort = createSelector(
  selectMyEventsState,
  fromReducer.getDefaultSort
);

// [MY EVENTS] Filters
export const selectMyEventsFilters = createSelector(
  selectMyEventsState,
  fromReducer.getFilters
);

export const selectMyEventsDefaultFilters = createSelector(
  selectMyEventsState,
  fromReducer.getDefaultFilters
);

export const selectMyEventsSearch = createSelector(
  selectMyEventsFilters,
  fromReducer.getSearch
);

export const selectMyEventsIsLoading = createSelector(
  selectMyEventsState,
  fromReducer.getIsLoading
);


// [MY PAST EVENTS]
export const selectMyPastEventsState = createSelector(
  selectState,
  fromReducer.getMyPastEvents
);

export const selectMyPastEventsRequestData = createSelector(
  selectMyPastEventsState,
  fromReducer.getRequestData
);

export const selectMyPastEventsData = createSelector(
  selectMyPastEventsState,
  fromReducer.getTableData
);

// [MY PAST EVENTS] Pagination
export const selectMyPastEventsPagination = createSelector(
  selectMyPastEventsState,
  fromReducer.getPagination
);

export const selectMyPastEventsTotal = createSelector(
  selectMyPastEventsPagination,
  fromReducer.getTotalCount
);

// [MY PAST EVENTS] Sort
export const selectMyPastEventsSort = createSelector(
  selectMyPastEventsState,
  fromReducer.getSort
);

export const selectMyPastEventsDefaultSort = createSelector(
  selectMyPastEventsState,
  fromReducer.getDefaultSort
);

// [MY PAST EVENTS] Filters
export const selectMyPastEventsFilters = createSelector(
  selectMyPastEventsState,
  fromReducer.getFilters
);

export const selectMyPastEventsDefaultFilters = createSelector(
  selectMyPastEventsState,
  fromReducer.getDefaultFilters
);

export const selectMyPastEventsSearch = createSelector(
  selectMyPastEventsFilters,
  fromReducer.getSearch
);

export const selectMyPastEventsIsLoading = createSelector(
  selectMyPastEventsState,
  fromReducer.getIsLoading
);