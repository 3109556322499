import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'rh-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {

  @HostBinding('class') class = 'footer';

  public year: number = new Date().getFullYear();
  public version: any = environment.version;
  constructor() {}
}
