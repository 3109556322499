import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import Cropper from 'cropperjs';

@Component({
  selector: 'rh-cropp',
  templateUrl: './cropp.component.html',
  styleUrls: ['./cropp.component.scss']
})
export class CroppComponent implements AfterViewInit {
  @ViewChild('imageRef', {static: false}) imageRef: ElementRef<HTMLImageElement>;
  image: any;
  cropper: Cropper;
  private fileName = '';
  private fileType = 'image/jpeg';
  public dimensions: {height: number, width: number, size: number} = {height: null, width: null, size: null};
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  
  keepAspectRatio = false;

  get currentImageRatio() {
    if (!this.imageRef) {
      return 0;
    }

    return +(this.imageRef.nativeElement.naturalWidth / this.imageRef.nativeElement.naturalHeight).toFixed(2);
  }

  @Input() file: File;
  @Input() imageUrl: string;
  @Input() imageName: string;
  @Input() aspectRatio: number;
  
  @Output() cropped = new EventEmitter<File>();
  @Output() ready = new EventEmitter<boolean>();
  @Output() notFound = new EventEmitter<any>();
  @Output() ratioChanged = new EventEmitter<number>();

  constructor() {}

  ngAfterViewInit(): void {
    if (!!this.aspectRatio) {
      this.keepAspectRatio = true;
      this.aspectRatio = this.aspectRatio;
    }
    if (!!this.file) {
      const reader = new FileReader();
      const that = this;
      reader.onload = function(e) {
        that.image = e.target.result;
      }
      this.fileName = this.file.name;
      this.fileType = this.file.type;
      reader.readAsDataURL(this.file);
    }
  }

  imgLoaded() {
    this.cropper = new Cropper(this.imageRef.nativeElement, {
      dragMode: 'move',
      toggleDragModeOnDblclick: false,
      viewMode: 1,
      aspectRatio: this.aspectRatio,
      ready: (event) => {
        this.ready.emit(true);
        this.cropper.zoomTo(0.01);
        const containerData = this.cropper.getContainerData();
        const canvasData = this.cropper.getCanvasData();
        this.cropper.moveTo((containerData.width - canvasData.width) / 2, (containerData.height - canvasData.height) / 2);
        this.ratioChanged.emit(this.currentImageRatio);
      },
    });
  }

  replaceImage({url, name, size}) {
    this.ready.emit(false);
    if (!!this.cropper) {
      this.cropper.destroy();
    }
    this.image = null;
    this.image = url;
    this.fileName = name;
  }

  imgError() {
    this.image = null;
    this.notFound.emit();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.fileName = event.target.files[0].name;
  }

  cropp() {
    this.cropper.getCroppedCanvas(
      { imageSmoothingEnabled: false, imageSmoothingQuality: "high" }
    ).toBlob(async (blob) => {
      const croppedImage = new File([blob], this.fileName || this.imageName, { type: blob.type });
      this.cropped.emit(croppedImage);
    }, this.fileType);
  }
}