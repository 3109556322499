import { UserSearch } from './user-search.model';

export interface Taxonomy {
  id: string;
  externalId?: string;
  name: string;
  description?: string;
  isActive?: boolean;
  children?: Taxonomy[];
  parentId?: string;
  parentName?: string;
  // approval?: {
  //   isApproved: boolean;
  //   comment: string;
  // };
  isApproved?: boolean;
  comment?: string;
  isApprovalRelevant?: boolean;
}

export interface TaxonomyFlat {
  externalId: string;
  id: string;
  parentId: string;
  name: string;
  parentName: string;
  description: string;
  isActive: true;
  childrenIds: string[];
}
export interface UserDefaultTaxonomies {
  location: Taxonomy;
  department: Taxonomy;
  function: Taxonomy;
}

export interface ManagedTaxonomy {
  login: string;
  locations: Taxonomy[];
  departments: Taxonomy[];
  functions: Taxonomy[];
}


export interface TaxonomyApproval {
  id: string,
  isApprovalRelevant: boolean;
  isApproved: boolean;
  comment?: string;
}

export interface AudienceApproval {
  locations: Array<TaxonomyApproval>;
  departments: Array<TaxonomyApproval>;
  functions: Array<TaxonomyApproval>;
}

export interface SingleAudience {
  location: string;
  department: string;
  function: string;
}

export const SingleAudience: SingleAudience = {
  location: null,
  department: null,
  function: null,
}

export interface Audience {
  locations: Array<string>;
  departments: Array<string>;
  functions: Array<string>;
}

export const Audience: Audience = {
  locations: [],
  departments: [],
  functions: [],
}

export interface TaxonomyManagers {
  taxonomyId: string;
  managers: Array<UserSearch>;
}

export const taxonomyOptionsModel = {
  idKey: 'id',
  parentIdKey: 'parentId',
  nestKey: 'childrenIds',
  nameKey: 'name',
  parentNameKey: 'parentName',
};
