import { AdvancedFilterDefinition, DateFilterDefinition, DictionaryFilterDefinition, DictionaryTypeEnum, FilterDefinition, FilterTypeEnum, SEARCH_FILTER, SuggestionFilterDefinition, SuggestionTypeEnum } from '../filters-model';

export interface ManageSetsTableFilters {
    locations?: DictionaryFilterDefinition;
    departments?: DictionaryFilterDefinition;
    functions?: DictionaryFilterDefinition;
    advancedFilters: AdvancedFilterDefinition<ManageSetsTableAdvancedFilters>;
    search?: FilterDefinition;
  }

  export interface ManageSetsTableAdvancedFilters {
    modifiedBy: SuggestionFilterDefinition;
    modifiedDate: DateFilterDefinition;
    catalogs: SuggestionFilterDefinition;
    channels: SuggestionFilterDefinition;
  }

  export function ADVANCED_MANAGE_SETS_FILTERS_FACTORY(): AdvancedFilterDefinition<ManageSetsTableAdvancedFilters> {
    return{
      type: FilterTypeEnum.ADVANCED,
      changable: true,
      activeCount: 0,
      filters: {
        modifiedBy: {
          type: FilterTypeEnum.SUGGESTION,
          suggestion: SuggestionTypeEnum.USER,
          label: 'Last Modified by',
          multiple: true,
          changable: true,
          value: []
        },
        modifiedDate: {
          type: FilterTypeEnum.DATE,
          label: 'Modified',
          range: false,
          changable: true,
          value: null
        },
        catalogs: {
          type: FilterTypeEnum.SUGGESTION,
          suggestion: SuggestionTypeEnum.CATALOG,
          label: 'Catalogs',
          multiple: true,
          changable: true,
          value: []
        },
        channels: {
          type: FilterTypeEnum.SUGGESTION,
          suggestion: SuggestionTypeEnum.CHANNEL,
          label: 'Channels',
          multiple: true,
          changable: true,
          value: []
        },
      }
  }
  }
  export const ADVANCED_QUICKLINK_SETS_FILTERS = ADVANCED_MANAGE_SETS_FILTERS_FACTORY();
  export const ADVANCED_CHANNEL_SETS_FILTERS = ADVANCED_MANAGE_SETS_FILTERS_FACTORY();

  export const INITIAL_MANAGE_SETS_FILTERS = {
    locations: {
      type: FilterTypeEnum.DICTIONARY,
      dictionary: DictionaryTypeEnum.LOCATIONS,
      label: 'Location',
      changable: true,
      value: []
    },
    departments: {
      type: FilterTypeEnum.DICTIONARY,
      dictionary: DictionaryTypeEnum.DEPARTMENTS,
      label: 'Department',
      changable: true,
      value: []
    },
    functions: {
      type: FilterTypeEnum.DICTIONARY,
      dictionary: DictionaryTypeEnum.FUNCTIONS,
      label: 'Function',
      changable: true,
      value: []
    }
  }

  export const INITIAL_QL_SETS_FILTERS: ManageSetsTableFilters = {
    ...INITIAL_MANAGE_SETS_FILTERS,
    advancedFilters: {
      ...ADVANCED_QUICKLINK_SETS_FILTERS,
      filters: {
        ...ADVANCED_QUICKLINK_SETS_FILTERS.filters,
        channels: {
          ...ADVANCED_QUICKLINK_SETS_FILTERS.filters.channels,
          changable: false
        }
      }
    },
    ...SEARCH_FILTER
  }

  export const INITIAL_CHANNEL_SETS_FILTERS: ManageSetsTableFilters = {
    ...INITIAL_MANAGE_SETS_FILTERS,
    advancedFilters: {
      ...ADVANCED_CHANNEL_SETS_FILTERS,
      filters: {
        ...ADVANCED_CHANNEL_SETS_FILTERS.filters,
        catalogs: {
          ...ADVANCED_CHANNEL_SETS_FILTERS.filters.catalogs,
          changable: false
        },
      }
    },
    ...SEARCH_FILTER
  }