import { createSelector } from '@ngrx/store';

import * as fromReducer from './form.reducer';
import * as fromRoleAssign from '../role-assign.reducer';
import { UserRoleRequest } from '@app/core/models';
import { RoleIds } from '@app/core/enums/roles.enum';

export const selectState = createSelector(
  fromRoleAssign.selectState,
  fromRoleAssign.getForm
);

export const selectForm = createSelector(
  selectState,
  fromReducer.getForm
);

export const selectUserWithoutRole = createSelector(
  selectState,
  fromReducer.getUserWithoutRole
);


export const selectFormForRequest = createSelector(
  selectForm,
  (form): UserRoleRequest => {
    return {
      login: form?.login,
      roles: [].concat(
        (form?.roles.isAdministrator ? [RoleIds.ADMINISTRATOR] : []),
        (form?.roles.isContentQualityManager ? [RoleIds.CONTENT_QUALITY_MANAGER] : []),
        (form?.roles.isAudienceManager ? [RoleIds.AUDIENCE_MANAGER] : [])
      ),
      taxonomies: {
        locations: form?.roles.isAudienceManager ? form?.audience.locations : [],
        departments: form?.roles.isAudienceManager ? form?.audience.departments : [],
        functions: form?.roles.isAudienceManager ? form?.audience.functions : [],
      }
    }
  }
);

export const selectInitialForm = createSelector(
  selectState,
  fromReducer.getInitialForm
);

export const selectId = createSelector(
  selectForm,
  fromReducer.getId
);

export const selectIsTouched = createSelector(
  selectState,
  fromReducer.getTouched
);

