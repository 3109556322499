import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {
    RECIPIENTS_LIST_TYPE,
    RecipientsListSteps,
    RhRecipientsListForm,
    RhRecipientsListRequestBody,
} from '@app/newsletter-new/models/recipients-list';
import {FormStepStatus} from '@shared/form-status-badge/form-status-badge.component';
import {FormStatus, ImportStepStatus} from '@app/newsletter-new/store/recipients-form/recipients-form.reducer';
import {ListType} from '@app/newsletter-new/store/recipients-table';

export const form = createActionGroup({
    source: 'RECIPIENTS FORM',
    events: {
        init: emptyProps(),
        setChecked: emptyProps(),
        setValue: props<{
            form: Partial<RhRecipientsListForm>;
        }>(),
        updateFormRecipientList: props<{
            recipientUsers: Array<any>;
        }>(),
        updateFormBlockList: props<{
            blockUsers: Array<any>;
        }>(),
        updateGeneral: emptyProps(),
        setInitialValue: props<{
            form: Partial<RhRecipientsListForm>;
        }>(),
        setStepStatus: props<{
            status: FormStepStatus;
            step: RecipientsListSteps;
        }>(),
        setStepIsValid: props<{
            status: FormStatus;
            step: RecipientsListSteps;
        }>(),
        add: props<{
            destination: RECIPIENTS_LIST_TYPE;
            results?: Array<any>;
            otherStore?: string;
            source: 'select' | 'file';
        }>(),
        addFromFile: props<{
            destination: RECIPIENTS_LIST_TYPE;
            results: Array<any>;
        }>(),
        addFromSelect: props<{
            destination: RECIPIENTS_LIST_TYPE;
            results: Array<Partial<RhRecipientsListRequestBody>>;
        }>(),
        getById: props<{
            id: number;
        }>(),
        getByIdSuccess: props<{
            form: Partial<RhRecipientsListForm>;
        }>(),
        getByIdFailure: props<{
            error: string;
        }>(),
        create: emptyProps(),
        createSuccess: props<{
            form: Partial<RhRecipientsListForm>;
        }>(),
        createFailure: props<{
            error: string;
        }>(),
        update: emptyProps(),
        updateSuccess: props<{
            id: number;
            form: Partial<RhRecipientsListForm>;
        }>(),
        updateFailure: props<{
            error: string;
        }>(),
        changeStatus: props<{
            status: ListType;
        }>(),
        clear: emptyProps(),
    },
});

export const dialog = createActionGroup({
    source: 'RECIPIENTS FORM DIALOG',
    events: {
        openDetailsRada: props<{
            email: string;
        }>(),
        openLeavePageDialog: props<{
            url: string;
        }>(),
        openImportDialog: props<{
            destination: RECIPIENTS_LIST_TYPE;
            source: 'select' | 'file';
            otherStore?: string;
        }>(),
        openMappingDialog: props<{
            groupedByCol: any;
            sheet: any;
        }>(),
        openConfirmCreate: emptyProps(),
    },
});

export const importExistingList = createActionGroup({
    source: 'RECIPIENTS FORM IMPORT FILE',
    events: {
        request: emptyProps(),
        success: props<{
            lists: Array<{
                id: number;
                name: string
            }>;
        }>(),
        failure: props<{
            error: string;
        }>(),
        clear: emptyProps(),
    },
});
export const importFile = createActionGroup({
    source: 'RECIPIENTS FORM IMPORT FILE',
    events: {
        setStep: props<{
            step: ImportStepStatus;
        }>(),
        setInitialValues: props<{
            initialValues: {
                [key: string]: any
            }[]
        }>(),
        calculateValues: emptyProps(),
        setMappedData: emptyProps(),
        setMappingForm: props<{
            mappingForm: any
        }>(),
        selectedFile: props<{
            sheet: any;
        }>(),
        setInvalidFile: props<{
            invalidFile: boolean
        }>(),
        setGroupedByCol: emptyProps(),
        setDataToMapping: props<{
            groupedByCol: {
                [key: string]: {
                    values: Array<string>;
                    emailsPercent: number;
                };
            };
            rows: number;
            columnWithEmails: string;
        }>(),
        updateMappedData: props<{
            mappedData: Array<{
                lastName: string;
                firstName: string;
                email: string;
            }>;
        }>(),
        setImportStep: props<{
            step: ImportStepStatus;
        }>(),
        clearImport: emptyProps(),
        setExcludedFirstRow: props<{
            excludedFirstRow: boolean;
        }>(),
    },
});
