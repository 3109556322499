<button rds-fab-button size="l" (click)="popover.toggle()" #popover="rdsPopover" rdsPopoverPosition="right-center"
  rdsPopoverClass="sitemap-helper-popover" [rdsPopover]="popoverTemplate">
  <rds-icon rdsTooltip="Open Site Map helper" icon="map" namespace="outlined"></rds-icon>
</button>

<ng-template #popoverTemplate>
  <rds-popover-text-content *ngIf="sitemapRoute" style="width: 400px; display: block;">
    <div rds-popover-header>
      <rds-breadcrumbs [items]="[
        { text: 'Sitemap', title: '/sitemap' },
        { text: shortenTitle(sitemapRoute.title), title: sitemapRoute.url },
      ]" (itemClick)="breadcrumbItemClicked($event)"></rds-breadcrumbs>
    </div>
    <div rds-popover-paragraph>
      <div class="row">
        <div class="col mb-3">
          <p class="ui-overline-s">
            Status
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-8 offset-2 mb-5">
          <rh-sitemap-status-toggle [status]="sitemapRoute.status"
            (statusChanged)="changeStatus(sitemapRoute, $event)"></rh-sitemap-status-toggle>
        </div>
      </div>
      <ng-container *ngIf="{
          newNote: null,
          isEmpty: true,
      } as variables">
        <div class="row">
          <div class="col mb-3">
            <p class="ui-overline-s">Quick note</p>
          </div>
          <div class="col-auto">
            <button class="mx-2" [disabled]="variables.isEmpty"
              (click)="addNote(sitemapRoute, variables.newNote); variables.newNote = null; variables.isEmpty = true; input.innerText = ''"
              rds-icon-button size="s">
              <rds-icon namespace="outlined" rdsTooltip="Add note" icon="plus"></rds-icon>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col mb-5">
            <div [class.empty]="variables.isEmpty" class="editing">
              <p #input contenteditable
                (input)="variables.newNote = input.innerText; variables.isEmpty = input.innerText.length === 0"
                class="ui-label-m"></p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </rds-popover-text-content>
</ng-template>