import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '@app/layout/store';
import { filter, map, switchMap } from 'rxjs';
import { ConfirmDialogComponent, ConfirmDialogData } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import { RdsDialogService } from '@rds/angular-components';

@Injectable()
export class LayoutEffects {
	public showRatioWarningDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.showRatioWarningDialog),

				map(({ scale }) => {
					const data: ConfirmDialogData = {
						ids: [],
						messages: [
							`We have detected that the application works in zoom.`,
							`For better satisfaction, change scale in windows to 100% or reduce the zoom in your browser.`,
						],
						title: 'The application works in zoom',
						confirmButtonLabel: 'Confirm',
						confirmButtonType: 'primary',
					};
					const dialog = this.dialogService.open(ConfirmDialogComponent, {
						data,
					});
					return { dialog };
				}),
				switchMap(({ dialog }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((data: ConfirmDialogData) => {
							return { data };
						})
					)
				),
				map(({ data }) => {
					localStorage.setItem('ratioWarningDialog', 'true');
					return fromActions.closeRatioWarningDialog();
				})
			),
		{ dispatch: true }
	);

	constructor(
		private actions$: Actions,
		private store$: Store<any>,
		private dialogService: RdsDialogService
	) {}
}
