import {ChangeDetectionStrategy, Component} from '@angular/core';
import * as fromCatalogs from '@app/layout/header/store/catalogs';
import {select, Store} from '@ngrx/store';
import {Category} from '@app/layout/header/models';
import {Observable} from 'rxjs';

@Component({
  selector: 'rh-header-content',
  templateUrl: './header-content.component.html',
  styleUrls: ['./header-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class HeaderContentComponent {

  categories$: Observable<Array<Category>> = this.store$.pipe(select(fromCatalogs.selectCategories));
  openedCategory$: Observable<Category> = this.store$.pipe(select(fromCatalogs.selectOpenedCategory));


  openCategory(category: Category) {
    this.store$.dispatch(fromCatalogs.openCategory({category}));
  }

  constructor(
    private store$: Store<fromCatalogs.State>
  ) {
  }

}
