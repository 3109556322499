import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

@Directive({ selector: '[atrAutoFocus]' })
export class AutofocusDirective implements OnInit, OnDestroy {

  constructor(
    private host: ElementRef<HTMLInputElement>
  ) {
  }
  ngOnDestroy(): void {
    if (document.activeElement === this.host.nativeElement) {
      this.host.nativeElement.blur();
    }
  }
  ngOnInit(): void {
    this.host.nativeElement.focus();
  }
}
