import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';

import * as fromScreen from './screen/screen.reducer';
import * as fromToast from './toast/toast.reducer';
import * as fromNotifications from './notifications/notifications.reducer';
import * as fromBackButton from './back-button/back-button.reducer';
import * as fromSiteMap from './sitemap/sitemap.reducer';

export const feature = 'ui';

export interface State {
  screen: fromScreen.State;
  notifications: fromNotifications.State;
  toast: fromToast.State;
  backButton: fromBackButton.State
  siteMap: fromSiteMap.State
}

export function reducer(state: State | undefined, action: Action) {
  return combineReducers({
    screen: fromScreen.reducer,
    notifications: fromNotifications.reducer,
    toast: fromToast.reducer,
    backButton: fromBackButton.reducer,
    siteMap: fromSiteMap.reducer
  })(state, action);
}

export const selectState = createFeatureSelector<State>(feature);

export const getScreen = (state: State) => state.screen;
export const getToast = (state: State) => state.toast;
export const getNotifications = (state: State) => state.notifications;
export const getBackButton = (state: State) => state.backButton;
export const getSiteMap = (state: State) => state.siteMap;
