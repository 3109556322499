import { Component, Input } from '@angular/core';
import { User } from '@app/core/models/newsboard';

@Component({
  selector: 'rh-user-autocomplete-item',
  templateUrl: './user-autocomplete-item.component.html',
  styleUrls: ['./user-autocomplete-item.component.scss']
})
export class UserAutocompleteItemComponent {
  @Input() option: User;
  @Input() highlightPhrase: string;
}
