import {createSelector} from '@ngrx/store';

import * as fromReducer from '@app/newsletter/store/newsletter.reducer';
import * as fromNewsletterInline from '@app/newsletter/store/newsletter-inline/newsletter-inline.reducer';
import * as fromNewsletterBlocks from '@app/newsletter/store/newsletter-blocks/newsletter-blocks.selectors';

export const selectNewsletterForm = createSelector(
  fromReducer.selectState,
  fromReducer.getNewsletterInline
);

export const selectForm = createSelector(
  selectNewsletterForm,
  fromNewsletterInline.getForm
);

export const selectEditType = createSelector(
  selectNewsletterForm,
  fromNewsletterInline.getEditType
);

export const selectTitle = createSelector(
  selectForm,
  fromNewsletterInline.getTitle
);

export const selectContactEmailForFeedback = createSelector(
  selectForm,
  fromNewsletterInline.getContactEmailForFeedback
);

export const selectRocheLogoInHeader = createSelector(
  selectForm,
  fromNewsletterInline.getRocheLogoInHeader
);

export const selectDisclaimerIncluded = createSelector(
  selectForm,
  fromNewsletterInline.getDisclaimerIncluded
);

export const selectBannerPhotoIncluded = createSelector(
  selectForm,
  fromNewsletterInline.getBannerPhotoIncluded
);

export const selectTitleIncluded = createSelector(
  selectForm,
  fromNewsletterInline.getTitleIncluded
);

export const selectFeedbackIncluded = createSelector(
  selectForm,
  fromNewsletterInline.getFeedbackIncluded
);

export const selectSentByName = createSelector(
  selectForm,
  fromNewsletterInline.getSentByName
);

export const selectSenderName = createSelector(
  selectForm,
  fromNewsletterInline.getSenderName
);

export const selectColor = createSelector(
  selectForm,
  fromNewsletterInline.getColor
);

export const selectContentBlocksForm = createSelector(
  selectForm,
  (form) => ({
    contactEmailForFeedback: form.contactEmailForFeedback,
    rocheLogoInHeader: form.rocheLogoInHeader,
    disclaimerIncluded: form.disclaimerIncluded,
    bannerPhotoIncluded: form.bannerPhotoIncluded,
    titleIncluded: form.titleIncluded,
    feedbackIncluded: form.feedbackIncluded,
  })
);

export const selectStepOneValidityForm = createSelector(
  selectForm,
  (form) => ({
    title: form.title,
    bannerPhotoUrl: form.bannerPhotoUrl,
    contactEmailForFeedback: form.contactEmailForFeedback,
    rocheLogoInHeader: form.rocheLogoInHeader,
    disclaimerIncluded: form.disclaimerIncluded,
    bannerPhotoIncluded: form.bannerPhotoIncluded,
    titleIncluded: form.titleIncluded,
    feedbackIncluded: form.feedbackIncluded,
  })
);

export const selectStepTwoForm = createSelector(
  selectForm,
  (form) => ({
    recipientType: form.recipientType,
    taxonomyRecipient: form.taxonomyRecipient,
    fromName: form.fromName,
    from: form.from,
    senderName: form.senderName,
    replyToName: form.replyToName,
    replyTo: form.replyTo,
    subject: form.subject,
    previewText: form.previewText,
    to: form.to,
    toGroups: form.toGroups,
    scheduleSentDate: form.scheduleSentDate,
    timeZone: form.timeZone
  })
);

export const selectCurrentStep = createSelector(
  selectNewsletterForm,
  fromNewsletterInline.getCurrentStep
);

export const selectCurrentStepIsValid = createSelector(
  selectNewsletterForm,
  selectCurrentStep,
  (state, currentStep) => state.steps[currentStep].valid
);

export const selectStepOneIsValid = createSelector(
  selectNewsletterForm,
  fromNewsletterBlocks.selectSections,
  (state, sections) => {
    const bannerValid = state.form.bannerPhotoIncluded ? !!state.form.bannerPhotoUrl : true;
    return state.steps[0].valid && bannerValid && sections.some(s => s.subsections.some(ss => ss.blocks.length > 0));
  }
);

export const selectStepTwoIsValid = createSelector(
  selectNewsletterForm,
  (state) => state.steps[1].valid
);

export const selectBannerData = createSelector(
  selectForm,
  (form) => ({
    bannerPhotoUrl: form.bannerPhotoUrl,
    bannerPhotoName: form.bannerPhotoName,
    bannerPhotoSize: form.bannerPhotoSize
  })
);

export const selectNewsletterRequestData = createSelector(
  selectNewsletterForm,
  fromNewsletterBlocks.selectState,
  (inlineState, blockState) => {
    return { form: inlineState.form, sections: blockState.sections };
  }
);

export const selectAllowEditTemplate = createSelector(
  selectCurrentStep,
  (step) => step === 0
);

export const selectNewsletterId = createSelector(
  selectForm,
  (form) => ({
    newsletterId: form.newsletterId,
    templateId: form.templateId,
    isContributed: form.isContributed
  })
);

export const selectUsersCount = createSelector(
  selectNewsletterForm,
  (state) => state.usersCount
);

export const selectPublishDialogDataRecipientsText = createSelector(
  selectNewsletterForm,
  (state) => {
    if (state.form.recipientType === 0) {
      let resultText = '';
      resultText += state.form.toGroups ? state.form.toGroups.split(',').join(', ') : '';
      resultText += state.form.toGroups && state.form.to ? ', ' : '';
      resultText += state.form.to ? state.form.to.split(',').join(', ') : '';
      return resultText;
    } else {
      return state.usersCount + ' recipients';
    }
  }
);

export const selectPublishDialogDataRecipientsTextAndWhenToSend = createSelector(
  selectNewsletterForm,
  (state) => {
    if (state.form.recipientType === 0) {
      let resultText = '';
      resultText += state.form.toGroups ? state.form.toGroups.split(',').join(', ') : '';
      resultText += state.form.toGroups && state.form.to ? ', ' : '';
      resultText += state.form.to ? state.form.to.split(',').join(', ') : '';
      return {recipients: resultText, whenToSend: state.form.whenToSend};
    } else {
      return {recipients: state.usersCount + ' recipients', whenToSend: state.form.whenToSend};
    }
  }
);

export const selectIsSaveProcessActive = createSelector(
  selectNewsletterForm,
  (state) => state.isSaveProcessActive
);

export const selectIsContributed = createSelector(
  selectForm,
  (form) => form && form.isContributed
);

export const selectCanSaveAsDraft = createSelector(
  selectForm,
  (form) => !!form.title && form.title.length < 129
);