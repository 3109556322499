import { createSelector } from '@ngrx/store';
import * as fromOther from './newsletter.reducer';
import * as fromReducer from '../newsletter.reducer';

export const selectNewsletterOther = createSelector(
  fromReducer.selectState,
  fromReducer.getNewsletterOther
);
export const selectChannels =
  createSelector(
    selectNewsletterOther,
    fromOther.getChannelsFromStore
  );

export const selectChannelsEntities = createSelector(
  selectChannels,
  fromOther.getChannelsEntities
);

export const selectChannelsWithNews =
  createSelector(
    selectNewsletterOther,
    fromOther.getNewsForChannelsFromStore
  );

export const selectNewsForChannelsEntities =
  createSelector(
    selectChannelsWithNews,
    fromOther.getChannelsWithNewsEntities
  );

export const selectNews =
  createSelector(
    selectNewsletterOther,
    fromOther.getNewsFromStore
  );
export const selectNewsEntities =
  createSelector(
    selectNews,
    fromOther.getNewsEntities
  );


export const selectChannelsById = (id) =>
  createSelector(
    selectChannelsEntities,
    entities => entities[id]
  );

export const selectNewsById = (id) => createSelector(
  selectNewsEntities,
  entities => entities[id]
);
export const selectNewsAreLoading =
  createSelector(
    selectNewsletterOther,
    fromOther.getNewsAreLoadingFromStore
  );
export const selectNewsletterAdding =
  createSelector(
    selectNewsletterOther,
    fromOther.getNewsletterAddingFromStore
  );

export const selectNewsletterLastUpdated =
  createSelector(
    selectNewsletterOther,
    fromOther.getNewsletterLastUpdatedChannelWithNewsFromStore
  );

export const selectDynamicLinks =
  createSelector(
    selectNewsletterOther,
    fromOther.getDynamicLinksFromStore
  );
export const selectDynamicLinksEntities =
  createSelector(
    selectDynamicLinks,
    fromOther.getDynamicLinksAll
  );

export const selectIsSaveProcessActive = createSelector(
  selectNewsletterOther,
  (state) => state.isSaveProcessActive
);
