import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import * as fromLayout from '@app/layout/store';
import * as fromScreen from '@app/root-store/ui/screen';
import * as fromQuickLinks from '@app/layout/header/store/quick-link/quick-link.selectors';
import { select, Store } from '@ngrx/store';
import { TabsEnum } from '@core/enums/header-tabs.enum';
import * as fromHeader from '@app/layout/header/store/header/header.actions';
import * as fromQuickLink from '@app/layout/header/store/quick-link';

import { SubSink } from 'subsink';

@Component({
	selector: 'atr-standard-layouts',
	templateUrl: './standard-layout.component.html',
	styleUrls: ['./standard-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardLayoutComponent implements OnInit {
	private subs: SubSink = new SubSink();

	public showOnlyDesktopWarning$ = this.store$.pipe(select(fromScreen.selectNotVisibleForMobile));

	public isOpened$ = this.store$.pipe(select(fromLayout.selectNotificationBarOpened));

	public areOpenedQuickLinks: boolean;
	public areOpenedQuickLinks$ = this.store$.pipe(select(fromQuickLink.selectQuickLinksOpened));

	public isHeaderOpened$ = this.store$.pipe(select(fromQuickLinks.selectQuickLinksOpened));
	public activeHeaderTab$ = this.store$.pipe(select(fromQuickLinks.selectCurrentTab));
	TabsEnum = TabsEnum;

	public onCloseBar(): void {
		this.store$.dispatch(fromLayout.closeNotificationBar());
	}

	ngOnInit() {
		this.store$.dispatch(fromHeader.getConfigurationLinks());

		this.subs.sink = this.areOpenedQuickLinks$.subscribe((result) => {
			this.areOpenedQuickLinks = result;
		});
	}

	public onQuickLinksToggle(): void {
		if (this.areOpenedQuickLinks) {
			this.store$.dispatch(fromQuickLink.closeHeaderQuickLinks());
		} else {
			this.store$.dispatch(fromQuickLink.toggleHeaderQuickLinks());
		}
	}

	constructor(private store$: Store<fromLayout.State>) {}
}
