import {createAction, props} from '@ngrx/store';
import {ContentItem, Pagination} from '@app/core/models';
import {ChannelSubscriptionFilter, Subscription, SubscriptionType} from './subscriptions.reducer';
import {ManagingChannel} from '@core/models/managing-channels.model';
import {LoadingState} from '@app/search/models/rh';
import {Language} from '@core/models/language.model';

export const initSubscriptions = createAction(
  '[HOME - SUBSCRIPTIONS] Init subscriptions'
);

export const changeSubscriptionsEditMode = createAction(
  '[HOME - SUBSCRIPTIONS] Change subscriptions edit mode',
  props<{ opened: boolean }>()
);

export const setLoading = createAction(
  '[HOME - SUBSCRIPTIONS] Set loading',
  props<{ loading: LoadingState, subType: SubscriptionType }>()
);

export const changeActiveSubscriptionType = createAction(
  '[HOME - SUBSCRIPTIONS] Change active subscription type',
  props<{ subType: SubscriptionType }>()
);

export const getAllSubscriptions = createAction(
  '[HOME - SUBSCRIPTIONS] Get Subscriptions',
);

export const getAllSubscriptionsSuccess = createAction(
  '[HOME - SUBSCRIPTIONS] Get Subscription success',
  props<{ channels: Array<Subscription>, authors: Array<Subscription>, topics: Array<Subscription> }>()
);

export const getNewsForSubscription = createAction(
  '[HOME - SUBSCRIPTIONS] Get News for subscription',
  props<{ subType: SubscriptionType }>()
);

export const addSubscriptionByIdsRequest = createAction(
  '[HOME - SUBSCRIPTIONS] Add Subscription By Ids Request',
  props<{ ids: Array<string>, subType: SubscriptionType }>()
);

export const addSubscriptionByIdsSuccess = createAction(
  '[HOME - SUBSCRIPTIONS] Add Subscription By Ids Success',
  props<{ updated: Array<Subscription>, subType: SubscriptionType, ids?: Array<string> }>()
);

export const setSubscriptionFilters = createAction(
  '[HOME - SUBSCRIPTIONS] Set subscription filters',
  props<{ filters: any, subType: SubscriptionType }>()
);

export const addSubscriptionByIdsFailure = createAction(
  '[HOME - SUBSCRIPTIONS] Add Subscription By Ids Failure',
  props<{ error: any, subType: SubscriptionType }>()
);

export const deleteSubscriptionByIdsRequest = createAction(
  '[HOME - SUBSCRIPTIONS] Delete Subscription By Ids Request',
  props<{ ids: Array<string>, subType: SubscriptionType }>()
);

export const deleteSubscriptionByIdsSuccess = createAction(
  '[HOME - SUBSCRIPTIONS] Delete Subscription By Ids Success',
  props<{ updated: Array<Subscription>, subType: SubscriptionType, ids?: Array<string> }>()
);

export const deleteSubscriptionByIdsFailure = createAction(
  '[HOME - SUBSCRIPTIONS] Delete Subscription By Ids Failure',
  props<{ error: any, subType: SubscriptionType }>()
);

export const getNewsForSubscriptionRequest = createAction(
  '[HOME - SUBSCRIPTIONS] Get News for subscription request',
  props<{ language: Language, pageIndex: number, pageSize: number, subscriptions: Array<Subscription>, sortType: number, subType: SubscriptionType }>()
);

export const loadMoreNews = createAction(
  '[HOME - SUBSCRIPTIONS] Load more News',
  props<{ subType: SubscriptionType }>()
);

export const loadMoreNewsRequest = createAction(
  '[HOME - SUBSCRIPTIONS] Load more News request',
  props<{ language: Language, pageIndex: number, pageSize: number, subscriptions: Array<Subscription>, sortType: number, subType: SubscriptionType }>()
);

export const getNewsSuccess = createAction(
  '[HOME - SUBSCRIPTIONS] Get News success',
  props<{
    subType: SubscriptionType,
    pagination: Pagination,
    local: { news: Array<ContentItem>, pagination: Pagination },
    global: { news: Array<ContentItem>, pagination: Pagination }
  }>()
);

export const loadMoreNewsSuccess = createAction(
  '[HOME - SUBSCRIPTIONS] Load more News success',
  props<{
    pagination: Pagination,
    local: { news: Array<ContentItem>, pagination: Pagination},
    global: { news: Array<ContentItem>, pagination: Pagination },
    subType: SubscriptionType
  }>()
);

export const setSelectedSubscriptions = createAction(
  '[HOME - SUBSCRIPTIONS] Set selected subscriptions',
  props<{ subscriptions: Array<Subscription>, subType: SubscriptionType }>()
)
export const displayEmpty = createAction(
  '[HOME - SUBSCRIPTIONS] Display empty',
  props<{ subType: SubscriptionType }>()
)

export const setSortType = createAction(
  '[HOME - SUBSCRIPTIONS] Set sort type',
  props<{ sortType: number, subType: SubscriptionType }>()
)

export const addLike = createAction(
  '[HOME - SUBSCRIPTIONS] Add like',
  props<{ id: string, likesCount: number }>()
);

export const addLikeSuccess = createAction(
  '[HOME - SUBSCRIPTIONS] Add like success',
  props<{ id: string }>()
);

export const addLikeFailure = createAction(
  '[HOME - SUBSCRIPTIONS] Add like failure',
);

export const removeLike = createAction(
  '[HOME - SUBSCRIPTIONS] Remove like',
  props<{ id: string, likesCount: number }>()
);

export const removeLikeSuccess = createAction(
  '[HOME - SUBSCRIPTIONS] Remove like success',
  props<{ id: string }>()
);

export const removeLikeFailure = createAction(
  '[HOME - SUBSCRIPTIONS] Remove like failure',
);


export const resetPageIndex = createAction(
  '[HOME - SUBSCRIPTIONS] reset page index',
  props<{ subType: SubscriptionType, id: string }>()
);

export const setPhrase = createAction(
  '[HOME - SUBSCRIPTIONS] set phrase',
  props<{ phrase: string }>()
);
export const setFilters = createAction(
  '[HOME - SUBSCRIPTIONS] set filters',
  props<{ filters: { locations: string[], departments: string[], functions: string[], myLocationsOnly: boolean } }>()
);

export const initAuthorsFilters = createAction(
  '[HOME - SUBSCRIPTIONS]  Init authors filters'
);

export const clearFilteredAuthors = createAction(
  '[HOME - SUBSCRIPTIONS]  Clear filtered authors'
);

export const getAllAuthorsRequest = createAction(
  '[HOME - SUBSCRIPTIONS] Get Authors Request',
  props<{
    phrase
  }>()
);

export const getAllAuthorsSuccess = createAction(
  '[HOME - SUBSCRIPTIONS] Get Authors Success',
  props<{ allAuthors: Subscription[] }>()
);

export const getAllAuthorFailure = createAction(
  '[HOME - SUBSCRIPTIONS] Get Author Failure',
  props<{ error: any }>()
);

export const initTopicsFilters = createAction(
  '[HOME - SUBSCRIPTIONS]  Init topics filters'
);

export const clearFilteredTopics = createAction(
  '[HOME - SUBSCRIPTIONS]  Clear filtered topics'
);

export const getAllTopicsRequest = createAction(
  '[HOME - SUBSCRIPTIONS] Get Topics Request',
  props<{
    phrase
  }>()
);

export const getAllTopicsSuccess = createAction(
  '[HOME - SUBSCRIPTIONS] Get Topics Success',
  props<{ allTopics: Subscription[] }>()
);

export const getAllTopicsFailure = createAction(
  '[HOME - SUBSCRIPTIONS] Get Topics Failure',
  props<{ error: any }>()
);


export const initChannelsFilters = createAction(
  '[HOME - SUBSCRIPTIONS]  Init channels filters'
);

export const clearFilteredChannels = createAction(
  '[HOME - SUBSCRIPTIONS]  Clear filtered channels'
);

export const getAllChannelsRequest = createAction(
  '[HOME - SUBSCRIPTIONS] Get User Managing Channels Request',
  props<{ filters: ChannelSubscriptionFilter }>()
);

export const getAllChannelsSuccess = createAction(
  '[HOME - SUBSCRIPTIONS] Get User Managing Channels Success',
  props<{ allChannels: ManagingChannel[] }>()
);

export const getAllChannelsFailure = createAction(
  '[HOME - SUBSCRIPTIONS] Get User Managing Channels Failure',
  props<{ error: any }>()
);

export const setLocalFilter = createAction(
  '[HOME - SUBSCRIPTIONS] set local search',
  props<{ localSearch: string }>()
);

export const markRead = createAction(
  '[HOME - SUBSCRIPTIONS] Mark read',
  props<{ id: string }>()
);
export const clearSuggested = createAction(
  '[HOME - SUBSCRIPTIONS] Cleaning suggested'
);
export const addBookmark = createAction(
  '[HOME - SUBSCRIPTIONS] Add bookmark',
  props<{ id: string }>()
);

export const addBookmarkSuccess = createAction(
  '[HOME - SUBSCRIPTIONS] Add bookmark success',
  props<{ id: string }>()
);

export const addBookmarkFailure = createAction(
  '[HOME - SUBSCRIPTIONS] Add bookmark failure',
);

export const removeBookmark = createAction(
  '[HOME - SUBSCRIPTIONS] Remove bookmark',
  props<{ id: string }>()
);

export const removeBookmarkSuccess = createAction(
  '[HOME - SUBSCRIPTIONS] Remove bookmark success',
  props<{ id: string }>()
);

export const removeBookmarkFailure = createAction(
  '[HOME - SUBSCRIPTIONS] Remove bookmark failure',
);

export const openConfirmAddDialog = createAction(
  '[HOME - SUBSCRIPTIONS] Confirm add modal',
  props<{ id: string, subType: string }>()
);
