import { Component } from '@angular/core';

@Component({
  selector: 'rh-development-helpers',
  templateUrl: './development-helpers.component.html',
  styleUrls: ['./development-helpers.component.scss']
})
export class DevelopmentHelpersComponent {

}
