import { Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { QuickLink } from '@app/core/models';
import * as fromUiReducer from '@app/root-store/ui/ui.reducer';
import { RDS_DIALOG_DATA, RdsDialogRef } from '@rds/angular-components';

export interface ResetQuicklinksDialogData {
  quickLinksToAdd: Array<QuickLink>;
  quickLinksToRemove: Array<QuickLink>;
}

@Component({
  selector: 'rh-reset-quicklinks-dialog',
  templateUrl: './reset-quicklinks-dialog.component.html',
  styleUrls: ['./reset-quicklinks-dialog.component.scss']
})
export class ResetQuicklinksDialogComponent {

  quickLinksToAdd: Array<QuickLink>;
  quickLinksToRemove: Array<QuickLink>;


  constructor(
    @Inject(RDS_DIALOG_DATA) public data: ResetQuicklinksDialogData,
    private dialog: RdsDialogRef<ResetQuicklinksDialogComponent>,
    private store$: Store<fromUiReducer.State>
  ) {
    if (!!data) {
      this.quickLinksToAdd = data.quickLinksToAdd;
      this.quickLinksToRemove = data.quickLinksToRemove;
    }
  }
}
