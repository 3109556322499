import { Pagination } from '@app/core/models';
import { EntityState } from '@ngrx/entity';
import { SortDirection } from '@rds/angular-components/lib/table';

export enum FilterTypeEnum {
	ADVANCED = 'advanced',
	SELECT = 'select',
	SEARCH = 'search',
	DICTIONARY = 'dictionary',
	DATE = 'date',
	SUGGESTION = 'suggestion',
	PREDEFINED_TOGGLE = 'predefinedToggle',
}

export enum DictionaryTypeEnum {
	LOCATIONS = 'locations',
	DEPARTMENTS = 'departments',
	FUNCTIONS = 'functions',
	CONTENT_TYPES = 'contentTypes',
	CATEGORIES = 'categories',
	CAMPAIGNS = 'campaigns',
}

export enum SuggestionTypeEnum {
	USER = 'user',
	CATALOG = 'catalog',
	CHANNEL = 'channel',
	TOPIC_NEWS = 'topic-news',
	TOPIC_EVENT = 'topic-news',
}

export enum SelectionStatus {
	NOT_INIT = 'NOT_INIT',
	LOADING = 'LOADING',
	LOADED = 'LOADED',
	LOAD_MORE = 'LOAD_MORE',
}

export interface SelectionListState<T, F = undefined> {
	data: EntityState<T>;
	status: SelectionStatus;
}

export interface TableState<T, F = undefined> {
	data: EntityState<T>;
	pagination: Pagination;
	sort: {
		active: string;
		direction: SortDirection;
	};
	defaultSort: {
		active: string;
		direction: SortDirection;
	};
	filters?: F;
	defaultFilters?: F;
	isLoading: boolean;
	resetIndex: boolean;
}

export function flattenFilters(filters) {
	var flattenedFilters = {};
	Object.keys(filters).forEach((key) => {
		if (filters[key].type === FilterTypeEnum.PREDEFINED_TOGGLE) {
			return;
		}
		if (!!filters[key].value || filters[key].value === false || filters[key].value === 0) {
			flattenedFilters = {
				...flattenedFilters,
				[key]: filters[key].value,
			};
		} else if (key === 'advancedFilters') {
			flattenedFilters = {
				...flattenedFilters,
				...flattenFilters(filters[key].filters),
			};
		}
	});
	return flattenedFilters;
}

export interface FilterDefinition {
	type?: FilterTypeEnum;
	changable?: boolean;
	value?: any;
}

export interface AdvancedFilterDefinition<T> extends FilterDefinition {
	activeCount: number;
	filters: T;
}

export interface PredefinedFilterDefinition extends FilterDefinition {
	label: string;
	predefinedValue: { [key: string]: any };
	toggleLabel: string;
}

export interface DictionaryFilterDefinition extends FilterDefinition {
	dictionary: DictionaryTypeEnum;
	fromBranch?: string | number;
	label: string;
}

export interface SelectFilterDefinition extends FilterDefinition {
	label: string;
	multiple: boolean;
	options: Array<{ label: string; value: any }>;
}

export interface DateFilterDefinition extends FilterDefinition {
	label: string;
	range: boolean;
}

export interface SuggestionFilterDefinition extends FilterDefinition {
	label: string;
	suggestion: SuggestionTypeEnum;
	multiple: boolean;
}

export const SEARCH_FILTER: { search: FilterDefinition } = {
	search: {
		type: FilterTypeEnum.SEARCH,
		changable: true,
		value: '',
	},
};

export interface OnlySearchFilters {
	search: FilterDefinition;
}

export interface AdminTableFilters {
	roles: SelectFilterDefinition;
}

export interface ChannelTableFilters {
	roles: SelectFilterDefinition;
	search: FilterDefinition;
}

export interface NewsTableFilters {
	types?: SelectFilterDefinition;
	statuses?: SelectFilterDefinition;
	search?: FilterDefinition;
	approvalStatuses?: SelectFilterDefinition;
	scope?: SelectFilterDefinition;
}

export const NEWS_TIME_RANGE = [
	{ label: '6 months', value: '6' },
	{ label: '1 year', value: '12' },
	{ label: '2 years', value: '24' },
	{ label: '5 years', value: '60' },
];

export const MY_ROLES = [
	{ label: 'Owner', value: 'Owner' },
	{ label: 'Manager', value: 'Manager' },
	{ label: 'Publisher', value: 'Publisher' },
];

export const NEWS_TYPES = [
	{ label: 'Link', value: 'link' },
	{ label: 'Content', value: 'embedded' },
];

export const NEWS_STATUSES = [
	{ label: 'Draft', value: 'draft' },
	{ label: 'Scheduled', value: 'scheduled' },
	{ label: 'Published', value: 'published' },
];

export const NEWS_STATUSES_EXTENDED = [...NEWS_STATUSES, { label: 'Archived', value: 'archived' }];

export const NEWS_APPROVAL_STATUSES = [
	{ label: 'Pending', value: 'Pending' },
	{ label: 'Approved', value: 'Approved' },
	{ label: 'Rejected', value: 'Rejected' },
];

export const ADMIN_ROLES = [
	{ label: 'Administrator', value: 'Admin' },
	{ label: 'Super Administrator', value: 'SuperAdmin' },
];

//initial tables filters

//Channels
export const INITIAL_MY_CHANNELS_FILTERS = {
	roles: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: [],
		label: 'My role',
		multiple: true,
		options: MY_ROLES,
	},
	...SEARCH_FILTER,
};

export const INITIAL_ALL_CHANNELS_FILTERS = {
	roles: {
		type: FilterTypeEnum.SELECT,
		changable: false,
		value: [],
		label: 'My role',
		multiple: true,
		options: MY_ROLES,
	},
	...SEARCH_FILTER,
};

//Single channel
export const INITIAL_CHANNEL_ALL_NEWS_FILTERS = {
	types: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: [],
		label: 'Type',
		multiple: true,
		options: NEWS_TYPES,
	},
	statuses: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: [],
		label: 'Status',
		multiple: true,
		options: NEWS_STATUSES,
	},
	...SEARCH_FILTER,
};

export const INITIAL_CHANNEL_NEWS_SUGGESTION_FILTERS = {
	types: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: [],
		label: 'Type',
		multiple: true,
		options: NEWS_TYPES,
	},
	statuses: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: [],
		label: 'Status',
		multiple: true,
		options: NEWS_STATUSES,
	},
	...SEARCH_FILTER,
	approvalStatuses: {
		type: FilterTypeEnum.SELECT,
		changable: false,
		value: ['pending'],
		label: 'Status',
		multiple: true,
		options: NEWS_APPROVAL_STATUSES,
	},
};

export const INITIAL_CHANNEL_ARCHIVED_NEWS_FILTERS = {
	types: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: [],
		label: 'Type',
		multiple: true,
		options: NEWS_TYPES,
	},
	statuses: {
		type: FilterTypeEnum.SELECT,
		changable: false,
		value: ['archived'],
		label: 'Status',
		multiple: true,
		options: NEWS_STATUSES,
	},
	...SEARCH_FILTER,
	approvalStatuses: {
		type: FilterTypeEnum.SELECT,
		changable: false,
		value: ['pending'],
		label: 'Status',
		multiple: true,
		options: NEWS_APPROVAL_STATUSES,
	},
};

//News
export const INITIAL_ALL_NEWS_FILTERS = {
	types: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: [],
		label: 'Type',
		multiple: true,
		options: NEWS_TYPES,
	},
	statuses: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: [],
		label: 'Status',
		multiple: true,
		options: NEWS_STATUSES_EXTENDED,
	},
	...SEARCH_FILTER,
};

export const INITIAL_MY_NEWS_FILTERS = {
	types: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: [],
		label: 'Type',
		multiple: true,
		options: NEWS_TYPES,
	},
	statuses: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: [],
		label: 'Status',
		multiple: true,
		options: NEWS_STATUSES,
	},
	...SEARCH_FILTER,
};

export const INITIAL_MY_SUGGESTED_FILTERS = {
	types: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: [],
		label: 'Type',
		multiple: true,
		options: NEWS_TYPES,
	},
	statuses: {
		type: FilterTypeEnum.SELECT,
		changable: false,
		value: [],
		label: 'Status',
		multiple: true,
		options: NEWS_STATUSES,
	},
	approvalStatuses: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: [],
		label: 'Status',
		multiple: true,
		options: NEWS_APPROVAL_STATUSES,
	},
	...SEARCH_FILTER,
};

export const INITIAL_MY_ARCHIVED_FILTERS = {
	types: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: [],
		label: 'Type',
		multiple: true,
		options: NEWS_TYPES,
	},
	statuses: {
		type: FilterTypeEnum.SELECT,
		changable: false,
		value: ['archived'],
		label: 'Status',
		multiple: true,
		options: NEWS_STATUSES,
	},
	...SEARCH_FILTER,
	approvalStatuses: {
		type: FilterTypeEnum.SELECT,
		changable: false,
		value: ['archived'],
		label: 'Status',
		multiple: true,
		options: NEWS_APPROVAL_STATUSES,
	},
};

//Admin list
export const INITIAL_ADMIN_FILTERS = {
	roles: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: [],
		label: 'Role',
		multiple: true,
		options: ADMIN_ROLES,
	},
};

//Related news
export const INITIAL_RELATED_NEWS_FILTERS = {
	scope: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: '6',
		label: 'Time range',
		multiple: false,
		options: NEWS_TIME_RANGE,
	},
	...SEARCH_FILTER,
};
