import { SimpleUser } from '@app/core/models/newsboard';
import { RecipientListsTableModel, SentRecipientsListModel } from '@app/core/models/newsletter.model';
import { RecipientsTableFilters } from '@app/shared/filters/models/recipients-table';
import { Pagination } from '@core/models';
import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { SortDirection } from '@rds/angular-components';
import { SelectFilterDefinition } from '@shared/filters/filters-model';

export const table = createActionGroup({
	source: 'RECIPIENTS TABLE',
	events: {
		init: emptyProps(),
		clear: emptyProps(),
		request: props<{
			pageIndex: number;
			pageSize: number;
			sort: {
				active: string;
				direction: SortDirection;
			};
			filters: { [key: string]: any };
		}>(),
		success: props<{ data: Array<RecipientListsTableModel>; pagination: Pagination }>(),
		failure: props<{ error: any }>(),
		filter: props<{ filters: RecipientsTableFilters }>(),
		sort: props<{ active: string; direction: SortDirection }>(),
		paginate: props<{ pageIndex: number; pageSize: number }>(),
	},
});

export const deleteList = createActionGroup({
	source: 'DELETE RECIPIENTS LIST',
	events: {
		dialog: props<{ lists: RecipientListsTableModel[] }>(),
		request: props<{ ids: number[] }>(),
		success: emptyProps(),
		failure: props<{ error: string }>(),
	},
});

export const sendList = createActionGroup({
	source: 'SEND RECIPIENTS LIST',
	events: {
		dialog: props<{ list: RecipientListsTableModel }>(),
		request: props<{ id: number; users: Array<SimpleUser> }>(),
		success: emptyProps(),
		failure: props<{ error: string }>(),
	},
});

export const getSentToMeList = createActionGroup({
	source: 'GET SENT TO ME RECIPIENTS LIST',
	events: {
		request: emptyProps(),
		success: props<{ lists: Array<SentRecipientsListModel> }>(),
		failure: props<{ error: string }>(),
	},
});

export const rejectSentToMeList = createActionGroup({
	source: 'REJECT SENT TO ME RECIPIENTS LIST',
	events: {
		request: props<{ id: number }>(),
		success: props<{ id: number }>(),
		failure: props<{ error: string }>(),
	},
});

export const acceptSentToMeList = createActionGroup({
	source: 'ACCEPT SENT TO ME RECIPIENTS LIST',
	events: {
		request: props<{ id: number }>(),
		success: props<{ id: number }>(),
		failure: props<{ error: string }>(),
	},
});
export const selectionList = createActionGroup({
	source: 'SELECT RECIPIENTS LIST',
	events: {
		request: emptyProps(),
		success: props<{ lists: Array<{ name: string; id: number }> }>(),
		failure: props<{ error: string }>(),
	},
});
export const updateCampaignsFilter = createAction(
	'[RECIPIENTS LIST TABLE] update campaigns options',
	props<{ campaigns: SelectFilterDefinition }>()
);
