import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromUser from '@app/core/user/store';
import {FormControl, FormGroup} from '@angular/forms';
import {first, Observable} from 'rxjs';
import {SubSink} from 'subsink';
import {RoleNames} from '@app/core/enums/roles.enum';
import {NewsboardAuthService, UserLogin} from '@app/core/auth/services/newsboard-auth.service';
import {distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'rh-roles-helper',
  templateUrl: './roles-helper.component.html',
  styleUrls: ['./roles-helper.component.scss']
})
export class RolesHelperComponent implements OnInit, OnDestroy {
  private subs: SubSink = new SubSink();
  initialManagedTaxonomy = {
    locations: [],
    departments: [],
    functions: []
  }
  private initialManagedTaxonomy$: Observable<{
    locations: Array<string>,
    departments: Array<string>,
    functions: Array<string>
  }> = this.store$.pipe(select(fromUser.selectInitialManagedTaxonomy));

  public initialRocheHomeRoles: Array<RoleNames> = [];
  public initialNewsBoardRoles: { isAdmin: boolean, isSuperAdmin: boolean } = {
    isAdmin: false,
    isSuperAdmin: false
  }
  private initialRocheHomeRoles$: Observable<Array<string>> = this.store$.pipe(select(fromUser.selectInitialRoles));
  private currentUserNewsboard$: Observable<UserLogin> = this.store$.pipe(select(fromUser.selectNewsBoardUser))
  private roles$: Observable<Array<string>> = this.store$.pipe(select(fromUser.selectRoles));

  private form: FormGroup = new FormGroup({
    rocheHomeRoles: new FormGroup({
      isAdministrator: new FormControl(null),
      isContentQualityManager: new FormControl(null),
      isAudienceManager: new FormControl(null),
    }),
    newsBoardRoles: new FormGroup({
      isAdministrator: new FormControl(null),
      isSuperAdministrator: new FormControl(null),
    }),
    audience: new FormGroup({
      locations: new FormControl([]),
      departments: new FormControl([]),
      functions: new FormControl([]),
    })
  })

  ngOnInit(): void {
    this.subs.sink = this.initialManagedTaxonomy$.pipe(first(managedTaxonomy => managedTaxonomy !== null)).subscribe(managedTaxonomy => {
      this.initialManagedTaxonomy = managedTaxonomy
    });

    this.subs.sink = this.currentUserNewsboard$.pipe(first()).subscribe((UserLogin) => {
      this.form.get('newsBoardRoles').patchValue({
        isAdministrator: UserLogin.isAdmin,
        isSuperAdministrator: UserLogin.isSuperAdmin,
      }, {emitEvents: false});
    })
    this.subs.sink = this.initialRocheHomeRoles$.pipe(first(roles => roles !== null)).subscribe(roles => {
      this.initialRocheHomeRoles = roles as Array<RoleNames>;
      this.initialNewsBoardRoles = {
        isAdmin: this.nbAuth.isAdmin,
        isSuperAdmin: this.nbAuth.isSuperAdmin
      }

      this.form.get('rocheHomeRoles').patchValue({
        isAdministrator: roles.includes(RoleNames.ADMINISTRATOR),
        isContentQualityManager: roles.includes(RoleNames.CONTENT_QUALITY_MANAGER),
        isAudienceManager: roles.includes(RoleNames.AUDIENCE_MANAGER),
      }, {emitEvents: false})
    });

    this.form.get('rocheHomeRoles').valueChanges.subscribe(roles => {
      const newRoles = [].concat(
        (roles.isAdministrator ? [RoleNames.ADMINISTRATOR] : []),
        (roles.isContentQualityManager ? [RoleNames.CONTENT_QUALITY_MANAGER] : []),
        (roles.isAudienceManager ? [RoleNames.AUDIENCE_MANAGER] : [])
      );
      this.store$.dispatch(fromUser.setUserRoles({roles: newRoles}))
    });

    this.form.get('newsBoardRoles').valueChanges.pipe(distinctUntilChanged()).subscribe(roles => {
      this.store$.dispatch(fromUser.setNewsboardUserRoles({roles: roles}))
    });

    (this.form.get('rocheHomeRoles') as FormGroup).controls.isAudienceManager.valueChanges.subscribe(isAudienceManager => {
      if (isAudienceManager) {
        this.store$.dispatch(fromUser.setUserManagedTaxonomies({managedTaxonomy: this.initialManagedTaxonomy}))
      } else {
        this.store$.dispatch(fromUser.setUserManagedTaxonomies({
          managedTaxonomy: {
            locations: [],
            departments: [],
            functions: []
          }
        }));
      }
    })
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  constructor(private nbAuth: NewsboardAuthService, private store$: Store<fromUser.State>) {
  }


}
