// import { createAction, props } from '@ngrx/store';

import { Pagination } from '@app/core/models';
import { ApiKey } from '@app/core/models/newsboard';
import { createAction, props } from '@ngrx/store';
import { SortDirection } from '@rds/angular-components';

export const initApiKeys = createAction(
  '[API KEYS] Initialize News'
);

export const clearAll = createAction(
  '[API KEYS] Clear all'
);

export const getApiKeysRequest = createAction(
  '[API KEYS] Get api key Request',
  props<{
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
  }>()
);

export const getApiKeysSuccess = createAction(
  '[API KEYS] Get api key Success',
  props<{ data: Array<Partial<ApiKey>>, pagination: Pagination }>()
);

export const getApiKeysFailure = createAction(
  '[API KEYS] Get api key Failure',
  props<{ error: any }>()
);

export const deleteApiKeysRequest = createAction(
  '[API KEYS] Delete api key Request',
  props<{ids: Array<number>}>()
);

export const deleteApiKeysSuccess = createAction(
  '[API KEYS] Delete api key Success',
  props<{count: number}>()
);

export const deleteApiKeysFailure = createAction(
  '[API KEYS] Delete api key Failure',
  props<{ error: any }>()
);

export const addApiKeysRequest = createAction(
  '[API KEYS] Add api key Request',
  props<{identifier: string}>()
);

export const addApiKeysSuccess = createAction(
  '[API KEYS] Add api key Success',
);

export const addApiKeysFailure = createAction(
  '[API KEYS] Add api key Failure',
  props<{ error: any }>()
);

export const changeApiKeysSort = createAction(
  '[API KEYS] Change api key sort',
  props<{ active: string, direction: SortDirection }>()
);

export const changeApiKeysPagination = createAction(
  '[API KEYS] Change api key pagination',
  props<{ pageIndex: number, pageSize: number }>()
);

export const apiKeyCopiedToClipboard = createAction(
  '[API KEYS] Api key copied to clipboard',
);