import { Block, BlockTypes } from '@app/newsletter/inline-editor/models/block-type.enum';
import { Section, SectionTypes } from '@app/newsletter/inline-editor/models/section-type.enum';
import { createAction, props } from '@ngrx/store';

export const addSection = createAction(
  '[NEWSLETTER BLOCKS] Add section',
  props<{ section: Section }>()
);

export const toggleBorders = createAction(
  '[NEWSLETTER BLOCKS] Toggle borders'
);

export const hoverBlockType = createAction(
  '[NEWSLETTER BLOCKS] Hover block drag',
  props<{ blockType: BlockTypes }>()
);

export const unhoverBlockType = createAction(
  '[NEWSLETTER BLOCKS] Unhover block drag'
);

export const startBlockDrag = createAction(
  '[NEWSLETTER BLOCKS] Start block drag',
  props<{ blockType: BlockTypes }>()
);

export const endBlockDrag = createAction(
  '[NEWSLETTER BLOCKS] End block drag'
);

export const createSection = createAction(
  '[NEWSLETTER BLOCKS] Create section',
  props<{ sectionType: SectionTypes }>()
);

export const insertSection = createAction(
  '[NEWSLETTER BLOCKS] Insert section',
  props<{ section: Section, index: number }>()
);

export const removeSection = createAction(
  '[NEWSLETTER BLOCKS] Remove section by id',
  props<{ id: string }>()
);

export const duplicateSection = createAction(
  '[NEWSLETTER BLOCKS] Duplicate section by id',
  props<{ id: string }>()
);

export const reorderSection = createAction(
  '[NEWSLETTER BLOCKS] Reorder section',
  props<{ oldId: number, newId: number }>()
);

export const createBlock = createAction(
  '[NEWSLETTER BLOCKS] Create block',
  props<{
    blockType: BlockTypes,
    subsectionGuid: string,
    id: number
  }>()
);

export const addBlock = createAction(
  '[NEWSLETTER BLOCKS] Add block',
  props<{
    block: Block,
    subsectionGuid: string,
    id: number
  }>()
);

export const addNewsBlock = createAction(
  '[NEWSLETTER BLOCKS] Add news block',
  props<{
    channelId: string,
    newsId: string,
    blockType: BlockTypes,
    subsectionGuid: string,
    id: number
  }>()
);

export const reorderBlock = createAction(
  '[NEWSLETTER BLOCKS] Reorder block',
  props<{
    subsectionGuid: string,
    oldId: number,
    newId: number}>()
);

export const moveBlock = createAction(
  '[NEWSLETTER BLOCKS] Move block between sections',
  props<{
    oldSubsectionGuid: string,
    newSubsectionGuid: string,
    oldId: number,
    newId: number}>()
);

export const removeBlock = createAction(
  '[NEWSLETTER BLOCKS] Remove block',
  props<{
    subsectionGuid: string,
    blockGuid: string}>()
);

export const duplicateBlock = createAction(
  '[NEWSLETTER BLOCKS] Duplicate block',
  props<{
    subsectionGuid: string,
    blockGuid: string,
    blockType: BlockTypes}>()
);

export const updateBlockMarkup = createAction(
  '[NEWSLETTER BLOCKS] Update block markup',
  props<{
    blockGuid: string, markup: string}>()
);

export const updateNewsBlockMarkup = createAction(
  '[NEWSLETTER BLOCKS] Update news block markup',
  props<{
    blockGuid: string, flipped: boolean; card?: boolean, channelId: string; newsId: string}>()
);

export const uploadBlockPhotoRequest = createAction(
  '[NEWSLETTER - BLOCKS] Upload block photo request',
  props<{file: File, blockGuid: string, link?: string}>()
);

export const uploadBlockPhotoSuccess = createAction(
  '[NEWSLETTER - BLOCKS] Upload block photo success',
  props<{photoUrl: string, blockGuid: string, link?: string}>()
);

export const uploadBlockPhotoFailure = createAction(
  '[NEWSLETTER - BLOCKS] Upload block photo response',
  props<{message: any}>()
);

export const clearData = createAction(
  '[NEWSLETTER - BLOCKS] Clear data',
);

export const setSections = createAction(
  '[NEWSLETTER - BLOCKS] Set sections',
  props<{sections: Array<Section>}>()
);

export const openSelectNewsDialog = createAction(
  '[NEWSLETTER - BLOCKS] Open select news modal',
  props<{
    blockType: BlockTypes,
    subsectionGuid: string,
    id: number
  }>()
);

export const selectNewsDialogClosed = createAction(
  '[NEWSLETTER - BLOCKS] Select news modal closed',
  props<{
    blockType: BlockTypes,
    subsectionGuid: string,
    id: number
  }>()
);
