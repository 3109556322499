import { Injectable } from '@angular/core';

@Injectable()
export class UtilsService {

  public addChildrenIdsAndParentNames<T>(options: Array<T>, idKey, parentIdKey, nameKey, parentNameKey, nestKey): Array<any> {
    return options.map(option => {
      const childrenIds = options.filter(o => o[parentIdKey] === option[idKey]).map(o => o[idKey]);
      const parent = options.find(o => o[idKey] === option[parentIdKey]);

      return {
        [idKey]: option[idKey],
        [parentIdKey]: option[parentIdKey],
        [nestKey]: childrenIds,
        [nameKey]: option[nameKey],
        [parentNameKey]: parent ? parent[nameKey] : null
      };
    });
  }

  public markChildWithParentIdName<T>(options: Array<T>, nested, id, name, parentId?, parentName?): Array<T> {
    const arr = (Array.isArray(options)) ? options : [options];
    return arr.reduce((acc, value: any) => {
      value.parentId = parentId || null;
      value.parentName = parentName || null;
      if (value[nested]) {
        acc.push({
          ...value,
          [nested]: [
            ...this.markChildWithParentIdName(value[nested], nested, id, name, value[id], value[name]),
          ]
        });
      } else {
        acc.push(value);
      }
      return acc;
    }, []);
  }

  public removeInActiveValues<T>(options: Array<T>, nested): Array<T> {
    const arr = (Array.isArray(options)) ? options : [options];
    return arr.reduce((acc, value: any) => {
      if (value.isActive === false) {
        return acc;
      }
      if (value[nested]) {
        acc.push({
          ...value,
          [nested]: [
            ...this.removeInActiveValues(value[nested], nested),
          ]
        });
      } else {
        acc.push(value);
      }
      return acc;
    }, []);
  }

  public removeInEmptyChildren<T>(options: Array<T>, nested): Array<T> {
    const arr = (Array.isArray(options)) ? options : [options];
    return arr.reduce((acc, value: any) => {
      if (value[nested] && value[nested].length === 0) {
        value[nested] = null;
      }
      if (value[nested]) {
        acc.push({
          ...value,
          [nested]: [
            ...this.removeInActiveValues(value[nested], nested),
          ]
        });
      } else {
        acc.push(value);
      }
      return acc;
    }, []);
  }

  public removeNonExistingChildren<T>(options: Array<T>, key, nestKey): Array<T> {
    const dict = options.reduce((acc, val) => {
      acc[val[key]] = true;
      return acc;
    }, {});
    return options.map(option => ({
      ...option,
      [nestKey]: option[nestKey].filter(childId => dict[childId])
    }));
  }

  constructor() {}
}


export function toDateString(date: Date, time: string): string {
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');
  const hour = time.split(':')[0];
  const minute = time.split(':')[1];
  return `${year}-${month}-${day}T${hour}:${minute}:00`;
}