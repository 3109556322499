// import { createAction, props } from '@ngrx/store';

import { Pagination } from '@app/core/models';
import { Channel } from '@app/core/models/newsboard';
import { BackButton } from '@app/root-store/ui/back-button';
import { EventTableFilters } from '@app/shared/filters/models/events-table';
import { createAction, props } from '@ngrx/store';
import { SortDirection } from '@rds/angular-components';

export const initMyEvents = createAction(
  '[EVENTS - MY TABLE] Initialize Channels'
);

export const initMyPastEvents = createAction(
  '[EVENTS - MY PAST EVENTS] Initialize Channels'
);

export const clearAll = createAction(
  '[CHANNEL] Clear all'
);

export const getMyEventsRequest = createAction(
  '[EVENTS - MY TABLE] Get events Request',
  props<{
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
    filters: EventTableFilters,
  }>()
);

export const getMyEventsSuccess = createAction(
  '[EVENTS - MY TABLE] Get events Success',
  props<{ data: Array<Partial<Channel>>, pagination: Pagination }>()
);

export const getMyEventsFailure = createAction(
  '[EVENTS - MY TABLE] Get events Failure',
  props<{ error: any }>()
);


export const deleteMyEventsRequest = createAction(
  '[EVENTS - MY TABLE] Delete events Request',
  props<{ids: Array<number>}>()
);

export const deleteMyEventsSuccess = createAction(
  '[EVENTS - MY TABLE] Delete events Success',
  props<{count: number}>()
);

export const deleteMyEventsFailure = createAction(
  '[EVENTS - MY TABLE] Delete events Failure',
  props<{ error: any }>()
);

export const changeMyEventsFilters = createAction(
  '[EVENTS - MY TABLE] Change events filters',
  props<{ filters: EventTableFilters }>()
);

export const changeMyEventsSort = createAction(
  '[EVENTS - MY TABLE] Change events sort',
  props<{ active: string, direction: SortDirection }>()
);

export const changeMyEventsPagination = createAction(
  '[EVENTS - MY TABLE] Change events pagination',
  props<{ pageIndex: number, pageSize: number }>()
);


export const getMyPastEventsRequest = createAction(
  '[EVENTS - MY PAST EVENTS] Get events Request',
  props<{
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
    filters: EventTableFilters
  }>()
);

export const getMyPastEventsSuccess = createAction(
  '[EVENTS - MY PAST EVENTS] Get events Success',
  props<{ data: Array<Partial<Channel>>, pagination: Pagination }>()
);

export const getMyPastEventsFailure = createAction(
  '[EVENTS - MY PAST EVENTS] Get events Failure',
  props<{ error: any }>()
);


export const deleteMyPastEventsRequest = createAction(
  '[EVENTS - MY PAST EVENTS] Delete events Request',
  props<{ids: Array<number>}>()
);

export const deleteMyPastEventsSuccess = createAction(
  '[EVENTS - MY PAST EVENTS] Delete events Success',
  props<{count: number}>()
);

export const deleteMyPastEventsFailure = createAction(
  '[EVENTS - MY PAST EVENTS] Delete events Failure',
  props<{ error: any }>()
);

export const changeMyPastEventsFilters = createAction(
  '[EVENTS - MY PAST EVENTS] Change events filters',
  props<{ filters: EventTableFilters }>()
);

export const changeMyPastEventsSort = createAction(
  '[EVENTS - MY PAST EVENTS] Change events sort',
  props<{ active: string, direction: SortDirection }>()
);

export const changeMyPastEventsPagination = createAction(
  '[EVENTS - MY PAST EVENTS] Change events pagination',
  props<{ pageIndex: number, pageSize: number }>()
);

export const openEventPreview = createAction(
  '[EVENTS] Open event preview',
  props<{ id: number, backButton: BackButton}>()
);