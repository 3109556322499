import { on, Action, createReducer } from '@ngrx/store';
import * as fromActions from './searching-preferences.actions';
import { Audience } from '@app/core/models';

export interface State {
  form: Audience;
  initialForm: Audience;
  
}

export const initialState: State = {
  form: Audience,
  initialForm: Audience
  ,
};

export const searchingPreferencesReducer = createReducer(
  initialState,
  on(fromActions.setInitialValue, (state, {form}) => ({
    ...state,
    form,
    initialForm: form
  })),
  on(fromActions.setFormValue, (state, {form}) => ({
    ...state,
    form,
  })),
  on(
    fromActions.savePersonalizationPreferencesSuccess,
    fromActions.resetPersonalizationPreferencesSuccess, (state, {personalizationPreferences}) => ({
    ...state,
    form: personalizationPreferences,
    initialForm: personalizationPreferences
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return searchingPreferencesReducer(state, action);
}

export const getForm = (state: State) => state.form;
export const getInitialForm = (state: State) => state.initialForm;
