import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromReducer from './form.reducer';
import * as fromActions from './form.actions';
import * as fromSelectors from './form.selectors';
import * as fromRouter from '@app/root-store/router';
import * as fromDetails from '@app/newsboard/channel/store/channel-details';
import * as fromBackButton from '@app/root-store/ui/back-button';

import { debounceTime, distinctUntilChanged, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs';
import { ChannelService } from '@app/newsboard/channel/channel.service';

@Injectable()
export class FormEffects {


  public saveSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.createChannelSuccess,
        fromActions.updateChannelSuccess,
      ),
      mergeMap(() => [
        fromActions.setFormTouched({ touched: false }),
        fromBackButton.back({ defaultLabel: 'Channels', defaultRoute: 'channels' })
      ]),
    ), { dispatch: true }
  );

  public createChannelRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createChannelRequest),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectFormForRequest))),
      switchMap(([action, form]) => this.channelService.create(form).pipe(
        map((id) => ({ form, id }))
      )),
      mergeMap(({ form, id }) => [
        fromActions.createChannelSuccess(),
      ])
    ), { dispatch: true }
  );

  public updateChannelRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateChannelRequest),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectFormForRequest))),
      switchMap(([action, form]) => this.channelService.update(form.id, form).pipe(
        map((id) => ({ form, id }))
      )),
      mergeMap(({ form, id }) => [
        fromActions.updateChannelSuccess(),
        fromDetails.clearAll(),
      ])
    ), { dispatch: true }
  );

  public checkNameUnique$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setFormValue),
      debounceTime(300),
      filter(({ form }) => !!form.name),
      distinctUntilChanged((prev, next) => prev.form.name === next.form.name),
      map(({ form }) => fromActions.isNameUniqueRequest({ name: form.name, id: form.id }))
    ))

  public isNameUniqueRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.isNameUniqueRequest),
      switchMap(({ name, id }) => this.channelService.checkName(name, id).pipe(
        map(({ isUnique }) => ({ isUnique }))
      )),
      map(({ isUnique }) => fromActions.isNameUniqueSuccess({ isUnique }))
    ), { dispatch: true }
  );

  public deleteChannelRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteChannelRequest),
      switchMap(({ ids }) => this.channelService.delete(ids).pipe(
        map(() => ({ ids }))
      )),
      mergeMap(({ ids }) => [
        fromActions.deleteChannelSuccess({ count: ids.length }),
        fromRouter.go({ path: `channels`, queryParams: {} }),
        fromDetails.clearAll(),
        fromActions.clearAll()
      ])
    ), { dispatch: true }
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private channelService: ChannelService
  ) { }

}
