import {ExpressLink, People} from '@app/layout/header/models';
import {Action, createReducer, on} from '@ngrx/store';
import * as fromActions from '../header/header.actions';
import {HeaderLinkNode} from '@app/layout/header/components/dummy-links';

export interface State {
  phrase: string;
  suggestionPhrase: string;
  suggestionPanelCondition: {
    inputFocused: boolean;
    ignoreFocus: boolean;
    phraseLength: number;
    suggestionsLength: number;
    suggestionsLoading: boolean;
    somethingChanged: number;
  };
  // gcs: string;
  // menuOpened: boolean;
  // menuMobileOpened: boolean;
  expressLinks: ExpressLink[];
  expressLinksLoading: boolean;
  expressLinksAreTouched: boolean;
  people: People[];
  peopleLoading: boolean;
  peopleAreTouched: boolean;
  autocompletes: string[];
  autocompletesLoading: boolean;
  autocompletesAreTouched: boolean;
  // isMobileInputOpened: boolean;
  nodes: HeaderLinkNode[];
  forceClose: boolean;
  headerVisible: boolean;
  defaultUrls: {[key: string]: string},
  shortcuts: Array<{
    path: string,
    title: string,
    icon: string
  }>;
}

export const initialState: State = {
  // menuOpened: false,
  // menuMobileOpened: false,
  phrase: '',
  suggestionPhrase: '',
  suggestionPanelCondition: {
    inputFocused: false,
    ignoreFocus: false,
    phraseLength: 0,
    suggestionsLength: 0,
    suggestionsLoading: false,
    somethingChanged: 0
  },
  // gcs: '',
  expressLinks: [],
  expressLinksLoading: false,
  expressLinksAreTouched: false,
  people: [],
  peopleLoading: false,
  peopleAreTouched: false,
  autocompletes: [],
  autocompletesLoading: false,
  autocompletesAreTouched: false,
  // isMobileInputOpened: false
  nodes: [],
  forceClose: false,
  headerVisible: true,
  defaultUrls: {},
  shortcuts: []
};

export const headerReducer = createReducer(
  initialState,
  on(fromActions.setPhrase, (state, {phrase}) => ({
    ...state,
    phrase,
    suggestionPhrase: phrase,
    suggestionPanelCondition: {
      ...state.suggestionPanelCondition,
      somethingChanged: state.suggestionPanelCondition.somethingChanged++,
      phraseLength: phrase.length
    }
  })),
  on(fromActions.forceClose, (state, {close}) => ({
    ...state,
    forceClose: close
  })),
  on(fromActions.setHeaderVisible, (state, {visible}) => ({
    ...state,
    headerVisible: visible
  })),
  on(fromActions.setSuggestionPhrase, (state, {phrase}) => ({
    ...state,
    suggestionPhrase: phrase,
    suggestionPanelCondition: {
      ...state.suggestionPanelCondition,
      somethingChanged: state.suggestionPanelCondition.somethingChanged++,
      phraseLength: phrase.length
    }
  })),
  on(fromActions.setSuggestionPhrase, (state, {phrase}) => ({
    ...state,
    phrase
  })),
  on(fromActions.getExpressLinks, (state) => ({
    ...state,
    expressLinksLoading: true,
    suggestionPanelCondition: {
      ...state.suggestionPanelCondition,
      somethingChanged: state.suggestionPanelCondition.somethingChanged++,
      suggestionsLoading: true || state.peopleLoading || state.autocompletesLoading
    }
  })),
  on(fromActions.getExpressLinksSuccess, (state, {expressLinks}) => ({
    ...state,
    expressLinksLoading: false,
    expressLinksAreTouched: true,
    expressLinks,
    suggestionPanelCondition: {
      ...state.suggestionPanelCondition,
      somethingChanged: state.suggestionPanelCondition.somethingChanged++,
      suggestionsLoading: false || state.peopleLoading || state.autocompletesLoading,
      suggestionsLength: expressLinks.length + state.people.length + state.autocompletes.length
    }
  })),
  on(fromActions.getExpressLinksFailure, (state) => ({
    ...state,
    expressLinksLoading: false,
    expressLinksAreTouched: true,
    suggestionPanelCondition: {
      ...state.suggestionPanelCondition,
      somethingChanged: state.suggestionPanelCondition.somethingChanged++,
      suggestionsLoading: false || state.peopleLoading || state.autocompletesLoading,
      suggestionsLength: 0 + state.people.length + state.autocompletes.length
    }
  })),
  on(fromActions.getAutocompletes, (state) => ({
    ...state,
    autocompletesLoading: true,
    suggestionPanelCondition: {
      ...state.suggestionPanelCondition,
      somethingChanged: state.suggestionPanelCondition.somethingChanged++,
      suggestionsLoading: true || state.peopleLoading || state.expressLinksLoading
    }
  })),
  on(fromActions.getAutocompletesSuccess, (state, {autocompletes}) => ({
    ...state,
    autocompletes: autocompletes.suggestions,
    autocompletesLoading: false,
    autocompletesAreTouched: true,
    suggestionPanelCondition: {
      ...state.suggestionPanelCondition,
      somethingChanged: state.suggestionPanelCondition.somethingChanged++,
      suggestionsLoading: false || state.peopleLoading || state.expressLinksLoading,
      suggestionsLength: autocompletes.suggestions.length + state.people.length + state.expressLinks.length
    }
  })),
  on(fromActions.getAutocompletesFailure, (state) => ({
    ...state,
    autocompletesLoading: false,
    autocompletesAreTouched: true,
    suggestionPanelCondition: {
      ...state.suggestionPanelCondition,
      somethingChanged: state.suggestionPanelCondition.somethingChanged++,
      suggestionsLoading: false || state.peopleLoading || state.expressLinksLoading,
      suggestionsLength: 0 + state.people.length + state.expressLinks.length
    }
  })),
  on(fromActions.getPeople, (state) => ({
    ...state,
    peopleLoading: true,
    suggestionPanelCondition: {
      ...state.suggestionPanelCondition,
      somethingChanged: state.suggestionPanelCondition.somethingChanged++,
      suggestionsLoading: true || state.expressLinksLoading || state.autocompletesLoading
    }
  })),
  on(fromActions.getPeopleSuccess, (state, {people}) => ({
    ...state,
    people,
    peopleLoading: false,
    peopleAreTouched: true,
    suggestionPanelCondition: {
      ...state.suggestionPanelCondition,
      somethingChanged: state.suggestionPanelCondition.somethingChanged++,
      suggestionsLoading: false || state.peopleLoading || state.expressLinksLoading,
      suggestionsLength: people.length + state.autocompletes.length + state.expressLinks.length
    }
  })),
  on(fromActions.getPeopleFailure, (state) => ({
    ...state,
    peopleLoading: false,
    peopleAreTouched: true,
    suggestionPanelCondition: {
      ...state.suggestionPanelCondition,
      somethingChanged: state.suggestionPanelCondition.somethingChanged++,
      suggestionsLoading: false || state.peopleLoading || state.expressLinksLoading,
      suggestionsLength: 0 + state.autocompletes.length + state.expressLinks.length
    }
  })),
  on(fromActions.getConfigurationLinksSuccess, (state, {nodes}) => ({
    ...state,
    nodes
  })),
  on(fromActions.setDefaultUrls, (state, {urls}) => ({
    ...state,
    defaultUrls: urls
  })),
  on(fromActions.searchFocused, (state) => ({
    ...state,
    suggestionPanelCondition: {
      ...state.suggestionPanelCondition,
      somethingChanged: state.suggestionPanelCondition.somethingChanged++,
      inputFocused: true,
      ignoreFocus: false
    }
  })),
  on(fromActions.searchBlurred, (state) => ({
    ...state,
    suggestionPanelCondition: {
      ...state.suggestionPanelCondition,
      somethingChanged: state.suggestionPanelCondition.somethingChanged++,
      inputFocused: false,
      ignoreFocus: true
    }
  })),
  on(fromActions.goToSearch, (state) => ({
    ...state,
    suggestionPanelCondition: {
      ...state.suggestionPanelCondition,
      somethingChanged: state.suggestionPanelCondition.somethingChanged++,
      inputFocused: false,
      ignoreFocus: false
    }
  })),
  on(fromActions.clearSuggestions, (state) => ({
    ...state,
    suggestionPhrase: '',
    suggestionPanelCondition: {
      inputFocused: false,
      ignoreFocus: false,
      phraseLength: 0,
      suggestionsLength: 0,
      suggestionsLoading: false,
      somethingChanged: 0
    },
    expressLinks: [],
    expressLinksLoading: false,
    expressLinksAreTouched: false,
    people: [],
    peopleLoading: false,
    peopleAreTouched: false,
    autocompletes: [],
    autocompletesLoading: false,
    autocompletesAreTouched: false,
  })),
  on(fromActions.shortcuts.add, (state, {path, title, icon}) => ({
    ...state,
    shortcuts: [...state.shortcuts, {path, title, icon}]
  })),
  on(fromActions.shortcuts.remove, (state, {path, title, icon}) => ({
    ...state,
    shortcuts: state.shortcuts.filter(s => s.title !== title).slice()
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return headerReducer(state, action);
}

export const getPhrase = (state: State) => state.phrase;
export const getSuggestionPhrase = (state: State) => state.suggestionPhrase;
export const getSuggestionPanelCondition = (state: State) => state.suggestionPanelCondition;
export const getExpressLinksItems = (state: State) => state.expressLinks;
export const getExpressLinksLoading = (state: State) => state.expressLinksLoading;
export const getExpressLinksAreTouched = (state: State) => state.expressLinksAreTouched;
export const getAutocompletesItems = (state: State) => state.autocompletes;
export const getAutocompletesLoading = (state: State) => state.autocompletesLoading;
export const getAutocompletesAreTouched = (state: State) => state.autocompletesAreTouched;
export const getPeopleItems = (state: State) => state.people;
export const getPeopleLoading = (state: State) => state.peopleLoading;
export const getPeopleAreTouched = (state: State) => state.peopleAreTouched;
export const getNodes = (state: State) => state.nodes;
export const getForceClose = (state: State) => state.forceClose;
export const getShortcuts = (state: State) => state.shortcuts;

export const getDefaultLinks = (state: State) => state.defaultUrls;
export const getWorkspaceGuideLink = (urls: {[key: string]: string}) => urls['workspaceGuide'] || 'https://sites.google.com/roche.com/newsboardguide/my-workspace';
export const getNotificationGuideLink = (urls: {[key: string]: string}) => urls['notificationGuide'] || 'https://home.roche.com/content/news:any:86266';
