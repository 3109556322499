/// <reference types="google.accounts" />

import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import * as fromAuth from '../store';
@Injectable()
export class GoogleService {
  GISCodeClient!: google.accounts.oauth2.CodeClient;

  authMode: Partial<google.accounts.oauth2.CodeClientConfig> = this.iOS() 
  ?
  {
      ux_mode: 'redirect',
      redirect_uri: `${environment.url}/google-auth`,
      callback: (response) => {
        this.store$.dispatch(fromAuth.getAuthorizationCodeSuccess({code: response.code, scope: response.scope, redirectUri: 'postmessage'}));
      },
    }
    :
    {
      ux_mode: 'popup',
      callback: (response) => {
        this.store$.dispatch(fromAuth.getAuthorizationCodeSuccess({code: response.code, scope: response.scope, redirectUri: 'postmessage'}));
      },
    }
  getAuthorizationCode() {
    this.GISCodeClient.requestCode();
  }

  onGISLoaded() {
    this.store$.dispatch(fromAuth.GISLibLoaded());
  }

  onGapiLoaded() {
    this.store$.dispatch(fromAuth.GapiLibLoaded());
  }

  addCloudSearchPermissions() {
    const gisClient = google.accounts.oauth2.initCodeClient({
      client_id: environment.googleClientId,
      scope: 'https://www.googleapis.com/auth/cloud_search',

      hint: 'user_email',
      enable_serial_consent: true,
      
      error_callback: (error) => {
        // this.store$.dispatch(fromAuth.getAuthorizationCodeFailure({error}));
      },
      ...this.authMode
    });
    gisClient.requestCode();
  }

  addCalendarPermissions() {
    const gisClient = google.accounts.oauth2.initCodeClient({
      client_id: environment.googleClientId,
      scope: 'https://www.googleapis.com/auth/calendar.events',

      hint: 'user_email',
      enable_serial_consent: true,
      
      error_callback: (error) => {
        // this.store$.dispatch(fromAuth.getAuthorizationCodeFailure({error}));
      },
      ...this.authMode

    });
    gisClient.requestCode();
  }

  initialize() {
    google.accounts.id.initialize({
      client_id: environment.googleClientId,
      auto_select: true,
      callback: (credentials) => {
        this.store$.dispatch(fromAuth.googleAuthenticationCompleted({credentials}));
      }
    })
    this.GISCodeClient = google.accounts.oauth2.initCodeClient({
      client_id: environment.googleClientId,
      scope: 'https://www.googleapis.com/auth/cloud_search https://www.googleapis.com/auth/calendar.events profile email',

      hint: 'user_email',
      enable_serial_consent: true,
      
      error_callback: (error) => {
        this.store$.dispatch(fromAuth.getAuthorizationCodeFailure({error}));
      },
      ...this.authMode
    })
    this.store$.dispatch(fromAuth.GISClientsInitialized())
  }

  oneTap() {
    google.accounts.id.prompt((event) => this.store$.dispatch(fromAuth.googleOneTapPromptEvent({event})));
  }

  signIn() {
    const btn = document.getElementById('gbtn')
    google.accounts.id.renderButton(
      btn as HTMLElement,
      {
        type:  'standard',
        shape: 'rectangular',
        theme: 'outline',
        text: 'signin_with',
        size:  'large',
        logo_alignment: 'left'
      }
    )
  }

  loadGoogleLibs() {
    this.loadGIS();
    this.loadGapi();
  }

  loadGIS() {
    const gis: HTMLScriptElement = document.createElement('script');
    gis.type = 'text/javascript';
    gis.src ='https://accounts.google.com/gsi/client';
    gis.defer = true;
    gis.referrerPolicy = this.iOS() ? 'strict-origin-when-cross-origin' : 'no-referrer';
    gis.onload = this.onGISLoaded.bind(this);
    document.getElementsByTagName('head')[0].appendChild(gis);
   }

    loadGapi() {
    const gapi = document.createElement('script');
    gapi.type = 'text/javascript';
    gapi.src ='https://apis.google.com/js/api.js';
    gapi.referrerPolicy = this.iOS() ? 'strict-origin-when-cross-origin' : 'no-referrer';
    gapi.defer = true;
    gapi.onload = this.onGapiLoaded.bind(this);
    document.getElementsByTagName('head')[0].appendChild(gapi);
   }

  constructor(private store$: Store<any>) {}

  iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }
}
