import {Action, combineReducers, createFeatureSelector} from '@ngrx/store';
import * as fromCalendar from '@app/events/store/calendar/calendar.reducer';
import * as fromDetails from '@app/events/store/details/details.reducer';
import * as fromFilters from '@app/events/store/filters/filters.reducer';
import * as fromGcal from '@app/events/store/gcal/gcal.reducer';
import * as fromGrid from '@app/events/store/grid/grid.reducer';
import * as fromTop from '@app/events/store/top/top.reducer';

export const feature = 'event';

export interface State {
  calendar: fromCalendar.State;
  details: fromDetails.State;
  filters: fromFilters.State;
  gcal: fromGcal.State;
  grid: fromGrid.State;
  top: fromTop.State;
}

export function reducer(state: State | undefined, action: Action) {
  return combineReducers({
    calendar: fromCalendar.reducer,
    details: fromDetails.reducer,
    filters: fromFilters.reducer,
    gcal: fromGcal.reducer,
    grid: fromGrid.reducer,
    top: fromTop.reducer,
  })(state, action);
}

export const selectState = createFeatureSelector<State>(feature);

export const getCalendarState = (state: State) => state.calendar;
export const getDetailsState = (state: State) => state.details;
export const getFiltersState = (state: State) => state.filters;
export const getGcalState = (state: State) => state.gcal;
export const getGridState = (state: State) => state.grid;
export const getTopState = (state: State) => state.top;
