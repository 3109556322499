import { Component, Inject } from '@angular/core';
import { AlertModel } from '@app/core/models';
import { RDS_DIALOG_DATA, RdsDialogRef } from '@rds/angular-components';

export interface AlertRecievedDialogData  {
  alert: AlertModel;
}

@Component({
  selector: 'rh-alert-recieved-dialog',
  templateUrl: './alert-recieved-dialog.component.html',
  styleUrls: ['./alert-recieved-dialog.component.scss'],
})
export class AlertRecievedDialogComponent {
  alert: AlertModel;

  constructor(
    private dialogRef: RdsDialogRef<AlertRecievedDialogComponent>,
    @Inject(RDS_DIALOG_DATA) data: AlertRecievedDialogData
  ) {
    this.alert = data.alert;
  }

  close(status?): void {
    this.dialogRef.close({status});
  }

  gotoLink(): void {
    window.open(this.alert.url, '_blank');
  }

}
