import { AudienceApproval, CatalogPermissions, SingleAudience } from '@app/core/models';
import { CatalogEntryForm, EMPTY_CATALOG_FORM } from '@core/models/catalog.model';
import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import * as fromActions from './catalog-entry.actions';
import { ApprovalDetails } from '@app/core/models/approval-details';

export const feature = 'catalogEntry';

export interface State {
  form: CatalogEntryForm;
  initialForm: CatalogEntryForm;
  isNameUnique: boolean;
  contentManagerTaxonomy: SingleAudience;
  catalogPermissions: CatalogPermissions;
  approvalDetails: ApprovalDetails;
  audienceApproval: AudienceApproval;
  touched: boolean
  invalidManager: boolean;
  invalidOwner: boolean;
}

export const initialState: State = {
  form: EMPTY_CATALOG_FORM,
  initialForm: EMPTY_CATALOG_FORM,
  isNameUnique: true,
  contentManagerTaxonomy: SingleAudience,
  catalogPermissions: null,
  approvalDetails: null,
  audienceApproval: null,
  touched: false,
  invalidManager: false,
  invalidOwner: false,
};

export const catalogEntryReducer = createReducer(
  initialState,
  on(fromActions.initAddForm, (state, {
    catalog,
    contentManagerTaxonomy,
    catalogPermissions,
    approvalDetails,
    audienceApproval
  }) => ({
    ...state,
    form: catalog,
    initialForm: catalog,
    contentManagerTaxonomy,
    catalogPermissions,
    approvalDetails,
    audienceApproval
  })),
  on(fromActions.setFormValue, (state, { form }) => ({
    ...state,
    form: {
      ...state.form,
      ...form
    }
  })),
  on(fromActions.setFormTouched, (state, { touched }) => ({
    ...state,
    touched
  })),
  on(fromActions.setInvalidManager, (state, { invalid }) => ({
    ...state,
    invalidManager: invalid
  })),
  on(fromActions.setInvalidOwner, (state, { invalid }) => ({
    ...state,
    invalidOwner: invalid
  })),
  on(fromActions.isNameUniqueSuccess, (state, {isUnique}) => ({
    ...state,
    isNameUnique: isUnique
  })),
  on(fromActions.getContentManagerTaxonomySuccess, (state, {contentManagerTaxonomy}) => ({
    ...state,
    form: {
      ...state.form,
      contentFromLocation: contentManagerTaxonomy.location,
      contentFromOrganization: contentManagerTaxonomy.department
    },
    contentManagerTaxonomy
  })),
  on(fromActions.clearAll, (state) => ({
    ...initialState
  }))
    // on(fromActions.getUserTaxonomiesRequest, (state, { login }) => ({
    //   ...state,
    //   contentManagerDefTax: {
    //     ...STATUS_LOADING,
    //     ...state.contentManagerDefTax,
    //     login
    //   }
    // })),
    // on(fromActions.getUserTaxonomiesSuccess, (state, dfTxms) => ({
    //   ...state,
    //   contentManagerDefTax: {
    //     ...STATUS_SUCCESS,
    //     ...state.contentManagerDefTax,
    //     function: dfTxms.function,
    //     department: dfTxms.department,
    //     location: dfTxms.location
    //   }
    // })),
    // on(fromActions.getUserTaxonomiesFailure, (state, { error }) => ({
    //   ...state,
    //   contentManagerDefTax: {
    //     ...STATUS_FAILURE,
    //     ...state.contentManagerDefTax,
    //   }
    // })),

);

export function reducer(state: State | undefined, action: Action) {
  return catalogEntryReducer(state, action);
}

export const selectState = createFeatureSelector<State>(feature);


export const getForm = (state: State) => state.form;
export const getInitialForm = (state: State) => state.initialForm;
export const getId = (form: Partial<CatalogEntryForm>) => form.id;
export const getStatus = (form: Partial<CatalogEntryForm>) => form.status?.statusId;
export const getTouched = (state: State) => state.touched;
export const getIsNameUnique = (state: State) => state.isNameUnique;

export const getInvalidManager = (state: State) => state.invalidManager;
export const getInvalidOwner = (state: State) => state.invalidOwner;

export const getContentManagerTaxonomy = (state: State) => state.contentManagerTaxonomy;

export const getCatalogPermissions = (state: State) => state.catalogPermissions;
export const getCanDeletePermission = (permissions: CatalogPermissions) => permissions.canDelete;
export const getCanEditPermission = (permissions: CatalogPermissions) => permissions.canEdit;
export const getCanPublishPermission = (permissions: CatalogPermissions) => permissions.canPublish;
export const getCanUnpublishPermission = (permissions: CatalogPermissions) => permissions.canUnpublish;

export const getApprovalDetails = (state: State) => state.approvalDetails;

export const getAudienceApproval = (state: State) => state.audienceApproval;
export const getLocationsApproval = (approval: AudienceApproval) => approval?.locations;
export const getDepartmentsApproval = (approval: AudienceApproval) => approval?.departments;
export const getFunctionsApproval = (approval: AudienceApproval) => approval?.functions;


// export const getNotAsked = (state: State) => state.notAsked;
// export const getLoading = (state: State) => state.loading;
// export const getSuccess = (state: State) => state.success;
// export const getFailure = (state: State) => state.failure;

// export const getKeywordsSug = (state: State) => state.suggestions.keywordsSearched;
// export const getContentManagerSug = (state: State) => state.suggestions.contentManager;
// export const getContentOwnerSug = (state: State) => state.suggestions.contentOwner;
// export const getCatalogForm = (state: State) => state.catalogForm;
// export const getNameUniq = (state: State) => state.nameUniq;
// export const getContentManagerDefTax = (state: State) => state.contentManagerDefTax;

// export const getIsNotUniqueName = (state: NameUniq) => state.isNotUnique;
// export const getLoadingName = (state: NameUniq) => state.loading;
// export const getSuccessName = (state: NameUniq) => state.success;

// export const getSendCatalogForApprovalStatus = (state: State) => state.sendCatalogForApprovalStatus;
// export const getSaveAsDraftStatus = (state: State) => state.saveAsDraftStatus;
// export const getDeleteStatus = (state: State) => state.deleteStatus;

// export const getCatalogPermissions = (state: State) => state.catalogPermissions;
// export const getCanDeletePermission = (permissions: CatalogPermissions) => permissions.canDelete;
// export const getCanEditPermission = (permissions: CatalogPermissions) => permissions.canEdit;
// export const getCanPublishPermission = (permissions: CatalogPermissions) => permissions.canPublish;
// export const getCanUnpublishPermission = (permissions: CatalogPermissions) => permissions.canUnpublish;

// export const getAnyRequestPending = (state: State) => [
//   state.sendCatalogForApprovalStatus,
//   state.saveAsDraftStatus,
// ].some(status => status.loading);
