import { createAction, props } from '@ngrx/store';
import { RDSBreakpoint } from '@app/core/models/screen.model';

export const setRdsBreakpoint = createAction(
  '[SCREEN] Set RDS breakpoint',
  props<{rdsBreakpoint: RDSBreakpoint }>()
);

export const setDesktopOnly = createAction(
  '[SCREEN] Set desktop only',
  props<{canShow: boolean }>()
);

export const setDesktopOnlyViewActive = createAction(
  '[SCREEN] Set desktop only view active',
  props<{desktopOnly: boolean }>()
);

export const screenResized = createAction(
  '[SCREEN] Screen resized',
  props<{viewportWidth: number}>()
);
