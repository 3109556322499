
  <ng-container *ngIf="scrollable">
    <cdk-virtual-scroll-viewport [itemSize]="itemSize" [style.height.px]="161" [minBufferPx]="10 * itemSize" [maxBufferPx]="20 * itemSize">
      <ng-container *cdkVirtualFor="let item of data | async; trackBy: trackByFn; templateCacheSize: 0"></ng-container>
      <ng-container *ngTemplateOutlet="treeTemplate"></ng-container>
    </cdk-virtual-scroll-viewport>
  </ng-container>
  <ng-container *ngIf="!scrollable">
    <ng-container *ngTemplateOutlet="treeTemplate"></ng-container>

  </ng-container>

  <ng-template #treeTemplate>
    <rds-tree [size]="size" [treeControl]="control" [dataSource]="dataSource">
      <rds-tree-node
        *rdsTreeNodeDef="let node"
        [disabled]="disabled"
      >
      <rds-tree-node-text>
        <ng-container [ngSwitch]="entityType">
          <rds-badge *ngSwitchCase="'channel'" [label]="node.name" [rdsTooltip]="node.name" [rdsTooltipShowDelay]="300" type="minimal" color="neutral" size="m"></rds-badge>
          <span *ngSwitchDefault>{{ node.name }}</span>
        </ng-container>
      </rds-tree-node-text>
      </rds-tree-node>
            <!-- TYPE: selection -->
      <rds-tree-node
        *rdsTreeNodeDef="let node; when: isSelection"
        [attr.aria-selected]="isLeafActive(node)"
        [active]="isLeafActive(node)"
        (click)="!disabled && toggleLeaf(node)"
        class="rds-tree-node-select"
        [class.readonly]="disabled"
      >
      <rds-checkbox
        *ngIf="!disabled"
        tabIndex="-1"
        [checked]="isLeafActive(node)"
        [attr.aria-labelledby]="'rds-tree-node-' + node.id + '-text'"
        (click)="$event.stopPropagation()"
        (change)="!disabled && toggleLeaf(node)"
      ></rds-checkbox>
      <rds-tree-node-text>
        <ng-container [ngSwitch]="entityType">
          <rds-badge *ngSwitchCase="'channel'" type="minimal" color="neutral" size="m"
            [label]="node.name" 
            rhIsOverflowing
            #overflow="rhIsOverflowing"
            [rdsTooltipDisabled]="!overflow.overflowingX"
            [rdsTooltip]="node.name" 
            [rdsTooltipShowDelay]="300"
          ></rds-badge>
          <rds-chip-list *ngSwitchCase="'topic'" size="s"
            rhIsOverflowing 
            #overflow="rhIsOverflowing"
            [rdsTooltipDisabled]="!overflow.overflowingX"
            [rdsTooltip]="node.name"
            [rdsTooltipShowDelay]="300"
          >
              <rds-chip style="pointer-events: none;">
                <p class="ellipsis-overflow">
                  {{node.name}}
                </p>
              </rds-chip>
          </rds-chip-list>
          <span *ngSwitchDefault>{{ node.name }}</span>
        </ng-container>
      </rds-tree-node-text>
      </rds-tree-node>
        <!-- TYPE: mark -->
        <rds-tree-node
          *rdsTreeNodeDef="let node; when: isMark"
          [active]="false"
          class="rds-tree-node-mark"
          [class.readonly]="disabled"
        >
        <button class="my-2" rds-icon-button size="m" (click)="!disabled && toggleLeaf(node)">
            <rds-icon icon="star" 
              [rdsTooltip]="!disabled 
                ? (isLeafActive(node) ? 'Unmark Top Quicklink' : 'Mark as Top Quicklink') 
                : (isLeafActive(node) ? 'Top Quicklink' : '')"
              [rdsTooltipDisabled]="disabled && !isLeafActive(node)"
              [rdsTooltipShowDelay]="300"
              [namespace]="isLeafActive(node) ? 'filled' : 'outlined'"></rds-icon>
        </button>
        <rds-tree-node-text>
          <ng-container [ngSwitch]="entityType">
            <rds-badge *ngSwitchCase="'channel'" type="minimal" color="neutral" size="m"
              [label]="node.name"
              [class.clickable]="clicked.observed" 
              rhIsOverflowing
              #overflow="rhIsOverflowing"
              [rdsTooltipDisabled]="!overflow.overflowingX"
              [rdsTooltip]="node.name" 
              [rdsTooltipShowDelay]="300"
              (click)="click(node)"
            ></rds-badge>
            <rds-chip-list *ngSwitchCase="'topic'" size="s"
              rhIsOverflowing 
              #overflow="rhIsOverflowing"
              [rdsTooltipDisabled]="!overflow.overflowingX"
              [rdsTooltip]="node.name"
              [rdsTooltipShowDelay]="300"
            >
                <rds-chip
                  [class.clickable]="clicked.observed"
                  (click)="click(node)"
                >
                  <p class="ellipsis-overflow">
                    {{node.name}}
                  </p>
                </rds-chip>
            </rds-chip-list>
            <div *ngSwitchDefault class="row row-centered row-nowrap">
              <div class="col">
                <p *ngIf="!clicked.observed" class="ui-label-m text-ellipsis">
                  {{ node.name }}
                </p>
                <a rds-link standalone (click)="click(node)">
                  {{ node.name }}
                </a>
              </div>
            </div>
          </ng-container>
        </rds-tree-node-text>
        <button *ngIf="!disabled" class="my-2" rds-icon-button size="s" (mousedown)="delete(node)">
          <rds-icon icon="delete" namespace="outlined">
    
          </rds-icon>
        </button>
        </rds-tree-node>
    
        <!-- TYPE: delete -->
        <rds-tree-node
          *rdsTreeNodeDef="let node; when: isDelete"
          [active]="false"
          class="rds-tree-node-delete"
          [class.readonly]="disabled"
        >
        <rds-tree-node-text>
          <ng-container [ngSwitch]="entityType">
            <rds-badge *ngSwitchCase="'channel'" type="minimal" color="neutral" size="m"
              [label]="node.name" 
              [class.clickable]="clicked.observed" 
              rhIsOverflowing
              #overflow="rhIsOverflowing"
              [rdsTooltipDisabled]="!overflow.overflowingX"
              [rdsTooltip]="node.name" 
              [rdsTooltipShowDelay]="300"
              (click)="click(node)"
            ></rds-badge>
            <rds-chip-list *ngSwitchCase="'topic'" size="s"
              rhIsOverflowing 
              #overflow="rhIsOverflowing"
              [rdsTooltipDisabled]="!overflow.overflowingX"
              [rdsTooltip]="node.name"
              [rdsTooltipShowDelay]="300"
            >
                <rds-chip
                  [class.clickable]="clicked.observed"
                  (click)="click(node)"
                >
                  <p class="ellipsis-overflow">
                    {{node.name}}
                  </p>
                </rds-chip>
            </rds-chip-list>
            <div *ngSwitchDefault class="row row-centered row-nowrap">
              <div class="col">
                <p *ngIf="!clicked.observed" class="ui-label-m text-ellipsis">
                  {{ node.name }}
                </p>
                <a rds-link standalone (click)="click(node)">
                  {{ node.name }}
                </a>
              </div>
            </div>
          </ng-container>
        </rds-tree-node-text>
        <button *ngIf="!disabled" class="my-2" rds-icon-button size="s" (mousedown)="delete(node)">
          <rds-icon icon="delete" namespace="outlined"></rds-icon>
        </button>
        </rds-tree-node>
    </rds-tree>
  </ng-template>

