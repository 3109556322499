import { createAction, props } from '@ngrx/store';

import { ContentDetails } from '@core/models/content-details.model';

export const contentDetailsCollectionAddOne = createAction(
  '[STORED CONTENT DETAILS - ADD ONE] Contents Details collection add one.',
  props<{ contentDetails: ContentDetails }>()
);

export const contentDetailsCollectionUpsertOne = createAction(
  '[STORED CONTENT DETAILS - UPSERT ONE] Contents Details collection add one.',
  props<{ contentDetails: ContentDetails }>()
);

export const updateNumComments = createAction(
  '[STORED CONTENT DETAILS - UPDATE COMMENTS NUMBER] Contents Details update Comments Number',
  props<{ contentId: string; count: number }>()
);
