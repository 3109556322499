import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {EndpointsService} from '@app/core/services/endpoints/endpoints.service';
import {ApiService} from '@app/core/services/api/api.service';

@Injectable()
export class AuthService {

  public authCreate({code, scope, redirectUri}: {code: string, scope: string, redirectUri: string}): Observable<any> {
    const url = this.endpoints.ENDPOINT.AUTHENTICATION.CREATE;
    const body = {
      code: code,
      scope: scope,
      redirectUri: redirectUri
    }
    return this.http.post(url, body);
  }

  public refreshToken(): Observable<any> {
    const url = this.endpoints.ENDPOINT.AUTHENTICATION.REFRESH;
    
    return this.http.get(url);
  }

  public test401(idToken) {
    const url = this.endpoints.ENDPOINT.AUTHENTICATION.TEST_401;
    return this.http.get(url);
  }

  constructor(
    private http: ApiService,
    private endpoints: EndpointsService,
  ) {
  }
}
