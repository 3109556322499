<ng-container *ngIf="slidesCount > 0">
  <div class="row">
    <div class="col-12 mt-10 mb-7">
      <span class="ui-heading-4">Related Articles</span>
    </div>
  </div>
  <div
    *ngIf="relatedNewsLoading && (!isAutoRelated || (isAutoRelated && !totalAutoRelated))"
    class="row"
  >

    <div
      *ngFor="let news of loaderTilesCount(0); trackBy: trackByFn"
      [class.col-3]="slidesInRow === 4"
      [class.col-4]="slidesInRow === 3"
      [class.col-6]="slidesInRow === 2"
      [class.col-12]="slidesInRow === 1"
    >
      <rh-news-card-loader></rh-news-card-loader>
    </div>
    <div class="col-12 mb-10 mt-9">
      <atr-rect-loader viewBox="0 0 163 40"></atr-rect-loader>
    </div>
  </div>
  <rds-carousel
    *ngIf="!relatedNewsLoading || isAutoRelated"
    #carousel
    class="mb-10"
    [showButtons]="false"
  >
    <rds-carousel-item
      *ngFor="let i of (slidesCount | numToArray)"
      [class.rds-carousel__item--visible]="carousel.indexOfActiveCarouselItem === i">
      <div class="row">
        <ng-container *ngIf="currentSlideItems(i).length > 0">
          <div
            *ngFor="let news of currentSlideItems(i); trackBy: trackByFn"
            [class.col]="slidesInRow === 5"
            [class.col-3]="slidesInRow === 4"
            [class.col-4]="slidesInRow === 3"
            [class.col-6]="slidesInRow === 2"
            [class.col-12]="slidesInRow === 1"
          >
            <rh-news-card-item
              [id]="news.id"
              [hasRead]="news.hasRead"
              [imageUrl]="news.imageUrl"
              [title]="news.title"
              [description]="news.description"
              [creationDate]="news.date"
              [likeEnabled]="news.likeEnabled"
              [likeCount]="news.numLikes"
              [hasBookmarked]="news.hasBookmarked"
              [enableBookmark]="true"
              [hasLiked]="news.hasLiked"
              [channelName]="news.channelName"
              [channelId]="news.channelId"
              [url]="news.externalUrl? news.externalUrl :'/content/' + news?.id + '?title=' + replaceSpaces(news.title)"
              [type]="news.type"
              (newsOpened)="openNews($event)"
              (channelOpened)="openChannel($event)"
              (likeToggled)="toggleLike($event, news.numLikes)"
              (bookmarkToggled)="toggleBookmark($event)"
              [isLikingProcessingIds]="isLikingProcessingIds"
              [color]="'neutral'">
            </rh-news-card-item>
          </div>
        </ng-container>
        <ng-container *ngIf="currentSlideItems(i).length === 0">
          <div
            *ngFor="let news of loaderTilesCount(i, carousel.indexOfActiveCarouselItem === i); trackBy: trackByFn"
            [class.col]="slidesInRow === 5"
            [class.col-3]="slidesInRow === 4"
            [class.col-4]="slidesInRow === 3"
            [class.col-6]="slidesInRow === 2"
            [class.col-12]="slidesInRow === 1"
          >
            <rh-news-card-loader></rh-news-card-loader>
          </div>
        </ng-container>
      </div>
    </rds-carousel-item>
  </rds-carousel>
  <div *ngIf="!!carousel && slidesCount > 0" class="mb-10 rds-carousel__carousel-pagination">
    <div
      class="rds-carousel__carousel-navigation-button-wrapper rds-carousel__carousel-navigation-button-wrapper--left"
    >
      <button
        [disabled]="carousel.indexOfActiveCarouselItem === 0 && totalAutoRelated !== relatedNews.length"
        rds-icon-button
        (click)="prev()"
        [size]="'m'"
      >
        <rds-icon namespace="filled" icon="chevron_left"></rds-icon>
      </button>
    </div>
    <div class="rds-carousel__carousel-pagination--text">
      {{ carousel.indexOfActiveCarouselItem + 1 }}/{{ slidesCount || 0 }}
    </div>
    <div
      class="rds-carousel__carousel-navigation-button-wrapper rds-carousel__carousel-navigation-button-wrapper--right"
    >
      <button
        rds-icon-button
        [disabled]="relatedNewsLoading"
        (click)="next()"
        [size]="'m'"
      >
        <rds-icon namespace="filled" icon="chevron_right"></rds-icon>
      </button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="slidesCount === 0 && relatedNewsLoading ">
  <div class="row">
    <div class="col-12 mt-10 mb-7">
      <span class="ui-heading-4">Related Articles</span>
    </div>
    <div
      *ngFor="let news of itemsForLoaderRow; trackBy: trackByFn"
      [class.col-3]="slidesInRow === 4"
      [class.col-4]="slidesInRow === 3"
      [class.col-6]="slidesInRow === 2"
      [class.col-12]="slidesInRow === 1"
    >
      <rh-news-card-loader></rh-news-card-loader>
    </div>
  </div>
</ng-container>
