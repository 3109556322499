import * as fromReducer from '../header.reducer';
import * as fromHeader from './header.reducer';
import {createSelector} from '@ngrx/store';
import {ExpressLink, People} from '@app/layout/header/models';
import {environment} from '@env/environment';
import forEach from '@lodash-es/forEach';
import uniq from '@lodash-es/uniq';

export const selectHeader = createSelector(
    fromReducer.selectState,
    fromReducer.getHeader
);

export const selectPhrase = createSelector(
    selectHeader,
    fromHeader.getPhrase
);

export const selectForceClose = createSelector(
    selectHeader,
    fromHeader.getForceClose
);

export const selectHeaderVisible = createSelector(
    selectHeader,
    (state) => state.headerVisible
);

export const selectShortcuts = createSelector(
    selectHeader,
    fromHeader.getShortcuts
);

export const selectSuggestionPhrase = createSelector(
    selectHeader,
    fromHeader.getSuggestionPhrase
);

export const selectExpressLinks = createSelector(
    selectHeader,
    fromHeader.getExpressLinksItems
);

export const selectExpressLinksLoading = createSelector(
    selectHeader,
    fromHeader.getExpressLinksLoading
);

export const selectDefaultLinks = createSelector(
    selectHeader,
    fromHeader.getDefaultLinks
);

export const selectWorkspaceGuideLink = createSelector(
    selectDefaultLinks,
    fromHeader.getWorkspaceGuideLink
);

export const selectNotificationGuideLink = createSelector(
    selectDefaultLinks,
    fromHeader.getNotificationGuideLink
);

export const selectAutocompletes = createSelector(
    selectHeader,
    fromHeader.getAutocompletesItems
);

export const selectAutocompletesLoading = createSelector(
    selectHeader,
    fromHeader.getAutocompletesLoading
);

export const selectPeople = createSelector(
    selectHeader,
    fromHeader.getPeopleItems
);

export const selectPeopleLoading = createSelector(
    selectHeader,
    fromHeader.getPeopleLoading
);

export const selectPeopleAreTouched = createSelector(
    selectHeader,
    fromHeader.getPeopleAreTouched
);

export const selectAutocompletesAreTouched = createSelector(
    selectHeader,
    fromHeader.getAutocompletesAreTouched
);

export const selectExpressLinksAreTouched = createSelector(
    selectHeader,
    fromHeader.getExpressLinksAreTouched
);

export const selectNodes = createSelector(
    selectHeader,
    fromHeader.getNodes
);

export const selectSearchResultsDropdownIsOpened = createSelector(
    selectAutocompletesAreTouched,
    selectExpressLinksAreTouched,
    selectPeopleAreTouched,
    (autocompletes, expressLinks, people) => {
        return autocompletes || expressLinks || people;
    }
);

export const selectIsLoadingSuggestions = createSelector(
    selectAutocompletesLoading,
    selectExpressLinksLoading,
    selectPeopleLoading,
    (autocompletes, expressLinks, people) => {
        return autocompletes || expressLinks || people;
    }
);

export const selectSuggestionPanelCondition = createSelector(
    selectHeader,
    fromHeader.getSuggestionPanelCondition
);

export const selectSuggestions = createSelector(
    selectAutocompletes,
    selectExpressLinks,
    selectPeople,
    (autocompletes, expressLinks, people) => ({
        suggestions: {
            autocompletes,
            expressLinks,
            people
        }
    })
);

export const selectExpressLinksForHeader = createSelector(
    selectExpressLinks,
    selectPeople,
    (expressLinksItems, peopleItems) => {

        let expressLinks: ExpressLink[] = [];
        const expressLinksCount = expressLinksItems.length;
        const peopleCount = peopleItems.length;

        if (expressLinksCount >= 3) {
            if (peopleCount >= 2) {
                expressLinks = [
                    ...expressLinksItems.slice(0, 3),
                    ...peopleItems.map((people) => convertPeople(people)).slice(0, 2),
                ];
            } else {
                if (peopleCount === 0) {
                    expressLinks = [
                        ...expressLinksItems.slice(0, 5),
                    ];
                }
                if (peopleCount === 1) {
                    expressLinks = [
                        ...expressLinksItems.slice(0, 4),
                        ...peopleItems.map((people) => convertPeople(people))
                    ];
                }
            }
        } else {
            if (expressLinksCount === 0) {
                expressLinks = [
                    ...peopleItems.map((people) => convertPeople(people)).slice(0, 5)
                ];
            }
            if (expressLinksCount === 1) {
                expressLinks = [
                    ...expressLinksItems,
                    ...peopleItems.map((people) => convertPeople(people)).slice(0, 4)
                ];
            }
            if (expressLinksCount === 2) {
                expressLinks = [
                    ...expressLinksItems,
                    ...peopleItems.map((people) => convertPeople(people)).slice(0, 3)
                ];
            }
        }

        return expressLinks;
    }
);

function convertPeople(people: People): ExpressLink {
    return {
        name: `${people.firstName} ${people.lastName}`,
        description: ``,
        category: {name: ''},
        url: `${environment.url}/users/${people.login}`
    };
}

const capitalize = (s: string) => {
    if (typeof s !== 'string') {
        return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
};

function convertPeopleToAutocomplete(people: People, text: string): string[] {

    const search = text.toLocaleLowerCase();
    let texts: string[] = [];
    if (people.firstName) {
        texts = [
            ...people.firstName.split(' ').map(s => s.toLowerCase()),
        ];
    }
    if (people.lastName) {
        texts = [
            ...texts,
            ...people.lastName.split(' ').map(s => s.toLowerCase()),
        ];
    }

    const results: string[] = [];

    forEach(texts, item => {
        if (item.indexOf(search) !== -1) {
            results.push(capitalize(item));
        }
    });

    return results;
}

export const selectAutocompletesForHeader = createSelector(
    selectSuggestionPhrase,
    selectAutocompletes,
    selectPeople,
    (text, autoCompletesItems, peopleItems) => {
        let autocompletes: string[] = [];
        const autocompleteCount = autoCompletesItems.length;

        const peopleTexts: string[] = [];
        forEach(peopleItems, (people) => {
            forEach(convertPeopleToAutocomplete(people, text), p => peopleTexts.push(p));
        });

        const peopleAutocompletes = uniq(peopleTexts);
        const peopleCount = peopleAutocompletes.length;

        if (autocompleteCount >= 3) {
            if (peopleCount >= 2) {
                autocompletes = [
                    ...autoCompletesItems.slice(0, 3),
                    ...peopleAutocompletes.slice(0, 2),
                ];
            } else {
                if (peopleCount === 0) {
                    autocompletes = [
                        ...autoCompletesItems.slice(0, 5),
                    ];
                }
                if (peopleCount === 1) {
                    autocompletes = [
                        ...autoCompletesItems.slice(0, 4),
                        ...peopleAutocompletes
                    ];
                }
            }
        } else {
            if (autocompleteCount === 0) {
                autocompletes = [
                    ...peopleAutocompletes.slice(0, 5)
                ];
            }
            if (autocompleteCount === 1) {
                autocompletes = [
                    ...autoCompletesItems,
                    ...peopleAutocompletes.slice(0, 4)
                ];
            }
            if (autocompleteCount === 2) {
                autocompletes = [
                    ...autoCompletesItems,
                    ...peopleAutocompletes.slice(0, 3)
                ];
            }
        }

        const original: string[] = [];
        const lowerCase: string[] = [];

        const autocompleteText = uniq(autocompletes).sort();

        forEach(autocompleteText, item => {
            if (!original.includes(item) && !lowerCase.includes(item.toLowerCase())) {
                original.push(item);
                lowerCase.push(item.toLowerCase());
            }
        });

        return original;
    }
);
