import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import * as fromActions from '../store/layout.actions';

export const feature = 'layouts';

export interface State {
	notificationBarOpened: boolean;
	tickerOpened: boolean;
	ratioWarningDialogShowed: boolean;
}

let ratioWarningDialogShowed = !!localStorage.getItem('ratioWarningDialog');
console.log(ratioWarningDialogShowed);
export const initialState: State = {
	notificationBarOpened: false,
	tickerOpened: true,
	ratioWarningDialogShowed: ratioWarningDialogShowed,
};

const layoutReducer = createReducer(
	initialState,
	on(fromActions.closeNotificationBar, (state) => ({
		...state,
		notificationBarOpened: false,
	})),
	on(fromActions.closeTicker, (state) => ({
		...state,
		tickerOpened: false,
	})),
	on(fromActions.closeRatioWarningDialog, (state) => ({
		...state,
		ratioWarningDialogShowed: true,
	}))
);

export function reducer(state: State | undefined, action: Action) {
	return layoutReducer(state, action);
}

export const selectState = createFeatureSelector<State>(feature);

export const getNotificationBarOpened = (state: State) => state.notificationBarOpened;
export const getTickerOpened = (state: State) => state.tickerOpened;
export const getRatioWarningShowed = (state: State) => state.ratioWarningDialogShowed;
