import { Injectable } from '@angular/core';
import { UserService } from '@app/core/user/services/user.service';
import * as fromBackButton from '@app/root-store/ui/back-button';
import { ConfirmDialogComponent } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { CatalogsService } from '@core/services/catalogs.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { RdsDialogService } from '@rds/angular-components';
import { debounceTime, distinctUntilChanged, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import * as fromActions from './catalog-entry.actions';
import * as fromReducer from './catalog-entry.reducer';
import * as fromSelectors from './catalog-entry.selectors';

@Injectable()
export class CatalogEntryEffects {

  public checkNameUnique$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setFormValue),
      debounceTime(300),
      filter(({form}) => !!form.name),
      distinctUntilChanged((prev, next) => prev.form.name === next.form.name),
      map(({form}) => fromActions.isNameUniqueRequest({name: form.name, id: form.id}))
    )
  );
  

  public openDeleteEntryConfirmDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openDeleteEntryConfirmDialog),
      switchMap(({id, name, status}) => this.dialogService.open(ConfirmDialogComponent, {
        data: {
          ids: [id],
          title: `Are you sure you want to delete "${name}" entry?`,
          confirmButtonLabel: `Yes, delete`,
          confirmButtonType: 'warning'
        }
      }).afterClosed().pipe(
        filter((data) => !!data),
        map((data) => fromActions.deleteRequest({id: data.ids[0], status}))
      ))
    ),
    {dispatch: true}
  );

  public deleteRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.deleteRequest),
    switchMap(({id, status}) => this.catalogService.deleteCatalog(id).pipe(
      map(() => fromActions.deleteSuccess({status}))
    )),
  ), { dispatch: true}
  );
  
  public openRejectEntryConfirmDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openRejectEntryConfirmDialog),
      switchMap(({id, name}) => this.dialogService.open(ConfirmDialogComponent, {
        data: {
          ids: [id],
          title: `Are you sure you want to reject "${name}" entry?`,
          confirmButtonLabel: `Yes, reject`,
          confirmationReason: {
            label: 'Reason for rejection',
            required: true,
          },
          confirmButtonType: 'warning'
        }
      }).afterClosed().pipe(
        filter((data) => !!data),
        map((data) => fromActions.rejectRequest({id: data.ids[0], comment: data.confirmationReason.value}))
      ))
    ),
    {dispatch: true}
  );

  public rejectRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.rejectRequest),
    switchMap(({id, comment}) => this.catalogService.rejectCatalog(id, comment).pipe(
      map(() => fromActions.rejectSuccess())
    )),
  ), { dispatch: true}
  );

  public approveRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.approveRequest),
    switchMap(({id}) => this.catalogService.approveCatalog(id).pipe(
      map(() => fromActions.approveSuccess())
    )),
  ), { dispatch: true}
  );

  public openProlongEntryConfirmDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openProlongEntryConfirmDialog),
      switchMap(({id, name}) => this.dialogService.open(ConfirmDialogComponent, {
        data: {
          ids: [id],
          title: `Are you sure you want to prolong "${name}" entry?`,
          messages: [`Your entry will be prolonged for another 12 months.`],
          confirmButtonLabel: `Yes, prolong`,
          confirmButtonType: 'primary'
        }
      }).afterClosed().pipe(
        filter((data) => !!data),
        map((data) => fromActions.prolongRequest({id: data.ids[0]}))
      ))
    ),
    {dispatch: true}
  );

  public prolongRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.prolongRequest),
    switchMap(({id}) => this.catalogService.prolongCatalog(id).pipe(
      map(() => fromActions.prolongSuccess())
    )),
  ), { dispatch: true}
  );
  
  public isNameUniqueRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.isNameUniqueRequest),
    withLatestFrom(this.store$.pipe(select(fromSelectors.selectInitialForm))),
    switchMap(([{name}, form]) => this.catalogService.getIsNameUnique(name).pipe(
      map((isUnique) => ({isUnique: isUnique || form.name === name}))
    )),
    map(({isUnique}) => fromActions.isNameUniqueSuccess({isUnique}))
  ), { dispatch: true}
  );

  public getContentManagerTaxonomyRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.getContentManagerTaxonomyRequest),
    switchMap(({login}) => this.userService.getUserDefaultTaxonomies(login).pipe(
      map((taxonomies) => ({
        location: taxonomies.location.id,
        department: taxonomies.department.id,
        function: taxonomies.function.id,
      }))
    )),
    map((contentManagerTaxonomy) => fromActions.getContentManagerTaxonomySuccess({contentManagerTaxonomy}))
  ), { dispatch: true}
  );

  
  public saveAsDraftRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveAsDraftRequest),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectFormForRequest))),
      switchMap(([action, form]) => this.catalogService.saveAsDraft(form).pipe(
        map((id) => ({ form, id }))
      )),
      mergeMap(({ form, id }) => [
        fromActions.saveAsDraftSuccess(),
      ])
    ), { dispatch: true }
  );

  public updateAsDraftRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateAsDraftRequest),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectFormForRequest))),
      switchMap(([action, form]) => this.catalogService.updateAsDraft(form).pipe(
        map((id) => ({ form, id }))
      )),
      mergeMap(({ form, id }) => [
        fromActions.updateAsDraftSuccess(),
      ])
    ), { dispatch: true }
  );

  public sendForApprovalRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.sendForApprovalRequest),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectFormForRequest))),
      switchMap(([action, form]) => this.catalogService.sendForApproval(form).pipe(
        map((id) => ({ form, id }))
      )),
      mergeMap(({ form, id }) => [
        fromActions.sendForApprovalSuccess(),
      ])
    ), { dispatch: true }
  );

  public updateForApprovalRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateForApprovalRequest),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectFormForRequest))),
      switchMap(([{approvalRequired}, form]) => this.catalogService.updateForApproval(form).pipe(
        map((id) => ({ approvalRequired, form, id }))
      )),
      mergeMap(({ approvalRequired, form, id }) => [
        fromActions.updateForApprovalSuccess({approvalRequired}),
      ])
    ), { dispatch: true }
  );

  public saveSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(
      fromActions.saveAsDraftSuccess,
      fromActions.updateAsDraftSuccess,
      fromActions.sendForApprovalSuccess,
      fromActions.updateForApprovalSuccess,
      fromActions.prolongSuccess
    ),
    mergeMap(() => [
      fromActions.clearAll(),
      fromBackButton.back({defaultLabel: 'My Content', defaultRoute: 'content/my'})
    ]),
  ), { dispatch: true}
);

public saveManagerSuccess$ = createEffect(() =>
this.actions$.pipe(
  ofType(
    fromActions.rejectSuccess,
    fromActions.approveSuccess,
  ),
  mergeMap(() => [
    fromActions.clearAll(),
    fromBackButton.back({defaultLabel: 'Content Approvals', defaultRoute: 'content/approvals'})
  ]),
), { dispatch: true}
);

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private catalogService: CatalogsService,
    private userService: UserService,
    private dialogService: RdsDialogService
  ) {}
}
