/// <reference types="google.accounts" />

import { RhEvent } from '@app/events/models/event';
import { createAction, props } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

export const loadGoogleLibs = createAction(
  '[AUTH] Load Google Identity Services ang Google API libraries',
  // props<{scope: string}>()
);

export const GISLibLoaded = createAction(
  '[AUTH] Google Identity Services library loaded',
);

export const GapiLibLoaded = createAction(
  '[AUTH] Google API library loaded',
);

export const GISClientsInitialized = createAction(
  '[AUTH] Google Identity Services clients initialized',
);

export const authenticateAfterRefresh401 = createAction(
  '[AUTH] Authenticate after refresh 401',
);

export const authenticateWithGIS = createAction(
  '[AUTH] Authenticate with Google Identity Services',
  props<{tokenId: string, tokenAccess: string, jwtToken: string}>()
);

export const googleAuthenticationCompleted = createAction(
  '[AUTH] Google Authentication completed',
  props<{credentials: google.accounts.id.CredentialResponse}>()
);

export const redirectToLogin = createAction(
  '[AUTH] Redirect to login'
);

export const getAuthorizationCodeRequest = createAction(
  '[AUTH] Get authorization code request'
);

export const getAuthorizationCodeSuccess = createAction(
  '[AUTH] Get authorization code success',
  props<{code: string, scope: string, redirectUri: string}>()
);

export const getAuthorizationCodeFailure = createAction(
  '[AUTH] Get authorization code failure',
  props<{error: google.accounts.oauth2.ClientConfigError}>()
);

export const authorizeUserRequest = createAction(
  '[AUTH] Authorize user request',
  props<{code: string, scope: string, redirectUri: string}>()
);

export const authorizeUserSuccess = createAction(
  '[AUTH] Authorize user success',
  props<{tokenId: string; tokenAccess: string, scope: string, jwtToken: string, actionToRetry?: TypedAction<any>}>()
);

export const authorizeUserFailure = createAction(
  '[AUTH] Authorize user failure',
  props<{error: string}>()
);

export const refreshTokenRequest = createAction(
  '[AUTH] Refresh token request',
  props<{actionToRetry?: TypedAction<any>}>()
);

export const refreshTokenSuccess = createAction(
  '[AUTH] Refresh token success',
  props<{tokenId: string; tokenAccess: string, scope: string, jwtToken: string, actionToRetry?: TypedAction<any>}>()
);

export const refreshTokenFailure = createAction(
  '[AUTH] Refresh token failure',
  props<{error: string}>()
);

export const googleOneTap = createAction(
  '[AUTH] Google One Tap'
);

export const googleOneTapPromptEvent = createAction(
  '[AUTH] Google One Tap prompt event',
  props<{event: google.accounts.id.PromptMomentNotification}>()
)

export const updateAuthToken = createAction(
  '[AUTH] Update Auth token',
  props<{ tokenAccess: string }>()
);

export const updateIdToken = createAction(
  '[AUTH] Update ID token',
  props<{ tokenId: string }>()
);

export const updateJwtToken = createAction(
  '[AUTH] Update JWT token',
  props<{ jwtToken: string }>()
);


export const updateScope = createAction(
  '[AUTH] Update scope',
  props<{ scope: string }>()
);

export const askForPermissionAndAddEvent = createAction(
  '[EVENTS - GCAL] add event to gcal failure',
  props<{ event: Partial<RhEvent>}>()
);

export const dialogOpened = createAction(
  '[EVENTS - GCAL] dialog opened'
);

export const openGoogleLoginDialog = createAction(
  '[EVENTS - GCAL] open google login dialog'
);

export const openGoogleOneTapDialog = createAction(
  '[EVENTS - GCAL] open google one tap dialog'
);

export const openUserNotCreatedDialog = createAction(
  '[EVENTS - GCAL] open user not created dialog'
);
// old

export const startAppInitializer = createAction(
  '[APP INITIALIZER START] Start App init'
);

export const finishAppInitializer = createAction(
  '[APP INITIALIZER FINISH] Finish App init'
);

export const initAuth = createAction(
  '[AUTH INIT] Init Auth'
);

export const clearAuth = createAction(
  '[AUTH API] Clear Auth'
);

export const authCompleted = createAction(
  '[AUTH API] Auth Completed'
);

export const loginWithGoogleStart = createAction(
  '[AUTH API] Login with Google Start'
);

export const loginWithGoogleEnd = createAction(
  '[AUTH API] Login with Google End'
);

export const isLoggedWithGoogle = createAction(
  '[AUTH API] Is Logged With Google',
  props<{ tokenId: string, tokenAccess: string, photoUrl?: string }>()
);

export const isNotLoggedWithGoogle = createAction(
  '[AUTH API] Is Not Logged With Google'
);

export const clearAndLoginAgain = createAction(
  '[AUTH API] Clear And Login Again'
);

export const refreshTokenStartTimer = createAction(
  '[AUTH API] Refresh Token Start Timer'
);

export const ensureCreated = createAction(
  '[AUTH API] Ensure Created'
);

export const ensureCreatedSuccess = createAction(
  '[AUTH API] Ensure Created Success'
);

export const ensureCreatedFailure = createAction(
  '[AUTH API] Ensure Created Failure',
);

export const userNotCreatedDialogIsOpened = createAction(
  '[AUTH API] Ensure Created Dialog is Opened',
);

export const initQueryAPI = createAction(
  '[AUTH API] Init query API',
  props<{token?: string}>()
  );

export const initQueryAPISuccess = createAction('[AUTH API] Init query API Success');
export const initGcalAPISuccess = createAction('[AUTH API] Init gCal API Success');
export const addPermissionCalendar = createAction('[AUTH API] Add permission to calendar');
export const addPermissionCloudSearch = createAction('[AUTH API] Add permission to Cloud Search');
export const signOut = createAction('[AUTH API] sign out');
