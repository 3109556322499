import Command from '@ckeditor/ckeditor5-core/src/command';
import {checkIsCustomBlock} from "../blocks/blocks-utils";

export default class SectionColorsBoxCommand extends Command {
  execute(options) {
    const model = this.editor.model;

    model.change(writer => {
      const selection = model.document.selection;
      const parent = selection.getFirstPosition()?.parent;
      const backgroundName = options?.value;
      const selectedModelBlocks = Array.from(selection.getSelectedBlocks());
      const selectedElement = selection.getSelectedElement();
      const sectionColorsBox = this._findParentSectionColorsBox(selectedElement, selectedModelBlocks);
      const otherBlockBox = this._findBlockBox(selectedElement, selectedModelBlocks);

      if (sectionColorsBox) {

        const sectionColorsBoxChildrens = Array.from(sectionColorsBox.getChildren());
        const sectionContent = sectionColorsBoxChildrens[0];

        if (backgroundName) {
          writer.rename(sectionContent, backgroundName)
        } else {
          writer.unwrap(sectionContent);
          writer.unwrap(sectionColorsBox);
        }
        return;
      }

      if (!sectionColorsBox) {
        if (backgroundName) {
          if (otherBlockBox) {
            this._wrapElement(writer, otherBlockBox, backgroundName);
            return;
          }
          if (selectedElement) {
            this._wrapElement(writer, selectedElement, backgroundName);
            return;
          }

          if (parent && parent.name === 'paragraph') {
            this._wrapElement(writer, parent, backgroundName);
            return;
          }
          if (selectedModelBlocks && selectedModelBlocks.length) {
            const box = createSection(writer);
            const content = createSectionContent(writer, backgroundName);
            model.insertObject(box, null, {findOptimalPosition: true});
            for (const block of selectedModelBlocks) {
              const range = writer.createRangeOn(block);
              writer.move(range, content, 'end')
            }
          }
        }
      }
      this.value = backgroundName;
    });
  }

  refresh() {
    this.value = '';
    const model = this.editor.model;
    const selection = model.document.selection;
    const element = selection.getSelectedElement();

    const selectedModelBlocks = Array.from(selection.getSelectedBlocks());
    const selectedElement = selection.getSelectedElement();
    const sectionColorsBox = this._findParentSectionColorsBox(selectedElement, selectedModelBlocks);
    if (sectionColorsBox) {
      this.value = sectionColorsBox?.getChild(0)?.name;
    }
    this.isEnabled = true;
  }

  _wrapElement(writer, element, backgroundName) {
    const range = writer.createRangeOn(element);
    writer.wrap(range, backgroundName)
    writer.wrap(range, 'sectionColorsBox')
  }

  _findParentSectionColorsBox(element, blocks) {
    if (!element) {
      element = blocks[0];
    }
    if (element?.name && this._checkIsSectionColorsBox(element.name)) {
      return element;
    }
    if (element?.parent) {
      return this._findParentSectionColorsBox(element.parent)
    }
    return null;
  }

  _findBlockBox(element, blocks) {
    if (!element) {
      element = blocks[0];
    }
    if (element?.name && checkIsCustomBlock(element.name)) {
      return element;
    }
    if (element?.parent) {
      return this._findBlockBox(element.parent)
    }
    return null;
  }

  _checkIsSectionColorsBox(name) {
    return name === 'sectionColorsBox';
  }
}

function createSection(writer) {
  return writer.createElement('sectionColorsBox');
}

function createSectionContent(writer, model) {
  return writer.createElement(model);
}
