import { SearchingPreferencesIds } from '@app/core/models';
import { ColorModel, NewsletterInlineForm } from '@app/newsletter/models';
import { NewsletterEditType } from '@app/newsletter/models/newsletter-edit-type.enum';
import {createAction, props} from '@ngrx/store';

export const setStepFormValidity = createAction(
  '[NEWSLETTER - INLINE] Set step validity',
  props<{step: number, validity: boolean}>()
);

export const nextStep = createAction(
  '[NEWSLETTER - INLINE] Newsletter next step',
);

export const prevStep = createAction(
  '[NEWSLETTER - INLINE] Newsletter prev step',
);

export const saveAsDraft = createAction(
  '[NEWSLETTER - INLINE] Newsletter save as draft',
);

export const saveAsDraftSuccess = createAction(
  '[NEWSLETTER - INLINE] Newsletter save as draft success',
);

export const saveAsDraftFailure = createAction(
  '[NEWSLETTER - INLINE] Newsletter save as draft failure',
  props<{message: any}>()
);

export const updateAsDraft = createAction(
  '[NEWSLETTER - INLINE] Newsletter update as draft',
);

export const updateAsDraftSuccess = createAction(
  '[NEWSLETTER - INLINE] Newsletter update as draft success',
);

export const updateAsDraftFailure = createAction(
  '[NEWSLETTER - INLINE] Newsletter update as draft failure',
  props<{message: any}>()
);


export const openConfirmLeaveDialog = createAction(
  '[NEWSLETTER - INLINE] Open confirm leave dialog',
);

export const openSaveAsDraftDialog = createAction(
  '[NEWSLETTER - INLINE] Open save as draft dialog',
);

export const startSaveAsDraftNewsletterProccess = createAction(
  '[NEWSLETTER - INLINE] Start Save as draft newsletter process',
);

export const sendTest = createAction(
  '[NEWSLETTER - INLINE] Newsletter send test',
  props<{to: string}>()
);

export const sendTestSuccess = createAction(
  '[NEWSLETTER - INLINE] Newsletter send test success',
);

export const sendTestFailure = createAction(
  '[NEWSLETTER - INLINE] Newsletter send test failure',
);

export const openSendTestDialog = createAction(
  '[NEWSLETTER - INLINE] Open send test dialog',
);

export const startSendNewsletterProccess = createAction(
  '[NEWSLETTER - INLINE] Start Send newsletter process',
);

export const sendNewsletter = createAction(
  '[NEWSLETTER - INLINE] Send newsletter request'
);

export const sendNewsletterSuccess = createAction(
  '[NEWSLETTER - INLINE] Send newsletter success'
);

export const sendNewsletterFailure = createAction(
  '[NEWSLETTER - INLINE] Send newsletter failure',
  props<{message: any}>()
);

export const sendNewsletterFromDraft = createAction(
  '[NEWSLETTER - INLINE] Send newsletter from draft request'
);

export const sendNewsletterFromDraftSuccess = createAction(
  '[NEWSLETTER - INLINE] Send newsletter from draft success'
);

export const sendNewsletterFromDraftFailure = createAction(
  '[NEWSLETTER - INLINE] Send newsletter from draft failure',
  props<{message: any}>()
);

export const openSendNewsletterDialog = createAction(
  '[NEWSLETTER - INLINE] Open send newsletter dialog',
);

export const publish = createAction(
  '[NEWSLETTER - INLINE] Newsletter publish',
);

export const setEditType = createAction(
  '[NEWSLETTER - INLINE] Set edit type',
  props<{ editType: NewsletterEditType}>()
);

export const setTitle = createAction(
  '[NEWSLETTER - INLINE] Set title',
  props<{title: string}>()
);

export const getEmailFromNewsletter = createAction(
  '[NEWSLETTER - GET EMAIL] Get email from'
);

export const getEmailFromNewsletterSuccess = createAction(
  '[NEWSLETTER - GET EMAIL] Get email from success',
  props<{ fromEmail: string }>()
);

export const getEmailFromNewsletterFailure = createAction(
  '[NEWSLETTER - GET EMAIL] Get email from failure'
);

export const uploadBannerPhotoRequest = createAction(
  '[NEWSLETTER - INLINE] Upload banner photo request',
  props<{file: File, bannerPhotoName: string, bannerPhotoSize: number}>()
);

export const uploadBannerPhotoSuccess = createAction(
  '[NEWSLETTER - INLINE] Upload banner photo success',
  props<{bannerPhotoUrl: string, bannerPhotoName: string, bannerPhotoSize: number}>()
);

export const uploadBannerPhotoFailure = createAction(
  '[NEWSLETTER - INLINE] Upload banner photo response',
  props<{message: any}>()
);

export const setContactEmailForFeedback = createAction(
  '[NEWSLETTER - INLINE] Set contact email for feedback',
  props<{contactEmailForFeedback: string}>()
);

export const setRocheLogoInHeader = createAction(
  '[NEWSLETTER - INLINE] Set show logo',
  props<{rocheLogoInHeader: boolean}>()
);

export const setDisclaimerIncluded = createAction(
  '[NEWSLETTER - INLINE] Set disclaimer included',
  props<{disclaimerIncluded: boolean}>()
);

export const setBannerPhotoIncluded = createAction(
  '[NEWSLETTER - INLINE] Set banner photo included',
  props<{bannerPhotoIncluded: boolean}>()
);

export const setTitleIncluded = createAction(
  '[NEWSLETTER - INLINE] Set title included',
  props<{titleIncluded: boolean}>()
);

export const setFeedbackIncluded = createAction(
  '[NEWSLETTER - INLINE] Set feedback included',
  props<{feedbackIncluded: boolean}>()
);

export const setSentByName = createAction(
  '[NEWSLETTER - INLINE] Set sent by name',
  props<{sentByName: string}>()
);

export const setSenderName = createAction(
  '[NEWSLETTER - INLINE] Set sender name',
  props<{senderName: string}>()
);

export const setRecipientType = createAction(
  '[NEWSLETTER - INLINE] Set recipient type',
  props<{recipientType: string}>()
);

export const setTaxonomyRecipient = createAction(
  '[NEWSLETTER - INLINE] Set taxonomy recipient',
  props<{taxonomyRecipient: {
    locations: Array<string>,
    departments: Array<string>,
    functions: Array<string>
  }}>()
);

export const setFromName = createAction(
  '[NEWSLETTER - INLINE] Set from name',
  props<{fromName: string}>()
);

export const setReplyToName = createAction(
  '[NEWSLETTER - INLINE] Set reply to name',
  props<{replyToName: string}>()
);

export const setReplyTo = createAction(
  '[NEWSLETTER - INLINE] Set reply to',
  props<{replyTo: string}>()
);

export const setSubject = createAction(
  '[NEWSLETTER - INLINE] Set subject',
  props<{subject: string}>()
);

export const setPreviewText = createAction(
  '[NEWSLETTER - INLINE] Set preview text',
  props<{previewText: string}>()
);

export const setTo = createAction(
  '[NEWSLETTER - INLINE] Set to',
  props<{to: string}>()
);

export const setToGroups = createAction(
  '[NEWSLETTER - INLINE] Set to groups',
  props<{toGroups: string}>()
);

export const setScheduleSentDate = createAction(
  '[NEWSLETTER - INLINE] Set schedule sent date',
  props<{scheduleSentDate: string}>()
);

export const setTimeZone = createAction(
  '[NEWSLETTER - INLINE] Set timeZone',
  props<{timeZone: string}>()
);

export const setWhenToSend = createAction(
  '[NEWSLETTER - INLINE] Set when to send',
  props<{whenToSend: number}>()
);

export const setImagePreviewUrl = createAction(
  '[NEWSLETTER - INLINE] Set image preview URL',
  props<{imagePreviewUrl: string}>()
);

export const setColor = createAction(
  '[NEWSLETTER - INLINE] Set color',
  props<{color: ColorModel}>()
);

export const clearData = createAction(
  '[NEWSLETTER - INLINE] Clear data',
);

export const loadNew = createAction(
  '[NEWSLETTER - INLINE] Clear data',
);

export const setWholeForm = createAction(
  '[NEWSLETTER - INLINE] Set whole form',
  props<{ form: NewsletterInlineForm }>()
);

export const getNewsletterById = createAction(
  '[NEWSLETTER - INLINE] Get newsletter by id',
  props<{ id: number }>()
);

export const getNewsletterByIdFailure = createAction(
  '[NEWSLETTER - INLINE] Get newsletter by id failure'
);

export const putSearchingPreferencesCountRequest = createAction(
  '[NEWSLETTER - NEWSLETTER INLINE] Put Searching Preferences Count',
  props<{ preferencesIds: SearchingPreferencesIds }>()
);

export const putSearchingPreferencesCountSuccess = createAction(
  '[NEWSLETTER - NEWSLETTER INLINE] Put Searching Preferences Count Success,',
  props<{ usersCount: number }>()
);

export const putSearchingPreferencesCountFailure = createAction(
  '[NEWSLETTER - NEWSLETTER INLINE] Put Searching Preferences Count Failed',
  props<{ error: any }>()
);

export const loadChannelDataForNewsletter = createAction(
  '[NEWSLETTER - NEWSLETTER INLINE] Load channel data for newsletter',
  props<{ channelIds: Array<string> }>()
);
