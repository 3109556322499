import {createAction, props} from '@ngrx/store';
import {Catalog, CatalogFilters, Category, QuickLink} from '@app/layout/header/models';
import {HeaderLinkFilter} from '@app/layout/header/components/dummy-links';
import {CatalogHeaderFilters} from '@app/shared/filters/models/catalog-header';

export const getCategoriesRequest = createAction(
  '[HEADER2 - CATALOGS] Get categories request'
);

export const loadDefaultTaxonomyFilter = createAction(
  '[HEADER2 - CATALOGS] Load default taxonomy filter',
);

export const setDefaultTaxonomyFilter = createAction(
  '[HEADER2 - CATALOGS] Set default taxonomy filter',
  props<{locations: Array<string>, departments: Array<string>, functions: Array<string>}>()
);

export const getCategoriesSuccess = createAction(
  '[HEADER2 - CATALOGS] Get categories success',
  props<{ categories: Array<Category> }>()
);

export const getCategoriesFailure = createAction(
  '[HEADER2 - CATALOGS] Get categories failure',
  props<{ error: any }>()
);

export const openCategory = createAction(
  '[HEADER2 - CATALOGS] Open category',
  props<{ category: Category }>()
);
export const openCategoryFromLink = createAction(
  '[HEADER2 - CATALOGS] Open from link category',
  props<{ category: Category }>()
);

export const openCategoryAndUpdateFilters = createAction(
  '[HEADER2 - CATALOGS] Open category and update',
  props<{ filters: HeaderLinkFilter }>()
);

export const backToCategories = createAction(
  '[HEADER2 - CATALOGS] Back to all categories'
);

export const showAdvancedFiltersDialog = createAction(
  'HEADER2 - SHOW ADVANCED FILTERS] Show advanced filters modal',
  props<{ categoryId: number }>()
);
export const showMobileAdvancedFiltersDialog = createAction(
  'HEADER2 - SHOW MOBILE ADVANCED FILTERS] Show mobile advanced filters modal',
  props<{ categoryId: number }>()
);

export const updateFilters = createAction(
  '[HEADER2 - UPDATE FILTERS] Update filters',
  props<{ filters: CatalogHeaderFilters }>()
);

export const getCatalogsRequest = createAction(
  '[HEADER2 - CATALOGS] Get catalogs request',
  props<{ categoryId: number, filters: CatalogFilters }>()
);

export const getCatalogsSuccess = createAction(
  '[HEADER2 - CATALOGS] Get catalogs success',
  props<{ catalogs: Array<Catalog> }>()
);

export const getCatalogsFailure = createAction(
  '[HEADER2 - CATALOGS] Get catalogs failure',
  props<{ error: any }>()
);

export const addQuickLinkRequest = createAction(
  '[HEADER2 - CATALOGS] Add QuickLink request',
  props<{ catalogId: number }>()
);

export const addQuickLinkSuccess = createAction(
  '[HEADER2 - CATALOGS] Add QuickLink success',
  props<{ quickLink: QuickLink }>()
);

export const addQuickLinkFailure = createAction(
  '[HEADER2 - CATALOGS] Add QuickLink failure',
  props<{ error: any }>()
);
