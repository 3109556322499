<div #formField class="bg-white">
  <div class="user__value-container p-4 pb-0 bg-with-border"
    [class.readonly]="disabled">
    <rh-table-with-edit-performance
      #table
      [autocomplete]="autocomplete"
      [autocompleteColumn]="autocompleteColumn"
      [readOnly]="disabled"
      [groupColumn]="groupColumn"
      [validityColumn]="validityColumn"
      [columns]="displayedColumns"
      [editableColumns]="editableColumns"
      [rows]="rows"
      [defaultSortActive]="validityColumn"
      [filters]="filters"
      defaultSortDirection="asc"
      (rowsDeleted)="remove($event)"
      (rowsEdited)="editRow($event)"
      (updateValidation)="updateValidation($event)"
      (userAdded)="userAdded($event)"
      (search)="search.emit($event)"
    >
      <span bulkText>
        {{table.selectedCount}} {{table.selectedCount.length === 1 ? 'recipient' : 'recipients'}} selected
      </span>
      <ng-container bulkActions>
        <button rds-warning-button (click)="remove(table.selection.selected)">Remove</button>
      </ng-container>
      <span emptyTitle>
        {{validityColumn === 'included' ? 'Looks like you don’t have any recipients added yet.' : 'Looks like you don’t blocked any user yet.'}}
        
      </span>
    </rh-table-with-edit-performance>
    <!-- <rh-table-with-edit 
      #table
      [readOnly]="disabled"
      [groupColumn]="groupColumn"
      [validityColumn]="validityColumn"
      [columns]="displayedColumns"
      [editableColumns]="editableColumns"
      [data]="rows | async"
      [defaultSortActive]="validityColumn"
      defaultSortDirection="'desc'"
      [hasBulk]="true"
      (rowsDeleted)="deleteRows($event)"
      (rowsEdited)="editRow($event)"
      (updateValidation)="updateValidation($event)"
    >
      <span bulkText>
        {{table.selectedCount}} {{table.selectedCount.length === 1 ? 'recipient' : 'recipients'}} selected
      </span>
      <ng-container bulkActions>
        <button rds-warning-button (click)="deleteRows(table.selection.selected)">Remove</button>
      </ng-container>
    </rh-table-with-edit> -->
  </div>
</div>
