import { SortEnum } from '../enums/sort.enum';
import { SimpleUser } from './newsboard';

export interface UserRoleForm {
  login: string;
  user: Array<SimpleUser>;
  roles: {
    isAdministrator: boolean;
    isContentQualityManager: boolean;
    isAudienceManager: boolean;
  };
  audience: {
    locations: Array<string>;
    departments: Array<string>;
    functions: Array<string>;
  },
  userWithoutRole: boolean;
}

export interface UserRoleRequest {
  login: string;
  roles: Array<number>;
  taxonomies: {
    locations: Array<string>;
    departments: Array<string>;
    functions: Array<string>;
  }
}

export interface UserRole {
  email: string;
  firstName: string;
  isAdministrator: boolean;
  isAudienceManager: boolean;
  isContentQualityManager: boolean;
  lastName: string;
  login: string;
  locations: string[];
  departments: string[];
  functions: string[];
}

export interface UserRoleResponse {
  email: string;
  firstName: string;
  isAdministrator: boolean;
  isAudienceManager: boolean;
  isContentQualityManager: boolean;
  lastName: string;
  login: string;
  taxonomies: {
    locations: Array<string>;
    departments: Array<string>;
    functions: Array<string>;
  };
}

export interface RoleAssignEditForm {
  login: string;
  loginSearch: string;
  roles: {
    isAdministrator: boolean;
    isAudienceManager: boolean;
    isContentQualityManager: boolean;
  };
  audience?: {
    locations: string[];
    departments: string[];
    functions: string[];
  };
}


export const UserWithRolesTableForm = {
  pageIndex: 0,
  pageSize: 8,
  totalCount: 0,
  filters: {
    roles: [],
    name: '',
    departments: [],
    locations: [],
    functions: [],
  },
  sort: {
    name: SortEnum.NONE,
  }
};

export const EMPTY_USER_ROLE_FORM = {
  login: null,
  user: [],
  roles: {
    isAdministrator: false,
    isContentQualityManager: false,
    isAudienceManager: false
  },
  audience: {
    locations: [],
    departments: [],
    functions: [],
  },
  userWithoutRole: false
}
