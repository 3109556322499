import {of} from 'rxjs';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import * as fromReducer from '@app/home/store/alert/alert.reducer';
import * as fromActions from '@app/home/store/alert/alert.actions';
import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import {AlertsService} from '@core/services/alerts.service';

@Injectable()
export class AlertHomeEffects {

	public getFeed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				fromActions.getFeedRequest,
			),
			mergeMap(() => {
				return this.alertsService.getFeed().pipe(
					switchMap((data) => [fromActions.getFeedSuccess({data})]),
					catchError(({message}) => of(fromActions.getFeedFailure({message}))))
			})
		))

	public markAsRead$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				fromActions.markAsReadRequest,
			),
			mergeMap(({id}) => {
				return this.alertsService.markAsRead(id).pipe(
					switchMap(() => [fromActions.markAsReadSuccess({id})]),
					catchError(({message}) => of(fromActions.getFeedFailure({message}))))
			})
		))

	constructor(
		private actions$: Actions,
		private store$: Store<fromReducer.State>,
		private alertsService: AlertsService
	) {
	}
}
