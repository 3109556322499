<h2 rds-dialog-header>Are you sure you want to send the alert?</h2>
<div rds-dialog-content>
  <p *ngIf="alert.recipientType === 0">
    The "{{alert.title}}" alert will appear on devices of Roche associates that belong to your selected audience. The audience you selected is located in
    <span *ngFor="let location of alert.locations; let last = last">
      {{location | dictionary : 'location' | async}}{{last? '': ', '}}
    </span>belong,  to the departments of
    <span *ngFor="let department of alert.departments; let last = last">
      {{department | dictionary : 'department' | async}}{{last? '': ', '}}
    </span> and the functions of
    <span *ngFor="let function of alert.functions; let last = last">
      {{function | dictionary : 'function' | async}}{{last? '': ', '}}
    </span>.
  </p>
  <p *ngIf="alert.recipientType === 1">
    The "{{alert.title}}" alert will appear on devices of Users selected by you: <span *ngFor="let user of alert.alertRecipients; let last = last">
      {{user.name}}{{last? '': ', '}}
    </span>.
  </p>
</div>
<div rds-dialog-actions-container align="end">
  <button
      rds-secondary-button
      [rds-dialog-close]="null"
      size="m"
  >Cancel</button>
  <button
      rds-primary-button
      [rds-dialog-close]="true"
      size="m"
  >Yes, send</button>
</div>
