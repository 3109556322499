
<div *ngIf="(catalogs$ | async).length > 0" class="catalogs-in-category">
  <div class="catalogs-in-category__header">
    {{category.name}}
  </div>
  <div class="catalogs-in-category__body">
    <rh-single-catalog-item *ngFor="let catalog of catalogs$ | async" [catalog]="catalog">
      <ng-container actions>
        <button rds-icon-button (click)="addQL(catalog.catalogId)">
          <rds-icon icon="add_circle" namespace="outlined"></rds-icon>
        </button>
      </ng-container>
    </rh-single-catalog-item>
  </div>
</div>