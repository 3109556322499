
<div class="row">
  <div class="col-8 offset-2 py-4">
    <div class="row">
      <h4 class="ui-heading-4">Profile</h4>
    </div>
  </div>
</div>
  <div *ngIf="userProfile$ | async as user" class="col-8 offset-2 bg-white py-10 py-s-4 mb-9">
    <div class="row">
      <div class="col px-11 px-s-5">
        <div  class="row row-centered row-nowrap">
          <div class="col-auto">
              <rh-user-single
                  size="xl"
                  [user]="user">
              </rh-user-single>
          </div>
          <div class="col-auto px-s-4 col-squeez">
              <div class="row">
                  <div class="col">
                      <p class="ui-label-m-bold text-ellipsis">
                          {{user.firstName}} {{user.lastName}}
                      </p>
                  </div>
              </div>
              <div class="row">
                  <div class="col">
                      <p class="ui-label-s text-ellipsis">
                        {{user.email}}
                      </p>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
