import {Action, createReducer, on} from '@ngrx/store';
import {Dictionary, EntityAdapter, EntityState, createEntityAdapter} from '@ngrx/entity';
import * as fromActions from '@core/core-store/user-photo/user-photo.actions';

export interface UserPhoto {
  login: string;
  url?: string;
  requested: boolean;
  failure: boolean;
  userNotFound: boolean;
}

export interface State extends EntityState<UserPhoto> {}

export const adapter: EntityAdapter<UserPhoto> =
  createEntityAdapter<UserPhoto>({
    selectId: photo => photo.login
});

export const initialState: State = {
  ...adapter.getInitialState()
};

const userPhotoReducer = createReducer(
  initialState,
  on(fromActions.getUserPhoto, (state, {login}) => (
    adapter.addOne({login, url: null, requested: false, failure: false, userNotFound: false}, state)
  )),
    on(fromActions.getUserPhotoRequest, (state, {login}) => (
      adapter.updateOne({id: login, changes: {requested: true}}, state)
  )),
  on(fromActions.getUserPhotoSuccess, (state, {login, url}) => (
    adapter.updateOne({id: login, changes: {url}}, state)
  )),
  on(fromActions.getUserPhotoFailure, (state, {userNotFound, login}) => (
    adapter.updateOne({id: login, changes: {failure: true, userNotFound}}, state)
  ))
);

export function reducer(state: State | undefined, action: Action) {
  return userPhotoReducer(state, action);
}

export const getIds = adapter.getSelectors().selectIds;
export const getEntities = adapter.getSelectors().selectEntities;
export const getAll = adapter.getSelectors().selectAll;

export const getEntityById = (entities: Dictionary<UserPhoto>, {id}) => entities[id];
export const getEntitiesById = (entities: Dictionary<UserPhoto>, {ids}) => ids.map(id => entities[id]);


