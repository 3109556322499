<div class="badge-header" rds-dialog-header>
  <rds-badge class="my-1" label="ALERT" type="minimal" color="red" size="m"></rds-badge>
</div>
<div rds-dialog-content>
  <div>
    <p class="article-heading-3-bold mb-7" >{{alert.title.length > 0? alert.title : 'Alert Title'}}</p>
    <p class="ui-label-m-bold mb-7">
      {{alert.message.length > 0? alert.message : 'The alert message should be short and include only the most important information. '}}
    </p>
  </div>
</div>
<div rds-dialog-actions-container align="end">
  <button rds-text-button >
    <a rds-link standalone size="m" [href]="alert.url" target="_blank">Read more</a>
  </button>
  <button rds-secondary-button [rds-dialog-close]="true" size="m">
    Mark as read and close
  </button>
</div>
