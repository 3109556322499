import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, createEntityAdapter, EntityAdapter, Dictionary } from '@ngrx/entity';

import * as fromActions from './channels-news.actions';
import { Channel, ContentItem } from '@app/core/models';

export const feature = 'channels-news';

export interface ChannelsNews extends Channel {
  news: EntityState<ContentItem>;
}
export interface State extends EntityState<ChannelsNews> {}

export const channelAdapter: EntityAdapter<ChannelsNews> =
 createEntityAdapter<ChannelsNews>();

export const newsAdapter: EntityAdapter<ContentItem> =
 createEntityAdapter<ContentItem>();

export const initialState: State = {
  ...channelAdapter.getInitialState(),
};

const channelsNewsReducer = createReducer(
  initialState,
  on(fromActions.channelsCollectionUpsertOne, (state, {channel}) => {
    if (!!state.entities[channel.id]) {
      return state;
    }
    return (
      channelAdapter.upsertOne({
        ...channel,
        news: newsAdapter.getInitialState()
      }, state)
    );
  }),
  on(fromActions.newsCollectionForChannelAddAll, (state, {channelId, news}) => (
    channelAdapter.updateOne({
      id: channelId,
      changes: {
        news: newsAdapter.setAll(news, state.entities[channelId].news)
      }
    }, state)
  )),
);

export function reducer(state: State | undefined, action: Action) {
  return channelsNewsReducer(state, action);
}

export const getChannelIds = channelAdapter.getSelectors().selectIds;
export const getChannelEntities = channelAdapter.getSelectors().selectEntities;
export const getChannelAll = channelAdapter.getSelectors().selectAll;
export const getChannelTotal = channelAdapter.getSelectors().selectTotal;

export const getChannelEntityById = (entities: Dictionary<ChannelsNews>, {id}) => entities[id];
export const getChannelEntitiesById = (entities: Dictionary<ChannelsNews>, {ids}) => ids.map(id => entities[id]);

export const getNewsIds = newsAdapter.getSelectors().selectIds;
export const getNewsEntities = newsAdapter.getSelectors().selectEntities;
export const getNewsAll = newsAdapter.getSelectors().selectAll;
export const getNewsTotal = newsAdapter.getSelectors().selectTotal;

export const getNewsEntityById = (entities: Dictionary<ContentItem>, {id}) => entities[id];
export const getNewsEntitiesById = (entities: Dictionary<ContentItem>, {ids}) => ids.map(id => entities[id]);
