import { Injectable } from '@angular/core';
import { ContentService } from '@app/core/services/content.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { filter, map, switchMap } from 'rxjs/operators';
import * as fromActions from './channels-news.actions';
import * as fromChannelsSelectors from '../channels/channels.selectors';

@Injectable()
export class ChannelsNewsEffects {

  public addChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addChannel),
      switchMap(({channelId}) => this.store$.pipe(
        select(fromChannelsSelectors.selectEntityById, {id: channelId}),
        filter(channel => !!channel),
        map((channel) => fromActions.channelsCollectionUpsertOne({channel}))
        ))
  ), { dispatch: true});

  public channelsCollectionUpsertOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.channelsCollectionUpsertOne),
      filter(channel => !!channel),
      switchMap(({channel}) => this.contentService.getAllNewsForChannel(channel).pipe(
        map(({news}) => fromActions.newsCollectionForChannelAddAll({channelId: channel.id, news}))
      ))
  ), { dispatch: true});

  constructor(
    private actions$: Actions,
    private store$: Store<any>,
    private contentService: ContentService
  ) {}
}
