import {Component, HostBinding, Input} from '@angular/core';
import {Store} from '@ngrx/store';

@Component({
  selector: 'rh-header-mobile-gcs-menu',
  templateUrl: './header-mobile-gcs-menu.component.html',
  styleUrls: ['./header-mobile-gcs-menu.component.scss'],
})
export class HeaderMobileGcsMenuComponent {
  @Input() isOpen: boolean = false;
  @HostBinding('class.opened') get opened() { return this.isOpen }; 

  constructor(
    private store$: Store
  ) {
  }

}
