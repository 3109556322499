import {Component, Input, OnDestroy} from '@angular/core';
import {ColorModel, NewsletterValueModel} from '@app/newsletter/models';
import {SubSink} from 'subsink';

@Component({
  selector: 'rh-newsletter-preview-without-sections',
  templateUrl: './newsletter-preview-without-sections.component.html',
  styleUrls: ['./newsletter-preview-without-sections.component.scss']
})
export class NewsletterPreviewWithoutSectionsComponent implements OnDestroy {
  private subs: SubSink = new SubSink();
  public rocheLogo = '/assets/images/roche_logo.png';
  public placeholderImage = '/assets/images/news-placeholder.png';

  @Input() selectedIndex: number;
  @Input() newsletter: NewsletterValueModel;
  @Input() overrideColor: ColorModel;
  @Input() newsboardFix = false;
  now = Date.now();

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  incrementIndex(i: string) {
    return parseInt(i, 10) + 1;
  }

  constructor() {
  }

}
