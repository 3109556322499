<rds-form-field>
	<rds-control-label>{{ label }}</rds-control-label>
	<ng-container *ngIf="multi">
		<rds-multi-select
			#select
			#selectNgModel="ngModel"
			[(ngModel)]="selectedLists"
			[disabled]="!options"
			[required]="required"
			(valueChange)="select.resetFilterValue(); selectChanged($event)"
			(openChange)="openChange($event)"
			[filterBy]="filterByMulti"
		>
			<rds-select-search-input #searchInput></rds-select-search-input>
			<ng-container *ngIf="groupByPersonalizationSetting && !personalizedEmpty" ngProjectAs="rds-multi-select-option">
				<p class="ui-overline-m py-3 px-5 rds-menu-header">ELIGIBLE FOR PERSONALIZATION</p>
			</ng-container>
			<rds-multi-select-option
				#personalized
				*ngFor="let option of options | recipientListFilter: (groupByPersonalizationSetting ? 'personalized' : 'all')"
				[id]="option.name"
				[value]="option.id"
				><span [innerHTML]="option.name | highlight: searchInput.control.value"></span
			></rds-multi-select-option>
			<ng-container *ngIf="groupByPersonalizationSetting && !noPersonalizedEmpty" ngProjectAs="rds-multi-select-option">
				<p class="ui-overline-m py-3 px-5 rds-menu-header">OTHERS</p>
			</ng-container>
			<rds-multi-select-option
				#noPersonalized
				*ngFor="
					let option of options | recipientListFilter: (groupByPersonalizationSetting ? 'no_personalized' : 'none')
				"
				[id]="option.name"
				[value]="option.id"
				><span [innerHTML]="option.name | highlight: searchInput.control.value"></span
			></rds-multi-select-option>
		</rds-multi-select>
	</ng-container>
	<ng-container *ngIf="!multi">
		<rds-select
			#select
			#selectNgModel="ngModel"
			[(ngModel)]="selectedLists"
			[disabled]="!options"
			[required]="required"
			(valueChange)="select.resetFilterValue(); selectChanged($event)"
			(openChange)="openChange($event)"
			[filterBy]="filterBySingle"
		>
			<rds-select-search-input #searchInput></rds-select-search-input>
			<rds-select-option-separator></rds-select-option-separator>
			<rds-select-option *ngFor="let option of options" [id]="option.name" [value]="option.id"
				><span [innerHTML]="option.name | highlight: searchInput.control.value"></span
			></rds-select-option>
		</rds-select>
	</ng-container>
</rds-form-field>
<div class="selected-channels">
	<div
		class="selected-channels__chips-list"
		atrOverflowingContent
		overflowingContent="64"
		#overflowing="overflowingContent"
	>
		<rds-chip-list size="s" type="filter">
			<rds-chip *ngIf="selectedLists.length === 0" [disabled]="true">Chosen Recipient list will appear here</rds-chip>
			<rds-chip
				*ngFor="let selected of chipsList; let index = index"
				rdsChipInput
				(click)="detailsClick.emit(selected)"
				(closeChip)="remove(index)"
				[closable]="!disabled ? true : null"
				>{{ selected.name }}</rds-chip
			>
		</rds-chip-list>
	</div>
	<div *ngIf="overflowing.isOverflowing" class="selected-channels__actions-container">
		<button rds-text-button (click)="overflowing.isExpanded ? overflowing.collapse() : overflowing.expand()">
			Show {{ overflowing.isExpanded ? 'less' : 'all' }}
			<rds-icon [icon]="overflowing.isExpanded ? 'chevron_up' : 'chevron_down'"></rds-icon>
		</button>
	</div>
</div>
<rh-subscript-wrapper *ngIf="!!control" [hasError]="!control.valid && control.touched" [disabled]="disabled">
	<ng-content select="rds-control-hint" ngProjectAs="rds-control-hint"></ng-content>
	<ng-content select="rds-control-error" ngProjectAs="rds-control-error"></ng-content>
</rh-subscript-wrapper>
