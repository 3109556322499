<div class="container bg-white">
  <div class="row">
    <div class="col">
      <rds-header searchPosition="none" [minimalMargins]="true"></rds-header>
    </div>
  </div>
</div>
<div class="content container bg-100-neutral-1">
  <div class="row">
    <div class="col-6 offset-3">
      <h1 class="ui-heading-1-bold--xxl pb-5">
        Welcome to RocheHome,
        <br/>
        please sign-in to continue.
      </h1>
      <div id="gbtn"></div>
    </div>
  </div>
</div>