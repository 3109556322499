<h2 rds-dialog-header>{{ data.title }}</h2>
<div rds-dialog-content>
	<div class="row" [formGroup]="form">
		<div class="row">
			<p *ngIf="data.message" class="ui-label-l">{{ data.message }}</p>
		</div>
		<div class="row">
			
			<div class="col mt-5">
				<rh-autocomplete
					uniqueKey="identifier"
					displayKey="email"
					label="Enter user name or email"
					[options]="'send-copy' | autocompletes"
					[preventAdd]="data.preventAddYourself? [currentUser] : []"
					[preventRemove]="notRemovable"
					(search)="getAutocompletes('send-copy', $event)"
					formControlName="users"
					[multi]="true"
					[itemTemplate]="itemTemplate"
					[selectedTemplate]="selectedTemplate"
				>
					<ng-template #itemTemplate let-option="option" let-highlightPhrase="highlightPhrase">
						<rh-user-autocomplete-item [option]="option" [highlightPhrase]="highlightPhrase"></rh-user-autocomplete-item>
					</ng-template>
					<ng-template #selectedTemplate let-selectedOptions="selectedOptions" let-disabled="disabled" let-removeFn="removeFn" let-checkRemovePrevented="checkRemovePrevented">
						<rh-user-autocomplete-selected [selectedOptions]="selectedOptions" [disabled]="disabled" (removed)="removeFn($event)" [checkRemovePrevented]="checkRemovePrevented"></rh-user-autocomplete-selected>
					</ng-template>
				</rh-autocomplete>
			</div>
		</div>
	</div>
</div>
<div rds-dialog-actions-container align="end">
	<button rds-secondary-button [rds-dialog-close]="null" size="m">Cancel</button>
	<button
		rds-primary-button
		[disabled]="form.get('users').valid === false"
		[rds-dialog-close]="form.get('users').value"
		size="m"
	>
		{{ data.confirmButtonLabel || 'Send template' }}
	</button>
</div>
