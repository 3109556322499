<ng-container [ngSwitch]="isMobile">
  <div [@.disabled]="!showButtons" *ngSwitchCase="false" [class]="'ticker ticker--' + tickerClass + ' p-4'">
    <ng-container *ngIf="ticker" [ngSwitch]="tickerClass">
      <rds-badge class="ticker__badge me-5" [@desktopAnimation]="tickerDirection.badge" *ngSwitchCase="'news'" color="warning" [label]="tickerTranslation | translate | uppercase"   size="m" ></rds-badge>
      <rds-badge class="ticker__badge me-5" [@desktopAnimation]="tickerDirection.badge" *ngSwitchCase="'events'" color="purple" type="minimal" [label]="tickerTranslation | translate | uppercase"    size="m"></rds-badge>
      <rds-badge class="ticker__badge me-5" [@desktopAnimation]="tickerDirection.badge" *ngSwitchCase="'updates'" color="success" [label]="tickerTranslation | translate | uppercase"    size="m"></rds-badge>
      <rds-badge class="ticker__badge me-5" [@desktopAnimation]="tickerDirection.badge" *ngSwitchCase="'breaking-news'" color="error" [label]="tickerTranslation | translate | uppercase"    size="m"></rds-badge>
    </ng-container>
    <ng-container *ngIf="ticker">
      <div [@desktopAnimation]="tickerDirection.text" class="ticker__content">
        <div class="ticker__message ui-label-m-bold">
          {{_ticker.message || 'Type in news message'}}
        </div>
        <div *ngIf="!!_ticker.link" class="ticker__read-more ui-label-m-bold">
          <a rds-link standalone size="m" href="" [attr.href]="_ticker.link" target="_blank">&nbsp;&nbsp;Read more</a>
        </div>
      </div>
    </ng-container>
    <div class="ticker__navigation" [hidden]="!ticker">
        <rds-icon *ngIf="showButtons" icon="chevron_left" namespace="outlined" (click)="blockButtons? {} : onChangeTicker(-1)" [hidden]="canChange?.length < 2"></rds-icon>
        <rds-icon *ngIf="showButtons" icon="chevron_right" namespace="outlined" (click)="blockButtons? {} : onChangeTicker(1)" [hidden]="canChange?.length < 2"></rds-icon>
        <rds-icon icon="close" namespace="outlined" (click)="blockButtons? {} : onCloseTicker(ticker.id)" ></rds-icon>
    </div>
  </div>
  <div [@.disabled]="!showButtons" *ngSwitchCase="true" [class]="'ticker-mobile ticker-mobile--' + tickerClass + ' pt-5 px-6 pb-4'">
    <div class="ticker-mobile__top">
      <ng-container *ngIf="ticker" [ngSwitch]="tickerClass">
        <rds-badge class="ticker-mobile__badge me-5" [@mobileAnimation]="tickerDirection.badge" *ngSwitchCase="'news'" color="warning" [label]="tickerTranslation | translate | uppercase"  size="m"></rds-badge>
        <rds-badge class="ticker-mobile__badge me-5" [@mobileAnimation]="tickerDirection.badge" *ngSwitchCase="'events'" color="purple" type="minimal" [label]="tickerTranslation | translate | uppercase"  size="m"></rds-badge>
        <rds-badge class="ticker-mobile__badge me-5" [@mobileAnimation]="tickerDirection.badge" *ngSwitchCase="'updates'" color="success" [label]="tickerTranslation | translate | uppercase"  size="m"></rds-badge>
        <rds-badge class="ticker-mobile__badge me-5" [@mobileAnimation]="tickerDirection.badge" *ngSwitchCase="'breaking-news'" color="error" [label]="tickerTranslation | translate | uppercase"  size="m"></rds-badge>
      </ng-container>
      <div class="ticker-mobile__navigation" [hidden]="!ticker">
        <rds-icon *ngIf="showButtons" icon="chevron_left" namespace="outlined" (click)="blockButtons? {} : onChangeTicker(-1)" [hidden]="canChange?.length < 2"></rds-icon>
        <rds-icon *ngIf="showButtons" icon="chevron_right" namespace="outlined" (click)="blockButtons? {} : onChangeTicker(1)" [hidden]="canChange?.length < 2"></rds-icon>
        <rds-icon *ngIf="!isSinglePreview" icon="close" namespace="outlined" (click)="blockButtons? {} : onCloseTicker(ticker.id)"></rds-icon>
      </div>
    </div>
    <ng-container *ngIf="ticker">
      <div [@mobileAnimation]="tickerDirection.text" class="ticker-mobile__content">
        <div class="ticker-mobile__message ui-label-m-bold">
          {{_ticker.message || 'Type in news message'}}
        </div>
        <div *ngIf="!!_ticker.link" class="ticker-mobile__read-more ui-label-m-bold">
          <a rds-link href="" [attr.href]="_ticker.link" target="_blank">Read more</a>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
