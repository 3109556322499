import { Pipe, PipeTransform } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import * as fromChannelsNews from '@core/core-store/channels-news-all-language/channels-news-all-language.selectors';
import { distinctUntilChanged, map} from 'rxjs/operators';
import { NewsletterNews } from '@app/core/models';

@Pipe({
  name: 'newsForChannel'
})
export class NewsForChannelPipe implements PipeTransform {
  transform(channelId: string, options?: {selectedOnTop: boolean, selectedId: string}): Observable<Array<NewsletterNews>> {
    if (!channelId) {
      return of([])
    }
    return this.store$.pipe(
      select(fromChannelsNews.selectNewsAll(channelId)),
      distinctUntilChanged(),
      map(news => {
        
        if (options && options.selectedOnTop && options.selectedId) {
          const selected = news.find(n => n.newsId === options.selectedId);
          const others = news.filter(n => n.newsId !== options.selectedId)
          return [selected, ...others];
        }
        return news;
        
      })
    );
  }

  constructor(
    private store$: Store<any>) {
  }
}
