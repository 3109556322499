import { Injectable } from '@angular/core';
import { SortDirection } from '@rds/angular-components';

import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';


import { ApiClient } from '@app/core/services/api-newsboard/api-client.service';
import { ChannelTableFilters } from '@app/shared/filters/filters-model';
import { Channel, ChannelAssignment, ChannelCreation, ChannelDetails, ChannelListRequest, PageInformation, PagedData } from '@app/core/models/newsboard';
import { Pagination } from '@app/core/models';

@Injectable({
  providedIn: 'root'
})
export class ChannelService {
  private _channels: BehaviorSubject<Channel[]> = new BehaviorSubject([]);

  private _loading = new ReplaySubject<boolean>(1);

  private _mockData: PagedData<Channel>;
  private _initialDataRequest: ChannelListRequest;

  get channels(): Observable<Channel[]> {
    return this._channels.asObservable();
  }

  get isLoading(): Observable<boolean> {
    return this._loading.asObservable();
  }

  constructor(private client: ApiClient) {

  }

  load(request?: ChannelListRequest, reload = false): Observable<PageInformation> {
    this._channels.next(null);
    this._loading.next(true);

    if (!reload) {
      this._initialDataRequest = request;
    }

    const obs = this.createRequest(request);

    if (reload) {
      obs.subscribe(() => {});
    }

    return obs;
  }

  createRequest(request: ChannelListRequest) {
    return this.client.post('/channels', request).pipe(
      tap((resp: PagedData<Channel>) => {
        this._channels.next(resp.data);
        this._loading.next(false);
      }),
      map(({ data, ...rest }) => {
        return ({ data, ...rest }) ;
      })
    );
  }

  getChannels({pageIndex, pageSize, sort, filters}: {
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
    filters: ChannelTableFilters,
  }, showAll: boolean = false): Observable<{data: Array<Channel>, pagination: Pagination}> {
    const request: ChannelListRequest = {
      count: pageSize,
      offset: pageSize * pageIndex,
      order: sort.direction,
      sortBy: sort.active,
      query: filters.search.value,
      roles: filters.roles.value,
      showAll
    }
    
    return this.client.post('/channels', request).pipe(
      map((res: PagedData<Channel>): {data: Array<Channel>, pagination: Pagination} => {
        return ({ 
          data: res.data, 
          pagination: {
            isFirst: res.offset === 0,
            isLast: ((res.offset / res.perPage) + 1) * res.perPage >= res.total,
            pageCount: Math.ceil(res.total / res.perPage),
            pageIndex: res.offset / res.perPage,
            pageSize: res.perPage,
            totalCount: res.total,
          }
        }) ;
      })
    );
  }

  get(id: number): Observable<ChannelDetails> {
    return this.client.get(`/channel/${id}`);
  }

  listAssignable(newsId?: number): Observable<ChannelAssignment[]> {
    return newsId ? this.client.get(`/channels/assignable/${newsId}`) : this.client.get('/channels/assignable');
  }

  listNonAssignable(newsId?: number): Observable<ChannelAssignment[]> {
    return newsId ? this.client.get(`/channels/other/${newsId}`) : this.client.get('/channels/other');
  }

  create(channel: Partial<ChannelCreation>): Observable<number> {
    return this.client.post('/channel', channel);
  }

  update(channelId: number, channel: Partial<ChannelCreation>): Observable<any> {
    return this.client.put(`/channel/${channelId}`, channel);
  }

  delete(ids: Array<number>): Observable<any> {
    return this.client.delete(`/channel`, ids);
  }

  checkName(name: string, channelId?: number): Observable<any> {
    const id = channelId ? channelId.toString() : '';
    return this.client.put(`/channel/unique/${id}`, { name });
  }
}
