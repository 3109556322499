import {Action, createReducer, on} from '@ngrx/store';
import {UserSharedProfile} from '@app/newsletter/models';
import {STATUS_FAILURE, STATUS_LOADING, STATUS_SUCCESS, UserSearchSuggestion} from '@app/core/models';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as fromActions from './newsletter-share.actions';

export const adapter: EntityAdapter<UserSharedProfile> = createEntityAdapter<UserSharedProfile>({
  selectId: (user) => user.login
});


export interface State extends UserSharedProfile {
  newsletterContributors: EntityState<UserSharedProfile>;
  initNewsletterContributors: EntityState<UserSharedProfile>;
  emailSuggestions: UserSearchSuggestion;
}

export const initialState: State = {
  newsletterId: null,
  login: null,
  firstName: null,
  lastName: null,
  photoUrl: null,
  email: null,
  newsletterContributors: {...adapter.getInitialState()},
  initNewsletterContributors: {...adapter.getInitialState()},
  emailSuggestions: null
};

export const newsletterShareReducer = createReducer(
  initialState,
  on(fromActions.getSearchedUser, (state) => ({
    ...state,
    email: '',
    ...STATUS_LOADING,
  })),
  on(fromActions.getSearchedUserSuccess, (state, {email, login, firstName, lastName, photoUrl}) => ({
    ...state,
    ...STATUS_SUCCESS,
    email,
    login,
    firstName,
    lastName,
    photoUrl
  })),
  on(fromActions.getSearchedUserFailure, (state) => ({
    ...state,
    ...STATUS_FAILURE,
  })),
  on(fromActions.getUserSearchSuccess, (state, {usersSearched}) => ({
    ...state,
    emailSuggestions: {
      ...state.emailSuggestions,
      suggestions: usersSearched
    }
  })),
  on(fromActions.clearEmailSearch, (state) => ({
    ...state,
    emailSuggestions: null
  })),
  on(fromActions.openShareNewsletterDialog, fromActions.openChangeOwnerDialog, (state) => ({
    ...state,
    newsletterContributors: adapter.removeAll(state.newsletterContributors),
  })),
  on(fromActions.initContributors, (state, {newsletterContributors}) => ({
    ...state,
    initNewsletterContributors: adapter.addMany(newsletterContributors, state.newsletterContributors),
    newsletterContributors: adapter.addMany(newsletterContributors, state.newsletterContributors)
  })),
  on(fromActions.addContributor, (state, {contributor}) => ({
    ...state,
    newsletterContributors: adapter.upsertOne(contributor, state.newsletterContributors)
  })),
  on(fromActions.removeContributor, (state, {contributor}) => ({
    ...state,
    newsletterContributors: adapter.removeOne(contributor.login, state.newsletterContributors)
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return newsletterShareReducer(state, action);
}

export const getEmail = (state: State) => state.email;
export const getLogin = (state: State) => state.login;
export const getUserData = (state: State) => ({
  email: state.email,
  login: state.login,
  firstName: state.firstName,
  lastName: state.lastName,
  photoUrl: state.photoUrl
});
export const getEmailSuggestion = (state: State) => state.emailSuggestions;
export const getNewsletterContributors = (state: State) => state.newsletterContributors;
