import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { Comment } from '@app/core/models/comment.model';
import { ApiClient } from './api-newsboard/api-client.service';

@Injectable()
export class CommentService {

  public getCommentsByNewsId(
    newsId: string
  ): Observable<Array<Comment>> {
    
    return this.client.get(`/comment/list/${newsId.split(':')[2]}`)
  }

  public createComment(
    newsId: string,
    text: string,
    isAnonymous = false,
    parentId?: number
  ): Observable<Comment> {
    let req_body;
    if (parentId) {
      req_body = { newsId: `${newsId.split(':')[2]}`, text, isAnonymous, parentId };
    } else {
      req_body = { newsId: `${newsId.split(':')[2]}`, text, isAnonymous };
    }

    return this.client.post(`/comment`, req_body)
  }

  public updateComment(
    commentId: number,
    text: string,
  ): Observable<Comment> {
    return this.client.put(`/comment/${commentId}`, { text })
  }
  
  public removeComment(
    commentId: number,
  ): Observable<Comment> {
    return this.client.delete(`/comment/${commentId}`)
  }
  
  public hideComment(
    commentId: number,
  ): Observable<Comment> {
    return this.client.put(`/comment/hide/${commentId}`, null)
  }
  
  public showComment(
    commentId: number
  ): Observable<Comment> {
    return this.client.put(`/comment/show/${commentId}`, null)
  }

  constructor(private client: ApiClient) {

  }


}
