import { Component, Inject } from '@angular/core';
import { RDS_DIALOG_DATA } from '@rds/angular-components';
import { RhRecipientsListForm } from '@app/newsletter-new/models/recipients-list';

export interface RecipientsListDialogData {
	title: string;
	form: Partial<RhRecipientsListForm>;
	cancelButtonLabel?: string;
	confirmButtonLabel?: string;
	excludedRada?: number;
	excludedBlock?: number;
	shortLabel?: boolean;
}

@Component({
	selector: 'rh-recipients-list-confirm-dialog',
	templateUrl: './recipients-list-confirm-dialog.component.html',
	styleUrls: ['./recipients-list-confirm-dialog.component.scss'],
})
export class RecipientsListConfirmDialogComponent {
	constructor(@Inject(RDS_DIALOG_DATA) public data: RecipientsListDialogData) {
	}
}
