import { Component, Input } from '@angular/core';
import { UserProfile } from '@core/models/user-profile.model';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromUser from '@core/user/store';
@Component({
  selector: 'rh-profile-header',
  templateUrl: './profile-header.container.html',
  styleUrls: ['./profile-header.container.scss']
})
export class ProfileHeaderContainer {

  @Input() userProfile: UserProfile;
  constructor( private store$: Store<fromUser.State>,) {}

  public userProfile$: Observable<UserProfile> = this.store$.pipe(
    select(fromUser.selectUserProfile));

  public userProfileLoadFailure$: Observable<boolean> = this.store$.pipe(
    select(fromUser.selectFailure));
}
