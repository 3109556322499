import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {ChannelNotificationSettings, NotificationsNewsType} from '@app/core/models/notifications.model';
import * as fromNotification from '@app/user-settings/store/notification-settings';
import {select, Store} from '@ngrx/store';
import {from, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';
import {SubSink} from 'subsink';
import * as fromHeader from '@app/layout/header/store/header/header.selectors';

@Component({
  selector: 'atr-notifications',
  templateUrl: './notifications.container.html',
  styleUrls: ['./notifications.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsContainer implements OnInit, OnDestroy {
  private subs = new SubSink();

  form: FormGroup = new FormGroup({
    notificationsEnabled: new FormControl(false),
    channelSettings: new FormArray([])
  });

  notificationGuideLink$: Observable<string> = this.store$.pipe(select(fromHeader.selectNotificationGuideLink));

  get notificationsEnabledControl() {
    return this.form.controls.notificationsEnabled as FormControl;
  }

  get channelSettingsArray() {
    return this.form.controls.channelSettings as FormArray;
  }

  initialChannelSettingsArray;

  get nothingChanged() {
    return JSON.stringify(this.initialChannelSettingsArray) === JSON.stringify(this.channelSettingsArray.value);
  }

  areNotificationPermissionGranted$ = from(Notification.requestPermission()).pipe(
    map((permission) => permission === 'granted')
  );

  NotificationsNewsType = NotificationsNewsType;

  notificationsEnabled$: Observable<boolean> = this.store$.pipe(select(fromNotification.selectNotificationsEnabled));
  channelSettings$: Observable<Array<ChannelNotificationSettings>> = this.store$.pipe(select(fromNotification.selectChannelSettings));
  initialChannelSettings$: Observable<Array<ChannelNotificationSettings>> = this.store$.pipe(select(fromNotification.selectInitialChannelSettings));

  ngOnInit() {
    this.store$.dispatch(fromNotification.initForm());

    this.subs.sink = this.notificationsEnabled$.pipe(distinctUntilChanged(), debounceTime(50)).subscribe(enabled => {
      this.notificationsEnabledControl.patchValue(enabled, {emitEvent: false})
    });

    this.subs.sink = this.notificationsEnabledControl.valueChanges.subscribe((enabled) => {

      this.store$.dispatch(fromNotification.setNotificationsEnabled({notificationsEnabled: enabled}))
      this.store$.dispatch(fromNotification.saveAllowNotificationsRequest())
    });

    this.subs.sink = this.initialChannelSettings$.pipe(
      distinctUntilChanged(),
      debounceTime(50)
    ).subscribe(channelSettings => {
      this.initialChannelSettingsArray = channelSettings;
      this.cdr.markForCheck();
    });

    this.subs.sink = this.channelSettings$.pipe(
      distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next)),
      debounceTime(50),
      filter(channelSettings => channelSettings.length > 0),
    ).subscribe(channelSettings => {
      channelSettings.forEach((setting, index) => {
        if (!!this.channelSettingsArray.at(index)) {
          this.channelSettingsArray.at(index).patchValue(setting, {emitEvent: false});
        } else {
          this.channelSettingsArray.push(new FormGroup({
            notificationsEnabled: new FormControl(setting.notificationsEnabled),
            channelId: new FormControl(setting.channelId),
            notificationsType: new FormControl(setting.notificationsType),
            name: new FormControl(setting.name),
          }), {emitEvent: false});
        }
      });
      this.cdr.markForCheck();
    });

    this.subs.sink = this.channelSettingsArray.valueChanges.pipe(
      distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next)),
      debounceTime(50)).subscribe((channelSettings) => {
      this.store$.dispatch(fromNotification.setChannelSettings({channelSettings}))
    });

  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.store$.dispatch(fromNotification.clearAll());
  }

  reset() {
    this.store$.dispatch(fromNotification.openResetChannelSettingsConfirmDialog());
  }

  cancel() {
    this.channelSettingsArray.patchValue(this.initialChannelSettingsArray);
  }

  save() {
    this.store$.dispatch(fromNotification.saveChannelSettingsRequest());
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private store$: Store<fromNotification.State>
  ) {
  }
}
