<h2 rds-dialog-header>
    Important notice
  </h2>
<div rds-dialog-content>
    <div class="ui-label-m">
        <p>
            You are going to embed a Streaming Media platform video. Please make sure that this resource is
            <b>Published</b>.
        </p>
        <p>
            Only published videos will be shown via RocheHome platform. Visit <a [href]="data.url"
                target="_blank">Streaming Media page</a> to check the publication status.
        </p>
        <br/>
        <p>
            More information about Streaming Media platform you can find in <a
                href="https://docs.google.com/document/d/11bNw33kmt-8Dl4tj_Vrk0BKGO2Yp5WpMU9Ngd-JUtGk/edit"
                target="_blank">this document</a>
        </p>
    </div>
</div>
<div rds-dialog-actions-container align="end">
    <button rds-secondary-button size="m" (click)="close()">Cancel</button>
    <button rds-primary-button size="m"  (click)="confirm()">Confirm</button>
</div>