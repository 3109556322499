<div class="row">
  <div class="col-auto lmt-8 offset-both">
    <rds-empty-state alignment="center" size="m">
      <img rds-empty-state-image src="../../../../assets/images/404.png"/>
      <rds-empty-state-title class="mb-3">Not found</rds-empty-state-title>
      <rds-empty-state-subtitle class="mb-3">We are sorry but this page does not exist.<br/>Go home or try again later
      </rds-empty-state-subtitle>
      <button class="m-auto mt-4" rds-primary-button rds-empty-state-button [routerLink]="''">Home</button>
    </rds-empty-state>
  </div>
</div>
