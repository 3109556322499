import { createSelector } from '@ngrx/store';
import * as fromNewsletter from '@app/newsletter-new/store/newsletter.reducer';
import * as fromReducer from '@app/newsletter-new/store/recipients-table/recipients-table.reducer';

export const selectRecipientsTable = createSelector(
  fromNewsletter.selectState,
  fromNewsletter.getRecipientsTable
);

export const selectTableState = createSelector(
  selectRecipientsTable,
  fromReducer.getTableState
);

export const selectTableRequestData = createSelector(
  selectTableState,
  fromReducer.getRequestData
);

export const selectTableData = createSelector(
  selectTableState,
  fromReducer.getTableData
);

export const selectTablePagination = createSelector(
  selectTableState,
  fromReducer.getPagination
);

export const selectTableTotal = createSelector(
  selectTablePagination,
  fromReducer.getTotalCount
);

export const selectTableSort = createSelector(
  selectTableState,
  fromReducer.getSort
);

export const selectTableDefaultSort = createSelector(
  selectTableState,
  fromReducer.getDefaultSort
);

export const selectTableFilters = createSelector(
  selectTableState,
  fromReducer.getFilters
);

export const selectTableDefaultFilters = createSelector(
  selectTableState,
  fromReducer.getDefaultFilters
);

export const selectTableSearch = createSelector(
  selectTableFilters,
  fromReducer.getSearch
);

export const selectTableIsLoading = createSelector(
  selectTableState,
  fromReducer.getIsLoading
);


export const selectSentToMe = createSelector(
  selectRecipientsTable,
  fromReducer.getSentToMe
);
