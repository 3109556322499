import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService, HttpError } from '@core/services/api/api.service';
import { EndpointsService } from '@core/services/endpoints/endpoints.service';
import { GenericAccount, GenericAccountsRequest, GenericAccountsResponse } from '@core/models';
import { Sort } from '@rds/angular-components';

@Injectable()
export class GenericAccountsService {

  postAccount( user: GenericAccount): Observable<any> {
    const url = this.endpoints.ENDPOINT.WORKDAY.ACCOUNT.POST;
    return this.http
      .post(url, user)
      .pipe(
        map(data => ({...data})),
        catchError((error: HttpError) => throwError(`Cannot save user. ${error.message}`))
      );
  }

  updateAccount( username: string, user: GenericAccount): Observable<any> {
    const url = this.endpoints.ENDPOINT.WORKDAY.ACCOUNT.UPDATE;
    return this.http
      .put(url, user)
      .pipe(
        map(data => ({...data})),
        catchError((error: HttpError) => throwError(`Cannot save user. ${error.message}`))
      );
  }

  getByUsername(username: string): Observable<GenericAccount> {
    const url = this.endpoints.addParams(this.endpoints.ENDPOINT.WORKDAY.ACCOUNT.GET, {username});
    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get user. ${error.message}`))
      );
  }

  getIsUsernameUnique(username: string): Observable<boolean> {
    const url = this.endpoints.addParams(this.endpoints.ENDPOINT.WORKDAY.ACCOUNT.IS_USERNAME_UNIQUE, {username});
    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get unique. ${error.message}`))
      );
  }

  getIsEmailUnique(email: string): Observable<boolean> {
    const url = this.endpoints.addParams(this.endpoints.ENDPOINT.WORKDAY.ACCOUNT.IS_EMAIL_UNIQUE, {email});
    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get unique. ${error.message}`))
      );
  }

  getGenericAccounts(
    {sort, filters, pageIndex, pageSize}: {
      sort: Sort,
      filters: { [key: string]: any },
      pageIndex: number,
      pageSize: number
    }
  ): Observable<GenericAccountsResponse> {
    const url = this.endpoints.ENDPOINT.WORKDAY.ACCOUNT.PUT;
    const body: GenericAccountsRequest = {
      order: `${sort.active} ${sort.direction}`,
      search: filters.search
    };

    return this.http
      .put(this.endpoints.addParams(url, {pageIndex, pageSize}), body).pipe(
        map((res) => ({
          data: res.data,
          pagination: {
            isFirst: res.isFirst,
            isLast: res.isLast,
            pageCount: res.pageCount,
            pageIndex: res.pageIndex,
            pageSize: res.pageSize,
            totalCount: res.totalCount,
          }
        }))
      );
  }

  deleteGenericAccount(username: string) {
    const url = this.endpoints.replaceUrlTokens(this.endpoints.ENDPOINT.WORKDAY.ACCOUNT.DELETE, {userId: username});

    return this.http
      .delete(url);
  }

  constructor(
    private http: ApiService,
    private endpoints: EndpointsService,
    ) {}
}
