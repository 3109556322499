import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {TickerNewsModel} from '@core/models';
import {TICKER_NEWS_TYPES} from '@app/core/models/ticker.model';
import {interval, Subscription} from 'rxjs';

@Component({
  selector: 'atr-tickers',
  templateUrl: './tickers.component.html',
  styleUrls: ['./tickers.component.scss']
})
export class TickersComponent implements OnDestroy {
  private tickerNumber = 0;
  public animationSubscribe = new Subscription();
  public animationTime = 5000;
  public displayedTicker: TickerNewsModel;
  public tickerTypeClass: string;
  public tickerTypeTranslation: string;
  public tickerDirection = { badge: 'up', text: 'down'};
  public canChange: {
    prev: true | false;
    next: true | false;
    length: number;
  } = {prev: false, next: false, length: 0};
  _tickersOptions: TickerNewsModel[];
  get tickersOptions() {
    if (this.tickerNumber > this._tickersOptions.length) {
      this.tickerNumber = 0;
    }
    return this._tickersOptions;
  }

  @Input() set tickersOptions(tickers: TickerNewsModel[]) {
    if (this.isSinglePreview || (!this._tickersOptions || this._tickersOptions.length !== tickers.length)) {
      this._tickersOptions = tickers;
      this.displayTicker();
    }
  }

  @Input() isSinglePreview: true | false;
  @Input() isMobile = false;

  @Output() closeTicker: EventEmitter<any> = new EventEmitter();

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnDestroy(): void {
    this.animationSubscribe.unsubscribe();
  }

  private getDisplayedTickerType(): void {
    for (const i of TICKER_NEWS_TYPES) {
      if (this.displayedTicker && i.value === this.displayedTicker.type) {
        this.tickerTypeClass = i.class;
        this.tickerTypeTranslation = i.label;
      }
    }
  }

  private displayTicker(): void {
    if (this._tickersOptions.length) {
      this.displayedTicker = this._tickersOptions[this.tickerNumber];
      this.cdr.detectChanges();
      this.getDisplayedTickerType();
      this.checkCanChange();
      if (this._tickersOptions.length > 1) {
        this.startAnimation();
      } else {
        if (this.animationSubscribe) {
          this.animationSubscribe.unsubscribe();
        }
      }
    }
  }


  public changeTicker(num: number) {
    num > 0 ? this.tickerDirection = { badge: 'next', text: 'next'} : this.tickerDirection = { badge: 'prev', text: 'prev'}
    this.displayedTicker = null;
    this.cdr.detectChanges();
    this.tickerNumber += num;
    if (this.tickerNumber === this.tickersOptions.length) {
      this.tickerNumber = 0;
    } else if (this.tickerNumber < 0) {
      this.tickerNumber = this.tickersOptions.length - 1;
    }
    this.displayTicker();
  }


  private checkCanChange() {
    const num = 1;

    this.canChange.next = true;
    if (this.tickerNumber + num > this._tickersOptions.length) {
      this.canChange.next = false;
    }

    this.canChange.prev = true;
    if (this.tickerNumber < 1) {
      this.canChange.prev = false;
    }
    this.canChange.length = this._tickersOptions.length;
  }

  public onCloseTicker(id) {
    if (this.tickersOptions.length - 1 === this.tickerNumber) {
      this.tickerNumber = -1;
      this.changeTicker(1);
    }
    this.closeTicker.emit(id);
    if (this.animationSubscribe) {
      this.animationSubscribe.unsubscribe();
    }
  }

  public startAnimation() {
    const source = interval(this.animationTime);
    if (this.animationSubscribe) {
      this.animationSubscribe.unsubscribe();
    }
    this.animationSubscribe = source.subscribe(() => {
      if (this.canChange.next) {
        this.changeTicker(1);

      } else {
        if (this.tickerNumber > 1) {
          this.tickerNumber = -1;
          this.changeTicker(1);
        }
      }
    });
  }
}
