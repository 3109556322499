<div class="container bg-white">
  <div class="row">
    <div class="col">
      <rds-header searchPosition="none" [minimalMargins]="true"></rds-header>
    </div>
  </div>
</div>
<div class="content container bg-100-neutral-1">
  <div class="row">
    <div class="col-6 offset-3">
      <h1 class="ui-heading-1-bold--xxl pb-5">
        Your credentials haven’t been synchronized properly,
        <br/>
        this may take a few days to synchronize.
      </h1>
    </div>
  </div>
</div>