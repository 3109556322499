import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Channel, NewsletterNews } from '@app/core/models';
import { BlockTypes, ChannelBlockProperties } from '@app/newsletter-new/models/block-type.enum';
import { ConfirmDialogComponent, ConfirmDialogData } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { CustomValidators } from '@app/shared/form-controls/validators/validator.function';
import * as fromChannels from '@core/core-store/channels';
import * as fromChannelsNews from '@core/core-store/channels-news-all-language';
import * as fromLanguages from '@core/core-store/languages-dict';
import { Store, select } from '@ngrx/store';
import {
	RDS_DIALOG_DATA,
	RdsDialogRef,
	RdsDialogService,
	RdsSingleSelectFilterByFunc,
	RdsSingleSelectOptionComponent,
} from '@rds/angular-components';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';

export interface SelectChannelDialogData {
	id: number;
	blockType: BlockTypes;
	addNewAsCard?: boolean;
	settings: ChannelBlockProperties;
}

@Component({
	selector: 'rh-select-channel-dialog',
	templateUrl: './select-channel-dialog.component.html',
	styleUrls: ['./select-channel-dialog.component.scss'],
})
export class SelectChannelNewDialogComponent implements OnInit, OnDestroy {
	private subs = new SubSink();

	public channels$: Observable<Array<Channel>> = this.store$.pipe(select(fromChannels.selectAllNotEmpty));
	public news$: Observable<Array<NewsletterNews>> = of([]);
	allNews: Array<NewsletterNews> = [];
	public channelsNews$: Observable<Array<NewsletterNews>> = of([]);
	newsletterLanguage: string = null;
	availableLanguages: Array<string> = [];
	userCustomTitle = false;
	channelForm: FormGroup = new FormGroup({
		channel: new FormControl(null, [Validators.required]),
		// did we really need this here?
		layout: new FormControl(0),
		addLink: new FormControl(false),
		linkText: new FormControl(null),
		selectionType: new FormControl('recent'),
		recent: new FormGroup({
			newsCount: new FormControl({ value: 5, disabled: false }, [
				Validators.required,
				Validators.min(1),
				Validators.max(10),
			]),
			selectedNews: new FormArray([], [CustomValidators.notEmptyList]),
		}),
		manual: new FormGroup({
			languageFilter: new FormControl(null),
			selectedNews: new FormArray([]),
		}),
	});

	get newsArray() {
		return this.channelForm.controls.news as FormArray;
	}

	get recentFormGroup() {
		return this.channelForm.controls.recent as FormGroup;
	}

	get manualFormGroup() {
		return this.channelForm.controls.manual as FormGroup;
	}

	newsCount = 10;

	get maxNumberOfNews() {
		return Math.min(this.newsCount, 10);
	}

	constructor(
		private dialogRef: RdsDialogRef<SelectChannelNewDialogComponent>,
		@Inject(RDS_DIALOG_DATA) public data: SelectChannelDialogData,
		private cdr: ChangeDetectorRef,
		private store$: Store<any>,
		private dialogService: RdsDialogService
	) {}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit() {
		this.store$.dispatch(fromLanguages.loadLanguagesDict());
		if (this.data.settings.channel) {
			this.createChannelForm(this.data.settings);
		}
		this.subs.sink = this.channelForm.controls.selectionType.valueChanges.subscribe((selectionType) => {
			switch (selectionType) {
				case 'recent': {
					this.manualFormGroup.controls.selectedNews.setValidators([]);
					this.recentFormGroup.controls.newsCount.setValidators([
						Validators.required,
						Validators.min(1),
						Validators.max(this.allNews.length),
					]);
					this.recentFormGroup.controls.selectedNews.setValidators([CustomValidators.notEmptyList]);
					break;
				}
				case 'manual': {
					this.manualFormGroup.controls.selectedNews.setValidators([CustomValidators.notEmptyList]);
					this.recentFormGroup.controls.newsCount.setValidators([]);
					this.recentFormGroup.controls.selectedNews.setValidators([]);
					break;
				}
			}
			this.channelForm.updateValueAndValidity();
		})

		this.subs.sink = this.channelForm.controls.channel.valueChanges
			.pipe(
				startWith(null),
				distinctUntilChanged((prev, curr) => prev?.id === curr?.id),
				filter((channel) => !!channel),
				tap((channel) => {
					this.allNews = [];
					(this.recentFormGroup.controls.selectedNews as FormArray).clear();
					(this.manualFormGroup.controls.selectedNews as FormArray).clear();
					this.manualFormGroup.controls.languageFilter.setValue(null);
				}),
				switchMap((channel) => 
					this.store$.pipe(
						select(fromChannelsNews.selectNewsAll((channel as Channel).id)),
						filter((news) => !!news.length),
						map((news) => ({ channel, news }))
					)
				)
			)
			.subscribe(({ channel, news }) => {
				this.allNews = news;
				if (news.length > 0) {
					this.recentFormGroup.controls.newsCount.setValidators([
						Validators.required,
						Validators.min(1),
						Validators.max(news.length),
					]);
					this.recentFormGroup.controls.newsCount.setValue(
						Math.min(this.recentFormGroup.controls.newsCount.value, news.length)
					);
					this.recentFormGroup.controls.newsCount.updateValueAndValidity();
				}
				this.availableLanguages = news.reduce(
					(result, item) => [...result, ...Object.keys(item.abstracts).filter((l) => !result.includes(l))],
					[]
				);
				news.slice(0, this.recentFormGroup.controls.newsCount.value).map((n) => {
					this.selectNews(this.recentFormGroup.controls.selectedNews as FormArray, n);
					this.selectNews(this.manualFormGroup.controls.selectedNews as FormArray, n);
				});

				if (this.userCustomTitle) {
					const data: ConfirmDialogData = {
						title: 'Override label?',
						messages: [
							'We detected that you changed default link text.',
							'Would you like to override text with new channel name or keep current one?',
						],
						cancelButtonLabel: 'Keep current text',
						confirmButtonLabel: 'Use new channel name',
						confirmButtonType: 'primary',
					};
					const dialog = this.dialogService.open(ConfirmDialogComponent, {
						data,
					});

					this.subs.sink = dialog
						.afterClosed()
						.pipe(filter((data) => !!data))
						.subscribe(() => {
							this.channelForm.controls.linkText.setValue(channel.name);
							this.userCustomTitle = false;
						});
				} else {
					this.channelForm.controls.linkText.setValue(channel.name);
					this.userCustomTitle = false;
				}

				this.cdr.detectChanges();
			});

		this.subs.sink = this.recentFormGroup.controls.newsCount.valueChanges.subscribe((count) => {
			(this.recentFormGroup.controls.selectedNews as FormArray).clear();
			this.allNews.slice(0, count).map((n) => {
				this.selectNews(this.recentFormGroup.controls.selectedNews as FormArray, n);
			});
		});

		this.subs.sink = this.channelForm.controls.linkText.valueChanges.subscribe((linkText) => {
			const channelName = this.channelForm.controls.channel.value.title;
			this.userCustomTitle = linkText !== channelName;
		});
	}

	channelChange(channel) {
		this.store$.dispatch(fromChannelsNews.addChannel({ channelId: channel.id }));
	}

	refreshChannelNews() {}

	newsChange() {
		this.cdr.detectChanges();
	}

	selectNews(formArray: FormArray, news: NewsletterNews, language?) {
		if ((formArray.value as Array<{ news: NewsletterNews }>).findIndex((n) => n.news.newsId === news.newsId) > -1) {
			return;
		}
		const newsFormGroup = new FormGroup({
			news: new FormControl(news),
			language: new FormControl({
				value: language || news.primaryLanguage,
				disabled: Object.keys(news.abstracts).length === 1,
			}),
		});
		formArray.push(newsFormGroup);
	}

	deselectNews(formArray: FormArray, index: number) {
		formArray.removeAt(index);
	}

	submit() {
		const formRawValue = this.channelForm.getRawValue();
		let settings: ChannelBlockProperties = {
			channel: formRawValue.channel,
			selectionType: formRawValue.selectionType,
			selectedNews:
				formRawValue.selectionType === 'recent' ? formRawValue.recent.selectedNews : formRawValue.manual.selectedNews,
			addLink: formRawValue.addLink,
			linkText: formRawValue.linkText,
			card: this.data.addNewAsCard,
		};
		this.dialogRef.close({
			...this.data,
			settings,
		});
	}

	filterBy: RdsSingleSelectFilterByFunc<Channel> = (
		text: string | null,
		item: RdsSingleSelectOptionComponent<Channel>
	) => {
		if (text === null || text === '') {
			return true;
		} else {
			return (
				item.value.name.toLowerCase().includes(text.toLowerCase()) || item.id.toLowerCase().includes(text.toLowerCase())
			);
		}
	};

	filterChannelNews(news: NewsletterNews, args: Array<any>) {
		const language: string = args[0];
		const selectedNews: Array<{ news: NewsletterNews }> = args[1];
		return (
			(!language || Object.keys(news.abstracts).includes(language)) &&
			selectedNews?.findIndex((n) => n.news.newsId === news.newsId) === -1
		);
	}

	drop(event: CdkDragDrop<FormGroup[]>) {
		const from = event.previousIndex;
		const to = event.currentIndex;
		this.moveItemInFormArray(this.manualFormGroup.controls.selectedNews as FormArray, from, to);
	}

	createChannelForm(settings: ChannelBlockProperties) {
		this.channelForm.controls.channel.setValue(settings.channel, { emitEvent: false });
		this.channelForm.controls.layout.setValue(settings.card ? 1 : 0);
		this.channelForm.controls.selectionType.setValue(settings.selectionType, { emitEvent: false });
		this.channelForm.controls.addLink.setValue(settings.addLink, { emitEvent: false });
		if (settings.addLink && settings.linkText !== settings.channel.name) {
			this.channelForm.controls.linkText.setValue(settings.linkText, { emitEvent: false });
			this.userCustomTitle = true;
		} else {
			this.channelForm.controls.linkText.setValue(settings.channel.name, { emitEvent: false });
		}
		let initNewsSub = this.store$
			.pipe(select(fromChannelsNews.selectNewsAll(settings.channel.id)), debounceTime(300))
			.subscribe((news) => {
				this.allNews = news;
				switch (settings.selectionType) {
					case 'recent': {
						// set recentFormGroup based on settings
						settings.selectedNews.map((n) =>
							this.selectNews(this.recentFormGroup.controls.selectedNews as FormArray, n.news, n.language)
						);
						this.recentFormGroup.controls.newsCount.setValue(settings.selectedNews.length || 5, { emitEvent: false });

						// fill manualFormGroup with 5 latest news from all news
						news.slice(0, 5).map((n) => this.selectNews(this.manualFormGroup.controls.selectedNews as FormArray, n));
						this.manualFormGroup.controls.languageFilter.setValue(null, { emitEvent: false });
						break;
					}
					case 'manual': {
						// set manualFormGroup based on settings
						settings.selectedNews.map((n) =>
							this.selectNews(this.manualFormGroup.controls.selectedNews as FormArray, n.news, n.language)
						);
						this.manualFormGroup.controls.languageFilter.setValue(null, { emitEvent: false });

						// fill recentFormGroup with 5 latest news from all news
						news.slice(0, 5).map((n) => this.selectNews(this.recentFormGroup.controls.selectedNews as FormArray, n));
						this.recentFormGroup.controls.newsCount.setValue(5, { emitEvent: false });
						break;
					}
				}
				initNewsSub.unsubscribe();
			});

	  }
	  
	moveItemInFormArray(formArray: FormArray, fromIndex: number, toIndex: number): void {
		// Check if the fromIndex and toIndex are within the bounds of the FormArray
		const controlsCount = formArray.length;
		if (fromIndex < 0 || fromIndex >= controlsCount || toIndex < 0 || toIndex >= controlsCount) {
			console.error('moveControlInFormArray: index out of bounds');
			return;
		}

		// Remove the control from its current position
		const [controlToMove] = formArray.controls.splice(fromIndex, 1);

		// Insert the control at the new position
		formArray.controls.splice(toIndex, 0, controlToMove);
	}
}
