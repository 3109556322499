import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromSuggestions from '@app/root-store/suggestions';
import { RDS_DIALOG_DATA, RdsSingleSelectFilterByFunc, RdsSingleSelectOptionComponent } from '@rds/angular-components';
import { first, Observable } from 'rxjs';
import { AutocompleteOptions, SuggestionType } from '@app/root-store/suggestions';

export type SelectType = 'select' | 'autocomplete';

export interface SelectDialogData {
  title: string;
  selects: Array<{
    prop: string;
    selectType: SelectType;
    entityType: SuggestionType;
    autocomplete?: Observable<AutocompleteOptions<any>>;
    options?: Array<any>;
    optionsObs?: Observable<Array<any>>;
    label?: string;
    description?: string;
    tooltipInfo?: string;
    multiple: boolean;
    required: boolean;
    value?: any;
    additionalInformation?: any;
  }>;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
}
@Component({
  selector: 'rh-select-dialog',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['./select-dialog.component.scss']
})
export class SelectDialogComponent {

  selectForm: FormGroup = new FormGroup({})

  filterBy: RdsSingleSelectFilterByFunc<string> = (
    text: string | null,
    item: RdsSingleSelectOptionComponent<string>
  ) => {
    if (text === null || text === "") {
      return true;
    } else {
      console.log(text, item);
      return item.value.toLowerCase().includes(text.toLowerCase()) || item.id.toLowerCase().includes(text.toLowerCase());
    }
  };

  filterByCampaign: RdsSingleSelectFilterByFunc<string> = (
    text: string | null,
    item: RdsSingleSelectOptionComponent<any>
  ) => {
    if (text === null || text === "") {
      return true;
    } else {
      return item.value.name.toLowerCase().includes(text.toLowerCase());
    }
  };

  constructor(
    @Inject(RDS_DIALOG_DATA) public data: SelectDialogData,
    private store$: Store<fromSuggestions.State>) {
    if (this.data.selects.length) {
      this.data.selects.forEach(us => {
        this.selectForm.addControl(us.prop, new FormControl(us.value?.length ? [us.value] : []));
        if (us.required) {
          this.selectForm.controls[us.prop].addValidators(Validators.required);
        }
        if (!!us.optionsObs) {
          us.optionsObs.pipe(first(o => o.length > 0)).subscribe(opts => us.options = opts);
        }
        if (us.selectType === 'autocomplete') {
          this.store$.dispatch(fromSuggestions.initSuggestion({suggestionType: us.entityType, prop: us.prop}));
        }
        this.selectForm.get(us.prop).valueChanges.subscribe(val => us.value = val);
      })
    }
   }

   getAutocompletes(suggestionType, prop, event) {
    this.store$.dispatch(fromSuggestions.loadSuggestion({suggestionType, prop, phrase: event}))
   }


}
