import {ModuleWithProviders, NgModule} from '@angular/core';
import {HeaderModule2} from '@app/layout/header/header.module';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {SharedModule} from '@shared/shared.module';
import {COMPONENTS} from './components';
import {FooterModule} from './footer/footer.module';
import {feature, LayoutEffects, reducer} from './store';

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    SharedModule,
    // HeaderModule,
    HeaderModule2,
    FooterModule,
    StoreModule.forFeature(feature, reducer),
    EffectsModule.forFeature([LayoutEffects]),
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class LayoutModule {
  static forRoot(): ModuleWithProviders<LayoutModule> {
    return {
      ngModule: LayoutModule,
      providers: []
    };
  }
}
