import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';
import * as fromChannelsSetsForm from './channels-sets-form/channels-sets-form.reducer';
import * as fromChannelsSetsTable from './channels-sets-table/channels-sets-table.reducer';
import * as fromQuicklinksSetsForm from './quicklinks-sets-form/quicklinks-sets-form.reducer';
import * as fromQuicklinksSetsTable from './quicklinks-sets-table/quicklinks-sets-table.reducer';

export const feature = 'manageSets';

export interface AudienceManagerInfo {
  isAudienceManager: boolean,
  isOnlyAudienceManager: boolean,
  managedTaxonomy: {
    locations: Array<string>,
    departments: Array<string>,
    functions: Array<string>,
  }
}

export interface State {
  channelsSetsForm: fromChannelsSetsForm.State;
  channelsSetsTable: fromChannelsSetsTable.State;
  quicklinksSetsForm: fromQuicklinksSetsForm.State;
  quicklinksSetsTable: fromQuicklinksSetsTable.State;
}

export function reducer(state: State | undefined, action: Action) {
  return combineReducers({
    channelsSetsForm: fromChannelsSetsForm.reducer,
    channelsSetsTable: fromChannelsSetsTable.reducer,
    quicklinksSetsForm: fromQuicklinksSetsForm.reducer,
    quicklinksSetsTable: fromQuicklinksSetsTable.reducer,
  })(state, action);
}

export const selectState = createFeatureSelector<State>(feature);

export const getChannelsSetsForm = (state: State) => state.channelsSetsForm;
export const getChannelsSetsTable = (state: State) => state.channelsSetsTable;
export const getQuicklinksSetsForm = (state: State) => state.quicklinksSetsForm;
export const getQuicklinksSetsTable = (state: State) => state.quicklinksSetsTable;
