export interface NewsletterInlineForm {
  newsletterId?: number;
  templateId: string;
  title: string;
  rocheLogoInHeader: boolean;
  disclaimerIncluded: boolean;
  titleIncluded: boolean;
  feedbackIncluded: boolean;
  bannerPhotoUrl: string;
  bannerPhotoName: string;
  bannerPhotoSize: number;
  contactEmailForFeedback: string;
  to: string;
  toGroups: string;
  recipientType: string | number;
  content: string;
  subject: string;
  statusId?: number;
  replayTo: string;
  replyToName: string;
  replyTo: string;
  fromName: string;
  senderName: string;
  from: string;
  previewText?: string;
  whenToSend: number;
  scheduleSentDate?: string;
  timeZone?: string;
  taxonomyRecipient: {
    locations: string[],
    departments: string[],
    functions: string[]
  };
  sentBy?: string;
  sentByName?: string;
  color?: string;
  colorDark?: string;
  colorLight?: string;
  step?: number;
  languageId: number;
  googleAnalyticsUrl?: string;
  imagePreviewUrl?: string;
  bannerPhotoIncluded?: boolean;
  isContributed?: boolean;
}

export const EMPTY_NEWSLETTER_INLINE: Partial<NewsletterInlineForm> = {
  templateId: 'NewsletterTemplateInlineEditor',
  title: null,
  rocheLogoInHeader: true,
  disclaimerIncluded: false,
  feedbackIncluded: true,
  titleIncluded: true,
  bannerPhotoUrl: null,
  contactEmailForFeedback: null,
  fromName: null,
  recipientType: 0,
  taxonomyRecipient: {
    departments: [],
    functions: [],
    locations: [],

  },
  from: null,
  senderName: null,
  replyToName: null,
  replyTo: null,
  subject: null,
  previewText: null,
  to: '',
  toGroups: '',
  scheduleSentDate: null,
  timeZone: null,
  color: 'blue',
  step: 2,
  languageId: 7,
  googleAnalyticsUrl: '',
  imagePreviewUrl: '',
  bannerPhotoIncluded: true,
};
