<h2 rds-dialog-header>Are you sure you want to send out this newsletter?</h2>
<div rds-dialog-content>
	<p class="mb-5">When you click on “Yes, send” this newsletter will be sent to</p>
	<p class="mb-2" *ngIf="data.recipientsRadaCount > 0 || data.recipientsCount > 0">
		<rds-badge
			size="m"
			label="{{ data.recipientsCount + ' Recipients' }}"
			type="minimal"
			color="blue"
			*ngIf="data.recipientsCount > 0"
		></rds-badge>
		&nbsp;
		<rds-badge
			size="m"
			label="{{ data.recipientsRadaCount + ' RADA groups' }}"
			type="minimal"
			color="blue"
			*ngIf="data.recipientsRadaCount > 0"
		></rds-badge>
	</p>

	<p class="mb-5" *ngIf="data.recipientsLists.length">
		<rds-chip-list type="choice">
			<rds-chip value="{{ recipient.name }}" *ngFor="let recipient of data.recipientsLists">
				{{ recipient.name }}
			</rds-chip>
		</rds-chip-list>
	</p>
	<p class="mb-5"></p>
	<p class="mb-5">You will not be able to undo this action.</p>
	<p class="mb-5">Please use this newsletter tool responsibly.</p>
</div>
<div rds-dialog-actions-container align="end">
	<button rds-secondary-button [rds-dialog-close]="null" size="m">{{ data.cancelButtonLabel || 'Cancel' }}</button>
	<button rds-primary-button [rds-dialog-close]="true" size="m">{{ data.confirmButtonLabel || 'Confirm' }}</button>
</div>
