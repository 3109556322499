import { Pipe, PipeTransform } from '@angular/core';
import { CatalogEntryForm } from '@app/core/models';

@Pipe({
  name: 'attentionRequired'
})
export class AttentionRequiredPipe implements PipeTransform {

  transform(catalog: CatalogEntryForm): Array<string> {
    const attention = [];
    if (catalog) {
      if (!catalog.isContentOwnerValid) {
        attention.push('Content Owner in your content is not valid.');
      }
      if (!catalog.isContentManagerValid) {
        attention.push('Content Manager in your content is not valid.')
      }
      if (catalog.urlStatus && (catalog.urlStatus === 'Red' || catalog.urlStatus === 3) ) {
        attention.push('The URL in your content does not respond.')
      }
      switch (catalog.status.statusId) {
        case 6:
          attention.push('Your content needs to be reviewed.')
          break;
        case 7:
          attention.push('Your content was automatically deleted due to the expiration.')
          break;
        case 3:
          attention.push('Your content was rejected by the Intranet Coordinator.')
          break;
        case 5: {
          if (catalog.isAttentionRequired) {
            attention.push('Your content needs to be reviewed.');
          }
          break;
        }
      }
    }
    return attention;
  }
}
