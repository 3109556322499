
import { BalloonPanelView, EditorUIView, InlineEditableUIView, ToolbarView } from 'ckeditor5/src/ui';
import { Rect, ResizeObserver, toUnit } from 'ckeditor5/src/utils';

const toPx = toUnit( 'px' );

export default class InlineEditorUIView extends EditorUIView {

	constructor(locale, editingView, editableElement, options = {}) {
        super(locale);
        const t = locale.t;
        this.toolbar = new ToolbarView(locale, {
            shouldGroupWhenFull: options.shouldToolbarGroupWhenFull,
            isFloating: true
        });
        this.set('viewportTopOffset', 0);
        this.panel = new BalloonPanelView(locale);
        this.panelPositions = this._getPanelPositions();
        this.panel.extendTemplate({
            attributes: {
                class: 'force-hide ck-toolbar-container ck-balloon-panel_with-arrow ck-balloon-panel_arrow_s ck-balloon-panel_move-top'
            }
        });
        this.editable = new InlineEditableUIView(locale, editingView, editableElement, {
            label: editableView => {
                return t('Rich Text Editor. Editing area: %0', editableView.name);
            }
        });
        this._resizeObserver = null;
    }

	render() {
		super.render();

		this.body.add( this.panel );
		this.registerChild( this.editable );
		this.panel.content.add( this.toolbar );

		const options = this.toolbar.options;

		// Set toolbar's max-width on the initialization and update it on the editable resize,
		// if 'shouldToolbarGroupWhenFull' in config is set to 'true'.
		if ( options.shouldGroupWhenFull ) {
			const editableElement = this.editable.element;

			this._resizeObserver = new ResizeObserver( editableElement, () => {
				this.toolbar.maxWidth = toPx( new Rect( editableElement ).width );
			} );
		}
	}

	destroy() {
		super.destroy();

		if ( this._resizeObserver ) {
			this._resizeObserver.destroy();
		}
	}

	_getPanelPositionTop( editableRect, panelRect ) {
		let top;

		if ( editableRect.top > panelRect.height + this.viewportTopOffset ) {
			top = editableRect.top - panelRect.height;
		} else if ( editableRect.bottom > panelRect.height + this.viewportTopOffset + 50 ) {
			top = this.viewportTopOffset;
		} else {
			top = editableRect.bottom;
		}

		return top;
	}

	_getPanelPositions() {
		const positions = [
			( editableRect, panelRect ) => {
				return {
					top: this._getPanelPositionTop( editableRect, panelRect ),
					left: editableRect.left,
					name: 'toolbar_west',
					config: {
						withArrow: true
					}
				};
			},
			( editableRect, panelRect ) => {
				return {
					top: this._getPanelPositionTop( editableRect, panelRect ),
					left: editableRect.left + editableRect.width - panelRect.width,
					name: 'toolbar_east',
					config: {
						withArrow: true
					}
				};
			}
		];

		if ( this.locale.uiLanguageDirection === 'ltr' ) {
			return positions;
		} else {
			return positions.reverse();
		}
	}
}
