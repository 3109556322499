import { Channel, ChannelsSet, ChannelsSetForm, ChannelsSetFormChannelFilters } from '@app/core/models';
import { createAction, props } from '@ngrx/store';


export const initAddForm = createAction(
  '[CHANNELS SETS - FORM] Init form',
  props<{
    channelsSet?: Partial<ChannelsSet>,
    audienceManagerInfo: {
      isAudienceManager: boolean,
      isOnlyAudienceManager: boolean,
      managedTaxonomy: {
        locations: Array<string>,
        departments: Array<string>,
        functions: Array<string>,
      }
    }
  }>()
);

export const getChannelsSetDetailsRequest = createAction(
  '[CHANNELS SETS - FORM] Get channels set details request',
  props<{id: number}>()
);

export const getChannelsSetDetailsSuccess = createAction(
  '[CHANNELS SETS - FORM] Get channels set details success',
  props<{channelsSet: Partial<ChannelsSet>}>()
);

export const getChannelsSetDetailsFailure = createAction(
  '[CHANNELS SETS - FORM] Get channels set details failure',
  props<{error: string}>()
);

export const setFormValue = createAction(
  '[CHANNELS SETS - FORM] Set form value',
  props<{form: Partial<ChannelsSetForm>}>()
);
export const setFormTouched = createAction(
  '[CHANNELS SETS - FORM] Set form touched',
  props<{touched: boolean}>()
);

export const createChannelsSetRequest = createAction(
  '[CHANNELS SETS - FORM] Create channels set request'
);

export const createChannelsSetSuccess = createAction(
  '[CHANNELS SETS - FORM] Create channels set success',
);

export const createChannelsSetFailure = createAction(
  '[CHANNELS SETS - FORM] Create channels set failure',
  props<{error: string}>()
);

export const updateChannelsSetRequest = createAction(
  '[CHANNELS SETS - FORM] Update channels set request',
  props<{id: number}>()
);

export const updateChannelsSetSuccess = createAction(
  '[CHANNELS SETS - FORM] Update channels set success',
);

export const updateChannelsSetFailure = createAction(
  '[CHANNELS SETS - FORM] Update channels set failure',
  props<{error: string}>()
);

export const isNameUniqueRequest = createAction(
  '[CHANNELS SETS - FORM] Is name unique request',
  props<{name: string, id?: number}>()
);

export const isNameUniqueSuccess = createAction(
  '[CHANNELS SETS - FORM] Is name unique success',
  props<{isUnique: boolean}>()
);

export const isNameUniqueFailure = createAction(
  '[CHANNELS SETS - FORM] Is name unique failure',
  props<{error: string}>()
);

export const initChannelsFilters = createAction(
  '[CHANNELS SETS - FORM] Init channels filters'
);

export const clearFilteredChannels = createAction(
  '[CHANNELS SETS - FORM] Clear filtered channels'
);

export const getFilteredChannelsRequest = createAction(
  '[CHANNELS SETS - FORM] Get filtered channels request',
  props<{filters: ChannelsSetFormChannelFilters}>()
);

export const getFilteredChannelsSuccess = createAction(
  '[CHANNELS SETS - FORM] Get filtered channels success',
  props<{channels: Array<Channel>}>()
);

export const getFilteredChannelsFailure = createAction(
  '[CHANNELS SETS - FORM] Get filtered channels failure',
  props<{error: string}>()
);

export const deleteChannelsSetRequest = createAction(
  '[CHANNELS SETS - FORM] Delete User role Request',
  props<{id: number}>()
);

export const deleteChannelsSetSuccess = createAction(
  '[CHANNELS SETS - FORM] Delete channels Success',
);

export const deleteChannelsSetFailure = createAction(
  '[CHANNELS SETS - FORM] Delete channels Failure',
  props<{ error: any }>()
);

export const clearAll = createAction(
  '[CHANNELS SETS - FORM] Clear all'
);

export const openIntranetCoordinatorsDetailsDialog = createAction(
  '[CHANNELS SETS - FORM] Open Intranet Coordinator Details dialog'
);