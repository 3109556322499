<h2 rds-dialog-header>{{ data.title }}</h2>
<div rds-dialog-content>
	<div class="row">
		<div class="col mb-5">
			<p class="ui-label-l" *ngIf="data.shortLabel">
				<span class="ui-label-l-bold">{{ data.form.name }} recipient list </span>
			</p>
			<p class="ui-label-l" *ngIf="!data.shortLabel">
				You are going to {{ data.form.id !== null ? 'edit' : 'create' }}:
				<span class="ui-label-l-bold">{{ data.form.name }}</span> recipient list
			</p>
		</div>
	</div>
	<div *ngIf="data.form.recipientRada.length" class="row">
		<div class="col-auto">
			<p class="ui-label-l">RADA group included:</p>
		</div>
		<div class="col mb-5">
			<rds-chip-list size="s">
				<rds-chip *ngFor="let rada of data.form.recipientRada">{{ rada.displayName || rada.name }}</rds-chip>
			</rds-chip-list>
		</div>
	</div>
	<div class="row">
		<div class="col mb-5">
			<p class="ui-label-l">
				Emails list count: <span class="ui-label-l-bold">{{ data.form.recipientUsers.length }}</span>
				<span *ngIf="data.excludedRada">&nbsp;({{ data.excludedRada }} excluded)</span>
			</p>
		</div>
	</div>

	<div *ngIf="data.form.blockRada.length" class="row">
		<div class="col-auto">
			<p class="ui-label-l">RADA group excluded:</p>
		</div>
		<div class="col mb-5">
			<rds-chip-list size="s">
				<rds-chip *ngFor="let rada of data.form.blockRada">{{ rada.displayName || rada.name }}</rds-chip>
			</rds-chip-list>
		</div>
	</div>
	<div class="row" *ngIf="data.form.blockUsers.length">
		<div class="col mb-5">
			<p class="ui-label-l">
				Emails blocked: <span class="ui-label-l-bold">{{ data.form.blockUsers.length }}</span>
				<span *ngIf="data.excludedBlock">&nbsp;({{ data.excludedBlock }} excluded)</span>
			</p>
		</div>
	</div>
</div>
<div rds-dialog-actions-container align="end">
	<button rds-secondary-button [rds-dialog-close]="null" size="m">{{ data.cancelButtonLabel || 'Cancel' }}</button>
	<button rds-primary-button [rds-dialog-close]="true" size="m">
		{{ data.confirmButtonLabel || 'Yes, Create recipients list' }}
	</button>
</div>
