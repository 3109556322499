import { combineReducers, Action, createFeatureSelector } from '@ngrx/store';

import * as fromUserInformation from './user-information/user-information.reducer';
import * as fromUserOrganisation from './user-organisation/user-organisation.reducer';
import * as fromUserNewsArticles from './user-news-articles/user-news-articles.reducer';

export const feature = 'workdayUser';

export interface State {
  information: fromUserInformation.State;
  organisation: fromUserOrganisation.State;
  articles: fromUserNewsArticles.State;
}

export function reducer(state: State | undefined, action: Action) {
  return combineReducers({
    information: fromUserInformation.reducer,
    organisation: fromUserOrganisation.reducer,
    articles: fromUserNewsArticles.reducer
  })(state, action);
}

export const selectState = createFeatureSelector<State>(feature);

export const getUserInformmation = (state: State) => state.information;
export const getUserOrganisation = (state: State) => state.organisation;
export const getUserNewsArticles = (state: State) => state.articles;
