import { createSelector } from '@ngrx/store';

import * as fromUi from '../ui.reducer';
import * as fromScreen from './screen.reducer';

export const selectState = createSelector(
  fromUi.selectState,
  fromUi.getScreen
);

export const selectIsMobile = createSelector(
  selectState,
  fromScreen.getIsMobile
);

export const selectIsTablet = createSelector(
  selectState,
  fromScreen.getIsTablet
);

export const selectScreenMode = createSelector(
  selectState,
  fromScreen.getScreenMode
);

export const selectIsDesktop = createSelector(
  selectState,
  fromScreen.getIsDesktop
);

export const selectRdsBreakpoint = createSelector(
  selectState,
  fromScreen.getRdsBreakpoint
);

export const selectNotVisibleForMobile = createSelector(
  selectState,
  fromScreen.getNotVisibleForMobile
);