import { DictionaryFilterDefinition, DictionaryTypeEnum, FilterTypeEnum } from '../filters-model';

export interface QuicklinksSetFormFilters {
    contentTypes: DictionaryFilterDefinition;
    categories: DictionaryFilterDefinition;
    locations?: DictionaryFilterDefinition;
    departments?: DictionaryFilterDefinition;
    functions?: DictionaryFilterDefinition;
  }

  export const INITIAL_QUICKLINKS_SET_FORM_FILTERS: QuicklinksSetFormFilters = {
    contentTypes: {
      type: FilterTypeEnum.DICTIONARY,
      dictionary: DictionaryTypeEnum.CONTENT_TYPES,
      label: 'Content types',
      changable: true,
      value: []
    },
    categories: {
      type: FilterTypeEnum.DICTIONARY,
      dictionary: DictionaryTypeEnum.CATEGORIES,
      label: 'Categories',
      changable: true,
      value: []
    },
    locations: {
      type: FilterTypeEnum.DICTIONARY,
      dictionary: DictionaryTypeEnum.LOCATIONS,
      label: 'Location',
      changable: true,
      value: []
    },
    departments: {
      type: FilterTypeEnum.DICTIONARY,
      dictionary: DictionaryTypeEnum.DEPARTMENTS,
      label: 'Department',
      changable: true,
      value: []
    },
    functions: {
      type: FilterTypeEnum.DICTIONARY,
      dictionary: DictionaryTypeEnum.FUNCTIONS,
      label: 'Function',
      changable: true,
      value: []
    }
  }
