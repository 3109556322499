import {Action, createReducer, on} from '@ngrx/store';
import * as fromActions from './notification-settings.actions';
import {ChannelNotificationSettings} from '@app/core/models/notifications.model';

export interface State {
  notificationsEnabled: boolean;
  channelSettings: Array<ChannelNotificationSettings>;
  initialChannelSettings: Array<ChannelNotificationSettings>;
}

export const initialState: State = {
  notificationsEnabled: false,
  channelSettings: [],
  initialChannelSettings: [],
};

export const notificationSettingsReducer = createReducer(
  initialState,
  on(
    fromActions.setInitialValue,
    fromActions.saveAllowNotificationsSuccess,
    fromActions.saveChannelSettingsSuccess,
    fromActions.resetChannelSettingsSuccess, (state, {notificationsEnabled, channelSettings}) => ({
      ...state,
      notificationsEnabled,
      channelSettings,
      initialChannelSettings: channelSettings,
    })),
  on(fromActions.setChannelSettings, (state, {channelSettings}) => ({
    ...state,
    channelSettings,
  })),
  on(fromActions.setNotificationsEnabled, (state, {notificationsEnabled}) => ({
    ...state,
    notificationsEnabled,
  })),
  on(fromActions.clearAll, () => ({
    ...initialState
  })),
  on(fromActions.saveChannelSettingsSuccess, (state, {channelSettings}) => ({
    ...state,
    initialChannelSettings: channelSettings
  }))
);


export function reducer(state: State | undefined, action: Action) {
  return notificationSettingsReducer(state, action);
}

export const getNotificationsEnabled = (state: State) => state.notificationsEnabled;
export const getChannelSettings = (state: State) => state.channelSettings;
export const getInitialChannelSettings = (state: State) => state.initialChannelSettings;
