import {Action, createFeatureSelector, createReducer, on} from '@ngrx/store';
import * as UserActions from './user.actions';
import {Dictionary} from '@ngrx/entity';
import {Status, STATUS_FAILURE, STATUS_LOADING, STATUS_NOT_ASKED, STATUS_SUCCESS} from '@core/models/status.model';
import {UserProfile} from '@app/core/models/user-profile.model';
import {RoleNames} from '@app/core/enums/roles.enum';
import * as AuthActions from '@core/auth/store/auth.actions';
import {Audience, UserChannel} from '@app/core/models';
import {UserWorkdayInformation} from '@core/models/user-workday.model';
import map from '@lodash-es/map';
import compact from '@lodash-es/compact';
import {UserLogin} from '@app/core/models/newsboard';

export const feature = 'user';

export interface State extends Status {
  userProfile: UserProfile;
  channelIds: number[] | string[];
  roles: Array<RoleNames>;
  newsBoardUser: UserLogin;
  workdayData: UserWorkdayInformation;
  managedTaxonomy: Audience;
  personalizationPreferences: Audience;
  initialRoles: Array<RoleNames>;
  initialManagedTaxonomy: Audience;
}

export const initialState: State = {
  userProfile: {...UserProfile},
  channelIds: [],
  roles: [],
  newsBoardUser: null,
  ...STATUS_NOT_ASKED,
  workdayData: null,
  managedTaxonomy: null,
  personalizationPreferences: null,
  initialRoles: null,
  initialManagedTaxonomy: null,

};

export const reducer = createReducer(
  initialState,
  on(UserActions.getUserInfo, (state) => ({
    ...state,
    ...STATUS_LOADING,
  })),
  on(UserActions.getUserInfoSuccess, (state, {userProfile}) => ({
    ...state,
    ...STATUS_SUCCESS,
    userProfile: {
      ...userProfile,
      photoUrl: state.userProfile.photoUrl,
      wasWelcomePageDisplayed: false
    }
  })),
  on(UserActions.getUserInfoFailure, (state) => ({
    ...state,
    ...STATUS_FAILURE,
    userProfile: {
      ...UserProfile
    },
    roles: []
  })),
  on(UserActions.getUserInfoLoaded, (state, {userProfile}) => ({
    ...state,
    userProfile
  })),
  on(UserActions.getUserChannels, (state) => ({
    ...state,
    ...STATUS_LOADING
  })),
  on(UserActions.getUserChannelsSuccess, UserActions.updateUserChannels, (state, {channels}) => {
    const channelIds = [...channels]
      .sort((a, b) => a.order - b.order)
      .map(channel => channel.id);
    return {
      ...state,
      channelIds
    };
  }),
  on(UserActions.getUserChannelsError, (state, {error}) => ({
    ...state,
    error,
  })),
  on(UserActions.setUserLanguage, (state, {newsLanguage}) => ({
    ...state,
    userProfile: {
      ...state.userProfile,
      newsLanguage
    }
  })),
  on(UserActions.getUserRolesSuccess, (state, {roles}) => ({
    ...state,
    roles: state.newsBoardUser?.role ? [...roles, state.newsBoardUser.role as RoleNames] : roles,
    initialRoles: state.newsBoardUser?.role ? [...roles, state.newsBoardUser.role as RoleNames] : roles,
  })),
  on(UserActions.getNewsBoardUserSuccess, (state, {user}) => ({
    ...state,
    newsBoardUser: {
      ...user,
      isAdmin: user.role === 'Admin',
      isSuperAdmin: user.role === 'SuperAdmin'
    },
    roles: state.roles.length ? [...state.roles, user.role as RoleNames] : [user.role as RoleNames],
    initialRoles: state.roles.length ? [...state.roles, user.role as RoleNames] : [user.role as RoleNames],
  })),
  on(UserActions.setUserRoles, (state, {roles}) => ({
    ...state,
    roles
  })),
  on(UserActions.setNewsboardUserRoles, (state, {roles}) => ({
    ...state,
    newsBoardUser: {
      ...state.newsBoardUser,
      isAdmin: roles.isAdministrator,
      isSuperAdmin: roles.isSuperAdministrator
    },
  })),
  on(UserActions.getUserRolesError, (state, {error}) => ({
    ...state,
    error
  })),
  on(UserActions.getUserManagedTaxonomiesSuccess, (state, {managedTaxonomy}) => ({
    ...state,
    managedTaxonomy,
    initialManagedTaxonomy: {
      ...state.managedTaxonomy,
      locations: managedTaxonomy.locations,
      departments: managedTaxonomy.departments,
      functions: managedTaxonomy.functions,
    },
  })),
  on(UserActions.setUserManagedTaxonomies, (state, {managedTaxonomy}) => ({
    ...state,
    managedTaxonomy
  })),
  on(UserActions.getUserManagedTaxonomiesError, (state, {error}) => ({
    ...state,
    error
  })),
  on(UserActions.getUserPersonalizationPreferencesSuccess, (state, {personalizationPreferences}) => ({
    ...state,
    personalizationPreferences,
  })),
  on(UserActions.setUserPersonalizationPreferences, (state, {personalizationPreferences}) => ({
    ...state,
    personalizationPreferences
  })),
  on(UserActions.getUserPersonalizationPreferencesError, (state, {error}) => ({
    ...state,
    error
  })),
  on(AuthActions.isLoggedWithGoogle, (state, {photoUrl}) => ({
    ...state,
    userProfile: {
      ...state.userProfile,
      photoUrl: photoUrl ? photoUrl : state.userProfile.photoUrl
    }
  })),
  on(UserActions.setQuickLinksPageVisitedSuccess, (state) => ({
    ...state,
    userProfile: {
      ...state.userProfile,
      wasQuickLinksPageDisplayed: true,
    }
  })),
  on(UserActions.setUserWasLoggedSuccess, (state) => ({
    ...state,
    userProfile: {
      ...state.userProfile,
      wasWelcomePageDisplayed: true,
    }
  })),
  on(UserActions.setUserSettingsVisitedSuccess, (state) => ({
    ...state,
    userProfile: {
      ...state.userProfile,
      wasUserSettingsDisplayed: true,
    }
  })),
  on(UserActions.setCatalogPageVisitedSuccess, (state) => ({
    ...state,
    userProfile: {
      ...state.userProfile,
      wasCatalogPageDisplayed: true,
    }
  })),
  on(UserActions.setSearchPageVisitedSuccess, (state) => ({
    ...state,
    userProfile: {
      ...state.userProfile,
      wasSearchPageDisplayed: true,
    }
  })),
  on(UserActions.setMyContentPageVisitedSuccess, (state) => ({
    ...state,
    userProfile: {
      ...state.userProfile,
      wasMyContentPageDisplayed: true,
    }
  })),
  on(UserActions.setLayoutSettingsPageVisitedSuccess, (state) => ({
    ...state,
    userProfile: {
      ...state.userProfile,
      wasLayoutSettingsPageDisplayed: true,
    }
  })),
  on(UserActions.setMenuVisitedSuccess, (state) => ({
    ...state,
    userProfile: {
      ...state.userProfile,
      wasMenuDisplayed: true,
    }
  })),
  on(UserActions.getUserDetailsSuccess, (state, {user}) => ({
    ...state,
    workdayData: user
  })),
);

export function userReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}

export const selectState = createFeatureSelector<State>(feature);

export const getUserProfile = (state: State) => state.userProfile;
export const getLoading = (state: State) => state.loading;
export const getFailure = (state: State) => state.failure;
export const getChannelIds = (state: State) => state.channelIds;
export const getRoles = (state: State) => state.roles;
export const getNewsBoardUser = (state: State) => state?.newsBoardUser;
export const getInitialRoles = (state: State) => state.initialRoles;
export const getManagedTaxonomy = (state: State) => state.managedTaxonomy;
export const getPersonalizationPreferences = (state: State) => state.personalizationPreferences;
export const getPersonalizationPreferencesLocations = (audience: Audience) => audience.locations;
export const getPersonalizationPreferencesDepartments = (audience: Audience) => audience.departments;
export const getPersonalizationPreferencesFunctions = (audience: Audience) => audience.functions;
export const getInitialManagedTaxonomy = (state: State) => state.initialManagedTaxonomy;
export const getWorkdayData = (state: State) => state.workdayData;
export const getIsOnlyAudienceManager = (roles: Array<RoleNames>) => roles.findIndex(r => r === RoleNames.AUDIENCE_MANAGER) > -1 && roles.findIndex(r => r === RoleNames.ADMINISTRATOR) === -1 ;
export const getIsAdmin = (roles: Array<RoleNames>) => {
  if (roles) {
    for (const item of roles) {
      if (item === RoleNames.ADMINISTRATOR) {
        return true;
      }
    }
  } else {
    return false;
  }
};

export const getIsNewsboardAdmin = (roles: Array<RoleNames>) => {
  if (roles) {
    for (const item of roles) {
      if (item === RoleNames.NB_ADMINISTRATOR || item === RoleNames.NB_SUPER_ADMINISTRATOR) {
        return true;
      }
    }
  } else {
    return false;
  }
};

export const getIsNewsboardSuperAdmin = (roles: Array<RoleNames>) => {
  if (roles) {
    for (const item of roles) {
      if (item === RoleNames.NB_SUPER_ADMINISTRATOR) {
        return true;
      }
    }
  } else {
    return false;
  }
};

export const getIsAudienceManager = (roles: Array<RoleNames>) => {
  if (roles) {
    for (const item of roles) {
      if (item === RoleNames.AUDIENCE_MANAGER) {
        return true;
      }
    }
  } else {
    return false;
  }
};

export const getChannels =
  (userIds: string[] | number[], entities: Dictionary<UserChannel>) =>
    compact(map(userIds, (id => entities[id])));

export const getLogin = (userProfile: UserProfile) => userProfile.login;
export const getEmail = (userProfile: UserProfile) => userProfile.email;
export const getFirstName = (userProfile: UserProfile) => userProfile.firstName;
export const getLastName = (userProfile: UserProfile) => userProfile.lastName;

export const getNewsLaguage = (userProfile: UserProfile) => userProfile.newsLanguage;
export const getNewsLaguageCode = (userProfile: UserProfile) => userProfile.newsLanguage.code;

export const getFullName = (name: string, surname: string) => `${name} ${surname}`;
export const getWasWelcomePageDisplayed = (userProfile: UserProfile) => userProfile.wasWelcomePageDisplayed;
export const getWasUserSettingsDisplayed = (userProfile: UserProfile) => userProfile.wasUserSettingsDisplayed;
export const getWasQuickLinksPageDisplayed = (userProfile: UserProfile) => userProfile.wasQuickLinksPageDisplayed;
export const getWasCatalogPageDisplayed = (userProfile: UserProfile) => userProfile.wasCatalogPageDisplayed;
export const getWasSearchPageDisplayed = (userProfile: UserProfile) => userProfile.wasSearchPageDisplayed;
export const getWasMyContentPageDisplayed = (userProfile: UserProfile) => userProfile.wasMyContentPageDisplayed;
export const getWasLayoutSettingsPageDisplayed = (userProfile: UserProfile) => userProfile.wasLayoutSettingsPageDisplayed;
export const getWasMenuDisplayed = (userProfile: UserProfile) => userProfile.wasMenuDisplayed;

// MVP
// export const getImage = (user: UserProfile) => user.image;
