import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { TOKENS } from './tokens';

@Component({
	selector: 'atr-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
	tokens = TOKENS;

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.cdr.markForCheck();
	}

	constructor(private cdr: ChangeDetectorRef) {}

	getName(token) {
		return JSON.parse(atob(token.split('.')[1])).family_name;
	}
}
