import {createAction, props} from '@ngrx/store';

export const setChannelIds = createAction(
  '[HOME - NEWSFEED] set channel ids',
  props<{ channelIds: string[] }>()
);
export const getFeedNewsRequest = createAction(
  '[HOME - NEWSFEED] request');


export const getChannelNews = createAction(
  '[HOME - NEWSFEED] get news for channel by id request',
  props<{ id: string, pageIndex: number, pageSize: number, index: number }>());

export const getChannelNewsSuccess = createAction(
  '[HOME - NEWSFEED] get news for channel by id success',
  props<{ id: string, data: any, index: number}>());

export const markRead = createAction(
  '[HOME - NEWSFEED] Mark read',
  props<{ id: string }>()
);

export const addBookmark = createAction(
  '[HOME - NEWSFEED] Add bookmark',
  props<{ id: string, channelId: string }>()
);

export const addBookmarkSuccess = createAction(
  '[HOME - NEWSFEED] Add bookmark success',
  props<{ id: string, channelId: string}>()
);

export const addBookmarkFailure = createAction(
  '[HOME - NEWSFEED] Add bookmark failure',
);

export const removeBookmark = createAction(
  '[HOME - NEWSFEED] Remove bookmark',
  props<{ id: string, channelId: string }>()
);

export const removeBookmarkSuccess = createAction(
  '[HOME - NEWSFEED] Remove bookmark success',
  props<{ id: string, channelId: string }>()
);

export const removeBookmarkFailure = createAction(
  '[HOME - NEWSFEED] Remove bookmark failure',
);

export const addLike = createAction(
  '[HOME - NEWSFEED] Add like',
  props<{ id: string, likesCount: number, channelId: string }>()
);

export const addLikeSuccess = createAction(
  '[HOME - NEWSFEED] Add like success',
  props<{ id: string, channelId: string }>()
);

export const addLikeFailure = createAction(
  '[HOME - NEWSFEED] Add like failure',
);

export const removeLike = createAction(
  '[HOME - NEWSFEED] Remove like',
  props<{ id: string, likesCount: number, channelId: string }>()
);

export const removeLikeSuccess = createAction(
  '[HOME - NEWSFEED] Remove like success',
  props<{ id: string , channelId: string}>()
);

export const removeLikeFailure = createAction(
  '[HOME - NEWSFEED] Remove like failure',
);
