<div #tile class="tile grid">
  <div #imageRef class="tile__image">
    <ng-content select="[body-image]"></ng-content>
  </div>
  <div #titleRef class="tile__body">
    <div class="tile__body__header">
      <ng-content select="[body-channel]"></ng-content>
    </div>
    <div class="tile__body__title">
      <ng-content select="[body-title]"></ng-content>
    </div>
    <div #descriptionRef class="tile__body__description">
      <ng-content select="[body-description]"></ng-content>
    </div>
    <div class="tile__body__footer">
      <ng-content select="[body-liking]"></ng-content>
      <ng-content select="[body-read-more]"></ng-content>
    </div>
  </div>
</div>
