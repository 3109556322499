import { createSelector } from '@ngrx/store';
import * as fromReducer from './catalog-entry.reducer';
import * as fromUser from '@app/core/user/store/user.selectors';
import { UserSearch } from '@app/core/models/user-search.model';
import { CatalogEntryRequest, EntryStatus } from '@app/core/models';


export const selectForm = createSelector(
  fromReducer.selectState,
  fromReducer.getForm
);

export const selectFormForRequest = createSelector(
  selectForm,
  (form): CatalogEntryRequest => {
    return {
      id: form?.id,
      name: form?.name,
      url: form?.url,
      categoryId: form?.category[0],
      contentFromLocation: form?.contentFromLocation,
      contentFromOrganization: form?.contentFromOrganization,
      contentManager: form?.contentManager.length ? form.contentManager[0].identifier : null,
      contentOwner: form?.contentOwner.length ? form.contentOwner[0].identifier : null,
      contentTypeId: form?.contentType[0],
      description: form?.description,
      endDate: form?.endDate,
      startDate: form?.startDate,
      isVpnRequired: form?.isVpnRequired,
      keywords: form?.keywords.join(','),
      locations: form?.audience.locations,
      departments: form?.audience.departments,
      functions: form?.audience.functions,
    }
  }
);

export const selectInitialForm = createSelector(
  fromReducer.selectState,
  fromReducer.getInitialForm
);

export const selectId = createSelector(
  selectForm,
  fromReducer.getId
);

export const selectCatalogStatus = createSelector(
  selectForm,
  fromReducer.getStatus
);

export const selectIsTouched = createSelector(
  fromReducer.selectState,
  fromReducer.getTouched
);

export const selectContentManagerTaxonomy = createSelector(
  fromReducer.selectState,
  fromReducer.getContentManagerTaxonomy
);

export const selectInvalidManager = createSelector(
  fromReducer.selectState,
  fromReducer.getInvalidManager
);

export const selectInvalidOwner = createSelector(
  fromReducer.selectState,
  fromReducer.getInvalidOwner
);

export const selectIsNameUnique = createSelector(
  fromReducer.selectState,
  fromReducer.getIsNameUnique
);

export const selectIsMine = (user: UserSearch) => createSelector(
  fromUser.selectLogin,
  (userLogin) => !!user && userLogin === user.login
);

export const selectPermissions = createSelector(
  fromReducer.selectState,
  fromReducer.getCatalogPermissions
);

export const selectCanDelete = createSelector(
  selectPermissions,
  fromReducer.getCanDeletePermission
);

export const selectCanEdit = createSelector(
  selectPermissions,
  fromReducer.getCanEditPermission
);

export const selectCanPublish = createSelector(
  selectPermissions,
  fromReducer.getCanPublishPermission
);

export const selectCanUnpublish = createSelector(
  selectPermissions,
  fromReducer.getCanUnpublishPermission
);

export const selectApprovalDetails = createSelector(
  fromReducer.selectState,
  fromReducer.getApprovalDetails
);

export const selectAudienceApproval = createSelector(
  fromReducer.selectState,
  fromReducer.getAudienceApproval
);

export const selectActiveApprovalDimmension = createSelector(
  selectCatalogStatus,
  selectAudienceApproval,
  (status, audience) => {
    if (!status || status === EntryStatus.DELETED_BY_CLM || status === EntryStatus.DELETED_BY_USER || status === EntryStatus.TRASHED) {
      return null;
    }
    if (audience?.locations.some(t => t.isApprovalRelevant)) {
      return 'locations';
    }
    if (audience?.departments.some(t => t.isApprovalRelevant)) {
      return 'departments';
    }
    if (audience?.functions.some(t => t.isApprovalRelevant)) {
      return 'functions';
    }
    return null;
  }
)

export const selectLocationsApproval = createSelector(
  selectAudienceApproval,
  fromReducer.getLocationsApproval,

);

export const selectLocationsApprovalInOrder = createSelector(
  selectLocationsApproval,
  selectActiveApprovalDimmension,
  (taxonomyApproval, activeDimmension) => activeDimmension === 'locations' ? taxonomyApproval?.sort((a, b) =>{
    const aPriority = a.isApproved ? -1 : (a.isApproved === false ? 1 : 0);
    const bPriority = b.isApproved ? -1 : (b.isApproved === false ? 1 : 0);
    return aPriority - bPriority
  }) : taxonomyApproval
)

export const selectDepartmentsApproval = createSelector(
  selectAudienceApproval,
  fromReducer.getDepartmentsApproval,
);


export const selectDepartmentsApprovalInOrder = createSelector(
  selectDepartmentsApproval,
  selectActiveApprovalDimmension,
  (taxonomyApproval, activeDimmension) => activeDimmension === 'departments' ? taxonomyApproval?.sort((a, b) =>{
    const aPriority = a.isApproved ? -1 : (a.isApproved === false ? 1 : 0);
    const bPriority = b.isApproved ? -1 : (b.isApproved === false ? 1 : 0);
    return aPriority - bPriority
  }) : taxonomyApproval
);

export const selectFunctionsApproval = createSelector(
  selectAudienceApproval,
  fromReducer.getFunctionsApproval
);

export const selectFunctionsApprovalInOrder = createSelector(
  selectFunctionsApproval,
  selectActiveApprovalDimmension,
  (taxonomyApproval, activeDimmension) => activeDimmension === 'functions' ? taxonomyApproval?.sort((a, b) =>{
    const aPriority = a.isApproved ? -1 : (a.isApproved === false ? 1 : 0);
    const bPriority = b.isApproved ? -1 : (b.isApproved === false ? 1 : 0);
    return aPriority - bPriority
  }) : taxonomyApproval
);