<rh-list *ngIf="result">
  <div image class="list-image">
    <rds-icon namespace="filled" icon="link" class="cursor-pointer" (click)="openContent()"></rds-icon>
  </div>
  <p
    body-top
    class="ui-label-l-bold text-high-contrast text-truncate">
      <span class="cursor-pointer"
            (click)="openContent()">
          {{result.title}}
      </span>
  </p>
  <p
    body-middle
    class="ui-label-l-bold text-low-contrast  text-truncate">
    <span [rdsTooltip]="titleTemplate"
          rdsTooltipPosition="top-center"
          [rdsTooltipShowDelay]="500"
          [innerHTML]="result.description">
    </span>
  </p>
  <div body-bottom style="display: flex; justify-content: space-between;">
    <p
      [rdsTooltip]="urlTemplate"
      rdsTooltipPosition="top-center"
      [rdsTooltipShowDelay]="500"
      class="ui-component-text-s text-low-contrast">
      <a [href]="result.url">{{result.displayUrl}}</a>
    </p>
    <rds-icon *ngIf="!isInQuickLinks" class="result__button" icon="add_circle" rdsTooltip="Add to Quicklinks"  namespace="outlined"
              (click)="addQuicklink()"></rds-icon>
    <rds-icon *ngIf="isInQuickLinks" class="result__button result__button--inactive" icon="checkmark_circle"
              namespace="filled"></rds-icon>
    <!-- TODO: Add subscription button -->
  </div>
</rh-list>

<ng-template #titleTemplate>
  <span [innerHTML]="result.title"></span>
</ng-template>
<ng-template #descriptionTemplate>
  <span [innerHTML]="result.description"></span>
</ng-template>
<ng-template #urlTemplate>
  {{result.displayUrl}}
</ng-template>
