import {Action, createReducer, on} from '@ngrx/store';
import * as fromActions from '@app/newsletter-new/store/campaign-form/campaign-form.actions';
import {EMPTY_CAMPAIGN_FORM, RhCampaignForm} from '@app/newsletter-new/models/campaign';


export interface State {
    form: RhCampaignForm,
    initialForm: RhCampaignForm;
    formChecked: boolean;
}

export const initialState: State = {
    form: null,
    initialForm: null,
    formChecked: false
};

export const campaignFormReducer = createReducer(
        initialState,
        // on(fromActions.userAgreement.confirmed, (state) => ({
        //   ...state,
        //   agreementsAccepted: true
        // })),
        on(fromActions.getCampaignById.success, (state, {campaign}) => ({
            ...state,
            initialForm: {
                ...state.initialForm,
                ...campaign
            },
            form: {
                ...state.form,
                ...campaign
            }
        })),
        on(fromActions.form.setInitialValue, (state, {form}) => ({
            ...state,
            initialForm: {
                ...state.initialForm,
                ...form
            }
        })),
        on(fromActions.form.setValue, (state, {form}) => ({
            ...state,
            form: {
                ...state.form,
                ...form
            }
        })), on(fromActions.form.clear, (state) => ({
            ...state,
            form: {
                ...EMPTY_CAMPAIGN_FORM,
            }
        })),
    )
;

export function reducer(state: State | undefined, action: Action) {
    return campaignFormReducer(state, action);
}

export const getFormChecked = (state: State) => state.formChecked;
export const getForm = (state: State) => state.form;
export const getInitialForm = (state: State) => state.initialForm;
// export const getNewsletter = (state: State) => state.newsletter
// export const getNewsletterMetadata = (newsletter: NewsletterLoadedValueModel) => ({
//   newsletterId: newsletter?.newsletterId,
//   templateId: newsletter?.templateId,
//   isContributed: newsletter?.isContributed
// })
