import {ImageCaptionUtils} from "@ckeditor/ckeditor5-image";
import ImageUtils from "@ckeditor/ckeditor5-image/src/imageutils";
import {BalloonPanelView} from 'ckeditor5/src/ui';

ImageUtils.prototype.isImage = function (modelElement) {
    return modelElement && ['imageInline', 'imageBlock', 'customImageBox'].indexOf(modelElement.name) > -1 ? modelElement : null;
};
ImageUtils.prototype.isCustomImageBox = function (modelElement) {
    return modelElement && modelElement.name === 'customImageBox' ? modelElement : null;
};

export function isImageWidgetCustom(viewElement) {
    return !!viewElement.getCustomProperty('image') && isWidget(viewElement) || viewElement.name === 'figure' && viewElement.hasClass('custom-figure');
}

ImageUtils.prototype.getClosestSelectedImageWidgetCustom = function (selection) {
    const selectionPosition = selection.getFirstPosition();
    if (!selectionPosition) {
        return null;
    }
    const viewElement = selection.getSelectedElement();
    if (viewElement && isImageWidgetCustom(viewElement)) {
        return viewElement;
    }
    let parent = selectionPosition.parent;
    while (parent) {
        if (parent.is('element') && isImageWidgetCustom(parent)) {
            return parent;
        }
        parent = parent.parent;
    }
    return null;
}
ImageCaptionUtils.prototype.custoMatchImageCaptionViewElement = function (element) {
    if (element.name == 'figcaption' && element.parent?.name === 'figure' && element.parent.hasClass('custom-figure')) {
        return {name: true};
    }
    return null;
};
ImageCaptionUtils.prototype.getCaptionFromModelSelection = function (selection) {
    const imageUtils = this.editor.plugins.get('ImageUtils');
    const captionElement = selection.getFirstPosition().findAncestor('caption');
    if (!captionElement) {
        return null;
    }
    if (imageUtils.isBlockImage(captionElement.parent) || imageUtils.isCustomImageBox(captionElement.parent)) {
        return captionElement;
    }
    return null;
};


export function getBalloonPositionData(editor) {
    const editingView = editor.editing.view;
    const defaultPositions = BalloonPanelView.defaultPositions;
    const imageUtils = editor.plugins.get('ImageUtils');
    return {
        target: editingView.domConverter.mapViewToDom(imageUtils.getClosestSelectedImageWidgetCustom(editingView.document.selection)),
        positions: [
            defaultPositions.northArrowSouth,
            defaultPositions.northArrowSouthWest,
            defaultPositions.northArrowSouthEast,
            defaultPositions.southArrowNorth,
            defaultPositions.southArrowNorthWest,
            defaultPositions.southArrowNorthEast,
            defaultPositions.viewportStickyNorth
        ]
    };
}
