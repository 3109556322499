import * as fromReducer from '../header.reducer';
import * as fromCatalogs from './catalogs.reducer';
import {createSelector} from '@ngrx/store';

export const selectCatalogs = createSelector(
  fromReducer.selectState,
  fromReducer.getCatalogs
);

export const selectCategories = createSelector(
  selectCatalogs,
  fromCatalogs.getCategories
);

export const selectOpenedCategory = createSelector(
  selectCatalogs,
  fromCatalogs.getOpenedCategory
);

export const selectFilters = createSelector(
  selectCatalogs,
  fromCatalogs.getFilters
);

export const selectDefaultFilters = createSelector(
  selectCatalogs,
  fromCatalogs.getDefaultFilters
);

export const selectAdvancedFiltersCount = createSelector(
  selectCatalogs,
  fromCatalogs.getAdvancedFiltersCount
);

export const selectCatalogsList = createSelector(
  selectCatalogs,
  fromCatalogs.getCatalogs
);

export const selectCatalogsByCategoryId = (id: number | string) => createSelector(
  selectCatalogsList,
  (catalogs) => {
    return catalogs.filter(c => c.category.categoryId === id);
  }
);

export const selectCategoryByCategoryId = (id: number | string) => createSelector(
  selectCategories,
  (categories) => {
    return categories.filter(c => c.categoryId === id)[0];
  }
);

export const selectIsLoading = createSelector(
  selectCatalogs,
  fromCatalogs.getIsLoading
);

export const selectCatalogsLength = createSelector(
  selectCatalogsList,
  (catalogs) => {
    return catalogs ? catalogs.length : 0;
  }
);


