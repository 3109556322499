<h2 rds-dialog-header>
	<ng-container *ngIf="data.settings.news"> Edit News </ng-container>
	<ng-container *ngIf="!data.settings.news">Select News </ng-container>
</h2>
<div rds-dialog-content style="overflow-x: hidden">
	<ng-container [formGroup]="newsForm">
		<div
			*ngIf="!data.settings.news && data.addNewAsCard && newsArray.length > 1"
			class="row bg-white"
			style="position: sticky; top: 0; z-index: 4"
		>
			<div class="col-4 mb-6">
				<p class="mt-5 mb-3 ui-label-l-bold">How to insert News?</p>
				<rds-radio-group formControlName="insertOption" class="inline">
					<rds-radio-button [value]="0">All in one column</rds-radio-button>
					<rds-radio-button [value]="1">Distributed evenly in columns</rds-radio-button>
				</rds-radio-group>
			</div>
			<rds-divider></rds-divider>
		</div>
		<div *ngFor="let newsForm of newsArray.controls; let i = index" [formGroup]="newsForm">
			<div class="row">
				<div class="col mt-5">
					<div *ngIf="!data.settings.news" class="row">
						<div class="col">
							<h4 class="ui-heading-4 mb-3">News {{ i + 1 }}</h4>
						</div>
						<div class="col-auto">
							<button [disabled]="newsArray.length === 1" rds-icon-button (click)="removeNews(i)">
								<rds-icon icon="delete" namespace="outlined"></rds-icon>
							</button>
						</div>
					</div>
					<div class="mb-5">
						<rds-form-field>
							<rds-control-label>Which channel do you want news from?</rds-control-label>
							<rds-select
								#select
								formControlName="channelId"
								(valueChange)="select.resetFilterValue(); channelChange($event, i)"
								[filterBy]="filterBy"
							>
								<rds-select-search-input></rds-select-search-input>
								<rds-select-option *ngFor="let channel of channels$ | async" [value]="channel.id">{{
									channel.name
								}}</rds-select-option>
							</rds-select>
						</rds-form-field>
					</div>
					<div class="mb-5">
						<rds-form-field>
							<rds-control-label>Which news do you want from the channel?</rds-control-label>
							<rds-select
								#select
								[id]="'nl-select-news-' + i"
								formControlName="news"
								[rhOverrideSelectLabel]="
									newsForm.controls.news.value && newsForm.controls.newsLanguage.value
										? newsForm.controls.news.value?.abstracts[newsForm.controls.newsLanguage.value].title
										: null
								"
								(valueChange)="select.resetFilterValue(); newsChange($event, i)"
								[filterBy]="filterByNews"
							>
								<rds-select-search-input
									[style.visibility]="
										(newsForm.controls.channelId.value | newsForChannel | async).length > 0 ? 'visible' : 'hidden'
									"
								></rds-select-search-input>
								<rds-select-option
									*ngIf="(newsForm.controls.channelId.value | newsForChannel | async).length === 0"
									[disabled]="true"
								>
									No News in selected channel.
								</rds-select-option>
								<rds-select-option
									*ngFor="
										let news of newsForm.controls.channelId.value
											| newsForChannel: { selectedOnTop: true, selectedId: newsForm.controls.news.value?.newsId }
											| async
									"
									[value]="news"
									class="rich-select-option"
								>
									<ng-container
										*ngIf="
											newsForm.controls.news.value && newsForm.controls.news.value.newsId === news.newsId
												? newsForm.controls.newsLanguage.value
												: news.primaryLanguage as visibleLanguage
										"
									>
										<div
											*ngIf="news.abstracts[visibleLanguage] as abstract"
											style="display: flex; gap: 24px; align-items: center"
										>
											<img
												style="height: 36px; aspect-ratio: 16/9"
												[src]="abstract.imgUrl || '/assets/images/news-placeholder.png'"
												alt=""
											/>
											<div style="display: flex; flex-direction: column; min-width: 0; flex: 1">
												<div style="display: flex; flex-direction: row">
													<p class="ui-label-m-bold text-ellipsis" style="flex: 1">
														{{ abstract.title }}
													</p>
												</div>
												<p class="ui-label-s text-ellipsis">
													{{ abstract.abstract }}
												</p>
											</div>
											<!-- Tooltip version -->
											<div
												rdsBadge
												[rdsBadgeLabel]="(news.abstracts | keyvalue).length + ''"
												[rdsTooltip]="languageTooltip"
												class="language-count"
												style="margin-right: 12px; display: flex; align-items: center; justify-content: center"
											>
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													<path
														d="M21 21.1072H18.85L18.13 19.0572H14.11L13.39 21.1072H11.24L15.05 10.8772H17.15L20.98 21.1072H21ZM16.12 13.3172L14.7 17.3772H17.55L16.12 13.3172ZM5.44 16.9172L4.32 15.7972L8.46 11.6572C7.95 11.0572 7.49 10.4572 7.09 9.85719C6.69 9.25719 6.34 8.62719 6.04 7.97719H7.81C8.04 8.42719 8.29 8.84719 8.57 9.24719C8.85 9.64719 9.16 10.0572 9.51 10.4772C10.12 9.83719 10.63 9.17719 11.03 8.49719C11.44 7.81719 11.78 7.09719 12.07 6.35719H3V4.73719H8.69V3.11719H10.31V4.73719H16V6.35719H13.7C13.4 7.28719 13.01 8.20719 12.51 9.10719C12.01 10.0072 11.41 10.8672 10.69 11.6772L12.68 13.6872L12.07 15.3372L9.49 12.8572L5.43 16.9172H5.44Z"
														fill="#21201F"
													/>
												</svg>
												<ng-template #languageTooltip>
													<p>
														<span> {{ news.primaryLanguage | languageName | async }} (primary) </span>
														<ng-container *ngFor="let l of news.abstracts | keyvalue">
															<span *ngIf="news.primaryLanguage !== l.key"> | {{ l.key | languageName | async }} </span>
														</ng-container>
													</p>
												</ng-template>
											</div>
										</div>
									</ng-container>
								</rds-select-option>
							</rds-select>
						</rds-form-field>
					</div>
					<div *ngIf="newsForm.controls.news.value as news" class="mb-5">
						<div class="row">
							<div class="col-4">
								<rds-form-field>
									<rds-control-label>Language version</rds-control-label>
									<rds-select
										#select
										formControlName="newsLanguage"
										(valueChange)="select.resetFilterValue()"
										[filterBy]="filterBy"
									>
										<rds-select-option
											*ngIf="newsForm.controls.newsLanguage.value as newsLanguage"
											[value]="newsLanguage"
										>
											{{ newsLanguage | languageName | async }}
											<span *ngIf="news.primaryLanguage === newsLanguage">(primary)</span>
										</rds-select-option>
										<ng-container
											ngProjectAs="rds-select-option"
											*ngFor="let language of news.abstracts | keyvalue as abstracts"
										>
											<rds-select-option
												*ngIf="language.key !== newsForm.controls.newsLanguage.value"
												[value]="language.key"
											>
												{{ language.key | languageName | async }}
												<span *ngIf="news.primaryLanguage === language.key">(primary)</span>
											</rds-select-option>
										</ng-container>
									</rds-select>
								</rds-form-field>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</div>
<div rds-dialog-actions-container align="end">
	<button *ngIf="!data.settings.news" rds-text-button (click)="addNews()" style="align-self: center; margin-right: auto;">Add another News</button>
	<button rds-secondary-button [rds-dialog-close]="null" size="m">Cancel</button>
	<button rds-primary-button [disabled]="newsForm.invalid" size="m" (click)="submit()">Save changes</button>
</div>
