import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PeopleResult} from '@app/search/store/rh-search';

@Component({
  selector: 'rh-people-card-item',
  templateUrl: './people-card-item.component.html',
  styleUrls: ['./people-card-item.component.scss']
})
export class PeopleCardItemComponent {
  @Input() result!: PeopleResult;
  @Output() peopleOpened: EventEmitter<{ login: string }> = new EventEmitter();

  constructor() {
  }

  openPeople(): void {
    this.peopleOpened.emit({login: this.result.login});
  }
}
