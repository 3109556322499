import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialOptions } from '@app/core/models';
import { Language } from '@app/core/models/language.model';
import { Subscription } from '@app/home/store/subscriptions';

@Component({
  selector: 'rh-news-header',
  templateUrl: './news-header.component.html',
  styleUrls: ['./news-header.component.scss']
})
export class NewsHeaderComponent implements OnInit {

  @Input() language: string;
  @Input() isLanguageLoading: boolean;
  @Input() allLanguages: Array<string>;
  @Input() viewMode: 'desktop' | 'mobile' = null;

  _title: string;
  get title(): string {
      return this._title;
  }
  @Input() set title(value: string) {
      this._title = value;
      // const title = value.trim().replace(/\s/g, '_');
      // this.router.navigate([], {
      //   relativeTo: this.route,
      //   queryParams: {
      //     title
      //   },
      //   queryParamsHandling: 'merge',
			//
      // });
      this.titleService.setTitle(value);
      this.loadDynamicLink.emit({url: window.location.href, lang: this.language});

  }
  @Input() image: string;
  @Input() blockLike: boolean;
  @Input() blockBookmark: boolean;
  @Input() publicationDate: string;
  @Input() author: string;
  @Input() authorName: string;
  @Input() publisher: string;
  @Input() publisherName: string;
  @Input() numLikes: number;
  @Input() numComments: number;
  @Input() commentsCount: number;
  @Input() options: SocialOptions;
  @Input() subscribedAuthors: Array<Subscription>;
  @Input() dynamicLink: string;
  @Output() loadDynamicLink: EventEmitter<{url: string, lang: string}> = new EventEmitter();
  @Output() changeLanguageVersion: EventEmitter<{language: string}> = new EventEmitter();
  @Output() subscribeAuthor: EventEmitter<{author: string}> = new EventEmitter();
  @Output() unsubscribeAuthor: EventEmitter<{author: string}> = new EventEmitter();
  @Output() likeClick: EventEmitter<{hasLiked: boolean}> = new EventEmitter();
  @Output() bookmarkClick: EventEmitter<{hasBookmarked: boolean}> = new EventEmitter();
  @Output() commentsClick: EventEmitter<any> = new EventEmitter();
  @Output() shareClick: EventEmitter<{dynamicLink: string}> = new EventEmitter();
  @Output() linkClick: EventEmitter<any> = new EventEmitter();

  @Input() languagesDict: Array<Language>;

  languageForm = this.fb.group({
    language: new FormControl(null)
  })

  get availableLanguages() {
    return this.languagesDict?.filter(l => this.allLanguages.includes(l.code));
  }

  get currentLanguage() {
    return this.languagesDict?.find(l => this.language === l.code )
  }

  get isAuthorSubscribed() {
    return this.subscribedAuthors?.findIndex(a => a.id === this.author) > -1;
  }
  constructor(
    private fb: FormBuilder,
    private titleService: Title,
    private route: ActivatedRoute,
    private router: Router,
    ) { }

  ngOnInit(): void {
      this.languageForm.controls.language.setValue(this.languagesDict?.find(l => l.code === this.language).code);
  }

  languageVersionChanged(language) {
    this.changeLanguageVersion.emit({language});
  }
  authorSubscribed(author) {
    this.subscribeAuthor.emit({author})
  }
  authorUnsubscribed(author) {
    this.unsubscribeAuthor.emit({author})
  }

  likeClicked() {
    this.likeClick.emit({hasLiked: this.options.hasLiked})
  }

  bookmarkClicked() {
    this.bookmarkClick.emit({hasBookmarked: this.options.hasBookmarked})
  }

  commentsClicked() {
    this.commentsClick.emit()
  }

  shareClicked() {
    this.shareClick.emit({dynamicLink: this.dynamicLink})
  }

  linkClicked() {
    this.linkClick.emit()
  }
}
