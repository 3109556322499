import { AfterViewInit, ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { GoogleService } from '@app/core/auth/services/google.service';
import { RDS_DIALOG_DATA } from '@rds/angular-components';

export interface GoogleOneTapDialogData {
  firstName: string;
}

@Component({
  selector: 'atr-google-one-tap-dialog',
  templateUrl: './google-one-tap-dialog.container.html',
  styleUrls: ['./google-one-tap-dialog.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleOneTapDialogContainer implements AfterViewInit {

  constructor(
    @Inject(RDS_DIALOG_DATA) public data: GoogleOneTapDialogData,
    private googleService: GoogleService
  ) {}

  ngAfterViewInit(): void {
    this.googleService.oneTap();
  }
}
