import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';

interface LoaderLayersColor {
  primary: string;
  secondary: string;
  style: {
    [propName: string]: any;
  };
}

export const DEFAULT_COLOR_HEX = 0xE3;
export const DEFAULT_GAP_HEX = 0xB;

@Injectable()
export class ComponentsLoaderService {

  public primaryColor = '#D8D8D8';
  public secondaryColor = '#4A4A4A';
  public preserveAspectRatio = 'xMinYMin slice';
  public style = {
    height: '100%'
  };
  public baseUrl = this.router.url;

  public getConfigForLayer(
    layerNumber: number, color: number = DEFAULT_COLOR_HEX, gap: number = DEFAULT_GAP_HEX): LoaderLayersColor {
    const primaryHex = Math.max(color - (layerNumber) * gap, 0);
    const secondaryHex = Math.max(color - (layerNumber + 1) * gap, 0);
    return {
      primary: this.createColorFromHex(primaryHex),
      secondary: this.createColorFromHex(secondaryHex),
      style: this.createStyleForLayer(layerNumber)
    };
  }

  public createColorFromHex(hex: number) {
    let stringHex = hex.toString(16);
    stringHex = stringHex.padEnd(2, '0');
    return `#${stringHex.repeat(3)}`;
  }

  private createStyleForLayer(layerNumber: number): { [propName: string]: any } {
    if (layerNumber > 0) {
      return {
        'transform': `translateY(-100%)`, // tslint:disable-line
        'margin-bottom': `-100%`
      };
    } else {
      return {};
    }
  }

  constructor(private router: Router) {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe(res => {
      this.baseUrl = this.router.url;
    });
  }
}
