import { ChannelCreation, SimpleUser } from '@app/core/models/newsboard';
import { createAction, props } from '@ngrx/store';

export const initAddForm = createAction(
  '[CHANNEL - FORM] Init form',
  props<{user: SimpleUser, channel?: Partial<ChannelCreation>}>()
);

export const getChannelDetailsRequest = createAction(
  '[CHANNEL - FORM] Get channel details request',
  props<{id: number}>()
);

export const getChannelDetailsSuccess = createAction(
  '[CHANNEL - FORM] Get channel details success',
  props<{channel: Partial<ChannelCreation>}>()
);

export const getChannelDetailsFailure = createAction(
  '[CHANNEL - FORM] Get channel details failure',
  props<{error: string}>()
);

export const getTopicAutocompleteRequest = createAction(
  '[CHANNEL - FORM] Get topic autocomplete',
  props<{search: string}>()
);

export const getTopicAutocompleteSuccess = createAction(
  '[CHANNEL - FORM] Get topic autocomplete success',
  props<{autocomplete: Array<string>}>()
);

export const clearTopicAutocomplete = createAction(
  '[CHANNEL - FORM] Clear topic autocomplete',
);

export const setFormValue = createAction(
  '[CHANNEL - FORM] Set form value',
  props<{form: Partial<ChannelCreation>}>()
);
export const setFormTouched = createAction(
  '[CHANNEL - FORM] Set form touched',
  props<{touched: boolean}>()
);

export const createChannelRequest = createAction(
  '[CHANNEL - FORM] Save channel request',
);

export const createChannelSuccess = createAction(
  '[CHANNEL - FORM] Save channel success',
);

export const createChannelFailure = createAction(
  '[CHANNEL - FORM] Save channel failure',
  props<{error: string}>()
);

export const updateChannelRequest = createAction(
  '[CHANNEL - FORM] Update channel request',
  props<{id: number}>()
);

export const updateChannelSuccess = createAction(
  '[CHANNEL - FORM] Update channel success',
);

export const updateChannelFailure = createAction(
  '[CHANNEL - FORM] Update channel failure',
  props<{error: string}>()
);

export const clearAll = createAction(
  '[CHANNEL - FORM] Clear form'
);

export const isNameUniqueRequest = createAction(
  '[CHANNEL - FORM] Is name unique request',
  props<{name: string, id?: number}>()
);

export const isNameUniqueSuccess = createAction(
  '[CHANNEL - FORM] Is name unique success',
  props<{isUnique: boolean}>()
);

export const isNameUniqueFailure = createAction(
  '[CHANNEL - FORM] Is name unique failure',
  props<{error: string}>()
);


export const deleteChannelRequest = createAction(
  '[CHANNEL - MY TABLE] Delete channel Request',
  props<{ids: Array<number>}>()
);

export const deleteChannelSuccess = createAction(
  '[CHANNEL - MY TABLE] Delete channel Success',
  props<{count: number}>()
);

export const deleteChannelFailure = createAction(
  '[CHANNEL - MY TABLE] Delete channel Failure',
  props<{ error: any }>()
);