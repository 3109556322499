<div 
  class="calendar-label"
  [class.calendar-label--size-m]="size === 'm'"
  [class.calendar-label--size-l]="size === 'l'"
>
  <div class="calendar-date" [class.calendar-date--with-time]="showTime">
    <span class="day">
      {{ date | date:'dd' }}
    </span>
    <span class="month-year">
      {{ date | date:'LLL' | uppercase }}
      <br/>
      {{ date | date:'yyyy' }}
    </span>
  </div>
  <div *ngIf="showTime" class="calendar-time">
    {{ date | date:'HH' }}:{{ date | date:'mm' }}
  </div>
</div>
