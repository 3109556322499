import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { NewsletterService } from '@app/core/services/newsletter.service';
import { Section } from '@app/newsletter/inline-editor/models/section-type.enum';
import { NewsletterInlineForm, NewsletterValueModel } from '@app/newsletter/models';
import { RDS_DIALOG_DATA, RdsDialogRef } from '@rds/angular-components';
import { NEWS_PLACEHOLDER_DATA } from '@src/assets/images/news-placeholder-base64';
import * as htmlToImage from 'html-to-image';
import { Observable, combineLatest, debounceTime, filter } from 'rxjs';
import { SubSink } from 'subsink';

export interface SaveNewsletterPreviewDialogData {
  templateId?: string;
  newsletterId?: number;
  formNewsletter?: Observable<NewsletterValueModel>;
  sections?: Observable<Array<Section>>;
  form?: Observable<Partial<NewsletterInlineForm>>;
}

@Component({
  selector: 'rh-save-newsletter-preview-dialog',
  templateUrl: './save-newsletter-preview-dialog.component.html',
  styleUrls: ['./save-newsletter-preview-dialog.component.scss']
})
export class SaveNewsletterPreviewDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('image') image: ElementRef;
  private subs: SubSink = new SubSink();
  templateId: string;
  newsletterId: number;
  predefinedNewsletter:  Partial<NewsletterValueModel>;
  formNewsletter: Observable<NewsletterValueModel>;
  sections: Observable<Array<Section>>;
  form: Observable<Partial<NewsletterInlineForm>>;

  news
  imageData
  isGenerating: boolean;

  isChecking = false;

  constructor(
    @Inject(RDS_DIALOG_DATA) public data: SaveNewsletterPreviewDialogData,
    public rdsDialogRef: RdsDialogRef<SaveNewsletterPreviewDialogComponent>,
    private newsletterService: NewsletterService,
  ) {
    if (!!data) {
      this.formNewsletter = data.formNewsletter;
      this.form = data.form;
      this.sections = data.sections;
      this.templateId = data.templateId;
      this.newsletterId = data.newsletterId;
     
    }
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  
  ngAfterViewInit(): void {
    switch (this.templateId) {
      case 'NewsletterTemplateInlineEditor':
        this.subs.sink = combineLatest([
          this.form,
          this.sections
        ])
        .pipe(
          filter(([form, sections]) => !!form && !!sections),
          debounceTime(200)
        )
        .subscribe(([form, sections]) => {
          this.createPreview();
        })
        break;
      case 'NewsletterTemplateWithSections':
      case 'NewsletterTemplateWithoutSections':
        this.subs.sink = this.formNewsletter
        .pipe(
          filter((form) => !!form),
          debounceTime(200)
        )
        .subscribe((form) => {
          this.createPreview();
        })
        break;
    }
  }

  createPreview() {
    this.isGenerating = true;
    htmlToImage.toPng(
      this.image.nativeElement,
      {
        quality: 1,
        width: this.image.nativeElement.offsetWidth,
        height: this.image.nativeElement.offsetHeight,
        canvasWidth: this.image.nativeElement.offsetWidth,
        canvasHeight: this.image.nativeElement.offsetHeight,
        imagePlaceholder: NEWS_PLACEHOLDER_DATA,
      }
    ).then(async (dataUrl) => {
      const blob = this.dataURItoBlob(dataUrl);
      const file = new File([blob], "file_name.png");
      this.newsletterService.uploadFile(file).subscribe(res => {
        this.rdsDialogRef.close({url: res, error: null});
      });
    }).catch(err => {
        this.rdsDialogRef.close({url: null, error: true});
    });
  }

  dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

}
                     