import {Action, createReducer, on} from '@ngrx/store';
import * as fromActions from '@app/newsletter/store/newsletter-saved-templates/newsletter-saved-templates.actions';
import {  MySavedTemplatesModel } from '@app/newsletter/models';
import { Pagination } from '@app/core/models';
import { createEntityAdapter, Dictionary, EntityAdapter } from '@ngrx/entity';

export interface MySavedTemplates extends Pagination {
  data: {
    ids: Array<number> | Array<string>;
    entities: Dictionary<MySavedTemplatesModel>
  };
}

export const templatesAdapter: EntityAdapter<MySavedTemplatesModel> =
  createEntityAdapter<MySavedTemplatesModel>({
    selectId: (template) => template.newsletterId
  });

export const sharedTemplatesAdapter: EntityAdapter<MySavedTemplatesModel> =
  createEntityAdapter<MySavedTemplatesModel>({
    selectId: (template) => template.newsletterId
});

export interface State {
  templates: MySavedTemplates;
  sharedTemplates: MySavedTemplates;
}

export const initialState: State = {
  templates: { data: {...templatesAdapter.getInitialState()}, ...Pagination},
  sharedTemplates: { data: {...sharedTemplatesAdapter.getInitialState()}, ...Pagination},
};

export const newsletterInlineReducer = createReducer(
  initialState,
  on(fromActions.getDashboardTemplatesSuccessUpsert, (state, {response}) => ({
    ...state,
    templates: {
      ...state.templates,
      data: {...templatesAdapter.upsertMany(response.data, state.templates.data)},
      isLast: response.isLast,
      isFirst: response.isFirst,
      pageIndex: response.pageIndex,
      pageSize: response.pageSize,
      pageCount: response.pageCount,
    }
  })),
  on(fromActions.getDashboardTemplatesSuccessAddAll, (state, {response}) => ({
    ...state,
    templates: {
      ...state.templates,
      data: {...templatesAdapter.setAll(response.data, state.templates.data)},
      isLast: response.isLast,
      isFirst: response.isFirst,
      pageIndex: response.pageIndex,
      pageSize: response.pageSize,
      pageCount: response.pageCount,
    }
  })),
  on(fromActions.deleteNewsletterSuccess, (state, {id, fromDashboard}) => {
    if (!fromDashboard) {
      return ({
        ...state,
        templates: {
          ...state.templates,
          data: {...templatesAdapter.removeOne(id, state.templates.data)},
        }
      });
    } else {
      return ({
        ...state,
      });
    }

  }),
  on(fromActions.renameNewsletterSuccess, (state, {template}) => ({
    ...state,
    templates: {
      ...state.templates,
      data: {...templatesAdapter.upsertOne(template, state.templates.data)},
    }
  })),
  on(fromActions.getDashboardSharedTemplatesSuccessAddAll, (state, {response}) => ({
    ...state,
    sharedTemplates: {
      ...state.templates,
      data: {...sharedTemplatesAdapter.setAll(response.data, state.templates.data)},
      isLast: response.isLast,
      isFirst: response.isFirst,
      pageIndex: response.pageIndex,
      pageSize: response.pageSize,
      pageCount: response.pageCount,
    }
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return newsletterInlineReducer(state, action);
}

export const getTemplates = (state: State) => state.templates.data;
export const getAll = (state: State) => templatesAdapter.getSelectors().selectAll(state.templates.data);

export const getPageIndex = (state: State) => state.templates.pageIndex;
export const getPageSize = (state: State) => state.templates.pageSize;
export const getPageIndexSize = (state: State) => ({
  pageSize: state.templates.pageSize,
  pageIndex: state.templates.pageIndex
});

export const getPageCount = (state: State) => state.templates.pageCount;
export const getIsFirst = (state: State) => state.templates.isFirst;
export const getIsLast = (state: State) => state.templates.isLast;

export const getSharedTemplates = (state: State) => state.sharedTemplates.data;
export const getAllShared = (state: State) => sharedTemplatesAdapter.getSelectors().selectAll(state.sharedTemplates.data);

export const getSharedPageIndex = (state: State) => state.sharedTemplates.pageIndex;
export const getSharedPageSize = (state: State) => state.sharedTemplates.pageSize;
export const getSharedPageIndexSize = (state: State) => ({
  pageSize: state.sharedTemplates.pageSize,
  pageIndex: state.sharedTemplates.pageIndex
});

export const getSharedPageCount = (state: State) => state.sharedTemplates.pageCount;
export const getSharedIsFirst = (state: State) => state.sharedTemplates.isFirst;
export const getSharedIsLast = (state: State) => state.sharedTemplates.isLast;
