import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';
import * as fromNewsletterTable from './newsletter-table/newsletter-table.reducer';
import * as fromNewsletterForm from './newsletter-form/newsletter-form.reducer';
import * as fromNewsletterRecipientsForm from '@app/newsletter-new/store/recipients-form/recipients-form.reducer';
import * as fromNewsletterWebSocket from './newsletter-websocket/newsletter-websocket.reducer';
import * as fromNewsletterTemplates from './templates/templates.reducer';

import * as fromRecipientsTable from './recipients-table/recipients-table.reducer';
import * as fromCampaignTable from './campaign-table/campaign-table.reducer';
import * as fromStatisticsTable from './statistics-table/statistics-table.reducer';
import * as fromStatisticsAll from './statistics-all/statistics-all.reducer';
import * as fromStatisticsAllCampaign from './statistics-all-campaign/statistics-all-campaign.reducer';
import * as fromCampaignFom from './campaign-form/campaign-form.reducer';
import * as fromNewsletterBlocks from './newsletter-blocks/newsletter-blocks.reducer';
import * as fromTemplateForm from './template-form/template-form.reducer';

// MOCK TO DELETE
import * as fromMock from './mock/mock.reducer';

export const feature = 'newsletter';

export interface State {
	campaignForm: fromCampaignFom.State;
	campaignTable: fromCampaignTable.State;
	newsletterBlocks: fromNewsletterBlocks.State;
	newsletterForm: fromNewsletterForm.State;
	newsletterRecipientsForm: fromNewsletterRecipientsForm.State;
	newsletterWebSocket: fromNewsletterWebSocket.State;
	newsletterTable: fromNewsletterTable.State;
	newsletterTemplates: fromNewsletterTemplates.State;
	recipientsTable: fromRecipientsTable.State;
	statisticsTable: fromStatisticsTable.State;
	statisticsAll: fromStatisticsAll.State;
	statisticsAllCampaign: fromStatisticsAllCampaign.State;
	templateForm: fromTemplateForm.State;
	// MOCK TO DELETE
	mock: fromMock.State;
}

export function reducer(state: State | undefined, action: Action) {
	return combineReducers({
		campaignForm: fromCampaignFom.reducer,
		campaignTable: fromCampaignTable.reducer,
		newsletterBlocks: fromNewsletterBlocks.reducer,
		newsletterTable: fromNewsletterTable.reducer,
		newsletterForm: fromNewsletterForm.reducer,
		newsletterRecipientsForm: fromNewsletterRecipientsForm.reducer,
		newsletterWebSocket: fromNewsletterWebSocket.reducer,
		newsletterTemplates: fromNewsletterTemplates.reducer,
		recipientsTable: fromRecipientsTable.reducer,
		statisticsTable: fromStatisticsTable.reducer,
		statisticsAll: fromStatisticsAll.reducer,
		statisticsAllCampaign: fromStatisticsAllCampaign.reducer,
		templateForm: fromTemplateForm.reducer,
		// MOCK TO DELETE
		mock: fromMock.reducer,
	})(state, action);
}

export const selectState = createFeatureSelector<State>(feature);
export const getNewsletterTable = (state: State) => state.newsletterTable;
export const getNewsletterForm = (state: State) => state.newsletterForm;
export const getNewsletterWebSocket = (state: State) => state.newsletterWebSocket;
export const getNewsletterRecipientsForm = (state: State) => state.newsletterRecipientsForm;
export const getRecipientsTable = (state: State) => state.recipientsTable;
export const getCampaignTable = (state: State) => state.campaignTable;
export const getNewsletterTemplates = (state: State) => state.newsletterTemplates;
export const getStatisticsTable = (state: State) => state.statisticsTable;
export const getStatisticsAll = (state: State) => state.statisticsAll;
export const getStatisticsAllCampaign = (state: State) => state.statisticsAllCampaign;
export const getMock = (state: State) => state.mock;
export const getCampaignForm = (state: State) => state.campaignForm;
export const getNewsletterBlocks = (state: State) => state.newsletterBlocks;
export const getTemplateForm = (state: State) => state.templateForm;
