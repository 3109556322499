import { AlertForm } from '@app/core/models/alerts.model';
import { createAction, props } from '@ngrx/store';

export const setFormValue = createAction(
  '[ALERT - FORM] Set form value',
  props<{form: Partial<AlertForm>}>()
);
export const setFormTouched = createAction(
  '[ALERT - FORM] Set form touched',
  props<{touched: boolean}>()
);

export const sendAlertRequest = createAction(
  '[ALERT - FORM] Save alert request',
);

export const sendAlertSuccess = createAction(
  '[ALERT - FORM] Save alert success',
);

export const sendAlertFailure = createAction(
  '[ALERT - FORM] Save alert failure',
  props<{error: string}>()
);

export const clearAll = createAction(
  '[ALERT - FORM] Clear form'
);

export const openPreviewDialog = createAction(
  '[ALERT - FORM] Open preview dialog',
  props<{canSave: boolean}>()
);

export const openConfirmDialog = createAction(
  '[ALERT - FORM - OPEN CONFIRM DIALOG]',
);
