export const getSelectedFromLocalAndMapFrom = (items, selected) => {
  if (selected?.length) {
    const itemsById = {};
    const newSelected = [];
    items.forEach(item => {
      itemsById[item.id] = item;
    });
    selected.forEach(value => {
      if (itemsById[value]) {
        newSelected.push(itemsById[value]);
      }
    });
    return newSelected;
  } else {
    return items;
  }
}


export function sortAndFilter(items, search) {
  return items.filter(i => {
    if (search) {
      return i.name.toLocaleLowerCase().indexOf(search.toLowerCase()) > -1
    } else {
      return i;
    }
  })
}

export function getFromLocalStorage(subType,addedIds) : Array<string> {
  const tmpSelectedString = localStorage.getItem(`selected-${subType}`) || '[]';
  const tmpSelected = [...JSON.parse(tmpSelectedString), ...addedIds];
  return tmpSelected;
}
