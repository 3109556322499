import { RhEvent } from '@app/events/models/event';
import {createAction, props} from '@ngrx/store';

export const getEventDetails = createAction(
  '[EVENTS - DETAILS] Get event details',
  props<{ id: number }>()
);

export const getEventDetailsSuccess = createAction(
  '[EVENTS - DETAILS] Get event details success',
  props<{ event: RhEvent }>()
);

export const getEventDetailsFailure = createAction(
  '[EVENTS - DETAILS] Get event details failure',
  props<{ error: string }>()
);

export const clearDetails = createAction(
  '[EVENTS - DETAILS] Clear details'
);

export const addToCalendarRequest = createAction(
  '[EVENTS - DETAILS] add to calendar request',
  props<{ event: Partial<RhEvent> }>()
);

export const addToCalendarSuccess = createAction(
  '[EVENTS - DETAILS] add to calendar success',
  props<{ event: Partial<RhEvent> }>()
);
export const addToCalendarFailure = createAction(
  '[EVENTS - DETAILS] add to calendar failure',
  props<{ event: Partial<RhEvent> }>()
);
export const shareEvent = createAction(
  '[EVENTS - DETAILS] share event',
  props<{ body: string }>()
);
