import {Action, createReducer, on} from '@ngrx/store';
import * as fromActions from './newsletter.actions';
import {ChannelNewsletterModel, NewsDynamicLink} from '@app/newsletter/models';
import {createEntityAdapter, Dictionary, EntityAdapter} from '@ngrx/entity';
import { NewsModel } from '@app/core/models/news.model';

export const channelsAdapter: EntityAdapter<ChannelNewsletterModel> = createEntityAdapter<ChannelNewsletterModel>();
export const newsAdapter: EntityAdapter<NewsModel> = createEntityAdapter<NewsModel>();
export const channelsWithNewsAdapter: EntityAdapter<any> = createEntityAdapter<any>();
export const dynamicLinksAdapter: EntityAdapter<NewsDynamicLink> = createEntityAdapter<NewsDynamicLink>();

export interface State {
  newsAreLoading: true | false;
  channels: {
    ids: string [] | number[];
    entities: Dictionary<ChannelNewsletterModel>;
  };
  news: {
    ids: string [] | number[];
    entities: Dictionary<NewsModel>;
  };
  channelsWithNews: {
    ids: string [] | number[];
    entities: Dictionary<any>;
  };
  newsDynamicLinks: {
    ids: string [] | number [],
    entities: Dictionary<NewsDynamicLink>;
  };
  newsletterAdded: false | true;
  lastUpdatedChannelWithNews: any;
  isSaveProcessActive: any;
}

export const initialState: State = {
  newsAreLoading: false,
  channels: {...channelsAdapter.getInitialState()},
  news: {...newsAdapter.getInitialState()},
  channelsWithNews: {...channelsWithNewsAdapter.getInitialState()},
  newsDynamicLinks: {...dynamicLinksAdapter.getInitialState()},
  newsletterAdded: false,
  lastUpdatedChannelWithNews: null,
  isSaveProcessActive: false
};

export const newsletterOtherReducer = createReducer(
  initialState,
  on(fromActions.getChannelsNewsletterSuccess, (state, {channels}) => ({
    ...state,
    channels: {
      ...channelsAdapter.setAll(channels, state.channels),
    }
  })),
  on(fromActions.getNewsForChannel, (state, {}) => ({
    ...state,
    newsAreLoading: true
  })), on(fromActions.getNewsForChannel, fromActions.updateNewsForChannel, (state, {}) => ({
    ...state,
    newsAreLoading: true
  })),
  on(fromActions.publishNewsletter, (state, {}) => ({
    ...state,
    newsletterAdded: false
  })),
  on(fromActions.publishNewsletterFromDraft, (state, {}) => ({
    ...state,
    newsletterAdded: false
  })),
  on(fromActions.publishNewsletterSuccess, (state, {}) => ({
    ...state,
    newsletterAdded: true
  })),
  on(fromActions.scrollToElementSuccess, (state, {}) => ({
    ...state,
    newsletterAdded: false
  })),
  on(fromActions.getNewsForChannelSuccess, (state, {id, totalCount, news}) => {
    const tmp = {
      id,
      totalCount,
      news
    };
    return {
      ...state,
      news: newsAdapter.upsertMany(news, state.news),
      channelsWithNews: {...channelsWithNewsAdapter.addOne(tmp, state.channelsWithNews)},
      newsAreLoading: false
    };
  }),
  on(fromActions.clearNewsletter, (state, {}) => ({
    ...state,
    newsAreLoading: false,
    lastUpdatedChannelWithNews: null,
    channels: {...channelsAdapter.getInitialState()},
    news: {...newsAdapter.getInitialState()},
    channelsWithNews: {...channelsWithNewsAdapter.getInitialState()},
    isSaveProcessActive: false
  })),
  on(fromActions.updateNewsForChannelSuccess, (state, {id, totalCount, news}) => {
    const newsById = {};
    const makeNewsById = () => {
      for (const oneNews of news) {
        newsById[oneNews.id] = oneNews;
      }
      return newsById;
    };
    const tmp = {
      id,
      totalCount,
      news: news.map((oneNews) => {
        return {...oneNews, imageUrl: oneNews.imageUrl === null ? '' : oneNews.imageUrl};
      })
    };
    return {
      ...state,
      news:
        newsAdapter.upsertMany(news, state.news),
      channelsWithNews: channelsWithNewsAdapter.updateOne({id: tmp.id, changes: tmp}, state.channelsWithNews),
      newsAreLoading: false,
      lastUpdatedChannelWithNews: {...tmp, newsById: makeNewsById()}
    };
  }),
  on(fromActions.newsDynamicLinkUpsertOne, (state, {link}) => ({
    ...state,
    newsDynamicLinks: {
      ...dynamicLinksAdapter.upsertOne(link, state.newsDynamicLinks)
    }
  })),
  on(fromActions.startSaveAsDraftNewsletterProccess, fromActions.startSendNewsletterProccess, (state) => ({
    ...state,
    isSaveProcessActive: true
  })),
  on(fromActions.publishNewsletterSuccess,
    fromActions.createDraftSuccess,
    fromActions.publishFailure,
    fromActions.createDraftFailure,
    (state) => ({
    ...state,
    isSaveProcessActive: false
  })),
  )
;

export function reducer(state: State | undefined, action: Action) {
  return newsletterOtherReducer(state, action);
}

export const getChannelsEntities = channelsAdapter.getSelectors().selectEntities;
export const getChannelsAll = channelsAdapter.getSelectors().selectAll;

export const getNewsEntities = newsAdapter.getSelectors().selectEntities;
export const getNewsAll = newsAdapter.getSelectors().selectAll;

export const getChannelsWithNewsEntities = channelsWithNewsAdapter.getSelectors().selectEntities;
export const getChannelsWithNewsAll = channelsWithNewsAdapter.getSelectors().selectAll;

export const getDynamicLinksEntities = dynamicLinksAdapter.getSelectors().selectEntities;
export const getDynamicLinksAll = dynamicLinksAdapter.getSelectors().selectAll;

export const getChannelsFromStore = (state: State) => state.channels;
export const getNewsFromStore = (state: State) => state.news;
export const getDynamicLinksFromStore = (state: State) => state.newsDynamicLinks;
export const getNewsForChannelsFromStore = (state: State) => state.channelsWithNews;
export const getNewsAreLoadingFromStore = (state: State) => state.newsAreLoading;
export const getNewsletterAddingFromStore = (state: State) => state.newsletterAdded;
export const getNewsletterLastUpdatedChannelWithNewsFromStore = (state: State) => state.lastUpdatedChannelWithNews;
