<h2 rds-dialog-header>Disable commenting?</h2>
<div rds-dialog-content>
    <p class="mb-7">
        Commenting functionality will be disabled to all Roche Home users.
    </p>
    <div class="mb-7" [formGroup]="form">
        <rds-switch size="m" formControlName="hide">Hide all comments from users visibility</rds-switch>
    </div>
    <p *ngIf="form.controls.hide.value" class="mb-5">
        Comments authors will be notified about this action via email.
    </p>
    <p *ngIf="!form.controls.hide.value" class="mb-5">
        Previously added comments will be visible to Roche Home users.
    </p>
</div>
<div rds-dialog-actions-container align="end">
    <button
        rds-secondary-button
        [rds-dialog-close]="null"
        size="m"
    >Cancel</button>
    <button
        rds-warning-button
        [rds-dialog-close]="{hide: form.controls.hide.value}"
        size="m"
    >{{form.controls.hide.value ? 'Disable & hide comments' : 'Disable commenting'}}</button>
</div>