import { Component, Inject, ViewChild } from '@angular/core';
import { RhEvent } from '@app/events/models/event';
import { BackButton } from '@app/root-store/ui/back-button';
import { Store } from '@ngrx/store';
import { RDS_DIALOG_DATA, RdsDialogRef, RdsTabsComponent } from '@rds/angular-components';
import * as fromRouter from '@app/root-store/router';
import { environment } from '@env/environment';
import { zonedTimeToUtc } from 'date-fns-tz';

type ContentMode = 'desktop' | 'mobile';

export interface EventPreviewButtonState {
  visible: boolean;
  disabled: boolean;
}

export interface EventPreviewButtons {
  editEvent?: EventPreviewButtonState
  canSaveEvent?: EventPreviewButtonState
}
export interface EventPreviewDialogData {
  event: Partial<RhEvent>;
  context: 'Editor' | 'Table';
  buttons?: EventPreviewButtons
  backButton?: BackButton;
}

@Component({
  selector: 'rh-event-preview-dialog',
  templateUrl: './event-preview-dialog.component.html',
  styleUrls: ['./event-preview-dialog.component.scss']
})
export class EventPreviewDialogComponent {
  @ViewChild('tabs') tabs: RdsTabsComponent;
  context: 'Editor' | 'Table';

  backButton: BackButton;
  buttons: EventPreviewButtons;
  event: Partial<RhEvent>;

  contentMode: ContentMode = 'desktop';

  public userTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  get showViewToggle() {
    return this.tabs?.selectedIndex > 0;
  }

  get zonedDate() {
    const eventDateWithShift = new Date((this.event.date as Date));
    return this.event.date ? zonedTimeToUtc(eventDateWithShift, this.event.timeZone) : '';
  }

  get zonedStart() {
    return this.event.date ? zonedTimeToUtc(this.event.startTime, this.event.timeZone) : '';
  }

  get zonedEnd() {
    return this.event.date ? zonedTimeToUtc(this.event.endTime, this.event.timeZone) : '';
  }
  
  constructor(
    private store$: Store<any>,
    private dialogRef: RdsDialogRef<EventPreviewDialogComponent>,
    @Inject(RDS_DIALOG_DATA) public data: EventPreviewDialogData) { 
    this.event = data.event;
    this.context = data?.context;
    this.backButton = data?.backButton;
    this.buttons = data?.buttons;
  }
  
  close(status?): void {
    this.dialogRef.close({status});
  }

  setContentMode(contentMode: ContentMode): void {
    this.contentMode = contentMode;
  }

  gotoEventDetails(id): void {
    this.store$.dispatch(fromRouter.goOutsideInNewTab({url: `${environment.url}/event/details/${id}`,redirect: true}))
  }
}
