import {Component, Input} from '@angular/core';
import { ComponentsLoaderService } from '../components-loader.service';

@Component({
  selector: 'atr-hero-card-loader',
  templateUrl: './hero-card-loader.component.html',
  styleUrls: ['./hero-card-loader.component.scss']
})
export class HeroCardLoaderComponent {
  @Input() type;
  constructor(public componentsLoaderService: ComponentsLoaderService) {}
}
