import {createSelector} from '@ngrx/store';

import * as fromCore from '@core/core-store/core.reducer';
import * as fromReducer from './categories-flat.reducer';
import {CategoryFlat} from '@app/core/models';

export const selectState = createSelector(
    fromCore.selectState,
    fromCore.getCategoriesFlat
);

export const selectAll = createSelector(
    selectState,
    fromReducer.getAll
);

export const selectEntities = createSelector(
    selectState,
    fromReducer.getEntities
);

export const selectIds = createSelector(
    selectState,
    fromReducer.getIds
);

export const selectTotal = createSelector(
    selectState,
    fromReducer.getTotal
);

export const selectEntityById = createSelector(
    selectEntities,
    fromReducer.getEntityById
);

export const selectEntitiesById = createSelector(
    selectEntities,
    fromReducer.getEntitiesById
);

export const selectBranchWithChildrens = (id, includeMainBranch = false) => createSelector(
    selectAll,
    (categories) => {
        let childrens = categories.filter(c => c.parentCategoryId === id);
        let branchCategories = childrens.map(c => ({
            ...c,
            parentCategoryId: null,
            parentCategoryName: null,
        }) as CategoryFlat);

        while (childrens.length > 0) {
            childrens = categories.filter(c => childrens.some(sc => sc.childrenIds.includes(c.categoryId)));
            branchCategories = [...branchCategories, ...childrens];
        }

        return includeMainBranch ? [categories.find(c => c.categoryId === id), ...branchCategories] : branchCategories;
    }
);

export const selectIdsOfBranchWithChildrens = (id) => createSelector(
    selectBranchWithChildrens(id),
    (categories) => [id, ...categories.map(c => c.categoryId)]
);

export const selectAllByIdsWithParents = (ids: Array<number>) => createSelector(
    selectAll,
    (categories) => {
        let selectedCategories = categories.filter(c => ids.includes(c.categoryId));
        let parents = categories.filter(c => selectedCategories.some(sc => sc.parentCategoryId === c.categoryId));
        while (parents.length > 0) {
            selectedCategories = [...new Set([...selectedCategories, ...parents])];
            parents = categories.filter(c => parents.some(pc => pc.parentCategoryId === c.categoryId));
        }
        return selectedCategories;
    }
);

export const selectRoot = createSelector(
    selectAll,
    fromReducer.getRoot
);
export const selectRootId = createSelector(
    selectRoot,
    fromReducer.getRootId
);
