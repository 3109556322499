import { createSelector } from '@ngrx/store';
import * as fromEmail from './email.reducer';

export const selectEmail = createSelector(
  fromEmail.selectState,
  fromEmail.getEmail,
);

export const selectLogin = createSelector(
  fromEmail.selectState,
  fromEmail.getLogin,
);

export const selectEmailAndLogin = createSelector(
  fromEmail.selectState,
  fromEmail.getEmailAndLogin,
);

export const selectLoading = createSelector(
  fromEmail.selectState,
  fromEmail.getLoading,
);

export const selectEmailSuggestions = createSelector(
  fromEmail.selectState,
  fromEmail.getEmailSuggestion
);
