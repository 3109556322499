import { Injectable } from '@angular/core';
import { GapiService } from '@app/core/services/gapi.service';
import * as fromCloudSearch from '@app/search/store/cloud-search';
import { ConfirmDialogComponent } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { GoogleLoginDialogContainer } from '@app/shared/dialogs/overlay-dialogs/google-login-dialog/google-login-dialog.container';
import { GoogleOneTapDialogContainer } from '@app/shared/dialogs/overlay-dialogs/google-one-tap-dialog/google-one-tap-dialog.container';
import { UserNotCreatedDialogContainer } from '@app/shared/dialogs/overlay-dialogs/user-not-created-dialog/user-not-created-dialog.container';
import { GoogleService } from '@core/auth/services/google.service';
import { UserProfileService } from '@core/services/userProfile.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { RdsDialogService } from '@rds/angular-components';
import { fromEvent, of, timer } from 'rxjs';
import { catchError, debounce, filter, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import * as fromAuthActions from './auth.actions';
import * as fromAuth from './auth.reducer';
import * as fromSelectors from './auth.selectors';

enum ConstNumbers {
  five = 5,
  sixty = 60,
  tausend = 1000
}

const MS_IN_DAY = 86400000

@Injectable()
export class AuthEffects {

  public localStorageTokensChanged$ = createEffect(() =>
    fromEvent<StorageEvent>(window, 'storage').pipe(
      filter((evt) => evt.key === 'tokenId'),
      filter(evt => evt.newValue !== null),
      map((evt) => fromAuthActions.updateIdToken({tokenId: evt.newValue}))
    )
  );

  public authTokenChanged$ = createEffect(() =>
    fromEvent<StorageEvent>(window, 'storage').pipe(
      filter((evt) => evt.key === 'tokenAccess'),
      filter(evt => evt.newValue !== null),
      map((evt) => fromAuthActions.updateAuthToken({tokenAccess: evt.newValue}))
    )
  );

  public jwtTokenChanged$ = createEffect(() =>
  fromEvent<StorageEvent>(window, 'storage').pipe(
    filter((evt) => evt.key === 'jwtToken'),
    filter(evt => evt.newValue !== null),
    map((evt) => fromAuthActions.updateJwtToken({jwtToken: evt.newValue}))
  )
);

  public scopesChanged$ = createEffect(() =>
    fromEvent<StorageEvent>(window, 'storage').pipe(
      filter((evt) => evt.key === 'scope'),
      filter(evt => evt.newValue !== null),
      map((evt) => fromAuthActions.updateScope({scope: evt.newValue}))
    )
  );

  public initAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.initAuth),
      filter(() => window.location.pathname !== '/maintenance'),
      map(() => fromAuthActions.loadGoogleLibs())
    ), {dispatch: true}
  );

  public loadGISLib$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.loadGoogleLibs),
      tap(() => this.googleService.loadGoogleLibs())
    ), {dispatch: false}
  );

  public GISLibLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.GISLibLoaded),
      tap(() => this.googleService.initialize())
    ), {dispatch: false}
  );

  public GapiLibLoaded$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromAuthActions.GapiLibLoaded),
    withLatestFrom(
      this.store$.pipe(select(fromSelectors.selectAuthToken))
    ),
    tap(([action, token]) => this.gapiService.init(token))
  ), {dispatch: false}
);

  public GISClientsInitialized$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.GISClientsInitialized),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectIdToken)),
        this.store$.pipe(select(fromSelectors.selectAuthToken)),
        this.store$.pipe(select(fromSelectors.selectJwtToken))
      ),
      map(([action, tokenId, tokenAccess, jwtToken]) => {
        let shouldAuthenticate = !tokenId || !tokenAccess || !jwtToken;
        if (!!tokenId && !!jwtToken) {
          // const expireTime = JSON.parse(atob(tokenId.split('.')[1])).exp;
          const expireTimeJwt = JSON.parse(atob(jwtToken.split('.')[1])).exp;

          shouldAuthenticate = shouldAuthenticate || new Date().getTime() - new Date(expireTimeJwt * ConstNumbers.tausend).getTime() > 0
        }
        return ({tokenId, tokenAccess, jwtToken, shouldAuthenticate})
      }),
      mergeMap(({tokenId, tokenAccess, jwtToken, shouldAuthenticate}) => {
        if (shouldAuthenticate) {
          return [fromAuthActions.authenticateWithGIS({tokenId, tokenAccess, jwtToken})]
        } else {  
          return [
            fromAuthActions.updateAuthToken({tokenAccess}),
            fromAuthActions.updateIdToken({tokenId}),
            fromAuthActions.updateJwtToken({jwtToken}),
            fromAuthActions.ensureCreated(),
            fromAuthActions.initQueryAPI({token: tokenAccess}),
          ]
        }
      })
    ), {dispatch: true}
  );

  public authenticateAfterRefresh401$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.authenticateAfterRefresh401),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectIdToken)),
        this.store$.pipe(select(fromSelectors.selectAuthToken)),
        this.store$.pipe(select(fromSelectors.selectJwtToken))
      ),
      map(([action, tokenId, tokenAccess, jwtToken]) =>fromAuthActions.authenticateWithGIS({tokenId, tokenAccess, jwtToken})),
    ), {dispatch: true}
  );

  public openUserNotCreatedDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.openUserNotCreatedDialog),
      switchMap(() => this.dialogService.open(UserNotCreatedDialogContainer, {
        panelClass: 'overlay-dialog'
      }).afterOpened().pipe(
        map(() => fromAuthActions.dialogOpened())
      ))
    ), {dispatch: true}
  );

  public openGoogleLoginDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.openGoogleLoginDialog),
      switchMap(() => this.dialogService.open(GoogleLoginDialogContainer, {
        panelClass: 'overlay-dialog'
      }).afterOpened().pipe(
        map(() => fromAuthActions.dialogOpened())
      ))
    ), {dispatch: true}
  );

  public openGoogleOneTapDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.openGoogleOneTapDialog),
      switchMap(() => this.dialogService.open(GoogleOneTapDialogContainer, {
        panelClass: 'overlay-dialog'
      }).afterOpened().pipe(
        map(() => fromAuthActions.dialogOpened())
      ))
    ), {dispatch: true}
  );

  public signIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.authenticateWithGIS),
      filter(({tokenId, tokenAccess, jwtToken}) => !tokenId || !jwtToken),
      map(() => fromAuthActions.openGoogleLoginDialog())
    ), {dispatch: true}
  );

  public oneTap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.authenticateWithGIS),
      filter(({tokenId, tokenAccess, jwtToken}) => !!tokenId && !!jwtToken),
      map(() => fromAuthActions.openGoogleOneTapDialog())
    ), {dispatch: true}
  );

  public oneTapPromptEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.googleOneTapPromptEvent),
      filter(({event}) => event.isSkippedMoment()),
      tap(() => localStorage.clear()),
      mergeMap(() => [
        fromAuthActions.clearAuth(),
        fromAuthActions.openGoogleLoginDialog()
      ])
    ), {dispatch: true}
  );

  public authorizationDeclined$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.getAuthorizationCodeFailure),
      tap(({error}) => {
        localStorage.clear()
      }),
      mergeMap(() => [
        fromAuthActions.clearAuth(),
        fromAuthActions.openGoogleLoginDialog()
      ])
    ), {dispatch: true}
  );

  public googleAuthenticationCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.googleAuthenticationCompleted),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectAuthToken))
      ),
      tap(([{credentials}]) => {
        localStorage.setItem('tokenId', credentials.credential);
      }),
      mergeMap(([{credentials}, tokenAuth]) => {
        if (!tokenAuth) {
          return [
            fromAuthActions.updateIdToken({tokenId: credentials.credential}),
            fromAuthActions.getAuthorizationCodeRequest(),
            // fromAuthActions.ensureCreated(),
          ]
        } else {
          return [
            fromAuthActions.updateIdToken({tokenId: credentials.credential}),
            fromAuthActions.refreshTokenRequest({}),
            fromAuthActions.ensureCreated(),
          ]
        }
      })
    ), {dispatch: true}
  );

  public getAuthorizationCodeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.getAuthorizationCodeRequest),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectIdTokenValid))),
      tap(([action, valid]) => {
        if (valid) {
          this.googleService.getAuthorizationCode()
        } else {
          this.googleService.oneTap()
        }
      })
    ), {dispatch: false}
  );

  public getAuthorizationCodeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.getAuthorizationCodeSuccess),
      map(({code, redirectUri, scope}) => fromAuthActions.authorizeUserRequest({code, redirectUri, scope}))
    ), {dispatch: true}
  );

  public authorizeUserRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.authorizeUserRequest),
      switchMap(({code, redirectUri, scope}) => this.authService.authCreate({code, redirectUri, scope}).pipe(
        mergeMap(({idToken, accessToken, scope, jwtToken}) => [fromAuthActions.authorizeUserSuccess({
          tokenId: idToken,
          tokenAccess: accessToken,
          scope,
          jwtToken
        }),
        fromAuthActions.ensureCreated(),
      ]),
      ))
    ), {dispatch: true}
  );

  public refreshTokenRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.refreshTokenRequest),
      switchMap(({actionToRetry}) => this.authService.refreshToken().pipe(
        map(({idToken, accessToken, scope, jwtToken}) => fromAuthActions.refreshTokenSuccess({
          tokenId: idToken,
          tokenAccess: accessToken,
          jwtToken,
          scope,
          actionToRetry
        })),
      ))
    ), {dispatch: true}
  );

  public authorizeUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromAuthActions.authorizeUserSuccess,
        fromAuthActions.refreshTokenSuccess
      ),
      tap(({tokenAccess, tokenId, scope, jwtToken}) => {
        localStorage.setItem('tokenAccess', tokenAccess);
        localStorage.setItem('tokenId', tokenId);
        localStorage.setItem('jwtToken', jwtToken)
        localStorage.setItem('scope', scope);
      }),
      mergeMap((action) => {
        const scopeAction = [];
        const actionToRetry = action.actionToRetry ? [action.actionToRetry] : []
        if (action.scope.split(' ').includes('https://www.googleapis.com/auth/cloud_search')) {
          scopeAction.push(fromCloudSearch.permissionGranted());
          scopeAction.push(fromAuthActions.initQueryAPI({token: action.tokenAccess}))
          scopeAction.push(fromCloudSearch.loadResults());
        } else {
          scopeAction.push(fromCloudSearch.setNoPermissions());
        }
        return [
          ...scopeAction,
          fromAuthActions.updateAuthToken({tokenAccess: action.tokenAccess}),
          fromAuthActions.updateIdToken({tokenId: action.tokenId}),
          fromAuthActions.updateJwtToken({jwtToken: action.jwtToken}),
          fromAuthActions.updateScope({scope: action.scope}),
          ...actionToRetry
        ]
      })
    ), {dispatch: true}
  );

  public ensure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromAuthActions.authorizeUserSuccess,
      ),
      tap(({tokenAccess, tokenId, scope, jwtToken}) => {
        localStorage.setItem('tokenAccess', tokenAccess);
        localStorage.setItem('tokenId', tokenId);
        localStorage.setItem('jwtToken', jwtToken)
        localStorage.setItem('scope', scope);
      }),
      map(() => fromAuthActions.ensureCreated())
    ), {dispatch: true}
  );

  // public idTokenChanged$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromAuthActions.updateIdToken),
  //     map(({tokenId}) => ({tokenId, expireTime: JSON.parse(atob(tokenId.split('.')[1])).exp})),
  //     debounce(({tokenId, expireTime}) => {
  //       const timeBeforeExpire = ConstNumbers.five * ConstNumbers.sixty * ConstNumbers.tausend;
  //       const date = new Date((expireTime * ConstNumbers.tausend - timeBeforeExpire));
  //       return timer(date);
  //     }),
  //     map(() => fromAuthActions.refreshTokenRequest())
  //   ), {dispatch: true}
  // );

  public jwtTokenRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.updateJwtToken),
      map(({jwtToken}) => ({jwtToken, expireTime: JSON.parse(atob(jwtToken.split('.')[1])).exp})),
      filter(({expireTime}) => (expireTime * ConstNumbers.tausend - new Date().getTime()) / MS_IN_DAY < 1),
      debounce(({jwtToken, expireTime}) => {
        const timeBeforeExpire = ConstNumbers.five * ConstNumbers.sixty * ConstNumbers.tausend;
        const date = new Date((expireTime * ConstNumbers.tausend - timeBeforeExpire));
        return timer(date);
      }),
      map(() => fromAuthActions.refreshTokenRequest({}))
    ), {dispatch: true}
  );

  public updateGapiToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.updateAuthToken),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectIsQueryAPILoaded))
      ),
      filter(([{tokenAccess}, isLoaded]) => isLoaded),
      tap(([{tokenAccess}]) => this.gapiService.setToken(tokenAccess))
    ), {dispatch: false}
  );

  public initQueryAPI$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromAuthActions.initQueryAPI),
    withLatestFrom(
      this.store$.pipe(select(fromSelectors.selectIsGapiLoaded)
      )
    ),
    first(([action, isLoaded]) => !!isLoaded),
    tap(([{token}]) => this.gapiService.init(token)),
  ), {dispatch: false}
);

  public ensureCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.ensureCreated),
      mergeMap(() => this.userProfileService.ensureCreated().pipe(
        map(() => fromAuthActions.ensureCreatedSuccess()),
        catchError(() => of(fromAuthActions.ensureCreatedFailure()))
      ))
    ), {dispatch: true}
  );

  public ensureCreatedSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.ensureCreatedSuccess),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectIsDialogOpened))
      ),
      mergeMap(([action, isDialogOpened]) => {
        if (isDialogOpened) {
          this.dialogService.closeAll();
          return [
            fromAuthActions.authCompleted()
          ];
        } else {
          return [
            fromAuthActions.authCompleted()
          ];
        }
      }),
    ), {dispatch: true});

  public ensureCreatedFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.ensureCreatedFailure),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectIsDialogOpenedUserNotCreated))
      ),
      mergeMap(([action, isDialogOpened]) => {
        if (!isDialogOpened) {
          return [
            fromAuthActions.openUserNotCreatedDialog(),
            fromAuthActions.userNotCreatedDialogIsOpened()
          ];
        } else {
          return [
            fromAuthActions.authCompleted()
          ];
        }
      }),
    ), {dispatch: true});

  public addPermissionCalendar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.addPermissionCalendar),
      switchMap(() => this.dialogService.open(ConfirmDialogComponent, {
        data:{
          title: `Please sign in to Google to add Events to your calendar.`,
          messages: ['This is a one-time operation, after which you need to add the event again to see it in your calendar.'],
          confirmButtonLabel: 'Confirm',
          confirmButtonType: 'primary'
        },
        size: 'm',
    }).afterClosed().pipe(
        filter(data => !!data),
        map((data) => ({data}))
    )),
    tap(() => this.googleService.addCalendarPermissions()),
    ), {dispatch: false});

  public askForPermissionAndAddEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.addPermissionCloudSearch),
      tap(() => this.googleService.addCloudSearchPermissions()),
    ), {dispatch: false});

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<fromAuth.State>,
    private readonly googleService: GoogleService,
    private readonly authService: AuthService,
    private readonly gapiService: GapiService,
    private readonly userProfileService: UserProfileService,
    private dialogService: RdsDialogService
  ) {
  }
}
