import {
	FeedbackStatisticsEnum,
	PeriodStatisticsModel,
	DeliveryStatisticsModel,
	CampaignStatisticsModel,
} from '@app/core/models/newsletter.model';
import { PeriodEnum } from '@app/shared/filters/models/newsletter-link-statistics';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const statistics = createActionGroup({
	source: 'STATISTICS ALL CAMPAIGN',
	events: {
		init: props<{ id: number }>(),
		clear: emptyProps(),
		requestOverall: props<{
			id: number;
		}>(),
		saveCampaignInfo: props<{ title: string; createdAt: Date; newsletters: Array<any> }>(),
		successOverall: props<{ statistics: DeliveryStatisticsModel }>(),
		initDelivery: props<{
			id: number;
			newsletters: Array<any>;
		}>(),
		requestDelivery: props<{
			id: number;
			range: { start: Date; end: Date };
			newsletters: Array<any>;
		}>(),
		successDelivery: props<{
			statistics: CampaignStatisticsModel;
			availableNewsletters: Array<{ label: string; value: number }>;
		}>(),
		updateRangeFilter: props<{ start: Date; end: Date }>(),
		initPeriod: props<{
			id: number;
			period: PeriodEnum;
			date: Date;
		}>(),
		updatePeriodFilter: props<{ period: PeriodEnum; date: Date }>(),
		requestPeriod: props<{
			id: number;
			period: PeriodEnum;
			date: Date;
		}>(),
		successPeriod: props<{ statistics: Array<PeriodStatisticsModel> }>(),
		// temporary
		updateNewslettersFilter: props<{ chartType; selected }>(),
		successNewsletter: props<{ newsletterDeliveryStatistics: any; availableNewsletters: Array<any> }>(),
		failure: props<{ error: any }>(),
		randomizeFeedback: emptyProps(),
		changeFeedbackType: props<{ feedbackType: FeedbackStatisticsEnum }>(),
	},
});
