import { Dictionary, EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { RhEvent } from '@app/events/models/event';
import * as fromActions from './events.actions';

export const feature = 'events';

export interface EventsSearch {
  id: string;
  events: EntityState<Partial<RhEvent>>;
}
export interface State extends EntityState<EventsSearch> {}

export const searchByAdapter: EntityAdapter<EventsSearch> =
 createEntityAdapter<EventsSearch>();

export const eventsAdapter: EntityAdapter<Partial<RhEvent>> =
 createEntityAdapter<Partial<RhEvent>>();

export const initialState: State = {
  ...searchByAdapter.getInitialState({
    ids: ['singleEvents'],
    entities: {
      'singleEvents': {
        events: {
          ...eventsAdapter.getInitialState()
        },
      }
    }

  }),
};

const eventsReducer = createReducer(
  initialState,
  on(fromActions.searchByRangeCollectionUpsertOne, (state, {start, end}) => {
    const id = `${start.getTime()}:${end.getTime()}`;
    if (!!state.entities[id]) {
      return state;
    }
    return (
      searchByAdapter.upsertOne({
        id,
        events: eventsAdapter.getInitialState()
      }, state)
    );
  }),
  on(fromActions.eventsByRangeCollectionUpsertMany, (state, {start, end, events}) => {
    return (
      searchByAdapter.upsertMany([
        {
          id: `${start.getTime()}:${end.getTime()}`,
          events: eventsAdapter.upsertMany(events, state.entities[`${start.getTime()}:${end.getTime()}`].events)
        },
        {
          id: 'singleEvents',
          events: eventsAdapter.upsertMany(events, state.entities['singleEvents'].events)
        }
      ], state)
    );
  }),
  on(fromActions.eventByIdCollectionUpsertOne, (state, {event}) => {
    return (
      searchByAdapter.upsertOne({
        id: 'singleEvents',
        events: eventsAdapter.upsertOne(event, state.entities['singleEvents'].events)
      }, state)
    );
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return eventsReducer(state, action);
}

export const getSearchByIds = searchByAdapter.getSelectors().selectIds;
export const getSearchByEntities = searchByAdapter.getSelectors().selectEntities;
export const getSearchByAll = searchByAdapter.getSelectors().selectAll;
export const getSearchByTotal = searchByAdapter.getSelectors().selectTotal;

export const getSearchByEntityById = (entities: Dictionary<EventsSearch>, {id}) => entities[id];
export const getSearchByEntitiesById = (entities: Dictionary<EventsSearch>, {ids}) => ids.map(id => entities[id]);

export const getEventsIds = eventsAdapter.getSelectors().selectIds;
export const getEventsEntities = eventsAdapter.getSelectors().selectEntities;
export const getEventsAll = eventsAdapter.getSelectors().selectAll;
export const getEventsTotal = eventsAdapter.getSelectors().selectTotal;

export const getEventsEntityById = (entities: Dictionary<Partial<RhEvent>>, {id}) => entities[id];
export const getEventsEntitiesById = (entities: Dictionary<Partial<RhEvent>>, {ids}) => ids.map(id => entities[id]);
