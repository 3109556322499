import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { RdsFlatTreeNode, RdsTreeControl, RdsTreeDataSource, RdsTreeFlattener, RdsTreeNode, RdsTreeSelectionModel } from '@rds/angular-components';
import { Observable, combineLatest, distinctUntilChanged, of } from 'rxjs';
import { SubSink } from 'subsink';

let nodeId = 0;

const getOrCreateId = (node: RdsTreeNode): string | number => {
  if (node.id) {
    return node.id;
  }

  return nodeId++;
};

const nodeTransformer = (
  node: RdsTreeNode,
  level: number
): RdsFlatTreeNode => ({
  id: getOrCreateId(node),
  expandable: !!node.children && !!node.children.length,
  name: node.name,
  level,
});

const treeFlattener = new RdsTreeFlattener<RdsTreeNode, RdsFlatTreeNode>(
  (node, level) => nodeTransformer(node, level),
  (node) => node.level,
  (node) => node.expandable,
  (node) => node.children
);

@Component({
  selector: 'rh-flat-tree',
  templateUrl: './flat-tree.component.html',
  styleUrls: ['./flat-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlatTreeComponent implements AfterViewInit, OnDestroy {
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport; 
  subs: SubSink = new SubSink();

  @Input() initialSelection: Observable<Array<number | string>> = of([]);
  @Input() data: Observable<Array<RdsTreeNode>>;
  @Input() size: 'm' | 's' = 'm'
  @Input() scrollable: boolean = false;
  @Input() disabled = false;
  @Input() entityType: 'channel' | 'topic' | 'user'| 'default' = 'default';
  @Input() treeType: 'selection' | 'mark' | 'delete' | 'info' = 'info';

  isSelection = () => {
    return this.treeType === 'selection';
  }

  isMark = () => {
    return this.treeType === 'mark';
  }

  isDelete = () => {
    return this.treeType === 'delete';
  }
  
  isInfo = () => {
    return this.treeType === 'info';
  }
  
  @Output() selected: EventEmitter<any> = new EventEmitter();
  @Output() deselected: EventEmitter<any> = new EventEmitter();
  @Output() deleted: EventEmitter<any> = new EventEmitter();
  @Output() clicked: EventEmitter<any> = new EventEmitter();

  activeNodeName = "";
  control = new RdsTreeControl<RdsFlatTreeNode>(
    (node) => node.level,
    (node) => node.expandable
  );
  allData: RdsTreeNode[] = [];
  dataSource = new RdsTreeDataSource(this.control, treeFlattener);
  selectionModel = new RdsTreeSelectionModel(this.control);
  
  constructor(private cdr: ChangeDetectorRef) {}
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get itemSize() {
    return this.size === 's' ? 46 : 50;
  }

  ngAfterViewInit(): void {
    if (this.scrollable) {
      this.subs.sink = combineLatest({
        initial: this.initialSelection,
        data: this.data,
        range: this.virtualScroll.renderedRangeStream
      }).pipe(
        distinctUntilChanged()
      ).subscribe(({initial, data, range}) => {
        this.updateData(data.slice(range.start, range.end), initial)
      });
    } else {
        this.subs.sink = combineLatest({
          initial: this.initialSelection,
          data: this.data
        }).subscribe(({initial, data}) => {
          this.updateData(data, initial)
        });
    }

  }

  toggleLeaf(node: RdsFlatTreeNode): void {
      this.selectionModel.leafItemSelectionToggle(node);
      if (this.selectionModel.isSelected(node)) {
        this.selected.emit(node);
      } else {
        this.deselected.emit(node);
      }
  }

  isLeafActive(node: RdsFlatTreeNode): boolean {
      return this.selectionModel.isSelected(node);
  }

  delete(node: RdsFlatTreeNode) {
    this.deleted.emit(node);
  }

  click(node: RdsFlatTreeNode) {
    this.clicked.emit(node);
  }

  updateData(data: RdsTreeNode[], initialSelection: Array<string | number>) {
    if (data.map(d => d.id).join('') !== this.dataSource.data.map(d => d.id).join('')) {
      this.dataSource.data = data;
    }
    const toSelect = this.control.dataNodes?.filter(n => initialSelection.includes(n.id) )
    if (!!toSelect) {
      this.selectionModel.setSelection(...toSelect)
    }

    this.cdr.markForCheck();
  }


  trackByFn(index: number, item: RdsFlatTreeNode) {
    return item.id;
  }

}
