import * as fromReducer from '../header.reducer';
import * as fromHeader from './quick-link.reducer';
import * as fromCategoriesFlat from '@core/core-store/categories-flat/categories-flat.selectors';

import {createSelector} from '@ngrx/store';
import { CategoryFlat } from '@app/core/models';

export const selectQuickLink = createSelector(
  fromReducer.selectState,
  fromReducer.getQuickLink
);

export const selectQuickLinksOpened = createSelector(
  selectQuickLink,
  fromHeader.getHeaderQuickLinksOpened
);

export const selectAllQuickLinks = createSelector(
  selectQuickLink,
  fromHeader.getAllQuickLinks
);

export const selectQuickLinksFavouritesInOrder = createSelector(
  selectQuickLink,
  fromHeader.getQuickLinksFavouritesInOrder
);

export const selectQuickLinksLoading = createSelector(
  selectQuickLink,
  fromHeader.getQuickLinksLoading
);

export const selectIsTemporaryListFilled = createSelector(
  selectQuickLink,
  fromHeader.getIsTemporaryListFilled
);

export const selectTemporaryQuickLinks = createSelector(
  selectQuickLink,
  fromHeader.getTemporaryQuickLinks
);

export const selectAllQuicklinksWithFavouritesInOrder = createSelector(
  selectQuickLink,
  fromHeader.getAllQuicklinksWithFavouritesInOrder
);

export const selectQuickLinksReorganizing = createSelector(
  selectQuickLink,
  fromHeader.getQuickLinksReorganizing
);

export const selectTemporaryQuicklinksWithAllQuickLinks = createSelector(
  selectQuickLink,
  fromHeader.getTemporaryQuicklinksWithAllQuickLinks
);

export const selectIsDeleteModeActive = createSelector(
  selectQuickLink,
  fromHeader.getIsDeleteModeActive
);

export const selectQuickLinkIdsToDelete = createSelector(
  selectQuickLink,
  fromHeader.getQuickLinkIdsToDelete
);

export const selecMyQuickLinks = createSelector(
  selectAllQuickLinks,
  (quicklinks) => quicklinks.filter(ql => !ql.isFavourite)
);

export const selectIsQuickLinkToDelete = (id: number) => createSelector(
  selectQuickLinkIdsToDelete,
  (ids) => ids.includes(id)
);

export const selectAllQuickLinksInCategory = (categoryId, selectedCategories) => createSelector(
  selectAllQuickLinks,
  fromCategoriesFlat.selectIdsOfBranchWithChildrens(categoryId),
  fromCategoriesFlat.selectBranchWithChildrens(categoryId, true),
  (quickLinks, categoryIds, category) => {
    categoryIds = selectedCategories.length ? getDescendats(selectedCategories, category) : categoryIds;
    return quickLinks.filter(ql => categoryIds.includes(ql.category.categoryId) && !ql.isFavourite);
  }
);

function getDescendats(parentCategoryIds: Array<number>, categories: Array<CategoryFlat>) {
  return categories.reduce((acc, curr) => {
    if (parentCategoryIds.includes(curr.categoryId)) {
      return [...acc, curr.categoryId, ...getDescendats(curr.childrenIds, categories)]
    } else return acc
  }, [])
}

export const selectAllUsedCategoriesIds = createSelector(
  selectAllQuickLinks,
  (quickLinks) => [...new Set([...quickLinks.filter(ql => !ql.isFavourite).map(ql => ql.category.categoryId)])]
);

export const selectCurrentTab = createSelector(
  selectQuickLink,
  fromHeader.getCurrentTab
);

export const selectEntitiesNames = createSelector(
  selectQuickLink,
  fromHeader.getEntitiesNames
);
