import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, filter, first, map, mergeMap, switchMap } from 'rxjs/operators';

import * as fromActions from '@app/content-list/store/content-list.actions';
import * as fromReducer from '@app/content-list/store/content-list.reducer';
import * as fromSelectors from '@app/content-list/store/content-list.selectors';
import * as formContentItems from '@app/core/core-store/content-items';
import { ContentService } from '@app/core/services/content.service';
import * as fromUsers from '@app/core/user/store';
import * as fromSubscription from '@app/home/store/subscriptions';
import * as fromRouter from '@app/root-store/router/router.actions';
import { ConfirmDialogComponent } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import * as fromLocationsFlat from '@core/core-store/locations-flat';
import * as fromUser from '@core/user/store/user.selectors';
import { SubscriptionType } from '@home/store/subscriptions';
import { RdsDialogService } from '@rds/angular-components';
import { of } from 'rxjs';

@Injectable()
export class ContentListEffects {

  public getItemsLocalGlobalList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.init,
        fromActions.loadMoreItems,
      ),
      switchMap(() => this.store$.pipe(
        select(fromSelectors.selectSubType),
        first(subType => !!subType),
        map((subType) => ({subType})),
      )),
      filter(({subType}) => ('' + subType === 'local' || '' + subType === 'global')),
      switchMap(({subType}) => this.store$.pipe(
        select(fromSubscription.selectAllUserChannels),
        first(value => !!value.length),
        map((value) => ({subType, value: value.map(item => item.id)})),
      )),
      switchMap(({subType, value}) => this.store$.pipe(
        select(fromSelectors.selectPagination),
        first(),
        map(({pageIndex, pageSize}) => ({value, subType, pageIndex, pageSize})),
      )),
      switchMap(({value, subType, pageIndex, pageSize}) => this.store$.pipe(
        select(fromUser.selectNewsLanguage),
        first(),
        map((language) => ({value, subType, pageIndex, pageSize, language}))
      )),
      switchMap(({value, subType, pageIndex, pageSize, language}) => this.store$.pipe(
        select(fromSelectors.selectSortType),
        first(),
        map((sortType) => ({value, subType, pageIndex, pageSize, language, sortType}))
      )),
      switchMap(({value, subType, pageIndex, pageSize, language, sortType}) => [fromActions.getItemsRequest({
          value,
          subType,
          pageIndex,
          pageSize,
          language: language.code,
          sortType
        })]
      )
    ), {dispatch: true}
  );

  public getItemsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.init,
        fromActions.loadMoreItems,
      ),
      switchMap(() => this.store$.pipe(
        select(fromSelectors.selectSubType),
        first(subType => !!subType),
        map((subType) => ({subType})),
      )),
      filter(({subType}) => subType === SubscriptionType.TOPICS || subType === SubscriptionType.CHANNELS || subType === SubscriptionType.AUTHORS),
      switchMap(({subType}) => this.store$.pipe(
        select(fromSelectors.selectId),
        first(id => !!id),
        map((value) => ({subType, value})),
      )),
      switchMap(({subType, value}) => this.store$.pipe(
        select(fromSelectors.selectPagination),
        first(),
        map(({pageIndex, pageSize}) => ({value, subType, pageIndex, pageSize})),
      )),
      switchMap(({value, subType, pageIndex, pageSize}) => this.store$.pipe(
        select(fromUser.selectNewsLanguage),
        first(),
        map((language) => ({value, subType, pageIndex, pageSize, language}))
      )),
      switchMap(({value, subType, pageIndex, pageSize, language}) => this.store$.pipe(
        select(fromSelectors.selectSortType),
        first(),
        map((sortType) => ({value, subType, pageIndex, pageSize, language, sortType}))
      )),
      switchMap(({value, subType, pageIndex, pageSize, language, sortType}) => [fromActions.getItemsRequest({
          value,
          subType,
          pageIndex,
          pageSize,
          language: language.code,
          sortType
        })]
      )
    ), {dispatch: true}
  );

  public getItemsRequestTopics$ = createEffect(() =>
      this.actions$.pipe(
        ofType(fromActions.getItemsRequest),
        filter(({subType}) => subType === SubscriptionType.TOPICS),
        switchMap(({
                     value,
                     subType,
                     pageIndex,
                     pageSize,
                     language,
                     sortType
                   }) => this.contentService.getNewsFromTopicSubscription(language, pageSize, pageIndex, [value], sortType).pipe(
          map(({global, pagination}) => fromActions.getItemsSuccess({
            value,
            contentItems: global.news,
            pagination: pagination
          })),
          catchError(({message}) => of(fromActions.getItemsError({value, error: message})))))
      ),
    {dispatch: true});

  public getItemsRequestAuthors$ = createEffect(() =>
      this.actions$.pipe(
        ofType(fromActions.getItemsRequest),
        filter(({subType}) => subType === SubscriptionType.AUTHORS),
        switchMap(({
                     value,
                     subType,
                     pageIndex,
                     pageSize,
                     language,
                     sortType
                   }) => this.contentService.getNewsFromAuthorSubscription(language, pageSize, pageIndex, [value], sortType).pipe(
          map(({global, pagination},) => fromActions.getItemsSuccess({
            value,
            contentItems: global.news,
            pagination: pagination
          })),
          catchError(({message}) => of(fromActions.getItemsError({value, error: message})))))
      ),
    {dispatch: true});

  public getItemsRequestChannels$ = createEffect(() =>
      this.actions$.pipe(
        ofType(fromActions.getItemsRequest),
        filter(({subType}) => subType === SubscriptionType.CHANNELS),
        switchMap(({
                     value,
                     subType,
                     pageIndex,
                     pageSize,
                     language,
                     sortType
                   }) => this.contentService.getNewsFromChannelSubscription(language, pageSize, pageIndex, [value], sortType).pipe(
          map(({global, local, pagination},) => {
            return fromActions.getItemsSuccess({
              value,
              contentItems: [...global.news, ...local.news],
              pagination: pagination
            })
          }),
          catchError(({message}) => of(fromActions.getItemsError({value, error: message})))))
      ),
    {dispatch: true});

  public getItemsRequestLocalGlobal = createEffect(() =>
      this.actions$.pipe(
        ofType(fromActions.getItemsRequest),
        filter(({subType}) => ('' + subType === 'local' || '' + subType === 'global')),
        switchMap(({
                     value,
                     subType,
                     pageIndex,
                     pageSize,
                     language,
                     sortType
                   }) => this.contentService.getNewsFromChannelSubscription(language, pageSize, pageIndex, value, sortType).pipe(
          map(({global, local, pagination},) => {
            return fromActions.getItemsSuccess({
              value,
              contentItems: '' + subType === 'local' ? [...local.news] : [...global.news],
              pagination: '' + subType === 'local' ? local.pagination : global.pagination
            })
          }),
          catchError(({message}) => of(fromActions.getItemsError({value, error: message})))))
      ),
    {dispatch: true});

  public getContentByIdSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getItemsSuccess),
      mergeMap(({contentItems}) => [
        formContentItems.contentItemsCollectionAddMany({contentItems}),
      ]),
    ), {dispatch: true}
  );

  public getContentByIdError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getItemsError),
      map(() => fromRouter.go({path: '500', queryParams: {}}))
    )
  );

  public goToContentPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToContent),
      mergeMap(({path, queryParams}) => [
        fromRouter.go({path, queryParams})
      ])
    ), {dispatch: true}
  );

  public goOutside$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goOutside),
      mergeMap(({url}) => [
        fromRouter.goOutsideInNewTab({url, redirect: true})
      ])
    ), {dispatch: true}
  );

  public openUnsubscribeConfirmDialog$ = createEffect(() =>
  this.actions$.pipe(
      ofType(fromActions.openUnsubscribeConfirmDialog),
      map(({id, name, subType}) => {
        let singularSubscriptionName = ''
        switch (subType) {
          case SubscriptionType.AUTHORS:
            singularSubscriptionName =  'Author';
          case SubscriptionType.TOPICS:
            singularSubscriptionName =  'topic';
          case SubscriptionType.CHANNELS:
            singularSubscriptionName =  'channel';
        }
        return ({
          data:{
            title: `Are you sure you want to delete "${name}" from your ${subType} list?`,
            messages: [`You can subscribe to this ${singularSubscriptionName} again at a later time.`],
            confirmButtonLabel: 'Yes, unsubscribe',
            confirmButtonType: 'primary'
          },
          id,
          subType
        })
      }),
      switchMap(({data, id, subType}) => this.dialogService.open(ConfirmDialogComponent, {
          data,
          size: 'm',
      }).afterClosed().pipe(
          filter(data => !!data),
          map(() => fromSubscription.deleteSubscriptionByIdsRequest({ids: [id], subType}))
      ))
  )
);
  // public addSharedChannelRequest$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromActions.addSharedChannelRequest),
  //     mergeMap(({ id }) => this.managingChannelsService.postSharedChannel(id).pipe(
  //       map(({updatedChannels, sharedChannelName}) => fromActions.addSharedChannelSuccess({updatedChannels, sharedChannelName, sharedChannelId: id})),
  //       catchError(({ message }) => of(fromActions.addSharedChannelFailure({ error: message })))
  //     ))
  //   ), { dispatch: true}
  // );

  // public undoSharedChannelRequest$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromActions.undoSharedChannelRequest),
  //     mergeMap(({ id }) => this.managingChannelsService.deleteUserChannelsByIds([id]).pipe(
  //       map(({updatedChannels}) => fromActions.undoSharedChannelSuccess({updatedChannels})),
  //       catchError(({ message }) => of(fromActions.undoSharedChannelFailure({ error: message })))
  //     ))
  //   ));

  // public addSharedChannelSuccess = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(
  //       fromActions.addSharedChannelSuccess,
  //       fromActions.undoSharedChannelSuccess),
  //     mergeMap(({ updatedChannels }) =>
  //     [
  //       fromUserChannels.userChannelsCollectionUpsertAll({channels: updatedChannels}),
  //       fromSubscriptions.deleteSubscriptionByIdsSuccess({
  //           updated: updatedChannels.map((channel): Subscription => ({
  //           name: channel.name,
  //           value: channel.id,
  //           url: `channel/${channel.id}`,
  //           isMandatory: channel.isMandatory
  //         })).sort((a, b) => a.name > b.name ? 1 : -1),
  //         subType: SubscriptionType.CHANNELS
  //       }),
  //       fromHome.loadChannels({channels: updatedChannels}),
  //       fromUserActions.updateUserChannels({ channels: updatedChannels})
  //     ]
  //   ),
  //   )
  // );


  public addLike$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addLike),
      mergeMap(({id, likeCount}) => this.contentService.putLikeByContentId({contentId: id}).pipe(
        mergeMap(() => [
          fromActions.addLikeSuccess({id, likeCount}),
          formContentItems.putLikeByContentIdSuccess({contentId: id, numLikes: likeCount})
        ]),
        catchError(() => of(fromActions.addLikeFailure({id, likeCount})))
      ))
    ), {dispatch: true}
  );

  public removeLike$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeLike),
      mergeMap(({id, likeCount}) => this.contentService.removeLikeByContentId({contentId: id}).pipe(
        mergeMap(() => [
          fromActions.removeLikeSuccess({id, likeCount}),
          formContentItems.removeLikeByContentIdSuccess({contentId: id, numLikes: likeCount})
        ]),
        catchError(() => of(fromActions.removeLikeFailure({id, likeCount})))
      ))
    ), {dispatch: true}
  );

  public addBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addBookmark),
      mergeMap(({id}) => this.contentService.addBookmark({contentId: id}).pipe(
        mergeMap(() => [
          fromActions.addBookmarkSuccess({id}),
          formContentItems.putBookmarkByContentIdSuccess({contentId: id})
        ]),
        catchError(() => of(fromActions.addBookmarkFailure()))
      ))
    ), {dispatch: true}
  );

  public removeBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeBookmark),
      mergeMap(({id}) => this.contentService.removeBookmark({contentId: id}).pipe(
        mergeMap(() => [
          fromActions.removeBookmarkSuccess({id}),
          formContentItems.removeBookmarkByContentIdSuccess({contentId: id})
        ]),
        catchError(() => of(fromActions.removeBookmarkFailure()))
      ))
    ), {dispatch: true}
  );

  public setSubTypeAndValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setSubTypeAndValue),
      mergeMap(({subType, value}) => {

        switch (subType) {
          case SubscriptionType.AUTHORS:
          case SubscriptionType.TOPICS:
            return [fromActions.setDataInfo({value, subType, name: value})]
          case SubscriptionType.CHANNELS:
            return this.contentService.getChannelById(value).pipe(
              map((channel) => fromActions.setDataInfo({
                subType,
                name: channel.name,
                value,
                isMandatory: channel.isMandatory
              })),
              catchError(() => of(fromRouter.go({path: '404', queryParams: {}})))
            );
        }

      })), {dispatch: true}
  );

  public setSubTypeLocalGlobal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setSubTypeLocalGlobal),
      mergeMap(({subType}) => {
        switch (subType) {
          case 'local':
            return [fromActions.getPersonalization({subType})];
          case 'global':
            return [fromActions.setLocalGlobalDataInfo({subType, name: 'Your other News'})]
        }

      })), {dispatch: true}
  );

  public getPersonalization$ = createEffect(() =>
      this.actions$.pipe(
        ofType(fromActions.getPersonalization),
        switchMap(({subType}) => this.store$.pipe(
          select(fromUsers.selectPersonalizationPreferencesLocations),
          first(ids => !!ids.length),
          map((ids) => ({ids, subType})),
        )),
        switchMap(({subType, ids}) => {
          if (ids && ids.length > 1) {
            return [fromActions.setLocalGlobalDataInfo({subType, name: 'Your local news'})];
          } else {
            return [fromLocationsFlat.loadLocationsFlat(),fromActions.getLocationText({subType, ids })];
          }
        })),

    {dispatch: true}
  );

  public getLocationText$ = createEffect(() =>
      this.actions$.pipe(
        ofType(fromActions.getLocationText),
        switchMap(({subType, ids}) => this.store$.pipe(
          select(fromLocationsFlat.selectEntityById, {id: ids[0]}),
          first(result => !!result),
          map((result) => ({name: result && result.name || '', subType})),
        )),
        switchMap(({subType, name}) => [fromActions.setLocalGlobalDataInfo({subType, name: 'Your news from ' + name})])),
    {dispatch: true}
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private contentService: ContentService,
    private dialogService: RdsDialogService
  ) {
  }
}
