import { GCSSearchResult, SearchSourceListItem } from '@app/search/models/gcs';
import { createAction, props } from '@ngrx/store';

export const clearSearch = createAction(
  '[CLOUD SEARCH] clear search'
);

export const searchRequest = createAction(
  '[CLOUD SEARCH] search request'
);

export const searchSuccess = createAction(
  '[CLOUD SEARCH] search success',
  props<{results: Array<GCSSearchResult>}>()
);

export const searchFailure = createAction(
  '[CLOUD SEARCH] search failure'
);

export const changeSource = createAction(
  '[CLOUD SEARCH] Change source',
  props<{source: SearchSourceListItem}>()
);

export const changeFilters = createAction(
  '[CLOUD SEARCH] Change filters',
  props<{filters: { [key: string]: string} }>()
);


export const initialRequest = createAction(
  '[CLOUD SEARCH] Initial request'
);

export const loadResults = createAction(
  '[CLOUD SEARCH] Load results'
);

export const loadResultsSuccess = createAction(
  '[CLOUD SEARCH] Load results success',
  props<{source: string; results: Array<GCSSearchResult>, hasMoreResults: boolean}>()
);

export const loadMoreResults = createAction(
  '[CLOUD SEARCH] Load more results'
);

export const loadMoreResultsSuccess = createAction(
  '[CLOUD SEARCH] Load more results success',
  props<{source: string; results: Array<GCSSearchResult>, hasMoreResults: boolean}>()
);

export const openMobileFilters = createAction(
  '[CLOUD SEARCH] open mobile filters'
)
export const closeMobileFilters = createAction(
  '[CLOUD SEARCH] close mobile filters'
)
export const closeMobileFiltersAndRevert = createAction(
  '[CLOUD SEARCH] close and revert mobile filters'
)

export const setNoPermissions = createAction(
  '[CLOUD SEARCH] set no permissions to CloudSearch'
);
export const permissionGranted = createAction(
  '[CLOUD SEARCH] permission granted'
);