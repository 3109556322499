import {createSelector} from '@ngrx/store';

import * as fromReducer from '@app/newsletter/store/newsletter.reducer';
import * as fromNewsletterForm from '@app/newsletter/store/newsletter-form/newsletter-form.reducer';
import * as fromOther from '@app/newsletter/store/newsletter-other/newsletter.selectors';

export const selectNewsletterForm = createSelector(
  fromReducer.selectState,
  fromReducer.getNewsletterForm
);

export const selectEditType = createSelector(
  selectNewsletterForm,
  fromNewsletterForm.getEditType
);

export const selectFormNewsletter = createSelector(
  selectNewsletterForm,
  fromNewsletterForm.getForm
);

export const selectFromEmail =
  createSelector(
    selectNewsletterForm,
    fromNewsletterForm.getFromEmail
);

export const selectAvailableSenders =
  createSelector(
    selectNewsletterForm,
    fromNewsletterForm.getAvailableSenders
);

export const selectRefreshed =
  createSelector(
    selectNewsletterForm,
    fromNewsletterForm.getRefreshed
  );

export const selectSearchingPreferencesUsersCount = createSelector(
  selectNewsletterForm,
  fromNewsletterForm.getUsersCount
);

export const selectNewsMoved = createSelector(
  selectNewsletterForm,
  fromNewsletterForm.getNewsMoved
);

export const selectNewsletterSections = createSelector(
  selectFormNewsletter,
  fromNewsletterForm.getSections
);

export const selectAllNewsFromSection = createSelector(
  selectNewsletterSections,
  (sections) => {
    let news = [];
    sections.forEach(s => {
      news = [
        ...news,
        ...s.news
          .filter(n => n.linkUrl && !n.linkUrl.includes('rochehome.page.link') && n.linkUrl.includes(location.origin))
          .map(n => ({id: n.newsId[0], title: n.title}))
      ];
    });
    return news;
  }
);

export const selectCanSaveAsDraft = createSelector(
  selectFormNewsletter,
  (form) => !!form?.title && form?.title.length < 129
);

export const selectFormWithDynamicLinks = createSelector(
  selectFormNewsletter,
  fromOther.selectDynamicLinksEntities,
  (form, dynamicLinks) => {
    dynamicLinks.forEach(link => {
      const sectionId = form.content.sections.findIndex(s => !!s.news.find(n => n.newsId[0] === link.id));
      if (sectionId > -1) {
        const sectionNews = form.content.sections[sectionId] && form.content.sections[sectionId].news ? form.content.sections[sectionId].news : [];
        const newsIdx = sectionNews.findIndex((n => n.newsId[0] === link.id));
        if (newsIdx > -1) {
          form = {
            ...form,
            content: {
              ...form.content,
              sections: [
                ...form.content.sections.slice(0, sectionId),
                {
                  ...form.content.sections[sectionId],
                  news: [
                    ...form.content.sections[sectionId].news.slice(0, newsIdx),
                    {
                      ...form.content.sections[sectionId].news[newsIdx],
                      linkUrl: form.content.sections[sectionId].news[newsIdx].linkUrl.includes(window.location.origin)
                        ?
                        link.linkUrl
                        :
                        form.content.sections[sectionId].news[newsIdx].linkUrl
                    },
                    ...form.content.sections[sectionId].news.slice(newsIdx + 1),
                  ]
                },
                ...form.content.sections.slice(sectionId + 1),
              ]
            }
          };
        }
      }
    });
    return form;
  }
);


export const selectFormNewsletterLoaded = createSelector(
  selectNewsletterForm,
  fromNewsletterForm.getLoaded
);
export const selectFormNewsletterIsLoaded = createSelector(
  selectNewsletterForm,
  fromNewsletterForm.getIsLoaded
);

export const selectIsContributed = createSelector(
  selectFormNewsletter,
  (form) => form && form.isContributed
);
