<input #fileInput rds-upload [config]="uploader.config" (fileSelected)="onFileSelected($event)" />
<ng-container *ngIf="!file?.url">
	<ng-container *ngIf="showStepInformation && this.disabled">
		<p class="mb-4 ui-label-l step-information">
			Your image will appear here. <br />
			Add it in the 2nd step.
		</p>
	</ng-container>
	<ng-container *ngIf="!showStepInformation || !this.disabled">
		<button
			*ngIf="hasDropZone; else simpleButton"
			[class.rds-upload-drop-zone-error]="hasError && !uploader.isUploading"
			rds-upload-drop-zone
			[disabled]="disabled"
			(click)="fileInput.click()"
			(fileDropped)="onFileSelected($event)"
			class="custom-button"
		>
			<p class="mb-4 ui-label-l">
				<rds-icon namespace="outlined" icon="upload"></rds-icon>
				<span class="c-600-roche-blue"> Upload </span>
				or drop image here
			</p>

			<ng-content select="rds-control-hint" ngProjectAs="rds-control-hint"></ng-content>
		</button>
		<rh-subscript-wrapper *ngIf="!!control" [hasError]="hasError && !uploader.isUploading" [disabled]="disabled">
			<ng-content select="rds-control-error" ngProjectAs="rds-control-error"></ng-content>
		</rh-subscript-wrapper>
		<ng-template #simpleButton>
			<button rds-secondary-button [disabled]="disabled" (click)="fileInput.click()">Choose files...</button>
		</ng-template>
	</ng-container>
</ng-container>
<ng-container *ngIf="file?.url">
	<div class="banner-upload">
		<img
			crossorigin="anonymous"
			rhDragDrop
			(fileDropped)="onFileSelected($event)"
			class="banner-upload__image"
			[src]="file?.url + '?v=' + now"
		/>
		<div class="banner-upload__reupload" (mousedown)="fileInput.click()" *ngIf="!disabled">
			<rds-icon
				class="banner-upload__reupload--icon"
				icon="upload"
				color="c-600-roche-blue"
				namespace="outlined"
			></rds-icon>
		</div>
	</div>
</ng-container>
