import {CatalogHeaderFilters, INITIAL_CATALOG_HEADER_FILTERS} from '@app/shared/filters/models/catalog-header';
import {Action, createReducer, on} from '@ngrx/store';
import {Category} from '../../models';
import {Catalog} from '../../models/Catalog';
import * as fromActions from '../catalogs/catalogs.actions';

export interface State {
  categories: Array<Category>;
  openedCategory: Category;
  filters: CatalogHeaderFilters;
  defaultFilters: CatalogHeaderFilters;
  advancedFiltersCount: number;
  catalogs: Array<Catalog>;
  isLoading: boolean;
}

export const initialState: State = {
  categories: [],
  openedCategory: null,
  filters: INITIAL_CATALOG_HEADER_FILTERS,
  defaultFilters: INITIAL_CATALOG_HEADER_FILTERS,
  advancedFiltersCount: 0,
  catalogs: [],
  isLoading: false,
};

export const catalogsReducer = createReducer(
  initialState,
  on(fromActions.getCategoriesSuccess, (state, {categories}) => ({
    ...state,
    categories
  })),
  on(fromActions.setDefaultTaxonomyFilter, (state, {locations, departments, functions}) => ({
    ...state,
    filters: {
      ...state.filters,
      predefined: {
        ...state.filters.predefined,
        predefinedValue: {
          locations,
          departments,
          functions
        }
      }
    },
    defaultFilters: {
      ...state.defaultFilters,
      predefined: {
        ...state.defaultFilters.predefined,
        predefinedValue: {
          locations,
          departments,
          functions
        }
      }
    }
  })),
  on(fromActions.getCatalogsRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(fromActions.getCatalogsSuccess, (state, {catalogs}) => ({
    ...state,
    catalogs,
    isLoading: false,
  })),
  on(fromActions.openCategory, fromActions.openCategoryFromLink, (state, {category}) => ({
    ...state,
    openedCategory: category
  })),
  // on(fromActions.openCategoryAndUpdateFilters, (state, {filters}) => ({
  //   ...state,
  //   isLoading$: true,
  //   openedCategory: null,
  //   catalogs: [],
  //   filters:  {
  //     ...state.filters,
  //     categories: {
  //       ...state.filters.categories,
  //       value: filters.categoryId
  //     },
  //     locations: {
  //       ...state.filters.locations,
  //       value: filters.locations
  //     },
  //     departments: {
  //       ...state.filters.locations,
  //       value: filters.locations
  //     },
  //     functions: {
  //       ...state.filters.locations,
  //       value: filters.locations
  //     }
  //   },
  //   advancedFiltersCount: 0
  // })),
  on(fromActions.backToCategories, (state) => ({
    ...state,
    openedCategory: null,
    catalogs: [],
    filters: INITIAL_CATALOG_HEADER_FILTERS,
    advancedFiltersCount: 0
  })),
  on(fromActions.updateFilters, (state, {filters}) => ({
    ...state,
    filters: {
      ...filters,
      categories: {
        ...filters.categories,
        fromBranch: state.openedCategory?.categoryId
      }
    }
  })),
  on(fromActions.addQuickLinkSuccess, (state, {quickLink}) => {
    return {
      ...state,
      catalogs: [...state.catalogs].filter(catalog => catalog.catalogId !== quickLink.catalogId)
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return catalogsReducer(state, action);
}

export const getCategories = (state: State) => state.categories;
export const getOpenedCategory = (state: State) => state.openedCategory;
export const getFilters = (state: State) => state.filters;
export const getDefaultFilters = (state: State) => state.defaultFilters;
export const getAdvancedFiltersCount = (state: State) => state.advancedFiltersCount;
export const getCatalogs = (state: State) => state.catalogs;
export const getIsLoading = (state: State) => state.isLoading;
