import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import * as fromCatalog from '@app/layout/header/store/catalogs';
import * as fromQuickLink from '@app/layout/header/store/quick-link';
import { Store } from '@ngrx/store';
import { RdsMenuComponent } from '@rds/angular-components';
import { HeaderLinkFilter, HeaderLinkNode } from '../dummy-links';

@Component({
  selector: 'rh-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent {
  @ViewChild("menu", {static: true}) menu: RdsMenuComponent;
  @Input() links: Array<HeaderLinkNode>;
  @Input() triggerIndex: number;

  @Output() submenuToggled: EventEmitter<any> = new EventEmitter();

  constructor(
    private store$: Store
  ) {
  }

  openCatalog(filters: HeaderLinkFilter) {
    this.store$.dispatch(fromQuickLink.toggleHeaderQuickLinks());
    this.store$.dispatch(fromQuickLink.catalogsTabOpened());
    this.store$.dispatch(fromCatalog.openCategoryAndUpdateFilters({filters}));
    // this.store$.dispatch(fromCatalog.updateFilters({filters: filters, advancedFiltersCount: 0}));
  }

  toggleSubmenu() {
    this.submenuToggled.emit();
  }
}
