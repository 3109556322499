<div *ngIf="width > 0">
  <content-loader
    [baseUrl]="componentsLoaderService.baseUrl"
    [backgroundColor]="'#EAE8E5'"
    [foregroundColor]="'#DBD6D1'"
    [viewBox]="'0 0  1008 1250'"
    [speed]="2"
    [interval]="0.75"
    [gradientRatio]="2.4">
    <svg:rect x="0" y="72" rx="0" width="420" height="32"/>
    <svg:rect x="0" y="116" rx="0" width="460" height="32"/>
    <svg:rect x="0" y="160" rx="0" width="300" height="32"/>

    <svg:rect x="0" y="298" rx="0" width="120" height="18"/>
    <svg:rect x="132" y="298" rx="0" width="120" height="18"/>

    <svg:rect x="0" y="330" rx="0" width="18" height="18"/>
    <svg:rect x="21" y="330" rx="0" width="24" height="18"/>

    <svg:rect x="60" y="330" rx="0" width="18" height="18"/>
    <svg:rect x="81" y="330" rx="0" width="24" height="18"/>
    <svg:rect x="120" y="330" rx="0" width="18" height="18"/>
    <svg:rect x="160" y="330" rx="0" width="18" height="18"/>
    <svg:rect x="200" y="330" rx="0" width="18" height="18"/>

    <svg:rect x="520" y="72" rx="0" width="504" height="274"/>


    <svg:rect x="0" y="420" rx="0" width="1008" height="18"/>
    <svg:rect x="0" y="446" rx="0" width="1008" height="18"/>
    <svg:rect x="0" y="472" rx="0" width="860" height="18"/>


    <svg:rect x="0" y="550" rx="0" width="1008" height="18"/>
    <svg:rect x="0" y="576" rx="0" width="1008" height="18"/>
    <svg:rect x="0" y="602" rx="0" width="860" height="18"/>

    <svg:rect x="0" y="680" rx="0" width="1008" height="18"/>
    <svg:rect x="0" y="706" rx="0" width="1008" height="18"/>
    <svg:rect x="0" y="732" rx="0" width="860" height="18"/>

    <svg:rect x="0" y="860" rx="0" width="100" height="24"/>
    <svg:rect x="112" y="860" rx="20" width="100" height="24"/>
    <svg:rect x="224" y="860" rx="20" width="100" height="24"/>
    <svg:rect x="336" y="860" rx="20" width="100" height="24"/>
    <svg:rect x="448" y="860" rx="20" width="100" height="24"/>
    <svg:rect x="560" y="860" rx="20" width="100" height="24"/>

    <svg:rect x="0" y="904" rx="0" width="80" height="24"/>
    <svg:rect x="102" y="904" rx="20" width="80" height="24"/>
    <svg:rect x="204" y="904" rx="20" width="80" height="24"/>
    <svg:rect x="306" y="904" rx="20" width="80" height="24"/>
    <svg:rect x="408" y="904" rx="20" width="80" height="24"/>
    <svg:rect x="510" y="904" rx="20" width="80" height="24"/>

    <svg:rect x="0" y="1000" rx="0" width="128" height="24"/>

    <svg:rect x="0" y="1032" rx="0" width="1008" height="152"/>
    <svg:rect x="0" y="1190" rx="0" width="256" height="18"/>
    <svg:rect x="944" y="1190" rx="0" width="64" height="18"/>
    <svg:rect x="880" y="1212" rx="0" width="128" height="18"/>

  </content-loader>
</div>
