import { Pipe, PipeTransform } from '@angular/core';
import { Channel, NewsletterNews } from '@app/core/models';
import { GapiService } from '@app/core/services/gapi.service';
import { RhEvent } from '@app/events/models/event';
import { environment } from '@env/environment';
import { getISOWeek } from 'date-fns';

type LinkType = 'event-details' | 'event-add-gcal' | 'news-details-for-newsletter' | 'channel-details-for-newsletter';
@Pipe({
	name: 'getLink',
})
export class GetLinkPipe implements PipeTransform {
	transform(entity: RhEvent | NewsletterNews | Channel, type: LinkType, language?: string): any {
		if (!type) {
			throw new Error('Specify type for a link');
		}
		switch (type) {
			case 'event-details':
				return `${environment.url}/event/details/${(entity as RhEvent).id}`;
			case 'event-add-gcal':
				return this.gapiService.getAddEventToGcalUrl(entity as Partial<RhEvent>);
			case 'news-details-for-newsletter': {
				const news = entity as NewsletterNews;
				let newsLanguage = language;
				if (!Object.keys(news.abstracts).includes(language)) {
					newsLanguage = news.primaryLanguage;
				}
				if (news.type === 'link') {
					return news.abstracts[newsLanguage].url;
				}
				return this.createUrlForInternalNews(entity as NewsletterNews, newsLanguage);
			}
			case 'channel-details-for-newsletter': {
				return `${environment.url}/list/channel/${(entity as Channel).id}`;
			}
		}
	}

	constructor(private gapiService: GapiService) {}

	createUrlForInternalNews(data: NewsletterNews, language: string): string {
		const weekOfYear = getISOWeek(new Date());
		const abstract = data.abstracts[language];
		return `${environment.url}/content/${data.newsId}?language=${language}&title=${encodeURIComponent(abstract.title.trim().replace(/\s/g, '_')).replace(/'/g, '%27')}&utm_source=newsletter&utm_medium=email&utm_campaign=newsletter_cw${weekOfYear}_${encodeURIComponent(abstract.title.trim().replace(/\s/g, '_')).replace(/'/g, '%27')}`;
	}
}
