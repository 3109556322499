import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { Component, ContentChild, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Pagination } from '@app/core/models';
import { PageEvent, RDS_AVATAR_MAX_IN_GROUP, RdsPaginatorComponent, RdsSortDirective, RdsTableComponent, Sort, SortDirection } from '@rds/angular-components';
import { Observable, Subject, distinctUntilChanged } from 'rxjs';
import { SubSink } from 'subsink';
import { SORTABLE_LOADING } from './loading-sort-columns';

export interface TableColumn {
  sortable: boolean;
  widthStyle: string;
}

@Component({
  selector: 'rh-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [{
    provide: RDS_AVATAR_MAX_IN_GROUP,
    useValue: 999
  }]
})
export class TableComponent implements OnInit, OnDestroy {
  @ViewChild('sortRef', { static: true, read: RdsSortDirective }) sortRef: RdsSortDirective | null = null;
  @ViewChild('paginatorRef', { static: true, read: RdsPaginatorComponent }) paginatorRef: RdsPaginatorComponent | null = null;
  @ViewChild('tableRef', { static: true, read: RdsTableComponent }) tableRef: RdsTableComponent<any> | null = null;
  
  @ContentChild('actions') actionsTemplate: TemplateRef<any>;

  subs: SubSink = new SubSink();

  @Input() data: Array<any> = [];
  @Input() defaultSortActive: string;
  @Input() defaultSortDirection: SortDirection;
  @Input() columns: Array<string> = [];
  @Input() hasBulk: boolean = false;
  @Input() bulkPredicate: (args: any) => boolean;
  @Input() disableRowPredicate: (args: any) => boolean = (row) => false;
  @Input() initialSelectionPredicate: (args: any) => boolean;
  @Input() pagination: Pagination;
  @Input() pageSizeOptions: Array<number> = [10,25,50];
  @Input() totalObs: Observable<number>;
  @Input() hasActions: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() highlight: string = '';
  @Input() filtersApplied: boolean = false;
  @Input() customRowVisible: boolean = false;
  loadingRows = [];

  get allColumns(): Array<string> {
    const bulk = this.hasBulk ? ['select'] : [];
    const actions = this.hasActions ? ['actions'] : [];

    return [...this.columns.filter(c => c === 'pin'), ...bulk, ...this.columns.filter(c => c !== 'pin'), ...actions];
  }

  get loadingColumns() {
    return this.allColumns.map((c) => `${c}.loading`);
  }

  isSortable(columnName) {
    return SORTABLE_LOADING.includes(columnName);
  }

  get deleteColumns(): Array<string> {
    const pin = this.columns.find(c => c === 'pin');
    const pinColumn = pin? [pin] : []; 
    return [...pinColumn, ...['delete-checkbox', 'delete']];
  } ;

  @Output() linkClicked: EventEmitter<{link: string, linkType: 'route' | 'url' }> = new EventEmitter();
  @Output() pinClicked: EventEmitter<{id: number, isPinned: boolean}> = new EventEmitter();
  @Output() rejectClicked: EventEmitter<{id: number, title: string}> = new EventEmitter();
  @Output() acceptClicked: EventEmitter<{id: number}> = new EventEmitter();
  @Output() assignClicked: EventEmitter<{id: number, isDraft: boolean}> = new EventEmitter();

  @Output() sortChanged: EventEmitter<Sort> = new EventEmitter();
  @Output() paginationChanged: EventEmitter<PageEvent> = new EventEmitter();
  
  selection = new SelectionModel<any>(true, []);

  @Output() selectionChanged: Subject<SelectionChange<any>> = this.selection.changed;

  public defaultTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  get selectedCount() {
    return this.selection.selected.length;
  }

  get disableMasterToggle() {
    if (!!this.bulkPredicate) {
      return this.data.filter(r => this.bulkPredicate(r)).length === 0;
      } else {
      return false
      }
  }

  get isAnyOnPageSelected() {
    if (!!this.bulkPredicate) {
    return this.data.filter(r => this.bulkPredicate(r)).some(i => this.selection.selected.includes(i));
    } else {
    return this.data.some(i => this.selection.selected.includes(i));
    }
  }
  get isAllOnPageSelected() {
    if (!!this.bulkPredicate) {
      return this.data.filter(r => this.bulkPredicate(r)).every(i => this.selection.selected.includes(i));
      } else {
      return this.data.every(i => this.selection.selected.includes(i));
      }
  }

  constructor() { }

  ngOnInit(): void {
    this.setLoadingRows(this.pagination?.pageSize)
    this.subs.sink = this.totalObs.pipe(
      distinctUntilChanged()).subscribe(total => {
      this.clearAll();
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setLoadingRows(size) {
    this.loadingRows = [...Array(size)];
  }

  onPagination(event: PageEvent) {
    if (event.pageSize !== this.loadingRows.length) {
      this.setLoadingRows(event.pageSize);
    }
    this.clearAll();
    this.paginationChanged.emit(event);
  }

  onSort(event: Sort) {
    let start = this.defaultSortDirection
    if (event.direction === '') {
      if (event.active === this.defaultSortActive) {
        switch (this.defaultSortDirection) {
          case 'asc':
            start = 'asc';
            break;
          case 'desc':
            start = 'asc';
        }
      }
      this.sortRef.sort({
        id: this.defaultSortActive,
        start,
        disableClear: false
      })
    } else {
      this.clearAll();
      this.sortChanged.emit(event);
    }
  }

  masterToggle() {
    this.isAllOnPageSelected ?
      this.clearAllOnPage() : this.selectAllOnPage();
  }

  clearAllOnPage() {
    if (!!this.bulkPredicate) {
      this.selection.deselect(...this.data.filter(r => this.bulkPredicate(r)));
    } else {
    this.selection.deselect(...this.data);
    }
  }

  clearAll() {
    this.selection.clear();
  }

  selectAllOnPage() {
    if (!!this.bulkPredicate) {
      this.selection.select(...this.data.filter(r => this.bulkPredicate(r)));
    } else {
      this.selection.select(...this.data);
    }
  }

}
