import { createAction, props } from '@ngrx/store';
import { UserChannel } from '@app/core/models';

export const initHomePage = createAction(
  '[HOME PAGE - INIT] Init Home page'
);
export const initOtherRequests = createAction(
  '[HOME PAGE - INIT] Init other requests'
);

export const loadChannels = createAction(
  '[HOME PAGE - LOAD CHANNELS] Load channels for Home page',
  props<{ channels: Array<UserChannel> }>()
);

export const updateChannels = createAction(
  '[HOME PAGE - UPDATE CHANNELS] Update channels for Home page',
  props<{ channels: Array<UserChannel> }>()
);

export const renderNextChannel = createAction(
  '[HOME PAGE - RENDER NEXT CHANNEL] Render next channel',
);

export const resetChannels = createAction(
  '[HOME PAGE - RESET CHANNELS] Reset channels',
);
