import {Injectable} from '@angular/core';
import * as fromActions from './details.actions';
import * as fromRouter from '@app/root-store/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { EventsService } from '@app/core/services/managing-events.service';
import {mergeMap} from 'rxjs/operators';
import {GapiService} from '@core/services/gapi.service';

@Injectable()
export class DetailsEffects {

  public getEventDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getEventDetails),
      switchMap(({id}) => this.eventsService.getById(id).pipe(
        map(event => fromActions.getEventDetailsSuccess({event: {...event, id}})),
        catchError(({ message }) => of(fromActions.getEventDetailsFailure({ error: message })))
      )),
    ), { dispatch: true}
  );

  public getEventDetailsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getEventDetailsFailure),
      map(() => fromRouter.go({ path: 'event', queryParams: {}}))
    ), { dispatch: true}
  );

  public addToCalendar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addToCalendarRequest),
      mergeMap(({event}) => this.gapiService.addEventToGcal(event).pipe(
        map((events) => fromActions.addToCalendarSuccess({event})),
        catchError(() => of(fromActions.addToCalendarFailure({event})))
      ))
    ), {dispatch: true}
  );

  public shareEvent = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.shareEvent),
      switchMap(({body}) => [fromRouter.mailToInNewTab({body, content: 'event'})])
    ), {dispatch: true}
  );

    constructor(
      private actions$: Actions,
      private eventsService: EventsService,
      private gapiService: GapiService
  ) {
  }
}
