<ng-container>
    <rds-form-field class="hide">
        <rds-control-label>Label</rds-control-label>
        <rds-select>
            <rds-select-option value="onion">Onion</rds-select-option>
        </rds-select>
    </rds-form-field>
    <div class="ql__container"
         [ngClass]="{'ql__container--opened': areOpenedQuickLinks, 'ql__container--mobile': isMobile}">
        <div class="ql__container__content">
            <rds-tabs [size]="('MOBILE' | isViewMode | async)? 's' : 'm'" #tabs (click)="tabsClicked(tabs)">
                <rds-tab label="QuickLinks">
                    <ng-container *atrIfViewMode="['TABLET','DESKTOP']">
                        <ng-container
                                *ngIf="{topQuickLinks: topQuickLinks$ | async , temporaryTopQuickLinks: temporaryTopQuickLinks$ | async} as topQuickLinks">
                            <ng-container
                                    *ngIf="topQuickLinks.temporaryTopQuickLinks.length ||topQuickLinks.topQuickLinks.length; else buttons ">
                                <div class="row row-centered">
                                    <div class="col mt-9 mb-5">
                                        <h4 class="ui-heading-4-bold--xl">
                                            My Top Quicklinks
                                        </h4>
                                    </div>
                                    <div class="col-auto">
                                        <button
                                                *ngIf="(isDeleteModeActive$ | async) === true"
                                                rds-secondary-button (click)="closeDeleteMode()"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                    <div class="col-auto">
                                        <button
                                                *ngIf="(isDeleteModeActive$ | async) === true"
                                                rds-warning-button (click)="openDeleteDialog()"
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>

                                <rh-quick-links-paginator
                                        [allData]="(isTemporaryListFilled$ | async) === false? (topQuickLinks$ | async) : temporaryTopQuickLinks$ | async"></rh-quick-links-paginator>
                            </ng-container>
                        </ng-container>
                        <div *ngIf="quickLinkCategories$ | async as categories">
                            <div class="row row-centered">
                                <div class="col-auto mt-9 mb-5">
                                    <h4 class="ui-heading-4-bold--xl">
                                        My Quicklinks
                                    </h4>
                                </div>
                                <div class="col-auto mt-9 mb-5">
                                    <div class="ql__container__filter" [formGroup]="categoryForm">
                                        <rh-dropdown
                                                formControlName="category"
                                                [alwaysOpened]="false"
                                                [showChips]="false"
                                                [floatingOptions]="true"
                                                [isMultiple]="true"
                                                [optionsDictionary]="(categoriesDict$ | async)"
                                                [optionsModel]="categoriesOptionsModel"
                                                (withOpenChange)="categoriesOpened = $event"
                                                [selectParentWhenAllSelected]="false"
                                                (ngModelChange)="refreshView()"
                                        >
                                            <div
                                                    class="ql__container__category"
                                                    [class.ql__container__category--opened]="categoriesOpened"
                                                    trigger
                                            >
                                                <span class="ql__container__category__name">Category: </span>
                                                <div class="ql__container__category__value">
                                                    {{
                                                    (categoryForm?.get('category')?.value?.length)
                                                        ? 'Selected (' + categoryForm?.get('category')?.value?.length + ')'
                                                        : 'All'
                                                    }}
                                                </div>
                                                <rds-icon
                                                        class="ql__container__category__icon"
                                                        [icon]="(categoriesOpened) ? 'chevron_up' : 'chevron_down'"
                                                ></rds-icon>
                                            </div>
                                        </rh-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div *ngFor="let category of categories; index as i;">
                                <ng-container *ngIf="(getQuickLinksForCategory(category) | async) as quickLinks">
                                    <rh-quick-links-paginator *ngIf="quickLinks.length" [allData]="quickLinks"
                                                              [isFirstCategory]="i === 0"
                                                              [title]="category.name"></rh-quick-links-paginator>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *atrIfViewMode="['MOBILE']">
                        <div class="row">
                            <div class="col-auto my-4">
                                <rh-back-button (click)="closeQuickLink()" defaultLabel="Home"
                                                defaultRoute="/"></rh-back-button>
                            </div>
                        </div>
                        <div *ngIf="(isDeleteModeActive$ | async) === true" class="ql__container__btns">
                            <button
                                    *ngIf="(isDeleteModeActive$ | async) === true"
                                    rds-secondary-button (click)="closeDeleteMode()"
                            >
                                Cancel
                            </button>
                            <button
                                    *ngIf="(isDeleteModeActive$ | async) === true"
                                    rds-warning-button (click)="openDeleteDialog()"
                            >
                                Delete
                            </button>
                        </div>
                        <div class="ql__container__accordion">
                            <rds-accordion multi>
                                <ng-container
                                        *ngIf="((isTemporaryListFilled$ | async) === false? (topQuickLinks$ | async) : temporaryTopQuickLinks$ | async) as quickLinksSet">
                                    <rds-expansion-panel *ngIf="quickLinksSet.length">
                                        <rds-expansion-panel-header class="ql__container__accordion--header">
                    <span class="ql__container__accordion--header__text">
                      <span>My Top Quicklinks</span>
                    </span>
                                        </rds-expansion-panel-header>
                                        <div class="ql__container__accordion--quicklinks">
                                            <rh-single-catalog-item
                                                    *ngFor="let ql of quickLinksSet"
                                                    [catalog]="ql"
                                                    [allowSwipe]="(isDeleteModeActive$ | async) === false"
                                                    [actionsAlwaysVisible]="(isDeleteModeActive$ | async) === true">
                                                <ng-container *ngIf="(isDeleteModeActive$ | async) === false" swipe>
                                                    <button (click)="ql.isFavourite? removeFromTopQL(ql) : addToTopQL(ql)">
                                                        <rds-icon icon="star"
                                                                  [namespace]="ql.isFavourite? 'filled' : 'outlined'"></rds-icon>
                                                    </button>
                                                    <button (click)="renameQL(ql)">
                                                        <rds-icon icon="edit" namespace="outlined"></rds-icon>
                                                    </button>
                                                    <button (click)="deleteQL(ql)">
                                                        <rds-icon icon="delete" namespace="outlined"></rds-icon>
                                                    </button>
                                                </ng-container>
                                                <ng-container actions *ngIf="(isDeleteModeActive$ | async) === true">
                                                    <rds-checkbox
                                                            (changed)="checkboxClicked($event, ql)"
                                                            [checked]="shouldBeDeleted(ql) | async">
                                                    </rds-checkbox>
                                                </ng-container>
                                            </rh-single-catalog-item>
                                        </div>
                                    </rds-expansion-panel>
                                </ng-container>
                                <rds-expansion-panel>
                                    <rds-expansion-panel-header class="ql__container__accordion--header">
                    <span class="ql__container__accordion--header__text">
                      <span>My Quicklinks</span>
                    </span>
                                    </rds-expansion-panel-header>
                                    <div class="ql__container__accordion--quicklinks">
                                        <rh-single-catalog-item *ngFor="let ql of allMyQuickLinks$ | async"
                                                                [catalog]="ql"
                                                                [allowSwipe]="(isDeleteModeActive$ | async) === false"
                                                                [actionsAlwaysVisible]="(isDeleteModeActive$ | async) === true">
                                            <ng-container *ngIf="(isDeleteModeActive$ | async) === false" swipe>
                                                <button (click)="ql.isFavourite? removeFromTopQL(ql) : addToTopQL(ql)">
                                                    <rds-icon icon="star"
                                                              [namespace]="ql.isFavourite? 'filled' : 'outlined'"></rds-icon>
                                                </button>
                                                <button (click)="renameQL(ql)">
                                                    <rds-icon icon="edit" namespace="outlined"></rds-icon>
                                                </button>
                                                <button (click)="deleteQL(ql)">
                                                    <rds-icon icon="delete" namespace="outlined"></rds-icon>
                                                </button>
                                            </ng-container>
                                            <ng-container actions *ngIf="(isDeleteModeActive$ | async) === true">
                                                <rds-checkbox
                                                        (changed)="checkboxClicked($event, ql)"
                                                        [checked]="shouldBeDeleted(ql) | async">
                                                </rds-checkbox>
                                            </ng-container>
                                        </rh-single-catalog-item>
                                    </div>
                                </rds-expansion-panel>
                            </rds-accordion>
                        </div>
                    </ng-container>
                </rds-tab>
                <rds-tab label="Catalogue">
                    <div *atrIfViewMode="['MOBILE']" class="row">
                        <div class="col-auto my-4">
                            <rh-back-button [class.hide]="openedCategory$ | async" (click)="closeQuickLink()"
                                            defaultLabel="Home" defaultRoute="/"></rh-back-button>
                        </div>
                    </div>
                    <rh-header-content></rh-header-content>
                </rds-tab>
            </rds-tabs>
            <div class="ql__container__content--options" [class.mobile-options]="('MOBILE' | isViewMode | async)">
                <button
                        *ngIf="tabsEnum.CATALOGUE !== currentTab"
                        rds-icon-button size="m"
                        [rdsMenuTriggerFor]="menu"
                >
                    <rds-icon namespace="filled" icon="more_horizontal"></rds-icon>
                </button>
                <button rds-icon-button size="m" (click)="closeQuickLink()">
                    <rds-icon namespace="filled" icon="close"></rds-icon>
                </button>
            </div>
        </div>
    </div>
</ng-container>
<rds-menu #menu="rdsMenu" [xPosition]="'before'">
    <button rds-menu-item (click)="reorganize()">
        <span>Reorganize Top Quicklinks</span>
    </button>
    <button rds-menu-item (click)="deleteMulti()">
        <span>Select and delete</span>
    </button>
    <button rds-menu-item (click)="resetToDefault()">
        <span>Reset to default</span>
    </button>
</rds-menu>
<ng-template #buttons>
    <div class="ql__container__section-header">
        <button
                *ngIf="(isDeleteModeActive$ | async) === true"
                rds-secondary-button (click)="closeDeleteMode()"
        >
            Cancel
        </button>
        <button
                *ngIf="(isDeleteModeActive$ | async) === true"
                rds-warning-button (click)="openDeleteDialog()"
        >
            Delete
        </button>
    </div>
</ng-template>
