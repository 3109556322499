<h2 rds-dialog-header>Are you sure you want to reset Quicklinks to its default?</h2>
<div rds-dialog-content>
  <p class="mb-5">
    This will revert your Quicklinks to its default based on your personalization preferences, to change them go to <a href="/settings/global">Settings</a>. Below is the list of links that will be added and removed from your Quicklinks.
  </p>
  <div *ngIf="quickLinksToAdd && quickLinksToRemove" class="reset-ql-modal__accordion">
    <rds-accordion *ngIf="quickLinksToAdd.length + quickLinksToRemove.length > 0">
      <rds-expansion-panel [disabled]="quickLinksToRemove.length === 0">
        <rds-expansion-panel-header>
          Quicklinks to be deleted ({{quickLinksToRemove.length}})
        </rds-expansion-panel-header>
        <div class="reset-ql-modal__accordion--quicklinks">
          <rh-single-catalog-item *ngFor="let ql of quickLinksToRemove" [catalog]="ql">
          </rh-single-catalog-item>
        </div>
      </rds-expansion-panel>
      <rds-expansion-panel [disabled]="quickLinksToAdd.length === 0">
        <rds-expansion-panel-header>
          Quicklinks to be added ({{quickLinksToAdd.length}})
        </rds-expansion-panel-header>
        <div class="reset-ql-modal__accordion--quicklinks">
          <rh-single-catalog-item *ngFor="let ql of quickLinksToAdd" [catalog]="ql">
          </rh-single-catalog-item>
        </div>
      </rds-expansion-panel>
    </rds-accordion>
    <p *ngIf="quickLinksToAdd.length + quickLinksToRemove.length === 0" class="reset-ql-modal__quicklinks-are-default">Your Quicklinks are default.</p>
  </div>

</div>
<div rds-dialog-actions-container align="end">
  <button
      rds-secondary-button
      [rds-dialog-close]="null"
      size="m"
  >Cancel</button>
  <button
      rds-primary-button
      [rds-dialog-close]="data"
      size="m"
  >Confirm</button>
</div>
