import { DictionaryFilterDefinition, DictionaryTypeEnum, FilterTypeEnum } from '../filters-model';

export interface ChannelsSubscriptionFilters {
    locations?: DictionaryFilterDefinition;
    departments?: DictionaryFilterDefinition;
    functions?: DictionaryFilterDefinition;
  }
  export const INITIAL_CHANNELS_SUBSCRIPTION_FILTERS: ChannelsSubscriptionFilters = {
    locations: {
      type: FilterTypeEnum.DICTIONARY,
      dictionary: DictionaryTypeEnum.LOCATIONS,
      label: 'Location',
      changable: true,
      value: []
    },
    departments: {
      type: FilterTypeEnum.DICTIONARY,
      dictionary: DictionaryTypeEnum.DEPARTMENTS,
      label: 'Department',
      changable: true,
      value: []
    },
    functions: {
      type: FilterTypeEnum.DICTIONARY,
      dictionary: DictionaryTypeEnum.FUNCTIONS,
      label: 'Function',
      changable: true,
      value: []
    }
  }
