import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { mergeMap, catchError, map, first, switchMap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { CategoriesService } from '@app/core/services/categories.service';

import * as fromActions from './categories-flat.actions';
import * as fromReducer from './categories-flat.reducer';
import * as fromSlectors from './categories-flat.selectors';

@Injectable()
export class CategoriesFlatEffects {

  public loadCategoriesFlat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadCategoriesFlat),
      switchMap(() => this.store$.pipe(
        select(fromSlectors.selectEntities),
        first(entities => this.isEmpty(entities)),
      )),
      mergeMap(() => [
        fromActions.getCategoriesFlatRequest()
      ])
    ), { dispatch: true }
  );

  public getCategoriesFlatRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getCategoriesFlatRequest),
      mergeMap(() =>
        this.categoriesService.getCategoriesFlat().pipe(
          map((categoriesFlat) => fromActions.getCategoriesFlatSuccess({ categoriesFlat })),
          catchError(({ message }) => of(fromActions.getCategoriesFlatFailure({ error: message })))
        )
      )
    ), { dispatch: true }
  );

  public getCategoriesFlatSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getCategoriesFlatSuccess),
      mergeMap(({ categoriesFlat }) => [
        fromActions.categoriesFlatCollectionAddMany({ categoriesFlat }),
      ]),
    ), { dispatch: true }
  );

  private isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private categoriesService: CategoriesService
  ) {}
}
