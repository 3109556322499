import { createSelector } from '@ngrx/store';
import * as fromHome from '../home.reducer';
import * as fromTicker from './alert.reducer';

export const selectAlert = createSelector(
  fromHome.selectState,
  fromHome.getHomeAlert
);

export const selectFeeds = createSelector(
	selectAlert,
  fromTicker.getData
);
