import { createReducer, on } from '@ngrx/store';
import * as fromScreenActions from './screen.actions';
import { RDSBreakpoint } from '@app/core/models/screen.model';

export interface State {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  screenMode: 'MOBILE' | 'TABLET' | 'DESKTOP';
  rdsBreakpoint: RDSBreakpoint;
  canShowDesktopOnly: boolean;
  isDesktopOnlyViewActive: boolean;
}

export const initialState: State = {
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  screenMode: null,
  rdsBreakpoint: null,
  canShowDesktopOnly: false,
  isDesktopOnlyViewActive: false
};

export const reducer = createReducer(
  initialState,
  on(fromScreenActions.setRdsBreakpoint, (state, { rdsBreakpoint }) => {
    let screenMode;
    switch (rdsBreakpoint) {
      case 's':
        screenMode = 'MOBILE'
        break;
      case 'm':
        screenMode = 'TABLET'
        break;
      default:
        screenMode = 'DESKTOP'
        break;
    }
    return {
      ...state,
      rdsBreakpoint,
      isMobile: rdsBreakpoint === 's',
      isTablet: rdsBreakpoint === 'm',
      isDesktop: rdsBreakpoint !== 's' && rdsBreakpoint !== 'm',
      screenMode
    }
  }),
  on(fromScreenActions.setDesktopOnly, (state, { canShow }) => ({
    ...state,
    canShowDesktopOnly: canShow
  })),
  on(fromScreenActions.setDesktopOnlyViewActive, (state, { desktopOnly }) => ({
    ...state,
    isDesktopOnlyViewActive: desktopOnly
  })),
);

export const getScreenMode = (state: State) => state.screenMode;

export const getIsMobile = (state: State) => state.isMobile;
export const getIsTablet = (state: State) => state.isTablet;
export const getIsDesktop = (state: State) => state.isDesktop;

export const getRdsBreakpoint = (state: State) => state.rdsBreakpoint;
export const getNotVisibleForMobile = (state: State) => !state.canShowDesktopOnly && state.isDesktopOnlyViewActive;
