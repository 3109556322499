import { createAction, props } from '@ngrx/store';

export const clearSearch = createAction(
  '[SEARCH] clear search'
);

export const setPhrase = createAction(
  '[SEARCH] Set phrase',
  props<{ phrase: string}>()
);
