import {createSelector} from '@ngrx/store';
import * as fromNewsletter from '@app/newsletter-new/store/newsletter.reducer';
import * as fromRecipientsForm from 'src/app/newsletter-new/store/recipients-form';

export const selectRecipientsFormState = createSelector(
    fromNewsletter.selectState,
    fromNewsletter.getNewsletterRecipientsForm
);

export const selectRecipientsForm = createSelector(selectRecipientsFormState, fromRecipientsForm.getForm);
export const selectInitialRecipientsForm = createSelector(selectRecipientsFormState, fromRecipientsForm.getInitialForm);
export const selectGeneralInformationStepStatus = createSelector(
    selectRecipientsFormState,
    fromRecipientsForm.getGeneralInformationStepStatus
);

export const selectGeneralInformationFormStatus = createSelector(
    selectRecipientsFormState,
    fromRecipientsForm.getGeneralInformationFormStatus
);

export const selectBlockStepStatus = createSelector(selectRecipientsFormState, fromRecipientsForm.getBlockStepStatus);

export const selectBlockFormStatus = createSelector(selectRecipientsFormState, fromRecipientsForm.getBlockFormStatus);

export const selectRecipientsStepStatus = createSelector(
    selectRecipientsFormState,
    fromRecipientsForm.getRecipientsStepStatus
);

export const selectRecipientsFormStatus = createSelector(
    selectRecipientsFormState,
    fromRecipientsForm.getRecipientsFormStatus
);

export const selectGeneralInformationForm = createSelector(selectRecipientsForm, (form) =>
    form
        ? {
            id: form.id,
            name: form.name,
            description: form.description,
        }
        : null
);
export const selectRecipientsListForm = createSelector(selectRecipientsForm, (form) =>
    form
        ? {
            recipientRada: form.recipientRada,
            recipientUsers: form.recipientUsers,
        }
        : null
);
export const selectBlockUsersForm = createSelector(selectRecipientsForm, (form) =>
    form
        ? {
            blockRada: form.blockRada,
            blockUsers: form.blockUsers,
        }
        : null
);

export const selectChangesMade = createSelector(
    selectRecipientsForm,
    selectInitialRecipientsForm,
    (form, initialForm) => JSON.stringify(form) !== JSON.stringify(initialForm)
);
export const selectCanSave = createSelector(
    selectGeneralInformationFormStatus,
    selectRecipientsFormStatus,
    (general, recipients) => general === 'VALID' && recipients === 'VALID'
);

export const selectFormChecked = createSelector(selectRecipientsFormState, fromRecipientsForm.getFormChecked);

export const selectImport = createSelector(selectRecipientsFormState, fromRecipientsForm.getImport);
export const selectInitialValues = createSelector(selectImport, fromRecipientsForm.getInitialValues);
export const selectImportStep = createSelector(selectImport, (importState) => importState?.step);
export const selectImportGroupedByCol = createSelector(selectImport, (importState) => importState?.groupedByCol);


export const selectMappingImport = createSelector(selectImport, ({groupedByCol, initialValues}) => {
    return {
        groupedByCol,
        initialValues
    };
});
export const selectMappingForm = createSelector(selectImport, fromRecipientsForm.getMappingForm);
export const selectMappedData = createSelector(selectImport, (importState) => importState?.mappedData);
export const selectColumnWithEmails = createSelector(selectImport, (importState) => importState?.columnWithEmails);
export const selectInvalidFile = createSelector(selectImport, (importState) => importState?.invalidFile);
export const selectExcludedFirstRow = createSelector(selectImport, (importState) => importState?.excludedFirstRow);

export const selectExcludedNumbers = createSelector(selectRecipientsFormState, (state) => {
    return {
        excludedRada: state.form?.recipientUsers?.filter((user) => !user.included)?.length || 0,
        excludedBlock: state.form?.blockUsers?.filter((user) => !user.blocked)?.length || 0,
    };
});
export const selectSelectableRecipientLists = createSelector(
    selectRecipientsFormState,
    fromRecipientsForm.getSelectableRecipientLists
);

export const selectStatus = createSelector(selectRecipientsForm, (form) => form?.status);
