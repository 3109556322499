import { createAction, props } from '@ngrx/store';

import { UserChannel } from '@core/models/user-channel.model';

export const userChannelsCollectionUpsertMany = createAction(
  '[STORED USER CHANNELS - Upsert MANY] User Sections collection add many.',
  props<{ channels: UserChannel[] }>()
);

export const userChannelsCollectionUpsertAll = createAction(
  '[STORED USER CHANNELS - Upsert All] User Sections collection upsert all.',
  props<{ channels: UserChannel[] }>()
);
