import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { icons } from './catalog-icons';

@Component({
  selector: 'rh-catalog-icons',
  templateUrl: './catalog-icons.component.html',
  styleUrls: ['./catalog-icons.component.scss']
})
export class CatalogIconsComponent implements OnInit {

  icons = icons;

  @Input() catalogName: string;

  secureIcon;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.secureIcon = this.sanitizer.bypassSecurityTrustHtml(this.icons[this.catalogName]);
  }

}
