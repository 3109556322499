import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';

import {SimpleUser, User, UserLogin, UserRole} from '@app/core/models/newsboard/user';
import {select, Store} from '@ngrx/store';
import * as fromUser from '@app/core/user/store'
import {distinctUntilChanged} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NewsboardAuthService {
  private currentUser$: Observable<UserLogin> = this.store$.pipe(select(fromUser.selectNewsBoardUser))
  currentUser: UserLogin;

  get isAdmin() {
    return this.currentUser?.isAdmin;
  }

  get isSuperAdmin() {
    return this.currentUser?.isSuperAdmin;
  }

  get currentSimpleUser(): SimpleUser {
    return {
      identifier: this.currentUser.identifier,
      name: this.currentUser.name,
      email: this.currentUser.email,
      avatar: this.currentUser.avatar
    };
  }

  constructor(private store$: Store<any>) {
    this.currentUser$.pipe(
      distinctUntilChanged(),
    ).subscribe(user => {
      this.currentUser = user
    })
  }

}


export {SimpleUser, User, UserLogin, UserRole};
