import {Action, createFeatureSelector, createReducer, on} from '@ngrx/store';
import * as fromActions from '@app/events/store/details/details.actions';
import { RhEvent } from '@app/events/models/event';

export const feature = 'details';

export interface State {
  event: Partial<RhEvent>
}

export const initialState: State = {
  event: null
};

export const detailsReducer = createReducer(
  initialState,
  on(fromActions.getEventDetailsSuccess, (state, {event}) => ({
    ...state,
    event
  })),
  on(fromActions.clearDetails, (state) => ({
    ...initialState
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return detailsReducer(state, action);
}

export const selectState = createFeatureSelector<State>(feature);
export const getEvent = (state: State) => state.event;
