import { Injectable } from '@angular/core';
import { SimpleUser } from '@app/core/models/newsboard';
import { NewsletterService } from '@app/core/services/newsletter.service';
import {
	NewsletterPreviewDialogComponent,
	NewsletterPreviewDialogData,
} from '@app/newsletter/dialogs/newsletter-preview-dialog/newsletter-preview-dialog.component';
import * as fromNewsletterBlocksSelectors from '@app/newsletter/store/newsletter-blocks/newsletter-blocks.selectors';
import * as fromDashboardActions from '@app/newsletter/store/newsletter-dashboard/newsletter-dashboard.actions';
import * as fromNewsletterFormActions from '@app/newsletter/store/newsletter-form/newsletter-form.actions';
import * as fromNewsletterFormSelectors from '@app/newsletter/store/newsletter-form/newsletter-form.selectors';
import * as fromNewsletterInlineActions from '@app/newsletter/store/newsletter-inline/newsletter-inline.actions';
import * as fromNewsletterInlineSelectors from '@app/newsletter/store/newsletter-inline/newsletter-inline.selectors';
import * as fromActions from '@app/newsletter/store/newsletter-saved-templates/newsletter-saved-templates.actions';
import * as fromSelectors from '@app/newsletter/store/newsletter-saved-templates/newsletter-saved-templates.selectors';
import { ConfirmDialogComponent } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { RenameDialogComponent, RenameDialogData } from '@app/shared/dialogs/rename-dialog/rename-dialog.component';
import {
	SendCopyDialogComponent,
	SendCopyDialogData,
} from '@app/shared/dialogs/send-copy-dialog/send-copy-dialog.component';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { RdsDialogService } from '@rds/angular-components';
import { of } from 'rxjs';
import { catchError, filter, first, map, mergeMap, skip, switchMap, tap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class NewsletterSavedTemplatesEffects {
	public getDashboardTemplates$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.getDashboardTemplates),
				switchMap(({ pageIndex, pageSize }) =>
					this.newsletterService.getMySavedTemplates(pageIndex, pageSize).pipe(
						filter((response) => !!response),
						map((response) => fromActions.getDashboardTemplatesSuccessAddAll({ response })),
						catchError(({ message }) => of(fromActions.getDashboardTemplatesFailure({ message })))
					)
				)
			),
		{ dispatch: true }
	);

	public loadMoreTemplates$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.loadMore),
				withLatestFrom(this.store$.pipe(select(fromSelectors.selectPageIndexSize))),
				switchMap(([action, { pageIndex, pageSize }]) =>
					this.newsletterService.getMySavedTemplates(pageIndex + 1, pageSize).pipe(
						filter((response) => !!response),
						map((response) => fromActions.getDashboardTemplatesSuccessUpsert({ response })),
						catchError(({ message }) => of(fromActions.getDashboardTemplatesFailure({ message })))
					)
				)
			),
		{ dispatch: true }
	);

	public openDeleteNewsletterDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.openDeleteNewsletterDialog),
				switchMap(({ template, fromDashboard }) =>
					this.dialogService
						.open(ConfirmDialogComponent, {
							data: {
								ids: [template.newsletterId],
								title: `Are you sure you want to delete "${template.displayName}" template?`,
								confirmButtonLabel: `Yes, delete`,
								confirmButtonType: 'warning',
							},
						})
						.afterClosed()
						.pipe(
							filter((data) => !!data),
							map((data) => fromActions.deleteNewsletterRequest({ id: template.newsletterId, fromDashboard }))
						)
				)
			),
		{ dispatch: true }
	);

	public deleteNewsletterRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.deleteNewsletterRequest),
			switchMap(({ id, fromDashboard }) =>
				this.newsletterService.deleteNewsletter(id).pipe(
					map(() => fromActions.deleteNewsletterSuccess({ id, fromDashboard })),
					catchError(({ message }) => of(fromActions.deleteNewsletterFailure({ message })))
				)
			)
		)
	);

	public deleteNewsletterSuccessFromDashboard$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.deleteNewsletterSuccess),
			filter(({ fromDashboard }) => fromDashboard),
			mergeMap(() => [
				fromActions.getDashboardTemplates({ pageIndex: 0, pageSize: 4 }),
				fromActions.getDashboardSharedTemplates({ pageIndex: 0, pageSize: 4 }),
			])
		)
	);

	public deleteNewsletterSuccessFromAll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.deleteNewsletterSuccess),
			filter(({ fromDashboard }) => !fromDashboard),
			map(({ id }) => fromActions.deleteNewsletterSuccessFromAll({ id }))
		)
	);

	public openPreviewDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.openPreviewDialog),
				tap(({ template }) => {
					switch (template.templateId) {
						case 'NewsletterTemplateInlineEditor':
							this.store$.dispatch(fromNewsletterInlineActions.getNewsletterById({ id: template.newsletterId }));
							break;
						case 'NewsletterTemplateWithSections':
						case 'NewsletterTemplateWithoutSections':
							this.store$.dispatch(fromNewsletterFormActions.getNewsletterById({ id: template.newsletterId }));
							break;
					}
					const data: NewsletterPreviewDialogData = {
						formNewsletter: this.store$.pipe(
							select(fromNewsletterFormSelectors.selectFormNewsletterLoaded),
							skip(1),
							first()
						),
						sections: this.store$.pipe(select(fromNewsletterBlocksSelectors.selectSections)),
						form: this.store$.pipe(select(fromNewsletterInlineSelectors.selectForm)),
						templateId: template.templateId,
						newsletterId: template.newsletterId,
						context: 'Template',
					};

					this.dialogService.open(NewsletterPreviewDialogComponent, {
						size: 'xl',
						height: '100%',
						maxHeight: '100%',
						data,
					});
				})
			),
		{ dispatch: false }
	);

	public saveAsTemplateSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromDashboardActions.saveAsTemplateSuccess),
			map(() => fromActions.getDashboardTemplates({ pageIndex: 0, pageSize: 4 }))
		)
	);

	public openRenameDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.openRenameDialog),
				map(({ id, name }) => {
					const data: RenameDialogData = {
						title: 'Rename Template',
						oldName: name,
						controlLabel: 'Name of the Template',
						confirmButtonLabel: 'Rename Template',
						required: true,
						maxLength: 512,
					};
					const dialog = this.dialogService.open(RenameDialogComponent, {
						size: 'l',
						data,
					});
					return { id, name, dialog };
				}),
				switchMap(({ id, name, dialog }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((name) => ({ id, name }))
					)
				),
				switchMap(({ id, name }) =>
					this.newsletterService.rename(id, name).pipe(
						map((template) => fromActions.renameNewsletterSuccess({ template })),
						catchError(({ message }) => of(fromActions.renameNewsletterFailure({ message })))
					)
				)
			),
		{ dispatch: true }
	);

	public openSendCopyDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.openShareDialog),
				map(({ template }) => {
					const data: SendCopyDialogData = {
						title: 'Send template copy',
						message: 'Template will appear in recipients "My Saved Templates" section.',
						confirmButtonLabel: 'Send template',
						preventAddYourself: true,
					};
					const dialog = this.dialogService.open(SendCopyDialogComponent, {
						size: 'l',
						data,
					});
					return { template, dialog };
				}),
				switchMap(({ template, dialog }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((users: Array<SimpleUser>) => {
							return { id: template.newsletterId, emails: users.map((u) => u.email) };
						})
					)
				),
				map(({ id, emails }) => fromActions.sendTemplateCopyRequest({ id, emails }))
			),
		{ dispatch: true }
	);

	// public sendTemplateCopyRequest$ = createEffect(() =>
	// this.actions$.pipe(
	//   ofType(fromActions.sendTemplateCopyRequest),
	//   switchMap(({id, emails}) => this.newsletterService.sendTemplateCopy({id, emails}).pipe(
	//     map(() => fromActions.sendTemplateCopySuccess()),
	//     catchError((error) => of(fromActions.sendTemplateCopyFailure({message: error})))
	//   )),
	// )
	// );

	public getDashboardSharedTemplates$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.getDashboardSharedTemplates),
				switchMap(({ pageIndex, pageSize }) =>
					this.newsletterService.getMySharedTemplates(pageIndex, pageSize).pipe(
						filter((response) => !!response),
						map((response) => fromActions.getDashboardSharedTemplatesSuccessAddAll({ response })),
						catchError(({ message }) => of(fromActions.getDashboardSharedTemplatesFailure({ message })))
					)
				)
			),
		{ dispatch: true }
	);

	public openSharedPreviewDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.openSharedPreviewDialog),
				map(({ template, fromDashboard }) => {
					switch (template.templateId) {
						case 'NewsletterTemplateInlineEditor':
							this.store$.dispatch(fromNewsletterInlineActions.getNewsletterById({ id: template.newsletterId }));
							break;
						case 'NewsletterTemplateWithSections':
						case 'NewsletterTemplateWithoutSections':
							this.store$.dispatch(fromNewsletterFormActions.getNewsletterById({ id: template.newsletterId }));
							break;
					}
					const data: NewsletterPreviewDialogData = {
						formNewsletter: this.store$.pipe(
							select(fromNewsletterFormSelectors.selectFormNewsletterLoaded),
							skip(1),
							first()
						),
						sections: this.store$.pipe(select(fromNewsletterBlocksSelectors.selectSections)),
						form: this.store$.pipe(select(fromNewsletterInlineSelectors.selectForm)),
						templateId: template.templateId,
						newsletterId: template.newsletterId,
						context: 'SharedTemplate',
					};

					const dialog = this.dialogService.open(NewsletterPreviewDialogComponent, {
						size: 'xl',
						height: '100%',
						maxHeight: '100%',
						data,
					});

					return { dialog, template, fromDashboard };
				}),
				switchMap(({ template, dialog, fromDashboard }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((data) => {
							switch (data) {
								case 'acceptTemplate':
									return fromActions.acceptNewsletterRequest({ id: template.newsletterId });
								case 'rejectTemplate':
									return fromActions.deleteNewsletterRequest({ id: template.newsletterId, fromDashboard });
							}
						})
					)
				)
			),
		{ dispatch: true }
	);

	public acceptTemplateCopyRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.acceptNewsletterRequest),
			switchMap(({ id }) =>
				this.newsletterService.acceptSharedTemplate({ id }).pipe(
					map(() => fromActions.acceptNewsletterSuccess()),
					catchError((error) => of(fromActions.acceptNewsletterFailure({ message: error })))
				)
			)
		)
	);

	public rejectTemplateCopyRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.deleteNewsletterRequest),
			switchMap(({ id, fromDashboard }) =>
				this.newsletterService.deleteNewsletter(id).pipe(
					map(() => fromActions.deleteNewsletterSuccess({ id, fromDashboard })),
					catchError((error) => of(fromActions.deleteNewsletterFailure({ message: error })))
				)
			)
		)
	);

	public acceptNewsletterSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.acceptNewsletterSuccess),
			mergeMap(() => [
				fromActions.getDashboardTemplates({ pageIndex: 0, pageSize: 4 }),
				fromActions.getDashboardSharedTemplates({ pageIndex: 0, pageSize: 4 }),
			])
		)
	);

	constructor(
		private actions$: Actions,
		private store$: Store<any>,
		private newsletterService: NewsletterService,
		private dialogService: RdsDialogService
	) {}
}
