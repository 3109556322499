import { createSelector } from '@ngrx/store';

import * as fromUi from '../ui.reducer';
import * as fromReducer from './back-button.reducer';

export const selectState = createSelector(
  fromUi.selectState,
  fromUi.getBackButton
);

export const selectBackConfig = createSelector(
  selectState,
  fromReducer.getBackConfig
)

export const selectAddNewsMiddleRoute = createSelector(
  selectState,
  fromReducer.getAddNewsMiddleRoute
)