import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import * as fromRecipients from '@app/newsletter-new/store/recipients-form';
import {ImportStepStatus} from '@app/newsletter-new/store/recipients-form';
import {RDS_DIALOG_DATA} from '@rds/angular-components';
import {read, utils, WorkBook, WorkSheet} from 'xlsx';
import {Observable} from 'rxjs';
import {SubSink} from 'subsink';
import {distinctUntilChanged, first, tap} from 'rxjs/operators';
import {Pagination} from '@core/models';
import {validateAllNeededSelected} from '@shared/form-controls/validators/validator.function';

export interface ImportRecipientsDialogData {
    title: string;
    options: any[];
    required: any[];
    results: any[];
    cancelButtonLabel?: string;
    confirmButtonLabel?: string;
    changeFileLabel?: string;
    confirmMappingInformation: string;
}

export const optionsToMap = [
    {
        name: 'Surname',
        value: 'surname',
    },
    {
        name: 'First name',
        value: 'name',
    },
    {
        name: 'Email',
        value: 'email',
    },
    {
        name: 'Not defined',
        value: null,
    },
];

@Component({
    selector: 'rh-import-dialog-recipients',
    templateUrl: './import-dialog-recipients.component.html',
    styleUrls: ['./import-dialog-recipients.component.scss'],
})
export class ImportDialogRecipientsComponent implements OnInit, OnDestroy {
    private subs: SubSink = new SubSink();
    public mappingForm: FormGroup = new FormGroup<any>({}, []);

    public excludedFirstRow: boolean;
    public columnWithEmails: string;
    importStep$: Observable<ImportStepStatus> = this.store$.pipe(select(fromRecipients.selectImportStep));
    mappingImportData$: Observable<any> = this.store$.pipe(
        distinctUntilChanged(),
        select(fromRecipients.selectMappingImport)
    );
    groupedByCol$: Observable<any> = this.store$.pipe(
        distinctUntilChanged(),
        select(fromRecipients.selectImportGroupedByCol)
    );
    invalidFile$: Observable<any> = this.store$.pipe(distinctUntilChanged(), select(fromRecipients.selectInvalidFile));
    excludedFirstRow$: Observable<any> = this.store$.pipe(
        distinctUntilChanged(),
        select(fromRecipients.selectExcludedFirstRow),
        tap((excludedFirstRow) => (this.excludedFirstRow = excludedFirstRow))
    );
    mappedData$: Observable<
        Array<{
            lastName: string;
            firstName: string;
            email: string;
        }>
    > = this.store$.pipe(
        select(fromRecipients.selectMappedData),
        tap((data) => {
            this.data.results = data;
            this.pagination = {
                ...this.pagination,
                totalCount: data.length,
                pageSize: 10,
            };
        })
    );
    stepStatus = ImportStepStatus;
    pagination: Pagination = Pagination;
    importedData;
    editableColumns: {
        [key: string]: ValidatorFn | Array<ValidatorFn>;
    } = {
        email: [],
        name: [],
        surname: [],
    };
    optionsToSelect: any[];

    ngOnInit() {
        this.subs.sink = this.mappingImportData$.pipe(distinctUntilChanged()).subscribe((data) => {
            this.importedData = data;
        });
        this.subs.sink = this.groupedByCol$.pipe(distinctUntilChanged()).subscribe((groupedByCol) => {
            if (groupedByCol) {
                this.importedData.groupedByCol = groupedByCol;
                this.createFormGroup();
            }
        });
        this.mappingForm.valueChanges.pipe(distinctUntilChanged()).subscribe((mappingForm) => {
            if (mappingForm) {
                this.store$.dispatch(fromRecipients.importFile.setMappingForm({mappingForm}));

            }
        });
    }

    ngOnDestroy() {
        this.store$.dispatch(fromRecipients.importFile.clearImport());
    }

    onFilesSelected(event) {
        const reader = new FileReader();
        reader.onload = this.onReaderLoad.bind(this);
        reader.readAsArrayBuffer(event.target.files[0]);
        event.target.value = null;
    }

    onReaderLoad(event) {
        const file = event.target.result;

        const wb: WorkBook = read(file);
        const sheet: WorkSheet = wb.Sheets?.Sheet1;
        const initialValues: {
            [key: string]: any
        }[] = sheet ? utils.sheet_to_json(sheet, {blankrows: false, header: 'A'}) : [];
        this.store$.dispatch(fromRecipients.importFile.setInitialValues({initialValues}));
    }

    optionsNotSelected(col) {
        const values = Object.values(this.mappingForm.value);
        const selected = this.mappingForm.value[col];

        return this.optionsToSelect.filter(
            (item) => !values.includes(item.value) || item.value === selected || !item.value
        );
    }

    createFormGroup() {
        this.mappingForm.controls = {};

        this.subs.sink = this.store$
            .pipe(select(fromRecipients.selectColumnWithEmails), distinctUntilChanged(), first())
            .subscribe((columnWithEmails) => {
                for (let col in this.importedData.groupedByCol) {
                    this.mappingForm.addControl(col, new FormControl('', []), {emitEvent: false});
                    if (columnWithEmails === col) {
                        this.mappingForm.get(col).setValue('email', {emitEvent: false});
                        this.columnWithEmails = columnWithEmails;
                    }
                }
                this.mappingForm.validator = validateAllNeededSelected(this.data.required);
                this.mappingForm.updateValueAndValidity();
            });
    }

    // createMappedData(mappingForm) {
    //     this.store$.dispatch(fromRecipients.importFile.setMappedData({mappingForm}));
    // }

    excludeFirstRow(ev: {
        checked: boolean
    }) {
        this.store$.dispatch(fromRecipients.importFile.setExcludedFirstRow({excludedFirstRow: ev.checked}));

    }

    changeView(ev: ImportStepStatus) {
        this.store$.dispatch(fromRecipients.importFile.setImportStep({step: ev}));
    }

    constructor(
        @Inject(RDS_DIALOG_DATA) public data: ImportRecipientsDialogData,
        private store$: Store<fromRecipients.State>
    ) {
        this.optionsToSelect = optionsToMap.filter((item) => !item.value || data.options.includes(item.value));
    }
}
