import {Action, createReducer, on} from '@ngrx/store';
import * as fromActions from '@app/home/store/hero-section/hero-section.actions';

export interface State {
  channelId: string,
  newsCount: number,
  news: any[]
  loading: boolean;
  loaded: boolean;
  isLikeProcessing: boolean;
  isLikeProcessingIds: string[];
}

export const initialState: State = {
  channelId: null,
  newsCount: null,
  news: [],
  loading: false,
  loaded: false,
  isLikeProcessing: false,
  isLikeProcessingIds: []
};

export const heroSectionReducer = createReducer(
  initialState,
  on(fromActions.setChannelIdAndCount, (state, {channelId, newsCount}) => ({
    ...state,
    channelId,
    newsCount: newsCount || 5

  })),
  on(fromActions.getChannelNews, (state, {id}) => ({
    ...state,
    news: [],
    loaded: false,
    loading: true
  })),
  on(fromActions.getChannelNewsSuccess, (state, {id, data}) => ({
    ...state,
    news: data,
    loaded: true,
    loading: false
  })),

  on(fromActions.addLike, (state, {id, likesCount}) => {
    return {
      ...state,
      isLikeProcessing: true,
      isLikeProcessingIds: [...state.isLikeProcessingIds, id],
      news: state.news.map((i) => {
        if (i.id === id) {
          return {
            ...i,
            numLikes: likesCount + 1,
            hasLiked: true
          }
        }
        return i;
      })
    }
  }),
  on(fromActions.removeLike, (state, {id, likesCount}) => {
    return ({
      ...state,
      isLikeProcessing: true,
      isLikeProcessingIds: [...state.isLikeProcessingIds, id],
      news: state.news.map((i) => {
        if (i.id === id) {
          return {
            ...i,
            numLikes: likesCount - 1,
            hasLiked: false
          }
        }
        return i;
      })
    })
  }),
  on(fromActions.addLikeSuccess, fromActions.removeLikeSuccess, (state, {id}) => ({
    ...state,
    isLikeProcessing: false,
    isLikeProcessingIds: [...state.isLikeProcessingIds].filter(i => i !== id)
  })),
  on(fromActions.addBookmark, (state, {id}) => ({
    ...state,
    news: state.news.map((i) => {
      if (i.id === id) {
        return {
          ...i,
          hasBookmarked: true
        }
      }
      return i;
    })
  })),
  on(fromActions.removeBookmark, (state, {id}) => ({
    ...state,
    news: state.news.map((i) => {
      if (i.id === id) {
        return {
          ...i,
          hasBookmarked: false
        }
      }
      return i;
    })
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return heroSectionReducer(state, action);
}

export const getChannelId = (state: State) => state.channelId;
export const getNewsCount = (state: State) => state.newsCount;
export const getNews = (state: State) => state.news;
export const getIsLikeProcessing = (state: State) => state.isLikeProcessing;
export const getLoading = (state: State) => state.loading;
export const getLoaded = (state: State) => state.loaded;
export const getIsLikeProcessingIds = (state: State) => state.isLikeProcessingIds;
