import { Injectable } from '@angular/core';
import * as fromUser from '@app/core/user/store';
import * as fromBackButton from '@app/root-store/ui/back-button';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import * as fromActions from './form.actions';
import * as fromReducer from './form.reducer';
import * as fromSelectors from './form.selectors';

import { UserProfileODataService } from '@app/core/services/odata/user-profile.odata.service';
import { UserProfileService } from '@app/core/services/userProfile.service';
import { catchError, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';

@Injectable()
export class RoleAssignFormEffects {


public saveSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(
      fromActions.assignUserRoleSuccess,
      fromActions.deleteUserRoleSuccess
    ),
    mergeMap(() => [
      fromActions.clearAll(),
      fromBackButton.back({defaultLabel: 'Assign a role', defaultRoute: 'role'})
    ]),
  ), { dispatch: true}
);

public assignUserRoleRequest$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.assignUserRoleRequest),
  withLatestFrom(this.store$.pipe(select(fromSelectors.selectFormForRequest))),
  switchMap(([action, form]) => this.userProfileService.saveUserRoles(form).pipe(
    map((id) => ({form, id}))
  )),
  mergeMap(({form, id}) => [
    fromActions.assignUserRoleSuccess(),
    fromUser.getUserManagedTaxonomies()
  ])
), { dispatch: true}
);

public getUserRolesRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.getUserRolesRequest),
    switchMap(({user}) => this.userProfileOdataService.getUserWithRoles({login: user.identifier}).pipe(
      map((userRole) => ({ user, userRole}))
    )),
    map(({user, userRole}) => fromActions.getUserRolesSuccess(({user, userRole}))
    ),
  ), { dispatch: true}
);

public getUserRolesSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.getUserRolesSuccess),
    map(({user, userRole}) => fromActions.initAddForm(({user, userRole}))
    ),
  ), { dispatch: true}
);
  
public deleteUserRoleRequest$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.deleteUserRoleRequest),
  mergeMap(({login}) =>
    this.userProfileService.deleteUserRoles(login).pipe(
      mergeMap(() => [
        fromActions.deleteUserRoleSuccess(),
      ]),
      catchError(({ message }) => of(fromActions.deleteUserRoleFailure({error: message })))
    )
  )
), { dispatch: true }
);

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private userProfileService: UserProfileService,
    private userProfileOdataService: UserProfileODataService
  ) {}

}
