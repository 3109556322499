import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ContentTypeEnum} from '@core/models';
import {ScreenModeEnum} from '@core/models/screen.model';

@Component({
  selector: 'atr-hero-news-card-swiper',
  templateUrl: './hero-news-card-swiper.component.html',
  styleUrls: ['./hero-news-card-swiper.component.scss']
})
export class HeroNewsCardSwiperComponent {

  @Input() screenMode!: ScreenModeEnum;
  @Input() result!: any;
  @Input() news!: any;
  @Input() id!: string;
  @Input() hasRead!: boolean;
  @Input() imageUrl!: string;
  @Input() title!: string;
  @Input() channelId!: string;
  @Input() channelName!: string;
  @Input() badge!: string;
  @Input() description!: string;
  @Input() creationDate!: string;
  @Input() likeEnabled!: boolean;
  @Input() isLikingProcessingIds!: string[];
  @Input() likeCount!: number;
  @Input() hasBookmarked!: boolean;
  @Input() hasLiked!: boolean;
  @Input() url!: string;
  @Input() type!: ContentTypeEnum;

  @Input() enableBookmark = false;

  @Output() newsOpened: EventEmitter<{ newTab: boolean, url: string, id: string, title: string }> = new EventEmitter();
  @Output() channelOpened: EventEmitter<{ channelId: string}> = new EventEmitter();
  @Output() likeToggled: EventEmitter<{ hasLiked: boolean, newsId: string }> = new EventEmitter();
  @Output() bookmarkToggled: EventEmitter<{ hasBookmarked: boolean, newsId: string }> = new EventEmitter();


  constructor() {
  }

  openNews(): void {
    const newTab = this.type === ContentTypeEnum.LINK;
    this.newsOpened.emit({newTab, url: this.url, id: `${this.channelId}:${this.id.split(':')[2]}`, title: this.title})
  }

  openChannel(ev): void {
    ev.preventDefault();
    this.channelOpened.emit({channelId: this.channelId})
  }

  toggleLike(ev: Event): void {
    ev.stopPropagation();
    if (this.isLikingProcessingIds.indexOf(this.id) === -1) {
      this.likeToggled.emit({hasLiked: this.hasLiked, newsId: this.id})
    }
  }

  toggleBookmark(ev: Event): void {
    ev.stopPropagation();
    this.bookmarkToggled.emit({hasBookmarked: this.hasBookmarked, newsId: this.id})
  }
}
