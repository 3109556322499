import { createReducer, Action, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';

import { CatalogTable } from '@core/models/catalog.model';
import * as fromActions from '@core/core-store/catalogs/catalogs.actions';

export interface State extends EntityState<CatalogTable> {}

export const adapter: EntityAdapter<CatalogTable> =
 createEntityAdapter<CatalogTable>({
  selectId: catalog => catalog.catalogId
 });

export const initialState: State = {
  ...adapter.getInitialState(),
};

const catalogsReducer = createReducer(
  initialState,
  on(fromActions.catalogsCollectionAddMany, (state, {catalogs}) => (
    adapter.addMany(catalogs, state)
  )),
  on(fromActions.catalogsCollectionAddOne, (state, {catalog}) => (
    adapter.addOne(catalog, state)
  )),
  on(fromActions.catalogsCollectionUpsertOne, (state, {catalog}) => (
    adapter.upsertOne(catalog, state)
  )),
  on(fromActions.catalogsCollectionRemoveOne, (state, {catalog}) => (
    adapter.removeOne(catalog.catalogId, state)
  )),
);

export function reducer(state: State | undefined, action: Action) {
  return catalogsReducer(state, action);
}

export const getIds = adapter.getSelectors().selectIds;
export const getEntities = adapter.getSelectors().selectEntities;
export const getAll = adapter.getSelectors().selectAll;
export const getTotal = adapter.getSelectors().selectTotal;

export const getEntityById = (entities: Dictionary<CatalogTable>, {id}) => entities[id];
export const getEntitiesById = (entities: Dictionary<CatalogTable>, {ids}) => ids.map(id => entities[id]);
