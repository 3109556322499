import {Action, createReducer, on} from '@ngrx/store';
import * as fromActions from '../quick-link/quick-link.actions';
import {QuickLink} from '../../models';
import {TabsEnum} from '@core/enums/header-tabs.enum';
export interface State {
  quickLinksMenuOpened: boolean;
  quickLinksLoading: boolean;
  quickLinksReorganizing: boolean;
  quickLinks: Array<QuickLink>;
  quickLinksTemporary: Array<QuickLink>;
  isDeleteModeActive: boolean;
  quickLinkIdsToDelete: Array<number>;
  activeTab: TabsEnum;
}

export const initialState: State = {
  quickLinksMenuOpened: false,
  quickLinksLoading: true,
  quickLinksReorganizing: false,
  quickLinks: [],
  quickLinksTemporary: [],
  isDeleteModeActive: false,
  quickLinkIdsToDelete: [],
  activeTab: null
};

export const quickLinkReducer = createReducer(
  initialState,
  on(fromActions.toggleHeaderQuickLinks, (state) => ({
    ...state,
    quickLinksMenuOpened: true
  })),
  on(fromActions.closeHeaderQuickLinks, (state) => ({
    ...state,
    quickLinksMenuOpened: false
  })),
  on(fromActions.getMyQuickLinks, (state) => ({
    ...state,
    quickLinksLoading: true
  })),
  on(fromActions.getMyQuickLinksSuccess, (state, {quickLinks}) => ({
    ...state,
    quickLinks,
    quickLinksTemporary: [],
    quickLinksLoading: false,
  })),
  on(fromActions.setTemporaryFavouriteQuickLinks, (state, {quickLinks}) => ({
    ...state,
    quickLinksTemporary: quickLinks
  })),
  on(fromActions.clearTemporaryFavouriteQuickLinks, (state) => ({
    ...state,
    quickLinksTemporary: [],
    quickLinksReorganizing: false,
  })),
  on(fromActions.reorganizeQuickLinks, (state) => ({
    ...state,
    quickLinksReorganizing: true,
  })),
  on(fromActions.reorganizeQuickLinksSuccess, fromActions.reorganizeQuickLinksFailure, (state) => ({
    ...state,
    quickLinksReorganizing: false,
  })),
  on(fromActions.deleteMultipleQuickLinks, (state) => ({
    ...state,
    isDeleteModeActive: true,
  })),
  on(fromActions.closeDeleteMultipleQuickLinks, (state) => ({
    ...state,
    isDeleteModeActive: false,
  })),
  on(fromActions.selectQuickLinkToDelete, (state, {id}) => ({
    ...state,
    quickLinkIdsToDelete: [
      ...state.quickLinkIdsToDelete,
      id
    ]
  })),
  on(fromActions.deselectQuickLinkToDelete, (state, {id}) => ({
    ...state,
    quickLinkIdsToDelete: state.quickLinkIdsToDelete.slice().filter(qlId => qlId !== id)
  })),
  on(fromActions.clearSelectedQuickLinkToDelete, fromActions.deleteQuickLinksSuccess, fromActions.deleteQuickLinksFailure, (state) => ({
    ...state,
    quickLinkIdsToDelete: [],
    isDeleteModeActive: false
  })),
  on(fromActions.addToTopSuccess, fromActions.removeFromTopSuccess, fromActions.renameQuickLinkSuccess, (state, {quickLink}) => ({
    ...state,
    quickLinks: [
      ...state.quickLinks.slice().filter(ql => ql.quickLinkId !== quickLink.quickLinkId),
      quickLink
    ].sort((a, b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0)
  })),
  on(fromActions.catalogsTabOpened, (state) => ({
    ...state,
    activeTab: TabsEnum.CATALOGUE
  })),
  on(fromActions.quicklinksTabOpened, (state) => ({
    ...state,
    activeTab: TabsEnum.QUICKLINKS
  })),
  on(fromActions.addToQuickLinksSuccess, (state, {quickLink}) => ({
    ...state,
    quickLinks: [...state.quickLinks, quickLink]
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return quickLinkReducer(state, action);
}

export const getHeaderQuickLinksOpened = (state: State) => state.quickLinksMenuOpened;
export const getAllQuickLinks = (state: State) => state.quickLinks;

export const getQuickLinksFavouritesInOrder = (state: State) =>
  state.quickLinks.filter(ql => ql.isFavourite).slice().sort((a, b) => b.order - a.order).sort((a, b) => {
    if (a.order === null && b.order !== null) {
      return 1;
    }
    if (b.order === null && a.order !== null) {
      return -1;
    }
    return 0;
  });

export const getAllQuicklinksWithFavouritesInOrder = (state: State) => [
  ...state.quickLinks.filter(ql => ql.isFavourite).slice().sort((a, b) => b.order - a.order).sort((a, b) => {
    if (a.order === null && b.order !== null) {
      return 1;
    }
    if (b.order === null && a.order !== null) {
      return -1;
    }
    return 0;
  }),
  ...state.quickLinks.filter(ql => !ql.isFavourite)
];

export const getIsTemporaryListFilled = (state: State) => state.quickLinksTemporary.length > 0;
export const getTemporaryQuickLinks = (state: State) => state.quickLinksTemporary;
export const getTemporaryQuicklinksWithAllQuickLinks = (state: State) => [
  ...state.quickLinksTemporary,
  ...state.quickLinks.filter(ql => !ql.isFavourite)
];

export const getQuickLinksLoading = (state: State) => state.quickLinksLoading;
export const getQuickLinksReorganizing = (state: State) => state.quickLinksReorganizing;

export const getIsDeleteModeActive = (state: State) => state.isDeleteModeActive;
export const getQuickLinkIdsToDelete = (state: State) => state.quickLinkIdsToDelete;
export const getCurrentTab = (state: State) => state.activeTab;
export const getEntitiesNames = (state: State) => {
  const obj = {};
  Object.values(state.quickLinks).forEach(entity => {
    obj[entity.catalogId] = true;
  });
  return obj;
};
