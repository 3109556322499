import { Pipe, PipeTransform } from '@angular/core';
import { NewsletterNews, NewsletterNewsAbstract } from '@app/core/models';

@Pipe({
  name: 'newsAbstractForLanguage'
})
export class NewsAbstractForLanguagePipe implements PipeTransform {
  transform(news: NewsletterNews, langCode: string): NewsletterNewsAbstract {
    if (!news) {
      return null;
    }
    
    const allLanguages = Object.keys(news.abstracts);
    if (allLanguages.includes(langCode)) {
      return news.abstracts[langCode]
    }
    if (allLanguages.includes(news.primaryLanguage)) {
      return news.abstracts[news.primaryLanguage];
    }
    return news.abstracts[0]
  }
}
