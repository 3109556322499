import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType} from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, debounceTime, distinctUntilChanged, filter, map, of, switchMap, tap } from 'rxjs';

import * as fromRouter from '@app/root-store/router';
import * as fromActions from './news-table.actions';
import * as fromSelectors from './news-table.selectors';
import * as fromReducer from './news-table.reducer';
import { RdsDialogService } from '@rds/angular-components';
import { ChannelAssignment, NewsUpdate } from '@app/core/models/newsboard';
import { AssignSuggestChannelsDialogComponent, AssignSuggestChannelsDialogData } from '@app/shared/dialogs/assign-suggest-channels-dialog/assign-suggest-channels-dialog.component';
import { Template } from '@app/core/models/newsboard/template';
import { SaveTemplateDialogComponent, SaveTemplateDialogData } from '@app/shared/dialogs/save-template-dialog/save-template-dialog.component';
import { ChannelService } from '@app/newsboard/channel/channel.service';
import { NewsService } from '../../news.service';
import { NewsboardAuthService } from '@app/core/auth/services/newsboard-auth.service';
import { NewsPreviewDialogComponent, NewsPreviewDialogData } from '@app/shared/dialogs/previews/news-preview-dialog/news-preview-dialog.component';

@Injectable()
export class NewsTableEffects {

  public initAllNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initAllNews),
      switchMap(() => this.store$.pipe(
          select(fromSelectors.selectAllNewsRequestData),
          debounceTime(300),
          distinctUntilChanged((prev, next) => {
            if (prev.resetIndex && !next.resetIndex) {
              delete prev.resetIndex
              delete next.resetIndex
            }
            return JSON.stringify(prev) === JSON.stringify(next)
          }),
          map(({filters, pageIndex, pageSize, sort, resetIndex}) => ({
            filters, 
            sort,
            pageIndex: resetIndex ? 0 : pageIndex,
            pageSize
          }))
        )
      ),
      map((requestData) => fromActions.getAllNewsRequest(requestData))
    ), { dispatch: true}
  );

  public getAllNewsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllNewsRequest),
      switchMap((requestData) => this.newsService.getNews(requestData, 'all').pipe(
        map(res => ({
          data: res.data, pagination: res.pagination
        }))
      )),
      map(({data, pagination}) => fromActions.getAllNewsSuccess({data, pagination})),
      catchError(({ message }) => of(fromActions.getAllNewsFailure({ error: message })))

    ), { dispatch: true}
  );

  public deleteAllNewsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteAllNewsRequest),
      switchMap(({ids}) => this.newsService.delete(ids).pipe(
        map(() => ({ids}))
      )),
      map(({ids}) => fromActions.deleteAllNewsSuccess({count: ids.length}))
    ), { dispatch: true}
  );

  public archiveAllNewsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.archiveAllNewsRequest),
      filter(({ids}) => ids.length === 1),
      switchMap(({ids}) => this.newsService.archive(ids[0]).pipe(
        map(() => ({ids}))
      )),
      map(({ids}) => fromActions.archiveAllNewsSuccess({count: ids.length}))
    ), { dispatch: true}
  );

  public bulkArchiveAllNewsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.archiveAllNewsRequest),
      filter(({ids}) => ids.length > 1),
      switchMap(({ids}) => this.newsService.bulkArchive(ids).pipe(
        map(() => ({ids}))
      )),
      map(({ids}) => fromActions.archiveAllNewsSuccess({count: ids.length}))
    ), { dispatch: true}
  );

  public initMyNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initMyNews),
      switchMap(() => this.store$.pipe(
          select(fromSelectors.selectMyNewsRequestData),
          debounceTime(300),
          distinctUntilChanged((prev, next) => {
            if (prev.resetIndex && !next.resetIndex) {
              delete prev.resetIndex
              delete next.resetIndex
            }
            return JSON.stringify(prev) === JSON.stringify(next)
          }),
          map(({filters, pageIndex, pageSize, sort, resetIndex}) => ({
            filters, 
            sort,
            pageIndex: resetIndex ? 0 : pageIndex,
            pageSize
          }))
        )
      ),
      map((requestData) => fromActions.getMyNewsRequest(requestData))
    ), { dispatch: true}
  );

  public getMyNewsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getMyNewsRequest),
      switchMap((requestData) => this.newsService.getNews(requestData, 'my').pipe(
        map(res => ({
          data: res.data, pagination: res.pagination
        }))
      )),
      map(({data, pagination}) => fromActions.getMyNewsSuccess({data, pagination})),
      catchError(({ message }) => of(fromActions.getMyNewsFailure({ error: message })))

    ), { dispatch: true}
  );

  public deleteMyNewsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteMyNewsRequest),
      switchMap(({ids}) => this.newsService.delete(ids).pipe(
        map(() => ({ids}))
      )),
      map(({ids}) => fromActions.deleteMyNewsSuccess({count: ids.length}))
    ), { dispatch: true}
  );

  public archiveMyNewsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.archiveMyNewsRequest),
      filter(({ids}) => ids.length === 1),
      switchMap(({ids}) => this.newsService.archive(ids[0]).pipe(
        map(() => ({ids}))
      )),
      map(({ids}) => fromActions.archiveMyNewsSuccess({count: ids.length}))
    ), { dispatch: true}
  );

  public bulkArchiveMyNewsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.archiveMyNewsRequest),
      filter(({ids}) => ids.length > 1),
      switchMap(({ids}) => this.newsService.bulkArchive(ids).pipe(
        map(() => ({ids}))
      )),
      map(({ids}) => fromActions.archiveMyNewsSuccess({count: ids.length}))
    ), { dispatch: true}
  );
  
  public initMySuggestedNews$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.initMySuggestedNews),
    switchMap(() => this.store$.pipe(
        select(fromSelectors.selectMySuggestedNewsRequestData),
        debounceTime(300),
        distinctUntilChanged((prev, next) => {
          if (prev.resetIndex && !next.resetIndex) {
            delete prev.resetIndex
            delete next.resetIndex
          }
          return JSON.stringify(prev) === JSON.stringify(next)
        }),
        map(({filters, pageIndex, pageSize, sort, resetIndex}) => ({
          filters, 
          sort,
          pageIndex: resetIndex ? 0 : pageIndex,
          pageSize
        }))
      )
    ),
    map((requestData) => fromActions.getMySuggestedNewsRequest(requestData))
  ), { dispatch: true}
);

public getMySuggestedNewsRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.getMySuggestedNewsRequest),
    switchMap((requestData) => this.newsService.getNews(requestData, 'suggested').pipe(
      map(res => ({
        data: res.data, pagination: res.pagination
      }))
    )),
    map(({data, pagination}) => fromActions.getMySuggestedNewsSuccess({data, pagination})),
    catchError(({ message }) => of(fromActions.getMySuggestedNewsFailure({ error: message })))

  ), { dispatch: true}
);

  public initMyArchivedNews$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.initMyArchivedNews),
    switchMap(() => this.store$.pipe(
        select(fromSelectors.selectMyArchivedNewsRequestData),
        debounceTime(300),
        distinctUntilChanged((prev, next) => {
          if (prev.resetIndex && !next.resetIndex) {
            delete prev.resetIndex
            delete next.resetIndex
          }
          return JSON.stringify(prev) === JSON.stringify(next)
        }),
        distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next)),
        map(({filters, pageIndex, pageSize, sort, resetIndex}) => ({
          filters, 
          sort,
          pageIndex: resetIndex ? 0 : pageIndex,
          pageSize
        }))
      )
    ),
    map((requestData) => fromActions.getMyArchivedNewsRequest(requestData))
  ), { dispatch: true}
);

public getMyArchivedNewsRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.getMyArchivedNewsRequest),

    switchMap((requestData) => this.newsService.getNews(requestData, 'archived').pipe(
      map(res => ({
        data: res.data, pagination: res.pagination
      }))
    )),
    map(({data, pagination}) => fromActions.getMyArchivedNewsSuccess({data, pagination})),
    catchError(({ message }) => of(fromActions.getMyArchivedNewsFailure({ error: message })))
  ), { dispatch: true}
);

public deleteMyArchivedNewsRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.deleteMyArchivedNewsRequest),
    switchMap(({ids}) => this.newsService.delete(ids).pipe(
      map(() => ({ids}))
    )),
    map(({ids}) => fromActions.deleteMyArchivedNewsSuccess({count: ids.length}))
  ), { dispatch: true}
);

public duplicateNewsRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.duplicateNewsRequest),
    switchMap(({newsId}) => this.newsService.duplicate(newsId).pipe(
      map((id) => ({id}))
    )),
    map(({id}) => fromActions.duplicateNewsSuccess({id})),
    catchError((error) => of(fromActions.duplicateNewsFailure({error})))
  )
);


public duplicateNewsSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.duplicateNewsSuccess),
    map(({id}) => fromRouter.go({path: `news/${id}/edit`, queryParams: {}})),
    catchError((error) => of(fromActions.duplicateNewsFailure({error})))
  )
);

public removeSuggestionRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.removeSuggestionRequest),
    switchMap(({newsIds, channelId, channelName}) => this.newsService.removeSuggestion(newsIds, channelId).pipe(
      map(() => ({newsIds, channelName}))
    )),
    map(({newsIds, channelName}) => fromActions.removeSuggestionSuccess({channelName})),
    catchError((error) => of(fromActions.removeSuggestionFailure({error})))
  )
);

public openNewsPreview$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.openNewsPreview),
    switchMap(({newsId, backButton}) => this.newsService.get(newsId).pipe(
      map((news) => ({news, backButton}))
    )),
    tap(({news, backButton}) => {
      const simpleUser = this.auth.currentSimpleUser;
      const isAdmin = this.auth.isAdmin || this.auth.isSuperAdmin;
      const canEdit = isAdmin || !!news.owners.find(u => u.identifier === simpleUser.identifier) || !!news.editors.find(u => u.identifier === simpleUser.identifier);
      const data: NewsPreviewDialogData = {
        news,
        channel: null,
        context: 'Table',
        backButton,
        buttons: {
          editNews: {
            visible: true,
            disabled: !canEdit
          }
        }
      }
      this.dialogService.open(NewsPreviewDialogComponent, {
        size: 'xl',
        height: '100%',
        maxHeight: '100%',
        data
      }
      )
    } )
  ), { dispatch: false}
);

public openAssignementsDialog$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.openAssignementsDialog),
    map(({newsId, isDraft}) => {
      const data: AssignSuggestChannelsDialogData = {
        assignableChannels: this.channelService.listAssignable(newsId),
        nonAassignableChannels: this.channelService.listNonAssignable(newsId),
      }
      const dialog = this.dialogService.open(AssignSuggestChannelsDialogComponent, {
        size: 'l',
        data
      });
      return ({newsId, isDraft, dialog})
    }),
    switchMap(({newsId, isDraft, dialog}) => dialog.afterClosed().pipe(
      filter((data) => !!data),
      map(({assign, suggest, assignable, nonAssignable}) => {
        const hasAssignChanged = assignable.some((c: ChannelAssignment) => c.assignmentStatus === 'None');
        const hasSuggestChanged = nonAssignable.some((c: ChannelAssignment) => c.assignmentStatus === 'None');
        const newsUpdate: NewsUpdate = {
          contentCreate: [],
          contentDelete: [],
          contentUpdate: [],
          assign,
          suggest,
          isDraft
        }
        return ({newsId, newsUpdate, hasAssignChanged, hasSuggestChanged})
      })
    )),
    map(({newsId, newsUpdate, hasAssignChanged, hasSuggestChanged}) => fromActions.updateNewsAssignmentsRequest({newsId, newsUpdate, hasAssignChanged, hasSuggestChanged}))
  ), { dispatch: true}
);

public updateNewsAssignmentsRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.updateNewsAssignmentsRequest),
    switchMap(({newsId, newsUpdate, hasAssignChanged, hasSuggestChanged}) => this.newsService.edit(newsId, newsUpdate).pipe(
      map(() => ({newsId, hasAssignChanged, hasSuggestChanged}))
    )),
    map(({newsId, hasAssignChanged, hasSuggestChanged}) => fromActions.updateNewsAssignmentsSuccess({hasAssignChanged, hasSuggestChanged})),
    catchError((error) => of(fromActions.updateNewsAssignmentsFailure({error})))
  )
);

public moveAsDraftRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.moveAsDraftRequest),
      filter(({ids}) => ids.length === 1),
    switchMap(({ids}) => this.newsService.unarchive(ids[0], true).pipe(
      map(() => ({newsId: ids[0]}))
    )),
    map(({newsId}) => fromActions.moveAsDraftSuccess()),
    catchError((error) => of(fromActions.moveAsDraftFailure({error})))
  )
);

public bulkMoveAsDraftRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.moveAsDraftRequest),
    filter(({ids}) => ids.length > 1),
    switchMap(({ids}) => this.newsService.bulkUnarchive(ids, true).pipe(
      map(() => ({newsId: ids}))
    )),
    map(({newsId}) => fromActions.moveAsDraftSuccess()),
    catchError((error) => of(fromActions.moveAsDraftFailure({error})))
  )
);

public unpublishNewsRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.unpublishNewsRequest),
    switchMap(({newsId}) => this.newsService.unarchive(newsId, true).pipe(
      map(() => ({newsId}))
    )),
    map(({newsId}) => fromActions.unpublishNewsSuccess()),
    catchError((error) => of(fromActions.unpublishNewsFailure({error})))
  )
);

public saveNewsAsTemplateRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.saveNewsAsTemplateRequest),

    switchMap(({newsId, imagePreview}) => this.newsService.saveAsTemplate(newsId, imagePreview).pipe(
      map(() => fromActions.saveNewsAsTemplateSuccess()),
      catchError((error) => of(fromActions.saveNewsAsTemplateFailure({error})))
    )),
  )
);

public saveNewsAsTemplateSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.saveNewsAsTemplateSuccess),
    map(() => fromRouter.go({path: 'news/create', queryParams: {activeTab: 'mytemplates'}}))
  )
);

public openSaveTemplateDialog$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.openSaveAsTemplateDialog),
    switchMap(({newsId}) => this.newsService.get(newsId).pipe(
      map((news) => ({newsId, news})),
    )),
    map(({newsId, news}) =>{
      const template: Template = {
        content: news.content,
        createdDate: new Date(),
        imagePreview: null,
        editors: [],
        owners: [],
        viewers: [],
        isPredefined: false,
        language: news.language,
        modifiedDate: new Date(),
        title: news.content[0].title,
      }
      const data: SaveTemplateDialogData = {
        template
      }
           
      const dialog = this.dialogService.open(SaveTemplateDialogComponent, {
        data,
        size: 's',
        disableClose: true,
        closeOnNavigation: false,
      });
      return ({dialog, template, newsId})
    }),
    switchMap(({dialog, template, newsId}) => dialog.afterClosed().pipe(
      filter(data => !!data),
      map(({url, error}) => {
        if (!!url) {
          return fromActions.saveNewsAsTemplateRequest({newsId, imagePreview: url})
        } else {
          return fromActions.saveNewsAsTemplateFailure({error})
        }
      })
    )),
  ), {dispatch: true})

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private channelService: ChannelService,
    private auth: NewsboardAuthService,
    private newsService: NewsService,
    private dialogService: RdsDialogService
  ) {}
}
