import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as fromRouter from '../../../root-store/router';
import * as fromAuth from '../store';

@Injectable()
export class AuthGuard  {

  canActivate(): Observable<boolean> {
    return this.store$.pipe(
      select(fromAuth.selectIsAuthenticated),
      tap((isAuthenticated) => {
        if (!isAuthenticated) {
          this.store$.dispatch(fromRouter.go({ path: '/access-denied', queryParams: {} }));
        }
      }),
      map((isAuthenticated) => {
        return isAuthenticated;
      }),
    );
  }

  constructor(private store$: Store<fromAuth.State>) {}
}
