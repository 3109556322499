import {Injectable} from '@angular/core';
import * as fromDashboard from '@app/newsletter/store/newsletter-dashboard/newsletter-dashboard.actions';
import * as fromWS from '@app/newsletter/store/newsletter-websocket/newsletter-websocket.actions';
import * as fromRouter from '@app/root-store/router/router.actions';
import * as fromUiReducer from '@app/root-store/ui/ui.reducer';
import {NewsletterService} from '@core/services/newsletter.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {RdsDialogService} from '@rds/angular-components';
import {of} from 'rxjs';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import * as fromActions from './newsletter.actions';
import {
    NewsletterConfidentalNoteDialogContainer
} from '@app/newsletter/dialogs/newsletter-confidental-note-dialog/newsletter-confidental-note-dialog.container';


@Injectable()
export class NewsletterEffects {

    public init$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.init),
            mergeMap(() => [
                fromDashboard.getDrafts(),
                fromDashboard.getTemplates(),
                fromDashboard.getNewsletters()
            ])
        ), {dispatch: true}
    );

    public createDraft$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.createDraft),
            mergeMap((data) =>
                this.newsletterService.createDraft(data.formNewsletter).pipe(
                    mergeMap(() => [
                        fromDashboard.getDrafts(),
                        fromRouter.go({
                            path: 'newsletter',
                            queryParams: {}
                        }),
                        fromActions.createDraftSuccess(),
                        fromWS.wsStopWork({newsletterId: data.formNewsletter.newsletterId}),
                    ]),
                    catchError(({message}) => of(fromActions.createDraftFailure({message}))
                    )))
        ), {dispatch: true}
    );

    public publishNewsletter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.publishNewsletter),
            mergeMap((data) =>
                this.newsletterService.publish(data.formNewsletter).pipe(
                    mergeMap(() => [
                        fromRouter.go({
                            path: 'newsletter',
                            queryParams: {}
                        }),
                        fromDashboard.getNewsletters(),
                        fromDashboard.getDrafts(),
                        fromActions.publishNewsletterSuccess(),
                        fromWS.wsStopWork({newsletterId: data.formNewsletter.newsletterId})
                    ]),
                    catchError(({message}) => of(fromActions.publishFailure({message}))
                    )))
        ), {dispatch: true}
    );

    public publishNewsletterfromDashboard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.publishNewsletterFromDraft),
            mergeMap((data) =>
                this.newsletterService.publishNewsletterFromDraft(data.formNewsletter).pipe(
                    mergeMap(() => [
                        fromRouter.go({
                            path: 'newsletter',
                            queryParams: {}
                        }),
                        fromDashboard.getNewsletters(),
                        fromDashboard.getDrafts(),
                        fromActions.publishNewsletterSuccess(),
                        fromWS.wsStopWork({newsletterId: data.formNewsletter.newsletterId})
                    ]),
                    catchError(({message}) => of(fromActions.publishFailure({message}))
                    )))
        ), {dispatch: true}
    );

    public updateDraft$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.updateDraft),
            mergeMap((data) =>
                this.newsletterService.updateDraft(data.formNewsletter).pipe(
                    mergeMap(() => [
                        fromDashboard.getNewsletters(),
                        fromRouter.go({
                            path: 'newsletter',
                            queryParams: {}
                        }),
                        fromActions.createDraftSuccess(),
                        fromWS.wsStopWork({newsletterId: data.formNewsletter.newsletterId})
                    ]),
                    catchError(({message}) => of(fromActions.createDraftFailure({message}))
                    )))
        ), {dispatch: true}
    );

    public sendTestNewsletter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.sendTestNewsletter),
            mergeMap(({formNewsletter}) => {
                return this.newsletterService.sendTest(formNewsletter).pipe(
                    mergeMap(() => [fromActions.sendTestNewsletterSuccess()]),
                    catchError(({}) => of(null)
                    ));
            })
        ), {dispatch: true});

    public getChannelsNewsletter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getChannelsNewsletter),
            mergeMap(() => {
                return this.newsletterService.getChannels().pipe(
                    mergeMap((channels) => {
                        return [fromActions.getChannelsNewsletterSuccess({channels})];
                    }),
                    catchError(({message}) => of(fromActions.getChannelsNewsletterFailed({error: message}))
                    ));
            })
        ), {dispatch: true});

    public getNewsForChannelNewsletter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getNewsForChannel),
            mergeMap((data) => {
                return this.newsletterService.getNewsForChannel(data.id, data.language).pipe(
                    mergeMap((response) => {
                        return [fromActions.getNewsForChannelSuccess({
                            id: data.id,
                            totalCount: response.totalCount,
                            news: response.data
                        })];
                    }),
                    catchError(({message}) => of(fromActions.getNewsForChannelFailed({error: message}))
                    ));
            })
        ), {dispatch: true});

    public updateNewsForChannelNewsletter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.updateNewsForChannel),
            mergeMap((data) => {
                return this.newsletterService.getNewsForChannel(data.id, data.language).pipe(
                    mergeMap((response) => {
                        return [fromActions.updateNewsForChannelSuccess({
                            id: data.id,
                            totalCount: response.totalCount,
                            news: response.data
                        })];
                    }),
                    catchError(({message}) => of(fromActions.updateNewsForChannelFailed({error: message}))
                    ));
            })
        ), {dispatch: true});


    public openNewsPreview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getImagesUserAgreement),
            map(({newsletterId}) => fromActions.agreementsAccepted({newsletterId}))
        ), {dispatch: true}
    );

    public getImagesUserAgreement$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getImagesUserAgreement),
            map(({newsletterId}) => {
                const dialog = this.dialogService.open(NewsletterConfidentalNoteDialogContainer, {
                    size: 'l',
                    maxHeight: '90%'
                })
                return ({dialog, newsletterId})
            }),
            switchMap(({dialog, newsletterId}) => dialog.afterClosed().pipe(
                map((confirmed) => {
                    if (confirmed) {
                        return fromActions.agreementsAccepted({newsletterId})
                    } else {
                        return fromRouter.go({path: 'newsletter', queryParams: {}})
                    }
                })
            )),
        ), {dispatch: true}
    );

    public setUserAgreement$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.setImagesUserAgreement),
            mergeMap(() => {
                return this.newsletterService.setUserAgreement().pipe(
                    mergeMap(() => [fromActions.setImagesUserAgreementSuccess()]),
                    catchError(() => of(fromActions.setImagesUserAgreementFailure())
                    ));
            })
        ), {dispatch: true});

    constructor(
        private actions$: Actions,
        private store$: Store<fromUiReducer.State>,
        private newsletterService: NewsletterService,
        private dialogService: RdsDialogService
    ) {
    }
}
