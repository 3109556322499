import { Component, Input } from '@angular/core';
import { RDS_AVATAR_MAX_IN_GROUP, RdsAvatarSize } from '@rds/angular-components';

@Component({
	selector: 'rh-user-group',
	templateUrl: './user-group.component.html',
	styles: [],
	providers: [{ provide: RDS_AVATAR_MAX_IN_GROUP, useValue: 999 }],
})
export class UserGroupComponent {
	@Input() size: RdsAvatarSize = 'm';
	@Input() maxInGroup: number = 4;

	visibleUsers: Array<{
		login: string;
		name: string;
	}>;
	hiddenUsers: Array<{
		login: string;
		name: string;
	}>;
	public _users: Array<{
		login: string;
		name: string;
	}>;

	@Input() set users(value: Array<any>) {
		this._users = value.map((u) => ({
			login: u.login || u.identifier || u.username || u.userLogin,
			name: u.name || `${u.firstName} ${u.lastName}`,
		}));
		this.visibleUsers = this.users.slice(
			0,
			this.users.length > this.maxInGroup ? this.maxInGroup - 1 : this.users.length
		);
		this.hiddenUsers = this.users.length > this.maxInGroup ? this.users.slice(this.maxInGroup - 1) : [];
	}

	get users() {
		return this._users;
	}
}
