<h2 rds-dialog-header>Assign or Suggest to channel</h2>
<div rds-dialog-content [formGroup]="assignement">
    <h4 class="ui-heading-4 mb-7">Assign to channels
            <span [rdsTooltip]="assignTooltipTemplate">
                <rds-icon  class="text-icon" icon="info" size="s" namespace="outlined"></rds-icon>
            </span>
            <ng-template #assignTooltipTemplate>
                <span style="text-align: left" [innerHTML]="'Please note that once you assign the news to selected channels, they cannot be un-assigned.<br/>After clicking saving the news, you will be able to assign more channels but will not be able to remove any assigned channel.'"></span>
            </ng-template>
    </h4>
    <p class="ui-body-s mb-5">News in assigned channels will be directly published on the publish date.</p>
    <rh-channel-picker 
        class="mb-7"
        [multi]="true"
        formControlName="assign"
        [options]="assignable">
    </rh-channel-picker>
    <rds-divider class="mb-7"></rds-divider>
    <h4 class="ui-heading-4 mb-7">Suggest to channels
        <span [rdsTooltip]="suggestTooltipTemplate">
            <rds-icon  class="text-icon" icon="info" size="s" namespace="outlined"></rds-icon>
        </span>
        <ng-template #suggestTooltipTemplate>
            <span style="text-align: left" [innerHTML]=" 'Only the person who made the original suggestion can un-suggest for as long as the suggestion request status is \'pending\'.<br/>This means other editors and owners of this news will not be able to modify your suggestions.'"></span>
        </ng-template>
    </h4>
    <p class="ui-body-s mb-5">Selected channels will receive a news suggestion request. Your news will need to get approved by selected channels in order to be published. You'll receive an email notification about accepted / declined suggestion.</p>
    <rh-channel-picker 
        class="mb-7"
        [multi]="true"
        formControlName="suggest"
        [options]="nonAssignable">
    </rh-channel-picker>
</div>
<div rds-dialog-actions-container align="end">
    <button
        rds-secondary-button
        [rds-dialog-close]="null"
        size="m"
    >Cancel</button>
    <button
        rds-primary-button
        [rds-dialog-close]="confirmData"
        [disabled]="assignement.valid === false || noChangesMade || !assignable || !nonAssignable"
        size="m"
    >Assign/suggest to channels</button>

</div>