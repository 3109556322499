import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numToArray'
})
export class NumToArrayPipe implements PipeTransform {

  transform(args: number, value: any): any {
    return [...Array(args).keys()]
  }

}
