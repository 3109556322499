import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[rhIsOverflowing]',
  exportAs: 'rhIsOverflowing'
})
export class IsOVerflowingDirective implements AfterViewInit {

  resizeObserver: ResizeObserver;

  constructor(private _elementRef: ElementRef, private cdr: ChangeDetectorRef) {

  }

  private _overflowingX;
  get overflowingX() {
    return this._overflowingX;
  };

  
  private _overflowingY;
  get overflowingY() {
    return this._overflowingY;
  };

  checkOverflowX(element: HTMLElement) {
    return element.scrollWidth > element.clientWidth
  }

  checkOverflowY(element: HTMLElement) {
    return element.scrollHeight > element.clientHeight
  }

  checkOverflows(element: HTMLElement) {
    if (element.tagName === 'P' || element.tagName === 'SPAN') {
      this._overflowingX = this.checkOverflowX(element);
      this._overflowingY = this.checkOverflowY(element);
    } else {
      let firstText = element.getElementsByTagName('P')[0] || element.getElementsByTagName('SPAN')[0];
      this.checkOverflows(firstText as HTMLElement);
    }
  }

  ngAfterViewInit(): void {
    this.resizeObserver = new ResizeObserver(entries => {
      const element = this._elementRef.nativeElement as HTMLElement;
      this.checkOverflows(element)
    })

    this.resizeObserver.observe(this._elementRef.nativeElement);
  }

}
