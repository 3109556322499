<h2 rds-dialog-header>
	<ng-container> Template preview </ng-container>
</h2>
<div rds-dialog-icon-placeholder>
	<button rds-icon-button size="m" [rds-dialog-close]="null">
		<rds-icon icon="close" namespace="outlined"></rds-icon>
	</button>
</div>
<div rds-dialog-content class="p-7">
	<div class="preview__wrapper">
		<div class="preview__content">
			<rh-newsletter-preview-readonly
				[sections]="data.sections | async"
				[preview]="data.form | async"
			></rh-newsletter-preview-readonly>
		</div>
	</div>
</div>
<div rds-dialog-actions-container align="end">
	<button rds-secondary-button [rds-dialog-close]="null" size="m">Close</button>
</div>
