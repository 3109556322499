import { createAction, props } from '@ngrx/store';
import { Channel, NewsletterNews } from '@app/core/models';

export const channelsCollectionUpsertOne = createAction(
  '[STORED CHANNELS - ALL LANGUAGE] Channels collection upsert one.',
  props<{ channel: Channel }>()
);

export const newsCollectionForChannelAddAll = createAction(
  '[STORED CHANNELS - ALL LANGUAGE] News collection add all.',
  props<{ channelId: string; news: Array<NewsletterNews> }>()
);

export const addChannel = createAction(
  '[STORED CHANNELS - ALL LANGUAGE] Add channel',
  props<{ channelId: string }>()
);

export const loadChannelsSuccess = createAction(
  '[STORED CHANNELS - ALL LANGUAGE] Load chanels success',
  props<{ channels: Array<Channel> }>()
);

export const loadChannelsFailure = createAction(
  '[STORED CHANNELS - ALL LANGUAGE] Load chanels error',
  props<{ error: any }>()
);
