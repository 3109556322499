<h2 rds-dialog-header>
  <ng-container [ngSwitch]="context">
    <ng-container *ngSwitchCase="'Editor'">
      News preview
    </ng-container>
    <ng-container *ngSwitchCase="'Table'">
      News preview
    </ng-container>
    <ng-container *ngSwitchCase="'Template'">
      Template preview
    </ng-container>
  </ng-container>
</h2>
<div rds-dialog-icon-placeholder>
  <button rds-icon-button size="m" [rds-dialog-close]="null">
    <rds-icon icon="close" namespace="outlined"></rds-icon>
  </button>
</div>
<div rds-dialog-content>
  <div class="news-preview__heading">
    <rds-tabs #tabs size="m" >
      <rds-tab label="News Abstract">
        <div class="abstract-container mt-9 pb-11">
          <div class="abstract">
            <p class="abstract--title">Normal view</p>
            <div class="abstract--card">
              <rh-news-card-item
              [id]="news.id"
              [hasRead]="false"
              [imageUrl]="activeContent.image?.url"
              [title]="activeContent.title "
              [description]="activeContent.abstract"
              [creationDate]="publishDate"
              [likeEnabled]="true"
              [likeCount]="0"
              [hasBookmarked]="false"
              [enableBookmark]="true"
              [hasLiked]="false"
              [channelName]="channelName"
              [channelId]="channelId"
              [url]="null"
              [type]="news.contentType"
              [isLikingProcessingIds]="false"
              [color]="'neutral'"
              [isEdit]="context === 'TemplateEditor' || context === 'NewsEditor'"
              [languageName]="activeLanguageName"
              [disableAnimation]="true"
              [forceHoverState]="false">
            </rh-news-card-item>
            </div>
          </div>
          <div class="abstract">
            <p class="abstract--title">Mouse over</p>
            <div class="abstract--card">
              <rh-news-card-item
              [id]="news.id"
              [hasRead]="false"
              [imageUrl]="activeContent.image?.url"
              [title]="activeContent.title"
              [description]="activeContent.abstract"
              [creationDate]="publishDate"
              [likeEnabled]="true"
              [likeCount]="0"
              [hasBookmarked]="false"
              [enableBookmark]="true"
              [hasLiked]="false"
              [channelName]="channelName"
              [channelId]="channelId"
              [url]="null"
              [type]="news.contentType"
              [isLikingProcessingIds]="false"
              [color]="'neutral'"
              [isEdit]="context === 'TemplateEditor' || context === 'NewsEditor'"
              [languageName]="activeLanguageName"
              [disableAnimation]="true"
              [forceHoverState]="true">
            </rh-news-card-item>
            </div>
          </div>
        </div>
      </rds-tab>
      <rds-tab *ngIf="news.contentType !== 'Link'" label="News Content" [active]="true">
        <rh-news-content-preview
          [languageName]="activeLanguageName"
          [language]="activeLanguage"
          [languages]="languageOptions"
          [news]="news"
          [viewMode]="contentMode"
          [channelName]="channelName"
          [channelsDict]="channelsDict"
          [isEdit]="context === 'TemplateEditor' || context === 'NewsEditor'" 
        >
        </rh-news-content-preview>
      </rds-tab>
    </rds-tabs>
    <div class="news-preview__heading--options">
      <rds-toggle *ngIf="showViewToggle"
      [(value)]="contentMode"
      [type]="'outlined'"
      >
      <rds-toggle-button
        value="desktop"
        [checked]="contentMode === 'desktop'"
      >
        <svg width="24" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
          <path d="M21.8595 17.57L19.9995 14.07V6C19.9995 4.9 19.0995 4 17.9995 4H5.99953C4.89953 4 3.99953 4.9 3.99953 6V14.07L2.13953 17.57C1.92953 18.03 1.95953 18.54 2.20953 18.97L2.25953 19.05C2.58953 19.63 3.27953 20 4.03953 20H19.9595C20.7195 20 21.4095 19.63 21.7395 19.05L21.7895 18.97C22.0395 18.54 22.0695 18.03 21.8595 17.57ZM5.99953 6H17.9995V14H5.99953V6ZM4.17953 18L5.23953 16H18.7595L19.8195 18H4.17953Z" />
        </svg>
      </rds-toggle-button>
      <rds-toggle-button
        value="mobile"
        [checked]="contentMode === 'mobile'"
      >
        <svg width="24" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
          <path d="M16 4V20H8V4H16ZM16 2H8C6.9 2 6 2.9 6 4V20C6 21.1 6.9 22 8 22H16C17.1 22 18 21.1 18 20V4C18 2.9 17.1 2 16 2Z" />
          <path d="M10 3H14V4C14 4.55 13.55 5 13 5H11C10.45 5 10 4.55 10 4V3Z" />
        </svg>
      </rds-toggle-button>
    </rds-toggle>
      <button *ngIf="languageOptions?.length > 1" class="bg-white" rds-dropdown-toggle-button [rdsMenuTriggerFor]="menu" #trigger="rdsMenuTrigger"
        [isOpen]="trigger.menuOpen">{{activeLanguageName}}
      </button>
      <rds-menu #menu="rdsMenu">
        <button *ngFor="let language of languageOptions" rds-menu-item (click)="setActiveLanguage(language)">
          <span>{{language.name}}</span>
        </button>
      </rds-menu>
    </div>
  </div>
</div>
<div rds-dialog-actions-container align="end">
  <ng-container [ngSwitch]="context">
    <ng-container *ngSwitchCase="'NewsEditor'">
      <button *ngIf="buttons?.canSaveNews?.visible" [disabled]="buttons?.canSaveNews?.disabled" rds-primary-button [rds-dialog-close]="'saveNewsAsDraft'" size="m">
        Save as draft
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'TemplateEditor'">
      <button *ngIf="buttons?.canSaveTemplate?.visible" [disabled]="buttons?.canSaveTemplate?.disabled" rds-primary-button [rds-dialog-close]="'saveTemplate'" size="m">
        Save template
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'Table'">
      <ng-container [ngSwitch]="news.status">
        <rds-badge *ngSwitchCase="'Published'" class="me-auto" size="m" label="Published" type="minimal" color="green" ></rds-badge>
        <rds-badge *ngSwitchCase="'Draft'" class="me-auto" size="m" label="Draft" type="minimal" color="blue" ></rds-badge>
        <rds-badge *ngSwitchCase="'Scheduled'" class="me-auto" size="m" label="Scheduled" type="minimal" color="neutral" ></rds-badge>
      </ng-container>
      <button *ngIf="news.contentType !== 'Link' && news.status === 'Published'" rds-text-button (click)="gotoEmbedded()" size="m">
        Open in RocheHome <rds-icon icon="launch"></rds-icon>
      </button>
      <button *ngIf="news.contentType === 'Link' && news.status === 'Published'" rds-text-button (click)="gotoLink()" size="m">
        Open in new tab <rds-icon icon="launch"></rds-icon>
      </button>
      <button *ngIf="buttons?.editNews?.visible" [disabled]="buttons?.editNews?.disabled"  rnbBackButtonSet [label]="backButton?.label" [routeBack]="backButton?.routeBack" [routeCurrent]="backButton?.routeCurrent" rds-primary-button [routerLink]="'/news/'+news.id+'/edit'" [rds-dialog-close]="null" size="m">
        Edit news
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'Template'">
      <button *ngIf="buttons?.editTemplate?.visible" [disabled]="buttons?.editTemplate?.disabled" rds-secondary-button [rds-dialog-close]="'editTemplate'" size="m">
        Edit template
      </button>
      <button rds-primary-button [rds-dialog-close]="'useTemplate'" size="m">
        Use this template
      </button>
    </ng-container>
  </ng-container>
  <!-- <ng-container [ngSwitch]="news.status">
    <rds-badge *ngSwitchCase="'Published'" class="me-auto" size="m" label="Published" type="minimal" color="green" ></rds-badge>
    <rds-badge *ngSwitchCase="'Draft'" class="me-auto" size="m" label="Draft" type="minimal" color="blue" ></rds-badge>
    <rds-badge *ngSwitchCase="'Scheduled'" class="me-auto" size="m" label="Scheduled" type="minimal" color="neutral" ></rds-badge>
  </ng-container>
  <button *ngIf="news.contentType !== 'Link' && news.status === 'Published' && !isEdit" rds-text-button (click)="gotoEmbedded()" size="m">
    Open in RocheHome <rds-icon icon="launch"></rds-icon>
  </button>
  <button *ngIf="news.contentType === 'Link' && !isEdit" rds-text-button (click)="gotoLink()" size="m">
    Open in new tab <rds-icon icon="launch"></rds-icon>
  </button>
  <button rds-secondary-button [rds-dialog-close]="null" size="m">
    Exit preview
  </button>
  <button *ngIf="showEdit && !isEdit" rds-primary-button [routerLink]="'/news/'+news.id+'/edit'" [rds-dialog-close]="null" size="m">
    Edit news
  </button>
  <button *ngIf="isEdit" rds-primary-button [disabled]="buttonsCreateAndEdit?.canSaveDraft === false" [rds-dialog-close]="true" size="m">
    Save as draft
  </button> -->
</div>