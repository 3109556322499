import {createAction, props} from '@ngrx/store';
import {AlertModel} from '@core/models';

export const getFeedRequest = createAction(
  '[ALERT - FEED] get'
);

export const getFeedSuccess = createAction(
  '[ALERT - FEED] success',
  props<{ data: AlertModel[] }>()
);

export const getFeedFailure = createAction(
  '[ALERT - FEED] failure',
  props<{ message: string }>()
);

export const markAsReadRequest = createAction(
  '[ALERT - FEED] mark as read request',
  props<{ id: number }>()
);

export const markAsReadSuccess = createAction(
  '[ALERT - FEED] mark as read success',
  props<{ id: number }>()
);

export const markAsReadFailure = createAction(
  '[ALERT - FEED] mark as read failure',
  props<{ message: string }>()
);

