<rh-list *ngIf="result">
  <div image class="list-image">
    <img [src]="result.imageUrl || '/assets/images/news-placeholder.png'" (click)="openNews()" class="cursor-pointer">
  </div>
  <p
    body-top class="ui-label-l-bold text-high-contrast text-truncate"
  >
    <span
      class="cursor-pointer"
      (click)="openNews()">
      {{result.title}}
      <rds-icon class="icon-button cursor-pointer" icon="launch" namespace="outlined" *ngIf="result.type === 'link'" style="width: 16px;vertical-align: middle"></rds-icon>
    </span>
  </p>
  <p
    body-middle

    class="ui-label-l-bold text-low-contrast text-truncate">
      <span
        [rdsTooltip]="descriptionTemplate"
        rdsTooltipPosition="top-center"
        [rdsTooltipShowDelay]="500" [innerHTML]="result.description">
    </span>
  </p>
  <div body-bottom style="display: flex; justify-content: space-between;">
    <p class="ui-component-text-s text-low-contrast text-uppercase">
      
      <span
        [rdsTooltip]="dateTemplate"
        rdsTooltipPosition="top-center"
        [rdsTooltipShowDelay]="500">
        {{result.creationDate | timeAgo}}
      </span>
    </p>
    <p class="ui-component-text-m text-low-contrast text-uppercase">
      <rds-icon *ngIf="!result.hasLiked" class="result__button" icon="heart" size="s" namespace="outlined"
                (click)="toggleLike(result)"></rds-icon>
      <rds-icon *ngIf="result.hasLiked" class="result__button" icon="heart" size="s" namespace="filled"
                (click)="toggleLike(result)"></rds-icon>
      {{result.likeCount}}
    </p>
    <!-- TODO: Add subscription button -->
  </div>
</rh-list>

<ng-template #titleTemplate>
  <span [innerHTML]="result.title"></span>
</ng-template>
<ng-template #descriptionTemplate>
  <span [innerHTML]="result.description"></span>
</ng-template>
<ng-template #dateTemplate>
  <span [innerHTML]="result.creationDate | timeAgo"></span>
</ng-template>
