import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NewsboardAuthService } from '@app/core/auth/services/newsboard-auth.service';
import { Language } from '@app/core/models/language.model';
import { ChannelAssignment, ChannelBase, ContentType, NewsContent, NewsDetails } from '@app/core/models/newsboard';
import * as fromRouter from '@app/root-store/router';
import { BackButton } from '@app/root-store/ui/back-button';
import * as fromLanguages from '@core/core-store/languages-dict';
import { Store, select } from '@ngrx/store';
import { RDS_DIALOG_DATA, RdsDialogRef, RdsTabsComponent } from '@rds/angular-components';
import { Observable } from 'rxjs';

type ContentMode = 'desktop' | 'mobile';

export interface NewsPreviewButtonState {
  visible: boolean;
  disabled: boolean;
}

export interface NewsPreviewButtons {
  editNews?: NewsPreviewButtonState
  canSaveNews?: NewsPreviewButtonState
  editTemplate?: NewsPreviewButtonState
  canSaveTemplate?: NewsPreviewButtonState
}

export interface NewsPreviewDialogData  {
  news: NewsDetails;
  channel: ChannelBase;
  channelsDict?: Array<ChannelAssignment>;
  context: 'TemplateEditor' | 'NewsEditor' | 'Table' | 'Template' | 'NewsSelect';
  buttons?: NewsPreviewButtons
  backButton?: BackButton;
}

@Component({
  selector: 'rh-news-preview-dialog',
  templateUrl: './news-preview-dialog.component.html',
  styleUrls: ['./editor.scss', './news-preview-dialog.component.scss']
})
export class NewsPreviewDialogComponent implements OnInit {
  @ViewChild('tabs') tabs: RdsTabsComponent;

  context: 'TemplateEditor' | 'NewsEditor' | 'Table' | 'Template' | 'NewsSelect';
  backButton: BackButton;
  buttons: NewsPreviewButtons;
  channelId: number;
  channelName: string;
  channelsDict: Array<ChannelAssignment>;
  languageOptions: Array<{ id: string; name: string }>;
  news: NewsDetails;
  activeLanguage: string;
  viewMode: ContentType = 'Link';
  contentMode: ContentMode = 'desktop';

  languages$: Observable<Array<Language>> = this.store$.pipe(select(fromLanguages.selectAll));


  get isAdmin(): boolean {
    return this.auth.isAdmin || this.auth.isSuperAdmin;
  }

  get publishDate(): Date {
    return this.news.publishDate ? new Date(this.news.publishDate) : null;
  }

  get showViewToggle() {
    return this.tabs?.selectedIndex > 0;
  }

  get showEdit() {
    return this.news.isOwner || this.news.isEditor || this.isAdmin;
  }

  get activeContent(): NewsContent {
    return this.news.content.find((newsContent) => this.activeLanguage === newsContent.language);
  }

  get activeLanguageName() {
    return this.languageOptions?.find((option) => this.activeLanguage === option.id)?.name;
  }
  

  constructor(
    private store$: Store<any>,
    private auth: NewsboardAuthService,
    private dialogRef: RdsDialogRef<NewsPreviewDialogComponent>,
    @Inject(RDS_DIALOG_DATA) data: NewsPreviewDialogData
  ) {
    this.news = data.news;
    this.channelId = data.channel?.id;
    this.channelName = data.channel?.name;
    this.channelsDict = data.channelsDict;
    this.activeLanguage = this.news.content[0].language;
    this.context = data?.context;
    this.backButton = data?.backButton;
    this.buttons = data?.buttons;
  }

  ngOnInit(): void {
    this.store$.dispatch(fromLanguages.getLanguagesDictRequest());
    
    this.languages$.subscribe((languageData) => {
      const contentLanguageIds = this.news.content.map(({language}) => language);
      this.languageOptions = languageData
        .filter((language) => contentLanguageIds.includes(language.code))
        .map(({code: id, englishName}) => ({id, name: englishName}));
    });
  }

  close(status?): void {
    this.dialogRef.close({status});
  }

  setContentMode(contentMode: ContentMode): void {
    this.contentMode = contentMode;
  }

  setActiveLanguage(language): void {
    this.activeLanguage = language.id;
  }

  setViewMode(viewMode: ContentType): void {
    this.viewMode = viewMode;
  }

  gotoLink(): void {
    window.open(this.activeContent.link, '_blank');
  }

  gotoEmbedded(): void {
    const channelId = this.channelId?.toString() || 'any';
    this.store$.dispatch(fromRouter.goInNewTab({url: `content/news:${channelId}:${this.news.id.toString()}`, redirect: true}))
  }

}
