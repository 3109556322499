import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RhNewsletterForm, RhNewsletterRemappingResults } from '@app/newsletter-new/models/newsletter';
import { NewsletterValueModel } from '@app/newsletter/models';
import { CroppComponent } from '@app/shared/cropp/cropp.component';
import { RDS_DIALOG_DATA, RdsDialogRef } from '@rds/angular-components';
import { filter } from 'rxjs';
@Component({
  selector: 'rh-newsletter-legacy-remapping-dialog',
  templateUrl: './newsletter-legacy-remapping-dialog.container.html',
  styleUrls: ['./newsletter-legacy-remapping-dialog.container.scss']
})
export class NewsletterLegacyRemappingDialogContainer implements AfterViewInit {
  @ViewChild(CroppComponent) cropp: CroppComponent;
  get noErrors() {
    return !this.data.results.image && !this.data.results.news && !this.data.results.radaGroups;
  }

  form = new FormGroup({
    image: new FormControl(null),
  });

  isCropReady = false;
  currentRatio = 0;
  isCurrentRatioMatching = false;

  constructor(
    private dialogRef: RdsDialogRef<NewsletterLegacyRemappingDialogContainer>,
    @Inject(RDS_DIALOG_DATA) public data: {
      mode: 'decision' | 'progress' | 'result',
      legacyNewsletter?: NewsletterValueModel;
      newNewsletter?: RhNewsletterForm,
      sections?: any;
      results?: RhNewsletterRemappingResults
    }) {}

  ngAfterViewInit(): void {
    if (this.data.mode === 'result') {
      this.form.controls.image.valueChanges.pipe(
        filter(value => !!value),
      ).subscribe((value) => {
        this.cropp.replaceImage(value);
      });

      this.form.controls.image.setValue({
        id: 'current',
        url: this.data.legacyNewsletter.bannerPhotoUrl,
        name: this.data.legacyNewsletter.bannerPhotoUrl,
        size: this.data.legacyNewsletter.bannerPhotoSize
      });
    }
}

  close(reason) {
    let newNewsletter = null;
    if (reason === 'finish') {
        newNewsletter = {
          ...this.data.newNewsletter,
          banner: this.data.legacyNewsletter.titleIncluded? null : this.form.controls.image.value,
          image: this.data.legacyNewsletter.titleIncluded? this.form.controls.image.value : null,
        } as RhNewsletterForm
    }
    this.dialogRef.close({
      reason,
      newNewsletter
    })
  }

  checkRatio(currentRatio: number) {
    this.currentRatio = currentRatio;
    this.isCurrentRatioMatching = Math.abs(currentRatio - this.data.results.image.correctRatio) < 0.015;
  }
}
