import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {NewsboardAuthService} from '@app/core/auth/services/newsboard-auth.service';
import {Template, TemplateRoles} from '@app/core/models/newsboard/template';
import * as fromSuggestions from '@app/root-store/suggestions';
import {Autocomplete} from '@app/root-store/suggestions';
import {RolePickerUtils} from '@app/shared/custom-controls/people-role-picker/roles';
import {CustomValidators} from '@app/shared/form-controls/validators/validator.function';
import {select, Store} from '@ngrx/store';
import {RDS_DIALOG_DATA} from '@rds/angular-components';
import cloneDeep from '@lodash-es/cloneDeep';

import {Observable} from 'rxjs';

export interface ShareTemplateDialogData {
    template: Template
}

@Component({
    selector: 'rh-share-template-dialog',
    templateUrl: './share-template-dialog.component.html',
    styleUrls: ['./share-template-dialog.component.scss']
})
export class ShareTemplateDialogComponent {
    permissionsAutocomplete$: Observable<Autocomplete> = this.store$.pipe(select(fromSuggestions.selectAutocomplete('permissions')))

    TEMPLATE_ROLES;

    userIdentifier = this.auth.currentSimpleUser.identifier;

    template: Template;
    public form: FormGroup = new FormGroup({
        permissions: new FormControl({
            owners: [],
            editors: [],
            viewers: [],
        }, CustomValidators.validateTemplatePermissions)
    });

    initialOwners;

    get formOwners() {
        return (this.form?.controls.permissions as FormGroup)?.value.owners;
    }

    initialEditors;

    get formEditors() {
        return (this.form?.controls.permissions as FormGroup)?.value.editors;
    }

    initialViewers;

    get formViewers() {
        return (this.form?.controls.permissions as FormGroup)?.value.viewers;
    }

    get isMyTemplate() {
        const form = [...this.formOwners, ...this.formEditors, ...this.formViewers].map(u => u.identifier).sort().toString();

        return form === this.userIdentifier;
    }

    get initialUsersNotChanged() {
        const initial = [...this.initialOwners, ...this.initialEditors, ...this.initialViewers].map(u => u.identifier).sort().toString();
        const form = [...this.formOwners, ...this.formEditors, ...this.formViewers].map(u => u.identifier).sort().toString();
        return initial === form;
    }

    get initialRolesNotChanged() {
        return this.initialOwners.every(io => this.formOwners?.findIndex(fo => fo.identifier === io.identifier) > -1) && this.initialEditors.every(ie => this.formEditors?.findIndex(fe => fe.identifier === ie.identifier) > -1) && this.initialViewers.every(iv => this.formViewers?.findIndex(fv => fv.identifier === iv.identifier) > -1);
    }

    get noChangesMade() {
        return this.formOwners.every(fo => this.initialOwners?.findIndex(io => io.identifier === fo.identifier) > -1) && this.formEditors.every(fe => this.initialEditors?.findIndex(ie => ie.identifier === fe.identifier) > -1) && this.formViewers.every(fv => this.initialViewers?.findIndex(iv => iv.identifier === fv.identifier) > -1);
    }

    constructor(@Inject(RDS_DIALOG_DATA) public data: ShareTemplateDialogData, private auth: NewsboardAuthService, private store$: Store<any>) {
        this.store$.dispatch(fromSuggestions.initSuggestion({suggestionType: 'user', prop: 'permissions'}));
        if (!!data) {
            this.template = cloneDeep(data.template);
            let role: TemplateRoles;
            role = this.template.owners.findIndex(c => c.identifier === this.userIdentifier) > -1 ? 'Owner' : role;
            role = this.template.editors.findIndex(c => c.identifier === this.userIdentifier) > -1 ? 'Editor' : role;
            role = this.template.viewers.findIndex(c => c.identifier === this.userIdentifier) > -1 ? 'Viewer' : role;
            this.TEMPLATE_ROLES = RolePickerUtils.getTemplateRoles(role);

            this.initialOwners = cloneDeep(this.template.owners);
            this.initialEditors = cloneDeep(this.template.editors);
            this.initialViewers = cloneDeep(this.template.viewers);

            this.form.controls.permissions.patchValue({
                owners: this.template.owners,
                editors: this.template.editors,
                viewers: this.template.viewers,
            })
        }
    }

    getAutocompletes(prop, event) {
        this.store$.dispatch(fromSuggestions.loadSuggestion({suggestionType: 'user', prop, phrase: event}))
    }
}
