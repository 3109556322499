import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	Host,
	Input,
	Optional,
	Output,
	QueryList,
	SkipSelf,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { ControlContainer, FormControl, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { CustomValidators } from '@app/shared/form-controls/validators/validator.function';
import {
	RdsMultiSelectFilterByFunc,
	RdsMultiSelectOptionComponent,
	RdsSingleSelectFilterByFunc,
	RdsSingleSelectOptionComponent,
} from '@rds/angular-components';
import { debounceTime } from 'rxjs';
import { OverflowingContentDirective } from '../../utils/overflowing-content.directive';
import { RecipientListsTableModel } from '@app/core/models/newsletter.model';

@Component({
	selector: 'rh-recipient-list-picker',
	templateUrl: './recipient-list-picker.component.html',
	styleUrls: ['./recipient-list-picker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => RecipientListPickerComponent),
		},
	],
})
export class RecipientListPickerComponent implements AfterViewInit {
	@ViewChild('overflowing', { static: false }) overflowing: OverflowingContentDirective;
	@ViewChild('selectNgModel', { static: false }) selectNgModel: NgModel;
	@ViewChildren('noPersonalized', { read: ElementRef }) noPersonalized!: QueryList<ElementRef<HTMLElement>>;
	@ViewChildren('personalized', { read: ElementRef }) personalized!: QueryList<ElementRef<HTMLElement>>;
	selectedLists: Array<number> = [];

	get personalizedEmpty() {
		const empty = this.personalized?.toArray().every((e) => e.nativeElement.classList.contains('hide'));
		return empty;
	}

	get noPersonalizedEmpty() {
		const empty = this.noPersonalized?.toArray().every((e) => e.nativeElement.classList.contains('hide'));
		return empty;
	}

	get chipsList() {
		return this.options?.filter((c) => this.selectedLists.some((id) => id === c.id));
	}

	onChange = (users) => {};

	onTouched = () => {};

	touched = false;

	disabled = false;

	hostWidth: number;

	writeValue(suggestions: Array<number>) {
		this.selectedLists = suggestions;
	}

	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}

	selectChanged(event) {
		this.selectedLists = event;
		this.selected.emit();

		this.onChange(this.selectedLists);
		if (!!this.overflowing) {
			setTimeout(() => {
				this.overflowing.update();
			}, 1);
		}
	}

	@Output() search: EventEmitter<string> = new EventEmitter<string>();
	@Output() selected: EventEmitter<string> = new EventEmitter<string>();
	@Output() removed: EventEmitter<string> = new EventEmitter<string>();
	@Output() detailsClick: EventEmitter<any> = new EventEmitter<any>();
	_options: Array<RecipientListsTableModel>;
	get options(): Array<RecipientListsTableModel> {
		return this._options;
	}

	@Input() set options(value: Array<RecipientListsTableModel>) {
		if (!!value) {
			this._options = value;
		}
	}

	@Input() formControl!: FormControl;
	@Input() formControlName!: string;
	@Input() required = false;

	get control() {
		return this.formControl || this.controlContainer.control?.get(this.formControlName);
	}

	@Input() label: string = 'Select Recipient list';
	@Input() myLabel: string;
	@Input() addedLabel: string;
	@Input() allowRemoveSelf: boolean = false;
	@Input() multi: boolean = false;
	@Input() groupByPersonalizationSetting = false;

	select(id: number) {
		this.multi ? (this.selectedLists = [...this.selectedLists, id]) : (this.selectedLists = [id]);
		this.selected.emit();
		this.onChange(this.selectedLists.slice());
	}

	remove(index: number) {
		this.selectedLists = this.selectedLists.filter((t, i) => i !== index);

		this.removed.emit();
		this.onChange(this.selectedLists);
	}

	ngAfterViewInit(): void {
		this.selectNgModel.control.setValidators(CustomValidators.isMasterControlValid(this.control));
		this.selectNgModel.control.updateValueAndValidity();

		const originalMarkAsTouched = this.control.markAsTouched;
		const that = this;
		this.control.markAsTouched = function () {
			originalMarkAsTouched.apply(this, arguments);
			that.selectNgModel.control.markAsTouched();
			that.selectNgModel.control.updateValueAndValidity();
		};

		this.control.statusChanges.pipe(debounceTime(100)).subscribe((status) => {
			this.selectNgModel.control.updateValueAndValidity();
		});
	}

	constructor(
		@Optional()
		@Host()
		@SkipSelf()
		private controlContainer: ControlContainer
	) {}

	filterByMulti: RdsMultiSelectFilterByFunc<string> = (
		text: string | null,
		item: RdsMultiSelectOptionComponent<string>
	) => {
		if (text === null || text === '') {
			return true;
		} else {
			return item.id.toLowerCase().includes(text.toLowerCase());
		}
	};

	filterBySingle: RdsSingleSelectFilterByFunc<string> = (
		text: string | null,
		item: RdsSingleSelectOptionComponent<string>
	) => {
		if (text === null || text === '') {
			return true;
		} else {
			return item.id.toLowerCase().includes(text.toLowerCase());
		}
	};

	openChange(opened: boolean) {
		this.control.markAsTouched();
	}
}
