import { createSelector } from '@ngrx/store';
import * as fromReducer from './template-form.reducer';
import * as fromManageEvents from '../news.reducer';

export const selectState = createSelector(
  fromManageEvents.selectState,
  fromManageEvents.getTemplateForm
);

export const selectForm = createSelector(
  selectState,
  fromReducer.getForm
);

export const selectFormChecked = createSelector(
  selectState,
  fromReducer.getFormChecked
);

export const selectUserRole = createSelector(
  selectState,
  fromReducer.getUserRole
);

export const selectInitialForm = createSelector(
  selectState,
  fromReducer.getInitialForm
);

export const selectCreateFromTemplate = createSelector(
  selectState,
  fromReducer.getCreateFromTemplate
);

export const selectId = createSelector(
  selectForm,
  fromReducer.getId
);

export const selectLanguages = createSelector(
  selectForm,
  fromReducer.getLanguages
);

export const selectPrimaryLanguageTitle = createSelector(
  selectForm,
  fromReducer.getPrimaryLanguageTitle
);

export const selectPrimaryLanguage = createSelector(
  selectForm,
  fromReducer.getPrimaryLanguage
);

export const selectPrimaryLanguageImage = createSelector(
  selectForm,
  fromReducer.getPrimaryLanguageImage
);

// export const selectStatus = createSelector(
//   selectForm,
//   fromReducer.getStatus
// );

export const selectCreateFormForRequest = createSelector(
  selectForm,
  (form) => ({
    title: form?.title,
    language: form?.language,
    content: form?.content,
    owners: form?.isPredefined? [] : form?.permissions?.owners,
    editors: form?.isPredefined? [] : form?.permissions?.editors,
    viewers: form?.isPredefined? [] : form?.permissions?.viewers,
    isPredefined: form?.isPredefined,
    imagePreview: form?.imagePreview,
    contentType: 'Embedded'
  })
);

export const selectUpdateFormForRequest = createSelector(
  selectForm,
  selectInitialForm,
  (form, initialForm) => {
    const contentDelete = initialForm?.content?.reduce((acc, item) => {
      if (form?.content?.findIndex(c => c.language === item.language) < 0) {
        acc.push(item.language)
      }
      return acc
    }, []);

    const contentCreate = form?.content?.reduce((acc, item) => {
      if (initialForm?.content.findIndex(c => c.language === item.language) < 0) {
        acc.push(item)
      }
      return acc
    }, []);

    const contentUpdate = form?.content?.reduce((acc, item) => {
      const initialContent = initialForm?.content?.find(c => c.language === item.language);

      if (!!initialContent && JSON.stringify(initialContent) !== JSON.stringify(item)) {
        acc.push(item)
      }
      return acc
    }, []);

    return {
      contentDelete,
      contentUpdate,
      contentCreate,
      title: form?.title,
      language: form?.language,
      owners: form?.isPredefined? [] : form?.permissions?.owners,
      editors: form?.isPredefined? [] : form?.permissions?.editors,
      viewers: form?.isPredefined? [] : form?.permissions?.viewers,
      isPredefined: form?.isPredefined,
      imagePreview: form?.imagePreview,
      contentType: 'Embedded',
      updateMetaData: true
    }
  }
);

export const selectGeneralForm = createSelector(
  selectForm,
  (form) => ({
    id: form?.id,
    content: form?.content.filter(c => c.language === form.language),
    title: form?.title,
    language: form?.language,
  })
);

export const selectContentForm = createSelector(
  selectForm,
  (form) => ({
    content: form?.content,
  })
);


export const selectPermissionsForm = createSelector(
  selectForm,
  (form) => ({
    permissions: form.permissions,
    isPredefined: form.isPredefined
  })
);

export const selectGeneralStepStatus = createSelector(
  selectState,
  fromReducer.getGeneralStepStatus
);

export const selectGeneralFormStatus = createSelector(
  selectState,
  fromReducer.getGeneralFormStatus
);

export const selectContentStepStatus = createSelector(
  selectState,
  fromReducer.getContentStepStatus
);

export const selectContentFormStatus = createSelector(
  selectState,
  fromReducer.getContentFormStatus
);

export const selectPermissionsStepStatus = createSelector(
  selectState,
  fromReducer.getPermissionsStepStatus
);

export const selectPermissionsFormStatus = createSelector(
  selectState,
  fromReducer.getPermissionsFormStatus
);

export const selectChangesMade = createSelector(
  selectForm,
  selectInitialForm,
  (form, initialForm) => JSON.stringify(form) !== JSON.stringify(initialForm)
)

export const selectIsUploadInProgress = createSelector(
  selectState,
  fromReducer.getIsUploadInProgress
);

export const areFormsValid = createSelector(
  selectGeneralFormStatus,
  selectContentFormStatus,
  selectPermissionsFormStatus,
  (general, content, permissions) => {
    const areFormsValid = general === 'VALID' && content === 'VALID' && permissions === 'VALID'
    return areFormsValid
  }
);

export const canSaveTemplate = createSelector(
  areFormsValid,
  selectChangesMade,
  selectIsUploadInProgress,
  (formsValid, changesMade, isUploadInProgress) => {
    return formsValid && changesMade && !isUploadInProgress
  }
);

export const disableSaveTemplateTooltip = createSelector(
  areFormsValid,
  selectChangesMade,
  selectIsUploadInProgress,
  (formsValid, changesMade, isUploadInProgress) => {
    if (!changesMade) {
      return `No changes made`
    }
    if (!formsValid) {
      return 'Fill mandatory fields to save template'
    }
    if (isUploadInProgress) {
      return `Upload in progress`
    }
    return ''
  }
)

export const selectIsTouched = createSelector(
  selectState,
  fromReducer.getTouched
);

export const selectAllSuperAdmins = createSelector(
  selectState,
  fromReducer.getAllSuperAdmins
  );
