<rh-card *ngIf="result">
  <ng-container body-title>
    <p class="ui-label-l-bold text-low-contrast">
      <span class="cursor-pointer" (click)="openContent()">{{result.title}}</span>
    </p>
  </ng-container>
  <ng-container body-description>
    <p class="ui-component-text-m text-low-contrast">
      {{result.description}}
    </p>
  </ng-container>
  <ng-container footer>
    <p class="ui-component-text-s text-low-contrast">
      <a [href]="result.url">{{result.displayUrl}}</a>
    </p>
    <rds-icon *ngIf="!isInQuickLinks" class="result__button" icon="add_circle" namespace="outlined"
              (click)="addQuicklink()"></rds-icon>
    <rds-icon *ngIf="isInQuickLinks" class="result__button result__button--inactive" icon="checkmark_circle"
              namespace="filled"></rds-icon>
  </ng-container>
</rh-card>
