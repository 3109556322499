import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import * as fromCatalogs from '../../store/catalogs';
import {select, Store} from '@ngrx/store';
import { Observable } from 'rxjs';
import { UntypedFormBuilder } from '@angular/forms';
import { Catalog } from '../../models/Catalog';
import { SubSink } from 'subsink';

@Component({
  selector: 'rh-catalogs-in-category',
  templateUrl: './catalogs-in-category.container.html',
  styleUrls: ['./catalogs-in-category.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogsInCategoryContainer implements OnInit {
  private subs = new SubSink();

  @Input() category: {name: string, categoryId: number | string};

  catalogs$: Observable<Array<Catalog>>;


  addQL(catalogId) {
    this.store$.dispatch(fromCatalogs.addQuickLinkRequest({catalogId}));
  }

  ngOnInit(): void {
    this.catalogs$ = this.store$.pipe(select(fromCatalogs.selectCatalogsByCategoryId(this.category.categoryId)));
  }
  constructor(
    private store$: Store<fromCatalogs.State>,
    private fb: UntypedFormBuilder
  ) {
  }

}
