import {Injectable} from '@angular/core';

import {EndpointsService} from '@app/core/services/endpoints/endpoints.service';
import {ApiService} from '@app/core/services/api/api.service';
import {Observable} from 'rxjs';
import {GetContentItemsResponse} from '@core/models';
import {map} from 'rxjs/operators';

@Injectable()
export class NewsFeedService {

  public findContentItemsByChannelId(
    channelId: string,
    index = 0,
    size = 4,
    language = 'en'
  ): Observable<GetContentItemsResponse> {
    const url = this.endpoints.ENDPOINT.CONTENT.GET;
    const params = { channelId, language, pageIndex: index, pageSize: size };

    return this.http
      .get(this.endpoints.addParams(url, params)).pipe(
        map(({data, pageSize, pageIndex, ...response}) => ({
          data,
          ...response,
          pageSize: +pageSize,
          pageIndex: +pageIndex,
        })),
      );
  }

  getHeroSection(
    channelId: string,
    index = 0,
    size = 4,
    language = 'en') {
    const url = this.endpoints.ENDPOINT.CONTENT.HERO_SECTION;
    const params = { channelId, language, pageIndex: index, pageSize: size }
    return this.http
      .get(this.endpoints.addParams(url, params)).pipe(
        map(({data, pageSize, pageIndex, ...response}) => ({
          data: data.map(news => {
						return {...news,
							channelId};
					}),
          ...response,
          pageSize: +pageSize,
          pageIndex: +pageIndex,
        })),
      );
  }
  
  getHeroSectionLegacyEndpoint(
    channelId: string,
    index = 0,
    size = 4,
    language = 'en') {
    const url = this.endpoints.ENDPOINT.CONTENT.GET;
    const params = { channelId, language, pageIndex: index, pageSize: size }
    return this.http
      .get(this.endpoints.addParams(url, params)).pipe(
        map(({data, pageSize, pageIndex, ...response}) => ({
          data: data.map(news => {
						return {...news,
							channelId};
					}),
          ...response,
          pageSize: +pageSize,
          pageIndex: +pageIndex,
        })),
      );
  }


  constructor(
    private http: ApiService,
    private endpoints: EndpointsService,
  ) {}

}
