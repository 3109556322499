import { Injectable, OnDestroy } from '@angular/core';
import { SortDirection } from '@rds/angular-components';

import { Observable, BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';


import { UserLogin, UserListRequest, PageInformation, PagedData, BaseListRequest, ApiKey } from '@app/core/models/newsboard';
import { Pagination } from '@app/core/models';
import { AdminTableFilters } from '@app/shared/filters/filters-model';
import { ApiClient } from '@app/core/services/api-newsboard/api-client.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService implements OnDestroy {
  private _users: BehaviorSubject<UserLogin[]> = new BehaviorSubject([]);
  private _keys: BehaviorSubject<ApiKey[]> = new BehaviorSubject([]);

  private _userLoading = new ReplaySubject<boolean>(1);
  private _keysLoading = new ReplaySubject<boolean>(1);

  get users(): Observable<UserLogin[]> {
    return this._users.asObservable();
  }

  get keys(): Observable<ApiKey[]> {
    return this._keys.asObservable();
  }

  get isLoading(): Observable<boolean> {
    return combineLatest([this._userLoading.asObservable(), this._keysLoading.asObservable()]).pipe(
      map((result) => result[0] || result[1])
    );
  }

  constructor(private client: ApiClient) {
    // initalize both loading observables (otherwise combineLatest will not output anything)
    this._userLoading.next(false);
    this._keysLoading.next(false);
  }

  ngOnDestroy() {
    this._userLoading.complete();
    this._keysLoading.complete();
    this._users.complete();
    this._keys.complete();
  }

  load(request: UserListRequest): Observable<PageInformation> {
    this._users.next(null);
    this._userLoading.next(true);
    return this.client.post('/admin/users', request).pipe(
      tap((resp: PagedData<UserLogin>) => {
        this._users.next(resp.data);
        this._userLoading.next(false);
      }),
      map((val) => {
        delete val.data;
        return val;
      })
    );
  }

  getAdmins({pageIndex, pageSize, sort, filters}: {
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
    filters: AdminTableFilters
  }): Observable<{data: Array<UserLogin>, pagination: Pagination}> {
    const request: UserListRequest = {
      count: pageSize,
      offset: pageSize * pageIndex,
      order: sort.direction,
      sortBy: sort.active,
      roles: filters.roles.value
    }
    return this.client.post('/admin/users', request).pipe(
      map((res: PagedData<UserLogin>): {data: Array<UserLogin>, pagination: Pagination} => {
        return ({ 
          data: res.data, 
          pagination: {
            isFirst: res.offset === 0,
            isLast: ((res.offset / res.perPage) + 1) * res.perPage >= res.total,
            pageCount: Math.ceil(res.total / res.perPage),
            pageIndex: res.offset / res.perPage,
            pageSize: res.perPage,
            totalCount: res.total,
          }
        }) ;
      })
    );
  }

  loadKeys(request: BaseListRequest): Observable<PageInformation> {
    this._keys.next(null);
    this._keysLoading.next(true);
    return this.client
      .get(`/admin/apikeys/${request.offset}/${request.count}/${request.sortBy}/${request.sortReverse}`)
      .pipe(
        tap((resp: PagedData<ApiKey>) => {
          this._keys.next(resp.data);
          this._keysLoading.next(false);
        }),
        map((val) => {
          delete val.data;
          return val;
        })
      );
  }

  getApiKeys({pageIndex, pageSize, sort}: {
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
  }): Observable<{data: Array<ApiKey>, pagination: Pagination}> {
    const request: BaseListRequest = {
      count: pageSize,
      offset: pageSize * pageIndex,
      sortReverse: sort.direction === 'desc',
      sortBy: sort.active,
    }
    return this.client.get(`/admin/apikeys/${request.offset}/${request.count}/${request.sortBy}/${request.sortReverse}`).pipe(
      map((res: PagedData<ApiKey>): {data: Array<ApiKey>, pagination: Pagination} => {
        return ({ 
          data: res.data, 
          pagination: {
            isFirst: res.offset === 0,
            isLast: ((res.offset / res.perPage) + 1) * res.perPage >= res.total,
            pageCount: Math.ceil(res.total / res.perPage),
            pageIndex: res.offset / res.perPage,
            pageSize: res.perPage,
            totalCount: res.total,
          }
        }) ;
      })
    );
  }

  add(identifier: string): Observable<any> {
    return this.client.put('admin/add', { identifier });
  }

  remove(identifier: string): Observable<any> {
    return this.client.put('admin/remove', { identifier });
  }

  promote(identifier: string): Observable<any> {
    return this.client.put('admin/promote', { identifier });
  }

  addApiKey(identifier: string): Observable<any> {
    return this.client.post('admin/apikey', { identifier });
  }

  removeApiKey(id: number): Observable<any> {
    return this.client.delete(`admin/apikey/${id}`);
  }
}

export { UserLogin, UserListRequest, PageInformation };
