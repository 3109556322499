import { ChannelsSetRequest } from '@app/core/models';
import { createSelector } from '@ngrx/store';
import * as fromManageSets from '../manage-sets.reducer';
import * as fromReducer from './channels-sets-form.reducer';
import { flattenFilters } from '@app/shared/filters/filters-model';

export const selectState = createSelector(
  fromManageSets.selectState,
  fromManageSets.getChannelsSetsForm
);

export const selectForm = createSelector(
  selectState,
  fromReducer.getForm
);

export const selectFormForRequest = createSelector(
  selectForm,
  (form): ChannelsSetRequest => {
    return {
      id: form?.id,
      name: form?.name,
      locations: form?.audience.locations,
      departments: form?.audience.departments,
      functions: form?.audience.functions,
      channels: form?.channels.selected.map(c => c.id),
    }
  }
);

export const selectInitialForm = createSelector(
  selectState,
  fromReducer.getInitialForm
);

export const selectId = createSelector(
  selectForm,
  fromReducer.getId
);

export const selectIsTouched = createSelector(
  selectState,
  fromReducer.getTouched
);

export const selectIsNameUnique = createSelector(
  selectState,
  fromReducer.getIsNameUnique
);

export const selectChannelsFilters = createSelector(
  selectForm,
  fromReducer.getChannelsFilters
);


export const selectChannelsFiltersForRequest = createSelector(
  selectChannelsFilters,
  (filters) => ({
    name: filters.name,
    ...flattenFilters(filters.advancedFilters)
  })
)

export const selectSelectedChannels = createSelector(
  selectForm,
  fromReducer.getSelectedChannels
);

export const selectSelectedChannelsIds = createSelector(
  selectSelectedChannels,
  (selected) => selected.map(c => c.id)
)

export const selectFilteredChannels = createSelector(
  selectState,
  fromReducer.getFilteredChannels
);

export const selectFilteredChannelsCount = createSelector(
  selectFilteredChannels,
  (filtered) => filtered.length
);

export const selectFilteredChannelsLoading = createSelector(
  selectState,
  fromReducer.getFilteredChannelsLoading
);

export const selectAudienceManagerInfo = createSelector(
  selectState,
  fromReducer.getAudienceManagerInfo
);