import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[rhClickOutside]'
})
export class ClickOutsideDirective {

  @Output()
  public rhClickOutside = new EventEmitter<MouseEvent>();

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const overlayContainer = document.getElementsByClassName('cdk-overlay-container');
    const clickedInside = 
      this._elementRef.nativeElement.contains(targetElement) ||
      this.clickedInside(event, this._elementRef.nativeElement) ||
      overlayContainer[0]?.contains(targetElement);
    if (!clickedInside) {
      this.rhClickOutside.emit(event);
    }
  }

  clickedInside(event, element)   {
    var rect = element.getBoundingClientRect();
    var x = event.clientX;
    if (x < rect.left || x >= rect.right) return false;
    var y = event.clientY;
    if (y < rect.top || y >= rect.bottom) return false;
    return true;
}

  constructor(private _elementRef: ElementRef) {}
}

