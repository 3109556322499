<h2 rds-dialog-header>Enable commenting?</h2>
<div rds-dialog-content>
    <p class="mb-7">
        The commenting functionality will be enabled to Roche Home users. 
    </p>
    <p class="mb-5">
        Previously added comments will be visible to Roche Home users. Please be aware that comments hidden individually will not be affected.
    </p>
</div>
<div rds-dialog-actions-container align="end">
    <button
        rds-secondary-button
        [rds-dialog-close]="null"
        size="m"
    >Cancel</button>
    <button
        rds-primary-button
        [rds-dialog-close]="true"
        size="m"
    >Enable & reveal comments</button>
</div>