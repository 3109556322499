import { on, createReducer, Action } from '@ngrx/store';

import * as fromActions from '@home/store/channels-section/channels-section.actions';
import { Status, STATUS_LOADING, STATUS_SUCCESS, STATUS_FAILURE } from '@core/models/status.model';
import { Dictionary } from '@ngrx/entity';
import { ContentItem } from '@app/core/models/content-item.model';
import { Pagination } from '@app/core/models/pagination.model';

export const feature = 'channelsSection';

interface ChannelSection extends Status, Pagination {
  itemIds: string[];
  carouselAnimating: boolean;
  carouselAnimDuration: number;
  carouselDisplaySize: number;
  carouselIncrementSize: number;
  carouselStartPoint: number;
  elementsPerSlide: number;
  actualSlide: number;
}
export interface State extends Dictionary<ChannelSection> {}

export const initialState: State = {};

export const channelsSectionReducer = createReducer(
  initialState,
  on(fromActions.initChannelSectionByChanelId, (state, {id, carouselDisplaySize, elementsPerSlide}) => ({
    ...state,
    [id]: {
      ...Pagination,
      ...state[id],
      pageSize: 12,
      itemIds: state[id] && state[id].itemIds ? state[id].itemIds : [],
      carouselAnimating: false,
      carouselAnimDuration: 2.5,
      carouselDisplaySize,
      carouselIncrementSize: carouselDisplaySize,
      carouselStartPoint: state[id] && state[id].carouselStartPoint ? state[id].carouselStartPoint : 0,
      elementsPerSlide,
      actualSlide: state[id] && state[id].actualSlide ? state[id].actualSlide : 0,
    }
  })),
  on(fromActions.changeCarouselStart, (state, {id, carouselIncrementSize}) => {
    const carouselStartPoint = Math.max(0, state[id].carouselStartPoint + carouselIncrementSize);

    return {
      ...state,
      [id]: {
        ...state[id],
        carouselStartPoint,
        carouselIncrementSize: (carouselStartPoint === 0) ? -state[id].carouselStartPoint : carouselIncrementSize
      }
    };
  }),
  on(fromActions.setCarouselDisplaySize, (state, {id, carouselDisplaySize}) => ({
    ...state,
    [id]: {
      ...state[id],
      carouselDisplaySize,
      carouselIncrementSize: carouselDisplaySize - state[id].carouselDisplaySize
    }
  })),
  on(fromActions.carouselAnimationStarted, (state, {id}) => ({
    ...state,
    [id]: {
      ...state[id],
      carouselAnimating: true,
    }
  })),
  on(fromActions.carouselAnimationFinished, (state, {id}) => ({
    ...state,
    [id]: {
      ...state[id],
      carouselAnimating: false,
    }
  })),
  on(fromActions.loadMoreItems, (state, {id}) => ({
      ...state,
      [id]: {
        ...state[id],
        pageIndex: state[id].pageIndex + 1,
        ...STATUS_LOADING,
      }
    })),
  on(fromActions.showNextSlide, (state, {id}) => ({
    ...state,
    [id]: {
      ...state[id],
      actualSlide: state[id].actualSlide + 1,
    }
  })),
  on(fromActions.showPrevSlide, (state, {id}) => ({
    ...state,
    [id]: {
      ...state[id],
      actualSlide: state[id].actualSlide - 1,
    }
  })),
  on(fromActions.getContentByChannelIdRequest, (state, {id}) => ({
      ...state,
      [id]: {
        ...state[id],
        ...STATUS_LOADING,
      }
    })),
  on(fromActions.getContentByChannelIdSuccess, (state, {id, contentItems, pagination}) => {
    const itemIds = [...new Set([...state[id].itemIds, ...contentItems.map(item => item.id)])];
    return {
      ...state,
      [id]: {
        ...state[id],
        itemIds,
        ...pagination,
        ...STATUS_SUCCESS,
      }
    };
  }),
  on(fromActions.getContentByChannelIdError, (state, {id, error}) => ({
    ...state,
    [id]: {
      ...state[id],
      ...STATUS_FAILURE,
      error
    }
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return  channelsSectionReducer(state, action);
}

export const getChannelSection = (state: State, id: string) => state[id];
export const getItemIds = (state: ChannelSection) => state.itemIds;
export const getItemIdsCount = (state: ChannelSection) => state.itemIds.length;

export const getCarouselAnimating = (state: ChannelSection) => state.carouselAnimating;
export const getCarouselAnimDuration = (state: ChannelSection) => state.carouselAnimDuration;
export const getCarouselDisplaySize = (state: ChannelSection) => state.carouselDisplaySize;
export const getCarouselIncrementSize = (state: ChannelSection) => state.carouselIncrementSize;
export const getCarouselStart = (state: ChannelSection) => state.carouselStartPoint;
export const getCurrentIds = (itemIds: string[], carouselDisplaySize: number, carouselStartPoint: number) => {
  return { currIds: itemIds.slice(carouselStartPoint, carouselStartPoint + carouselDisplaySize) };
};

export const getCurrentContent = (itemIds, entities: Dictionary<ContentItem>) => itemIds.map(id => entities[id]);

export const getLoadParams
 = ({currIds}, itemIds: string[], carouselDisplaySize: number, carouselStartPoint: number,  isFirst: boolean, isLast: boolean) =>
 ({currIds, itemIds, carouselStartPoint, carouselDisplaySize, isFirst, isLast});

export const getNotAsked = (state: ChannelSection) => state.notAsked;
export const getLoading = (state: ChannelSection) => state.loading;
export const getSuccess = (state: ChannelSection) => state.success;
export const getFailure = (state: ChannelSection) => state.failure;

export const getPageSize = (state: ChannelSection) => state.pageSize;
export const getPageIndex = (state: ChannelSection) => state.pageIndex;
export const getIsLast = (state: ChannelSection) => state.isLast;
export const getIsFirst = (state: ChannelSection) => state.isFirst;

export const getPageIndexSize = (pageIndex: number, pageSize: number) => ({pageIndex, pageSize});
export const getDataForLoadCondition
 = (pageIndex: number, pageSize: number, count: number, isLast: boolean) => ({pageIndex, pageSize, count, isLast});

export const getElementsPerSlide = (state: ChannelSection) => state.elementsPerSlide;
export const getActualSlideIndex = (state: ChannelSection) => state.actualSlide;
