import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EndpointsService } from '@core/services/endpoints/endpoints.service';
import { ApiService } from '@core/services/api/api.service';
import { Channel } from '@app/core/models/channel.model';
import { UserProfile, UserDefaultTaxonomies } from '@app/core/models';
import { RoleNames } from '@app/core/enums/roles.enum';

@Injectable()
export class UserService {

  public getUserInfo(): Observable<UserProfile> {
    const url = this.endpoints.ENDPOINT.USER_PROFILE.GET;

    return this.http
      .get(url)
      .pipe(
        map((user: UserProfile) => ({
          login: user.login,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          newsLanguage: user.newsLanguage,
          phone: user.phone,
          wasWelcomePageDisplayed: user.wasWelcomePageDisplayed,
          wasUserSettingsDisplayed: user.wasUserSettingsDisplayed,
          wasQuickLinksPageDisplayed: user.wasQuickLinksPageDisplayed,
          wasCatalogPageDisplayed: user.wasCatalogPageDisplayed,
          wasSearchPageDisplayed: user.wasSearchPageDisplayed,
          wasMyContentPageDisplayed: user.wasMyContentPageDisplayed,
          wasLayoutSettingsPageDisplayed: user.wasLayoutSettingsPageDisplayed,
          wasMenuDisplayed: user.wasMenuDisplayed,
          photoUrl: user.photoUrl
        })),
        catchError((fault) => throwError(`Cannot get user details. ${fault}`))
      );
  }

  public getUserChannels(): Observable<Channel[]> {
    const url = this.endpoints.ENDPOINT.CHANNEL.GET;

    return this.http
      .get(url);
  }

  public getUserRoles(login: string): Observable<Array<RoleNames>> {
    const url = this.endpoints.replaceUrlTokens(this.endpoints.ENDPOINT.USER_PROFILE.ROLES.GET, { login });
    return this.http
      .get(url).pipe(
        map(data => {
          return data.roles;
        })
      );
  }

  public setUserWasLogged(): Observable<void> {
    const url = this.endpoints.ENDPOINT.USER_PROFILE.WAS_LOGGED;

    return this.http
      .post(url, {});
  }

  public setSettingsVisited(): Observable<void> {
    const url = this.endpoints.ENDPOINT.USER_PROFILE.SETTINGS_VISITED;

    return this.http
      .post(url, {});
  }

  public setQuicklinksVisited(): Observable<void> {
    const url = this.endpoints.ENDPOINT.USER_PROFILE.QUICKLINKS_VISITED;

    return this.http
      .post(url, {});
  }

  public setCatalogVisited(): Observable<void> {
    const url = this.endpoints.ENDPOINT.USER_PROFILE.CATALOG_VISITED;

    return this.http
      .post(url, {});
  }

  public setSearchVisited(): Observable<void> {
    const url = this.endpoints.ENDPOINT.USER_PROFILE.SEARCH_VISITED;

    return this.http
      .post(url, {});
  }

  public setMyContentVisited(): Observable<void> {
    const url = this.endpoints.ENDPOINT.USER_PROFILE.MY_CONTENT_VISITED;

    return this.http
      .post(url, {});
  }

  public setLayoutSettingsVisited(): Observable<void> {
    const url = this.endpoints.ENDPOINT.USER_PROFILE.LAYOUT_SETTINGS_VISITED;

    return this.http
      .post(url, {});
  }

  public setMenuVisited(): Observable<void> {
    const url = this.endpoints.ENDPOINT.USER_PROFILE.MENU_VISITED;

    return this.http
      .post(url, {});
  }

  public getUserDefaultTaxonomies(login: string): Observable<UserDefaultTaxonomies> {
    const url = this.endpoints.replaceUrlTokens(
      this.endpoints.ENDPOINT.USER_PROFILE.DEFAULT_TAXONOMIES.GET, { login }
    );
    return this.http
    .get(url);
  }

  constructor(private http: ApiService, private endpoints: EndpointsService) {}
}
