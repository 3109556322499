
<div *ngIf="displayData && displayData" class="mb-8">
  <div class="row">
    <div class="col">
      <p class="ui-label-m-bold">
        {{title}}
      </p>
    </div>
    <div class="col-auto">
      <p class="ui-label-m-bold">
        {{allData.length}} Quicklinks
      </p>
    </div>
    <div  *ngIf="!isOpen" class="col-auto">
      <div class="rds-component-group">
        <button rds-text-button (click)="setPage(-1)">
          &nbsp;<rds-icon color="primary" icon="chevron_left"></rds-icon>
        </button>
        <p class="ui-label-m-bold c-600-roche-blue">
          {{page}} / {{totalPage}}
        </p>
        <button rds-text-button (click)="setPage(-1)">
          <rds-icon color="primary" icon="chevron_right"></rds-icon>&nbsp;
        </button>
      </div>
    </div>
    <div class="col-auto">
      <button rds-text-button (click)="toggleElements($event)"> {{isOpen ? 'Hide All': 'View All'}}</button>
    </div>
  </div>
  <div class="ql-paginator__body">
    <rh-single-catalog-item *ngFor="let link of displayData; index as i" [catalog]="link" [actionsPosition]="(isDeleteModeActive$ | async) === false? 'top' : 'center'"  [actionsAlwaysVisible]="(isDeleteModeActive$ | async) === true">
      <ng-container actions *ngIf="(isDeleteModeActive$ | async) === true">
        <rds-checkbox
        (changed)="checkboxClicked($event, link)"
        [checked]="shouldBeDeleted(link) | async">
      </rds-checkbox>
      </ng-container>
      <ng-container actions *ngIf="(isDeleteModeActive$ | async) === false">
        <button rds-icon-button class="mx-2" (click)="link.isFavourite? removeFromTopQL(link) : addToTopQL(link)">
          <rds-icon icon="star" [namespace]="link.isFavourite? 'filled' : 'outlined'"></rds-icon>
        </button>
        <button rds-icon-button class="mx-2" (click)="renameQL(link)">
          <rds-icon icon="edit" namespace="outlined"></rds-icon>
        </button>
        <button rds-icon-button class="mx-2" (click)="deleteQL(link)">
          <rds-icon icon="delete" namespace="outlined"></rds-icon>
        </button>
      </ng-container>
    </rh-single-catalog-item>
  </div>
</div>
