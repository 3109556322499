import {Component, EventEmitter, Host, Input, OnInit, Optional, Output, SkipSelf, ViewChild} from '@angular/core';
import {ControlContainer, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {SimpleUser} from '@app/core/models/newsboard';
import {ConfirmDialogComponent} from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import {CustomValidators} from '@app/shared/form-controls/validators/validator.function';
import {RdsDialogService, RdsMenuTriggerDirective} from '@rds/angular-components';
import {debounceTime, distinctUntilChanged} from 'rxjs';


@Component({
  selector: 'rh-email-picker',
  templateUrl: './email-picker.component.html',
  styleUrls: ['./email-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: EmailPickerComponent
    }
  ]
})
export class EmailPickerComponent implements OnInit {
  @ViewChild("trigger", {static: false}) trigger: RdsMenuTriggerDirective;

  selectedEmails: string = '';

  invalidValue: string;

  get selectedEmailsArray() {
    return this.selectedEmails ? this.selectedEmails.split(',').filter(e => e !== '') : [];
  }

  onChange = (users) => {
  };

  onTouched = () => {
  };

  touched = false;

  disabled = false;

  hostWidth: number;

  writeValue(value: string) {
    this.selectedEmails = value;
    this.form.controls.search.updateValueAndValidity();
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() selected: EventEmitter<string> = new EventEmitter<string>();
  @Output() removed: EventEmitter<string> = new EventEmitter<string>();

  _autocomplete: { suggestions: Array<SimpleUser>, loading: boolean };
  get autocomplete(): { suggestions: Array<SimpleUser>, loading: boolean } {
    return this._autocomplete;
  }

  @Input() set autocomplete(value: { suggestions: Array<SimpleUser>, loading: boolean }) {
    this._autocomplete = {
      suggestions: value.suggestions.filter(s => !this.restrictedEmails.some(e => e === s.email)),
      loading: value.loading
    };
    if (!!this.trigger) {
      this._autocomplete.suggestions.length > 0 && !this._autocomplete.loading ? this.trigger.openMenu() : this.trigger.closeMenu();
    }
  }

  @Input() restrictedEmails: Array<string> = [];
  @Input() formControl!: FormControl;
  @Input() formControlName!: string;

  get control() {
    return this.formControl || this.controlContainer.control?.get(this.formControlName);
  }

  get required() {
    return this.control.hasValidator(Validators.required)
  }

  form: FormGroup = new FormGroup({
    search: new FormControl('')
  });

  @Input() labelFn: (args: any) => string;
  @Input() notRemovable: Array<string> = [];
  @Input() multi: boolean = false;

  canRemove(email) {
    return !this.notRemovable.includes(email);
  }

  selectedIncludesEmail(email: string) {
    return this.selectedEmailsArray.findIndex(e => e === email) > -1;
  }

  focus() {
    if (this.autocomplete?.suggestions.length > 0 && !this.autocomplete?.loading) {
      this.trigger.openMenu()
    }
  }

  blur() {
    this.form.controls.search.updateValueAndValidity();
  }

  checkEmails(value) {
    const emails: string[] = value.split(',').map(e => e.trim());
    const invalidEmails = emails.filter((email) => Validators.email(new FormControl(email)) && email.length);
    const validEmails = emails.filter((email) => !Validators.email(new FormControl(email)) && email.length);
    validEmails.map(email => !this.selectedEmailsArray.includes(email) ? this.select(email) : {});
    if (invalidEmails.length && !validEmails.length) {
      this.control.setErrors({
        ...this.control.errors,
        invalidEmails: true
      });
      this.invalidValue = value;
    }
    if (invalidEmails.length && validEmails.length) {
      this.dialogService.open(ConfirmDialogComponent, {
        size: 'm',
        data: {
          ids: [],
          title: `Invalid email addresses`,
          messages: [
            'Following email addresses are invalid and will be not added to the list of recipients:',
            `${invalidEmails.length > 1 ? invalidEmails.join(', ') : invalidEmails[0]}`
          ],
          hideCancelButton: true,
          confirmButtonLabel: `Close`,
          confirmButtonType: 'primary'
        }
      })
    }
    this.markAsTouched();
  }

  select(email: string) {
    if (this.multi) {
      let newValue = [...this.selectedEmailsArray];
      newValue.push(email);
      this.selectedEmails = newValue.join(',')
    } else {
      this.selectedEmails = email;
    }
    this.form.controls.search.setValue('');
    this.selected.emit();
    this.onChange(this.selectedEmails);
    this.markAsTouched();
  }

  remove(index: number) {
    this.selectedEmails = this.selectedEmailsArray.filter((e, i) => i !== index).join(',');
    this.form.controls.search.setValue('');
    this.removed.emit();
    this.onChange(this.selectedEmails);
  }

  ngOnInit(): void {
    this.form.controls.search.setValidators(CustomValidators.isMasterControlValid(this.control));
    this.form.controls.search.updateValueAndValidity();

    const originalMarkAsTouched = this.control.markAsTouched;
    const that = this;
    this.control.markAsTouched = function () {
      originalMarkAsTouched.apply(this, arguments);
      that.form.controls.search.markAsTouched();
      that.form.controls.search.updateValueAndValidity();
    }

    this.control.statusChanges.pipe(debounceTime(100)).subscribe(status => {
      this.form.controls.search.updateValueAndValidity();
    });
    this.form.controls.search.valueChanges.pipe(distinctUntilChanged()).subscribe(s => {
        this.search.emit(s);
        if (s !== this.invalidValue) {
          if (this.control.hasError('invalidEmails')) {
            this.control.hasError('emailDuplicate') ? this.control.setErrors({emailDuplicate: true}) : this.control.setErrors(null)
          }
        }
      }
    );
  }

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
    private dialogService: RdsDialogService
  ) {
  }

}
