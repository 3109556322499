import { UserRole, UserRoleResponse } from '@app/core/models';
import { SimpleUser } from '@app/core/models/newsboard';
import { createAction, props } from '@ngrx/store';

export const initAddForm = createAction(
  '[ASSIGN ROLE - FORM] Init form',
  props<{user: SimpleUser; userRole?: Partial<UserRoleResponse>}>()
);

export const getUserRoleDetailsRequest = createAction(
  '[ASSIGN ROLE - FORM] Get user role details request',
  props<{id: number}>()
);

export const getUserRoleDetailsSuccess = createAction(
  '[ASSIGN ROLE - FORM] Get user role details success',
  props<{userRole: Partial<UserRole>}>()
);

export const getUserRoleDetailsFailure = createAction(
  '[ASSIGN ROLE - FORM] Get user role details failure',
  props<{error: string}>()
);

export const setFormValue = createAction(
  '[ASSIGN ROLE - FORM] Set form value',
  props<{form: Partial<UserRole>}>()
);
export const setFormTouched = createAction(
  '[ASSIGN ROLE - FORM] Set form touched',
  props<{touched: boolean}>()
);

export const assignUserRoleRequest = createAction(
  '[ASSIGN ROLE - FORM] Update user role request',
  props<{login: string}>()
);

export const assignUserRoleSuccess = createAction(
  '[ASSIGN ROLE - FORM] Update user role success',
);

export const assignUserRoleFailure = createAction(
  '[ASSIGN ROLE - FORM] Update user role failure',
  props<{error: string}>()
);

export const clearAll = createAction(
  '[ASSIGN ROLE - FORM] Clear form'
);

export const getUserRolesRequest = createAction(
  '[ASSIGN ROLE - FORM] Get user roles request',
  props<{user: SimpleUser}>()
);

export const getUserRolesSuccess = createAction(
  '[ASSIGN ROLE - FORM] Get user roles success',
  props<{user: SimpleUser; userRole: UserRoleResponse}>()
);

export const getUserRolesFailure = createAction(
  '[ASSIGN ROLE - FORM] Get user roles failure',
  props<{error: string}>()
);

export const deleteUserRoleRequest = createAction(
  '[ASSIGN ROLE - FORM] Delete User role Request',
  props<{login: string}>()
);

export const deleteUserRoleSuccess = createAction(
  '[ASSIGN ROLE - FORM] Delete channels Success',
);

export const deleteUserRoleFailure = createAction(
  '[ASSIGN ROLE - FORM] Delete channels Failure',
  props<{ error: any }>()
);