import { Injectable } from '@angular/core';
import { NewsletterService } from '@app/core/services/newsletter.service';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import * as fromActions from './newsletter-blocks.actions';
import * as fromBlockSelectors from './newsletter-blocks.selectors';
import { RdsDialogService } from '@rds/angular-components';
import { of } from 'rxjs';
import { createGuid } from '@app/shared/utils/guid';
import {
	AnchorBlockProperties,
	BLOCK_MARKUPS,
	Block,
	BlockTypes,
	ChannelBlockProperties,
	DEFAULT_BLOCK_SETTINGS,
	EmptyBlockProperties,
	EventBlockProperties,
	NewsBlockProperties,
	TableOfContentBlockProperties,
} from '@app/newsletter-new/models/block-type.enum';
import { createSection } from '@app/newsletter-new/utils/helpers';
import {
	SelectNewsDialogComponent,
	SelectNewsDialogData,
} from '@app/newsletter-new/dialogs/select-news-dialog/select-news-dialog.component';
import {
	SelectEventsDialogComponent,
	SelectEventsDialogData,
} from '@app/newsletter-new/dialogs/select-events-dialog/select-events-dialog.component';
import { SelectChannelDialogData } from '@app/newsletter-new/dialogs/select-channel-dialog/select-channel-dialog.component';
import {
	TableOfContentDialogComponent,
	TableOfContentDialogData,
} from '@app/newsletter-new/dialogs/table-of-content-dialog/table-of-content-dialog.component';
import { SelectChannelNewDialogComponent } from '@app/newsletter-new/dialogs/select-channel-new/select-channel-dialog.component';

@Injectable()
export class NewsletterBlocksEffects {
	public createSection$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.createSection),
				map(({ sectionType }) => {
					const section = createSection(sectionType);
					return fromActions.addSection({ section });
				})
			),
		{ dispatch: true }
	);

	public createBlock$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.createBlock),
				map(({ blockType, subsectionGuid, id }) => {
					const block: Block = {
						id: createGuid(),
						type: blockType,
						markup: BLOCK_MARKUPS[blockType],
						settings: DEFAULT_BLOCK_SETTINGS[blockType],
					};
					return fromActions.addBlock({ block, subsectionGuid, id });
				})
			),
		{ dispatch: true }
	);

	public uploadBlockPhotoRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.uploadBlockPhotoRequest),
				switchMap(({ file, blockGuid, link }) =>
					this.newsletterService.uploadFile(file).pipe(
						map(
							(url) => fromActions.uploadBlockPhotoSuccess({ photoUrl: url, photoName: file.name, blockGuid, link }),
							catchError(({ message }) => of(fromActions.uploadBlockPhotoFailure({ message })))
						)
					)
				)
			),
		{ dispatch: true }
	);

	public uploadBlockPhotoSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.uploadBlockPhotoSuccess),
				map(({ photoUrl, photoName, blockGuid, link }) =>
					fromActions.updateBlockSettings({
						blockGuid,
						// tslint:disable-next-line
						settings: {
							link,
							photoUrl,
							photoName
						},
					})
				)
			),
		{ dispatch: true }
	);

	public openEditNewsDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.openEditNewsDialog),
				mergeMap(
					({ blockGuid }) =>
						of({ blockGuid }).pipe(
							withLatestFrom(this.store$.pipe(select(fromBlockSelectors.selectBlockSettings(blockGuid))))
						),
					(action, initialSettings) => initialSettings
				),
				switchMap(([{ blockGuid }, initialSettings]) => {
					const dialog = this.dialogService.open(SelectNewsDialogComponent, {
						size: 'l',
						data: {
							blockType: BlockTypes.NEWS,
							id: null,
							settings: initialSettings,
						} as SelectNewsDialogData,
					});
					return dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((data) => ({ blockGuid, settings: data.settings[0] }))
					);
				}),
				map(({ blockGuid, settings }) =>
					fromActions.updateBlockSettings({
						blockGuid,
						settings,
					})
				)
			),
		{ dispatch: true }
	);

	public openSelectNewsDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.openSelectNewsDialog),
				mergeMap(
					({ blockType, id, subsectionGuid }) =>
						of({ blockType, id, subsectionGuid }).pipe(
							withLatestFrom(this.store$.pipe(select(fromBlockSelectors.selectSubsectionsSiblings(subsectionGuid))))
						),
					(action, subsections) => subsections
				),
				map(([{ blockType, id, subsectionGuid }, subsections]) => {
					const dialog = this.dialogService.open(SelectNewsDialogComponent, {
						size: 'l',
						data: {
							id,
							blockType,
							addNewAsCard: subsections.length > 1,
							settings: {
								...DEFAULT_BLOCK_SETTINGS[blockType],
								card: subsections.length > 1,
							},
						} as SelectNewsDialogData,
					});
					return { dialog, subsections, subsectionGuid };
				}),
				switchMap(({ dialog, subsections, subsectionGuid }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map(({ blockType, settings, insertOption, id }) => ({
							blockType,
							settings,
							insertOption,
							subsectionGuid,
							id,
							subsections,
						}))
					)
				),
				mergeMap(({ blockType, settings, insertOption, subsectionGuid, id, subsections }) => {
					const addNewsActions = [];
					settings.forEach((settings, index) => {
						addNewsActions.push(
							fromActions.addBlock({
								block: {
									id: createGuid(),
									type: blockType,
									settings,
								} as Block<NewsBlockProperties>,
								subsectionGuid: insertOption === 0 ? subsectionGuid : subsections[index % 2],
								id,
							})
						);
					});
					return addNewsActions;
				})
			),
		{ dispatch: true }
	);

	public openEditEventDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.openEditEventDialog),
				mergeMap(
					({ blockGuid }) =>
						of({ blockGuid }).pipe(
							withLatestFrom(this.store$.pipe(select(fromBlockSelectors.selectBlockSettings(blockGuid))))
						),
					(action, initialSettings) => initialSettings
				),
				switchMap(([{ blockGuid }, initialSettings]) => {
					const dialog = this.dialogService.open(SelectEventsDialogComponent, {
						size: 'l',
						data: {
							blockType: BlockTypes.EVENT,
							id: null,
							settings: initialSettings,
						} as SelectEventsDialogData,
					});
					return dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((data) => ({ blockGuid, settings: data.settings[0] }))
					);
				}),
				map(({ blockGuid, settings }) =>
					fromActions.updateBlockSettings({
						blockGuid,
						settings,
					})
				)
			),
		{ dispatch: true }
	);

	public openSelectEventsDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.openSelectEventDialog),
				mergeMap(
					({ blockType, id, subsectionGuid }) =>
						of({ blockType, id, subsectionGuid }).pipe(
							withLatestFrom(this.store$.pipe(select(fromBlockSelectors.selectSubsectionsSiblings(subsectionGuid))))
						),
					(action, subsections) => subsections
				),
				map(([{ blockType, id, subsectionGuid }, subsections]) => {
					const dialog = this.dialogService.open(SelectEventsDialogComponent, {
						size: 'l',
						data: {
							id,
							blockType,
							addNewAsCard: subsections.length > 1,
							settings: {
								...DEFAULT_BLOCK_SETTINGS[blockType],
								card: subsections.length > 1,
							},
						} as SelectEventsDialogData,
					});
					return { dialog, subsections, subsectionGuid };
				}),
				switchMap(({ dialog, subsections, subsectionGuid }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map(({ blockType, settings, insertOption, id }) => ({
							blockType,
							settings,
							insertOption,
							subsectionGuid,
							id,
							subsections,
						}))
					)
				),
				mergeMap(({ blockType, settings, insertOption, subsectionGuid, id, subsections }) => {
					const addEventActions = [];
					settings.reverse().forEach((settings, index) => {
						addEventActions.push(
							fromActions.addBlock({
								block: {
									id: createGuid(),
									type: blockType,
									settings,
								} as Block<EventBlockProperties>,
								subsectionGuid: insertOption === 0 ? subsectionGuid : subsections[index % 2],
								id,
							})
						);
					});
					return addEventActions;
				})
			),
		{ dispatch: true }
	);

	public openEditChannelDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.openEditChannelDialog),
				mergeMap(
					({ blockGuid }) =>
						of({ blockGuid }).pipe(
							withLatestFrom(this.store$.pipe(select(fromBlockSelectors.selectBlockSettings(blockGuid))))
						),
					(action, initialSettings) => initialSettings
				),
				switchMap(([{ blockGuid }, initialSettings]) => {
					const dialog = this.dialogService.open(SelectChannelNewDialogComponent, {
						size: 'xl',
						height: '100vh',
						minHeight: '100vh',
						data: {
							blockType: BlockTypes.CHANNEL,
							id: null,
							settings: initialSettings,
						} as SelectChannelDialogData,
					});
					return dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((data) => ({ blockGuid, settings: data.settings }))
					);
				}),
				map(({ blockGuid, settings }) =>
					fromActions.updateBlockSettings({
						blockGuid,
						settings,
					})
				)
			),
		{ dispatch: true }
	);

	public openSelectChannelDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.openSelectChannelDialog),
				mergeMap(
					({ blockType, id, subsectionGuid }) =>
						of({ blockType, id, subsectionGuid }).pipe(
							withLatestFrom(this.store$.pipe(select(fromBlockSelectors.selectSubsectionsSiblings(subsectionGuid))))
						),
					(action, subsections) => subsections
				),
				map(([{ blockType, id, subsectionGuid }, subsections]) => {
					const dialog = this.dialogService.open(SelectChannelNewDialogComponent, {
						size: 'xl',
						height: '100vh',
						minHeight: '100vh',
						data: {
							id,
							blockType,
							addNewAsCard: subsections.length > 1,
							settings: {
								...DEFAULT_BLOCK_SETTINGS[blockType],
								card: subsections.length > 1,
							},
						} as SelectChannelDialogData,
					});
					return { dialog, subsections, subsectionGuid };
				}),
				switchMap(({ dialog, subsections, subsectionGuid }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map(({ blockType, sectionTitle, settings, id }) => ({
							blockType,
							sectionTitle,
							subsectionGuid,
							id,
							subsections,
							settings,
						}))
					)
				),
				mergeMap(({ blockType, sectionTitle, subsectionGuid, id, settings}) => {
					const sectionTitleAction = sectionTitle
						? [
								fromActions.addBlock({
									block: {
										id: createGuid(),
										type: BlockTypes.TEXT,
										markup: `<h3>${sectionTitle}</h3>`,
										settings: {},
									} as Block<EmptyBlockProperties>,
									subsectionGuid,
									id,
								}),
							]
						: [];
					const channelAddAction = [
						fromActions.addBlock({
							block: {
								id: createGuid(),
								type: BlockTypes.CHANNEL,
								settings,
							} as Block<ChannelBlockProperties>,
							subsectionGuid,
							id,
						}),
					];
					return [...channelAddAction, ...sectionTitleAction];
				})
			),
		{ dispatch: true }
	);

	public openEditAnchorsDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.openEditAnchorsDialog),
				mergeMap(
					({ blockGuid }) =>
						of({ blockGuid }).pipe(
							withLatestFrom(
								this.store$.pipe(select(fromBlockSelectors.selectBlockSettings(blockGuid))),
								this.store$.pipe(select(fromBlockSelectors.selectAllAnchorBlocks))
							)
						),
					(action, initialSettings) => initialSettings
				),
				switchMap(([{ blockGuid }, initialSettings, anchors]) => {
					const dialog = this.dialogService.open(TableOfContentDialogComponent, {
						size: 'l',
						data: {
							id: null,
							blockType: BlockTypes.ANCHOR,
							anchors,
							settings: initialSettings,
						} as TableOfContentDialogData,
					});
					return dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((data) => ({ blockGuid, settings: data.settings[0] }))
					);
				}),
				map(({ blockGuid, settings }) =>
					fromActions.updateBlockSettings({
						blockGuid,
						settings,
					})
				)
			),
		{ dispatch: true }
	);

	public openTableOfContentsDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.openTableOfContentDialog),
				map(({ blockType, id, subsectionGuid }) => {
					const dialog = this.dialogService.open(TableOfContentDialogComponent, {
						size: 'l',
						data: {
							id,
							blockType,
							anchors: [],
							settings: {
								...DEFAULT_BLOCK_SETTINGS[blockType],
							},
						} as TableOfContentDialogData,
					});
					return { dialog, subsectionGuid };
				}),
				switchMap(({ dialog, subsectionGuid }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map(({ tableOfContentSettings, anchors, id }) => ({ tableOfContentSettings, anchors, subsectionGuid, id }))
					)
				),
				mergeMap(({ tableOfContentSettings, anchors, subsectionGuid, id }) => {
					const addAnchorsActions = [];
					const addTableOfContentAction = fromActions.addBlock({
						block: {
							id: createGuid(),
							type: BlockTypes.ANCHOR,
							settings: tableOfContentSettings,
						} as Block<TableOfContentBlockProperties>,
						subsectionGuid,
						id,
					});

					anchors.reverse().forEach((anchor) => {
						addAnchorsActions.push(
							fromActions.addBlock({
								block: anchor as Block<AnchorBlockProperties>,
								subsectionGuid,
								id,
							})
						);
					});
					return [...addAnchorsActions, addTableOfContentAction];
				})
			),
		{ dispatch: true }
	);

	constructor(
		private actions$: Actions,
		private store$: Store<any>,
		private newsletterService: NewsletterService,
		private dialogService: RdsDialogService
	) {}
}
