<ng-container *ngFor="let group of data; let last = last">
  <div class="items p-5">
    <a *ngFor="let item of group" class="item ui-label-m-bold text-low-contrast" href="{{item[3]}}" target="{{item[4]}}">

      <div class="item__image-container">
      <span [ngStyle]="{'background-position':item[2]}"
          class="item__image-container__image"> </span>
      </div>
      <span class="">{{item[1]}} </span>
    </a>
  </div>
  <span *ngIf="!last" class="spacer"></span>
</ng-container>
