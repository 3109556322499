<rds-toggle [(value)]="status" [type]="'outlined'">
    <rds-toggle-button class="success" value="success" (changed)="changeStatus($event)"
        [checked]="status === 'success'">
        <rds-icon rdsTooltip="Everything is OK" namespace="outlined"
            icon="checkmark_circle"></rds-icon>
    </rds-toggle-button>
    <rds-toggle-button class="default" value="in_progress" (changed)="changeStatus($event)"
    [checked]="status === 'in_progress'">
    <rds-icon rdsTooltip="In progress" namespace="outlined" icon="play"></rds-icon>
</rds-toggle-button>
    <rds-toggle-button class="warning" value="not_set" (changed)="changeStatus($event)"
        [checked]="status === 'not_set'">
        <rds-icon rdsTooltip="Not checked yet" namespace="outlined" icon="pause"></rds-icon>
    </rds-toggle-button>
    <rds-toggle-button class="error" value="error" (changed)="changeStatus($event)"
        [checked]="status === 'error'">
        <rds-icon rdsTooltip="View contains error" namespace="outlined"
            icon="close_circle"></rds-icon>
    </rds-toggle-button>
</rds-toggle>