export enum EntryStatus {
  DRAFT = 1,
  APPROVAL_REQUIRED = 2,
  NOT_APPROVED = 3,
  PARTIALLY_PUBLISHED = 4,
  PUBLISHED = 5,
  REVIEW_REQUIRED = 6,
  DELETED_BY_CLM = 7,
  DELETED_BY_USER = 8,
  TRASHED = 9
}



export const MyContentEntryStatusOptions = [
  {
    name: 'Draft',
    statusId: EntryStatus.DRAFT,
  },
  {
    name: 'Approval Required',
    statusId: EntryStatus.APPROVAL_REQUIRED,
  },
  {
    name: 'Not Approved',
    statusId: EntryStatus.NOT_APPROVED,
  },
  {
    name: 'Partially published',
    statusId: EntryStatus.PARTIALLY_PUBLISHED,
  },
  {
    name: 'Published',
    statusId: EntryStatus.PUBLISHED,
  },
  {
    name: 'Review Required',
    statusId: EntryStatus.REVIEW_REQUIRED,
  },
  {
    name: 'Deleted By CLM',
    statusId: EntryStatus.DELETED_BY_CLM,
  },
  {
    name: 'Deleted By User',
    statusId: EntryStatus.DELETED_BY_USER,
  }
];

export const AllContentEntryStatusOptions = [
  {
    name: 'Partially published',
    statusId: EntryStatus.PARTIALLY_PUBLISHED,
  },
  {
    name: 'Published',
    statusId: EntryStatus.PUBLISHED,
  },
  {
    name: 'Review Required',
    statusId: EntryStatus.REVIEW_REQUIRED,
  },
];

export const statusOptionsModel = {
  idKey: 'statusId',
  nameKey: 'name'
};
