// import { createAction, props } from '@ngrx/store';

import { createAction, props } from '@ngrx/store';
import { SortDirection } from '@rds/angular-components';
import { NewsTableFilters } from './related-news.reducer';
import { News } from '@app/core/models/newsboard';
import { Pagination } from '@app/core/models';

export const initRelatedNews = createAction(
  '[RELATED NEWS - ALL NEWS TABLE] Initialize News'
);

export const clearAll = createAction(
  '[RELATED NEWS] Clear all'
);

export const getRelatedNewsRequest = createAction(
  '[RELATED NEWS - ALL NEWS TABLE] Get all news Request',
  props<{
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
    filters: NewsTableFilters
  }>()
);

export const getRelatedNewsSuccess = createAction(
  '[RELATED NEWS - ALL NEWS TABLE] Get all news Success',
  props<{ data: Array<Partial<News>>, pagination: Pagination }>()
);

export const getRelatedNewsFailure = createAction(
  '[RELATED NEWS - ALL NEWS TABLE] Get all news Failure',
  props<{ error: any }>()
);

export const changeRelatedNewsFilters = createAction(
  '[RELATED NEWS - ALL NEWS TABLE] Change all news filters',
  props<{ filters: NewsTableFilters }>()
);

export const changeRelatedNewsSort = createAction(
  '[RELATED NEWS - ALL NEWS TABLE] Change all news sort',
  props<{ active: string, direction: SortDirection }>()
);

export const changeRelatedNewsPagination = createAction(
  '[RELATED NEWS - ALL NEWS TABLE] Change all news pagination',
  props<{ pageIndex: number, pageSize: number }>()
);

// NEWS ACTIONS

export const openNewsPreview = createAction(
  '[RELATED NEWS] Open news preview',
  props<{ newsId: number}>()
);

export const getNewsDetailsRequest = createAction(
  '[RELATED NEWS] get news details request',
  props<{newsId: number, isPinned: boolean}>()
);

export const getNewsDetailsSuccess = createAction(
  '[RELATED NEWS] get news details success',
  props<{news: News}>()
);

export const getNewsDetailsFailure = createAction(
  '[RELATED NEWS] get news details failure'
);


export const newsSelectionChanged = createAction(
  '[RELATED NEWS] News selection changed',
  props<{news: Array<Partial<News>>}>()
);

export const addRelatedNews = createAction(
  '[RELATED NEWS] add related news',
  props<{news: Array<Partial<News>>}>()
);

export const fetchRelatedNewsRequest = createAction(
  '[RELATED NEWS] fetch related news request',
  props<{ids: Array<number>}>()
);

export const fetchRelatedNewsSuccess = createAction(
  '[RELATED NEWS] fetch related news success',
  props<{news: Array<Partial<News>>}>()
);

export const fetchRelatedNewsFailure = createAction(
  '[RELATED NEWS] fetch related news failure',
);

export const removeRelatedNews = createAction(
  '[RELATED NEWS] remove related news',
  props<{id: number}>()
);

export const openRelatedNewsDialog = createAction(
  '[RELATED NEWS] Open related news dialog',
);