import { createSelector } from '@ngrx/store';

import * as fromCore from '@core/core-store/core.reducer';
import * as fromReducer from './channels-news.reducer';

export const selectState = createSelector(
  fromCore.selectState,
  fromCore.getChannelsNews
);

export const selectChannelAll = createSelector(
  selectState,
  fromReducer.getChannelAll
);

export const selectChannelEntities = createSelector(
  selectState,
  fromReducer.getChannelEntities
);

export const selectChannelIds = createSelector(
  selectState,
  fromReducer.getChannelIds
);

export const selectChannelTotal = createSelector(
  selectState,
  fromReducer.getChannelTotal
);

export const selectChannelEntityById = createSelector(
  selectChannelEntities,
  fromReducer.getChannelEntityById
);

export const selectChannelEntitiesById = createSelector(
  selectChannelEntities,
  fromReducer.getChannelEntitiesById
);

export const selectChannelEntityState = (id: string) => createSelector(
  selectState,
  (state) => state.entities[id]?.news
);

export const selectNewsAll = (id: string) => createSelector(
  selectChannelEntityState(id),
  fromReducer.getNewsAll
);

export const selectNewsEntities = (id: string) => createSelector(
  selectChannelEntityState(id),
  fromReducer.getNewsEntities
);

export const selectNewsIds = (id: string) => createSelector(
  selectChannelEntityState(id),
  fromReducer.getNewsIds
);

export const selectNewsTotal = (id: string) => createSelector(
  selectChannelEntityState(id),
  fromReducer.getNewsTotal
);

export const selectNewsEntityById = (id: string) => createSelector(
  selectNewsEntities(id),
  fromReducer.getNewsEntityById
);

export const selectNewsEntitiesById = (id: string) => createSelector(
  selectNewsEntities(id),
  fromReducer.getNewsEntitiesById
);
