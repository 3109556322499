import { Block} from './block-type.enum';

export enum SectionTypes {
  SINGLE = 'SINGLE',
  TWO_EQUAL = 'TWO_EQUAL',
  THREE_IN_ONE = 'THREE_IN_ONE',
  ONE_IN_THREE = 'ONE_IN_THREE',
  THREE_EQUAL = 'THREE_EQUAL'
}

export enum SubsectionTypes {
  PERCENT_100 = '6',
  PERCENT_50 = '3',
  PERCENT_33 = '2',
  PERCENT_66 = '4',
}

export interface SectionBackground {
  class: string;
  hex: string;
}

export const DEFAULT_SECTION_BACKGROUND: SectionBackground = {
  class: 'bg-white',
  hex: '#FFFFFF'
};

export const SECTION_BACKGROUNDS: Array<SectionBackground> = [
  {
    class: 'bg-300-neutral-3',
    hex: '#FAD6C7'
  },
  {
    class: 'bg-200-neutral-2',
    hex: '#FFE8DE'
  },
  {
    class: 'bg-100-neutral-1',
    hex: '#FFF7F5'
  },
  {
    class: 'bg-white',
    hex: '#FFFFFF'
  },
];

export interface Section {
  id: string;
  type: SectionTypes;
  background: SectionBackground;
  verticalSpace: string;
  subsections: Array<Subsection>;
}

export interface Subsection {
  id: string;
  colspan: SubsectionTypes;
  blocks: Array<Block>;
}
