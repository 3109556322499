import { Injectable } from '@angular/core';
import { ApiClient } from '../api-newsboard/api-client.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(private client: ApiClient) {}

  uploadFile(file: File) {
    return this.client.postFile('/uploadFile', file);
  }
  
}
