import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PeopleResult} from '@app/search/store/rh-search';

@Component({
  selector: 'rh-people-list-item',
  templateUrl: './people-list-item.component.html',
  styleUrls: ['./people-list-item.component.scss']
})
export class PeopleListItemComponent {

  @Input() result!: PeopleResult;
  @Output() peopleOpened: EventEmitter<{ login: string }> = new EventEmitter();

  constructor() {
  }

  onOpenPeople(): void {
    this.peopleOpened.emit({login: this.result.login});
  }
}
