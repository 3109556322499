import { createReducer, on, Action, createFeatureSelector} from '@ngrx/store';
import * as AuthActions from './auth.actions';

export const feature = 'auth';

export interface State  {
  tokenId: string | null;
  tokenAccess: string | null;
  jwtToken: string | null;
  scope: string | null;
  isLogging: boolean;
  isAuthenticated: boolean;
  isGISLoaded: boolean;
  isGapiLoaded: boolean;
  isQueryAPILoaded: boolean;
  isGcalAPILoaded: boolean;
  authCompleted: boolean;
  isDialogOpened: boolean;
  isDialogOpenedUserNotCreated: boolean;
}

export const initialState: State = {
  tokenId: !!localStorage.getItem('tokenId') && localStorage.getItem('tokenId') !== "null" ? localStorage.getItem('tokenId') : null,
  tokenAccess: !!localStorage.getItem('tokenAccess') && localStorage.getItem('tokenAccess') !== "null" ? localStorage.getItem('tokenAccess') : null,
  jwtToken: !!localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken') !== "null" ? localStorage.getItem('jwtToken') : null,
  scope: !!localStorage.getItem('scope') && localStorage.getItem('scope') !== "null" ? localStorage.getItem('scope') : null,
  isAuthenticated: false,
  isLogging: false,
  isGISLoaded: false,
  isGapiLoaded: false,
  isQueryAPILoaded: false,
  isGcalAPILoaded: false,
  authCompleted: false,
  isDialogOpened: false,
  isDialogOpenedUserNotCreated: false,
};

export const reducer = createReducer(
  initialState,
  on(AuthActions.GISLibLoaded, (state) => ({
    ...state,
    isGISLoaded: true
  })),
  on(AuthActions.GapiLibLoaded, (state) => ({
    ...state,
    isGapiLoaded: true
  })),
  //
  on(AuthActions.loginWithGoogleStart, (state) => ({
    ...state,
    isLogging: true,
  })),
  on(AuthActions.loginWithGoogleEnd, (state) => ({
    ...state,
    isLogging: false,
  })),
  on(AuthActions.isLoggedWithGoogle, (state) => ({
    ...state,
    isAuthenticated: true,
    isLogging: false,
  })),
  on(AuthActions.updateAuthToken, (state, { tokenAccess }) => ({
    ...state,
    tokenAccess,
  })),
  on(AuthActions.updateIdToken, (state, { tokenId }) => ({
    ...state,
    tokenId,
  })),
  on(AuthActions.updateJwtToken, (state, { jwtToken }) => ({
    ...state,
    jwtToken,
  })),
  on(AuthActions.updateScope, (state, { scope }) => ({
    ...state,
    scope,
  })),
  on(AuthActions.authCompleted, (state) => ({
    ...state,
    authCompleted: true,
    isDialogOpened: false,
    isAuthenticated: true,
  })),
  on(AuthActions.userNotCreatedDialogIsOpened, (state) => ({
    ...state,
    isDialogOpenedUserNotCreated: true,
  })),
  on(AuthActions.clearAuth, (state) => ({
    ...state,
    authCompleted: false,
    isAuthenticated: false,
    isLogging: false,
    tokenId: !!localStorage.getItem('tokenId') && localStorage.getItem('tokenId') !== "null" ? localStorage.getItem('tokenId') : null,
    tokenAccess: !!localStorage.getItem('tokenAccess') && localStorage.getItem('tokenAccess') !== "null" ? localStorage.getItem('tokenAccess') : null,
    jwtToken: !!localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken') !== "null" ? localStorage.getItem('jwtToken') : null,
    scope: !!localStorage.getItem('scope') && localStorage.getItem('scope') !== "null" ? localStorage.getItem('scope') : null,
  })),
  on(AuthActions.dialogOpened, (state) => ({
    ...state,
    isDialogOpened: true
  })),
  on(AuthActions.initQueryAPISuccess, (state) => ({
    ...state,
    isQueryAPILoaded: true
  })),
  on(AuthActions.initGcalAPISuccess, (state) => ({
    ...state,
    isGcalAPILoaded: true
  })),
);

export function authReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}

export const selectAuthState = createFeatureSelector<State>(feature);

export const getIsAuthenticated = (state: State) => state.isAuthenticated;
export const getIdToken = (state: State) => state.tokenId;
export const getAuthToken = (state: State) => state.tokenAccess;
export const getJwtToken = (state: State) => state.jwtToken;
export const getScope = (state: State) => state.scope;
export const getIsDialogOpened = (state: State) => state.isDialogOpened;
export const getIsDialogOpenedUserNotCreated = (state: State) => state.isDialogOpenedUserNotCreated;
export const getIsQueryAPILoaded = (state: State) => state.isQueryAPILoaded;
export const getIsGcalAPILoaded = (state: State) => state.isGcalAPILoaded;
export const getIsGISLoaded = (state: State) => state.isGISLoaded;
export const getIsGapiLoaded = (state: State) => state.isGapiLoaded;
