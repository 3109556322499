<rds-footer [neutral]="true" class="footer">
  <rds-footer-navigation>
    <rds-footer-link>
      <a target="_blank"
        href="https://roche.service-now.com/service_portal?id=sc_cat_item&sys_id=e9313fc6db0c449867942bce859619c1">
        {{ 'footer.links.report-issue' | translate }}
      </a>
    </rds-footer-link>
    <rds-footer-link>
      <a target="_blank" href="https://go.roche.com/IntranetPolicies">
      {{ 'footer.links.policies' | translate }}
    </a></rds-footer-link>
    <rds-footer-link>
      <a target="_blank"
      href="https://roche.service-now.com/rose?id=search&q=rochehome">
     {{ 'footer.links.questions' | translate }}
   </a></rds-footer-link>
  </rds-footer-navigation>
  <rds-footer-disclaimer>
    <rds-footer-caption>
      This information is proprietary and is intended as a reference and for internal use only. Do not share with external audiences.
    </rds-footer-caption>
  </rds-footer-disclaimer>

</rds-footer>
