import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  transform(value: string, text: string): any {
    if (value) {
      if (!text || text.length === 0) {
        return value;
      }
      const reg = new RegExp(this.escapeRegExp(text), 'gi');
      return `<span>${value.replace(reg, (str: string) => '<b>' + str + '</b>')}</span>`;
    }
    return '';
  }

  escapeRegExp = (text: string) => {
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

}
