import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { environment } from '@env/environment';
import { exhaustMap, filter, map, mergeMap, repeat, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import * as fromRouter from '@app/root-store/router/router.actions';
import * as fromActions from './newsletter-websocket.actions';
import * as fromReducer from './newsletter-websocket.reducer';
import * as fromSelectors from './newsletter-websocket.selectors';
import * as fromUser from '@app/core/user/store';
import * as fromDashboardSelectors from '../newsletter-dashboard/newsletter-dashboard.selectors';
import * as fromDashboardActions from '../newsletter-dashboard/newsletter-dashboard.actions';
import * as fromFormSelectors from '../newsletter-form/newsletter-form.selectors';
import * as fromInlineSelectors from '../newsletter-inline/newsletter-inline.selectors';
import * as fromNewsletterOther from '../newsletter-other/newsletter.actions';
import { interval } from 'rxjs';

const KEEP_ALIVE_TIMER = 1000 * 60 * 9;

@Injectable()
export class NewsletterWebSocketEffects {
  ws: WebSocket;

  public wsConnect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.wsConnect),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectIsWebSocketConnected)),
      ),
      tap(([action, isAlreadyConnected]) => {
        if (!isAlreadyConnected) {
          this.ws = new WebSocket(environment.websocketUrl);
          this.ws.addEventListener('open', (e) => this.openListener(e));
          this.ws.addEventListener('close', (e) => this.closeListener(e));
          this.ws.addEventListener('error', (e) => this.errorListener(e));
          this.ws.addEventListener('message', (e) => this.messageListener(e));
        }
      })
    ), { dispatch: false }
  );

  public wsConnectAndCheckNewsletter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.wsConnectAndCheckNewsletter),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectIsWebSocketConnected)),
      ),
      tap(([{newsletterId}, isAlreadyConnected]) => {
        if (!isAlreadyConnected) {
          this.ws = new WebSocket(`${environment.websocketUrl}?newsletterId=${newsletterId}`);
          this.ws.addEventListener('open', (e) => this.openListener(e));
          this.ws.addEventListener('close', (e) => this.closeListener(e));
          this.ws.addEventListener('error', (e) => this.errorListener(e));
          this.ws.addEventListener('message', (e) => this.messageListener(e));
        }
      })
    ), { dispatch: false }
  );

  public wsDisconnectIfConnected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.wsDisconnectIfConnected),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectIsWebSocketConnected)),
      ),
      filter(([action, isAlreadyConnected]) => isAlreadyConnected),
      map(() => fromActions.wsDisconnect())
    ), { dispatch: true}
  );

  public wsDisconnect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.wsDisconnect),
      tap(() => {
        this.ws.close();
        this.ws.removeEventListener('open', this.openListener);
        this.ws.removeEventListener('close', this.closeListener);
        this.ws.removeEventListener('error', this.errorListener);
        this.ws.removeEventListener('message', this.messageListener);
      }),
      map(() => fromActions.wsDisconnected())
    ), { dispatch: true}
  );

  public wsConnected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.wsConnected),
      map(() => fromActions.wsUpdateNewsletters())
    ), { dispatch: true}
  );

  public keepAliveConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.wsStartKeepAlive),
      exhaustMap(() => interval(KEEP_ALIVE_TIMER).pipe(
        tap(() => {
          const payload = {
            action: 'keepAlive',
          };
          this.ws.send(JSON.stringify(payload));
        }),
      )),
      takeUntil(
        this.actions$.pipe(
          ofType(fromActions.wsStopKeepAlive)
        )
      ),
      repeat(),
    ), { dispatch: false}
  );

  public wsUpdateNewsletters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.wsUpdateNewsletters),
      withLatestFrom(
        this.store$.pipe(select(fromUser.selectLogin)),
        this.store$.pipe(select(fromDashboardSelectors.selectSharedDraftIds)),
        this.store$.pipe(select(fromDashboardSelectors.selectSharedScheduledIds)),
        this.store$.pipe(select(fromDashboardSelectors.selectSharedSentIds)),
        this.store$.pipe(select(fromSelectors.selectIsWebSocketConnected)),
      ),
      filter(([action, login, draftIds, scheduledIds, sentIds, isWebSocketConnected]) => isWebSocketConnected),
      tap(([action, login, draftIds, scheduledIds, sentIds]) => {
        const payload = {
          action: 'updateNewsletters',
          data: {
            login,
            newsletterIds: [...draftIds, ...scheduledIds, ...sentIds]
          }
        };
        this.ws.send(JSON.stringify(payload));
      })
    ), { dispatch: false}
  );

  public wsUpdateNewslettersWithNewsletterId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.wsUpdateNewslettersWithNewsletterId),
      withLatestFrom(
        this.store$.pipe(select(fromUser.selectLogin)),
        this.store$.pipe(select(fromSelectors.selectIsWebSocketConnected)),
      ),
      filter(([action, login, isWebSocketConnected]) => isWebSocketConnected),
      tap(([{newsletterId}, login]) => {
        const payload = {
          action: 'updateNewsletters',
          data: {
            login,
            newsletterIds: [newsletterId]
          }
        };
        this.ws.send(JSON.stringify(payload));
      })
    ), { dispatch: false}
  );

  public wsStartWork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.wsStartWork),
      withLatestFrom(
        this.store$.pipe(select(fromUser.selectLogin)),
        this.store$.pipe(select(fromUser.selectFullName)),
        this.store$.pipe(select(fromSelectors.selectIsWebSocketConnected)),
      ),
      filter(([{newsletterId}, workingUserLogin, workingUserFullName, isWebSocketConnected]) => isWebSocketConnected),
      tap(([{newsletterId}, workingUserLogin, workingUserFullName]) => {
        const payload = {
          action: 'changeWorkStatus',
          data: {
            workInProgress: true,
            newsletterId,
            workingUserLogin,
            workingUserFullName,
          }
        };
        this.ws.send(JSON.stringify(payload));
      })
    ), { dispatch: false}
  );

  public wsStopWork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.wsStopWork),
      withLatestFrom(
        this.store$.pipe(select(fromUser.selectLogin)),
        this.store$.pipe(select(fromUser.selectFullName)),
        this.store$.pipe(select(fromSelectors.selectIsWebSocketConnected)),
      ),
      filter(([{newsletterId}, workingUserLogin, workingUserFullName, isWebSocketConnected]) => isWebSocketConnected),
      tap(([{newsletterId}, workingUserLogin, workingUserFullName]) => {
        const payload = {
          action: 'changeWorkStatus',
          data: {
            workInProgress: false,
            newsletterId,
            workingUserLogin,
            workingUserFullName,
          }
        };
        this.ws.send(JSON.stringify(payload));
      })
    ), { dispatch: false}
  );

  public wsCheckNewsletter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.wsCheckNewsletter),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectIsWebSocketConnected)),
      ),
      filter(([{newsletterId}, isWebSocketConnected]) => isWebSocketConnected),
      tap(([{newsletterId}]) => {
        const payload = {
          action: 'checkNewsletter',
          data: {
            newsletterId,
          }
        };
        this.ws.send(JSON.stringify(payload));
      })
    ), { dispatch: false}
  );

  public wsNewsletterDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.wsNewsletterDeleted),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectIsWebSocketConnected)),
      ),
      filter(([{newsletterId}, isWebSocketConnected]) => isWebSocketConnected),
      tap(([{newsletterId}]) => {
        const payload = {
          action: 'newsletterDeleted',
          data: {
            newsletterId,
          }
        };
        this.ws.send(JSON.stringify(payload));
      })
    ), { dispatch: false}
  );

  public wsMovedToDraft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.wsMovedToDraft),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectIsWebSocketConnected)),
      ),
      filter(([{newsletterId}, isWebSocketConnected]) => isWebSocketConnected),
      tap(([{newsletterId}]) => {
        const payload = {
          action: 'newsletterMovedToDraft',
          data: {
            newsletterId,
          }
        };
        this.ws.send(JSON.stringify(payload));
      })
    ), { dispatch: false}
  );

  public wsUnsher$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.wsUnshare),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectIsWebSocketConnected)),
      ),
      filter(([{newsletterId}, isWebSocketConnected]) => isWebSocketConnected),
      tap(([{newsletterId}]) => {
        const payload = {
          action: 'newsletterUnshare',
          data: {
            newsletterId,
          }
        };
        this.ws.send(JSON.stringify(payload));
      })
    ), { dispatch: false}
  );

  public wsMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.wsMessageReceived),
      map(({messageType, data, singleNewsletter}) => {
        switch (messageType) {
          case 'newsletterInfo': {
            console.log('Newsletter info: ', data);
            return fromActions.wsNewsletterInfo({newsletters: data, singleNewsletter});
          }
          case 'newsletterTaken': {
            console.log('Newsletter taken: ', data);
            return fromActions.wsNewsletterTaken({ newsletter: data, singleNewsletter});
          }
          case 'newsletterReleased': {
            console.log('Newsletter released: ', data);
            return fromActions.wsNewsletterReleased({ newsletter: data , singleNewsletter});
          }
          case 'newsletterAccessDenied': {
            console.log('Newsletter access denied: ', data);
            return fromActions.wsNewsletterAccessDenied({ newsletterId: data.newsletterId, singleNewsletter});
          }
          case 'newsletterAccessGranted': {
            console.log('Newsletter access granted: ', data);
            return fromActions.wsNewsletterAccessGranted({ newsletterId: data.newsletterId, singleNewsletter});
          }
          case 'updateRequired': {
            console.log('Update required: ', data);
            return fromActions.wsUpdateRequired();
          }
          default: {
            console.log('Unknown message: ', data);
            return fromActions.wsUnknownMessage({ messageType, data, singleNewsletter});
          }
        }
      })
    ), { dispatch: true}
  );

  public wsNewsletterAccessDenied$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.wsNewsletterAccessDenied),
      mergeMap(() => [
        fromRouter.go({path: 'newsletter', queryParams: {}}),
        fromNewsletterOther.forceCloseGetImagesUserAgreementDialog(),
      ])
    ), { dispatch: true}
  );

  public wsUpdateRequired$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.wsUpdateRequired),
      map(() => fromDashboardActions.getNewsletters())
    ), { dispatch: true}
  );

  public wsNewsletterAccessGranted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.wsNewsletterAccessGranted),
      mergeMap(({newsletterId}) => [
        fromActions.wsUpdateNewslettersWithNewsletterId({newsletterId})
      ])
    ), { dispatch: true}
  );

  // public wsNewsletterInfo$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromActions.wsNewsletterInfo),
  //     filter(({singleNewsletter}) => singleNewsletter),
  //     map(({newsletters}) =>
  //         fromActions.wsStartWork({newsletterId: newsletters[0].newsletterId}))
  //   ), { dispatch: true}
  // );

  public wsUnknownMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.wsUnknownMessage),
    ), { dispatch: false}
  );

  public wsAgreementsAccepted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsletterOther.agreementsAccepted),
      withLatestFrom(
        this.store$.pipe(select(fromFormSelectors.selectIsContributed)),
        this.store$.pipe(select(fromInlineSelectors.selectIsContributed)),
      ),
      tap(([{newsletterId}, isFormContributed, isInlineContributed]) => {
        console.log('isFormContributed: ', isFormContributed, '\nisInlineContributed: ', isInlineContributed);
      }),
      filter(([{newsletterId}, isFormContributed, isInlineContributed]) => isFormContributed || isInlineContributed),
      map(([{newsletterId}]) =>
          fromActions.wsStartWork({newsletterId}))
    ), { dispatch: true}
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>
  ) {
  }

  private openListener = (e) => {
    console.log('WebSocket is connected', e);
    this.store$.dispatch(fromActions.wsSetIsConnected());
    this.store$.dispatch(fromActions.wsStartKeepAlive());
    const singleNewsletter = e.target.url.split('?')[1];
    if (singleNewsletter) {
      this.store$.dispatch(fromActions.wsCheckNewsletter({newsletterId: singleNewsletter.split('=')[1]}));
    } else {
      this.store$.dispatch(fromActions.wsConnected());
    }
  }

  private closeListener = (e) => {
    console.log('WebSocket is closed', e);
    this.store$.dispatch(fromActions.wsStopKeepAlive());
  }

  private errorListener = (e) => {
    console.log('WebSocket is on error', e);
  }

  private messageListener = (e) => {
    console.log('WebSocket got message', e);
    const data = JSON.parse(e.data);
    const singleNewsletter = e.target.url.split('?')[1];
    this.store$.dispatch(fromActions.wsMessageReceived({messageType: data.messageType, data: data.data, singleNewsletter: !!singleNewsletter}));
  }
}

