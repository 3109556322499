import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'atr-not-found-component',
  templateUrl: './not-found-error.component.html',
  styleUrls: ['./not-found-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundErrorComponent  {


  constructor() { }
}
