import {createFeatureSelector, createReducer, on} from '@ngrx/store';
import * as fromActions from './suggestions.actions';
import {ChannelAssignment, SimpleUser} from '@app/core/models/newsboard';
import {CatalogTable} from '@app/core/models';
import { RecipientListsTableModel } from '@app/core/models/newsletter.model';
import { RhEvent } from '@app/events/models/event';

export const feature = 'suggestions';

export type SuggestionType =
  'channel'
  | 'user'
  | 'news'
  | 'topic-news'
  | 'topic-event'
  | 'language'
  | 'catalog'
  | 'catalog-keyword'
  | 'rada-groups'
  | 'recipients-list'
  | 'event';

const STRING_SUG_TYPES = ['topic-news', 'topic-event', 'catalog-keyword'];

export interface Autocomplete {
  type: SuggestionType;
  phrase: string;
  suggestions: Array<SimpleUser | ChannelAssignment | CatalogTable | RecipientListsTableModel | RhEvent | string> | Array<any>;
  loading: boolean;
}

export interface AutocompleteOptions<T> {
  isString: boolean;
  options: Array<T>;
  phrase: string;
  loading: boolean;
}

export interface State {
  [key: string]: Autocomplete
}

export const initialState: State = {};

export const reducer = createReducer(
  initialState,
  on(fromActions.initSuggestion, fromActions.clearSuggestion, (state, {suggestionType, prop}) => ({
    ...state,
    [prop]: {
      type: suggestionType,
      phrase: null,
      suggestions: [],
      loading: false
    }
  })),
  on(fromActions.loadSuggestionRequest, (state, {prop, phrase}) => ({
    ...state,
    [prop]: {
      ...state[prop],
      phrase,
      loading: true
    }
  })),
  on(fromActions.loadSuggestionSuccess, fromActions.loadSuggestionFailure, (state, {prop}) => ({
    ...state,
    [prop]: {
      ...state[prop],
      loading: false
    }
  })),
  on(fromActions.loadSuggestionSuccess, (state, {prop, suggestions }) => ({
    ...state,
    [prop]: {
      ...state[prop],
      suggestions
    }
  })),
);

export const selectState = createFeatureSelector<State>(feature);

export const getSuggestions = (autocomplete: {
  suggestions: Array<SimpleUser | ChannelAssignment | CatalogTable | RecipientListsTableModel | RhEvent | string>,
  loading: boolean
}) => autocomplete.suggestions;

export const getLoading = (autocomplete: {
  suggestions: Array<SimpleUser | ChannelAssignment | CatalogTable | RecipientListsTableModel | RhEvent | string>,
  loading: boolean
}) => autocomplete.loading;

export const getAutocompletes = (autocomplete: Autocomplete) => ({
  isString: STRING_SUG_TYPES.includes(autocomplete.type),
  options: autocomplete.suggestions,
  phrase: autocomplete.phrase,
  loading: autocomplete.loading
});
