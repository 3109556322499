import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {RdsMenuComponent} from '@rds/angular-components';

@Component({
    selector: 'rh-button-with-dropdown',
    templateUrl: './button-with-dropdown.component.html',
    styles: []
})
export class ButtonWithDropdownComponent {
    @ViewChild("menu", {static: true}) menu: RdsMenuComponent;
    @Input() options: Array<any>
    @Input() type: string;
    @Input() label: string;
    @Output() clicked: EventEmitter<any> = new EventEmitter<any>();


    select(option) {
        this.clicked.emit(option);
    }


    constructor() {
    }
}
