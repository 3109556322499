import {
  Directive,
  ElementRef
} from '@angular/core';

@Directive({
  selector: '[rnbShouldTruncate]',
  exportAs: 'rnbShouldTruncate'
})
export class ShouldTruncateDirective {

  public get shouldTruncate() {
    const elementWidth = this.host.nativeElement.offsetWidth;
    const contentWidth = (this.host.nativeElement.firstChild as HTMLElement).offsetWidth
    return contentWidth - elementWidth > 0;
  }
  constructor(
    private host: ElementRef<HTMLElement>,
  ) {}

}
