import { Pagination, QuicklinksSet } from '@app/core/models';
import { ManageSetsTableFilters } from '@app/shared/filters/models/manage-sets-table';
import { createAction, props } from '@ngrx/store';
import { SortDirection } from '@rds/angular-components';

export const initTable = createAction(
  '[QUICKLINKS SETS - TABLE] Initialize Channels'
);

export const clearAll = createAction(
  '[QUICKLINKS SETS - TABLE] Clear all'
);

export const getTableRequest = createAction(
  '[QUICKLINKS SETS - TABLE] Get channels Request',
  props<{
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
    filters: {[key: string]: any},
  }>()
);

export const getTableSuccess = createAction(
  '[QUICKLINKS SETS - TABLE] Get channels Success',
  props<{ data: Array<QuicklinksSet>, pagination: Pagination }>()
);

export const getTableFailure = createAction(
  '[QUICKLINKS SETS - TABLE] Get channels Failure',
  props<{ error: any }>()
);

export const changeTableFilters = createAction(
  '[QUICKLINKS SETS - TABLE] Change channels filters',
  props<{ filters: ManageSetsTableFilters }>()
);

export const changeTableSort = createAction(
  '[QUICKLINKS SETS - TABLE] Change channels sort',
  props<{ active: string, direction: SortDirection }>()
);

export const changeTablePagination = createAction(
  '[QUICKLINKS SETS - TABLE] Change channels pagination',
  props<{ pageIndex: number, pageSize: number }>()
);

export const deleteQuicklinksSetRequest = createAction(
  '[QUICKLINKS SETS - TABLE] Delete Quicklinks set Request',
  props<{id: number}>()
);

export const deleteQuicklinksSetSuccess = createAction(
  '[QUICKLINKS SETS - TABLE] Delete Quicklinks set Success',
);

export const deleteQuicklinksSetFailure = createAction(
  '[QUICKLINKS SETS - TABLE] Delete Quicklinks set Failure',
  props<{ error: any }>()
);