import { RhEventType } from '@app/events/models/event';
import { Language } from './language.model';
import { Taxonomy } from './taxonomy.model';

export interface UserProfile {
  login: string;
  email: string;
  firstName: string;
  lastName: string;
  newsLanguage: Language;
  phone: string;
  wasWelcomePageDisplayed: boolean;
  wasUserSettingsDisplayed: boolean;
  wasQuickLinksPageDisplayed: boolean;
  wasCatalogPageDisplayed: boolean;
  wasSearchPageDisplayed: boolean;
  wasMyContentPageDisplayed: boolean;
  wasLayoutSettingsPageDisplayed: boolean;
  wasMenuDisplayed: boolean;
  photoUrl: string;
}

export const UserProfile: UserProfile = {
  login: '',
  email: '',
  firstName: '',
  lastName: '',
  newsLanguage: {
    id: 9,
    code: 'en',
    englishName: 'English'
  },
  phone: '',
  wasWelcomePageDisplayed: true,
  wasUserSettingsDisplayed: true,
  wasQuickLinksPageDisplayed: true,
  wasCatalogPageDisplayed: true,
  wasSearchPageDisplayed: true,
  wasMyContentPageDisplayed: true,
  wasLayoutSettingsPageDisplayed: true,
  wasMenuDisplayed: true,
  photoUrl: ''
};

export interface GlobalSettingsIds {
  newsLanguageId: string[];
}

export interface GlobalSettingsDetails {
  newsLanguage: Language;
}

export interface SearchingPreferencesIds {
  locations: string[];
  departments: string[];
  functions: string[];
}

export interface SearchingPreferencesDetails {
  locations: Taxonomy[];
  departments: Taxonomy[];
  functions: Taxonomy[];
}

export interface SavedFilters {
  filter: {
    categories: string[],
    locations: string[],
    departments: string[],
    functions: string[]
  };
  preferences: {
    locations: string[]
  };
}

export interface EventFilters {
  filter: {
    eventCategory: Array<RhEventType>,
    locations: Array<string>,
    departments: Array<string>,
    functions: Array<string>
  };
  preferences: {
    locations: Array<string>
  };
}