import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NewsResult} from '@app/search/store/rh-search';
import {ContentTypeEnum} from '@core/models';

@Component({
  selector: 'rh-news-list-item',
  templateUrl: './news-list-item.component.html',
  styleUrls: ['./news-list-item.component.scss']
})
export class NewsListItemComponent {

  @Input() result!: NewsResult;
  @Output() newsOpened: EventEmitter<{ newTab: boolean, url: string, id: string, title: string }> = new EventEmitter();
  @Output() likeToggled: EventEmitter<{ hasLiked: boolean, news: NewsResult }> = new EventEmitter();

  constructor() {
  }

  openNews(): void {
    const newTab = this.result.type === ContentTypeEnum.LINK
    this.newsOpened.emit({newTab, url: this.result.url, id: this.result.id, title: this.result.title})
  }

  toggleLike(): void {
    this.likeToggled.emit({hasLiked: this.result.hasLiked, news: this.result})
  }
}
