import { Injectable } from '@angular/core';
import { NewsletterNewsResponse } from '@app/core/models';
import { Observable } from 'rxjs';
import { ApiClient } from '../api-newsboard/api-client.service';

@Injectable({
  providedIn: 'root'
})
export class NewsboardNewsService {
  constructor(private client: ApiClient) {}

  getNewsForChannel(channelId: number): Observable<Array<NewsletterNewsResponse>> {
    return this.client.get(`/news/preview/${channelId}?rand=${new Date().getTime()}`);
  }
  
}
