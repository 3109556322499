import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, switchMap, first } from 'rxjs/operators';
import { of } from 'rxjs';
import { ContentService } from '@app/core/services/content.service';

import * as fromActions from './content-types.actions';
import * as fromReducer from './content-types.reducer';
import * as fromSlectors from './content-types.selectors';
import { select, Store } from '@ngrx/store';

@Injectable()
export class ContentTypesEffects {

  public loadContentTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadContentTypes),
      switchMap(() => this.store$.pipe(
        select(fromSlectors.selectEntities),
        first(entities => this.isEmpty(entities)),
      )),
      mergeMap(() => [
        fromActions.getContentTypesRequest()
      ])
    ), { dispatch: true }
  );

  public getContentTypesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getContentTypesRequest),
      mergeMap(() =>
        this.contentService.getContentTypes().pipe(
          map((contentTypes) => fromActions.getContentTypesSuccess({ contentTypes })),
          catchError(({ message }) => of(fromActions.getContentTypesFailure({ error: message })))
        )
      )
    ), { dispatch: true }
  );

  public getContentTypesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getContentTypesSuccess),
      mergeMap(({ contentTypes }) => [
        fromActions.contentTypesCollectionAddMany({ contentTypes }),
      ]),
    ), { dispatch: true }
  );

  private isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private contentService: ContentService,
  ) {}
}
