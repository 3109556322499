import {Action, createFeatureSelector, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import * as fromActions from '@app/events/store/calendar/calendar.actions';
import * as fromFiltersActions from '../filters/filters.actions';
import { RhEvent } from '@app/events/models/event';
import { isWithinInterval, isSameDay } from 'date-fns';
import { LoadingState } from '@app/search/models/rh';

export const feature = 'calendar';

export const adapter: EntityAdapter<any> =
  createEntityAdapter<any>();

export interface State {
  selectedDay: Date;

  selectedDate: Date;
  calendarPageRange: {
    start: Date,
    end: Date
  }

  events: Array<Partial<RhEvent>>;
  loading: LoadingState;
}

export const initialState: State = {
  selectedDay: new Date(new Date().setHours(0, 0, 0, 0)),
  selectedDate: new Date(),
  calendarPageRange: {
    start: new Date(),
    end: new Date()
  },
  events: [],
  loading: LoadingState.LOADING_ALL
};

export const calendarReducer = createReducer(
  initialState,
  on(fromActions.changeSelectedDate, (state, {selectedDate}) => ({
    ...state,
    selectedDate
  })),
  on(fromActions.selectDay, (state, {day}) => ({
    ...state,
    selectedDay: day
  })),
  on(fromActions.setCalendarPageRange, (state, {start, end}) => ({
    ...state,
    calendarPageRange: {
      start,
      end
    }
  })),
  on(
    fromActions.getCalendarEventsRequest,
    fromFiltersActions.setSearch,
    fromFiltersActions.filtersChanged, (state) => ({
      ...state,
      loading: LoadingState.LOADING_ALL
  })),
  on(fromActions.getCalendarEventsSuccess, (state, {events}) => ({
    ...state,
    events,
    loading: LoadingState.FINISHED
  })),
  on(fromActions.getCalendarEventsFailure, (state) => ({
    ...state,
    loading: LoadingState.FINISHED
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return calendarReducer(state, action);
}

export const selectState = createFeatureSelector<State>(feature);
export const getSelectedDate = (state: State) => state.selectedDate;
export const getSelectedDay = (state: State) => state.selectedDay;
export const getCalendarPageRange = (state: State) => state.calendarPageRange;
export const getLoading = (state: State) => state.loading

export const getEvents = (state: State) => state.events;

export const getCountGroups = (events: Array<RhEvent>): {
  [date: string]: Array<RhEvent>
} => {
  const countGroups = events.reduce((acc, curr) => {
    const datetime = new Date(curr.date)
    const date = new Date(datetime.setHours(0, 0, 0, 0)).getTime();
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(curr);
    return acc;
  }, {});
  return countGroups;
}

export const getEventsByDate = (groups: {
  [date: string]: Array<RhEvent>
}, date: Date) => groups[new Date(date.setHours(0, 0, 0, 0)).getTime()] || [];

export const getTopNews = (events: Array<RhEvent>) => events.filter(e => e.topEvent);

export const getEventsForRange = (events: Array<RhEvent>, range: { start: Date, end: Date}) => events.filter(e => {
  if (!!range && !!range.start && !!range.end) {
    return isWithinInterval(new Date(e.date), {
      start:  range.start,
      end: range.end
    }) || isSameDay(new Date(e.date), range.end) || isSameDay(new Date(e.date), range.start)
  } else {
    return false
  }
});