import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromNewsletterForm from '@app/newsletter/store/newsletter-form';
import {ColorModel, NewsletterValueModel} from '@app/newsletter/models';
import {SubSink} from 'subsink';
import {distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'rh-newsletter-preview',
  templateUrl: './newsletter-preview.container.html',
  styleUrls: ['./newsletter-preview.container.scss']
})
export class NewsletterPreviewContainer implements OnInit, OnDestroy {
  private subs: SubSink = new SubSink();
  public formNewsletter$: Observable<NewsletterValueModel | null> = this.store$.pipe(
    select(fromNewsletterForm.selectFormNewsletter));
  @Input() selectedIndex: number;
  @Input() newsletter: NewsletterValueModel;
  @Input() overrideColor: ColorModel;
  @Input() newsboardFix = false;

  ngOnInit() {
    this.subs.sink = this.formNewsletter$.pipe(distinctUntilChanged()).subscribe((data => {
      if ( data ) {
        this.newsletter = data;
        this._changeDetRef.detectChanges();
      }
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  constructor(private store$: Store<fromNewsletterForm.State>, private _changeDetRef: ChangeDetectorRef) {
  }
}
