import { Component, Input } from '@angular/core';

@Component({
  selector: 'rh-rada-group-autocomplete-item',
  templateUrl: './rada-group-autocomplete-item.component.html',
  styleUrls: ['./rada-group-autocomplete-item.component.scss']
})
export class RadaGroupAutocompleteItemComponent {
  @Input() option: any;
  @Input() highlightPhrase: string;
}
