import { SimpleUser } from './newsboard';
import { Pagination } from './pagination.model';

export enum CommentStatus {
  VISIBLE = 0,
  HIDDEN = 1
}
export interface Comment {
  author: SimpleUser;
  text: string;
  canEdit: boolean;
  id: string;
  modifiedDate: Date;
  createdDate: Date;
  status: CommentStatus;
  responses?: Comment[];
}

export interface SendCommentRequest {
  contentId?: string;
  commentId?: boolean;
  parentCommentId?: string;
  text?: string;
  isAnonymous?: boolean;
}

export interface GetCommentsResponse extends Pagination {
  data: Comment[];
}

export interface CreateCommentResponse extends Pagination {
  comment: Comment;
}

export interface UpdateCommentResponse extends Pagination {
  comment: Comment;
}
