import { Pipe, PipeTransform } from '@angular/core';
import { RhEvent } from '@app/events/models/event';
import * as fromEvents from '@core/core-store/events/events.selectors';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Pipe({
  name: 'eventsForRange'
})
export class EventsForRangePipe implements PipeTransform {
  transform({start, end}): Observable<Array<Partial<RhEvent>>> {
    if (!start || !end) {
      return of([])
    }
    return this.store$.pipe(
      select(fromEvents.selectEventsAll(`${start.getTime()}:${end.getTime()}`)),
      distinctUntilChanged()
    );
  }

  constructor(
    private store$: Store<any>) {
  }
}
