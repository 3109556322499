import { createSelector } from '@ngrx/store';
import * as fromNewsletter from '../newsletter.reducer';
import * as fromReducer from './newsletter-websocket.reducer';


export const selectState = createSelector(
  fromNewsletter.selectState,
  fromNewsletter.getNewsletterWebSocket
);

export const selectNewsletters = createSelector(
  selectState,
  fromReducer.getNewsletters
);

export const selectAll = createSelector(
  selectState,
  fromReducer.getAll
);

export const selectEntities = createSelector(
  selectState,
  fromReducer.getEntities
);

export const selectIds = createSelector(
  selectState,
  fromReducer.getIds
);

export const selectTotal = createSelector(
  selectState,
  fromReducer.getTotal
);

export const selectTaken = createSelector(
  selectState,
  fromReducer.getTaken
);

export const selectEntityById = createSelector(
  selectEntities,
  fromReducer.getEntityById
);

export const selectEntitiesById = createSelector(
  selectEntities,
  fromReducer.getEntitiesById
);

export const selectIsWebSocketConnected = createSelector(
  selectState,
  fromReducer.getIsWebSocketConnected
);
