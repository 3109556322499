import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EndpointsService } from '@core/services/endpoints/endpoints.service';
import { ApiService } from '@core/services/api/api.service';

@Injectable()
export class EmailService {

  public getEmail(login: string): Observable<string> {
    const url = this.endpoints.ENDPOINT.EMAIL.GET;
    const urlWithToken = this.endpoints.replaceUrlTokens(url, { login });

    return this.http
      .get(urlWithToken)
      .pipe(
        map((email: string) => email),
        catchError((fault) => throwError(`Cannot get email. ${fault}`))
      );
  }

  constructor(private http: ApiService, private endpoints: EndpointsService) {}
}
