import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { RdsDialogService } from '@rds/angular-components';
import * as fromReducer from './campaign-form.reducer';
import { NewsletterService } from '@app/core/services/newsletter.service';
import * as fromActions from '@app/newsletter-new/store/campaign-form/campaign-form.actions';
import * as fromSelectors from '@app/newsletter-new/store/campaign-form/campaign-form.selectors';
import * as fromTemplatesFrom from '@app/newsletter-new/store/template-form';
import { filter, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as fromRouter from '@app/root-store/router';
import { RecipientsListConfirmDialogComponent } from '@app/newsletter-new/dialogs/recipients-list-confirm-dialog/recipients-list-confirm-dialog.component';
import * as fromUser from '@app/core/user/store';
import { createGuid } from '@shared/utils/guid';
import { TYPE_RECIPIENT } from '@app/newsletter-new/models/recipients-list';
import { EMPTY_CAMPAIGN_FORM, RhCampaignForm } from '@app/newsletter-new/models/campaign';
import * as fromNewsletterForm from '@app/newsletter-new/store/newsletter-form';

@Injectable()
export class CampaignFormEffects {
	public initForm$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.form.init),
				withLatestFrom(this.store$.pipe(select(fromUser.selectUserProfile))),
				map(([{ user }, userProfile]): Partial<RhCampaignForm> => {
					return {
						...EMPTY_CAMPAIGN_FORM,
						permissions: {
							editors: [],
							owners: [{ ...user, role: 'owners', firstName: userProfile.firstName, lastName: userProfile.lastName }],
						},
					};
				}),
				mergeMap((form) => [
					fromActions.form.setValue({ form: { ...form } }),
					fromActions.form.setInitialValue({ form: { ...form } }),
					fromActions.form.setChecked(),
				])
			),
		{ dispatch: true }
	);

	public submitForm$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.form.submit),
				withLatestFrom(this.store$.pipe(select(fromSelectors.selectCampaignForm))),
				switchMap(([{}, form]) =>
					this.newsletterService.createCampaign(form).pipe(
						switchMap(() => [
							fromActions.form.createSuccess({ campaign: form }),
							fromRouter.go({
								path: '/newsletter/campaigns',
								queryParams: {},
							}),
						]),
						catchError(({ message }) => of(fromActions.form.createFailure({ message })))
					)
				)
			),
		{ dispatch: true }
	);
	public getCampaign$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.getCampaignById.request),
				switchMap(({ id }) => {
					return this.newsletterService.getCampaign(id).pipe(
						map((campaign) =>
							fromActions.getCampaignById.success({
								campaign: {
									...campaign,
									newsletterTemplateId: campaign.newsletterTemplateId || campaign.newsletterTemplate?.id,
								},
							})
						),
						catchError(({ message }) => of(fromActions.getCampaignById.failure({ message })))
					);
				})
			),
		{ dispatch: true }
	);

	public openDetailsRecipientsList$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.dialog.openDetailsRecipientsList),
				switchMap(({ id }) => this.newsletterService.getRecipientsList(id)),
				map((results) => {
					let component = RecipientsListConfirmDialogComponent;
					const recipientUsers = results.recipients
						.filter((item) => item.type === TYPE_RECIPIENT.SINGLE)
						.map((user) => ({
							...user,
							id: createGuid(),
						}));
					const recipientRada = results.recipients.filter((item) => item.type === TYPE_RECIPIENT.GROUP);
					const blockUsers = results.blockedRecipients
						.filter((item) => item.type === TYPE_RECIPIENT.SINGLE)
						.map((user) => ({
							...user,
							id: createGuid(),
						}));
					const blockRada = results.blockedRecipients.filter((item) => item.type === TYPE_RECIPIENT.GROUP);

					const dialog = this.dialogService.open(component, {
						size: 'l',
						data: {
							form: {
								...results,
								recipientUsers,
								recipientRada,
								blockUsers,
								blockRada,
							},
							confirmButtonLabel: 'Edit recipient list',
							shortLabel: true,
						},
					});
					return { dialog, id: results.id };
				}),
				switchMap(({ dialog, id }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						switchMap(() => [
							fromRouter.goOutsideInNewTab({
								url: `/newsletter/recipients/${id}/edit`,
								redirect: true,
							}),
						])
					)
				)
			),
		{ dispatch: true }
	);

	public getTemplateById$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.getTemplateById.request),
				switchMap(({ id }) =>
					this.newsletterService.getTemplateById(id).pipe(
						switchMap((template) => [
							fromActions.form.setValue({
								form: {
									newsletterTemplate: {
										id: template.id,
										title: template.title,
										imagePreviewUrl: template.imagePreviewUrl,
									},
								},
							}),
							fromTemplatesFrom.form.setFormValue({
								form: {
									...template,
								},
							}),
							fromNewsletterForm.preparing.prepareContentFromTemplate({ template: template }),
						]),
						catchError(({ message }) => of(fromActions.getCampaignById.failure({ message })))
					)
				)
			),
		{ dispatch: true }
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromReducer.State>,
		private newsletterService: NewsletterService,
		private dialogService: RdsDialogService
	) {}
}
