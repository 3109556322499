// todo
import { Injectable } from '@angular/core';

import { fromEvent, merge, timer } from 'rxjs';
import { map, switchMapTo } from 'rxjs/operators';

import { createEffect } from '@ngrx/effects';
import * as fromIdleActions from './idle-timeout.actions';

@Injectable()
export class IdleTimeoutEffects {
  public clicks$ = fromEvent(document, 'click');
  public keys$ = fromEvent(document, 'keydown');
  public mouse$ = fromEvent(document, 'mousemove');

  public idleTimeout$ = createEffect(() =>
    merge(this.clicks$, this.keys$, this.mouse$).pipe(
      switchMapTo(timer(5 * 60 * 1000)), // 5 minute inactivity timeout
      map(() => fromIdleActions.idleTimeout())
    )
  );
}
