<h2 rds-dialog-header>
  Newsletter preview
</h2>
<div rds-dialog-icon-placeholder>
  <button rds-icon-button size="m" [rds-dialog-close]="null">
    <rds-icon icon="close" namespace="outlined"></rds-icon>
  </button>
</div>
<div rds-dialog-content>
  <div class="newsletter-preview__heading">
    <rds-tabs #tabs size="m" >
      <rds-tab label="Newsletter">
        <div class="newsletter-container mt-9 pb-11">
          <div class="newsletter" *ngIf="!!predefinedNewsletter">
            <ng-container [ngSwitch]="predefinedNewsletter.templateId">
              <rh-newsletter-preview-with-sections *ngSwitchCase="'NewsletterTemplateWithSections'"
                                                    [newsletter]="predefinedNewsletter"></rh-newsletter-preview-with-sections>
              <rh-newsletter-preview-without-sections *ngSwitchCase="'NewsletterTemplateWithoutSections'"
                                                       [newsletter]="predefinedNewsletter"></rh-newsletter-preview-without-sections>
            </ng-container>
          </div>

          <div class="newsletter" *ngIf="!!templateId && !!newsletterId">
            <rh-newsletter-preview-read-only *ngIf="templateId === 'NewsletterTemplateInlineEditor'" [sections]="sections | async" [form]="form | async"></rh-newsletter-preview-read-only>
            <rh-newsletter-preview *ngIf="templateId === 'NewsletterTemplateWithSections' || templateId === 'NewsletterTemplateWithoutSections'" [newsletter]="formNewsletter | async"></rh-newsletter-preview>
          </div>
          <div class="newsletter" *ngIf="context === 'ChangeTemplate'">
            <ng-container [ngSwitch]="activeTemplate?.current?.templateId">
              <rh-newsletter-preview-with-sections *ngSwitchCase="'NewsletterTemplateWithSections'"
                                                    [newsletter]="emptyNewsletterWithSections"
                                                    [overrideColor]="selectedColor"></rh-newsletter-preview-with-sections>
              <rh-newsletter-preview-without-sections *ngSwitchCase="'NewsletterTemplateWithoutSections'"
                                                       [newsletter]="emptyNewsletterWithoutSections"
                                                       [overrideColor]="selectedColor"></rh-newsletter-preview-without-sections>
            </ng-container>
          </div>
          <div class="newsletter-select" *ngIf="context === 'ChangeTemplate'">
            <ng-container *ngFor="let template of templates | async">
              <rh-templates-list-item [templateConfiguration]="template"
                [hideActions]="true"
                (click)="changeTemplate(template)"
                [class.selected]="template.templateId === activeTemplate?.current?.templateId"
              ></rh-templates-list-item>
            </ng-container>
          </div>
        </div>
      </rds-tab>
    </rds-tabs>
  </div>
</div>
<div rds-dialog-actions-container align="end">
  <ng-container [ngSwitch]="context">
    <ng-container *ngSwitchCase="'Editor'">
      <button *ngIf="buttons?.canSaveNewsletter?.visible" [disabled]="buttons?.canSaveNewsletter?.disabled" rds-primary-button [rds-dialog-close]="'saveNewsAsDraft'" size="m">
        Create newsletter
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'Dashboard'">
      <button *ngIf="buttons?.editNewsletter?.visible" [disabled]="buttons?.editNewsletter?.disabled" rds-primary-button [routerLink]="'/newsletter/form/'+newsletterId+'/edit'" [rds-dialog-close]="null" size="m">
        Edit newsletter
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'Template'">
      <button rds-primary-button [routerLink]="'/newsletter/form/'+newsletterId" [rds-dialog-close]="null" size="m">
        Use this template
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'SharedTemplate'">
      <button rds-text-button [rds-dialog-close]="'rejectTemplate'" size="m">
        Reject
      </button>
      <button rds-secondary-button [rds-dialog-close]="'acceptTemplate'" size="m">
        Accept
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'PredefinedTemplate'">
      <button rds-primary-button [routerLink]="'/newsletter/form'" [queryParams]="{templateId: templateId}" [rds-dialog-close]="null" size="m">
        Use this template
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'ReuseNewsletter'">
      <button rds-primary-button [rds-dialog-close]="true" size="m">
        Reuse this Newsletter
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'SaveAsTemplate'">
      <button rds-primary-button [rds-dialog-close]="true" size="m">
        Save as template
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'ChangeTemplate'">
      <button rds-primary-button [disabled]="templateId === activeTemplate?.current?.templateId" [rds-dialog-close]="activeTemplate?.current" size="m">
        Change template
      </button>
    </ng-container>
  </ng-container>
</div>