import {Taxonomy} from '@app/core/models';
import {NewsImage} from '@app/core/models/newsboard';
import {UserSearch} from '@app/core/models/user-search.model';

export type EventStatus = EventStatusEnum

export enum EventStatusEnum {
  DRAFT = 0,
  PUBLISHED = 1
}

export enum RhEventType {
  ON_SITE = 0,
  VIRTUAL = 1,
  HYBRID = 2,
  OFF_SITE = 3
}

export interface RhEventGeneral {
  title: string;
  allDay: boolean;
  date: Date,
  startDate: Date,
  endDate?: Date,
  startTime: Date | string;
  endTime: Date | string;
  timeZone: string;
  type: RhEventType;
  eventType?: RhEventType;
  location: string;
  meetingUrl?: string;
  eventUrl?: string;
  registrationUrl?: string;
  registration?: boolean;
  topEvent?: boolean;
  eventOwners: Array<UserSearch>;
}

export interface RhEventImage {
  imageName: string;
  imageSize: number;
  imageUrl: string;
}

export interface RhEventFormImage {
  image: NewsImage
}

export interface RhEventContent {
  description: string;
  contact?: string;
  content?: string;
  host?: string;
  topics: Array<string>;
}

export interface RhEventAudience {
  locations: Array<Taxonomy | string>;
  departments: Array<Taxonomy | string>;
  functions: Array<Taxonomy | string>;
}

export interface RhEvent extends RhEventGeneral, RhEventImage, RhEventContent, RhEventAudience {
  createdDate: Date;
  id: number;
  status?: EventStatus
}

export interface RhEventForm extends RhEventGeneral, RhEventFormImage, RhEventContent, RhEventAudience {
  createdDate: Date;
  id: number;
  status?: EventStatus
}

export type RhEventToRequest = RhEvent | {
  date: string;
}

export const EMPTY_EVENT_FORM: Partial<RhEventForm> = {
  id: null,
  title: null,
  allDay: false,
  date: null,
  startTime: null,
  endTime: null,
  timeZone: null,
  type: null,
  eventType: null,
  location: null,
  meetingUrl: null,
  registrationUrl: null,
  registration: false,
  eventUrl: null,
  topEvent: false,
  eventOwners: [],
  image: null,
  description: null,
  contact: null,
  content: null,
  host: null,
  topics: [],
  locations: [],
  departments: [],
  functions: [],
}
