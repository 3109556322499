import { createReducer, Action, on} from '@ngrx/store';
import { createEntityAdapter, Dictionary, EntityAdapter } from '@ngrx/entity';
import * as fromActions from './admin.actions';

import { UserLogin } from '../../admin.service';
import { Pagination } from '@app/core/models';
import { AdminTableFilters, INITIAL_ADMIN_FILTERS, TableState } from '@app/shared/filters/filters-model';

export const feature = 'details';


export const adapter: EntityAdapter<Partial<UserLogin>> =
 createEntityAdapter<Partial<UserLogin>>({
  selectId: (e) => e.identifier
 });

export interface State {
  admins: TableState<Partial<UserLogin>, AdminTableFilters>;
}

export const initialState: State = {
  admins: {
    data: adapter.getInitialState(),
    pagination: {
      ...Pagination,
      pageIndex: 0,
      pageSize: 10,
    },
    sort: {
      active: 'identifier',
      direction: 'asc'
    },
    defaultSort: {
      active: 'identifier',
      direction: 'asc'
    },
    filters: INITIAL_ADMIN_FILTERS,
    defaultFilters: INITIAL_ADMIN_FILTERS,
    isLoading: false,
    resetIndex: false,
  },
};

const tableReducer = createReducer(
  initialState,
  on(fromActions.getAdminsRequest, (state) => ({
    ...state,
    admins: {
      ...state.admins,
      isLoading: true
    }
  })),
  on(fromActions.getAdminsSuccess, fromActions.getAdminsFailure, (state) => ({
    ...state,
    admins: {
      ...state.admins,
      isLoading: false
    }
  })),
  on(fromActions.getAdminsSuccess, (state, {data, pagination}) => ({
    ...state,
    admins: {
      ...state.admins,
      data: adapter.setAll(data, state.admins.data),
      pagination,
      resetIndex: false,
    }
  })),
  on(fromActions.deleteAdminSuccess, fromActions.addAdminSuccess, fromActions.promoteAdminSuccess, (state) => ({
    ...state,
    admins: {
      ...state.admins,
      resetIndex: true
    }
  })),
  on(fromActions.changeAdminsFilters, (state, {filters}) => ({
    ...state,
    admins: {
      ...state.admins,
      filters,
      resetIndex: true
    }
  })),
  on(fromActions.changeAdminsSort, (state, {active, direction}) => ({
    ...state,
    admins: {
      ...state.admins,
      sort: {
        active,
        direction
      }
    }
  })),
  on(fromActions.changeAdminsPagination, (state, {pageIndex, pageSize}) => ({
    ...state,
    admins: {
      ...state.admins,
      pagination: {
        ...state.admins.pagination,
        pageIndex,
        pageSize
      }
    }
  })),
  on(fromActions.clearAll, (state) => ({
    ...initialState
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return tableReducer(state, action);
}


export const getAdmins = (state: State) => state.admins;

export const getPagination = (table: TableState<Partial<UserLogin>, AdminTableFilters>) => table.pagination;
export const getSort = (table: TableState<Partial<UserLogin>, AdminTableFilters>) => table.sort;
export const getFilters = (table: TableState<Partial<UserLogin>, AdminTableFilters>) => table.filters;
export const getDefaultFilters = (table: TableState<Partial<UserLogin>, AdminTableFilters>) => table.defaultFilters;
export const getIsLoading = (table: TableState<Partial<UserLogin>, AdminTableFilters>) => table.isLoading;

export const getRequestData = ({pagination, sort, filters, resetIndex}: TableState<Partial<UserLogin>, AdminTableFilters>) => ({
  pageIndex: pagination.pageIndex,
  pageSize: pagination.pageSize,
  sort,
  filters,
  resetIndex});

export const getTableData = (table: TableState<Partial<UserLogin>, AdminTableFilters>) => adapter.getSelectors().selectAll(table.data);

// Pagination fields
export const getPageSize = (pagination: Pagination) => pagination.pageSize;
export const getPageIndex = (pagination: Pagination) => pagination.pageIndex;
export const getTotalCount = (pagination: Pagination) => pagination.totalCount;

// Filters fields
// export const getSearch = (filters: AdminTableFilters) => filters.search.value;

export const getEntitiesByIds = (entities: Dictionary<UserLogin>, ids: string[]): UserLogin[] => ids.map(id => entities[id]);
