import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import * as fromCatalogs from '@app/layout/header/store/catalogs';
import {select, Store} from '@ngrx/store';
import {Category} from '@app/layout/header/models';
import {UntypedFormBuilder} from '@angular/forms';
import {SubSink} from 'subsink';
import * as fromCategoriesFlat from '@core/core-store/categories-flat';
import * as fromUiScreen from '@app/root-store/ui/screen';
import {map, take, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {CategoryFlat} from '@app/core/models';
import {CatalogHeaderFilters} from '@app/shared/filters/models/catalog-header';

@Component({
  selector: 'rh-header-content-details',
  templateUrl: './header-content-details.component.html',
  styleUrls: ['./header-content-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderContentDetailsComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  public isMobile: true | false;

  allCategories$!: Observable<Array<{ name: string, categoryId: string | number }>>;

  _category: Category;

  @Input() set category(value: Category) {
    this._category = value
    this.allCategories$ = this.store$.pipe(
      select(fromCategoriesFlat.selectBranchWithChildrens(this._category.categoryId)),
      take(1),
      map((categoriesFlat: Array<CategoryFlat>) => {
        const mappedCategories = categoriesFlat.map(c => ({
          name: c.name,
          categoryId: c.categoryId
        }));

        return [
          {
            name: this.category.name,
            categoryId: this.category.categoryId
          },
          ...mappedCategories
        ];
      })
    );
  };

  get category() {
    return this._category;
  }


  filters$: Observable<CatalogHeaderFilters> = this.store$.pipe(select(fromCatalogs.selectFilters));
  defaultFilters$: Observable<CatalogHeaderFilters> = this.store$.pipe(select(fromCatalogs.selectDefaultFilters));
  isLoading$ = this.store$.pipe(select(fromCatalogs.selectIsLoading));
  catalogsLength$ = this.store$.pipe(select(fromCatalogs.selectCatalogsLength));

  backToCategories() {
    this.store$.dispatch(fromCatalogs.backToCategories());
  }

  advancedFiltersChanged(event) {
    this.store$.dispatch(fromCatalogs.updateFilters({filters: event}));
  }

  ngOnInit(): void {
    this.subs.sink = this.store$.pipe(
      select(fromUiScreen.selectIsMobile), tap((res) => this.isMobile = res)).subscribe();
  }

  constructor(
    private store$: Store<fromCatalogs.State>,
    private fb: UntypedFormBuilder
  ) {
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
