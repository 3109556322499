import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {EndpointsService} from '@app/core/services/endpoints/endpoints.service';
import {ApiService} from '@app/core/services/api/api.service';
import {ContentItem} from '@core/models';
import {map} from 'rxjs/operators';


@Injectable()
export class BookmarksService {
  public getBookmarks(pageIndex?, pageSize?): Observable<{ data: ContentItem[] }> {
    const url = this.endpoints.ENDPOINT.CONTENT.BOOKMARK.GET;

    return this.http
      .get(this.endpoints.addParams(url, {pageSize, pageIndex})).pipe(map(response => {
        return {
          ...response, data: response.data.map((item, index) => {
            return {
              ...item,
              order: pageIndex * pageSize + index
            };
          })
        };
      }));
  }

  constructor(
    private http: ApiService,
    private endpoints: EndpointsService,
  ) {
  }
}
