<h2 rds-dialog-header>
  <ng-container *ngIf="data.anchors.length > 0">
    Edit Table of Content
  </ng-container>
  <ng-container *ngIf="data.anchors.length === 0">
    Add Table of Content
  </ng-container>
</h2>
<div rds-dialog-content style="overflow-x: hidden">
	<ng-container [formGroup]="tableOfContentForm">
		<div class="row">
			<div class="col">
				<p class="ui-body-l-bold--xl mb-3">Title</p>
				<rds-form-field class="mb-7">
					<rds-control-label>Section title</rds-control-label>
					<input rdsInput formControlName="title" />
				</rds-form-field>
			</div>
		</div>
		<ng-container *ngFor="let anchor of anchorsArray.controls; let i = index" [formGroup]="anchor">
			<div class="row">
				<div class="col mt-5">
					<h4 *ngIf="!data.settings.anchor" class="ui-heading-4 mb-3">Anchor {{ i + 1 }}</h4>
					<div class="mb-5">
						<rds-form-field>
							<rds-control-label>Anchor label</rds-control-label>
							<input type="text" rdsInput formControlName="label" />
						</rds-form-field>
					</div>
				</div>
			</div>
		</ng-container>
		<div class="row">
			<div class="col">
				<button rds-text-button (click)="createAnchorsForm()">Add next Anchor</button>
			</div>
		</div>
	</ng-container>
</div>
<div rds-dialog-actions-container align="end">
	<button rds-secondary-button [rds-dialog-close]="null" size="m">Cancel</button>
	<button rds-primary-button [disabled]="tableOfContentForm.invalid" size="m" (click)="submit()">Save changes</button>
</div>
