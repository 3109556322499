import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'countText'
})
export class CountTextPipe implements PipeTransform {
  transform(value: string) {
    const p = document.createElement('p');
    p.innerHTML = value;
    return p.textContent.length;
  }
}
