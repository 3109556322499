import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './quicklinks-sets-form.actions';

import { CatalogResult, EMPTY_QL_SET_FORM, QuicklinksSetForm } from '@app/core/models';
import { AudienceManagerInfo } from '../manage-sets.reducer';

export const feature = 'quicklinksSetsTable';

export interface State {
  form: Partial<QuicklinksSetForm>;
  initialForm: Partial<QuicklinksSetForm>;
  isNameUnique: boolean;
  filteredCatalogs: Array<CatalogResult>;
  filteredCatalogsLoading: boolean;
  audienceManagerInfo: AudienceManagerInfo;
  touched: boolean
}

export const initialState: State = {
  form: EMPTY_QL_SET_FORM,
  initialForm: EMPTY_QL_SET_FORM,
  isNameUnique: true,
  filteredCatalogs: [],
  filteredCatalogsLoading: false,
  audienceManagerInfo: {
    isAudienceManager: false,
    isOnlyAudienceManager: false,
    managedTaxonomy: {
      locations: [],
      departments: [],
      functions: []
    }
  },
  touched: false
};

const tableReducer = createReducer(
  initialState,
  on(fromActions.initAddForm, (state, { quicklinksSet, audienceManagerInfo }) => quicklinksSet? ({
    ...state,
    form: {
      ...state.form,
      id: quicklinksSet.id,
      name: quicklinksSet.name,
      catalogs: {
        ...state.form.catalogs,
        selected: [
          ...quicklinksSet.topCatalogs,
          ...quicklinksSet.catalogs,
        ]
      },
      audience: {
        locations: quicklinksSet.locations.map(t => t.id),
        departments: quicklinksSet.departments.map(t => t.id),
        functions: quicklinksSet.functions.map(t => t.id),
      }
    },
    initialForm: {
      ...state.form,
      id: quicklinksSet.id,
      name: quicklinksSet.name,
      catalogs: {
        ...state.form.catalogs,
        selected: [
          ...quicklinksSet.topCatalogs,
          ...quicklinksSet.catalogs
        ]
      },
      audience: {
        locations: quicklinksSet.locations.map(t => t.id),
        departments: quicklinksSet.departments.map(t => t.id),
        functions: quicklinksSet.functions.map(t => t.id),
      }
    },
    audienceManagerInfo
  }) : ({
    ...state,
    form: {
      ...state.form,
      audience: {
        ...state.form.audience,
        locations: audienceManagerInfo.managedTaxonomy.locations,
        departments: audienceManagerInfo.managedTaxonomy.departments,
        functions: audienceManagerInfo.managedTaxonomy.functions,
      }
    },
    initialForm: {
      ...state.form,
      audience: {
        ...state.form.audience,
        locations: audienceManagerInfo.managedTaxonomy.locations,
        departments: audienceManagerInfo.managedTaxonomy.departments,
        functions: audienceManagerInfo.managedTaxonomy.functions,
      }
    },
    audienceManagerInfo
  })),
  on(fromActions.setFormValue, (state, { form }) => ({
    ...state,
    form: {
      ...state.form,
      ...form
    }
  })),
  on(fromActions.setFormTouched, (state, { touched }) => ({
    ...state,
    touched
  })),
  on(fromActions.isNameUniqueSuccess, (state, {isUnique}) => ({
    ...state,
    isNameUnique: isUnique
  })),
  on(fromActions.getFilteredCatalogsRequest, (state) => ({
    ...state,
    filteredCatalogsLoading: true
  })),
  on(fromActions.getFilteredCatalogsSuccess, fromActions.getFilteredCatalogsFailure, (state) => ({
    ...state,
    filteredCatalogsLoading: false
  })),
  on(fromActions.getFilteredCatalogsSuccess, (state, { catalogs }) => ({
    ...state,
    filteredCatalogs: catalogs
  })),
  on(fromActions.clearFilteredCatalogs, (state) => ({
    ...state,
    filteredCatalogs: [],
    filteredCatalogsLoading: false
  })),
  on(fromActions.clearAll, (state) => ({
    ...initialState
  })),

);

export function reducer(state: State | undefined, action: Action) {
  return tableReducer(state, action);
}

export const getForm = (state: State) => state.form;
export const getInitialForm = (state: State) => state.initialForm;
export const getId = (form: Partial<QuicklinksSetForm>) => form.id;
export const getTouched = (state: State) => state.touched;
export const getIsNameUnique = (state: State) => state.isNameUnique;

export const getAudienceManagerInfo = (state: State) => state.audienceManagerInfo;

export const getCatalogsFilters = (form: QuicklinksSetForm) => form.catalogs.filters;
export const getSelectedCatalogs = (form: QuicklinksSetForm) => form.catalogs.selected;
export const getFilteredCatalogs = (state: State) => state.filteredCatalogs;
export const getFilteredCatalogsLoading = (state: State) => state.filteredCatalogsLoading;
