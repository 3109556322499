import ClassicEditorUIView from '@ckeditor/ckeditor5-editor-classic/src/classiceditoruiview';
import { EditorUI,  } from 'ckeditor5/src/ui';

// The class organizing the UI of the editor, binding it with existing Bootstrap elements in the DOM.
export default class RdsEditorUI extends EditorUI {
    constructor( editor ) {
        super( editor );

        // A helper to easily replace the editor#element with editor.editable#element.
        this._elementReplacer = new ElementReplacer();

        // The global UI view of the editor. It aggregates various Bootstrap DOM elements.
        const view = this._view = new ClassicEditorUIView( editor.locale );

        // This is the main editor element in the DOM.
        view.element = $( 'ckeditor' );

        // This is the editable view in the DOM. It will replace the data container in the DOM.
        view.editable = new InlineEditableUIView( editor.locale, editor.editing.view );

        // References to the dropdown elements for further usage. See #_setupBootstrapHeadingDropdown.
        view.dropdownMenu = view.element.find( '.dropdown-menu' );
        view.dropdownToggle = view.element.find( '.dropdown-toggle' );

        // References to the toolbar buttons for further usage. See #_setupBootstrapToolbarButtons.
        view.toolbarButtons = {};

        [ 'bold', 'italic', 'underline', 'undo', 'redo' ].forEach( name => {
            // Retrieve the jQuery object corresponding with the button in the DOM.
            view.toolbarButtons[ name ] = view.element.find( `#${ name }` );
        } );
    }

    // All EditorUI subclasses should expose their view instance
    // so other UI classes can access it if necessary.
    get view() {
        return this._view;
    }

    init( replacementElement ) {
        const editor = this.editor;
        const view = this.view;
        const editingView = editor.editing.view;

        // Make sure the EditorUIView is rendered. This will, for instance, create a place for UI elements
        // like floating panels detached from the main editor UI in DOM.
        this._view.render();

        // Create an editing root in the editing layer. It will correspond with the
        // document root created in the constructor().
        const editingRoot = editingView.document.getRoot();

        // The editable UI and editing root should share the same name.
        view.editable.name = editingRoot.rootName;

        // Render the editable component in the DOM first.
        view.editable.render();

        const editableElement = view.editable.element;

        // Register editable element so it is available via getEditableElement() method.
        this.setEditableElement( view.editable.name, editableElement );

        // Let the editable UI element respond to the changes in the global editor focus tracker
        // and let the focus tracker know about the editable element.
        this.focusTracker.add( editableElement );
        view.editable.bind( 'isFocused' ).to( this.focusTracker );

        // Bind the editable UI element to the editing view, making it an end– and entry–point
        // of the editor's engine. This is where the engine meets the UI.
        editingView.attachDomRoot( editableElement );

        // Setup the existing, external Bootstrap UI so it works with the rest of the editor.
        this._setupBootstrapToolbarButtons();
        this._setupBootstrapHeadingDropdown();

        // Replace the editor#element with editor.editable#element.
        this._elementReplacer.replace( replacementElement, editableElement );

        // Tell the world that the UI of the editor is ready to use.
        this.fire( 'ready' );
    }

    destroy() {
        super.destroy();

        // Restore the original editor#element.
        this._elementReplacer.restore();

        // Destroy the view.
        this._view.editable.destroy();
        this._view.destroy();
    }

// This method activates Bold, Italic, Underline, Undo and Redo buttons in the toolbar.
_setupBootstrapToolbarButtons() {
    const editor = this.editor;

    for ( const name in this.view.toolbarButtons ) {
        // Retrieve the editor command corresponding with the ID of the button in the DOM.
        const command = editor.commands.get( name );
        const button = this.view.toolbarButtons[ name ];

        // Clicking the buttons should execute the editor command...
        button.click( () => editor.execute( name ) );

        // ...but it should not steal the focus so the editing is uninterrupted.
        button.mousedown( evt => evt.preventDefault() );

        const onValueChange = () => {
            button.toggleClass( 'active', command.value );
        };

        const onIsEnabledChange = () => {
            button.attr( 'disabled', () => !command.isEnabled );
        };

        // Commands can become disabled, e.g. when the editor is read-only.
        // Make sure the buttons reflect this state change.
        command.on( 'change:isEnabled', onIsEnabledChange );
        onIsEnabledChange();

        // Bold, Italic and Underline commands have a value that changes
        // when the selection starts in an element the command creates.
        // The button should indicate that e.g. you are editing text which is already bold.
        if ( !new Set( [ 'undo', 'redo' ] ).has( name ) ) {
            command.on( 'change:value', onValueChange );
            onValueChange();
        }
    }
}

// This method activates the headings dropdown in the toolbar.
_setupBootstrapHeadingDropdown() {
    const editor = this.editor;
    const dropdownMenu = this.view.dropdownMenu;
    const dropdownToggle = this.view.dropdownToggle;

    // Retrieve the editor commands for heading and paragraph.
    const headingCommand = editor.commands.get( 'heading' );
    const paragraphCommand = editor.commands.get( 'paragraph' );

    // Create a dropdown menu entry for each heading configuration option.
    editor.config.get( 'heading.options' ).map( option => {
        // Check if options is a paragraph or a heading as their commands differ slightly.
        const isParagraph = option.model === 'paragraph';

        // Create the menu item DOM element.
        const menuItem = $(
            `<a href="#" class="dropdown-item heading-item_${ option.model }">` +
                `${ option.title }` +
            '</a>'
        );

        // Upon click, the dropdown menu item should execute the command and focus
        // the editing view to keep the editing process uninterrupted.
        menuItem.click( () => {
            const commandName = isParagraph ? 'paragraph' : 'heading';
            const commandValue = isParagraph ? undefined : { value: option.model };

            editor.execute( commandName, commandValue );
            editor.editing.view.focus();
        } );

        dropdownMenu.append( menuItem );

        const command = isParagraph ? paragraphCommand : headingCommand;

        // Make sure the dropdown and its items reflect the state of the
        // currently active command.
        const onValueChange = isParagraph ? onValueChangeParagraph : onValueChangeHeading;
        command.on( 'change:value', onValueChange );
        onValueChange();

        // Heading commands can become disabled, e.g. when the editor is read-only.
        // Make sure the UI reflects this state change.
        command.on( 'change:isEnabled', onIsEnabledChange );

        onIsEnabledChange();

        function onValueChangeHeading() {
            const isActive = !isParagraph && command.value === option.model;

            if ( isActive ) {
                dropdownToggle.children( ':first' ).text( option.title );
            }

            menuItem.toggleClass( 'active', isActive );
        }

        function onValueChangeParagraph() {
            if ( command.value ) {
                dropdownToggle.children( ':first' ).text( option.title );
            }

            menuItem.toggleClass( 'active', command.value );
        }

        function onIsEnabledChange() {
            dropdownToggle.attr( 'disabled', () => !command.isEnabled );
        }
    } );
}
}