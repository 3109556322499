import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'SafeHtml' })
export class SafeHtmlPipe implements PipeTransform {

  transform(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  constructor(public sanitizer: DomSanitizer) {}
}
