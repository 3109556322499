import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNowStrict } from 'date-fns';

@Pipe({name: 'timeAgo'})
export class TimeAgoPipe implements PipeTransform {

    transform(value: Date): string {
        if (!value) {
            return '';
        }
        return `${formatDistanceToNowStrict(new Date(value))} ago`;
    }

    constructor() { }
}
