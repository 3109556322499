import { Component, Input } from '@angular/core';
import { RdsAvatarSize } from '@rds/angular-components';

@Component({
  selector: 'rh-user-single',
  templateUrl: './user-single.component.html',
  styles: []
})
export class UserSingleComponent {

  @Input() size: RdsAvatarSize = 'm'; 
  @Input() disableTooltip = false;
  @Input() customTooltip;
  @Input() chipsAvatar: boolean = false;

  public _user: {
    login: string;
    name: string;
    photoUrl: string;
  };
  @Input() fetchPhoto: boolean = true;

  @Input() set user(value: any) {
    const name = (value.name || (value.firstName && value.lastName ? `${value.firstName} ${value.lastName}` : null))
    this._user = {
      login: (value.login || value.identifier || value.username) as string,
      name,
      photoUrl: value.photoUrl || value.avatar
    }
  }

  get user() {
    return this._user;
  }
}
