import { Block} from './block-type.enum';

export enum SectionTypes {
  SINGLE = 'SINGLE',
  TWO_EQUAL = 'TWO_EQUAL',
  THREE_IN_ONE = 'THREE_IN_ONE',
  ONE_IN_THREE = 'ONE_IN_THREE'
}

export interface Section {
  id: string;
  type: SectionTypes;
  subsections: Array<Subsection>;
}

export interface Subsection {
  id: string;
  colspan: string;
  blocks: Array<Block>;
}
