import {Component} from '@angular/core';
import * as fromUser from '@app/core/user/store';
import {UserProfile} from '@core/models';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

@Component({
  selector: 'rh-header-gcs-menu',
  templateUrl: './header-gcs-menu.container.html',
  styleUrls: ['./header-gcs-menu.container.scss'],
})
export class HeaderGcsMenuContainer {
  public opened: true | false = false;
  private account = [192, 'Account', '0 -1587px',
    'https://myaccount.google.com/?utm_source\u003dOGB\u0026authuser\u003d0\u0026utm_medium\u003dapp', '_blank', null, null,
    'https://lh3.google.com/u/0/ogw/ADea4I5ugQAb2QL-OWRXsBL5V7NwYGMLiubGRRbJfKDt\u003ds128-b16-cc-rp-mo'];
  public data = [
    [
      [...this.account],
      [23, 'Gmail', '0 -2001px', 'https://mail.google.com/mail/?authuser\u003d0', '_blank', null, null, ''],
      [49, 'Drive', '0 -2967px', 'https://drive.google.com/?authuser\u003d0', '_blank', null, null, ''],
      [25, 'Docs', '0 -1242px', 'https://docs.google.com/document/?usp\u003ddocs_ald\u0026authuser\u003d0', '_blank', null, null, ''],
      [283, 'Sheets', '0 -2208px', 'https://docs.google.com/spreadsheets/?usp\u003dsheets_ald\u0026authuser\u003d0', '_blank', null, null, ''],
      [281, 'Slides', '0 -2277px', 'https://docs.google.com/presentation/?usp\u003dslides_ald\u0026authuser\u003d0', '_blank', null, null, ''],
      [24, 'Calendar', '0 -2553px', 'https://calendar.google.com/calendar?authuser\u003d0', '_blank', null, null, ''],
      [385, 'Chat', '0 -1656px', 'https://chat.google.com/?authuser\u003d0', '_blank', null, null, ''],
      [411, 'Meet', '0 -690px', 'https://meet.google.com?hs\u003d197\u0026pli\u003d1\u0026authuser\u003d0', '_blank', null, null, ''],
      [330, 'Forms', '0 -2139px', 'https://docs.google.com/forms/?authuser\u003d0\u0026usp\u003dforms_ald', '_blank', null, null, ''],
      [38, 'Sites', '0 -1104px', 'https://sites.google.com/?authuser\u003d0', '_blank', null, null, ''],
      [53, 'Contacts', '0 -2898px', 'https://contacts.google.com/?hl\u003den\u0026authuser\u003d0', '_blank', null, null, '']],
    [
      [3, 'Groups', '0 -897px', 'https://groups.google.com/d/homeredir?hl\u003den\u0026authuser\u003d0', '_blank', null, null, ''],
      [8, 'Maps', '0 -483px', 'https://maps.google.com/?authuser=0', '_blank', null, null, ''],
      [136, 'Keep', '0 -276px', 'https://keep.google.com/u/0', '_blank', null, null, ''],
      [357, 'Jamboard', '0 -2070px', 'https://jamboard.google.com/?authuser\u003d0\u0026usp\u003djam_ald', '_blank', null, null, ''],
      [258, 'Cloud Search', '0 -1449px', 'https://cloudsearch.google.com/cloudsearch?authuser\u003d0', '_blank', null, null, ''],
      [429, 'Earth', '0 -1380px', 'https://earth.google.com/web/?authuser\u003d0', '_blank', null, null, ''],
      [338, 'Collections', '0 -621px', 'https://www.google.pl/save?authuser\u003d0', '_blank', null, null, ''],
      [475, 'Podcasts', '0 -552px', 'https://podcasts.google.com/?authuser\u003d0', '_blank', null, null, ''],
      [405, 'Travel', '0 -3036px', 'https://www.google.com/travel/?dest_src\u003dal\u0026authuser\u003d0', '_blank', null, null, '']
    ]
  ];


  public userProfile$: Observable<UserProfile> = this.store$.pipe(
    select(fromUser.selectUserProfile));


  onMenuClick(): void {
    this.opened = !this.opened;
  }

  clickOutside(): void {
    this.opened = false;
  }

  constructor(private store$: Store<fromUser.State>) {
  }
}
