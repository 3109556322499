import { createSelector } from '@ngrx/store';
import * as fromSearchResults from './search.reducer';

export const selectState = createSelector(
  fromSearchResults.selectState,
  fromSearchResults.getSearch
);

export const selectPhrase = createSelector(
  selectState,
  fromSearchResults.getPhrase
);
