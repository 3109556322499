import { StatisticItemModel } from '@app/newsletter/models';
import { TableState } from '@app/shared/filters/filters-model';
import { Pagination } from '@core/models';
import { Dictionary, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './newsletter-statistics.actions';

export const adapter: EntityAdapter<Partial<StatisticItemModel>> =
 createEntityAdapter<Partial<StatisticItemModel>>({
  selectId: (e) => e.url
 });

 export interface State {
  id: number;
  statistics: TableState<Partial<StatisticItemModel>>;
}

export const initialState: State = {
  id: null,
  statistics: {
    data: adapter.getInitialState(),
    pagination: {
      ...Pagination,
      pageIndex: 0,
      pageSize: 10,
    },
    sort: {
      active: 'numberofclicks',
      direction: 'desc'
    },
    defaultSort: {
      active: 'numberofclicks',
      direction: 'desc'
    },
    isLoading: false,
    resetIndex: false,
  },
};

export const newsletterStatisticsReducer = createReducer(
  initialState,
  on(fromActions.initStatistics, (state, {id}) => ({
    ...state,
    id
  })),
  on(fromActions.getStatisticsRequest, (state) => ({
    ...state,
    statistics: {
      ...state.statistics,
      isLoading: true
    }
  })),
  on(fromActions.getStatisticsSuccess, fromActions.getStatisticsFailure, (state) => ({
    ...state,
    statistics: {
      ...state.statistics,
      isLoading: false
    }
  })),
  on(fromActions.getStatisticsSuccess, (state, {data, pagination}) => ({
    ...state,
    statistics: {
      ...state.statistics,
      data: adapter.setAll(data, state.statistics.data),
      pagination,
      resetIndex: false,
    }
  })),
  
  on(fromActions.changeStatisticsSort, (state, {active, direction}) => ({
    ...state,
    statistics: {
      ...state.statistics,
      sort: {
        active,
        direction
      }
    }
  })),
  on(fromActions.changeStatisticsPagination, (state, {pageIndex, pageSize}) => ({
    ...state,
    statistics: {
      ...state.statistics,
      pagination: {
        ...state.statistics.pagination,
        pageIndex,
        pageSize
      }
    }
  })),
 
  on(fromActions.clearAll, (state) => ({
    ...initialState
  })),
  );

export function reducer(state: State | undefined, action: Action) {
  return newsletterStatisticsReducer(state, action);
}

export const getId = (state: State) => state.id;
export const getStatistics = (state: State) => state.statistics;

export const getPagination = (table: TableState<Partial<StatisticItemModel>>) => table.pagination;
export const getSort = (table: TableState<Partial<StatisticItemModel>>) => table.sort;
export const getIsLoading = (table: TableState<Partial<StatisticItemModel>>) => table.isLoading;

export const getRequestData = ({pagination, sort, resetIndex}: TableState<Partial<StatisticItemModel>>) => ({
  pageIndex: pagination.pageIndex,
  pageSize: pagination.pageSize,
  sort,
  resetIndex});

export const getTableData = (table: TableState<Partial<StatisticItemModel>>) => adapter.getSelectors().selectAll(table.data);

// Pagination fields
export const getPageSize = (pagination: Pagination) => pagination.pageSize;
export const getPageIndex = (pagination: Pagination) => pagination.pageIndex;
export const getTotalCount = (pagination: Pagination) => pagination.totalCount;

// Filters fields
export const getEntitiesByIds = (entities: Dictionary<StatisticItemModel>, ids: string[]): StatisticItemModel[] => ids.map(id => entities[id]);
