import { createSelector } from '@ngrx/store';

import * as fromChannel from '../news.reducer';
import * as fromReducer from './related-news.reducer';

export const selectState = createSelector(
  fromChannel.selectState,
  fromChannel.getRelatedNews
);

// [ALL NEWS]
export const selectRelatedNewsState = createSelector(
  selectState,
  fromReducer.getRelatedNews
);

export const selectRelatedNewsRequestData = createSelector(
  selectRelatedNewsState,
  fromReducer.getRequestData
);

export const selectRelatedNewsData = createSelector(
  selectRelatedNewsState,
  fromReducer.getNewsData
);

export const selectSelectedNews = createSelector(
  selectState,
  fromReducer.getSelectedNews
);

export const selectRelatedNewsPagination = createSelector(
  selectRelatedNewsState,
  fromReducer.getPagination
);

export const selectRelatedNewsTotal = createSelector(
  selectRelatedNewsPagination,
  fromReducer.getTotalCount
);

export const selectRelatedNewsSort = createSelector(
  selectRelatedNewsState,
  fromReducer.getSort
);

export const selectRelatedNewsDefaultSort = createSelector(
  selectRelatedNewsState,
  fromReducer.getDefaultSort
);

export const selectRelatedNewsFilters = createSelector(
  selectRelatedNewsState,
  fromReducer.getFilters
);

export const selectRelatedNewsDefaultFilters = createSelector(
  selectRelatedNewsState,
  fromReducer.getDefaultFilters
);

export const selectRelatedNewsSearch = createSelector(
  selectRelatedNewsFilters,
  fromReducer.getSearch
);

export const selectRelatedNewsIsLoading = createSelector(
  selectRelatedNewsState,
  fromReducer.getIsLoading
);
