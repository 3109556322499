import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as fromRouter from '@app/root-store/router';
import * as fromActions from './back-button.actions';
import * as fromReducer from './back-button.reducer';
import * as fromSelector from './back-button.selectors';

import { NavigationEnd, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { RdsDialogService } from '@rds/angular-components';
import { filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class BackButtonEffects {

  public routerEvent$ = createEffect(() =>
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      tap((event: NavigationEnd) => console.log('Navigation finished', event)),
      withLatestFrom(
        this.store$.pipe(select(fromSelector.selectBackConfig))
      ),
      filter(([event, {routeCurrent}]) => {
        let routesToCheck = [];
        if (routeCurrent instanceof Array === false) {
          routesToCheck = [routeCurrent as string]
        } else {
          routesToCheck = routeCurrent as Array<string>;
        }
        return !routesToCheck.includes(event.url)
      }),
      map(() => fromActions.clear())
    ), {dispatch: true}
  );
  
  public back$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.back),
      withLatestFrom(this.store$.pipe(select(fromSelector.selectBackConfig))),
      filter(([action, {label, routeBack, routeCurrent, routeBackQueryParams}]) => !!label && !!routeBack &&!!routeCurrent),
      mergeMap(([action, {label, routeBack, routeCurrent, routeBackQueryParams}]) => [
        fromRouter.go({path: routeBack, queryParams: routeBackQueryParams? routeBackQueryParams : {}}),
        fromActions.clear()
      ])
    ), {dispatch: true})

  public backToDefault$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.back),
      withLatestFrom(this.store$.pipe(select(fromSelector.selectBackConfig))),
      filter(([action, {label, routeBack, routeCurrent}]) => !label || !routeBack || !routeCurrent),
      mergeMap(([{defaultLabel, defaultRoute}, selector]) => [
        fromRouter.go({path: defaultRoute, queryParams: {}}),
        fromActions.clear()
      ])
    ), {dispatch: true})

  constructor(
    private actions$: Actions,
    private dialogService: RdsDialogService,
    private router: Router,
    private store$: Store<fromReducer.State>
  ) {}
}
