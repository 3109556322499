import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType} from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, debounceTime, distinctUntilChanged, filter, map, of, switchMap, tap, withLatestFrom } from 'rxjs';

import * as fromRouter from '@app/root-store/router';
import * as fromActions from './channel-details.actions';
import * as fromSelectors from './channel-details.selectors';
import * as fromReducer from './channel-details.reducer';
import { RdsDialogService } from '@rds/angular-components';
import { NewsboardAuthService } from '@app/core/auth/services/newsboard-auth.service';
import { NewsService } from '@app/newsboard/news/news.service';
import { NewsPreviewDialogComponent, NewsPreviewDialogData } from '@app/shared/dialogs/previews/news-preview-dialog/news-preview-dialog.component';


@Injectable()
export class DetailsEffects {

  public initAllNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initAllNews),
      switchMap(() => this.store$.pipe(
          select(fromSelectors.selectAllNewsRequestData),
          debounceTime(300),
          distinctUntilChanged((prev, next) => {
            if (prev.resetIndex && !next.resetIndex) {
              delete prev.resetIndex
              delete next.resetIndex
            }
            return JSON.stringify(prev) === JSON.stringify(next)
          }),
          map(({filters, pageIndex, pageSize, sort, resetIndex}) => ({
            filters, 
            sort,
            pageIndex: resetIndex ? 0 : pageIndex,
            pageSize
          }))
        )
      ),
      map((requestData) => fromActions.getAllNewsRequest(requestData))
    ), { dispatch: true}
  );

  public getAllNewsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllNewsRequest),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectChannelId))
      ),
      filter(([action, id]) => !!id),
      switchMap(([requestData, id]) => this.newsService.getNews(requestData, 'channel', id).pipe(
        map(res => ({
          data: res.data, pagination: res.pagination
        }))
      )),
      map(({data, pagination}) => fromActions.getAllNewsSuccess({data, pagination})),
      catchError(({ message }) => of(fromActions.getAllNewsFailure({ error: message })))

    ), { dispatch: true}
  );

  public removeAllNewsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeAllNewsRequest),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectChannelId))
      ),
      switchMap(([{ids}, channelId]) => this.newsService.removeFromChannel(ids, channelId).pipe(
        map(() => ({ids}))
      )),
      map(({ids}) => fromActions.removeAllNewsSuccess({count: ids.length}))
    ), { dispatch: true}
  );

  public initNewsSuggestions$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.initNewsSuggestions),
    switchMap(() => this.store$.pipe(
        select(fromSelectors.selectNewsSuggestionsRequestData),
        debounceTime(300),
        distinctUntilChanged((prev, next) => {
          if (prev.resetIndex && !next.resetIndex) {
            delete prev.resetIndex
            delete next.resetIndex
          }
          return JSON.stringify(prev) === JSON.stringify(next)
        }),
        map(({filters, pageIndex, pageSize, sort, resetIndex}) => ({
          filters, 
          sort,
          pageIndex: resetIndex ? 0 : pageIndex,
          pageSize
        }))
      )
    ),
    map((requestData) => fromActions.getNewsSuggestionsRequest(requestData))
  ), { dispatch: true}
);

public getNewsSuggestionsRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.getNewsSuggestionsRequest),
    withLatestFrom(
      this.store$.pipe(select(fromSelectors.selectChannelId))
    ),
    filter(([action, id]) => !!id),
    switchMap(([requestData, id]) => this.newsService.getNews(requestData, 'approve', id).pipe(
      map(res => ({
        data: res.data, pagination: res.pagination
      }))
    )),
    map(({data, pagination}) => fromActions.getNewsSuggestionsSuccess({data, pagination})),
    catchError(({ message }) => of(fromActions.getNewsSuggestionsFailure({ error: message })))

  ), { dispatch: true}
);

  public initArchivedNews$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.initArchivedNews),
    switchMap(() => this.store$.pipe(
        select(fromSelectors.selectArchivedNewsRequestData),
        debounceTime(300),
        distinctUntilChanged((prev, next) => {
          if (prev.resetIndex && !next.resetIndex) {
            delete prev.resetIndex
            delete next.resetIndex
          }
          return JSON.stringify(prev) === JSON.stringify(next)
        }),
        distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next)),
        map(({filters, pageIndex, pageSize, sort, resetIndex}) => ({
          filters, 
          sort,
          pageIndex: resetIndex ? 0 : pageIndex,
          pageSize
        }))
      )
    ),
    map((requestData) => fromActions.getArchivedNewsRequest(requestData))
  ), { dispatch: true}
);

public getArchivedNewsRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.getArchivedNewsRequest),
    withLatestFrom(
      this.store$.pipe(select(fromSelectors.selectChannelId))
    ),
    filter(([action, id]) => !!id),
    switchMap(([requestData, id]) => this.newsService.getNews(requestData, 'carchive', id).pipe(
      map(res => ({
        data: res.data, pagination: res.pagination
      }))
    )),
    map(({data, pagination}) => fromActions.getArchivedNewsSuccess({data, pagination})),
    catchError(({ message }) => of(fromActions.getArchivedNewsFailure({ error: message })))
  ), { dispatch: true}
);

public deleteArchivedNewsRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.removeArchivedNewsRequest),
    withLatestFrom(
      this.store$.pipe(select(fromSelectors.selectChannelId))
    ),
    switchMap(([{ids}, channelId]) => this.newsService.removeFromChannel(ids, channelId).pipe(
      map(() => ({ids}))
    )),
    map(({ids}) => fromActions.removeArchivedNewsSuccess({count: ids.length}))
  ), { dispatch: true}
);

public pinNewsRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.pinNewsRequest),
    withLatestFrom(
      this.store$.pipe(select(fromSelectors.selectChannelId))
    ),
    switchMap(([{newsId, isPinned}, channelId]) => this.newsService.pin(newsId, channelId, isPinned).pipe(
      map(() => ({isPinned}))
    )),
    map(({isPinned}) => fromActions.pinNewsSuccess({isPinned: !isPinned})),
    catchError((error) => of(fromActions.pinNewsFailure({error})))
  )
);

public duplicateNewsRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.duplicateNewsRequest),
    switchMap(({newsId}) => this.newsService.duplicate(newsId).pipe(
      map((id) => ({id}))
    )),
    map(({id}) => fromActions.duplicateNewsSuccess({id})),
    catchError((error) => of(fromActions.duplicateNewsFailure({error})))
  )
);

public duplicateNewsSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.duplicateNewsSuccess),
    map(({id}) => fromRouter.go({path: `news/${id}/edit`, queryParams: {}})),
    catchError((error) => of(fromActions.duplicateNewsFailure({error})))
  )
);

public rejectNewsRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.rejectNewsRequest),
    withLatestFrom(
      this.store$.pipe(select(fromSelectors.selectChannelId))
    ),
    switchMap(([{newsId, reason}, channelId]) => this.newsService.rejectSuggestion(newsId, channelId, reason).pipe(
      map(() => ({newsId}))
    )),
    map(({newsId}) => fromActions.rejectNewsSuccess()),
    catchError((error) => of(fromActions.rejectNewsFailure({error})))
  )
);

public acceptNewsRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.acceptNewsRequest),
    withLatestFrom(
      this.store$.pipe(select(fromSelectors.selectChannelId))
    ),
    switchMap(([{newsId}, channelId]) => this.newsService.acceptSuggestion(newsId, channelId).pipe(
      map(() => ({newsId}))
    )),
    map(({newsId}) => fromActions.acceptNewsSuccess()),
    catchError((error) => of(fromActions.acceptNewsFailure({error})))
  )
);

public openNewsPreview$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.openNewsPreview),
    withLatestFrom(
      this.store$.pipe(select(fromSelectors.selectChannelDetails))
    ),
    switchMap(([{newsId, backButton}, channel]) => this.newsService.get(newsId).pipe(
      map((news) => ({news, channel, backButton}))
    )),
    tap(({news, channel, backButton}) => {
      const simpleUser = this.auth.currentSimpleUser;
      const isAdmin = this.auth.isAdmin || this.auth.isSuperAdmin;
      const canEdit = isAdmin || !!news.owners.find(u => u.identifier === simpleUser.identifier) || !!news.editors.find(u => u.identifier === simpleUser.identifier);
      const data: NewsPreviewDialogData = {
        news,
        channel,
        context: 'Table',
        backButton,
        buttons: {
          editNews: {
            visible: true,
            disabled: !canEdit
          }
        }
      }
      this.dialogService.open(NewsPreviewDialogComponent, {
        size: 'xl',
        height: '80vh',
        maxHeight: '860px',
        data
      }
      )
    } )
  ), { dispatch: false}
);


  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private auth: NewsboardAuthService,
    private newsService: NewsService,
    private dialogService: RdsDialogService
  ) {}
}
