import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { EndpointsService } from '../endpoints/endpoints.service';
import { escapeApostrophe, prepareSortStringArray } from './odata.utils';

@Injectable()
export class CatalogODataService {
    columns = ['name', 'status', 'isAttentionRequired', 'contentOwner', 'contentManager',
    'creationDate', 'lastModifiedDate', 'locations', 'departments', 'functions',
    'catalogId', 'contentOwnerFirstName', 'contentOwnerLastName', 'contentManagerFirstName',
    'categoryId',
    'contentManagerLastName', 'contentType','isContentOwnerValid', 'isContentManagerValid', 'urlStatus'];

    getMyContent(queryParams) {
        const prepParams = {
            ...queryParams,
            filters: this.prepareFilters(queryParams.filters)
        };
        const url = `${this.endpoints.ENDPOINT.ODATA}/directory/odata/Catalog/myContent`;
        const select=`?$select=${this.columns.join(',')}`;
        const filter=prepParams.filters.length ? `&$filter=${prepParams.filters}` : '';
        const top=`&$top=${prepParams.pageSize}`;
        const orderBy=`&$orderby=${prepareSortStringArray(prepParams.sort)}`;
        const skip= prepParams.pageSize * prepParams.pageIndex > 0 ? `&$skip=${prepParams.pageSize * prepParams.pageIndex}` : '';
        const count='&$count=true';
        const fullUrl = `${url}${select}${filter}${top}${skip}${orderBy}${count}`
        return this.http.get(fullUrl)
        .pipe(
            map(result => ({
                data: result.value,
                pagination: {
                    isFirst: (prepParams.pageSize * prepParams.pageIndex) === 0,
                    isLast: (((prepParams.pageSize * prepParams.pageIndex) / prepParams.pageSize) + 1) * prepParams.pageSize >= result['@odata.count'],
                    pageCount: Math.ceil(result['@odata.count'] / prepParams.pageSize),
                    pageIndex: (prepParams.pageSize * prepParams.pageIndex) / prepParams.pageSize,
                    pageSize: prepParams.pageSize,
                    totalCount: result['@odata.count'],
                }

            })),
            catchError((fault) => throwError(`Cannot get catalogs. ${fault}`))
        );
    }
    
    getAllContent(queryParams) {
        const prepParams = {
            ...queryParams,
            filters: this.prepareFilters(queryParams.filters)
        };
        const url = `${this.endpoints.ENDPOINT.ODATA}/directory/odata/Catalog/allContent`;
        const select=`?$select=${this.columns.join(',')}`;
        const filter=prepParams.filters.length ? `&$filter=${prepParams.filters}` : '';
        const top=`&$top=${prepParams.pageSize}`;
        const orderBy=`&$orderby=${prepareSortStringArray(prepParams.sort)}`;
        const skip= prepParams.pageSize * prepParams.pageIndex > 0 ? `&$skip=${prepParams.pageSize * prepParams.pageIndex}` : '';
        const count='&$count=true';
        const fullUrl = `${url}${select}${filter}${top}${skip}${orderBy}${count}`
        return this.http.get(fullUrl)
        .pipe(
            map(result => ({
                data: result.value,
                pagination: {
                    isFirst: (prepParams.pageSize * prepParams.pageIndex) === 0,
                    isLast: (((prepParams.pageSize * prepParams.pageIndex) / prepParams.pageSize) + 1) * prepParams.pageSize >= result['@odata.count'],
                    pageCount: Math.ceil(result['@odata.count'] / prepParams.pageSize),
                    pageIndex: (prepParams.pageSize * prepParams.pageIndex) / prepParams.pageSize,
                    pageSize: prepParams.pageSize,
                    totalCount: result['@odata.count'],
                }

            })),
            catchError((fault) => throwError(`Cannot get catalogs. ${fault}`))
        );
    }

    getAttentionRequiredCount() {
        const url = `${this.endpoints.ENDPOINT.ODATA}/directory/odata/Catalog/myContent`;
        const filter=`?$filter=statusId in (1,2,3,4,5,6) and isAttentionRequired eq true`;
        const top=`&$top=0`;
        const count='&$count=true';
        const fullUrl = `${url}${filter}${top}${count}`
        return this.http.get(fullUrl)
        .pipe(
            map(result => result['@odata.count']),
            catchError((fault) => throwError(`Cannot get count. ${fault}`))
        );
      }

    private prepareFilters(filters) {
        let transformedFilters = '';
        let index = 0;
        for (const [key, value] of Object.entries<Object>(filters)) {
            if (key.includes('Date') && !!(value as any).start && !!(value as any).end) {
                transformedFilters += this.prepareFilter(key, value, index);
                index++;
            }
            if ((!key.includes('Date') && (!!value || value === false) && (!Array.isArray(value) || value.length))) {
                transformedFilters += this.prepareFilter(key, value, index);
                index++;
            }
        }
        return transformedFilters;
    }

    private prepareFilter(key, value, index) {
        let filter = '';
        if (index) {
            filter += ' and ';
        }
        switch (key) {
            case('contentOwner'): {
                filter += `contentOwner in (${value.map((u) => '\'' + u.identifier + '\'').join(',')})`;
                break;
            }
            case('contentManager'): {
                filter += `contentManager in (${value.map((u) => '\'' + u.identifier + '\'').join(',')})`;
                break;
            }
            case('lastModifiedDate'): {
                filter += `date(${key}) ge ${this.datePipe.transform(new Date(value.start), 'yyyy-MM-dd')} and date(${key}) le ${this.datePipe.transform(new Date(value.end), 'yyyy-MM-dd')}`;
                break;
            }
            case('creationDate'): {
                filter += `date(${key}) ge ${this.datePipe.transform(new Date(value.start), 'yyyy-MM-dd')} and date(${key}) le ${this.datePipe.transform(new Date(value.end), 'yyyy-MM-dd')}`;
                break;
            }
            case('approvedDate'): {
                filter += `date(${key}) ge ${this.datePipe.transform(new Date(value.start), 'yyyy-MM-dd')} and date(${key}) le ${this.datePipe.transform(new Date(value.end), 'yyyy-MM-dd')}`;
                break;
            }
            case('isAttentionRequired'): {
                filter += `${key} eq ${value}`;
                break;
            }
            case('status'): {
                filter += `statusId in (${(value as Array<string>).join(',')})`;
                break;
            }
            case('locations'): {
                filter += `locations/any(l: l in (${value.map((l) => '\'' + l + '\'').join(',')}))`;
                break;
            }
            case('departments'): {
                filter += `departments/any(d: d in (${value.map((d) => '\'' + d + '\'').join(',')}))`;
                break;
            }
            case('functions'): {
                filter += `functions/any(f: f in (${value.map((f) => '\'' + f + '\'').join(',')}))`;
                break;
            }
            case('contentTypes'): {
                filter += `contentTypeId in (${(value as Array<string>).join(',')})`;
                break;
            }
            case('categories'): {
                filter += `categoryId in (${(value as Array<string>).join(',')})`;
                break;
            }
            case('search'): {
                value = value.trim();
                filter += `contains(toLower(name), '${(escapeApostrophe(value).toLowerCase())}')`;
                break;
            }
            default: {
                value = value.trim();
                filter += `contains(toLower(${key}), '${(escapeApostrophe(value).toLowerCase())}')`;
                break;
            }
        }
        return filter;
    }

    constructor(
        private http: ApiService,
        private endpoints: EndpointsService,
        private datePipe: DatePipe,
    ) {
    }
}
