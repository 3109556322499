import {createSelector} from '@ngrx/store';
import * as fromNewsletter from '@app/newsletter-new/store/newsletter.reducer';
import * as fromCampaignForm from '@app/newsletter-new/store/campaign-form/campaign-form.reducer';

export const selectCampaignFormState = createSelector(
  fromNewsletter.selectState,
  fromNewsletter.getCampaignForm
);
export const selectCampaignForm = createSelector(
  selectCampaignFormState,
  fromCampaignForm.getForm
);
export const selectInitialCampaignForm = createSelector(
  selectCampaignFormState,
  fromCampaignForm.getInitialForm
);

export const selectFormChecked = createSelector(
  selectCampaignFormState,
  fromCampaignForm.getFormChecked
);

export const selectChangesMade = createSelector(
  selectCampaignForm,
  selectInitialCampaignForm,
  (form, initialForm) => JSON.stringify(form) !== JSON.stringify(initialForm)
);
