<rds-tree
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  size="l"
>
  <rds-tree-node class="text-low-contrast" *rdsTreeNodeDef="let node" rdsTreeNodePadding [disabled]="disabled" (click)="handleClick(node.link)">
      <rds-icon
        class="me-5"
        *ngIf="node.link.icon"
        namespace="outlined"
        [icon]="node.link.icon"
      ></rds-icon>
    <rds-tree-node-text>{{ node.link.link }}
    </rds-tree-node-text>
  </rds-tree-node>
  <rds-tree-node class="text-low-contrast" 
  *rdsTreeNodeDef="let node; when: isDivider"
  rdsTreeNodePadding
  rdsTreeNodeToggle [disabled]="true"
>
  <rds-divider size="l" style="width: 100%" class="my-2"></rds-divider>
</rds-tree-node>
  <rds-tree-node class="text-low-contrast" 
    *rdsTreeNodeDef="let node; when: hasChild"
    rdsTreeNodePadding
    rdsTreeNodeToggle [disabled]="disabled"
  >
    <rds-icon
      class="me-5"
      *ngIf="node.link.icon"
      namespace="outlined"
      [icon]="node.link.icon"
    ></rds-icon>
    <rds-tree-node-text>{{ node.link.link }}</rds-tree-node-text>
    <div rdsTreeNodeContentRight>
      <rds-icon
        [icon]="
          treeControl.isExpanded(node) ? 'chevron_up' : 'chevron_down'
        "
      ></rds-icon>
    </div>
  </rds-tree-node>
</rds-tree>