import { LinkStatisticsTableModel } from '@app/core/models/newsletter.model';
import { RecipientsTableFilters } from '@app/shared/filters/models/recipients-table';
import { Pagination } from '@core/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SortDirection } from '@rds/angular-components';

export const table = createActionGroup({
  source: 'STATISTICS TABLE',
  events: {
    init: emptyProps(),
    clear: emptyProps(),
    request: props<{
      pageIndex: number,
      pageSize: number,
      sort: {
        active: string;
        direction: SortDirection
      },
      filters: {[key: string]: any},
    }>(),
    setAllLinks: props<{ data: Array<LinkStatisticsTableModel> }>(),
    success: props<{ data: Array<LinkStatisticsTableModel>, pagination: Pagination }>(),
    failure: props<{ error: any }>(),
    filter: props<{ filters: RecipientsTableFilters }>(),
    sort: props<{ active: string, direction: SortDirection }>(),
    paginate: props<{ pageIndex: number, pageSize: number }>()
  }
});
