<h2 rds-dialog-header>
  Ticker preview
</h2>
<div rds-dialog-icon-placeholder>
  <button rds-icon-button size="m" [rds-dialog-close]="null">
    <rds-icon icon="close" namespace="outlined"></rds-icon>
  </button>
</div>
<div rds-dialog-content>
  <div class="ticker-preview__heading">
    <rds-tabs #tabs size="m" >
      <rds-tab label="Ticker">
        <div class="ticker-container mt-9 pb-11" [class.mobile]="contentMode === 'mobile'">
          <div class="ticker">
            <atr-tickers [isMobile]="contentMode === 'mobile'" [tickersOptions]="[ticker]"></atr-tickers>
          </div>
        </div>
      </rds-tab>
    </rds-tabs>
    <div class="ticker-preview__heading--options">
      <rds-toggle
      [(value)]="contentMode"
      [type]="'outlined'"
      >
      <rds-toggle-button
        value="desktop"
        [checked]="contentMode === 'desktop'"
      >
        <svg width="24" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
          <path d="M21.8595 17.57L19.9995 14.07V6C19.9995 4.9 19.0995 4 17.9995 4H5.99953C4.89953 4 3.99953 4.9 3.99953 6V14.07L2.13953 17.57C1.92953 18.03 1.95953 18.54 2.20953 18.97L2.25953 19.05C2.58953 19.63 3.27953 20 4.03953 20H19.9595C20.7195 20 21.4095 19.63 21.7395 19.05L21.7895 18.97C22.0395 18.54 22.0695 18.03 21.8595 17.57ZM5.99953 6H17.9995V14H5.99953V6ZM4.17953 18L5.23953 16H18.7595L19.8195 18H4.17953Z" />
        </svg>
      </rds-toggle-button>
      <rds-toggle-button
        value="mobile"
        [checked]="contentMode === 'mobile'"
      >
        <svg width="24" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
          <path d="M16 4V20H8V4H16ZM16 2H8C6.9 2 6 2.9 6 4V20C6 21.1 6.9 22 8 22H16C17.1 22 18 21.1 18 20V4C18 2.9 17.1 2 16 2Z" />
          <path d="M10 3H14V4C14 4.55 13.55 5 13 5H11C10.45 5 10 4.55 10 4V3Z" />
        </svg>
      </rds-toggle-button>
    </rds-toggle>
    </div>
  </div>
</div>
<div rds-dialog-actions-container align="end">
  <ng-container [ngSwitch]="context">
    <ng-container *ngSwitchCase="'Editor'">
      <button *ngIf="!!ticker.link" rds-text-button (click)="gotoLink()" size="m">
        Open link in new tab <rds-icon icon="launch"></rds-icon>
      </button>
      <button *ngIf="buttons?.canSaveTicker?.visible" [disabled]="buttons?.canSaveTicker?.disabled" rds-primary-button [rds-dialog-close]="'saveNewsAsDraft'" size="m">
        Create ticker
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'Table'">
      <button *ngIf="!!ticker.link" rds-text-button (click)="gotoLink()" size="m">
        Open link in new tab <rds-icon icon="launch"></rds-icon>
      </button>
      <button *ngIf="buttons?.editTicker?.visible" [disabled]="buttons?.editTicker?.disabled"  rnbBackButtonSet [label]="backButton?.label" [routeBack]="backButton?.routeBack" [routeCurrent]="backButton?.routeCurrent" rds-primary-button [routerLink]="'/ticker/'+ticker.id+'/edit'" [rds-dialog-close]="null" size="m">
        Edit ticker
      </button>
    </ng-container>
  </ng-container>
</div>