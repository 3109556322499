import { createReducer } from '@ngrx/store';

export const feature = 'toast';
export interface State {
}

export const initialState: State = {
};

export const reducer = createReducer(
  initialState,

);
