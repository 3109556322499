import { createSelector } from '@ngrx/store';
import * as fromReducer from './notification-settings.reducer';
import * as fromUserProfile from '@app/user-settings/store/user-settings.reducer';

export const selectNotificationSettingsState = createSelector(
  fromUserProfile.selectState,
  fromUserProfile.getNotificationSettings,
);

export const selectNotificationsEnabled = createSelector(
  selectNotificationSettingsState,
  fromReducer.getNotificationsEnabled
);

export const selectChannelSettings = createSelector(
  selectNotificationSettingsState,
  fromReducer.getChannelSettings
);

export const selectInitialChannelSettings = createSelector(
  selectNotificationSettingsState,
  fromReducer.getInitialChannelSettings
);
