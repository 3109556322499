import { createAction, props } from '@ngrx/store';
import {  Audience } from '@app/core/models';

export const initForm = createAction(
  '[PERSONALIZATION PREFERENCES - FORM] Init form',
);

export const setInitialValue = createAction(
  '[PERSONALIZATION PREFERENCES - FORM] Set initial form value',
  props<{ form: Audience }>()
);

export const setFormValue = createAction(
  '[PERSONALIZATION PREFERENCES - FORM] Set form value',
  props<{ form: Audience }>()
);

export const savePersonalizationPreferencesRequest = createAction(
  '[PERSONALIZATION PREFERENCES - FORM] Save personalization preferences Request'
);

export const savePersonalizationPreferencesSuccess = createAction(
  '[PERSONALIZATION PREFERENCES - FORM] Save personalization preferences Success',
  props<{ personalizationPreferences: Audience }>()
);

export const savePersonalizationPreferencesFailure = createAction(
  '[PERSONALIZATION PREFERENCES - FORM] Save personalization preferences Failed',
  props<{ error: any }>()
);

export const resetPersonalizationPreferencesRequest = createAction(
  '[PERSONALIZATION PREFERENCES - FORM] Reset personalization preferences Request'
);

export const resetPersonalizationPreferencesSuccess = createAction(
  '[PERSONALIZATION PREFERENCES - FORM] Reset personalization preferences Success',
  props<{ personalizationPreferences: Audience }>()
);

export const resetPersonalizationPreferencesFailure = createAction(
  '[PERSONALIZATION PREFERENCES - FORM] Reset personalization preferences Failed',
  props<{ error: any }>()
);

export const openResetPersonalizationPreferencesConfirmDialog = createAction(
  '[PERSONALIZATION PREFERENCES - FORM] Open reset personalization preferences confirm dialog'
);

