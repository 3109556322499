import { Dictionary, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './channels-sets-table.actions';

import { ChannelsSet, Pagination } from '@app/core/models';
import { TableState, flattenFilters } from '@app/shared/filters/filters-model';
import { INITIAL_CHANNEL_SETS_FILTERS, ManageSetsTableFilters } from '@app/shared/filters/models/manage-sets-table';

export const feature = 'channelsSetsTable';

export const adapter: EntityAdapter<ChannelsSet> =
 createEntityAdapter<ChannelsSet>({
  selectId: (e) => e.id
 });

export interface State {
  table: TableState<ChannelsSet, ManageSetsTableFilters>;
}

export const initialState: State = {
  table: {
    data: adapter.getInitialState(),
    pagination: {
      ...Pagination,
      pageIndex: 0,
      pageSize: 10,
    },
    sort: {
      active: 'name',
      direction: 'asc'
    },
    defaultSort: {
      active: 'name',
      direction: 'asc'
    },
    filters: INITIAL_CHANNEL_SETS_FILTERS,
    defaultFilters: INITIAL_CHANNEL_SETS_FILTERS,
    isLoading: false,
    resetIndex: false,
  },
};

const tableReducer = createReducer(
  initialState,
  on(fromActions.getTableRequest, (state) => ({
    ...state,
    table: {
      ...state.table,
      isLoading: true
    }
  })),
  on(fromActions.getTableSuccess, fromActions.getTableFailure, (state) => ({
    ...state,
    table: {
      ...state.table,
      isLoading: false
    }
  })),
  on(fromActions.getTableSuccess, (state, {data, pagination}) => ({
    ...state,
    table: {
      ...state.table,
      data: adapter.setAll(data, state.table.data),
      pagination,
      resetIndex: false,
    }
  })),
  on(fromActions.changeTableFilters, (state, {filters}) => ({
    ...state,
    table: {
      ...state.table,
      filters,
      resetIndex: true
    }
  })),
  on(fromActions.changeTableSort, (state, {active, direction}) => ({
    ...state,
    table: {
      ...state.table,
      sort: {
        active,
        direction
      }
    }
  })),
  on(fromActions.changeTablePagination, (state, {pageIndex, pageSize}) => ({
    ...state,
    table: {
      ...state.table,
      pagination: {
        ...state.table.pagination,
        pageIndex,
        pageSize
      }
    }
  })),
  on(fromActions.deleteChannelsSetSuccess, (state) => ({
    ...state,
    table: {
      ...state.table,
      resetIndex: true
    }
  })),
  on(fromActions.clearAll, (state) => ({
    ...initialState
  })),

);

export function reducer(state: State | undefined, action: Action) {
  return tableReducer(state, action);
}

// NEW WAY TABLE
export const getTableState = (state: State) => state.table;

export const getPagination = (table: TableState<ChannelsSet, ManageSetsTableFilters>) => table.pagination;
export const getSort = (table: TableState<ChannelsSet, ManageSetsTableFilters>) => table.sort;
export const getDefaultSort = (table: TableState<ChannelsSet, ManageSetsTableFilters>) => table.defaultSort;
export const getFilters = (table: TableState<ChannelsSet, ManageSetsTableFilters>) => table.filters;
export const getDefaultFilters = (table: TableState<ChannelsSet, ManageSetsTableFilters>) => table.defaultFilters;
export const getIsLoading = (table: TableState<ChannelsSet, ManageSetsTableFilters>) => table.isLoading;

export const getRequestData = ({pagination, sort, filters, resetIndex}: TableState<ChannelsSet, ManageSetsTableFilters>) => ({
  pageIndex: pagination.pageIndex,
  pageSize: pagination.pageSize,
  sort,
  filters: flattenFilters(filters),
  resetIndex
});

export const getTableData = (table: TableState<ChannelsSet, ManageSetsTableFilters>) => adapter.getSelectors().selectAll(table.data);

// Pagination fields
export const getPageSize = (pagination: Pagination) => pagination.pageSize;
export const getPageIndex = (pagination: Pagination) => pagination.pageIndex;
export const getTotalCount = (pagination: Pagination) => pagination.totalCount;

// Filters fields
export const getSearch = (filters: ManageSetsTableFilters) => filters.search.value;

export const getEntitiesByIds = (entities: Dictionary<ChannelsSet>, ids: string[]): ChannelsSet[] => ids.map(id => entities[id]);
