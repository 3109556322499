import { createReducer, Action, on} from '@ngrx/store';
import { createEntityAdapter, Dictionary, EntityAdapter } from '@ngrx/entity';

import * as fromActions from './table.actions';

import { Pagination } from '@app/core/models';

import { RhEvent } from '@app/events/models/event';
import { TableState, flattenFilters } from '@app/shared/filters/filters-model';
import { EventTableFilters, INITIAL_EVENTS_FILTERS } from '@app/shared/filters/models/events-table';

export const feature = 'table';

export const adapter: EntityAdapter<Partial<RhEvent>> =
 createEntityAdapter<Partial<RhEvent>>({
  selectId: (e) => e.id
 });

export interface State {
  myEvents: TableState<Partial<RhEvent>, EventTableFilters>;
  myPastEvents: TableState<Partial<RhEvent>, EventTableFilters>;
}

export const initialState: State = {
  myEvents: {
    data: adapter.getInitialState(),
    pagination: {
      ...Pagination,
      pageIndex: 0,
      pageSize: 10,
    },
    sort: {
      active: 'date',
      direction: 'asc'
    },
    defaultSort: {
      active: 'date',
      direction: 'asc'
    },
    filters: INITIAL_EVENTS_FILTERS,
    defaultFilters: INITIAL_EVENTS_FILTERS,
    isLoading: false,
    resetIndex: false,
  },
  myPastEvents: {
    data: adapter.getInitialState(),
    pagination: {
      ...Pagination,
      pageIndex: 0,
      pageSize: 10,
    },
    sort: {
      active: 'date',
      direction: 'desc'
    },
    defaultSort: {
      active: 'date',
      direction: 'desc'
    },
    filters: INITIAL_EVENTS_FILTERS,
    defaultFilters: INITIAL_EVENTS_FILTERS,
    isLoading: false,
    resetIndex: false,
  }

};

const tableReducer = createReducer(
  initialState,
  on(fromActions.getMyEventsRequest, (state) => ({
    ...state,
    myEvents: {
      ...state.myEvents,
      isLoading: true
    }
  })),
  on(fromActions.getMyEventsSuccess, fromActions.getMyEventsFailure, (state) => ({
    ...state,
    myEvents: {
      ...state.myEvents,
      isLoading: false
    }
  })),
  on(fromActions.getMyEventsSuccess, (state, {data, pagination}) => ({
    ...state,
    myEvents: {
      ...state.myEvents,
      data: adapter.setAll(data, state.myEvents.data),
      pagination,
      resetIndex: false,
    }
  })),
  on(fromActions.deleteMyEventsSuccess, (state) => ({
    ...state,
    myEvents: {
      ...state.myEvents,
      resetIndex: true
    }
  })),
  on(fromActions.changeMyEventsFilters, (state, {filters}) => ({
    ...state,
    myEvents: {
      ...state.myEvents,
      filters,
      resetIndex: true
    }
  })),
  on(fromActions.changeMyEventsSort, (state, {active, direction}) => ({
    ...state,
    myEvents: {
      ...state.myEvents,
      sort: {
        active,
        direction
      }
    }
  })),
  on(fromActions.changeMyEventsPagination, (state, {pageIndex, pageSize}) => ({
    ...state,
    myEvents: {
      ...state.myEvents,
      pagination: {
        ...state.myEvents.pagination,
        pageIndex,
        pageSize
      }
    }
  })),

  on(fromActions.getMyPastEventsRequest, (state) => ({
    ...state,
    myPastEvents: {
      ...state.myPastEvents,
      isLoading: true
    }
  })),
  on(fromActions.getMyPastEventsSuccess, fromActions.getMyPastEventsFailure, (state) => ({
    ...state,
    myPastEvents: {
      ...state.myPastEvents,
      isLoading: false
    }
  })),
  on(fromActions.getMyPastEventsSuccess, (state, {data, pagination}) => ({
    ...state,
    myPastEvents: {
      ...state.myPastEvents,
      data: adapter.setAll(data, state.myPastEvents.data),
      pagination,
      resetIndex: false
    }
  })),
  on(fromActions.deleteMyPastEventsSuccess, (state) => ({
    ...state,
    myPastEvents: {
      ...state.myPastEvents,
      resetIndex: true
    }
  })),
  on(fromActions.changeMyPastEventsFilters, (state, {filters}) => ({
    ...state,
    myPastEvents: {
      ...state.myPastEvents,
      filters,
      resetIndex: true
    }
  })),
  on(fromActions.changeMyPastEventsSort, (state, {active, direction}) => ({
    ...state,
    myPastEvents: {
      ...state.myPastEvents,
      sort: {
        active,
        direction
      }
    }
  })),
  on(fromActions.changeMyPastEventsPagination, (state, {pageIndex, pageSize}) => ({
    ...state,
    myPastEvents: {
      ...state.myPastEvents,
      pagination: {
        ...state.myPastEvents.pagination,
        pageIndex,
        pageSize
      }
    }
  })),
  on(fromActions.clearAll, (state) => ({
    ...initialState
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return tableReducer(state, action);
}

export const getMyEvents = (state: State) => state.myEvents;
export const getMyPastEvents = (state: State) => state.myPastEvents;

export const getPagination = (table: TableState<Partial<RhEvent>, EventTableFilters>) => table.pagination;
export const getSort = (table: TableState<Partial<RhEvent>, EventTableFilters>) => table.sort;
export const getDefaultSort = (table: TableState<Partial<RhEvent>, EventTableFilters>) => table.defaultSort;
export const getFilters = (table: TableState<Partial<RhEvent>, EventTableFilters>) => table.filters;
export const getDefaultFilters = (table: TableState<Partial<RhEvent>, EventTableFilters>) => table.defaultFilters;
export const getIsLoading = (table: TableState<Partial<RhEvent>, EventTableFilters>) => table.isLoading;

export const getRequestData = ({pagination, sort, filters, resetIndex}: TableState<Partial<RhEvent>, EventTableFilters>) => ({
  pageIndex: pagination.pageIndex,
  pageSize: pagination.pageSize,
  sort,
  filters: flattenFilters(filters),
  resetIndex});

export const getTableData = (table: TableState<Partial<RhEvent>, EventTableFilters>) => adapter.getSelectors().selectAll(table.data);

// Pagination fields
export const getPageSize = (pagination: Pagination) => pagination.pageSize;
export const getPageIndex = (pagination: Pagination) => pagination.pageIndex;
export const getTotalCount = (pagination: Pagination) => pagination.totalCount;

// Filters fields
export const getSearch = (filters: EventTableFilters) => filters.search.value;

export const getEntitiesByIds = (entities: Dictionary<RhEvent>, ids: string[]): RhEvent[] => ids.map(id => entities[id]);
