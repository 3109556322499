<rds-menu #menu="rdsMenu" xPosition="before" size="l" class="app-menu">
  <button rds-menu-item size="l" [routerLinkActive]="'rds-menu-item-highlighted'" [routerLink]="'/bookmarks'">
    <rds-icon class="action" namespace="outlined" icon="bookmark"></rds-icon>
    My Bookmarks
  </button>
  <button rds-menu-item size="l" [routerLinkActive]="'rds-menu-item-highlighted'" [routerLink]="'/settings'">
    <rds-icon class="action" namespace="outlined" icon="settings"></rds-icon>
    My Settings
  </button>
  <button *ngIf="(shortcuts$ | async).length > 0" rds-menu-item size="l" [routerLinkActive]="'rds-menu-item-highlighted'" [rdsMenuTriggerFor]="shortcutMenu"  [routerLink]="'/workspace'">
    <rds-icon class="action" namespace="outlined" icon="dashboard"></rds-icon>
    RocheHome Workspace
  </button>
  <button *ngIf="(shortcuts$ | async).length === 0" rds-menu-item size="l" [routerLinkActive]="'rds-menu-item-highlighted'" [routerLink]="'/workspace'">
    <rds-icon class="action" namespace="outlined" icon="dashboard"></rds-icon>
    RocheHome Workspace
  </button>
  <button rds-menu-item size="l" [rdsMenuTriggerFor]="nestedMenu">
    <rds-icon class="action" namespace="outlined" icon="edit"></rds-icon>
    RocheHome content
  </button>
</rds-menu>
<rds-menu #nestedMenu="rdsMenu" xPosition="before" size="l" class="app-menu">
  <button rds-menu-item size="l" [routerLinkActive]="'rds-menu-item-highlighted'" [routerLink]="item.path" *ngFor="let item of menuItems">
    <rds-icon class="action" namespace="outlined" [icon]="item.icon"></rds-icon>
    {{item.title}}
  </button>
</rds-menu>
<rds-menu #shortcutMenu="rdsMenu" xPosition="before" size="l" class="app-menu">
  <button rds-menu-item size="l" [routerLinkActive]="'rds-menu-item-highlighted'" [routerLink]="item.path" *ngFor="let item of (shortcuts$ | async)">
    <rds-icon class="action" namespace="outlined" [icon]="item.icon"></rds-icon>
    {{item.title}}
  </button>
</rds-menu>

<rds-menu #newsMenu="rdsMenu" xPosition="before" class="app-menu">
  <button rds-menu-item size="l" [routerLinkActive]="'rds-menu-item-highlighted'" [routerLink]="'/news/create/link'">
    <rds-icon class="action" namespace="outlined" icon="link"></rds-icon>
    News via Link
  </button>
  <button rds-menu-item size="l" [routerLinkActive]="'rds-menu-item-highlighted'" [routerLink]="'/news/create/content'">
    <rds-icon class="action" namespace="outlined" icon="document"></rds-icon>
    News in Editor
  </button>
  <button rds-menu-item size="l" [routerLinkActive]="'rds-menu-item-highlighted'" [routerLink]="'/news/create/template'">
    <rds-icon class="action" namespace="outlined" icon="dashboard"></rds-icon>
    News Template
  </button>
</rds-menu>
<rds-menu #newsletterMenu="rdsMenu" xPosition="before" size="l" class="app-menu">
  <button rds-menu-item size="l" [routerLinkActive]="'rds-menu-item-highlighted'" [routerLink]="'/newsletter/form?templateId=NewsletterTemplateWithoutSections'">
    <rds-icon class="action" namespace="outlined" icon="email"></rds-icon>
    Newsletter without sections
  </button>
  <button rds-menu-item size="l" [routerLinkActive]="'rds-menu-item-highlighted'" [routerLink]="'/newsletter/form?templateId=NewsletterTemplateWithSections'">
    <rds-icon class="action" namespace="outlined" icon="email"></rds-icon>
    Newsletter with sections
  </button>
  <button rds-menu-item size="l" [routerLinkActive]="'rds-menu-item-highlighted'" [routerLink]="'/newsletter/form?templateId=NewsletterTemplateInlineEditor'">
    <rds-icon class="action" namespace="outlined" icon="email"></rds-icon>
    Newsletter with Inline editor
  </button>
</rds-menu>

<rds-menu #allMenu="rdsMenu" xPosition="before" size="l" class="app-menu">
  <div rds-menu-header>DASHBOARDS</div>
  <button rds-menu-item size="l" [routerLinkActive]="'rds-menu-item-highlighted'" [routerLink]="'/news/create'">
    <rds-icon class="action" namespace="outlined" icon="document"></rds-icon>
    Add News dashboard
  </button>
  <button rds-menu-item size="l" [routerLinkActive]="'rds-menu-item-highlighted'" [routerLink]="'/newsletter'">
    <rds-icon class="action" namespace="outlined" icon="email"></rds-icon>
    Newsletter dashboard
  </button>
  <div rds-menu-header>CREATE</div>
  <button rds-menu-item size="l" [routerLinkActive]="'rds-menu-item-highlighted'" [routerLink]="'/catalog/entry'">
    <rds-icon class="action" namespace="outlined" icon="edit"></rds-icon>
    Catalog entry (linked pages)
  </button>
  <button rds-menu-item size="l" class="ps-5" [routerLinkActive]="'rds-menu-item-highlighted'" [routerLink]="'/news/create/link'">
    <rds-icon class="action" namespace="outlined" icon="link"></rds-icon>
    News via Link
  </button>
  <button rds-menu-item size="l" class="ps-5" [routerLinkActive]="'rds-menu-item-highlighted'" [routerLink]="'/news/create/content'">
    <rds-icon class="action" namespace="outlined" icon="document"></rds-icon>
    News in Editor
  </button>
  <button rds-menu-item size="l" class="ps-5" [routerLinkActive]="'rds-menu-item-highlighted'" [routerLink]="'/news/create/template'">
    <rds-icon class="action" namespace="outlined" icon="dashboard"></rds-icon>
    News Template
  </button>
  <button rds-menu-item size="l" [routerLinkActive]="'rds-menu-item-highlighted'" [routerLink]="'/channels/create'">
    <rds-icon class="action" namespace="outlined" icon="wifi_on"></rds-icon>
    Channel
  </button>
  <button rds-menu-item size="l" class="ps-5" [routerLinkActive]="'rds-menu-item-highlighted'" [routerLink]="'/newsletter/form?templateId=NewsletterTemplateWithoutSections'">
    <rds-icon class="action" namespace="outlined" icon="email"></rds-icon>
    Newsletter without sections
  </button>
  <button rds-menu-item size="l" class="ps-5" [routerLinkActive]="'rds-menu-item-highlighted'" [routerLink]="'/newsletter/form?templateId=NewsletterTemplateWithSections'">
    <rds-icon class="action" namespace="outlined" icon="email"></rds-icon>
    Newsletter with sections
  </button>
  <button rds-menu-item size="l" class="ps-5" [routerLinkActive]="'rds-menu-item-highlighted'" [routerLink]="'/newsletter/form?templateId=NewsletterTemplateInlineEditor'">
    <rds-icon class="action" namespace="outlined" icon="email"></rds-icon>
    Newsletter with Inline editor
  </button>
  <button rds-menu-item size="l" [routerLinkActive]="'rds-menu-item-highlighted'" [routerLink]="'/managing-events/create'">
    <rds-icon class="action" namespace="outlined" icon="calendar"></rds-icon>
    Event
  </button>
</rds-menu>
