import { INITIAL_QUICKLINKS_SET_FORM_FILTERS, QuicklinksSetFormFilters } from '@app/shared/filters/models/quicklinks-set-form';
import { Pagination } from './pagination.model';
import { Taxonomy } from './taxonomy.model';

export interface QuicklinksSet {
  id?: number;
  name: string;
  functions: Array<Taxonomy>;
  departments: Array<Taxonomy>;
  locations: Array<Taxonomy>;
  catalogs: Array<CatalogResult>;
  topCatalogs: Array<CatalogResult>;
}

export interface QuicklinksSetForm {
  id?: number;
  name: string;
  audience: {
    locations: Array<string>;
    departments: Array<string>;
    functions: Array<string>;
  };
  catalogs: {
    filters: {
      name: string,
      advancedFilters: QuicklinksSetFormFilters
    };
    selected: Array<CatalogResult>;
  }
}

export interface QuicklinksSetFormCatalogFilters {
  name: string;
  contentTypes: Array<string>;
  categories: Array<string>;
  locations: Array<string>;
  departments: Array<string>;
  functions: Array<string>;
}

export interface QuicklinksSetRequest {
  id?: number;
  name: string;
  locations: Array<string>;
  departments: Array<string>;
  functions: Array<string>;
  catalogIds: Array<number>;
  topCatalogIds: Array<number>;
}


export interface CatalogResult {
  catalogId?: number;
  id: number;
  name: string;
  isTop: boolean;
}

export interface PagedQuicklinksSet {
  data: QuicklinksSet[];
  pagination: Pagination
}

export const EMPTY_QL_SET_FORM: QuicklinksSetForm = {
  id: null,
  name: null,
  audience: {
    locations: [],
    departments: [],
    functions: [],
  },
  catalogs: {
    filters: {
      name: '',
      advancedFilters: INITIAL_QUICKLINKS_SET_FORM_FILTERS
    },
    selected: []
  }
}