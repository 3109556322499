import { SectionTypes } from './section-type.enum';

export enum BlockTypes {
  TEXT = 'TEXT',
  DIVIDER = 'DIVIDER',
  SPACER = 'SPACER',
  IMAGE = 'IMAGE',
  BUTTON_LINK = 'BUTTON_LINK',
  NEWS = 'NEWS'
}

export const BLOCK_LIST: Array<BlockTypes> = [
  BlockTypes.TEXT,
  BlockTypes.SPACER,
  BlockTypes.DIVIDER,
  BlockTypes.BUTTON_LINK,
  BlockTypes.IMAGE,
  BlockTypes.NEWS
];

export interface Block {
  id: string;
  type: BlockTypes;
  markup: string;
}

/* tslint:disable */
export const BLOCK_MARKUPS = {
  [BlockTypes.TEXT]: ``,
  [BlockTypes.SPACER]: `<p class='email-block__spacer' style='pointer-events: none; width: 100%; height: 1px; border-bottom: 1px solid transparent; margin: 16px 0 15px;'></p>`,
  [BlockTypes.DIVIDER]: `<p class='email-block__divider' style='pointer-events: none; width: 100%; height: 1px; border-bottom: 1px solid  #DBD6D1; margin: 16px 0 15px;'></p>`,
  [BlockTypes.IMAGE]: ``,
  [BlockTypes.BUTTON_LINK]: ``,
  [BlockTypes.NEWS]: ``
};
/* tslint:disable */

export const BLOCK_ALLOWED_SECTIONS = {
  [BlockTypes.TEXT]: [SectionTypes.SINGLE, SectionTypes.ONE_IN_THREE, SectionTypes.THREE_IN_ONE, SectionTypes.TWO_EQUAL],
  [BlockTypes.SPACER]: [SectionTypes.SINGLE, SectionTypes.ONE_IN_THREE, SectionTypes.THREE_IN_ONE, SectionTypes.TWO_EQUAL],
  [BlockTypes.DIVIDER]: [SectionTypes.SINGLE, SectionTypes.ONE_IN_THREE, SectionTypes.THREE_IN_ONE, SectionTypes.TWO_EQUAL],
  [BlockTypes.IMAGE]: [SectionTypes.SINGLE, SectionTypes.ONE_IN_THREE, SectionTypes.THREE_IN_ONE, SectionTypes.TWO_EQUAL],
  [BlockTypes.BUTTON_LINK]: [SectionTypes.SINGLE, SectionTypes.ONE_IN_THREE, SectionTypes.THREE_IN_ONE, SectionTypes.TWO_EQUAL],
  [BlockTypes.NEWS]: [SectionTypes.SINGLE, SectionTypes.TWO_EQUAL],
}