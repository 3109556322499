import { createAction, props } from '@ngrx/store';
import { SharedNewsletter } from '.';

export const wsConnect = createAction('[NEWSLETTER2 - WS] Connect');

export const wsConnectAndCheckNewsletter = createAction(
	'[NEWSLETTER2 - WS] Connect and check newsletter',
	props<{
		newsletterId: number;
	}>()
);

export const wsConnected = createAction('[NEWSLETTER2 - WS] Connected');

export const wsSetIsConnected = createAction('[NEWSLETTER2 - WS] Set is connected');

export const wsDisconnect = createAction('[NEWSLETTER2 - WS] Disconnect');

export const wsDisconnectIfConnected = createAction('[NEWSLETTER2 - WS] Disconnect if connected');

export const wsDisconnected = createAction('[NEWSLETTER2 - WS] Disconnected');

export const wsStartKeepAlive = createAction('[NEWSLETTER2 - WS] Start keep alive!');

export const wsStopKeepAlive = createAction('[NEWSLETTER2 - WS] Stop keep alive!');

// FROM CLIENT

export const wsUpdateNewsletters = createAction('[NEWSLETTER2 - WS] Init User');

export const wsUpdateNewslettersWithNewsletterId = createAction(
	'[NEWSLETTER2 - WS] Update by Newsletter Id',
	props<{
		newsletterId: number;
		action: 'renamed' | 'deleted' | 'movedToDraft' | 'shared' | 'sent';
		data: any;
	}>()
);

export const wsStartWork = createAction(
	'[NEWSLETTER2 - WS] Start work',
	props<{
		newsletterId: number;
	}>()
);

export const wsStopWork = createAction(
	'[NEWSLETTER2 - WS] Stop work',
	props<{
		newsletterId: number;
	}>()
);

export const wsCheckNewsletter = createAction(
	'[NEWSLETTER2 - WS] Check newsletter',
	props<{
		newsletterId: number;
	}>()
);

// TO CLIENT
export const wsMessageReceived = createAction(
	'[NEWSLETTER2 - WS] Message received',
	props<{
		messageType: string;
		data: any;
	}>()
);

export const wsNewsletterInfo = createAction(
	'[NEWSLETTER2 - WS] Newsletter info message',
	props<{
		newsletters: Array<SharedNewsletter>;
	}>()
);

export const wsNewsletterTaken = createAction(
	'[NEWSLETTER2 - WS] Newsletter taken message',
	props<{
		newsletter: SharedNewsletter;
	}>()
);

export const wsNewsletterReleased = createAction(
	'[NEWSLETTER2 - WS] Newsletter released message',
	props<{
		newsletter: SharedNewsletter;
	}>()
);

export const wsNewsletterAccessDenied = createAction(
	'[NEWSLETTER2 - WS] Newsletter access denied message',
	props<{
		newsletterId: number;
	}>()
);

export const wsNewsletterAccessGranted = createAction(
	'[NEWSLETTER2 - WS] Newsletter access granted message',
	props<{
		newsletterId: number;
	}>()
);

export const wsUpdateRequired = createAction(
	'[NEWSLETTER2 - WS] Newsletter update required',
	props<{
		id: number;
		action: 'renamed' | 'deleted' | 'movedToDraft' | 'shared' | 'sent' | 'contributorAdded' | 'contributorRemoved';
		data: any;
	}>()
);

export const wsUnknownMessage = createAction(
	'[NEWSLETTER2 - WS] Newsletter unknown message',
	props<{
		messageType: string;
		data: any;
	}>()
);
