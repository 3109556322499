import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

declare let ga: (arg1: any, arg2: any, arg3?: any) => void;

@Injectable()
export class GoogleAnalyticsService {

  private sub: Subscription;

  constructor(public router: Router) {
  }

  public startTracking() {
    this.sub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
  }

  public emitEvent(eventCategory: string,
                   eventAction: string,
                   eventLabel: string = null,
                   eventValue: number = null) {
    ga('send', 'event', {eventCategory, eventLabel, eventAction, eventValue});
  }

  public stopTracking() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
