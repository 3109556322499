<div class="dropdown__tree--node"
[class.dropdown__tree--node__selected]="nodeScope.isMultiple? (nodeScope.hasChild? nodeScope.areAllChildsSelected && nodeScope.isSelected : nodeScope.isSelected) : nodeScope.isSelected"
[style.padding-left]="nodeScope.isMultiple? (16 + nodeScope.node.level * 24) + 'px' : '16px'">
  <rds-checkbox
    *ngIf="nodeScope.isMultiple"
    (mousedown)="$event.stopPropagation()"
    (change)="$event ? toggleCheckbox(nodeScope.node) : null"
    [indeterminate]="nodeScope.hasSelectionValue && nodeScope.isAnyChildSelected && (!nodeScope.areAllChildsSelected || (!nodeScope.selectParentWhenAllSelected && !nodeScope.isSelected && nodeScope.areAllChildsSelected))"
    [checked]="nodeScope.hasChild? nodeScope.areAllChildsSelected && nodeScope.isSelected : nodeScope.isSelected"
  >
  <span
  [innerHTML]="nodeScope.node | dropdownKey:nodeScope.optionsModel.nameKey ?? '' | highlight:nodeScope.searchPhrase">
</span>
  </rds-checkbox>
  <span *ngIf="!nodeScope.isMultiple" class="dropdown__tree--node__name"
    [style.padding-left]="nodeScope.isMultiple? '0px' : (nodeScope.size === 'small'? (nodeScope.node.level * 40 + 5) + 'px' : (nodeScope.node.level * 40 + 14)+ 'px')"
    [class.dropdown__tree--node__name--small]="nodeScope.size === 'small'"
    [class.dropdown__tree--node__name--medium]="nodeScope.size === 'medium'"
    (mousedown)="nodeScope.isMultiple? {} : nameClicked(nodeScope.node)"
    [innerHTML]="nodeScope.node | dropdownKey:nodeScope.optionsModel.nameKey ?? '' | highlight:nodeScope.searchPhrase">
  </span>
  <button 
    rds-icon-button
    *ngIf="nodeScope.hasChild && nodeScope.searchPhrase"
    (mousedown)="$event.preventDefault(); toggleNode(nodeScope.node)"
    (keyup.enter)="$event.preventDefault(); toggleNode(nodeScope.node)"
    (keyup.space)="$event.preventDefault(); toggleNode(nodeScope.node)"
    [style.visibility]="nodeScope.node.expandable ? 'visible' : 'hidden'">
    <rds-icon
    [icon]="nodeScope.isExpanded || nodeScope.isAnyChildMatchingPhrase? 'chevron_down' : 'chevron_right'"></rds-icon>
  </button>
  <button 
    rds-icon-button
    *ngIf="nodeScope.hasChild && !nodeScope.searchPhrase"
    (mousedown)="$event.preventDefault(); toggleNode(nodeScope.node)" 
    [style.visibility]="nodeScope.node.expandable ? 'visible' : 'hidden'">
    <rds-icon
    [icon]="nodeScope.isExpanded? 'chevron_down' : 'chevron_right'"></rds-icon>
  </button>
</div>