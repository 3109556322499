import { createReducer, Action, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';

import * as fromActions from '@core/core-store/timezones/timezones.actions';
import { TimeZone } from '@app/core/models';

export interface State {
  defaultTimeZone: string;
  timeZones: {
    ids: string[] | number[];
    entities: Dictionary<TimeZone>;
  };
}

export const adapter: EntityAdapter<TimeZone> = createEntityAdapter<TimeZone>({});

export const initialState: State = {
  defaultTimeZone: '',
  timeZones: {
    ...adapter.getInitialState()
  }
};

const timeZonesReducer = createReducer(
  initialState,
  on(fromActions.timeZonesCollectionAddMany, (state, { timeZones, defaultTimeZone }) => ({
    ...state,
    defaultTimeZone,
    timeZones: {
      ...adapter.addMany(timeZones, state.timeZones)
    }
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return timeZonesReducer(state, action);
}

export const getDefaultTimeZone = (state: State) => state.defaultTimeZone;
export const getTimezones = (state: State) => state.timeZones;

export const getIds = adapter.getSelectors().selectIds;
export const getEntities = adapter.getSelectors().selectEntities;
export const getAll = adapter.getSelectors().selectAll;
export const getTotal = adapter.getSelectors().selectTotal;

export const getEntityById = (entities: Dictionary<TimeZone>, { id }) => entities[id];
export const getEntitiesById = (entities: Dictionary<TimeZone>, { ids }) => ids.map(id => entities[id]);

