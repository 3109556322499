import { Component, Inject } from '@angular/core';
import { NewsSuggestion } from '@app/core/models/newsboard';
import { RDS_DIALOG_DATA } from '@rds/angular-components';

export interface RejectionCommentDialogData {
  news: NewsSuggestion
}
@Component({
  selector: 'rh-rejection-comment-dialog',
  templateUrl: './rejection-comment-dialog.component.html',
  styleUrls: ['./rejection-comment-dialog.component.scss']
})
export class RejectionCommentDialogComponent {

  constructor(@Inject(RDS_DIALOG_DATA) public data: RejectionCommentDialogData) {
   }
}
