<div *ngIf="event" class="event-header keyline" [class.force-mobile]="forceMobileView">
	<div class="container">
		<div class="row">
			<div [class.col-8]="!forceMobileView" [class.col-12]="forceMobileView" [class.offset-2]="!forceMobileView" class="lmt-3 lmb-2">
				<rds-breadcrumbs [items]="breadcrumbs" (itemClick)="breadcrumbItemClicked($event)"></rds-breadcrumbs>
			</div>
		</div>
		<div class="row">
			<div [class.col-8]="!forceMobileView" [class.col-12]="forceMobileView" [class.offset-2]="!forceMobileView" class="lmb-3">
				<h1 class="article-heading-1">{{event.title}}</h1>
			</div>
		</div>
		<div *ngIf="event.meetingUrl && !isPast" class="row">
			<div [class.col-8]="!forceMobileView" [class.col-12]="forceMobileView" [class.offset-2]="!forceMobileView" class="lmb-3">
				<button
					*ngIf="isNow"
					rds-primary-button
					size="l"
					(click)="openMeetingURL(event.meetingUrl)"
				>
					Join On-Line Now!
				</button>
				<button
					*ngIf="isFuture"
					rds-secondary-button
					size="l"
				>
					Join in&nbsp;<countdown *ngIf="config" #cd [config]="config"></countdown>
				</button>
			</div>
		</div>
		<div class="row">
			<div [class.col-8]="!forceMobileView" [class.col-12]="forceMobileView" [class.offset-2]="!forceMobileView" class="lmb-4">


				<ng-container *ngIf="event.registrationUrl">
					<button rds-secondary-button class="me-9" (click)="openRegistrationLink(event.registrationUrl)">
						Register to the event
						<rds-icon class="icon-button cursor-pointer" icon="launch" namespace="outlined"></rds-icon>
					</button>

				</ng-container>
				<ng-container *ngIf="!event.registrationUrl">
					<button rds-secondary-button class="me-9" (click)="addToCalendar()">
						<rds-icon
							icon="calendar"
							namespace="outlined"
						></rds-icon>
						Add to calendar
					</button>
				</ng-container>

				<button rds-text-button class="me-9" (click)="shareEvent()">
					<rds-icon
						icon="share"
						namespace="outlined"
					></rds-icon>
					Share
				</button>
			</div>
		</div>
	</div>
</div>
<div class="event-info" [class.force-mobile]="forceMobileView">
	<div class="container">
		<div class="row">
			<div [class.col-12]="!forceMobileView" [class.col-12]="forceMobileView">
				<div class="row">
					<div [class.col-3]="!forceMobileView" [class.col-12]="forceMobileView" [class.offset-1]="!forceMobileView" class="   lmt-3">
						<p class="ui-label-m mb-5">Date & Time</p>
						<p *ngIf="event.date && readOnly" class="ui-body-l-bold mb-8">{{event.date | date:'MMMM d, y'}}
							<br/>{{event.allDay ? 'All day' : (event.startTime | date:'shortTime') + ' - ' + (event.endTime | date:'shortTime')}}
						</p>
						<p *ngIf="event.date && !readOnly" class="ui-body-l-bold mb-8">{{zonedDate | date:'MMMM d, y'}}
							<br/>{{event.allDay ? 'All day' : (zonedStart | date:'shortTime') + ' - ' + (zonedEnd | date:'shortTime')}}
						</p>
					</div>
					<div [class.col-3]="!forceMobileView" [class.col-12]="forceMobileView" [class.offset-1]="!forceMobileView" class="   lmt-3">
						<ng-container *ngIf="event.location">
							<p class="ui-label-m mb-5">Location</p>
							<p class="ui-body-l-bold mb-8">{{event.location}} </p>
						</ng-container>

					</div>
					<div [class.col-3]="!forceMobileView" [class.col-12]="forceMobileView" [class.offset-1]="!forceMobileView" class="   lmt-3">
						<ng-container *ngIf="event.host">
							<p class="ui-label-m mb-5">Host</p>
							<p class="ui-body-l-bold mb-8">{{event.host}} </p>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="event-image" [class.force-mobile]="forceMobileView">
	<!-- <div class="event-image__background">
		<img [src]="event.image">
	</div> -->
	<div class="container">
		<div class="row">
			<div [class.col-8]="!forceMobileView" [class.col-12]="forceMobileView" [class.offset-2]="!forceMobileView">
				<img [src]="event.imageUrl || '/assets/images/event-placeholder.png'">
			</div>
		</div>
	</div>
</div>
<div class="event-content" [class.force-mobile]="forceMobileView">
	<div class="container">
		<div class="row">
			<div [class.col-8]="!forceMobileView" [class.col-12]="forceMobileView" [class.offset-2]="!forceMobileView" class="lmt-5 lmb-3 ck-content" [innerHTML]="content">

			</div>
		</div>
	</div>
</div>
<div class="event-footer" [class.force-mobile]="forceMobileView">
	<div class="container">
		<div class="row">
			<div [class.col-3]="!forceMobileView" [class.col-12]="forceMobileView" [class.offset-1]="!forceMobileView" class="   lmb-4 lmt-3">
				<p class="ui-label-m">Event type</p>
				<ng-container [ngSwitch]="event.type">
					<p *ngSwitchCase="RhEventType.ON_SITE" class="ui-body-l-bold">On-Site</p>
					<p *ngSwitchCase="RhEventType.VIRTUAL" class="ui-body-l-bold">Virtual</p>
					<p *ngSwitchCase="RhEventType.HYBRID" class="ui-body-l-bold">Hybrid</p>
					<p *ngSwitchCase="RhEventType.OFF_SITE" class="ui-body-l-bold">Off-Site</p>
				</ng-container>
			</div>
			<div *ngIf="event.contact" [class.col-3]="!forceMobileView" [class.col-12]="forceMobileView" [class.offset-1]="!forceMobileView" class="   lmb-4 lmt-3">
					<p class="ui-label-m">Contact</p>
					<p class="ui-body-l-bold">
						<a *ngIf="isEmail(event.contact)" [href]="'mailto:'+event.contact">{{event.contact}}</a>
						<span *ngIf="!isEmail(event.contact)">{{event.contact}}</span>
					</p>
			</div>
			<div *ngIf="event.eventUrl" [class.col-3]="!forceMobileView" [class.col-12]="forceMobileView" [class.offset-1]="!forceMobileView" class="  lmb-4 lmt-3">
					<p class="ui-label-m">Event page</p>
					<p *ngIf="event.eventUrl" class="ui-body-l-bold"><a [href]="event.eventUrl" target="_blank">Visit event
						page</a></p>
			</div>
		</div>
		<div *ngIf="event.topics && event.topics.length > 0" class="row">
			<div [class.col]="!forceMobileView" [class.col-12]="forceMobileView" [class.offset-1]="!forceMobileView" class=" lmb-3">
				<span class="ui-body-l-bold">Topics</span>
				<rds-chip-list class="ms-5" size="s">
					<rds-chip *ngFor="let topic of event.topics">{{topic}}</rds-chip>
				</rds-chip-list>
			</div>
		</div>
	</div>
</div>
