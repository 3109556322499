import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';

import { MessagingService } from '@app/core/services/messaging.service';
import { ConfirmDialogComponent, ConfirmDialogData } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { RdsDialogService } from '@rds/angular-components';
import { of } from 'rxjs';
import * as fromActions from './notification-settings.actions';
import * as fromReducer from './notification-settings.reducer';
import * as fromSelectors from './notification-settings.selectors';

@Injectable()
export class NotificationSettingsEffects {

  public initForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initForm),
      switchMap(() => this.messagingService.getNotificationSettings()),
      map(({notificationsEnabled, channelSettings}) => fromActions.setInitialValue({notificationsEnabled, channelSettings})),
    ), { dispatch: true }
  );

  
  public saveAllowNotificationsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveAllowNotificationsRequest),
      switchMap(() => this.store$.pipe(
        select(fromSelectors.selectNotificationsEnabled),
        distinctUntilChanged((prev, next) => prev === next),
        take(1)
      )),
      switchMap((notificationsEnabled) => this.messagingService.putNotificationSettings({notificationsEnabled}).pipe(
        map(({notificationsEnabled, channelSettings}) => fromActions.saveAllowNotificationsSuccess({ notificationsEnabled, channelSettings})),
        catchError(({ message }) => of(fromActions.saveAllowNotificationsFailure({ error: message })))
      )),
    ), { dispatch: true }
  );

  public saveChannelSettingsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveChannelSettingsRequest),
      switchMap(() => this.store$.pipe(
        select(fromSelectors.selectChannelSettings),
        distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next)),
        take(1)
        )),
      switchMap((channelSettings) => this.messagingService.putSaveChannelsSettings(channelSettings).pipe(
        map((newChannelSettings) => fromActions.saveChannelSettingsSuccess({notificationsEnabled: true, channelSettings: newChannelSettings})),
        catchError(({ message }) => of(fromActions.saveChannelSettingsFailure({ error: message })))
      )),
    ), { dispatch: true }
  );

  
  public openResetChannelSettingsConfirmDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openResetChannelSettingsConfirmDialog),
      map(() => {
        const data: ConfirmDialogData = {
          title: 'Are you sure you want to reset your Channel notification settings?',
          messages: [
            'Your Channel notification settings will be reset to its default value'
          ],
          confirmButtonType: 'primary',
          confirmButtonLabel: 'Yes, reset'
        }
        return ({data})
      }),
      switchMap(({data}) => this.dialogService.open(ConfirmDialogComponent, {
        data
      }).afterClosed().pipe(
        filter((closedData) => !!closedData),
        map(() => fromActions.resetChannelSettingsRequest())
      ))
    )
  )

  public resetChannelSettingsRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.resetChannelSettingsRequest),
    switchMap((form) => this.messagingService.putResetChannelsSettings().pipe(
      map(({notificationsEnabled, channelSettings}) => fromActions.resetChannelSettingsSuccess({notificationsEnabled, channelSettings})),
      catchError(({ message }) => of(fromActions.resetChannelSettingsFailure({ error: message })))
    )),
  ), { dispatch: true }
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private messagingService: MessagingService,
    private dialogService: RdsDialogService
  ) {}
}

