import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService, HttpError } from '@core/services/api/api.service';
import { EndpointsService } from '@core/services/endpoints/endpoints.service';
import { Taxonomy, TaxonomyFlat, ManagedTaxonomy, TaxonomyManagers } from '@core/models/taxonomy.model';
import { UtilsService } from './utils.service';

@Injectable()
export class TaxonomyService {

  getDepartmentsFlat(): Observable<TaxonomyFlat[]> {
    const url = this.endpoints.ENDPOINT.DEPARTMENT.FLAT.GET;

    return this.http
      .get(this.endpoints.addParams(url, {activeOnly: false}))
      .pipe(
        map(data => this.utilsService.removeNonExistingChildren<TaxonomyFlat>(data, 'id', 'childrenIds')),
        catchError((error: HttpError) => throwError(`Cannot get flat departments. ${error.message}`))
      );
  }

  getDepartmentsNested(): Observable<Taxonomy[]> {
    const url = this.endpoints.ENDPOINT.DEPARTMENT.NESTED.GET;

    return this.http
      .get(url)
      .pipe(
        map((data) => this.utilsService.markChildWithParentIdName<Taxonomy>(data, 'children', 'id', 'name')),
        map(data => this.utilsService.removeInActiveValues<Taxonomy>(data,  'children')),
        map(data => this.utilsService.removeInEmptyChildren<Taxonomy>(data,  'children')),
        catchError((error: HttpError) => throwError(`Cannot get nested departments. ${error.message}`))
      );
  }

  getFunctionsFlat(): Observable<TaxonomyFlat[]> {
    const url = this.endpoints.ENDPOINT.FUNCTION.FLAT.GET;

    return this.http
      .get(this.endpoints.addParams(url, {activeOnly: false}))
      .pipe(
        map(data => this.utilsService.removeNonExistingChildren<TaxonomyFlat>(data, 'id', 'childrenIds')),
        catchError((error: HttpError) => throwError(`Cannot get flat functions. ${error.message}`))
      );
  }

  getFunctionsNested(): Observable<Taxonomy[]> {
    const url = this.endpoints.ENDPOINT.FUNCTION.NESTED.GET;

    return this.http
      .get(url)
      .pipe(
        map((data) => this.utilsService.markChildWithParentIdName<Taxonomy>(data, 'children', 'id', 'name')),
        map(data => this.utilsService.removeInActiveValues<Taxonomy>(data,  'children')),
        map(data => this.utilsService.removeInEmptyChildren<Taxonomy>(data,  'children')),
        catchError((error: HttpError) => throwError(`Cannot get nested functions. ${error.message}`))
      );
  }

  getLocationsFlat(): Observable<TaxonomyFlat[]> {
    const url = this.endpoints.ENDPOINT.LOCATION.FLAT.GET;

    return this.http
      .get(this.endpoints.addParams(url, {activeOnly: false}))
      .pipe(
        map(data => this.utilsService.removeNonExistingChildren<TaxonomyFlat>(data, 'id', 'childrenIds')),
        catchError((error: HttpError) => throwError(`Cannot get flat locations. ${error.message}`))
      );
  }

  getLocationsNested(): Observable<Taxonomy[]> {
    const url = this.endpoints.ENDPOINT.LOCATION.NESTED.GET;

    return this.http
      .get(url)
      .pipe(
        map((data) => this.utilsService.markChildWithParentIdName<Taxonomy>(data, 'children', 'id', 'name')),
        map(data => this.utilsService.removeInActiveValues<Taxonomy>(data,  'children')),
        map(data => this.utilsService.removeInEmptyChildren<Taxonomy>(data,  'children')),
        catchError((error: HttpError) => throwError(`Cannot get nested locations. ${error.message}`))
      );
  }

  getTaxonomyManagerByName(login: string): Observable<ManagedTaxonomy[]>  {
    const url = this.endpoints.ENDPOINT.TAXONOMY_MANAGER.BY_NAME.PUT;

    return this.http
      .put(url, [login])
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get nested locations. ${error.message}`))
      );
  }

  getLocationManagers(locationId: string, includeParents = false): Observable<TaxonomyManagers> {
    const url = this.endpoints.replaceUrlTokens(
      this.endpoints.ENDPOINT.LOCATION.MANAGERS.GET, { locationId }
    );

    const queryParamsUrl = this.endpoints.addParams(url, {includeParents});

    return this.http
      .get(queryParamsUrl)
      .pipe(
        map(result => ({
          taxonomyId: locationId,
          managers: result
        })),
        catchError((error: HttpError) => throwError(`Cannot get managers ${error.message}`))
      );
  }

  getDepartmentManagers(departmentId: string, includeParents = false): Observable<TaxonomyManagers> {
    const url = this.endpoints.replaceUrlTokens(
      this.endpoints.ENDPOINT.DEPARTMENT.MANAGERS.GET, { departmentId }
    );

    const queryParamsUrl = this.endpoints.addParams(url, {includeParents});

    return this.http
      .get(queryParamsUrl)
      .pipe(
        map(result => ({
          taxonomyId: departmentId,
          managers: result
        })),
        catchError((error: HttpError) => throwError(`Cannot get managers ${error.message}`))
      );
  }

  getFunctionManagers(functionId: string, includeParents = false): Observable<TaxonomyManagers> {
    const url = this.endpoints.replaceUrlTokens(
      this.endpoints.ENDPOINT.FUNCTION.MANAGERS.GET, { functionId }
    );

    const queryParamsUrl = this.endpoints.addParams(url, {includeParents});

    return this.http
      .get(queryParamsUrl)
      .pipe(
        map(result => ({
          taxonomyId: functionId,
          managers: result
        })),
        catchError((error: HttpError) => throwError(`Cannot get managers ${error.message}`))
      );
  }

  constructor(
    private http: ApiService,
    private endpoints: EndpointsService,
    private utilsService: UtilsService,
  ) {}
}
