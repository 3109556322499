import { Injectable } from '@angular/core';

import { environment } from '@env/environment';

@Injectable()
export class DevelopmentGuard  {
  canActivate() {
    return environment.dev;
  }
}
