import Command from '@ckeditor/ckeditor5-core/src/command';

export default class CustomImageAlternativeCommand extends Command {
    execute(options) {
        const model = this.editor.model;
        model.change(writer => {
            const selection = model.document.selection;
            const element = selection.getSelectedElement();
            writer.setAttribute('alt', options.newValue, element);
            writer.setSelection(element, 'on');
        });
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        let element = selection.getSelectedElement();


        if (!element) {
            element = checkParent(selection);
        }

        this.isEnabled = !!element;

        if (this.isEnabled && element.hasAttribute('alt')) {
            this.value = element.getAttribute('alt');
        } else {
            this.value = false;
        }
    }
}

export function checkParent(selection) {
    const selectionPosition = selection.getFirstPosition();
    let parent = selectionPosition.parent;
    while (parent) {
        if (parent.hasAttribute('alt')) {
            return parent;
        }
        parent = parent.parent;
    }
    return null
}
