import { createAction, props } from '@ngrx/store';
import { ContentItem } from '@app/core/models/content-item.model';
import { Pagination } from '@app/core/models/pagination.model';

export const initChannelSectionByChanelId = createAction(
  '[CHANNEL SECTION - INIT] Init Section with default values',
  props<{ id: string, carouselDisplaySize: number, elementsPerSlide: number }>()
);

export const showNextItems = createAction(
  '[CHANNEL SECTION - SHOW NEXT] Show next items click',
  props<{ id: string }>()
);

export const showPreviousItems = createAction(
  '[CHANNEL SECTION - SHOW PREVIOUS] Show previous items click',
  props<{ id: string }>()
);

export const loadMoreItems = createAction(
  '[CHANNEL SECTION - LOAD MORE] Load more items to display',
  props<{ id: string }>()
);

export const setCarouselDisplaySize = createAction(
  '[CHANNEL SECTION - SET CAROUSEL DISPLAY SIZE] Set number of displayed items',
  props<{ id: string, carouselDisplaySize: number }>()
);

export const setCarouselIncrementSize = createAction(
  '[CHANNEL SECTION - SET CAROUSEL INCREMENT SIZE] Set number of incremented items',
  props<{ id: string, carouselIncrementSize: number }>()
);

export const carouselAnimationStarted = createAction(
  '[CHANNEL SECTION - CAROUSEL ANIMATION STARTED] Animation started',
  props<{ id: string }>()
);

export const carouselAnimationFinished = createAction(
  '[CHANNEL SECTION - CAROUSEL ANIMATION FINISHED] Animation finished',
  props<{ id: string }>()
);

export const changeCarouselStart = createAction(
  '[CHANNEL SECTION - SET CAROUSEL START] CHANGE start point from where to display items',
  props<{ id: string, carouselIncrementSize?: number }>()
);

export const goToChannel = createAction(
  '[CHANNEL SECTION - GO CHANNEL] Navigate to Channel Details',
  props<{ path: string }>()
);

export const goToContent = createAction(
  '[CHANNEL SECTION - GO CONTENT] Navigate to Content Details',
  props<{ path: string, queryParams }>()
);

export const goOutside = createAction(
  '[CHANNEL SECTION - REDIRECT OUT] Redirect outside',
  props<{ url: string }>()
);

export const getContentByChannelIdRequest = createAction(
  '[CONTENT API - REQUEST] Get Content by channel Id Request',
  props<{ id: string, pageIndex: number, pageSize: number }>()
);

export const getContentByChannelIdSuccess = createAction(
  '[CONTENT API - SUCCESS] Get Content by channel Id Success',
  props<{ id: string, contentItems: ContentItem[], pagination: Pagination }>()
);

export const getContentByChannelIdError = createAction(
  '[CONTENT API - ERROR] Get Content by channel Id Error',
  props<{ id: string, error: any }>()
);

export const showNextSlide = createAction(
  '[CHANNEL SECTION - NEW CAROUSEL SHOW NEXT SLIDE] Show next slide for new carousel',
  props<{ id: string }>()
);

export const showPrevSlide = createAction(
  '[CHANNEL SECTION - NEW CAROUSEL SHOW PREV SLIDE] Show prev slide for new carousel',
  props<{ id: string }>()
);

export const loadNextSlide = createAction(
  '[CHANNEL SECTION - NEW CAROUSEL LOAD NEXT SLIDE] Load next slide for new carousel',
  props<{ id: string }>()
);
// BOOKMARKS
export const setBookmarkFromChannelItem =  createAction(
  '[CHANNEL SECTION - PUT BOOKMARK] Request',
  props<{ contentId: string}>()
);
export const setNoBookmarkFromChannelItem =  createAction(
  '[CHANNEL SECTION - REMOVE BOOKMARK] Request',
  props<{ contentId: string}>()
);
export const setBookmarkFromLatestNews =  createAction(
  '[CHANNEL SECTION - PUT BOOKMARK] Request From Latest',
  props<{ contentId: string}>()
);
export const setNoBookmarksFromLatestNews =  createAction(
  '[CHANNEL SECTION - REMOVE BOOKMARK] Request From Latest',
  props<{ contentId: string}>()
);
export const setBookmarkFromChannelItemFailure =  createAction(
  '[CHANNEL SECTION - PUT BOOKMARK ] Failure',
);
export const setNoBookmarkFromChannelItemFailure =  createAction(
  '[CHANNEL SECTION - REMOVE BOOKMARK] Failure',
);
