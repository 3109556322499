import {Component, Inject, ViewChild} from '@angular/core';
import {NewsboardAuthService} from '@app/core/auth/services/newsboard-auth.service';
import {Section} from '@app/newsletter/inline-editor/models/section-type.enum';
import {
  ColorsPalette,
  EMPTY_NEWSLETTER_WITH_SECTIONS,
  EMPTY_NEWSLETTER_WITHOUT_SECTIONS,
  NewsletterInlineForm,
  NewsletterValueModel,
  TemplateModel
} from '@app/newsletter/models';
import {BackButton} from '@app/root-store/ui/back-button';
import {RDS_DIALOG_DATA, RdsDialogRef, RdsTabsComponent} from '@rds/angular-components';
import {Observable} from 'rxjs';

type ContentMode = 'desktop' | 'mobile';

export interface PreviewButtonState {
    visible: boolean;
    disabled: boolean;
}

export interface NewsletterPreviewButtons {
    editNewsletter?: PreviewButtonState
    canSaveNewsletter?: PreviewButtonState
}

export interface NewsletterPreviewDialogData {
    predefinedNewsletter?: Partial<NewsletterValueModel>;
    templateId?: string;
    newsletterId?: number;
    formNewsletter?: Observable<NewsletterValueModel>;
    sections?: Observable<Array<Section>>;
    form?: Observable<Partial<NewsletterInlineForm>>;
    // Change template modal;
    templates?: Observable<Array<TemplateModel>>;
    selectedTemplate?: Observable<TemplateModel>;

    //context
    context:
        'Editor'
        | 'Dashboard'
        | 'Template'
        | 'SharedTemplate'
        | 'PredefinedTemplate'
        | 'ReuseNewsletter'
        | 'SaveAsTemplate'
        | 'ChangeTemplate';
    buttons?: NewsletterPreviewButtons
}

@Component({
    selector: 'rh-newsletter-preview-dialog',
    templateUrl: './newsletter-preview-dialog.component.html',
    styleUrls: ['./newsletter-preview-dialog.component.scss']
})
export class NewsletterPreviewDialogComponent {
    @ViewChild('tabs') tabs: RdsTabsComponent;
    templateId: string;
    newsletterId: number;
    predefinedNewsletter: Partial<NewsletterValueModel>;
    formNewsletter: Observable<NewsletterValueModel>;
    sections: Observable<Array<Section>>;
    form: Observable<Partial<NewsletterInlineForm>>;
    // Change template modal;
    templates: Observable<Array<TemplateModel>>;
    selectedTemplate: Observable<TemplateModel>;

    selectedColor = ColorsPalette[0];

    emptyNewsletterWithSections = EMPTY_NEWSLETTER_WITH_SECTIONS;
    emptyNewsletterWithoutSections = EMPTY_NEWSLETTER_WITHOUT_SECTIONS;

    context:
        'Editor'
        | 'Dashboard'
        | 'Template'
        | 'SharedTemplate'
        | 'PredefinedTemplate'
        | 'ReuseNewsletter'
        | 'SaveAsTemplate'
        | 'ChangeTemplate';

    backButton: BackButton;
    buttons: NewsletterPreviewButtons;
    contentMode: ContentMode = 'desktop';

    public activeTemplate: { selected: TemplateModel, current: TemplateModel } = {selected: null, current: null};

    constructor(
        private auth: NewsboardAuthService,
        private dialogRef: RdsDialogRef<NewsletterPreviewDialogComponent>,
        @Inject(RDS_DIALOG_DATA) data: NewsletterPreviewDialogData
    ) {
        if (!!data) {
            console.log(data)
            this.formNewsletter = data.formNewsletter;
            this.form = data.form;
            this.sections = data.sections;
            this.context = data?.context;
            this.buttons = data?.buttons;
            this.templateId = data.templateId;
            this.newsletterId = data.newsletterId;
            this.predefinedNewsletter = data.predefinedNewsletter;
            this.templates = data.templates;
            this.selectedTemplate = data.selectedTemplate;

            if (!!this.templates) {
                this.templates.subscribe(data => {
                    const template = data.find(t => t.templateId === this.templateId);
                    this.activeTemplate.selected = template;
                    this.activeTemplate.current = template;
                });
            }
        }
    }

    close(status?): void {
        this.dialogRef.close({status});
    }

    public changeTemplate(template: TemplateModel) {
        this.activeTemplate.current = template;
    }

}
