import { createAction, props } from '@ngrx/store';

import { Language } from '@core/models/language.model';

export const loadLanguagesDict = createAction(
  '[CORE LANGUAGES DICT - LOAD LANGUAGES DICT] Load LANGUAGES dictionary',
);

export const getLanguagesDictRequest = createAction(
  '[CORE LANGUAGES DICT - LANGUAGES DICT REQUEST] Get LANGUAGES dictionary Request',
);

export const getLanguagesDictSuccess = createAction(
  '[CORE LANGUAGES DICT - LANGUAGES DICT SUCCESS] Get LANGUAGES dictionary Success',
    props<{ languagesDict: Array<Language> }>()
);

export const getLanguagesDictFailure = createAction(
  '[CORE LANGUAGES DICT - LANGUAGES DICT FAILURE] Get LANGUAGES dictionary Failure',
    props<{ error: any }>()
);

export const languagesDictCollectionAddMany = createAction(
  '[STORED LANGUAGES DICT - ADD MANY] Languages Dict collection add many.',
  props<{ languagesDict: Array<Language> }>()
);
