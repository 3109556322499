import {ChangeDetectorRef, Directive, Input, OnDestroy, OnInit} from '@angular/core';
import * as fromUserPhoto from '@core/core-store/user-photo';
import {select, Store} from '@ngrx/store';
import {SubSink} from 'subsink';

@Directive({
  selector: '[rhFetchAvatar]',
  exportAs: 'rhFetchAvatar'
})
export class FetchAvatarDirective implements OnInit, OnDestroy {

  @Input('rhFetchAvatar') login: string;
  @Input('rhFetchAvatarFetchPhoto') fetchPhoto: boolean = true;
  @Input('rhFetchAvatarPhotoUrl') photoUrl: string;
  subs: SubSink = new SubSink();
  src = null;
  notFound = false

  ngOnInit() {
    if (this.fetchPhoto) {
      this.store$.dispatch(fromUserPhoto.getUserPhoto({login: this.login}));
      this.subs.sink = this.store$.pipe(
        select(fromUserPhoto.selectEntityById, {id: this.login}),
      ).subscribe(userPhoto => {
        if (!!userPhoto && userPhoto.url) {
          this.src = userPhoto.url;
          this.cdr.markForCheck()
        }
        this.notFound = userPhoto?.userNotFound;
      });
    } else {
      this.src = this.photoUrl;
      this.cdr.markForCheck()
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  constructor(
    private cdr: ChangeDetectorRef, private store$: Store<fromUserPhoto.State>) {
  }

}
