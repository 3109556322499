<div class="container">
  <div class="row">
    <div class="col">
      <rds-header [scrollTarget]="null" searchPosition="none" (logoTriggered)="logoClick()" minimalMargins>
        <rds-divider rds-header-start-divider></rds-divider>
        <rds-header-app-name></rds-header-app-name>
        <rds-header-navigation-primary>
          <div class="search-container" rdsDropdown [autoClose]="'outside'" (hidden)="onHide()" #dropdownContainer
              #searchSuggestions="rdsDropdown">
          <rds-header-searchbar #searchBar placeholder="Search RocheHome and Google Cloud" (search)="search($event)"
                      (searchChange)="searchChange($event)"></rds-header-searchbar>
          <div rdsDropdownToggle style="visibility: hidden;"></div>
          <rh-header-suggestions *rdsDropdownMenu class="dropdown" (selected)="closeSuggestions()"
                        (shouldBeHidden)="closeSuggestions()" [parentElement]="dropdownContainer"
                        [searchValue]="searchBar.searchValue"></rh-header-suggestions>
          </div>
          <rds-header-link
            (toggle)="onQuickLinksToggle()">Quicklinks
          </rds-header-link>
          <ng-container *ngFor="let link of topLinks">
            <rds-header-link class="rds-header-link-m"
              [rdsMenuTriggerFor]="panel.menu"
              #topTrigger="rdsMenuTrigger"
              [active]="topTrigger.menuOpen">
              {{link.link}}
            </rds-header-link>
            <rh-header-menu
              style="display: none"
              #panel
              [links]="link.nodes"
              [trigger]="topTrigger"
            >
            </rh-header-menu>
          </ng-container>
        </rds-header-navigation-primary>
        <rds-divider rds-header-end-divider></rds-divider>
        <rds-header-actions>
          <button rds-dropdown-toggle-icon-button [size]="'m'"
                  [rdsMenuTriggerFor]="gcsMenu"
                  #gcsMenuTrigger="rdsMenuTrigger"
                  [isOpen]="gcsMenuTrigger.menuOpen">
            <rds-icon class="action" namespace="outlined" icon="app_launcher"></rds-icon>
          </button>
          <rds-menu #gcsMenu="rdsMenu" class="gcs-menu"
                    xPosition="before"
                    yPosition="below">
            <rh-header-gcs-menu></rh-header-gcs-menu>
          </rds-menu>
          <button rds-dropdown-toggle-icon-button [size]="'m'"
                  [rdsMenuTriggerFor]="appMenuPanel.menu"

                  #appMenuTrigger="rdsMenuTrigger"
                  [isOpen]="appMenuTrigger.menuOpen">
            <rds-icon class="action" namespace="outlined" icon="menu"></rds-icon>
          </button>
          <rh-header-app-menu
            style="display: none"
            #appMenuPanel
          >
          </rh-header-app-menu>
        </rds-header-actions>
        <rds-header-navigation-secondary>
          <ng-container *ngFor="let link of bottomLinks; let i = index;">
            <rds-header-link
              [id]="link.link | lowercase"
              class="bottom-link"
              [rhDynamicMenu]="panel"
              [rdsMenuTriggerFor]="panel.menu"
              #bottomTrigger="rdsMenuTrigger"
              [active]="bottomTrigger.menuOpen">
              {{link.link}}
            </rds-header-link>
            <rh-header-menu
              style="display: none"
              #panel
              [links]="link.nodes"
              [triggerIndex]="i"
            >
            </rh-header-menu>
          </ng-container>
        </rds-header-navigation-secondary>
      </rds-header>
    </div>
  </div>
</div>
