import { createReducer, Action, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';

import { ContentDetails } from '@core/models/content-details.model';
import * as fromActions from '@core/core-store/content-details/content-details.actions';
import * as fromContentItemActions from '@core/core-store/content-items/content-items.actions';

export interface State extends EntityState<ContentDetails> {}

export const adapter: EntityAdapter<ContentDetails> = createEntityAdapter<ContentDetails>({});

export const initialState: State = {
  ...adapter.getInitialState(),
};

const ContentsReducer = createReducer(
  initialState,
  on(fromActions.contentDetailsCollectionAddOne, (state, { contentDetails }) => (
    adapter.addOne(contentDetails, state)
  )),
  on(fromActions.contentDetailsCollectionUpsertOne, (state, { contentDetails }) => (
    adapter.upsertOne(contentDetails, state)
  )),
  on(fromContentItemActions.putLikeByContentIdSuccess, (state, { contentId, numLikes }) => {
    if (state.entities[contentId]) {
      const options = state.entities[contentId].options;
      return {
        ...state,
        entities: {
          ...state.entities,
          [contentId]: {
            ...state.entities[contentId],
            numLikes,
            options: {
              ...options,
              hasLiked: true,
            }
          }
        }
      };
    } else {
      return state;
    }
  }),
  on(fromContentItemActions.removeLikeByContentIdSuccess, (state, { contentId, numLikes }) => {
      if (state.entities[contentId]) {
        const options = state.entities[contentId].options;
        return {
          ...state,
          entities: {
            ...state.entities,
            [contentId]: {
              ...state.entities[contentId],
              numLikes,
              options: {
                ...options,
                hasLiked: false
              }
            }
          }
        };
      } else {
        return state;
      }
  }),
  on(fromContentItemActions.putBookmarkByContentIdSuccess, (state, { contentId }) => {
    if (state.entities[contentId]) {
      const options = state.entities[contentId].options;
      return {
        ...state,
        entities: {
          ...state.entities,
          [contentId]: {
            ...state.entities[contentId],
            options: {
              ...options,
              hasBookmarked: true
            }
          }
        }
      };
    }
  }),
  on(fromContentItemActions.removeBookmarkByContentIdSuccess, (state, { contentId }) => {
    if (state.entities[contentId]) {
      const options = state.entities[contentId].options;
      return {
        ...state,
        entities: {
          ...state.entities,
          [contentId]: {
            ...state.entities[contentId],
            options: {
              ...options,
              hasBookmarked: false
            }
          }
        }
      };
    }
  }),
  on(fromActions.updateNumComments, (state, { contentId, count }) => {
    const numComments = state.entities[contentId].numComments + count;
    return {
      ...state,
      entities: {
        ...state.entities,
        [contentId]: {
          ...state.entities[contentId],
          numComments
        }
      }
    };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return ContentsReducer(state, action);
}

export const getIds = adapter.getSelectors().selectIds;
export const getEntities = adapter.getSelectors().selectEntities;
export const getAll = adapter.getSelectors().selectAll;
export const getTotal = adapter.getSelectors().selectTotal;

export const getEntityById = (entities: Dictionary<ContentDetails>, {id}) => entities[id];
export const getEntitiesById = (entities: Dictionary<ContentDetails>, {ids}) => ids.map(id => entities[id]);
