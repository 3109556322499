import { Pagination } from '@app/core/models';
import { RhEvent } from '@app/events/models/event';
import {createAction, props} from '@ngrx/store';

export const changeSelectedDate = createAction(
  '[EVENTS - GRID] change selected date ',
  props<{ selectedDate: Date }>()
);

export const getGridEventsRequest = createAction(
  '[EVENTS - GRID] Get events request'
);

export const getGridEventsSuccess = createAction(
  '[EVENTS - GRID] Get events success',
  props<{events: Array<Partial<RhEvent>>, pagination: Pagination}>()
);

export const getGridEventsFailure = createAction(
  '[EVENTS - GRID] Get events failure',
  props<{error: string}>()
);

export const loadMoreEvents = createAction(
  '[EVENTS - GRID] Load more events'
);