import { Component, Input } from '@angular/core';
import { RhEvent } from '@app/events/models/event';

@Component({
  selector: 'rh-event-autocomplete-item',
  templateUrl: './event-autocomplete-item.component.html',
  styleUrls: ['./event-autocomplete-item.component.scss']
})
export class EventAutocompleteItemComponent {
  @Input() option: RhEvent;
  @Input() highlightPhrase: string;
}
