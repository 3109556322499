import { createSelector } from '@ngrx/store';
import * as fromReducer from './global-settings.reducer';
import * as fromUserSettings from '@app/user-settings/store/user-settings.reducer';

export const selectGlobalSettings = createSelector(
  fromUserSettings.selectState,
  fromUserSettings.getGlobalSettings,
);

export const selectForm = createSelector(
  selectGlobalSettings,
  fromReducer.getForm
);

export const selectInitialForm = createSelector(
  selectGlobalSettings,
  fromReducer.getInitialForm
);
