import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, filter, first, map, mergeMap, switchMap } from 'rxjs/operators';

import * as fromActions from './user-news-articles.actions';
import * as fromReducer from './user-news-articles.reducer';
import * as fromSelectors from './user-news-articles.selectors';
import * as formContentItems from '@app/core/core-store/content-items';
import { ContentService } from '@app/core/services/content.service';
import * as fromSubscription from '@app/home/store/subscriptions';
import * as fromRouter from '@app/root-store/router/router.actions';
import { ConfirmDialogComponent } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import * as fromUser from '@core/user/store/user.selectors';
import { SubscriptionType } from '@home/store/subscriptions';
import { RdsDialogService } from '@rds/angular-components';
import { of } from 'rxjs';

@Injectable()
export class UserNewsArticlesEffects {

  public getItemsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.init,
        fromActions.loadMoreItems,
      ),

      switchMap(() => this.store$.pipe(
        select(fromSelectors.selectId),
        first(id => !!id),
        map((value) => ({value})),
      )),
      switchMap(({value}) => this.store$.pipe(
        select(fromSelectors.selectPagination),
        first(),
        map(({pageIndex, pageSize}) => ({value, pageIndex, pageSize})),
      )),
      switchMap(({value, pageIndex, pageSize}) => this.store$.pipe(
        select(fromUser.selectNewsLanguage),
        first(),
        map((language) => ({value, pageIndex, pageSize, language}))
      )),
      switchMap(({value, pageIndex, pageSize, language}) => this.store$.pipe(
        select(fromSelectors.selectSortType),
        first(),
        map((sortType) => ({value, pageIndex, pageSize, language, sortType}))
      )),
      switchMap(({value, pageIndex, pageSize, language, sortType}) => [fromActions.getItemsRequest({
          value,
          pageIndex,
          pageSize,
          language: language.code,
          sortType
        })]
      )
    ), {dispatch: true}
  );

  public getItemsRequestAuthors$ = createEffect(() =>
      this.actions$.pipe(
        ofType(fromActions.getItemsRequest),
        switchMap(({
                     value,
                     pageIndex,
                     pageSize,
                     language,
                     sortType
                   }) => this.contentService.getNewsFromAuthorSubscription(language, pageSize, pageIndex, [value], sortType).pipe(
          map(({global, pagination},) => fromActions.getItemsSuccess({
            value,
            contentItems: global.news,
            pagination: pagination
          })),
          catchError(({message}) => of(fromActions.getItemsError({value, error: message})))))
      ),
    {dispatch: true});

  public getContentByIdSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getItemsSuccess),
      mergeMap(({contentItems}) => [
        formContentItems.contentItemsCollectionAddMany({contentItems}),
      ]),
    ), {dispatch: true}
  );

  public getContentByIdError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getItemsError),
      map(() => fromRouter.go({path: '500', queryParams: {}}))
    )
  );

  public goToContentPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToContent),
      mergeMap(({path, queryParams}) => [
        fromRouter.go({path, queryParams})
      ])
    ), {dispatch: true}
  );

  public goOutside$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goOutside),
      mergeMap(({url}) => [
        fromRouter.goOutsideInNewTab({url, redirect: true})
      ])
    ), {dispatch: true}
  );

  public openUnsubscribeConfirmDialog$ = createEffect(() =>
  this.actions$.pipe(
      ofType(fromActions.openUnsubscribeConfirmDialog),
      map(({id, name}) => ({
        data:{
          title: `Are you sure you want to delete "${name}" from your authors list?`,
          messages: [`You can subscribe to this Author again at a later time.`],
          confirmButtonLabel: 'Yes, unsubscribe',
          confirmButtonType: 'primary'
        },
        id,
      })),
      switchMap(({data, id}) => this.dialogService.open(ConfirmDialogComponent, {
          data,
          size: 'm',
      }).afterClosed().pipe(
          filter(data => !!data),
          map(() => fromSubscription.deleteSubscriptionByIdsRequest({ids: [id], subType: SubscriptionType.AUTHORS}))
      ))
  )
);

public addLike$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.addLike),
  mergeMap(({id, likeCount}) => this.contentService.putLikeByContentId({contentId: id}).pipe(
    mergeMap(() => [
      fromActions.addLikeSuccess({id, likeCount}),
      formContentItems.putLikeByContentIdSuccess({contentId: id, numLikes: likeCount})
    ]),
    catchError(() => of(fromActions.addLikeFailure({id, likeCount})))
  ))
), {dispatch: true}
);

public removeLike$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.removeLike),
  mergeMap(({id, likeCount}) => this.contentService.removeLikeByContentId({contentId: id}).pipe(
    mergeMap(() => [
      fromActions.removeLikeSuccess({id, likeCount}),
      formContentItems.removeLikeByContentIdSuccess({contentId: id, numLikes: likeCount})
    ]),
    catchError(() => of(fromActions.removeLikeFailure({id, likeCount})))
  ))
), {dispatch: true}
);

public addBookmark$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.addBookmark),
  mergeMap(({id}) => this.contentService.addBookmark({contentId: id}).pipe(
    mergeMap(() => [
      fromActions.addBookmarkSuccess({id}),
      formContentItems.putBookmarkByContentIdSuccess({contentId: id})
    ]),
    catchError(() => of(fromActions.addBookmarkFailure()))
  ))
), {dispatch: true}
);

public removeBookmark$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.removeBookmark),
  mergeMap(({id}) => this.contentService.removeBookmark({contentId: id}).pipe(
    mergeMap(() => [
      fromActions.removeBookmarkSuccess({id}),
      formContentItems.removeBookmarkByContentIdSuccess({contentId: id})
    ]),
    catchError(() => of(fromActions.removeBookmarkFailure()))
  ))
), {dispatch: true}
);
  public setSubTypeAndValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setSubTypeAndValue),
      mergeMap(({value}) => [
        fromActions.setDataInfo({value, name: value})
      ])), {dispatch: true}
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private contentService: ContentService,
    private dialogService: RdsDialogService
  ) {
  }
}
