<ng-container *ngIf="!!data">
  <div rds-dialog-header></div>
  <div rds-dialog-content>
    <rh-cropp #croppCmp [file]="data.file" [aspectRatio]="data.aspectRatio" (cropped)="confirm($event)"></rh-cropp>
  </div>
  <div rds-dialog-actions-container align="end">
    <button
        rds-secondary-button
        [rds-dialog-close]="null"
        size="m"
    >Cancel</button>
    <button
        rds-primary-button
        (click)="croppCmp.cropp()"
        size="m"
    >Confirm</button>
  </div>
</ng-container>
