import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {RhEvent, RhEventType} from '@app/events/models/event';
import {Store} from '@ngrx/store';
import {SubSink} from 'subsink';
import * as fromDetails from '@app/events/store/details';
import * as fromRouter from '@app/root-store/router';
import {RdsBreadcrumbItem} from '@rds/angular-components';
import {formatDistance, intervalToDuration, isFuture, isPast, isToday, isWithinInterval} from 'date-fns';
import {CountdownConfig} from 'ngx-countdown';
import {DomSanitizer} from '@angular/platform-browser';
import {OembedPipe} from '@app/shared/oembed/oembed.pipe';
import {GapiService} from '@core/services/gapi.service';
import {zonedTimeToUtc} from 'date-fns-tz';

@Component({
  selector: 'rh-event-preview',
  templateUrl: './event-preview.component.html',
  styleUrls: ['./event-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventPreviewComponent implements OnInit, OnDestroy {
  @Input() forceMobileView = false;

  config: CountdownConfig;
  RhEventType = RhEventType;

  breadcrumbs: Array<RdsBreadcrumbItem> = [
    {text: 'RocheHome', title: '/'},
    {text: 'All events', title: 'event'}
  ];

  @Input() event: Partial<RhEvent>;
  @Input() readOnly = false;
  private subs: SubSink = new SubSink();
  @HostBinding('style.pointer-events') pointerEvents;

  get content() {
    if (this.event.content) {
      const oembedHTML = this.oembedPipe.transform(this.event.content);
      return this.sanitizer.bypassSecurityTrustHtml(oembedHTML)
    }
    return '';
  }

  get eventImage() {
    return `url('${this.event.imageUrl}')`;
  }

  get hasMeeting() {
    return this.event.meetingUrl;
  }

  get getTime() {
    return this.event.date.getTime();
  }

  get zonedDate() {
    const eventDateWithShift = new Date((this.event.date as Date));
    return this.event.date ? zonedTimeToUtc(eventDateWithShift, this.event.timeZone) : '';
  }

  get zonedDateWithResetTime() {
    const eventDateWithShift = new Date((this.event.date as Date));
    eventDateWithShift.setHours(0,0,0,0);
    return this.event.date ? zonedTimeToUtc(eventDateWithShift, this.event.timeZone) : '';
  }

  get zonedStart() {
    return this.event.date ? zonedTimeToUtc(this.event.startTime, this.event.timeZone) : '';
  }

  get zonedEnd() {
    return this.event.date ? zonedTimeToUtc(this.event.endTime, this.event.timeZone) : '';
  }

  get getFormat() {
    return 'HH:mm'
  }

  isPast;
  isFuture;
  isNow;

  constructor(
    private store$: Store<fromDetails.State>,
    private sanitizer: DomSanitizer,
    private oembedPipe: OembedPipe,
    private cdr: ChangeDetectorRef,
    private gapiService: GapiService
  ) {
  }

  ngOnInit(): void {
    this.pointerEvents = this.readOnly ? 'none' : 'unset';
    this.setTimeCondition();
    if (this.isFuture) {
      this.config = {
        stopTime: this.event.allDay ? (this.zonedDateWithResetTime as Date).getTime() : (this.zonedStart as Date).getTime(),
        format: 'HH:mm:ss',
        formatDate: ({date}) => {
          const duration = intervalToDuration({start: 0, end: date})
          if (duration.years > 0 || duration.months > 0) {
            return formatDistance(date, 0)
          }
          const countdown = duration.years + duration.months + duration.days + duration.hours + duration.minutes + duration.seconds;
          if (countdown === 0) {
            setTimeout(() => {
              this.setTimeCondition();
              this.cdr.detectChanges();
            }, 1000);
          }
          const value = `${duration.days === 0 ? '' : duration.days + 'd '}${duration.days + duration.hours === 0 ? '' : duration.hours + 'h '}${duration.days + duration.hours + duration.minutes === 0 ? '' : duration.minutes + 'm '}${duration.seconds + 's'}`;
          return value
        }
      };
    }
  }

  ngOnDestroy(): void {
    this.store$.dispatch(fromDetails.clearDetails());
  }

  breadcrumbItemClicked(event) {
    if (this.readOnly) {
      return
    }
    ;
    if (event.title) {
      this.store$.dispatch(fromRouter.go({path: event.title, queryParams: {}}));
    }
  }

  setTimeCondition() {
    this.isNow = this.event.allDay ? isToday(this.event.date) : isWithinInterval(new Date(), {
      start: this.event.startTime as Date,
      end: this.event.endTime as Date
    })
    this.isFuture = this.event.allDay ? isFuture(this.event.date) && !isToday(this.event.date) : (isFuture((this.event.startTime as Date)) && isFuture((this.event.endTime as Date)));
    this.isPast = this.event.allDay ? isPast(this.event.date) && !isToday(this.event.date) : (isPast((this.event.startTime as Date)) && isPast((this.event.endTime as Date)));
  }

  addToCalendar() {
    this.store$.dispatch(fromDetails.addToCalendarRequest({event: this.event}))
  }

  shareEvent() {
    if (this.event?.id) {
      this.store$.dispatch(fromDetails.shareEvent({body: location.origin + '/event/details/' + this.event?.id}));

    }
  }

  openMeetingURL(url) {
    this.store$.dispatch(fromRouter.goOutsideInNewTab({url, redirect: true}));
  }
	openRegistrationLink(url) {
    this.store$.dispatch(fromRouter.goOutsideInNewTab({url, redirect: true}));
  }

  isEmail(string) {
    return string.includes('@');
  }
}
