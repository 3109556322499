import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule } from '@angular/router';
import { routes } from '@app/app-routing.consts';

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: NoPreloading,
    scrollPositionRestoration: 'enabled',
    useHash: false,
    onSameUrlNavigation: 'reload'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
