import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import * as fromReducer from '@app/home/store/newsfeed/newsfeed.reducer';
import * as fromHeroSection from '@home/store/hero-section';
import * as fromSubscriptions from '@home/store/subscriptions/subscriptions.actions';
import {filter, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {NewsFeedService} from '@core/services/newsfeed.service';
import {catchError, map, of, first} from 'rxjs';
import * as fromActions from '@home/store/subscriptions/subscriptions.actions';
import {ContentService} from '@core/services/content.service';
import * as fromContentItem from '@core/core-store/content-items';

@Injectable()
export class HeroSectionEffects {

  public getNewsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromHeroSection.getNewsRequest,
      ),
      withLatestFrom(
        this.store$.pipe(select(fromHeroSection.selectChannelId)),
        this.store$.pipe(select(fromHeroSection.selectNewsCount))

      ),
      filter((id) => !!id),
      switchMap(([action, id, newsCount]) => [fromHeroSection.getChannelNews({id, pageIndex: 0, pageSize: newsCount, index: 0})])
    ), {dispatch: true}
  );


  public getNewsForChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromHeroSection.getChannelNews,
      ),
      mergeMap(({id, pageIndex, pageSize, index}) => {
        return this.newsFeedService.getHeroSectionLegacyEndpoint(id, pageIndex, pageSize).pipe(
          map(({data}) => fromHeroSection.getChannelNewsSuccess({id, data, index})),
          catchError(({message}) => of(fromActions.getAllChannelsFailure({error: message})))
        );
      })
    ), {dispatch: true}
  );

  public loadHomePage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromHeroSection.getChannelNewsSuccess,
      ),
      first(),
      map(() => fromSubscriptions.initSubscriptions())
    ), {dispatch: true}
  );

  public addLike$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromHeroSection.addLike),
      mergeMap(({id},) => this.contentService.putLikeByContentId({contentId: id}).pipe(
        map(() => fromHeroSection.addLikeSuccess({id})),
        catchError(() => of(fromHeroSection.addLikeFailure()))
      ))
    ), {dispatch: true}
  );

  public removeLike$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromHeroSection.removeLike),
      mergeMap(({id}) => this.contentService.removeLikeByContentId({contentId: id}).pipe(
        map(() => fromHeroSection.removeLikeSuccess({id})),
        catchError(() => of(fromActions.removeLikeFailure()))
      ))
    ), {dispatch: true}
  );

  public markRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromContentItem.markReadSuccess,
      ),
      map(({contentId}) => fromActions.markRead({id: contentId}))
    ), {dispatch: true}
  );

  public addBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromHeroSection.addBookmark),
      mergeMap(({id}) => this.contentService.addBookmark({contentId: id}).pipe(
        map(() => fromHeroSection.addBookmarkSuccess({id})),
        catchError(() => of(fromHeroSection.addBookmarkFailure()))
      ))
    ), {dispatch: true}
  );
  public removeBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromHeroSection.removeBookmark),
      mergeMap(({id}) => this.contentService.removeBookmark({contentId: id}).pipe(
        map(() => fromHeroSection.removeBookmarkSuccess({id})),
        catchError(() => of(fromHeroSection.removeBookmarkFailure()))
      ))
    ), {dispatch: true}
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private newsFeedService: NewsFeedService,
    private contentService: ContentService,
  ) {
  }
}
