import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RhEvent } from '@app/events/models/event';

@Component({
  selector: 'rh-event-autocomplete-selected',
  templateUrl: './event-autocomplete-selected.component.html',
  styleUrls: ['./event-autocomplete-selected.component.scss']
})
export class EventAutocompleteSelectedComponent {
  
  @Input() selectedOptions: Array<RhEvent> = [];
  @Input() disabled: boolean;
  @Input() checkRemovePrevented: Function = () => false;

  @Output() removed: EventEmitter<number> = new EventEmitter<number>();
}
