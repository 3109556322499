import { createSelector } from '@ngrx/store';
import * as fromReducer from './quicklinks-sets-table.reducer';
import * as fromManageSets from '../manage-sets.reducer';

export const selectState = createSelector(
  fromManageSets.selectState,
  fromManageSets.getQuicklinksSetsTable
);

export const selectTableState = createSelector(
  selectState,
  fromReducer.getTableState
);

export const selectTableRequestData = createSelector(
  selectTableState,
  fromReducer.getRequestData
);

export const selectTableData = createSelector(
  selectTableState,
  fromReducer.getTableData
);

export const selectTablePagination = createSelector(
  selectTableState,
  fromReducer.getPagination
);

export const selectTableTotal = createSelector(
  selectTablePagination,
  fromReducer.getTotalCount
);

export const selectTableSort = createSelector(
  selectTableState,
  fromReducer.getSort
);

export const selectTableDefaultSort = createSelector(
  selectTableState,
  fromReducer.getDefaultSort
);

export const selectTableFilters = createSelector(
  selectTableState,
  fromReducer.getFilters
);

export const selectTableDefaultFilters = createSelector(
  selectTableState,
  fromReducer.getDefaultFilters
);

export const selectTableSearch = createSelector(
  selectTableFilters,
  fromReducer.getSearch
);

export const selectTableIsLoading = createSelector(
  selectTableState,
  fromReducer.getIsLoading
);
