<rh-card *ngIf="result">
  <div body-header class="avatar-image">
    <rh-user-single [user]="result" size="xl"(click)="openPeople()" class="cursor-pointer"></rh-user-single>
  </div>
  <ng-container body-title>
    <p class="ui-label-l-bold text-low-contrast text-uppercase text-truncate mb-4">
      <span (click)="openPeople()" class="cursor-pointer">{{result.firstName + ' ' + result.lastName}} </span>
    </p>
    <p class="ui-component-text-m text-low-contrast text-truncate mb-4">
      {{result.supervisory}}
    </p>
    <p class="ui-component-text-m text-low-contrast text-truncate text-nodecor">
      <a [href]="'mailto:' + result.email">{{result.email}}</a>
    </p>
  </ng-container>
  <ng-container footer>
    <p class="ui-component-text-s text-low-contrast text-uppercase text-truncate">
      {{result.cityName}}
    </p>
    <!-- TODO: Add subscription button -->
  </ng-container>
</rh-card>
