<div style="display: flex; flex-direction: column; min-height: 100vh;">
	<rh-header2 *ngIf="('MOBILE' | isViewMode | async) === false" class="bg-white"></rh-header2>
	<rh-header-mobile *ngIf="('MOBILE' | isViewMode | async) === true"></rh-header-mobile>
	<rh-quick-link-component [isMobile]="'MOBILE' | isViewMode | async"></rh-quick-link-component>
	<div class="layout">
		<rh-desktop-only-warning *ngIf="(showOnlyDesktopWarning$ | async) == true"></rh-desktop-only-warning>
		<div
			class="main lpb-8"
			[class.mobile]="'MOBILE' | isViewMode | async"
			[class.hide]="(showOnlyDesktopWarning$ | async) == true"
		>
			<router-outlet></router-outlet>
		</div>
		<rh-development-helpers *atrIfEnvironment="'dev'"></rh-development-helpers>
	</div>
	<rh-footer class="no-print"></rh-footer>
</div>
