<div *ngIf="selectedOptions?.length > 0" class="select-container py-6">
    <div *ngFor="let selected of selectedOptions; let i = index" class="row row-centered">
        <div class="col-auto">
            <rh-user-single [user]="selected"></rh-user-single>
        </div>
        <div class="col">
            <p class="ui-label-m-bold">
                {{selected.name || (selected.firstName + ' ' + selected.lastName)}} <span
                    *ngIf="!!selected.email" class="ui-label-s">({{selected.email}})</span>
            </p>
        </div>
        <div *ngIf="!disabled && !checkRemovePrevented(selected)" class="col-auto">
            <button rds-icon-button size="m"  (click)="removed.emit(i)">
                <rds-icon icon="delete" namespace="outlined"></rds-icon>
            </button>
        </div>
    </div>
</div>