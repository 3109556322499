<h2 rds-dialog-header>{{ data.title }}</h2>
<div rds-dialog-content style="overflow-x: hidden">
    <ng-container [ngSwitch]="importStep$ | async">
        <ng-container *ngSwitchCase="stepStatus.IMPORT">
            <div
                rhDragDrop
                class="upload__placeholder"
                (fileDroppedEvent)="onFilesSelected($event)"
                (click)="importRef.click()"
            >
                <button rds-secondary-button style="border: none">
                    <rds-icon icon="upload" color="c-600-roche-blue" namespace="outlined"></rds-icon>
                    <strong>Upload </strong> &nbsp; <span class="text-low-contrast"> or drop file here </span>
                </button>

                <div style="visibility: hidden; width: 0">
                    <input
                        #importRef
                        type="file"
                        (change)="onFilesSelected($event)"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                </div>
            </div>
            <p class="ui-body-s text-low-contrast">Supports .xls, .csv</p>
        </ng-container>
        <ng-container *ngSwitchCase="stepStatus.MAPPING">
            <ng-container *ngIf="(invalidFile$ | async) === false">
                <div class="row">
                    <div class="col">
                        <p class="ui-body-m--l" style="display: flex; justify-content: space-between">
                            {{ data.confirmMappingInformation }}
                            <ng-container *ngIf="(importStep$ | async) !== stepStatus.IMPORT">
                                <rds-toggle [type]="'outlined'">
                                    <rds-toggle-button
                                        [value]="stepStatus.MAPPING"
                                        [checked]="(importStep$ | async) === stepStatus.MAPPING"
                                        (click)="changeView(stepStatus.MAPPING)"
                                    >
                                        Mapping
                                    </rds-toggle-button>
                                    <rds-toggle-button
                                        [value]="stepStatus.PREVIEW"
                                        [checked]="(importStep$ | async) === stepStatus.PREVIEW"
                                        (click)="changeView(stepStatus.PREVIEW)"
                                    >
                                        Preview
                                    </rds-toggle-button>
                                </rds-toggle>
                            </ng-container>
                        </p>
                    </div>
                </div>
                <div class="row mt-9 mb-3">
                    <div class="col-6 ui-body-l-bold">
                        Data Sample
                        <p>
                            <rds-switch (buttonChanged)="excludeFirstRow($event)" [checked]="excludedFirstRow$ | async">
                                Exclude first row
                            </rds-switch>
                        </p>
                    </div>
                    <div class="col-6 ui-body-l-bold">Field mapped to</div>
                </div>
                <rds-divider></rds-divider>
            </ng-container>
            <ng-container *ngIf="invalidFile$ | async">
                <div class="row">
                    <div class="col mt-6">
                        <rds-alert [style]="'error'"> Invalid data</rds-alert>
                    </div>
                </div>
            </ng-container>

            <div class="row" [formGroup]="mappingForm" *ngIf="importedData?.initialValues.length">
                <div
                    class="col-12 mt-6"
                    *ngFor="let col of importedData.groupedByCol | keyvalue"
                    [style.order]="columnWithEmails === col.key ? 0 : 1"
                >
                    <div class="row mb-6">
                        <div class="col-6">
                            <p
                                *ngFor="let val of col.value.values.slice(0, 3); let index = index"
                                class="ui-label-l text-high-contrast"
                            >
                                <span [class.line-through]="index === 0 && excludedFirstRow$ | async">{{ val }} </span>
                            </p>
                        </div>
                        <div class="col-4">
                            <rds-form-field>
                                <rds-control-label>Select a field</rds-control-label>
                                <rds-select [formControlName]="col.key">
                                    <rds-select-option *ngFor="let option of optionsNotSelected(col.key)"
                                                       [value]="option.value">{{
                                        option.name
                                        }}</rds-select-option>
                                </rds-select>
                            </rds-form-field>
                            <p style="display: flex; vertical-align: middle" class="mt-3">
                                <rds-icon
                                    namespace="outlined"
                                    icon="{{ col.value.valuesIcon }}"
                                    class="{{ col.value.valuesClass }}"
                                ></rds-icon>
                                &nbsp;<span> {{ col.value.percentValues | percent }} of rows have value. </span>
                            </p>

                            <p *ngIf="col.value.percentEmails" style="display: flex; vertical-align: middle"
                               class="mt-3">
                                <rds-icon
                                    namespace="outlined"
                                    icon="{{ col.value.emailsIcon }}"
                                    class="{{ col.value.emailsClass }}"
                                ></rds-icon>
                                &nbsp;<span> {{ col.value.percentEmails | percent }}
                                of data indicated as emails. </span>
                            </p>
                        </div>
                    </div>
                    <rds-divider></rds-divider>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="stepStatus.PREVIEW">
            <div class="row">
                <div class="col">
                    <p class="ui-body-m--l" style="display: flex; justify-content: space-between">
						<span>
							<rds-switch (buttonChanged)="excludeFirstRow($event)" [checked]="excludedFirstRow$ | async"
                            >Exclude first row</rds-switch
                            >
						</span>
                        <ng-container *ngIf="(importStep$ | async) !== stepStatus.IMPORT">
                            <rds-toggle [type]="'outlined'">
                                <rds-toggle-button
                                    [value]="stepStatus.MAPPING"
                                    [checked]="(importStep$ | async) === stepStatus.MAPPING"
                                    (click)="changeView(stepStatus.MAPPING)"
                                >
                                    Mapping
                                </rds-toggle-button>
                                <rds-toggle-button
                                    [value]="stepStatus.PREVIEW"
                                    [checked]="(importStep$ | async) === stepStatus.PREVIEW"
                                    (click)="changeView(stepStatus.PREVIEW)"
                                >
                                    Preview
                                </rds-toggle-button>
                            </rds-toggle>
                        </ng-container>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col mt-6">
                    <div *ngIf="mappedData$ | async as mappedData">
                        <rh-table-with-edit-performance
                            [columns]="['email', 'name', 'surname']"
                            [groupColumn]="''"
                            [rows]="mappedData$"
                            [editableColumns]="editableColumns"
                            readOnly="true"
                        >
                        </rh-table-with-edit-performance>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
<div rds-dialog-actions-container style="display: flex; justify-content: space-between">
    <div>
        <button rds-text-button *ngIf="(importStep$ | async) !== stepStatus.IMPORT"
                (click)="changeView(stepStatus.IMPORT)">
            <rds-icon icon="refresh" namespace="filled"></rds-icon>
            {{ data.changeFileLabel || 'Change file' }}
        </button>
    </div>
    <div style="display: flex; flex-direction: row; gap: 16px">
        <button rds-secondary-button [rds-dialog-close]="null"
                size="m">{{ data.cancelButtonLabel || 'Cancel' }}</button>
        <button
            *ngIf="(importStep$ | async) !== stepStatus.IMPORT"
            [disabled]="mappingForm.invalid"
            rds-primary-button
            [rds-dialog-close]="(mappedData$ | async)"
            size="m"
        >
            {{ data.confirmButtonLabel || 'Import' }}
        </button>
    </div>
</div>
