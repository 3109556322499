import {Action, createReducer, on} from '@ngrx/store';
import * as fromActions from '@app/home/store/event/event.actions';
import {RhEvent} from '@app/events/models/event';

export interface State {
  events: RhEvent[],
  processingIds: number[]
  loading: boolean
}

export const initialState: State = {
  events: [],
  processingIds: [],
  loading: true,
};

export const eventReducer = createReducer(
  initialState,
  on(fromActions.getEventsSuccess, (state, {events}) => ({
    ...state,
    loading: false,
    events
  })),
  on(fromActions.addToCalendarRequest, (state, {event}) => ({
    ...state,
    processingIds:    [...state.processingIds, event.id],
  })),
  on(fromActions.addToCalendarSuccess, (state, {event}) => ({
    ...state,
    processingIds:   [...state.processingIds].filter(ev => ev !== event.id)
  })),
  on(fromActions.addToCalendarFailure, (state, {eventId}) => ({
    ...state,
    processingIds:   [...state.processingIds].filter(ev => ev !== eventId)
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return eventReducer(state, action);
}

export const getEvents = (state: State) => state.events;
export const getLoading = (state: State) => state.loading;
export const getIsProcessingIds = (state: State) => state.processingIds;
