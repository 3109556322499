import { createSelector } from '@ngrx/store';

import * as fromReducer from '../store/layout.reducer';

export const selectNotificationBarOpened = createSelector(
	fromReducer.selectState,
	fromReducer.getNotificationBarOpened
);
export const selectTickerOpened = createSelector(fromReducer.selectState, fromReducer.getTickerOpened);

export const selectRatioWarningShowed = createSelector(fromReducer.selectState, fromReducer.getRatioWarningShowed);
