import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ChannelResult} from '@app/search/store/rh-search';

@Component({
  selector: 'rh-channel-card-item',
  templateUrl: './channel-card-item.component.html',
  styleUrls: ['./channel-card-item.component.scss']
})
export class ChannelCardItemComponent {
  public isInSubscriptions: boolean = false;
  @Input() result!: ChannelResult;
  @Output() toggleSubscription: EventEmitter<{ id: string, status: boolean }> = new EventEmitter();
  @Output() openChannel: EventEmitter<{ id: string }> = new EventEmitter();

  constructor() {
  }

  @ViewChild('titleLength') titleLength: ElementRef;

  open(ev: Event): void {
    this.openChannel.emit({id: this.result.id});
    ev.stopPropagation();
  }

  toggleSubscriptionClick(): void {
    this.result.hasSubscribed = !this.result.hasSubscribed;
    this.toggleSubscription.emit({id: this.result.id, status: this.result.hasSubscribed});
  }
}
