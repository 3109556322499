import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import * as fromReducer from '@app/newsletter/store/newsletter.reducer';
import * as fromActions from '@app/newsletter/store/newsletter-dashboard/newsletter-dashboard.actions';
import * as fromSelector from '@app/newsletter/store/newsletter-dashboard/newsletter-dashboard.selectors';
import * as fromSavedTemplates from '@app/newsletter/store/newsletter-saved-templates/newsletter-saved-templates.actions';
import * as fromWebSocket from '@app/newsletter/store/newsletter-websocket';
import * as fromRouter from '@app/root-store/router/router.actions';
import {NewsletterService} from '@core/services/newsletter.service';
import * as fromNewsletterFormSelectors from '@app/newsletter/store/newsletter-form/newsletter-form.selectors';
import * as fromNewsletterInlineSelectors from '@app/newsletter/store/newsletter-inline/newsletter-inline.selectors';
import * as fromNewsletterBlocksSelectors from '@app/newsletter/store/newsletter-blocks/newsletter-blocks.selectors';
import * as fromNewsletterFormActions from '@app/newsletter/store/newsletter-form/newsletter-form.actions';
import * as fromNewsletterInlineActions from '@app/newsletter/store/newsletter-inline/newsletter-inline.actions';
import {catchError, map, mergeMap, filter, switchMap, withLatestFrom, tap, skip, first} from 'rxjs/operators';
import { of} from 'rxjs';
import { RdsDialogService } from '@rds/angular-components';
import { NewsletterPreviewDialogComponent, NewsletterPreviewDialogData } from '@app/newsletter/dialogs/newsletter-preview-dialog/newsletter-preview-dialog.component';
import { RenameDialogComponent, RenameDialogData } from '@app/shared/dialogs/rename-dialog/rename-dialog.component';
import { EMPTY_NEWSLETTER_WITHOUT_SECTIONS, EMPTY_NEWSLETTER_WITH_SECTIONS, NewsletterValueModel } from '@app/newsletter/models';
import { ConfirmDialogComponent } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { SaveNewsletterPreviewDialogComponent } from '@app/newsletter/dialogs/save-newsletter-preview-dialog/save-newsletter-preview-dialog.component';

@Injectable()
export class NewsletterDashboardEffects {
  public getDraftToTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getDrafts),
      mergeMap(() =>
        this.newsletterService.getDraft().pipe(
          map((draft) => fromActions.getDraftSuccess({draft})),
          catchError(({message}) => of(fromActions.getDraftFailure({message}))
          )))
    ), {dispatch: true}
  );

  public getTemplatesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getTemplates),
      mergeMap(() => {

          return this.newsletterService.getTemplates().pipe(
            map((data) => {
              return fromActions.getTemplatesSuccess({templates: data});
            }),
            catchError(({message}) => of(fromActions.getTemplatesFailure({message}))
            ));
        }
      )
    ), {dispatch: true}
  );

  public chooseColor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.changeColor),
    ), {dispatch: false}
  );

  public chooseTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.changeTemplate),
    ), {dispatch: false}
  );

  public getNewslettersList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getNewsletters),
      withLatestFrom(this.store$.pipe(select(fromSelector.selectSelectedTab))),
      mergeMap(([action, index]) => {
        switch (index) {
          case 0:
            return [
              fromActions.getMyRecentDraft({}),
              fromActions.getMyRecentlyScheduled({}),
              fromActions.getMyRecentlySent({})
            ];
          case 1:
            return [
              fromActions.getSharedRecentDraft({}),
              fromActions.getSharedRecentlyScheduled({}),
              fromActions.getSharedRecentlySent({})
            ];
          default:
            return [];
        }
        }
      )
    ), {dispatch: true}
  );

  public getMyRecentDraft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getMyRecentDraft),
      withLatestFrom(
        this.store$.pipe(select(fromSelector.selectRecentDraftItemsLength))
      ),
      mergeMap(([{pageSize, pageIndex, isLoadMore}, itemsLength]) =>
        this.newsletterService.getMyAllDraft(pageSize ? pageSize : itemsLength > 3 ? itemsLength : pageSize, pageIndex).pipe(
          map((myRecentDraft) => fromActions.getMyRecentDraftSuccess({myRecentDraft, isLoadMore})),
          catchError(({message}) => of(fromActions.getNewslettersFailure({message}))
          )))
    ), {dispatch: true}
  );

  public getMyRecentlySent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getMyRecentlySent),
      withLatestFrom(
        this.store$.pipe(select(fromSelector.selectRecentlySentItemsLength))
      ),
      mergeMap(([{pageSize, pageIndex, isLoadMore}, itemsLength]) =>
        this.newsletterService.getMyAllSent(pageSize ? pageSize : itemsLength > 3 ? itemsLength : pageSize, pageIndex).pipe(
          map((myRecentlySent) => fromActions.getMyRecentlySentSuccess({myRecentlySent, isLoadMore})),
          catchError(({message}) => of(fromActions.getMyRecentDraftFailure({message}))
          )))
    ), {dispatch: true}
  );
  public getMyRecentlyScheduled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getMyRecentlyScheduled),
      withLatestFrom(
        this.store$.pipe(select(fromSelector.selectRecentlyScheduledItemsLength))
      ),
      mergeMap(([{pageSize, pageIndex, isLoadMore}, itemsLength]) =>
        this.newsletterService.getMyAllScheduled(pageSize ? pageSize : itemsLength > 3 ? itemsLength : pageSize, pageIndex).pipe(
          map((myRecentlyScheduled) => fromActions.getMyRecentlyScheduledSuccess({myRecentlyScheduled, isLoadMore})),
          catchError(({message}) => of(fromActions.getMyRecentlyScheduledFailure({message}))
          )))
    ), {dispatch: true}
  );

  public getSharedRecentDraft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getSharedRecentDraft),
      withLatestFrom(
        this.store$.pipe(select(fromSelector.selectSharedRecentDraftItemsLength))
      ),
      mergeMap(([{pageSize, pageIndex, isLoadMore}, itemsLength]) =>
        this.newsletterService.getSharedAllDraft(pageSize ? pageSize : itemsLength > 3 ? itemsLength : pageSize, pageIndex).pipe(
          map((sharedRecentDraft) => fromActions.getSharedRecentDraftSuccess({sharedRecentDraft, isLoadMore})),
          catchError(({message}) => of(fromActions.getSharedRecentDraftFailure({message}))
          )))
    ), {dispatch: true}
  );

  public getSharedRecentDraftSuccessConnectedWS$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getSharedRecentDraftSuccess),
      withLatestFrom(
        this.store$.pipe(select(fromWebSocket.selectIsWebSocketConnected)),
        this.store$.pipe(select(fromWebSocket.selectIds)),
        this.store$.pipe(select(fromSelector.selectSharedDraftIds)),
      ),
      filter(([action, isConnected, wsIds, draftIds]) => isConnected && !draftIds.every((id) => (wsIds as Array<number>).includes(id))),
      map(() => fromWebSocket.wsUpdateNewsletters())
    ), {dispatch: true}
  );

  public getSharedRecentDraftSuccessDisconnectedWS$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.getSharedRecentDraftSuccess),
    withLatestFrom(
      this.store$.pipe(select(fromWebSocket.selectIsWebSocketConnected)),
    ),
    filter(([action, isConnected]) => !isConnected),
    map(() => fromWebSocket.wsConnect())
  ), {dispatch: true}
);

  public getSharedRecentlySent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getSharedRecentlySent),
      withLatestFrom(
        this.store$.pipe(select(fromSelector.selectSharedRecentlySentItemsLength))
      ),
      mergeMap(([{pageSize, pageIndex, isLoadMore}, itemsLength]) =>
        this.newsletterService.getSharedAllSent(pageSize ? pageSize : itemsLength > 3 ? itemsLength : pageSize, pageIndex).pipe(
          map((sharedRecentlySent) => fromActions.getSharedRecentlySentSuccess({sharedRecentlySent, isLoadMore})),
          catchError(({message}) => of(fromActions.getSharedRecentDraftFailure({message}))
          )))
    ), {dispatch: true}
  );

  public getSharedRecentlySentSuccessConnectedWS$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getSharedRecentlySentSuccess),
      withLatestFrom(
        this.store$.pipe(select(fromWebSocket.selectIsWebSocketConnected)),
        this.store$.pipe(select(fromWebSocket.selectIds)),
        this.store$.pipe(select(fromSelector.selectSharedSentIds)),
      ),
      filter(([action, isConnected, wsIds, sentIds]) => isConnected && !sentIds.every((id) => (wsIds as Array<number>).includes(id))),
      map(() => fromWebSocket.wsUpdateNewsletters())
    ), {dispatch: true}
  );

  public getSharedRecentlyScheduled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getSharedRecentlyScheduled),
      withLatestFrom(
        this.store$.pipe(select(fromSelector.selectSharedRecentlyScheduledItemsLength))
      ),
      mergeMap(([{pageSize, pageIndex, isLoadMore}, itemsLength]) =>
        this.newsletterService.getSharedAllScheduled(pageSize ? pageSize : itemsLength > 3 ? itemsLength : pageSize, pageIndex).pipe(
          map((sharedRecentlyScheduled) => fromActions.getSharedRecentlyScheduledSuccess({sharedRecentlyScheduled, isLoadMore})),
          catchError(({message}) => of(fromActions.getSharedRecentlyScheduledFailure({message}))
          )))
    ), {dispatch: true}
  );

  public getSharedRecentlyScheduledSuccessConnectedWS$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getSharedRecentlyScheduledSuccess),
      withLatestFrom(
        this.store$.pipe(select(fromWebSocket.selectIsWebSocketConnected)),
        this.store$.pipe(select(fromWebSocket.selectIds)),
        this.store$.pipe(select(fromSelector.selectSharedScheduledIds)),
      ),
      filter(([action, isConnected, wsIds, scheduledIds]) => isConnected && !scheduledIds.every((id) => (wsIds as Array<number>).includes(id))),
      map(() => fromWebSocket.wsUpdateNewsletters())
    ), {dispatch: true}
  );
  
  public openChangeTemplateDialog$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.openChangeTemplateDialog),
    withLatestFrom(this.store$.pipe(select(fromSelector.selectSelectedColor))),
    map(([{templateId}, color]) => {
      const data: NewsletterPreviewDialogData = {
        formNewsletter: this.store$.pipe(
          select(fromNewsletterFormSelectors.selectFormNewsletterLoaded)
        ),
        templates: this.store$.pipe(select(fromSelector.selectTemplatesAll),map(data => data.filter(t => t.templateId !== 'NewsletterTemplateInlineEditor'))),
        selectedTemplate: this.store$.pipe(select(fromSelector.selectSelectedTemplate)),
        templateId,
        context: 'ChangeTemplate',
      }

      const dialog = this.dialogService.open(NewsletterPreviewDialogComponent, {
        size: 'xl',
        height: '100%',
        maxHeight: '100%',
        data
      })

      return ({templateId, color,  dialog})
    } ),
    switchMap(({ templateId, color, dialog }) => dialog.afterClosed().pipe(
      filter(data => !!data),
      mergeMap((selectedTemplate) => [
        fromActions.changeTemplateAndColor({templateAndColor: {
          templateId: selectedTemplate.templateId,
          color: color.label
        }}),
        fromNewsletterFormActions.changeTemplate({templateId: selectedTemplate.templateId})
      ])
    )),
  ), { dispatch: true}
);
  
public openPreviewPredefinedTemplateDialog$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.openPreviewPredefinedTemplateDialog),
    tap(({ templateId}) => {
      let predefinedNewsletter: Partial<NewsletterValueModel>;
      switch (templateId) {
        case 'NewsletterTemplateWithSections':
          predefinedNewsletter = EMPTY_NEWSLETTER_WITH_SECTIONS;
          break;
        case 'NewsletterTemplateWithoutSections':
          predefinedNewsletter = EMPTY_NEWSLETTER_WITHOUT_SECTIONS;
          break;
      }
      const data: NewsletterPreviewDialogData = {
        predefinedNewsletter: {
          ...predefinedNewsletter,
          color: 'blue'
        },
        templateId,
        context: 'PredefinedTemplate',
      }

      this.dialogService.open(NewsletterPreviewDialogComponent, {
        size: 'xl',
        height: '100%',
        maxHeight: '100%',
        data
      }
      )
    } )
  ), { dispatch: false}
);

public openNewsPreview$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.openPreviewNewsletterDialog),
  tap(({card}) => {
    switch (card.templateId) {
      case 'NewsletterTemplateInlineEditor':
        this.store$.dispatch(fromNewsletterInlineActions.getNewsletterById({id: card.id}));
        break;
      case 'NewsletterTemplateWithSections':
      case 'NewsletterTemplateWithoutSections':
        this.store$.dispatch(fromNewsletterFormActions.getNewsletterById({id: card.id}));
        break;
    }
    const data: NewsletterPreviewDialogData = {
      formNewsletter: this.store$.pipe(
        select(fromNewsletterFormSelectors.selectFormNewsletterLoaded),
        skip(1),
        first()
      ),
      sections: this.store$.pipe(select(fromNewsletterBlocksSelectors.selectSections)),
      form: this.store$.pipe(select(fromNewsletterInlineSelectors.selectForm)),
      templateId: card.templateId,
      newsletterId: card.id,
      context: 'Dashboard',
      buttons: {
        editNewsletter: {
          visible: true,
          disabled: false
        }
      }
    }

    this.dialogService.open(NewsletterPreviewDialogComponent, {
      size: 'xl',
      height: '100%',
      maxHeight: '100%',
      data
    }
    )
  } )
), { dispatch: false}
);


  public openRenameDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openRenameDialog),
      map(({ id, name }) => {
        const data: RenameDialogData = {
          title: 'Rename Newsletter',
          oldName: name,
          controlLabel: 'Name of the Newsletter',
          confirmButtonLabel: 'Rename Newsletter',
          required: true,
          maxLength: 512,
        }
        const dialog = this.dialogService.open(RenameDialogComponent, {
          size: 'l',
          data
        });
        return ({ id, name, dialog })
      }),
      switchMap(({ id, name, dialog }) => dialog.afterClosed().pipe(
        filter(data => !!data),
        map((name) => ({ id, name }))
      )),
      switchMap(({id, name}) =>
        this.newsletterService.rename(id, name).pipe(
          map((newsletter) => fromActions.renameNewsletterSuccess({newsletter})),
          catchError(({message}) => of(fromActions.renameNewsletterFailure({message}))
          )
        )
      )
    ), { dispatch: true }
  );

  
  public openReuseNewsletterDialog$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.openReuseNewsletterDialog),
    map(({card}) => {
      switch (card.templateId) {
        case 'NewsletterTemplateInlineEditor':
          this.store$.dispatch(fromNewsletterInlineActions.getNewsletterById({id: card.id}));
          break;
        case 'NewsletterTemplateWithSections':
        case 'NewsletterTemplateWithoutSections':
          this.store$.dispatch(fromNewsletterFormActions.getNewsletterById({id: card.id}));
          break;
      }
      const data: NewsletterPreviewDialogData = {
        formNewsletter: this.store$.pipe(
          select(fromNewsletterFormSelectors.selectFormNewsletterLoaded),
          skip(1),
          first()
        ),
        sections: this.store$.pipe(select(fromNewsletterBlocksSelectors.selectSections)),
        form: this.store$.pipe(select(fromNewsletterInlineSelectors.selectForm)),
        templateId: card.templateId,
        newsletterId: card.id,
        context: 'ReuseNewsletter',
      }
  
      const dialog = this.dialogService.open(NewsletterPreviewDialogComponent, {
        size: 'xl',
        height: '100%',
        maxHeight: '100%',
        data
      }
      )

      return ({dialog, id: card.id})
    } ),
    switchMap(({ id, dialog, }) => dialog.afterClosed().pipe(
      filter(data => data),
      map(({data}) => fromRouter.go({
        path: 'newsletter/form/' + id + '/reuse',
        queryParams: {}
      }))
    )),
    
  ), { dispatch: true}
  );

  public editNewsletter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.editNewsletter),
      map(({id}) => fromRouter.go({
        path: 'newsletter/form/' + id + '/edit',
        queryParams: {}
      }))
    )
  );
  
  public openDeleteNewsletterDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openDeleteNewsletterDialog),
      switchMap(({ card }) => this.dialogService.open(ConfirmDialogComponent, {
        data: {
          ids: [card.id],
          title: `Are you sure you want to delete "${card.displayName || card.title}" newsletter draft?`,
          confirmButtonLabel: `Yes, delete`,
          confirmButtonType: 'warning'
        }
      }).afterClosed().pipe(
        filter((data) => !!data),
        map((data) => fromActions.deleteNewsletterRequest({id: card.id}))
      ))
    ),
    { dispatch: true }
  );

  public deleteNewsletterRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteNewsletterRequest),
      switchMap(({id}) =>
        this.newsletterService.deleteNewsletter(id).pipe(
          mergeMap(() => [
            fromActions.deleteNewsletterSuccess({id}),
            fromWebSocket.wsNewsletterDeleted({newsletterId: id})
          ]),
          catchError(({message}) => of(fromActions.deleteNewsletterFailure({message}))
          )
        )
      )
    )
  );

    
  public openCancelNewsletterDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openCancelNewsletterDialog),
      switchMap(({ card }) => this.dialogService.open(ConfirmDialogComponent, {
        data: {
          ids: [card.id],
          title: `Are you sure you want to move "${card.displayName || card.title}" to draft?`,
          confirmButtonLabel: `Yes, move`,
          confirmButtonType: 'primary'
        }
      }).afterClosed().pipe(
        filter((data) => !!data),
        map((data) => fromActions.cancelNewsletterRequest({id: card.id}))
      ))
    ),
    { dispatch: true }
  );

  public cancelNewsletterRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.cancelNewsletterRequest),
      switchMap(({id}) =>
        this.newsletterService.cancelNewsletter(id).pipe(
          mergeMap(() => [
            fromActions.cancelNewsletterSuccess({id}),
            fromWebSocket.wsMovedToDraft({newsletterId: id})
          ]),
          catchError(({message}) => of(fromActions.cancelNewsletterFailure({message}))
          )
        )
      )
    )
  );

  public openSaveAsTemplateDialog$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.openSaveAsTemplateDialog),
    map(({template}) => {
      switch (template.templateId) {
        case 'NewsletterTemplateInlineEditor':
          this.store$.dispatch(fromNewsletterInlineActions.getNewsletterById({id: template.newsletterId}));
          break;
        case 'NewsletterTemplateWithSections':
        case 'NewsletterTemplateWithoutSections':
          this.store$.dispatch(fromNewsletterFormActions.getNewsletterById({id: template.newsletterId}));
          break;
      }
      const data: NewsletterPreviewDialogData = {
        formNewsletter: this.store$.pipe(
          select(fromNewsletterFormSelectors.selectFormNewsletterLoaded),
          skip(1),
          first()
        ),
        sections: this.store$.pipe(select(fromNewsletterBlocksSelectors.selectSections)),
        form: this.store$.pipe(select(fromNewsletterInlineSelectors.selectForm)),
        templateId: template.templateId,
        newsletterId: template.newsletterId,
        context: 'SaveAsTemplate',
      }
  
      const dialog = this.dialogService.open(NewsletterPreviewDialogComponent, {
        size: 'xl',
        height: '100%',
        maxHeight: '100%',
        data
      }
      )

      return ({
        dialog,
        data: {
          templateId: data.templateId,
          newsletterId: data.newsletterId,
          formNewsletter: data.formNewsletter,
          sections: data.sections,
          form: data.form,
        },
        template
      })
    } ),
    switchMap(({ data, dialog, template}) => dialog.afterClosed().pipe(
      filter(confirmed => confirmed),
      map(({}) => {
        if (template.imagePreviewUrl) {
          return fromActions.saveAsTemplateRequest({template})
        } else {
          return fromActions.startSaveAsTemplateProccess({data, template})
        }
      })
    )),
  ), { dispatch: true}
  );

  public startSaveAsTemplateProccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.startSaveAsTemplateProccess),
    map(({data, template}) => {
      const dialog = this.dialogService.open(SaveNewsletterPreviewDialogComponent, {
        data,
        size: 'm',
        disableClose: true,
        closeOnNavigation: false,
      }
      )

      return ({dialog, template})
    } ),
    switchMap(({ dialog, template}) => dialog.afterClosed().pipe(
      filter(data => !!data),
      map(({url, error}) => {
        if (!!url) {
          return fromActions.saveAsTemplateRequest({template: {...template, imagePreviewUrl: url}})
        } else {
          fromActions.saveAsTemplateFailure({message: error})
        }
      })
    )),
  ), { dispatch: true}
  );

  public saveAsTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveAsTemplateRequest),
      switchMap(({template}) =>
        this.newsletterService.saveAsTemplate(template).pipe(
          map(() => fromActions.saveAsTemplateSuccess()),
          catchError(({message}) => of(fromActions.saveAsTemplateFailure({message}))
          )
        )
      )
    )
  );

  public refreshLists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.cancelNewsletterSuccess,
        fromActions.deleteNewsletterSuccess),
      map(() => fromActions.getNewsletters())
    )
  );

  public refreshSaveAsTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveAsTemplateSuccess),
      map(() => fromSavedTemplates.getDashboardTemplates({pageIndex: 0, pageSize: 4}))
    )
  );

  public setContributorsSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.setContributorsSuccess),
    filter(({newsletter}) => newsletter.newsletterContributors.length < 2),
    map(({newsletter}) => fromWebSocket.wsUnshare({newsletterId: newsletter.id}))
  )
);

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private newsletterService: NewsletterService,
    private dialogService: RdsDialogService
  ) {
  }
}
