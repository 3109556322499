import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {TranslationModule} from './translation/translation.module';
import {ServicesModule} from './services/services.module';
import {AuthModule} from '@core/auth/auth.module';
import {CoreStoreModule} from '@core/core-store/core-store.module';
import {UserModule} from '@core/user/user.module';
import {LayoutModule} from '@app/layout/layout.module';
import {EmailModule} from './email/email.module';
import {UserSettingsModule} from '@app/user-settings/user-settings.module';

@NgModule({
  declarations: [],
  imports: [
    TranslationModule.forRoot(),
    ServicesModule.forRoot(),
    UserModule.forRoot(),
    EmailModule.forRoot(),
    AuthModule.forRoot(),
    LayoutModule.forRoot(),
    UserSettingsModule.forRoot(),
    CoreStoreModule.forRoot() // CORE MUST BE REGISTERED BEFORE AUTH!!!
  ],
  exports: [],
  providers: []
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
      parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: []
    };
  }
}
