import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { RDS_DIALOG_DATA, RdsDialogRef, RdsToastService } from '@rds/angular-components';
import * as htmlToImage from 'html-to-image';
import { FormControl, FormGroup } from '@angular/forms';
import { NEWS_PLACEHOLDER_DATA } from '@src/assets/images/news-placeholder-base64';
import { UploadService } from '@app/core/services/newsboard/upload.service';
import { Template, mapTemplateToNews } from '@app/core/models/newsboard/template';

const PHRASE_TO_CHECK: string = "I ACKNOWLEDGE TEMPLATE CHANGE FOR ALL USERS"
export interface SaveTemplateDialogData {
  template: Template
}

@Component({
  selector: 'rh-save-template-dialog',
  templateUrl: './save-template-dialog.component.html',
  styleUrls: ['./save-template-dialog.component.scss']
})
export class SaveTemplateDialogComponent implements OnDestroy {
  @ViewChild('image') image: ElementRef;
  @ViewChild('imagePlaceholder') imagePlaceholder: ElementRef;
  @ViewChild('videoPlaceholder') videoPlaceholder: ElementRef;
  news
  imageData
  isGenerating: boolean;

  phrase = PHRASE_TO_CHECK;
  get splitPhrase() {
    return this.phrase.split('');
  }
  form = new FormGroup({
    phrase: new FormControl({value: '', disabled: true}),
  });
  sec = 5;

  isChecking = false;
  checkArray = this.resetCheckArray();
  get checkedCount() {
    return this.checkArray.filter(c => c !== 'NOT_CHECKED').length
  }

  get isChecked() {
    return this.checkArray.every(c => c === 'PASSED')
  }
  constructor(@Inject(RDS_DIALOG_DATA) public data: SaveTemplateDialogData, private cdr: ChangeDetectorRef, private toast: RdsToastService, public rdsDialogRef: RdsDialogRef<SaveTemplateDialogComponent>, private uploadService: UploadService, private ref: ElementRef) {
    if (!!data) {
    this.news = mapTemplateToNews(data.template);
    }
    const countdownInterval = setInterval(() => {
      this.sec--;
      this.cdr.markForCheck();
      if (this.sec === 0) {
        this.form.controls.phrase.enable();
        clearInterval(countdownInterval);
      }
    }, 1000)
  }

  ngOnDestroy() {
    clearTimeout(this.timeout)
  }

  resetCheckArray() {
    return Array(this.phrase.length).fill('NOT_CHECKED');
  }
  createPreview() {
    this.isGenerating = true;
    htmlToImage.toPng(
      this.image.nativeElement,
      {
        quality: 1,
        width: this.image.nativeElement.offsetWidth,
        height: this.image.nativeElement.offsetHeight,
        canvasWidth: this.image.nativeElement.offsetWidth,
        canvasHeight: this.image.nativeElement.offsetHeight,
        imagePlaceholder: NEWS_PLACEHOLDER_DATA,
      }
    ).then(async (dataUrl) => {
      const blob = this.dataURItoBlob(dataUrl);
      const file = new File([blob], "file_name");
      this.uploadService.uploadFile(file).subscribe(res => {
        this.rdsDialogRef.close({url: res.url, error: null});
      });
    }).catch(err => {
        this.rdsDialogRef.close({url: null, error: true});
    });
  }

  dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  pasteProhibited(action,event) {
    event.preventDefault();
    this.toast.error(
      `${action} action is prohibited for this input`
    )
  }

  timeout
  check(index, phraseToCheck) {
    clearTimeout(this.timeout);
    if (index === 0) {
      this.checkArray = this.resetCheckArray();
      this.cdr.markForCheck();
    }
    if (index < this.phrase.length) {
      this.isChecking = true;
      const timeOut = Math.random() * 200;
      this.timeout = setTimeout(() => {
        if (this.phrase[index] === phraseToCheck[index]) {
          this.checkArray[index] = 'PASSED';
        } else {
          this.checkArray[index] = 'FAILED';
        }
        this.cdr.markForCheck();
        this.check(index+1, phraseToCheck);
      }, timeOut)
    } else {
    }
  }

}
