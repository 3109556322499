<ng-container [ngSwitch]="status">
  <rds-badge
    *ngSwitchCase="FormStepStatus.NOT_DEFINED"
    label="Not defined"
    size="m"
    color="neutral"
  ></rds-badge>
  <rds-badge
    *ngSwitchCase="FormStepStatus.IN_PROGRESS"
    label="In progress"
    type="minimal"
    size="m"
    color="blue"
  ></rds-badge>
  <rds-badge
    *ngSwitchCase="FormStepStatus.INCOMPLETE"
    label="Incomplete"
    type="minimal"
    size="m"
    color="red"
  ></rds-badge>
  <rds-badge
    *ngSwitchCase="FormStepStatus.COMPLETED"
    label="Completed"
    type="minimal"
    size="m"
    color="green"
  ></rds-badge>
  <rds-badge
    *ngSwitchCase="FormStepStatus.PREDEFINED"
    label="Predefined"
    type="minimal"
    size="m"
    color="orange"
  ></rds-badge>
</ng-container>