import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'atr-user-not-created-dialog',
  templateUrl: './user-not-created-dialog.container.html',
  styleUrls: ['./user-not-created-dialog.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNotCreatedDialogContainer {

  constructor(
  ) {}

}
