<h2 rds-dialog-header>
  <ng-container [ngSwitch]="data.mode">
    <ng-container *ngSwitchCase="'decision'">Would you like to remap Newsletter?</ng-container>
    <ng-container *ngSwitchCase="'progress'">Remapping Newsletter</ng-container>
    <ng-container *ngSwitchCase="'result'">Remapping Newsletter summary</ng-container>
  </ng-container>
</h2>
<div *ngIf="data.mode !== 'progress'" rds-dialog-icon-placeholder>
  <button rds-icon-button size="m" (click)="close('cancel')">
    <rds-icon icon="close" namespace="outlined"></rds-icon>
  </button>
</div>
<div rds-dialog-content>
  <ng-container *ngIf="data.mode === 'decision'">
    <p class="ui-label-m">
      You are trying to open Newsletter created with Legacy editor, which is no longer supported.
      We can try to remap it to the new editor, but some of the content may be lost.
      We recommend to create a new Newsletter using the new editor.
    </p>
  </ng-container>
  <ng-container *ngIf="data.mode === 'progress'">
    <rds-progress-bar></rds-progress-bar>
  </ng-container>
  <ng-container *ngIf="data.mode === 'result'">
    <ng-container *ngIf="noErrors">
      <p class="ui-label-m mb-5">
        Remapping has been completed successfully. We have not encountered any problems during the process, but we
        recommend checking the form before sending it.
      </p>
      <rds-alert>If you leave the form without <strong>sending the Newsletter</strong> or <strong>saving it as a
          draft</strong>, the changes related to remapping will be <strong>lost</strong></rds-alert>
    </ng-container>
    <ng-container *ngIf="!noErrors">
      <p class="ui-label-m mb-5">
        Remapping has been completed with problems. Fix all issues pointed as error to be able to finish.
      </p>
      <rds-accordion>
        <rds-expansion-panel [expanded]="true" togglePosition="after">
          <rds-expansion-panel-header>
            <rds-panel-title>
              Header & Footer
            </rds-panel-title>
            <ng-container *ngIf="!isCurrentRatioMatching">
              <rds-badge size="m" label="1 error" color="error"></rds-badge>
            </ng-container>
          </rds-expansion-panel-header>
          <div>
            <div class="mb-5">
            <ng-container *ngIf="!isCurrentRatioMatching">
              <rds-alert *ngIf="currentRatio === 0" class="mb-5" [style]="'error'">Newsletter image link is broken.</rds-alert>
              <rds-alert *ngIf="currentRatio > 0" class="mb-5" [style]="'error'">Newsletter image has incorrect ratio. Cropp it to be able to finish.</rds-alert>
            </ng-container>
            <rds-alert *ngIf="isCurrentRatioMatching" class="mb-5" [style]="'success'">Newsletter image meet all of the requirements.</rds-alert>
            </div>
            <div class="row">
              <div class="col-8">
                <div style="height: 200px;">
                  <rh-cropp 
                    #croppCmp
                    [aspectRatio]="data.results.image.correctRatio"
                    [buttonLabel]="'Upload another image'"
                    (cropped)="uploadCmp.onFileSelected([$event], true)"
                    (ready)="isCropReady = $event"
                    (notFound)="form.controls.image.setValue(null)"
                    (ratioChanged)="checkRatio($event)"
                  ></rh-cropp>
                </div>
              </div>
              <div class="col-4" [formGroup]="form">
                <button
                  class="mb-4"
                  rds-primary-button
                  size="m"
                  [disabled]="!isCropReady"
                  (click)="croppCmp.cropp()">Crop and save image</button>
                <rh-upload 
                  #uploadCmp
                  [hasDropZone]="false"
                  [allowRemove]="false"
                  [forceSkipCrop]="true"
                  buttonLabel="Upload another image"
                  imageStorage="newsletter"
                  size="l"
                  formControlName="image">
                </rh-upload>
              </div>
            </div>
          </div>
        </rds-expansion-panel>
        <rds-expansion-panel *ngIf="data.results.news.missing.length > 0" [active]="true" togglePosition="after">
          <rds-expansion-panel-header>
            <rds-panel-title>
              Body
            </rds-panel-title>
            <rds-badge size="m" [label]="data.results.news.missing.length + ' warning' + (data.results.news.missing.length > 1 ? 's' : '')" color="warning"></rds-badge>
          </rds-expansion-panel-header>
          <div>
            <div *ngFor="let news of data.results.news.missing"  class="mb-4">
              <rds-alert class="mb-5" [style]="'warning'">News with title <strong>{{news.title}}</strong> no longer exists in RocheHome and will be removed from Newsletter.</rds-alert>
            </div>
          </div>
        </rds-expansion-panel>
        <rds-expansion-panel *ngIf="data.results.radaGroups.missing.length > 0" [active]="true" togglePosition="after">
          <rds-expansion-panel-header>
            <rds-panel-title>
              Recipients
            </rds-panel-title>
            <rds-badge size="m" [label]="data.results.radaGroups.missing.length + ' warning' + (data.results.radaGroups.missing.length > 1 ? 's' : '')" color="warning"></rds-badge>
          </rds-expansion-panel-header>
          <div>
            <div *ngFor="let rada of data.results.radaGroups.missing" class="mb-4">
              <rds-alert class="mb-5" [style]="'warning'"><strong>{{rada}}</strong> RADA Group was not found and will be removed from recipients list.</rds-alert>
            </div>
          </div>
        </rds-expansion-panel>
      </rds-accordion>
    </ng-container>
  </ng-container>
</div>
<div *ngIf="data.mode === 'decision'" rds-dialog-actions-container align="end">
  <button rds-secondary-button (click)="close('cancel')" size="m">
    Cancel
  </button>
  <button rds-primary-button size="m" (click)="close('start')">
    Start remapping
  </button>
</div>
<div *ngIf="data.mode === 'result'" rds-dialog-actions-container align="end">
  <button *ngIf="noErrors" rds-secondary-button (click)="close('cancel')" size="m">
    Cancel
  </button>
  <button rds-primary-button size="m" [disabled]="!isCurrentRatioMatching" (click)="close('finish')">
    Finish
  </button>
</div>