<div class="buttons-container">
    <div class="row-default px-1 my-3">


        <button
                rds-secondary-button
                (click)="clearRange()"
        >Clear
        </button>


        <button
                rds-primary-button
                rdsDatepickerApply
        >OK
        </button>
    </div>

    <div class="row my-3">
        <p class="ui-label-l-bold">Preset date ranges:</p>
    </div>
    <div class="row my-3">
        <div class="row__button">
            <button
                    rds-primary-button
                    (click)="setDateRange({type: 'subtract', value: 29, key: 'days'})">Last 30 days
            </button>
        </div>
        <div class="row__button">
            <button
                    rds-primary-button
                    (click)="setDateRange({type: 'add', value: 29, key: 'days'})">Next 30 days
            </button>
        </div>
    </div>
    <div class="row my-3">
        <div class="row__button">
            <button
                    rds-primary-button
                    (click)="setDateRange({type: 'subtract', value: 6, key: 'months'})">Last 6
                mths
            </button>
        </div>
        <div class="row__button">
            <button
                    rds-primary-button
                    (click)="setDateRange({type: 'add', value: 6, key: 'months'})">
                Next 6 mths
            </button>
        </div>
    </div>
    <div class="my-3 row">
        <div class="row__button">
            <button
                    rds-primary-button
                    (click)="setDateRange({type: 'subtract', value: 12, key: 'months'})">Last 12
                mths
            </button>
        </div>
        <div class="row__button">
            <button
                    rds-primary-button
                    (click)="setDateRange({type: 'add', value: 12, key: 'months'})">Next 12 mths
            </button>
        </div>
    </div>
</div>