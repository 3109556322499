<div *ngIf="selectedOptions?.length > 0" class="select-container py-6" [class.readonly]="disabled">
    <div *ngFor="let selected of selectedOptions" class="row row-centered row-nowrap">
        <div class="col-auto" style="display: flex;gap: 24px; align-items: center;">
            <img style="height: 36px; aspect-ratio: 16/9" [src]="selected.imageUrl || '/assets/images/news-placeholder.png'" alt="">
            <div style="display: flex; flex-direction: column; min-width: 0;">
                <div style="display: flex; min-width: 0; gap: 8px;">
                <rds-badge size="s" [label]="selected.date | date:'dd LLL yyyy' | uppercase" type="minimal" color="purple"></rds-badge>
                <p class="ui-label-m-bold text-ellipsis">
                    {{selected.title}}
                </p>
                </div>
                <p class="ui-label-s text-ellipsis">
                {{selected.description}}
                </p>
            </div>
        </div>
        <div *ngIf="!disabled && !checkRemovePrevented(selected)" class="col-auto offset-auto">
            <button rds-icon-button size="m"  (click)="removed.emit(i)">
                <rds-icon icon="delete" namespace="outlined"></rds-icon>
            </button>
        </div>
    </div>
</div>