import { Injectable } from '@angular/core';
import { ChannelSetService } from '@app/core/services/channel-set.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import * as fromActions from '@app/manage-sets/store/channels-sets-form/channels-sets-form.actions';
import * as fromReducer from '@app/manage-sets/store/channels-sets-form/channels-sets-form.reducer';
import * as fromSelectors from '@app/manage-sets/store/channels-sets-form/channels-sets-form.selectors';
import * as fromBackButton from '@app/root-store/ui/back-button';
import * as fromUser from '@app/core/user/store';

import { ContentService } from '@app/core/services/content.service';
import { RdsDialogService } from '@rds/angular-components';
import { IntranetCoordinatorPreviewDialogComponent } from '@app/shared/dialogs/intranet-coordinator-preview-dialog/intranet-coordinator-preview-dialog.component';
import { ChannelsSetFormChannelFilters } from '@app/core/models';

@Injectable()
export class ChannelsSetsFormEffects {

public saveSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(
      fromActions.createChannelsSetSuccess,
      fromActions.updateChannelsSetSuccess,
      fromActions.deleteChannelsSetSuccess
    ),
    mergeMap(() => [
      fromActions.clearAll(),
      fromBackButton.back({defaultLabel: 'Manage sets', defaultRoute: 'manage-sets/channels'})
    ]),
  ), { dispatch: true}
);

public createChannelsSetRequest$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.createChannelsSetRequest),
  withLatestFrom(this.store$.pipe(select(fromSelectors.selectFormForRequest))),
  switchMap(([action, form]) => this.channelsSetService.postChannelsSet(form).pipe(
    map((id) => ({form, id}))
  )),
  mergeMap(({form, id}) => [
    fromActions.createChannelsSetSuccess(),
  ])
), { dispatch: true}
);

public updateChannelsSetRequest$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.updateChannelsSetRequest),
  withLatestFrom(this.store$.pipe(select(fromSelectors.selectFormForRequest))),
  switchMap(([action, form]) => this.channelsSetService.putChannelsSet(form).pipe(
    map((id) => ({form, id}))
  )),
  mergeMap(({form, id}) => [
    fromActions.updateChannelsSetSuccess(),
  ])
), { dispatch: true}
);

public deleteChannelsSetRequest$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.deleteChannelsSetRequest),
  mergeMap(({id}) =>
    this.channelsSetService.deleteChannelsSet(id).pipe(
      mergeMap(() => [
        fromActions.deleteChannelsSetSuccess(),
      ]),
      catchError(({ message }) => of(fromActions.deleteChannelsSetFailure({error: message })))
    )
  )
), { dispatch: true }
);

public checkNameUnique$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.setFormValue),
    debounceTime(300),
    filter(({form}) => !!form.name),
    distinctUntilChanged((prev, next) => prev.form.name === next.form.name),
    map(({form}) => fromActions.isNameUniqueRequest({name: form.name, id: form.id}))
  ))

  public isNameUniqueRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.isNameUniqueRequest),
    withLatestFrom(this.store$.pipe(select(fromSelectors.selectInitialForm))),
    switchMap(([{name}, form]) => this.channelsSetService.getIsNameUnique(name).pipe(
      map((isUnique) => ({isUnique: isUnique || form.name === name}))
    )),
    map(({isUnique}) => fromActions.isNameUniqueSuccess({isUnique}))
  ), { dispatch: true}
  );
  

  public initChannelsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initChannelsFilters),
      switchMap(() => this.store$.pipe(
          select(fromSelectors.selectChannelsFiltersForRequest),
          debounceTime(50),
          distinctUntilChanged((prev, next) => {
            return JSON.stringify(prev) === JSON.stringify(next)
          }),
          filter(({
            name,
            departments,
            functions,
            locations}: ChannelsSetFormChannelFilters) => (name.length + locations.length + departments.length + functions.length) >  0),
          map((filters) => fromActions.getFilteredChannelsRequest({filters}))
        )
      ),
    ), { dispatch: true }
  );

  public clearChannelsFilters$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.initChannelsFilters),
    switchMap(() => this.store$.pipe(
        select(fromSelectors.selectChannelsFiltersForRequest),
        debounceTime(50),
        distinctUntilChanged((prev, next) => {
          return JSON.stringify(prev) === JSON.stringify(next)
        }),
        filter(({
          name,
          departments,
          functions,
          locations}: ChannelsSetFormChannelFilters) => (name.length + locations.length + departments.length + functions.length) ===  0),
        map(() => fromActions.clearFilteredChannels())
      )
    ),
  ), { dispatch: true }
);

  
  public getFilteredChannelsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getFilteredChannelsRequest),
      switchMap(({filters}) =>
        this.contentService.putFilterAllChannels(filters).pipe(
          map((channels) => fromActions.getFilteredChannelsSuccess({ channels })),
          catchError(({ message }) => of(fromActions.getFilteredChannelsFailure({ error: message })))
        )
      )

    ), { dispatch: true}
  );

  public openIntranetCoordinatorsDetailsDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openIntranetCoordinatorsDetailsDialog),
      withLatestFrom(
        this.store$.pipe(select(fromUser.selectUserProfile)),
        this.store$.pipe(select(fromSelectors.selectAudienceManagerInfo))
      ),
      tap(([action, user, audienceManagerInfo]) => this.dialogService.open(IntranetCoordinatorPreviewDialogComponent, {
        data: {
          user: {
            login: user.login,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            photoUrl: user.photoUrl
          },
          managedTaxonomies: {
            locations: audienceManagerInfo.managedTaxonomy.locations,
            departments: audienceManagerInfo.managedTaxonomy.departments,
            functions: audienceManagerInfo.managedTaxonomy.functions,
          }
        },
        size: 'l'
      }))
    ), { dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private contentService: ContentService,
    private channelsSetService: ChannelSetService,
    private dialogService: RdsDialogService
  ) {}
}
