import {Channel, Pagination} from '@app/core/models';
import {LoadingState} from '@app/search/models/rh';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import * as fromActions from './rh-search.actions';
import {RhEvent} from '@app/events/models/event';

export const feature = 'rhSearch';

export const allAdapter: EntityAdapter<AllResult> = createEntityAdapter<AllResult>({
  selectId: (item: AllResult) => {
    console.log(item.searchItemType, item.id, item.name)
    return item.searchItemType + ':' + item.id;
  }
});
export const peopleAdapter: EntityAdapter<PeopleResult> = createEntityAdapter<PeopleResult>({});

export interface AllResult {
  searchItemType: string,
  id: any,
  name: string
}

export interface PeopleResult {
  cityName: string;
  email: string;
  firstName: string;
  lastName: string;
  login: string;
  phone: string;
  photoUrl: string;
  supervisory: string
}

export const newsAdapter: EntityAdapter<NewsResult> = createEntityAdapter<NewsResult>({});

export interface NewsResult {
  creationDate: Date;
  description: string;
  displayUrl: string;
  hasBookmarked: boolean;
  hasLiked: boolean;
  id: string;
  imageUrl: string;
  likeCount: number;
  snippet: string;
  title: string;
  type: string;
  url: string;
}

export const channelsAdapter: EntityAdapter<ChannelResult> = createEntityAdapter<ChannelResult>({});

export interface ChannelResult extends Channel {
  lastNewsPublishDate: Date;
  hasSubscribed: boolean;
  numberOfNews: number;
  snippet: string
}

export const eventsAdapter: EntityAdapter<EventResult> = createEntityAdapter<EventResult>({});

export interface EventResult extends RhEvent {

}

export const contentAdapter: EntityAdapter<ContentResult> = createEntityAdapter<ContentResult>({});

export interface ContentResult {
  category: string;
  description: string;
  displayUrl: string;
  id: number;
  isVpnRequired: boolean;
  name: string;
  snippet: string;
  title: string;
  url: string;
}

export interface State {
  isLoaded: boolean;
  searchType: string;
  lastSearchType: string;
  mobileFiltersOpened: boolean;
  filtersCounter: {
    locations: number;
    departments: number;
    functions: number;
    categories: number;
    total: number
  } | null,
  filters: {
    locations: Array<string>;
    departments: Array<string>;
    functions: Array<string>;
    categories: Array<string>;
  } | null,
  lastFilters: {
    locations: Array<string>;
    departments: Array<string>;
    functions: Array<string>;
    categories: Array<string>;
  } | null,
  userDefaultFilters: {
    locations: Array<string>;
    departments: Array<string>;
    functions: Array<string>;
    categories: Array<string>;
  } | null,
  savingProcessInProgress: boolean;
  userPreference: {
    locations: Array<string>;
  } | null,
  all: {
    mostRelevant: AllResult | null,
    results: EntityState<AllResult>;
    pagination: Pagination;
    isLoading: LoadingState;
  }
  people: {
    mostRelevant: PeopleResult | null,
    results: EntityState<PeopleResult>;
    pagination: Pagination;
    isLoading: LoadingState;
  }
  news: {
    mostRelevant: NewsResult | null,
    results: EntityState<NewsResult>;
    pagination: Pagination;
    isLoading: LoadingState;
  },
  channels: {
    mostRelevant: ChannelResult | null,
    results: EntityState<ChannelResult>;
    pagination: Pagination;
    isLoading: LoadingState;
  },
  content: {
    mostRelevant: ContentResult | null;
    results: EntityState<ContentResult>;
    pagination: Pagination;
    isLoading: LoadingState;
  },
  events: {
    mostRelevant: EventResult | null;
    results: EntityState<EventResult>;
    pagination: Pagination;
    isLoading: LoadingState;
  }
}

export const initialState: State = {
  isLoaded: false,
  searchType: 'all',
  lastSearchType: 'all',
  mobileFiltersOpened: false,
  filters: null,
  filtersCounter: null,
  lastFilters: null,
  userDefaultFilters: null,
  savingProcessInProgress: false,
  userPreference: null,
  all: {
    mostRelevant: null,
    results: allAdapter.getInitialState(),
    pagination: {
      ...Pagination,
      pageSize: 12,
      pageIndex: 1
    },
    isLoading: LoadingState.LOADING_ALL
  },
  people: {
    mostRelevant: null,
    results: peopleAdapter.getInitialState(),
    pagination: {
      ...Pagination,
      pageSize: 12,
      pageIndex: 1
    },
    isLoading: LoadingState.LOADING_ALL
  },
  news: {
    mostRelevant: null,
    results: newsAdapter.getInitialState(),
    pagination: {
      ...Pagination,
      pageSize: 12,
      pageIndex: 1
    },
    isLoading: LoadingState.LOADING_ALL
  },
  channels: {
    mostRelevant: null,
    results: channelsAdapter.getInitialState(),
    pagination: {
      ...Pagination,
      pageSize: 12,
      pageIndex: 1
    },
    isLoading: LoadingState.LOADING_ALL
  },
  content: {
    mostRelevant: null,
    results: contentAdapter.getInitialState(),
    pagination: {
      ...Pagination,
      pageSize: 12,
      pageIndex: 1
    },
    isLoading: LoadingState.LOADING_ALL
  },
  events: {
    mostRelevant: null,
    results: eventsAdapter.getInitialState(),
    pagination: {
      ...Pagination,
      pageSize: 12,
      pageIndex: 1
    },
    isLoading: LoadingState.LOADING_ALL
  }
};

export const rhSearchReducer = createReducer(
  initialState,
  on(fromActions.setSearchType, (state, {searchType}) => ({
    ...state,
    searchType
  })),
  on(fromActions.loadAllResults, (state) => ({
    ...state,
    all: {
      ...state.all,
      results: allAdapter.removeAll(state.all.results),
      isLoading: LoadingState.LOADING_ALL
    }
  })),
  on(fromActions.loadMoreAllResults, (state) => ({
    ...state,
    all: {
      ...state.all,
      isLoading: LoadingState.LOADING_MORE
    }
  })),
  on(fromActions.loadAllSuccess, (state, {count, isFirst, isLast, pageCount, pageIndex, pageSize, results,}) => ({
    ...state,
    isLoaded: true,
    all: {
      ...state.channels,
      mostRelevant: results[0],
      results: allAdapter.setAll(results, state.all.results),
      pagination: {
        isFirst,
        isLast,
        pageCount,
        pageIndex,
        pageSize,
        totalCount: count,
      },
      isLoading: LoadingState.FINISHED
    }
  })),
  on(fromActions.loadMoreAllSuccess, (state, {
    count,
    isFirst,
    isLast,
    pageCount,
    pageIndex,
    pageSize,
    results,
  }) => ({
    ...state,
    all: {
      ...state.all,
      results: allAdapter.addMany(results, state.all.results),
      pagination: {
        isFirst,
        isLast,
        pageCount,
        pageIndex,
        pageSize,
        totalCount: count,
      },
      isLoading: LoadingState.FINISHED
    }
  })),
  on(fromActions.loadAllFailure, (state) => ({
    ...state,
    all: {
      ...state.all,
      isLoading: LoadingState.FINISHED,
      pagination: {
        ...state.all.pagination,
        isLast: true
      }
    }
  })),

  on(fromActions.loadChannelsResults, (state) => ({
    ...state,
    channels: {
      ...state.channels,
      results: channelsAdapter.removeAll(state.channels.results),
      isLoading: LoadingState.LOADING_ALL
    }
  })),
  on(fromActions.loadMoreChannelsResults, (state) => ({
    ...state,
    channels: {
      ...state.channels,
      isLoading: LoadingState.LOADING_MORE
    }
  })),
  on(fromActions.loadChannelsSuccess, (state, {count, isFirst, isLast, pageCount, pageIndex, pageSize, results,}) => ({
    ...state,
    isLoaded: true,
    channels: {
      ...state.channels,
      mostRelevant: results[0],
      results: channelsAdapter.setAll(results, state.channels.results),
      pagination: {
        isFirst,
        isLast,
        pageCount,
        pageIndex,
        pageSize,
        totalCount: count,
      },
      isLoading: LoadingState.FINISHED
    }
  })),
  on(fromActions.loadMoreChannelsSuccess, (state, {
    count,
    isFirst,
    isLast,
    pageCount,
    pageIndex,
    pageSize,
    results,
  }) => ({
    ...state,
    channels: {
      ...state.channels,
      results: channelsAdapter.addMany(results, state.channels.results),
      pagination: {
        isFirst,
        isLast,
        pageCount,
        pageIndex,
        pageSize,
        totalCount: count,
      },
      isLoading: LoadingState.FINISHED
    }
  })),
  on(fromActions.loadChannelsFailure, (state) => ({
    ...state,
    channels: {
      ...state.channels,
      isLoading: LoadingState.FINISHED
    }
  })),

  on(fromActions.loadEventsSuccess, (state, {count, isFirst, isLast, pageCount, pageIndex, pageSize, results,}) => ({
    ...state,
    isLoaded: true,
    events: {
      ...state.events,
      mostRelevant: results[0],
      results: eventsAdapter.setAll(results, state.events.results),
      pagination: {
        isFirst,
        isLast,
        pageCount,
        pageIndex,
        pageSize,
        totalCount: count,
      },
      isLoading: LoadingState.FINISHED
    }
  })),
  on(fromActions.loadMoreEventsSuccess, (state, {
    count,
    isFirst,
    isLast,
    pageCount,
    pageIndex,
    pageSize,
    results,
  }) => ({
    ...state,
    events: {
      ...state.events,
      results: eventsAdapter.addMany(results, state.events.results),
      pagination: {
        isFirst,
        isLast,
        pageCount,
        pageIndex,
        pageSize,
        totalCount: count,
      },
      isLoading: LoadingState.FINISHED
    }
  })),
  on(fromActions.loadEventsFailure, (state) => ({
    ...state,
    events: {
      ...state.events,
      isLoading: LoadingState.FINISHED
    }
  })),

  on(fromActions.loadPeopleResults, (state) => ({
    ...state,
    people: {
      ...state.people,
      results: peopleAdapter.removeAll(state.people.results),
      isLoading: LoadingState.LOADING_ALL
    }
  })),
  on(fromActions.loadMorePeopleResults, (state) => ({
    ...state,
    people: {
      ...state.people,
      isLoading: LoadingState.LOADING_MORE
    }
  })),
  on(fromActions.loadPeopleSuccess, (state, {count, isFirst, isLast, pageCount, pageIndex, pageSize, results,}) => ({
    ...state,
    isLoaded: true,
    people: {
      ...state.people,
      mostRelevant: results[0],
      results: peopleAdapter.setAll(results, state.people.results),
      pagination: {
        isFirst,
        isLast,
        pageCount,
        pageIndex,
        pageSize,
        totalCount: count,
      },
      isLoading: LoadingState.FINISHED
    }
  })),
  on(fromActions.loadMorePeopleSuccess, (state, {
    count,
    isFirst,
    isLast,
    pageCount,
    pageIndex,
    pageSize,
    results,
  }) => ({
    ...state,
    people: {
      ...state.people,
      results: peopleAdapter.addMany(results, state.people.results),
      pagination: {
        isFirst,
        isLast,
        pageCount,
        pageIndex,
        pageSize,
        totalCount: count,
      },
      isLoading: LoadingState.FINISHED
    }
  })),
  on(fromActions.loadPeopleFailure, (state) => ({
    ...state,
    people: {
      ...state.people,
      isLoading: LoadingState.FINISHED
    }
  })),
  on(fromActions.loadContentResults, (state) => ({
    ...state,
    content: {
      ...state.content,
      results: contentAdapter.removeAll(state.content.results),
      isLoading: LoadingState.LOADING_ALL
    }
  })),
  on(fromActions.loadMoreContentResults, (state) => ({
    ...state,
    content: {
      ...state.content,
      isLoading: LoadingState.LOADING_MORE
    }
  })),
  on(fromActions.loadContentSuccess, (state, {count, isFirst, isLast, pageCount, pageIndex, pageSize, results,}) => ({
    ...state,
    isLoaded: true,
    content: {
      ...state.content,
      mostRelevant: results[0],
      results: contentAdapter.setAll(results, state.content.results),
      pagination: {
        isFirst,
        isLast,
        pageCount,
        pageIndex,
        pageSize,
        totalCount: count,
      },
      isLoading: LoadingState.FINISHED
    }
  })),
  on(fromActions.loadMoreContentSuccess, (state, {
    count,
    isFirst,
    isLast,
    pageCount,
    pageIndex,
    pageSize,
    results,
  }) => ({
    ...state,
    content: {
      ...state.content,
      results: contentAdapter.addMany(results, state.content.results),
      pagination: {
        isFirst,
        isLast,
        pageCount,
        pageIndex,
        pageSize,
        totalCount: count,
      },
      isLoading: LoadingState.FINISHED
    }
  })),
  on(fromActions.loadContentFailure, (state) => ({
    ...state,
    content: {
      ...state.content,
      isLoading: LoadingState.FINISHED
    }
  })),
  on(fromActions.loadNewsResults, (state) => ({
    ...state,
    news: {
      ...state.news,
      results: newsAdapter.removeAll(state.news.results),
      isLoading: LoadingState.LOADING_ALL
    }
  })),
  on(fromActions.loadMoreNewsResults, (state) => ({
    ...state,
    news: {
      ...state.news,
      isLoading: LoadingState.LOADING_MORE
    }
  })),
  on(fromActions.loadNewsSuccess, (state, {count, isFirst, isLast, pageCount, pageIndex, pageSize, results,}) => ({
    ...state,
    isLoaded: true,
    news: {
      ...state.news,
      mostRelevant: results[0],
      results: newsAdapter.setAll(results, state.news.results),
      pagination: {
        isFirst,
        isLast,
        pageCount,
        pageIndex,
        pageSize,
        totalCount: count,
      },
      isLoading: LoadingState.FINISHED
    }
  })),
  on(fromActions.loadMoreNewsSuccess, (state, {count, isFirst, isLast, pageCount, pageIndex, pageSize, results,}) => ({
    ...state,
    news: {
      ...state.news,
      results: newsAdapter.addMany(results, state.news.results),
      pagination: {
        isFirst,
        isLast,
        pageCount,
        pageIndex,
        pageSize,
        totalCount: count,
      },
      isLoading: LoadingState.FINISHED
    }
  })),
  on(fromActions.loadNewsFailure, (state) => ({
    ...state,
    news: {
      ...state.news,
      isLoading: LoadingState.FINISHED
    }
  })),
  on(fromActions.getSavedFiltersSuccess, (state, {filters}) => ({
    ...state,
    filters: {
      ...filters.filter
    },
    userDefaultFilters: filters.filter,
    userPreference: filters.preferences
  })),
  on(fromActions.filtersChanged, (state, {filters}) => ({
    ...state,
    filters: {
      ...filters
    }
  })),
  on(fromActions.setFiltersCounterAsNumbers, (state, {filtersCounter}) => ({
    ...state,
    filtersCounter: {
      ...filtersCounter
    }
  })),
  on(fromActions.saveFilters, (state) => ({
    ...state,
    savingProcessInProgress: true
  })),
  on(fromActions.saveFiltersSuccess, (state, {filters}) => ({
    ...state,
    userDefaultFilters: filters.filter
  })),
  on(fromActions.saveFiltersSuccess, fromActions.saveFiltersFailure, (state) => ({
    ...state,
    savingProcessInProgress: false
  })),
  on(fromActions.addLikeSuccess, (state, {news, numLikes}) => ({
    ...state,
    news: {
      ...state.news,
      results: newsAdapter.updateOne({
        id: news.id,
        changes: {...news, likeCount: numLikes, hasLiked: true}
      }, state.news.results)
    },
    all: {
      ...state.all,
      results: allAdapter.updateOne({
        id: 'news:' + news.id,
        changes: {...news, likeCount: numLikes, hasLiked: true}
      }, state.all.results)
    }

  })),
  on(fromActions.removeLikeSuccess, (state, {news, numLikes}) => ({
    ...state,
    news: {
      ...state.news,
      results: newsAdapter.updateOne({
        id: news.id,
        changes: {...news, likeCount: numLikes, hasLiked: false}
      }, state.news.results)
    },
    all: {
      ...state.all,
      results: allAdapter.updateOne({
        id: 'news:' + news.id,
        changes: {...news, likeCount: numLikes, hasLiked: false}
      }, state.all.results)
    }
  })),
  on(fromActions.clearPreferences, (state) => ({
    ...state,
    filters: null,
    userDefaultFilters: null,
    userPreference: null
  })),
  on(fromActions.clearSearch, (state) => ({
    ...initialState
  })),
  on(fromActions.openMobileFilters, (state) => ({
    ...state,
    mobileFiltersOpened: true,
    lastFilters: state.filters,
    lastSearchType: state.searchType
  })),
  on(fromActions.closeMobileFilters, (state) => ({
    ...state,
    mobileFiltersOpened: false
  })),
  on(fromActions.closeMobileFiltersAndRevert, (state) => ({
    ...state,
    mobileFiltersOpened: false,
    filters: state.lastFilters,
    searchType: state.lastSearchType
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return rhSearchReducer(state, action);
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getSearchType = (state: State) => state.searchType;

export const getAll = (state: State) => state.all;
export const getAllAll = allAdapter.getSelectors().selectAll;
export const getPeople = (state: State) => state.people;
export const getAllPeople = peopleAdapter.getSelectors().selectAll;

export const getContent = (state: State) => state.content;
export const getAllContent = contentAdapter.getSelectors().selectAll;

export const getNews = (state: State) => state.news;
export const getAllNews = newsAdapter.getSelectors().selectAll;

export const getCurrentFilters = (state: State) => state.filters;
export const getUserPreference = (state: State) => state.userPreference;
export const getUserDefaultFilters = (state: State) => state.userDefaultFilters;
export const getFiltersAndPreference = (state: State) => ({
  filters: state.filters,
  preference: state.userPreference
});

export const getSavingProccessInProgress = (state: State) => state.savingProcessInProgress;
export const getMobileFiltersOpened = (state: State) => state.mobileFiltersOpened;
export const getFiltersCounter = (state: State) => state.filtersCounter;

export const getChannels = (state: State) => state.channels;
export const getAllChannels = channelsAdapter.getSelectors().selectAll;

export const getEvents = (state: State) => state.events;
export const getAllEvents = eventsAdapter.getSelectors().selectAll;
