import { createAction, props } from '@ngrx/store';
import { ContentItem } from '@app/core/models/content-item.model';
import { Pagination } from '@app/core/models';

export const initRelatedSection = createAction(
  '[RELATED SECTION] Init Section with default values',
  props<{ newsIds: Array<number> }>()
);

export const initAutoRelatedSection = createAction(
  '[RELATED SECTION] Init auto related section',
  props<{newsId: number}>()
)

export const loadMoreAutoNews = createAction(
  '[RELATED SECTION] Load more related'
)

export const getAutoNewsListRequest = createAction(
  '[RELATED SECTION] Get auto news list Request',
  props<{newsId: number, pagination: Pagination}>()
);

export const getAutoNewsListSuccess = createAction(
  '[CONTENT API - SUCCESS] Get auto news list Success',
  props<{ news: Array<ContentItem>, pagination: Pagination }>()
);

export const getAutoNewsListFailure = createAction(
  '[CONTENT API - ERROR] Get auto news list Failure',
  props<{ error: any }>()
);


export const getNewsListRequest = createAction(
  '[RELATED SECTION] Get news list Request',
  props<{ newsIds: Array<string> }>()
);

export const getNewsListSuccess = createAction(
  '[CONTENT API - SUCCESS] Get news list Success',
  props<{ news: Array<ContentItem> }>()
);

export const getNewsListFailure = createAction(
  '[CONTENT API - ERROR] Get news list Failure',
  props<{ error: any }>()
);

export const likeRequest = createAction(
  '[RELATED SECTION] like request',
  props<{newsId: string}>()
);

export const likeSuccess = createAction(
  '[RELATED SECTION] like success',
  props<{newsId: string, numLikes: number}>()
);

export const likeFailure = createAction(
  '[RELATED SECTION] like failure',
  props<{newsId: string}>()
);

export const unlikeRequest = createAction(
  '[RELATED SECTION] unlike request',
  props<{newsId: string}>()
);

export const unlikeSuccess = createAction(
  '[RELATED SECTION] unlike success',
  props<{newsId: string, numLikes: number}>()
);

export const unlikeFailure = createAction(
  '[RELATED SECTION] unlike failure',
  props<{newsId: string}>()
);

export const bookmarkRequest = createAction(
  '[RELATED SECTION] bookmark request',
  props<{newsId: string}>()
);

export const bookmarkSuccess = createAction(
  '[RELATED SECTION] bookmark success',
  props<{newsId: string}>()
);

export const bookmarkFailure = createAction(
  '[RELATED SECTION] bookmark failure',
  props<{newsId: string}>()
);

export const unbookmarkRequest = createAction(
  '[RELATED SECTION] unbookmark request',
  props<{newsId: string}>()
);

export const unbookmarkSuccess = createAction(
  '[RELATED SECTION] unbookmark success',
  props<{newsId: string}>()
);

export const unbookmarkFailure = createAction(
  '[RELATED SECTION] unbookmark failure',
  props<{newsId: string}>()
);

export const goToContent = createAction(
  '[RELATED SECTION - GO CONTENT] Navigate to Content Details',
  props<{ path: string, queryParams }>()
);

export const goOutside = createAction(
  '[RELATED SECTION - REDIRECT OUT] Redirect outside',
  props<{ url: string }>()
);

export const markAsRead = createAction(
  '[RELATED SECTION] Mark as read',
  props<{ id: string }>()
);

export const markAsReadSuccess = createAction(
  '[RELATED SECTION] Mark as read success',
  props<{ id: string }>()
);

export const markAsReadFailure = createAction(
  '[RELATED SECTION] Mark as read failure',
);

// BOOKMARKS
export const setBookmarkFromChannelItem =  createAction(
  '[RELATED SECTION - PUT BOOKMARK] Request',
  props<{ contentId: string}>()
);
export const setNoBookmarkFromChannelItem =  createAction(
  '[RELATED SECTION - REMOVE BOOKMARK] Request',
  props<{ contentId: string}>()
);
export const setBookmarkFromLatestNews =  createAction(
  '[RELATED SECTION - PUT BOOKMARK] Request From Latest',
  props<{ contentId: string}>()
);
export const setNoBookmarksFromLatestNews =  createAction(
  '[RELATED SECTION - REMOVE BOOKMARK] Request From Latest',
  props<{ contentId: string}>()
);
export const setBookmarkFromChannelItemFailure =  createAction(
  '[RELATED SECTION - PUT BOOKMARK ] Failure',
);
export const setNoBookmarkFromChannelItemFailure =  createAction(
  '[RELATED SECTION - REMOVE BOOKMARK] Failure',
);

export const clearAll =  createAction(
  '[RELATED SECTION] Clear all',
);
