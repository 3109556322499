import { createSelector } from '@ngrx/store';

import * as fromCore from '@core/core-store/core.reducer';
import * as fromItems from './content-items.reducer';

export const selectState = createSelector(
  fromCore.selectState,
  fromCore.getContentItems
);

export const selectAll = createSelector(
  selectState,
  fromItems.getAll
);

export const selectEntities = createSelector(
  selectState,
  fromItems.getEntities
);

export const selectIds = createSelector(
  selectState,
  fromItems.getIds
);

export const selectTotal = createSelector(
  selectState,
  fromItems.getTotal
);

export const selectEntityById = createSelector(
  selectEntities,
  fromItems.getEntityById
);

export const selectEntitiesById = createSelector(
  selectEntities,
  fromItems.getEntitiesById
);
