import { NewsModel } from '@app/core/models/news.model';
import { getISOWeek } from 'date-fns';
import { Section, SectionTypes } from '../models/section-type.enum';

export function createSection(sectionType: SectionTypes) {
  let section: Section;
  switch (sectionType) {
    case SectionTypes.SINGLE: {
      section = {
        id: createGuid(),
        type: sectionType,
        subsections: [
          {
            id: createGuid(),
            colspan: '6',
            blocks: []
          }
        ]
      };
      break;
    }
    case SectionTypes.TWO_EQUAL: {
      section = {
        id: createGuid(),
        type: sectionType,
        subsections: [
          {
            id: createGuid(),
            colspan: '3',
            blocks: []
          },
          {
            id: createGuid(),
            colspan: '3',
            blocks: []
          }
        ]
      };
      break;
    }
    case SectionTypes.THREE_IN_ONE: {
      section = {
        id: createGuid(),
        type: sectionType,
        subsections: [
          {
            id: createGuid(),
            colspan: '4',
            blocks: []
          },
          {
            id: createGuid(),
            colspan: '2',
            blocks: []
          }
        ]
      };
      break;
    }
    case SectionTypes.ONE_IN_THREE: {
      section = {
        id: createGuid(),
        type: sectionType,
        subsections: [
          {
            id: createGuid(),
            colspan: '2',
            blocks: []
          },
          {
            id: createGuid(),
            colspan: '4',
            blocks: []
          }
        ]
      };
      break;
    }
  }
  return section;
}

/* tslint:disable */
export function createGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0,
    v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function generateImageMarkup(photoUrl: string, linkUrl: string) {
  const aOpen = `<a href='${linkUrl}' target='_blank'>`;
  const aClose = '</a>';
  const img = `<img class='email-block__image' style='display: block; width: 100%; border-radius: 4px; margin-bottom: 16px;' crossorigin="anonymous" src='${photoUrl}'>`;
  return linkUrl && linkUrl.length ? aOpen + img + aClose : img;
}

export function generateNewsCardMarkup(news: NewsModel) {
  let textDiv: string;
  let imgDiv: string;
  let newsHTML: string;
  imgDiv = `
  <div style='width: 100%'>
    <div style='width: 100%; padding-top: 56.25%; background-size:cover; margin-bottom: 16px; border-radius: 4px; border: 0.5px solid #dbd6d1; background-image: url(${news.imageUrl || createPlaceholderUrl()})' data-nbimg></div>
  </div>`;

  textDiv = `
    <div style='padding-bottom: 8px; width: 100%;'>
      <h3 style='color: #0B41CD; font-size: 14px; font-weight: bold; letter-spacing: 0.03px; line-height: 22px; margin-top: 0; margin-bottom: 8px;'>
        <a style='text-decoration: none;' target='_blank' href='${news.linkUrl}'>${news.title}</a>
      </h3>
      <p style='color: #706B69; font-size: 12px; letter-spacing: 0; line-height: 16px; margin: 0;'>
        ${news.description}
      </p>
    </div>`;
    newsHTML = imgDiv + textDiv;
  return `
    <div data-newsid='${news.newsId}' style='margin-bottom: 24px; display: flex; flex-direction: column;'>
      ${newsHTML}
    </div>
  `;
}

export function generateNewsListMarkup(news: NewsModel, flipped = false) {
  let textDiv: string;
  let imgDiv: string;
  let newsHTML: string;
  imgDiv = `
  <div style='margin-${flipped ? 'left' : 'right'}: 24px; width: 206px'>
    <div style='width: 100%; padding-top: 56.25%; background-size:cover; margin-bottom: 16px; border-radius: 4px; border: 0.5px solid #dbd6d1; background-image: url(${news.imageUrl || createPlaceholderUrl()})' data-nbimg></div>
  </div>`;

  textDiv = `
    <div style='padding-bottom: 8px; width: 100%;'>
      <h3 style='color: #0B41CD; font-size: 14px; font-weight: bold; letter-spacing: 0.03px; line-height: 22px; margin-top: 0; margin-bottom: 8px;'>
        <a style='text-decoration: none;' target='_blank' href='${news.linkUrl}'>${news.title}</a>
      </h3>
      <p style='color: #706B69; font-size: 12px; letter-spacing: 0; line-height: 16px; margin: 0;'>
        ${news.description}
      </p>
    </div>`;
    newsHTML = flipped ? textDiv + imgDiv : imgDiv + textDiv
  return `
    <div data-newsid='${news.newsId}' style='margin-bottom: 24px; display: flex;'>
      ${newsHTML}
    </div>
  `;
}

export function createUrlForInternalNews(data: any): string {
  const weekOfYear = getISOWeek(new Date());
  return `${location.origin}/content/${data.id}?title=${encodeURIComponent(data.title.trim().replace(/\s/g, '_')).replace(/'/g, '%27')}&utm_source=newsletter&utm_medium=email&utm_campaign=newsletter_cw${weekOfYear}_${encodeURIComponent(data.title.trim().replace(/\s/g, '_')).replace(/'/g, '%27')}`
  
}

export function createPlaceholderUrl(): string {
  return location.origin + '/assets/images/news-placeholder.png';
}
/* tslint:enable */
