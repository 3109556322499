<h2 rds-dialog-header>
  Alert preview
</h2>
<div rds-dialog-icon-placeholder>
  <button rds-icon-button size="m" [rds-dialog-close]="null">
    <rds-icon icon="close" namespace="outlined"></rds-icon>
  </button>
</div>
<div rds-dialog-content>
  <div class="alert-preview__heading">
    <rds-tabs #tabs size="m" >
      <rds-tab label="RocheHome">
        <div class="alert-preview__content my-5">
          <div class="alert-preview__content--web-ext mt-5" *ngIf="alert">
            <rds-alert class="mb-5">Alerts received by the user while using RocheHome will be displayed in a <a rds-link (click)="openDialogPreview()">dialog box</a></rds-alert>
            <rh-alert [alert]="alert" [length]="0"></rh-alert>
          </div>
        </div>
      </rds-tab>
      <rds-tab label="News App - iOS">
        <div class="alert-preview__content my-5">
          <div class="alert-preview__content--ios">
            <img [src]="iosImageUrl" />  
            <div class="alert-preview__content--ios__preview" *ngIf="alert">
              <div class="alert-preview__content--ios__preview--header">
                {{alert.title.length > 0? alert.title : 'Alert Title'}}
              </div>
              <div class="alert-preview__content--ios__preview--content">
                {{alert.message.length > 0? alert.message : 'The alert message should be short and include only the most important information. '}}
              </div>
              <div class="alert-preview__content--ios__preview--actions">
                <div class="alert-preview__content--ios__preview--actions__button">
                  Close
                </div>
                <div class="alert-preview__content--ios__preview--actions__button">
                  Read More
                </div>
              </div>
            </div>
          </div>
        </div>

      </rds-tab>
      <rds-tab label="News App - Android">
        <div class="alert-preview__content my-5">
          <div class="alert-preview__content--android">
            <img [src]="androidImageUrl" />  
            <div class="alert-preview__content--android__preview" *ngIf="alert">
              <div class="alert-preview__content--android__preview--header">
                {{alert.title.length > 0? alert.title : 'Alert Title'}}
              </div>
              <div class="alert-preview__content--android__preview--content">
                {{alert.message.length > 0? alert.message : 'The alert message should be short and include only the most important information. '}}
              </div>
              <div class="alert-preview__content--android__preview--actions">
                <div class="alert-preview__content--android__preview--actions__button">
                  CLOSE
                </div>
                <div class="alert-preview__content--android__preview--actions__button ">
                  READ MORE
                </div>
              </div>
            </div>
          </div>
        </div>
      </rds-tab>
    </rds-tabs>
  </div>
</div>
<div rds-dialog-actions-container align="end">
  <ng-container [ngSwitch]="context">
    <ng-container *ngSwitchCase="'Editor'">
      <button *ngIf="!!alert.url" rds-text-button (click)="gotoLink()" size="m">
        Open link in new tab <rds-icon icon="launch"></rds-icon>
      </button>
      <button *ngIf="buttons?.canSaveAlert?.visible" [disabled]="buttons?.canSaveAlert?.disabled" rds-primary-button [rds-dialog-close]="'saveNewsAsDraft'" size="m">
        Send alert
      </button>
    </ng-container>
  </ng-container>
</div>