import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { GoogleService } from '@app/core/auth/services/google.service';

@Component({
  selector: 'atr-google-login-dialog',
  templateUrl: './google-login-dialog.container.html',
  styleUrls: ['./google-login-dialog.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleLoginDialogContainer implements AfterViewInit {

  constructor(
    private googleService: GoogleService
  ) {}

  ngAfterViewInit(): void {
    this.googleService.signIn();
  }
}
