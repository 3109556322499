import { Injectable } from '@angular/core';
import { EventsService } from '@app/core/services/managing-events.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';
import * as fromActions from './events.actions';

@Injectable()
export class EventsEffects {

  public getEventsByRange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getEventsByRange),
      map(({start, end}) => fromActions.searchByRangeCollectionUpsertOne({start, end}))
  ), { dispatch: true});

  public searchByRangeCollectionUpsertOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.searchByRangeCollectionUpsertOne),
      switchMap(({start, end}) => this.eventsService.getGrid({
        departments: [],
        endDate: end,
        functions: [],
        locations: [],
        pageIndex: 0,
        pageSize: 1000,
        search: null,
        startDate: start,
        types: []
      }).pipe(
        map(({events}) => fromActions.eventsByRangeCollectionUpsertMany({start, end, events}))
      ))
  ), { dispatch: true});

  
  public getEventById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getEventById),
      switchMap(({id}) => this.eventsService.getById(id).pipe(
        map((event) => fromActions.eventByIdCollectionUpsertOne({event}))
      ))
  ), { dispatch: true});

  constructor(
    private actions$: Actions,
    private store$: Store<any>,
    private eventsService: EventsService
  ) {}
}
