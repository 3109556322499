<div class="catalog-details">
    <div class="row">
        <div class="col-auto my-4">
            <rh-back-button (click)="backToCategories()" defaultLabel="Cataloge"
                            [preventRedirect]="true"></rh-back-button>
        </div>
    </div>
    <div class="row row-centered">
        <div class="col-auto mt-9 mb-5">
            <h4 class="ui-heading-4-bold--xl">
                {{category.name}}
            </h4>
        </div>
        <div class="col-auto mt-9 mb-5">
            <rh-advanced-filters
                    class="m-4"
                    [advancedFilters]="filters$ | async"
                    [defaultFilters]="defaultFilters$ | async"
                    (filtersChanged)="advancedFiltersChanged($event)"
            >
            </rh-advanced-filters>
        </div>
    </div>
    <div class="catalog-details__body">
        <div *ngIf="(isLoading$ | async) === true && (catalogsLength$ | async) === 0"
             class="catalog-details__body--loader">
            <rds-progress-bar></rds-progress-bar>
        </div>
        <ng-container *ngIf="allCategories$">
            <ng-container *ngFor="let category of allCategories$ | async">
                <rh-catalogs-in-category [category]="category"></rh-catalogs-in-category>
            </ng-container>
        </ng-container>
    </div>
</div>
