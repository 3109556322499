import { createAction, props } from '@ngrx/store';

import { ContentType } from '@core/models/content-type.model';

export const loadContentTypes = createAction(
  '[CORE CONTENT TYPES - LOAD CONTENT TYPES] Load Content Types'
);

export const getContentTypesRequest = createAction(
  '[CORE CONTENT TYPES - GET CONTENT TYPES REQUEST] Get Content Types Request'
);

export const getContentTypesSuccess = createAction(
  '[CORE CONTENT TYPES - GET CONTENT TYPES SUCCESS] Get Content Types Success',
  props<{ contentTypes: ContentType[] }>()
);

export const getContentTypesFailure = createAction(
  '[CORE CONTENT TYPES - GET CONTENT TYPES FAILURE] Get Content Types Failure',
  props<{ error: any }>()
);

export const contentTypesCollectionAddMany = createAction(
  '[STORED CONTENT TYPES - ADD MANY] Content Types collection add many.',
  props<{ contentTypes: ContentType[] }>()
);

