  
<h2 rds-dialog-header>What email address should we send the test email?</h2>
<div rds-dialog-content>
  <ng-container [formGroup]="form">
    <rh-email-picker 
    (search)="getAutocompletes('testRecipients', $event)"
    [multi]="true"
    formControlName="to"
    [autocomplete]="testRecipientsAutocomplete$ | async">
    <rds-control-hint>Use comma as separator for multiple email addresses.</rds-control-hint>
    </rh-email-picker>
  </ng-container>
</div>
<div rds-dialog-actions-container align="end">
  <button
      rds-secondary-button
      [rds-dialog-close]="null"
      size="m"
  >Cancel</button>
  <button
      rds-primary-button
      [disabled]="form.invalid"
      [rds-dialog-close]="form.value"
      size="m"
  >Send test Newsletter</button>
</div>