import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Comment, CommentStatus } from '@app/core/models';
import { UserSearch } from '@app/core/models/user-search.model';
import { CommentState } from '@app/news-details/store/comments';

@Component({
  selector: 'rh-news-comments',
  templateUrl: './news-comments.component.html',
  styleUrls: ['./news-comments.component.scss']
})
export class NewsCommentsComponent implements OnChanges {

  selectedValue = 'text';
  commentsExpanded: boolean = false;

  CommentStatus = CommentStatus;

  @Input() commentsEnabled: boolean;
  @Input() commentsHidden: boolean;
  @Input() numComments: number = 0;
  @Input() comments: { [id: string]: CommentState };
  visibleComments: { [id: string]: CommentState };
  @Input() isNewsboardAdmin: boolean = false;
  @Input() canDeleteComments: boolean;
  @Input() userFullName: string;
  @Input() userLogin: string;
  @Input() mentionAutocomplete: { data: Array<UserSearch>, loading: boolean };

  commentForm: FormGroup<{ comment: FormControl, [key: string]: FormControl }> = this.fb.group({
    comment: new FormControl('')
  });

  @Output() replySent: EventEmitter<{ parentId: number, text: string }> = new EventEmitter();
  @Output() editSaved: EventEmitter<{ commentId: number, text: string, parentId?: number }> = new EventEmitter();
  @Output() commentDeleted: EventEmitter<{ commentId: number, parentId?: number }> = new EventEmitter();
  @Output() commentHidden: EventEmitter<{ commentId: number, parentId?: number }> = new EventEmitter();
  @Output() commentRevealed: EventEmitter<{ commentId: number, parentId?: number }> = new EventEmitter();
  @Output() commentingDisabled: EventEmitter<any> = new EventEmitter();
  @Output() commentingEnabled: EventEmitter<any> = new EventEmitter();
  @Output() commentPosted: EventEmitter<{ text: string }> = new EventEmitter();
  @Output() autocompleteRequested: EventEmitter<{ phrase: string }> = new EventEmitter();
  @Output() autocompleteClear: EventEmitter<any> = new EventEmitter();

  constructor(private fb: FormBuilder) {
    if (!!location.hash) {
      this.commentsExpanded = true;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.comments) {
      this.visibleComments = this.filterComments(this.comments);
    }
  }

  filterComments(comments: { [id: string]: CommentState }) {
    return Object.keys(comments).reduce((result, key) => {
      if (this.isNewsboardAdmin || (comments[key].status === CommentStatus.HIDDEN && comments[key].canEdit) || comments[key].status === CommentStatus.VISIBLE) {
        return result = {
          ...result,
          [key]: {
            ...comments[key],
            responses: this.filterComments((comments[key] as any).responses)
          }
        }
      } else {
        return result
      }
    }, {}) 
  }

  get now() {
    return new Date();
  }

  public urlify(text: string): string {
    const urlRegex = /(( |^)((https?:\/\/)|(www\.))[^\s]+)/g;

    return text.replace(urlRegex, (url, b, c) => {
      const url2 = (c === 'www.') ? 'http://' + url : url;
      return '<a href="' + url2 + '" target="_blank">' + url + '</a>';
    });
  }

  onGetAutocomplete(phrase) {
    this.autocompleteRequested.emit({phrase});
  }

  onClearAutocomplete() {
    this.autocompleteClear.emit();
  }

  isEditing(id: number) {
    const idStr = id + '';
    return !!Object.keys(this.commentForm.controls).find(controlKey => controlKey === idStr);
  }

  isReplying(id: number) {
    const idStr = id + '';
    return !!Object.keys(this.commentForm.controls).find(controlKey => controlKey === `reply-${idStr}`);
  }

  hasResponses(responses) {
    return Object.keys(responses).length > 0;
  }

  cancel() {
    const toCancel = Object.keys(this.commentForm.controls).filter(controlKey => controlKey !== 'comment');
    toCancel.forEach(controlKey => {
      this.commentForm.removeControl(controlKey);
    });
  }

  edit(id: number, text: string) {
    const idStr = id + '';
    this.cancel();
    this.commentForm.addControl(idStr, new FormControl(text));
  }

  reply(id: number) {
    const idStr = id + '';
    this.cancel();
    this.commentForm.addControl(`reply-${idStr}`, new FormControl(''))
  }

  sendReply(id: string) {
    const parentId = +id.split('-')[1];
    const text = this.commentForm.controls[id].value;
    this.replySent.emit({parentId, text});
    this.cancel();
  }

  postComment() {
    const text = this.commentForm.controls.comment.value;
    this.commentPosted.emit({text});
    this.commentForm.controls.comment.setValue('')
    this.cancel();
  }

  deleteComment(commentId: string, parentId: string) {
    this.commentDeleted.emit({commentId: +commentId, parentId: +parentId});
    this.cancel();
  }

  hideComment(commentId: string, parentId: string) {
    this.commentHidden.emit({commentId: +commentId, parentId: +parentId});
    this.cancel();
  }

  revealComment(commentId: string, parentId: string) {
    this.commentRevealed.emit({commentId: +commentId, parentId: +parentId});
    this.cancel();
  }

  saveEdit(commentId: string, parentId: string) {
    const text = this.commentForm.controls[commentId].value;
    this.editSaved.emit({commentId: +commentId, text, parentId: +parentId});
    this.cancel();
  }

  compareComments = (a: KeyValue<number, Comment>, b: KeyValue<number, Comment>): number => {
    const aDate = a.value.modifiedDate || a.value.createdDate;
    const bDate = b.value.modifiedDate || b.value.createdDate;
    return new Date(bDate).getTime() - new Date(aDate).getTime();
  }

  enableCommenting() {
    this.commentingEnabled.emit();
  }

  disableCommenting() {
    this.commentingDisabled.emit();
  }

  trackByFn(index, item) {
    return index;
  }
}
