import {Plugin} from 'ckeditor5/src/core';
import {isWidget, WidgetToolbarRepository} from 'ckeditor5/src/widget';
import {createNewButtons} from '../blocks/blocks-utils'

const options = [
    {
        name: 'single',
        label: 'Text in one column',
        command: 'changeTypeTextBox',
        commandValue: 'single',
        type: 'action'
    },
    {
        name: 'double',
        label: 'Text in two columns',
        command: 'changeTypeTextBox',
        commandValue: 'double',
        type: 'action'
    },
    {
        name: 'triple',
        label: 'Text in three columns',
        command: 'changeTypeTextBox',
        commandValue: 'triple',
        type: 'action'
    },
    {
        name: 'block',
        label: 'Text block',
        command: 'changeTypeTextBox',
        commandValue: 'block',
        type: 'action'
    }
]

export default class TextBoxToolbar extends Plugin {

    static get requires() {
        return [WidgetToolbarRepository];
    }

    static get pluginName() {
        return 'TextBoxToolbar';
    }


    afterInit() {

        const editor = this.editor;
        const t = editor.t;
        const widgetToolbarRepository = editor.plugins.get(WidgetToolbarRepository);


        createNewButtons(this, options);

        if (options) {
            widgetToolbarRepository.register('TextBox', {
                ariaLabel: t('Image and text box toolbar'),
                items: getOptionsCommand(),
                getRelatedElement: getSelectedImageAndTextWidget,
                balloonClassName: 'ck-custom-fieldset-toolbar'

            });
        }
    }
}

export function getSelectedImageAndTextWidget(selection) {
    const viewElement = selection.getSelectedElement();

    if (viewElement && isTextBoxWidget(viewElement)) {
        return viewElement;
    }

    return null;
}

export function getTextBoxWidgetAncestor(selection) {
    const selectionPosition = selection.getFirstPosition();

    if (!selectionPosition) {
        return null;
    }

    let parent = selectionPosition.parent;

    while (parent) {
        if (parent.is('element') && isTextBoxWidget(parent)) {
            return parent;
        }

        parent = parent.parent;
    }

    return null;
}

function isTextBoxWidget(viewElement) {
    return !!viewElement.hasClass('text-box') && isWidget(viewElement);
}

function getOptionsCommand() {
    const newOptions = options.map(option => {
        if (option.commandValue) {
            return `${option.command}:${option.commandValue}`;
        } else {
            return option.command;
        }
    });
    newOptions.push('blockBackgroundClass');
    return newOptions;
}
