
<div class="row">
  <div class="col-8 offset-2 py-4">
    <div class="row">
      <div class="col">
        <h4 class="ui-heading-4">News language</h4>
      </div>
    </div>
  </div>
</div>
  <div [formGroup]="form" class="col-8 offset-2 bg-white py-10 py-s-4">
    <div class="row">
      <div class="col px-11 px-s-5">
        <rds-form-field>
          <rds-select
            #select
            formControlName="newsLanguageId"
            (valueChange)="select.resetFilterValue()"
            [filterBy]="filterBy"
          >
            <rds-select-search-input #searchInput></rds-select-search-input>
            <rds-select-option-separator></rds-select-option-separator>
            <rds-select-option *ngFor="let language of languages$ | async" [value]="language.id" [id]="language.englishName" [innerHTML]="language.englishName | highlight:searchInput.control.value"></rds-select-option>
          </rds-select>
        </rds-form-field>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-8 offset-2 py-5 mb-9">
      <div class="row">
        <div class="col-auto offset-auto">
          <button rds-secondary-button [disabled]="nothingChanged" (click)="cancel()" size="m">
            Cancel
          </button>
        </div>
        <div class="col-auto">
          <button rds-primary-button [disabled]="nothingChanged" (click)="save()" size="m">
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>