import { ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { NgIf } from '@angular/common';
import { select, Store } from '@ngrx/store';
import * as fromUser from '@app/core/user/store';
import { RoleNames } from '@core/enums/roles.enum';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({ selector: '[atrUserHasRole]' })
export class HasRoleDirective extends NgIf implements OnDestroy {
  private storeSubscription: Subscription;

  @Input() set atrUserHasRole(roles: RoleNames[] | null) {
    this.storeSubscription = this.store$.pipe(
      select(fromUser.selectRoles),
      map(userRoles => (!userRoles) ? [] : userRoles)
    ).subscribe((userRoles: RoleNames[]) => {
      let canShow = false;

      if (!roles) {
        this.ngIf = true;
        return;
      }

      if (!userRoles) {
        this.ngIf = false;
        return;
      }

      canShow = roles.some(role => userRoles.includes(role));

      this.ngIf = canShow;
    });
  }

  @Input() set atrUserHasRoleElse(templateElse: TemplateRef<any>) {
    this.ngIfElse = templateElse;
  }

  ngOnDestroy() {
    this.changeDetectorRef.detach();
    this.storeSubscription.unsubscribe();
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private changeDetectorRef: ChangeDetectorRef,
    private store$: Store<fromUser.State>
  ) {
    super(viewContainerRef, templateRef);
  }
}
