import { createReducer, Action, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';

import * as fromActions from '@core/core-store/department-managers/department-managers.actions';
import { TaxonomyManagers } from '@app/core/models';

export interface State extends EntityState<TaxonomyManagers> {}

export const adapter: EntityAdapter<TaxonomyManagers> =
 createEntityAdapter<TaxonomyManagers>({
  selectId: departmentManagers => departmentManagers.taxonomyId
 });

export const initialState: State = {
  ...adapter.getInitialState(),
};

const departmentManagersReducer = createReducer(
  initialState,
  on(fromActions.departmentManagersCollectionAddMany, (state, {departmentsManagers}) => (
    adapter.addMany(departmentsManagers, state)
  )),
  on(fromActions.departmentManagersCollectionAddOne, (state, {departmentManagers}) => (
    adapter.addOne(departmentManagers,  state)
  )),
  on(fromActions.departmentManagersCollectionUpsertOne, (state, {departmentManagers}) => (
    adapter.upsertOne(departmentManagers,  state)
  )),
  on(fromActions.departmentManagersCollectionRemoveOne, (state, {departmentId}) => (
    adapter.removeOne(departmentId, state)
  )),
);

export function reducer(state: State | undefined, action: Action) {
  return departmentManagersReducer(state, action);
}

export const getIds = adapter.getSelectors().selectIds;
export const getEntities = adapter.getSelectors().selectEntities;
export const getAll = adapter.getSelectors().selectAll;
export const getTotal = adapter.getSelectors().selectTotal;

export const getEntityById = (entities: Dictionary<TaxonomyManagers>, {id}) => entities[id];
export const getEntitiesById = (entities: Dictionary<TaxonomyManagers>, {ids}) => ids.map(id => entities[id]);
