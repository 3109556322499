import { DictionaryFilterDefinition, DictionaryTypeEnum, FilterTypeEnum, PredefinedFilterDefinition } from '../filters-model';

export interface CatalogHeaderFilters {
    predefined: PredefinedFilterDefinition;
    categories: DictionaryFilterDefinition;
    locations?: DictionaryFilterDefinition;
    departments?: DictionaryFilterDefinition;
    functions?: DictionaryFilterDefinition;
  }

  export const INITIAL_CATALOG_HEADER_FILTERS: CatalogHeaderFilters = {
    predefined: {
      type: FilterTypeEnum.PREDEFINED_TOGGLE,
      label: null,
      changable: true,
      value: false,
      predefinedValue: null,
      toggleLabel: 'Use my taxonomies'
    },
    categories: {
      type: FilterTypeEnum.DICTIONARY,
      dictionary: DictionaryTypeEnum.CATEGORIES,
      label: 'Categories',
      changable: true,
      value: []
    },
    locations: {
      type: FilterTypeEnum.DICTIONARY,
      dictionary: DictionaryTypeEnum.LOCATIONS,
      label: 'Location',
      changable: true,
      value: []
    },
    departments: {
      type: FilterTypeEnum.DICTIONARY,
      dictionary: DictionaryTypeEnum.DEPARTMENTS,
      label: 'Department',
      changable: true,
      value: []
    },
    functions: {
      type: FilterTypeEnum.DICTIONARY,
      dictionary: DictionaryTypeEnum.FUNCTIONS,
      label: 'Function',
      changable: true,
      value: []
    }
  }
