import { createReducer, on } from '@ngrx/store';
import * as fromActions from './back-button.actions';

export interface BackButton {
  label: string;
  routeBack: string;
  routeBackQueryParams?: {[key: string]: string},
  routeCurrent: string | Array<string>;
}
export interface State extends BackButton {
  addNewsMiddleRoutes: Array<{
    label: string;
    route: string;
  }>;
}

export const initialState: State = {
  label: null,
  routeBack: null,
  routeBackQueryParams: null,
  routeCurrent: null,
  addNewsMiddleRoutes: []
};

export const reducer = createReducer(
  initialState,
  on(fromActions.set, (state, {label, routeBack, routeCurrent, routeBackQueryParams}) => ({
    ...state,
    label,
    routeBack,
    routeCurrent,
    routeBackQueryParams: routeBackQueryParams? routeBackQueryParams : null
  })),
  on(fromActions.clear, (state) => ({
    ...state,
    label: null,
    routeBack: null,
    routeCurrent: null
  })),
  on(fromActions.setAddNewsMiddleRoute, (state, {routes}) => ({
    ...state,
    addNewsMiddleRoutes: routes
  }))
);

export const getBackConfig = ({label, routeBack, routeBackQueryParams, routeCurrent}: State) => ({
  label,
  routeBack,
  routeBackQueryParams,
  routeCurrent
});

export const getAddNewsMiddleRoute = (state: State) => state.addNewsMiddleRoutes;