import {Injectable} from '@angular/core';
import * as fromUser from '@app/core/user/store';
import * as fromCatalogs from '@app/layout/header/store/catalogs';
import {ConfirmDialogComponent} from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import {RenameDialogComponent, RenameDialogData} from '@app/shared/dialogs/rename-dialog/rename-dialog.component';
import * as fromCategoriesFlat from '@core/core-store/categories-flat';
import {HeaderService} from '@app/layout/header/services/header.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {RdsDialogService} from '@rds/angular-components';
import {catchError, filter, first, map, mergeMap, of, switchMap, take, tap, withLatestFrom} from 'rxjs';
import {
  ReorganizeQuickLinksDialogComponent
} from '../../components/reorganize-quicklinks-dialog/reorganize-quicklinks-dialog.component';
import {
  ResetQuicklinksDialogComponent
} from '../../components/reset-quicklinks-dialog/reset-quicklinks-dialog.component';
import * as fromReducer from '../header.reducer';
import * as fromActions from '../quick-link/quick-link.actions';
import * as fromSelectors from '../quick-link/quick-link.selectors';

@Injectable()
export class QuickLinkEffects {

  public getMyQuickLinks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getMyQuickLinks),
      mergeMap(() => this.headerService.getMyQuickLinks().pipe(
        map(quickLinks => fromActions.getMyQuickLinksSuccess({quickLinks})),
        catchError(() => of(fromActions.getMyQuickLinksFailure()))
      ))
    ), {dispatch: true}
  );

  public resetQuickLinksToDefault$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resetQuickLinksToDefault),
      switchMap(() => this.headerService.getResetQuickLinks().pipe(
        map(({quickLinksToRemove, quickLinksToAdd}) => ({quickLinksToAdd, quickLinksToRemove}))
      )),
      switchMap(({quickLinksToAdd, quickLinksToRemove}) => this.dialogService.open(ResetQuicklinksDialogComponent, {
        data: {
          quickLinksToAdd,
          quickLinksToRemove
        }
      }).afterClosed().pipe(
        filter((data) => !!data),
        map((data) => ({data}))
      )),
      switchMap(() => this.headerService.postResetQuickLinks().pipe(
        map((quickLinks) => fromActions.resetQuickLinksToDefaultSuccess({quickLinks})),
        catchError(() => of(fromActions.resetQuickLinksToDefaultFailure()))
      ))
    )
  );

  public resetQuickLinksToDefaultSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resetQuickLinksToDefaultSuccess),
      map(({quickLinks}) => fromActions.getMyQuickLinksSuccess({quickLinks})),
    )
  );

  public reorganizeQuickLinks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.reorganizeQuickLinks),
      switchMap(() => this.store$.pipe(
        select(fromSelectors.selectQuickLinksFavouritesInOrder),
        take(1),
        map((quickLinks) => ({quickLinks})),
      )),
      switchMap(({quickLinks}) => this.dialogService.open(ReorganizeQuickLinksDialogComponent, {
        data: {
          quickLinks
        }
      }).afterClosed().pipe(
        tap((data) => {
          if (!data) {
            this.store$.dispatch(fromActions.clearTemporaryFavouriteQuickLinks())
          }
        }),
        filter(data => !!data),
        map((quickLinks) => ({quickLinks}))
      )),
      switchMap(({quickLinks}) => this.headerService.putReorganizeQuickLinks(quickLinks).pipe(
        map(() => fromActions.reorganizeQuickLinksSuccess()),
        catchError(() => of(fromActions.reorganizeQuickLinksFailure()))
      ))
    )
  );

  public reorganizeQuickLinksSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.reorganizeQuickLinksSuccess, fromActions.deleteQuickLinksSuccess, fromActions.deleteQuickLinkSuccess),
      map(() => fromActions.getMyQuickLinks()),
    )
  );

  public openDeleteQuickLinksConfirmDialog$ = createEffect(() =>
      this.actions$.pipe(
        ofType(fromActions.openDeleteQuickLinksConfirmDialog),
        switchMap(() => this.store$.pipe(
          select(fromSelectors.selectQuickLinkIdsToDelete),
          take(1),
          map((ids) => ({ids})),
        )),
        switchMap(({ids}) => this.dialogService.open(ConfirmDialogComponent, {
          data: {
            ids: ids,
            title: `Are you sure you want to delete selected QuickLink?`,
            confirmButtonLabel: `Yes, delete`,
            confirmButtonType: 'warning'
          }
        }).afterClosed().pipe(
          filter((data) => !!data),
          map((data) => ({ids: data.ids}))
        )),
        switchMap(({ids}) => this.headerService.deleteQuickLinks(ids).pipe(
          map(() => fromActions.deleteQuickLinksSuccess()),
          catchError(() => of(fromActions.deleteQuickLinksFailure()))
        ))
      ),
    {dispatch: true}
  );

  public openDeleteQuickLinkConfirmDialog$ = createEffect(() =>
      this.actions$.pipe(
        ofType(fromActions.openDeleteQuickLinkConfirmDialog),
        switchMap(({id, name}) => this.dialogService.open(ConfirmDialogComponent, {
          data: {
            ids: [id],
            title: `Are you sure you want to delete "${name}" QuickLink?`,
            confirmButtonLabel: `Yes, delete`,
            confirmButtonType: 'warning'
          }
        }).afterClosed().pipe(
          filter((data) => !!data),
          map((data) => ({id}))
        )),
        switchMap(({id}) => this.headerService.deleteQuickLinks([id]).pipe(
          map(() => fromActions.deleteQuickLinkSuccess()),
          catchError(() => of(fromActions.deleteQuickLinkFailure()))
        ))
      ),
    {dispatch: true}
  );

  public addToTopRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addToTopRequest),
      switchMap(({id}) => this.headerService.addToTopQuickLinks(id).pipe(
        map((quickLink) => fromActions.addToTopSuccess({quickLink})),
        catchError(() => of(fromActions.addToTopFailure()))
      ))
    )
  );

  public removeFromTopRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeFromTopRequest),
      switchMap(({id}) => this.headerService.removeFromTopQuickLinks(id).pipe(
        map((quickLink) => fromActions.removeFromTopSuccess({quickLink})),
        catchError(() => of(fromActions.removeFromTopFailure()))
      ))
    )
  );

  public openRenameQuickLinkDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openRenameQuickLinkDialog),
      map(({id, name}) => {
        const data: RenameDialogData = {
          title: 'Rename QuickLink',
          oldName: name,
          controlLabel: 'Name of the QuickLink',
          confirmButtonLabel: 'Rename QuickLink',
          required: true,
          maxLength: 100,
        }
        const dialog = this.dialogService.open(RenameDialogComponent, {
          size: 'l',
          data
        });
        return ({id, name, dialog})
      }),
      switchMap(({id, name, dialog}) => dialog.afterClosed().pipe(
        filter(data => !!data),
        map((title) => ({id, title}))
      )),
      switchMap(({id, title}) => this.headerService.renameQuickLink(id, title).pipe(
        map((quickLink) => fromActions.renameQuickLinkSuccess({quickLink})),
        catchError(() => of(fromActions.renameQuickLinkFailure()))
      ))
    ), {dispatch: true}
  );

  public closeDeleteMultipleQuickLinks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.closeDeleteMultipleQuickLinks),
      map(() => fromActions.clearSelectedQuickLinkToDelete()))
  );
  public openQuickLinksContainer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.toggleHeaderQuickLinks),
      map(() => fromActions.quicklinksTabOpened()))
  );

  public loadDataIfEmpty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.toggleHeaderQuickLinks),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.selectAllQuickLinks))
      ),
      first(([action, ql]) => ql.length === 0),
      mergeMap(() => [
        fromActions.getMyQuickLinks(),
        fromCatalogs.getCategoriesRequest(),
        fromCatalogs.loadDefaultTaxonomyFilter(),
        fromCategoriesFlat.getCategoriesFlatRequest(),
        fromUser.getUserPersonalizationPreferences()
      ])
    ));

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private headerService: HeaderService,
    private dialogService: RdsDialogService
  ) {
  }
}
