<rh-template-card
[title]="templateConfiguration?.displayName"
[description]="templateConfiguration?.description"
[image]="imagePreview"
[size]="size"
>
<ng-container *ngIf="!imagePreview" heading>
  <div>
    <p *ngIf="!!templateConfiguration.id" class="ui-label-m" >
      Continue working on your draft
    </p>
  </div>
</ng-container>
<button *ngIf="!hideActions && !templateConfiguration.isDraftColumn" rds-secondary-button [size]="size" (click)="useTemplate()">Use template</button>
<button *ngIf="!hideActions && !templateConfiguration.isDraftColumn && templateConfiguration.templateId !== 'NewsletterTemplateInlineEditor'" rds-text-button [size]="size" (click)="showPreview(templateConfiguration.templateId)">Preview</button>
<button *ngIf="!hideActions && templateConfiguration.isDraftColumn && templateConfiguration.id" rds-secondary-button [size]="size" (click)="useTemplate()">Edit draft</button>

</rh-template-card>
