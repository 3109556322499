import { createAction, props } from '@ngrx/store';
import { Pagination, TickerNewsListModel } from '@core/models';
import { SortDirection } from '@rds/angular-components';
import { TickerTableFilters } from '@app/shared/filters/models/ticker-table';
import { BackButton } from '@app/root-store/ui/back-button';


export const initTable = createAction(
  '[TICKERS] Initialize Tickers'
);

export const clearAll = createAction(
  '[TICKERS] Clear all'
);

export const getTableRequest = createAction(
  '[TICKERS] Get tickers Request',
  props<{
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
    filters: {[key: string]: any},
  }>()
);

export const getTableSuccess = createAction(
  '[TICKERS] Get tickers Success',
  props<{ data: Array<TickerNewsListModel>, pagination: Pagination }>()
);

export const getTableFailure = createAction(
  '[TICKERS] Get tickers Failure',
  props<{ error: any }>()
);

export const changeTableFilters = createAction(
  '[TICKERS] Change tickers filters',
  props<{ filters: TickerTableFilters }>()
);

export const changeTableSort = createAction(
  '[TICKERS] Change tickers sort',
  props<{ active: string, direction: SortDirection }>()
);

export const changeTablePagination = createAction(
  '[TICKERS] Change tickers pagination',
  props<{ pageIndex: number, pageSize: number }>()
);

export const openNewsPreview = createAction(
  '[TICKERS] Open news preview',
  props<{ id: number, backButton: BackButton}>()
);

export const deleteTickerRequest = createAction(
  '[TICKERS] delete ticker request',
  props<{ id: number }>()
);

export const deleteTickerSuccess = createAction(
  '[TICKERS] delete ticker success'
);

export const deleteTickerFailure = createAction(
  '[TICKERS] delete ticker failure',
  props<{ message: string }>()
);
