import { RhEvent } from '@app/events/models/event';
import {createAction, props} from '@ngrx/store';

export const getIncomingEvents = createAction(
  '[EVENTS - GCAL] get incoming events '
);

export const getIncomingEventsSuccess = createAction(
  '[EVENTS - GCAL] get incoming events success',
  props<{ events: Array<RhEvent>}>()
);

export const getIncomingEventsFailure = createAction(
  '[EVENTS - GCAL] get incoming events failure'
);

export const addEventToGcal = createAction(
  '[EVENTS - GCAL] add event to gcal ',
  props<{ event: Partial<RhEvent>}>()
);

export const addEventToGcalSuccess = createAction(
  '[EVENTS - GCAL] add event to gcal success',
  props<{ event: Partial<RhEvent>}>()
);

export const addEventToGcalFailure = createAction(
  '[EVENTS - GCAL] add event to gcal failure',
  props<{ event: Partial<RhEvent>}>()
);
