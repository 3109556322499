import { Component, Inject } from '@angular/core';
import { Alert } from '@app/core/models/alerts.model';
import { RDS_DIALOG_DATA } from '@rds/angular-components';

export interface ConfirmAlertDialogData {
  alert: Alert;
}

@Component({
  selector: 'rh-confirm-alert-dialog',
  templateUrl: './confirm-alert-dialog.container.html',
  styleUrls: ['./confirm-alert-dialog.container.scss']
})
export class ConfirmAlertDialogContainer {
  public alert: Alert;

  constructor(
    @Inject(RDS_DIALOG_DATA) public data: ConfirmAlertDialogData
  ) {
    if (!!data) {
      this.alert = this.data.alert;
    }
  }

}
