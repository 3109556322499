import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/core-store/core.reducer';
import * as fromReducer from '@core/core-store/user-photo/user-photo.reducer';

export const selectUserPhotoState = createSelector(
  fromCore.selectState,
  fromCore.getUserPhoto
);

export const selectAll = createSelector(
  selectUserPhotoState,
  fromReducer.getAll
);

export const selectEntities = createSelector(
  selectUserPhotoState,
  fromReducer.getEntities
);

export const selectEntityById = createSelector(
  selectEntities,
  fromReducer.getEntityById
);

export const selectEntitiesById = createSelector(
  selectEntities,
  fromReducer.getEntitiesById
);
