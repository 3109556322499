import {Action, createFeatureSelector, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as fromActions from './grid.actions';
import * as fromFiltersActions from '../filters/filters.actions';

import { Pagination } from '@app/core/models';
import { RhEvent } from '@app/events/models/event';
import { LoadingState } from '@app/search/models/rh';

export const feature = 'grid';

export const adapter: EntityAdapter<Partial<RhEvent>> =
  createEntityAdapter<Partial<RhEvent>>({
    selectId: (e) => e.id
});

export interface State {
  events: EntityState<Partial<RhEvent>>,
  pagination: Pagination,
  loading: LoadingState,
}

export const initialState: State = {
  events: adapter.getInitialState(),
  pagination: {
    ...Pagination,
    pageIndex: 0,
    pageSize: 12,
  },
  loading: LoadingState.LOADING_ALL
};

export const gridReducer = createReducer(
  initialState,
  on(fromActions.getGridEventsSuccess, (state, {events, pagination}) => ({
    ...state,
    events: adapter.addMany(events, state.events),
    pagination,
    loading: LoadingState.FINISHED
  })),
    on(fromActions.getGridEventsFailure, (state) => ({
    ...state,
    loading: LoadingState.FINISHED
  })),
  on(fromActions.loadMoreEvents, (state) => ({
    ...state,
    pagination: {
      ...state.pagination,
      pageIndex: ++state.pagination.pageIndex
    },
    loading: LoadingState.LOADING_MORE
  })),
  on(
    fromFiltersActions.setRange,
    fromFiltersActions.setSearch,
    fromFiltersActions.filtersChanged, (state) => ({
      ...state,
      events: adapter.removeAll(state.events),
      pagination: {
        ...state.pagination,
        pageIndex: 0
      },
      loading: LoadingState.LOADING_ALL
    })) 
);

export function reducer(state: State | undefined, action: Action) {
  return gridReducer(state, action);
}

export const selectState = createFeatureSelector<State>(feature);

export const getEvents = (state: State) => adapter.getSelectors().selectAll(state.events);

export const getPagination = (state: State) => state.pagination;
export const getPageIndexSize = (pagination: Pagination) => ({pageIndex: pagination.pageIndex, pageSize: pagination.pageSize});;

export const getLoading = (state: State) => state.loading;
