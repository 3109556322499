import { createFeatureSelector, Action, combineReducers, createReducer, on } from '@ngrx/store';

import * as fromRhSearch from './rh-search/rh-search.reducer';
import * as fromCloudSearch from './cloud-search/cloud-search.reducer';
import * as fromActions from './search.actions';

export const feature = 'search';

export interface State {
  rhSearch: fromRhSearch.State;
  cloudSearch: fromCloudSearch.State;
  search: SearchState
}

export interface SearchState {
  phrase: string;
}

export const initialState: SearchState = {
  phrase: ''
};

export const searchReducer = createReducer(
  initialState,
  on(fromActions.setPhrase, (state, {phrase}) => ({
    ...state,
    phrase
  })),
  on(fromActions.clearSearch, (state) => ({
    ...initialState
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return combineReducers({
    rhSearch: fromRhSearch.reducer,
    cloudSearch: fromCloudSearch.reducer,
    search: searchReducer
  })(state, action);
}

export const selectState = createFeatureSelector<State>(feature);

export const getRhSearch = (state: State) => state.rhSearch;
export const getCloudSearch = (state: State) => state.cloudSearch;
export const getSearch = (state: State) => state.search;
export const getPhrase = (searchState: SearchState) => searchState.phrase;
