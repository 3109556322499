<rds-menu #menu backdrop="transparent" [class]="'dynamic-menu-' + triggerIndex" size="l">
  <div>
    <ng-container *ngFor="let link of links">
      <ng-container *ngIf="!link.nodes; else branch">
        <a rds-menu-item size="l" *ngIf="link.url" [href]="link.url" target="blank">
          {{link.link}}
        </a>
        <button rds-menu-item size="l" *ngIf="link.filter" (click)="openCatalog(link.filter)">
          {{link.link}}
        </button>
      </ng-container>
      <ng-template #branch>
        <button rds-menu-item size="l" #nestedTrigger="rdsMenuTrigger" [rdsMenuTriggerFor]="innerPanel.menu" [rdsMenuTriggerData]="link.link" (menuOpened)="toggleSubmenu()" (menuClosed)="toggleSubmenu()">{{link.link}}</button>
        <rh-header-menu #innerPanel [triggerIndex]="triggerIndex" [links]="link.nodes" [trigger]="nestedTrigger" (submenuToggled)="toggleSubmenu()"></rh-header-menu>
      </ng-template>
    </ng-container>
  </div>
</rds-menu>