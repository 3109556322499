import { Directive } from '@angular/core';
import { RDS_DATE_FORMATS } from '@rds/angular-components';
export const YEAR_FORMAT = {
	parse: {
		dateInput: null,
	},
	display: {
		dateInput: {
			year: 'numeric',
		},
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};
@Directive({
	selector: '[rhYearFormat]',
	providers: [{ provide: RDS_DATE_FORMATS, useValue: YEAR_FORMAT }],
})
export class YearFormatDirective {}

export const MONTH_FORMAT = {
	parse: {
		dateInput: null,
	},
	display: {
		dateInput: {
			month: 'long',
		},
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};
@Directive({
	selector: '[rhMonthFormat]',
	providers: [{ provide: RDS_DATE_FORMATS, useValue: MONTH_FORMAT }],
})
export class MonthFormatDirective {}

export const DAY_FORMAT = {
	parse: {
		dateInput: null,
	},
	display: {
		dateInput: {
			day: 'numeric',
			weekday: 'long',
		},
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};
@Directive({
	selector: '[rhDayFormat]',
	providers: [{ provide: RDS_DATE_FORMATS, useValue: DAY_FORMAT }],
})
export class DayFormatDirective {}
