<div class="preview" [ngClass]="form.color">
    <div class="preview__body preview__body--no-events">
        <ng-container *ngIf="form.rocheLogoInHeader">
            <div class="preview__logo">
                <img [src]="rocheLogo" width="58" height="30" alt=""/>
            </div>
        </ng-container>

        <div class="newsletter__heading">
            <ng-container *ngIf="form.titleIncluded">
                <h2 #title class="newsletter__heading--title">
                    {{form.title}}
                </h2>
            </ng-container>
            <ng-container *ngIf="form.bannerPhotoIncluded">
                <img *ngIf="form.bannerPhotoUrl" class="newsletter__heading--image" crossorigin="anonymous"
                     [src]="form.bannerPhotoUrl  + '?v=' + now"/>
                <div *ngIf="!form.bannerPhotoUrl" class="newsletter__heading--image__placeholder">
                    <rds-icon class="newsletter__heading--image__placeholder--icon" icon="upload"
                              namespace="outlined"></rds-icon>
                    <span class="newsletter__heading--image__placeholder--text">Select Image</span>
                </div>
            </ng-container>
        </div>

        <div *ngIf="sections" class="newsletter__blocks">
            <div id="sections" class="newsletter__drag-drop">
                <ng-container
                        *ngFor="let section of parsedMarkups; let sectionIndex = index; let sFirst = first; let sLast = last">
                    <div
                            [id]="section.id"
                            class="newsletter__drag-drop--section"
                            [class.single]="section.type === sectionTypes.SINGLE"
                            [class.two-equal]="section.type === sectionTypes.TWO_EQUAL"
                            [class.three-in-one]="section.type === sectionTypes.THREE_IN_ONE"
                            [class.one-in-three]="section.type === sectionTypes.ONE_IN_THREE"
                            [class.first]="sFirst"
                            [class.last]="sLast"
                    >
                        <div
                                *ngFor="let subsection of section.subsections; let ssFirst = first; let ssLast = last"
                                [id]="subsection.id"
                                [class.first]="ssFirst"
                                [class.last]="ssLast"
                                class="newsletter__drag-drop--subsection">
                            <ng-container *ngFor="let block of subsection.blocks; let blockIndex = index">
                                <!--                                {{block.markup | json}}-->
                                <div class="block-container block-container--full-width block-container--pure"
                                     tabindex="1" [innerHTML]="block?.markup"></div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <ng-container *ngIf="(form.feedbackIncluded) === true">
            <div class="preview__feedback">
                <h2 class="preview__feedback--title">
                    Send us your feedback!
                </h2>
                <p class="preview__feedback--info">
                    What would you like to read about? Do you have any ideas? <br/>
                    We would love to hear from you. <br/>
                    Reach us at: <a style="pointer-events: none;"
                                    href="mailto:group.comms-newsletter@roche.com">{{(form.contactEmailForFeedback) || 'Add a contact email'}}</a>
                </p>
            </div>
        </ng-container>
        <ng-container *ngIf="(form.disclaimerIncluded) === true">
            <div class="preview__disclaimer">
                <p>Official disclaimer:</p>
                <p>This newsletter was sent by {{(form.sentByName) || (form.senderName)}}. The information transmitted
                    in this message is
                    intended only for
                    the
                    person or entity to which it is addressed and may contain confidential and/or privileged material.
                    Any
                    review,
                    re-transmission dissemination or other use of, or taking of any action in reliance upon, this
                    information
                    by
                    persons or entities other than the intended recipient is prohibited. If you receive this message in
                    error,
                    please contact the sender and delete the material from any computer.</p>
            </div>
        </ng-container>

        <ng-container *ngIf="(form.disclaimerIncluded) === false">
            <div class="preview__disclaimer">
                <p>Sender disclaimer:</p>
                <p>This newsletter was sent by {{(form.sentByName) || (form.senderName)}}.</p>
            </div>
        </ng-container>
    </div>
</div>
