import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'firebase/auth';

@Component({
  selector: 'rh-user-autocomplete-selected',
  templateUrl: './user-autocomplete-selected.component.html',
  styleUrls: ['./user-autocomplete-selected.component.scss']
})
export class UserAutocompleteSelectedComponent implements OnInit {
  
  @Input() selectedOptions: Array<User> = [];
  @Input() disabled: boolean;
  @Input() checkRemovePrevented: Function = () => false;

  @Output() removed: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit(): void {
    console.log(this);
    console.log(this.checkRemovePrevented);
  }
}
