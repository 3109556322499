import { createSelector } from '@ngrx/store';

import * as fromChannel from '../channel.reducer';
import * as fromReducer from './channel-details.reducer';

export const selectState = createSelector(
  fromChannel.selectState,
  fromChannel.getChannelDetails
);

export const selectChannelDetails = createSelector(
  selectState,
  fromReducer.getDetails
);

export const selectChannelId = createSelector(
  selectChannelDetails,
  fromReducer.getChannelId
);

export const selectRoleInChannel = createSelector(
  selectChannelDetails,
  fromReducer.getRoleInChannel
);
// [ALL NEWS]
export const selectAllNewsState = createSelector(
  selectState,
  fromReducer.getAllNews
);

export const selectAllNewsRequestData = createSelector(
  selectAllNewsState,
  fromReducer.getRequestData
);

export const selectAllNewsData = createSelector(
  selectAllNewsState,
  fromReducer.getTableData
);

export const selectAllNewsPagination = createSelector(
  selectAllNewsState,
  fromReducer.getPagination
);

export const selectAllNewsTotal = createSelector(
  selectAllNewsPagination,
  fromReducer.getTotalCount
);

export const selectAllNewsSort = createSelector(
  selectAllNewsState,
  fromReducer.getSort
);

export const selectAllNewsDefaultSort = createSelector(
  selectAllNewsState,
  fromReducer.getDefaultSort
);

export const selectAllNewsFilters = createSelector(
  selectAllNewsState,
  fromReducer.getFilters
);

export const selectAllNewsDefaultFilters = createSelector(
  selectAllNewsState,
  fromReducer.getDefaultFilters
);

export const selectAllNewsSearch = createSelector(
  selectAllNewsFilters,
  fromReducer.getSearch
);

export const selectAllNewsIsLoading = createSelector(
  selectAllNewsState,
  fromReducer.getIsLoading
);


// [NEWS SUGGESTIONS]
export const selectNewsSuggestionsState = createSelector(
  selectState,
  fromReducer.getNewsSuggestions
);

export const selectNewsSuggestionsRequestData = createSelector(
  selectNewsSuggestionsState,
  fromReducer.getRequestData
);

export const selectNewsSuggestionsData = createSelector(
  selectNewsSuggestionsState,
  fromReducer.getTableData
);

export const selectNewsSuggestionsPagination = createSelector(
  selectNewsSuggestionsState,
  fromReducer.getPagination
);

export const selectNewsSuggestionsTotal = createSelector(
  selectNewsSuggestionsPagination,
  fromReducer.getTotalCount
);

export const selectNewsSuggestionsSort = createSelector(
  selectNewsSuggestionsState,
  fromReducer.getSort
);

export const selectNewsSuggestionsDefaultSort = createSelector(
  selectNewsSuggestionsState,
  fromReducer.getDefaultSort
);

export const selectNewsSuggestionsFilters = createSelector(
  selectNewsSuggestionsState,
  fromReducer.getFilters
);

export const selectNewsSuggestionsDefaultFilters = createSelector(
  selectNewsSuggestionsState,
  fromReducer.getDefaultFilters
);

export const selectNewsSuggestionsSearch = createSelector(
  selectNewsSuggestionsFilters,
  fromReducer.getSearch
);

export const selectNewsSuggestionsIsLoading = createSelector(
  selectNewsSuggestionsState,
  fromReducer.getIsLoading
);

// [ARCHIVED NEWS]
export const selectArchivedNewsState = createSelector(
  selectState,
  fromReducer.getArchivedNews
);

export const selectArchivedNewsRequestData = createSelector(
  selectArchivedNewsState,
  fromReducer.getRequestData
);

export const selectArchivedNewsData = createSelector(
  selectArchivedNewsState,
  fromReducer.getTableData
);

export const selectArchivedNewsPagination = createSelector(
  selectArchivedNewsState,
  fromReducer.getPagination
);

export const selectArchivedNewsTotal = createSelector(
  selectArchivedNewsPagination,
  fromReducer.getTotalCount
);

export const selectArchivedNewsSort = createSelector(
  selectArchivedNewsState,
  fromReducer.getSort
);

export const selectArchivedNewsDefaultSort = createSelector(
  selectArchivedNewsState,
  fromReducer.getDefaultSort
);

export const selectArchivedNewsFilters = createSelector(
  selectArchivedNewsState,
  fromReducer.getFilters
);

export const selectArchivedNewsDefaultFilters = createSelector(
  selectArchivedNewsState,
  fromReducer.getDefaultFilters
);

export const selectArchivedNewsSearch = createSelector(
  selectArchivedNewsFilters,
  fromReducer.getSearch
);

export const selectArchivedNewsIsLoading = createSelector(
  selectArchivedNewsState,
  fromReducer.getIsLoading
);