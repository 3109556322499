import { EventFilters } from '@app/core/models';
import { RhEventType } from '@app/events/models/event';
import {createAction, props} from '@ngrx/store';

export const setRange = createAction(
  '[EVENTS - FILTERS] set range ',
  props<{ start: Date, end: Date }>()
);

export const setSearch = createAction(
  '[EVENTS - FILTERS] set search ',
  props<{ search: string }>()
);

export const getSavedFilters = createAction(
  '[EVENTS - FILTERS] Get saved filters'
);

export const getSavedFiltersSuccess = createAction(
  '[EVENTS - FILTERS] Get saved filters success',
  props<{ filters: EventFilters }>()
);

export const getSavedFiltersFailure = createAction(
  '[EVENTS - FILTERS] Get saved filters failure'
);

export const saveFilters = createAction(
  '[EVENTS - FILTERS] Save filters',
  props<{ filters: {
    eventCategory: Array<RhEventType>,
    locations: Array<string>,
    departments: Array<string>,
    functions: Array<string>
  } }>()
);

export const saveFiltersSuccess = createAction(
  '[EVENTS - FILTERS] Save filters success',
  props<{ filters: EventFilters }>()
);

export const saveFiltersFailure = createAction(
  '[EVENTS - FILTERS] Save filters failure'
);

export const filtersChanged = createAction(
  '[EVENTS - FILTERS] Filters changed',
  props<{
    filters: {
      eventCategory: Array<RhEventType>,
      locations: Array<string>,
      departments: Array<string>,
      functions: Array<string>,
    }
  }>()
);

export const clearPreferences = createAction(
  '[EVENTS - FILTERS] Clear preferences'
);
export const clearFilters = createAction(
  '[EVENTS - FILTERS] Clear filters'
);
