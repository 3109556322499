import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiClient } from '@app/core/services/api-newsboard/api-client.service';
import { UserLogin } from '@app/core/models/newsboard';

@Injectable({
  providedIn: 'root'
})
export class NewsboardAuthApiService {
  
  constructor(private client: ApiClient) {

  }

  getUser(): Observable<UserLogin> {
    return this.client.get(`/user/current`)
  }
}