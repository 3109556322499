<div [class.desktop]="viewMode === 'desktop'" [class.mobile]="viewMode === 'mobile'"
  [class.bg-neutral-gradient-top-left]="!noBackground" class="news-content-preview ">
  <div class="container">
    <div class="row">
      <div class="news-column col-12 mb-10">
        <rh-news-header [viewMode]="viewMode" [isLanguageLoading]="false" [language]="language" [blockLike]="true"
          [blockBookmark]="true" [allLanguages]="languages" [title]="newsContent.title" [image]="newsContent.image?.url"
          [publicationDate]="news.publishDate" [author]="news.authors[0]" [authorName]="news.authors[0]?.name"
          [publisher]="news.bylineEmail" [publisherName]="news.byline" [numLikes]="0" [numComments]="0" [options]="{
          hasLiked: false,
          likeEnabled: true,
          commentsEnabled: true,
          commentsCanReply: true,
          hasBookmarked: false
        }" [languagesDict]="null" [dynamicLink]="null" [subscribedAuthors]="[]">
        </rh-news-header>
        <div class="row">
          <div class="col-12">
            <div *ngIf="!!newsContent.html" class="ck-content ck" [innerHTML]="innerHTML"></div>
            <div *ngIf="!newsContent.html" class="ck-content ck">
              <ng-container *ngTemplateOutlet="contentPlaceholder"></ng-container>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div *ngIf="!!news.websiteLinkName && !!news.websiteLinkUrl" class="row">
              <div class="col-12 mb-8">
                <p class="website-link">
                  <!-- ask rds to add gsite icon -->
                  <rds-icon namespace="filled" [icon]="websiteLinkIcon"></rds-icon>
                  <a rds-link href="news.websiteLinkUrl">{{news.websiteLinkName}} </a>
                </p>
              </div>
            </div>
            <div *ngIf="isEdit || channelName" class="row">
              <div class="col-12 mb-3 channels-col">
                <p class="ui-body-m--l m-0">Channels</p>
                <rds-chip-list size="s" class="p-2" type="action">
                  <rds-chip *ngIf="news.assignedChannels.length === 0" class="no-click placeholder"
                    [rdsTooltipDisabled]="news.assignedChannels.length !== 0"
                    [rdsTooltip]="'Assigned channels are not defined'" [disabled]="true">
                    <span>
                      Assigned channel
                    </span>
                  </rds-chip>
                  <rds-chip *ngIf="news.assignedChannels.length > 0">
                    <span>
                      {{channelName}}
                    </span>
                  </rds-chip>
                </rds-chip-list>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-3 topics-col">
                <p class="ui-body-m--l m-0">Topics</p>
                <rds-chip-list size="s" class="p-2" type="action">
                  <rds-chip *ngIf="news.topics.length === 0" class="no-click placeholder"
                    [rdsTooltipDisabled]="news.topics.length !== 0" [rdsTooltip]="'Topics are not defined'"
                    [disabled]="true">
                    <span>
                      Selected topic
                    </span>
                  </rds-chip>
                  <rds-chip *ngFor="let topic of news.topics" class="no-click">
                    <span>
                      {{topic}}
                    </span>
                  </rds-chip>
                </rds-chip-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
<ng-template #contentPlaceholder>
  <div class="placeholder" [rdsTooltip]="'Content is not defined'">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ut eros quis nibh ullamcorper vulputate a nec ante.
    Suspendisse interdum molestie ligula at consectetur. Curabitur nec gravida tellus. Morbi laoreet dignissim leo,
    vulputate vehicula lorem luctus eu. Sed eu nisl venenatis ipsum mollis viverra. Praesent porta nibh ac sollicitudin
    semper. Morbi facilisis sapien nisl, nec vulputate arcu malesuada nec. Nulla et nisl elit. Suspendisse ultrices
    scelerisque massa a scelerisque.
    Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Sed mi sapien, sollicitudin
    ac leo sit amet, pellentesque fermentum metus. Cras ut dolor gravida, pellentesque magna non, sollicitudin orci.
    Etiam imperdiet pharetra mi vel ullamcorper. Maecenas eleifend nec sapien sed venenatis. Pellentesque quis laoreet
    tortor. Praesent dignissim vitae ex ac pulvinar. Nam hendrerit ultricies ante. Morbi nisl tortor, vestibulum et
    molestie a, euismod eu odio. Donec feugiat leo mauris, ac rutrum libero bibendum sit amet. Sed metus enim, mattis at
    mauris non, volutpat ultrices orci. Nunc et accumsan ligula. Mauris in urna non tellus mollis porta.
  </div>
</ng-template>