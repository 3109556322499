import {Action, createFeatureSelector, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import * as fromActions from '@app/events/store/filters/filters.actions';
import {RhEventType} from '@app/events/models/event';

export const feature = 'filters';

export const adapter: EntityAdapter<any> =
  createEntityAdapter<any>();

export interface State {
  range: {
    start: Date,
    end: Date
  },
  search: string;
  filters: {
    locations: Array<string>;
    departments: Array<string>;
    functions: Array<string>;
    eventCategory: Array<RhEventType>;
  } | null,
  lastFilters: {
    locations: Array<string>;
    departments: Array<string>;
    functions: Array<string>;
    eventCategory: Array<RhEventType>;
  } | null,
  userDefaultFilters: {
    locations: Array<string>;
    departments: Array<string>;
    functions: Array<string>;
    eventCategory: Array<RhEventType>;
  } | null,
  savingProcessInProgress: boolean;
  userPreference: {
    locations: Array<string>;
  } | null,
}

export const initialState: State = {
  range: null,
  search: null,
  filters: null,
  lastFilters: null,
  userDefaultFilters: null,
  savingProcessInProgress: false,
  userPreference: null,
};

export const filtersReducer = createReducer(
  initialState,
  on(fromActions.setRange, (state, {start, end}) => ({
    ...state,
    range: {
      start,
      end
    }
  })),
  on(fromActions.setSearch, (state, {search}) => ({
    ...state,
    search
  })),
  on(fromActions.getSavedFiltersSuccess, (state, {filters}) => ({
    ...state,
    filters: filters.filter,
    userDefaultFilters: filters.filter,
    userPreference: filters.preferences
  })),
  on(fromActions.filtersChanged, (state, {filters}) => ({
    ...state,
    filters
  })),
  on(fromActions.saveFilters, (state) => ({
    ...state,
    savingProcessInProgress: true
  })),
  on(fromActions.saveFiltersSuccess, (state, {filters}) => ({
    ...state,
    userDefaultFilters: filters.filter
  })),
  on(fromActions.saveFiltersSuccess, fromActions.saveFiltersFailure, (state) => ({
    ...state,
    savingProcessInProgress: false
  })),
  on(fromActions.clearPreferences, (state) => ({
    ...state,
    filters: null,
    userDefaultFilters: null,
    userPreference: null
  })),
  on(fromActions.clearFilters, (state) => ({
    ...state,
    search: null,
    range: {
      start: null,
      end: null
    },
    filters: {...state.userDefaultFilters}
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return filtersReducer(state, action);
}

export const selectState = createFeatureSelector<State>(feature);
export const getRange = (state: State) => state.range;
export const getSearch = (state: State) => state.search;

export const getCurrentFilters = (state: State) => state.filters;
export const getUserPreference = (state: State) => state.userPreference;
export const getUserDefaultFilters = (state: State) => state.userDefaultFilters;
export const getFiltersAndPreference = (state: State) => ({
  filters: state.filters,
  preference: state.userPreference
});

export const getSavingProccessInProgress = (state: State) => state.savingProcessInProgress;
