<div *ngIf="!!websiteLinkName && !!websiteLinkUrl" class="row">
  <div class="col-12 mb-8">
    <p class="website-link">
      <rds-icon *ngIf="!isWebsiteLinkGsite" namespace="filled" [icon]="websiteLinkIcon"></rds-icon>
      <svg *ngIf="isWebsiteLinkGsite" width="24px" height="24px" fill="#7D0096" [innerHTML]="safeSvg"> </svg>
      <a rds-link standalone target="_blank" [href]="websiteLinkUrl">{{websiteLinkName}}</a>
    </p>
  </div>
</div>
<div *ngIf="!!channels" class="row">
  <div class="col-12 mb-3 channels-col">
    <p class="ui-body-l m-0">Channels</p>
    <rds-chip-list size="s" class="p-2" type="action">
      <rds-chip
        *ngFor="let channel of visibleChannels"
        (click)="channelChipsClick($event, channel.channelId)"
      >
        <span>
          {{channel.channelName}}
        </span>
      </rds-chip>
      <rds-chip
        *ngIf="hiddenChannels.length === 1"
        (click)="channelChipsClick($event, hiddenChannels[0].channelId)"
      >
        <span>
          {{hiddenChannels[0].channelName}}
        </span>
      </rds-chip>
      <rds-chip
        *ngIf="hiddenChannels.length > 1"
        [rdsMenuTriggerFor]="menu"
        #trigger="rdsMenuTrigger"
        class="reverse-chip-icon"
        [class.rds-chips__chip--selected]="trigger.menuOpen"
      >
      <rds-icon
        rds-chip-leading-icon
        [icon]="trigger.menuOpen ? 'chevron_up' : 'chevron_down'"
        namespace="outlined"
      ></rds-icon>
        <span>See more ({{hiddenChannels.length}})</span>

        <rds-menu #menu="rdsMenu">
          <button *ngFor="let channel of hiddenChannels" (click)="channelChipsClick($event, channel.channelId)" rds-menu-item>
            <span>{{channel.channelName}}</span>
          </button>
        </rds-menu>
      </rds-chip>
    </rds-chip-list>
  </div>
</div>
<div *ngIf="topics" class="row">
  <div class="col-12 mb-3 topics-col">
    <p class="ui-body-l m-0">Topics</p>
    <rds-chip-list size="s" class="p-2" type="action">
      <rds-chip
        *ngFor="let topic of topics"
        (click)="topicChipsClick($event, topic)"
      >
      <span>
        {{topic}}
      </span>
      </rds-chip>
    </rds-chip-list>
  </div>
</div>
