import {Action, createReducer, on} from '@ngrx/store';
import * as fromActions from '@app/newsletter/store/newsletter-inline/newsletter-inline.actions';
import { EMPTY_NEWSLETTER_INLINE, NewsletterInlineForm } from '@app/newsletter/models';
import { NewsletterEditType } from '@app/newsletter/models/newsletter-edit-type.enum';

export interface State {
  editType: NewsletterEditType;
  form: Partial<NewsletterInlineForm>;
  currentStep: number;
  steps: {
    0: {valid: boolean},
    1: {valid: boolean}
  };
  usersCount: number;
  isSaveProcessActive: boolean;
}

export const initialState: State = {
  editType: null,
  form: EMPTY_NEWSLETTER_INLINE,
  currentStep: 0,
  steps: {
    0: { valid: false },
    1: { valid: false }
  },
  usersCount: 0,
  isSaveProcessActive: false
};

export const newsletterInlineReducer = createReducer(
  initialState,
  on(fromActions.setStepFormValidity, (state, { step, validity }) => ({
    ...state,
    steps: {
      ...state.steps,
      [step]: {
        ...state.steps[step],
        valid: validity
      }
    }
  })),
  on(fromActions.prevStep, (state) => ({
    ...state,
    currentStep: state.currentStep === 0 ? state.currentStep : state.currentStep - 1
  })),
  on(fromActions.nextStep, (state) => ({
    ...state,
    currentStep: state.currentStep === 1 ? state.currentStep : state.currentStep + 1
  })),
  on(fromActions.setTitle, (state, {title}) => ({
    ...state,
    form: {
      ...state.form,
      title
    }
  })),
  on(fromActions.getEmailFromNewsletterSuccess, (state, {fromEmail}) => ({
    ...state,
    form: {
      ...state.form,
      from: fromEmail
    }
  })),
  on(fromActions.uploadBannerPhotoSuccess, (state, {bannerPhotoUrl, bannerPhotoSize, bannerPhotoName}) => ({
    ...state,
    form: {
      ...state.form,
      bannerPhotoName,
      bannerPhotoSize, bannerPhotoUrl
    }
  })),
  on(fromActions.setContactEmailForFeedback, (state, {contactEmailForFeedback}) => ({
    ...state,
    form: {
      ...state.form,
      contactEmailForFeedback
    }
  })),
  on(fromActions.setRocheLogoInHeader, (state, {rocheLogoInHeader}) => ({
    ...state,
    form: {
      ...state.form,
      rocheLogoInHeader
    }
  })),
  on(fromActions.setDisclaimerIncluded, (state, {disclaimerIncluded}) => ({
    ...state,
    form: {
      ...state.form,
      disclaimerIncluded
    }
  })),
  on(fromActions.setBannerPhotoIncluded, (state, {bannerPhotoIncluded}) => ({
    ...state,
    form: {
      ...state.form,
      bannerPhotoIncluded
    }
  })),
  on(fromActions.setTitleIncluded, (state, {titleIncluded}) => ({
    ...state,
    form: {
      ...state.form,
      titleIncluded
    }
  })),
  on(fromActions.setFeedbackIncluded, (state, {feedbackIncluded}) => ({
    ...state,
    form: {
      ...state.form,
      feedbackIncluded
    }
  })),
  on(fromActions.setSentByName, (state, {sentByName}) => ({
    ...state,
    form: {
      ...state.form,
      sentByName
    }
  })),
  on(fromActions.setSenderName, (state, {senderName}) => ({
    ...state,
    form: {
      ...state.form,
      senderName
    }
  })),
  on(fromActions.setRecipientType, (state, {recipientType}) => ({
    ...state,
    form: {
      ...state.form,
      recipientType
    }
  })),
  on(fromActions.setTaxonomyRecipient, (state, {taxonomyRecipient}) => ({
    ...state,
    form: {
      ...state.form,
      taxonomyRecipient
    }
  })),
  on(fromActions.setFromName, (state, {fromName}) => ({
    ...state,
    form: {
      ...state.form,
      fromName
    }
  })),
  on(fromActions.setReplyToName, (state, {replyToName}) => ({
    ...state,
    form: {
      ...state.form,
      replyToName
    }
  })),
  on(fromActions.setReplyTo, (state, {replyTo}) => ({
    ...state,
    form: {
      ...state.form,
      replyTo
    }
  })),
  on(fromActions.setSubject, (state, {subject}) => ({
    ...state,
    form: {
      ...state.form,
      subject
    }
  })),
  on(fromActions.setPreviewText, (state, {previewText}) => ({
    ...state,
    form: {
      ...state.form,
      previewText
    }
  })),
  on(fromActions.setTo, (state, {to}) => ({
    ...state,
    form: {
      ...state.form,
      to
    }
  })),
  on(fromActions.setToGroups, (state, {toGroups}) => ({
    ...state,
    form: {
      ...state.form,
      toGroups
    }
  })),
  on(fromActions.setScheduleSentDate, (state, {scheduleSentDate}) => ({
    ...state,
    form: {
      ...state.form,
      scheduleSentDate
    }
  })),
  on(fromActions.setTimeZone, (state, {timeZone}) => ({
    ...state,
    form: {
      ...state.form,
      timeZone
    }
  })),
  on(fromActions.setWhenToSend, (state, {whenToSend}) => ({
    ...state,
    form: {
      ...state.form,
      whenToSend
    }
  })),
  on(fromActions.setImagePreviewUrl, (state, {imagePreviewUrl}) => ({
    ...state,
    form: {
      ...state.form,
      imagePreviewUrl
    }
  })),
  on(fromActions.setColor, (state, {color}) => ({
    ...state,
    form: {
      ...state.form,
      color: color.label,
      colorLight: color.valueLight,
      colorDark: color.valueDark,
    }
  })),
  on(fromActions.clearData, (state) => ({
    ...initialState
  })),
  on(fromActions.setWholeForm, (state, { form }) => ({
    ...state,
    form: {
      ...form,
      senderName: form.sentByName
    }
  })),
  on(fromActions.putSearchingPreferencesCountSuccess, (state, {usersCount}) => ({
    ...state,
    usersCount
  })),
  on(fromActions.setEditType, (state, {editType}) => ({
    ...state,
    editType
  })),
  on(fromActions.startSaveAsDraftNewsletterProccess, fromActions.startSendNewsletterProccess, (state) => ({
    ...state,
    isSaveProcessActive: true
  })),
  on(fromActions.saveAsDraftSuccess,
    fromActions.updateAsDraftSuccess,
    fromActions.sendNewsletterSuccess,
    fromActions.saveAsDraftFailure,
    fromActions.updateAsDraftFailure,
    fromActions.sendNewsletterFailure,
    (state) => ({
    ...state,
    isSaveProcessActive: false
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return newsletterInlineReducer(state, action);
}

export const getForm = (state: State) => state.form;
export const getEditType = (state: State) => state.editType;
export const getTitle = (form: Partial<NewsletterInlineForm>) => form.title;
export const getContactEmailForFeedback = (form: Partial<NewsletterInlineForm>) => form.contactEmailForFeedback;
export const getRocheLogoInHeader = (form: Partial<NewsletterInlineForm>) => form.rocheLogoInHeader;
export const getDisclaimerIncluded = (form: Partial<NewsletterInlineForm>) => form.disclaimerIncluded;
export const getTitleIncluded = (form: Partial<NewsletterInlineForm>) => form.titleIncluded;
export const getFeedbackIncluded = (form: Partial<NewsletterInlineForm>) => form.feedbackIncluded;
export const getBannerPhotoIncluded = (form: Partial<NewsletterInlineForm>) => form.bannerPhotoIncluded;
export const getSentByName = (form: Partial<NewsletterInlineForm>) => form.sentByName;
export const getSenderName = (form: Partial<NewsletterInlineForm>) => form.senderName;
export const getColor = (form: Partial<NewsletterInlineForm>) => form.color;

export const getCurrentStep = (state: State) => state.currentStep;

