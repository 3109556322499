<atr-hero-card-swiper *ngIf="screenMode === 'DESKTOP'" (click)="openNews()">
  <img body-image class="grid-image" [src]="imageUrl || '/assets/images/news-placeholder.png'">
  <ng-container body-title>
    <p class="ui-heading-2-bold cursor-pointer">
      {{title}}
    </p>
  </ng-container>
  <ng-container body-channel>
    <div class="grid-header">
      <rds-badge [label]="channelName" [type]="'minimal'" [color]="badge" size="m"
                 (click)="openChannel($event)"></rds-badge>
      <div>
        <a href="/list/channel/{{channelId}}" rds-link class="channel-link" (click)="$event.stopPropagation()">
          <span>View all </span>
          <rds-icon icon="arrow_right" size="s" namespace="outlined"></rds-icon>
        </a>
      </div>
    </div>
  </ng-container>
  <ng-container body-description>
    <p class="ui-label-m-bold text-low-contrast">
      {{description}}
    </p>
  </ng-container>
  <ng-container body-liking>
    <div class="ui-component-text-m text-low-contrast text-uppercase liking-buttons">
      <span>  {{creationDate | timeAgo}}</span>
      <span class="like-counter">
             <rds-icon *ngIf="!hasLiked" class="result__button" icon="heart" size="s" namespace="outlined"
                       (click)="toggleLike($event)"></rds-icon>
      <rds-icon *ngIf="hasLiked" class="result__button" icon="heart" size="s" namespace="filled"
                (click)="toggleLike($event)"></rds-icon>
        {{likeCount}}
      </span>
    </div>
  </ng-container>
  <ng-container body-read-more>
    <div style="flex: 1">
      <button rds-secondary-button>
        Read more
      </button>
    </div>
  </ng-container>
</atr-hero-card-swiper>

<atr-hero-card-swiper-mobile *ngIf="screenMode === 'MOBILE'" (click)="openNews()">
  <img body-image class="grid-image" [src]="imageUrl || '/assets/images/news-placeholder.png'">
  <ng-container body-title>
    <p class="ui-heading-2-bold cursor-pointer">
      {{title}}
    </p>
  </ng-container>
  <ng-container body-channel>
    <div class="grid-header">
      <rds-badge [label]="channelName | uppercase" [type]="'minimal'" [color]="badge" size="m"
                 (click)="openChannel($event)"></rds-badge>
      <div>
        <a href="/list/channel/{{channelId}}" rds-link class="channel-link">
          <span>View all </span>
          <rds-icon icon="arrow_right" size="s" namespace="outlined"></rds-icon>
        </a>
      </div>
    </div>
  </ng-container>
  <ng-container body-description>
    <p class="ui-label-l-bold text-low-contrast">
      {{description}}
    </p>
  </ng-container>
  <ng-container body-liking>
    <div class="ui-component-text-m text-low-contrast text-uppercase liking-buttons">
      <span>  {{creationDate | timeAgo}}</span>
      <span class="like-counter">
             <rds-icon *ngIf="!hasLiked" class="result__button" icon="heart" size="s" namespace="outlined"
                       (click)="toggleLike($event)"></rds-icon>
      <rds-icon *ngIf="hasLiked" class="result__button" icon="heart" size="s" namespace="filled"
                (click)="toggleLike($event)"></rds-icon>
        {{likeCount}}
      </span>
    </div>
  </ng-container>
  <ng-container body-read-more>
    <div style="flex: 1">
      <button rds-secondary-button>
        Read more
      </button>
    </div>
  </ng-container>
</atr-hero-card-swiper-mobile>


<atr-hero-card-swiper-tablet *ngIf="screenMode === 'TABLET'" (click)="openNews()">
  <img body-image class="grid-image" [src]="imageUrl || '/assets/images/news-placeholder.png'">
  <ng-container body-title>
    <p class="ui-heading-2-bold cursor-pointer">
      {{title}}
    </p>
  </ng-container>
  <ng-container body-channel>
    <div class="grid-header">
      <rds-badge [label]="channelName | uppercase" [type]="'minimal'" [color]="badge" size="m"
                 (click)="openChannel($event)"></rds-badge>
      <div>
        <a href="#" rds-link (click)="openChannel($event)" class="channel-link">
          <span>View all </span>
          <rds-icon icon="arrow_right" size="s" namespace="outlined"></rds-icon>
        </a>
      </div>
    </div>
  </ng-container>
  <ng-container body-description>
    <p class="ui-label-l-bold text-low-contrast">
      {{description}}
    </p>
  </ng-container>
  <ng-container body-liking>
    <div class="ui-component-text-m text-low-contrast text-uppercase liking-buttons">
      <span>  {{creationDate | timeAgo}}</span>
      <span class="like-counter">
             <rds-icon *ngIf="!hasLiked" class="result__button" icon="heart" size="s" namespace="outlined"
                       (click)="toggleLike($event)"></rds-icon>
      <rds-icon *ngIf="hasLiked" class="result__button" icon="heart" size="s" namespace="filled"
                (click)="toggleLike($event)"></rds-icon>
        {{likeCount}}
      </span>
    </div>
  </ng-container>
  <ng-container body-read-more>
    <div style="flex: 1">
      <button rds-secondary-button>
        Read more
      </button>
    </div>
  </ng-container>
</atr-hero-card-swiper-tablet>
