import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {EndpointsService} from './endpoints/endpoints.service';
import {ChannelNotificationSettings, GlobalNotificationSettings} from '../models/notifications.model';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class MessagingService {
    constructor(
        private http: ApiService,
        private endpoints: EndpointsService,
    ) {
    }

    registerToken(token: string): Observable<boolean> {
        const url = this.endpoints.ENDPOINT.USER_NOTIFICATION.PUT;

        const body = {
            token,
            timeZone: Math.floor((new Date().getTimezoneOffset() / 60) * -1)
        };

        return this.http.put(url, body).pipe(
            catchError((fault) => throwError(`Cannot register token. ${fault} `))
        );
    }

    getNotificationSettings(): Observable<GlobalNotificationSettings> {
        const url = this.endpoints.ENDPOINT.USER_NOTIFICATION.SETTINGS.GET;

    return this.http.get(url).pipe(
      map(({channelSettings, notificationsEnabled}) => ({
        channelSettings: channelSettings.filter(c => !!c.name).sort((a, b) => a?.name > b?.name ? 1 : a?.name < b?.name ? -1 : 0),
        notificationsEnabled
      }))
    )
  }

    putNotificationSettings({notificationsEnabled}): Observable<GlobalNotificationSettings> {
        const url = this.endpoints.ENDPOINT.USER_NOTIFICATION.SETTINGS.PUT;

    return this.http.put(url, {notificationsEnabled}).pipe(
      map(({channelSettings, notificationsEnabled}) => ({
        channelSettings: channelSettings.filter(c => !!c.name).sort((a,b) => a.name > b.name ? 1 : a.name < b.name ? - 1 : 0),
        notificationsEnabled
      }))
    );
  }

    putResetChannelsSettings(): Observable<GlobalNotificationSettings> {
        const url = this.endpoints.ENDPOINT.USER_NOTIFICATION.SETTINGS.RESET;

    return this.http.put(url).pipe(
      map(({channelSettings, notificationsEnabled}) => ({
        channelSettings: channelSettings.filter(c => !!c.name).sort((a,b) => a.name > b.name ? 1 : a.name < b.name ? - 1 : 0),
        notificationsEnabled
      }))
    );
  }

    putSaveChannelsSettings(channelsSettings: Array<ChannelNotificationSettings>): Observable<Array<ChannelNotificationSettings>> {
        const url = this.endpoints.ENDPOINT.USER_NOTIFICATION.SETTINGS.CHANNELS;

    return this.http.put(url, channelsSettings).pipe(
      map((channelSettings) => channelSettings.filter(c => !!c.name).sort((a,b) => a.name > b.name ? 1 : a.name < b.name ? - 1 : 0))
    );
  }
}
