<rh-card *ngIf="result">
  <ng-container body-title>
    <p class="ui-label-l-bold text-low-contrast text-truncate">
      <span class="cursor-pointer">
        <rds-badge *ngIf="result" [label]="result.name"
                   [class.placeholder]="!result.name" [type]="'minimal'" (click)="open($event)"
                   [color]="'neutral'" #tooltip="rdsTooltip"
                   [rdsTooltip]="result.name" size="m"
                   rhIsOverflowing #overflow="rhIsOverflowing" [rdsTooltipDisabled]="!overflow.overflowingX"
        ></rds-badge>
      </span>
    </p>
  </ng-container>
  <ng-container body-description>
    <p class="ui-component-text-m text-low-contrast">
      {{result.snippet}}
    </p>
  </ng-container>
  <ng-container footer>
    <p class="ui-component-text-s text-low-contrast">
      <ng-container *ngIf="result.lastNewsPublishDate">
        Last news: <span
        class="text-uppercase">{{(result.lastNewsPublishDate | timeAgo) + '|'}}</span>
      </ng-container>
      {{'All news: ' + result.numberOfNews}}
    </p>
    <rds-icon *ngIf="!result.hasSubscribed" class="result__button" icon="add_circle" namespace="outlined"
              (click)="toggleSubscriptionClick()"
              rdsTooltip="Subscribe to the channel"
              [rdsTooltipShowDelay]="300"
    ></rds-icon>
    <rds-icon *ngIf="result.hasSubscribed" class="result__button result__button--inactive" icon="checkmark_circle"
              (click)="toggleSubscriptionClick()"
              namespace="filled"
              rdsTooltip="Unsubscribe from the channel"
              [rdsTooltipShowDelay]="300"></rds-icon>
  </ng-container>
</rh-card>
