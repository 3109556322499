import { CampaignTableModel, SentRecipientsListModel } from '@app/core/models/newsletter.model';
import { RhRecipientsListForm } from '@app/newsletter-new/models/recipients-list';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const mock = createActionGroup({
  source: 'NEWSLETTER MOCK',
  events: {
    init: emptyProps(),
  }
})

export const campaign = createActionGroup({
  source: 'MOCK CAMPAIGN',
  events: {
    delete: props<{id: number}>(),
    create: props<{data: CampaignTableModel}>(),
    edit: props<{id: number, data: CampaignTableModel}>()
  }
});

export const recipientList = createActionGroup({
  source: 'MOCK RECIPIENT LIST',
  events: {
    delete: props<{id: number}>(),
    create: props<{data: Partial<RhRecipientsListForm>}>(),
    edit: props<{id: number, data: Partial<RhRecipientsListForm>}>()
  }
});

export const recipientListSentToMe = createActionGroup({
  source: 'MOCK RECIPIENT LIST SENT TO ME',
  events: {
    accept: props<{id: number}>(),
    reject: props<{id: number}>(),
    recieve: props<{data: SentRecipientsListModel}>()
  }
});