import { createReducer, Action, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';

import { UserChannel } from '@core/models/user-channel.model';
import * as fromActions from '@core/core-store/user-channels/user-channels.actions';

export interface State extends EntityState<UserChannel> {}

export function sortByOrder(a: UserChannel, b: UserChannel): number {
  return a.order - b.order;
}

export const adapter: EntityAdapter<UserChannel> =
  createEntityAdapter<UserChannel>({
    sortComparer: sortByOrder,
  });

export const initialState: State = {
  ...adapter.getInitialState(),
};

const userChannelsReducer = createReducer(
  initialState,
  on(fromActions.userChannelsCollectionUpsertAll, (state, {channels}) => {
    return adapter.setAll(channels, state);
  }),
  on(fromActions.userChannelsCollectionUpsertMany, (state, {channels}) => (
    adapter.upsertMany(channels, state)
  )),
);

export function reducer(state: State | undefined, action: Action) {
  return userChannelsReducer(state, action);
}

export const getIds = adapter.getSelectors().selectIds;
export const getEntities = adapter.getSelectors().selectEntities;
export const getAll = adapter.getSelectors().selectAll;
export const getTotal = adapter.getSelectors().selectTotal;

export const getEntityById = (entities: Dictionary<UserChannel>, {id}) => entities[id];
export const getEntitiesById = (entities: Dictionary<UserChannel>, {ids}) => ids.map(id => entities[id]);

