<h2 rds-dialog-header>
    Notice on the use of personal and confidential data
</h2>
<div rds-dialog-icon-placeholder>
    <button rds-icon-button size="m" [rds-dialog-close]="false">
        <rds-icon icon="close" namespace="outlined"></rds-icon>
    </button>
</div>
<div rds-dialog-content>
    <p class="ui-label-m px-9">
        While establishing a newsletter be aware of the basic principles
        of data protection and please consider the following guidance rules with an appropriate level of care:
    </p>
    <ul class="ui-label-m px-9 mt-3">
        <li>
            Do not include personal or sensitive personal information that allows to identify
            an individual or to reveal person-specific criterias in the newsletter
            (e.g. data about the person’s economic status, data revealing
            racial or ethnic origin, political opinions, religious beliefs
            or data concerning mental and physical health, sex life
            or genetic and biometric data). Additionally,
            please refrain from publishing any confidential
            information in the newsletter that may not be made available to the public.
        </li>
        <li><p>To ensure compliance with all applicable laws and regulations, including
            data privacy laws when inserting images in the newsletter that have
            a potential risk to disclose personal or confidential information,
            it is recommended to use only images that can be found in the Roche Media Library. </p>

            <p class="mt-4">If you use pictures, quotes or other data of specific people as content
                for the newsletter, we recommend you to contact your local Data
                Protection Officer (DPO) or Data Privacy Coordinator (DPC)
                in order to assess and implement the specific requirements (e.g. consent). </p>
        </li>
        <li>If you have any privacy related questions while
            creating the content of the newsletter, please contact your local DPO or DPC.
        </li>
        <li>If you believe you have experienced an
            actual or potential breach, please report it immediately to your local DPO or DPC.
        </li>
    </ul>
</div>
<div rds-dialog-actions-container align="end">
    <button rds-secondary-button [rds-dialog-close]="false" size="m">
        Cancel
    </button>
    <button rds-primary-button [rds-dialog-close]="true" size="m">
        I have read and understood this guidance
    </button>
</div>
