import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArray'
})
export class FilterArrayPipe implements PipeTransform {

  transform(items: Array<any>, callback: (...a) => boolean, ...args: any): any {
    return items.filter(i => callback(i, args))
  }
}
