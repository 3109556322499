import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tile } from '@app/core/models/newsboard/dashboard';

@Component({
  selector: 'rh-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss' ]
})
export class TileComponent {
  @Input() size: 'small' | 'big';
  @Input() tile: Tile;
  @Input() shortcutPinned = null;

  @Output() dispatchAction: EventEmitter<any> = new EventEmitter();
  @Output() pin: EventEmitter<Tile> = new EventEmitter();
  @Output() unpin: EventEmitter<Tile> = new EventEmitter();

  onPin(tile, event) {
    event.stopPropagation();
    event.preventDefault();
    this.pin.emit(tile)
  }
  onUnpin(tile, event) {
    event.stopPropagation();
    event.preventDefault();
    this.unpin.emit(tile)
  }
}
