import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, Dictionary, EntityAdapter, EntityState} from '@ngrx/entity';

import {TaxonomyFlat} from '@core/models/taxonomy.model';
import * as fromActions from '@core/core-store/functions-flat/functions-flat.actions';

export interface State extends EntityState<TaxonomyFlat> {
}

export const adapter: EntityAdapter<TaxonomyFlat> =
    createEntityAdapter<TaxonomyFlat>({
        selectId: entity => entity.id
    });

export const initialState: State = {
    ...adapter.getInitialState(),
};

const functionsFlatReducer = createReducer(
    initialState,
    on(fromActions.functionsFlatCollectionAddMany, (state, {functionsFlat}) => (
        adapter.addMany(functionsFlat, state)
    )),
);

export function reducer(state: State | undefined, action: Action) {
    return functionsFlatReducer(state, action);
}

export const getIds = adapter.getSelectors().selectIds;
export const getEntities = adapter.getSelectors().selectEntities;
export const getAll = adapter.getSelectors().selectAll;
export const getAllActive = (taxonomies: Array<TaxonomyFlat>) => {
    const activeTaxo = taxonomies.filter(t => {
        const parent = taxonomies.find(p => !!t.parentId && p.id === t.parentId);
        return (t.isActive && parent && parent.isActive) || (t.isActive && t.parentId === null);
    });
    return activeTaxo;
};

export const getTotal = adapter.getSelectors().selectTotal;

export const getEntityById = (entities: Dictionary<TaxonomyFlat>, {id}) => entities[id];
export const getEntitiesById = (entities: Dictionary<TaxonomyFlat>, {ids}) => ids.map(id => entities[id]);
export const getRoot = (taxonomies: Array<TaxonomyFlat>) => taxonomies.filter((entity) => entity.parentId === null);
export const getRootId = (taxonomies: Array<TaxonomyFlat>) => taxonomies[0]?.id;
