<h2 rds-dialog-header>{{ data.title }}</h2>
<div rds-dialog-content [formGroup]="selectForm">
	<ng-container *ngFor="let select of data.selects; let last = last">
		<h4 *ngIf="select.label" class="ui-heading-4 mb-7">
			{{ select.label }}
			<ng-container *ngIf="select.tooltipInfo">
				<span [rdsTooltip]="tooltipTemplate">
					<rds-icon class="text-icon" icon="info" size="s" namespace="outlined"></rds-icon>
				</span>
				<ng-template #tooltipTemplate>
					<span style="text-align: left" [innerHTML]="select.tooltipInfo"></span>
				</ng-template>
			</ng-container>
		</h4>
		<p *ngIf="select.description" class="ui-body-s mb-5">{{ select.description }}</p>
		<rh-recipient-list-picker
			*ngIf="select.entityType === 'recipients-list'"
			class="mb-7"
			[multi]="true"
			[formControlName]="select.prop"
			[options]="select.optionsObs | async"
			[groupByPersonalizationSetting]="select.additionalInformation?.groupByPersonalizationSetting || false"
		>
		</rh-recipient-list-picker>
		<rh-autocomplete
			*ngIf="select.entityType === 'user'"
			uniqueKey="identifier"
			displayKey="email"
			label="Enter user name or email"
			[options]="select.prop | autocompletes"
			(search)="getAutocompletes(select.entityType, select.prop, $event)"
			[formControlName]="select.prop"
			[multi]="true"
			[itemTemplate]="itemTemplate"
			[selectedTemplate]="selectedTemplate"
		>
		</rh-autocomplete>
		<rh-channel-picker
			*ngIf="select.entityType === 'channel'"
			class="mb-7"
			[multi]="true"
			[formControlName]="select.prop"
			[options]="select.optionsObs | async"
		></rh-channel-picker>
		<div *ngIf="select.entityType === 'campaign-list'">
			<div class="row">
				<div class="col-4">
					<rds-form-field>
						<rds-control-label>Select Campaign</rds-control-label>
						<rds-select
							#langSelect
							[formControlName]="select.prop"
							(valueChange)="langSelect.resetFilterValue()"
							[filterBy]="filterByCampaign"
						>
							<rds-select-search-input #langSearchInput></rds-select-search-input>
							<rds-select-option-separator></rds-select-option-separator>
							<rds-select-option
								*ngFor="let option of select.options"
								[value]="option"
								[innerHTML]="option.name | highlight: langSearchInput.control.value"
							></rds-select-option>
						</rds-select>
					</rds-form-field>
				</div>
			</div>
		</div>
		<div *ngIf="select.entityType === 'language'">
			<div class="row">
				<div class="col-4">
					<rds-form-field>
						<rds-control-label>Select language</rds-control-label>
						<rds-select
							#langSelect
							[formControlName]="select.prop"
							(valueChange)="langSelect.resetFilterValue()"
							[filterBy]="filterBy"
						>
							<rds-select-search-input #langSearchInput></rds-select-search-input>
							<rds-select-option-separator></rds-select-option-separator>
							<rds-select-option
								*ngFor="let language of select.options"
								[value]="language.code"
								[id]="language.englishName"
								[innerHTML]="language.englishName | highlight: langSearchInput.control.value"
							></rds-select-option>
						</rds-select>
					</rds-form-field>
				</div>
			</div>
		</div>
		<rds-divider *ngIf="!last" class="mb-7"></rds-divider>
	</ng-container>
</div>
<div rds-dialog-actions-container align="end">
	<button rds-secondary-button [rds-dialog-close]="null" size="m">{{ data.cancelButtonLabel || 'Cancel' }}</button>
	<button rds-primary-button [rds-dialog-close]="data" [disabled]="selectForm.valid === false" size="m">
		{{ data.confirmButtonLabel || 'Confirm' }}
	</button>
</div>
