import { Injectable } from '@angular/core';
import { CatalogsService } from '@app/core/services/catalogs.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import * as fromActions from '@app/manage-sets/store/quicklinks-sets-form/quicklinks-sets-form.actions';
import * as fromReducer from '@app/manage-sets/store/quicklinks-sets-form/quicklinks-sets-form.reducer';
import * as fromSelectors from '@app/manage-sets/store/quicklinks-sets-form/quicklinks-sets-form.selectors';
import * as fromBackButton from '@app/root-store/ui/back-button';
import * as fromUser from '@app/core/user/store';

import { QuicklinksSetService } from '@app/core/services/quicklinks-set.service';
import { RdsDialogService } from '@rds/angular-components';
import { IntranetCoordinatorPreviewDialogComponent } from '@app/shared/dialogs/intranet-coordinator-preview-dialog/intranet-coordinator-preview-dialog.component';
import { CatalogResult, QuicklinksSetFormCatalogFilters } from '@app/core/models';

@Injectable()
export class QuicklinksSetsFormEffects {

public saveSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(
      fromActions.createQuicklinksSetSuccess,
      fromActions.updateQuicklinksSetSuccess,
      fromActions.deleteQuicklinksSetSuccess
    ),
    mergeMap(() => [
      fromActions.clearAll(),
      fromBackButton.back({defaultLabel: 'Manage sets', defaultRoute: 'manage-sets/quicklinks'})
    ]),
  ), { dispatch: true}
);

public createQuicklinksSetRequest$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.createQuicklinksSetRequest),
  withLatestFrom(this.store$.pipe(select(fromSelectors.selectFormForRequest))),
  switchMap(([action, form]) => this.quicklinksSetService.postQuicklinksSet(form).pipe(
    map((id) => ({form, id}))
  )),
  mergeMap(({form, id}) => [
    fromActions.createQuicklinksSetSuccess(),
  ])
), { dispatch: true}
);

public updateQuicklinksSetRequest$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.updateQuicklinksSetRequest),
  withLatestFrom(this.store$.pipe(select(fromSelectors.selectFormForRequest))),
  switchMap(([action, form]) => this.quicklinksSetService.putQuicklinksSet(form).pipe(
    map((id) => ({form, id}))
  )),
  mergeMap(({form, id}) => [
    fromActions.updateQuicklinksSetSuccess(),
  ])
), { dispatch: true}
);

public deleteQuicklinksSetRequest$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.deleteQuicklinksSetRequest),
  mergeMap(({id}) =>
    this.quicklinksSetService.deleteQuicklinksSet(id).pipe(
      mergeMap(() => [
        fromActions.deleteQuicklinksSetSuccess(),
      ]),
      catchError(({ message }) => of(fromActions.deleteQuicklinksSetFailure({error: message })))
    )
  )
), { dispatch: true }
);

public checkNameUnique$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.setFormValue),
    debounceTime(300),
    filter(({form}) => !!form.name),
    distinctUntilChanged((prev, next) => prev.form.name === next.form.name),
    map(({form}) => fromActions.isNameUniqueRequest({name: form.name, id: form.id}))
  ))

  public isNameUniqueRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.isNameUniqueRequest),
    withLatestFrom(this.store$.pipe(select(fromSelectors.selectInitialForm))),
    switchMap(([{name}, form]) => this.quicklinksSetService.getIsNameUnique(name).pipe(
      map((isUnique) => ({isUnique: isUnique || form.name === name}))
    )),
    map(({isUnique}) => fromActions.isNameUniqueSuccess({isUnique}))
  ), { dispatch: true}
  );

  public initCatalogsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initCatalogsFilters),
      switchMap(() => this.store$.pipe(
          select(fromSelectors.selectCatalogsFiltersForRequest),
          debounceTime(50),
          distinctUntilChanged((prev, next) => {
            return JSON.stringify(prev) === JSON.stringify(next)
          }),
          filter(({
            name,
            categories,
            contentTypes,
            departments,
            functions,
            locations}: QuicklinksSetFormCatalogFilters) => (name.length + categories.length + contentTypes.length + locations.length + departments.length + functions.length) >  0),
          map((filters) => fromActions.getFilteredCatalogsRequest({filters}))
        )
      ),
    ), { dispatch: true }
  );

  public clearCatalogsFilters$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.initCatalogsFilters),
    switchMap(() => this.store$.pipe(
        select(fromSelectors.selectCatalogsFiltersForRequest),
        debounceTime(50),
        distinctUntilChanged((prev, next) => {
          return JSON.stringify(prev) === JSON.stringify(next)
        }),
        filter(({
          name,
          categories,
          contentTypes,
          departments,
          functions,
          locations}: QuicklinksSetFormCatalogFilters) => (name.length + categories.length + contentTypes.length + locations.length + departments.length + functions.length) === 0),
        map(() => fromActions.clearFilteredCatalogs())
      )
    ),
  ), { dispatch: true }
);

  
  public getFilteredCatalogsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getFilteredCatalogsRequest),
      switchMap(({filters}) =>
          this.catalogsService.putCatalogSearchFiltered(filters).pipe(
          map((catalogs) => ({
            catalogs: catalogs.map((c): CatalogResult => ({
              id: c.id,
              name: c.name,
              isTop: false
            }))
          })),
          map(({catalogs}) => fromActions.getFilteredCatalogsSuccess({ catalogs })),
          catchError(({ message }) => of(fromActions.getFilteredCatalogsFailure({ error: message })))
        )
      )

    ), { dispatch: true}
  );
  
public openIntranetCoordinatorsDetailsDialog$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.openIntranetCoordinatorsDetailsDialog),
    withLatestFrom(
      this.store$.pipe(select(fromUser.selectUserProfile)),
      this.store$.pipe(select(fromSelectors.selectAudienceManagerInfo))
    ),
    tap(([action, user, audienceManagerInfo]) => this.dialogService.open(IntranetCoordinatorPreviewDialogComponent, {
      data: {
        user: {
          login: user.login,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          photoUrl: user.photoUrl
        },
        managedTaxonomies: {
          locations: audienceManagerInfo.managedTaxonomy.locations,
          departments: audienceManagerInfo.managedTaxonomy.departments,
          functions: audienceManagerInfo.managedTaxonomy.functions,
        }
      },
      size: 'l'
    }))
  ), { dispatch: false}
);

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private catalogsService: CatalogsService,
    private quicklinksSetService: QuicklinksSetService,
    private dialogService: RdsDialogService
  ) {}

}
