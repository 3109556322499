import { Component, Inject } from '@angular/core';
import { RDS_DIALOG_DATA, RdsDialogRef } from '@rds/angular-components';

export interface CropImageData {
  event?: Event,
  file?: File,
  aspectRatio?: number
}
@Component({
  selector: 'rh-crop-image-dialog',
  templateUrl: './crop-image-dialog.component.html',
  styles: []
})
export class CropImageDialogComponent {
  
  constructor(
    public dialogRef: RdsDialogRef<CropImageDialogComponent>,
    @Inject(RDS_DIALOG_DATA) public data: CropImageData
  ) {}

  confirm(croppedImage) {
    this.dialogRef.close(croppedImage);
  }
}
