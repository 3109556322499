<div tabindex="0" role="button" class="tile"  [class.tile__big]="size === 'big'" [class.tile__small]="size === 'small'" rnbBackButtonSet [label]="tile.backButton?.label" [routeBack]="tile.backButton?.routeBack" [routeCurrent]="tile.backButton?.routeCurrent"  (keydown.enter)="dispatchAction.emit(tile.action)" (click)="dispatchAction.emit(tile.action)">
  <rds-icon class="tile__icon" namespace="outlined" [icon]="tile.icon"></rds-icon>
  <div class="tile__text">
    <div class="tile__title">
      <h3 *ngIf="size === 'big'" class="tile__title--label">{{tile.title}}
        <ng-container *ngIf="!!tile.count">
          {{(tile.count | async) > 0 ? '('+(tile.count | async)+')' : ''}}
        </ng-container>
      </h3>
      <h4 *ngIf="size === 'small'"  class="tile__title--label">{{tile.title}}</h4>
      <rds-icon class="tile__title--arrow" namespace="outlined" icon="arrow_right"></rds-icon>
    </div>
    <p class="tile__description">{{tile.description}}</p>
  </div>
  <!-- <button *ngIf="shortcutPinned !== null" class="pin"  rdsTooltip="Add to Shortcuts" rds-icon-button (click)="shortcutPinned ? onUnpin(tile, $event) : onPin(tile, $event)">
    <rds-icon [namespace]="shortcutPinned ? 'filled' : 'outlined'" [icon]="shortcutPinned ? 'pinned' : 'pin'"></rds-icon>
  </button> -->
</div>
