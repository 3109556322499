import { Component } from '@angular/core';
import { ComponentsLoaderService } from '../components-loader.service';

@Component({
  selector: 'atr-home-event-list-loader',
  templateUrl: './home-event-list-loader.component.html',
  styleUrls: ['./home-event-list-loader.component.scss']
})
export class HomeEventListLoaderComponent {

  constructor(public componentsLoaderService: ComponentsLoaderService) {}
}
