<div>
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'DESKTOP'">
      <content-loader [baseUrl]="componentsLoaderService.baseUrl"
                      [backgroundColor]="'#EAE8E5'"
                      [foregroundColor]="'#DBD6D1'"
                      [viewBox]="'0 0 1512 350'"
                      [speed]="3"
                      [interval]="0.75"
                      [gradientRatio]="2.4">

        <svg:rect x="132" y="40" rx="0" width="492" height="222" />
        <svg:rect x="648" y="40" rx="0" width="150" height="20"/>
        <svg:rect x="1224" y="40" rx="0" width="150" height="20"/>
        <svg:rect x="648" y="74" rx="0" width="726" height="40"/>
        <svg:rect x="648" y="124" rx="0" width="726" height="96"/>
        <svg:rect x="648" y="236" rx="0" width="150" height="24"/>
        <svg:rect x="1224" y="236" rx="0" width="150" height="24"/>
        <svg:rect x="680" y="296" rx="0" width="150" height="24"/>
      </content-loader>
    </ng-container>
    <ng-container *ngSwitchCase="'TABLET'">
      <content-loader [baseUrl]="componentsLoaderService.baseUrl"
                      [backgroundColor]="'#EAE8E5'"
                      [foregroundColor]="'#DBD6D1'"
                      [viewBox]="'0 0 1023 310'"
                      [speed]="3"
                      [interval]="0.75"
                      [gradientRatio]="2.4">

        <svg:rect x="132" y="40" rx="0" width="492" height="222" />
        <svg:rect x="648" y="40" rx="0" width="150" height="20"/>
        <svg:rect x="1224" y="40" rx="0" width="150" height="20"/>
        <svg:rect x="648" y="74" rx="0" width="676" height="40"/>
        <svg:rect x="648" y="124" rx="0" width="676" height="96"/>
        <svg:rect x="648" y="236" rx="0" width="676" height="24"/>
        <svg:rect x="1224" y="236" rx="0" width="150" height="24"/>
        <svg:rect x="430" y="276" rx="0" width="150" height="24"/>
      </content-loader>
    </ng-container>
    <ng-container *ngSwitchCase="'MOBILE'">
      <content-loader [baseUrl]="componentsLoaderService.baseUrl"
                      [backgroundColor]="'#EAE8E5'"
                      [foregroundColor]="'#DBD6D1'"
                      [viewBox]="'0 0 767 555'"
                      [speed]="3"
                      [interval]="0.75"
                      [gradientRatio]="2.4">

        <svg:rect x="20" y="18" rx="0" width="150" height="26" />
        <svg:rect x="597" y="18" rx="0" width="150" height="26" />
        <svg:rect x="20" y="46" rx="0" width="727" height="179"/>
        <svg:rect x="20" y="234" rx="0" width="727" height="20"/>
        <svg:rect x="20" y="264" rx="0" width="727" height="80"/>
        <svg:rect x="20" y="360" rx="0" width="727" height="80"/>
        <svg:rect x="220" y="460" rx="0" width="327" height="30"/>
      </content-loader>
    </ng-container>
  </ng-container>
</div>

