import { NgModule, ModuleWithProviders } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducer } from './core.reducer';
import { CategoriesFlatEffects } from './categories-flat';
import { ContentItemsEffects } from '@core/core-store/content-items';
import { ContentTypesEffects } from './content-types';
import { DepartmentsFlatEffects } from './departments-flat';
import { FunctionsFlatEffects } from './functions-flat';
import { LocationsFlatEffects } from './locations-flat';
import { LanguagesNestedEffects } from './languages-dict';
import { UserChannelsEffects } from './user-channels';
import { ChannelsEffects } from './channels';
import { TimeZonesEffects } from './timezones';
import { ChannelsNewsEffects } from './channels-news';
import { UserPhotoEffects } from './user-photo';
import { EventsEffects } from './events';
import { ChannelsNewsAllLanguageEffects } from './channels-news-all-language';

const EFFECTS = [
  CategoriesFlatEffects,
  ContentItemsEffects,
  ContentTypesEffects,
  DepartmentsFlatEffects,
  FunctionsFlatEffects,
  LanguagesNestedEffects,
  LocationsFlatEffects,
  UserChannelsEffects,
  ChannelsEffects,
  TimeZonesEffects,
  ChannelsNewsEffects,
  ChannelsNewsAllLanguageEffects,
  UserPhotoEffects,
  EventsEffects
];

@NgModule({
  imports: [
      StoreModule.forFeature('core', reducer),
      EffectsModule.forFeature(EFFECTS),
  ],
  declarations: [],
  exports: [],
  providers: []
})

export class CoreStoreModule {
  static forRoot(): ModuleWithProviders<CoreStoreModule> {
    return {
      ngModule: CoreStoreModule,
      providers: []
    };
  }
}
