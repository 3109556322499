import {Component, Input} from '@angular/core';
import { ComponentsLoaderService } from '../components-loader.service';

@Component({
  selector: 'atr-label-loader',
  templateUrl: './label-loader.component.html',
  styleUrls: ['./label-loader.component.scss']
})
export class LabelLoaderComponent {

  @Input() viewBox =  '0 0 291 32';
  constructor(public componentsLoaderService: ComponentsLoaderService) {}
}
