import { Component, EventEmitter, Input, Output, OnInit, ViewChild, Optional, Host, SkipSelf, OnDestroy } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SimpleUser, User } from '@app/core/models/newsboard';
import { AutocompleteOptions } from '@app/root-store/suggestions';
import { CustomValidators } from '@app/shared/form-controls/validators/validator.function';
import { RdsAutocompleteComponent } from '@rds/angular-components';
import { debounceTime, distinctUntilChanged, filter, map, Observable } from 'rxjs';
import { SubSink } from 'subsink';

export interface PeopleRolePickerModel {
  [key: string]: {
    label: string;
    description: string;
    canEdit: boolean;
  }
}

@Component({
  selector: 'rh-people-role-picker',
  templateUrl: './people-role-picker.component.html',
  styleUrls: ['./people-role-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: PeopleRolePickerComponent
    }
  ]
})
export class PeopleRolePickerComponent implements OnInit, OnDestroy {
  private subs: SubSink = new SubSink();
  
  private _autocomplete: RdsAutocompleteComponent<User>;

  get autocomplete() {
    return this._autocomplete
  }
  @ViewChild('autocompleteRef', { read: RdsAutocompleteComponent, static: false}) set autocomplete(a: RdsAutocompleteComponent<User>) {
    if (!!a) {
      this._autocomplete = a;
      console.log(this.autocomplete);
      (a as any).inputEl.nativeElement.addEventListener('blur', (e) => {
        this.markAsTouched();
      });
    }
  };

  selectedUsers: {[key: string]: Array<SimpleUser>} = {};

  get selectedUsersMerged(): Array<SimpleUser> {
    return Object.keys(this.selectedUsers).reduce((acc, current) => [...acc, ...this.selectedUsers[current]], []).slice();
  }

  onChange = (users) => {
  };

  onTouched = () => {
  };

  touched = false;

  disabled = false;

  hostWidth: number;

  writeValue(suggestions: {[key: string]: Array<SimpleUser>}) {
    this.selectedUsers = suggestions;
    this.form.controls.search.updateValueAndValidity();
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  showRolesInOrder() {
    return 0
  }

  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() selected: EventEmitter<string> = new EventEmitter<string>();
  @Output() removed: EventEmitter<string> = new EventEmitter<string>();

  @Input() restrictedUsers: Array<SimpleUser> = [];
  @Input() formControl!: FormGroup;
  @Input() formControlName!: string;
  @Input() roles: PeopleRolePickerModel;
  @Input() defaultRole: string;
  @Input() readonly: boolean = false;
  @Input() options: Observable<AutocompleteOptions<User>>;

  get control() {
    return this.formControl || this.controlContainer.control?.get(this.formControlName);
  }

  form: FormGroup = new FormGroup({
    search: new FormControl('')
  });

  @Input() labelFn: (args: any) => string;
  @Input() notRemovable: Array<string> = [];

  canRemove(identifier) {
    return !this.notRemovable.includes(identifier);
  }

  enter(phrase: string) {
    setTimeout(() => {
      (this.autocomplete as any).focusChanged(false);
      (this.autocomplete as any).focusChanged(true);
    }, 100);
  }

  fetchOptions = (search: string) => {
    this.search.emit(search);
    return this.options.pipe(
      filter(({options, phrase, loading, isString}) => phrase === search && loading === false),
      map(({options}) => options.filter((o) => !this.selectedIncludesUser(o.identifier)))
    )
  }

  selectedIncludesUser(identifier: string) {
    return this.selectedUsersMerged.findIndex(a => a.identifier === identifier) > -1;
  }

  select(user: SimpleUser) {
    const userWithRoleAndOrder = {
      ...user,
      role: this.defaultRole,
      order: this.selectedUsersMerged.length
    }
    this.selectedUsers[this.defaultRole] = [...this.selectedUsers[this.defaultRole], userWithRoleAndOrder];
    this.selectedUsers = {
      ...this.selectedUsers,
      [this.defaultRole]: this.selectedUsers[this.defaultRole].slice()
    }
    this.form.controls.search.setValue('');
    this.selected.emit();
    this.onChange(this.selectedUsers);
    this.control.markAsTouched();
  }

  remove(user: SimpleUser) {
    this.selectedUsers = {
      ...this.selectedUsers,
      [user.role]: this.selectedUsers[user.role].filter(u => u.identifier !== user.identifier).slice(),
    };
    this.updateOrderAfterRemove(user.order);
    this.form.controls.search.setValue('');
    this.removed.emit();
    this.onChange(this.selectedUsers);
    this.control.markAsTouched();
  }

  updateOrderAfterRemove(removedOrder: number) {
    Object.keys(this.selectedUsers).forEach(role => {
      this.selectedUsers[role] = this.selectedUsers[role].map(u => ({
        ...u,
        order: u.order > removedOrder ? u.order - 1 : u.order
      })).slice()
    })
  }

  changeRole(user: SimpleUser, newRole: string) {
    if (user.role !== newRole) {
      this.selectedUsers[newRole] = [
        ...this.selectedUsers[newRole],
        {
          ...user,
          role: newRole
        }
      ].slice();
      
      this.selectedUsers = {
        ...this.selectedUsers,
        [user.role]: this.selectedUsers[user.role].filter(u => u.identifier !== user.identifier),
        [newRole]: this.selectedUsers[newRole].slice()
      }
      this.selected.emit();
      this.onChange(this.selectedUsers);
      this.control.markAsTouched();
    }
  }

  ngOnInit(): void {
    this.form.controls.search.setValidators(CustomValidators.isMasterControlValid(this.control));
    this.form.controls.search.updateValueAndValidity();

    const originalMarkAsTouched = this.control.markAsTouched;
    const that = this;
    this.control.markAsTouched = function() {
      originalMarkAsTouched.apply(this, arguments);
      that.form.controls.search.markAsTouched();
      that.form.controls.search.updateValueAndValidity();
    }

    this.subs.sink = this.control.statusChanges.pipe(debounceTime(100)).subscribe(status => {
      this.form.controls.search.updateValueAndValidity();
    });

    this.subs.sink = this.form.controls.search.valueChanges.pipe(distinctUntilChanged()).subscribe(s => this.search.emit(s));
    
    this.subs.sink = this.form.controls.search.valueChanges
      .pipe( 
        filter(item => !!item)
      )
      .subscribe(item => this.select(item))
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  constructor(    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer,) {
  }

}
