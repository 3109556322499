import {DOCUMENT} from '@angular/common';
import {Component, EventEmitter, Inject, Input, Output, ViewChild} from '@angular/core';
import {ContentItem} from '@app/core/models';
import {RdsCarouselComponent} from '@rds/angular-components';

@Component({
  selector: 'rh-news-related',
  templateUrl: './news-related.component.html',
  styleUrls: ['./news-related.component.scss']
})
export class NewsRelatedComponent {
  @ViewChild('carousel', {static: false}) carousel: RdsCarouselComponent;
  @Input() isAutoRelated: boolean;
  @Input() totalAutoRelated: number = 0;

  @Input() newsLoaded: boolean;
  @Input() relatedNews: Array<ContentItem>;
  @Input() relatedNewsIds: Array<string> | Array<number>;
  @Input() relatedNewsLoading: boolean;
  @Input() isLikingProcessingIds: Array<string>;
  @Output() loadMore: EventEmitter<any> = new EventEmitter();
  @Output() relatedLike: EventEmitter<{ hasLiked: boolean, newsId: string }> = new EventEmitter();
  @Output() relatedBookmark: EventEmitter<{ hasBookmarked: boolean, newsId: string }> = new EventEmitter();
  @Output() relatedOpen: EventEmitter<{ newTab: boolean, url: string, id: string, title: string }> = new EventEmitter();

  get slidesInRow() {
    const width = this.document.documentElement.clientWidth;
    if (width < 768) {
      return 1
    }
    if (width >= 768 && width < 1024) {
      return 2
    }
    if (width >= 1024 && width < 1440) {
      return 3
    }
    if (width >= 1440 && width < 1672) {
      return 4
    }
    if (width >= 1672) {
      return 4
    }
  }

  get itemsForLoaderRow() {
    let arr = [];
    for (let i = 0; i < this.slidesInRow; i++) {
      arr.push(i);
    }
    return arr
  }

  get slidesCount() {
    const cardsCount = this.isAutoRelated ? this.totalAutoRelated : this.relatedNews.length;
    return Math.ceil(cardsCount / this.slidesInRow);
  }

  currentSlideItems(slide: number, isActive = false) {
    return this.relatedNews.slice(this.slidesInRow * slide, this.slidesInRow * slide + this.slidesInRow);
  }

  loaderTilesCount(slide: number, isActive = false) {
    const isLastPageWithRelated = (this.slidesInRow * slide + this.slidesInRow) >= this.relatedNews.length;
    if (isActive && isLastPageWithRelated && this.isAutoRelated && !this.relatedNewsLoading) {
      this.loadMore.emit();
    }
    const allElements = this.isAutoRelated ? [...Array(this.totalAutoRelated || this.slidesInRow).keys()] : this.relatedNewsIds;
    return allElements.slice(this.slidesInRow * slide, this.slidesInRow * slide + this.slidesInRow);
  }

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  public replaceSpaces(title: string): string {
    try {
      return encodeURIComponent(title);
    } catch (e) {
      return '';
    }
  }

  trackByFn(index, item) {
    return index;
  }

  openNews({newTab, url, id, title}) {
    this.relatedOpen.emit({newTab, url, id, title});
  }

  toggleLike({hasLiked, newsId}) {
    this.relatedLike.emit({hasLiked, newsId})
  }

  toggleBookmark({hasBookmarked, newsId}) {
    this.relatedBookmark.emit({hasBookmarked, newsId})
  }

  showSlides() {
    for (let i = 0; i < this.slidesCount; i++) {
      console.log(this.currentSlideItems(i))
    }
  }

  prev() {
    this.carousel.prev();
  }

  next() {
    this.carousel.next();
  }

}
