import { FilterDefinition, FilterTypeEnum, SEARCH_FILTER, SelectFilterDefinition } from '../filters-model';

export interface NewsletterTableFilters {
	statuses?: SelectFilterDefinition;
	isContributed?: SelectFilterDefinition;
	search?: FilterDefinition;
}

export const NEWSLETTER_STATUS = [
	{ label: 'Draft', value: 0 },
	{ label: 'Sent', value: 1 },
	{ label: 'Scheduled', value: 2 },
];

export const NEWSLETTER_IS_CONTRIBUTED = [
	{ label: 'All', value: null },
	{ label: 'Yes', value: true },
	{ label: 'No', value: false },
];

export const INITIAL_NEWSLETTER_FILTERS: NewsletterTableFilters = {
	isContributed: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: null,
		label: 'Shared',
		multiple: false,
		options: NEWSLETTER_IS_CONTRIBUTED,
	},
	statuses: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: [],
		label: 'Status',
		multiple: true,
		options: NEWSLETTER_STATUS,
	},
	...SEARCH_FILTER,
};
