import { Component, EventEmitter, Host, HostBinding, Inject, Input, OnDestroy, OnInit, Optional, Output, SkipSelf } from '@angular/core';
import { RdsDialogService, RdsFileItem, RdsFileUploader } from '@rds/angular-components';
import { ControlContainer, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { NewsImage } from '@app/core/models/newsboard';
import { NewsboardAuthService } from '@app/core/auth/services/newsboard-auth.service';
import { CropImageDialogComponent } from '@app/shared/dialogs/crop-image-dialog/crop-image-dialog.component';
import { EndpointsService } from '@app/core/services/endpoints/endpoints.service';
import { MAX_FILE_UPLOAD_SIZE } from '@app/shared/utils/tokens';

@Component({
  selector: 'rh-upload',
  templateUrl: './upload.component.html',
  styleUrls: [ './upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: UploadComponent
    }
  ]
})
export class UploadComponent implements OnInit, OnDestroy {
  private subs: SubSink = new SubSink();
  file: NewsImage;
  @Input() id: string = 'upload';
  @Input() size: 's' | 'm' | 'l' = 'l';
  @Input() disabled: boolean;
  @Input() hasDropZone: boolean;
  @Input() aspectRatio = 16 / 9;
  @Input() formControl!: FormControl;
  @Input() formControlName!: string;
  @Input() buttonLabel: string;
  @Input() forceSkipCrop: boolean = false;
  @Input() allowRemove: boolean = true;

  uploader: RdsFileUploader

  private _imageStorage: 'newsboard' | 'event' | 'newsletter';

  @Input() set imageStorage(value: 'newsboard' | 'event' | 'newsletter') {
    this._imageStorage = value;
    
    switch (value) {
      case 'newsboard': {
        const headers: HttpHeaders = new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`});
        this.uploader = new RdsFileUploader({
          autoUpload: true,
          url: `${environment.apiBase}/uploadFile`,
          method: 'POST',
          headers: headers,
          removeAfterUpload: true,
          accept: ['image/*']
        }, this.httpClient);
        break;
      }
      case 'event': {
        const headers: HttpHeaders = new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`});
        this.uploader = new RdsFileUploader({
          autoUpload: true,
          url: `${this.endpoints.ENDPOINT.IMAGE.DIRECTORY_UPLOAD}`,
          method: 'POST',
          headers: headers,
          removeAfterUpload: true,
          accept: ['image/*']
        }, this.httpClient);
      }
      case 'newsletter': {
        const headers: HttpHeaders = new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`});
        this.uploader = new RdsFileUploader({
          autoUpload: true,
          url: `${this.endpoints.ENDPOINT.NEWSLETTER.POST_PHOTO}`,
          method: 'POST',
          headers: headers,
          removeAfterUpload: true,
          accept: ['image/*']
        }, this.httpClient);
      }
    }
  }

  get imageStorage() {
    return this._imageStorage;
  }

  @HostBinding('class') class = `rds-upload-size-${this.size}`;

  @Output() uploadStarted: EventEmitter<string> = new EventEmitter();
  @Output() uploadEnded: EventEmitter<string> = new EventEmitter();
  @Output() uploadAborted: EventEmitter<string> = new EventEmitter();
  @Output() uploadFailed: EventEmitter<string> = new EventEmitter();
  
  
  
  
  lastUploadedFile: RdsFileItem;
  
  onChange = (file) => {};

  onTouched = () => {};

  touched = false;

  writeValue(file: NewsImage) {
    this.file = file;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  clearControl() {
    this.onChange(null);
    this.file = null;
    this.markAsTouched();
  }

  clearQueue(fileItem) {
    this.uploader.removeFromQueue(fileItem);
  }

  get control() {
    return this.formControl || this.controlContainer.control?.get(this.formControlName);
  }
  
  get hasError() {
    return this.control?.status === 'INVALID' && this.control?.touched;
  }

  constructor(
    @Inject(MAX_FILE_UPLOAD_SIZE) private maxFileSize: {[type: string]: number},
    private endpoints: EndpointsService,
    private auth: NewsboardAuthService,
    private httpClient: HttpClient,
    private dialogService: RdsDialogService,
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer) {
  }
  ngOnInit(): void {
    this.subs.sink = this.uploader.fileItemError$.subscribe(res => {
      this.uploadFailed.emit(this.id);
    });
    this.subs.sink = this.uploader.fileItemCanceled$.subscribe(res => {
      this.uploadAborted.emit(this.id);
    });
    this.subs.sink = this.uploader.fileItemSuccess$.subscribe(res => {
      this.uploadEnded.emit(this.id);
      this.lastUploadedFile = res.item;
      switch(this.imageStorage) {
        case 'newsboard': {
          const {id, url} = res.event.body as {id: string, url: string};
          this.file = {
            id,
            url,
            name: res.item.file.name,
            size: res.item.file.size,
          }
          break;
        }
        case 'event': {
          const url = res.event.body as string;
          this.file = {
            id: this.id,
            url,
            name: res.item.file.name,
            size: res.item.file.size,
          }
          break;
        }
        case 'newsletter': {
          const url = res.event.body as string;
          this.file = {
            id: this.id,
            url,
            name: res.item.file.name,
            size: res.item.file.size,
          }
          break;
        }
      }

      this.onChange(this.file);
    })
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onFileSelected(event, skipCrop = false) {
    const file = event[0];

    this.uploader.updateConfig({
      accept: Object.keys(this.maxFileSize),
      maxFileSize: this.maxFileSize[file.type]
    });

    if (this.uploader.queue.length > 0) {
      this.uploader.queue.map((q) => this.uploader.removeFromQueue(q))
    }
    if (skipCrop || this.forceSkipCrop || this.isGif(file.type) || !this.isImage(file.type) || file.size > this.maxFileSize[file.type]) {
      this.uploader.addToQueue([file]);
      this.uploadStarted.emit(this.id);
    } else {
      const dialogRef = this.dialogService.open(CropImageDialogComponent, {
        size: 'l',
        data: {
          aspectRatio: this.aspectRatio,
          file
        }
      });
      this.subs.sink = dialogRef.afterClosed().subscribe((croppedImage) => {
        this.control.markAsTouched();
        if (!!croppedImage) {
          this.uploader.addToQueue([croppedImage]);
          this.uploadStarted.emit(this.id);
        }
      });
    }
  }

  isGif(type: string): boolean {
    return /(gif)/i.test(type);
  }

  isImage(type: string): boolean {
    return Object.keys(this.maxFileSize).includes(type);
  }
}
