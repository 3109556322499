import { FilterDefinition, FilterTypeEnum, SEARCH_FILTER, DictionaryFilterDefinition, DictionaryTypeEnum, SelectFilterDefinition, AdvancedFilterDefinition } from '../filters-model';

export interface AssignRoleTableFilters {
    roles: SelectFilterDefinition;
    advancedFilters: AdvancedFilterDefinition<AssignRoleTableAdvancedFilters>;
    search: FilterDefinition;
  }

  export interface AssignRoleTableAdvancedFilters {
    locations: DictionaryFilterDefinition;
    departments: DictionaryFilterDefinition;
    functions: DictionaryFilterDefinition;
  }
  export const ROLES = [
    { label: 'Admin', value:'isAdministrator' },
    { label: 'Intranet Coordinator', value:'isAudienceManager' },
    { label: 'Content Quality Manager', value:'isContentQualityManager' }
  ];

  export const INITIAL_ASSIGN_ROLES_FILTERS: AssignRoleTableFilters = {
    roles: {
      type: FilterTypeEnum.SELECT,
      changable: true,
      value: [],
      label: 'Role',
      multiple: true,
      options: ROLES
    },
    advancedFilters: {
      type: FilterTypeEnum.ADVANCED,
      changable: true,
      activeCount: 0,
      filters: {
        locations: {
          type: FilterTypeEnum.DICTIONARY,
          label: 'Locations',
          dictionary: DictionaryTypeEnum.LOCATIONS,
          changable: true,
          value: []
        },
        departments: {
          type: FilterTypeEnum.DICTIONARY,
          label: 'Departments',
          dictionary: DictionaryTypeEnum.DEPARTMENTS,
          changable: true,
          value: []
        },
        functions: {
          type: FilterTypeEnum.DICTIONARY,
          label: 'Functions',
          dictionary: DictionaryTypeEnum.FUNCTIONS,
          changable: true,
          value: []
        }
      }
    },
    ...SEARCH_FILTER
  }