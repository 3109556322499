import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './newsletter-blocks.actions';
import { BlockTypes } from '@app/newsletter-new/models/block-type.enum';
import { createGuid } from '@app/shared/utils/guid';
import { Section, SectionTypes, SubsectionTypes } from '@app/newsletter-new/models/section-type.enum';

export const feature = 'newsletter-blocks';
export interface State {
	connectedBlockList: Array<{ id: string; sectionType: SectionTypes; subsectionType: SubsectionTypes }>;
	sections: Array<Section>;
	isBlockDragging: boolean;
	draggingBlockType: BlockTypes;
	activeGuid: string;
}

export const initialState: State = {
	connectedBlockList: [],
	sections: [],
	isBlockDragging: false,
	draggingBlockType: null,
	activeGuid: null,
};

export const reducer = createReducer(
	initialState,
	on(fromActions.hoverBlockType, (state, { blockType }) => ({
		...state,
		draggingBlockType: state.isBlockDragging ? state.draggingBlockType : blockType,
	})),
	on(fromActions.unhoverBlockType, (state) => ({
		...state,
		draggingBlockType: state.isBlockDragging ? state.draggingBlockType : null,
	})),
	on(fromActions.activateItem, (state, { itemGuid }) => ({
		...state,
		activeGuid: itemGuid,
	})),
	on(fromActions.deactivateItem, (state, { itemGuid }) => ({
		...state,
		activeGuid: state.activeGuid === itemGuid ? null : state.activeGuid,
	})),
	on(fromActions.startBlockDrag, (state, { blockType }) => ({
		...state,
		isBlockDragging: true,
		draggingBlockType: blockType,
	})),
	on(fromActions.endBlockDrag, (state) => ({
		...state,
		isBlockDragging: false,
		draggingBlockType: null,
	})),
	on(fromActions.loadNewsletterSections, (state, { sections }) => {
		return {
			...state,
			sections,
			connectedBlockList: sections.reduce((result, section) => [...result, ...section.subsections.map((ss) => ({ id: ss.id, sectionType: section.type, subsectionType: ss.colspan }))],[])
		};
	}),
	on(fromActions.addSection, (state, { section }) => {
		return {
			...state,
			sections: [...state.sections, section],
			connectedBlockList: [
				...state.connectedBlockList,
				...section.subsections.map((ss) => ({ id: ss.id, sectionType: section.type, subsectionType: ss.colspan })),
			],
		};
	}),
	on(fromActions.removeSection, (state, { id }) => {
		const sections = state.sections.slice().filter((s) => s.id !== id);
		const connectedBlockList = sections.reduce(
			(acc, curr) => [
				...acc,
				...curr.subsections.map((ss) => ({ id: ss.id, sectionType: curr.type, subsectionType: ss.colspan })),
			],
			[]
		);
		return {
			...state,
			sections,
			connectedBlockList,
		};
	}),
	on(fromActions.duplicateSection, (state, { id }) => {
		const sectionIndex = state.sections.findIndex((s) => s.id === id);
		let section = state.sections[sectionIndex];
		section = {
			...section,
			id: createGuid(),
			subsections: section.subsections.map((ss) => ({
				...ss,
				id: createGuid(),
				blocks: ss.blocks.map((b) => ({
					...b,
					id: createGuid(),
				})),
			})),
		};
		const sections = state.sections;
		sections.splice(sectionIndex, 0, section);
		const connectedBlockList = sections.reduce(
			(acc, curr) => [
				...acc,
				...curr.subsections.map((ss) => ({ id: ss.id, sectionType: curr.type, subsectionType: ss.colspan })),
			],
			[]
		);
		return {
			...state,
			sections: [...sections],
			connectedBlockList,
		};
	}),
	on(fromActions.setSectionBackground, (state, { id, background }) => {
		const sections = state.sections;
		const sectionIndex = state.sections.findIndex((s) => s.id === id);
		sections[sectionIndex].background = background;
		return {
			...state,
			sections: [...sections],
		};
	}),
	on(fromActions.setSectionVerticalSpacing, (state, { id, value }) => {
		const sections = state.sections;
		const sectionIndex = state.sections.findIndex((s) => s.id === id);
		sections[sectionIndex].verticalSpace = value;
		return {
			...state,
			sections: [...sections],
		};
	}),
	on(fromActions.reorderSection, (state, { oldId, newId }) => {
		const newSections = [...state.sections];
		moveItemInArray(newSections, oldId, newId);
		return {
			...state,
			sections: [...newSections],
		};
	}),
	on(fromActions.addBlock, (state, { block, subsectionGuid, id }) => {
		const sectionIndex = state.sections.findIndex((s) => s.subsections.some((ss) => ss.id === subsectionGuid));
		const subsectionIndex = state.sections[sectionIndex].subsections.findIndex((ss) => ss.id === subsectionGuid);

		const sections = state.sections;
		sections[sectionIndex].subsections[subsectionIndex].blocks.splice(id, 0, block);
		return {
			...state,
			sections: [...sections],
		};
	}),
	on(fromActions.reorderBlock, (state, { subsectionGuid, oldId, newId }) => {
		const sectionIndex = state.sections.findIndex((s) => s.subsections.some((ss) => ss.id === subsectionGuid));
		const subsectionIndex = state.sections[sectionIndex].subsections.findIndex((ss) => ss.id === subsectionGuid);

		const sections = state.sections;
		moveItemInArray(sections[sectionIndex].subsections[subsectionIndex].blocks, oldId, newId);

		return {
			...state,
			sections: [...sections],
		};
	}),
	on(fromActions.moveBlock, (state, { oldSubsectionGuid, newSubsectionGuid, oldId, newId }) => {
		const oldSectionIndex = state.sections.findIndex((s) => s.subsections.some((ss) => ss.id === oldSubsectionGuid));
		const oldSubsectionIndex = state.sections[oldSectionIndex].subsections.findIndex(
			(ss) => ss.id === oldSubsectionGuid
		);

		const newSectionIndex = state.sections.findIndex((s) => s.subsections.some((ss) => ss.id === newSubsectionGuid));
		const newSubsectionIndex = state.sections[newSectionIndex].subsections.findIndex(
			(ss) => ss.id === newSubsectionGuid
		);

		const sections = state.sections;

		transferArrayItem(
			sections[oldSectionIndex].subsections[oldSubsectionIndex].blocks,
			sections[newSectionIndex].subsections[newSubsectionIndex].blocks,
			oldId,
			newId
		);

		return {
			...state,
			sections: [...sections],
		};
	}),
	on(fromActions.removeBlock, (state, { subsectionGuid, blockGuid }) => {
		const sectionIndex = state.sections.findIndex((s) => s.subsections.some((ss) => ss.id === subsectionGuid));
		const subsectionIndex = state.sections[sectionIndex].subsections.findIndex((ss) => ss.id === subsectionGuid);
		const blockIndex = state.sections[sectionIndex].subsections[subsectionIndex].blocks.findIndex(
			(b) => b.id === blockGuid
		);

		const sections = state.sections;

		sections[sectionIndex].subsections[subsectionIndex].blocks.splice(blockIndex, 1);

		return {
			...state,
			sections: [...sections],
		};
	}),
	on(fromActions.duplicateBlock, (state, { subsectionGuid, blockGuid, blockType }) => {
		const sectionIndex = state.sections.findIndex((s) => s.subsections.some((ss) => ss.id === subsectionGuid));
		const subsectionIndex = state.sections[sectionIndex].subsections.findIndex((ss) => ss.id === subsectionGuid);
		const blockIndex = state.sections[sectionIndex].subsections[subsectionIndex].blocks.findIndex(
			(b) => b.id === blockGuid
		);

		const sections = state.sections;
		const newGuid = createGuid();
		sections[sectionIndex].subsections[subsectionIndex].blocks.splice(blockIndex, 0, {
			...state.sections[sectionIndex].subsections[subsectionIndex].blocks[blockIndex],
			id: newGuid,
		});

		return {
			...state,
			sections: [...sections],
		};
	}),
	on(fromActions.updateBlockMarkup, (state, { blockGuid, markup }) => {
		const sectionIndex = state.sections.findIndex((s) =>
			s.subsections.some((ss) => ss.blocks.some((b) => b.id === blockGuid))
		);
		const subsectionIndex = state.sections[sectionIndex].subsections.findIndex((ss) =>
			ss.blocks.some((b) => b.id === blockGuid)
		);
		const blockIndex = state.sections[sectionIndex].subsections[subsectionIndex].blocks.findIndex(
			(b) => b.id === blockGuid
		);

		const sections = state.sections;
		sections[sectionIndex].subsections[subsectionIndex].blocks[blockIndex].markup = markup;
		return {
			...state,
			sections: [...sections],
		};
	}),
	on(fromActions.updateBlockSettings, (state, { blockGuid, settings }) => {
		const sectionIndex = state.sections.findIndex((s) =>
			s.subsections.some((ss) => ss.blocks.some((b) => b.id === blockGuid))
		);
		const subsectionIndex = state.sections[sectionIndex].subsections.findIndex((ss) =>
			ss.blocks.some((b) => b.id === blockGuid)
		);
		const blockIndex = state.sections[sectionIndex].subsections[subsectionIndex].blocks.findIndex(
			(b) => b.id === blockGuid
		);

		const sections = state.sections;
		sections[sectionIndex].subsections[subsectionIndex].blocks[blockIndex].settings = settings;
		return {
			...state,
			sections: [...sections],
		};
	}),
	on(fromActions.clearData, (state) => ({
		...initialState,
	})),
	on(fromActions.setSections, (state, { sections }) => ({
		...state,
		sections,
		connectedBlockList: [
			...state.connectedBlockList,
			...sections.reduce(
				(acc, curr) => [
					...acc,
					...curr.subsections.map((ss) => ({ id: ss.id, sectionType: curr.type, subsectionType: ss.colspan })),
				],
				[]
			),
		],
	}))
);

export const getSections = (state: State) => state.sections;
export const getSectionsLength = (state: State) => state.sections.length;
export const getConnectedBlockList = (state: State) => state.connectedBlockList;
export const getIsBlockDragging = (state: State) => state.isBlockDragging;
export const getDraggingBlockType = (state: State) => state.draggingBlockType;
export const getActiveItemGuid = (state: State) => state.activeGuid;
