<div class="rds-shadow-2">
  <content-loader [baseUrl]="componentsLoaderService.baseUrl"
                  [backgroundColor]="'#EAE8E5'"
                  [foregroundColor]="'#DBD6D1'"
                  [speed]="3"
                  [viewBox]="null"
                  [style]="{
                    preserveAspectRatio: 'none',
                    width: '100%',
                    height: '132px'
                  }"
                  [interval]="0.75"
                  [gradientRatio]="2.4">
    <svg:rect x="16" y="16" rx="0" width="24" height="24" />
    <svg:rect x="16" y="68" rx="0" width="235" height="18" />
    <svg:rect x="16" y="98" rx="0" width="215" height="14" />
  </content-loader>
</div>
