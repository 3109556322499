import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ColorModel, ColorsPalette } from '@app/newsletter/models';
import { SelectedTemplateModel } from '@app/newsletter/models/selected-template.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rh-templates-list-item',
  templateUrl: './templates-list-item.component.html',
  styleUrls: ['./templates-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplatesListItemComponent {
  @Input() templateConfiguration: SelectedTemplateModel;
  @Input() selectedTemplate: SelectedTemplateModel;
  @Input() size: 's' | 'm' = 'm';
  selectedColor: ColorModel = ColorsPalette[0];
  @Input() showContinueButton: true | false | null;
  @Input() hideActions = false;
  @Output() selectTemplate: EventEmitter<SelectedTemplateModel> = new EventEmitter();
  @Output() selectColor: EventEmitter<ColorModel> = new EventEmitter();
  @Output() goToWizardEmitter: EventEmitter<null> = new EventEmitter();

  @Output() useTemplateEmitter: EventEmitter<SelectedTemplateModel> = new EventEmitter();
  @Output() showPreviewEmitter: EventEmitter<string> = new EventEmitter();


  get imagePreview() {
    switch (this.templateConfiguration.templateId) {
      case 'NewsletterTemplateWithSections':
        return `/assets/images/thumbnail/with_section/${this.selectedColor.label}.png`;
      case 'NewsletterTemplateWithoutSections':
        return `/assets/images/thumbnail/without_section/${this.selectedColor.label}.png`;
      case 'NewsletterTemplateInlineEditor':
        return `/assets/images/thumbnail/inline/${this.selectedColor.label}.png`;
      default: 
        return null
    }
  }
  public translation = {
    selectTemplate: this.translateService.instant('newsletter.tooltips.select-template')
  };

  public onSelectTemplate(): void {
    if (!this.templateConfiguration.templateId) {
      return;
    }
    this.selectTemplate.emit(this.templateConfiguration);
    this.selectColor.emit(this.selectedColor);
  }

  public checkIsActiveTemplate(): boolean {
    return this.selectedTemplate && this.templateConfiguration && this.templateConfiguration.templateId === this.selectedTemplate.templateId;
  }

  public goToWizard(): void {
    this.goToWizardEmitter.emit();
  }

  public useTemplate() {
    this.useTemplateEmitter.emit(this.templateConfiguration);
  }
  public showPreview(temlpateId: string) {
    this.showPreviewEmitter.emit(temlpateId);
  }

  colorChanged($event) {
    this.selectedColor = $event;
    this.selectColor.emit(this.selectedColor);
  }

  constructor(private translateService: TranslateService) {
  }
}
