import { createAction, props } from '@ngrx/store';
import { CatalogTableFilters } from '@app/shared/filters/models/catalog-table';
import { SortDirection } from '@rds/angular-components/lib/table';
import { CatalogPermissions, EntryStatus, Pagination, QuicklinksSet } from '@app/core/models';

export const initTable = createAction(
  '[MY CONTENT] Initialize Channels'
);

export const clearAll = createAction(
  '[MY CONTENT] Clear all'
);

export const getTableRequest = createAction(
  '[MY CONTENT] Get channels Request',
  props<{
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
    filters: {[key: string]: any},
  }>()
);

export const getTableSuccess = createAction(
  '[MY CONTENT] Get channels Success',
  props<{ data: Array<Partial<QuicklinksSet>>, pagination: Pagination }>()
);

export const getTableFailure = createAction(
  '[MY CONTENT] Get channels Failure',
  props<{ error: any }>()
);


export const openDeleteEntryConfirmDialog = createAction(
  '[MY CONTENT] Open confirm delete dialog',
  props<{id: number, name: string, status: EntryStatus}>()
);

export const openProlongEntryConfirmDialog = createAction(
  '[MY CONTENT] Open confirm prolong entry dialog',
  props<{id: number, name: string}>()
);

export const prolongRequest = createAction(
  '[MY CONTENT] Prolong catalog entry request',
  props<{id: number}>()
);

export const prolongSuccess = createAction(
  '[MY CONTENT] Prolong catalog entry success'
);

export const prolongFailure = createAction(
  '[MY CONTENT] Prolong catalog entry failure',
  props<{error: string}>()
);

export const deleteRequest = createAction(
  '[MY CONTENT] Delete catalog entry request',
  props<{id: number, status: EntryStatus}>()
);

export const deleteSuccess = createAction(
  '[MY CONTENT] Delete catalog entry success',
  props<{status: EntryStatus}>()
);

export const deleteFailure = createAction(
  '[MY CONTENT] Delete catalog entry failure',
  props<{error: string}>()
);

export const changeTableFilters = createAction(
  '[MY CONTENT] Change channels filters',
  props<{ filters: CatalogTableFilters }>()
);

export const changeTableSort = createAction(
  '[MY CONTENT] Change channels sort',
  props<{ active: string, direction: SortDirection }>()
);

export const changeTablePagination = createAction(
  '[MY CONTENT] Change channels pagination',
  props<{ pageIndex: number, pageSize: number }>()
);

export const getCatalogPermissionsRequest = createAction(
  '[MY CONTENT] Check permissions request',
  props<{ catalogId: number, permissionsToCheck: CatalogPermissions}>()
);

export const getCatalogPermissionsSuccess = createAction(
  '[MY CONTENT] Check permissions success',
  props<{ permissions: CatalogPermissions}>()
);

export const getCatalogPermissionsFailure = createAction(
  '[MY CONTENT] Check permissions failure',
  props<{ error: any }>()
);

export const clearCatalogPermissions = createAction(
  '[MY CONTENT] Clear permissions failure'
);

export const duplicateCatalogRequest = createAction(
  '[MY CONTENT] Duplicate catalog request',
  props<{ catalogId: number}>()
);

export const duplicateCatalogSuccess = createAction(
  '[MY CONTENT] Duplicate catalog success',
);

export const duplicateCatalogFailure = createAction(
  '[MY CONTENT] Duplicate catalog failure',
  props<{ error: any }>()
);
