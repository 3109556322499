<div class="catalogs" *ngIf="(openedCategory$ | async) === null">
  <div class="catalogs__wrapper">
    <div class="catalogs__box--wrapper" *ngFor="let category of categories$ | async" (click)="openCategory(category)">
      <div class="catalogs__box--content" >
        <rh-catalog-icons class="catalogs__box--content__icon" [catalogName]="category.name"></rh-catalog-icons>
        <span class="catalogs__box--content__name">
          {{category.name}}
        </span>
      </div>
      <div class="catalogs__box--wrapper__shadow">
      </div>
    </div>
  </div>
</div>

<div class="catalog" *ngIf="(openedCategory$ | async) as openedCategory">
  <rh-header-content-details [category]="openedCategory"></rh-header-content-details>
</div>
