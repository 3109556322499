import { Component, EventEmitter, Input, Output} from '@angular/core';
import {ColorModel} from '@app/newsletter/models';


@Component({
  selector: 'rh-color',
  templateUrl: './color.component.html',
  styleUrls: ['./color.component.scss']
})
export class ColorComponent {
  // TODO: MOVE TO NL MODULE
  @Input() color: ColorModel;
  @Input() selectedColor: ColorModel;
  @Output() selectColor: EventEmitter<ColorModel> = new EventEmitter();

  public onSelectColor(color: ColorModel): void {
    this.selectColor.emit(color);
  }

  constructor() {
  }
}
