import { DatePipe } from '@angular/common';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { GAService } from '@core/services/ga.service';
import { GoogleAnalyticsService } from '@core/services/google-analytics.service';
import { NewsFeedService } from '@core/services/newsfeed.service';
import { TickerService } from '@core/services/ticker.service';
import { AlertsService } from './alerts.service';
import { ApiService } from './api/api.service';
import { BookmarksService } from './bookmarks.service';
import { CatalogsService } from './catalogs.service';
import { CategoriesService } from './categories.service';
import { ChannelSetService } from './channel-set.service';
import { CommentService } from './comment.service';
import { ContentApprovalsService } from './content-approvals.service';
import { ContentService } from './content.service';
import { DevelopmentGuard } from './development.guard';
import { DynamicLinksService } from './dynamic-links.service';
import { EndpointsService } from './endpoints/endpoints.service';
import { GapiService } from './gapi.service';
import { GenericAccountsService } from './generic-accounts.service';
import { ManagingChannelsService } from './managing-channels.service';
import { EventsService } from './managing-events.service';
import { MessagingService } from './messaging.service';
import { NewsletterService } from './newsletter.service';
import { CatalogODataService } from './odata/catalog.odata.service';
import { UserProfileODataService } from './odata/user-profile.odata.service';
import { QuicklinksSetService } from './quicklinks-set.service';
import { SearchService } from './search.service';
import { SubscriptionsService } from './subscriptions.service';
import { TaxonomyService } from './taxonomy.service';
import { TimeZoneService } from './timezone.service';
import { UserProfileService } from './userProfile.service';
import { UtilsService } from './utils.service';
import { WorkdayService } from './workday.service';
import { NewsletterMockService } from './newsletter-mock.service';
import { NewsboardNewsService } from './newsboard/newsboard-news.service';
import { NewsletterFormService } from '@core/services/newsletter-form.service';

export const GUARDS = [DevelopmentGuard];

export const INTERCEPTORS = [];

export const SERVICES = [
	ApiService,
	GAService,
	BookmarksService,
	EndpointsService,
	ContentService,
	CommentService,
	GapiService,
	SearchService,
	UserProfileService,
	CatalogsService,
	CategoriesService,
	CatalogODataService,
	ManagingChannelsService,
	EventsService,
	UserProfileODataService,
	WorkdayService,
	UtilsService,
	NewsletterService,
	TaxonomyService,
	GoogleAnalyticsService,
	ChannelSetService,
	QuicklinksSetService,
	ContentApprovalsService,
	AlertsService,
	DynamicLinksService,
	MessagingService,
	TickerService,
	NewsFeedService,
	TimeZoneService,
	SubscriptionsService,
	GenericAccountsService,
	NewsletterMockService,
	NewsboardNewsService,
	NewsletterFormService,
	{ provide: ErrorHandler, useClass: ErrorHandlerService },
];

export const PIPES = [DatePipe];

@NgModule({
	imports: [],
	declarations: [],
	providers: [...INTERCEPTORS, ...SERVICES, ...GUARDS, ...PIPES],
})
export class ServicesModule {
	static forRoot(): ModuleWithProviders<ServicesModule> {
		return {
			ngModule: ServicesModule,
			providers: [...INTERCEPTORS, ...SERVICES, ...GUARDS, ...PIPES],
		};
	}
}
