import {Action, createReducer, on} from '@ngrx/store';

import * as fromActions from './form.actions';
import cloneDeep from '@lodash-es/cloneDeep';

import {ChannelAssignment, NewsForm, NewsRoles, NewsStatus} from '@app/core/models/newsboard';
import {Template} from '@app/core/models/newsboard/template';
import {FormStepStatus} from '../../../../shared/form-status-badge/form-status-badge.component';

export const feature = 'form';

export interface State {
  newsStatus: NewsStatus;
  form: Partial<NewsForm>;
  initialForm: Partial<NewsForm>;
  createFromTemplate: Template;
  generalStatus: {
    step: FormStepStatus;
    form: string
  }
  contentStatus: {
    step: FormStepStatus;
    form: string
  }
  publishingStatus: {
    step: FormStepStatus;
    form: string
  }
  permissionsStatus: {
    step: FormStepStatus;
    form: string
  }
  touched: boolean;
  assignableChannels: Array<ChannelAssignment>;
  suggestableChannels: Array<ChannelAssignment>;
  fillFormStartedAt: Date;
  formChecked: boolean;
  userRole: NewsRoles;
  requestSent: boolean
}

export const initialState: State = {
  newsStatus: null,
  form: null,
  initialForm: null,
  createFromTemplate: null,
  generalStatus: {
    step: FormStepStatus.NOT_DEFINED,
    form: 'INVALID'
  },
  contentStatus: {
    step: FormStepStatus.NOT_DEFINED,
    form: 'INVALID'
  },
  publishingStatus: {
    step: FormStepStatus.NOT_DEFINED,
    form: 'INVALID'
  },
  permissionsStatus: {
    step: FormStepStatus.NOT_DEFINED,
    form: 'VALID'
  },
  touched: false,
  assignableChannels: [],
  suggestableChannels: [],
  fillFormStartedAt: null,
  formChecked: false,
  userRole: 'Owner',
  requestSent: false
};

const addReducer = createReducer(
  initialState,
  on(fromActions.initAddForm, (state) => ({
    ...state,
    fillFormStartedAt: new Date(new Date().setSeconds(0, 0))
  })),
  on(fromActions.setFormChecked, (state) => ({
    ...state,
    formChecked: true
  })),
  on(fromActions.setFormValue, (state, {form}) => ({
    ...state,
    form: {
      ...state.form,
      ...form
    }
  })),
  on(fromActions.initCreateFormFromTemplateSuccess, (state, {template}) => ({
    ...state,
    createFromTemplate: template
  })),
  on(fromActions.setInitialFormValue, (state, {form}) => ({
    ...state,
    initialForm: cloneDeep(form)
  })),
  on(fromActions.updatePrimaryTitle, (state, {title}) => {
    const currentPrimaryId = state.form.content.findIndex(c => c.language === state.form?.language);
    return {
      ...state,
      form: {
        ...state.form,
        content: state.form.content.map((c, index) => ({
          ...c,
          title: currentPrimaryId === index ? title : c.title
        }))
      }
    }
  }),
  on(fromActions.updatePrimaryLanguage, (state, {oldLang, newLang}) => {
    const currentPrimaryId = state.form.content.findIndex(c => c.language === oldLang);
    const versionExists = state.form.content.findIndex(c => c.language === newLang) > -1;

    return {
      ...state,
      form: {
        ...state.form,
        language: newLang,
        content: state.form.content.map((c, index) => ({
          ...c,
          language: !versionExists && currentPrimaryId === index ? newLang : c.language,
        }))
      }
    }
  }),
  on(fromActions.updateAsPublishRequest, fromActions.updateAsDraftRequest, (state) => ({
    ...state,
    requestSent: true
  })),
  on(fromActions.updateAsDraftSuccess, fromActions.updateAsPublishSuccess, fromActions.updateAsDraftFailure, fromActions.updateAsPublishFailure, (state) => ({
    ...state,
    requestSent: false
  })),
  on(fromActions.addLanguageVersion, (state, {language}) => {
    const primaryImage = state.form.content.find(c => c.language === state.form.language).image
    const currentPrimaryImage = !!primaryImage ? {
      ...primaryImage
    } : null;

    return {
      ...state,
      form: {
        ...state.form,
        content: [
          ...state.form.content,
          {
            language,
            abstract: null,
            title: null,
            html: null,
            image: currentPrimaryImage,
            link: null,
            syncImage: true
          }
        ]
      }
    }
  }),
  on(fromActions.removeLanguageVersion, (state, {language}) => {
    return {
      ...state,
      form: {
        ...state.form,
        content: state.form.content.filter(c => c.language !== language)
      }
    }
  }),
  on(fromActions.setFormTouched, (state, {touched}) => ({
    ...state,
    touched
  })),
  on(fromActions.setGeneralStepStatus, (state, {status}) => ({
    ...state,
    generalStatus: {
      ...state.generalStatus,
      step: status
    }
  })),
  on(fromActions.setGeneralFormStatus, (state, {status}) => ({
    ...state,
    generalStatus: {
      ...state.generalStatus,
      form: status
    }
  })),
  on(fromActions.setContentStepStatus, (state, {status}) => ({
    ...state,
    contentStatus: {
      ...state.contentStatus,
      step: status
    }
  })),
  on(fromActions.setContentFormStatus, (state, {status}) => ({
    ...state,
    contentStatus: {
      ...state.contentStatus,
      form: status
    }
  })),
  on(fromActions.setPublishingStepStatus, (state, {status}) => ({
    ...state,
    publishingStatus: {
      ...state.publishingStatus,
      step: status
    }
  })),
  on(fromActions.setPublishingFormStatus, (state, {status}) => ({
    ...state,
    publishingStatus: {
      ...state.publishingStatus,
      form: status
    }
  })),
  on(fromActions.setPermissionsStepStatus, (state, {status}) => ({
    ...state,
    permissionsStatus: {
      ...state.permissionsStatus,
      step: status
    }
  })),
  on(fromActions.setPermissionsFormStatus, (state, {status}) => ({
    ...state,
    permissionsStatus: {
      ...state.permissionsStatus,
      form: status
    }
  })),
  on(fromActions.getNewsDetailsSuccess, (state, {news, status, role}) => ({
    ...state,
    initialForm: cloneDeep(news),
    newsStatus: status,
    userRole: role
  })),
  on(fromActions.loadAssignableSuccess, (state, {channels}) => ({
    ...state,
    assignableChannels: channels
  })),
  on(fromActions.loadSuggestableSuccess, (state, {channels}) => ({
    ...state,
    suggestableChannels: channels
  })),
  on(fromActions.clearAll, (state) => ({
    ...initialState
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return addReducer(state, action);
}

export const getForm = (state: State) => state.form;
export const getInitialForm = (state: State) => state.initialForm;

export const getCreateFromTemplate = (state: State) => state.createFromTemplate;

export const getFormChecked = (state: State) => state.formChecked;
export const getNewsStatus = (state: State) => state.newsStatus;
export const getFillFormStartedAt = (state: State) => state.fillFormStartedAt;
export const getId = (form: Partial<NewsForm>) => form.id;
export const getType = (form: Partial<NewsForm>) => form?.type;
export const getLanguages = (form: Partial<NewsForm>) => form?.content.map(c => c.language);
export const getPrimaryLanguageTitle = (form: Partial<NewsForm>) => form?.content.find(c => c.language === form?.language)?.title;
export const getPrimaryLanguage = (form: Partial<NewsForm>) => form?.language;
export const getPrimaryLanguageImage = (form: Partial<NewsForm>) => form?.content.find(c => c.language === form?.language)?.image;

export const getGeneralStepStatus = (state: State) => state.generalStatus.step;
export const getGeneralFormStatus = (state: State) => state.generalStatus.form;

export const getContentStepStatus = (state: State) => state.contentStatus.step;
export const getContentFormStatus = (state: State) => state.contentStatus.form;

export const getPublishingStepStatus = (state: State) => state.publishingStatus.step;
export const getPublishingFormStatus = (state: State) => state.publishingStatus.form;

export const getPermissionsStepStatus = (state: State) => state.permissionsStatus.step;
export const getPermissionsFormStatus = (state: State) => state.permissionsStatus.form;

export const getTouched = (state: State) => state.touched;
export const getUserRole = (state: State) => state.userRole;

export const getAssignableChannels = (state: State) => state.assignableChannels?.sort((a, b) => a.name.localeCompare(b.name));
export const getSuggestableChannels = (state: State) => state.suggestableChannels?.sort((a, b) => a.name.localeCompare(b.name));
export const getRequestSent = (state: State) => state.requestSent;
