<div class="comment__wrapper" [class.error]="error">
  <div class="floating-label__wrapper">
    <div 
    class="floating-label__text"
    [class.floating-label__text--float]="commentLength > 0"
    (click)="input.focus()"
    >
    {{label}}
    </div>
</div>
  <div #input class="comment__input" (input)="onInput($event)" (paste)="onPaste($event)" (keydown)="onKeydown($event)" (blur)="onBlur()" contenteditable="true"></div>
</div>

<span
class="mention__trigger"
[style.top]="triggerY + 'px'"
[style.left]="triggerX + 'px'"
[rdsMenuTriggerFor]="autocomplete"
#trigger="rdsMenuTrigger">
TRIGGER
</span>
<rds-menu #autocomplete="rdsMenu" class="mention__menu">
  <div class="mention__items">
    <div *ngFor="let autocomplete of mentionAutocomplete.data" (click)="mentionUser(autocomplete)" rds-menu-item class="mention__item" style="display: flex;">
      <rh-user-single class="mention__item--avatar" [user]="autocomplete"></rh-user-single>
      <div>
        <p class="mention__item--name">
          {{ autocomplete.firstName}} {{autocomplete.lastName}}
        </p>
        <p class="mention__item--email">
          {{ autocomplete.email }}
        </p>
      </div>
    </div>
  </div>
</rds-menu>