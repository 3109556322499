import { combineReducers, Action, createFeatureSelector } from '@ngrx/store';

import * as fromSearchingPreferences from './searching-preferences/searching-preferences.reducer';
import * as fromGlobalSettings from '@app/user-settings/store/global-settings/global-settings.reducer';
import * as fromNotificationSettings from '@app/user-settings/store/notification-settings/notification-settings.reducer';

export const feature = 'userSettings';

export interface State {
  searchingPreferences: fromSearchingPreferences.State;
  globalSettings: fromGlobalSettings.State;
  notificationSettings: fromNotificationSettings.State;
}

export function reducer(state: State | undefined, action: Action) {
  return combineReducers({
    searchingPreferences: fromSearchingPreferences.reducer,
    globalSettings: fromGlobalSettings.reducer,
    notificationSettings: fromNotificationSettings.reducer
  })(state, action);
}

export const selectState = createFeatureSelector<State>(feature);

export const getSearchingPreferences = (state: State) => state.searchingPreferences;
export const getGlobalSettings = (state: State) => state.globalSettings;
export const getNotificationSettings = (state: State) => state.notificationSettings;
