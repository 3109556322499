import { Component } from '@angular/core';
import { ComponentsLoaderService } from '../components-loader.service';

@Component({
  selector: 'atr-recently-edited-loader',
  templateUrl: './recently-edited-loader.component.html',
  styleUrls: ['./recently-edited-loader.component.scss']
})
export class RecentlyEditedLoaderComponent {
  constructor(public componentsLoaderService: ComponentsLoaderService) {}
}
