import { QuicklinksSetRequest } from '@app/core/models';
import { createSelector } from '@ngrx/store';
import * as fromManageSets from '../manage-sets.reducer';
import * as fromReducer from './quicklinks-sets-form.reducer';
import { flattenFilters } from '@app/shared/filters/filters-model';

export const selectState = createSelector(
  fromManageSets.selectState,
  fromManageSets.getQuicklinksSetsForm
);

export const selectForm = createSelector(
  selectState,
  fromReducer.getForm
);

export const selectFormForRequest = createSelector(
  selectForm,
  (form): QuicklinksSetRequest => {
    return {
      id: form?.id,
      name: form?.name,
      locations: form?.audience.locations,
      departments: form?.audience.departments,
      functions: form?.audience.functions,
      catalogIds: form?.catalogs.selected.filter(c => !c.isTop).map(c => c.id as number),
      topCatalogIds: form?.catalogs.selected.filter(c => c.isTop).map(c => c.id as number)
    }
  }
);

export const selectInitialForm = createSelector(
  selectState,
  fromReducer.getInitialForm
);

export const selectId = createSelector(
  selectForm,
  fromReducer.getId
);

export const selectIsTouched = createSelector(
  selectState,
  fromReducer.getTouched
);


export const selectIsNameUnique = createSelector(
  selectState,
  fromReducer.getIsNameUnique
);

export const selectCatalogsFilters = createSelector(
  selectForm,
  fromReducer.getCatalogsFilters
);

export const selectCatalogsFiltersForRequest = createSelector(
  selectCatalogsFilters,
  (filters) => ({
    name: filters.name,
    ...flattenFilters(filters.advancedFilters)
  })
)

export const selectSelectedCatalogs = createSelector(
  selectForm,
  fromReducer.getSelectedCatalogs
);

export const selectSelectedCatalogsIds = createSelector(
  selectSelectedCatalogs,
  (selected) => selected.map(c => c.id as number)
);

export const selectSelectedTopCatalogsIds = createSelector(
  selectSelectedCatalogs,
  (selected) => selected.filter(c => c.isTop).map(c => c.id as number)
);


export const selectFilteredCatalogs = createSelector(
  selectState,
  fromReducer.getFilteredCatalogs
);

export const selectFilteredCatalogsCount = createSelector(
  selectFilteredCatalogs,
  (filtered) => filtered.length
);

export const selectFilteredCatalogsLoading = createSelector(
  selectState,
  fromReducer.getFilteredCatalogsLoading
);

export const selectAudienceManagerInfo = createSelector(
  selectState,
  fromReducer.getAudienceManagerInfo
);
