import {Injectable} from '@angular/core';
import {ApiService, HttpError} from './api/api.service';
import {EndpointsService} from './endpoints/endpoints.service';
import {Observable, throwError} from 'rxjs';
import {
  GlobalSettingsDetails,
  SearchingPreferencesDetails,
  SearchingPreferencesIds,
  UserProfile,
  SavedFilters,
  EventFilters
} from '../models/user-profile.model';
import {catchError, map} from 'rxjs/operators';
import {Language} from '../models/language.model';
import {UserRole, UserRoleRequest} from '../models';
import { RhEventType } from '@app/events/models/event';

@Injectable()
export class UserProfileService {

  public getUserPhoto(login: string): Observable<any> {
    const mainUrl = this.endpoints.ENDPOINT.WORKDAY.USER.PHOTO.GET.replace('{login}', login);

    return this.http
      .get(mainUrl)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get user profile details data. ${error.message}`))
      );
  }

  public getUserProfile(): Observable<UserProfile> {
    const mainUrl = this.endpoints.ENDPOINT.USER_PROFILE.GET;

    return this.http
      .get(mainUrl)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get user profile details data. ${error.message}`))
      );
  }

  public getUserHasAnyRoles(login): Observable<{ login, hasAnyRole }> {
    const url = this.endpoints.replaceUrlTokens(
      this.endpoints.ENDPOINT.USER_PROFILE.ROLES.GET, {login}
    );

    return this.http
      .get(url).pipe(
        map(({roles}) => ({login, hasAnyRole: (roles.length !== 0)}))
      );
  }

  public getLanguagesDict(): Observable<Array<Language>> {
    const mainUrl = this.endpoints.ENDPOINT.USER_PROFILE.LANGUAGE.GET;

    return this.http
      .get(mainUrl)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get user profile language. ${error.message}`))
      );
  }

  public postGlobalSettings({newsLanguageId}): Observable<GlobalSettingsDetails> {
    const mainUrl = this.endpoints.ENDPOINT.USER_PROFILE.POST;
    return this.http
      .post(mainUrl, {newsLanguageId: parseInt(newsLanguageId, 10)})
      .pipe(
        map(({newsLanguage}) => ({newsLanguage})),
        catchError((error: HttpError) => throwError(`Cannot post user profile global settings data. ${error.message}`))
      );
  }

  public ensureCreated(): Observable<any> {
    const url = this.endpoints.ENDPOINT.USER_PROFILE.CREATED;
    return this.http
      .post(url, {})
      .pipe(
        map(response => (response)),
        catchError((error: HttpError) => throwError(`Cannot post ensure created. ${error.message}`))
      );
  }

  public putSearchingPreferencesCount(preferencesIds: SearchingPreferencesIds): Observable<number> {
    const url = this.endpoints.ENDPOINT.USER_PROFILE.USER_COUNT_BY_TAXONOMIES.PUT;
    return this.http
      .put(url, preferencesIds)
      .pipe(
        map(response => (response)),
        catchError((error: HttpError) => throwError(`Cannot post ensure created. ${error.message}`))
      );
  }

  public putSearchingPreferences(preferencesIds: SearchingPreferencesIds): Observable<SearchingPreferencesIds> {
    const mainUrl = this.endpoints.ENDPOINT.USER_PROFILE.SEARCHING_PREFERENCES.PUT;

    return this.http
      .put(mainUrl, preferencesIds)
      .pipe(
        map(response => (response)),
        catchError((error: HttpError) => throwError(`Cannot post user searching preferences data. ${error.message}`))
      );
  }

  public getSearchingPreferences(): Observable<SearchingPreferencesIds> {
    const mainUrl = this.endpoints.ENDPOINT.USER_PROFILE.SEARCHING_PREFERENCES.GET;

    return this.http
      .get(mainUrl)
      .pipe(
        catchError((error: HttpError) => throwError('Cannot get topics'))
      );
  }

  public getSearchingPreferencesDetails(): Observable<SearchingPreferencesDetails> {
    const mainUrl = this.endpoints.ENDPOINT.USER_PROFILE.SEARCHING_PREFERENCES.DETAILS.GET;
    return this.http
      .get(mainUrl)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get user searching preferences details data. ${error.message}`))
      );
  }

  public getSavedEventFilters(): Observable<EventFilters> {
    const mainUrl = this.endpoints.ENDPOINT.USER_PROFILE.EVENT_FILTERS.GET;
    return this.http
      .get(mainUrl)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get saved event filters. ${error.message}`))
      );
  }

  public saveEventFilters(filters: {
    eventCategory: Array<RhEventType>,
    locations: Array<string>,
    departments: Array<string>,
    functions: Array<string>
  }): Observable<EventFilters> {
    const mainUrl = this.endpoints.ENDPOINT.USER_PROFILE.EVENT_FILTERS.POST;
    return this.http
      .post(mainUrl, filters)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot save event filter filters. ${error.message}`))
      );
  }

  public getSavedFilters(): Observable<SavedFilters> {
    const mainUrl = this.endpoints.ENDPOINT.USER_PROFILE.FILTERS.GET;
    return this.http
      .get(mainUrl)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get user saved filters. ${error.message}`))
      );
  }

  public saveFilters(filters: {
    categories: string[],
    locations: string[],
    departments: string[],
    functions: string[]
  }): Observable<SavedFilters> {
    const mainUrl = this.endpoints.ENDPOINT.USER_PROFILE.FILTERS.POST;
    return this.http
      .post(mainUrl, filters)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot save user filters. ${error.message}`))
      );
  }

  public saveUserRoles(body: UserRoleRequest): Observable<{userWithRoles: UserRole}> {
    const mainUrl = this.endpoints.ENDPOINT.USER_PROFILE.USER_ROLE.PUT;
    return this.http
      .put(mainUrl, body)
      .pipe(
        map(response => ({
          ...response,
          login: response.login.toLowerCase()
        })),
        map(response => ({userWithRoles: response})),
        catchError((error: HttpError) => throwError(`Cannot post user searching preferences data. ${error.message}`))
      );
  }

  public deleteUserRoles(login)
    : Observable<{ userWithRoles: any }> {
    const mainUrl = this.endpoints.ENDPOINT.USER_PROFILE.USER_ROLE.PUT;
    const userWithoutRoles: any = {
      login,
      roles: [],
      taxonomies: {
        locations: [],
        departments: [],
        functions: []
      }
    };
    return this.http
      .put(mainUrl, userWithoutRoles)
      .pipe(
        map(response => ({userWithRoles: response})),
        catchError((error: HttpError) => throwError(`Cannot post user searching preferences data. ${error.message}`))
      );
  }

  public postResetSearchingPreferences(): Observable<SearchingPreferencesIds> {
    const mainUrl = this.endpoints.ENDPOINT.USER_PROFILE.SEARCHING_PREFERENCES.RESET.POST;
    return this.http
      .post(mainUrl)
      .pipe(
        map(({locations, departments, functions}) => ({
          locations: locations.map(({id}) => id),
          departments: departments.map(({id}) => id),
          functions: functions.map(({id}) => id),
        })),
        catchError((error: HttpError) => throwError(`Cannot reset searching preferences. ${error.message}`))
      );
  }

  constructor(private http: ApiService, private endpoints: EndpointsService) {
  }
}
