import {Action, createReducer, on} from '@ngrx/store';
import * as fromActions from '@app/home/store/ticker/ticker.actions';
import {TickerFeed} from '@app/core/models';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

export interface State extends EntityState<TickerFeed> {}

export const adapter: EntityAdapter<TickerFeed> =
  createEntityAdapter<TickerFeed>({
    selectId: feed => feed.id
  });

export const initialState: State = {
  ...adapter.getInitialState(),
};

export const tickerReducer = createReducer(
  initialState,
  on(fromActions.getFeedSuccess, (state, {data}) => (
    adapter.addMany(data, state))),
  on(fromActions.removeFeedByIdSuccess, (state, {id}) => (
    adapter.removeOne(id, state))),
);

export function reducer(state: State | undefined, action: Action) {
  return tickerReducer(state, action);
}

export const getData = adapter.getSelectors().selectAll;
