import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RdsDateRangePickerComponent } from '@rds/angular-components/lib/datepicker/date-range-picker.component';
import { add, set, sub } from 'date-fns';

@Component({
  selector: 'rh-datepicker-range-actions',
  templateUrl: './datepicker-range-actions.component.html',
  styleUrls: ['./datepicker-range-actions.component.scss']
})
export class DatepickerRangeActionsComponent {

  @Input() dateRangeInput: RdsDateRangePickerComponent<Date>;
  @Input() datepicker: RdsDateRangePickerComponent<Date>;
  @Input() rangeFormGroup: FormGroup;
  @Input() startDateControlName: string = 'start';
  @Input() endDateControlName: string = 'end';

  setDateRange({type, value, key}) {
    let startDate = set(new Date(), {hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
    let endDate = set(new Date(), {hours: 23, minutes: 59, seconds: 59, milliseconds: 999});
    switch (type) {
        case 'add':
            endDate = add(endDate, {[key]: value});
            break;
        case 'subtract':
            startDate = sub(startDate, {[key]: value});
            break;
    }
    const range = {
      [this.startDateControlName]: startDate,
      [this.endDateControlName]: endDate
    }
    this.rangeFormGroup.setValue(range);
    this.datepicker.close();
  }

  clearRange() {
    const range = {
      [this.startDateControlName]: null,
      [this.endDateControlName]: null
    }
    this.rangeFormGroup.setValue(range)
    this.datepicker.close();
  }
}
