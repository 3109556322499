import { Category } from './category.model';
import { CatalogStatus } from './catalog-status.model';
import { ContentType } from './content-type.model';
import { Taxonomy } from './taxonomy.model';
import { UserSearch } from './user-search.model';
import { SimpleUser } from './newsboard';

export interface CatalogEntryModel {
  catalogId: number;
  name: string;
  url: string;
  description: string;
  contentOwner: UserSearch;
  contentOwnerSearch: string;
  contentManager: UserSearch;
  contentManagerSearch: string;
  keywords?: string[];
  keywordsSearch?: string;
  creationDate: Date;
  lastModifiedDate: string;
  urlStatus: number;
  isContentOwnerValid: boolean;
  isContentManagerValid: boolean;
  startDate:	Date;
  endDate:	Date;
  isAttentionRequired?: boolean;
  urlStatusLastModifiedDate?: Date;
  status?: CatalogStatus;
  category: string[];
  contentType: number[];
  locations: string[];
  functions: string[];
  departments: string[];
  contentFromOrganization: string[];
  contentFromLocation: string[];
  isVpnRequired: boolean;
  // todo: Remove after audience preview update
  contentOwnerFirstName?: string;
  contentManagerFirstName?: string;
  contentOwnerLastName?: string;
  contentManagerLastName?: string;
}

export interface CatalogTableModel {
  catalogId: number;
  name: string;
  url: string;
  isVpnRequired: boolean;
  description: string;
  contentOwner: string;
  contentOwnerFirstName: string;
  contentOwnerLastName: string;
  contentManager: string;
  contentManagerFirstName: string;
  contentManagerLastName: string;
  lastModifiedBy: string;
  lastModifiedByFirstName: string;
  lastModifiedByLastName: string;
  creationDate: string;
  lastModifiedDate: string;
  urlStatus: number;
  keywords?: string;
  isContentOwnerValid: boolean;
  isContentManagerValid: boolean;
  startDate:	string;
  endDate:	string;
  isAttentionRequired: boolean;
  urlStatusLastModifiedDate: string;
  category: Category;
  status: CatalogStatus;
  contentType: ContentType;
  locations: Array<Taxonomy>;
  functions: Array<Taxonomy>;
  departments: Array<Taxonomy>;
  contentFromOrganization: Taxonomy;
  contentFromLocation: Taxonomy;
  // TEMPORARY ADDED WHILE NO MODEL CONFIRMED WITH BE
  acceptationDate: string;
  rejectionDate: string;
  comment: string;
}

export interface CatalogEntryRequest {
  id: number;
  name: string;
  url: string;
  isVpnRequired: boolean;
  description: string;
  contentOwner: string;
  contentManager: string;
  keywords: string;
  startDate:	Date;
  endDate:	Date;
  urlStatusLastModifiedDate?: Date;
  categoryId: number;
  contentTypeId: number;
  contentFromLocation: string;
  contentFromOrganization: string;
  functions: Array<string>;
  departments: Array<string>;
  locations: Array<string>;
}

export interface CatalogAdvancedFilterModel {
  contentOwner: Array<UserSearch>;
  contentManager: Array<UserSearch>;
  creationDate: string;
  lastModifiedDate: string;
  approvedDate: string;
  rejectedDate: string;
  locations: Array<string>;
  departments: Array<string>;
  functions: Array<string>;
  comment: string;
  contentTypes: Array<string>;
}
export interface CatalogFilterModel extends CatalogAdvancedFilterModel {
  name: string;
  status: Array<number>;
  isAttentionRequired: boolean | null;
}

export interface CatalogFilter extends Partial<CatalogFilterModel> {}
export interface CatalogAdvancedFilter extends Partial<CatalogAdvancedFilterModel> {}
export interface CatalogTable extends Partial<CatalogTableModel> {}
export interface CatalogEntry extends Partial<CatalogEntryModel> {}

export const catalogsOptionsModel = {
  idKey: 'catalogId',
  nameKey: 'name',
};

export const EMPTY_CATALOG: CatalogEntry = {
  name: '',
  category: [],
  contentType: [],
  urlStatusLastModifiedDate: null,
  contentManager: null,
  contentManagerSearch: '',
  contentOwner: null,
  contentOwnerSearch: '',
  contentFromOrganization: [],
  contentFromLocation: [],
  locations: [],
  departments: [],
  functions: [],
  keywords: null,
};


export interface CatalogEntryForm {
  id: number;
  status?: CatalogStatus;
  creationDate: Date,
  lastModifiedDate: Date,
  lastModifiedBy: Array<SimpleUser>,
  contentManager: Array<SimpleUser>,
  contentFromLocation: string,
  contentFromOrganization: string,
  contentType: Array<number>,
  category: Array<number>,
  name: string,
  description: string,
  url: string,
  isVpnRequired: boolean,
  contentOwner: Array<SimpleUser>,
  keywords: Array<string>,
  startDate: Date,
  endDate: Date,
  audience: {
    locations: Array<string>,
    departments: Array<string>,
    functions: Array<string>
  },
  isAttentionRequired?: boolean;
  isContentOwnerValid?: boolean;
  isContentManagerValid?: boolean;
  urlStatus?: string | number;

}

export const EMPTY_CATALOG_FORM: CatalogEntryForm = {
  id: null,
  creationDate: new Date(),
  lastModifiedDate: null,
  lastModifiedBy: [],
  contentManager: [],
  contentFromLocation: null,
  contentFromOrganization: null,
  contentType: [],
  category: [],
  name: '',
  description: '',
  url: '',
  isVpnRequired: false,
  contentOwner: [],
  keywords: [],
  startDate: null,
  endDate: null,
  audience: {
    locations: [],
    departments: [],
    functions: []
  }
}