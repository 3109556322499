import {Component, Input} from '@angular/core';
import {QuickLink} from '@app/layout/header/models';

@Component({
  selector: 'rh-single-catalog-item',
  templateUrl: './single-catalog-item.component.html',
  styleUrls: ['./single-catalog-item.component.scss']
})
export class SingleCatalogItemComponent {

  @Input() catalog: QuickLink;
  @Input() actionsAlwaysVisible: boolean;
  @Input() actionsPosition: 'top' | 'center';

  _allowSwipe: boolean;
  get allowSwipe(): boolean {
    return this._allowSwipe;
  }

  @Input() set allowSwipe(value: boolean) {
    this._allowSwipe = value;
    if (!value) {
      this.swiped = false;
    }
  }

  swiped = false;

  constructor() {
  }

  onSwipeLeft() {
    this.swiped = true;
  }

  onSwipeRight() {
    this.swiped = false;
  }
}
