import {animate, state, style, transition, trigger} from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as fromQuickLink from '@app/layout/header/store/quick-link';
import * as fromRouter from '@app/root-store/router';
import * as fromUiScreen from '@app/root-store/ui/screen';
import {select, Store} from '@ngrx/store';
import {RdsDropdownDirective, RdsHeaderComponent} from '@rds/angular-components';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {SubSink} from 'subsink';
import * as fromHeader from '../../store/header';
import * as fromHeaderActions from '../../store/header/header.actions';

@Component({
  selector: 'rh-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('menu', [
      state(
        'start',
        style({
          transform: '',
        }),
      ),
      state(
        'end',
        style({
          transform: 'rotate(360deg)',
        }),
      ),
      transition('start => end', [animate('0.5s')]),
      transition('end => start', [animate('0.5s')]),
    ]),

  ]
})
export class HeaderMobileComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding('style.top') top: string = '0';
  @HostBinding('style.opacity') opacity: string = '1';

  @HostListener('window:scroll')
  scrolled() {
    if (this.scrollPosition > window.scrollY || window.scrollY < 72) {
      this.top = '0';
      this.opacity = '1'
    } else {
      this.top = '-72px';
      this.opacity = '0'
    }
    this.scrollPosition = window.scrollY;
  }

  appMenuOpened: boolean = false;
  gcsMenuOpened: boolean = false;

  scrollPosition = window.scrollY;

  private subs: SubSink = new SubSink();
  @ViewChild("searchSuggestions", {static: true}) searchSuggestions: RdsDropdownDirective;
  @ViewChild("header", {static: false}) header: RdsHeaderComponent;
  public isMobile: true | false;
  public isMobile$: Observable<boolean> = this.store$.pipe(
    select(fromUiScreen.selectIsMobile), tap((res) => this.isMobile = res));
  public areOpenedQuickLinks: boolean;
  public areOpenedQuickLinks$ = this.store$.pipe(
    select(fromQuickLink.selectQuickLinksOpened));

  public selectLinks$ = this.store$.pipe(
    select(fromHeader.selectNodes));

  suggestionPanelCondition$: Observable<{
    inputFocused: boolean;
    ignoreFocus: boolean;
    phraseLength: number;
    suggestionsLength: number;
    suggestionsLoading: boolean;
  }> = this.store$.pipe(
    select(fromHeader.selectSuggestionPanelCondition)
  );

  headerSearchOpened: boolean = false;

  public allLinks;
  public bottomLinks;
  public topLinks;

  suggestionsLength: number;
  suggestionsLoading: boolean;

  _suggestionsOpened: boolean;
  get suggestionsOpened() {
    return this._suggestionsOpened;
  }

  set suggestionsOpened(value: boolean) {
    this._suggestionsOpened = value;
    this.setScrollDisabled(value);
  }

  onHide() {
    this.suggestionsOpened = this.header.searchbar.searchInput.nativeElement === document.activeElement && ((!this.suggestionsLoading && this.suggestionsLength > 0) || this.suggestionsLoading);
  }

  ngAfterViewInit(): void {
    this.setRdsInputHooks();
    this.subs.sink = this.activatedRoute.queryParams.subscribe(params => {
      if (params.phrase) {
        this.header.searchbar.searchValue = params.phrase;
        (this.header.searchbar.searchInput.nativeElement as HTMLInputElement).value = params.phrase;
        this.store$.dispatch(fromHeaderActions.setPhrase({phrase: params.phrase}));
        this.store$.dispatch(fromHeaderActions.loadSuggestions({text: params.phrase}));
      } else {
        this.header.searchbar.searchValue = '';
        (this.header.searchbar.searchInput.nativeElement as HTMLInputElement).value = '';
      }
    });

    this.subs.sink = this.suggestionPanelCondition$.pipe(
    ).subscribe(res => {
      this.suggestionsLength = res.suggestionsLength;
      this.suggestionsLoading = res.suggestionsLoading;
      this.suggestionsOpened = res.phraseLength > 2 && res.suggestionsLength > 0 && res.inputFocused;
    })
  }

  ngOnInit(): void {


    this.subs.sink = this.selectLinks$.subscribe(nodes => {
      this.bottomLinks = nodes.filter(n => n.link !== 'Tools ' && n.link !== 'Tools & Services');
      this.topLinks = nodes.filter(n => n.link === 'Tools ' || n.link === 'Tools & Services');
      this.allLinks = nodes;
      this.cdr.detectChanges();
    });

    this.subs.sink = this.areOpenedQuickLinks$.subscribe((result) => {
      this.setScrollDisabled(result)
      this.areOpenedQuickLinks = result;
    });
  }

  logoClick() {
    this.store$.dispatch(fromRouter.go({path: '/', queryParams: {}}));
  }

  setRdsInputHooks() {
    (this.header.searchbar.searchInput.nativeElement as HTMLInputElement).onfocus = () => {
      this.suggestionsOpened = true;
      this.store$.dispatch(fromHeaderActions.searchFocused());
    }
    (this.header.searchbar.searchInput.nativeElement as HTMLInputElement).onblur = () => {
      this.store$.dispatch(fromHeaderActions.searchBlurred());
    }

    this.header.searchbar.closeSearch.subscribe(res => {
      this.suggestionsOpened = false;
      this.header.searchValue = '';
      (this.header.searchbar.searchInput.nativeElement as HTMLInputElement).value = '';
      this.store$.dispatch(fromHeaderActions.clearSuggestions());
    })

  }

  setScrollDisabled(disabled: boolean) {
    const className = 'disabled-scroll';
    if (disabled) {
      document.body.className = className;
    } else {
      if (document.body.className.indexOf(className) > -1) {
        document.body.className = document.body.className.replace(className, '');
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  search(phrase: string) {
    this.store$.dispatch(fromHeaderActions.goToSearch({phrase}));
  }

  searchChange(phrase: string) {
    this.store$.dispatch(fromHeaderActions.setSuggestionPhrase({phrase}));
    this.store$.dispatch(fromHeaderActions.loadSuggestions({text: phrase}));
  }

  closeSuggestions() {
    this.suggestionsOpened = false;
    this.header.searchbar.onCloseSearch();
  }

  public onQuickLinksToggle(): void {
    if (this.areOpenedQuickLinks) {
      this.store$.dispatch(fromQuickLink.closeHeaderQuickLinks());
    } else {
      this.store$.dispatch(fromQuickLink.toggleHeaderQuickLinks());
    }
  }

  openAppMenu() {
    this.appMenuOpened = true;
    this.setScrollDisabled(true);
  }

  closeAppMenu() {
    this.appMenuOpened = false;
    this.setScrollDisabled(false);
  }

  openGcsMenu() {
    this.gcsMenuOpened = true;
    this.setScrollDisabled(true);
  }

  closeGcsMenu() {
    this.gcsMenuOpened = false;
    this.setScrollDisabled(false);
  }

  constructor(private store$: Store, private activatedRoute: ActivatedRoute, private router: Router, private cdr: ChangeDetectorRef) {
  }
}
