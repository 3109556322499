import { AfterContentInit, Directive, EventEmitter, Output, Renderer2 } from '@angular/core';
import { SaveTemplateDialogComponent } from './save-template-dialog.component';

@Directive({
  selector: '[rnbSkeleton]'
})
export class SkeletonDirective implements AfterContentInit {

  @Output() skeletoned: EventEmitter<any> = new EventEmitter();
  constructor(private host: SaveTemplateDialogComponent, private renderer: Renderer2) {
   }
  ngAfterContentInit(): void {
    setTimeout(() => {
      const native = this.host.image.nativeElement;
      const allImages = native.querySelectorAll("img");
      const allMedias = native.querySelectorAll("figure.media");
      allMedias.forEach(media => {
        const videoPlaceholder = (this.host.videoPlaceholder.nativeElement as HTMLDivElement).cloneNode(true);
        this.renderer.setStyle(videoPlaceholder, 'width', media.clientWidth+'px')
        this.renderer.setStyle(videoPlaceholder, 'height', media.clientHeight+'px');
        const parent = media.parentElement;
        parent.insertBefore(videoPlaceholder, media);
        media.remove()
      });

      allImages.forEach(image => {
        const imagePlaceholder = (this.host.imagePlaceholder.nativeElement as HTMLDivElement).cloneNode(true);
        this.renderer.setStyle(imagePlaceholder, 'width', image.clientWidth+'px')
        this.renderer.setStyle(imagePlaceholder, 'height', image.clientHeight+'px');
        const parent = image.parentElement;
        parent.insertBefore(imagePlaceholder, image);
        image.remove()
      });
      this.skeletoned.emit();
    }, 1000)


  }

}
