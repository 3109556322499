import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';

@Injectable()
export class UserChannelsEffects {

  constructor(
    private actions$: Actions,
  ) {}
}
