import {ContentItem, Pagination} from '@app/core/models';
import {Action, createReducer, on} from '@ngrx/store';
import * as fromActions from '@home/store/subscriptions/subscriptions.actions';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {LoadingState} from '@app/search/models/rh';
import {SortEnum} from '@core/enums/sort.enum';
import {getSelectedFromLocalAndMapFrom, sortAndFilter} from './utils';
import { ChannelsSubscriptionFilters, INITIAL_CHANNELS_SUBSCRIPTION_FILTERS } from '@app/shared/filters/models/channels-subscription';

export const feature = 'subscriptions';

export enum SubscriptionType {
  AUTHORS = 'authors',
  CHANNELS = 'channels',
  TOPICS = 'topics'
}

export interface ChannelSubscriptionFilter {
  name: string;
  locations: Array<string>;
  departments: Array<string>;
  functions: Array<string>;
}

export interface Subscription {
  id: string;
  url: string;
  name: string;
  isMandatory: boolean;
  type: SubscriptionType;
  tooltip?: string;
}

export const newsAdapter: EntityAdapter<ContentItem> = createEntityAdapter<ContentItem>({});


export interface UserSubscription {
  subscriptions: Array<Subscription>;
  filtered: Array<Subscription>;
  selected: Array<Subscription>;
  suggested: Array<Subscription>;
  local: {
    news: EntityState<ContentItem>,
    pagination: Pagination
  },
  global: {
    news: EntityState<ContentItem>,
    pagination: Pagination
  }
  newsLoading: LoadingState;
  isLikeProcessing: boolean;
  suggestedLoading: boolean;
  suggestedLoaded: boolean;
  isLikeProcessingIds: string[];
  pagination: Pagination;
  localSearch: string;
  sortType: number;
  filters: {
    name: string;
    advancedFilters?: ChannelsSubscriptionFilters
  }
}

export interface UserSubs {
  channels: UserSubscription;
  authors: UserSubscription;
  topics: UserSubscription;
}

export interface State {
  isEdit: boolean;
  form: {
    pageIndex: number;
    pageSize: number;
    totalCount: number;
    filters: {
      name: string;
      description: string;
      departments: string[];
      locations: string[];
      functions: string[];
      departmentsChildIds?: string[];
      locationsChildIds?: string[];
      functionsChildIds?: string[];
    };
    sort: {
      name: SortEnum;
      description: SortEnum;
    };
  };
  currentType: SubscriptionType;
  userSubscriptions: UserSubs;
  userSubscriptionsLoading: LoadingState;
}

export const initialState: State = {
  isEdit: false,
  currentType: SubscriptionType.CHANNELS,
  form: {
    pageIndex: 0,
    pageSize: 1000,
    totalCount: 0,
    filters: {
      name: '',
      description: '',
      departments: [],
      locations: [],
      functions: [],
    },
    sort: {
      name: SortEnum.ASC,
      description: SortEnum.NONE
    }
  },
  userSubscriptions: {
    channels: {
      subscriptions: [],
      filtered: [],
      selected: [],
      suggested: [],
      suggestedLoading: false,
      suggestedLoaded: false,
      local: {
        news: newsAdapter.getInitialState(), pagination: {
          ...Pagination,
          pageSize: 12
        },
      },
      global: {
        news: newsAdapter.getInitialState(), pagination: {
          ...Pagination,
          pageSize: 12
        },
      },
      newsLoading: LoadingState.LOADING_ALL,
      isLikeProcessing: false,
      isLikeProcessingIds: [],
      pagination: {
        ...Pagination,
        pageSize: 12
      },
      localSearch: '',
      sortType: +localStorage.getItem('channelSortType') || 0,
      filters: {
        name: '',
        advancedFilters: INITIAL_CHANNELS_SUBSCRIPTION_FILTERS
      }
    },
    authors: {
      subscriptions: [],
      filtered: [],
      selected: [],
      suggested: [],
      suggestedLoading: false,
      suggestedLoaded: false,
      local: {
        news: newsAdapter.getInitialState(), pagination: {
          ...Pagination,
          pageSize: 12
        },
      },
      global: {
        news: newsAdapter.getInitialState(), pagination: {
          ...Pagination,
          pageSize: 12
        },
      },
      newsLoading: LoadingState.LOADING_ALL,
      isLikeProcessing: false,
      isLikeProcessingIds: [],
      pagination: {
        ...Pagination,
        pageSize: 12
      },
      localSearch: '',
      sortType: +localStorage.getItem('authorSortType') || 0,
      filters: {
        name: '',
      }
    },
    topics: {
      subscriptions: [],
      filtered: [],
      selected: [],
      suggested: [],
      suggestedLoading: false,
      suggestedLoaded: false,
      local: {
        news: newsAdapter.getInitialState(), pagination: {
          ...Pagination,
          pageSize: 12
        },
      },
      global: {
        news: newsAdapter.getInitialState(), pagination: {
          ...Pagination,
          pageSize: 12
        },
      },
      newsLoading: LoadingState.LOADING_ALL,
      isLikeProcessing: false,
      isLikeProcessingIds: [],
      pagination: {
        ...Pagination,
        pageSize: 12
      },
      localSearch: '',
      sortType: +localStorage.getItem('topicSortType') || 0,
      filters: {
        name: '',
      }
    },
  },
  userSubscriptionsLoading: LoadingState.LOADING_ALL
};

export const subscriptionsReducer = createReducer(
    initialState,
    on(fromActions.changeSubscriptionsEditMode, (state, {opened}) => ({
      ...state,
      isEdit: opened
    })),
    on(fromActions.resetPageIndex, (state, {subType}) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        [subType]: {
          ...state.userSubscriptions[subType],
          local: {
            ...state.userSubscriptions[subType].local,
            pagination: {
              ...state.userSubscriptions[subType].local.pagination,
              pageIndex: 0
            }
          },
          global: {
            ...state.userSubscriptions[subType].global,
            pagination: {
              ...state.userSubscriptions[subType].global.pagination,
              pageIndex: 0
            }
          },
          pagination: {
            ...state.userSubscriptions[subType].pagination,
            pageIndex: 0
          }
        }
      }
    })),
    on(fromActions.changeActiveSubscriptionType, (state, {subType}) => ({
      ...state,
      currentType: subType
    })),
    on(fromActions.setSubscriptionFilters, (state, {filters, subType}) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        [subType]: {
          ...state.userSubscriptions[subType],
          filters
        }
      }
    })),
    on(fromActions.getAllSubscriptionsSuccess, (state, {channels, authors, topics}) => {
      const selectedChannels = getSelectedFromLocalAndMapFrom(channels, JSON.parse(localStorage.getItem('selected-channels')));
      return {
        ...state,
        userSubscriptions: {
          ...state.userSubscriptions,
          channels: {
            ...state.userSubscriptions.channels,
            subscriptions: channels,
            selected: selectedChannels,
            filtered: sortAndFilter(channels, state.userSubscriptions.channels.localSearch)
          },
          authors: {
            ...state.userSubscriptions.authors,
            subscriptions: authors,
            selected: authors,
            filtered: sortAndFilter(authors, state.userSubscriptions.authors.localSearch),
            newsLoading: authors.length ? state.userSubscriptions.authors.newsLoading : LoadingState.FINISHED
          },
          topics: {
            ...state.userSubscriptions.topics,
            subscriptions: topics,
            selected: topics,
            filtered: sortAndFilter(topics, state.userSubscriptions.topics.localSearch),
            newsLoading: topics.length ? state.userSubscriptions.topics.newsLoading : LoadingState.FINISHED
          }
        },
        userSubscriptionsLoading: LoadingState.FINISHED
      }
    }),
    on(fromActions.getNewsForSubscriptionRequest, (state, {subType}) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        [subType]: {
          ...state.userSubscriptions[subType],
          newsLoading: state.userSubscriptions[subType].subscriptions.length > 0 ? LoadingState.LOADING_ALL : LoadingState.FINISHED,
        }
      }
    })),
    on(fromActions.loadMoreNews, (state, {subType}) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        [subType]: {
          ...state.userSubscriptions[subType],
          pagination: {
            ...state.userSubscriptions[subType].pagination,
            pageIndex: ++state.userSubscriptions[subType].pagination.pageIndex
          }
        }
      }
    })),
    on(fromActions.loadMoreNewsRequest, (state, {subType}) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        [subType]: {
          ...state.userSubscriptions[subType],
          newsLoading: LoadingState.LOADING_MORE
        }
      }
    })),
    on(fromActions.getNewsSuccess, (state, {local, global, subType, pagination}) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        [subType]: {
          ...state.userSubscriptions[subType],
          local: {
            news: newsAdapter.setAll(local.news, state.userSubscriptions[subType].local.news),
          },
          global: {
            news: newsAdapter.setAll(global.news, state.userSubscriptions[subType].global.news),
          },
          newsLoading: LoadingState.FINISHED,
          pagination
        }
      }
    })),
    on(fromActions.loadMoreNewsSuccess, (state, {local, global, subType, pagination}) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        [subType]: {
          ...state.userSubscriptions[subType],
          local: {
            news: newsAdapter.addMany(local.news, state.userSubscriptions[subType].local.news)
          },
          global: {
            news: newsAdapter.addMany(global.news, state.userSubscriptions[subType].global.news)
          },
          newsLoading: LoadingState.FINISHED,
          pagination
        }
      }
    })),
    on(fromActions.setSelectedSubscriptions, (state, {subscriptions, subType}) => {
      if (subType === SubscriptionType.CHANNELS) {
        const selected = subscriptions.map(item => item.id);
        localStorage.setItem(`selected-${subType}`, JSON.stringify(selected));
      }
      return {
        ...state,
        userSubscriptions: {
          ...state.userSubscriptions,
          [subType]: {
            ...state.userSubscriptions[subType],
            selected: subscriptions,
            newsLoading: LoadingState.LOADING_ALL,
            local: {
              news: newsAdapter.removeAll(state.userSubscriptions[subType].local.news),
            }, global: {
              news: newsAdapter.removeAll(state.userSubscriptions[subType].global.news)
            },
            pagination: {
              ...state.userSubscriptions[subType].pagination,
              pageIndex: 0
            },
          }
        }
      }
    }),
    on(fromActions.displayEmpty, (state, {subType}) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        [subType]: {
          ...state.userSubscriptions[subType],
          local: {
            news: newsAdapter.removeAll(state.userSubscriptions[subType].local.news),
          },
          global: {
            news: newsAdapter.removeAll(state.userSubscriptions[subType].global.news),
          },
          newsLoading: LoadingState.FINISHED,
          pagination: {
            ...state.userSubscriptions[subType].pagination,
            pageIndex: 0
          }
        }
      }
    })),
    on(fromActions.setLocalFilter, (state, {localSearch}) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        authors: {
          ...state.userSubscriptions.authors,
          localSearch,
          filtered: sortAndFilter(state.userSubscriptions.authors.subscriptions, localSearch)
        },
        channels: {
          ...state.userSubscriptions.channels,
          localSearch,
          filtered: sortAndFilter(state.userSubscriptions.channels.subscriptions, localSearch)
        },
        topics: {
          ...state.userSubscriptions.topics,
          localSearch,
          filtered: sortAndFilter(state.userSubscriptions.topics.subscriptions, localSearch)
        }
      }
    })),
    on(fromActions.getAllChannelsSuccess, (state, {allChannels}) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        channels: {
          ...state.userSubscriptions.channels,
          suggestedLoading: false,
          suggestedLoaded: true,
          suggested: (() => {
            let channels = allChannels;
            return channels.map((channel): Subscription => ({
              name: channel.name,
              id: channel.id,
              type: SubscriptionType.CHANNELS,
              url: `list/channel/${channel.id}`,
              isMandatory: channel.isMandatory
            }))
          })(),
        }
      }
    })),
    on(fromActions.getAllAuthorsSuccess, (state, {allAuthors}) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        authors: {
          ...state.userSubscriptions.authors,
          suggestedLoading: false,
          suggestedLoaded: true,
          suggested: allAuthors
        }
      }
    })),
    on(fromActions.getAllTopicsSuccess, (state, {allTopics}) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        topics: {
          ...state.userSubscriptions.topics,
          suggestedLoading: false,
          suggestedLoaded: true,
          suggested: allTopics
        }
      }
    })),
    on(fromActions.setSortType, (state, {sortType, subType}) => {
      localStorage.setItem('channelSortType', '' + sortType);
      return {
        ...state,
        userSubscriptions: {
          ...state.userSubscriptions,
          [subType]: {
            ...state.userSubscriptions[subType],
            sortType,
            pagination: {
              ...state.userSubscriptions[subType].pagination,
              pageIndex: 0
            }
          }
        }

      }
    }),
// channel specific
    on(fromActions.addSubscriptionByIdsSuccess, (state, {updated, subType, ids}) => {
      let selected = updated;
      if (subType === SubscriptionType.CHANNELS) {
        const tmpSelectedString = localStorage.getItem(`selected-${subType}`) || '[]';
        const tmpSelected = [...JSON.parse(tmpSelectedString), ...ids];
        localStorage.setItem(`selected-${subType}`, JSON.stringify(tmpSelected));
        selected = getSelectedFromLocalAndMapFrom(updated, tmpSelected);
      }

      return {
        ...state,
        userSubscriptions: {
          ...state.userSubscriptions,
          [subType]: {
            ...state.userSubscriptions[subType],
            subscriptions: updated,
            selected,
            filtered: sortAndFilter(updated, state.userSubscriptions[subType].localSearch)
          },
        }
      }
    }),

    on(fromActions.deleteSubscriptionByIdsSuccess, (state, {updated, subType, ids}) => {
      let selected = updated;
      if (subType === SubscriptionType.CHANNELS) {
        const tmpSelectedString = localStorage.getItem(`selected-${subType}`) || '[]';
        const tmpSelected = [...JSON.parse(tmpSelectedString).filter(item => item !== ids[0])];
        localStorage.setItem(`selected-${subType}`, JSON.stringify(tmpSelected));
        selected = getSelectedFromLocalAndMapFrom(updated, tmpSelected);
      }
      return {
        ...state,
        userSubscriptions: {
          ...state.userSubscriptions,
          [subType]: {
            ...state.userSubscriptions[subType],
            subscriptions: updated,
            selected,
            filtered: sortAndFilter(updated, state.userSubscriptions[subType].localSearch)
          },
        }
      }
    }),
    on(fromActions.setFilters, (state, {filters}) => ({
      ...state,
      form: {
        ...state.form,
        filters: {
          ...state.form.filters,
          ...filters
        }
      }
    })),
    on(fromActions.clearSuggested, (state) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        authors: {
          ...state.userSubscriptions.authors,
          suggested: [],
          filters: {
            name: '',
          }
        },
        channels: {
          ...state.userSubscriptions.channels,
          suggested: [],
          filters: {
            name: '',
            advancedFilters: INITIAL_CHANNELS_SUBSCRIPTION_FILTERS
          }
        },
        topics: {
          ...state.userSubscriptions.topics,
          suggested: [],
          filters: {
            name: '',
          }
        }
      }
    })),
    on(fromActions.clearFilteredAuthors, (state) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        authors: {
          ...state.userSubscriptions.authors,
          suggested: [],
        }
      }
    })),
    on(fromActions.clearFilteredChannels, (state) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        channels: {
          ...state.userSubscriptions.channels,
          suggested: [],
        }
      }
    })),
    on(fromActions.clearFilteredTopics, (state) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        topics: {
          ...state.userSubscriptions.topics,
          suggested: [],
        }
      }
    })),
    on(fromActions.getAllAuthorsRequest, fromActions.getAllTopicsRequest, fromActions.getAllChannelsRequest, (state) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        authors: {
          ...state.userSubscriptions.authors,
          suggestedLoading: true,
          suggestedLoaded: false
        },
        channels: {
          ...state.userSubscriptions.channels,
          suggestedLoading: true,
          suggestedLoaded: false
        },
        topics: {
          ...state.userSubscriptions.topics,
          suggestedLoading: true,
          suggestedLoaded: false
        }
      }
    })),
    on(fromActions.addLike, (state, {id, likesCount}) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        authors: {
          ...state.userSubscriptions.authors,
          local: {
            ...state.userSubscriptions.authors.local,
            news: newsAdapter.updateOne({
              id,
              changes: {
                numLikes: likesCount + 1,
                hasLiked: true
              }
            }, state.userSubscriptions.authors.local.news),
          },
          global: {
            ...state.userSubscriptions.authors.global,
            news: newsAdapter.updateOne({
              id,
              changes: {
                numLikes: likesCount + 1,
                hasLiked: true
              }
            }, state.userSubscriptions.authors.global.news)
          },
          isLikeProcessing: true,
          isLikeProcessingIds: [...state.userSubscriptions.authors.isLikeProcessingIds, id],
        },
        channels: {
          ...state.userSubscriptions.channels,
          local: {
            ...state.userSubscriptions.channels.local,
            news: newsAdapter.updateOne({
              id,
              changes: {
                numLikes: likesCount + 1,
                hasLiked: true
              }
            }, state.userSubscriptions.channels.local.news),
          },
          global: {
            ...state.userSubscriptions.channels.global,
            news:
              newsAdapter.updateOne({
                id,
                changes: {
                  numLikes: likesCount + 1,
                  hasLiked: true
                }
              }, state.userSubscriptions.channels.global.news)
          },
          isLikeProcessing: true,
          isLikeProcessingIds: [...state.userSubscriptions.channels.isLikeProcessingIds, id],
        },
        topics: {
          ...state.userSubscriptions.topics,
          local: {
            ...state.userSubscriptions.topics.local,
            news: newsAdapter.updateOne({
              id,
              changes: {
                numLikes: likesCount + 1,
                hasLiked: true
              }
            }, state.userSubscriptions.topics.local.news)
          },
          global: {
            ...state.userSubscriptions.topics.global,
            news: newsAdapter.updateOne({
              id,
              changes: {
                numLikes: likesCount + 1,
                hasLiked: true
              }
            }, state.userSubscriptions.topics.global.news)
          },
          isLikeProcessing: true,
          isLikeProcessingIds: [...state.userSubscriptions.topics.isLikeProcessingIds, id],
        }
      }
    })),
    on(fromActions.removeLike, (state, {id, likesCount}) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        authors: {
          ...state.userSubscriptions.authors,
          local: {
            ...state.userSubscriptions.authors.local,
            news: newsAdapter.updateOne({
              id,
              changes: {
                numLikes: likesCount - 1,
                hasLiked: false
              }
            }, state.userSubscriptions.authors.local.news)
          },
          global: {
            ...state.userSubscriptions.authors.global,
            news: newsAdapter.updateOne({
              id,
              changes: {
                numLikes: likesCount - 1,
                hasLiked: false
              }
            }, state.userSubscriptions.authors.global.news)
          },
          isLikeProcessing: true,
          isLikeProcessingIds: [...state.userSubscriptions.authors.isLikeProcessingIds, id],
        },
        channels: {
          ...state.userSubscriptions.channels,
          local: {
            ...state.userSubscriptions.channels.local,
            news: newsAdapter.updateOne({
              id,
              changes: {
                numLikes: likesCount - 1,
                hasLiked: false
              }
            }, state.userSubscriptions.channels.local.news)
          },
          global: {
            ...state.userSubscriptions.channels.global,
            news: newsAdapter.updateOne({
              id,
              changes: {
                numLikes: likesCount - 1,
                hasLiked: false
              }
            }, state.userSubscriptions.channels.global.news)
          },
          isLikeProcessing: true,
          isLikeProcessingIds: [...state.userSubscriptions.channels.isLikeProcessingIds, id],
        },
        topics: {
          ...state.userSubscriptions.topics,
          local: {
            ...state.userSubscriptions.topics.local,
            news: newsAdapter.updateOne({
              id,
              changes: {
                numLikes: likesCount - 1,
                hasLiked: false
              }
            }, state.userSubscriptions.topics.local.news)
          },
          global: {
            ...state.userSubscriptions.topics.global,
            news: newsAdapter.updateOne({
              id,
              changes: {
                numLikes: likesCount - 1,
                hasLiked: false
              }
            }, state.userSubscriptions.topics.global.news)
          },
          isLikeProcessing: true,
          isLikeProcessingIds: [...state.userSubscriptions.topics.isLikeProcessingIds, id],
        },
      }
    })),

    on(fromActions.addLikeSuccess, fromActions.removeLikeSuccess, (state, {id}) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        authors: {
          ...state.userSubscriptions.authors,
          isLikeProcessing: false,
          isLikeProcessingIds: [...state.userSubscriptions.authors.isLikeProcessingIds].filter(i => i !== id)
        },
        channels: {
          ...state.userSubscriptions.channels,
          isLikeProcessing: false,
          isLikeProcessingIds: [...state.userSubscriptions.channels.isLikeProcessingIds].filter(i => i !== id)
        },
        topics: {
          ...state.userSubscriptions.topics,
          isLikeProcessing: false,
          isLikeProcessingIds: [...state.userSubscriptions.topics.isLikeProcessingIds].filter(i => i !== id)
        },
      }
    })),
    on(fromActions.markRead, (state, {id}) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        authors: {
          ...state.userSubscriptions.authors,
          local: {
            ...state.userSubscriptions.authors.local,
            news:
              newsAdapter.updateOne({
                id,
                changes: {
                  hasRead: true
                }
              }, state.userSubscriptions.authors.local.news)
          },
          global: {
            ...state.userSubscriptions.authors.global,
            news: newsAdapter.updateOne({
              id,
              changes: {
                hasRead: true
              }
            }, state.userSubscriptions.authors.global.news)
          }
        },
        channels: {
          ...state.userSubscriptions.channels,
          local: {
            ...state.userSubscriptions.channels.local,
            news: newsAdapter.updateOne({
              id,
              changes: {
                hasRead: true
              }
            }, state.userSubscriptions.channels.local.news),
          },
          global: {
            ...state.userSubscriptions.channels.global,
            news: newsAdapter.updateOne({
              id,
              changes: {
                hasRead: true
              }
            }, state.userSubscriptions.channels.global.news),
          }
        },
        topics: {
          ...state.userSubscriptions.topics,
          local: {
            ...state.userSubscriptions.topics.local,
            news: newsAdapter.updateOne({
              id,
              changes: {
                hasRead: true
              }
            }, state.userSubscriptions.topics.local.news),
          },
          global: {
            ...state.userSubscriptions.topics.global,
            news: newsAdapter.updateOne({
              id,
              changes: {
                hasRead: true
              }
            }, state.userSubscriptions.topics.global.news)
          }
        },
      }
    })),
    on(fromActions.addBookmark, (state, {id}) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        authors: {
          ...state.userSubscriptions.authors,
          local: {
            ...state.userSubscriptions.authors.local,
            news: newsAdapter.updateOne({
              id,
              changes: {
                hasBookmarked: true
              }
            }, state.userSubscriptions.authors.local.news),
          },
          global: {
            ...state.userSubscriptions.authors.global,
            news: newsAdapter.updateOne({
              id,
              changes: {
                hasBookmarked: true
              }
            }, state.userSubscriptions.authors.global.news)
          }
        },
        channels: {
          ...state.userSubscriptions.channels,
          local: {
            ...state.userSubscriptions.channels.local, news: newsAdapter.updateOne({
              id,
              changes: {
                hasBookmarked: true
              }
            }, state.userSubscriptions.channels.local.news)
          },
          global: {
            ...state.userSubscriptions.channels.global, news: newsAdapter.updateOne({
              id,
              changes: {
                hasBookmarked: true
              }
            }, state.userSubscriptions.channels.global.news)
          }
        },
        topics: {
          ...state.userSubscriptions.topics,
          local: {
            ...state.userSubscriptions.topics.local,
            news: newsAdapter.updateOne({
              id,
              changes: {
                hasBookmarked: true
              }
            }, state.userSubscriptions.topics.local.news)
          },
          global: {
            ...state.userSubscriptions.topics.global,
            news: newsAdapter.updateOne({
              id,
              changes: {
                hasBookmarked: true
              }
            }, state.userSubscriptions.topics.global.news)
          }
        }
      }
    })),
    on(fromActions.removeBookmark, (state, {id}) => ({
      ...state,
      userSubscriptions: {
        ...state.userSubscriptions,
        authors: {
          ...state.userSubscriptions.authors,
          local: {
            ...state.userSubscriptions.authors.local,
            news: newsAdapter.updateOne({
              id,
              changes: {
                hasBookmarked: false
              }
            }, state.userSubscriptions.authors.local.news)
          },
          global: {
            ...state.userSubscriptions.authors.global,
            news: newsAdapter.updateOne({
              id,
              changes: {
                hasBookmarked: false
              }
            }, state.userSubscriptions.authors.global.news)
          }
        },
        channels: {
          ...state.userSubscriptions.channels,
          local: {
            ...state.userSubscriptions.channels.local,
            news: newsAdapter.updateOne({
              id,
              changes: {
                hasBookmarked: false
              }
            }, state.userSubscriptions.channels.local.news),
          },
          global: {
            ...state.userSubscriptions.channels.global,
            news: newsAdapter.updateOne({
              id,
              changes: {
                hasBookmarked: false
              }
            }, state.userSubscriptions.channels.global.news)
          }
        },
        topics: {
          ...state.userSubscriptions.topics,
          local: {
            ...state.userSubscriptions.topics.local,
            news: newsAdapter.updateOne({
              id,
              changes: {
                hasBookmarked: false
              }
            }, state.userSubscriptions.topics.local.news)
          },
          global: {
            ...state.userSubscriptions.topics.global,
            news: newsAdapter.updateOne({
              id,
              changes: {
                hasBookmarked: false
              }
            }, state.userSubscriptions.topics.global.news)
          }
        },
      }
    })),
  )
;

export function reducer(state: State | undefined, action: Action) {
  return subscriptionsReducer(state, action);
}

export const getIsEdit = (state: State) => state.isEdit;
export const getForm = (state: State) => state.form;
export const getCurrentType = (state: State) => state.currentType;

export const getSubscriptionsLoading = (state: State) => state.userSubscriptionsLoading;
export const getSubscriptions = (state: State) => state.userSubscriptions;

export const getAllUserSubscriptions = (userSubs: UserSubs) => ({
  channels: userSubs.channels.subscriptions,
  topics: userSubs.topics.subscriptions,
  authors: userSubs.authors.subscriptions
});

export const getUserChannelSubscriptions = (userSubs: UserSubs) => userSubs.channels;
export const getUserAuthorSubscriptions = (userSubs: UserSubs) => userSubs.authors;
export const getUserTopicSubscriptions = (userSubs: UserSubs) => userSubs.topics;

export const getFiltered = (subscription: UserSubscription) => subscription.filtered;
export const getFilters = (subscription: UserSubscription) => subscription.filters;
export const getSelected = (subscription: UserSubscription) => subscription.selected;
export const getSuggested = (subscription: UserSubscription) => subscription.suggested;
export const getSortTypeFor = (subscription: UserSubscription) => subscription.sortType;
export const getAll = (subscription: UserSubscription) => subscription.subscriptions;
export const getPagination = (subscription: UserSubscription) => subscription.pagination;
export const getPageIndex = (pagination: Pagination) => pagination.pageIndex;
export const getPageSize = (pagination: Pagination) => pagination.pageSize;
export const getNewsLoading = (subscription: UserSubscription) => subscription.newsLoading;
export const getIsLikeProcessing = (subscription: UserSubscription) => subscription.isLikeProcessing;
export const getIsLikeProcessingIds = (subscription: UserSubscription) => subscription.isLikeProcessingIds;
export const getLocal = (subscription: UserSubscription) => subscription.local;
export const getGlobal = (subscription: UserSubscription) => subscription.global;
export const getNews = (state: { news: EntityState<ContentItem>, pagination: Pagination }) => state.news;
export const getInsidePagination = (state: { news: EntityState<ContentItem>, pagination: Pagination }) => state.pagination;
export const getSuggestionLoading = (subscription: UserSubscription) => subscription.suggestedLoading;
export const getSuggestionLoaded = (subscription: UserSubscription) => subscription.suggestedLoaded;
export const getAllNews = newsAdapter.getSelectors().selectAll;
