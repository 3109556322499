import { ChannelsSetFormFilters, INITIAL_CHANNELS_SET_FORM_FILTERS } from '@app/shared/filters/models/channels-set-form';
import { Channel } from './channel.model';
import { Pagination } from './pagination.model';
import { Taxonomy } from './taxonomy.model';

export interface ChannelsSet {
  id?: number;
  name: string;
  locations: Array<Taxonomy>;
  departments: Array<Taxonomy>;
  functions: Array<Taxonomy>;
  channels: Array<Channel>;
}

export interface ChannelsResult {
  departments: Array<Taxonomy>;
  description: string;
  functions: Array<Taxonomy>;
  id: string;
  isMandatory: boolean;
  lastUpdate: string;
  locations: Array<Taxonomy>;
  name: string;
}

export interface ChannelsSetForm {
  id: number;
  name: string;
  audience: {
    locations: Array<string>;
    departments: Array<string>;
    functions: Array<string>;
  };
  channels: {
    filters: {
      name: string;
      advancedFilters: ChannelsSetFormFilters;
    };
    selected: Array<Channel>;
  }
}

export interface ChannelsSetFormChannelFilters {
  name: string;
  locations: Array<string>;
  departments: Array<string>;
  functions: Array<string>;
}

export interface ChannelsSetRequest {
  id?: number;
  name: string;
  locations: Array<string>;
  departments: Array<string>;
  functions: Array<string>;
  channels: Array<string>;
}

export interface PagedChannelsSet {
  data: ChannelsSet[];
  pagination: Pagination;
}

export const EMPTY_CHANNEL_SET_FORM: ChannelsSetForm = {
  id: null,
  name: null,
  audience: {
    locations: [],
    departments: [],
    functions: [],
  },
  channels: {
    filters: {
      name: '',
      advancedFilters: INITIAL_CHANNELS_SET_FORM_FILTERS
    },
    selected: []
  }
}