import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, Dictionary, EntityAdapter, EntityState} from '@ngrx/entity';

import {ContentItem} from '@core/models/content-item.model';
import * as fromActions from '@core/core-store/content-items/content-items.actions';
import forEach from '@lodash-es/forEach';

export interface State extends EntityState<ContentItem> {
}

export const adapter: EntityAdapter<ContentItem> = createEntityAdapter<ContentItem>({});

export const initialState: State = {
    ...adapter.getInitialState(),
};

const contentItemsReducer = createReducer(
    initialState,
    on(fromActions.contentItemsCollectionAddMany, (state, {contentItems}) => (
        adapter.addMany(contentItems, state)
    )),
    on(fromActions.contentItemsCollectionUpsertOne, (state, {contentItem}) => (
        adapter.upsertOne(contentItem, state)
    )),
    on(fromActions.updateNumComments, (state, {contentId, count}) => {
        if (!state.entities[contentId]) {
            return {
                ...state
            };
        }
        const numComments = state.entities[contentId].numComments + count;
        return {
            ...state,
            entities: {
                ...state.entities,
                [contentId]: {
                    ...state.entities[contentId],
                    numComments
                }
            }
        };
    }),
    on(fromActions.putBookmarkByContentIdSuccess, (state, {contentId}) => {

        const theSameItems = [];
        forEach(state.ids, id => {
            if (id.split(':')[2] === contentId.split(':')[2]) {
                theSameItems.push(id);
            }
        });

        const entities = {};

        if (!state.entities[contentId]) {
            return {
                ...state
            };
        }

        for (const key in state.entities) {

            if (state.entities.hasOwnProperty(key)) {

                const propFromObj = {...state.entities[key]};

                if (propFromObj.id && (propFromObj.id === contentId || theSameItems.includes(propFromObj.id))) {
                    entities[key] = {
                        ...propFromObj,
                        hasBookmarked: true
                    };
                } else {
                    entities[key] = {
                        ...propFromObj,
                    };
                }
            }
        }

        return {
            ...state,
            entities: {
                ...entities
            }
        };
    }),
    on(fromActions.removeBookmarkByContentIdSuccess, (state, {contentId}) => {

        const theSameItems = [];
        forEach(state.ids, id => {
            if (id.split(':')[2] === contentId.split(':')[2]) {
                theSameItems.push(id);
            }
        });

        if (!state.entities[contentId]) {
            return {
                ...state
            };
        }
        const entities = {};

        for (const key in state.entities) {

            if (state.entities.hasOwnProperty(key)) {

                const propFromObj = {...state.entities[key]};

                if (propFromObj.id && (propFromObj.id === contentId || theSameItems.includes(propFromObj.id))) {
                    entities[key] = {
                        ...propFromObj,
                        hasBookmarked: false
                    };
                } else {
                    entities[key] = {
                        ...propFromObj,
                    };
                }
            }
        }

        return {
            ...state,
            entities: {
                ...entities,
            }
        };
    }),
    on(fromActions.putLikeByContentIdSuccess, (state, {contentId, numLikes}) => {

        const theSameItems = [];
        forEach(state.ids, id => {
            if (id.split(':')[2] === contentId.split(':')[2]) {
                theSameItems.push(id);
            }
        });

        if (!state.entities[contentId]) {
            return {
                ...state
            };
        }

        const entities = {};

        for (const key in state.entities) {

            if (state.entities.hasOwnProperty(key)) {

                const propFromObj = {...state.entities[key]};

                if (propFromObj.id && (propFromObj.id === contentId || theSameItems.includes(propFromObj.id))) {
                    entities[key] = {
                        ...propFromObj,
                        hasLiked: true,
                        numLikes
                    };
                } else {
                    entities[key] = {
                        ...propFromObj,
                    };
                }
            }
        }

        return {
            ...state,
            entities: {
                ...entities
            }
        };
    }),
    on(fromActions.removeLikeByContentIdSuccess, (state, {contentId, numLikes}) => {

        const theSameItems = [];

        forEach(state.ids, id => {
            if (id.split(':')[2] === contentId.split(':')[2]) {
                theSameItems.push(id);
            }
        });

        if (!state.entities[contentId]) {
            return {
                ...state
            };
        }

        const entities = {};

        for (const key in state.entities) {

            if (state.entities.hasOwnProperty(key)) {

                const propFromObj = {...state.entities[key]};

                if (propFromObj.id && (propFromObj.id === contentId || theSameItems.includes(propFromObj.id))) {
                    entities[key] = {
                        ...propFromObj,
                        hasLiked: false,
                        numLikes
                    };
                } else {
                    entities[key] = {
                        ...propFromObj,
                    };
                }
            }
        }

        return {
            ...state,
            entities: {
                ...entities,
            }
        };
    }),
    on(fromActions.markReadSuccess, (state, {contentId}) => {

        const theSameItems = [];
        forEach(state.ids, id => {
            if (id.split(':')[2] === contentId.split(':')[2]) {
                theSameItems.push(id);
            }
        });

        if (!state.entities[contentId]) {
            return {
                ...state
            };
        }
        const entities = {};

        for (const key in state.entities) {

            if (state.entities.hasOwnProperty(key)) {

                const propFromObj = {...state.entities[key]};

                if (propFromObj.id && (propFromObj.id === contentId || theSameItems.includes(propFromObj.id))) {
                    entities[key] = {
                        ...propFromObj,
                        hasRead: true
                    };
                } else {
                    entities[key] = {
                        ...propFromObj,
                    };
                }
            }
        }

        return {
            ...state,
            entities: {
                ...entities,
            }
        };
    }),
);

export function reducer(state: State | undefined, action: Action) {
    return contentItemsReducer(state, action);
}

export const getIds = adapter.getSelectors().selectIds;
export const getEntities = adapter.getSelectors().selectEntities;
export const getAll = adapter.getSelectors().selectAll;
export const getTotal = adapter.getSelectors().selectTotal;

export const getEntityById = (entities: Dictionary<ContentItem>, {id}) => entities[id];
export const getEntitiesById = (entities: Dictionary<ContentItem>, {ids}) => ids.map(id => entities[id]);
