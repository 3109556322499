import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType} from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, debounceTime, distinctUntilChanged, map, of, switchMap, tap } from 'rxjs';

import * as fromActions from './table.actions';
import * as fromSelectors from './table.selectors';
import * as fromReducer from './table.reducer';
import { EventsService } from '@app/core/services/managing-events.service';
import { EventPreviewDialogComponent, EventPreviewDialogData } from '@app/shared/dialogs/previews/event-preview-dialog/event-preview-dialog.component';
import { RdsDialogService } from '@rds/angular-components';


@Injectable()
export class TableEffects {

  public initMyEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initMyEvents),
      switchMap(() => this.store$.pipe(
          select(fromSelectors.selectMyEventsRequestData),
          debounceTime(300),
          distinctUntilChanged((prev, next) => {
            if (prev.resetIndex && !next.resetIndex) {
              delete prev.resetIndex
              delete next.resetIndex
            }
            return JSON.stringify(prev) === JSON.stringify(next)
          }),
          map(({filters, pageIndex, pageSize, sort, resetIndex}) => ({
            filters, 
            sort,
            pageIndex: resetIndex ? 0 : pageIndex,
            pageSize
          }))
        )
      ),
      map((requestData) => fromActions.getMyEventsRequest(requestData))
    ), { dispatch: true}
  );

  public getMyEventsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getMyEventsRequest),
      switchMap((requestData) => this.eventsService.getMyEvents(requestData).pipe(
        map(res => ({
          data: res.data, pagination: res.pagination
        }))
      )),
      map(({data, pagination}) => fromActions.getMyEventsSuccess({data, pagination})),
      catchError(({ message }) => of(fromActions.getMyEventsFailure({ error: message })))

    ), { dispatch: true}
  );

  public deleteMyEventsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteMyEventsRequest),
      switchMap(({ids}) => this.eventsService.deleteEventsByIds(ids).pipe(
        map(() => ({ids}))
      )),
      map(({ids}) => fromActions.deleteMyEventsSuccess({count: ids.length}))
    ), { dispatch: true}
  );

  public initMyPastEvents$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.initMyPastEvents),
    switchMap(() => this.store$.pipe(
        select(fromSelectors.selectMyPastEventsRequestData),
        debounceTime(300),
        distinctUntilChanged((prev, next) => {
          if (prev.resetIndex && !next.resetIndex) {
            delete prev.resetIndex
            delete next.resetIndex
          }
          return JSON.stringify(prev) === JSON.stringify(next)
        }),
        map(({filters, pageIndex, pageSize, sort, resetIndex}) => ({
          filters, 
          sort,
          pageIndex: resetIndex ? 0 : pageIndex,
          pageSize
        }))
      )
    ),
    map((requestData) => fromActions.getMyPastEventsRequest(requestData))
  ), { dispatch: true}
);

public getMyPastEventsRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.getMyPastEventsRequest),
    switchMap((requestData) => this.eventsService.getMyEvents(requestData, true).pipe(
      map(res => ({
        data: res.data, pagination: res.pagination
      }))
    )),
    map(({data, pagination}) => fromActions.getMyPastEventsSuccess({data, pagination})),
    catchError(({ message }) => of(fromActions.getMyPastEventsFailure({ error: message })))
  ), { dispatch: true}
);

public deleteMyPastEventsRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.deleteMyPastEventsRequest),
    switchMap(({ids}) => this.eventsService.deleteEventsByIds(ids).pipe(
      map(() => ({ids}))
    )),
    map(({ids}) => fromActions.deleteMyPastEventsSuccess({count: ids.length}))
  ), { dispatch: true}
);

public openNewsPreview$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.openEventPreview),
    switchMap(({id, backButton}) => this.eventsService.getById(id).pipe(
      map((event) => ({event, backButton}))
    )),
    tap(({event, backButton}) => {
      const data: EventPreviewDialogData = {
        event,
        context: 'Table',
        backButton,
        buttons: {
          editEvent: {
            visible: true,
            disabled: false
          }
        }
      }
      this.dialogService.open(EventPreviewDialogComponent, {
        size: 'xl',
        height: '100%',
        maxHeight: '100%',
        data
      }
      )
    } )
  ), { dispatch: false}
);

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private eventsService: EventsService,
    private dialogService: RdsDialogService
  ) {}
}
