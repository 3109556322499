import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'rh-news-footer',
  templateUrl: './news-footer.component.html',
  styleUrls: ['./news-footer.component.scss']
})
export class NewsFooterComponent {

  @Input() websiteLinkUrl: string;
  @Input() websiteLinkName: string;
  @Input() channels: Array<{id: string, name: string}>;
  private visibleChannelsCount = 4;
  get visibleChannels() {
    return this.channels.slice(0,this.visibleChannelsCount);
  }
  get hiddenChannels() {
    return this.channels.slice(this.visibleChannelsCount, this.channels.length);
  }
  @Output() openChannelDetails: EventEmitter<{id: string}> = new EventEmitter(); 

  @Input() topics: Array<string>;
  private visibleTopicsCount = 3;
  get visibleTopics() {
    return this.topics.slice(0,this.visibleTopicsCount);
  }
  get hiddenTopics() {
    return this.topics.slice(this.visibleTopicsCount, this.topics.length);
  }

  get isWebsiteLinkGsite() {
    return this.websiteLinkUrl.includes('//sites.google.com');
  }

  get websiteLinkIcon() {
    return this.isWebsiteLinkGsite ? `<use xlink:href="assets/images/gcs-search-results/google_sites.svg#google_sites"></use>` : 'link'
  }

  get safeSvg() {
    return this.sanitizer.bypassSecurityTrustHtml(this.websiteLinkIcon);
  }

  @Output() openTopicDetails: EventEmitter<{topic: string}> = new EventEmitter(); 

  constructor(private sanitizer: DomSanitizer) { }

  channelChipsClick(event: MouseEvent, id: string) {
    this.openChannelDetails.emit({id: `news:${id}`});
  }

  topicChipsClick(event: MouseEvent, topic: string) {
    this.openTopicDetails.emit({topic});
  }

}
