import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gcsMatchHighlight'
})
export class GcsMatchHighlightPipe implements PipeTransform {

  transform(value: string, matches: Array<{start: number, end: number}>): any {
    if (value) {
    if (!matches) {
      return value;
    }
    for (let i = matches.length - 1; i >= 0; i--) {
      value = value.slice(0,matches[i].start || 0) + '<b>' + value.slice(matches[i].start || 0, matches[i].end) + '</b>' + value.slice(matches[i].end, value.length);
    }
    return value;
    }
    return '';
  }
}
