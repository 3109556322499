import { createAction, props } from '@ngrx/store';
import { Channel, ContentItem } from '@app/core/models';

export const channelsCollectionUpsertOne = createAction(
  '[STORED CHANNELS] Channels collection upsert one.',
  props<{ channel: Channel }>()
);

export const newsCollectionForChannelAddAll = createAction(
  '[STORED CHANNELS] News collection add all.',
  props<{ channelId: string; news: Array<ContentItem> }>()
);

export const addChannel = createAction(
  '[STORED CHANNELS] Add channel',
  props<{ channelId: string }>()
);

export const loadChannelsSuccess = createAction(
  '[STORED CHANNELS] Load chanels success',
  props<{ channels: Array<Channel> }>()
);

export const loadChannelsFailure = createAction(
  '[STORED CHANNELS] Load chanels error',
  props<{ error: any }>()
);
