import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BlockTypes, DEFAULT_BLOCK_SETTINGS, EventBlockProperties } from '@app/newsletter-new/models/block-type.enum';
import { createGuid } from '@app/newsletter-new/utils/helpers';
import * as fromSuggestions from '@app/root-store/suggestions';
import * as fromEvents from '@core/core-store/events';
import { Store } from '@ngrx/store';
import { RDS_DIALOG_DATA, RdsDialogRef, RdsSingleSelectFilterByFunc, RdsSingleSelectOptionComponent } from '@rds/angular-components';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { SubSink } from 'subsink';

export interface SelectEventsDialogData {
  id: number;
  blockType: BlockTypes;
  addNewAsCard?: boolean;
  settings: EventBlockProperties;
}

@Component({
  selector: 'rh-select-events-dialog',
  templateUrl: './select-events-dialog.component.html',
  styleUrls: ['./select-events-dialog.component.scss']
})
export class SelectEventsDialogComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  eventForm: FormGroup = new FormGroup({
    numberOfEvents: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(10)]),
    insertOption: new FormControl(0),
    event: new FormArray([])
  });

  get eventArray() {
    return (this.eventForm.controls.event as FormArray);
  }

  rangeSubscriptionArray = []

  constructor(
    private dialogRef: RdsDialogRef<SelectEventsDialogComponent>,
    @Inject(RDS_DIALOG_DATA) public data: SelectEventsDialogData,
    private cdr: ChangeDetectorRef,
    private store$: Store<any>
  ) { }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.createEventForm(this.data.settings);
    this.subs.sink = this.eventArray.valueChanges.pipe(
      debounceTime(300),
    ).subscribe((value: Array<any>) => {
      value.map((e, index) => {
        const eventFormGroup = this.eventArray.at(index) as FormGroup;
        const rangeFormGroup = eventFormGroup.controls.range as FormGroup;
        if (e.searchBy === 0 && !eventFormGroup.controls.rangeEvent.hasValidator(Validators.required)) {
          eventFormGroup.controls.rangeEvent.setValidators([Validators.required]);
          rangeFormGroup.controls.start.setValidators([Validators.required]);
          rangeFormGroup.controls.end.setValidators([Validators.required]);
          eventFormGroup.controls.phraseEvent.removeValidators([Validators.required]);
          
          eventFormGroup.controls.rangeEvent.updateValueAndValidity({emitEvent: false});
          rangeFormGroup.controls.start.updateValueAndValidity({emitEvent: false});
          rangeFormGroup.controls.end.updateValueAndValidity({emitEvent: false});
          eventFormGroup.controls.phraseEvent.updateValueAndValidity({emitEvent: false});
        } else if (e.searchBy === 1 && !eventFormGroup.controls.phraseEvent.hasValidator(Validators.required)) {
          eventFormGroup.controls.phraseEvent.setValidators([Validators.required]);
          eventFormGroup.controls.rangeEvent.removeValidators([Validators.required]);
          rangeFormGroup.controls.start.removeValidators([Validators.required]);
          rangeFormGroup.controls.end.removeValidators([Validators.required]);

          eventFormGroup.controls.phraseEvent.updateValueAndValidity({emitEvent: false});
          eventFormGroup.controls.rangeEvent.updateValueAndValidity({emitEvent: false});
          rangeFormGroup.controls.start.updateValueAndValidity({emitEvent: false});
          rangeFormGroup.controls.end.updateValueAndValidity({emitEvent: false});
        }
        this.cdr.markForCheck();
      });

    });

    this.subs.sink = this.eventForm.controls.numberOfEvents.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(300),
        filter(() => this.eventForm.controls.numberOfEvents.valid),
      ).subscribe(numberOfEvents => {
      const currentEventsLength = this.eventArray.length;
      if (numberOfEvents < currentEventsLength) {
        for (let i = currentEventsLength; i >= numberOfEvents; i--) {
          this.eventArray.removeAt(i);

        }
      }
      if (numberOfEvents > currentEventsLength) {
        for (let i = 0; i < numberOfEvents - currentEventsLength; i++) {
          const defaultSetting = {
            ...DEFAULT_BLOCK_SETTINGS[BlockTypes.EVENT],
            card: this.data.addNewAsCard
          };
          this.createEventForm(defaultSetting);
        }
      }
      this.cdr.markForCheck();
    });
  }

  
  addEvent() {
    const defaultSetting = {
      ...DEFAULT_BLOCK_SETTINGS[BlockTypes.EVENT],
      card: this.data.addNewAsCard
    };
    this.createEventForm(defaultSetting);
  }

  removeEvent(i) {
    this.eventArray.removeAt(i);
    this.rangeSubscriptionArray[i].unsubscribe();
    this.rangeSubscriptionArray.splice(i, 1);
  }

  eventFormChanged(value) {
  }

  saveEvent(event) {
    this.store$.dispatch(fromEvents.eventByIdCollectionUpsertOne({event}));
  }

  rangeChanged(event, index) {
    this.store$.dispatch(fromEvents.getEventsByRange({start: new Date(event.start), end: new Date(event.end)}))
    this.eventArray.at(index).enable();
    (this.eventArray.at(index) as FormGroup).controls.rangeEvent.patchValue(null)
  }
  eventChange() {
    this.cdr.detectChanges();
  }

  submit() {
    this.dialogRef.close({
      ...this.data,
      settings: this.eventForm.controls.event.value.map(e => ({
        ...e.settings,
        event: e.searchBy === 0 ? e.rangeEvent : e.phraseEvent[0],
        start: e.searchBy === 0 ? e.range.start?.getTime() : null,
        end: e.searchBy === 0 ? e.range.end?.getTime() : null,
      } as EventBlockProperties)),
      insertOption: this.eventForm.controls.insertOption.value
    });
  }
     
  filterBy: RdsSingleSelectFilterByFunc<string> = (
    text: string | null,
    item: RdsSingleSelectOptionComponent<string>
  ) => {
    if (text === null || text === "") {
      return true;
    } else {
      return item.viewValue.toLowerCase().includes(text.toLowerCase());
    }
  };
  
  getAutocompletes(prop, event) {
    this.store$.dispatch(fromSuggestions.loadSuggestion({suggestionType: 'event', prop, phrase: event}))
   }

  createEventForm(settings: EventBlockProperties) {
    const autocompleteGuid = createGuid();
    const searchBy = settings.event === null  || (settings.start && settings.end) ? 0 : 1;
    const start = (searchBy === 0 && settings.event === null) || searchBy === 1 ? null : new Date(settings.start);
    const end = (searchBy === 0 && settings.event === null) || searchBy === 1 ? null : new Date(settings.end);
    this.eventArray.push(
      new FormGroup({
        searchBy: new FormControl(searchBy),
        range: new FormGroup({
          start: new FormControl(
            start,
            searchBy === 0 ? [Validators.required] : []
          ),
          end: new FormControl(
            end,
            searchBy === 0 ? [Validators.required] : []
          ),
        }),
        rangeEvent: new FormControl(
          {value: searchBy === 0 ? settings.event : null, disabled: !settings.event},
          searchBy === 0 ? [Validators.required] : []
        ),
        phraseEvent: new FormControl(
          searchBy === 0 ? [] : [settings.event],
          searchBy === 0 ? [] : [Validators.required]
        ),
        autocompleteGuid: new FormControl(autocompleteGuid),
        settings: new FormControl(settings)
      })
    );
    this.rangeSubscriptionArray.push(
      this.eventArray.at(this.eventArray.length - 1).get('range').valueChanges
        .pipe(
          filter(({start, end}) => start && end),
          distinctUntilChanged((prev, next) => prev.start.getTime() === next.start.getTime() && prev.end.getTime() === next.end.getTime()),
          debounceTime(100)
        )
        .subscribe((event) => this.rangeChanged(event, this.eventArray.length - 1)));
    this.store$.dispatch(fromSuggestions.initSuggestion({suggestionType: 'event', prop: autocompleteGuid}));
  }
}
