import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {EndpointsService} from '@core/services/endpoints/endpoints.service';
import {ApiService, HttpError} from '@core/services/api/api.service';
import {catchError, map} from 'rxjs/operators';
import {PagedResults, Result} from '@app/core/models/search-results.model';
import {QuickLink} from '@core/models';

@Injectable()
export class SearchService {

  public getPeopleResults({search, pageIndex, pageSize, isGlobal}): Observable<PagedResults> {
    const mainUrl = this.endpoints.ENDPOINT.SEARCH.PAGED.GET;
    const dataSource = 'atroche';

    const queryParamsUrl = this.endpoints.addParams(mainUrl, {
      query: encodeURIComponent(search),
      pageIndex,
      pageSize,
      dataSource,
      isGlobal
    });

    return this.http
      .get(queryParamsUrl)
      .pipe(
        map(({results, hasMoreResults, allResultsCount}) =>
          this.addPaginationToResponse({results, hasMoreResults, allResultsCount, pageIndex, pageSize})),
        catchError((error: HttpError) => throwError(`Cannot get search results. ${error.message}`))
      );
  }

  public getContentResults({search, pageIndex, pageSize, filters}): Observable<PagedResults> {
    const mainUrl = this.endpoints.ENDPOINT.SEARCH.PAGED.PUT;
    const dataSource = 'atroche';

    const queryParamsUrl = this.endpoints.addParams(mainUrl, {
      query: encodeURIComponent(search),
      pageIndex,
      pageSize,
      dataSource
    });

    return this.http
      .put(queryParamsUrl, {...filters, personalized: true})
      .pipe(
        map(({results, hasMoreResults, allResultsCount}) =>
          this.addPaginationToResponse({results, hasMoreResults, allResultsCount, pageIndex, pageSize})),
        catchError((error: HttpError) => throwError(`Cannot get search results. ${error.message}`))
      );
  }

  public getNewsResult({search, pageIndex, pageSize, filters}): Observable<PagedResults> {
    const mainUrl = this.endpoints.ENDPOINT.SEARCH.PAGED.PUT;
    const dataSource = 'news';
    const queryParamsUrl = this.endpoints.addParams(mainUrl, {
      query: encodeURIComponent(search),
      pageIndex,
      pageSize,
      dataSource
    });

    return this.http
      .put(queryParamsUrl, {...filters, personalized: true})
      .pipe(
        map(({results, hasMoreResults, allResultsCount}) =>
          this.addPaginationToResponse({results, hasMoreResults, allResultsCount, pageIndex, pageSize})),
        catchError((error: HttpError) => throwError(`Cannot get search results. ${error.message}`))
      );
  }

  public getChannelResults({search, pageIndex, pageSize, filters}): Observable<PagedResults> {
    const mainUrl = this.endpoints.ENDPOINT.SEARCH.PAGED.PUT;
    const dataSource = 'channel';
    const queryParamsUrl = this.endpoints.addParams(mainUrl, {
      query: encodeURIComponent(search),
      pageIndex,
      pageSize,
      dataSource
    });

    return this.http
      .put(queryParamsUrl, {...filters, personalized: true})
      .pipe(
        map(({results, hasMoreResults, allResultsCount}) =>
          this.addPaginationToResponse({results, hasMoreResults, allResultsCount, pageIndex, pageSize})),
        catchError((error: HttpError) => throwError(`Cannot get search results. ${error.message}`))
      );
  }

  public getAllResults({search, pageIndex, pageSize, filters}): Observable<PagedResults> {
    const mainUrl = this.endpoints.ENDPOINT.SEARCH.PAGED.PUT;
    const dataSource = 'all';
    const queryParamsUrl = this.endpoints.addParams(mainUrl, {
      query: encodeURIComponent(search),
      pageIndex,
      pageSize,
      dataSource
    });

    return this.http
      .put(queryParamsUrl, {...filters, personalized: true})
      .pipe(
        map(({results, hasMoreResults, allResultsCount}) =>
          this.addPaginationToResponse({results, hasMoreResults, allResultsCount, pageIndex, pageSize})),
        catchError((error: HttpError) => throwError(`Cannot get search results. ${error.message}`))
      );
  }

  public getEventResults({search, pageIndex, pageSize, filters}): Observable<PagedResults> {
    const mainUrl = this.endpoints.ENDPOINT.SEARCH.PAGED.PUT;
    const dataSource = 'events';
    const queryParamsUrl = this.endpoints.addParams(mainUrl, {
      query: encodeURIComponent(search),
      pageIndex,
      pageSize,
      dataSource
    });

    return this.http
      .put(queryParamsUrl, {...filters, personalized: true})
      .pipe(
        map(({results, hasMoreResults, allResultsCount}) =>
          this.addPaginationToResponse({results, hasMoreResults, allResultsCount, pageIndex, pageSize})),
        catchError((error: HttpError) => throwError(`Cannot get search results. ${error.message}`))
      );
  }

  public addQuicklink(id: number): Observable<QuickLink> {
    const url = this.endpoints.ENDPOINT.QUICKLINKS.POST;
    return this.http
      .post(url, {catalogId: id, isFavorite: false});
  }

  public addLike(id: string): Observable<{ numLikes: number }> {
    const url = this.endpoints.ENDPOINT.CONTENT.LIKE.PUT;
    const params = {contentId: 'news:any:' + id.match(/(?:(?!-).)*/gi)[0]};
    return this.http.put(this.endpoints.addParams(url, params));
  }

  public removeLike(id: string): Observable<{ numLikes: number }> {
    const url = this.endpoints.ENDPOINT.CONTENT.LIKE.DELETE;
    const params = {contentId: 'news:any:' + id.match(/(?:(?!-).)*/gi)[0]};
    return this.http.delete(this.endpoints.addParams(url, params));
  }


  private takeFirstElements(collection, amount) {
    return collection.filter((item, index) => {
      return index < amount;
    });
  }

  private addIdToCollection(collection: Result[]): Result[] {
    return collection.map((item, index) => ({
      ...item,
      // id: index + ': ' + item.title
      id: item.id || item.title,
    }));
  }

  private addPaginationToResponse({results, allResultsCount, hasMoreResults, pageIndex, pageSize}): PagedResults {

    // FIXED BACKEND BUG!!!
    let count;

    if (allResultsCount < pageSize * pageIndex && hasMoreResults) {
      count = pageSize * pageIndex;
    } else {
      count = allResultsCount;
    }

    return {
      results: this.addIdToCollection(results.results),
      count,
      pageIndex,
      pageSize,
      isFirst: pageIndex === 1,
      isLast: !hasMoreResults,
      pageCount: Math.ceil(allResultsCount / pageSize),
    };
  }

  constructor(private http: ApiService, private endpoints: EndpointsService) {
  }
}
