import {Action, createReducer, on} from '@ngrx/store';

import {EventStatus, RhEvent, RhEventForm} from '@app/events/models/event';
import {FormStepStatus} from '@app/shared/form-status-badge/form-status-badge.component';
import cloneDeep from '@lodash-es/cloneDeep';
import * as fromActions from './form.actions';

export const feature = 'form';

export interface State {
    eventStatus: EventStatus;
    form: Partial<RhEventForm>;
    initialForm: Partial<RhEventForm>;
    audienceStatus: {
        step: FormStepStatus;
        form: string
    }
    contentStatus: {
        step: FormStepStatus;
        form: string
    }
    generalStatus: {
        step: FormStepStatus;
        form: string
    }
    touched: boolean;
    fillFormStartedAt: Date;
    formChecked: boolean;
}

export const initialState: State = {
    eventStatus: null,
    form: null,
    initialForm: null,
    audienceStatus: {
        step: FormStepStatus.NOT_DEFINED,
        form: 'INVALID'
    },
    contentStatus: {
        step: FormStepStatus.NOT_DEFINED,
        form: 'INVALID'
    },
    generalStatus: {
        step: FormStepStatus.NOT_DEFINED,
        form: 'INVALID'
    },
    touched: false,
    fillFormStartedAt: null,
    formChecked: false
};

const addReducer = createReducer(
    initialState,
    on(fromActions.initAddForm, (state) => ({
        ...state,
        fillFormStartedAt: new Date(new Date().setSeconds(0, 0))
    })),
    on(fromActions.setFormChecked, (state) => ({
        ...state,
        formChecked: true
    })),
    on(fromActions.setFormValue, (state, {form}) => ({
        ...state,
        form: {
            ...state.form,
            ...form
        }
    })),
    on(fromActions.setInitialFormValue, (state, {form}) => ({
        ...state,
        initialForm: cloneDeep(form)
    })),
    on(fromActions.setFormTouched, (state, {touched}) => ({
        ...state,
        touched
    })),
    on(fromActions.setAudienceStepStatus, (state, {status}) => ({
        ...state,
        audienceStatus: {
            ...state.audienceStatus,
            step: status
        }
    })),
    on(fromActions.setAudienceFormStatus, (state, {status}) => ({
        ...state,
        audienceStatus: {
            ...state.audienceStatus,
            form: status
        }
    })),
    on(fromActions.setContentStepStatus, (state, {status}) => ({
        ...state,
        contentStatus: {
            ...state.contentStatus,
            step: status
        }
    })),
    on(fromActions.setContentFormStatus, (state, {status}) => ({
        ...state,
        contentStatus: {
            ...state.contentStatus,
            form: status
        }
    })),
    on(fromActions.setGeneralStepStatus, (state, {status}) => ({
        ...state,
        generalStatus: {
            ...state.generalStatus,
            step: status
        }
    })),
    on(fromActions.setGeneralFormStatus, (state, {status}) => ({
        ...state,
        generalStatus: {
            ...state.generalStatus,
            form: status
        }
    })),
    on(fromActions.getEventDetailsSuccess, (state, {event, status}) => ({
        ...state,
        initialForm: cloneDeep(event),
        eventStatus: status
    })),
    on(fromActions.clearAll, (state) => ({
        ...initialState
    })),
);

export function reducer(state: State | undefined, action: Action) {
    return addReducer(state, action);
}

export const getForm = (state: State) => state.form;
export const getInitialForm = (state: State) => state.initialForm;

export const getFormChecked = (state: State) => state.formChecked;
export const getEventStatus = (state: State) => state.eventStatus;
export const getFillFormStartedAt = (state: State) => state.fillFormStartedAt;
export const getId = (form: Partial<RhEvent>) => form?.id;
export const getTitle = (form: Partial<RhEvent>) => form?.title;
export const getType = (form: Partial<RhEvent>) => form?.type;

export const getAudienceStepStatus = (state: State) => state.audienceStatus.step;
export const getAudienceFormStatus = (state: State) => state.audienceStatus.form;

export const getContentStepStatus = (state: State) => state.contentStatus.step;
export const getContentFormStatus = (state: State) => state.contentStatus.form;

export const getGeneralStepStatus = (state: State) => state.generalStatus.step;
export const getGeneralFormStatus = (state: State) => state.generalStatus.form;

export const getTouched = (state: State) => state.touched;
