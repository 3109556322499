import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RhEvent, RhEventType} from '@app/events/models/event';
import {zonedTimeToUtc} from 'date-fns-tz';

@Component({
  selector: 'rh-event-list-item',
  templateUrl: './event-list-item.component.html',
  styleUrls: ['./event-list-item.component.scss']
})
export class EventListItemComponent {
  @Input() event!: RhEvent;
  @Output() eventOpened: EventEmitter<{ id: number }> = new EventEmitter();
  @Output() addedToGcal: EventEmitter<RhEvent> = new EventEmitter();
  @Input() forceHoverState: boolean = false;
  @Input() disableAnimation: boolean = false;

  constructor() {
  }

  get zonedTime() {
    return zonedTimeToUtc(this.event.startDate || this.event.date, this.event.timeZone);
  }

  openEvent(id, ev) {
    this.eventOpened.emit({id: this.event.id});
    ev.stopPropagation();
  }

  addEventToGcal(ev) {
    this.addedToGcal.emit(this.event);
    ev.stopPropagation();
  }

  get eventTime() {
    // if (this.dateInEventTimeZone) {
    //   return this.event.startDate || this.event.date;
    // }
    return zonedTimeToUtc(this.event.startDate || this.event.date, this.event.timeZone);
  }

  protected readonly RhEventType = RhEventType;
}
