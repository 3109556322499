import { Component, Input } from '@angular/core';
import { ImageBlockProperties } from '@app/newsletter-new/models/block-type.enum';
import { TagTypes } from '@app/shared/utils/get-ses-tags';

@Component({
	selector: 'rh-image-element',
	templateUrl: './image-element.component.html',
	styleUrls: ['./image-element.component.scss'],
})
export class ImageElementComponent {
	TagTypes = TagTypes;
	@Input() blockProperties: ImageBlockProperties;
}
