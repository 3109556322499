export enum ScreenModeEnum {
    DESKTOP = 'DESKTOP',
    TABLET = 'TABLET',
    MOBILE = 'MOBILE',
}

export enum ScreenOrientationEnum {
    PORTRAIT = 'PORTRAIT',
    LANDSCAPE = 'LANDSCAPE'
}

export interface ScreenBreakpoint {
    mode: ScreenModeEnum;
    orientation: ScreenOrientationEnum;
}

// RDS

export type RDSBreakpoint = 's' | 'm' | 'l' | 'xl' | 'xxl';
const RDSBreakpointMin = {
    S: 0,
    M: 768,
    L: 1024,
    XL: 1440,
    XXL: 1672,
    DESKTOP_ONLY: 1280,
  }
  
  const RDSBreakpointDiff = 0.000001;
  
  export const RDSBreakpointsMediaQuery = {
    S: `(max-width: ${RDSBreakpointMin.M - RDSBreakpointDiff}px)`,
    M: `(min-width: ${RDSBreakpointMin.M}px) and (max-width: ${RDSBreakpointMin.L - RDSBreakpointDiff}px)`,
    L: `(min-width: ${RDSBreakpointMin.L}px) and (max-width: ${RDSBreakpointMin.XL - RDSBreakpointDiff}px)`,
    XL: `(min-width: ${RDSBreakpointMin.XL}px) and (max-width: ${RDSBreakpointMin.XXL - RDSBreakpointDiff}px)`,
    XXL: `(min-width: ${RDSBreakpointMin.XXL}px)`,
    DESKTOP_ONLY: `(min-width: ${RDSBreakpointMin.DESKTOP_ONLY}px)`,
  }