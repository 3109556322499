import { createSelector } from '@ngrx/store';
import * as fromReducer from './sitemap.reducer';
import * as fromUi from '../ui.reducer';

export const selectRoutes = createSelector(
  fromUi.selectState,
  fromUi.getSiteMap
);

export const selectAllRoutes = createSelector(
  selectRoutes,
  fromReducer.getRoutesData
);

export const selecRouteByUrl = (url: string) => createSelector(
  selectAllRoutes,
  (routes) => routes.find(r => r.url === url)
)

export const selectLoading = createSelector(
  selectRoutes,
  fromReducer.getLoading
)

export const selectFilters = createSelector(
  selectRoutes,
  fromReducer.getFilters
);

export const selectDefaultFilters = createSelector(
  selectRoutes,
  fromReducer.getDefaultFilters
);

export const selectFilteredData = createSelector(
  selectAllRoutes,
  selectFilters,
  (routes, filters) => routes.filter(r => {
    let moduleFilter = true;
    if (!!filters?.module.value && filters.module.value.length > 0) {
      moduleFilter = moduleFilter && filters.module.value.includes(r.module);
    }
    let searchFilter = true;
    if (!!filters?.search.value && filters.search.value.length > 0) {
      searchFilter = searchFilter && (r.url.trim().toLowerCase().includes(filters.search.value.trim().toLowerCase()) || r.title.trim().toLowerCase().includes(filters.search.value.trim().toLowerCase()));
    }
    let statusFilter = true;
    if (filters?.status.value.length > 0) {
      statusFilter = statusFilter && filters.status.value.includes(r.status);
    }

    let hasNotesFilter = true;
    if (filters?.hasNotes.value !== null) {
      if (filters.hasNotes.value) {
        hasNotesFilter = hasNotesFilter && r.notes.length > 0;
      } else {
        hasNotesFilter = hasNotesFilter && r.notes.length === 0;
      }
    }
    return moduleFilter && searchFilter && statusFilter && hasNotesFilter
  })
)