import {QuickLink, SavedFilters, TaxonomyFlat} from '@app/core/models';
import {createAction, props} from '@ngrx/store';
import {AllResult, ChannelResult, ContentResult, EventResult, NewsResult, PeopleResult} from '.';

export const clearSearch = createAction(
  '[RH SEARCH] clear search'
);

export const clearPreferences = createAction(
  '[RH SEARCH] Clear preferences'
);

export const setSearchType = createAction(
  '[RH SEARCH] Set searchType',
  props<{
    searchType: string
  }>()
);

export const loadResults = createAction(
  '[RH SEARCH] Load results'
);

export const filtersChanged = createAction(
  '[RH SEARCH] Filters changed',
  props<{
    filters: {
      locations: Array<string>,
      departments: Array<string>,
      functions: Array<string>,
      categories: Array<string>,
    }
  }>()
)

export const setFiltersCounter = createAction(
  '[RH SEARCH] Filters counter changed',
  props<{
    filters: {
      locations: Array<string>,
      departments: Array<string>,
      functions: Array<string>,
      categories: Array<string>,
    }
  }>()
)
export const setFiltersCounterAsNumbers = createAction(
  '[RH SEARCH] Filters counter as numbers',
  props<{
    filtersCounter: {
      locations: number,
      departments: number,
      functions: number,
      categories: number,
      total: number,
    }
  }>()
)

export const loadAllResults = createAction(
  '[RH SEARCH] Load All results',
  props<{
    phrase: string,
    filters: {
      locations: Array<TaxonomyFlat>,
      departments: Array<TaxonomyFlat>,
      functions: Array<TaxonomyFlat>,
      categories: Array<string>,
    }
  }>()
);
export const loadPeopleResults = createAction(
  '[RH SEARCH] Load People results',
  props<{
    phrase: string,
    filters: {
      locations: Array<TaxonomyFlat>,
      departments: Array<TaxonomyFlat>,
      functions: Array<TaxonomyFlat>,
      categories: Array<string>,
    }
  }>()
);

export const loadMoreAllResults = createAction(
  '[RH SEARCH] Load more All results'
);

export const loadAllSuccess = createAction(
  '[RH SEARCH] Load All results success',
  props<{
    count: number,
    isFirst: boolean,
    isLast: boolean,
    pageCount: number,
    pageIndex: number,
    pageSize: number,
    results: Array<AllResult>
  }>()
);

export const loadMoreAllSuccess = createAction(
  '[RH SEARCH] Load more All results success',
  props<{
    count: number,
    isFirst: boolean,
    isLast: boolean,
    pageCount: number,
    pageIndex: number,
    pageSize: number,
    results: Array<AllResult>
  }>()
);


export const loadAllFailure = createAction(
  '[RH SEARCH] Load All results failure',
);

export const loadMorePeopleResults = createAction(
  '[RH SEARCH] Load more People results'
);

export const loadPeopleSuccess = createAction(
  '[RH SEARCH] Load People results success',
  props<{
    count: number,
    isFirst: boolean,
    isLast: boolean,
    pageCount: number,
    pageIndex: number,
    pageSize: number,
    results: Array<PeopleResult>
  }>()
);

export const loadMorePeopleSuccess = createAction(
  '[RH SEARCH] Load more People results success',
  props<{
    count: number,
    isFirst: boolean,
    isLast: boolean,
    pageCount: number,
    pageIndex: number,
    pageSize: number,
    results: Array<PeopleResult>
  }>()
);

export const loadPeopleFailure = createAction(
  '[RH SEARCH] Load People results failure',
);

export const loadContentResults = createAction(
  '[RH SEARCH] Load Content results',
  props<{
    phrase: string,
    filters: {
      locations: Array<TaxonomyFlat>,
      departments: Array<TaxonomyFlat>,
      functions: Array<TaxonomyFlat>,
      categories: Array<string>;
    }
  }>()
);

export const loadMoreContentResults = createAction(
  '[RH SEARCH] Load more Content results'
);

export const loadContentSuccess = createAction(
  '[RH SEARCH] Load Content results success',
  props<{
    count: number,
    isFirst: boolean,
    isLast: boolean,
    pageCount: number,
    pageIndex: number,
    pageSize: number,
    results: Array<ContentResult>
  }>()
);

export const loadMoreContentSuccess = createAction(
  '[RH SEARCH] Load more Content results success',
  props<{
    count: number,
    isFirst: boolean,
    isLast: boolean,
    pageCount: number,
    pageIndex: number,
    pageSize: number,
    results: Array<ContentResult>
  }>()
);

export const loadContentFailure = createAction(
  '[RH SEARCH] Load Content results failure',
);

export const addQuicklink = createAction(
  '[RH SEARCH] Add QuickLink',
  props<{ catalogId: number }>()
);

export const addQuicklinkSuccess = createAction(
  '[RH SEARCH] Add QuickLink success',
  props<{
    quickLink: QuickLink
  }>()
);

export const addQuicklinkFailure = createAction(
  '[RH SEARCH] Add QuickLink failure',
  // props<{ catalogId: number}>()
);

export const loadChannelsResults = createAction(
  '[RH SEARCH] Load Channels results',
  props<{
    phrase: string,
    filters: {
      locations: Array<TaxonomyFlat>,
      departments: Array<TaxonomyFlat>,
      functions: Array<TaxonomyFlat>,
      categories: Array<string>,
    }
  }>()
);

export const loadMoreChannelsResults = createAction(
  '[RH SEARCH] Load more Channels results',
);

export const loadChannelsSuccess = createAction(
  '[RH SEARCH] Load Channels results success',
  props<{
    count: number,
    isFirst: boolean,
    isLast: boolean,
    pageCount: number,
    pageIndex: number,
    pageSize: number,
    results: Array<ChannelResult>
  }>()
);

export const loadMoreChannelsSuccess = createAction(
  '[RH SEARCH] Load more Channels results success',
  props<{
    count: number,
    isFirst: boolean,
    isLast: boolean,
    pageCount: number,
    pageIndex: number,
    pageSize: number,
    results: Array<ChannelResult>
  }>()
);

export const loadChannelsFailure = createAction(
  '[RH SEARCH] Load Channels results failure',
);
export const loadNewsResults = createAction(
  '[RH SEARCH] Load News results',
  props<{
    phrase: string,
    filters: {
      locations: Array<TaxonomyFlat>,
      departments: Array<TaxonomyFlat>,
      functions: Array<TaxonomyFlat>,
      categories: Array<string>,
    }
  }>()
);

export const loadMoreNewsResults = createAction(
  '[RH SEARCH] Load more News results'
);

export const loadNewsSuccess = createAction(
  '[RH SEARCH] Load News results success',
  props<{
    count: number,
    isFirst: boolean,
    isLast: boolean,
    pageCount: number,
    pageIndex: number,
    pageSize: number,
    results: Array<NewsResult>
  }>()
);


export const loadMoreNewsSuccess = createAction(
  '[RH SEARCH] Load more News results success',
  props<{
    count: number,
    isFirst: boolean,
    isLast: boolean,
    pageCount: number,
    pageIndex: number,
    pageSize: number,
    results: Array<NewsResult>
  }>()
);

export const loadNewsFailure = createAction(
  '[RH SEARCH] Load News results failure',
);

export const loadEventsResults = createAction(
  '[RH SEARCH] Load Events results',
  props<{
    phrase: string,
    filters: {
      locations: Array<TaxonomyFlat>,
      departments: Array<TaxonomyFlat>,
      functions: Array<TaxonomyFlat>,
      categories: Array<string>,
    }
  }>()
);

export const loadMoreEventsResults = createAction(
  '[RH SEARCH] Load more Events results'
);

export const loadEventsSuccess = createAction(
  '[RH SEARCH] Load Events results success',
  props<{
    count: number,
    isFirst: boolean,
    isLast: boolean,
    pageCount: number,
    pageIndex: number,
    pageSize: number,
    results: Array<EventResult>
  }>()
);

export const loadMoreEventsSuccess = createAction(
  '[RH SEARCH] Load more Events results success',
  props<{
    count: number,
    isFirst: boolean,
    isLast: boolean,
    pageCount: number,
    pageIndex: number,
    pageSize: number,
    results: Array<EventResult>
  }>()
);

export const loadEventsFailure = createAction(
  '[RH SEARCH] Load Events results failure',
);

export const getSavedFilters = createAction(
  '[RH SEARCH] Get saved filters'
);

export const getSavedFiltersSuccess = createAction(
  '[RH SEARCH] Get saved filters success',
  props<{
    filters: SavedFilters
  }>()
);

export const getSavedFiltersFailure = createAction(
  '[RH SEARCH] Get saved filters failure'
);

export const saveFilters = createAction(
  '[RH SEARCH] Save filters',
  props<{
    filters: {
      locations: Array<string>,
      departments: Array<string>,
      functions: Array<string>,
      categories: Array<string>,
    }
  }>()
);

export const saveFiltersSuccess = createAction(
  '[RH SEARCH] Save filters success',
  props<{
    filters: SavedFilters
  }>()
);

export const saveFiltersFailure = createAction(
  '[RH SEARCH] Save filters failure'
);

export const addLikeRequest = createAction(
  '[RH SEARCH] add like request',
  props<{
    news: NewsResult
  }>()
)
export const addLikeSuccess = createAction(
  '[RH SEARCH] add like success',
  props<{
    news: any,
    numLikes: number
  }>()
)
export const addLikeFailure = createAction(
  '[RH SEARCH] add like failure',
  props<{
    message: string
  }>()
)

export const removeLikeRequest = createAction(
  '[RH SEARCH] remove like request',
  props<{
    news: NewsResult
  }>()
)
export const removeLikeSuccess = createAction(
  '[RH SEARCH] remove like success',
  props<{
    news: any,
    numLikes: number
  }>()
)
export const removeLikeFailure = createAction(
  '[RH SEARCH] remove like failure',
  props<{
    message: string
  }>()
)
export const openMobileFilters = createAction(
  '[RH SEARCH] open mobile filters'
)

export const closeMobileFilters = createAction(
  '[RH SEARCH] close mobile filters'
)
export const closeMobileFiltersAndRevert = createAction(
  '[RH SEARCH] close and revert  mobile filters'
)
