import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { environment } from '@env/environment';
import { exhaustMap, filter, map, mergeMap, repeat, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import * as fromRouter from '@app/root-store/router/router.actions';
import * as fromActions from './newsletter-websocket.actions';
import * as fromReducer from './newsletter-websocket.reducer';
import * as fromSelectors from './newsletter-websocket.selectors';
import * as fromUser from '@app/core/user/store';
import * as fromNewsletterTable from '../newsletter-table';
import { interval, switchMap } from 'rxjs';

const KEEP_ALIVE_TIMER = 1000 * 60 * 9;

@Injectable()
export class NewsletterWebSocketEffects {
	ws: WebSocket;

	public wsConnect$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.wsConnect),
				withLatestFrom(this.store$.pipe(select(fromSelectors.selectIsWebSocketConnected))),
				tap(([action, isAlreadyConnected]) => {
					if (!isAlreadyConnected) {
						this.ws = new WebSocket(environment.websocketUrl);
						this.ws.addEventListener('open', (e) => this.openListener(e));
						this.ws.addEventListener('close', (e) => this.closeListener(e));
						this.ws.addEventListener('error', (e) => this.errorListener(e));
						this.ws.addEventListener('message', (e) => this.messageListener(e));
					}
				})
			),
		{ dispatch: false }
	);

	public wsConnectAndCheckNewsletter$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.wsConnectAndCheckNewsletter),
				withLatestFrom(this.store$.pipe(select(fromSelectors.selectIsWebSocketConnected))),
				tap(([{ newsletterId }, isAlreadyConnected]) => {
					if (!isAlreadyConnected) {
						this.ws = new WebSocket(`${environment.websocketUrl}?newsletterId=${newsletterId}`);
						this.ws.addEventListener('open', (e) => this.openListener(e));
						this.ws.addEventListener('close', (e) => this.closeListener(e));
						this.ws.addEventListener('error', (e) => this.errorListener(e));
						this.ws.addEventListener('message', (e) => this.messageListener(e));
					}
				})
			),
		{ dispatch: false }
	);

	public wsDisconnectIfConnected$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.wsDisconnectIfConnected),
				withLatestFrom(this.store$.pipe(select(fromSelectors.selectIsWebSocketConnected))),
				filter(([action, isAlreadyConnected]) => isAlreadyConnected),
				map(() => fromActions.wsDisconnect())
			),
		{ dispatch: true }
	);

	public wsDisconnect$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.wsDisconnect),
				tap(() => {
					this.ws.close();
					this.ws.removeEventListener('open', this.openListener);
					this.ws.removeEventListener('close', this.closeListener);
					this.ws.removeEventListener('error', this.errorListener);
					this.ws.removeEventListener('message', this.messageListener);
				}),
				map(() => fromActions.wsDisconnected())
			),
		{ dispatch: true }
	);

	public wsConnected$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.wsConnected),
				map(() => fromActions.wsUpdateNewsletters())
			),
		{ dispatch: true }
	);

	public keepAliveConnection$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.wsStartKeepAlive),
				exhaustMap(() =>
					interval(KEEP_ALIVE_TIMER).pipe(
						tap(() => {
							const payload = {
								action: 'keepAlive',
							};
							this.ws.send(JSON.stringify(payload));
						})
					)
				),
				takeUntil(this.actions$.pipe(ofType(fromActions.wsStopKeepAlive))),
				repeat()
			),
		{ dispatch: false }
	);

	public wsUpdateNewsletters$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.wsUpdateNewsletters),
				withLatestFrom(
					this.store$.pipe(select(fromUser.selectLogin)),
					this.store$.pipe(select(fromNewsletterTable.selectTableDataContributedIds)),
					this.store$.pipe(select(fromSelectors.selectIsWebSocketConnected))
				),
				filter(([action, login, newsletterIds, isWebSocketConnected]) => isWebSocketConnected),
				tap(([action, login, newsletterIds]) => {
					console.log('setNewslettersList: ', newsletterIds);
					const payload = {
						action: 'setNewslettersList',
						data: {
							login,
							newsletterIds,
						},
					};
					this.ws.send(JSON.stringify(payload));
				})
			),
		{ dispatch: false }
	);

	public wsUpdateNewslettersWithNewsletterId$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.wsUpdateNewslettersWithNewsletterId),
				withLatestFrom(
					this.store$.pipe(select(fromUser.selectLogin)),
					this.store$.pipe(select(fromUser.selectFullName)),
					this.store$.pipe(select(fromSelectors.selectIsWebSocketConnected))
				),
				filter(([{ action, data, newsletterId }, login, fullName, isWebSocketConnected]) => isWebSocketConnected),
				tap(([{ newsletterId, action, data }, login, fullName]) => {
					const payload = {
						action: 'updateNewsletter',
						data: {
							login,
							fullName,
							action,
							newsletterId,
							data,
						},
					};
					this.ws.send(JSON.stringify(payload));
				})
			),
		{ dispatch: false }
	);

	public wsStartWork$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.wsStartWork),
				withLatestFrom(
					this.store$.pipe(select(fromUser.selectLogin)),
					this.store$.pipe(select(fromUser.selectFullName)),
					this.store$.pipe(select(fromSelectors.selectIsWebSocketConnected))
				),
				// filter(
				//     ([{newsletterId}, workingUserLogin, workingUserFullName, isWebSocketConnected]) => isWebSocketConnected
				// ),
				tap(([{ newsletterId }, workingUserLogin, workingUserFullName]) => {
					const payload = {
						action: 'startWork',
						data: {
							workInProgress: true,
							newsletterId,
							workingUserLogin,
							workingUserFullName,
						},
					};
					console.log(payload.data.newsletterId, payload, workingUserFullName, workingUserLogin);
					this.ws.send(JSON.stringify(payload));
				})
			),
		{ dispatch: false }
	);

	public wsStopWork$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.wsStopWork),
				withLatestFrom(
					this.store$.pipe(select(fromUser.selectLogin)),
					this.store$.pipe(select(fromUser.selectFullName)),
					this.store$.pipe(select(fromSelectors.selectIsWebSocketConnected))
				),
				tap(([{ newsletterId }, workingUserLogin, workingUserFullName, isWebSocketConnected]) =>
					console.log(isWebSocketConnected)
				),
				filter(
					([{ newsletterId }, workingUserLogin, workingUserFullName, isWebSocketConnected]) => isWebSocketConnected
				),
				tap(([{ newsletterId }, workingUserLogin, workingUserFullName]) => {
					const payload = {
						action: 'stopWork',
						data: {
							workInProgress: false,
							newsletterId,
							workingUserLogin,
							workingUserFullName,
						},
					};
					console.log(payload);
					this.ws.send(JSON.stringify(payload));
				})
			),
		{ dispatch: false }
	);

	public wsCheckNewsletter$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.wsCheckNewsletter),
				withLatestFrom(
					this.store$.pipe(select(fromUser.selectLogin)),
					this.store$.pipe(select(fromUser.selectFullName)),
					this.store$.pipe(select(fromSelectors.selectIsWebSocketConnected))
				),
				filter(
					([{ newsletterId }, workingUserLogin, workingUserFullName, isWebSocketConnected]) => isWebSocketConnected
				),
				tap(([{ newsletterId }, workingUserLogin, workingUserFullName]) => {
					const payload = {
						action: 'checkNewsletter',
						data: {
							newsletterId,
							workingUserLogin,
							workingUserFullName,
						},
					};
					this.ws.send(JSON.stringify(payload));
				})
			),
		{ dispatch: false }
	);

	public wsMessageReceived$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.wsMessageReceived),
				map(({ messageType, data }) => {
					switch (messageType) {
						case 'newsletterInfo': {
							console.log('Newsletter info: ', data);
							return fromActions.wsNewsletterInfo({ newsletters: data });
						}
						// case 'newsletterTaken': {
						//   console.log('Newsletter taken: ', data);
						//   return fromActions.wsNewsletterTaken({newsletter: data});
						// }
						// case 'newsletterReleased': {
						//   console.log('Newsletter released: ', data);
						//   return fromActions.wsNewsletterReleased({newsletter: data});
						// }
						case 'newsletterAccessDenied': {
							console.log('Newsletter access denied: ', data);
							return fromActions.wsNewsletterAccessDenied({ newsletterId: data.newsletterId });
						}
						case 'newsletterAccessGranted': {
							console.log('Newsletter access granted: ', data);
							return fromActions.wsNewsletterAccessGranted({ newsletterId: data.newsletterId });
						}
						case 'updateRequired': {
							console.log('Update required: ', data);
							return fromActions.wsUpdateRequired({ id: data.newsletterId, action: data.action, data });
						}

						case 'disabled': {
							console.log('Newsletter disabled: ', data);
							return fromActions.wsNewsletterTaken({ newsletter: data });
						}
						case 'released': {
							console.log('Newsletter released: ', data);
							return fromActions.wsNewsletterReleased({ newsletter: data });
						}
						default: {
							console.log('Unknown message: ', data);
							return fromActions.wsUnknownMessage({ messageType, data });
						}
					}
				})
			),
		{ dispatch: true }
	);

	public wsNewsletterAccessDenied$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.wsNewsletterAccessDenied),
				mergeMap(() => [fromRouter.go({ path: 'newsletter/newsletters', queryParams: {} })])
			),
		{ dispatch: true }
	);

	public wsUpdateRequired$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.wsUpdateRequired),
				switchMap(({ action, data, id }) => {
					console.log(data);
					switch (action) {
						case 'renamed':
							console.log('x');
							return [
								fromNewsletterTable.updateFromWs.update({
									id,
									action,
									data: { ...data, displayName: data?.title },
								}),
							];
							break;
						case 'movedToDraft':
							return [fromNewsletterTable.updateFromWs.update({ id, action, data: { ...data, statusId: 0 } })];
							break;
						case 'deleted':
							return [
								fromNewsletterTable.updateFromWs.update({
									id,
									action,
									data: {
										...data.data,
										statusId: 99,
										displayName: data.data?.displayName || data.data?.title + ' deleted by ' + data.login,
									},
								}),
							];
							break;
						case 'shared':
							return [
								fromNewsletterTable.updateFromWs.share({
									id,
									action,
									data: {
										...data,
										newsletterContributors: [
											...data.data.newsletterContributors.added,
											...data.data.newsletterContributors.notChanged,
										],
									},
								}),
							];
						case 'contributorAdded':
							console.log('Should i add this newsletter?');
							return [];
						case 'contributorRemoved':
							return [
								fromNewsletterTable.updateFromWs.share({
									id,
									action,
									data: {
										...data,
										statusId: 99,
										displayName: data.data?.displayName || data.data?.title + ' deleted by ' + data.login,
										newsletterContributors: [
											...data.data.newsletterContributors.added,
											...data.data.newsletterContributors.notChanged,
										],
									},
								}),
							];
					}
				})
			),
		{ dispatch: true }
	);

	public wsNewsletterAccessGranted$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.wsNewsletterAccessGranted),
				mergeMap(({ newsletterId }) => [fromActions.wsStartWork({ newsletterId })])
			),
		{ dispatch: true }
	);

	// public wsNewsletterInfo$ = createEffect(() =>
	//   this.actions$.pipe(
	//     ofType(fromActions.wsNewsletterInfo),
	//     filter(({singleNewsletter}) => singleNewsletter),
	//     map(({newsletters}) =>
	//         fromActions.wsStartWork({newsletterId: newsletters[0].newsletterId}))
	//   ), { dispatch: true}
	// );

	public wsUnknownMessage$ = createEffect(() => this.actions$.pipe(ofType(fromActions.wsUnknownMessage)), {
		dispatch: false,
	});

	constructor(
		private actions$: Actions,
		private store$: Store<fromReducer.State>
	) {}

	private openListener = (e) => {
		console.log('WebSocket is connected', e);
		this.store$.dispatch(fromActions.wsSetIsConnected());
		this.store$.dispatch(fromActions.wsStartKeepAlive());
		const singleNewsletter = e.target.url.split('?')[1];
		if (singleNewsletter) {
			this.store$.dispatch(fromActions.wsCheckNewsletter({ newsletterId: singleNewsletter.split('=')[1] }));
		} else {
			this.store$.dispatch(fromActions.wsConnected());
		}
	};

	private closeListener = (e) => {
		console.log('WebSocket is closed', e);
		this.store$.dispatch(fromActions.wsStopKeepAlive());
	};

	private errorListener = (e) => {
		console.log('WebSocket is on error', e);
	};

	private messageListener = (e) => {
		console.log('WebSocket got message', e);
		const data = JSON.parse(e.data);
		this.store$.dispatch(
			fromActions.wsMessageReceived({
				messageType: data.messageType,
				data: data.data,
			})
		);
	};
}
