import { UserSearch } from '@app/core/models/user-search.model';
import { EventStatus, RhEventForm } from '@app/events/models/event';
import { FormStepStatus } from '@app/shared/form-status-badge/form-status-badge.component';
import { createAction, props } from '@ngrx/store';
export const initAddForm = createAction(
  '[EVENT - FORM] Init add form',
  props<{user: UserSearch, id?: number}>()
);

export const setFormChecked = createAction(
  '[NEWS - FORM] Set form checked'
);

export const getEventDetailsRequest = createAction(
  '[EVENT - FORM] Get event details request',
  props<{id: number}>()
);

export const getEventDetailsSuccess = createAction(
  '[EVENT - FORM] Get event details success',
  props<{event: Partial<RhEventForm>, status: EventStatus}>()
);

export const getEventDetailsFailure = createAction(
  '[EVENT - FORM] Get event details failure',
  props<{error: string}>()
);

export const setGeneralStepStatus = createAction(
  '[EVENT - FORM] Set general step status',
  props<{status: FormStepStatus}>()
);

export const setGeneralFormStatus = createAction(
  '[EVENT - FORM] Set general form status',
  props<{status: string}>()
);

export const setContentStepStatus = createAction(
  '[EVENT - FORM] Set content step status',
  props<{status: FormStepStatus}>()
);

export const setContentFormStatus = createAction(
  '[EVENT - FORM] Set content form status',
  props<{status: string}>()
);

export const setAudienceStepStatus = createAction(
  '[EVENT - FORM] Set audience step status',
  props<{status: FormStepStatus}>()
);

export const setAudienceFormStatus = createAction(
  '[EVENT - FORM] Set audience form status',
  props<{status: string}>()
);

export const setFormValue = createAction(
  '[EVENT - FORM] Set form value',
  props<{form: Partial<RhEventForm>}>()
);

export const setInitialFormValue = createAction(
  '[EVENT - FORM] Set initial form value',
  props<{form: Partial<RhEventForm>}>()
);

export const setFormTouched = createAction(
  '[EVENT - FORM] Set form touched',
  props<{touched: boolean}>()
);

export const saveAsDraftRequest = createAction(
  '[EVENT - FORM] Save as draft request',
);

export const saveAsDraftSuccess = createAction(
  '[EVENT - FORM] Save as draft success',
);

export const saveAsDraftFailure = createAction(
  '[EVENT - FORM] Save as draft failure',
  props<{error: string}>()
);

export const updateAsDraftRequest = createAction(
  '[EVENT - FORM] Update as draft request',
  props<{id: number}>()
);

export const updateAsDraftSuccess = createAction(
  '[EVENT - FORM] Update as draft success',
);

export const updateAsDraftFailure = createAction(
  '[EVENT - FORM] Update as draft failure',
  props<{error: string}>()
);

export const saveAsPublishRequest = createAction(
  '[EVENT - FORM] Save as Publish request',
);

export const saveAsPublishSuccess = createAction(
  '[EVENT - FORM] Save as Publish success',
);

export const saveAsPublishFailure = createAction(
  '[EVENT - FORM] Save as Publish failure',
  props<{error: string}>()
);

export const updateAsPublishRequest = createAction(
  '[EVENT - FORM] Update as Publish request',
  props<{id: number}>()
);

export const updateAsPublishSuccess = createAction(
  '[EVENT - FORM] Update as Publish success',
);

export const updateAsPublishFailure = createAction(
  '[EVENT - FORM] Update as Publish failure',
  props<{error: string}>()
);

export const openPublishChangesConfirmDialog = createAction(
  '[EVENT - FORM] Open publish changes confirm dialog',
  props<{ id: number }>()
);

export const clearAll = createAction(
  '[EVENT - FORM] Clear form'
);

export const openLeavePageDialog = createAction(
  '[NEWS - FORM] Open Leave Page dialog',
  props<{
    url: string
  }>()
);

export const closeForm = createAction(
  '[EVENT] Close form'
);

export const openPreviewDialog = createAction(
  '[EVENT] Open preview dialog'
);

export const openUnpublishConfirmDialog = createAction(
  '[EVENT - FORM] Open Unpublish confirm dialog',
  props<{ id: number }>()
);

export const unpublishRequest = createAction(
  '[EVENT - FORM] Unpublish',
  props<{id: number}>()
);

export const unpublishSuccess = createAction(
  '[EVENT - FORM] Unpublish success',
  props<{id: number}>()
);

export const unpublishFailure = createAction(
  '[EVENT - FORM] Unpublish failure',
  props<{error: string}>()
);

