import { format, subDays, subMonths, subWeeks, subYears } from 'date-fns';

export enum GCSOwnershipEnum {
  EVERYTHING = "EVERYTHING",
  OWNED_BY_ME = "OWNED_BY_ME"
}

export interface GCSOwnershipItem {
  displayName: string;
  value: GCSOwnershipEnum;
}

export const OWNERSHIP: Array<GCSOwnershipItem> = [
  {
    displayName: 'Everything',
    value: GCSOwnershipEnum.EVERYTHING,
  },
  {
    displayName: 'Owned by me',
    value: GCSOwnershipEnum.OWNED_BY_ME,
  }
];

export enum GCSTimeEnum {
  ANY_TIME = "ANY_TIME",
  PAST_DAY = "PAST_DAY",
  PAST_WEEK = "PAST_WEEK",
  PAST_MONTH = "PAST_MONTH",
  PAST_YEAR = "PAST_YEAR",
}

export interface GCSTimeItem {
  displayName: string;
  value: GCSTimeEnum;
}

export const TIME: Array<GCSTimeItem> = [
  {
    displayName: 'Any time',
    value: GCSTimeEnum.ANY_TIME,
  },
  {
    displayName: 'Past day',
    value: GCSTimeEnum.PAST_DAY,
  },
  {
    displayName: 'Past week',
    value: GCSTimeEnum.PAST_WEEK,
  },
  {
    displayName: 'Past month',
    value: GCSTimeEnum.PAST_MONTH,
  },
  {
    displayName: 'Past year',
    value: GCSTimeEnum.PAST_YEAR,
  },
];

export enum GCSTypeEnum {
  ANY_TYPE = "ANY_TYPE",
  TEXT_DOCUMENTS = "TEXT_DOCUMENTS",
  SPREADSHEETS = "SPREADSHEETS",
  PRESENTATIONS = "PRESENTATIONS",
  PDFS = "PDFS",
  IMAGES = "IMAGES",
  VIDEOS = "VIDEOS",
  DRAWINGS = "DRAWINGS",
  FOLDERS = "FOLDERS",
}

export interface GCSTypeItem {
  displayName: string;
  value: GCSTypeEnum;
}

export const TYPE: Array<GCSTypeItem> = [
  {
    displayName: 'Any time',
    value: GCSTypeEnum.ANY_TYPE,
  },
  {
    displayName: 'Text documents',
    value: GCSTypeEnum.TEXT_DOCUMENTS,
  },
  {
    displayName: 'Spreadsheets',
    value: GCSTypeEnum.SPREADSHEETS,
  },
  {
    displayName: 'Presentations',
    value: GCSTypeEnum.PRESENTATIONS,
  },
  {
    displayName: `PDF's`,
    value: GCSTypeEnum.PDFS,
  },
  {
    displayName: 'Images',
    value: GCSTypeEnum.VIDEOS,
  },
  {
    displayName: 'Videos',
    value: GCSTypeEnum.VIDEOS,
  },
  {
    displayName: 'Drawings',
    value: GCSTypeEnum.DRAWINGS,
  },
  {
    displayName: `Folders`,
    value: GCSTypeEnum.FOLDERS,
  },
];


export enum GCSMailTypeEnum {
  ANY_TYPE = "ANY_TYPE",
  RECEIVED = "RECEIVED",
  SENT = "SENT",
  TO_ME = "TO_ME",
}

export interface GCSMailTypeItem {
  displayName: string;
  value: GCSMailTypeEnum;
}

export const MAIL_TYPE: Array<GCSMailTypeItem> = [
  {
    displayName: 'Any time',
    value: GCSMailTypeEnum.ANY_TYPE,
  },
  {
    displayName: 'Received',
    value: GCSMailTypeEnum.RECEIVED,
  },
  {
    displayName: 'Sent',
    value: GCSMailTypeEnum.SENT,
  },
  {
    displayName: 'To me',
    value: GCSMailTypeEnum.TO_ME,
  },
];

export enum GCSFromEnum {
  FROM_ANYONE = "FROM_ANYONE",
  FROM_ME = "FROM_ME",
}

export interface GCSFromItem {
  displayName: string;
  value: GCSFromEnum;
}

export const FROM: Array<GCSFromItem> = [
  {
    displayName: 'from anyone',
    value: GCSFromEnum.FROM_ANYONE,
  },
  {
    displayName: 'From me',
    value: GCSFromEnum.FROM_ME,
  }
];


// Models from developers.google.com Query API
export interface GCSQueryRequest {
  requestOptions: GCSRequestOptions;
  query: string;
  pageSize: number;
  start: number;
  dataSourceRestrictions: Array<Partial<GCSDataSourceRestrictions>>;
  facetOptions: Array<GCSFacetOptions>;
  sortOptions: GCSSortOptions;
  queryInterpretationOptions: GCSQueryInterpretationOptions;
  contextAttributes: Array<GCSContextAttribute>;
}

export interface GCSRequestOptions {
  languageCode: string;
  debugOptions: GCSDebugOptions;
  timeZone: string;
  searchApplicationId: string;
}

export interface GCSDebugOptions {
  enableDebugging: boolean;
}

export interface GCSDataSourceRestrictions {
  source: Partial<GCSSource>;
  filterOptions: Array<GCSFilterOptions>;
}

export interface GCSSource {
  // Union field value can be only one of the following:
  name: string;
  predefinedSource: GCSPredefinedSource;
}

export enum GCSPredefinedSource {
  NONE = 'NONE',
  QUERY_HISTORY = 'QUERY_HISTORY',
  PERSON = 'PERSON',
  GOOGLE_DRIVE = 'GOOGLE_DRIVE',
  GOOGLE_GMAIL = 'GOOGLE_GMAIL',
  GOOGLE_SITES = 'GOOGLE_SITES',
  GOOGLE_GROUPS = 'GOOGLE_GROUPS',
  GOOGLE_CALENDAR = 'GOOGLE_CALENDAR',
  GOOGLE_KEEP = 'GOOGLE_KEEP',
}

export interface GCSFilterOptions {
  objectType?: string;
  filter: GCSFilter;
}

export interface GCSFilter {
  // Union field value can be only one of the following:
  valueFilter?: GCSValueFilter;
  compositeFilter?: GCSCompositeFilter;
}

export interface GCSValueFilter {
  operatorName: string;
  value: GCSValue;
}

export interface GCSValue {
  // Union field value can be only one of the following:
  stringValue?: string;
  integerValue?: string;
  doubleValue?: number;
  timestampValue?: string;
  booleanValue?: boolean;
  dateValue?: GCSDate;
}

export interface GCSCompositeFilter {
  logicOperator: GCSLogicOperator;
  subFilters: Array<GCSFilter>;
}

export enum GCSLogicOperator {
  AND = 'AND',
  OR = 'OR',
  NOT = 'NOT'
}

export interface GCSDate {
  year: number;
  month: number;
  day: number;
}

export interface GCSFacetOptions {
  sourceName: string;
  objectType: string;
  operatorName: string;
  numFacetBuckets: number;
}

export interface GCSSortOptions {
  operatorName: string;
  sortOrder: GCSSortOrder;
}

export enum GCSSortOrder {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

export interface GCSQueryInterpretationOptions {
  forceVerbatimMode: boolean;
  forceDisableSupplementalResults: boolean;
}

export interface GCSContextAttribute {
  name: string;
  value: Array<string>;
}

export interface GCSQueryResponse {
  queryInterpretation: GCSQueryInterpretation;
  results: Array<GCSSearchResult>;
  structuredResults: Array<GCSStructuredResult>;
  spellResults: Array<GCSSpellResult>;
  facetResults: Array<GCSFacetResult>;
  hasMoreResults: boolean;
  debugInfo: GCSResponseDebugInfo;
  errorInfo: GCSErrorInfo;
  resultCounts: GCSResultCounts;
  // Union field value can be only one of the following:
  resultCountEstimate: string;
  resultCountExact: string;
}

export interface GCSQueryInterpretation {
  interpretedQuery: string;
  interpretationType: GCSInterpretationType;
  reason: GCSReason;
}

export enum GCSInterpretationType {
  NONE = 'NONE',
  BLEND = 'BLEND',
  REPLACE = 'REPLACE'
}

export enum GCSReason {
  UNSPECIFIED = 'UNSPECIFIED',
  QUERY_HAS_NATURAL_LANGUAGE_INTENT = 'QUERY_HAS_NATURAL_LANGUAGE_INTENT',
  NOT_ENOUGH_RESULTS_FOUND_FOR_USER_QUERY = 'NOT_ENOUGH_RESULTS_FOUND_FOR_USER_QUERY'
}

export interface GCSStructuredResult {
  person: GCSPerson;
}

export interface GCSSpellResult {
  suggestedQuery: string;
}

export interface GCSFacetResult {
  sourceName: string;
  objectType: string;
  operatorName: string;
  buckets: Array<GCSFacetBucket>;
}

export interface GCSFacetBucket {
  count: number;
  percentage: number;
  value: GCSValue;
}

export interface GCSResponseDebugInfo {
  formattedDebugInfo: string;
}

export interface GCSErrorInfo {
  errorMessages: Array<GCSErrorMessage>;
}

export interface GCSErrorMessage {
  source: GCSSource;
  errorMessage: string;
}

export interface GCSResultCounts {
  sourceResultCounts: Array<GCSSourceResultCount>;
}

export interface GCSSourceResultCount {
  source: GCSSource;
  hasMoreResults: boolean;
  // Union field result_count can be only one of the following:
  resultCountEstimate: string;
  resultCountExact: string;
}

export interface GCSSearchResult {
  title: string;
  url: string;
  snippet: GCSSnippet;
  metadata: GCSMetadata;
  clusteredResults: Array<GCSSearchResult>;
  debugInfo: GCSResultDebugInfo;
}

export interface GCSSnippet {
  snippet: string;
  matchRanges: Array<GCSMatchRange>;
}

export interface GCSMatchRange {
  start: number;
  end: number;
}

export interface GCSMetadata {
  source: GCSSource;
  mimeType: string;
  thumbnailUrl: string;
  owner: GCSPerson;
  createTime: string;
  updateTime: string;
  fields: Array<GCSNamedProperty>;
  displayOptions: GCSResultDisplayMetadata;
  objectType: string;
}

export interface GCSResultDisplayMetadata {
  objectTypeLabel: string;
  metalines: Array<GCSResultDisplayLine>;
}

export interface GCSResultDisplayLine {
  fields: Array<GCSResultDisplayField>;
};

export interface GCSResultDisplayField {
  label: string;
  operatorName: string;
  property: GCSNamedProperty;
}

export interface GCSResultDebugInfo {
  formattedDebugInfo: string;
}

export interface GCSPerson {
  name: string;
  obfuscatedId: string;
  personNames: Array<GCSName>;
  emailAddresses: Array<GCSEmailAddress>;
  phoneNumbers: Array<GCSPhoneNumber>;
  photos: Array<GCSPhoto>;
}

export interface GCSName {
  displayName: string;
}

export interface GCSEmailAddress {
  emailAddress: string;
  primary: boolean;
  type: string;
  customType: string;
}

export interface GCSPhoneNumber {
  phoneNumber: string;
  type: GCSPhoneNumberType;
}

export enum GCSPhoneNumberType {
  OTHER = 'OTHER',
  MOBILE = 'MOBILE',
  OFFICE = 'OFFICE'
}

export interface GCSPhoto {
  url: string;
}

export interface GCSNamedProperty {
  name: string;

  // Union field value can be only one of the following:
  integerValues: GCSIntegerValues;
  doubleValues: GCSDoubleValues;
  timestampValues: GCSTimestampValues;
  booleanValue: boolean;
  objectValues: GCSObjectValues;
  enumValues: GCSEnumValues;
  dateValues: GCSDateValues;
  textValues: GCSTextValues;
  htmlValues: GCSHtmlValues;
  // End of list of possible types for union field value.
}

export interface GCSIntegerValues {
  values: Array<string>;
}

export interface GCSDoubleValues {
  values: Array<number>;
}

export interface GCSTimestampValues {
  values: Array<string>;
}

export interface GCSObjectValues {
  values: Array<GCSStructuredDataObject>;
}

export interface GCSEnumValues {
  values: Array<string>;
}

export interface GCSDateValues {
  values: Array<GCSDate>;
}

export interface GCSTextValues {
  values: Array<string>;
}

export interface GCSHtmlValues {
  values: Array<string>;
}

export interface GCSStructuredDataObject {
  properties: Array<GCSNamedProperty>;
}

// Additional models
export enum GCSExternalDatasource {
  CONNECT = 'datasources/83ab929535fea1075687bf0bc13d4c4e',
  RDM_PORTAL = 'datasources/83ab929535fea107d8d7e9cc6696de61',
  RECOMMENDED_LINKS = 'datasources/83ab929535fea107839681c5877509d1',
  ROCHE_WEBSITES = 'datasources/83ab929535fea107934bd47c3f95e774',
  GWIZ = 'datasources/83ab929535fea107d13c70154343cedd',
  NEURAL_NETWORK = 'datasources/83ab929535fea1072009560d313eeb79',
  IT_LEARNING = 'datasources/83ab929535fea107558f4ff899910e76'
}

// Models from developers.google.com / Query sources API

//use it as params for list method
export interface GCSQuerySourcesRequest {
  requestOptions: GCSRequestOptions;
  pageToken: string;
}

export interface GCSQuerySourcesResponse {
  sources: Array<GCSQuerySource>;
  nextPageToken: string;
}

export interface GCSQuerySource {
  source: GCSSource;
  shorName: string;
  displayName: string;
  operators: Array<GCSQueryOperator>;
}

export interface GCSQueryOperator {
  operatorName: string,
  lessThanOperatorName: string,
  greaterThanOperatorName: string,
  type: GCSQueryOperatorType,
  displayName: string,
  isSortable: boolean,
  isFacetable: boolean,
  isReturnable: boolean,
  isRepeatable: boolean,
  isSuggestable: boolean,
  enumValues: Array<string>,
  objectType: string
}

export enum GCSQueryOperatorType {
  UNKNOWN = 'UNKNOWN',
  INTEGER = 'INTEGER',
  DOUBLE = 'DOUBLE',
  TIMESTAMP = 'TIMESTAMP',
  BOOLEAN = 'BOOLEAN',
  ENUM = 'ENUM',
  DATE = 'DATE',
  TEXT = 'TEXT',
  HTML = 'HTML',
}


// Helper consts

export interface GCSAdvancedFilter {
  type: GCSAdvancedFilterType;
  displayName: string;
  selectedOption: GCSAdvancedFilterOption;
  options: Array<GCSAdvancedFilterOption>;
  sources: Array<Partial<GCSSource>>;
}

export interface GCSAdvancedFilterOption {
  value: string;
  displayName: string;
}

export enum GCSAdvancedFilterType {
  TIME = 'TIME',
  OWNERSHIP = 'OWNERSHIP',
  TYPE_CONTENT = 'TYPE_CONTENT',
  TYPE_MAIL = 'TYPE_MAIL',
  FROM = 'FROM'
}

export const GCS_TIME_FILTER: GCSAdvancedFilter = {
  type: GCSAdvancedFilterType.TIME,
  displayName: 'Time',
  selectedOption: {
    value: '',
    displayName: 'Any time'
  },
  options: [
    {
      value: '',
      displayName: 'Any time'
    },
    {
      value: `after:${format(subDays(new Date(), 1), 'yyyy/MM/dd')} before:${format(new Date(), 'yyyy/MM/dd')}`,
      displayName: 'Past day'
    },
    {
      value: `after:${format(subWeeks(new Date(), 1), 'yyyy/MM/dd')} before:${format(new Date(), 'yyyy/MM/dd')}`,
      displayName: 'Past week'
    },
    {
      value: `after:${format(subMonths(new Date(), 1), 'yyyy/MM/dd')} before:${format(new Date(), 'yyyy/MM/dd')}`,
      displayName: 'Past month'
    },
    {
      value: `after:${format(subYears(new Date(), 1), 'yyyy/MM/dd')} before:${format(new Date(), 'yyyy/MM/dd')}`,
      displayName: 'Past year'
    },
  ],
  sources: [
    {predefinedSource: GCSPredefinedSource.GOOGLE_GMAIL},
    {predefinedSource: GCSPredefinedSource.GOOGLE_DRIVE},
    {predefinedSource: GCSPredefinedSource.GOOGLE_SITES},
    {predefinedSource: GCSPredefinedSource.GOOGLE_GROUPS},
    {predefinedSource: GCSPredefinedSource.GOOGLE_CALENDAR},
  ]
}

export const GCS_TYPE_CONTENT_FILTER: GCSAdvancedFilter = {
  type: GCSAdvancedFilterType.TYPE_CONTENT,
  displayName: 'Type',
  selectedOption: {
    value: '',
    displayName: 'Any type'
  },
  options: [
    {
      value: '',
      displayName: 'Any type'
    },
    {
      value: 'type:document contenttype:document',
      displayName: 'Documents'
    },
    {
      value: 'type:spreadsheet contenttype:spreadsheet',
      displayName: 'Spreadsheets'
    },
    {
      value: 'type:presentation contenttype:presentation',
      displayName: 'Presentations'
    },
    {
      value: 'type:pdf contenttype:pdf',
      displayName: 'PDFs'
    },
    {
      value: 'type:image contenttype:image',
      displayName: 'Images'
    },
    {
      value: 'type:video contenttype:video',
      displayName: 'Videos'
    },
    {
      value: 'type:drawing contenttype:drawing',
      displayName: 'Drawings'
    },
    {
      value: 'type:folder contenttype:folder',
      displayName: 'Folders'
    },
  ],
  sources: [
    {predefinedSource: GCSPredefinedSource.GOOGLE_DRIVE},
    {predefinedSource: GCSPredefinedSource.GOOGLE_GROUPS},
  ]
}

export const GCS_TYPE_MAIL_FILTER: GCSAdvancedFilter = {
  type: GCSAdvancedFilterType.TYPE_MAIL,
  displayName: 'Type',
  selectedOption: {
    value: '',
    displayName: 'Any type'
  },
  options: [
    {
      value: '',
      displayName: 'Any type'
    },
    {
      value: 'to:me',
      displayName: 'Received'
    },
    {
      value: 'from:me',
      displayName: 'Sent'
    },
    {
      value: 'to:me',
      displayName: 'To me'
    },
  ],
  sources: [
    {predefinedSource: GCSPredefinedSource.GOOGLE_GMAIL},
  ]
}

export const GCS_OWNERSHIP_FILTER: GCSAdvancedFilter = {
  type: GCSAdvancedFilterType.OWNERSHIP,
  displayName: 'Ownership',
  selectedOption: {
    value: '',
    displayName: 'Everything'
  },
  options: [
    {
      value: '',
      displayName: 'Everything'
    },
    {
      value: 'owner:me',
      displayName: 'Owned by me'
    },
  ],
  sources: [
    {predefinedSource: GCSPredefinedSource.GOOGLE_DRIVE},
    {predefinedSource: GCSPredefinedSource.GOOGLE_SITES},
    {predefinedSource: GCSPredefinedSource.GOOGLE_CALENDAR},
  ]
}

export const GCS_FROM_FILTER: GCSAdvancedFilter = {
  type: GCSAdvancedFilterType.FROM,
  displayName: 'From',
  selectedOption: {
    value: '',
    displayName: 'From anyone'
  },
  options: [
    {
      value: '',
      displayName: 'From anyone'
    },
    {
      value: 'from:me',
      displayName: 'From me'
    },
  ],
  sources: [
    {predefinedSource: GCSPredefinedSource.GOOGLE_GROUPS},
  ]
}

export interface SearchSourceListItem {
  displayName: string;
  sources?: Array<Partial<GCSSource>>;
  icon?: string;
  namespace?: string;
  svg?: string
  count?: number;
  value?: string;
  filters?: {
    [key: string]: GCSAdvancedFilter;
  };
}

export enum GCSSourceName {
  ALL = 'All results',
  MAIL = 'Mail',
  DRIVE = 'Drive',
  SITES = 'Sites',
  GROUPS = 'Groups',
  CALENDAR = 'Calendar',
  CONNECT = 'Connect',
  RDM_PORTAL = 'RDM Portal',
  RECOMMENDED_LINKS = 'Recommended Links',
  NEURAL_NETWORK = 'Neural Network',
  ROCHE_WEBSITES = 'Roche Websites',
  GWIZ = 'gWiz',
  IT_LEARNING = 'IT Learning'
}

export const GCS_SOURCES_LIST: Array<SearchSourceListItem> = [
  {
    displayName: GCSSourceName.ALL,
    sources: [
      {predefinedSource: GCSPredefinedSource.GOOGLE_GMAIL},
      {predefinedSource: GCSPredefinedSource.GOOGLE_DRIVE},
      {predefinedSource: GCSPredefinedSource.GOOGLE_SITES},
      {predefinedSource: GCSPredefinedSource.GOOGLE_GROUPS},
      {predefinedSource: GCSPredefinedSource.GOOGLE_CALENDAR},
      {name: GCSExternalDatasource.CONNECT},
      {name: GCSExternalDatasource.IT_LEARNING},
      {name: GCSExternalDatasource.ROCHE_WEBSITES},
      {name: GCSExternalDatasource.GWIZ},
      {name: GCSExternalDatasource.RECOMMENDED_LINKS},
      {name: GCSExternalDatasource.NEURAL_NETWORK},
    ],
    icon: 'globe',
    namespace: 'outlined',
    filters: {
      [GCSAdvancedFilterType.OWNERSHIP]: GCS_OWNERSHIP_FILTER,
      [GCSAdvancedFilterType.TIME]: GCS_TIME_FILTER,
      [GCSAdvancedFilterType.TYPE_CONTENT]: GCS_TYPE_CONTENT_FILTER,
    }
  },
  {
    displayName: GCSSourceName.MAIL,
    sources: [
      {predefinedSource: GCSPredefinedSource.GOOGLE_GMAIL}
    ],
    icon: 'email',
    namespace: 'outlined',
    filters: {
      [GCSAdvancedFilterType.TIME]: GCS_TIME_FILTER,
      [GCSAdvancedFilterType.TYPE_MAIL]: GCS_TYPE_MAIL_FILTER,
    }
  },
  {
    displayName: GCSSourceName.DRIVE,
    sources: [
      {predefinedSource: GCSPredefinedSource.GOOGLE_DRIVE}
    ],
    icon: 'folder',
    namespace: 'outlined',
    filters: {
      [GCSAdvancedFilterType.OWNERSHIP]: GCS_OWNERSHIP_FILTER,
      [GCSAdvancedFilterType.TIME]: GCS_TIME_FILTER,
      [GCSAdvancedFilterType.TYPE_CONTENT]: GCS_TYPE_CONTENT_FILTER,
    }
  },
  {
    displayName: GCSSourceName.SITES,
    sources: [
      {predefinedSource: GCSPredefinedSource.GOOGLE_SITES}
    ],
    svg: `<use xlink:href="assets/images/gcs-search-results/google_sites.svg#google_sites"></use>`,
    filters: {
      [GCSAdvancedFilterType.OWNERSHIP]: GCS_OWNERSHIP_FILTER,
      [GCSAdvancedFilterType.TIME]: GCS_TIME_FILTER,
    }
  },
  {
    displayName: GCSSourceName.GROUPS,
    sources: [
      {predefinedSource: GCSPredefinedSource.GOOGLE_GROUPS}
    ],
    icon: 'user_group',
    namespace: 'filled',
    filters: {
      [GCSAdvancedFilterType.TIME]: GCS_TIME_FILTER,
      [GCSAdvancedFilterType.FROM]: GCS_FROM_FILTER,
      [GCSAdvancedFilterType.TYPE_CONTENT]: GCS_TYPE_CONTENT_FILTER,
    }
  },
  {
    displayName: GCSSourceName.CALENDAR,
    sources: [
      {predefinedSource: GCSPredefinedSource.GOOGLE_CALENDAR}
    ],
    icon: 'calendar',
    namespace: 'outlined',
    filters: {
      [GCSAdvancedFilterType.OWNERSHIP]: GCS_OWNERSHIP_FILTER,
      [GCSAdvancedFilterType.TIME]: GCS_TIME_FILTER,
    }
  },
  {
    displayName: GCSSourceName.CONNECT,
    sources: [
      {name: GCSExternalDatasource.CONNECT}
    ],
    icon: 'link',
    namespace: 'outlined'
  },
  {
    displayName: GCSSourceName.IT_LEARNING,
    sources: [
      {name: GCSExternalDatasource.IT_LEARNING}
    ],
    icon: 'document',
    namespace: 'outlined'
  },
  {
    displayName: GCSSourceName.RDM_PORTAL,
    sources: [
      {name: GCSExternalDatasource.RDM_PORTAL}
    ],
    icon: 'link',
    namespace: 'outlined'
  },
  {
    displayName: GCSSourceName.RECOMMENDED_LINKS,
    sources: [
      {name: GCSExternalDatasource.RECOMMENDED_LINKS}
    ],
    icon: 'link',
    namespace: 'outlined'
  },
  {
    displayName: GCSSourceName.NEURAL_NETWORK,
    sources: [
      {name: GCSExternalDatasource.NEURAL_NETWORK}
    ],
    icon: 'document',
    namespace: 'outlined'
  },
  {
    displayName: GCSSourceName.ROCHE_WEBSITES,
    sources: [
      {name: GCSExternalDatasource.ROCHE_WEBSITES}
    ],
    icon: 'link',
    namespace: 'outlined'
  },
  {
    displayName: GCSSourceName.GWIZ,
    sources: [
      {name: GCSExternalDatasource.GWIZ}
    ],
    icon: 'document',
    namespace: 'outlined'
  },
];

