import {createAction, props} from '@ngrx/store';

export const setChannelIdAndCount = createAction(
  '[HOME - HERO-SECTION] set channel id and count',
  props<{ channelId: string; newsCount?: number }>()
);

export const getNewsRequest = createAction(
  '[HOME - HERO-SECTION] request');

export const getChannelNews = createAction(
  '[HOME - HERO-SECTION] get news for channel by id request',
  props<{ id: string, pageIndex: number, pageSize: number, index: number }>());

export const getChannelNewsSuccess = createAction(
  '[HOME - HERO-SECTION] get news for channel by id success',
  props<{ id: string, data: any, index: number}>());

export const markRead = createAction(
  '[HOME - HERO-SECTION] Mark read',
  props<{ id: string }>()
);

export const addLike = createAction(
  '[HOME - HERO-SECTION] Add like',
  props<{ id: string, likesCount: number}>()
);

export const addLikeSuccess = createAction(
  '[HOME - HERO-SECTION] Add like success',
  props<{ id: string, }>()
);

export const addLikeFailure = createAction(
  '[HOME - HERO-SECTION] Add like failure',
);

export const removeLike = createAction(
  '[HOME - HERO-SECTION] Remove like',
  props<{ id: string, likesCount: number, }>()
);

export const removeLikeSuccess = createAction(
  '[HOME - HERO-SECTION] Remove like success',
  props<{ id: string}>()
);

export const removeLikeFailure = createAction(
  '[HOME - HERO-SECTION] Remove like failure',
);


export const addBookmark = createAction(
  '[HOME - HERO-SECTION] Add bookmark',
  props<{ id: string }>()
);

export const addBookmarkSuccess = createAction(
  '[HOME - HERO-SECTION] Add bookmark success',
  props<{ id: string}>()
);

export const addBookmarkFailure = createAction(
  '[HOME - HERO-SECTION] Add bookmark failure',
);

export const removeBookmark = createAction(
  '[HOME - HERO-SECTION] Remove bookmark',
  props<{ id: string }>()
);

export const removeBookmarkSuccess = createAction(
  '[HOME - HERO-SECTION] Remove bookmark success',
  props<{ id: string}>()
);

export const removeBookmarkFailure = createAction(
  '[HOME - HERO-SECTION] Remove bookmark failure',
);
