import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, createEntityAdapter, EntityAdapter, Dictionary } from '@ngrx/entity';

import * as fromActions from './channels.actions';
import { Channel } from '@app/core/models';

export const feature = 'channels';

export interface State extends EntityState<Channel> {}

export const adapter: EntityAdapter<Channel> =
 createEntityAdapter<Channel>();

export const initialState: State = {
  ...adapter.getInitialState(),
};

const channelsReducer = createReducer(
  initialState,
  on(fromActions.channelsCollectionAddAll, (state, {channels}) => (
    adapter.setAll(channels, state)
  )),
  on(fromActions.channelsCollectionAddMany, (state, {channels}) => (
    adapter.addMany(channels, state)
  )),
  on(fromActions.channelsCollectionUpsertOne, (state, {channel}) => (
    adapter.upsertOne(channel, state)
  )),
  on(fromActions.channelsCollectionRemoveOne, (state, {channel}) => (
    adapter.removeOne(channel.id, state)
  )),
);

export function reducer(state: State | undefined, action: Action) {
  return channelsReducer(state, action);
}

export const getIds = adapter.getSelectors().selectIds;
export const getEntities = adapter.getSelectors().selectEntities;
export const getAll = adapter.getSelectors().selectAll;
export const getTotal = adapter.getSelectors().selectTotal;

export const getEntityById = (entities: Dictionary<Channel>, {id}) => entities[id];
export const getEntitiesById = (entities: Dictionary<Channel>, {ids}) => ids.map(id => entities[id]);
