<div [@main]="hovered? 'hovered' : 'unhovered'" class="header">
  <div class="header__rectangle">
    <div [@rect]="hovered? 'hovered' : 'unhovered'" class="header__rectangle--date">
      <span class="text">
        <span class="day">{{ date | date:'dd' }}</span>
        <span class="month-year">&nbsp;{{ date | date:'LLLL' | uppercase }}&nbsp;</span>
        <span class="month-year">{{ date | date:'yyyy' }}</span>
      </span>
    </div>
    <div [@rectTitle]="hovered? 'hovered' : 'unhovered'" class="header__rectangle--title">
      {{title}}
    </div>
  </div>
  <div class="header__square">
    <div [@square]="hovered? 'hovered' : 'unhovered'"class="header__square--date">
      <span class="text">
        <span class="day">{{ date | date:'dd' }}</span>
        <span class="month-year">{{ date | date:'LLL' | uppercase }}</span>
        <span class="month-year">{{ date | date:'yyyy' }}
        </span>
      </span>
    </div>
    <div [@squareTitle]="hovered? 'hovered' : 'unhovered'" class="header__square--title" [rdsTooltip]="title" [rdsTooltipShowDelay]="600">
      {{title}}
    </div>
  </div>
</div>