import { InjectionToken } from '@angular/core';

const kB = 1024;
const MB = kB * kB;

const MAX_IMAGE_SIZE = 5 * MB;
const MAX_GIF_SIZE = 50 * MB;

export const MAX_FILE_UPLOAD_SIZE = new InjectionToken<{[type: string]: number}>(
  'MAX_FILE_UPLOAD_SIZE',
  {
    providedIn: 'root',
    factory: MAX_FILE_UPLOAD_SIZE_FACTORY
  })

export function MAX_FILE_UPLOAD_SIZE_FACTORY(): {[type: string]: number} {
  return {
    'image/jpeg': MAX_IMAGE_SIZE,
    'image/png': MAX_IMAGE_SIZE,
    'image/gif': MAX_GIF_SIZE,
  }
}
