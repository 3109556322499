import {SortEnum} from '@core/enums/sort.enum';
import {Pagination} from '@core/models/pagination.model';

export interface TickerForm {
  id?: number;
  type: number;
  message: string;
  link: string;
  locations: string[];
  departments: string[];
  functions: string[];
  timeZone: string;
  publishDate: string;
  publishTime: string;
  expiryDate: string;
  expiryTime: string;
}

export interface TickerNewsModel {
  type: number;
  message: string;
  link: string;
  locations: string[];
  departments: string[];
  functions: string[];
  publishDate: string;
  publishTimeZone: string;
  expireDate: string;
  expireTimeZone: string;
  id?: number;
}
export interface TickerNewsModelNeedPluckId {
  type: string | number;
  message: string;
  link: string;
  locations: {id: string}[];
  departments: {id: string}[];
  functions: {id: string}[];
  publishDate: string;
  publishTimeZone: string;
  expireDate: string;
  expireTimeZone: string;
  id?: number;
}

export interface TickerNewsTypeModel {
  label: string;
  value: number;
  class?: string;
}

export interface TickerNewsListModel {
  expireDate: string;
  id: number;
  link: string;
  message: string;
  publishDate: string;
  type: number;
}

export const TICKER_NEWS_TYPES: TickerNewsTypeModel[] = [
  {
    label: 'ticker.news',
    value: 1,
    class: 'news',
  },
  {
    label: 'ticker.updates',
    value: 2,
    class: 'updates',
  },
  {
    label: 'ticker.breaking-news',
    value: 3,
    class: 'breaking-news',
  },
  {
    label: 'ticker.events',
    value: 4,
    class: 'events',
  }
];

export interface ManagingPublishedTableForm {
  pageIndex: number;
  pageSize: number;
  totalCount: number;
  filters: {
    name: string;
  };
  sort: {
    name: SortEnum;
    description: SortEnum;
  };
}

export const EMPTY_TICKER_FORM: TickerForm = {
  id: null,
  type: 1,
  message: '',
  link: '',
  locations: [],
  departments: [],
  functions: [],
  timeZone: null,
  publishDate: null,
  publishTime: null,
  expiryDate: null,
  expiryTime: null,
}

export interface GetTickersListResponse extends Pagination {
  data: TickerNewsListModel[];
  totalCount: number;
}
export interface TickerFeed {
  id: number;
  link: string;
  message: string;
  type: number;
}
