export enum USER_ROLES_TEMPLATE {
	OWNER = 0,
	EDITOR = 1,
	VIEWER = 2,
}

export enum USER_ROLES_CAMPAIGN {
	OWNER = 1,
	EDITOR = 2,
}

export enum USER_ROLES_NEWSLETTER {
	OWNER = 0,
	EDITOR = 1,
}
