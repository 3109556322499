import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {Store} from '@ngrx/store';

@Component({
  selector: 'rh-header-mobile-suggestions',
  templateUrl: './header-mobile-suggestions.component.html',
  styleUrls: ['./header-mobile-suggestions.component.scss'],
})
export class HeaderMobileSuggestionsComponent {
  @Input() isOpen: boolean = false;
  @Input() searchValue: string;
  @Output() closeSuggestions: EventEmitter<any> = new EventEmitter();
  @HostBinding('class.opened') get opened() { return this.isOpen }; 

  constructor(
    private store$: Store
  ) {
  }

}
