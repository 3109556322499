<div [ngStyle]="{}" [style.background-color]="'white'">
  <content-loader [baseUrl]="componentsLoaderService.baseUrl"
                  [backgroundColor]="'#EAE8E5'"
                  [foregroundColor]="'#DBD6D1'"
                  [viewBox]="viewBox"
                  [speed]="3"
                  [interval]="0.75"
                  [gradientRatio]="2.4">

        <svg:rect x="0" y="0" rx="0" width="291" height="164" />
        <svg:rect x="16" y="182" rx="0" width="259" height="16" />
        <svg:rect x="16" y="206" rx="0" width="259" height="16" />
        <svg:rect x="16" y="232" rx="0" width="259" height="16" />
        <svg:rect x="16" y="280" rx="0" width="85" height="16" />
        <svg:rect x="230" y="280" rx="0" width="45" height="16" />
    </content-loader>
  </div>
