import { Pagination } from './pagination.model';

export interface GenericAccount {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  createdAt?: Date;
  isSynchronizedWithWorkday?: boolean;
}

export interface GenericAccountsFilters {
  search: string;
}

export interface GenericAccountsResponse {
  data: Array<GenericAccount>;
  pagination: Pagination;
}

export interface GenericAccountsRequest {
  order: string;
  search: string;
}

export const EMPTY_GENERIC_ACCOUNT_FORM = {
  createdAt: new Date(),
  username: '',
  email: '',
  firstName: '',
  lastName: '',
  isSynchronizedWithWorkday: false,
}