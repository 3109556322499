<rds-avatar 
#avatar="rhFetchAvatar" 
[size]="size"
[rhFetchAvatar]="user.login"
[rhFetchAvatarFetchPhoto]="fetchPhoto"
[rhFetchAvatarPhotoUrl]="user.photoUrl"
[class.rds-chip__avatar]="chipsAvatar"
[class.rds-avatar-disable-interactions]="disableTooltip || !user.name && !avatar.notFound"
[rdsTooltipDisabled]="disableTooltip || !user.name && !avatar.notFound"
[rdsTooltip]="avatar.notFound ? 'User with login '+user.login+' not found!' : (customTooltip || user.name)"
[rdsTooltipShowDelay]="300">
  <img *ngIf="!!avatar.src" [src]="avatar.src" rds-avatar-image referrerpolicy="no-referrer"/>
  <span *ngIf="!avatar.src && !avatar.notFound && !!user.name" rds-avatar-initials>{{user.name | initials}}</span>
  <rds-icon *ngIf="!avatar.src && !avatar.notFound && !user.name" rds-avatar-icon namespace="outlined" icon="user"></rds-icon>
  <rds-icon *ngIf="!avatar.src && avatar.notFound" rds-avatar-icon class="c-error" namespace="outlined" icon="warning_triangle"></rds-icon>
</rds-avatar>
