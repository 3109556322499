import {createAction, props} from '@ngrx/store';

export const getUserPhoto = createAction(
  '[CONTENT PAGE] Get User Photo',
  props<{ login: string }>()
);

export const getUserPhotoRequest = createAction(
  '[CONTENT PAGE] Get User Photo Request',
  props<{ login: string }>()
);
export const getUserPhotoSuccess = createAction(
  '[CONTENT PAGE] Get User Photo Success',
  props<{ url: string, login: string }>()
);
export const getUserPhotoFailure = createAction(
  '[CONTENT PAGE] Get User Photo Failure',
  props<{ userNotFound: boolean, login: string }>()
);
