import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType} from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, debounceTime, distinctUntilChanged, map, of, switchMap, tap } from 'rxjs';

import * as fromActions from './related-news.actions';
import * as fromSelectors from './related-news.selectors';
import * as fromReducer from './related-news.reducer';
import { RdsDialogService } from '@rds/angular-components';
import { NewsService } from '../../news.service';
import { RelatedNewsDialogComponent } from '@app/shared/dialogs/related-news-dialog/related-news-dialog.component';
import { NewsPreviewDialogComponent, NewsPreviewDialogData } from '@app/shared/dialogs/previews/news-preview-dialog/news-preview-dialog.component';

@Injectable()
export class RelatedNewsEffects {

  public initRelatedNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initRelatedNews),
      switchMap(() => this.store$.pipe(
          select(fromSelectors.selectRelatedNewsRequestData),
          debounceTime(300),
          distinctUntilChanged((prev, next) => {
            if (prev.resetIndex && !next.resetIndex) {
              delete prev.resetIndex
              delete next.resetIndex
            }
            return JSON.stringify(prev) === JSON.stringify(next)
          }),
          map(({filters, pageIndex, pageSize, sort, resetIndex}) => ({
            filters, 
            sort,
            pageIndex: resetIndex ? 0 : pageIndex,
            pageSize
          }))
        )
      ),
      map((requestData) => fromActions.getRelatedNewsRequest(requestData))
    ), { dispatch: true}
  );

  public getRelatedNewsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getRelatedNewsRequest),
      switchMap((requestData) => this.newsService.getRelatedNews(requestData).pipe(
        map(res => ({
          data: res.data, pagination: res.pagination
        }))
      )),
      map(({data, pagination}) => fromActions.getRelatedNewsSuccess({data, pagination})),
      catchError(({ message }) => of(fromActions.getRelatedNewsFailure({ error: message })))

    ), { dispatch: true}
  );

  public fetchRelatedNewsRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.fetchRelatedNewsRequest),
    switchMap(({ids}) => this.newsService.getMultipleNewsInfo({ids}).pipe(
      map(res => ({ news: res
      }))
    )),
    map(({news}) => fromActions.addRelatedNews({news})),
  ), { dispatch: true}
);

public openNewsPreview$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.openNewsPreview),
    switchMap(({newsId}) => this.newsService.get(newsId).pipe(
      map((news) => ({news}))
    )),
    tap(({news}) => {
      const data: NewsPreviewDialogData = {
        news,
        channel: null,
        context: 'NewsSelect'
      }
      this.dialogService.open(NewsPreviewDialogComponent, {
        size: 'xl',
        height: '100%',
        maxHeight: '100%',
        data
      }
      )
    } )
  ), { dispatch: false}
);

public openRelatedNewsDialog$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.openRelatedNewsDialog),
    tap(() => {
      this.dialogService.open(RelatedNewsDialogComponent, {
        size: 'xl',
        height: 'unset',
        maxHeight: 'unset',
      }
      )
    } )
  ), { dispatch: false}
);
  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private newsService: NewsService,
    private dialogService: RdsDialogService
  ) {}
}
