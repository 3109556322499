export interface Status {
  notAsked: boolean;
  loading: boolean;
  failure: boolean;
  success: boolean;
  error?: string;
}

export const STATUS_NOT_ASKED: Status = {
  notAsked: true,
  loading: false,
  failure: false,
  success: false,
};

export const STATUS_LOADING: Status = {
  notAsked: false,
  loading: true,
  failure: false,
  success: false,
};

export const STATUS_FAILURE: Status = {
  notAsked: false,
  loading: false,
  failure: true,
  success: false,
};

export const STATUS_SUCCESS: Status = {
  notAsked: false,
  loading: false,
  failure: false,
  success: true,
};
