import { Injectable } from '@angular/core';
import { ContentService } from '@app/core/services/content.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromActions from './channels.actions';

@Injectable()
export class ChannelsEffects {

  public loadChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadChannelsRequest),
      switchMap(() => this.contentService.getAllChannels().pipe(
        map((channels) => fromActions.channelsCollectionAddMany({channels})),
        catchError(({ message }) => of(fromActions.loadChannelsFailure({ error: message })))
      ))
  ));

  constructor(
    private actions$: Actions,
    private store$: Store<any>,
    private contentService: ContentService
  ) {}
}
