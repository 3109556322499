import { DatePipe } from '@angular/common';
import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RhEvent, RhEventToRequest, RhEventType } from '@app/events/models/event';
import { Sort } from '@rds/angular-components';
import { Observable, map } from 'rxjs';
import { Pagination } from '../models';
import { ApiService } from './api/api.service';
import { EndpointsService } from './endpoints/endpoints.service';

export enum ConstEventService {
	formatDateWith1200 = 'yyyy-MM-ddT12:00:00',
	formatDate = 'yyyy-MM-ddTHH:mm:ss',
	hourUnix = 60000,
}

@Injectable()
export class EventsService {

	public getTopicSearch(phrase): Observable<Array<string>> {
		const url = this.endpoints.addParams(this.endpoints.ENDPOINT.EVENT.TOPIC_SEARCH, {phrase});
		return this.http.get(url);
	}

	uploadImage(file: File): Observable<string> {
		const url = this.endpoints.ENDPOINT.IMAGE.DIRECTORY_UPLOAD;
		return this.http.uploadFile(this.endpoints.addParams(url, {}), file);
	}

	uploadImageWithProgress(file: File): Observable<HttpEvent<any>> {
		const url = this.endpoints.ENDPOINT.IMAGE.DIRECTORY_UPLOAD;
		return this.http.uploadFile(this.endpoints.addParams(url, {}), file, true);
	}

	saveAsDraft(form) {
		const url = this.endpoints.ENDPOINT.EVENT.SAVE_AS_DRAFT;
		const body = {
			...form,
			...this.parseDateToUpload(form)
		}
		return this.http.post(url, body);
	}

	updateAsDraft(form, id) {
		const url = this.endpoints.replaceUrlTokens(this.endpoints.ENDPOINT.EVENT.UPDATE_AS_DRAFT, {id});

		const body = {
			...form,
			...this.parseDateToUpload(form)
		}
		return this.http.put(url, body);
	}

	saveAsPublish(form) {
		const url = this.endpoints.ENDPOINT.EVENT.SAVE_AS_PUBLISH;

		const body = {
			...form,
			...this.parseDateToUpload(form)
		}
		return this.http.post(url, body);
	}

	updateAsPublish(form, id) {
		const url = this.endpoints.replaceUrlTokens(this.endpoints.ENDPOINT.EVENT.UPDATE_AS_PUBLISH, {id});
		const body = {
			...form,
			...this.parseDateToUpload(form)
		}
		return this.http.put(url, body);
	}

	getById(id): Observable<RhEvent> {
		const url = this.endpoints.replaceUrlTokens(this.endpoints.ENDPOINT.EVENT.GET, {id});
		return this.http.get(url).pipe(map((event): RhEvent => ({
			...event,
			content: event.content ? this.fromBinary(event.content) : null,
			date: event.date ? new Date(event.date) : null,
			startTime: event.startTime ? new Date(event.startTime) : null,
			endTime: event.endTime ? new Date(event.endTime) : null,
			locations: event.locations.map(t => t.id),
			departments: event.departments.map(t => t.id),
			functions: event.functions.map(t => t.id),
			topics: event.topics
		})));
	}

	getMyEvents({sort, filters, pageIndex, pageSize}: {
		sort: Sort,
		filters: { [key: string]: any },
		pageIndex: number,
		pageSize: number
	  }, past: boolean = false): Observable<{ data: Array<Partial<RhEvent>>, pagination: Pagination }> {
		const url = this.endpoints.addParams(this.endpoints.ENDPOINT.EVENT.MY_EVENTS, {
			pageIndex,
			pageSize,
			past
		});

		const body = {
			type: filters.type,
			status: filters.status,
			startDate: !!filters.range && !!filters.range.start ? new Date(filters.range.start.getTime() - filters.range.start.getTimezoneOffset() * ConstEventService.hourUnix) : null,
			endDate: !!filters.range && !!filters.range.start ? new Date(filters.range.end.getTime() - filters.range.end.getTimezoneOffset() * ConstEventService.hourUnix) : null,
			search: filters.search,
			sort: `${sort.active[0].toUpperCase()}${sort.active.slice(1)}`,
			direction: sort.direction
		}

		return this.http.post(url, body).pipe(
			map(res => ({
				data: res.data,
				pagination: {
					isFirst: res.isFirst,
					isLast: res.isLast,
					pageCount: res.pageCount,
					totalCount: res.totalCount,
					pageIndex: res.pageIndex,
					pageSize: res.pageSize,
				}
			}))
		)
	}

	deleteEventsByIds(ids: Array<number>): Observable<true> {
		const url = this.endpoints.ENDPOINT.EVENT.DELETE;
		const body = ids;

		return this.http.deleteWithBody(url, body);
	}

	getFeed(pageIndex: number, pageSize: number): Observable<Array<Partial<RhEvent>>> {
		const url = this.endpoints.addParams(this.endpoints.ENDPOINT.EVENT.FEED, {
			pageIndex,
			pageSize
		});
		return this.http.get(url).pipe(map(response => response.data));
	}

	getCalendar({startDate, endDate, search, types, locations, departments, functions}: {
								startDate: Date
								endDate: Date
								search: string
								types: Array<RhEventType>
								locations: Array<string>
								departments: Array<string>
								functions: Array<string>
							}
	): Observable<Array<Partial<RhEvent>>> {
		const url = this.endpoints.ENDPOINT.EVENT.CALENDAR;
		const body = {
			startDate,
			endDate,
			search,
			types,
			departments,
			locations,
			functions
		};
		return this.http.post(url, body)
	}

	getGrid({pageIndex, pageSize, startDate, endDate, search, types, locations, departments, functions}: {
		pageIndex: number;
		pageSize: number;
		startDate: Date;
		endDate: Date;
		search: string;
		types: Array<RhEventType>;
		locations: Array<string>;
		departments: Array<string>;
		functions: Array<string>;
	}): Observable<{ events: Array<Partial<RhEvent>>, pagination: Pagination }> {
		const url = this.endpoints.addParams(this.endpoints.ENDPOINT.EVENT.GRID, {
			pageIndex,
			pageSize
		});
		const body = {
			startDate,
			endDate,
			search,
			types,
			departments,
			locations,
			functions
		};
		return this.http.post(url, body).pipe(
			map(res => ({
				events: res.data,
				pagination: {
					isFirst: res.isFirst,
					isLast: res.isLast,
					pageCount: res.pageCount,
					totalCount: res.totalCount,
					pageIndex: res.pageIndex,
					pageSize: res.pageSize,
				}
			}))
		);
	}

	getTop({startDate, endDate, search, types, locations, departments, functions}: {
		startDate: Date;
		endDate: Date;
		search: string;
		types: Array<RhEventType>;
		locations: Array<string>;
		departments: Array<string>;
		functions: Array<string>;
	}): Observable<Array<Partial<RhEvent>>> {
		const url = this.endpoints.ENDPOINT.EVENT.TOP;

		const body = {
			startDate,
			endDate,
			search,
			types,
			departments,
			locations,
			functions
		};

		return this.http.post(url, body);
	}

	toBinary(string) {
		const codeUnits = new Uint16Array(string.length);
		for (let i = 0; i < codeUnits.length; i++) {
			codeUnits[i] = string.charCodeAt(i);
		}
		return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
	}

	fromBinary(encoded) {
		const binary = atob(encoded);
		const bytes = new Uint8Array(binary.length);
		for (let i = 0; i < bytes.length; i++) {
			bytes[i] = binary.charCodeAt(i);
		}
		return String.fromCharCode(...new Uint16Array(bytes.buffer));
	}

	parseDateToUpload(form: RhEvent): RhEventToRequest {
		const content: string = form.content ? this.toBinary(form.content) : null;

		if (form.allDay) {
			return {
				...form,
				content,
				date: this.datePipe.transform(form.date, ConstEventService.formatDateWith1200) ,
				startTime: null,
				endTime: null,
			}
		} else {
			return {
				...form,
				content,
				date: this.datePipe.transform(form.startTime, ConstEventService.formatDate),
				startTime: this.datePipe.transform(form.startTime, ConstEventService.formatDate),
				endTime: this.datePipe.transform(form.endTime, ConstEventService.formatDate)
			}
		}
	}

	constructor(
		private readonly http: ApiService,
		private readonly endpoints: EndpointsService,
		private readonly datePipe: DatePipe
	) {
	}
}


