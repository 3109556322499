import {Autocompletes, ExpressLink, People} from '@app/layout/header/models';
import {createAction, createActionGroup, props} from '@ngrx/store';
import {HeaderLinks} from '@app/layout/header/components/dummy-links';

export const setPhrase = createAction(
  '[Header2] Set phrase',
  props<{ phrase: string }>()
);

export const forceClose = createAction(
  '[Header2] Force close',
  props<{ close: boolean }>()
);

export const setHeaderVisible = createAction(
  '[Header2] Set header visible',
  props<{ visible: boolean }>()
)

export const setSuggestionPhrase = createAction(
  '[Header2] Set suggestion phrase',
  props<{ phrase: string }>()
);

export const goToSearch = createAction(
  '[Header2] Go to search',
  props<{ phrase: string }>()
);

export const loadSuggestions = createAction(
  '[Header2] Load Suggestions',
  props<{ text: string }>()
);

export const clearSuggestions = createAction(
  '[Header2] Clear Suggestions'
);

export const getExpressLinks = createAction(
  '[Header2] Get Express Links',
  props<{ text: string }>()
);

export const getExpressLinksSuccess = createAction(
  '[Header2] Get Express Links Success',
  props<{ expressLinks: ExpressLink[] }>()
);

export const getExpressLinksFailure = createAction(
  '[Header2] Get Express Links Failure'
);

export const getAutocompletes = createAction(
  '[Header2] Get Autocompletes',
  props<{ text: string }>()
);

export const getAutocompletesSuccess = createAction(
  '[Header2] Get Autocompletes Success',
  props<{ autocompletes: Autocompletes }>()
);

export const getAutocompletesFailure = createAction(
  '[Header2] Get Autocompletes Failure'
);

export const getPeople = createAction(
  '[Header2] Get People',
  props<{ text: string }>()
);

export const getPeopleSuccess = createAction(
  '[Header2] Get People Success',
  props<{ people: People[] }>()
);

export const getPeopleFailure = createAction(
  '[Header2] Get People Failure'
);

export const getConfigurationLinks = createAction(
  '[Header2] Get header Links'
);

export const getConfigurationLinksSuccess = createAction(
  '[Header2] Get header Links Success',
  props<HeaderLinks>()
);

export const setDefaultUrls = createAction(
  '[Header2] set default urls',
  props<{urls: {[key: string]: string}}>()
)

export const getConfigurationLinksFailure = createAction(
  '[Header2] Get header Links Failure'
);

export const searchFocused = createAction(
  '[Header2] Search input focused'
);

export const searchBlurred = createAction(
  '[Header2] Search input blurred'
);


export const shortcuts = createActionGroup({
  source: 'HEADER SHORTCUTS',
  events: {
    add: props<{path: string, title: string, icon: string}>(),
    remove: props<{path: string, title: string, icon: string}>()
  }
});
