import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { filter, map, mergeMap } from 'rxjs/operators';
import * as fromActions from './channels-news-all-language.actions';
import * as fromChannelsSelectors from '../channels/channels.selectors';
import { NewsboardNewsService } from '@app/core/services/newsboard/newsboard-news.service';

@Injectable()
export class ChannelsNewsAllLanguageEffects {

  public addChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addChannel),
      mergeMap(({channelId}) => this.store$.pipe(
        select(fromChannelsSelectors.selectEntityById, {id: channelId}),
        filter(channel => !!channel),
        map((channel) => fromActions.channelsCollectionUpsertOne({channel}))
        ))
  ), { dispatch: true});

  public channelsCollectionUpsertOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.channelsCollectionUpsertOne),
      filter(channel => !!channel),
      mergeMap(({channel}) => this.newsboardNews.getNewsForChannel(+channel.id.split(':')[1]).pipe(
        map(news => ({
          news: news.map(n => {
            return {
              ...n,
              channelId: `news:${n.channelId}`,
              newsId: `news:${n.channelId}:${n.newsId}`,
              abstracts: n.abstracts.reduce((result, item) => ({
                ...result,
                [item.languageCode]: item
              }), {})
            }
          })
        })),
        map(({news}) => fromActions.newsCollectionForChannelAddAll({channelId: channel.id, news}))
      ))
  ), { dispatch: true});

  constructor(
    private actions$: Actions,
    private store$: Store<any>,
    private newsboardNews: NewsboardNewsService
  ) {}
}
