import { createReducer, Action, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';

import { Language } from '@core/models/language.model';
import * as fromActions from '@core/core-store/languages-dict/languages-dict.actions';

export interface State extends EntityState<Language> {}

export const adapter: EntityAdapter<Language> =
  createEntityAdapter<Language>({
    selectId: language => language.id,
});

export const initialState: State = {
  ...adapter.getInitialState(),
};

const languagesDictReducer = createReducer(
  initialState,
  on(fromActions.languagesDictCollectionAddMany, (state, {languagesDict}) => (
    adapter.addMany(languagesDict, state)
  )),
);

export function reducer(state: State | undefined, action: Action) {
  return languagesDictReducer(state, action);
}

export const getIds = adapter.getSelectors().selectIds;
export const getEntities = adapter.getSelectors().selectEntities;
export const getAll = adapter.getSelectors().selectAll;
export const getTotal = adapter.getSelectors().selectTotal;

export const getEntityById = (entities: Dictionary<Language>, {id}) => entities[id];
export const getEntitiesById = (entities: Dictionary<Language>, {ids}) => ids.map(id => entities[id]);

