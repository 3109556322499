import { on, Action, createReducer } from '@ngrx/store';
import * as fromActions from './global-settings.actions';

export interface State {
  form: {
    newsLanguageId: number
  },
  initialForm: {
    newsLanguageId: number;
  }
}

export const initialState: State = {
  form: {
    newsLanguageId: null
  },
  initialForm: {
    newsLanguageId: null
  }
};

export const globalSettingsReducer = createReducer(
  initialState,
  on(fromActions.setInitialValue, (state, {form}) => ({
    ...state,
    form,
    initialForm: form
  })),
  on(fromActions.setFormValue, (state, {form}) => ({
    ...state,
    form,
  })),
  on(fromActions.saveNewsLanguageSuccess, (state, {newsLanguage}) => ({
    ...state,
    form: { newsLanguageId: newsLanguage.id},
    initialForm: { newsLanguageId: newsLanguage.id}
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return globalSettingsReducer(state, action);
}

export const getForm = (state: State) => state.form;
export const getInitialForm = (state: State) => state.initialForm;
