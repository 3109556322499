import { FilterDefinition, FilterTypeEnum, SEARCH_FILTER, SelectFilterDefinition } from '@app/shared/filters/filters-model';

export interface SiteMapTableFilters {
    module: SelectFilterDefinition;
    status: SelectFilterDefinition;
    hasNotes: SelectFilterDefinition;
    search: FilterDefinition;
}

export const STATUS = [
    { label: 'Success', value: 'success' },
    { label: 'In progress', value: 'in_progress' },
    { label: 'Error', value: 'error' },
    { label: 'Not set', value: 'not_set' }
];

export const HAS_NOTES = [
    { label: 'All', value: null },
    { label: 'Yes', value: true },
    { label: 'No', value: false }
];

export const INITIAL_SITEMAP_FILTERS: SiteMapTableFilters = {
    module: {
        type: FilterTypeEnum.SELECT,
        changable: true,
        value: [],
        label: 'Module',
        multiple: true,
        options: []
    },
    status: {
        type: FilterTypeEnum.SELECT,
        changable: true,
        value: [],
        label: 'Status',
        multiple: true,
        options: STATUS
    },
    hasNotes: {
        type: FilterTypeEnum.SELECT,
        changable: true,
        value: null,
        label: 'Has notes',
        multiple: false,
        options: HAS_NOTES
    },
    ...SEARCH_FILTER
}