import Command from '@ckeditor/ckeditor5-core/src/command';
import {createText} from "./text-box-command";

export default class TextBoxChangeTypeCommand extends Command {
    execute(options) {
        const model = this.editor.model;
        model.change(writer => {
            const value = options.value;
            const selection = model.document.selection;
            const selectedModelName = selection.getSelectedElement()?.name;
            if (selectedModelName === 'textBox') {
                const selectedElement = selection.getSelectedElement()
                const oldValue = selectedElement.getAttribute('custom-type');

                if (value === oldValue) {
                    return;
                }
                switch (value) {
                    case 'single':
                    case 'block':
                        this.singleColumn(value, oldValue, writer, selectedElement);
                        break;
                    case 'double':
                        this.doubleColumn(value, oldValue, writer, selectedElement);
                        break;
                    case 'triple':
                        this.tripleColumn(value, oldValue, writer, selectedElement);
                        break;
                }
                this.setAttributesOnSelectedBlock(value, writer, selectedElement);
            }
        });
    }

    setAttributesOnSelectedBlock(value, writer, selectedElement) {
        if (value !== 'block') {
            writer.setAttribute('custom-background-class', '', selectedElement)
        } else {
            writer.setAttribute('custom-background-class', 'bg-neutral-light-colour', selectedElement);
        }
        writer.setAttribute('custom-type', value, selectedElement)
        writer.setSelection(selectedElement, 'on');
    }

    singleColumn(value, oldValue, writer, selectedElement) {
        if (oldValue === 'double') {
            const children = Array.from(selectedElement.getChildren());
            const child = children[0];
            const position = writer.createPositionAt(child, 'after')
            writer.merge(position);
        }
        if (oldValue === 'triple') {
            const children = Array.from(selectedElement.getChildren());
            const child = children[0];
            const child1 = children[1];
            const positionAfterChild1 = writer.createPositionAt(child1, 'after')
            const positionAfterChild = writer.createPositionAt(child, 'after')
            writer.merge(positionAfterChild1);
            writer.merge(positionAfterChild);
        }
    }

    doubleColumn(value, oldValue, writer, selectedElement) {
        if (oldValue === 'single' || oldValue === 'block') {
            const text = createText(writer);
            writer.append(text, selectedElement);
        }
        if (oldValue === 'triple') {
            const children = Array.from(selectedElement.getChildren());
            const child = children[1];
            const position = writer.createPositionAt(child, 'after')
            writer.merge(position);
        }
    }

    tripleColumn(value, oldValue, writer, selectedElement) {
        if (oldValue === 'single' || oldValue === 'block') {
            const text = createText(writer);
            const text2 = createText(writer);
            writer.append(text, selectedElement);
            writer.append(text2, selectedElement);
        }

        if (oldValue === 'double') {
            const text = createText(writer);
            writer.append(text, selectedElement);
        }
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const allowedIn = model.schema.findAllowedParent(selection.getFirstPosition(), 'textBox');
        const element = selection.getSelectedElement();
        const selectedModelName = element?.name;
        this.isEnabled = allowedIn !== null;
        if (selectedModelName === 'textBox') {
            if (this.isEnabled && element.hasAttribute('custom-type')) {
                this.value = element.getAttribute('custom-type');
            } else {
                this.value = false;
            }
        }
    }
}
