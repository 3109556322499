import { Pipe, PipeTransform } from '@angular/core';
import { SimpleUser } from '@app/core/models/newsboard';


@Pipe({
  name: 'userList'
})
export class UserListPipe implements PipeTransform {
  constructor() {}

  transform(value: Array<SimpleUser> | Array<Array<SimpleUser>>, separator: string = ', '): string {
    let list = [];
    if (value[0] instanceof Array) {
      value.forEach(a => list = [...list, ...a])
    } else {
      list = value;
    }
    let result = '';
    const length = list?.length || 0;
    for (let i = 0; i < length; i++) {
      const user = list[i];
      result = result + user.name;

      if (i + 1 !== length) {
        result = result + separator;
      }
    }
    return result;
  }
}
