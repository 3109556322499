import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {TickerNewsModel} from '@core/models';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'atr-single-ticker',
  templateUrl: './ticker.component.html',
  styleUrls: ['./ticker.component.scss'],
  animations: [
    trigger('desktopAnimation', [
      transition('void => prev', [style({ //enter
        opacity: 0,
        transform: 'translateY(200%)',
      }), animate('250ms 250ms cubic-bezier(0.64, 0.04, 0.35, 1)', style({opacity: 1, transform: 'translateY(0)'}))]),
      transition('prev => void', [style({ //leave
        opacity: 1,
        transform: 'translateY(0)',
      }), animate('250ms cubic-bezier(0.64, 0.04, 0.35, 1)', style({opacity: 0, transform: 'translateY(-200%)'}))]),
      transition('void => next', [style({ //enter
        opacity: 0,
        transform: 'translateY(-200%)',
      }), animate('250ms 250ms cubic-bezier(0.64, 0.04, 0.35, 1)', style({opacity: 1, transform: 'translateY(0)'}))]),
      transition('next => void', [style({ //leave
        opacity: 1,
        transform: 'translateY(0)',
      }), animate('250ms cubic-bezier(0.64, 0.04, 0.35, 1)', style({opacity: 0, transform: 'translateY(200%)'}))]),
      
      // transition('void => up-left', [style({ //enter
      //   opacity: 0,
      //   transformOrigin: 'calc(100% + 100px) center',
      //   transform: 'rotate(-90deg)',
      // }), animate('250ms 250ms cubic-bezier(0.64, 0.04, 0.35, 1)', style({opacity: 1, transform: 'rotate(0deg)'}))]),
      // transition('up-left => void', [style({ //leave
      //   opacity: 1,
      //   transformOrigin: 'calc(100% + 100px) center',
      //   transform: 'rotate(0deg)',
        
      // }), animate('250ms cubic-bezier(0.64, 0.04, 0.35, 1)', style({opacity: 0, transform: 'rotate(90deg)'}))]),
      // transition('void => down-left', [style({ //enter
      //   opacity: 0,
      //   transformOrigin: 'calc(100% + 100px) center',
      //   transform: 'rotate(90deg)',
      // }), animate('250ms 250ms cubic-bezier(0.64, 0.04, 0.35, 1)', style({opacity: 1, transform: 'rotate(0deg)'}))]),
      // transition('down-left => void', [style({ //leave
      //   opacity: 1,
      //   transformOrigin: 'calc(100% + 100px) center',
      //   transform: 'rotate(0deg)',
      // }), animate('250ms cubic-bezier(0.64, 0.04, 0.35, 1)', style({opacity: 0, transform: 'rotate(-90deg)'}))]),
      // transition('void => up-right', [style({ //enter
      //   opacity: 0,
      //   transformOrigin: '-100px center',
      //   transform: 'rotate(90deg)',
      // }), animate('250ms 250ms cubic-bezier(0.64, 0.04, 0.35, 1)', style({opacity: 1, transform: 'rotate(0deg)'}))]),
      // transition('up-right => void', [style({ //leave
      //   opacity: 1,
      //   transformOrigin: '-100px center',
      //   transform: 'rotate(0deg)',
      // }), animate('250ms cubic-bezier(0.64, 0.04, 0.35, 1)', style({opacity: 0, transform: 'rotate(-90deg)'}))]),
      // transition('void => down-right', [style({ //enter
      //   opacity: 0,
      //   transformOrigin: '-100px center',
      //   transform: 'rotate(-90deg)',
      // }), animate('250ms 250ms cubic-bezier(0.64, 0.04, 0.35, 1)', style({opacity: 1, transform: 'rotate(0deg)'}))]),
      // transition('down-right => void', [style({ //leave
      //   opacity: 1,
      //   transformOrigin: '-100px center',
      //   transform: 'rotate(0deg)',
      // }), animate('250ms cubic-bezier(0.64, 0.04, 0.35, 1)', style({opacity: 0, transform: 'rotate(90deg)'}))]),


    ]),
    trigger('mobileAnimation', [
      transition('void => prev', [style({ //enter
        opacity: 0,
        transform: 'translateX(200%)',
      }), animate('250ms 250ms cubic-bezier(0.64, 0.04, 0.35, 1)', style({opacity: 1, transform: 'translateX(0)'}))]),
      transition('prev => void', [style({ //leave
        opacity: 1,
        transform: 'translateX(0)',
      }), animate('250ms cubic-bezier(0.64, 0.04, 0.35, 1)', style({opacity: 0, transform: 'translateX(-200%)'}))]),
      transition('void => next', [style({ //enter
        opacity: 0,
        transform: 'translateX(-200%)',
      }), animate('250ms 250ms cubic-bezier(0.64, 0.04, 0.35, 1)', style({opacity: 1, transform: 'translateX(0)'}))]),
      transition('next => void', [style({ //leave
        opacity: 1,
        transform: 'translateX(0)',
      }), animate('250ms cubic-bezier(0.64, 0.04, 0.35, 1)', style({opacity: 0, transform: 'translateX(200%)'}))]),

    ]),
  ]
})
export class SingleTickerComponent {
  @Input() tickerClass: string;
  @Input() tickerTranslation: string;
  @Input() showButtons = true;
  @Input() isMobile = false;
  @Input() canChange: { prev: boolean, next: boolean, length: number };
  next = true;
  _ticker: TickerNewsModel;
  get ticker() {
    return this._ticker;
  }

  @Input() set ticker(ticker: TickerNewsModel) {
    if (!!ticker && ticker !== this._ticker) {
      this._ticker = {...ticker, link: this.isSinglePreview ? 'https://' + ticker.link : ticker.link};
    } else {
      this._ticker = null;
    }
    this.cdr.detectChanges();
  }

  _tickerDirection;

  get tickerDirection() {
    return this._tickerDirection;
  }

  @Input() set tickerDirection(dir) {
    this._tickerDirection = dir;
    this.cdr.detectChanges();
  };

  @Input() isSinglePreview: true | false;
  @Output() closeTicker: EventEmitter<any> = new EventEmitter();
  @Output() changeTicker: EventEmitter<any> = new EventEmitter();

  blockButtons = false;

  public onChangeTicker(num: number) {
    num > 0 ? this.tickerDirection = { badge: 'next', text: 'next'} : this.tickerDirection = { badge: 'prev', text: 'prev'}
    this.cdr.detectChanges();
    this._ticker = null;
    this.changeTicker.emit(num);
  }

  public onCloseTicker(id) {
    this.closeTicker.emit(id);
  }

  constructor(private cdr: ChangeDetectorRef) {
  }
}
