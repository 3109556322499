import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

export interface HttpError {
  type: HttpErrorType;
  message: string;
}

export enum HttpErrorType {
  API = 'API',
  CLIENT_OR_NETWORK = 'CLIENT_OR_NETWORK'
}

@Injectable()
export class ApiService {

  public get(
    url: string,
    responseType?: ResponseType,
    params?: HttpParams,
    withCredentials?: boolean
  ): Observable<any> {
    const options = this.createOptions(responseType, params, withCredentials);

    return this.http
      .get(url, options as any)
      .pipe(catchError(this.failure)) as Observable<any>;
  }

  public post(url: string, body?: any | null): Observable<any> {
    const options = this.createOptions();

    return this.http
      .post(url, body, options as any)
      .pipe(catchError(this.failure)) as Observable<any>;
  }

  public put(url: string, body?: any | null): Observable<any> {
    const options = this.createOptions();

    return this.http
      .put(url, body, options as any)
      .pipe(catchError(this.failure)) as Observable<any>;
  }

  public patch(url: string, body?: any | null): Observable<any> {
    const options = this.createOptions();

    return this.http
      .patch(url, body, options as any)
      .pipe(catchError(this.failure)) as Observable<any>;
  }

  public delete(url: string): Observable<any> {
    const options = this.createOptions();

    return this.http
      .delete(url, options as any)
      .pipe(catchError(this.failure)) as Observable<any>;
  }

  public deleteWithBody(url: string, body: any | null): Observable<any> {
    const options = this.createOptionsForDeleteWithBody('json', body);

    return this.http
      .delete(url, options as any)
      .pipe(catchError(this.failure)) as Observable<any>;
  }

  public uploadFile(url: string, file: File, reportProgress = false): Observable<any> {
    const headers = new HttpHeaders();
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const options: HttpOptions = {
      ...headers,
      reportProgress,
      observe: reportProgress? 'events' : 'body',
    }
    return this.http
      .post(url, formData, options as any)
      .pipe(catchError(this.failure)) as Observable<any>;
  }

  private failure(error: HttpErrorResponse): Observable<HttpError> {
    if (error.error instanceof ErrorEvent) {
      console.error('A client-side or network error occurred:', error.error.message);
      return throwError({
        type: HttpErrorType.CLIENT_OR_NETWORK,
        message: `[[${(new Date()).toString()}]] [Client/Network]: ${JSON.stringify(error)}`
      });
    } else {
      console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
      return throwError({
        type: HttpErrorType.API,
        message: `[[${(new Date()).toString()}]] [API]: Backend returned code ${error.status}, body was: ${error.error}`,
        error: error.error
      });
    }
  }

  private createOptions(
    responseType: ResponseType = 'json',
    params?: HttpParams,
    withCredentials?: boolean
  )
    : HttpOptions {
    const headers = new HttpHeaders();
    headers.append('content-type', 'application/json');
    headers.append('accept', 'application/json');
    return {
      responseType,
      params,
      headers,
      withCredentials
    };
  }

  private createOptionsForDeleteWithBody(responseType: ResponseType = 'json', body: any): HttpOptionsDeleteWithBody {
    responseType = responseType || 'json';
    return {
      responseType,
      body
    };
  }

  constructor(private http: HttpClient) {
  }
}

interface HttpOptions {
  headers?: HttpHeaders | {
    [header: string]: string | string[]
  };
  observe?: 'body' | 'events';
  params?: HttpParams | {
    [patam: string]: string | string[]
  };
  reportProgress?: boolean;
  responseType?: ResponseType;
  withCredentials?: boolean;
}

export type ResponseType = 'json' | 'arraybuffer' | 'text' | 'blob' | 'csv' | 'pdf';

interface HttpOptionsDeleteWithBody extends HttpOptions {
  body: any;
}

