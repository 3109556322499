import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[requireAsterisk]'
})
export class RequireAsteriskDirective {

  asterisk = document.createElement('span');
  @Input() set requireAsterisk(value) {
    if (value) {
      this.renderer.addClass(this.asterisk, 'c-error');
      this.renderer.setProperty(this.asterisk, 'innerHTML', ' *');
      this.renderer.appendChild(this.elementRef.nativeElement, this.asterisk);
    } else {
      this.renderer.removeChild(this.elementRef.nativeElement, this.asterisk)
    }
  }
  constructor(
    private elementRef: ElementRef<any>,
    private renderer: Renderer2
  ) {}

}
