<div class="row">
  <div class="col-12 lpy-3">
    <button *ngIf="availableLanguages?.length > 1" class="bg-white"
            rds-dropdown-toggle-button [rdsMenuTriggerFor]="menu" #trigger="rdsMenuTrigger"
      [isOpen]="trigger.menuOpen">{{currentLanguage.englishName}}
    </button>
    <rds-menu #menu="rdsMenu">
      <button *ngFor="let language of availableLanguages" rds-menu-item
              (click)="languageVersionChanged(language.code)" [disabled]="isLanguageLoading">
        <span>{{language.englishName}}</span>
      </button>
    </rds-menu>
  </div>
</div>
<div class="row">
  <div class="info-column" [class.col-6]="viewMode === 'desktop' || !viewMode" [class.col-12]="viewMode === 'mobile'">
    <h4 class="article-heading-4--xxl title">
      {{title}}
    </h4>
    <div class="info">
      <p class="ui-body-l">
        {{publicationDate | date: 'mediumDate'}} by
        <ng-container *ngIf="!!author">
          <button rds-text-button (click)="isAuthorSubscribed ? authorUnsubscribed(author) : authorSubscribed(author)" size="m">
            {{authorName}}
            <rds-icon [rdsTooltip]="isAuthorSubscribed ? 'Unsubscribe from the Author' : 'Subscribe to the Author'" [icon]="isAuthorSubscribed ? 'checkmark_circle' : 'add_circle'" namespace="outlined"></rds-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="!author">
          {{publisherName}}
        </ng-container>
      </p>
      <div *ngIf="options" class="actions">
        <div class="action" *ngIf="options.likeEnabled">
          <button [disabled]="blockLike" rds-icon-button (click)="likeClicked()">
            <rds-icon icon="heart" [namespace]="options.hasLiked? 'filled' : 'outlined'"></rds-icon>
          </button>
          <span class="action__count">
            {{numLikes}}
          </span>
        </div>
        <div class="action" *ngIf="options.commentsEnabled && numComments > -1">
          <button rds-icon-button (click)="commentsClicked()">
            <rds-icon icon="message" namespace="outlined"></rds-icon>
          </button>
          <span class="action__count">
            {{numComments}}
          </span>
          <span class="action__count">
          </span>
        </div>
        <div class="action">
          <button [disabled]="blockBookmark" rds-icon-button (click)="bookmarkClicked()">
            <rds-icon icon="bookmark" [namespace]="options.hasBookmarked? 'filled' : 'outlined'"></rds-icon>
          </button>
        </div>
        <div class="action">
          <button rds-icon-button (click)="shareClicked()">
            <rds-icon icon="share" namespace="outlined"></rds-icon>
          </button>
        </div>
        <div class="action">
          <button rds-icon-button (click)="linkClicked()">
            <rds-icon icon="link" namespace="outlined"></rds-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div [class.col-6]="viewMode === 'desktop' || !viewMode" [class.col-12]="viewMode === 'mobile'" [class.mt-7]="viewMode === 'mobile'">
    <img class="thumbnail" [src]="image || '/assets/images/news-placeholder.png'">
  </div>
</div>

