
import { MySavedTemplatesModel, MySavedTemplatesResponse} from '@app/newsletter/models';
import {createAction, props} from '@ngrx/store';

export const getDashboardTemplates = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] Get dashboard templates',
  props<{pageIndex: number, pageSize: number}>()
);

export const loadMore = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] Load more',
);

export const getDashboardTemplatesSuccessUpsert = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES]  Get dashboard templates success upsert',
  props<{response: MySavedTemplatesResponse}>()
);

export const getDashboardTemplatesSuccessAddAll = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES]  Get dashboard templates success add all',
  props<{response: MySavedTemplatesResponse}>()
);

export const getDashboardTemplatesFailure = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES]  Get dashboard templates failure',
  props<{ message: string }>()
);

export const openDeleteNewsletterDialog = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] Open delete Newsletter modal',
  props<{ template: MySavedTemplatesModel, fromDashboard: boolean }>()
);

export const deleteNewsletterRequest = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] delete Newsletter Request',
  props<{ id: number, fromDashboard: boolean }>()
);

export const deleteNewsletterSuccess = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] delete Newsletter Success',
  props<{ id: number, fromDashboard: boolean }>()
);

export const deleteNewsletterSuccessFromAll = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] delete Newsletter Success from All',
  props<{ id: number }>()
);

export const deleteNewsletterFailure = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] delete Newsletter Failure',
  props<{ message: string }>()
);

export const openPreviewDialog = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] Open preview Newsletter modal',
  props<{ template: MySavedTemplatesModel }>()
);

export const openRenameDialog = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] Open rename Newsletter modal',
  props<{ id: number, name: string }>()
);

export const renameNewsletterRequest = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] rename Template Request',
  props<{ id: number, name: string }>()
);

export const renameNewsletterSuccess = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] rename Template Success',
  props<{ template: MySavedTemplatesModel }>()
);

export const renameNewsletterFailure = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] rename Template Failure',
  props<{ message: string }>()
);

export const openShareDialog = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] Open share Newsletter modal',
  props<{ template: MySavedTemplatesModel }>()
);

export const shareNewsletterRequest = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] share Template Request',
  props<{ id: number, emails: string }>()
);

export const shareNewsletterSuccess = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] share Template Success',
);

export const shareNewsletterFailure = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] share Template Failure',
  props<{ message: string }>()
);

// SHARED TEMPLATES
export const getDashboardSharedTemplates = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] Get dashboard shared templates',
  props<{pageIndex: number, pageSize: number}>()
);

export const getDashboardSharedTemplatesSuccessAddAll = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES]  Get dashboard shared templates success add all',
  props<{response: MySavedTemplatesResponse}>()
);

export const getDashboardSharedTemplatesFailure = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES]  Get dashboard shared templates failure',
  props<{ message: string }>()
);

export const openSharedPreviewDialog = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] Open shared preview Newsletter modal',
  props<{ template: MySavedTemplatesModel, fromDashboard: boolean; }>()
);

export const openAcceptDialog = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] Open accept Newsletter modal',
  props<{ template: MySavedTemplatesModel }>()
);

export const acceptNewsletterRequest = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] accept Template Request',
  props<{ id: number }>()
);

export const acceptNewsletterSuccess = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] accept Template Success',
);

export const acceptNewsletterFailure = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] accept Template Failure',
  props<{ message: string }>()
);

export const sendTemplateCopyRequest = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] share Template Request',
  props<{ id: number, emails: Array<string> }>()
);

export const sendTemplateCopySuccess = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] share Template Success',
);

export const sendTemplateCopyFailure = createAction(
  '[NEWSLETTER - MY SAVED TEMPLATES] share Template Failure',
  props<{ message: string }>()
);
