import { RhEvent } from '@app/events/models/event';
import {createAction, props} from '@ngrx/store';

export const changeSelectedDate = createAction(
  '[EVENTS - TOP] change selected date ',
  props<{ selectedDate: Date }>()
);

export const getTopEventsRequest = createAction(
  '[EVENTS - TOP] Get events request'
);

export const getTopEventsSuccess = createAction(
  '[EVENTS - TOP] Get events success',
  props<{events: Array<Partial<RhEvent>>}>()
);

export const getTopEventsFailure = createAction(
  '[EVENTS - TOP] Get events failure',
  props<{error: string}>()
);