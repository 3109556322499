import { Sort } from '@rds/angular-components';

export const prepareSortStringArray = (sort: Sort): Array<string> => {
  if (sort.active === 'contentOwner' || sort.active === 'contentManager' ) {
    return [`${sort.active}LastName ${sort.direction}, ${sort.active}FirstName ${sort.direction}`];
  } else {
    return [`${sort.active} ${sort.direction}`];
  }
};

export const escapeApostrophe = (value: string): string => {
  const splittedString = value.split('\'');
  const result = splittedString.join('\'\'');
  return result;
};
