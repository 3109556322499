import { createSelector } from '@ngrx/store';

import * as fromUi from '../ui.reducer';
import * as fromNotifications from './notifications.reducer';

export const selectState = createSelector(
  fromUi.selectState,
  fromUi.getNotifications
);

export const selectAlert = createSelector(
  selectState,
  fromNotifications.getAlert
);
