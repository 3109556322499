import { createSelector } from '@ngrx/store';

import * as fromDepartmentsFlat from '@app/core/core-store/departments-flat';
import * as fromFunctionsFlat from '@app/core/core-store/functions-flat';
import * as fromLocationsFlat from '@app/core/core-store/locations-flat';
import { EventStatusEnum, RhEventType } from '@app/events/models/event';
import * as fromManageEvents from '../managing-events.reducer';
import * as fromReducer from './form.reducer';

export const selectState = createSelector(
    fromManageEvents.selectState,
    fromManageEvents.getForm
);

export const selectForm = createSelector(
    selectState,
    fromReducer.getForm
);

export const selectFormChecked = createSelector(
    selectState,
    fromReducer.getFormChecked
  );
  
export const selectInitialForm = createSelector(
    selectState,
    fromReducer.getInitialForm
);

export const selectEventStatus = createSelector(
    selectState,
    fromReducer.getEventStatus
);

export const selectFillFormStartedAt = createSelector(
    selectState,
    fromReducer.getFillFormStartedAt
);

export const selectOriginalPublishDate = createSelector(
    selectInitialForm,
    (form) => {
        if (!!form?.startDate) {
            return new Date(form.startDate);
        }
        return null
    }
);

export const selectDatesForValidation = createSelector(
    selectFillFormStartedAt,
    selectOriginalPublishDate,
    (fillFormStartedAt, originalPublishDate) => ({fillFormStartedAt, originalPublishDate})
);

export const selectId = createSelector(
    selectForm,
    fromReducer.getId
);

export const selectTitle = createSelector(
    selectForm,
    fromReducer.getTitle
);

export const selectType = createSelector(
    selectForm,
    fromReducer.getType
);

export const selectFormForRequest = (isDraft: boolean = false) => createSelector(
    selectForm,
    fromDepartmentsFlat.selectRoot,
    fromFunctionsFlat.selectRoot,
    fromLocationsFlat.selectRoot,
    (form, dRootId, fRootId, lRootId) => form && !!dRootId && dRootId.length > 0 && !!fRootId && fRootId.length > 0 && !!lRootId && lRootId.length > 0 ? ({
        title: form.title?.trim(),
        allDay: form.allDay,
        date: form.date,
        startTime: form.date && form.startTime ? new Date(new Date(form.date).setHours(+(form.startTime as string).split(':')[0], +(form.startTime as string).split(':')[1], 0, 0)) : null,
        endTime: form.date && form.endTime ? new Date(new Date(form.date).setHours(+(form.endTime as string).split(':')[0], +(form.endTime as string).split(':')[1], 0, 0)) : null,
        timeZone: form.timeZone,
        type: form.type,
        location: (form.type === RhEventType.HYBRID || form.type === RhEventType.ON_SITE || form.type === RhEventType.OFF_SITE) ? form.location : null,
        meetingUrl: (form.type === RhEventType.HYBRID || form.type === RhEventType.VIRTUAL) ? form.meetingUrl : null,
        registrationUrl: form.registrationUrl,
        registration: !!form.registrationUrl,
        eventUrl: form.eventUrl,
        topEvent: form.topEvent,
        eventOwners: (form.eventOwners as Array<any> ).map((u) => {
            if (!!u.login) {
                return u
            } else {
                return ({
                    login: u.identifier,
                    email: u.email,
                    firstName: u.name?.split(' ')[0],
                    lastName:  u.name?.split(' ')[1],
                    photoUrl: u.avatar
                })
            }
        }),
        imageName: form.image?.name,
        imageSize: form.image?.size || 0,
        imageUrl: form.image?.url,
        description: form.description,
        contact: form.contact,
        host: form.host,
        topics: form.topics,
        locations: form.locations.length > 0 ? form.locations : (isDraft ? [] : [lRootId[0].id]),
        departments: form.departments.length > 0 ? form.departments : (isDraft ? [] : [dRootId[0].id]),
        functions: form.functions.length > 0 ? form.functions : (isDraft ? [] : [fRootId[0].id]),
        content: form.content,
        id: form.id
    }) : null
);

export const selectGeneralForm = createSelector(
    selectForm,
    (form) => form ? ({
        id: form.id,
        title: form.title,
        allDay: form.allDay,
        date: form.date,
        startTime: form.startTime,
        endTime: form.endTime,
        timeZone: form.timeZone,
        type: form.type,
        location: form.location,
        meetingUrl: form.meetingUrl,
        registrationUrl: form.registrationUrl,
        registration: form.registration,
        eventUrl: form.eventUrl,
        topEvent: form.topEvent,
        eventOwners: form.eventOwners,
    }) : null
);

export const selectContentForm = createSelector(
    selectForm,
    (form) => form ? ({
        title: form.title,
        image: form.image,
        description: form.description,
        contact: form.contact,
        content: form.content,
        host: form.host,
        topics: form.topics,
        date: form.date,
        startTime: form.startTime,
        location: form.location,
        type: form.type,
        allDay: form.allDay,
    }) : null
);

export const selectAudienceForm = createSelector(
    selectForm,
    (form) => form ? ({
        locations: form.locations,
        departments: form.departments,
        functions: form.functions,
    }) : null
);

export const selectGeneralStepStatus = createSelector(
    selectState,
    fromReducer.getGeneralStepStatus
);

export const selectGeneralFormStatus = createSelector(
    selectState,
    fromReducer.getGeneralFormStatus
);

export const selectContentStepStatus = createSelector(
    selectState,
    fromReducer.getContentStepStatus
);

export const selectContentFormStatus = createSelector(
    selectState,
    fromReducer.getContentFormStatus
);

export const selectAudienceStepStatus = createSelector(
    selectState,
    fromReducer.getAudienceStepStatus
);

export const selectAudienceFormStatus = createSelector(
    selectState,
    fromReducer.getAudienceFormStatus
);

export const selectChangesMade = createSelector(
    selectForm,
    selectInitialForm,
    (form, initialForm) => JSON.stringify(form) !== JSON.stringify(initialForm)
);

export const canSaveAsDraft = createSelector(
    selectForm,
    (form) => !!form?.title && form?.eventOwners.length > 0
);

export const canPublish = createSelector(
    selectGeneralFormStatus,
    selectContentFormStatus,
    selectAudienceFormStatus,
    selectEventStatus,
    selectChangesMade,
    (general, content, audience, status, changesMade) => {
        const areFormsValid = general === 'VALID' && content === 'VALID' && audience === 'VALID';
        return areFormsValid && (changesMade || status === EventStatusEnum.DRAFT)
    }
);

export const selectIsTouched = createSelector(
    selectState,
    fromReducer.getTouched
);
