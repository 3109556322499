import { createReducer, on, Action, createFeatureSelector } from '@ngrx/store';
import * as fromActions from './email.actions';
import { Status, STATUS_NOT_ASKED, STATUS_LOADING, STATUS_SUCCESS, STATUS_FAILURE } from '@core/models/status.model';
import { UserSearchSuggestion } from '@app/core/models';

export const feature = 'email';

export interface State extends Status {
  email: string;
  login: string;
  emailSuggestions: UserSearchSuggestion;
}

export const initialState: State = {
  email: '',
  login: '',
  ...STATUS_NOT_ASKED,
  emailSuggestions: null
};

export const reducer = createReducer(
  initialState,
  on(fromActions.getUserEmail, (state) => ({
    ...state,
    email: '',
    ...STATUS_LOADING,
  })),
  on(fromActions.getUserEmailSuccess, (state, { email, login }) => ({
    ...state,
    ...STATUS_SUCCESS,
    email,
    login
  })),
  on(fromActions.getUserEmailFailure, (state) => ({
    ...state,
    ...STATUS_FAILURE,
  })),
  on(fromActions.getEmailSearchSuccess, (state, {usersSearched}) => ({
    ...state,
    emailSuggestions: {
      ...state.emailSuggestions,
      suggestions: usersSearched
    }
  })),
  on(fromActions.clearEmailSearch, (state) => ({
    ...state,
    emailSuggestions: null
  })),
);

export function emailReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}

export const selectState = createFeatureSelector<State>(feature);

export const getLoading = (state: State) => state.loading;
export const getFailure = (state: State) => state.failure;
export const getEmail = (state: State) => state.email;
export const getLogin = (state: State) => state.login;
export const getEmailAndLogin = (state: State) => ({ email: state.email, login: state.login});
export const getEmailSuggestion = (state: State) => state.emailSuggestions;
