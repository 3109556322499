import { Editor, ElementApiMixin } from '@ckeditor/ckeditor5-core';
import RdsEditorUI from './rdseditorui';

// Extending the Editor class, which brings the base editor API.
export default class RdsEditor extends ElementApiMixin( Editor ) {
    constructor( element, config ) {
        super( config );

        // Remember the element the editor is created with.
        this.sourceElement = element;
        console.log(element, config);
        // Create the ("main") root element of the model tree.
        // this.model.document.createRoot();

        // The UI layer of the editor.
        this.ui = new RdsEditorUI( this );

        // When editor#element is a textarea inside a form element,
        // the content of this textarea will be updated on form submit.
        attachToForm( this );
    }

    destroy() {
        // When destroyed, the editor sets the output of editor#getData() into editor#element...
        this.updateSourceElement();

        // ...and destroys the UI.
        this.ui.destroy();

        return super.destroy();
    }

    static create( element, config ) {
        return new Promise( resolve => {
            const editor = new this( element, config );

            resolve(
                editor.initPlugins()
                    // Initialize the UI first. See the BootstrapEditorUI class to learn more.
                    .then( () => editor.ui.init( element ) )
                    // Fill the editable with the initial data.
                    .then( () => editor.data.init( getDataFromElement( element ) ) )
                    // Fire the `editor#ready` event that announce the editor is complete and ready to use.
                    .then( () => editor.fire( 'ready' ) )
                    .then( () => editor )
            );
        } );
    }
}