import { CatalogResult, QuicklinksSet, QuicklinksSetForm, QuicklinksSetFormCatalogFilters } from '@app/core/models';
import { createAction, props } from '@ngrx/store';


export const initAddForm = createAction(
  '[QUICKLINKS SETS - FORM] Init form',
  props<{
    quicklinksSet?: Partial<QuicklinksSet>,
    audienceManagerInfo: {
      isAudienceManager: boolean,
      isOnlyAudienceManager: boolean,
      managedTaxonomy: {
        locations: Array<string>,
        departments: Array<string>,
        functions: Array<string>,
      }
    }
  }>()
);

export const setFormValue = createAction(
  '[QUICKLINKS SETS - FORM] Set form value',
  props<{form: Partial<QuicklinksSetForm>}>()
);
export const setFormTouched = createAction(
  '[QUICKLINKS SETS - FORM] Set form touched',
  props<{touched: boolean}>()
);

export const createQuicklinksSetRequest = createAction(
  '[QUICKLINKS SETS - FORM] Create quicklinks set request'
);

export const createQuicklinksSetSuccess = createAction(
  '[QUICKLINKS SETS - FORM] Create quicklinks set success',
);

export const createQuicklinksSetFailure = createAction(
  '[QUICKLINKS SETS - FORM] Create quicklinks set failure',
  props<{error: string}>()
);

export const updateQuicklinksSetRequest = createAction(
  '[QUICKLINKS SETS - FORM] Update quicklinks set request',
  props<{id: number}>()
);

export const updateQuicklinksSetSuccess = createAction(
  '[QUICKLINKS SETS - FORM] Update quicklinks set success',
);

export const updateQuicklinksSetFailure = createAction(
  '[QUICKLINKS SETS - FORM] Update quicklinks set failure',
  props<{error: string}>()
);

export const getQuicklinksSetsRequest = createAction(
  '[QUICKLINKS SETS - FORM] Get quicklinks sets request',
  props<{id: number}>()
);

export const getQuicklinksSetsSuccess = createAction(
  '[QUICKLINKS SETS - FORM] Get quicklinks sets success',
  props<{quicklinksSet: QuicklinksSet}>()
);

export const getQuicklinksSetsFailure = createAction(
  '[QUICKLINKS SETS - FORM] Get quicklinks sets failure',
  props<{error: string}>()
);

export const isNameUniqueRequest = createAction(
  '[QUICKLINKS SETS - FORM] Is name unique request',
  props<{name: string, id?: number}>()
);

export const isNameUniqueSuccess = createAction(
  '[QUICKLINKS SETS - FORM] Is name unique success',
  props<{isUnique: boolean}>()
);

export const isNameUniqueFailure = createAction(
  '[QUICKLINKS SETS - FORM] Is name unique failure',
  props<{error: string}>()
);

export const initCatalogsFilters = createAction(
  '[QUICKLINKS SETS - FORM] Init catalogs filters'
);

export const clearFilteredCatalogs = createAction(
  '[QUICKLINKS SETS - FORM] Clear filtered catalogs'
);

export const getFilteredCatalogsRequest = createAction(
  '[QUICKLINKS SETS - FORM] Get filtered catalogs request',
  props<{filters: QuicklinksSetFormCatalogFilters}>()
);

export const getFilteredCatalogsSuccess = createAction(
  '[QUICKLINKS SETS - FORM] Get filtered catalogs success',
  props<{catalogs: Array<CatalogResult>}>()
);

export const getFilteredCatalogsFailure = createAction(
  '[QUICKLINKS SETS - FORM] Get filtered catalogs failure',
  props<{error: string}>()
);


export const initTopCatalogsFilters = createAction(
  '[QUICKLINKS SETS - FORM] Init top catalogs filters'
);

export const clearFilteredTopCatalogs = createAction(
  '[QUICKLINKS SETS - FORM] Clear filtered top catalogs'
);

export const getFilteredTopCatalogsRequest = createAction(
  '[QUICKLINKS SETS - FORM] Get filtered top catalogs request',
  props<{filters: QuicklinksSetFormCatalogFilters}>()
);

export const getFilteredTopCatalogsSuccess = createAction(
  '[QUICKLINKS SETS - FORM] Get filtered top catalogs success',
  props<{catalogs: Array<CatalogResult>}>()
);

export const getFilteredTopCatalogsFailure = createAction(
  '[QUICKLINKS SETS - FORM] Get filtered top catalogs failure',
  props<{error: string}>()
);

export const clearAll = createAction(
  '[QUICKLINKS SETS - FORM] Clear all'
);

export const deleteQuicklinksSetRequest = createAction(
  '[QUICKLINKS SETS - FORM] Delete Quicklinks set Request',
  props<{id: number}>()
);

export const deleteQuicklinksSetSuccess = createAction(
  '[QUICKLINKS SETS - FORM] Delete Quicklinks set Success',
);

export const deleteQuicklinksSetFailure = createAction(
  '[QUICKLINKS SETS - FORM] Delete Quicklinks set Failure',
  props<{ error: any }>()
);

export const openIntranetCoordinatorsDetailsDialog = createAction(
  '[QUICKLINKS SETS - FORM] Open Intranet Coordinator Details dialog'
);