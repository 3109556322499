  <rds-avatar-group>
    <rds-avatar 
    *ngFor="let user of visibleUsers"
    #avatar="rhFetchAvatar" 
    [size]="size" 
    [rhFetchAvatar]="user.login"
    [rdsTooltipDisabled]="!user.name && !avatar.notFound"
    [rdsTooltip]="avatar.notFound ? 'User with login '+user.login+' not found!' : user.name"
    [rdsTooltipShowDelay]="300">
      <img *ngIf="!!avatar.src" [src]="avatar.src" rds-avatar-image referrerpolicy="no-referrer"/>
      <span *ngIf="!avatar.src && !avatar.notFound && !!user.name" rds-avatar-initials>{{user.name | initials}}</span>
      <rds-icon *ngIf="!avatar.src && !avatar.notFound && !user.name" rds-avatar-icon namespace="outlined" icon="user"></rds-icon>
      <rds-icon *ngIf="!avatar.src && avatar.notFound" rds-avatar-icon class="c-error" namespace="outlined" icon="warning_triangle"></rds-icon>
    </rds-avatar>
    <rds-avatar *ngIf="hiddenUsers.length > 0" [size]="size" [rdsTooltip]="hiddenUsers | listFromArray" [rdsTooltipShowDelay]="300">
      <span rds-avatar-initials>+{{hiddenUsers.length}}</span>
    </rds-avatar>
  </rds-avatar-group>