import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ApiService, HttpError } from '@app/core/services/api/api.service';
import { catchError } from 'rxjs/operators';

import { EndpointsService } from '@core/services/endpoints/endpoints.service';

@Injectable()
export class DynamicLinksService {

  public getDynamicLink(url: string): Observable<string> {
    const api = this.endpoints.ENDPOINT.CONTENT.DYNAMIC_LINK;
    const params = { url: encodeURIComponent(url) };
    return this.http
      .get(this.endpoints.addParams(api, params))
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get dynamic link. ${error}`))
      );
  }

  constructor(
    private http: ApiService,
    private endpoints: EndpointsService,
  ) {}
}
