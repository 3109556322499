<button rds-fab-button size="l" (click)="popover.toggle()" #popover="rdsPopover" rdsPopoverPosition="right-center"
  rdsPopoverClass="roles-helper-popover" [rdsPopover]="popoverTemplate">
  <rds-icon rdsTooltip="Open Roles helper" icon="user" namespace="outlined"></rds-icon>
</button>

<ng-template #popoverTemplate>
  <rds-popover-text-content style="width: 400px; display: block;">
    <div rds-popover-header>
        Set roles
    </div>
    <div rds-popover-paragraph [formGroup]="form">
        <div class="row">
            <div class="col mb-5">
                <rds-alert [style]="'warning'"> Roles change will affect only UI.<br/>API can throw 401 errors </rds-alert>
            </div>
        </div>
      <div class="row">
        <div class="col mb-3">
          <p class="ui-overline-s">
            RocheHome Roles
          </p>
        </div>
      </div>
      <ng-container [formGroup]="form.get('rocheHomeRoles')">
        <div class="row">
            <div class="mb-3 col">
              <rds-switch formControlName="isAdministrator" labelPosition="before" size="m">
                <rds-icon icon="user" namespace="outlined" class="c-600-roche-blue"></rds-icon>
                Admin
              </rds-switch>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col">
              <rds-switch formControlName="isContentQualityManager" labelPosition="before" size="m">
                <rds-icon icon="user" namespace="outlined" class="c-500-extra-dark-green"></rds-icon>
                Content Quality Manager
              </rds-switch>
            </div>
          </div>
          <div class="row">
            <div class="mb-6 col">
              <rds-switch formControlName="isAudienceManager" labelPosition="before" size="m">
                <rds-icon icon="user" namespace="outlined" class="c-200-orange"></rds-icon>
                Intranet Coordinator
              </rds-switch>
            </div>
          </div>
      </ng-container>
      <div class="row">
        <div class="col mb-3">
          <p class="ui-overline-s">
            NewsBoard Roles&nbsp;<rds-icon namespace="outlined" rdsTooltip="If changes not reflected on view, please scroll a bit"  class="text-icon" icon="info"></rds-icon>
          </p>
        </div>
      </div>
      <ng-container [formGroup]="form.get('newsBoardRoles')">
          <div class="row">
            <div class="mb-3 col">
              <rds-switch formControlName="isAdministrator" labelPosition="before" size="m">
                <rds-icon icon="user" namespace="outlined" class="c-600-roche-blue"></rds-icon>
                Administrator
              </rds-switch>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col">
              <rds-switch formControlName="isSuperAdministrator" labelPosition="before" size="m">
                <rds-icon icon="user" namespace="outlined" class="c-600-roche-blue"></rds-icon>
                Super Administrator
              </rds-switch>
            </div>
          </div>
      </ng-container>
    </div>
  </rds-popover-text-content>
</ng-template>