import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType} from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, filter, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import * as fromRouter from '@app/root-store/router';
import * as fromBackButton from '@app/root-store/ui/back-button';
import * as fromActions from './templates.actions';
import * as fromSelectors from './templates.selectors';
import * as fromReducer from './templates.reducer';
import { RdsDialogService } from '@rds/angular-components';
import { Template, TemplateUpdate, TemplatesListType, TemplatesRefresh, mapTemplateToNews } from '@app/core/models/newsboard/template';
import { Pagination } from '@app/core/models';
import { ConfirmDialogComponent, ConfirmDialogData } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { ShareTemplateDialogComponent, ShareTemplateDialogData } from '@app/shared/dialogs/share-template-dialog/share-template-dialog.component';
import { SendCopyDialogComponent, SendCopyDialogData } from '@app/shared/dialogs/send-copy-dialog/send-copy-dialog.component';
import { RenameDialogComponent, RenameDialogData } from '@app/shared/dialogs/rename-dialog/rename-dialog.component';
import { ChannelService } from '@app/newsboard/channel/channel.service';
import { NewsService } from '../../news.service';
import { TemplatesService } from '../../templates.service';
import { NewsboardAuthService } from '@app/core/auth/services/newsboard-auth.service';
import { NewsPreviewDialogComponent, NewsPreviewDialogData } from '@app/shared/dialogs/previews/news-preview-dialog/news-preview-dialog.component';

@Injectable()
export class TemplatesEffects {

  public initTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initTemplates),
      mergeMap(() => [
        fromActions.getMy({refresh: TemplatesRefresh.NONE}),
        fromActions.getShared({refresh: TemplatesRefresh.NONE}),
        fromActions.getReadonly({refresh: TemplatesRefresh.NONE}),
        fromActions.getPredefined({refresh: TemplatesRefresh.NONE}),
        fromActions.getSentToMeRequest({refresh: TemplatesRefresh.NONE}),
      ]),
    )
  );

  
  public useTemplate$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.useTemplate),
    mergeMap(({id, listType}) => [
      fromBackButton.set({
        label: 'Add News',
        routeBack: 'news/create',
        routeCurrent: `/news/create/content?useTemplate=${id}`,
        routeBackQueryParams: {activeTab: listType}
      }),
      fromRouter.go({path: `/news/create/content`, queryParams: {useTemplate: id}})
    ])

  ), {dispatch: true});

  public openTemplatePreview$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.openTemplatePreview),
    switchMap(({id, backButton}) => this.templatesService.get(id).pipe(
      map((template: Template) => {
        const simpleUser = this.auth.currentSimpleUser;
      const isAdmin = this.auth.isAdmin || this.auth.isSuperAdmin;
      const canEdit = isAdmin || !!template.owners.find(u => u.identifier === simpleUser.identifier) || !!template.editors.find(u => u.identifier === simpleUser.identifier);
        const data: NewsPreviewDialogData = {
          news: {
            ...mapTemplateToNews(template),
            authors: [this.auth.currentSimpleUser]
          },
          channel: null,
          context: 'Template',
          backButton,
          buttons: {
            editTemplate: {
              disabled: !canEdit,
              visible: (!template.isPredefined && canEdit) || (template.isPredefined && this.auth.isSuperAdmin)
            }
          }

        }
        const dialog = this.dialogService.open(NewsPreviewDialogComponent, {
          size: 'xl',
          height: '100%',
          maxHeight: '100%',
          data
        }
        )
        return ({dialog, template, backButton})
      }),
      switchMap(({dialog, template, backButton}) => dialog.afterClosed().pipe(
        filter(action => !!action),
        mergeMap((action: string) => {
          switch (action) {
            case 'editTemplate':
              return [
                fromBackButton.set({
                  label: backButton.label,
                  routeBack: backButton.routeBack,
                  routeCurrent: `/news/edit/template/${template.id}`,
                  routeBackQueryParams: backButton.routeBackQueryParams
                }),
                fromRouter.go({path: `/news/edit/template/${template.id}`, queryParams: {}})
              ];
            case 'useTemplate':
              return [
                fromBackButton.set({
                  label: backButton.label,
                  routeBack: backButton.routeBack,
                  routeCurrent: `/news/create/content?useTemplate=${template.id}`,
                  routeBackQueryParams: backButton.routeBackQueryParams
                }),
                fromRouter.go({path: `/news/create/content`, queryParams: {useTemplate: template.id}})
              ];
          }
        })
      )),
    ))
  ), {dispatch: true});

  public getMy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getMy),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectMyTemplatesPagination))),
      map(([{refresh}, pagination]) => {
        const isTotalLowerThanPageSize = pagination.totalCount < pagination.pageSize;
        const shouldAdd = refresh === TemplatesRefresh.REFRESH_PLUS;
        return {
        isTotalLowerThanPageSize,
        shouldAdd,
        refresh,
        pagination: refresh > 0? {
          ...Pagination,
          pageIndex: 0,
          pageSize: isTotalLowerThanPageSize ? pagination.totalCount + (shouldAdd ? 1 : 0) : ((pagination.pageIndex + 1) * pagination.pageSize)
        } : pagination}
      }),
      switchMap(({refresh, pagination, isTotalLowerThanPageSize, shouldAdd}) => this.templatesService.getTemplatesList(pagination, 'mytemplates').pipe(
        mergeMap(({data, pagination}) => [
          fromActions.getListSuccess({listType: 'mytemplates', data, pagination, refresh}),
          fromActions.incrementTotalCount({listType: 'mytemplates', by: isTotalLowerThanPageSize && shouldAdd ? 1 : 0})
        ]),
        catchError((error) => of(fromActions.getListFailure({listType: 'mytemplates', error})))
      )),
    )
  );
  public getShared$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getShared),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectSharedTemplatesPagination))),
      map(([{refresh}, pagination]) => {
        const isTotalLowerThanPageSize = pagination.totalCount < pagination.pageSize;
        const shouldAdd = refresh === TemplatesRefresh.REFRESH_PLUS;
        return {
        isTotalLowerThanPageSize,
        shouldAdd,
        refresh,
        pagination: refresh > 0? {
          ...Pagination,
          pageIndex: 0,
          pageSize: isTotalLowerThanPageSize ? pagination.totalCount + (shouldAdd ? 1 : 0) : ((pagination.pageIndex + 1) * pagination.pageSize)
        } : pagination}
      }),
      switchMap(({refresh, pagination, isTotalLowerThanPageSize, shouldAdd}) => this.templatesService.getTemplatesList(pagination, 'shared').pipe(
        mergeMap(({data, pagination}) => [
          fromActions.getListSuccess({listType: 'shared', data, pagination, refresh}),
          fromActions.incrementTotalCount({listType: 'shared', by: isTotalLowerThanPageSize && shouldAdd ? 1 : 0})
        ]),
        catchError((error) => of(fromActions.getListFailure({listType: 'shared', error})))
      )),
    )
  );

  public getReadonly$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getReadonly),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectReadonlyTemplatesPagination))),
      map(([{refresh}, pagination]) => {
        const isTotalLowerThanPageSize = pagination.totalCount < pagination.pageSize;
        const shouldAdd = refresh === TemplatesRefresh.REFRESH_PLUS;
        return {
        isTotalLowerThanPageSize,
        shouldAdd,
        refresh,
        pagination: refresh > 0? {
          ...Pagination,
          pageIndex: 0,
          pageSize: isTotalLowerThanPageSize ? pagination.totalCount + (shouldAdd ? 1 : 0) : ((pagination.pageIndex + 1) * pagination.pageSize)
        } : pagination}
      }),
      switchMap(({refresh, pagination, isTotalLowerThanPageSize, shouldAdd}) => this.templatesService.getTemplatesList(pagination, 'readonly').pipe(
        mergeMap(({data, pagination}) => [
          fromActions.getListSuccess({listType: 'readonly', data, pagination, refresh}),
          fromActions.incrementTotalCount({listType: 'readonly', by: isTotalLowerThanPageSize && shouldAdd ? 1 : 0})
        ]),
        catchError((error) => of(fromActions.getListFailure({listType: 'readonly', error})))
      )),
    )
  );
  public getPredefined$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getPredefined),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectPredefinedPagination))),
      map(([{refresh}, pagination]) => {
        const isTotalLowerThanPageSize = pagination.totalCount < pagination.pageSize;
        const shouldAdd = refresh === TemplatesRefresh.REFRESH_PLUS;
        return {
        isTotalLowerThanPageSize,
        shouldAdd,
        refresh,
        pagination: refresh > 0? {
          ...Pagination,
          pageIndex: 0,
          pageSize: isTotalLowerThanPageSize ? pagination.totalCount + (shouldAdd ? 1 : 0) : ((pagination.pageIndex + 1) * pagination.pageSize)
        } : pagination}
      }),
      switchMap(({refresh, pagination, isTotalLowerThanPageSize, shouldAdd}) => this.templatesService.getTemplatesList(pagination, 'predefined').pipe(
        mergeMap(({data, pagination}) => [
          fromActions.getListSuccess({listType: 'predefined', data, pagination, refresh}),
          fromActions.incrementTotalCount({listType: 'predefined', by: isTotalLowerThanPageSize && shouldAdd ? 1 : 0})
        ]),
        catchError((error) => of(fromActions.getListFailure({listType: 'predefined', error})))
      )),
    )
  );

  public getSentToMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getSentToMeRequest),
      withLatestFrom(this.store$.pipe(select(fromSelectors.selectSentToMeTemplatesPagination))),
      map(([{refresh}, pagination]) => {
        const isTotalLowerThanPageSize = pagination.totalCount < pagination.pageSize;
        const shouldAdd = refresh === TemplatesRefresh.REFRESH_PLUS;
        return {
        isTotalLowerThanPageSize,
        shouldAdd,
        refresh,
        pagination: refresh > 0? {
          ...Pagination,
          pageIndex: 0,
          pageSize: isTotalLowerThanPageSize ? pagination.totalCount + (shouldAdd ? 1 : 0) : ((pagination.pageIndex + 1) * pagination.pageSize)
        } : pagination}
      }),
      switchMap(({refresh, pagination, isTotalLowerThanPageSize, shouldAdd}) => this.templatesService.getSentToMeTemplatesList(pagination).pipe(
        map(({data, pagination}) => fromActions.getSentToMeSuccess({data, pagination, refresh})),
        catchError((error) => of(fromActions.getSentToMeFailure({error})))
      )),
    )
  );
  public deleteTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteTemplateRequest),
      switchMap(({id, listType}) => this.templatesService.deleteTemplate(id).pipe(
        map(() => fromActions.deleteTemplateSuccess({id, listType})),
        catchError((error) => of(fromActions.deleteTemplateFailure({error})))
      )),
    )
  );

  public deleteTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteTemplateSuccess),
      map(({listType}) => {
        switch (listType) {
          case 'mytemplates':
            return fromActions.getMy({refresh: TemplatesRefresh.REFRESH})
          case 'shared':
            return fromActions.getShared({refresh: TemplatesRefresh.REFRESH})
          case 'readonly':
            return fromActions.getReadonly({refresh: TemplatesRefresh.REFRESH})
          case 'predefined':
            return fromActions.getPredefined({refresh: TemplatesRefresh.REFRESH})
        }
      }),
    )
  );

  
  public openDeleteTemplateDialog$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.openDeleteTemplateDialog),
    map(({template, listType}) => {
      let title = '';
      let messages = [];
      switch(listType) {
        case 'mytemplates': {
          title = 'Delete this template?',
          messages.push('It will not be visible in My templates any more.')
          break;
        }
        case 'predefined': {
          title = 'Delete predefined template?',
          messages.push('It will not be visible in Predefined templates any more.')
          break;
        }
        case 'shared': {
          title = 'Delete shared template?',
          messages.push('It will not be visible in Shared templates for <strong>all enabled users.</strong>')
          break;
        }
      }
      const data: ConfirmDialogData = {
        ids: [template.id],
        title,
        messages,
        cancelButtonLabel: `Cancel`,
        confirmButtonLabel: `Delete template`,
        confirmButtonType: 'warning'
      }
      const dialog = this.dialogService.open(ConfirmDialogComponent, {
        size: 'm',
        data
      });
      return ({template, listType, dialog})
    }),
    switchMap(({template, listType, dialog}) => dialog.afterClosed().pipe(
      filter(data => !!data),
      map((data) => fromActions.deleteTemplateRequest({id: data.ids[0], listType}))
    )),
  ), { dispatch: true}
);

  public openShareTemplateDialog$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.openShareTemplateDialog),
    map(({template, listType}) => {
      const data: ShareTemplateDialogData = {
        template
      }
      const dialog = this.dialogService.open(ShareTemplateDialogComponent, {
        size: 'l',
        data
      });
      return ({template, listType, dialog})
    }),
    switchMap(({template, listType, dialog}) => dialog.afterClosed().pipe(
      filter(data => !!data),
      map(({value, isMyTemplate, initialUsersNotChanged, initialRolesNotChanged}) => {
        const simpleUser = this.auth.currentSimpleUser;
        let listsToRefresh: Array<{list: TemplatesListType,refresh: TemplatesRefresh}> = [];
        const sumOldUsers = template.owners.length + template.editors.length + template.viewers.length;
        const sumNewUsers = value.owners.length + value.editors.length + value.viewers.length;
        const fromMyToShared = (sumOldUsers === 1 && sumNewUsers > 1);
        const fromSharedToMy = (sumOldUsers > 1 && sumNewUsers === 1);
        const isViewer = value.viewers.find(u => u.identifier === simpleUser.identifier);
        if (fromMyToShared || fromSharedToMy) {
          listsToRefresh = [
            {list: 'mytemplates', refresh: fromMyToShared ? TemplatesRefresh.REFRESH : TemplatesRefresh.REFRESH_PLUS},
            {list: 'shared', refresh: fromSharedToMy ? TemplatesRefresh.REFRESH : (isViewer ? TemplatesRefresh.REFRESH : TemplatesRefresh.REFRESH_PLUS)},
            {list: 'readonly', refresh: fromSharedToMy ? TemplatesRefresh.REFRESH : (isViewer ? TemplatesRefresh.REFRESH_PLUS : TemplatesRefresh.REFRESH)}
          ];
        } else if (isViewer) {
          listsToRefresh = [
            {list: listType, refresh: TemplatesRefresh.REFRESH},
            {list: 'readonly', refresh: TemplatesRefresh.REFRESH_PLUS}
          ];
        } else {
          listsToRefresh = [
            {list: listType, refresh: TemplatesRefresh.REFRESH},
          ];
        }
        const templateUpdate: Partial<TemplateUpdate> = {
          contentCreate: [],
          contentDelete: [],
          contentUpdate: [],
          editors: value.editors,
          owners: value.owners,
          viewers: value.viewers,
          imagePreview: template.imagePreview,
          isPredefined: template.isPredefined,
          updateMetaData: false
        }
        let updateContext = '';

        if (
            (isMyTemplate && !initialRolesNotChanged && !initialRolesNotChanged)
            ||
            (!isMyTemplate && initialUsersNotChanged && !initialRolesNotChanged)
          ) {
          updateContext = 'permissionsUpdated';
        }
        if (!isMyTemplate && !initialRolesNotChanged && !initialRolesNotChanged) {
          updateContext = 'sharedAndPermissionsUpdated';
        }
        if (!isMyTemplate && !initialRolesNotChanged && initialRolesNotChanged) {
          updateContext = 'shared';
        }

        return ({id: template.id, templateUpdate, listType, listsToRefresh, updateContext})
      })
    )),
    map(({id, templateUpdate, listType, listsToRefresh, updateContext}) => fromActions.updateTemplateRequest({id, templateUpdate, listType, listsToRefresh, updateContext}))
  ), { dispatch: true}
);


public openSendCopyDialog$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.openSendTemplateCopyDialog),
  map(({template,}) => {
    const data: SendCopyDialogData = {
      title: 'Send template copy',
      message: 'Template will appear in recipients "My templates" tab.',
      confirmButtonLabel: 'Send template',
    }
    const dialog = this.dialogService.open(SendCopyDialogComponent, {
      size: 'l',
      data
    });
    return ({template, dialog})
  }),
  switchMap(({template, dialog}) => dialog.afterClosed().pipe(
    filter(data => !!data),
    map((users) => {
      return ({id: template.id, users})
    })
  )),
  map(({id, users}) => fromActions.sendTemplateCopyRequest({id, users}))
), { dispatch: true}
);

public sendTemplateCopyRequest$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.sendTemplateCopyRequest),
  switchMap(({id, users}) => this.templatesService.sendTemplateCopy(id, users).pipe(
    map(() => fromActions.sendTemplateCopySuccess()),
    catchError((error) => of(fromActions.sendTemplateCopyFailure({error})))
  )),
)
);
public acceptTemplateCopyRequest$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.acceptTemplateCopyRequest),
  switchMap(({template}) => this.templatesService.acceptTemplateCopy(template.id).pipe(
    map(() => fromActions.acceptTemplateCopySuccess()),
    catchError((error) => of(fromActions.acceptTemplateCopyFailure({error})))
  )),
)
);
public rejectTemplateCopyRequest$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.rejectTemplateCopyRequest),
  switchMap(({template}) => this.templatesService.rejectTemplateCopy(template.id).pipe(
    map(() => fromActions.rejectTemplateCopySuccess()),
    catchError((error) => of(fromActions.rejectTemplateCopyFailure({error})))
  )),
)
);

public openRenameDialog$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.openRenameDialog),
  map(({template, listType}) => {
    const data: RenameDialogData = {
      title: 'Rename Template',
      oldName: template.title,
      controlLabel: 'Title of the Template',
      confirmButtonLabel: 'Rename Template',
      required: true,
      maxLength: 100,
    }
    const dialog = this.dialogService.open(RenameDialogComponent, {
      size: 'l',
      data
    });
    return ({template, listType, dialog})
  }),
  switchMap(({template, listType, dialog}) => dialog.afterClosed().pipe(
    filter(data => !!data),
    map((title) => {
      const templateUpdate: Partial<TemplateUpdate> = {
        contentCreate: [],
        contentDelete: [],
        contentUpdate: [],
        imagePreview: template.imagePreview,
        isPredefined: template.isPredefined,
        title: title,
        updateMetaData: false
      }
      return ({id: template.id, templateUpdate, listType})
    })
  )),
    map(({id, templateUpdate, listType}) => fromActions.updateTemplateRequest({id, templateUpdate, listType, listsToRefresh: [{list: listType, refresh: TemplatesRefresh.REFRESH}], updateContext: 'renamed'}))
), { dispatch: true}
);

public updateTemplateRequest$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.updateTemplateRequest),
  switchMap(({id, templateUpdate, listType, listsToRefresh, updateContext}) => this.templatesService.updateTemplate(id, templateUpdate).pipe(
    map(() => fromActions.updateTemplateSuccess({listType, listsToRefresh, updateContext})),
    catchError((error) => of(fromActions.updateTemplateFailure({error})))
  )),
)
);

public updateTemplateSuccess$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.updateTemplateSuccess),
  mergeMap(({listType, listsToRefresh}) => {
    const actions = [];
    listsToRefresh.forEach(l => {
      switch (l.list) {
        case 'mytemplates':
          actions.push(fromActions.getMy({refresh: l.refresh}))
          break;
        case 'shared':
          actions.push(fromActions.getShared({refresh: l.refresh}))
          break;
        case 'readonly':
          actions.push(fromActions.getReadonly({refresh: l.refresh}))
          break;
        case 'predefined':
          actions.push(fromActions.getPredefined({refresh: l.refresh}))
          break;
      }
    })
    return actions;
  }),
)
);

public acceptRejectSuccess$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.acceptTemplateCopySuccess, fromActions.rejectTemplateCopySuccess),
  mergeMap(() => [
    fromActions.getMy({refresh: TemplatesRefresh.REFRESH_PLUS}),
    fromActions.getSentToMeRequest({refresh: TemplatesRefresh.REFRESH})
  ]),
)
);

public loadMore$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.loadMore),
  map(({listType}) => {
    switch (listType) {
      case 'mytemplates':
        return fromActions.getMy({refresh: TemplatesRefresh.NONE})
      case 'shared':
        return fromActions.getShared({refresh: TemplatesRefresh.NONE})
      case 'readonly':
        return fromActions.getReadonly({refresh: TemplatesRefresh.NONE})
      case 'predefined':
        return fromActions.getPredefined({refresh: TemplatesRefresh.NONE})
    }
  }),
)
);

public loadMoreSentToMe$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.loadMoreSentToMe),
  map(() => fromActions.getSentToMeRequest({refresh: TemplatesRefresh.NONE})),
)
);

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private channelService: ChannelService,
    private newsService: NewsService,
    private templatesService: TemplatesService,
    private auth: NewsboardAuthService,
    private dialogService: RdsDialogService
  ) {}
}
