import {createAction, props} from '@ngrx/store';
import {
  ColorModel,
  DraftModel,
  MySavedTemplatesModel,
  NewsletterListItemModel,
  NewsletterListModel,
  SelectedTemplateModel,
  TemplateModel
} from '@app/newsletter/models';
import { SaveNewsletterPreviewDialogData } from '@app/newsletter/dialogs/save-newsletter-preview-dialog/save-newsletter-preview-dialog.component';

export const getDrafts = createAction(
  '[NEWSLETTER - DRAFT] Get request '
);

export const getDraftSuccess = createAction(
  '[NEWSLETTER - DRAFT] load success',
  props<{ draft: DraftModel }>()
);
export const getDraftFailure = createAction(
  '[NEWSLETTER - DRAFT] load failed',
  props<{ message: string }>()
);

export const getTemplates = createAction(
  '[NEWSLETTER - TEMPLATES] init'
);

export const getTemplatesFailure = createAction(
  '[NEWSLETTER - TEMPLATES] Get templates failure',
  props<{ message: string }>()
);

export const getTemplatesSuccess = createAction(
  '[NEWSLETTER - TEMPLATES] Get templates success',
  props<{ templates: TemplateModel[] }>()
);

export const changeTemplate = createAction(
  '[NEWSLETTER - TEMPLATES] Select template',
  props<{ selectedTemplate: SelectedTemplateModel }>()
);

export const changeColor = createAction(
  '[NEWSLETTER - TEMPLATES] Select color',
  props<{ selectedColor: ColorModel }>()
);

export const changeTemplateAndColor = createAction(
  '[NEWSLETTER - TEMPLATES] change template and color',
  props<{ templateAndColor: { templateId: string, color: string } }>()
);

export const clearLoadedForm = createAction(
  '[NEWSLETTER - TEMPLATES] selected'
);

export const getNewsletters = createAction(
  '[NEWSLETTER - NEWSLETTERS] init'
);

export const getNewslettersFailure = createAction(
  '[NEWSLETTER - NEWSLETTERS] Get failure',
  props<{ message: string }>()
);

export const getNewslettersSuccess = createAction(
  '[NEWSLETTER - NEWSLETTERS] Get success',
  props<{ newsletters: { months: { month: string, newsletters: NewsletterListItemModel[] }[] } }>()
);
export const changeTab = createAction(
  '[NEWSLETTER - TAB CHANGE] Change success',
  props<{ selectedTab: number }>()
);
export const getMyRecentDraft = createAction(
  '[NEWSLETTER - MY NEWSLETTERS] Get recent draft',
  props<{
    pageSize?: number,
    pageIndex?: number,
    isLoadMore?: boolean
  }>()
);

export const clearMyRecentDraft = createAction(
  '[NEWSLETTER - MY NEWSLETTERS] clear my recent draft store'
);

export const getMyRecentDraftSuccess = createAction(
  '[NEWSLETTER - MY NEWSLETTERS] Get recent draft success',
  props<{ myRecentDraft: NewsletterListModel, isLoadMore: boolean }>()
);

export const getMyRecentDraftFailure = createAction(
  '[NEWSLETTER - MY NEWSLETTERS] Get my recent draft failure',
  props<{ message: string }>()
);

export const getMyRecentlyScheduled = createAction(
  '[NEWSLETTER - MY NEWSLETTERS] Get recently schedule',
  props<{
    pageSize?: number,
    pageIndex?: number,
    isLoadMore?: boolean
  }>()
);

export const getMyRecentlyScheduledSuccess = createAction(
  '[NEWSLETTER - MY NEWSLETTERS]  Get my recently schedule success',
  props<{ myRecentlyScheduled: NewsletterListModel, isLoadMore: boolean }>()
);

export const getMyRecentlyScheduledFailure = createAction(
  '[NEWSLETTER - MY NEWSLETTERS]  Get my recently schedule failure',
  props<{ message: string }>()
);

export const clearMyRecentlyScheduled = createAction(
  '[NEWSLETTER - MY NEWSLETTERS] clear my recently scheduled store'
);

export const getMyRecentlySent = createAction(
  '[NEWSLETTER - MY NEWSLETTERS] Get my recently sent',
  props<{
    pageSize?: number,
    pageIndex?: number,
    isLoadMore?: boolean
  }>()
);

export const getMyRecentlySentSuccess = createAction(
  '[NEWSLETTER - MY NEWSLETTERS] Get my recently sent success',
  props<{ myRecentlySent: NewsletterListModel, isLoadMore: boolean }>()
);

export const getMyRecentlySentFailure = createAction(
  '[NEWSLETTER - MY NEWSLETTERS] Get my recently sent failure',
  props<{ message: string }>()
);

export const clearMyRecentlySent = createAction(
  '[NEWSLETTER - MY NEWSLETTERS] clear my recently sent store'
);

export const getSharedRecentDraft = createAction(
  '[NEWSLETTER - SHARED NEWSLETTERS] Get recent draft',
  props<{
    pageSize?: number,
    pageIndex?: number,
    isLoadMore?: boolean
  }>()
);

export const getSharedRecentDraftSuccess = createAction(
  '[NEWSLETTER - SHARED NEWSLETTERS] Get recent draft success',
  props<{ sharedRecentDraft: NewsletterListModel, isLoadMore: boolean }>()
);

export const getSharedRecentDraftFailure = createAction(
  '[NEWSLETTER - SHARED NEWSLETTERS] Get recent draft failure',
  props<{ message: string }>()
);

export const getSharedRecentlyScheduled = createAction(
  '[NEWSLETTER - SHARED NEWSLETTERS] Get recently schedule',
  props<{
    pageSize?: number,
    pageIndex?: number,
    isLoadMore?: boolean
  }>()
);

export const getSharedRecentlyScheduledSuccess = createAction(
  '[NEWSLETTER - SHARED NEWSLETTERS] Get recently schedule success',
  props<{ sharedRecentlyScheduled: NewsletterListModel, isLoadMore: boolean }>()
);

export const getSharedRecentlyScheduledFailure = createAction(
  '[NEWSLETTER - SHARED NEWSLETTERS]  Get recently schedule failure',
  props<{ message: string }>()
);

export const clearSharedRecentlyScheduled = createAction(
  '[NEWSLETTER - SHARED NEWSLETTERS] clear recently scheduled store'
);


export const getSharedRecentlySent = createAction(
  '[NEWSLETTER - SHARED NEWSLETTERS] Get recently sent',
  props<{
    pageSize?: number,
    pageIndex?: number,
    isLoadMore?: boolean
  }>()
);

export const getSharedRecentlySentSuccess = createAction(
  '[NEWSLETTER - SHARED NEWSLETTERS] Get recently sent success',
  props<{ sharedRecentlySent: NewsletterListModel, isLoadMore: boolean }>()
);

export const getSharedRecentlySentFailure = createAction(
  '[NEWSLETTER - SHARED NEWSLETTERS] Get recently sent failure',
  props<{ message: string }>()
);

export const clearSharedRecentlySent = createAction(
  '[NEWSLETTER - SHARED NEWSLETTERS] clear recently sent store'
);

export const openPreviewNewsletterDialog = createAction(
  '[NEWSLETTER - DASHBOARD] Open preview Newsletter modal',
  props<{ card: NewsletterListItemModel }>()
);

export const openPreviewPredefinedTemplateDialog = createAction(
  '[NEWSLETTER - DASHBOARD] Open preview Predefined Template modal',
  props<{ templateId: string}>()
);

export const openReuseNewsletterDialog = createAction(
  '[NEWSLETTER - DASHBOARD] Open reuse Newsletter modal',
  props<{ card: NewsletterListItemModel }>()
);

export const editNewsletter = createAction(
  '[NEWSLETTER - DASHBOARD] Edit newsletter',
  props<{ id: number }>()
);

export const reuseNewsletter = createAction(
  '[NEWSLETTER - DASHBOARD] Reuse newsletter',
  props<{ id: number }>()
);

export const openDeleteNewsletterDialog = createAction(
  '[NEWSLETTER - DASHBOARD] Open delete Newsletter modal',
  props<{ card: NewsletterListItemModel }>()
);

export const deleteNewsletterRequest = createAction(
  '[NEWSLETTER - DASHBOARD] delete Newsletter Request',
  props<{ id: number }>()
);

export const deleteNewsletterSuccess = createAction(
  '[NEWSLETTER - DASHBOARD] delete Newsletter Success',
  props<{ id: number }>()
);

export const deleteNewsletterFailure = createAction(
  '[NEWSLETTER - DASHBOARD] delete Newsletter Failure',
  props<{ message: string }>()
);

export const openChangeTemplateDialog = createAction(
  '[NEWSLETTER - DASHBOARD] Open change template modal',
  props<{ templateId: string }>()
);

export const openCancelNewsletterDialog = createAction(
  '[NEWSLETTER - DASHBOARD] Open cancel Newsletter modal',
  props<{ card: NewsletterListItemModel }>()
);

export const cancelNewsletterRequest = createAction(
  '[NEWSLETTER - DASHBOARD] cancel Newsletter Request',
  props<{ id: number }>()
);

export const cancelNewsletterSuccess = createAction(
  '[NEWSLETTER - DASHBOARD] cancel Newsletter Success',
  props<{ id: number }>()
);

export const cancelNewsletterFailure = createAction(
  '[NEWSLETTER - DASHBOARD] cancel Newsletter Failure',
  props<{ message: string }>()
);


export const openSaveAsTemplateDialog = createAction(
  '[NEWSLETTER - DASHBOARD] Open save as template Newsletter modal',
  props<{ template: MySavedTemplatesModel }>()
);

export const startSaveAsTemplateProccess = createAction(
  '[NEWSLETTER - DASHBOARD] Start Save as template process',
  props<{ data: SaveNewsletterPreviewDialogData, template: MySavedTemplatesModel }>()

);

export const saveAsTemplateRequest = createAction(
  '[NEWSLETTER - DASHBOARD] save as template Newsletter Request',
  props<{ template: MySavedTemplatesModel }>()
);

export const saveAsTemplateSuccess = createAction(
  '[NEWSLETTER - DASHBOARD] save as template Newsletter Success',
);

export const saveAsTemplateFailure = createAction(
  '[NEWSLETTER - DASHBOARD] save as template Newsletter Failure',
  props<{ message: string }>()
);

export const setContributors = createAction(
  '[NEWSLETTER - DASHBOARD] Set contributors request',
  props<{ newsletterId: number, logins: Array<string> }>()
);

export const setContributorsSuccess = createAction(
  '[NEWSLETTER - DASHBOARD] Set contributors success',
  props<{ newsletter: NewsletterListItemModel }>()
);

export const setContributorsFailure = createAction(
  '[NEWSLETTER - DASHBOARD] Set contributors failure',
  props<{ message: string }>()
);

export const openRenameDialog = createAction(
  '[NEWSLETTER - DASHBOARD] Open rename Newsletter modal',
  props<{ id: number, name: string }>()
);

export const renameNewsletterSuccess = createAction(
  '[NEWSLETTER - DASHBOARD] rename Template Success',
  props<{ newsletter: MySavedTemplatesModel }>()
);

export const renameNewsletterFailure = createAction(
  '[NEWSLETTER - DASHBOARD] rename Template Failure',
  props<{ message: string }>()
);
