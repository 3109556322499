import {ModuleWithProviders, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {GUARDS} from './guards';
import {SERVICES} from './services';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {authReducer, feature} from './store/auth.reducer';
import {AuthEffects} from '@core/auth/store';
import {TokenInterceptor} from './interceptors/token.interceptor';

const EFFECTS = [
  AuthEffects
];


const PROVIDERS = [
  {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
];

@NgModule({
  imports: [
    StoreModule.forFeature(feature, authReducer),
    EffectsModule.forFeature(EFFECTS),
  ],
  declarations: [],
  exports: [],
  providers: []
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        ...GUARDS,
        ...SERVICES,
        ...PROVIDERS
      ]
    };
  }
}
