import { Injectable } from '@angular/core';
import { ChannelSetService } from '@app/core/services/channel-set.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, mergeMap, switchMap } from 'rxjs/operators';

import * as fromActions from '@app/manage-sets/store/channels-sets-table/channels-sets-table.actions';
import * as fromReducer from '@app/manage-sets/store/channels-sets-table/channels-sets-table.reducer';
import * as fromSelectors from '@app/manage-sets/store/channels-sets-table/channels-sets-table.selectors';

import { ChannelsSet } from '@app/core/models';
import { ContentService } from '@app/core/services/content.service';
import { WorkdayService } from '@app/core/services/workday.service';

@Injectable()
export class ChannelsSetsTableEffects {

  
public initTable$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.initTable),
  switchMap(() => this.store$.pipe(
      select(fromSelectors.selectTableRequestData),
      debounceTime(50),
      distinctUntilChanged((prev, next) => {
        if (prev.resetIndex && !next.resetIndex) {
          delete prev.resetIndex
          delete next.resetIndex
        }
        return JSON.stringify(prev) === JSON.stringify(next)
      }),
      map(({filters, pageIndex, pageSize, sort, resetIndex}) => ({
        filters, 
        sort,
        pageIndex: resetIndex ? 0 : pageIndex,
        pageSize
      }))
    )
  ),
  map((requestData) => fromActions.getTableRequest(requestData))
), { dispatch: true}
);
public getTableRequest$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.getTableRequest),
  switchMap((requestData) => this.channelsSetService.putFilterChannelsSets(requestData).pipe(
    map(res => ({
      data: res.data as Array<ChannelsSet>, pagination: res.pagination
    }))
  )),
  map(({data, pagination}) => fromActions.getTableSuccess({data, pagination})),
  catchError(({ message }) => of(fromActions.getTableFailure({ error: message })))

), { dispatch: true}
);
  
public deleteChannelsSetRequest$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromActions.deleteChannelsSetRequest),
  mergeMap(({id}) =>
    this.channelsSetService.deleteChannelsSet(id).pipe(
      mergeMap(() => [
        fromActions.deleteChannelsSetSuccess(),
      ]),
      catchError(({ message }) => of(fromActions.deleteChannelsSetFailure({error: message })))
    )
  )
), { dispatch: true }
);

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private contentService: ContentService,
    private channelsSetService: ChannelSetService,
    private workdayService: WorkdayService
  ) {}
}
