import { createSelector } from '@ngrx/store';

import * as fromCore from '@core/core-store/core.reducer';
import * as fromReducer from './languages-dict.reducer';

export const selectState = createSelector(
  fromCore.selectState,
  fromCore.getLanguagesDict
);

export const selectAll = createSelector(
  selectState,
  fromReducer.getAll
);

export const selectEntities = createSelector(
  selectState,
  fromReducer.getEntities
);

export const selectIds = createSelector(
  selectState,
  fromReducer.getIds
);

export const selectTotal = createSelector(
  selectState,
  fromReducer.getTotal
);

export const selectEntityById = createSelector(
  selectEntities,
  fromReducer.getEntityById
);

export const selectEntitiesById = createSelector(
  selectEntities,
  fromReducer.getEntitiesById
);

export const selectLanguageByCode = (code: string) => createSelector(
  selectAll,
  (languages) => languages.find((l) => l.code === code),
);

export const selectLanguagesByCode = (codes: Array<string>) => createSelector(
  selectAll,
  (languages) => languages.filter((l) => codes.includes(l.code)),
);
