import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostBinding,
	HostListener,
	OnDestroy,
	OnInit,
	QueryList,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as fromQuickLink from '@app/layout/header/store/quick-link';
import * as fromRouter from '@app/root-store/router';
import * as fromUiScreen from '@app/root-store/ui/screen';
import { select, Store } from '@ngrx/store';
import { RdsDropdownDirective, RdsHeaderSearchbarComponent, RdsMenuTriggerDirective } from '@rds/angular-components';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import * as fromHeader from '../../store/header';
import * as fromHeaderActions from '../../store/header/header.actions';

@Component({
	selector: 'rh-header2',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{ provide: Window, useValue: window }],
})
export class HeaderComponents2 implements OnInit, OnDestroy {
	@HostBinding('style.top') top: string = '0';
	@HostBinding('style.opacity') opacity: string = '1';

	@HostListener('window:scroll')
	scrolled() {
		if (!this.forceClose) {
			let html = document.querySelector('html');

			if (this.scrollPosition > this.window.scrollY && !html.className) {
				// this.opacity = '1';
				this.top = '0';
				this.store$.dispatch(fromHeaderActions.setHeaderVisible({ visible: true }));
			} else if (this.window.scrollY > 119) {
				this.top = '-120px';
				// this.opacity = '0'
				this.store$.dispatch(fromHeaderActions.setHeaderVisible({ visible: false }));
				this.gcsMenuTrigger.closeMenu();
				this.appMenuTrigger.closeMenu();
				this.topTriggers.map((t) => t.closeMenu());
				this.bottomTriggers.map((t) => t.closeMenu());
			}
		}
		this.scrollPosition = this.window.scrollY;
	}

	@ViewChild('searchSuggestions', { static: true }) searchSuggestions: RdsDropdownDirective;
	@ViewChild('searchBar', { static: true }) searchBar: RdsHeaderSearchbarComponent;
	@ViewChildren('bottomTrigger') bottomTriggers: QueryList<RdsMenuTriggerDirective>;
	@ViewChildren('topTrigger') topTriggers: QueryList<RdsMenuTriggerDirective>;
	@ViewChild('gcsMenuTrigger', { static: true }) gcsMenuTrigger: RdsMenuTriggerDirective;
	@ViewChild('appMenuTrigger', { static: true }) appMenuTrigger: RdsMenuTriggerDirective;

	scrollPosition = this.window.scrollY;

	private subs: SubSink = new SubSink();

	private forceClose = false;

	public isMobile: true | false;
	public isMobile$: Observable<boolean> = this.store$.pipe(
		select(fromUiScreen.selectIsMobile),
		tap((res) => (this.isMobile = res))
	);
	public areOpenedQuickLinks: boolean;
	public areOpenedQuickLinks$ = this.store$.pipe(select(fromQuickLink.selectQuickLinksOpened));

	public selectLinks$ = this.store$.pipe(select(fromHeader.selectNodes));

	public forceClose$ = this.store$.pipe(select(fromHeader.selectForceClose));

	suggestionPanelCondition$: Observable<{
		inputFocused: boolean;
		ignoreFocus: boolean;
		phraseLength: number;
		suggestionsLength: number;
		suggestionsLoading: boolean;
	}> = this.store$.pipe(select(fromHeader.selectSuggestionPanelCondition));

	public bottomLinks;
	public topLinks;

	suggestionsLength: number;
	suggestionsLoading: boolean;

	_suggestionsOpened: boolean;
	get suggestionsOpened() {
		return this._suggestionsOpened;
	}

	set suggestionsOpened(value: boolean) {
		this._suggestionsOpened = value;
		this.setScrollDisabled(value);
		this._suggestionsOpened ? this.searchSuggestions.show() : this.searchSuggestions.hide();
		this.searchSuggestions.isOpen();
	}

	onHide() {
		this.suggestionsOpened =
			this.searchBar.searchInput.nativeElement === document.activeElement &&
			((!this.suggestionsLoading && this.suggestionsLength > 0) || this.suggestionsLoading);
	}

	ngOnInit(): void {
		this.setRdsInputHooks();

		this.subs.sink = this.activatedRoute.queryParams.subscribe((params) => {
			if (params.phrase) {
				this.searchBar.searchValue = params.phrase;
				(this.searchBar.searchInput.nativeElement as HTMLInputElement).value = params.phrase;
				this.store$.dispatch(fromHeaderActions.setPhrase({ phrase: params.phrase }));
				this.store$.dispatch(fromHeaderActions.loadSuggestions({ text: params.phrase }));
			} else {
				this.searchBar.searchValue = '';
				(this.searchBar.searchInput.nativeElement as HTMLInputElement).value = '';
			}
		});

		this.subs.sink = this.selectLinks$.subscribe((nodes) => {
			this.bottomLinks = nodes.filter((n) => n.link !== 'Tools ' && n.link !== 'Tools & Services');
			this.topLinks = nodes.filter((n) => n.link === 'Tools ' || n.link === 'Tools & Services');
			this.cdr.detectChanges();
		});

		this.subs.sink = this.suggestionPanelCondition$.pipe().subscribe((res) => {
			this.suggestionsLength = res.suggestionsLength;
			this.suggestionsLoading = res.suggestionsLoading;
			this.suggestionsOpened =
				res.phraseLength > 1 &&
				(res.inputFocused || res.ignoreFocus) &&
				((!this.suggestionsLoading && this.suggestionsLength > 0) || this.suggestionsLoading);
		});

		this.subs.sink = this.forceClose$.subscribe((close) => {
			this.forceClose = close;
			if (close) {
				this.top = '-125px';
				this.opacity = '0';
			} else {
				this.top = '0';
				this.opacity = '1';
			}
		});
	}

	logoClick() {
		this.store$.dispatch(fromRouter.go({ path: '/', queryParams: {} }));
	}

	setRdsInputHooks() {
		(this.searchBar.searchInput.nativeElement as HTMLInputElement).onfocus = () => {
			this.store$.dispatch(fromHeaderActions.searchFocused());
		};
		(this.searchBar.searchInput.nativeElement as HTMLInputElement).onblur = () => {
			this.store$.dispatch(fromHeaderActions.searchBlurred());
		};

		this.subs.sink = this.areOpenedQuickLinks$.subscribe((result) => {
			this.setScrollDisabled(result);
		});
	}

	setScrollDisabled(disabled: boolean) {
		const className = 'disabled-scroll';
		if (disabled) {
			document.body.className = className;
		} else {
			if (document.body.className.indexOf(className) > -1) {
				document.body.className = document.body.className.replace(className, '');
			}
		}
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	search(phrase: string) {
		(this.searchBar.searchInput.nativeElement as HTMLInputElement).blur();
		this.store$.dispatch(fromHeaderActions.goToSearch({ phrase }));
	}

	searchChange(phrase: string) {
		this.store$.dispatch(fromHeaderActions.setSuggestionPhrase({ phrase }));
		this.store$.dispatch(fromHeaderActions.loadSuggestions({ text: phrase }));
	}

	closeSuggestions() {
		this.suggestionsOpened = false;
	}

	public onQuickLinksToggle(): void {
		if (this.areOpenedQuickLinks) {
			this.store$.dispatch(fromQuickLink.closeHeaderQuickLinks());
		} else {
			this.store$.dispatch(fromQuickLink.toggleHeaderQuickLinks());
		}
	}

	constructor(
		private window: Window,
		private store$: Store,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private cdr: ChangeDetectorRef
	) {}
}
