import { createAction, props } from '@ngrx/store';

import { TaxonomyFlat } from '@core/models/taxonomy.model';

export const loadFunctionsFlat = createAction(
  '[CORE FUNCTIONS FLAT - LOAD FUNCTIONS FLAT] Load Functions Flat'
);

export const getFunctionsFlatRequest = createAction(
  '[CORE FUNCTIONS FLAT - GET FUNCTIONS FLAT REQUEST] Get Functions Flat Request'
);

export const getFunctionsFlatSuccess = createAction(
  '[CORE FUNCTIONS FLAT - GET FUNCTIONS FLAT SUCCESS] Get Functions Flat Success',
  props<{ functionsFlat: TaxonomyFlat[] }>()
);

export const getFunctionsFlatFailure = createAction(
  '[CORE FUNCTIONS FLAT - GET FUNCTIONS FLAT FAILURE] Get Functions Flat Failure',
  props<{ error: any }>()
);

export const functionsFlatCollectionAddMany = createAction(
  '[CORE FUNCTIONS FLAT - ADD MANY] Functions Flat collection add many.',
  props<{ functionsFlat: TaxonomyFlat[] }>()
);
