<div [ngStyle]="{}">
  <content-loader [baseUrl]="componentsLoaderService.baseUrl"
                  [backgroundColor]="'#EAE8E5'"
                  [foregroundColor]="'#DBD6D1'"
                  [viewBox]="viewBox"
                  [speed]="3"
                  [interval]="0.75"
                  [gradientRatio]="2.4">

    <svg:rect x="0" y="8" rx="0" width="180" height="16" />
  </content-loader>
</div>
