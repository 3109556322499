import { Directive, ViewContainerRef, TemplateRef, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import * as fromScreen from '@app/root-store/ui/screen';
import { Store, select } from '@ngrx/store';
import { ScreenModeEnum } from '@app/core/models/screen.model';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[atrIfViewMode]'
})
export class IfViewModeDirective implements OnInit, OnDestroy {

  @Input('atrIfViewMode') views: ScreenModeEnum | Array<ScreenModeEnum>;

  private storeSubscription: Subscription;

  ngOnInit() {
    this.storeSubscription = this.store$.pipe(
      select(fromScreen.selectScreenMode)
    ).subscribe(mode => {
      if (this.views instanceof Array) {
        if (this.views.some(v => v === mode)) {
          this.viewContainerRef.clear();
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainerRef.clear();
        }
      } else {
        this.views === mode ?
          this.viewContainerRef.createEmbeddedView(this.templateRef)
        :
          this.viewContainerRef.clear();
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.changeDetectorRef.detach();
    this.storeSubscription?.unsubscribe();
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private changeDetectorRef: ChangeDetectorRef,
    private store$: Store<fromScreen.State>) { }

}
