import {ErrorHandler, Injectable} from '@angular/core';

const BROWSER_INSTRUCTION = {
  chrome: {
    header: {
      tag: 'div',
      value: 'To enable cookies and third-party cookies in Google Chrome',
      className: 'heading-2'
    },
    options: [
      'Click the Chrome menu icon Chrome Setting.',
      'Select Settings.',
      'In the "Privacy and security" section, select "Cookies and other site data".',
      'Allow all cookies!!!',
      'Close settings and refresh page.']
  },
  safari: {
    header: {
      tag: 'div',
      value: 'To enable cookies and third-party cookies in Safari',
      className: 'heading-2'
    },
    options: [
      'Click the "Safari" menu.',
      'Select the "Preferences" menu item.',
      'Select the "Privacy" tab".',
      'Disable Block all cookies!!!',
      'Close the preferences window and refresh page.']
  }
};

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

  constructor() {
    super();
  }

  handleError(error) {
    if (error && error.toString().indexOf('Cookies are not enabled in current environment') !== -1) {
      this.showTurnOnCookiesInstruction();
    }
    super.handleError(error);
  }

  showTurnOnCookiesInstruction() {
    let browser;
    const cookiesIdContainer = 'cookies-error-container';
    if (window.navigator.userAgent.indexOf('Chrome') !== -1) {
      browser = BROWSER_INSTRUCTION.chrome;
    }
    if (navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0) {
      browser = BROWSER_INSTRUCTION.safari;
    }

    const myNode = document.getElementById(cookiesIdContainer);
    if (myNode) {
      while (myNode.lastElementChild) {
        myNode.removeChild(myNode.lastElementChild);
      }
    }

    const div = this.createElement({tag: 'div', id: cookiesIdContainer, className: 'container container-center'});
    div.appendChild(this.createElement({tag: 'h2', value: 'Site not working?', className: 'heading-1'}));
    div.appendChild(this.createElement(browser.header));
    div.appendChild(this.getOptions(browser.options));
    document.body.prepend(div);
  }

  createElement(options: { tag: string, value?: string, className?: string, id?: string }) {
    const element = document.createElement(options.tag);
    if (options.value) {
      element.innerText = options.value;
    }
    if (options.className) {
      element.className = options.className;
    }
    if (options.id) {
      element.id = options.id;
    }
    return element;
  }

  getOptions(options) {
    const ol = this.createElement({tag: 'ol'});
    for (const i of options) {
      ol.appendChild(this.createElement({
        tag: 'li',
        value: i,
        className: 'heading-3'
      }));
    }
    return ol;
  }
}
