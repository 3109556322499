import {
  NewsletterListItemModel, UserSharedProfile,
} from '@app/newsletter/models';
import {createAction, props} from '@ngrx/store';
import {UserSearch} from '@core/models/user-search.model';

export const openShareNewsletterDialog = createAction(
  '[NEWSLETTER - SHARE] Open share Newsletter modal',
  props<{ card: NewsletterListItemModel }>()
);

export const openChangeOwnerDialog = createAction(
  '[NEWSLETTER - SHARE] Open change owner modal',
  props<{ card: NewsletterListItemModel }>()
);

export const initContributors = createAction(
  '[NEWSLETTER - SHARE] init contributors',
  props<{ newsletterContributors: UserSharedProfile[] }>()
);

export const removeContributor = createAction(
  '[NEWSLETTER - SHARE] remove contributor',
  props<{ contributor: UserSharedProfile }>()
);
export const addContributor = createAction(
  '[NEWSLETTER - SHARE] add contributor',
  props<{ contributor: UserSharedProfile }>()
);

export const getSearchedUser = createAction(
  '[NEWSLETTER - SHARE] Get User Data Email By Login',
  props<{ login: string }>()
);

export const getSearchedUserSuccess = createAction(
  '[NEWSLETTER - SHARE] Get User Data By Login Success',
  props<UserSharedProfile >()
);

export const getSearchedUserFailure = createAction(
  '[NEWSLETTER - SHARE] Get User By Login Failure'
);

export const getUserSearchRequest = createAction(
  '[NEWSLETTER - SHARE] Get Users Search Request',
  props<{ phrase: string }>()
);

export const getUserSearchSuccess = createAction(
  '[NEWSLETTER - SHARE] Get Users Success',
  props<{ usersSearched: Array<UserSearch> }>()
);

export const getUserSearchFailure = createAction(
  '[NEWSLETTER - SHARE] Get Users Search Error',
  props<{ error: any }>()
);

export const clearEmailSearch = createAction(
  '[NEWSLETTER - SHARE] Clear Search'
);

export const shareNewsletterRequest = createAction(
  '[NEWSLETTER - SHARE] share request',
  props<{ newsletterId: number, newsletterContributors: UserSharedProfile[] }>()
);

export const shareNewsletterSuccess = createAction(
  '[NEWSLETTER - SHARE] share success'
);

export const shareNewsletterFailure = createAction(
  '[NEWSLETTER - SHARE] share failure'
);

export const changeOwnerRequest = createAction(
  '[NEWSLETTER - SHARE] change owner request',
  props<{ newsletterId: number, newsletterContributors: UserSharedProfile[] }>()
);

export const changeOwnerSuccess = createAction(
  '[NEWSLETTER - SHARE] change owner success',
  props<{ newsletter: NewsletterListItemModel }>()
);

export const changeOwnerFailure = createAction(
  '[NEWSLETTER - SHARE] change owner failure'
);
