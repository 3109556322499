import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as fromActions from './content-items.actions';
import {catchError, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {ContentService} from '@core/services/content.service';
import * as fromUser from '@app/core/user/store';
import {GAService} from '@core/services/ga.service';

@Injectable()
export class ContentItemsEffects {

  public putLike$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.putLikeByContentIdRequest),
      mergeMap(({contentId}) => this.contentService.putLikeByContentId({contentId}).pipe(
        map((data: { result: boolean, numLikes: number }) =>
          fromActions.putLikeByContentIdSuccess({contentId, numLikes: data.numLikes})),
        catchError(() => of(fromActions.putLikeByContentIdError({contentId})))
      )),
    ), {dispatch: true}
  );

  public removeLike$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeLikeByContentIdRequest),
      mergeMap(({contentId}) => this.contentService.removeLikeByContentId({contentId}).pipe(
        map((data: { result: boolean, numLikes: number }) =>
          fromActions.removeLikeByContentIdSuccess({contentId, numLikes: data.numLikes})),
        catchError(() => of(fromActions.removeLikeByContentIdError({contentId})))
      )),
    ), {dispatch: true}
  );

  public putBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.putBookmarkByContentIdRequest),
      mergeMap(({contentId}) =>
        this.contentService.addBookmark({contentId}).pipe(
          map(() => fromActions.putBookmarkByContentIdSuccess({contentId})),
          catchError(({message}) => of(fromActions.putBookmarkByContentIdError({contentId}))
          )
        ))
    ), {dispatch: true}
  );

  public removeBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeBookmarkByContentIdRequest),
      mergeMap(({contentId}) =>
        this.contentService.removeBookmark({contentId}).pipe(
          map(() => fromActions.removeBookmarkByContentIdSuccess({contentId})),
          catchError(() => of(fromActions.removeBookmarkByContentIdError({contentId}))
          )
        ))
    ), {dispatch: true}
  );

  public sendExternalLinkToGA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.sendExternalLinkToGA),
      withLatestFrom(
        this.store$.pipe(select(fromUser.selectWorkdayData))
      ),
      tap(([action, workdayData]) =>
        this.gaService.sendExternalContentPageEvent(workdayData, action.content)
      )
    ), {dispatch: false}
  );

  public markRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.markReadRequest),
      mergeMap(({contentId}) =>
        this.contentService.markRead(contentId).pipe(
          switchMap(() => [fromActions.markReadSuccess({contentId})]),
          catchError((error) => of(fromActions.markReadFailure({message: error}))
          )
        ))
    ), {dispatch: true}
  );

  public copyLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.copyLink),
      tap(({route}) => {
        navigator.clipboard.writeText(route).catch(e => console.error(e));
      })
    ), {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private store$: Store<any>,
    private contentService: ContentService,
    private gaService: GAService,
  ) {
  }
}
