import { Component, Input } from '@angular/core';
import { CatalogStatus } from '@app/core/models';
import { RdsBadgeColor, RdsBadgeSize, RdsBadgeType } from '@rds/angular-components';

@Component({
  selector: 'rh-catalog-status',
  templateUrl: './catalog-status.component.html',
  styleUrls: ['./catalog-status.component.scss']
})
export class CatalogStatusComponent {
  @Input() status: CatalogStatus;
  @Input() size: RdsBadgeSize

  constructor() { }

  get badge(): {
    label: string,
    color: RdsBadgeColor,
    type: RdsBadgeType
  } {
    switch (this.status.statusId) {
      case 1:
        return {
          label: 'Draft',
          color: 'blue',
          type: 'minimal'
        };
      case 2:
        return {
          label: 'Approval Required',
          color: 'green',
          type: 'minimal'
        };
      case 3:
        return {
          label: 'Not Approved',
          color: 'red',
          type: 'minimal'
        };
      case 4:
        return {
          label: 'Partially published',
          color: 'green',
          type: 'minimal'
        };
      case 5:
        return {
          label: 'Published',
          color: 'success',
          type: 'default'
        };
      case 6:
        return {
          label: 'Review Required',
          color: 'yellow',
          type: 'minimal'
        };
      case 7:
        return {
          label: 'Deleted By CLM',
          color: 'neutral',
          type: 'default'
        };
      case 8:
        return {
          label: 'Deleted By User',
          color: 'neutral',
          type: 'default'
        };
      case 9:
        return {
          label: 'Trashed',
          color: 'neutral',
          type: 'default'
        };
    }
  }

}
