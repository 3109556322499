<h2 rds-dialog-header>Reorganize Top Quicklinks</h2>
<div rds-dialog-content>
  <p class="mb-5">
    Reorganize My Top Quicklinks in order you would like them to appear in your homepage.
  </p>
  <div *ngIf="quickLinks" class="drag-drop-field" cdkDropList (cdkDropListDropped)="drop($event)">
    <rh-single-catalog-item *ngFor="let ql of quickLinks" class="drag-drop-item" [catalog]="ql" cdkDrag cdkDragLockAxis="y">
      <rds-icon  cdkDragHandle dragDrop class="drag-drop-handle mx-4" icon="drag"></rds-icon>
    </rh-single-catalog-item>
  </div>
</div>
<div rds-dialog-actions-container align="end">
  <button
      rds-secondary-button
      [rds-dialog-close]="null"
      size="m"
  >Cancel</button>
  <button
      rds-primary-button
      (click)="closeDialog()"
      size="m"
  >Confirm</button>
</div>
