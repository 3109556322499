import {Component, Inject} from '@angular/core';
import {RDS_DIALOG_DATA, RdsDialogRef} from '@rds/angular-components';

export interface RadaDetailsModel {
    name: string;
    email: string;
    owner: string;
    description: string;
    members: Array<{
        email: string,
        role: string,
        type: string,
        kind: string
    }>;
    numberOfMembers: number;
    numberOfSubgroups: number;
}

export interface RadaPreviewDialogData {
    item: RadaDetailsModel
}

@Component({
    selector: 'rh-rada-preview-dialog',
    templateUrl: './rada-preview-dialog.component.html',
    styleUrls: ['./rada-preview-dialog.component.scss']
})
export class RadaPreviewDialogComponent {
    public rada: RadaDetailsModel;

    constructor(
        private dialogRef: RdsDialogRef<RadaPreviewDialogComponent>,
        @Inject(RDS_DIALOG_DATA) data: RadaPreviewDialogData
    ) {
        this.rada = {
            ...data.item,
            numberOfMembers: data.item.members.filter(item => item.type === 'USER').length,
            numberOfSubgroups: data.item.members.filter(item => item.type === 'GROUP').length,
        };
    }

    close(): void {
        this.dialogRef.close({});
    }
}
