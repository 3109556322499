export interface ChannelNotificationSettings {
  notificationsEnabled: boolean;
  channelId: string;
  notificationsType: NotificationsNewsType;
  name: string;
}

export interface GlobalNotificationSettings {
  notificationsEnabled: boolean;
  channelSettings: Array<ChannelNotificationSettings>;
}

export enum NotificationsNewsType {
  IMMEDIATE = 0,
  DAILY = 1
}

export enum NotificationsType {
  ALERT = 'alert',
  NEWS = 'news'
}

export interface NotificationAlert {
  data: {
    alertMessage: string;
    alertTitle: string;
    typeNotification: NotificationsType;
    url: string;
  };
  notification: {
    title: string;
    body: string;
  };
}

export interface NotificationNews {
  data: {
    idArticle: string;
    alertTitle: string;
    typeNotification: NotificationsType;
    url: string;
  };
  notification: {
    title: string;
    body: string;
  };
}
