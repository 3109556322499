import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import * as fromReducer from '../header.reducer';
import * as fromActions from '../header/header.actions';
import {catchError, debounceTime, filter, map, mergeMap} from 'rxjs/operators';
import {HeaderService} from '@app/layout/header/services/header.service';
import * as fromSearch from '@app/search/store/search.actions';
import * as fromRhSearch from '@app/search/store/rh-search/rh-search.actions';
import * as fromGcsSearch from '@app/search/store/cloud-search/cloud-search.actions';
import * as fromHeroSection from '@app/home/store/hero-section/';
import * as fromRouter from '@app/root-store/router/router.actions';
import {Router} from '@angular/router';
import {of} from 'rxjs';

@Injectable()
export class HeaderEffects {

  // public onTextChangeOrInputSubmit$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromActions.setPhrase),
  //     map(({phrase}) => fromSearch.setPhrase({phrase}))
  //   ), {dispatch: true}
  // );

  public goToSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToSearch),
      mergeMap(({phrase}) => {
        let arr = [];
        if (window.location.href.indexOf('search') < 0) {
          return [fromRouter.go({
            path: 'search/intranet',
            queryParams: {phrase}
          },), fromRhSearch.loadResults(), fromGcsSearch.loadResults()]
        }
        if (window.location.href.indexOf('intranet') < 0) {
          arr = [...arr, fromSearch.setPhrase({phrase}), fromRouter.updateQueryParams({queryParams: {phrase: phrase}}), fromGcsSearch.loadResults()];
        } else {
          arr = [...arr, fromSearch.setPhrase({phrase}), fromRouter.updateQueryParams({queryParams: {phrase: phrase}}), fromRhSearch.loadResults()];
        }
        return arr;
      })
    ), {dispatch: true}
  );

  public loadSuggestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadSuggestions),
      debounceTime(300),
      filter(({text}) => text && text.length >= 2),
      mergeMap(({text}) => [
        fromActions.getExpressLinks({text}),
        fromActions.getAutocompletes({text}),
        fromActions.getPeople({text}),
      ])
    ), {dispatch: true}
  );

  public getExpressLinks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getExpressLinks),
      mergeMap(({text}) => this.headerService.getExpressLinks(text).pipe(
        map(expressLinks => fromActions.getExpressLinksSuccess({expressLinks})),
        catchError(() => of(fromActions.getExpressLinksFailure()))
      ))
    ), {dispatch: true}
  );

  public getHeaderLinks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getConfigurationLinks),
      mergeMap(() => this.headerService.getHeaderLinks().pipe(
        mergeMap(({header, heroSection, urls}) => [
          fromActions.getConfigurationLinksSuccess({nodes: header.nodes}),
          fromActions.setDefaultUrls({urls}),
          fromHeroSection.setChannelIdAndCount({channelId: heroSection.channelId, newsCount: heroSection.newsCount})
        ]),
        catchError(() => of(fromActions.getConfigurationLinksFailure()))
      ))
    ), {dispatch: true}
  );

  public getAutocompletes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAutocompletes),
      mergeMap(({text}) => this.headerService.getAutocompletes(text).pipe(
        map(autocompletes => fromActions.getAutocompletesSuccess({autocompletes})),
        catchError(() => of(fromActions.getAutocompletesFailure()))
      ))
    ), {dispatch: true}
  );

  public getPeople$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getPeople),
      mergeMap(({text}) => this.headerService.getPeople(text).pipe(
        map(people => fromActions.getPeopleSuccess({people})),
        catchError(() => of(fromActions.getPeopleFailure()))
      ))
    ), {dispatch: true}
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private headerService: HeaderService,
    private router: Router
  ) {
  }
}
