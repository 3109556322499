import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import * as fromReducer from '../header.reducer';
import * as fromActions from '../catalogs/catalogs.actions';
import * as fromSelectors from '../catalogs/catalogs.selectors';
import * as fromQuicklinks from '../quick-link/quick-link.actions';
import * as fromCategoriesFlat from '@core/core-store/categories-flat/categories-flat.selectors';
import * as fromUser from '@app/core/user/store';

import {catchError, first, map, mergeMap, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {HeaderService} from '@app/layout/header/services/header.service';
import {of} from 'rxjs';
import {CatalogFilters} from '@app/layout/header/models/Catalog';
import {flattenFilters} from '@app/shared/filters/filters-model';
import {INITIAL_CATALOG_HEADER_FILTERS} from '@app/shared/filters/models/catalog-header';

@Injectable()
export class CatalogsEffects {

  public getCategoriesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getCategoriesRequest),
      mergeMap(() => this.headerService.getCategories().pipe(
        map(categories => fromActions.getCategoriesSuccess({categories})),
        catchError((error) => of(fromActions.getCategoriesFailure({error})))
      ))
    ), {dispatch: true}
  );

  public loadDefaultTaxonomyFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadDefaultTaxonomyFilter),
      withLatestFrom(
        this.store$.pipe(select(fromUser.selectPersonalizationPreferences))
      ),
      map(([action, preferences]) => fromActions.setDefaultTaxonomyFilter(preferences))
    ), {dispatch: true}
  );

  public getCatalogsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getCatalogsRequest),
      mergeMap(({categoryId, filters}) => this.headerService.getCatalogs(categoryId, filters).pipe(
        map(catalogs => fromActions.getCatalogsSuccess({catalogs})),
        catchError((error) => of(fromActions.getCatalogsFailure({error})))
      ))
    ), {dispatch: true}
  );
  public openCategoryAndUpdateFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openCategoryAndUpdateFilters),
      switchMap(({filters}) => this.store$.pipe(
        select(fromCategoriesFlat.selectEntityById, {id: filters.categoryId}),
        map((category) => ({filters, category}))
      )),
      switchMap(({filters, category}) => this.store$.pipe(
        select(fromUser.selectPersonalizationPreferences),
        first(preferences => !!preferences),
        map((preferences) => ({
          category,
          filters: {
            ...INITIAL_CATALOG_HEADER_FILTERS,
            locations: filters.locations ? {
              ...INITIAL_CATALOG_HEADER_FILTERS.locations,
              value: preferences.locations
            } : INITIAL_CATALOG_HEADER_FILTERS.locations,
            departments: filters.departments ? {
              ...INITIAL_CATALOG_HEADER_FILTERS.departments,
              value: preferences.departments
            } : INITIAL_CATALOG_HEADER_FILTERS.departments,
            functions: filters.functions ? {
              ...INITIAL_CATALOG_HEADER_FILTERS.functions,
              value: preferences.functions
            } : INITIAL_CATALOG_HEADER_FILTERS.functions,
          },
          preferences,
          advancedFiltersCount: Number(filters.locations) + Number(filters.departments) + Number(filters.functions)
        }))
      )),
      mergeMap(({category, filters, preferences}) => [
        fromActions.openCategoryFromLink({category}),
        fromActions.updateFilters({filters}),
        fromActions.setDefaultTaxonomyFilter(preferences)
      ])
    )
  );

  public openCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openCategory),
      mergeMap(({category}) => [
        fromActions.updateFilters({filters: INITIAL_CATALOG_HEADER_FILTERS}),
        fromActions.loadDefaultTaxonomyFilter()
      ])
    )
  );

  public updateFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateFilters),
      switchMap(({filters}) => this.store$.pipe(
        select(fromSelectors.selectOpenedCategory),
        take(1),
        map((category) => ({filters, categoryId: category?.categoryId}))
      )),
      map(({filters, categoryId}) => fromActions.getCatalogsRequest({
        filters: flattenFilters(filters) as CatalogFilters,
        categoryId
      }))
    )
  );

  public addQuickLinkRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addQuickLinkRequest),
      mergeMap(({catalogId}) => this.headerService.addQuicklink(catalogId).pipe(
        mergeMap(quickLink => [
          fromActions.addQuickLinkSuccess({quickLink}),
          fromQuicklinks.addToQuickLinksSuccess({quickLink})
        ]),
        catchError((error) => of(fromActions.addQuickLinkFailure({error})))
      ))
    ), {dispatch: true}
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private headerService: HeaderService
  ) {
  }
}
