import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SiteMapRouteStatus } from '@app/root-store/ui/sitemap';

@Component({
  selector: 'rh-sitemap-status-toggle',
  templateUrl: './sitemap-status-toggle.component.html',
  styleUrls: ['./sitemap-status-toggle.component.scss']
})
export class SitemapStatusToggleComponent {
  @Input() status: SiteMapRouteStatus;

  @Output() statusChanged: EventEmitter<SiteMapRouteStatus> = new EventEmitter()

  changeStatus(event) {
    this.statusChanged.emit(event.buttonToggle.value);
  }

}
