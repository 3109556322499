import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {EndpointsService} from '@app/core/services/endpoints/endpoints.service';
import {ApiService} from '@app/core/services/api/api.service';
import {AlertModel, ContentItem} from '@core/models';
import {Alert} from '../models/alerts.model';


@Injectable()
export class AlertsService {
	public sendAlert(alert: Alert): Observable<{ data: ContentItem[] }> {
		const url = this.endpoints.ENDPOINT.ALERT.SEND;

		const body = {
			title: alert.title,
			message: alert.message,
			url: alert.url,
			locations: alert.recipientType === 0 ? alert.locations : [],
			departments: alert.recipientType === 0 ? alert.departments : [],
			functions: alert.recipientType === 0 ? alert.functions : [],
			userIdList: alert.recipientType === 0 ? [] : alert.userIdList,
			emailList: alert.recipientType === 0 ? [] : alert.emailList
		};

		return this.http
			.post(url, body);
	}

	public getFeed(): Observable<Array<AlertModel>> {
		const url = this.endpoints.ENDPOINT.ALERT.GET.ALL_FOR_USER;

		return this.http
			.get(url);
	}

	public markAsRead(id: number): Observable<any> {
		const url = this.endpoints.replaceUrlTokens(
			this.endpoints.ENDPOINT.ALERT.PUT.MARK_AS_READ, {id}
		);

		return this.http
			.put(url, {});
	}


	constructor(
		private http: ApiService,
		private endpoints: EndpointsService,
	) {
	}
}
