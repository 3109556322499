import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {QuickLink} from '@app/layout/header/models';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import * as fromQuickLinkSelectors from '@app/layout/header/store/quick-link/quick-link.selectors';
import * as fromQuickLinkActions from '@app/layout/header/store/quick-link/quick-link.actions';

@Component({
  selector: 'rh-quick-links-paginator',
  templateUrl: './quick-links-paginator.component.html',
  styleUrls: ['./quick-links-paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickLinksPaginatorComponent {

  public isDeleteModeActive$: Observable<boolean> = this.store$.pipe(
    select(fromQuickLinkSelectors.selectIsDeleteModeActive));

  public isOpen: true | false = false;
  public page = 1;
  public totalPage = 1;
  public displayData: QuickLink[] = [];
  public rangeDisplayElements = 9;
  private _allData: QuickLink[] = [];
  @Input() title: string = null;
  @Input() isFirstCategory: true | false = false;

  @Input() set allData(value: QuickLink[]) {
    this.totalPage = Math.ceil(value.length / 9);
    this._allData = value;
    this.setDisplayData();
  }

  get allData(): QuickLink[] {
    return this._allData;
  }

  private setDisplayData() {
    const start = (this.page === 1 || this.isOpen) ? 0 : (this.page - 1) * this.rangeDisplayElements;
    const end = this.isOpen ? this.allData.length : this.page * this.rangeDisplayElements;

    this.displayData = this._allData.slice(start, end);
  }

  public setPage(value): void {

    if ((this.page + value) < 1 || (this.page + value) > this.totalPage) {
      return;
    }
    this.page += value;
    this.setDisplayData();
  }

  public toggleElements(e): void {
    e.preventDefault();
    this.isOpen = !this.isOpen;
    this.setDisplayData();
  }


  checkboxClicked(event, link: QuickLink) {
    if (event.checked) {
      this.store$.dispatch(fromQuickLinkActions.selectQuickLinkToDelete({id: link.quickLinkId}));
    } else {
      this.store$.dispatch(fromQuickLinkActions.deselectQuickLinkToDelete({id: link.quickLinkId}));
    }
  }

  shouldBeDeleted(link: QuickLink) {
    return this.store$.pipe(select(fromQuickLinkSelectors.selectIsQuickLinkToDelete(link.quickLinkId)));
  }

  removeFromTopQL(link) {
    this.store$.dispatch(fromQuickLinkActions.removeFromTopRequest({id: link.quickLinkId}));
  }

  addToTopQL(link) {
    this.store$.dispatch(fromQuickLinkActions.addToTopRequest({id: link.quickLinkId}));
  }

  deleteQL(link) {
    this.store$.dispatch(fromQuickLinkActions.openDeleteQuickLinkConfirmDialog({name: link.name, id: link.quickLinkId}));
  }

  renameQL(link) {
    this.store$.dispatch(fromQuickLinkActions.openRenameQuickLinkDialog({name: link.name, id: link.quickLinkId}));
  }

  constructor(
    private store$: Store<any>
  ) {
  }
}
