import { ChannelNotificationSettings } from '@app/core/models/notifications.model';
import { createAction, props } from '@ngrx/store';


export const initForm = createAction(
  '[NOTIFICATIONS SETTING - FORM] Init form',
);

export const setInitialValue = createAction(
  '[NOTIFICATIONS SETTING - FORM] Set initial form value',
  props<{ notificationsEnabled: boolean, channelSettings: Array<ChannelNotificationSettings> }>()
);

export const setNotificationsEnabled = createAction(
  '[NOTIFICATIONS SETTING - FORM] Set notifications enabled',
  props<{ notificationsEnabled: boolean }>()
);

export const setChannelSettings = createAction(
  '[NOTIFICATIONS SETTING - FORM] Set channel settings',
  props<{ channelSettings: Array<ChannelNotificationSettings> }>()
);

export const saveAllowNotificationsRequest = createAction(
  '[NOTIFICATIONS SETTING - FORM] Save allow notifications Request'
);

export const saveAllowNotificationsSuccess = createAction(
  '[NOTIFICATIONS SETTING - FORM] Save allow notifications Success',
  props<{ notificationsEnabled: boolean, channelSettings: Array<ChannelNotificationSettings> }>()
);

export const saveAllowNotificationsFailure = createAction(
  '[NOTIFICATIONS SETTING - FORM] Save allow notifications Failed',
  props<{ error: any }>()
);

export const saveChannelSettingsRequest = createAction(
  '[NOTIFICATIONS SETTING - FORM] Save channel settings Request'
);

export const saveChannelSettingsSuccess = createAction(
  '[NOTIFICATIONS SETTING - FORM] Save channel settings Success',
  props<{ notificationsEnabled: boolean, channelSettings: Array<ChannelNotificationSettings> }>()
);

export const saveChannelSettingsFailure = createAction(
  '[NOTIFICATIONS SETTING - FORM] Save channel settings Failed',
  props<{ error: any }>()
);

export const resetChannelSettingsRequest = createAction(
  '[NOTIFICATIONS SETTING - FORM] Reset channel settings Request'
);

export const resetChannelSettingsSuccess = createAction(
  '[NOTIFICATIONS SETTING - FORM] Reset channel settings Success',
  props<{ notificationsEnabled: boolean, channelSettings: Array<ChannelNotificationSettings> }>()
);

export const resetChannelSettingsFailure = createAction(
  '[NOTIFICATIONS SETTING - FORM] Reset channel settings Failed',
  props<{ error: any }>()
);

export const openResetChannelSettingsConfirmDialog = createAction(
  '[NOTIFICATIONS SETTING - FORM] Open reset channel settings confirm dialog'
);

export const clearAll = createAction(
  '[NOTIFICATIONS SETTINGS = FORM] Clear all'
)