export enum ContentTypeEnum {
  EMBEDDED = 'embedded',
  LINK = 'link',
  ANNOUNCEMENT = 'announcement'
}

export interface ContentType {
  id: number;
  name: string;
}

export const contentTypesOptionsModel = {
  idKey: 'id',
  nameKey: 'name',
};
