import { Injectable } from '@angular/core';
import { UserWorkdayInformation } from '@core/models/user-workday.model';
import { ContentDetails, ContentItem } from '@core/models';
import { GA_EVENTS } from '../enums/ga.enum';
import { GAFilters } from '@core/models/ga-filters.model';
import { environment } from '@env/environment';

@Injectable()
export class GAService {

  sendContentPageEvent({
    userWorkdayInformation,
    contentDetails,
    channelId,
  }: {
    userWorkdayInformation: UserWorkdayInformation,
    contentDetails: ContentDetails,
    channelId: string
  }
    ): void {

    if (userWorkdayInformation && contentDetails && channelId) {
      let contentIdInt;
      let channelIdInt;
      let userHash;

      try {
        contentIdInt = parseInt(contentDetails.id.split(':')[2], 10);
        channelIdInt = parseInt(channelId.split(':')[1], 10);
        userHash = Math.abs(this.hashCode(userWorkdayInformation.login));
      } catch (e) {
        console.error('Something went wrong with statistic');
      }
      const params = {
        event: GA_EVENTS.NEWSFLOW_ARTICLE,
        user_name: `${userWorkdayInformation.firstName} ${userWorkdayInformation.lastName}`,
        user_id: userHash,
        user_active: true,
        user_office: userWorkdayInformation.company ? userWorkdayInformation.company : '',
        user_department: userWorkdayInformation.department ? userWorkdayInformation.department : '',
        user_location: userWorkdayInformation.location ? userWorkdayInformation.location : '',
        user_position: userWorkdayInformation.position ? userWorkdayInformation.position : '',
        channel_id: channelIdInt ? channelIdInt : '',
        channel_name: contentDetails.channelName ? contentDetails.channelName : '',
        news_config: '',
        news_config_readable_name: '',
        news_id: contentIdInt ? contentIdInt : '',
        news_title: contentDetails.title ? contentDetails.title : '',
        news_author: contentDetails.authorName  ||  contentDetails.publisherName || '',
        news_pub_date: contentDetails.publicationDate ? contentDetails.publicationDate : '',
        news_comment_count: contentDetails.comments ? contentDetails.comments.length : 0,
        news_rating: contentDetails.numLikes ? contentDetails.numLikes : 0,
        news_language: contentDetails.language ? contentDetails.language : '',
        news_topics: contentDetails.topics ? contentDetails.topics : []
      };

      this.console(params);
      // @ts-ignore
      window.dataLayer.push(params);
    }
  }

  sendExternalContentPageEvent(
    userWorkdayInformation: UserWorkdayInformation,
    contentDetails: ContentItem): void {

    if (userWorkdayInformation && contentDetails && contentDetails.channelId) {
      let contentIdInt;
      let channelIdInt;
      let userHash;

      try {
        contentIdInt = parseInt(contentDetails.id.split(':')[2], 10);
        channelIdInt = parseInt(contentDetails.channelId.split(':')[1], 10);
        userHash = Math.abs(this.hashCode(userWorkdayInformation.login));
      } catch (e) {
        console.error('Something went wrong with statistic');
      }

      const params = {
        event: GA_EVENTS.NEWSFLOW_ARTICLE,
        user_name: `${userWorkdayInformation.firstName} ${userWorkdayInformation.lastName}`,
        user_id: userHash,
        user_active: true,
        user_office: userWorkdayInformation.company ? userWorkdayInformation.company : '',
        user_department: userWorkdayInformation.department ? userWorkdayInformation.department : '',
        user_location: userWorkdayInformation.location ? userWorkdayInformation.location : '',
        user_position: userWorkdayInformation.position ? userWorkdayInformation.position : '',
        channel_id: channelIdInt ? channelIdInt : '',
        channel_name: contentDetails.channelName ? contentDetails.channelName : '',
        news_config: '',
        news_config_readable_name: '',
        news_id: contentIdInt ? contentIdInt : '',
        news_title: contentDetails.title ? contentDetails.title : '',
        news_author: contentDetails.authorName ? contentDetails.authorName : '',
        news_pub_date: contentDetails.date ? contentDetails.date : '',
        news_comment_count: contentDetails.numComments ? contentDetails.numComments : 0,
        news_rating: contentDetails.numLikes ? contentDetails.numLikes : 0,
        news_language: 'en',
        news_topics: contentDetails.topics ? contentDetails.topics : []
      };

      this.console(params);
      // @ts-ignore
      window.dataLayer.push(params);
    }
  }

  sendSearchFilterOrFieldEvent(
    event: GA_EVENTS.CE_SEARCH_FIELD | GA_EVENTS.CE_SEARCH_FILTER,
    term: string,
    count: number,
    filters: GAFilters): void {

    const params = {
      event,
      search_term: term,
      search_result_count: count,
      search_filter_mylocationonly: filters.isMyLocations,
      search_filter_locations: filters.locations,
      search_filter_departments: filters.departments,
      search_filter_functions: filters.functions,
      search_filter_categories: filters.categories,
      search_category: filters.activePage,
    };

    this.console(params);
    // @ts-ignore
    window.dataLayer.push(params);
  }

  sendSearchResultClickEvent(
    event: GA_EVENTS.CE_SEARCH_RESULT_CLICK,
    term: string,
    count: number,
    name: string,
    url: string,
    filters: GAFilters): void {

    const params = {
      event,
      search_term: term,
      search_result_count: count,
      search_result_title: name,
      search_result_url: url,
      search_filter_mylocationonly: filters.isMyLocations,
      search_filter_locations: filters.locations,
      search_filter_departments: filters.departments,
      search_filter_functions: filters.functions,
      search_filter_categories: filters.categories,
      search_category: filters.activePage,
    };

    this.console(params);
    // @ts-ignore
    window.dataLayer.push(params);
  }

  sendSearchCategoryClickEvent(
    event: GA_EVENTS.CE_SEARCH_CATEGORY_CLICK,
    term: string,
    category: string): void {

    const params = {
      event,
      search_term: term,
      search_category: category,
    };

    this.console(params);
    // @ts-ignore
    window.dataLayer.push(params);
  }

  sendSearchLoadMoreClickEvent(
    event: GA_EVENTS.CE_SEARCH_LOADMORE_CLICK,
    term: string,
    count: number): void {

    const params = {
      event,
      search_term: term,
      search_result_count: count
    };

    this.console(params);
    // @ts-ignore
    window.dataLayer.push(params);
  }

  sendSearchMissingContentClickEvent(
    event: GA_EVENTS.CE_SEARCH_MISSINGCONTENT_CLICK,
    term: string,
    count: number): void {

    const params = {
      event,
      search_term: term,
      search_result_count: count
    };

    this.console(params);
    // @ts-ignore
    window.dataLayer.push(params);
  }

  sendAddQuickLinkClickEvent(
    event: GA_EVENTS.CE_SEARCH_QUICKADD_CLICK,
    name: string,
    url: string): void {

    const params = {
      event,
      search_result_title: name,
      search_result_url: url
    };

    this.console(params);
    // @ts-ignore
    window.dataLayer.push(params);
  }

  sendSearchFilterCleaningEvent(
    event: GA_EVENTS.CE_SEARCH_FILTERCLEARING_CLICK,
    filters: GAFilters): void {

    const params = {
      event,
      search_filter_mylocationonly: filters.isMyLocations,
      search_filter_locations: filters.locations,
      search_filter_departments: filters.departments,
      search_filter_functions: filters.functions,
      search_filter_categories: filters.categories,
    };

    this.console(params);
    // @ts-ignore
    window.dataLayer.push(params);
  }

  private console(params: any): void {
    if (!environment.prod) {
      console.log('GA params: ', params);
    }
  }

  private hashCode(str): number {
    return str.split('').reduce((prevHash, currVal) =>
      // tslint:disable-next-line:no-bitwise
      ((( prevHash << 5 ) - prevHash) + currVal.charCodeAt(0)) | 0, 0);
  }

  constructor() {}
}
