import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'atr-calendar-label',
  styleUrls: ['./calendar-label.component.scss'],
  templateUrl: './calendar-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarLabelComponent {
  @Input() date: Date;
  @Input() size: 'm' | 'l' = 'm';
  @Input() showTime = false;
}
