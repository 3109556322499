import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { SubSink } from 'subsink';

@Directive({
	selector: '[rhFormStoreSync]',
})
export class FormStoreSyncDirective implements OnInit, OnDestroy {
	private subs: SubSink = new SubSink();

	@Input('rhFormStoreSync') set data(value: any) {
		if (value) {
			this.formGroupDirective.form.patchValue(value, { emitEvent: true });
			this.formGroupDirective.form.markAsPristine();
		}
	}

	@Output() updateStoreForm: EventEmitter<any> = new EventEmitter();

	constructor(private formGroupDirective: FormGroupDirective) {}

	ngOnInit(): void {
		this.subs.sink = this.formGroupDirective.form.valueChanges
			.pipe(
				debounceTime(300),
				distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next))
			)
			.subscribe((value) => {
				this.updateStoreForm.emit(value);
			});
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}
}
