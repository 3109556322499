<h2 rds-dialog-header>Intranet Coordinator details</h2>
<div rds-dialog-content>
    <div class="row row-centered">
        <div class="col-auto mb-7">
            <rh-user-single
                size="xl"
                [user]="user">
            </rh-user-single>
        </div>
        <div class="col mb-7">
            <div class="row">
                <div class="col">
                    <p class="ui-label-m-bold">
                        {{user.firstName}} {{user.lastName}}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p class="ui-label-s">
                        {{user.email}}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col mb-3">
            <p class="ui-label-m-bold">
                Managed locations
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col mb-5">
            <rds-chip-list size="s">
                <rds-chip [disabled]="true" *ngIf="managedTaxonomies.locations.length === 0">
                    No managed locations
                </rds-chip>
                <rds-chip *ngFor="let location of managedTaxonomies.locations">
                  {{location | dictionary:'location' | async}}
                </rds-chip>
              </rds-chip-list>
        </div>
    </div>
    <div class="row">
        <div class="col mb-3">
            <p class="ui-label-m-bold">
                Managed departments
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col mb-5">
            <rds-chip-list size="s">
                <rds-chip [disabled]="true" *ngIf="managedTaxonomies.departments.length === 0">
                    No managed departments
                </rds-chip>
                <rds-chip *ngFor="let department of managedTaxonomies.departments">
                  {{department | dictionary:'department' | async}}
                </rds-chip>
              </rds-chip-list>
        </div>
    </div>
    <div class="row">
        <div class="col mb-3">
            <p class="ui-label-m-bold">
                Managed functions
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col mb-5">
            <rds-chip-list size="s">
                <rds-chip [disabled]="true" *ngIf="managedTaxonomies.functions.length === 0">
                    No managed functions
                </rds-chip>
                <rds-chip *ngFor="let function of managedTaxonomies.functions">
                  {{function | dictionary:'function' | async}}
                </rds-chip>
              </rds-chip-list>
        </div>
    </div>
</div>
<div rds-dialog-actions-container align="end">
    <button
        rds-primary-button
        [rds-dialog-close]="null"
        size="m"
    >Close</button>

</div>