import {createSelector} from '@ngrx/store';
import * as fromHome from '../home.reducer';
import * as fromReducer from './subscriptions.reducer';
import { flattenFilters } from '@app/shared/filters/filters-model';

export const selectState = createSelector(
  fromHome.selectState,
  fromHome.getSubscriptions
);

export const selectIsEdit = createSelector(
  selectState,
  fromReducer.getIsEdit
);

export const selectCurrentType = createSelector(
  selectState,
  fromReducer.getCurrentType
);

export const selectSubscriptionsLoading = createSelector(
  selectState,
  fromReducer.getSubscriptionsLoading
);

export const selectSubscriptions = createSelector(
  selectState,
  fromReducer.getSubscriptions
);

export const selectAllUserSubscriptions = createSelector(
  selectSubscriptions,
  fromReducer.getAllUserSubscriptions
);

// CHANNELS
export const selectUserChannelSubscriptions = createSelector(
  selectSubscriptions,
  fromReducer.getUserChannelSubscriptions
);

export const selectAllUserChannels = createSelector(
  selectUserChannelSubscriptions,
  fromReducer.getAll
);

export const selectSelectedChannels = createSelector(
  selectUserChannelSubscriptions,
  fromReducer.getSelected
);

export const selectFilteredChannels = createSelector(
  selectUserChannelSubscriptions,
  fromReducer.getFiltered
);

export const selectFilteredChannelsIds = createSelector(
  selectFilteredChannels,
  (subscription) => subscription?.map(s => s.id)
);

export const selectSelectedSortTypeForChannels = createSelector(
  selectUserChannelSubscriptions,
  fromReducer.getSortTypeFor
);


export const selectSuggestedChannels = createSelector(
  selectUserChannelSubscriptions,
  fromReducer.getSuggested
);

export const selectSuggestedChannelsCount = createSelector(
  selectSuggestedChannels,
  (subscriptions) => subscriptions?.length
);

export const selectChannelsNewsLoading = createSelector(
  selectUserChannelSubscriptions,
  fromReducer.getNewsLoading
);

export const selectChannelsIsLikeProcessing = createSelector(
  selectUserChannelSubscriptions,
  fromReducer.getIsLikeProcessing
);
export const selectChannelsIsLikeProcessingIds = createSelector(
  selectUserChannelSubscriptions,
  fromReducer.getIsLikeProcessingIds
);

export const selectChannelsFilters = createSelector(
  selectUserChannelSubscriptions,
  fromReducer.getFilters
);

export const selectChannelsFiltersForRequest = createSelector(
  selectChannelsFilters,
  (filters) => {
    return  ({
      name: filters?.name,
      ...flattenFilters(filters?.advancedFilters)
    })
  }
)

export const selectChannelsPagination = createSelector(
  selectUserChannelSubscriptions,
  fromReducer.getPagination
);

export const selectChannelsPageIndex = createSelector(
  selectChannelsPagination,
  fromReducer.getPageIndex
);

export const selectChannelsPageSize = createSelector(
  selectChannelsPagination,
  fromReducer.getPageSize
);

export const selectLocalForChannel = createSelector(
  selectUserChannelSubscriptions,
  fromReducer.getLocal
)

export const selectGlobalForChannel = createSelector(
  selectUserChannelSubscriptions,
  fromReducer.getGlobal
)

export const selectLocalNewsForChannel = createSelector(
  selectLocalForChannel,
  fromReducer.getNews
);

export const selectGlobalNewsForChannel = createSelector(
  selectGlobalForChannel,
  fromReducer.getNews
);
export const selectIsLastLocalForChannel = createSelector(
  selectLocalForChannel,
  fromReducer.getInsidePagination,
  ({pagination}) => pagination?.isLast
);

export const selectIsLastGlobalForChannel = createSelector(
  selectGlobalForChannel,
  fromReducer.getInsidePagination,
  ({pagination}) => pagination?.isLast
);

export const selectChannelsSuggestionLoading = createSelector(
  selectUserChannelSubscriptions,
  fromReducer.getSuggestionLoading
);

export const selectChannelsSuggestionLoaded = createSelector(
  selectUserChannelSubscriptions,
  fromReducer.getSuggestionLoaded
);

export const selectAllLocalNewsForChannel = createSelector(
  selectLocalNewsForChannel,
  fromReducer.getAllNews
);
export const selectAllGlobalNewsForChannel = createSelector(
  selectGlobalNewsForChannel,
  fromReducer.getAllNews
);


// AUTHORS
export const selectUserAuthorSubscriptions = createSelector(
  selectSubscriptions,
  fromReducer.getUserAuthorSubscriptions
);

export const selectAllUserAuthors = createSelector(
  selectUserAuthorSubscriptions,
  fromReducer.getAll
);

export const selectSelectedAuthors = createSelector(
  selectUserAuthorSubscriptions,
  fromReducer.getSelected
);

export const selectFilteredAuthors = createSelector(
  selectUserAuthorSubscriptions,
  fromReducer.getFiltered
);

export const selectFilteredAuthorsIds = createSelector(
  selectFilteredAuthors,
  (subscription) => subscription?.map(s => s.id)
);

export const selectSelectedSortTypeForAuthors = createSelector(
  selectUserAuthorSubscriptions,
  fromReducer.getSortTypeFor
);


export const selectSuggestedAuthors = createSelector(
  selectUserAuthorSubscriptions,
  fromReducer.getSuggested
);

export const selectSuggestedAuthorsCount = createSelector(
  selectSuggestedAuthors,
  (subscriptions) => subscriptions?.length
);

export const selectAuthorsSuggestionLoading = createSelector(
  selectUserAuthorSubscriptions,
  fromReducer.getSuggestionLoading
);
export const selectAuthorsSuggestionLoaded = createSelector(
  selectUserAuthorSubscriptions,
  fromReducer.getSuggestionLoaded
);

export const selectAuthorsNewsLoading = createSelector(
  selectUserAuthorSubscriptions,
  fromReducer.getNewsLoading
);

export const selectAuthorsIsLikeProcessing = createSelector(
  selectUserAuthorSubscriptions,
  fromReducer.getIsLikeProcessing
);
export const selectAuthorsIsLikeProcessingIds = createSelector(
  selectUserAuthorSubscriptions,
  fromReducer.getIsLikeProcessingIds
);

export const selectAuthorsFilters = createSelector(
  selectUserAuthorSubscriptions,
  fromReducer.getFilters
);

export const selectAuthorsFiltersForRequest = createSelector(
  selectAuthorsFilters,
  (filters) => {
    return  ({
      name: filters?.name
    })
  }
)

export const selectAuthorsPagination = createSelector(
  selectUserAuthorSubscriptions,
  fromReducer.getPagination
);

export const selectAuthorsPageIndex = createSelector(
  selectAuthorsPagination,
  fromReducer.getPageIndex
);

export const selectAuthorsPageSize = createSelector(
  selectAuthorsPagination,
  fromReducer.getPageSize
);

export const selectLocalForAuthor = createSelector(
  selectUserAuthorSubscriptions,
  fromReducer.getLocal
)

export const selectGlobalForAuthor = createSelector(
  selectUserAuthorSubscriptions,
  fromReducer.getGlobal
)

export const selectLocalNewsForAuthor = createSelector(
  selectLocalForAuthor,
  fromReducer.getNews
);
export const selectGlobalNewsForAuthor = createSelector(
  selectGlobalForAuthor,
  fromReducer.getNews
);

export const selectLocalAllNewsForAuthor = createSelector(
  selectLocalNewsForAuthor,
  fromReducer.getAllNews
);

export const selectGlobalAllNewsForAuthor = createSelector(
  selectGlobalNewsForAuthor,
  fromReducer.getAllNews
);

export const selectIsLastLocalForAuthor = createSelector(
  selectLocalForAuthor,
  fromReducer.getInsidePagination,
  ({pagination}) => pagination?.isLast
);

export const selectIsLastGlobalForAuthor = createSelector(
  selectGlobalForAuthor,
  fromReducer.getInsidePagination,
  ({pagination}) => pagination?.isLast
);

// TOPICS
export const selectUserTopicSubscriptions = createSelector(
  selectSubscriptions,
  fromReducer.getUserTopicSubscriptions
);

export const selectAllUserTopics = createSelector(
  selectUserTopicSubscriptions,
  fromReducer.getAll
);

export const selectSelectedTopics = createSelector(
  selectUserTopicSubscriptions,
  fromReducer.getSelected
);

export const selectFilteredTopics = createSelector(
  selectUserTopicSubscriptions,
  fromReducer.getFiltered
);

export const selectFilteredTopicsIds = createSelector(
  selectFilteredTopics,
  (subscription) => subscription?.map(s => s.id)
);

export const selectSelectedSortTypeForTopics = createSelector(
  selectUserTopicSubscriptions,
  fromReducer.getSortTypeFor
);


export const selectSuggestedTopics = createSelector(
  selectUserTopicSubscriptions,
  fromReducer.getSuggested
);

export const selectSuggestedTopicsCount = createSelector(
  selectSuggestedTopics,
  (subscriptions) => subscriptions?.length
);

export const selectTopicsNewsLoading = createSelector(
  selectUserTopicSubscriptions,
  fromReducer.getNewsLoading
);

export const selectTopicsIsLikeProcessing = createSelector(
  selectUserTopicSubscriptions,
  fromReducer.getIsLikeProcessing
);
export const selectTopicsIsLikeProcessingIds = createSelector(
  selectUserTopicSubscriptions,
  fromReducer.getIsLikeProcessingIds
);

export const selectTopicsFilters = createSelector(
  selectUserTopicSubscriptions,
  fromReducer.getFilters
);

export const selectTopicsFiltersForRequest = createSelector(
  selectTopicsFilters,
  (filters) => {
    return  ({
      name: filters?.name
    })
  }
)

export const selectTopicsPagination = createSelector(
  selectUserTopicSubscriptions,
  fromReducer.getPagination
);

export const selectTopicsPageIndex = createSelector(
  selectTopicsPagination,
  fromReducer.getPageIndex
);

export const selectTopicsPageSize = createSelector(
  selectTopicsPagination,
  fromReducer.getPageSize
);



export const selectLocalForTopic = createSelector(
  selectUserTopicSubscriptions,
  fromReducer.getLocal
)

export const selectGlobalFoTopic = createSelector(
  selectUserTopicSubscriptions,
  fromReducer.getGlobal
)

export const selectLocalNewsForTopic = createSelector(
  selectLocalForTopic,
  fromReducer.getNews
);

export const selectGlobalNewsForTopic = createSelector(
  selectGlobalFoTopic,
  fromReducer.getNews
);

export const selectTopicsSuggestionLoading = createSelector(
  selectUserTopicSubscriptions,
  fromReducer.getSuggestionLoading
);
export const selectTopicsSuggestionLoaded = createSelector(
  selectUserTopicSubscriptions,
  fromReducer.getSuggestionLoaded
);

export const selectGlobalAllNewsForTopic = createSelector(
  selectGlobalNewsForTopic,
  fromReducer.getAllNews
);

export const selectLocalAllNewsForTopic = createSelector(
  selectLocalNewsForTopic,
  fromReducer.getAllNews
);

export const selectForm = createSelector(
  selectState,
  fromReducer.getForm
);

export const selectIsLastLocalForTopic = createSelector(
  selectLocalForTopic,
  fromReducer.getInsidePagination,
  ({pagination}) => pagination?.isLast
);

export const selectIsLastGlobalForTopic = createSelector(
  selectGlobalFoTopic,
  fromReducer.getInsidePagination,
  ({pagination}) => pagination?.isLast
);
