import {createSelector} from '@ngrx/store';
import * as fromEventsReducer from '../event.reducer';
import * as fromDetailsReducer from './details.reducer';

export const selectDetailsState = createSelector(
  fromEventsReducer.selectState,
  fromEventsReducer.getDetailsState
);

export const selectEvent= createSelector(
  selectDetailsState,
  fromDetailsReducer.getEvent
);
