import { Comment } from '@core/models';
import { UserSearch } from '@core/models/user-search.model';
import { createAction, props } from '@ngrx/store';

export const setInitialCommentsState = createAction(
  '[COMMENTS] Set initial comments state.',
  props<{ commentsEnabled: boolean, commentsHidden: boolean }>()
);

export const getCommentsRequest = createAction(
  '[COMMENTS] Get all comments request.',
  props<{ newsId: string }>()
);

export const getCommentsSuccess = createAction(
  '[COMMENTS]  Get all comments success.',
  props<{ comments: Comment[] }>()
);

export const getCommentsFailure = createAction(
  '[COMMENTS]  Get all comments failure.',
  props<{ error: string }>()
);

export const postCommentRequest = createAction(
  '[COMMENTS] Post comment request',
  props<{ newsId: string, text: string }>()
);

export const postCommentSuccess = createAction(
  '[COMMENTS] Post comment success',
  props<{ comment: Comment }>()
);

export const postCommentFailure = createAction(
  '[COMMENTS] Post comment failure',
  props<{ error: string }>()
);

export const saveEditRequest = createAction(
  '[COMMENTS] Save edit comment request',
  props<{ newsId: string, commentId: number, text: string, parentId?: number }>()
);

export const saveEditSuccess = createAction(
  '[COMMENTS] Save edit comment success',
  props<{ commentId: number, text: string, parentId?: number }>()
);

export const saveEditFailure = createAction(
  '[COMMENTS] Save edit comment failure',
  props<{ error: string }>()
);

export const sendReplyRequest = createAction(
  '[COMMENTS] Send reply request',
  props<{ newsId: string, parentId: number, text: string }>()
);

export const sendReplySuccess = createAction(
  '[COMMENTS] Send reply success',
  props<{ parentId: number, comment: Comment }>()
);

export const sendReplyFailure = createAction(
  '[COMMENTS] Send reply failure',
  props<{ error: string }>()
);

export const getMentionAutocompleteRequest = createAction(
  '[COMMENTS] Get Mention autocomplete',
  props<{ search: string }>()
);

export const getMentionAutocompleteSuccess = createAction(
  '[COMMENTS] Get Mention autocomplete success',
  props<{ autocomplete: Array<UserSearch> }>()
);

export const clearMentionAutocomplete = createAction(
  '[COMMENTS] Clear Mention autocomplete',
);

export const openDeleteCommentConfirmDialog = createAction(
  '[COMMENTS] Open Delete comment confirm dialog',
  props<{ newsId: string, commentId: number, parentId?: number }>()
);

export const deleteCommentRequest = createAction(
  '[COMMENTS] Delete comment request',
  props<{ newsId: string, commentId: number, parentId?: number }>()
);

export const deleteCommentSuccess = createAction(
  '[COMMENTS] Delete comment success',
  props<{ commentId: number, parentId?: number }>()
);

export const deleteCommentFailure = createAction(
  '[COMMENTS] Delete comment failure',
  props<{ error: string }>()
);

export const openHideCommentConfirmDialog = createAction(
  '[COMMENTS] Hide comment confirm dialog',
  props<{ newsId: string, commentId: number, parentId?: number }>()
);

export const hideCommentRequest = createAction(
  '[COMMENTS] Hide comment request',
  props<{ newsId: string, commentId: number, parentId?: number }>()
);

export const hideCommentSuccess = createAction(
  '[COMMENTS] Hide comment success',
  props<{ commentId: number, parentId?: number }>()
);

export const hideCommentFailure = createAction(
  '[COMMENTS] Hide comment failure',
  props<{ error: string }>()
);

export const openRevealCommentConfirmDialog = createAction(
  '[COMMENTS] Reveal comment confirm dialog',
  props<{ newsId: string, commentId: number, parentId?: number }>()
);

export const revealCommentRequest = createAction(
  '[COMMENTS] Reveal comment request',
  props<{ newsId: string, commentId: number, parentId?: number }>()
);

export const revealCommentSuccess = createAction(
  '[COMMENTS] Reveal comment success',
  props<{ commentId: number, parentId?: number }>()
);

export const revealCommentFailure = createAction(
  '[COMMENTS] Reveal comment failure',
  props<{ error: string }>()
);

export const openCommentingDisableConfirmModal = createAction(
  '[COMMENTS] Disable commenting confirm dialog',
  props<{ newsId: string }>()
);

export const disableCommentingRequest = createAction(
  '[COMMENTS] Disable commenting request',
  props<{ newsId: string, hide: boolean; }>()
);

export const disableCommentingSuccess = createAction(
  '[COMMENTS] Disable commenting success',
  props<{ newsId: string, hide: boolean; }>()
);

export const disableCommentingFailure = createAction(
  '[COMMENTS] Disable commenting failure',
  props<{ error: string }>()
);

export const openCommentingEnableConfirmModal = createAction(
  '[COMMENTS] Enable commenting confirm dialog',
  props<{ newsId: string }>()
);

export const enableCommentingRequest = createAction(
  '[COMMENTS] Enable commenting request',
  props<{ newsId: string, hide: boolean; }>()
);

export const enableCommentingSuccess = createAction(
  '[COMMENTS] Enable commenting success',
  props<{ newsId: string, hide: boolean; }>()
);

export const enableCommentingFailure = createAction(
  '[COMMENTS] Enable commenting failure',
  props<{ error: string }>()
);

export const clear = createAction(
  '[COMMENTS] clear',
);
