import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, Dictionary, EntityAdapter, EntityState} from '@ngrx/entity';

import {CategoryFlat} from '@core/models/category.model';
import * as fromActions from '@core/core-store/categories-flat/categories-flat.actions';

export interface State extends EntityState<CategoryFlat> {
}

export const adapter: EntityAdapter<CategoryFlat> =
    createEntityAdapter<CategoryFlat>({
        selectId: category => category.categoryId
    });

export const initialState: State = {
    ...adapter.getInitialState(),
};

const categoriesFlatReducer = createReducer(
    initialState,
    on(fromActions.categoriesFlatCollectionAddMany, (state, {categoriesFlat}) => (
        adapter.addMany(categoriesFlat, state)
    )),
);

export function reducer(state: State | undefined, action: Action) {
    return categoriesFlatReducer(state, action);
}

export const getIds = adapter.getSelectors().selectIds;
export const getEntities = adapter.getSelectors().selectEntities;
export const getAll = adapter.getSelectors().selectAll;
export const getTotal = adapter.getSelectors().selectTotal;

export const getEntityById = (entities: Dictionary<CategoryFlat>, {id}) => entities[id];
export const getEntitiesById = (entities: Dictionary<CategoryFlat>, {ids}) => ids.map(id => entities[id]);

export const getRoot = (categories: Array<CategoryFlat>) => categories.filter((entity) => entity.parentCategoryId === null);
export const getRootId = (categories: Array<CategoryFlat>) => categories[0]?.categoryId;
