import { NgModule, ModuleWithProviders } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { feature, emailReducer } from './store/email.reducer';
import { EmailEffects } from '@core/email/store';
import { SERVICES } from './services';

const EFFECTS = [
  EmailEffects
];

@NgModule({
  imports: [
      StoreModule.forFeature(feature, emailReducer),
      EffectsModule.forFeature(EFFECTS),
  ],
  declarations: [],
  exports: [],
  providers: []
})
export class EmailModule {
  static forRoot(): ModuleWithProviders<EmailModule> {
      return {
          ngModule: EmailModule,
          providers: [
            ...SERVICES,
          ]
      };
  }
}
