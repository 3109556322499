import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Section, SectionTypes} from '../models/section-type.enum';
import {DomSanitizer} from '@angular/platform-browser';
import {NewsletterInlineForm} from '@app/newsletter/models';
import {createPlaceholderUrl} from '../helpers/helpers';


@Component({
    selector: 'rh-newsletter-preview-read-only',
    templateUrl: './newsletter-preview-read-only.component.html',
    styleUrls: ['./newsletter-preview-read-only.component.scss']
})
export class NewsletterPreviewReadOnlyComponent {

    @ViewChild('title', {static: true}) title: ElementRef<HTMLHeadingElement>;
    // @Input() sections: Array<Section>;
    @Input() form: Partial<NewsletterInlineForm>;
    @Input() replacementRequired: boolean;

    parsedMarkups;
    _sections: Array<Section>;
    @Input() set sections(value: Array<Section>) {
        this._sections = value;
        this.parsedMarkups = value.map(section => {
            return {
                ...section,
                subsections: section.subsections.map(subsection => {
                    return {
                        ...subsection,
                        blocks: subsection.blocks.map(block => {
                            return {
                                ...block,
                                markup: this.getSecureHTML(block)
                            }
                        })
                    }
                })
            };
        })
    }

    get sections(): Array<Section> {
        return this._sections;
    }

    now = Date.now();
    public rocheLogo = '/assets/images/roche_logo.png';

    sectionTypes = SectionTypes;

    constructor(
        private sanitizer: DomSanitizer,
    ) {
    }

    isEmpty(section: Section) {
        return section.subsections.every(ss => ss.blocks.length === 0);
    }

    isAnySubsectionFilled(section: Section) {
        return section.subsections.some(ss => ss.blocks.length > 0);
    }


    getSecureHTML(markup) {
        if (this.replacementRequired) {
            return this.sanitizer.bypassSecurityTrustHtml(
                markup
                    .replace(/href="(.*?)">/gm, 'href="">')
                    .replace(/href='(.*?)'>/gm, `href=''>`)
                    .replace(/data-nbimg src='(.*)'/gm, `data-nbimg src='${createPlaceholderUrl()}'`));

        }

        return this.sanitizer.bypassSecurityTrustHtml(markup);
    }
}
