import { createAction, props } from '@ngrx/store';
import { UserWorkdayInformation } from '@app/core/models/user-workday.model';

export const getUserByLoginRequest = createAction(
  '[WORKDAY USER - GET USER BY LOGIN REQUEST] Get User by login Request',
  props<{login: string}>()
);

export const getUserByLoginSuccess = createAction(
  '[WORKDAY USER - GET USER BY LOGIN SUCCESS] Get User by login Success',
  props<{user: UserWorkdayInformation}>()
);

export const getUserByLoginFailure = createAction(
  '[WORKDAY USER - GET USER BY LOGIN FAILURE] Get User by login Failure',
  props<{error: any}>()
);

export const goToWorkdayProfile = createAction(
  '[WORKDAY USER - GO TO WORKDAY PROFILE] Go to workday global-settings',
);

export const clearUserInformation = createAction(
  '[WORKDAY USER - CLEAR USER INFORMATION] Clear user information',
);
