import { SectionTypes } from '@app/newsletter/inline-editor/models/section-type.enum';
import { createSelector } from '@ngrx/store';

import * as fromReducer from './newsletter-blocks.reducer';
import * as fromNewsletter from '../newsletter.reducer';
import { BlockTypes } from '@app/newsletter/inline-editor/models/block-type.enum';

export const selectState = createSelector(
  fromNewsletter.selectState,
  fromNewsletter.getNewsletterBlocks
);

export const selectIsBlockDragging = createSelector(
  selectState,
  fromReducer.getIsBlockDragging
);

export const selectDraggingBlockType = createSelector(
  selectState,
  fromReducer.getDraggingBlockType
);

export const selectSections = createSelector(
  selectState,
  fromReducer.getSections
);

export const selectSectionTypeBySubsectionId = (id: string) => createSelector(
  selectSections,
  (sections) => sections.find(s => s.subsections.findIndex(ss => ss.id === id) > -1)?.type
);

export const selectSubsectionsSiblings = (id: string) => createSelector(
  selectSections,
  (sections) => sections.find(s => s.subsections.findIndex(ss => ss.id === id) > -1)?.subsections.map(s => s.id)
);

export const selectSectionTypeByBlockId = (id: string) => createSelector(
  selectSections,
  (sections) => sections.find(s => !!s.subsections.find(ss => ss.blocks.findIndex(b => b.id === id) > -1))?.type
);

export const selectSectionsLength = createSelector(
  selectState,
  fromReducer.getSectionsLength
);

export const selectConnectedBlocksList = createSelector(
  selectState,
  fromReducer.getConnectedBlockList
);

export const selectConnectedBlocksListForCurrentType = createSelector(
  selectConnectedBlocksList,
  selectDraggingBlockType,
  (connectedBlocksList, blockType) => {
    if (!blockType) {
      return connectedBlocksList.map(l => l.id);
    }
    switch (blockType) {
      case BlockTypes.NEWS:
        return connectedBlocksList.filter(l => l.sectionType === SectionTypes.SINGLE || l.sectionType === SectionTypes.TWO_EQUAL).map(l => l.id);
      default:
        return connectedBlocksList.map(l => l.id);
    }
  }
);

export const selectConnectedBlocksListForType = (blockType: BlockTypes) => createSelector(
  selectConnectedBlocksList,
  (connectedBlocksList) => {
    if (!blockType) {
      return connectedBlocksList.map(l => l.id);
    }
    switch (blockType) {
      case BlockTypes.NEWS:
        return connectedBlocksList.filter(l => l.sectionType === SectionTypes.SINGLE || l.sectionType === SectionTypes.TWO_EQUAL).map(l => l.id);
      default:
        return connectedBlocksList.map(l => l.id);
    }
  }
);

export const selectBlockMarkup = (id: string) => createSelector(
  selectSections,
  (sections) => {
    if (id) {
      const sectionIndex = sections.findIndex(s => s.subsections.some(ss => ss.blocks.some(b => b.id === id)));
      if (sectionIndex > -1) {
        const subsectionIndex = sections[sectionIndex].subsections.findIndex(ss => ss.blocks.some(b => b.id === id));
        const blockIndex = sections[sectionIndex].subsections[subsectionIndex].blocks.findIndex(b => b.id === id);

        const markup = sections[sectionIndex].subsections[subsectionIndex].blocks[blockIndex].markup;
        return markup;
      }

    }
    return 'DESTROYED';
  }
);

export const selectEmailBody = createSelector(
  selectSections,
  (sections) => {
    let html =
    `<table cols="6" style="width: 100%; height: 100%; border-spacing: 24px;">
      <colgroup>
        <col width="16.66%">
        <col width="16.66%">
        <col width="16.66%">
        <col width="16.66%">
        <col width="16.66%">
        <col width="16.66%">
      </colgroup>
    `;

    sections.forEach((s) => {
      html += `<tr>`;

      s.subsections.forEach((ss) => {
        html += `<td style="word-break: break-word; white-space: pre-wrap; vertical-align: top;" colspan="${ss.colspan}">`;
        ss.blocks.forEach((b) => {
          html += b.markup;
        });
        html += '</td>';
      });
      html += '</tr>';
    });
    html += '</table>';
    return html;
  }
);
