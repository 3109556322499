import {Component, ElementRef, OnInit} from '@angular/core';
import {ComponentsLoaderService} from '../components-loader.service';

@Component({
  selector: 'rh-news-details-loader',
  templateUrl: './news-details-loader.component.html',
  styleUrls: ['./news-details-loader.component.scss']
})
export class NewsDetailsLoaderComponent implements OnInit {
  width: number;

  constructor(public componentsLoaderService: ComponentsLoaderService, private el: ElementRef<HTMLElement>) {
  }

  ngOnInit(): void {
    this.width = +getComputedStyle(this.el.nativeElement).width.split('px')[0];
  }
}
