export enum RoleIds {
  ADMINISTRATOR = 1,
  CONTENT_QUALITY_MANAGER = 2,
  AUDIENCE_MANAGER = 3,
  NB_ADMINISTRATOR = 4,
  NB_SUPER_ADMINISTRATOR = 5
}

export enum RoleNames {
  ADMINISTRATOR = 'Administrator',
  CONTENT_QUALITY_MANAGER = 'Content Quality Manager',
  AUDIENCE_MANAGER = 'Audience Manager',
  NB_ADMINISTRATOR = 'Admin',
  NB_SUPER_ADMINISTRATOR = 'SuperAdmin'
}
