import {Injectable} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, concatMap, switchMap, filter} from 'rxjs/operators';

import * as fromActions from '@core/core-store/user-photo/user-photo.actions';
import * as fromReducer from '@core/core-store/user-photo/user-photo.reducer';
import * as fromSelectors from '@core/core-store/user-photo/user-photo.selectors';

import {UserProfileService} from '@core/services/userProfile.service';
import {of} from 'rxjs';

@Injectable()
export class UserPhotoEffects {

  
  public getUserPhoto$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getUserPhoto),
      switchMap(({login}) => this.store$.pipe(
        select(fromSelectors.selectEntityById, { id: login}),
        filter((userPhoto) => !userPhoto.requested ),
        map(({login }) => fromActions.getUserPhotoRequest({login}))
      )),
    ), {dispatch: true}
  );

  public getUserPhotoRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getUserPhotoRequest),
      concatMap(({ login }) => this.userProfileService.getUserPhoto(login).pipe(
        map(url => fromActions.getUserPhotoSuccess({ url, login })),
        catchError((error: string) => {
          const userNotFound = error.includes('404');
          return of(fromActions.getUserPhotoFailure({ login, userNotFound }))
        })
      )),
    ), {dispatch: true}
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private userProfileService: UserProfileService,
  ) {
  }
}
