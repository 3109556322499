import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[rhOverrideSelectLabel]'
})
export class OverrideSelectLabelDirective implements AfterViewInit{
  private overridedLabel = null;
  @Input() set rhOverrideSelectLabel(value: string) {
      this.overridedLabel = value;
      this.addCustomLabel(value);
  }

  constructor(private elRef: ElementRef, private renderer2: Renderer2) { }
  ngAfterViewInit(): void {
    if (this.overridedLabel) {
      this.addCustomLabel(this.overridedLabel);
    }
  }

  addCustomLabel(label) {
    const parent = (this.elRef.nativeElement as HTMLElement).getElementsByClassName('rds-form-field__select-value')[0];
    if (!parent) {
      return;
    }
    parent.textContent = null;
    const newLabel = this.renderer2.createElement('P') as HTMLParagraphElement;
    newLabel.textContent = label;
    newLabel.style.overflow = 'hidden';
    newLabel.style.whiteSpace = 'nowrap';
    newLabel.style.textOverflow = 'ellipsis';
    parent.appendChild(newLabel);
  }
}
