import {
	FeedbackStatisticsEnum,
	FeedbackStatisticsModel,
	DeliveryStatisticsModel,
} from '@app/core/models/newsletter.model';
import { RhFeedbackSettings } from '@app/newsletter-new/models/newsletter';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const statistics = createActionGroup({
	source: 'STATISTICS ALL',
	events: {
		init: props<{ id: number }>(),
		clear: emptyProps(),
		requestOverall: props<{
			id: number;
		}>(),
		successOverall: props<{ title: string; statistics: DeliveryStatisticsModel }>(),
		requestFeedback: props<{
			id: number;
			feedbackSettings: RhFeedbackSettings;
		}>(),
		successFeedback: props<{ feedback: FeedbackStatisticsModel }>(),
		failure: props<{ error: any }>(),
		randomizeFeedback: emptyProps(),
		changeFeedbackType: props<{ feedbackType: FeedbackStatisticsEnum }>(),
	},
});
