import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import { distinctUntilChanged, filter, first, map, switchMap } from 'rxjs/operators';
import * as fromCloudSearch from './';
import * as fromSearch from '../search.selectors';
import {GapiService} from '@app/core/services/gapi.service';
import * as fromAuth from '@core/auth/store/auth.selectors';
import {  GCSQueryRequest, SearchSourceListItem } from '@app/search/models/gcs';

@Injectable()
export class CloudSearchEffects {
  public initialRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCloudSearch.initialRequest),
      map(() => fromCloudSearch.loadResults())
    ), { dispatch: true }
  );

  public changeSource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCloudSearch.changeSource),
      switchMap(() => this.store$.pipe(
        select(fromCloudSearch.selectCurrentResults),
        first((currentResults) => !!currentResults),
      )),
      map(() => fromCloudSearch.loadResults())
    ), { dispatch: true }
  );

  public changeFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCloudSearch.changeFilters),
      map(() => fromCloudSearch.loadResults())
    ), { dispatch: true }
  );

  public changeFiltersLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCloudSearch.changeFilters),
      switchMap(() => this.store$.pipe(
        select(fromCloudSearch.selectCurrentSource),
        first((currentSource) => !!currentSource),
      )),
    ), { dispatch: false }
  );

  public loadResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCloudSearch.loadResults),
      switchMap(() => this.store$.pipe(
        select(fromAuth.selectIsQueryAPILoaded),
        first((isLoaded) => isLoaded)
      )),
      switchMap(() => this.store$.pipe(
        select(fromSearch.selectPhrase),
        distinctUntilChanged(),
        map((phrase) => ({phrase}))
      )),
      switchMap(({phrase}) => this.store$.pipe(
        select(fromCloudSearch.selectCurrentSource),
        first((currentSource) => !!currentSource),
        map((currentSource) => ({phrase, currentSource}))
      )),
      switchMap(({phrase, currentSource}) => this.store$.pipe(
        select(fromCloudSearch.selectCurrentResults),
        first((currentResults) => !!currentResults),
        map((currentResults) => ({phrase, currentSource, currentResults}))
      )),
      map(({phrase, currentResults, currentSource}): { currentSource: SearchSourceListItem, request: Partial<GCSQueryRequest> } => ({
        currentSource,
        request: {
          query: `${phrase} ${currentSource.filters ? Object.entries(currentSource.filters).map(([key, object]) => object.selectedOption.value).join(' ').trim() : ''}`,
          dataSourceRestrictions: currentSource.sources.map(s => ({
            source: s,
          })),
          start: 0,
          pageSize: currentResults.pageSize
        }
      })),
      filter(({currentSource, request}) => request.query.length > 3),
      switchMap(({currentSource, request}) => this.gapiService.query(request).pipe(
        map((response) => {
          if (currentSource.sources.length > 1) {
            return fromCloudSearch.loadResultsSuccess({
              source: 'all',
              results: response.results,
              hasMoreResults: response.hasMoreResults
            })
          } else {
            const source = currentSource.sources[0].predefinedSource || currentSource.sources[0].name;
            return fromCloudSearch.loadResultsSuccess({
              source,
              results: response.results,
              hasMoreResults: response.hasMoreResults
            });
          }
        })
      ))
    ), { dispatch: true }
  );

public loadMoreResults3$ = createEffect(() =>
this.actions$.pipe(
  ofType(fromCloudSearch.loadMoreResults),
  switchMap(() => this.store$.pipe(
    select(fromAuth.selectIsQueryAPILoaded),
    first((isLoaded) => isLoaded),
  )),
  switchMap(() => this.store$.pipe(
    select(fromSearch.selectPhrase),
    first((phrase) => !!phrase),
    map((phrase) => ({phrase}))
  )),
  switchMap(({phrase}) => this.store$.pipe(
    select(fromCloudSearch.selectCurrentSource),
    first((currentSource) => !!currentSource),
    map((currentSource) => ({phrase, currentSource}))
  )),
  switchMap(({phrase, currentSource}) => this.store$.pipe(
    select(fromCloudSearch.selectCurrentResults),
    first((currentResults) => !!currentResults),
    map((currentResults) => ({phrase, currentSource, currentResults}))
  )),
  filter(({phrase, currentResults, currentSource}) => currentResults.hasMoreResults),
  map(({phrase, currentResults, currentSource}): { currentSource: SearchSourceListItem, request: Partial<GCSQueryRequest> } => ({
    currentSource,
    request: {
      query: `${phrase} ${currentSource.filters ? Object.entries(currentSource.filters).map(([key, object]) => object.selectedOption.value).join(' ').trim() : ''}`,
      dataSourceRestrictions: currentSource.sources.map(s => ({
        source: s,
      })),
      start: currentResults.results.length,
      pageSize: currentResults.pageSize
    }
  })),
  filter(({currentSource, request}) => request.query.length > 3),
  switchMap(({currentSource, request}) => this.gapiService.query(request).pipe(
    map((response) => {
      if (currentSource.sources.length > 1) {
        return fromCloudSearch.loadMoreResultsSuccess({
          source: 'all',
          results: response.results,
          hasMoreResults: response.hasMoreResults
        })
      } else {
        const source = currentSource.sources[0].predefinedSource || currentSource.sources[0].name;
        return fromCloudSearch.loadMoreResultsSuccess({
          source,
          results: response.results,
          hasMoreResults: response.hasMoreResults
        });
      }
    })
  ))
), { dispatch: true }
);

  constructor(
    private actions$: Actions,
    private store$: Store,
    private gapiService: GapiService,
  ) {}
}
