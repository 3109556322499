import {FocusKeyManager} from '@angular/cdk/a11y';
import {ArrayDataSource, SelectionModel} from '@angular/cdk/collections';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {FlatTreeControl} from '@angular/cdk/tree';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  SimpleChanges,
  SkipSelf,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {ControlContainer, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {OverflowingContentDirective} from '@app/shared/utils/overflowing-content.directive';
import {Observable, Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil, tap} from 'rxjs/operators';
import {DropdownNodeComponent} from './dropdown-node/dropdown-node.component';
import {DropdownFlatNode, NestedOptionsModel} from './models';
import {getTreeStructure} from './utils';

@Component({
  selector: 'rh-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true
    },
  ]
})

export class DropdownComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @ViewChild('overflowing', {static: false}) overflowing: OverflowingContentDirective;

  //Accessibility (FocusManager etc.)
  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    this.cdRef.detectChanges();
    if (!this.isMultiple && !(event.target instanceof HTMLButtonElement) && event.key === 'Enter') {
      this.select((this.keyManager.activeItem?.nodeScope.node as DropdownFlatNode));
    } else {
      this.keyManager.onKeydown(event);
    }
  }

  @ViewChild(CdkVirtualScrollViewport, {static: true}) scrollViewport!: CdkVirtualScrollViewport;

  @ViewChildren(DropdownNodeComponent) nodesRef!: QueryList<DropdownNodeComponent>;
  private keyManager!: FocusKeyManager<DropdownNodeComponent>;

  private onChange: (_: any) => void;
  private onTouch: () => void;

  @ViewChild('contentTrigger', {static: true}) contentTrigger!: ElementRef<HTMLElement>;
  @Input() floatingOptions = false;
  @Input() visibleChipsNumber: number = 5;
  @Input() placeholder!: string;
  @Input() chipPlaceholder!: string;
  @Input() formControl!: FormControl;
  @Input() formControlName!: string;
  @Input() closeOnClickOutside!: boolean;
  @Input() showChips: boolean = true;
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() showSearch: boolean = true;
  @Input() isMultiple!: boolean;
  initialSelection: Array<string> = [];
  @Input() optionsDictionary!: any;
  @Input() optionsModel!: Partial<NestedOptionsModel>;
  dataSource!: ArrayDataSource<DropdownFlatNode>;
  @Input() visibleElementsNumber: number = 6;
  @Input() closeAfterSelect: boolean = true;
  @Input() scrollToIndex!: number;
  @Input() selectParentWhenAllSelected = true;
  @Input() alwaysOpened = false;
  @Input() transparent = false;

  @Output() withOpenChange = new EventEmitter<boolean>();

  treeData!: Array<DropdownFlatNode>;
  toUnselect!: DropdownFlatNode | null;
  selection!: SelectionModel<DropdownFlatNode>;
  visibleNodes: Array<DropdownFlatNode> = [];

  opened = false;
  isAllOptionsOpened = false;

  searchDropdownForm: FormGroup = this.fb.group({
    search: new FormControl('')
  });

  dropdownDestroyed$: Subject<boolean> = new Subject();

  searchPhrase$!: Observable<string>;
  searchPhrase!: string;

  get noCustomTrigger() {
    return this.contentTrigger.nativeElement.childNodes.length === 0;
  }

  get control() {
    return this.formControl || this.controlContainer.control?.get(this.formControlName);
  }

  get required() {
    return this.control.hasValidator(Validators.required) || false
  }

  get disabled() {
    return this.control.disabled || false;
  }

  subs: Subscription = new Subscription();
  selectionSub: Subscription = new Subscription();
  selectionChangedSub: Subscription = new Subscription();


  ngOnChanges(changes: SimpleChanges) {
    if ((changes['optionsDictionary'] && !changes['optionsDictionary'].firstChange) || (changes['optionsModel'] && !changes['optionsModel'].firstChange)) {
      this.setData();
    }
  }

  ngOnInit() {
    this.setData();
    this.searchDropdownForm.get('search')?.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap((phrase) => {
        this.searchPhrase = phrase;
        this.visibleNodes = this.treeData.filter(node => this.shouldRender(node));
        this.cdRef.detectChanges();
      }),
      takeUntil(this.dropdownDestroyed$)
    ).subscribe();

    this.cdRef.detectChanges();

    this.treeControl.expansionModel.changed.pipe(
      takeUntil(this.dropdownDestroyed$)
    ).subscribe(res => {
      this.visibleNodes = this.treeData.filter(node => this.shouldRender(node));
    });
  }

  ngAfterViewInit() {


    this.keyManager = new FocusKeyManager(this.nodesRef)
      .skipPredicate(item => {
        let shouldRender = this.visibleNodes.some(i => i === item.nodeScope.node);
        return !shouldRender;
      });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.selectionSub.unsubscribe();
    this.selectionChangedSub.unsubscribe();
    this.dropdownDestroyed$.next(true);
    this.dropdownDestroyed$.complete();
    this.cdRef.detach();
  }

  setData() {
    this.treeData = getTreeStructure(this.optionsDictionary, this.optionsModel);

    this.dataSource = new ArrayDataSource(this.treeData);
    this.treeControl.dataNodes = this.treeData;

    if (this.selection && this.selection.selected.length > 0) {
      let newNodes: DropdownFlatNode[] = [];
      this.selection.selected.forEach(snode => {
        const node = this.getTreeControlNodeFromSelectionNode(snode);
        if (node) {
          newNodes.push(node)
        }
      });
      this.selection.clear();
      this.selection.select(...newNodes);
    }

    if (this.treeData.filter(node => node.level === 0).length === 1) this.treeControl.expand(this.treeData[0]);
    this.visibleNodes = this.treeData.filter(node => this.shouldRender(node));
  }

  setActiveItem(node: DropdownFlatNode) {
    let nodeRef = this.nodesRef.find(item => item.nodeScope.node === node);
    this.keyManager.setActiveItem(nodeRef as DropdownNodeComponent);
  }

  toggleDropdown() {
    if (this.disabled) {
      return;
    }
    this.opened = !this.opened;
    if (this.opened === false) {
      this.onTouch();
      this.searchDropdownForm.controls.search.setValue('');
    } else {
      this.expandSelected();
    }
    this.withOpenChange.emit(this.opened);
  }

  expandSelected() {
    this.treeControl.collapseAll();
    if (this.treeData.filter(node => node.level === 0).length === 1) this.treeControl.expand(this.treeData[0]);
    this.selection.selected.forEach((selection) => {
      let isNodeExpandable = this.treeControl.isExpandable(selection);
      if (!isNodeExpandable) {
        let parentNode = this.getParentNode(selection);
        while (parentNode) {
          this.treeControl.expand(parentNode);
          parentNode = this.getParentNode(parentNode);
        }
      }
    });
    this.visibleNodes = this.treeData.filter(node => this.shouldRender(node));
  }

  onOutsideClick() {
    if (this.closeOnClickOutside) {
      if (this.opened === true) {
        this.onTouch();
      }
      this.opened = false;
      this.withOpenChange.emit(this.opened);
    }
  }

  writeValue(ids: string[]): void {
    this.initialSelection = ids;
    this.selectionChangedSub.unsubscribe();
    this.selection = new SelectionModel<DropdownFlatNode>(this.isMultiple, this.getInitialNodes(this.initialSelection));
    // Ask testers if last condition didnt destroyed old dropdowns
    if (this.optionsDictionary.length > 0 && this.getSelection().length !== this.initialSelection.length && this.isMultiple) {
      this.control.setValue(this.getSelection())
    }

    this.selectionChangedSub = this.selection.changed.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => {
        this.onSelectionChange(this.getSelection());
        if (!!this.overflowing) {
          this.overflowing.update();
        }
      }),
      takeUntil(this.dropdownDestroyed$)
    ).subscribe(
    )

    if (!(this.cdRef as any)['destroyed']) {
      this.cdRef.detectChanges();
    }
  }

  get invalidTouchedNotOpened(): boolean {
    return this.control.invalid && this.control.touched && this.opened === false;
  }

  get chipsList() {
    return this.treeData.filter(node => this.control.value.includes((node as any)[(this.optionsModel.idKey as string)]));
  }

  remove(node: DropdownFlatNode) {
    this.isMultiple ? this.toggleNodeChckbx(node) : this.selection.clear();
  }

  clearUnselect() {
    this.toUnselect = null;
    this.cdRef.detectChanges();
  }

  registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  onSelectionChange($event: any) {
    this.onChange($event);
    this.onTouch();
    this.cdRef.detectChanges();
  }

  getSelection() {
    let result: Array<DropdownFlatNode> = [];
    let shouldNotBeSelected: Array<DropdownFlatNode> = [];
    this.selection.selected.forEach((sNode) => {
      const node = this.getTreeControlNodeFromSelectionNode(sNode);
      if (shouldNotBeSelected.includes((node as any)[(this.optionsModel.idKey as string)])) {
        return
      } else {
        result.push((node as any)[(this.optionsModel.idKey as string)]);
        if (node) {
          let descendants = this.treeControl.getDescendants(node).map((n) => (n as any)[(this.optionsModel.idKey as string)]);
          shouldNotBeSelected = [...shouldNotBeSelected, ...descendants];
        }
      }
    });
    return result.filter(r => !shouldNotBeSelected.includes(r))
  }

  getTreeControlNodeFromSelectionNode(selectionNode: DropdownFlatNode) {
    return this.treeControl.dataNodes.find(n => (n as any)[(this.optionsModel.idKey as string)] === (selectionNode as any)[(this.optionsModel.idKey as string)]);
  }

  getInitialNodes(initialIds: Array<any>) {
    let selectedNodes: Array<DropdownFlatNode> = []
    let initialNodes = this.treeControl.dataNodes.filter(node => initialIds.includes((node as any)[(this.optionsModel.idKey as string)]));
    initialNodes.forEach(node => {
      selectedNodes = [...selectedNodes, ...this.treeControl.getDescendants(node)];
    });
    return [...selectedNodes, ...initialNodes];
  }

  treeControl: FlatTreeControl<DropdownFlatNode> = new FlatTreeControl<DropdownFlatNode>(
    node => node.level, node => node.expandable);

  hasChild = (node: DropdownFlatNode) => node.expandable;

  getParentNode(node: DropdownFlatNode) {
    const nodeIndex = this.treeData.indexOf(node);

    for (let i = nodeIndex - 1; i >= 0; i--) {
      if (this.treeData[i].level === node.level - 1) {
        return this.treeData[i];
      }
    }
    return null;
  }

  shouldRender(node: DropdownFlatNode) {
    let parent = this.getParentNode(node);
    if (this.searchPhrase && this.searchPhrase.length > 0) {
      if (this.isAnyChildMatchingPhrase(node, this.searchPhrase)) {
        return true;
      }
      if (this.isParentNodeMatchingPhraseAndIsExpandedOrSelected(node, this.searchPhrase)) {
        return true;
      }
      return (node as any)[(this.optionsModel.nameKey as string)].toLowerCase().includes(this.searchPhrase.trim().toLowerCase());
    } else {
      parent = this.getParentNode(node);
      while (parent) {
        if (!this.treeControl.isExpanded(parent)) {
          return false;
        }
        parent = this.getParentNode(parent);
      }
      return true;
    }
  }

  isAnyChildMatchingPhrase(node: DropdownFlatNode, phrase: string) {
    if (!phrase) return false;
    let trimmedPhrase = phrase.trim().toLowerCase();

    return this.treeControl.getDescendants(node).some(n => (n as any)[(this.optionsModel.nameKey as string)].toLowerCase().includes(trimmedPhrase))
  }

  isParentNodeMatchingPhraseAndIsExpandedOrSelected(node: DropdownFlatNode, phrase: string) {
    if (!phrase) return false;
    let trimmedPhrase = phrase.trim().toLowerCase();
    let parent = this.getParentNode(node);
    return !(node as any)[(this.optionsModel.nameKey as string)].toLowerCase().includes(trimmedPhrase) && ((parent && (parent as any)[(this.optionsModel.nameKey as string)].toLowerCase().includes(trimmedPhrase) && this.treeControl.isExpanded(parent)) || (parent && (parent as any)[(this.optionsModel.nameKey as string)].toLowerCase().includes(trimmedPhrase) && this.selection.isSelected(parent)))
  }

  areAllChildsSelected(node: DropdownFlatNode) {
    let childNodes = this.treeControl.getDescendants(node);
    return childNodes.every(node => this.selection.selected.includes(node));
  }

  isAnyChildSelected(node: DropdownFlatNode) {
    let childNodes = this.treeControl.getDescendants(node);
    return childNodes.some(node => this.selection.selected.includes(node));
  }

  toggleNodeChckbx(node: DropdownFlatNode) {
    this.selection.toggle(node);
    this.selection.isSelected(node) ?
      this.treeControl.getDescendants(node).forEach(node => this.selection.select(node)) :
      this.treeControl.getDescendants(node).forEach(node => this.selection.deselect(node));

    let parentNode = this.getParentNode(node);
    if (parentNode) this.updateParent(parentNode);
  }

  updateParent(node: DropdownFlatNode) {
    if (this.selectParentWhenAllSelected) {
      this.areAllChildsSelected(node) ?
        this.selection.select(node) :
        this.selection.deselect(node);
    } else {
      if (!this.areAllChildsSelected(node)) {
        this.selection.deselect(node);
      }
    }
    let parentNode = this.getParentNode(node);
    if (parentNode) this.updateParent(parentNode);
  }

  trackByFn(index: number, node: DropdownFlatNode) {
    return index;
  }

  select(node: DropdownFlatNode) {
    this.setActiveItem(node);
    this.selection.select(node);
    // this.onSelectionChange([node[this.optionsModel.idKey]]);

    if (this.closeAfterSelect) {
      if (this.opened === true) {
        this.onTouch();
      }
      this.opened = false;
      this.withOpenChange.emit(this.opened);
    }
  }

  searchChanged(event) {
    this.searchDropdownForm.controls.search.setValue(event);
  }

  constructor(
    private fb: FormBuilder,
    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer,
    private cdRef: ChangeDetectorRef,
  ) {
    this.onChange = (_: any): void => {
    };
    this.onTouch = (): void => {
    };
  }

}
