import { Pagination } from '@app/core/models';
import { StatisticItemModel } from '@app/newsletter/models';
import { createAction, props } from '@ngrx/store';
import { SortDirection } from '@rds/angular-components';

export const initStatistics = createAction(
  '[NEWSLETTER - STATISTICS] Initialize News',
  props<{id: number}>()
);

export const clearAll = createAction(
  '[NEWSLETTER - STATISTICS] Clear all'
);

export const getStatisticsRequest = createAction(
  '[NEWSLETTER - STATISTICS] Get statistics Request',
  props<{
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
  }>()
);

export const getStatisticsSuccess = createAction(
  '[NEWSLETTER - STATISTICS] Get statistics Success',
  props<{ data: Array<Partial<StatisticItemModel>>, pagination: Pagination }>()
);

export const getStatisticsFailure = createAction(
  '[NEWSLETTER - STATISTICS] Get statistics Failure',
  props<{ error: any }>()
);

export const changeStatisticsSort = createAction(
  '[NEWSLETTER - STATISTICS] Change statistics sort',
  props<{ active: string, direction: SortDirection }>()
);

export const changeStatisticsPagination = createAction(
  '[NEWSLETTER - STATISTICS] Change statistics pagination',
  props<{ pageIndex: number, pageSize: number }>()
);
