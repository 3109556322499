<rds-form-field *ngIf="disabled === false" [formGroup]="form">
    <rds-control-label>
        <span>{{label}}</span>
    </rds-control-label>
    <rds-icon rds-control-prefix namespace="outlined" icon="search"></rds-icon>
    <rds-autocomplete 
        #autocompleteRef
        [displayProperty]="displayKey"
        formControlName="item"
        placeholder=" "
        [options]="fetchOptions"
        (keyup.enter)="enter(autocompleteRef.getInputFilledValue())"
        [optionTemplate]="itemTemplate ? optionTemplate : null"
    />
    <ng-template #optionTemplate let-autocomplete let-option="option" let-keyboardFocusedItem="keyboardFocusedItem">
        <button
            [rdsAutocompleteOption]="autocomplete"
            [keyboardFocusedItem]="keyboardFocusedItem"
            class="rds-list-item rds-list-item--l rds-single-select-list-item"
            (click)="autocomplete.toggle(option)"
        >
            <ng-template
				[ngTemplateOutlet]="itemTemplate"
				[ngTemplateOutletContext]="{
                    option: option,
                    highlightPhrase: autocomplete.getSearchQuery(),
                    disabled: disabled
                    }"
			></ng-template>
        </button>
    </ng-template>
  </rds-form-field>
  <div *ngIf="!selectedTemplate" class="selected">
    <rds-chip-list size="s" type="filter">
        <rds-chip *ngIf="selectedOptions.length === 0" [disabled]="true">{{chipPlaceholder}}</rds-chip>
        <rds-chip-selectable *ngFor="let selected of selectedOptions; let index = index;"
                             [closable]="!control.disabled ? true : null"
                             (closeChip)="remove(index)" (click)="details.emit(selected)">
            {{displayKey ? selected[displayKey] : selected}}
        </rds-chip-selectable>
    </rds-chip-list>
</div>
<ng-template
[ngTemplateOutlet]="selectedTemplate"
[ngTemplateOutletContext]="{
    selectedOptions: selectedOptions,
    removeFn: remove.bind(this),
    checkRemovePrevented: isPreventedFromRemoving.bind(this),
    disabled: disabled 
}"
></ng-template>
<rh-subscript-wrapper *ngIf="!!control" [hasError]="!control.valid && control.touched" [disabled]="disabled">
    <ng-content select="rds-control-hint" ngProjectAs="rds-control-hint"></ng-content>
    <ng-content select="rds-control-error" ngProjectAs="rds-control-error"></ng-content>
</rh-subscript-wrapper>
<ng-content></ng-content>