import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { RdsDialogService } from '@rds/angular-components';
import { NewsletterService } from '@app/core/services/newsletter.service';
import { combineLatest, filter, map, mergeMap, Observable, of, switchMap, withLatestFrom } from 'rxjs';
import * as fromRouter from '@app/root-store/router/router.actions';
import * as fromActions from '@app/newsletter-new/store/recipients-form/recipients-form.actions';
import * as fromSelectors from '@app/newsletter-new/store/recipients-form/recipients-form.selectors';
import * as fromReducer from './recipients-form.reducer';

import * as fromNewsletter from '../newsletter-form';
import * as fromRecipientsTable from '../recipients-table';
import {
	EMPTY_RECIPIENTS_LIST_FORM,
	RECIPIENTS_LIST_TYPE,
	RecipientsListSteps,
	RhRecipientsListForm,
	RhRecipientsListRequestBody,
	TYPE_RECIPIENT,
} from '@app/newsletter-new/models/recipients-list';
import { ConfirmDialogComponent, ConfirmDialogData } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import * as fromBackButton from '@app/root-store/ui/back-button';
import {
	ImportDialogRecipientsComponent,
	ImportRecipientsDialogData,
} from '@shared/dialogs/import-dialog-recipients/import-dialog-recipients.component';
import { RadaPreviewDialogComponent } from '@shared/dialogs/previews/rada-preview-dialog/rada-preview-dialog.component';
import { SelectDialogComponent } from '@shared/dialogs/select-dialog/select-dialog.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormStepStatus } from '@shared/form-status-badge/form-status-badge.component';
import { catchError, tap } from 'rxjs/operators';
import {
	RecipientsListConfirmDialogComponent,
	RecipientsListDialogData,
} from '@app/newsletter-new/dialogs/recipients-list-confirm-dialog/recipients-list-confirm-dialog.component';
import { createGuid } from '@app/shared/utils/guid';
import { CustomValidators } from '@app/shared/form-controls/validators/validator.function';
import * as fromRecipients from '@app/newsletter-new/store/recipients-form/index';

@Injectable()
export class RecipientsFormEffects {
	public initForm$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.form.init),
				map((): Partial<RhRecipientsListForm> => {
					return {
						...EMPTY_RECIPIENTS_LIST_FORM,
					};
				}),
				mergeMap((form) => [
					fromActions.form.setValue({ form: { ...form } }),
					fromActions.form.setInitialValue({ form: { ...form } }),
					fromActions.form.setChecked(),
					fromRecipientsTable.selectionList.request(),
				])
			),
		{ dispatch: true }
	);

	public getByIdForm$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.form.getById),
				switchMap(({ id }) =>
					this.newsletterService.getRecipientsList(id).pipe(
						mergeMap((recipientsListResult) => {
							const recipientUsers = recipientsListResult.recipients
								.filter((item) => item.type === TYPE_RECIPIENT.SINGLE)
								.map((user) => ({
									...user,
									id: createGuid(),
								}));
							const recipientRada = recipientsListResult.recipients.filter(
								(item) => item.type === TYPE_RECIPIENT.GROUP
							);
							const blockUsers = recipientsListResult.blockedRecipients
								.filter((item) => item.type === TYPE_RECIPIENT.SINGLE)
								.map((user) => ({
									...user,
									id: createGuid(),
								}));
							const blockRada = recipientsListResult.blockedRecipients.filter(
								(item) => item.type === TYPE_RECIPIENT.GROUP
							);

							const general: FormGroup = new FormGroup({
								id: new FormControl(recipientsListResult.id, [Validators.required]),
								description: new FormControl(recipientsListResult.description),
							});
							const recipientsList: FormGroup = new FormGroup(
								{
									recipientUsers: new FormControl(recipientUsers),
									recipientRada: new FormControl(recipientRada),
								},
								[CustomValidators.anyControlNotEmpty]
							);
							const blockList: FormGroup = new FormGroup({
								blockUsers: new FormControl(blockUsers),
								blockRada: new FormControl(blockRada),
							});
							general.markAllAsTouched();
							recipientsList.markAllAsTouched();
							blockList.markAllAsTouched();

							return [
								fromActions.form.setStepIsValid({
									step: RecipientsListSteps.GENERAL_INFORMATION,
									status: general.status,
								}),
								fromActions.form.setStepIsValid({
									step: RecipientsListSteps.RECIPIENTS_LIST,
									status: recipientsList.status,
								}),
								fromActions.form.setStepIsValid({ step: RecipientsListSteps.BLOCK_USERS, status: blockList.status }),
								fromActions.form.setStepStatus({
									step: RecipientsListSteps.GENERAL_INFORMATION,
									status: general.valid ? FormStepStatus.COMPLETED : FormStepStatus.INCOMPLETE,
								}),
								fromActions.form.setStepStatus({
									step: RecipientsListSteps.RECIPIENTS_LIST,
									status: recipientsList.valid ? FormStepStatus.COMPLETED : FormStepStatus.INCOMPLETE,
								}),
								fromActions.form.setStepStatus({
									step: RecipientsListSteps.BLOCK_USERS,
									status: blockList.valid ? FormStepStatus.COMPLETED : FormStepStatus.INCOMPLETE,
								}),

								fromActions.form.setValue({
									form: {
										...recipientsListResult,
										recipientUsers,
										recipientRada,
										blockUsers,
										blockRada,
									},
								}),
								fromActions.form.setInitialValue({
									form: {
										...recipientsListResult,
										recipientUsers,
										recipientRada,
										blockUsers,
										blockRada,
									},
								}),
								fromActions.form.setChecked(),
								fromRecipientsTable.selectionList.request(),
							];
						})
					)
				)
			),
		{ dispatch: true }
	);

	public updateFormRecipientList$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.form.setValue),
				filter(({ form }) => !!form.recipientUsers),
				switchMap(({ form }) => [
					fromActions.form.updateFormRecipientList({
						recipientUsers: form.recipientUsers,
					}),
				])
			),
		{ dispatch: true }
	);

	public updateFormBlockList$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.form.setValue),
				filter(({ form }) => !!form.blockUsers),
				switchMap(({ form }) => [
					fromActions.form.updateFormBlockList({
						blockUsers: form.blockUsers,
					}),
				])
			),
		{ dispatch: true }
	);
	public updateGeneralList$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.form.setValue),
				switchMap(({ form }) => [fromActions.form.updateGeneral()])
			),
		{ dispatch: true }
	);

	public create$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.form.create),
				withLatestFrom(this.store$.pipe(select(fromSelectors.selectRecipientsForm))),
				switchMap(([{}, form]) =>
					this.newsletterService.createRecipientsList(form).pipe(
						map(() => fromActions.form.createSuccess({ form })),
						catchError(({ message }) => of(fromActions.form.createFailure({ error: message })))
					)
				)
			),
		{ dispatch: true }
	);

	public createSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.form.createSuccess),
				switchMap(() => [fromRouter.go({ path: '/newsletter', queryParams: {} })])
			),
		{ dispatch: true }
	);

	public openLeavePageDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.dialog.openLeavePageDialog),
				withLatestFrom(this.store$.pipe(select(fromSelectors.selectChangesMade))),
				filter(([{ url }, changesMade]) => !!changesMade),
				map(([{ url }, changesMade]) => {
					const data: ConfirmDialogData = {
						ids: [],
						messages: [],
						title: 'Do you want to discard changes?',
						confirmButtonLabel: 'Yes, discard',
						confirmButtonType: 'primary',
					};
					const dialog = this.dialogService.open(ConfirmDialogComponent, {
						data,
					});
					return { dialog, url };
				}),
				switchMap(({ dialog, url }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((data: ConfirmDialogData) => {
							return { url };
						})
					)
				),
				map(({ url }) => fromBackButton.back({ defaultLabel: '', defaultRoute: url }))
			),
		{ dispatch: true }
	);

	public openImportDialog = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.dialog.openImportDialog),
				filter(({ source }) => source === 'file'),

				map(({ destination, otherStore }) => {
					let data: ImportRecipientsDialogData = {
						title: 'Import from a file',
						options:
							destination === RECIPIENTS_LIST_TYPE.RECIPIENT
								? ['email', 'name', 'surname']
								: ['email', 'name', 'surname'],
						required: destination === RECIPIENTS_LIST_TYPE.RECIPIENT ? ['email'] : ['email'],
						confirmMappingInformation: 'Confirm each mapping choice for: Email, First and Last Name',
					} as ImportRecipientsDialogData;

					const dialog = this.dialogService.open(ImportDialogRecipientsComponent, {
						size: 'l',
						data,
					});
					return { dialog, destination, otherStore };
				}),
				switchMap(({ dialog, destination, otherStore }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						switchMap((data) => [fromActions.form.add({ destination, results: data, source: 'file', otherStore })])
					)
				)
			),
		{ dispatch: true }
	);

	public openRecipientsConfirmDialog = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.dialog.openConfirmCreate),
				withLatestFrom(
					this.store$.pipe(select(fromSelectors.selectRecipientsForm)),
					this.store$.pipe(select(fromSelectors.selectExcludedNumbers))
				),
				map(([{}, form, numbers]) => {
					let data: RecipientsListDialogData;
					data = {
						title: form.id === null ? 'Create recipients list' : 'Edit recipients list',
						form: form,
						confirmButtonLabel: form.id === null ? 'Yes, create recipients list' : 'Yes, save changes',
						...numbers,
					};

					const dialog = this.dialogService.open(RecipientsListConfirmDialogComponent, {
						size: 'l',
						data,
					});
					return { dialog };
				}),
				switchMap(({ dialog }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map(() => fromActions.form.create())
					)
				)
			),
		{ dispatch: true }
	);

	public openImportDialogSelect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.dialog.openImportDialog),
				filter(({ source }) => source === 'select'),

				map(({ destination, otherStore }) => {
					this.store$.dispatch(fromActions.importExistingList.request());
					const data = {
						// title: 'Import from Recipient list',
						selects: [
							{
								selectType: 'select',
								prop: 'id',
								optionsObs: this.store$.pipe(select(fromSelectors.selectSelectableRecipientLists)),
								multiple: false,
								required: true,
								entityType: 'recipients-list',
								tooltipInfo: null,
								label: 'Import from Recipient list',
							},
						],
						confirmButtonLabel: 'Import',
					};
					const dialog = this.dialogService.open(SelectDialogComponent, {
						size: 'l',
						data,
					});
					return { dialog, destination, otherStore };
				}),
				switchMap(({ dialog, destination, otherStore }) =>
					dialog.afterClosed().pipe(
						tap(() => this.store$.dispatch(fromActions.importExistingList.clear())),
						filter((data) => !!data),
						mergeMap(
							(data): Observable<Array<Partial<RhRecipientsListRequestBody>>> =>
								combineLatest(
									(data.selects[0].value as Array<number>).map((listId) =>
										this.newsletterService.getRecipientsList(listId)
									)
								)
						),
						switchMap((results) => [fromActions.form.add({ results, destination, otherStore, source: 'select' })])
					)
				)
			),
		{ dispatch: true }
	);

	public add = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.form.add),

				switchMap(({ destination, otherStore, source, results }) => {
					if (otherStore) {
						if (source === 'file') {
							if (otherStore === 'newsletterForm') {
								return [fromNewsletter.form.addFromFile({ destination, results })];
							}
						}
						if (source === 'select') {
							if (otherStore === 'newsletterForm') {
								return [fromNewsletter.form.addFromSelect({ results, destination })];
							}
						}
						return [];
					} else {
						if (source === 'file') {
							return [fromActions.form.addFromFile({ destination, results })];
						}
						if (source === 'select') {
							return [fromActions.form.addFromSelect({ results, destination })];
						}
					}
					return [];
				})
			),
		{ dispatch: true }
	);

	public openDetailsRada = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.dialog.openDetailsRada),
				switchMap(({ email }) => this.newsletterService.getRada(email)),
				map((results) => {
					let component = RadaPreviewDialogComponent;
					const dialog = this.dialogService.open(component, {
						size: 'l',
						data: {
							item: results,
						},
					});
					return { dialog };
				}),
				switchMap(({ dialog }) => dialog.afterClosed().pipe(filter((data) => !!data)))
			),
		{ dispatch: false }
	);

	public closeWithoutDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.dialog.openLeavePageDialog),
				withLatestFrom(this.store$.pipe(select(fromSelectors.selectChangesMade))),
				filter(([{ url }, changesMade]) => !changesMade),
				map(([{ url }]) => fromBackButton.back({ defaultLabel: '', defaultRoute: url }))
			),
		{ dispatch: true }
	);

	public setInitialValues$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.importFile.setInitialValues),
				switchMap(({ initialValues }) => {
					return [
						fromActions.importFile.calculateValues(),
						fromRecipients.importFile.setInvalidFile({ invalidFile: initialValues?.length ? false : true }),
					];
				})
			),
		{ dispatch: true }
	);

	public setExcludedFirstRow$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.importFile.setExcludedFirstRow),
				switchMap(() => {
					return [fromActions.importFile.calculateValues(), fromActions.importFile.setMappedData()];
				})
			),
		{ dispatch: true }
	);
	public setMappingForm$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.importFile.setMappingForm),
				switchMap(() => {
					return [fromActions.importFile.setMappedData()];
				})
			),
		{ dispatch: true }
	);

	public setMappedData$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.importFile.setMappedData),
				withLatestFrom(
					this.store$.pipe(select(fromSelectors.selectMappingForm)),
					this.store$.pipe(select(fromSelectors.selectInitialValues)),
					this.store$.pipe(select(fromSelectors.selectExcludedFirstRow))
				),
				switchMap(([action, mappingForm, initialValues, excludedFirstRow]) => {
					let mappedData = [];
					let startIndex = excludedFirstRow ? 1 : 0;
					for (startIndex; startIndex < initialValues.length; startIndex++) {
						// mappedData.push(this.createRow(startIndex, mappingForm));
						let row = {};
						for (let col in mappingForm) {
							if (mappingForm[col]) {
								row[mappingForm[col]] = initialValues[startIndex][col];
							}
						}
						mappedData.push(row);
					}

					return [fromRecipients.importFile.updateMappedData({ mappedData })];
				})
			),
		{ dispatch: true }
	);

	public calculateValues$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.importFile.calculateValues),
				withLatestFrom(
					this.store$.pipe(select(fromSelectors.selectInitialValues)),
					this.store$.pipe(select(fromSelectors.selectExcludedFirstRow))
				),
				switchMap(([action, initialValues, excludeFirstRow]) => {
					let newObj = {};
					let columnWithEmails = null;
					initialValues.forEach(
						(
							row: {
								[key: string]: any;
							}[],
							index
						) => {
							for (let col in row) {
								if (!newObj[col]) {
									newObj[col] = {
										percentEmails: null,
										percentValues: null,
										values: [],
										emailsIcon: null,
										emailsClass: null,
										valuesIcon: null,
										valuesClass: null,
									};
								}
								newObj[col].values = [...newObj[col].values, row[col]];

								let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
								let emailsInColumn = newObj[col].values.filter((text) => text.toString().match(emailRegex));
								let emailsPercent = emailsInColumn.length / initialValues.length;
								let valuesPercent = newObj[col].values.length / initialValues.length;

								if (excludeFirstRow) {
									emailsInColumn = newObj[col].values
										.slice(1, newObj[col].values.length)
										.filter((text) => text.toString().match(emailRegex));
									emailsPercent = emailsInColumn.length / (initialValues.length - 1);
									valuesPercent =
										newObj[col].values.slice(1, newObj[col].values.length).length / (initialValues.length - 1);
								}

								newObj[col].percentEmails = emailsPercent;
								newObj[col].percentValues = valuesPercent;
								newObj[col].emailsIcon = 'email';
								newObj[col].emailsClass =
									emailsPercent > 0.8 ? (emailsPercent > 0.9 ? 'c-success' : 'c-warning') : 'c-error';
								newObj[col].valuesClass =
									valuesPercent > 0.8 ? (valuesPercent > 0.9 ? 'c-success' : 'c-warning') : 'c-error';
								newObj[col].valuesIcon = valuesPercent > 0.9 ? 'checkmark_circle' : 'warning_triangle';
								if (newObj[col].percentEmails > 0.8) {
									if (columnWithEmails && columnWithEmails !== col) {
										columnWithEmails = null;
									} else {
										columnWithEmails = col;
									}
								}
							}
						}
					);

					return [
						fromActions.importFile.setDataToMapping({
							groupedByCol: newObj,
							rows: initialValues.length,
							columnWithEmails,
						}),
					];
				})
			),
		{ dispatch: true }
	);

	public importExistingList$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.importExistingList.request),
				switchMap(() =>
					this.newsletterService.getAllRecipientsLists().pipe(
						map((lists) => fromActions.importExistingList.success({ lists })),
						catchError((error) => of(fromActions.importExistingList.failure({ error })))
					)
				)
			),
		{ dispatch: true }
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromReducer.State>,
		private newsletterService: NewsletterService,
		private dialogService: RdsDialogService
	) {}
}
