import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RhEvent, RhEventType} from '@app/events/models/event';
import {zonedTimeToUtc} from 'date-fns-tz';

@Component({
  selector: 'rh-event-card-item',
  templateUrl: './event-card-item.component.html',
  styleUrls: ['./event-card-item.component.scss']
})
export class EventCardItemComponent {
  @Input() event!: RhEvent;
  @Output() eventOpened: EventEmitter<{ id: number }> = new EventEmitter();
  @Output() addedToGcal: EventEmitter<RhEvent> = new EventEmitter();
  @Input() forceHoverState: boolean = false;
  @Input() disableAnimation: boolean = false;
  @Input() dateInEventTimeZone: boolean = false;

  hovered = false;
  RhEventType = RhEventType;

  get eventTime() {
    if (this.dateInEventTimeZone) {
      return this.event.startDate || this.event.date;
    }
    return zonedTimeToUtc(this.event.startDate || this.event.date, this.event.timeZone);
  }

  constructor() {
  }

  changeHover(value: boolean) {
    this.hovered = value;
  }

  openEvent(id, ev) {
    this.eventOpened.emit({id: this.event.id});
    ev.stopPropagation();
  }

  addEventToGcal(ev) {
    this.addedToGcal.emit(this.event);
    ev.stopPropagation();
  }
}
