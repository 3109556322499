import { createAction, props } from '@ngrx/store';
import { UserWorkdayOrganisation } from '@app/core/models/user-workday.model';

export const getUserOrganisationByLoginRequest = createAction(
  '[WORKDAY USER - GET USER ORGANISATION BY LOGIN REQUEST] Get User Organisation by login Request',
  props<{login: string}>()
);

export const getUserOrganisationByLoginSuccess = createAction(
  '[WORKDAY USER - GET USER ORGANISATION BY LOGIN SUCCESS] Get User Organisation by login Success',
  props<{organisation: UserWorkdayOrganisation}>()
);

export const getUserOrganisationByLoginFailure = createAction(
  '[WORKDAY USER - GET USER ORGANISATION BY LOGIN FAILURE] Get User Organisation by login Failure',
  props<{error: any}>()
);

export const goToWorkdayProfile = createAction(
  '[WORKDAY USER - GO TO WORKDAY PROFILE] Go to workday global-settings',
);

export const goToUser = createAction(
  '[WORKDAY USER - GO TO USER] Navigate to User',
  props<{ path: string }>()
);

export const clearUserOrganisation = createAction(
  '[WORKDAY USER - CLEAR USER ORGANISATION] Clear user organisation',
);
