import { Injectable } from '@angular/core';
import { NewsboardAuthApiService } from '@app/core/auth/services/newsboard-auth-api.service';
import { ContentService } from '@app/core/services/content.service';
import { TaxonomyService } from '@app/core/services/taxonomy.service';
import { UserProfileService } from '@app/core/services/userProfile.service';
import * as fromUserChannels from '@core/core-store/user-channels';
import { WorkdayService } from '@core/services/workday.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { RdsDialogService } from '@rds/angular-components';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap, take } from 'rxjs/operators';
import * as fromAuthActions from '../../auth/store/auth.actions';
import { UserService } from '../services/user.service';
import * as fromUserActions from './user.actions';
import * as fromUser from './user.reducer';
import * as fromUserSelectors from './user.selectors';

@Injectable()
export class UserEffects {

  public authCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.authCompleted),
      map(() => fromUserActions.getUserInfo())
    )
  );

  public getUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.getUserInfo),
      mergeMap(() => this.userService.getUserInfo().pipe(
        map(userProfile => fromUserActions.getUserInfoSuccess({userProfile})),
        catchError(error => of(fromUserActions.getUserInfoFailure({error})))
      ))
    )
  );

//   public getUserInfoSuccess$ = createEffect(() =>
//   this.actions$.pipe(
//     ofType(fromUserActions.getUserInfoSuccess),
//     filter(({userProfile}) => !userProfile.wasWelcomePageDisplayed),
//     tap(({userProfile}) => {
//       this.dialogService.open(HelloDialogContainer, {
//         data: {
//           firstName: userProfile.firstName
//         },
//         panelClass: 'overlay-dialog'
//     })
//     }

//   )
//   ), {dispatch: false}
// );

  public getUserInfoCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.getUserInfoSuccess, fromUserActions.getUserInfoLoaded),
      mergeMap(({userProfile}) => [
        fromUserActions.getUserInfoCompleted(),
        fromUserActions.getUserDetails({login: userProfile.login}),
        fromUserActions.getUserChannels(),
        fromUserActions.getUserRoles(),
        fromUserActions.getNewsBoardUserRequest(),
        fromUserActions.getUserManagedTaxonomies(),
        fromUserActions.getUserPersonalizationPreferences()
      ])
    )
  );

  public getUserDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.getUserDetails),
      mergeMap(({login}) =>
        this.workdayService.getUserByLogin(login).pipe(
          map(user => fromUserActions.getUserDetailsSuccess({user})),
          catchError(() => of(fromUserActions.getUserDetailsFailure()))
        )
      )
    ), {dispatch: true}
  );

  public getUserChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.getUserChannels),
      exhaustMap(() => this.contentService.getUserChannels().pipe(
        mergeMap((channels) => [
          fromUserActions.getUserChannelsSuccess({channels}),
          fromUserChannels.userChannelsCollectionUpsertAll({channels})
        ]),
        catchError(({message}) => of(fromUserActions.getUserChannelsError({error: message})))
      ))
    ), {dispatch: true}
  );

  public getUserRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.getUserRoles),
      switchMap(() => this.store$.pipe(
        select(fromUserSelectors.selectLogin),
        take(1),
      )),
      mergeMap((login) => this.userService.getUserRoles(login).pipe(
        map(userRoles => fromUserActions.getUserRolesSuccess({roles: userRoles})),
        catchError(({message}) => of(fromUserActions.getUserRolesError({error: message})))
      ))
    )
  );

  public getNewsBoardUserRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromUserActions.getNewsBoardUserRequest),
    switchMap(() => this.store$.pipe(
      select(fromUserSelectors.selectLogin),
      take(1),
    )),
    mergeMap((login) => this.newsboardAuthService.getUser().pipe(
      map((user) => fromUserActions.getNewsBoardUserSuccess({user})),
      catchError(({message}) => of(fromUserActions.getNewsBoardUserError({error: message})))
    ))
  )
);

  public getUserManagedTaxonomiesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.getUserManagedTaxonomies),
      switchMap(() => this.store$.pipe(
        select(fromUserSelectors.selectLogin),
        take(1),
      )),
      mergeMap((login) =>
        this.taxonomiesService.getTaxonomyManagerByName(login).pipe(
          map((managedTaxonomies) => {
            const userTaxonomy = managedTaxonomies.find(t => t.login === login);
            return ({managedTaxonomy: {
              locations: userTaxonomy.locations.map(t => t.id),
              departments: userTaxonomy.departments.map(t => t.id),
              functions: userTaxonomy.functions.map(t => t.id),
            }})
          }),
          map(({managedTaxonomy}) => fromUserActions.getUserManagedTaxonomiesSuccess({ managedTaxonomy })),
          catchError(({ message }) => of(fromUserActions.getUserManagedTaxonomiesError({ error: message })))
        )
      )
    ), { dispatch: true }
  );

  public getUserPersonalizationPreferences$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromUserActions.getUserPersonalizationPreferences),
    mergeMap(() =>
      this.userProfileService.getSearchingPreferences().pipe(
        map((personalizationPreferences) => fromUserActions.getUserPersonalizationPreferencesSuccess({ personalizationPreferences })),
        catchError(error => of(fromUserActions.getUserPersonalizationPreferencesError(error)))
      )
    )
  ), { dispatch: true }
);

  public setUserWasLogged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.setUserWasLogged),
      mergeMap(() => this.userService.setUserWasLogged().pipe(
        map(() => fromUserActions.setUserWasLoggedSuccess()),
        catchError(() => of(fromUserActions.setUserWasLoggedFailure()))
      ))
    )
  );

  public setUserSettingsVisited$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.setUserSettingsVisited),
      mergeMap(() => this.userService.setSettingsVisited().pipe(
        map(() => fromUserActions.setUserSettingsVisitedSuccess()),
        catchError(() => of(fromUserActions.setUserSettingsVisitedFailure()))
      ))
    )
  );

  public setUserQuickLinksVisited$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.setQuickLinksPageVisited),
      mergeMap(() => this.userService.setQuicklinksVisited().pipe(
        map(() => fromUserActions.setQuickLinksPageVisitedSuccess()),
        catchError(() => of(fromUserActions.setQuickLinksPageVisitedFailure()))
      ))
    )
  );

  public setCatalogPageVisited$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.setCatalogPageVisited),
      mergeMap(() => this.userService.setCatalogVisited().pipe(
        map(() => fromUserActions.setCatalogPageVisitedSuccess()),
        catchError(() => of(fromUserActions.setCatalogPageVisitedFailure()))
      ))
    )
  );

  public setSearchPageVisited$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.setSearchPageVisited),
      mergeMap(() => this.userService.setSearchVisited().pipe(
        map(() => fromUserActions.setSearchPageVisitedSuccess()),
        catchError(() => of(fromUserActions.setSearchPageVisitedFailure()))
      ))
    )
  );

  public setMyContentPageVisited$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.setMyContentPageVisited),
      mergeMap(() => this.userService.setMyContentVisited().pipe(
        map(() => fromUserActions.setMyContentPageVisitedSuccess()),
        catchError(() => of(fromUserActions.setMyContentPageVisitedFailure()))
      ))
    )
  );

  public setLayoutSettingsPageVisited$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.setLayoutSettingsPageVisited),
      mergeMap(() => this.userService.setLayoutSettingsVisited().pipe(
        map(() => fromUserActions.setLayoutSettingsPageVisitedSuccess()),
        catchError(() => of(fromUserActions.setLayoutSettingsPageVisitedFailure()))
      ))
    )
  );
  public setMenuVisited$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.setMenuVisited),
      mergeMap(() => this.userService.setMenuVisited().pipe(
        map(() => fromUserActions.setMenuVisitedSuccess()),
        catchError(() => of(fromUserActions.setMenuVisitedFailure()))
      ))
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromUser.State>,
    private contentService: ContentService,
    private userProfileService: UserProfileService,
    private newsboardAuthService: NewsboardAuthApiService,
    private userService: UserService,
    private workdayService: WorkdayService,
    private taxonomiesService: TaxonomyService,
    private dialogService: RdsDialogService
  ) {
  }

}
