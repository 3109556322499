<div #formField>
    <rds-form-field [formGroup]="form" *ngIf="!disabled">
        <rds-control-label [requireAsterisk]="required">
            Type in recipients and click enter
        </rds-control-label>
        <rds-icon rds-control-prefix namespace="outlined" icon="search"></rds-icon>
        <input
                rdsInput
                (focus)="focus()"
                (blur)="blur()"
                (keyup.enter)="form.controls.search.value.length > 2 ? checkEmails(form.controls.search.value) : {}"
                formControlName="search"
        />
    </rds-form-field>
    <ng-container *ngIf="!!autocomplete">
        <div rdsDropdown [disabled]="autocomplete.loading || autocomplete.suggestions.length === 0">
            <button rds-dropdown-toggle-button size="l" class="user__value-container--autocomplete-trigger"
                    [rdsMenuTriggerFor]="menu"
                    #trigger="rdsMenuTrigger"
                    [isOpen]="trigger.menuOpen"
            >Toggle
            </button>
            <rds-menu #menu="rdsMenu" class="user__value-container--autocompletes">
                <ng-container *ngIf="autocomplete.loading">
                    <div [ngStyle]="{'width.px': formField.offsetWidth}"
                         class="user__value-container--autocompletes__row loading">
                        <rds-progress-bar></rds-progress-bar>
                    </div>
                </ng-container>
                <ng-container *ngIf="!autocomplete.loading">
                    <div *ngIf="autocomplete.suggestions.length === 0" [ngStyle]="{'width.px': formField.offsetWidth}"
                         class="user__value-container--autocompletes__row not-found">
            <span>
              User not found
            </span>
                    </div>
                    <div *ngFor="let option of autocomplete.suggestions"
                         (click)="selectedIncludesEmail(option.email) && canRemove(option.email)? remove(option.email) : select(option.email)"
                         [ngStyle]="{'width.px': formField.offsetWidth}"
                         class="user__value-container--autocompletes__row"
                         [class.is-selected]="selectedIncludesEmail(option.email)"
                         [class.disabled]="!canRemove(option.email)">
                        <rh-user-single [user]="option"></rh-user-single>
                        <span
                                [innerHTML]="(option.name || option.firstName + ' ' + option.lastName) + ' (' + option.email + ')' | highlight:form.controls.search.value"></span>
                        <rds-icon *ngIf="!selectedIncludesEmail(option.email)" namespace="filled"
                                  icon="plus"></rds-icon>
                        <rds-icon *ngIf="selectedIncludesEmail(option.email)" namespace="filled"
                                  icon="checkmark"></rds-icon>
                    </div>
                </ng-container>
            </rds-menu>
        </div>
    </ng-container>
    <div class="selected-emails">
        <rds-chip-list size="s" type="filter">
            <rds-chip *ngIf="selectedEmailsArray.length === 0" [disabled]="true">Added recipients will appear here
            </rds-chip>
            <rds-chip *ngFor="let selected of selectedEmailsArray;let index = index" rdsChipInput
                      [closable]="!disabled ? true : null"
                      (closeChip)="remove(index)">{{selected}}</rds-chip>
        </rds-chip-list>
    </div>
    <rh-subscript-wrapper *ngIf="!!control" [hasError]="!control.valid && control.touched" [disabled]="disabled">
        <rds-control-error *ngIf="control.getError('invalidEmails')">Invalid email address</rds-control-error>
        <ng-content select="rds-control-hint" ngProjectAs="rds-control-hint"></ng-content>
        <ng-content select="rds-control-error" ngProjectAs="rds-control-error"></ng-content>
    </rh-subscript-wrapper>
</div>
