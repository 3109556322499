import { createAction, props } from '@ngrx/store';

import { TaxonomyFlat } from '@app/core/models/taxonomy.model';

export const loadDepartmentsFlat = createAction(
  '[CORE DEPARTMENTS FLAT - LOAD DEPARTMENTS FLAT REQUEST] Load Departments Flat'
);

export const getDepartmentsFlatRequest = createAction(
  '[CORE DEPARTMENTS FLAT - GET DEPARTMENTS FLAT REQUEST] Get Departments Flat Request'
);

export const getDepartmentsFlatSuccess = createAction(
  '[CORE DEPARTMENTS FLAT - GET DEPARTMENTS FLAT SUCCESS] Get Departments Flat Success',
  props<{ departmentsFlat: TaxonomyFlat[] }>()
);

export const getDepartmentsFlatFailure = createAction(
  '[CORE DEPARTMENTS FLAT - GET DEPARTMENTS FLAT FAILURE] Get Departments Flat Failure',
  props<{ error: any }>()
);

export const departmentsFlatCollectionAddMany = createAction(
  '[STORED DEPARTMENTS FLAT - ADD MANY] Departments Flat collection add many.',
  props<{ departmentsFlat: TaxonomyFlat[] }>()
);
