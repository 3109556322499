import { createAction, props } from '@ngrx/store';
import { RhEvent } from '@app/events/models/event';

export const searchByRangeCollectionUpsertOne = createAction(
  '[STORED EVENTS] Search by range collection upsert many.',
  props<{ start: Date, end: Date }>()
);

export const eventsByRangeCollectionUpsertMany = createAction(
  '[STORED EVENTS] Events by range collection upsert many.',
  props<{ start: Date, end: Date, events: Array<Partial<RhEvent>> }>()
);

export const getEventsByRange = createAction(
  '[STORED CHANNELS] Get events by range',
  props<{ start: Date, end: Date }>()
);

export const getEventsByRangeSuccess = createAction(
  '[STORED CHANNELS] Get events by range success',
  props<{ events: Array<Partial<RhEvent>> }>()
);

export const getEventsByRangeFailure = createAction(
  '[STORED CHANNELS] Get events by range error',
  props<{ error: any }>()
);

export const getEventById = createAction(
  '[STORED CHANNELS] Get event by id',
  props<{ id: number }>()
);

export const getEventByIdSuccess = createAction(
  '[STORED CHANNELS] Get event by id success',
  props<{ event: Partial<RhEvent> }>()
);

export const getEventByIdFailure = createAction(
  '[STORED CHANNELS] Get event by id error',
  props<{ error: any }>()
);

export const eventByIdCollectionUpsertOne = createAction(
  '[STORED CHANNELS] Event by id collection upsert one.',
  props<{ event: Partial<RhEvent> }>()
);