import {Action, createFeatureSelector, createReducer, on} from '@ngrx/store';

import * as fromActions from '@app/content-list/store/content-list.actions';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {ContentItem} from '@app/core/models/content-item.model';
import {Pagination} from '@app/core/models/pagination.model';
import {LoadingState} from '@app/search/models/rh';
import {SubscriptionType} from '@home/store/subscriptions';

export const feature = 'list';

export const newsAdapter: EntityAdapter<ContentItem> = createEntityAdapter<ContentItem>({});

export interface subTypeSubscription {
  pagination: Pagination;
}

export interface DataInfo {
  name: string,
  value: string
  isMandatory?: boolean
}

export const DataInfo: DataInfo = {
  name: null,
  value: null,
  isMandatory: false
}

export interface State extends Pagination, DataInfo {
  news: EntityState<ContentItem>,
  subType: SubscriptionType;
  sortType: number
  loading: LoadingState;
}

export const initialState: State = {
  subType: null,
  sortType: +localStorage.getItem('channelSortType') || 0,
  news: newsAdapter.getInitialState(),
  ...Pagination,
  ...DataInfo,
  pageSize: 12,
  loading: LoadingState.LOADING_ALL,
};

export const channelsSectionReducer = createReducer(
  initialState,
  on(fromActions.setDataInfo, (state, {value, subType, name, isMandatory}) => ({
    ...state,
    subType,
    value,
    name,
    isMandatory
  })),
  on(fromActions.setLocalGlobalDataInfo, (state, {subType, name}) => ({
    ...state,
    subType: subType as SubscriptionType,
    name,
  })),
  on(fromActions.init, (state,) => ({
    ...state,
    ...Pagination,
    pageSize: 12,
    news: newsAdapter.getInitialState(),
    loading: LoadingState.LOADING_ALL
  })),
  on(fromActions.clearStore, (state,) => ({
    ...initialState
  })),
  on(fromActions.loadMoreItems, (state,) => ({
    ...state,
    loading: LoadingState.LOADING_MORE
  })),
  on(fromActions.addBookmarkSuccess, (state, {id}) => ({
    ...state,
    news: newsAdapter.updateOne({id: id, changes: {hasBookmarked: true}}, state.news),
  })),
  on(fromActions.removeBookmarkSuccess, (state, {id}) => ({
    ...state,
    news: newsAdapter.updateOne({id: id, changes: {hasBookmarked: false}}, state.news),
  })),
  on(fromActions.addLike, (state, {id, likeCount}) => ({
    ...state,
    news: newsAdapter.updateOne({id: id, changes: {hasLiked: true, numLikes: likeCount + 1}}, state.news),
  })),
  on(fromActions.addLikeFailure, (state, {id, likeCount}) => ({
    ...state,
    news: newsAdapter.updateOne({id: id, changes: {hasLiked: false, numLikes: likeCount - 1}}, state.news),
  })),
  on(fromActions.removeLike, (state, {id, likeCount}) => ({
    ...state,
    news: newsAdapter.updateOne({id: id, changes: {hasLiked: false, numLikes: likeCount - 1}}, state.news),
  })),
  on(fromActions.removeLikeFailure, (state, {id, likeCount}) => ({
    ...state,
    news: newsAdapter.updateOne({id: id, changes: {hasLiked: true, numLikes: likeCount + 1}}, state.news),
  })),
  on(fromActions.setSortType, (state, {sortType}) => ({
    ...state,
    sortType
  })),
  on(fromActions.getItemsSuccess, (state, {value, contentItems, pagination}) => {

    return {
      ...state,
      ...pagination,
      value,
      news: newsAdapter.addMany(contentItems, state.news),
      loading: LoadingState.FINISHED,
      pageIndex: pagination.pageIndex + 1
    };
  }),
  // on(fromActions.getContentByIdError, (state, {id, error}) => {
  //   return {
  //     ...state,
  //     [id]: {
  //       ...state[id],
  //       ...STATUS_FAILURE,
  //       error
  //     }
  //   };
  // }),
);

export function reducer(state: State | undefined, action: Action) {
  return channelsSectionReducer(state, action);
}

export const selectState = createFeatureSelector<State>(feature);
export const getSubType = (state: State) => state.subType;
export const getNews = (state: State) => state.news;
export const getValue = (state: State) => state.value;
export const getName = (state: State) => state.name;
export const getIsMandatory = (state: State) => state.isMandatory;
export const getSortType = (state: State) => state.sortType;

// export const getChannelPage = (state: State, {id}) => state[id];
// export const getItemIds = (state: ContentPage) => state.itemIds;
// export const getCurrentIds = (itemIds: string[], pageSize: number, pageIndex: number) => ({
//   itemIds: itemIds.slice((pageIndex) * pageSize, (pageIndex + 1) * pageSize)
// });
//
// export const getCurrentParams =
//   ({itemIds}, pageSize: number, pageIndex: number) => ({itemIds, pageIndex, pageSize});
//
// export const getAllContent =
//   (itemIds, entities: Dictionary<ContentItem>) => compact(map(itemIds, (id => entities[id])));
//
// export const getAllContentCount = (itemIds) => itemIds.length;
//
// export const getNotAsked = (state: ContentPage) => state.notAsked;
export const getLoading = (state) => state.loading;
// export const getSuccess = (state: ContentPage) => state.success;
// export const getFailure = (state: ContentPage) => state.failure;
//
// export const getPageSize = (state: ContentPage) => state.pageSize;
// export const getPageIndex = (state: ContentPage) => state.pageIndex;
export const getIsLast = (state) => state.isLast;
// export const getIsFirst = (state: ContentPage) => state.isFirst;
export const selectAll = newsAdapter.getSelectors().selectAll;
