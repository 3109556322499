import { Injectable, Injector } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Messaging, getToken, onMessage, isSupported } from '@angular/fire/messaging';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import * as fromActions from './notifications.actions';
import * as fromAlert from '@home/store/alert';
import * as fromAuth from '@core/auth/store';
import { MessagingService } from '@app/core/services/messaging.service';
import { BehaviorSubject, from, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AlertRecievedDialogComponent, AlertRecievedDialogData } from '@app/shared/dialogs/previews/alert-recieved-dialog/alert-recieved-dialog.component';
import { RdsDialogService } from '@rds/angular-components';
import { FirebaseApp } from '@angular/fire/app';
import { getMessaging } from 'firebase/messaging';

@Injectable()
export class NotificationsEffects {
  private _messaging: Messaging;
  private readonly _message = new BehaviorSubject<unknown | undefined>(undefined);
  message$ = this._message.asObservable();


  public requestPermission$ = createEffect(() =>
    this.store$.pipe(
      select(fromAuth.selectIsAuthenticated),
      filter(isAuthenticated => isAuthenticated),
      take(1),
      filter((isAuthenticated) => isAuthenticated && !!this._messaging),
      switchMap(() => from(Notification.requestPermission()).pipe(
        map((permission) => permission),
        filter((permission) => permission === 'granted')
      )),
      tap(() => onMessage(this._messaging, this._message)),
      switchMap(() => from(getToken(this._messaging)).pipe(
        switchMap((token) =>
          this.messagignService.registerToken(token).pipe(
            map(() => fromActions.registerTokenSuccess()),
            catchError(() => of(fromActions.registerTokenFailure()))
          ))
      )),
      // switchMap(() => this.angularFireMessaging.messaging.pipe(
      //   tap((_messaging) => {
      //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      //   })
      // )),
    ), {dispatch: true});

  public receiveAlert$ = createEffect(() =>
  this.store$.pipe(
    select(fromAuth.selectIsAuthenticated),
    filter(isAuthenticated => isAuthenticated),
    take(1),
    filter((isAuthenticated) => isAuthenticated && !!this._messaging),
    switchMap(() => from(Notification.requestPermission()).pipe(
      map((permission) => permission),
      filter((permission) => permission === 'granted')
    )),
    switchMap(() => this.message$.pipe(
      filter(payload => !!payload),
      map((payload: any) => {
        switch (payload?.data.typeNotification) {
          case 'alert':
            return fromActions.showAlert({
              alert: {
                payload,
                id: Date.now()
              }
            });
          case 'news':
            return fromActions.showNotification({
              notification: payload
            });
          case 'daily':
            return fromActions.showNotification({
              notification: payload
            });
        }
      })
    )
  ),
  ));

  public showAlert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.showAlert),
      map(({alert}): AlertRecievedDialogData => ({
        alert: {
          id: alert.payload.data.alertId,
          message: alert.payload.data.alertMessage,
          title: alert.payload.data.alertTitle,
          url: alert.payload.data.url
        }
      })),
      switchMap((data) => this.dialogService.open(AlertRecievedDialogComponent, {
        data,
        size: 'm',
        height: 'auto',
        maxHeight: '90%'
      }).afterClosed().pipe(
        filter(confirmed => !!confirmed),
        map(() => fromAlert.markAsReadRequest({id: data.alert.id}))
      ))
    ), { dispatch: true }
  );

  public showNotification$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.showNotification),
    tap(({notification}) => {
      const NotificationOptions = {
        body: notification.data.body,
        data: notification.data,
        icon: '/assets/images/notifications/notification-blue.png'
      };

      navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
        registration.showNotification(notification.data.title, NotificationOptions);
      });
    })
  ), { dispatch: false }
);

  constructor(
    private store$: Store<any>,
    private actions$: Actions,
    private messagignService: MessagingService,
    private dialogService: RdsDialogService,
    private injector: Injector,
    private fireApp: FirebaseApp
  ) {
    isSupported().then((r) => {
      if (r) {
        this._messaging = getMessaging(this.fireApp);
      }
    })
  }
}
