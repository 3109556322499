import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { HeaderMenuComponent } from '@app/layout/header/components/header-menu/header-menu.component';
import { RdsMenuTriggerDirective } from '@rds/angular-components';
import { SubSink } from 'subsink';

@Directive({
  selector: '[rhDynamicMenu]'
})
export class DynamicMenuDirective implements AfterViewInit, OnDestroy {
  private subs: SubSink = new SubSink();
  @Input()
  public rhDynamicMenu: HeaderMenuComponent;
  public openedMenus: Array<HTMLElement> = [];

  get triggerElement(): HTMLElement {
    return this._elementRef.nativeElement;
  }

  @HostListener('mouseenter', ['$event', '$event.target'])
  public onHover(): void {
    this.triggerElement.click();
  }


  @HostListener('document:mousemove', ['$event', '$event.target'])
  public onMove(event: MouseEvent): void {
    if (this.host.menuOpen) {
      const isMouseOverTriggerOrSubmenu = [...this.openedMenus, this.triggerElement].some(element => this.isMouseOver(event, element));
      if (!isMouseOverTriggerOrSubmenu) {
        this.host.closeMenu()
      }
    }
  }

  constructor(private host: RdsMenuTriggerDirective, private _elementRef: ElementRef) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.subs.sink = this.host.menuOpened
      .subscribe(() => {
        this.openedMenus = Array.from(document.querySelectorAll(`.dynamic-menu-${this.rhDynamicMenu.triggerIndex}`))
        this.rhDynamicMenu.submenuToggled.subscribe(res => {
          this.openedMenus = Array.from(document.querySelectorAll(`.dynamic-menu-${this.rhDynamicMenu.triggerIndex}`))
        });
      });
  }

  private isMouseOver(event: MouseEvent, element: HTMLElement): boolean {
    const clientX = Math.ceil(event.clientX);
    const clientY = Math.ceil(event.clientY);
    const bounds = element.getBoundingClientRect();
    const boundsX = Math.ceil(bounds.x);
    const boundsY = Math.ceil(bounds.y);
    const boundsWidth = Math.ceil(bounds.width);
    const boundsHeight = Math.ceil(bounds.height);

    return boundsX <= clientX && clientX <= (boundsX + boundsWidth)
      && boundsY <= clientY && clientY <= (boundsY + boundsHeight);
  }

}

