import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AlertModel} from '@core/models';

@Component({
	selector: 'rh-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
	@Input() alert: AlertModel;
	@Input() length: number;
	@Output() buttonCLick = new EventEmitter();

	constructor() {}
}
