import { createSelector } from '@ngrx/store';

import * as fromCore from '@core/core-store/core.reducer';
import * as fromReducer from './events.reducer';

export const selectState = createSelector(
  fromCore.selectState,
  fromCore.getEvents
);

export const selectSearchByAll = createSelector(
  selectState,
  fromReducer.getSearchByAll
);

export const selectSearchByEntities = createSelector(
  selectState,
  fromReducer.getSearchByEntities
);

export const selectSearchByIds = createSelector(
  selectState,
  fromReducer.getSearchByIds
);

export const selectSearchByTotal = createSelector(
  selectState,
  fromReducer.getSearchByTotal
);

export const selectSearchByEntityById = createSelector(
  selectSearchByEntities,
  fromReducer.getSearchByEntityById
);

export const selectSearchByEntitiesById = createSelector(
  selectSearchByEntities,
  fromReducer.getSearchByEntitiesById
);

export const selectSearchByEntityState = (id: string) => createSelector(
  selectState,
  (state) => {
    return state.entities[id]?.events
  }
);

export const selectEventsAll = (id: string) => createSelector(
  selectSearchByEntityState(id),
  (state) => {
    if (!!state) {
      return fromReducer.getEventsAll(state)
    } else {
      return []
    }
  }
);

export const selectEventsEntities = (id: string) => createSelector(
  selectSearchByEntityState(id),
  fromReducer.getEventsEntities
);

export const selectEventsIds = (id: string) => createSelector(
  selectSearchByEntityState(id),
  fromReducer.getEventsIds
);

export const selectEventsTotal = (id: string) => createSelector(
  selectSearchByEntityState(id),
  fromReducer.getEventsTotal
);

export const selectEventsEntityById = (id: string) => createSelector(
  selectEventsEntities(id),
  fromReducer.getEventsEntityById
);

export const selectEventsEntitiesById = (id: string) => createSelector(
  selectEventsEntities(id),
  fromReducer.getEventsEntitiesById
);
