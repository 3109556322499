import {createAction, props} from '@ngrx/store';
import {TickerFeed} from '@core/models';

export const getFeedRequest = createAction(
  '[TICKER - FEED] get',
  props<{ pageIndex: number }>()
);

export const getFeedSuccess = createAction(
  '[TICKER - FEED] success',
  props<{ data: TickerFeed[] }>()
);

export const getFeedFailure = createAction(
  '[TICKER - FEED] failure',
  props<{ message: string }>()
);

export const removeFeedByIdRequest = createAction(
  '[TICKER - FEED] remove request',
  props<{ id: number }>()
);

export const removeFeedByIdSuccess = createAction(
  '[TICKER - FEED] remove success',
  props<{ id: number }>()
);

export const removeFeedByIdFailure = createAction(
  '[TICKER - FEED] remove failure',
  props<{ message: string }>()
);

