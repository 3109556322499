import Command from '@ckeditor/ckeditor5-core/src/command';

export default class TextBoxBlockBackgroundCommand extends Command {
  execute(options) {
    const model = this.editor.model;

    model.change(writer => {
      const selection = model.document.selection;
      const element = selection.getSelectedElement();
      const elementAttributes = Array.from(element.getAttributes());
      writer.setAttribute('custom-background-class', options?.value || '', element);
      writer.setSelection(element, 'on');
    });
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    const element = selection.getSelectedElement();
    if (element && element.hasAttribute('custom-type') && element.hasAttribute('custom-background-class')) {
      this.value = element.getAttribute('custom-background-class')
    } else {
      this.value = null;
    }
    this.isEnabled = true;
  }
}
