import { Component, Inject } from '@angular/core';
import { ManagedTaxonomy } from '@app/core/models';
import { UserSearch } from '@app/core/models/user-search.model';
import { RDS_DIALOG_DATA } from '@rds/angular-components';

export interface IntranetCoordinatorPreviewDialogData {
  user: UserSearch;
  managedTaxonomies: ManagedTaxonomy;
}

@Component({
  selector: 'rh-intranet-coordinator-preview-dialog',
  templateUrl: './intranet-coordinator-preview-dialog.component.html',
  styleUrls: ['./intranet-coordinator-preview-dialog.component.scss']
})
export class IntranetCoordinatorPreviewDialogComponent {
  user: UserSearch;
  managedTaxonomies: ManagedTaxonomy;

  constructor(@Inject(RDS_DIALOG_DATA) public data: IntranetCoordinatorPreviewDialogData) {
    this.user = data.user;
    this.managedTaxonomies = data.managedTaxonomies;
  }
}
