<rh-list *ngIf="result">
    <div image class="list-image">
        <rds-icon namespace="filled" icon="wifi_on" class="cursor-pointer" (click)="open($event)">
        </rds-icon>
    </div>
    <p
            body-top
            class="ui-label-l-bold text-high-contrast text-truncate">
 <span class="cursor-pointer">
    <rds-badge *ngIf="result" (click)="open($event)" [label]="result.name" [type]="'minimal'"
               [color]="'neutral'" size="m">

    </rds-badge>
 </span>
    </p>
    <p
            body-middle
            class="ui-label-l-bold text-low-contrast  text-truncate cursor-pointer" (click)="open($event)">
    <span [rdsTooltip]="titleTemplate"
          rdsTooltipPosition="top-center"
          [rdsTooltipShowDelay]="500"
          [innerHTML]="result.snippet">
    </span>
    </p>
    <div body-bottom style="display: flex; justify-content: space-between;">
        <p
                [rdsTooltip]="newsCount"
                rdsTooltipPosition="top-center"
                [rdsTooltipShowDelay]="500"
                class="ui-component-text-s text-low-contrast">
            <ng-container *ngIf="result.lastNewsPublishDate">
                Last news: <span
                    class="text-uppercase">{{(result.lastNewsPublishDate | timeAgo) + '|'}}</span>
            </ng-container>
            {{'All news: ' + result.numberOfNews }}
        </p>
        <rds-icon *ngIf="!result.hasSubscribed" class="result__button" icon="add_circle" namespace="outlined"
                  (click)="toggleSubscriptionClick()"
                  rdsTooltip="Subscribe to the channel"
                  [rdsTooltipShowDelay]="300"></rds-icon>
        <rds-icon *ngIf="result.hasSubscribed" class="result__button result__button--inactive" icon="checkmark_circle"
                  (click)="toggleSubscriptionClick()"
                  namespace="filled"
                  rdsTooltip="Unsubscribe from the channel"
                  [rdsTooltipShowDelay]="300"></rds-icon>
    </div>
</rh-list>

<ng-template #titleTemplate>
    <span [innerHTML]="result.name"></span>
</ng-template>
<ng-template #descriptionTemplate>
    <span [innerHTML]="result.snippet"></span>
</ng-template>
<ng-template #newsCount>
    <ng-container *ngIf="result.lastNewsPublishDate">
        Last news: <span
            class="text-uppercase">{{(result.lastNewsPublishDate | timeAgo) + '|'}}</span>
    </ng-container>
    {{'All news: ' + result.numberOfNews}}
</ng-template>
