import { createReducer, Action, on } from '@ngrx/store';

import * as fromActions from './form.actions';
import { EMPTY_USER_ROLE_FORM, UserRoleForm } from '@app/core/models';

export const feature = 'form';

export interface State {

  form: Partial<UserRoleForm>;
  initialForm: Partial<UserRoleForm>;
  touched: boolean
}

export const initialState: State = {
  form: EMPTY_USER_ROLE_FORM,
  initialForm: EMPTY_USER_ROLE_FORM,
  touched: false
};

const addReducer = createReducer(
  initialState,
  on(fromActions.initAddForm, (state, { user, userRole }) => userRole? ({
    ...state,
    form: {
      ...state.form,
      login: user.identifier,
      user: [user],
      roles: {
        isAdministrator: userRole.isAdministrator,
        isContentQualityManager: userRole.isContentQualityManager,
        isAudienceManager: userRole.isAudienceManager,
      },
      audience: {
        locations: userRole.isAudienceManager ? userRole.taxonomies.locations : [],
        departments: userRole.isAudienceManager ? userRole.taxonomies.departments : [],
        functions: userRole.isAudienceManager ? userRole.taxonomies.functions : [],
      },
      userWithoutRoles: false
    },
    initialForm: {
      ...state.form,
      login: user.identifier,
      user: [user],
      roles: {
        isAdministrator: userRole.isAdministrator,
        isAudienceManager: userRole.isAudienceManager,
        isContentQualityManager: userRole.isContentQualityManager
      },
      audience: {
        locations: userRole.isAudienceManager ? userRole.taxonomies.locations : [],
        departments: userRole.isAudienceManager ? userRole.taxonomies.departments : [],
        functions: userRole.isAudienceManager ? userRole.taxonomies.functions : [],
      },
      userWithoutRoles: false
    },
  }) : ({
    ...state,
    form: {
      ...EMPTY_USER_ROLE_FORM,
      login: user.identifier,
      user: [user],
      userWithoutRole: true
    },
    initialForm: {
      ...EMPTY_USER_ROLE_FORM,
      login: user.identifier,
      user: [user],
      userWithoutRole: true
    }
  })),
  on(fromActions.setFormValue, (state, { form }) => ({
    ...state,
    form: {
      ...state.form,
      ...form
    }
  })),
  on(fromActions.setFormTouched, (state, { touched }) => ({
    ...state,
    touched
  })),
  on(fromActions.clearAll, (state) => ({
    ...initialState
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return addReducer(state, action);
}

export const getForm = (state: State) => state.form;
export const getUserWithoutRole = (state: State) => state.form.userWithoutRole;
export const getInitialForm = (state: State) => state.initialForm;
export const getId = (form: Partial<UserRoleForm>) => form.login;
export const getTouched = (state: State) => state.touched;
