import {
	EMPTY_OVERALL_STATS,
	FeedbackStatisticsModel,
	DeliveryStatisticsModel,
} from '@app/core/models/newsletter.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './statistics-all.actions';

export const CHART_COLOR_PALETE_FACTORY = (count: number) => {
	switch (count) {
		case 1:
			return ['#7C0095'];
		case 2:
			return ['#7C0095', '#C684BD'];
		case 3:
			return ['#7C0095', '#AD42C3', '#C684BD'];
		case 4:
			return ['#7C0095', '#AD42C3', '#C684BD', '#DEAABF'];
		case 5:
			return ['#7C0095', '#AD42C3', '#AD63BF', '#C684BD', '#DEAABF'];
		case 6:
			return ['#41004E', '#7C0095', '#AD42C3', '#AD63BF', '#C684BD', '#DEAABF'];
		case 7:
			return ['#41004E', '#7C0095', '#AD42C3', '#AD63BF', '#C684BD', '#DEAABF', '#FAD6C7'];
		case 8:
			return ['#41004E', '#7C0095', '#AD42C3', '#AD63BF', '#C684BD', '#DEAABF', '#FAD6C7', '#E7B198'];
		case 9:
			return ['#41004E', '#7C0095', '#AD42C3', '#AD63BF', '#C684BD', '#DEAABF', '#FAD6C7', '#E7B198', '#A26D51'];
		case 10:
			return [
				'#41004E',
				'#7C0095',
				'#AD42C3',
				'#AD63BF',
				'#C684BD',
				'#DEAABF',
				'#FAD6C7',
				'#E7B198',
				'#A26D51',
				'#C28D70',
			];
		default:
			return [];
	}
};

export interface State {
	title: string;
	id: number;
	statistics: DeliveryStatisticsModel;
	feedback: FeedbackStatisticsModel;
}

export const initialState: State = {
	title: null,
	id: null,
	statistics: EMPTY_OVERALL_STATS,
	feedback: null,
};

const contentPageReducer = createReducer(
	initialState,
	on(fromActions.statistics.init, (state, { id }) => ({
		...state,
		id,
	})),
	on(fromActions.statistics.successOverall, (state, { title, statistics }) => ({
		...state,
		title,
		statistics,
	})),
	on(fromActions.statistics.successFeedback, (state, { feedback }) => ({
		...state,
		feedback,
	})),
	on(fromActions.statistics.clear, (state) => ({
		...initialState,
	}))
);

export function reducer(state: State | undefined, action: Action) {
	return contentPageReducer(state, action);
}

export const getStatistics = (state: State) => state.statistics;

export const getRecipientsChartData = (statistics: DeliveryStatisticsModel) => [
	{
		name: 'Newsletter recipients',
		value: statistics?.totalReceivers + '',
	},
];

export const getDeliverabilityChartData = (statistics: DeliveryStatisticsModel) => {
	return {
		customColors: [
			{
				name: 'Delivered & opened',
				value: '#00A17C',
			},
			{
				name: 'Delivered not opened',
				value: '#E9B519',
			},
			{
				name: 'Newsletter bounced',
				value: '#C40000',
			},
		],
		chartData: [
			{
				name: 'Delivered & opened',
				value: statistics?.uniqueOpened + '',
			},
			{
				name: 'Delivered not opened',
				value: statistics?.delivered - statistics?.uniqueOpened + '',
			},
			{
				name: 'Newsletter bounced',
				value: statistics?.bounced + '',
			},
		],
	};
};

export const getClicksData = (statistics: DeliveryStatisticsModel) => ({
	unique: statistics?.uniqueClicked,
	total: statistics?.clicked,
});

export const getSuccessfulDeliveryChart = (statistics: DeliveryStatisticsModel) => [
	{
		name: 'Successful delivery',
		value: statistics?.delivered + '',
	},
];

export const getUniqueOpensChartData = (statistics: DeliveryStatisticsModel) => [
	{
		name: 'Unique newsletter opened',
		value: statistics?.uniqueOpened + '',
	},
];

export const getBouncesChartData = (statistics: DeliveryStatisticsModel) => [
	{
		name: 'Newsletter bounces',
		value: statistics?.bounced + '',
	},
];
export const getNewsletterTitle = (state: State) => state.title;
export const getNewsletterId = (state: State) => state.id;

export const getFeedbackChartData = (state: State) => {
	const { feedback, statistics } = state;
	if (!feedback) return null;
	const notAnsweredCount = statistics.delivered - feedback.options.reduce((acc, curr) => (acc += curr.count), 0);
	return {
		feedbackType: feedback.feedbackType,
		question: feedback.question,
		total: statistics.delivered,
		colorScheme: {
			domain: CHART_COLOR_PALETE_FACTORY(feedback.options.length + 1),
		},
		chartData: [
			...feedback.options.map((o, i) => ({
				name: o.name,
				icon: o.icon,
				value: o.count,
			})),
			{ name: 'Not answered', icon: null, value: notAnsweredCount },
		],
	};
};
