import { createFeatureSelector, combineReducers, Action } from '@ngrx/store';

import * as fromCatalogs from '@core/core-store/catalogs/catalogs.reducer';
import * as fromContentItems from '@core/core-store/content-items/content-items.reducer';
import * as fromContentDetails from '@core/core-store/content-details/content-details.reducer';
import * as fromContentTypes from '@core/core-store/content-types/content-types.reducer';
import * as fromLanguagesDict from '@core/core-store/languages-dict/languages-dict.reducer';
import * as fromCategoriesFlat from '@core/core-store/categories-flat/categories-flat.reducer';
import * as fromCategoriesNested from '@core/core-store/categories-nested/categories-nested.reducer';
import * as fromFunctionsFlat from '@core/core-store/functions-flat/functions-flat.reducer';
import * as fromFunctionsNested from '@core/core-store/functions-nested/functions-nested.reducer';
import * as fromLocationsFlat from '@core/core-store/locations-flat/locations-flat.reducer';
import * as fromLocationsNested from '@core/core-store/locations-nested/locations-nested.reducer';
import * as fromDepartmentsFlat from '@core/core-store/departments-flat/departments-flat.reducer';
import * as fromDepartmentsNested from '@core/core-store/departments-nested/departments-nested.reducer';
import * as fromUserChannels from '@app/core/core-store/user-channels/user-channels.reducer';
import * as fromChannels from '@app/core/core-store/channels/channels.reducer';
import * as fromChannelsNews from '@app/core/core-store/channels-news/channels-news.reducer';
import * as fromChannelsNewsAllLanguage from '@app/core/core-store/channels-news-all-language/channels-news-all-language.reducer';
import * as fromDepartmentManagers from '@app/core/core-store/department-managers/department-managers.reducer';
import * as fromTimeZones from '@app/core/core-store/timezones/timezones.reducer';
import * as fromUserPhoto from '@app/core/core-store/user-photo/user-photo.reducer';
import * as fromEvents from '@app/core/core-store/events/events.reducer';

export const feature = 'core';

export interface State {
  catalogs: fromCatalogs.State;
  contentItems: fromContentItems.State;
  contentDetails: fromContentDetails.State;
  contentTypes: fromContentTypes.State;
  languagesDict: fromLanguagesDict.State;
  categoriesFlat: fromCategoriesFlat.State;
  categoriesNested: fromCategoriesNested.State;
  departmentsFlat: fromDepartmentsFlat.State;
  departmentsNested: fromDepartmentsNested.State;
  functionsFlat: fromFunctionsFlat.State;
  functionsNested: fromFunctionsNested.State;
  locationsFlat: fromLocationsFlat.State;
  locationsNested: fromLocationsNested.State;
  userChannels: fromUserChannels.State;
  departmentManagers: fromDepartmentManagers.State;
  channels: fromChannels.State;
  channelsNews: fromChannelsNews.State;
  channelsNewsAllLanguage: fromChannelsNewsAllLanguage.State;
  timeZones: fromTimeZones.State;
  userPhoto: fromUserPhoto.State;
  events: fromEvents.State;
}

export function reducer(state: State | undefined, action: Action) {
  return combineReducers({
    catalogs: fromCatalogs.reducer,
    contentItems: fromContentItems.reducer,
    contentDetails: fromContentDetails.reducer,
    contentTypes: fromContentTypes.reducer,
    languagesDict: fromLanguagesDict.reducer,
    categoriesFlat: fromCategoriesFlat.reducer,
    categoriesNested: fromCategoriesNested.reducer,
    departmentsFlat: fromDepartmentsFlat.reducer,
    departmentsNested: fromDepartmentsNested.reducer,
    functionsFlat: fromFunctionsFlat.reducer,
    functionsNested: fromFunctionsNested.reducer,
    locationsFlat: fromLocationsFlat.reducer,
    locationsNested: fromLocationsNested.reducer,
    userChannels: fromUserChannels.reducer,
    departmentManagers: fromDepartmentManagers.reducer,
    channels: fromChannels.reducer,
    channelsNews: fromChannelsNews.reducer,
    channelsNewsAllLanguage: fromChannelsNewsAllLanguage.reducer,
    timeZones: fromTimeZones.reducer,
    userPhoto: fromUserPhoto.reducer,
    events: fromEvents.reducer
  })(state, action);
}

export const selectState = createFeatureSelector<State>(feature);

export const getCatalogs = (state: State) => state.catalogs;
export const getContentItems = (state: State) => state.contentItems;
export const getContentDetails = (state: State) => state.contentDetails;
export const getContentTypes = (state: State) => state.contentTypes;
export const getLanguagesDict = (state: State) => state.languagesDict;
export const getCategoriesFlat = (state: State) => state.categoriesFlat;
export const getCategoriesNested = (state: State) => state.categoriesNested;
export const getDepartmentsFlat = (state: State) => state.departmentsFlat;
export const getDepartmentsNested = (state: State) => state.departmentsNested;
export const getFunctionsFlat = (state: State) => state.functionsFlat;
export const getFunctionsNested = (state: State) => state.functionsNested;
export const getLocationsFlat = (state: State) => state.locationsFlat;
export const getLocationsNested = (state: State) => state.locationsNested;
export const getUserChannels = (state: State) => state.userChannels;
export const getDepartmentManagers = (state: State) => state.departmentManagers;
export const getChannels = (state: State) => state.channels;
export const getChannelsNews = (state: State) => state.channelsNews;
export const getChannelsNewsAllLanguage = (state: State) => state.channelsNewsAllLanguage;
export const getTimeZones = (state: State) => state.timeZones;
export const getUserPhoto = (state: State) => state.userPhoto;
export const getEvents = (state: State) => state.events;
