import { CdkVirtualScrollViewport, VirtualScrollStrategy } from '@angular/cdk/scrolling';
import { Injectable } from '@angular/core';
import { Observable, Subject, distinctUntilChanged } from 'rxjs';

export const BUFFER = 4;
@Injectable()
export class TableVirtualScrollStrategy implements VirtualScrollStrategy {
	private scrollHeight!: number;
	private scrollHeader!: number;
	private readonly indexChange = new Subject<number>();
	private readonly rangeChange = new Subject<{
		rangeWithBuffer: { start: number; end: number };
		rangeInViewport: { start: number; end: number };
	}>();

	private viewport: CdkVirtualScrollViewport;

	public scrolledIndexChange: Observable<number>;
	public scrolledRangeChange: Observable<{
		rangeWithBuffer: { start: number; end: number };
		rangeInViewport: { start: number; end: number };
	}>;

	constructor() {
		this.scrolledRangeChange = this.rangeChange.asObservable().pipe(distinctUntilChanged());
	}

	public attach(viewport: CdkVirtualScrollViewport): void {
		this.viewport = viewport;
		this.onDataLengthChanged();
		this.updateContent(viewport);
	}

	public detach(): void {
		// no-op
	}

	public onContentScrolled(): void {
		if (!this.viewport) {
			return;
		}
		this.updateContent(this.viewport);
	}

	public onDataLengthChanged(): void {
		if (!this.viewport) {
			return;
		}
		this.viewport.setTotalContentSize(this.viewport.getDataLength() * this.scrollHeight);
		this.updateContent(this.viewport);
	}

	public onContentRendered(): void {
		this.viewport.checkViewportSize();
	}

	public onRenderedOffsetChanged(): void {
		// no-op
	}

	public scrollToIndex(index: number, behavior: ScrollBehavior = 'smooth'): void {
		if (this.viewport) {
			const currentOffset = this.viewport.getOffsetToRenderedContentStart();
			const newOffset = this.scrollHeight * index;
			if (currentOffset < newOffset) {
				this.viewport.scrollToOffset((index - 15) * this.scrollHeight);
			} else {
				this.viewport.scrollToOffset((index + 15) * this.scrollHeight);
			}
			this.viewport.scrollToOffset(index * this.scrollHeight, 'smooth');
		}
	}

	public setScrollHeight(rowHeight: number, headerHeight: number) {
		this.scrollHeight = rowHeight;
		this.scrollHeader = headerHeight;
	}

	private updateContent(viewport: CdkVirtualScrollViewport) {
		if (!viewport) {
			return;
		}

		const scrollOffset = viewport.measureScrollOffset();
		const scrollIdx = Math.max(0, Math.round((scrollOffset - this.scrollHeader) / this.scrollHeight));
		const dataLength = viewport.getDataLength();

		const renderedRange = viewport.getRenderedRange();
		const renderedInViewport = Math.round(viewport.getViewportSize() / this.scrollHeight);
		const range = {
			start: renderedRange.start,
			end: renderedRange.end,
		};
		range.start = Math.max(0, scrollIdx - BUFFER);
		range.end = Math.min(dataLength, scrollIdx + renderedInViewport + BUFFER);
		viewport.setRenderedRange(range);
		const contentOffset =
			scrollOffset > this.scrollHeader ? this.scrollHeight * range.start : this.scrollHeight * range.start;
		viewport.setRenderedContentOffset(contentOffset);
		this.rangeChange.next({
			rangeWithBuffer: range,
			rangeInViewport: {
				start: Math.max(0, scrollIdx),
				end: Math.min(dataLength, scrollIdx + renderedInViewport),
			},
		});
	}
}
