import { createAction, props } from '@ngrx/store';
import { Channel } from '@app/core/models';

export const channelsCollectionAddMany = createAction(
  '[STORED CHANNELS - ADD MANY] Channels collection add many.',
  props<{ channels: Channel[] }>()
);

export const channelsCollectionAddAll = createAction(
  '[STORED CHANNELS - ADD All] Channels collection add all.',
  props<{ channels: Channel[] }>()
);

export const channelsCollectionUpsertOne = createAction(
  '[STORED CHANNELS - UPSERT ONE] Channels collection upsert one.',
  props<{ channel: Channel }>()
);

export const channelsCollectionRemoveOne = createAction(
  '[STORED CHANNELS - REMOVE ONE] Channels collection remove one.',
  props<{ channel: Channel }>()
);

export const loadChannelsRequest = createAction(
  '[STORED CHANNELS - LOAD CHANNELS REQUEST] Load chanels request'
);

export const loadChannelsSuccess = createAction(
  '[STORED CHANNELS - LOAD CHANNELS SUCCESS] Load chanels success',
  props<{ channels: Array<Channel> }>()
);

export const loadChannelsFailure = createAction(
  '[STORED CHANNELS - LOAD CHANNELS FAILURE] Load chanels error',
  props<{ error: any }>()
);
