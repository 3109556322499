import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { EndpointsService } from '@app/core/services/endpoints/endpoints.service';
import { ApiService } from '@app/core/services/api/api.service';


import { TimeZone, TimeZoneResponse } from '../models';
@Injectable()
export class TimeZoneService {


  public getTimeZones(defaultTimeZone): Observable<TimeZoneResponse> {
    const url = this.endpoints.addParams(this.endpoints.ENDPOINT.TIMEZONE.GET, { userTimeZone: defaultTimeZone});

    return this.http
      .get(url).pipe(
        map((result) => ({
          defaultTimeZone: result.defaultTimeZone,
          timeZones: result.timeZones.map((timeZone): TimeZone => ({
            id: timeZone.id,
            name: timeZone.name,
            offset: timeZone.name.split('(')[1].split(')')[0]
          }))
        }))
      );
  }

  constructor(
    private http: ApiService,
    private endpoints: EndpointsService,
  ) {}

}
