import {createSelector} from '@ngrx/store';
import * as fromSearchResults from '../search.reducer';
import * as fromReducer from './cloud-search.reducer';

export const selectState = createSelector(
  fromSearchResults.selectState,
  fromSearchResults.getCloudSearch
);

export const selectIsLoading = createSelector(
  selectState,
  fromReducer.getIsLoading
);

export const selectSourcesList = createSelector(
  selectState,
  fromReducer.getSourcesList
);

export const selectCurrentSource = createSelector(
  selectState,
  fromReducer.getCurrentSource
);

export const selectCurrentResults = createSelector(
  selectState,
  selectCurrentSource,
  (state, sourceListItem) => {
    if (sourceListItem.sources.length > 1) {
      return state.results.all;
    } else {
      const source = sourceListItem.sources[0].predefinedSource || sourceListItem.sources[0].name;
      return state.results[source];
    }
  }
)

export const selectMobileFiltersOpened = createSelector(
  selectState,
  fromReducer.getMobileFiltersOpened
);

export const selectNoPermissions = createSelector(
  selectState,
  fromReducer.getNoPermissions
);
