import { createAction, props } from '@ngrx/store';

import { ContentItem } from '@core/models/content-item.model';

export const contentItemsCollectionAddMany = createAction(
  '[STORED CONTENT ITEMS - ADD MANY] Content Items collection add many.',
  props<{ contentItems: ContentItem[] }>()
);

export const contentItemsCollectionUpsertOne
= createAction(
  '[STORED CONTENT ITEMS - UPSERT ONE] Content Items collection upsert one.',
  props<{ contentItem: ContentItem }>()
);

export const updateNumComments = createAction(
  '[STORED CONTENT ITEMS - UPDATE COMMENTS NUMBER] Content Items update Comments Number',
  props<{ contentId: string; count: number }>()
);

// LIKE
export const putLikeByContentIdRequest = createAction(
  '[CONTENT ITEM - PUT LIKE REQUEST] PUT Content Like by Content Id Request.',
  props<{ contentId: string }>()
);

export const putLikeByContentIdSuccess = createAction(
  '[CONTENT ITEM - PUT LIKE ] PUT Content Like by Content Id Success.',
  props<{ contentId: string, numLikes: number }>()
);

export const putLikeByContentIdError = createAction(
  '[CONTENT ITEM - PUT LIKE ERROR] PUT Content Like by Content Id Error.',
  props<{ contentId: string }>()
);

export const removeLikeByContentIdRequest = createAction(
  '[CONTENT ITEM - DELETE LIKE REQUEST] DELETE Content Like by Content Id Request.',
  props<{ contentId: string }>()
);

export const removeLikeByContentIdSuccess = createAction(
  '[CONTENT ITEM - DELETE LIKE SUCCESS] DELETE Content like by Content Id Success.',
  props<{ contentId: string, numLikes: number  }>()
);

export const removeLikeByContentIdError = createAction(
  '[CONTENT ITEM - DELETE LIKE ERROR] DELETE Content Like by Content Id Error.',
  props<{ contentId: string }>()
);

// BOOKMARK
export const putBookmarkByContentIdRequest = createAction(
  '[CONTENT ITEM - PUT BOOKMARK REQUEST] PUT Content Bookmark by Content Id Request.',
  props<{ contentId: string }>()
);

export const putBookmarkByContentIdSuccess = createAction(
  '[CONTENT ITEM - PUT BOOKMARK] PUT Content Bookmark by Content Id Success.',
  props<{ contentId: string }>()
);

export const putBookmarkByContentIdError = createAction(
  '[CONTENT ITEM - PUT BOOKMARK ERROR] PUT Content Bookmark by Content Id Error.',
  props<{ contentId: string }>()
);

export const removeBookmarkByContentIdRequest = createAction(
  '[CONTENT ITEM - DELETE BOOKMARK REQUEST] DELETE Content Bookmark by Content Id Request.',
  props<{ contentId: string }>()
);

export const removeBookmarkByContentIdSuccess = createAction(
  '[CONTENT ITEM - DELETE BOOKMARK SUCCESS] DELETE Content Bookmark by Content Id Success.',
  props<{ contentId: string }>()
);

export const removeBookmarkByContentIdError = createAction(
  '[CONTENT ITEM - DELETE BOOKMARK ERROR] DELETE Content Bookmark by Content Id Error.',
  props<{ contentId: string }>()
);

export const sendExternalLinkToGA = createAction(
  '[CONTENT ITEM] Send External Link to GA',
  props<{ content: ContentItem }>()
);

export const markReadRequest = createAction(
  '[CONTENT ITEM] Mark read Request',
  props<{ contentId: string}>()
);

export const markReadSuccess = createAction(
  '[CONTENT ITEM] Mark read Success',
  props<{ contentId: string}>()
);

export const markReadFailure = createAction(
  '[CONTENT ITEM] Mark read Failure',
  props<{ message: string}>()
);

export const copyLink = createAction(
  '[CONTENT ITEM] copy link',
  props<{route: string}>()
);
