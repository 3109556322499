import {Action, createReducer, on} from '@ngrx/store';

import * as fromActions from './template-form.actions';
import {TemplateRoles} from '@app/core/models/newsboard/template';
import cloneDeep from '@lodash-es/cloneDeep';
import {FormStepStatus} from '@shared/form-status-badge/form-status-badge.component';
import {RhTemplateForm} from '@app/newsletter-new/models/template';

export const feature = 'form';

export interface State {
    form: Partial<RhTemplateForm>;
    initialForm: Partial<RhTemplateForm>;
    shareStatus: {
        step: FormStepStatus;
        form: string;
    };
    touched: boolean;
    formChecked: boolean;
    role: TemplateRoles;
}

export const initialState: State = {
    form: null,
    initialForm: null,
    shareStatus: {
        step: FormStepStatus.NOT_DEFINED,
        form: 'VALID',
    },
    touched: false,
    formChecked: false,
    role: 'Owner',
};

const addReducer = createReducer(
    initialState,
    on(fromActions.form.setFormValue, (state, {form}) => ({
        ...state,
        form: {
            ...state.form,
            ...form,
        },
    })),
    on(fromActions.form.setFormChecked, (state) => ({
        ...state,
        formChecked: true,
    })),
    on(fromActions.form.setInitialFormValue, (state, {form}) => ({
        ...state,
        initialForm: cloneDeep(form),
    })),

    on(fromActions.form.setFormTouched, (state, {touched}) => ({
        ...state,
        touched,
    })),
    on(fromActions.form.setShareStepStatus, (state, {status}) => ({
        ...state,
        shareStatus: {
            ...state.shareStatus,
            step: status,
        },
    })),
    on(fromActions.form.setShareFormStatus, (state, {status}) => ({
        ...state,
        shareStatus: {
            ...state.shareStatus,
            form: status,
        },
    })),
    on(fromActions.form.clearAll, (state) => ({
        ...initialState,
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return addReducer(state, action);
}

export const getForm = (state: State) => state.form;
export const getInitialForm = (state: State) => state.initialForm;

export const getFormChecked = (state: State) => state.formChecked;
export const getId = (form: Partial<RhTemplateForm>) => form.id;

export const getShareStepStatus = (state: State) => state.shareStatus.step;
export const getShareFormStatus = (state: State) => state.shareStatus.form;

export const getTouched = (state: State) => state.touched;
export const getUserRole = (state: State) => state.role;
