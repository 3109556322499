<rh-list *ngIf="event">
  <div image class="list-image">
    <img [src]="event.imageUrl || '/assets/images/news-placeholder.png'" (click)="openEvent()"
         class="cursor-pointer">
  </div>
  <p
    body-top
    class="ui-label-l-bold text-high-contrast text-truncate">
 <span class="cursor-pointer">
    <rds-badge *ngIf="event" (click)="openEvent($event)"
               [label]="(eventTime | date:'dd') + ' ' +(eventTime | date:'LLLL')+ ' ' + (eventTime | date:'yyyy')"
               [type]="'minimal'"
               class="custom-color-badge"
               size="m">

    </rds-badge>
 </span>
  </p>
  <p
    body-middle
    class="ui-label-l-bold text-low-contrast  text-truncate cursor-pointer" (click)="openEvent($event)">
    <span [rdsTooltip]="titleTemplate"
          rdsTooltipPosition="top-center"
          [rdsTooltipShowDelay]="500"
          [innerHTML]="event.title">
    </span>
  </p>
  <div body-bottom style="display: flex; justify-content: space-between;">
    <p class="ui-component-text-m text-low-contrast text-truncate">
      {{event.allDay ? 'ALL DAY' : zonedTime | date: 'h:mm a'}}
      <ng-container [ngSwitch]="event.type || event.eventType">
        <span *ngSwitchCase="RhEventType.ON_SITE" class="ui-component-text-s"> | ON-SITE</span>
        <span *ngSwitchCase="RhEventType.VIRTUAL" class="ui-component-text-s"> | VIRTUAL</span>
        <span *ngSwitchCase="RhEventType.HYBRID" class="ui-component-text-s"> | HYBRID</span>
        <span *ngSwitchCase="RhEventType.OFF_SITE" class="ui-component-text-s"> | OFF-SITE</span>
        <span *ngSwitchDefault class="ui-component-text-s"> | TYPE</span>
      </ng-container>
    </p>

    <ng-container *ngIf="event.registrationUrl">
      <div class="register" (click)="$event.stopPropagation()">
        <a href="{{event.registrationUrl}}" class="ui-component-text-s" target="_blank"> REGISTER
          <rds-icon class="icon-button cursor-pointer" icon="launch" namespace="outlined"></rds-icon>
        </a>
      </div>
    </ng-container>
    <ng-container *ngIf="!event.registrationUrl">
      <rds-icon class="result__button" icon="add_circle" rdsTooltip="Add to calendar" namespace="outlined"
                (click)="addEventToGcal($event)"></rds-icon>
    </ng-container>
  </div>
</rh-list>

<ng-template #titleTemplate>
  <span [innerHTML]="event.title"></span>
</ng-template>
<ng-template #descriptionTemplate>
  <span [innerHTML]="event.title"></span>
</ng-template>
<ng-template #newsCount>
  Last news: <span class="text-uppercase">{{(event.lastNews | timeAgo)}}</span>
  | {{'All news: ' + event.newsCount}}
</ng-template>
