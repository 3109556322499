import { Injectable } from '@angular/core';
import { Autocompletes, People, QuickLink } from '@app/layout/header/models';
import { ExpressLink } from '@app/layout/header/models/ExpressLink';
import { ApiService, HttpError } from '@core/services/api/api.service';
import { EndpointsService } from '@core/services/endpoints/endpoints.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigurationParameter } from '../components/dummy-links';
import { CatalogFilters } from '../models/Catalog';

@Injectable()
export class HeaderService {

  getExpressLinks(text: string): Observable<ExpressLink[]> {
    const url = this.endpoints.ENDPOINT.SEARCH.PAGED.GET;
    const urlWithQuery = this.endpoints.addParams(url, {
      query: text,
      pageIndex: 1,
      pageSize: 12,
      dataSource: 'atroche'
    });

    const params = {
      categories: [],
      departments: [],
      functions: [],
      locations: [],
      personalized: false
    };

    return this.http
      .put(urlWithQuery, params)
      .pipe(
        map(results => {
          const expressLinks: ExpressLink[] = [];
          results.results.results.forEach((item) => {
            expressLinks.push({
              name: item.name,
              url: item.url,
              description: item.description,
              category: {name: item.category}
            });
          });
          return expressLinks;
        }),
        catchError((error: HttpError) => throwError(`Cannot search express links. ${error.message}`))
      );
  }

  getHeaderLinks(): Observable<ConfigurationParameter> {
    const url = this.endpoints.ENDPOINT.HEADER.GET_LINKS;
    return this.http
      .post(url, {parameters: ['header', 'heroSection', 'urls']})
  }

  getAutocompletes(text: string): Observable<Autocompletes> {
    const url = this.endpoints.ENDPOINT.SEARCH.AUTOCOMPLETE;
    const urlWithQuery = this.endpoints.addParams(url, {
      query: text,
    });

    return this.http
      .get(urlWithQuery)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get autocompletes. ${error.message}`))
      );
  }

  getPeople(text: string): Observable<People[]> {
    const url = this.endpoints.ENDPOINT.WORKDAY.USER.SEARCH;
    const urlWithQuery = this.endpoints.addParams(url, {
      query: text,
    });

    return this.http
      .get(urlWithQuery)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get people. ${error.message}`))
      );
  }

  getFavouriteQuickLinks(): Observable<any> {
    const url = this.endpoints.ENDPOINT.QUICKLINKS.GET_FAVORITE;
    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get my favourite quick-links. ${error.message}`))
      );
  }

  getMyQuickLinks(): Observable<QuickLink[]> {
    const url = this.endpoints.ENDPOINT.QUICKLINKS.GET_MY;
    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get my quick-links. ${error.message}`))
      );
  }

  getCategories() {
    const url = this.endpoints.ENDPOINT.CATEGORY.NESTED.GET;

    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpError) => throwError(`Cannot get categories. ${error.message}`))
      );
  }

  getCatalogs(categoryId: number, filters: CatalogFilters) {
    const url = this.endpoints.ENDPOINT.CATALOG.PUT.SEARCH_FILTERED_ASYNC;
    const body = {
      categoryIds: filters.categories.length === 0 ? [categoryId] : filters.categories,
      locationIds: filters.locations,
      departmentIds: filters.departments,
      functionIds: filters.functions,
      includeQuickLinks: false
    };
    return this.http
      .put(url, body)
      .pipe(
        map((res) => res),
        catchError((error: HttpError) => throwError(`Cannot get catalogs. ${error.message}`))
      );
  }

  addQuicklink(catalogId: number): Observable<QuickLink> {
    const url = this.endpoints.ENDPOINT.QUICKLINKS.POST;

    return this.http
      .post(url, {catalogId, isFavourite: false});
  }

  getResetQuickLinks(): Observable<{ quickLinksToRemove: Array<QuickLink>, quickLinksToAdd: Array<QuickLink> }> {
    const url = this.endpoints.ENDPOINT.QUICKLINKS.RESET;

    return this.http
      .get(url);
  }

  postResetQuickLinks(): Observable<Array<QuickLink>> {
    const url = this.endpoints.ENDPOINT.QUICKLINKS.RESET;

    return this.http
      .post(url);
  }

  putReorganizeQuickLinks(ids: Array<number>) {
    const url = this.endpoints.ENDPOINT.QUICKLINKS.FAVOURITE_REORDER;

    return this.http
      .put(url, ids);
  }

  deleteQuickLinks(ids: Array<number>) {
    const url = this.endpoints.ENDPOINT.QUICKLINKS.DELETE;

    return this.http
      .deleteWithBody(url, ids);
  }

  addToTopQuickLinks(id: number): Observable<QuickLink> {
    const url = this.endpoints.ENDPOINT.QUICKLINKS.PUT;

    return this.http
      .put(url, {quickLinkId: id, isFavourite: true});
  }

  removeFromTopQuickLinks(id: number): Observable<QuickLink> {
    const url = this.endpoints.ENDPOINT.QUICKLINKS.PUT;

    return this.http
      .put(url, {quickLinkId: id, isFavourite: false});
  }

  renameQuickLink(id: number, name: string): Observable<QuickLink> {
    const url = this.endpoints.ENDPOINT.QUICKLINKS.RENAME;

    return this.http
      .put(url, {quickLinkId: id, name});
  }

  constructor(
    private http: ApiService,
    private endpoints: EndpointsService,
  ) {
  }
}
