import { createReducer, on } from '@ngrx/store';
import * as fromNotificationActions from './notifications.actions';

export const feature = 'notifications';
export interface State {
  alert: any;
}

export const initialState: State = {
  alert: null,
};

export const reducer = createReducer(
  initialState,
  on(fromNotificationActions.showAlert, (state, { alert }) => ({
    ...state,
    alert
  })),
  on(fromNotificationActions.closeAlert, (state) => ({
    ...state,
    alert: null
  })),
);

export const getAlert = (state: State) => state.alert;
