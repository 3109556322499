export enum GA_EVENTS {
  NEWSFLOW_ARTICLE = 'newsflow_article',
  CE_SEARCH_FIELD = 'ce_search_field',
  CE_SEARCH_FILTER = 'ce_search_filter',
  CE_SEARCH_RESULT_CLICK = 'ce_search_results_click',
  CE_SEARCH_CATEGORY_CLICK = 'ce_search_category_click',
  CE_SEARCH_LOADMORE_CLICK = 'ce_search_loadmore_click',
  CE_SEARCH_MISSINGCONTENT_CLICK = 'ce_search_missingcontent_click',
  CE_SEARCH_QUICKADD_CLICK = 'ce_search_quickadd_click',
  CE_SEARCH_FILTERCLEARING_CLICK= 'ce_search_filterclearing_click',
}
