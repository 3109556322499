import { Pipe, PipeTransform } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromChannelsNews from '@core/core-store/channels-news-all-language/channels-news-all-language.selectors';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Pipe({
  name: 'newsTitle'
})
export class NewsTitlePipe implements PipeTransform {
  transform(channelId: string, newsId: string): Observable<string> {
    return this.store$.pipe(
      select(fromChannelsNews.selectNewsEntityById(channelId), { id: newsId}),
      distinctUntilChanged(),
      map((news) => news ? news.abstracts[0].abstract : null)
    );
  }

  constructor(
    private store$: Store<any>) {
  }
}
