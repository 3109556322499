import { createAction, props } from '@ngrx/store';
import { Pagination, GenericAccount } from '@core/models';
import { SortDirection } from '@rds/angular-components';
import { BackButton } from '@app/root-store/ui/back-button';
import { OnlySearchFilters } from '@app/shared/filters/filters-model';


export const initTable = createAction(
  '[GENERIC ACCOUNTS] Initialize GenericAccounts'
);

export const clearAll = createAction(
  '[GENERIC ACCOUNTS] Clear all'
);

export const getTableRequest = createAction(
  '[GENERIC ACCOUNTS] Get generic accounts Request',
  props<{
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
    filters: {[key: string]: any},
  }>()
);

export const getTableSuccess = createAction(
  '[GENERIC ACCOUNTS] Get generic accounts Success',
  props<{ data: Array<GenericAccount>, pagination: Pagination }>()
);

export const getTableFailure = createAction(
  '[GENERIC ACCOUNTS] Get generic accounts Failure',
  props<{ error: any }>()
);

export const changeTableFilters = createAction(
  '[GENERIC ACCOUNTS] Change generic accounts filters',
  props<{ filters: OnlySearchFilters }>()
);

export const changeTableSort = createAction(
  '[GENERIC ACCOUNTS] Change generic accounts sort',
  props<{ active: string, direction: SortDirection }>()
);

export const changeTablePagination = createAction(
  '[GENERIC ACCOUNTS] Change generic accounts pagination',
  props<{ pageIndex: number, pageSize: number }>()
);

export const openNewsPreview = createAction(
  '[GENERIC ACCOUNTS] Open news preview',
  props<{ id: number, backButton: BackButton}>()
);

export const deleteGenericAccountRequest = createAction(
  '[GENERIC ACCOUNTS] delete generic account request',
  props<{ username: string }>()
);

export const deleteGenericAccountSuccess = createAction(
  '[GENERIC ACCOUNTS] delete generic account success',
  props<{ username: string }>()
);

export const deleteGenericAccountFailure = createAction(
  '[GENERIC ACCOUNTS] delete generic account failure',
  props<{ message: string }>()
);
