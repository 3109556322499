import {Component, ElementRef, OnInit} from '@angular/core';
import {ComponentsLoaderService} from '../components-loader.service';

@Component({
  selector: 'rh-news-content-list-loader',
  templateUrl: './news-content-list-loader.component.html',
  styleUrls: ['./news-content-list-loader.component.scss']
})
export class NewsContentListLoaderComponent implements OnInit {
  width: number;

  constructor(public componentsLoaderService: ComponentsLoaderService, private el: ElementRef<HTMLElement>) {
  }

  ngOnInit(): void {
    this.width = +getComputedStyle(this.el.nativeElement).width.split('px')[0];
  }
}
