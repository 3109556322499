import { RhEvent } from '@app/events/models/event';
import {createAction, props} from '@ngrx/store';

export const changeSelectedDate = createAction(
  '[EVENTS - CALENDAR] change selected date ',
  props<{ selectedDate: Date }>()
);

export const selectDay = createAction(
  '[EVENTS - CALENDAR] Select day ',
  props<{ day: Date }>()
);

export const setCalendarPageToSelection = createAction(
  '[EVENTS - CALENDAR] Set calendar page to selection ',
)

export const prevCalendarPage = createAction(
  '[EVENTS - CALENDAR] prev calendar page '
);

export const nextCalendarPage = createAction(
  '[EVENTS - CALENDAR] next calendar page '
);

export const setCalendarPage = createAction(
  '[EVENTS - CALENDAR] set calendar page ',
  props<{ month: number, year: number }>()
);

export const loadGcalEvents = createAction(
  '[EVENTS - CALENDAR] load google calendar events'
);

export const setCalendarPageRange = createAction(
  '[EVENTS - CALENDAR] Set calendar page range',
  props<{ start: Date, end: Date }>()
)

export const gcalEventsSuccess = createAction(
  '[EVENTS - CALENDAR] GCAL Events success',
  props<{ events: Array<RhEvent>}>()
)

export const getCalendarEventsRequest = createAction(
  '[EVENTS - CALENDAR] Get events request'
);

export const getCalendarEventsSuccess = createAction(
  '[EVENTS - CALENDAR] Get events success',
  props<{events: Array<Partial<RhEvent>>}>()
);

export const getCalendarEventsFailure = createAction(
  '[EVENTS - CALENDAR] Get events failure',
  props<{error: string}>()
);
