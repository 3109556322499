
<div class="row py-7">
  <div class="col-8 offset-2 py-4">
    <div class="row">
      <div class="col">
        <h4 class="ui-heading-4">Notifications Settings</h4>
      </div>
    </div>
  </div>
</div>
  <div class="col-8 offset-2 bg-white py-10 py-s-4 px-11 px-s-5">
    <div class="row">
      <div class="col">
        <p class="ui-label-s mb-9">You can customize the way you receive your notifications, choose if you would like to
          receive your notifications daily, immediately or none by channel.</p>
      </div>
    </div>
    <div class="row" [formGroup]="form">
      <div class="col">
        <h4 class="ui-heading-4 mb-3">Allow Notifications</h4>
      </div>
      <div class="col-auto">
        <rds-switch formControlName="notificationsEnabled"></rds-switch>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <rds-alert *ngIf="(areNotificationPermissionGranted$ | async) === false" [style]="'error'">
          We have detected that notifications are being blocked by your browser, follow <a [href]="notificationGuideLink$ | async" target="_blank">instructions</a> to enable them.
        </rds-alert>
        <rds-alert *ngIf="(areNotificationPermissionGranted$ | async) === true" [style]="'warning'">
          If you still do not receive notifications, please check OS settings - <a [href]="notificationGuideLink$ | async" target="_blank">instructions</a>.
        </rds-alert>
        <rds-divider class="my-5"></rds-divider>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h4 class="ui-heading-4 mb-3">Channels Notifications</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-2 offset-6">
        <div class="row">
          <div class="col-auto offset-both">
            Allow          
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="row">
          <div class="col-auto offset-both">
            Immediately          
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="row">
          <div class="col-auto offset-both">
            Daily          
          </div>
        </div>
      </div>
    </div>
    <div class="row row-centered" *ngFor="let channelSetting of channelSettingsArray.controls" [formGroup]="channelSetting">
      <div class="col-6 py-4">
        <div class="overflowing-badge">
          <rds-badge [label]="channelSetting.get('name').value" rhIsOverflowing #overflow="rhIsOverflowing"
          [rdsTooltipDisabled]="!overflow.overflowingX" [rdsTooltip]="channelSetting.get('name').value" [rdsTooltipShowDelay]="300" type="minimal" color="neutral" size="m"></rds-badge>
        </div>
      </div>
      <div class="col-2">
        <div class="row">
          <div class="col-auto offset-both">
            <rds-switch [disabled]="form.controls.notificationsEnabled.value === false" formControlName="notificationsEnabled"></rds-switch>
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="row">
          <div class="col-auto offset-both">
            <rds-radio-group formControlName="notificationsType">
              <rds-radio-button [value]="NotificationsNewsType.IMMEDIATE"
              [disabled]="channelSetting.get('notificationsEnabled').value === false || (notificationsEnabled$ | async) === false"
              [checked]="channelSetting.get('notificationsType').value === NotificationsNewsType.IMMEDIATE"></rds-radio-button>
            </rds-radio-group>
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="row">
          <div class="col-auto offset-both">
            <rds-radio-group formControlName="notificationsType">
              <rds-radio-button  [value]="NotificationsNewsType.DAILY"
                                 [disabled]="channelSetting.get('notificationsEnabled').value === false || (notificationsEnabled$ | async) === false"
                                 [checked]="channelSetting.get('notificationsType').value === NotificationsNewsType.DAILY"></rds-radio-button>
            </rds-radio-group>
          </div>
        </div>
      </div>
    </div>
  </div>
<div class="row">
  <div class="col-8 offset-2 py-5 mb-9">
    <div class="row row-centered">
      <div class="col-auto">
        <button rds-text-button (click)="reset()" size="m">
          Reset to default
        </button>
      </div>
      <div class="col-auto offset-auto">
        <button rds-secondary-button [disabled]="nothingChanged" (click)="cancel()" size="m">
          Cancel
        </button>
      </div>
      <div class="col-auto">
        <button rds-primary-button [disabled]="nothingChanged" (click)="save()" size="m">
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>
