import { createAction, props } from '@ngrx/store';

import { TaxonomyFlat } from '@core/models/taxonomy.model';

export const loadLocationsFlat = createAction(
  '[CORE LOCATIONS FLAT - LOAD LOCATIONS FLAT] load Locations Flat'
);

export const getLocationsFlatRequest = createAction(
  '[CORE LOCATIONS FLAT - GET LOCATIONS FLAT REQUEST] Get Locations Flat Request'
);

export const getLocationsFlatSuccess = createAction(
  '[CORE LOCATIONS FLAT - GET LOCATIONS FLAT SUCCESS] Get Locations Flat Success',
  props<{ locationsFlat: TaxonomyFlat[] }>()
);

export const getLocationsFlatFailure = createAction(
  '[CORE LOCATIONS FLAT - GET LOCATIONS FLAT FAILURE] Get Locations Flat Failure',
  props<{ error: any }>()
);

export const locationsFlatCollectionAddMany = createAction(
  '[STORED LOCATIONS FLAT - ADD MANY] Locations Flat collection add many.',
  props<{ locationsFlat: TaxonomyFlat[] }>()
);
