import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit, ViewChild} from '@angular/core';
import * as fromReducer from '@app/layout/header/store/header.reducer';
import {select, Store} from '@ngrx/store';
import * as fromQuickLink from '@app/layout/header/store/quick-link';
import {Category, QuickLink} from '@app/layout/header/models';
import * as fromCatalogs from '@app/layout/header/store/catalogs/catalogs.selectors';
import {filter, switchMap} from 'rxjs/operators';
import {categoriesOptionsModel, CategoryFlat} from '@app/core/models';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import * as fromCategoriesFlat from '@core/core-store/categories-flat';
import {NavigationEnd, Router} from '@angular/router';
import {TabsEnum} from '@core/enums/header-tabs.enum';
import {SubSink} from 'subsink';
import {RdsTabsComponent} from '@rds/angular-components';
import { Observable } from 'rxjs';


@Component({
  selector: 'rh-quick-link-component',
  templateUrl: './quick-link-container.component.html',
  styleUrls: ['./quick-link-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickLinkComponent implements OnInit {
  @HostBinding('class.opened') opened = false;
  @HostBinding('class.mobile') mobile = false;
  @ViewChild('tabs') tabs: RdsTabsComponent;

  private subs: SubSink = new SubSink();

  private _isMobile;
  @Input() set isMobile(value: boolean) {
    this.mobile = value;
    this._isMobile = value;
  }

  get isMobile() {
    return this._isMobile;
  }

  public currentTab = 0;
  public tabsEnum = TabsEnum;
  public areOpenedQuickLinks: boolean;
  public topQuickLinks$: Observable<QuickLink[]> = this.store$.pipe(
    select(fromQuickLink.selectQuickLinksFavouritesInOrder));

  public isTemporaryListFilled$: Observable<boolean> = this.store$.pipe(
    select(fromQuickLink.selectIsTemporaryListFilled));

  public temporaryTopQuickLinks$: Observable<QuickLink[]> = this.store$.pipe(
    select(fromQuickLink.selectTemporaryQuickLinks));

  public isDeleteModeActive$: Observable<boolean> = this.store$.pipe(
    select(fromQuickLink.selectIsDeleteModeActive));

  public categoriesDict$: Observable<Array<CategoryFlat>> = this.store$.pipe(
    select(fromQuickLink.selectAllUsedCategoriesIds),
    switchMap((ids) => this.store$.pipe(
      select(fromCategoriesFlat.selectAllByIdsWithParents(ids)),
    ))
  );

  public allMyQuickLinks$: Observable<Array<QuickLink>> = this.store$.pipe(
    select(fromQuickLink.selecMyQuickLinks),
  );

  public currentTab$: Observable<TabsEnum> = this.store$.pipe(
    select(fromQuickLink.selectCurrentTab),
  );

  public quickLinkCategories$: Observable<Array<Category>> = this.store$.pipe(select(fromCatalogs.selectCategories));
  public openedCategory$: Observable<Category> = this.store$.pipe(select(fromCatalogs.selectOpenedCategory));
  categoriesOptionsModel = categoriesOptionsModel;
  categoriesOpened = false;

  categoryForm: UntypedFormGroup = this.fb.group({
    category: new UntypedFormControl([])
  });

  ngOnInit(): void {
    this.subs.sink = this.store$.pipe(
      select(fromQuickLink.selectQuickLinksOpened)).subscribe((result) => {
      this.areOpenedQuickLinks = result;
      this.opened = result;
      this.changeDetectorRef.detectChanges();
    });

    this.subs.sink = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.closeQuickLink();
      }
    });

    this.subs.sink = this.currentTab$.pipe(filter((data) => !!data)).subscribe((result) => {
      this.currentTab = result;
      this.tabs.selectTab(this.currentTab)
    });
  }

  refreshView() {
    this.changeDetectorRef.detectChanges();
  }

  getQuickLinksForCategory(category: Category) {
    const selectedCategories = this.categoryForm.controls.category.value;
    return this.store$.pipe(select(fromQuickLink.selectAllQuickLinksInCategory(category.categoryId, selectedCategories)));
  }

  resetToDefault() {
    this.store$.dispatch(fromQuickLink.resetQuickLinksToDefault());
  }

  reorganize() {
    this.store$.dispatch(fromQuickLink.reorganizeQuickLinks());
  }

  deleteMulti() {
    this.store$.dispatch(fromQuickLink.deleteMultipleQuickLinks());
  }

  closeDeleteMode() {
    this.store$.dispatch(fromQuickLink.closeDeleteMultipleQuickLinks());
  }

  openDeleteDialog() {
    this.store$.dispatch(fromQuickLink.openDeleteQuickLinksConfirmDialog());
  }

  closeQuickLink(): void {
    this.store$.dispatch(fromQuickLink.closeHeaderQuickLinks());
    // this.closeInputIfMobile();
  }


  removeFromTopQL(link) {
    this.store$.dispatch(fromQuickLink.removeFromTopRequest({id: link.quickLinkId}));
  }

  addToTopQL(link) {
    this.store$.dispatch(fromQuickLink.addToTopRequest({id: link.quickLinkId}));
  }

  deleteQL(link) {
    this.store$.dispatch(fromQuickLink.openDeleteQuickLinkConfirmDialog({name: link.name, id: link.quickLinkId}));
  }

  renameQL(link) {
    this.store$.dispatch(fromQuickLink.openRenameQuickLinkDialogMobile({name: link.name, id: link.quickLinkId}));
  }


  checkboxClicked(event, link: QuickLink) {
    if (event.checked) {
      this.store$.dispatch(fromQuickLink.selectQuickLinkToDelete({id: link.quickLinkId}));
    } else {
      this.store$.dispatch(fromQuickLink.deselectQuickLinkToDelete({id: link.quickLinkId}));
    }
  }

  shouldBeDeleted(link: QuickLink) {
    return this.store$.pipe(select(fromQuickLink.selectIsQuickLinkToDelete(link.quickLinkId)));
  }

  changeTab(tab) {
    if (this.tabsEnum.QUICKLINKS === tab.index) {
      this.store$.dispatch(fromQuickLink.quicklinksTabOpened());
    }
    if (this.tabsEnum.CATALOGUE === tab.index) {
      this.store$.dispatch(fromQuickLink.catalogsTabOpened());
    }
    this.currentTab = tab.index;
  }

  tabsClicked(tabs: RdsTabsComponent) {
    if (this.currentTab !== tabs.selectedIndex) {
      switch (tabs.selectedIndex) {
        case this.tabsEnum.QUICKLINKS: {
          this.store$.dispatch(fromQuickLink.quicklinksTabOpened());
          break;
        }
        case this.tabsEnum.CATALOGUE: {
          this.store$.dispatch(fromQuickLink.catalogsTabOpened());
          break;
        }
      }
      this.currentTab = tabs.selectedIndex;
    }
  }

  // closeInputIfMobile() {
  //   if (this.isMobile) {
  //     this.store$.dispatch(fromHeader.mobileInputClose());
  //   }
  // }

  constructor(
    private store$: Store<fromReducer.State>,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private router: Router) {
  }
}
