import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { RdsDialogService } from '@rds/angular-components';
import * as fromRouter from '@app/root-store/router/router.actions';
import * as fromReducer from './newsletter-form.reducer';
import * as fromActions from './newsletter-form.actions';
import * as fromSelectors from './newsletter-form.selectors';
import * as fromBlockActions from '../newsletter-blocks/newsletter-blocks.actions';
import * as fromBlockSelectors from '../newsletter-blocks/newsletter-blocks.selectors';
import * as fromCampaign from '../campaign-table';
import * as fromChannelsNews from '@core/core-store/channels-news-all-language';

import { catchError, filter, forkJoin, from, map, mergeMap, of, skipUntil, switchMap, withLatestFrom } from 'rxjs';
import { NewsletterConfidentalNoteDialogContainer } from '@app/newsletter-new/dialogs/newsletter-confidental-note-dialog/newsletter-confidental-note-dialog.container';
import { NewsletterService } from '@app/core/services/newsletter.service';
import { ConfirmDialogComponent, ConfirmDialogData } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import {
	DEFAULT_FEEDBACK_SETTINGS,
	EMPTY_NEWSLETTER_FORM,
	NewsletterSteps,
	RhNewsletterForm,
	RhNewsletterRemappingResults,
} from '@app/newsletter-new/models/newsletter';
import {
	ManageDialogData,
	ManageFeedbackSectionDialogComponent,
} from '@app/newsletter-new/dialogs/manage feedback-section-dialog/manage feedback-section-dialog.component';
import { SelectDialogComponent } from '@shared/dialogs/select-dialog/select-dialog.component';
import { FormStepStatus } from '@shared/form-status-badge/form-status-badge.component';
import * as fromUser from '@core/user/store';
import { concatMap, exhaustMap, first, tap } from 'rxjs/operators';
import { WorkdayService } from '@app/core/services/workday.service';
import { createGuid } from '@app/shared/utils/guid';
import { NewsletterLegacyRemappingDialogContainer } from '@app/newsletter-new/dialogs/newsletter-legacy-remapping-dialog/newsletter-legacy-remapping-dialog.container';
import { Block, BlockTypes, ChannelBlockProperties } from '@app/newsletter-new/models/block-type.enum';
import { NewsboardNewsService } from '@app/core/services/newsboard/newsboard-news.service';
import { Section, SectionTypes, SubsectionTypes } from '@app/newsletter-new/models/section-type.enum';
import { NewsletterFormService } from '@core/services/newsletter-form.service';
import {
	SendTestDialogComponent,
	SendTestDialogData,
} from '@app/newsletter-new/dialogs/send-test-dialog/send-test-dialog.component';
import { EventsService } from '@app/core/services/managing-events.service';
import { BLOCK_MARKUP_TO_SETTINGS } from '@app/newsletter-new/utils/helpers';

import {
	ConfirmSendDialogComponent,
	ConfirmSendDialogData,
} from '@app/newsletter-new/dialogs/confirm-send-dialog/confirm-send-dialog.component';
import { CAMPAIGN_STATUS } from '@app/newsletter-new/models/campaign';
import * as fromForm from '@app/newsletter-new/store/newsletter-form/index';

@Injectable()
export class NewsletterFormEffects {
	public openUserAgreementDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.userAgreement.openDialog),
				map(({ id }) => {
					const dialog = this.dialogService.open(NewsletterConfidentalNoteDialogContainer, {
						size: 'l',
						height: '90%',
						maxHeight: '90%',
					});
					return { dialog, id };
				}),
				switchMap(({ dialog, id }) =>
					dialog.afterClosed().pipe(
						map((confirmed) => {
							if (confirmed) {
								return fromActions.userAgreement.confirmed({ id });
							} else {
								return fromRouter.go({ path: 'newsletter', queryParams: {} });
							}
						})
					)
				)
			),
		{ dispatch: true }
	);

	public init$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.form.init),
				withLatestFrom(
					this.store$.pipe(select(fromSelectors.selectNewsletter)),
					this.store$.pipe(select(fromUser.selectUserProfile))
				),
				switchMap(([action, newsletter, currentUser]) =>
					this.newsletterService.getEmailFrom().pipe(map((fromEmail) => ({ fromEmail, newsletter, currentUser })))
				),
				switchMap(({ fromEmail, newsletter, currentUser }) =>
					this.workdayService.getByEmail(fromEmail).pipe(
						map((newsletterUser) => ({
							newsletterUser: {
								...newsletterUser,
								description: 'Use RocheHome Newsletter distribution account',
								userType: 'defaultGeneric',
							},
							newsletter,
							currentUser: {
								...currentUser,
								description: 'Send Newsletter on your behalf',
								userType: 'currentUser',
							},
						}))
					)
				),
				switchMap(({ newsletter, newsletterUser, currentUser }) => [
					fromActions.form.setValue({
						form: {
							...newsletter,
							fromEmail: currentUser.email,
							fromName: `${currentUser.firstName} ${currentUser.lastName}`,
						},
					}),
					fromActions.form.setInitialValue({
						form: {
							...newsletter,
							fromEmail: currentUser.email,
							fromName: `${currentUser.firstName} ${currentUser.lastName}`,
						},
					}),
					fromActions.form.setInitialSenders({ currentUser, newsletterUser }),
				])
			),
		{ dispatch: true }
	);

	public getNewsletterByIdRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.getNewsletterById.request),
				withLatestFrom(this.store$.pipe(select(fromUser.selectUserProfile))),
				switchMap(([{ id, useFor }, currentUser]) =>
					this.newsletterService.getEmailFrom().pipe(map((fromEmail) => ({ id, useFor, fromEmail, currentUser })))
				),
				switchMap(({ id, useFor, fromEmail, currentUser }) =>
					this.workdayService.getByEmail(fromEmail).pipe(
						map((newsletterUser) => ({
							id,
							useFor,
							newsletterUser: {
								...newsletterUser,
								description: 'Use RocheHome Newsletter distribution account',
								userType: 'defaultGeneric',
							},
							currentUser: {
								...currentUser,
								description: 'Send Newsletter on your behalf',
								userType: 'currentUser',
							},
						}))
					)
				),
				switchMap(({ id, useFor, newsletterUser, currentUser }) =>
					this.newsletterService.getNewsletter2(id).pipe(
						mergeMap((newsletterResponse) => {
							if (
								newsletterResponse.templateId === 'NewsletterTemplateInlineEditor' ||
								newsletterResponse.templateId === 'NewsletterTemplateWithSections' ||
								newsletterResponse.templateId === 'NewsletterTemplateWithoutSections'
							) {
								return [
									fromActions.remapping.openDecisionDialog({ legacyNewsletter: newsletterResponse.newsletterLegacy }),
									fromActions.form.setInitialSenders({ currentUser, newsletterUser }),
								];
							} else {
								return [
									fromActions.preparing.start({
										newsletterResponse: newsletterResponse,
										shouldFetchSettingsData: useFor !== 'preview',
										useFor,
									}),
									fromActions.form.setInitialSenders({ currentUser, newsletterUser }),
								];
							}
						}),
						catchError((error) => of(fromActions.getNewsletterById.failure({ message: error })))
					)
				)
			),
		{ dispatch: true }
	);

	public prepareNewsletter$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.preparing.start),
			map(({ newsletterResponse, shouldFetchSettingsData, useFor }) => {
				let newsletter: RhNewsletterForm = {
					...newsletterResponse.newsletter,
					recipientRada:
						newsletterResponse.newsletter.newsletterReceivers
							.filter((item) => !item.isReciever && item.type === 1)
							.map((item) => ({ ...item, id: createGuid() })) || [],
					recipientUsers:
						newsletterResponse.newsletter.newsletterReceivers
							.filter((item) => !item.isReciever && item.type === 0)
							.map((item) => ({ ...item, id: createGuid() })) || [],
					blockRada:
						newsletterResponse.newsletter.newsletterReceivers
							.filter((item) => item.isBlocked && item.type === 1)
							.map((item) => ({ ...item, id: createGuid() })) || [],
					blockUsers:
						newsletterResponse.newsletter.newsletterReceivers
							.filter((item) => item.isBlocked && item.type === 0)
							.map((item) => ({ ...item, id: createGuid() })) || [],
					banner: newsletterResponse.newsletter.hasBanner
						? {
								id: 'banner',
								name: (newsletterResponse.newsletter as any).bannerPhotoName,
								size: (newsletterResponse.newsletter as any).bannerPhotoSize,
								url: (newsletterResponse.newsletter as any).bannerPhotoUrl,
							}
						: null,
					image: newsletterResponse.newsletter.hasImage
						? {
								id: 'image',
								name: (newsletterResponse.newsletter as any).bannerPhotoName,
								size: (newsletterResponse.newsletter as any).bannerPhotoSize,
								url: (newsletterResponse.newsletter as any).bannerPhotoUrl,
							}
						: null,
				};

				if (newsletterResponse.newsletter.scheduleSentDate) {
					newsletter = {
						...newsletter,
						whenToSend: 1,
						scheduleDate: newsletterResponse.newsletter.scheduleSentDate,
						scheduleTime: !!newsletterResponse.newsletter.scheduleSentDate
							? new Date(newsletterResponse.newsletter.scheduleSentDate).toLocaleTimeString('en-GB', {
									hour: '2-digit',
									minute: '2-digit',
									hour12: false,
								})
							: null,
					};
				} else {
					newsletter = {
						...newsletter,
						whenToSend: 0,
					};
				}

				if (useFor !== 'reuse') {
					newsletter.newsletterId = newsletterResponse.newsletterId;
				}

				return fromActions.preparing.prepareContent({ newsletter, newsletterResponse, shouldFetchSettingsData });
			})
		)
	);
	public prepareContentFromTemplate$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.preparing.prepareContentFromTemplate),
			map(({ template }) => {
				let newsletter: RhNewsletterForm = {
					...EMPTY_NEWSLETTER_FORM,
					...template,
					feedbackSettings: template.newsletterTemplateFeedbackSection,
					banner: template.hasBanner
						? {
								id: 'banner',
								name: (template as any).bannerPhotoName,
								size: (template as any).bannerPhotoSize,
								url: (template as any).bannerPhotoUrl,
							}
						: null,
					image: template.hasImage
						? {
								id: 'image',
								name: (template as any).bannerPhotoName,
								size: (template as any).bannerPhotoSize,
								url: (template as any).bannerPhotoUrl,
							}
						: null,
				};
				const isJsonString = (str) => {
					try {
						JSON.parse(str);
					} catch (e) {
						return false;
					}
					return true;
				};
				let content = isJsonString(newsletter.content) ? JSON.parse(newsletter.content) : null;
				let sections = content?.sections || [];
				let blocks = sections.reduce(
					(acc, curr) => [
						...acc,
						...curr.subsections.reduce(
							(accss, currss) => [
								...accss,
								...currss.blocks.map((b) => ({
									...b,
									sectionId: curr.id,
									subsectionId: currss.id,
								})),
							],
							[]
						),
					],
					[]
				);
				return fromActions.preparing.prepareBlocks({ newsletter, sections, blocks, shouldFetchSettingsData: true });
			})
		)
	);

	public prepareContent$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.preparing.prepareContent),
			map(({ newsletter, newsletterResponse, shouldFetchSettingsData }) => {
				const isJsonString = (str) => {
					try {
						JSON.parse(str);
					} catch (e) {
						return false;
					}
					return true;
				};
				let content = isJsonString(newsletter.content) ? JSON.parse(newsletter.content) : null;
				let sections = content.sections;
				let blocks = sections.reduce(
					(acc, curr) => [
						...acc,
						...curr.subsections.reduce(
							(accss, currss) => [
								...accss,
								...currss.blocks.map((b) => ({
									...b,
									sectionId: curr.id,
									subsectionId: currss.id,
								})),
							],
							[]
						),
					],
					[]
				);
				return fromActions.preparing.prepareBlocks({ newsletter, sections, blocks, shouldFetchSettingsData });
			})
		)
	);

	public prepareSimpleBlocks$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.preparing.prepareBlocks),
			mergeMap(({ newsletter, sections, blocks, shouldFetchSettingsData }) => {
				blocks = blocks.map((block) => ({
					...block,
					settings: BLOCK_MARKUP_TO_SETTINGS[block.type](block.markup),
				}));
				let actions = !shouldFetchSettingsData
					? []
					: blocks
							.filter((b) => b.type === BlockTypes.NEWS || b.type === BlockTypes.EVENT || b.type === BlockTypes.CHANNEL)
							.map((b) =>
								fromActions.preparing.fetchSettingsData({ id: b.id, blockType: b.type, settings: b.settings })
							);
				return [fromActions.preparing.finish({ newsletter, sections, blocks }), ...actions];
			})
		)
	);

	public fetchNewsSettingsData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.preparing.fetchSettingsData),
			filter(({ blockType }) => blockType === BlockTypes.NEWS),
			tap(({ id, settings }) =>
				this.store$.dispatch(fromChannelsNews.addChannel({ channelId: settings.news.channelId }))
			),
			concatMap(({ id, settings }) =>
				this.store$.pipe(
					select(fromChannelsNews.selectNewsAll(settings.news.channelId)),
					first((news) => !!news && news.length > 0),
					map((news) => ({
						id,
						settings: {
							...settings,
							news: news.find((n) => n.newsId === settings.news.newsId),
						},
					}))
				)
			),
			map(({ id, settings }) => {
				return fromBlockActions.updateBlockSettings({ blockGuid: id, settings });
			})
		)
	);

	public fetchEventSettingsData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.preparing.fetchSettingsData),
			filter(({ blockType }) => blockType === BlockTypes.EVENT),
			concatMap(({ id, settings }) =>
				this.eventService.getById(settings.event.id).pipe(
					first((event) => !!event),
					map((event) => ({
						id,
						settings: {
							...settings,
							event: {
								id: event.id,
								imageUrl: event.imageUrl,
								eventUrl: event.eventUrl,
								title: event.title,
								allDay: event.allDay,
								date: event.date,
							},
						},
					}))
				)
			),
			map(({ id, settings }) => {
				return fromBlockActions.updateBlockSettings({ blockGuid: id, settings });
			})
		)
	);

	public fetchChannelSettingsData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.preparing.fetchSettingsData),
			filter(({ blockType }) => blockType === BlockTypes.CHANNEL),
			tap(({ id, settings }) => this.store$.dispatch(fromChannelsNews.addChannel({ channelId: settings.channel.id }))),
			concatMap(({ id, settings }) =>
				this.store$.pipe(
					select(fromChannelsNews.selectChannelEntityById, { id: settings.channel.id }),
					first((channel) => !!channel),
					map((channel) => ({
						id,
						settings: {
							...settings,
							channel,
						},
					}))
				)
			),
			concatMap(({ id, settings }) =>
				this.store$.pipe(
					select(fromChannelsNews.selectNewsAll(settings.channel.id)),
					first((news) => !!news && news.length > 0),
					map((news) => ({
						id,
						settings: {
							...settings,
							selectedNews: settings.selectedNews.map((selected) => ({
								language: selected.language,
								news: news.find((n) => n.newsId === selected.news.newsId),
							})),
						},
					}))
				)
			),
			map(({ id, settings }) => {
				return fromBlockActions.updateBlockSettings({ blockGuid: id, settings });
			})
		)
	);

	public finishPreparing$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.preparing.finish),
			mergeMap(({ newsletter, sections, blocks }) => {
				let newSections = sections.map((section) => ({
					...section,
					subsections: section.subsections.map((subsection) => ({
						...subsection,
						blocks: blocks.filter((b) => b.sectionId === section.id && b.subsectionId === subsection.id),
					})),
				}));
				console.log(newsletter);

				if (newsletter.newsletterId) {
					return [
						fromActions.getNewsletterById.success({ newsletter: { ...(newsletter as RhNewsletterForm) } }),
						fromActions.form.setInitialValue({ form: { ...(newsletter as RhNewsletterForm) } }),
						fromBlockActions.loadNewsletterSections({ sections: newSections }),
						fromActions.form.setStatusesForSteps({ steps: [0, 1, 2, 3, 4] }),
					];
				} else {
					return [
						fromActions.getNewsletterById.success({ newsletter: { ...(newsletter as RhNewsletterForm) } }),
						fromActions.form.setInitialValue({ form: { ...(newsletter as RhNewsletterForm) } }),
						fromBlockActions.loadNewsletterSections({ sections: newSections }),
					];
				}
				return [];
			})
		)
	);

	public openRemappingDecisionDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.remapping.openDecisionDialog),
				map(({ legacyNewsletter }) => {
					const dialog = this.dialogService.open(NewsletterLegacyRemappingDialogContainer, {
						data: {
							mode: 'decision',
							legacyNewsletter,
						},
					});
					return { legacyNewsletter, dialog };
				}),
				switchMap(({ legacyNewsletter, dialog }) =>
					dialog.afterClosed().pipe(
						map((data: { reason: 'cancel' | 'start' | 'finish' }) => {
							return { legacyNewsletter, data };
						})
					)
				),
				mergeMap(({ legacyNewsletter, data }) => {
					switch (data.reason) {
						case 'start':
							return [fromActions.remapping.start({ legacyNewsletter })];
						case 'cancel':
							return [fromRouter.go({ path: 'newsletter', queryParams: {} })];
					}
				})
			),
		{ dispatch: true }
	);

	public remappingStart$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.remapping.start),
			map(({ legacyNewsletter }) => {
				const progressDialogRef = this.dialogService.open(NewsletterLegacyRemappingDialogContainer, {
					data: { mode: 'progress' },
					disableClose: true,
				});
				return fromActions.remapping.generalCheckStep({
					legacyNewsletter,
					progressDialogRef,
				});
			})
		)
	);

	public generalCheckStep = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.remapping.generalCheckStep),
			map(({ legacyNewsletter, progressDialogRef }) => {
				let newNewsletter: Partial<RhNewsletterForm> = {
					banner: null,
					templateId: 'NewsletterTemplateNew',
					blockRada: [], //blockrecievers
					blockUsers: [], //blockrecievers
					campaignId: null,
					feedbackIncluded: legacyNewsletter.feedbackIncluded,
					feedbackSettings: legacyNewsletter.feedbackIncluded
						? {
								feedbackType: 0,
								contact: legacyNewsletter.contactEmailForFeedback, //only for type 0
								title: 'Send us your feedback',
								question: 'What would you like to read about? Do you have any ideas? We would love to hear from you.',
								answers: [], //Array<string> only for type 3
							}
						: null,
					disclaimer:
						'The information transmitted in this message is intended only for the person or entity to which it is addressed and may contain confidential and/or privileged material. Any review, re-transmission dissemination or other use of, or taking of any action in reliance upon, this information by persons or entities other than the intended recipient is prohibited. If you receive this message in error, please contact the sender and delete the material from any computer.',
					fromEmail: legacyNewsletter.from,
					fromName: legacyNewsletter.fromName,
					hasBanner: !legacyNewsletter.titleIncluded,
					hasFeedback: legacyNewsletter.feedbackIncluded,
					hasImage: !!legacyNewsletter.bannerPhotoUrl,
					id: legacyNewsletter.newsletterId,
					hasSenderDisclaimer: true,
					image: null,
					isContributed: legacyNewsletter.isContributed,
					previewText: legacyNewsletter.previewText,
					replyToEmail: legacyNewsletter.replyTo,
					replyToName: legacyNewsletter.replyToName,
					subject: legacyNewsletter.subject,
					title: legacyNewsletter.title,
					recipientRada: [], //recievers
					recipientUsers: [], //recievers
				};

				return fromActions.remapping.imageCheckStep({
					legacyNewsletter,
					newNewsletter: newNewsletter as RhNewsletterForm,
					progressDialogRef,
				});
			})
		)
	);

	public imageCheckStep$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.remapping.imageCheckStep),
			map(({ legacyNewsletter, newNewsletter, progressDialogRef }) => {
				let correctRatio = legacyNewsletter.titleIncluded ? +(270 / 164).toFixed(2) : +(750 / 205).toFixed(2);
				let image = new Image();
				image.src = legacyNewsletter.bannerPhotoUrl;
				return { legacyNewsletter, newNewsletter, progressDialogRef, correctRatio, image };
			}),
			switchMap(({ legacyNewsletter, newNewsletter, progressDialogRef, correctRatio, image }) =>
				from(image.decode()).pipe(
					map(() => {
						let currentRatio = +(image.naturalWidth / image.naturalHeight).toFixed(2);
						let isRatioMatching = currentRatio === correctRatio;
						let results: Partial<RhNewsletterRemappingResults> = {
							image: {
								imageNotExist: false,
								isRatioMatching,
								correctRatio,
								currentRatio,
							},
						};
						return fromActions.remapping.radaCheckStep({
							legacyNewsletter,
							newNewsletter: newNewsletter as RhNewsletterForm,
							results,
							progressDialogRef,
						});
					}),
					catchError(() =>
						of(
							fromActions.remapping.radaCheckStep({
								legacyNewsletter,
								newNewsletter: newNewsletter as RhNewsletterForm,
								results: {
									image: {
										imageNotExist: true,
										isRatioMatching: false,
										correctRatio,
										currentRatio: null,
									},
								},
								progressDialogRef,
							})
						)
					)
				)
			)
		)
	);

	public radaCheckStep$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.remapping.radaCheckStep),
			mergeMap(({ progressDialogRef, legacyNewsletter, newNewsletter, results }) => {
				let rada: Array<string> = legacyNewsletter.toGroups?.split(',') || [];
				const radaDetails$ = rada
					.filter((email) => email.length > 0)
					.map((email) =>
						this.newsletterService.getRada(email).pipe(
							map((rada) => ({
								email: rada.email,
								name: rada.name,
								description: rada.description,
								type: 1,
							})),
							catchError((error) => of({ email, type: 3 }))
						)
					);
				return radaDetails$.length > 0
					? forkJoin(radaDetails$).pipe(
							map((radaDetails) => {
								return fromActions.remapping.usersCheckStep({
									legacyNewsletter,
									newNewsletter: {
										...newNewsletter,
										recipientRada: radaDetails.filter((d) => d.type !== 3),
									} as RhNewsletterForm,
									results: {
										...results,
										radaGroups: {
											missing: radaDetails.filter((d) => d.type === 3).map((d) => d.email),
										},
									},
									progressDialogRef,
								});
							})
						)
					: of(
							fromActions.remapping.usersCheckStep({
								legacyNewsletter,
								newNewsletter,
								results: {
									...results,
									radaGroups: {
										missing: [],
									},
								},
								progressDialogRef,
							})
						);
			})
		)
	);

	public usersCheckStep$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.remapping.usersCheckStep),
			mergeMap(({ progressDialogRef, legacyNewsletter, newNewsletter, results }) => {
				let users: Array<string> = legacyNewsletter.to?.split(',') || [];
				const usersDetails$ = users
					.filter((email) => email.length > 0)
					.map((email) =>
						this.workdayService.getByEmail(email).pipe(
							map((user) => ({
								id: createGuid(),
								email: user.email,
								name: user.firstName,
								surname: user.lastName,
								type: 0,
							})),
							catchError((error) =>
								of({
									id: createGuid(),
									email: email,
									name: null,
									srname: null,
									type: 0,
								})
							)
						)
					);
				return usersDetails$.length > 0
					? forkJoin(usersDetails$).pipe(
							map((usersDetails) => {
								return {
									legacyNewsletter,
									newNewsletter: {
										...newNewsletter,
										recipientUsers: usersDetails,
									} as RhNewsletterForm,
									results,
									progressDialogRef,
								};
							})
						)
					: of({ legacyNewsletter, newNewsletter: newNewsletter as RhNewsletterForm, results, progressDialogRef });
			}),
			map(({ legacyNewsletter, newNewsletter, results, progressDialogRef }) => {
				console.log(legacyNewsletter.templateId);
				switch (legacyNewsletter.templateId) {
					case 'NewsletterTemplateInlineEditor':
						return fromActions.remapping.contentCheckStep({
							legacyNewsletter,
							newNewsletter,
							results,
							progressDialogRef,
						});
					case 'NewsletterTemplateWithSections':
					case 'NewsletterTemplateWithoutSections':
						return fromActions.remapping.sectionCheckStep({
							legacyNewsletter,
							newNewsletter,
							results,
							progressDialogRef,
						});
				}
			})
		)
	);

	public sectionCheckStep$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.remapping.sectionCheckStep),
			exhaustMap(({ legacyNewsletter, newNewsletter, results, progressDialogRef }) => {
				console.log(legacyNewsletter);
				let content = JSON.parse(legacyNewsletter.content as any);
				const channelsToFetch = [
					...new Set<string>(
						content.sections.reduce(
							(acc, curr) => [
								...acc,
								...curr.news.reduce((accNews, currNews) => [...accNews, ...currNews.channelId], []),
							],
							[]
						)
					),
				];
				const channelsWithNews$ = channelsToFetch.map((channelId) => {
					this.store$.dispatch(fromChannelsNews.addChannel({ channelId }));
					return this.store$.pipe(
						select(fromChannelsNews.isChannelAndNewsLoaded(channelId)),
						first((isLoaded) => isLoaded),
						switchMap(() =>
							this.store$.pipe(
								select(fromChannelsNews.selectChannelEntityById, { id: channelId }),
								map((channel) => ({ channel }))
							)
						),
						switchMap(({ channel }) =>
							this.store$.pipe(
								select(fromChannelsNews.selectNewsAll(channelId)),
								map((news) => ({ channel, news }))
							)
						),
						first(({ channel, news }) => !!channel && news.length > 0),
						map(({ channel, news }) => ({ channel, news }))
					);
				});
				return forkJoin(channelsWithNews$).pipe(
					map((channelsNews) => {
						return {
							legacyNewsletter,
							newNewsletter,
							sections: content.sections,
							channelsNews,
							results,
							progressDialogRef,
						};
					})
				);
			}),
			map(({ legacyNewsletter, newNewsletter, sections, channelsNews, results, progressDialogRef }) => {
				let missingNews = [];
				let newSections = sections.map((section): Section => {
					let blocks = [];
					if (section.isAutomatic) {
						let channelData = channelsNews.find((c) => c.channel.id === section.news[0].channelId[0]);
						let selectedNews = [];
						section.news.forEach((news) => {
							let foundNews = channelData.news.find((cn) => cn.newsId === news.newsId[0]);
							if (foundNews) {
								selectedNews.push({
									news: foundNews,
									language: foundNews.primaryLanguage,
								});
							} else {
								missingNews.push(news);
							}
						});
						if (selectedNews.length > 0) {
							let block: Block<ChannelBlockProperties> = {
								id: createGuid(),
								type: BlockTypes.CHANNEL,
								settings: {
									channel: channelData.channel,
									addLink: !!section.sectionName,
									card: false,
									linkText: section.sectionName || '',
									selectionType: 'manual',
									selectedNews,
								},
							};
							blocks.push(block);
						}
					} else {
						if (section.sectionName) {
							blocks.push({
								id: createGuid(),
								type: BlockTypes.TEXT,
								settings: {},
								markup: `<h2>${section.sectionName}</h2>`,
							});
						}
						section.news.forEach((news) => {
							const channelData = channelsNews.find((c) => c.channel.id === news.channelId[0]);
							const foundNews = channelData.news.find((n) => n.newsId === news.newsId[0]);
							if (foundNews) {
								blocks.push({
									id: createGuid(),
									type: BlockTypes.NEWS,
									settings: {
										news: foundNews,
										newsLanguage: foundNews.primaryLanguage,
										card: false,
										flipped: false,
									},
								});
							} else {
								missingNews.push(news);
							}
						});
					}
					return {
						id: createGuid(),
						type: SectionTypes.SINGLE,
						background: {
							class: 'bg-white',
							hex: '#ffffff',
						},
						subsections: [
							{
								id: createGuid(),
								colspan: SubsectionTypes.PERCENT_100,
								blocks,
							},
						],
						verticalSpace: '16px',
					};
				});
				progressDialogRef.close();
				return {
					legacyNewsletter,
					newNewsletter,
					sections: newSections,
					results: {
						...results,
						news: {
							missing: missingNews,
						},
					},
				};
			}),
			mergeMap(({ legacyNewsletter, newNewsletter, sections, results }) => [
				fromActions.remapping.openResultDialog({ legacyNewsletter, newNewsletter, sections, results }),
			])
		)
	);

	public remapInline$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.remapping.contentCheckStep),
				map(({ legacyNewsletter, newNewsletter, results, progressDialogRef }) => {
					let content = JSON.parse(legacyNewsletter.content as any);
					let sections = content.sections.map((section) => ({
						...section,
						verticalSpace: '16px',
						background: {
							class: 'bg-white',
							color: '#ffffff',
						},
					}));

					let blocks = sections.reduce(
						(acc, curr) => [
							...acc,
							...curr.subsections.reduce(
								(accss, currss) => [
									...accss,
									...currss.blocks.map((b) => ({
										...b,
										sectionId: curr.id,
										subsectionId: currss.id,
									})),
								],
								[]
							),
						],
						[]
					);
					return { legacyNewsletter, newNewsletter, sections, blocks, results, progressDialogRef };
				}),
				mergeMap(({ legacyNewsletter, newNewsletter, sections, blocks, results, progressDialogRef }) => {
					const channelIds = blocks
						.filter((b) => b.type === BlockTypes.NEWS)
						.map((b) => b.markup.split("data-newsid='")[1].split("'")[0].split(':')[1]);
					const channelsToFetch = [...new Set(channelIds)];
					const channelsNews$ = channelsToFetch.map((channelId) =>
						this.newsboardNews.getNewsForChannel(+channelId).pipe(
							map((news) => ({
								[`news:${channelId}`]: news.map((n) => {
									return {
										...n,
										channelId: `news:${n.channelId}`,
										newsId: `news:${n.channelId}:${n.newsId}`,
										abstracts: n.abstracts.reduce(
											(result, item) => ({
												...result,
												[item.languageCode]: item,
											}),
											{}
										),
									};
								}),
							}))
						)
					);
					return channelsNews$.length > 0
						? forkJoin(channelsNews$).pipe(
								map((channelsNews) => {
									return {
										legacyNewsletter,
										newNewsletter,
										sections,
										blocks,
										channelsNews: channelsNews.reduce((acc, curr) => ({ ...acc, ...curr }), {}),
										results,
										progressDialogRef,
									};
								})
							)
						: of({ legacyNewsletter, newNewsletter, sections, blocks, channelsNews: {}, results, progressDialogRef });
				}),
				map(({ legacyNewsletter, newNewsletter, sections, blocks, channelsNews, results, progressDialogRef }) => {
					const correctBlocks = [];
					const incorrectBlocks = [];
					blocks.forEach((block) => {
						switch (block.type) {
							case BlockTypes.TEXT:
								correctBlocks.push({
									...block,
									settings: {},
								});
								break;
							case BlockTypes.DIVIDER:
							case BlockTypes.SPACER:
								correctBlocks.push({
									...block,
									settings: {
										spacing: '16px 0',
									},
								});
								break;
							case BlockTypes.IMAGE:
								correctBlocks.push({
									...block,
									settings: {
										photoUrl: block.markup.split("src='")[1].split("'")[0],
										link: block.markup.split("href='")[1]?.split("'")[0] || '',
									},
								});
								break;
							case BlockTypes.BUTTON_LINK:
								correctBlocks.push({
									...block,
									settings: {
										link: block.markup.split("href='")[1].split("'")[0],
										text: block.markup.split("blank'>")[1].split('<')[0],
										position: block.markup.split('text-align:')[1].split(';')[0],
										type: 'primary',
									},
								});
								break;
							case BlockTypes.NEWS:
								let newsId = block.markup.split("data-newsid='")[1].split("'")[0];
								let channelId = `news:${block.markup.split(':')[1]}`;
								const news = channelsNews[channelId].find((n) => n.newsId === newsId);
								if (!!news) {
									correctBlocks.push({
										...block,
										settings: {
											card: false,
											news,
											flipped: false,
										},
									});
								} else {
									incorrectBlocks.push(block);
								}
								break;
						}
					});
					progressDialogRef.close();
					return {
						legacyNewsletter,
						newNewsletter,
						sections: sections.map((section) => ({
							...section,
							subsections: section.subsections.map((subsection) => ({
								...subsection,
								blocks: correctBlocks.filter((b) => b.sectionId === section.id && b.subsectionId === subsection.id),
							})),
						})),
						results: {
							...results,
							news: {
								missing: incorrectBlocks,
							},
						},
					};
				}),
				mergeMap(({ legacyNewsletter, newNewsletter, sections, results }) => [
					fromActions.remapping.openResultDialog({ legacyNewsletter, newNewsletter, sections, results }),
				])
			),
		{ dispatch: true }
	);

	public openRemappingResultDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.remapping.openResultDialog),
				map(({ legacyNewsletter, newNewsletter, results, sections }) => {
					const dialog = this.dialogService.open(NewsletterLegacyRemappingDialogContainer, {
						data: {
							mode: 'result',
							legacyNewsletter,
							newNewsletter,
							results,
							sections,
						},
						size: 'l',
						height: '90%',
					});
					return { legacyNewsletter, newNewsletter, results, sections, dialog };
				}),
				switchMap(({ legacyNewsletter, results, sections, dialog }) =>
					dialog.afterClosed().pipe(
						map((data: { reason: 'cancel' | 'start' | 'finish'; newNewsletter: RhNewsletterForm }) => {
							return { legacyNewsletter, newNewsletter: data.newNewsletter, results, sections, data };
						})
					)
				),
				mergeMap(({ legacyNewsletter, newNewsletter, sections, data }) => {
					switch (data.reason) {
						case 'finish':
							return [
								fromActions.getNewsletterById.success({ newsletter: { ...(newNewsletter as RhNewsletterForm) } }),
								fromActions.form.setInitialValue({ form: { ...(newNewsletter as RhNewsletterForm) } }),
								...sections.map((section) => fromBlockActions.addSection({ section })),
								fromActions.remapping.finish(),
							];
						case 'cancel':
							return [fromRouter.go({ path: 'newsletter', queryParams: {} })];
					}
				})
			),
		{ dispatch: true }
	);

	public setValue$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.form.setValue),
				switchMap(({ form }) => {
					return [fromActions.form.setStatusesForSteps({ steps: [] })];
				})
			),
		{ dispatch: true }
	);

	public setCurrentStep$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.form.setCurrentStep),
				withLatestFrom(this.store$.pipe(select(fromSelectors.selectNewsletter))),
				switchMap(([{ currentStep, previousStep }, form]) => {
					return [fromActions.form.setStatusesForSteps({ steps: [currentStep, previousStep] })];
				})
			),
		{ dispatch: true }
	);

	public setStatusesForSteps$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.form.setStatusesForSteps),
				withLatestFrom(
					this.store$.pipe(select(fromSelectors.selectCurrentStep)),
					this.store$.pipe(select(fromSelectors.selectNewsletter)),
					this.store$.pipe(select(fromBlockSelectors.selectIsAnyBlockAdded))
				),
				switchMap(([{ steps, form = {} }, step, newsletter, isAnyBlockAdded]) => {
					const value = { ...newsletter, ...form };
					if (!steps.length) {
						steps = [step];
					}
					if (value.campaign) {
						if (!steps.includes(2)) {
							steps.push(2);
						}
						if (!steps.includes(3)) {
							steps.push(3);
						}
					}
					let arr = [];
					this.newsletterFormService.updateValidationHeaderFooterForm({ value });
					this.newsletterFormService.updateValidationRecipientsListForm({ value });
					this.newsletterFormService.updateValidationRecipientsListBlockedForm({ value });
					this.newsletterFormService.updateValidationPublishInfoForm({ value });
					steps.forEach((number) => {
						switch (number) {
							case 0:
								const headerFooter = this.newsletterFormService.validateHeaderFooter({
									form: this.newsletterFormService.headerFooterForm,
									patchValue: true,
									markAllAsTouched: true,
									value: { ...newsletter, ...form },
								});

								arr = [
									...arr,
									fromActions.form.setStepIsValid({
										step: NewsletterSteps.HEADER_FOOTER,
										status: headerFooter.status,
									}),
									fromActions.form.setStepStatus({
										step: NewsletterSteps.HEADER_FOOTER,
										status:
											step === 0
												? FormStepStatus.IN_PROGRESS
												: headerFooter.valid
													? FormStepStatus.COMPLETED
													: FormStepStatus.INCOMPLETE,
									}),
								];
								break;

							case 1:
								arr = [
									...arr,
									fromActions.form.setStepIsValid({
										step: NewsletterSteps.BODY,
										status: isAnyBlockAdded ? 'VALID' : 'INVALID',
									}),
									fromActions.form.setStepStatus({
										step: NewsletterSteps.BODY,
										status:
											step === 1
												? FormStepStatus.IN_PROGRESS
												: isAnyBlockAdded
													? FormStepStatus.COMPLETED
													: FormStepStatus.INCOMPLETE,
									}),
								];
								break;
							case 2:
								if (value.campaign) {
									arr = [
										...arr,
										fromActions.form.setStepIsValid({
											step: NewsletterSteps.RECIPIENTS_LIST,
											status: 'VALID',
										}),
										fromActions.form.setStepStatus({
											step: NewsletterSteps.RECIPIENTS_LIST,
											status: FormStepStatus.PREDEFINED,
										}),
									];
								} else {
									const recipients = this.newsletterFormService.validateRecipientsList({
										form: this.newsletterFormService.recipientsListForm,
										patchValue: true,
										markAllAsTouched: true,
										value: { ...newsletter, ...form },
									});

									arr = [
										...arr,
										fromActions.form.setStepIsValid({
											step: NewsletterSteps.RECIPIENTS_LIST,
											status: recipients.status,
										}),
										fromActions.form.setStepStatus({
											step: NewsletterSteps.RECIPIENTS_LIST,
											status:
												step === 2
													? FormStepStatus.IN_PROGRESS
													: recipients.valid
														? FormStepStatus.COMPLETED
														: FormStepStatus.INCOMPLETE,
										}),
									];
								}
								break;
							case 3:
								if (value.campaign) {
									arr = [
										...arr,
										fromActions.form.setStepIsValid({
											step: NewsletterSteps.BLOCK_USERS,
											status: 'VALID',
										}),
										fromActions.form.setStepStatus({
											step: NewsletterSteps.BLOCK_USERS,
											status: FormStepStatus.PREDEFINED,
										}),
									];
								} else {
									const block = this.newsletterFormService.validateRecipientsListBlocked({
										form: this.newsletterFormService.recipientsListBlockedForm,
										patchValue: true,
										markAllAsTouched: true,
										value: { ...newsletter, ...form },
									});

									arr = [
										...arr,
										fromActions.form.setStepIsValid({
											step: NewsletterSteps.BLOCK_USERS,
											status: block.status,
										}),
										fromActions.form.setStepStatus({
											step: NewsletterSteps.BLOCK_USERS,
											status:
												step === 3
													? FormStepStatus.IN_PROGRESS
													: block.valid
														? FormStepStatus.COMPLETED
														: FormStepStatus.INCOMPLETE,
										}),
									];
								}
								break;
							case 4:
								const publishing = this.newsletterFormService.validatePublishInfo({
									form: this.newsletterFormService.publishInfoForm,
									patchValue: true,
									markAllAsTouched: true,
									value: { ...newsletter, ...form },
								});

								arr = [
									...arr,
									fromActions.form.setStepIsValid({
										step: NewsletterSteps.PUBLISHING_INFO,
										status: publishing.status,
									}),
									fromActions.form.setStepStatus({
										step: NewsletterSteps.PUBLISHING_INFO,
										status:
											step === 4
												? FormStepStatus.IN_PROGRESS
												: publishing.valid
													? FormStepStatus.COMPLETED
													: FormStepStatus.INCOMPLETE,
									}),
								];
								break;
						}
					});
					return arr;
				})
			),
		{ dispatch: true }
	);

	public setStatuses$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.form.setStatuses),
				withLatestFrom(
					this.store$.pipe(select(fromSelectors.selectCurrentStep)),
					this.store$.pipe(select(fromSelectors.selectNewsletter)),
					this.store$.pipe(select(fromBlockSelectors.selectIsAnyBlockAdded))
				),
				switchMap(([{ form }, step, newsletter, isAnyBlockAdded]) => {
					this.newsletterFormService.updateValidationHeaderFooterForm({ value: { ...newsletter, ...form } });
					this.newsletterFormService.updateValidationPublishInfoForm({ value: { ...newsletter, ...form } });
					this.newsletterFormService.updateValidationRecipientsListForm({ value: { ...newsletter, ...form } });
					this.newsletterFormService.updateValidationRecipientsListBlockedForm({ value: { ...newsletter, ...form } });
					const headerFooter = this.newsletterFormService.validateHeaderFooter({
						form: this.newsletterFormService.headerFooterForm,
						patchValue: true,
						markAllAsTouched: true,
						value: { ...newsletter, ...form },
					});
					const publishing = this.newsletterFormService.validatePublishInfo({
						form: this.newsletterFormService.publishInfoForm,
						patchValue: true,
						markAllAsTouched: true,
						value: { ...newsletter, ...form },
					});
					const recipients = this.newsletterFormService.validateRecipientsList({
						form: this.newsletterFormService.recipientsListForm,
						patchValue: true,
						markAllAsTouched: true,
						value: { ...newsletter, ...form },
					});
					const block = this.newsletterFormService.validateRecipientsListBlocked({
						form: this.newsletterFormService.recipientsListBlockedForm,
						patchValue: true,
						markAllAsTouched: true,
						value: { ...newsletter, ...form },
					});
					return [
						// validity
						fromActions.form.setStepIsValid({
							step: NewsletterSteps.HEADER_FOOTER,
							status: headerFooter.status,
						}),
						fromActions.form.setStepIsValid({
							step: NewsletterSteps.BODY,
							status: isAnyBlockAdded ? 'VALID' : 'INVALID',
						}),
						fromActions.form.setStepIsValid({
							step: NewsletterSteps.RECIPIENTS_LIST,
							status: recipients.status,
						}),
						fromActions.form.setStepIsValid({
							step: NewsletterSteps.BLOCK_USERS,
							status: block.status,
						}),
						fromActions.form.setStepIsValid({
							step: NewsletterSteps.PUBLISHING_INFO,
							status: publishing.status,
						}),
						// status
						fromActions.form.setStepStatus({
							step: NewsletterSteps.HEADER_FOOTER,
							status:
								step === 0
									? FormStepStatus.IN_PROGRESS
									: headerFooter.valid
										? FormStepStatus.COMPLETED
										: FormStepStatus.INCOMPLETE,
						}),
						fromActions.form.setStepStatus({
							step: NewsletterSteps.BODY,
							status:
								step === 1
									? FormStepStatus.IN_PROGRESS
									: isAnyBlockAdded
										? FormStepStatus.COMPLETED
										: FormStepStatus.INCOMPLETE,
						}),
						fromActions.form.setStepStatus({
							step: NewsletterSteps.RECIPIENTS_LIST,
							status:
								step === 2
									? FormStepStatus.IN_PROGRESS
									: recipients.valid
										? FormStepStatus.COMPLETED
										: FormStepStatus.INCOMPLETE,
						}),
						fromActions.form.setStepStatus({
							step: NewsletterSteps.BLOCK_USERS,
							status:
								step === 3
									? FormStepStatus.IN_PROGRESS
									: block.valid
										? FormStepStatus.COMPLETED
										: FormStepStatus.INCOMPLETE,
						}),
						fromActions.form.setStepStatus({
							step: NewsletterSteps.PUBLISHING_INFO,
							status:
								step === 4
									? FormStepStatus.IN_PROGRESS
									: publishing.valid
										? FormStepStatus.COMPLETED
										: FormStepStatus.INCOMPLETE,
						}),
					];
				})
			),
		{ dispatch: true }
	);

	public openDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.dialog.openDialog),
				withLatestFrom(this.store$.pipe(select(fromCampaign.selectSelectionListData))),
				skipUntil(this.store$.pipe(select(fromCampaign.selectSelectionListLoaded))),
				map(([{}, campaigns]) => {
					return {
						campaigns: campaigns.filter((c) => c.status === undefined || c.status === CAMPAIGN_STATUS.ACTIVE),
					};
				}),
				switchMap(({ campaigns }) => {
					if (!campaigns.length) {
						return [fromActions.dialog.openEmptyCampaignDialog()];
					}
					return [fromActions.dialog.openSelectDialog({ campaigns })];
				})
			),
		{ dispatch: true }
	);

	public openEmptyCampaignDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.dialog.openEmptyCampaignDialog),

				map(() => {
					const data: ConfirmDialogData = {
						ids: [],
						messages: [
							`Looks like you don't have any campaigns assigned to you.`,
							'Would you like to create your first campaign?',
						],
						title: 'No campaign available',
						confirmButtonLabel: 'Create new campaign',
						confirmButtonType: 'primary',
					};
					const dialog = this.dialogService.open(ConfirmDialogComponent, {
						data,
					});
					return { dialog };
				}),
				switchMap(({ dialog }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((data: ConfirmDialogData) => {
							return { data };
						})
					)
				),
				map(({ data }) => fromRouter.go({ path: 'newsletter/campaign/create', queryParams: {} }))
			),
		{ dispatch: true }
	);

	public openSelectDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.dialog.openSelectDialog),
				map(({ campaigns }) => {
					const data = {
						selects: [
							{
								selectType: 'select',
								prop: 'id',
								options: campaigns,
								multiple: false,
								required: true,
								entityType: 'campaign-list',
								tooltipInfo: null,
								label: 'Select the campaign',
							},
						],
						confirmButtonLabel: 'Create newsletter',
					};
					const dialog = this.dialogService.open(SelectDialogComponent, {
						size: 'xl',
						height: '60%',
						data,
					});
					return { dialog };
				}),
				switchMap(({ dialog }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((data) => data.selects[0].value),
						switchMap((data) => [
							fromActions.form.setValue({ form: { campaignName: data.name, campaignId: data.id } }),
							fromRouter.go({
								path: 'newsletter/newsletter/create/inline',
								queryParams: { campaignId: data.id },
							}),
						])
					)
				)
			),
		{ dispatch: true }
	);

	public openFeedbackDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.dialog.openFeedbackDialog),
				withLatestFrom(this.store$.pipe(select(fromSelectors.selectFeedbackSettings))),
				map(([{}, feedbackSettings]) => {
					console.log(feedbackSettings);
					const data: ManageDialogData = {
						title: 'Manage Feedback section',
						confirmButtonLabel: 'Add Feedback section',
						feedbackSettings: feedbackSettings.feedbackType === null ? DEFAULT_FEEDBACK_SETTINGS : feedbackSettings,
					};
					const dialog = this.dialogService.open(ManageFeedbackSectionDialogComponent, {
						data,
						size: 'xl',
					});
					return { dialog, feedbackSettings };
				}),
				switchMap(({ dialog, feedbackSettings }) =>
					dialog.afterClosed().pipe(
						map((feedbackSettingsNew) => {
							if (!!feedbackSettingsNew) {
								const form = { feedbackSettings: feedbackSettingsNew };
								return fromActions.form.setValue({ form });
							} else {
								if (this.newsletterFormService.isValidFeedbackSettings(feedbackSettings)) {
									const form = { feedbackSettings };
									return fromActions.form.setValue({ form });
								} else {
									const form = { hasFeedback: false };
									return fromActions.form.setValue({ form });
								}
							}
						})
					)
				)
			),
		{ dispatch: true }
	);

	public openPublishDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.publish.dialog),
				withLatestFrom(
					this.store$.pipe(select(fromSelectors.selectRecipientsListForm)),
					this.store$.pipe(select(fromForm.selectCampaign))
				),
				map(([action, recipientsForm, campaign]) => {
					let recipientsCount = recipientsForm.recipientUsers?.length;
					let recipientsRadaCount = recipientsForm.recipientRada?.length;
					let recipientsLists = [];
					if (campaign) {
						recipientsLists = campaign.recipientLists;
					}

					const data: ConfirmSendDialogData = {
						recipientsCount: recipientsCount,
						recipientsRadaCount: recipientsRadaCount,
						recipientsLists: recipientsLists,
						cancelButtonLabel: 'Cancel',
						confirmButtonLabel: 'Yes, send',
					};

					const dialog = this.dialogService.open(ConfirmSendDialogComponent, {
						data,
						size: 'l',
						height: '70%',
						maxHeight: '70%',
					});
					return { dialog };
				}),
				switchMap(({ dialog }) =>
					dialog.afterClosed().pipe(
						map((data) => {
							if (data) {
								return fromActions.publish.request();
							}
						})
					)
				)
			),
		{ dispatch: true }
	);

	public publish$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.publish.request),
				withLatestFrom(
					this.store$.pipe(select(fromSelectors.selectFormForRequest)),
					this.store$.pipe(select(fromBlockSelectors.selectSections))
				),
				switchMap(([action, newsletter, sections]) => {
					if (newsletter.newsletterId) {
						return this.newsletterService.publishInlineFromDraftNew(newsletter, sections).pipe(
							mergeMap(() => [
								fromActions.publish.success(),

								fromRouter.go({
									path: 'newsletter',
									queryParams: {},
								}),
							]),
							catchError(({ message }) => of(fromActions.publish.failure({ message })))
						);
					} else {
						return this.newsletterService.publishInlineNew(newsletter, sections).pipe(
							mergeMap(() => [
								fromActions.publish.success(),

								fromRouter.go({
									path: 'newsletter',
									queryParams: {},
								}),
							]),
							catchError(({ message }) => of(fromActions.publish.failure({ message })))
						);
					}
				})
			),
		{ dispatch: true }
	);

	public saveAsDraft$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.saveAsDraft.request),
				withLatestFrom(this.store$.pipe(select(fromSelectors.selectFormForRequest))),
				withLatestFrom(this.store$.pipe(select(fromBlockSelectors.selectSections))),
				switchMap(([[action, newsletter], sections]) => {
					return this.newsletterService.createDraftInlineNew(newsletter, sections).pipe(
						mergeMap(() => [
							fromActions.saveAsDraft.success({ newsletter }),
							// fromWS.wsStopWork({ newsletterId: data.form.newsletterId }),
							// fromDashboard.getDrafts(),
							fromRouter.go({
								path: 'newsletter/newsletters',
								queryParams: {},
							}),
						]),
						catchError(({ message }) => of(fromActions.saveAsDraft.failure({ message })))
					);
				})
			),
		{ dispatch: true }
	);

	public openSendTestDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.sendTest.openDialog),
				withLatestFrom(this.store$.pipe(select(fromUser.selectUserProfile))),
				map(([action, defaultUser]) => {
					const data: SendTestDialogData = {
						title: 'Send test Newsletter',
						confirmButtonLabel: 'Send test Newsletter',
						defaultUser,
					};

					const dialog = this.dialogService.open(SendTestDialogComponent, {
						data,
						size: 'l',
						height: '100%',
						maxHeight: '100%',
					});
					return { dialog };
				}),
				switchMap(({ dialog }) =>
					dialog.afterClosed().pipe(
						map((data) => {
							if (data) {
								return fromActions.sendTest.request({ user: data[0] });
							}
						})
					)
				)
			),
		{ dispatch: true }
	);

	public sendTest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.sendTest.request),
				withLatestFrom(
					this.store$.pipe(select(fromSelectors.selectFormForRequest)),
					this.store$.pipe(select(fromBlockSelectors.selectSections))
				),
				switchMap(([{ user }, newsletter, sections]) => {
					return this.newsletterService
						.sendTestNew(
							{
								...newsletter,
								receivers: [
									{
										email: user.email,
										isBlocked: false,
										name: user.firstName,
										surname: user.lastName,
										type: 0,
									},
								],
							},
							sections
						)
						.pipe(
							map(() => fromActions.sendTest.success()),
							catchError(({ message }) => of(fromActions.saveAsDraft.failure({ message })))
						);
				})
			),
		{ dispatch: true }
	);

	public getCampaign$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.getCampaignById.request),
				switchMap(({ id }) =>
					this.newsletterService.getCampaign(id).pipe(
						switchMap((campaign) => {
							console.log(campaign.newsletterTemplateId, campaign.newsletterTemplate?.id);
							if (campaign.newsletterTemplateId || campaign.newsletterTemplate?.id) {
								return [
									fromActions.getTemplateById.request({
										id: campaign.newsletterTemplateId || campaign.newsletterTemplate?.id,
										campaign,
									}),
								];
							}
							return [
								fromActions.getCampaignById.success({ campaign }),
								fromActions.form.setStatusesForSteps({ steps: [2, 3] }),
							];
						}),
						catchError(({ message }) => of(fromActions.getCampaignById.failure({ message })))
					)
				)
			),
		{ dispatch: true }
	);

	public getTemplate$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.getTemplateById.request),
				switchMap(({ id, campaign }) =>
					this.newsletterService.getTemplateById(id).pipe(
						switchMap((template) => {
							return [
								fromActions.preparing.prepareContentFromTemplate({ template: { ...template, id: null } }),
								fromActions.getTemplateById.success({ template: { ...template, id: null } }),
								fromActions.getCampaignById.success({ campaign }),
								fromActions.form.setStatusesForSteps({ steps: [0] }),
							];
						}),
						catchError(({ message }) => of(fromActions.getCampaignById.success({ campaign })))
					)
				)
			),
		{ dispatch: true }
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromReducer.State>,
		private newsletterService: NewsletterService,
		private newsboardNews: NewsboardNewsService,
		private eventService: EventsService,
		private workdayService: WorkdayService,
		private dialogService: RdsDialogService,
		private newsletterFormService: NewsletterFormService
	) {}
}
