<div class="newsletter-container" [ngClass]="overrideColor? overrideColor.label : newsletter.color">
  <ng-container *ngIf="newsletter.rocheLogoInHeader">
    <table class="logo-table">
      <tr>
        <td class="logo-cell">
          <img [src]="rocheLogo" width="58" height="30"/>
        </td>
      </tr>
    </table>
  </ng-container>

  <table class="header-table">
    <ng-container *ngIf="newsletter.titleIncluded">
    <tr>
      <td class="header-title-cell">
        <h1 class="header-title text-overflow-wrap">{{newsletter?.title || 'Newsletter title'}}</h1>
      </td>
    </tr>
    </ng-container>
    <tr>
      <td>
        <img *ngIf="newsletter.bannerPhotoUrl; else bannerPlaceholder" crossorigin="anonymous" [src]="newsletter.bannerPhotoUrl + '?v=' + now" alt=""
             class="header-image">
      </td>
    </tr>
  </table>

  <ng-container *ngIf="newsletter.content.sections[0] as section ">
    <table class="section-table">
      <tr *ngIf="newsletter.descriptionNoteIncluded && newsletter.descriptionNote">
        <td colspan="2" class="section-title-description description-note">
          <span [innerHTML]="newsletter.descriptionNote | insertLinks"></span>
        </td>
      </tr>
      <tr>
        <td class="section-cell">
          <ng-container *ngFor="let news of section.news; let i = index;">
            <table class="news-table">
              <tr>
                <td class="news-image-cell">
                  <ng-container *ngIf="news.title">
                    <div *ngIf="!newsboardFix" style='width: 100%; padding-top: 56.25%; background-size:cover; margin-bottom: 16px; border-radius: 4px; border: 0.5px solid #dbd6d1;' [style.background-image]="'url(' + news.imageUrl || placeholderImage + ')'" data-nbimg></div>
                    <div *ngIf="newsboardFix" style='width: 100%; padding-top: 56.25%; background-size:cover; margin-bottom: 16px; border-radius: 4px; border: 0.5px solid #dbd6d1;' [style.background-image]="'url('+ placeholderImage + ')'" data-nbimg></div>
                  </ng-container>
                  <ng-container *ngIf="!news.title">
                    <div class="placeholder-image news">
                      <rds-icon class="placeholder-icon" icon="image" namespace="outlined"></rds-icon>
                    </div>
                  </ng-container>
                </td>
                <td class="news-info-cell">
                  <h3 class="news-title">
                    <a [href]="newsboardFix? '' : news.linkUrl"
                       target="_blank">{{news.title || 'News ' + incrementIndex(i) + ' - Title'}}</a>
                  </h3>
                  <p class="news-description">
                    {{news.description || 'Abstract for News ' + incrementIndex(i)}}
                  </p>
                </td>
              </tr>
            </table>
          </ng-container>
        </td>
      </tr>
    </table>
  </ng-container>
  <ng-container *ngIf="newsletter.feedbackIncluded">
    <table class="feedback-table">
      <tr>
        <td class="feedback-cell">
          <h2 class="feedback-title">
            Send us your feedback!
          </h2>
          <p class="feedback-info">
            What would you like to read about? Do you have any ideas? <br/>
            We would love to hear from you.
            <br/>
            Reach us at: <a style="pointer-events: none;"
                            href="mailto:group.comms-newsletter@roche.com">{{newsletter?.contactEmailForFeedback || 'Add a contact email'}}</a>
          </p>
        </td>
      </tr>
    </table>
  </ng-container>
  <ng-container *ngIf="newsletter.disclaimerIncluded">
    <table class="disclaimer-table">
      <tr>
        <td class="disclaimer-cell">
          <p>Official disclaimer:</p>
          <p>This newsletter was sent by {{newsletter.sentByName || newsletter.senderName}}. The information transmitted
            in this message is
            intended only for the
            person or entity to which it is addressed and may contain confidential and/or privileged material. Any
            review,
            re-transmission dissemination or other use of, or taking of any action in reliance upon, this information by
            persons or entities other than the intended recipient is prohibited. If you receive this message in error,
            please contact the sender and delete the material from any computer.</p>
        </td>
      </tr>
    </table>
  </ng-container>
  <ng-container *ngIf="!newsletter.disclaimerIncluded">
    <table class="disclaimer-table">
      <tr>
        <td class="disclaimer-cell">
          <p>Sender disclaimer:</p>
          <p>This newsletter was sent by {{newsletter.sentByName || newsletter.senderName}}.</p>
        </td>
      </tr>
    </table>
  </ng-container>
</div>

<ng-template #bannerPlaceholder>
  <div class="placeholder-image banner">
    <rds-icon
      class="placeholder-icon"
      icon="image" namespace="outlined"></rds-icon>
  </div>
</ng-template>
