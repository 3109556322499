import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Language } from '@app/core/models/language.model';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SubSink } from 'subsink';

import * as fromGlobalSettings from '@app/user-settings/store/global-settings';
import * as fromLanguagesDict from '@core/core-store/languages-dict';
import { RdsSingleSelectFilterByFunc, RdsSingleSelectOptionComponent } from '@rds/angular-components';

@Component({
  selector: 'rh-language-news',
  templateUrl: './language-news.container.html',
  styleUrls: ['./language-news.container.scss'],
})
export class LanguageNewsContainer implements OnInit, OnDestroy {
  private subs = new SubSink();

  form: FormGroup = new FormGroup({
    newsLanguageId: new FormControl(null)
  })

  initialFormValue: {newsLanguageId: number};

  get nothingChanged() {
    return this.initialFormValue.newsLanguageId === this.form.controls.newsLanguageId.value;
  }
  
  public newsLanguage: Language;

  public form$: Observable<{newsLanguageId: number}> = this.store$.pipe(
    select(fromGlobalSettings.selectForm));
    
  public initialForm$: Observable<{newsLanguageId: number}> = this.store$.pipe(
      select(fromGlobalSettings.selectInitialForm));
  
  public languages$: Observable<Array<Language>> = this.store$.pipe(
    select(fromLanguagesDict.selectAll));

  ngOnInit() {
    this.store$.dispatch(fromGlobalSettings.initForm());
    this.store$.dispatch(fromLanguagesDict.loadLanguagesDict());

    this.subs.sink = this.form$.pipe(
      distinctUntilChanged((prev, next) => prev.newsLanguageId === next.newsLanguageId),
    ).subscribe(form => {
      this.form.patchValue({newsLanguageId: form.newsLanguageId}, {emitEvent: false});
    });

    this.subs.sink = this.initialForm$.pipe(
      distinctUntilChanged((prev, next) => prev.newsLanguageId === next.newsLanguageId),
    ).subscribe(form => {
      this.initialFormValue = form
    });

    this.subs.sink = this.form.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(50)
    ).subscribe((form)=> {
      this.store$.dispatch(fromGlobalSettings.setFormValue({form}))
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
  
  filterBy: RdsSingleSelectFilterByFunc<string> = (
    text: string | null,
    item: RdsSingleSelectOptionComponent<string>
  ) => {
    if (text === null || text === "") {
      return true;
    } else {
      return item.id.toLowerCase().includes(text.toLowerCase());
    }
  };

  cancel() {
    this.form.patchValue(this.initialFormValue)
  }

  save() {
    this.store$.dispatch(fromGlobalSettings.saveNewsLanguageRequest());
  }

  constructor(
    private store$: Store<fromGlobalSettings.State>,
    private cdr: ChangeDetectorRef
  ) { }
}
