import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromNewsletterTemplates from '@app/newsletter/store/newsletter-dashboard';
import {ColorModel, ColorsPalette} from '@app/newsletter/models';

@Component({
  selector: 'rh-newsletter-color-picker',
  templateUrl: './newsletter-color-picker.container.html',
  styleUrls: ['./newsletter-color-picker.container.scss']
})
export class NewsletterColorPickerContainer {
  public colorsPalette: ColorModel[] = ColorsPalette;
  @Input() active;
  @Input() selectedColor: ColorModel;
  @Input() emitSelect: boolean;
  @Input() isBox: true | null;
  @Output() colorChanged: EventEmitter<ColorModel> = new EventEmitter();


  public close(): void {
    this.store$.dispatch(fromNewsletterTemplates.changeTemplate({selectedTemplate: null}));
  }

  public onSelectColor(ev): void {
    if (this.emitSelect) {
      this.colorChanged.emit(ev ? ev : ColorsPalette[0]);
    } else {
      this.store$.dispatch(fromNewsletterTemplates.changeColor({
        selectedColor: ev ? ev : ColorsPalette[0]
      }));
    }

  }

  constructor(private store$: Store<fromNewsletterTemplates.State>) {
  }
}
