import { createFeatureSelector, combineReducers, Action } from '@ngrx/store';
import * as fromChannelTable from './channel-table/channel-table.reducer';
import * as fromChannelDetails from './channel-details/channel-details.reducer';
import * as fromForm from './form/form.reducer';

export const feature = 'channels';

export interface State {
  channelTable: fromChannelTable.State;
  channelDetails: fromChannelDetails.State;
  form: fromForm.State;
}

export function reducer(state: State | undefined, action: Action) {
  return combineReducers({
    channelTable: fromChannelTable.reducer,
    channelDetails: fromChannelDetails.reducer,
    form: fromForm.reducer,
  })(state, action);
}

export const selectState = createFeatureSelector<State>(feature);

export const getChannelTable = (state: State) => state.channelTable;
export const getChannelDetails = (state: State) => state.channelDetails;
export const getForm = (state: State) => state.form;
