import { createSelector } from '@ngrx/store';

import * as fromCore from '@core/core-store/core.reducer';
import * as fromReducer from './user-channels.reducer';

export const selectState = createSelector(
  fromCore.selectState,
  fromCore.getUserChannels
);

export const selectAll = createSelector(
  selectState,
  fromReducer.getAll
);

export const selectEntities = createSelector(
  selectState,
  fromReducer.getEntities
);

export const selectIds = createSelector(
  selectState,
  fromReducer.getIds
);

export const selectTotal = createSelector(
  selectState,
  fromReducer.getTotal
);

export const selectEntityById = createSelector(
  selectEntities,
  fromReducer.getEntityById
);

export const selectEntitiesById = createSelector(
  selectEntities,
  fromReducer.getEntitiesById
);
