import { createSelector } from '@ngrx/store';
import * as fromNewsletter from '@app/newsletter-new/store/newsletter.reducer';
import * as fromReducer from '@app/newsletter-new/store/mock/mock.reducer';

export const selectMock = createSelector(
  fromNewsletter.selectState,
  fromNewsletter.getMock
);

export const selectCampaignData = createSelector(
  selectMock,
  fromReducer.getCampaignData
);

export const selectRecipientListData = createSelector(
  selectMock,
  fromReducer.getRecipientListData
);

export const selectRecipientListDetailsData = createSelector(
  selectMock,
  fromReducer.getRecipientListDetailsData
);

export const selectRecipientListById = (id: number) => createSelector(
  selectRecipientListDetailsData,
  (data) => data.find(d => d.id === +id)
);

export const selectRecentlyAddedRecipientList = createSelector(
  selectRecipientListDetailsData,
  (data) => data.find(d => d.id === Math.max(...data.map(data => data.id)))
);