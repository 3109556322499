import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from '@env/environment';


@Directive({
  selector: '[atrIfEnvironment]'
})
export class IfEnvironmentDirective implements OnInit, OnDestroy {

  @Input('atrIfEnvironment') env: 'prod' | 'test' | 'dev' | Array<'prod' | 'test' | 'dev'>;


  ngOnInit() {
    if (this.env instanceof Array) {
      if (this.env.some(v => environment[v])) {
        this.viewContainerRef.clear();
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainerRef.clear();
      }
    } else {
      environment[this.env] ?
        this.viewContainerRef.createEmbeddedView(this.templateRef)
      :
        this.viewContainerRef.clear();
    }
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.changeDetectorRef.detach();
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private changeDetectorRef: ChangeDetectorRef
    ) { }

}
