import {createSelector} from '@ngrx/store';

import * as fromReducer from '@app/content-list/store/content-list.reducer';

export const selectSubType = createSelector(
  fromReducer.selectState,
  fromReducer.getSubType
)
export const selectId = createSelector(
  fromReducer.selectState,
  fromReducer.getValue
)

export const selectPagination = createSelector(
  fromReducer.selectState,
  ({pageIndex, pageSize}) => {
    return {
      pageIndex, pageSize
    }
  }
)

export const selectNews = createSelector(
  fromReducer.selectState,
  fromReducer.getNews,
);

export const selectData = createSelector(
  selectNews,
  fromReducer.selectAll
);

export const selectIsLoading = createSelector(
  fromReducer.selectState,
  fromReducer.getLoading
)

export const selectIsLast = createSelector(
  fromReducer.selectState,
  fromReducer.getIsLast
)

export const selectName = createSelector(
  fromReducer.selectState,
  fromReducer.getName
)
export const selectSortType = createSelector(
  fromReducer.selectState,
  fromReducer.getSortType
)
export const selectIsMandatory = createSelector(
  fromReducer.selectState,
  fromReducer.getIsMandatory
)
