<div class="tile list">
  <div class="tile__image" *atrIfViewMode="['DESKTOP','TABLET']">
    <ng-content select="[image]"></ng-content>
  </div>
  <div class="tile__text">
      <ng-content select="[body-top]"></ng-content>
      <ng-content select="[body-middle]"></ng-content>
      <ng-content select="[body-bottom]"></ng-content>
  </div>
</div>
