import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { NewsBlockProperties } from '@app/newsletter-new/models/block-type.enum';
import { createPlaceholderUrl } from '@app/newsletter-new/utils/helpers';
import { TagTypes } from '@app/shared/utils/get-ses-tags';

@Component({
	selector: 'rh-news-element',
	templateUrl: './news-element.component.html',
	styleUrls: ['./news-element.component.scss'],
	animations: [
		trigger('leftImageAnimate', [
			transition(':enter', [
				style({ width: 0, marginRight: 0, opacity: 0 }),
				animate('250ms cubic-bezier(0, 0, 0.2, 1)', style({ width: '160px', marginRight: '16px', opacity: 1 })),
			]),
			transition(':leave', [
				style({ width: '160px', marginRight: '16px', opacity: 1 }),
				animate('250ms cubic-bezier(0, 0, 0.2, 1)', style({ width: 0, marginRight: 0, opacity: 0 })),
			]),
		]),
		trigger('rightImageAnimate', [
			transition(':enter', [
				style({ width: 0, marginLeft: 0, opacity: 0 }),
				animate('250ms cubic-bezier(0, 0, 0.2, 1)', style({ width: '160px', marginLeft: '16px', opacity: 1 })),
			]),
			transition(':leave', [
				style({ width: '160px', marginLeft: '16px', opacity: 1 }),
				animate('250ms cubic-bezier(0, 0, 0.2, 1)', style({ width: 0, marginLeft: 0, opacity: 0 })),
			]),
		]),
	],
})
export class NewsElementComponent implements AfterViewInit {
	TagTypes = TagTypes;
	placeholderUrl = createPlaceholderUrl();

	@Input() blockProperties: NewsBlockProperties = {
		news: null,
		newsLanguage: null,
		flipped: false,
		card: false,
	};

	disableAnimation = true;
	@Input() forceDisabledAnimation = false;
	@Output() contentLoaded = new EventEmitter<void>();
	constructor() {}

	ngAfterViewInit(): void {
		if (!this.forceDisabledAnimation) {
			this.disableAnimation = false;
		}
		this.contentLoaded.emit();
	}
}
