import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  transform(name: string): any {
    if (name) {
      const splittedName = name.split(' ');
      if (splittedName.length < 2) {
        return '';
      }
      return `${splittedName[0][0].toUpperCase()}${splittedName[1][0].toUpperCase()}`
    }
    return '';
  }
}
