import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import {Collection} from '@ckeditor/ckeditor5-utils/src';
import {addListToDropdown, createDropdown, Model} from 'ckeditor5/src/ui';

export default class SectionColorsBoxUi extends Plugin {
  init() {
    const editor = this.editor;
    const t = editor.t;
    const options = getOptions(editor);
    const defaultTitle = t('Section colour');
    const dropdownTooltip = t('Section colour');

    editor.ui.componentFactory.add('sectionColorsBox', locale => {
      const titles = {};
      const itemDefinitions = new Collection();

      const sectionColorsCommand = editor.commands.get('insertSectionColorsBox');

      const commands = [sectionColorsCommand];

      for (const option of options) {
        const def = {
          type: 'button',
          model: new Model({
            label: option.title,
            class: option.model + ' ck-custom-button',
            withText: true
          })
        };


        def.model.bind('isOn').to(sectionColorsCommand, 'value', value => {
          return !!value && value === option.model
        });
        def.model.set({
          commandName: 'insertSectionColorsBox',
          commandParam: option.model,
          commandValue: option.model
        });

        // Add the option to the collection.
        itemDefinitions.add(def);

        titles[option.model] = option.title;
      }

      const dropdownView = createDropdown(locale);
      addListToDropdown(dropdownView, itemDefinitions);

      dropdownView.buttonView.set({
        isOn: false,
        withText: true,
        tooltip: dropdownTooltip,
        class: 'ck-custom-toolbar-button'
      });

      dropdownView.bind('isEnabled').toMany(commands, 'isEnabled', (...areEnabled) => {
        return areEnabled.some(isEnabled => isEnabled);
      });

      dropdownView.buttonView.bind('label').to(sectionColorsCommand, 'value', (value, para) => {
        return defaultTitle;
      });

      // Execute command when an item from the dropdown is selected.
      this.listenTo(dropdownView, 'execute', evt => {
        editor.execute(evt.source.commandName, evt.source.commandValue ? {value: evt.source.commandValue} : undefined);
        editor.editing.view.focus();
      });

      return dropdownView;
    });
  }
}

export function getOptions(editor) {
  const t = editor.t;
  const localizedTitles = {
    '': t('Transparent'),
    'bg-neutral-dark-colour': 'Neutral dark colour',
    'bg-neutral-medium-colour': 'Neutral medium colour',
    'bg-neutral-light-colour': 'Neutral light colour',
    'bg-blue-gradient-top-left': t('Blue gradient (top left)'),
    'bg-blue-gradient-bottom-right': t('Blue gradient (bottom right)'),
    'bg-neutral-gradient-top-left': t('Neutral gradient (top left)'),
    'bg-neutral-gradient-bottom-right': t('Neutral gradient (bottom right)'),
    'bg-neutral-gradient': t('Neutral gradient'),
    'bg-warm-blue-gradient-top-left': t('Warm blue gradient (top left)'),
    'bg-warm-blue-gradient-bottom-right': t('Warm blue gradient (bottom right)'),
    'bg-gradient-white-to-grey': t('Gray gradient')
  };

  return editor.config.get('sectionColorsBox.options').map(option => {
    const title = localizedTitles[option.model];

    if (title && title != option.model) {
      option.title = title;
    }

    return option;
  });
}
