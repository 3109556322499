import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, Dictionary, EntityAdapter } from '@ngrx/entity';
import * as fromActions from './recipients-table.actions';
import * as fromRecipientsForm from '../recipients-form/recipients-form.actions';
import { flattenFilters, TableState } from '@app/shared/filters/filters-model';
import { Pagination } from '@app/core/models';
import { RecipientListsTableModel } from '@app/core/models/newsletter.model';
import { INITIAL_RECIPIENTS_FILTERS, RecipientsTableFilters } from '@app/shared/filters/models/recipients-table';

export enum ListType {
	NONE = 0,
	ACCEPTED = 1,
	WAITING = 2,
}

export interface Sugg {
	name: string;
	tickersMessage: string[];
}

export const adapter: EntityAdapter<RecipientListsTableModel> = createEntityAdapter<RecipientListsTableModel>({
	selectId: (e) => e.id,
});

export interface State {
	table: TableState<RecipientListsTableModel, RecipientsTableFilters>;
	sentToMe: Array<RecipientListsTableModel>;
}

export const initialState: State = {
	table: {
		data: adapter.getInitialState(),
		pagination: {
			...Pagination,
			pageIndex: 0,
			pageSize: 10,
		},
		sort: {
			active: 'lastModifiedDate',
			direction: 'desc',
		},
		defaultSort: {
			active: 'lastModifiedDate',
			direction: 'desc',
		},
		filters: INITIAL_RECIPIENTS_FILTERS,
		defaultFilters: INITIAL_RECIPIENTS_FILTERS,
		isLoading: false,
		resetIndex: false,
	},
	sentToMe: [],
};

const contentPageReducer = createReducer(
	initialState,
	on(fromActions.table.request, (state) => ({
		...state,
		table: {
			...state.table,
			isLoading: true,
		},
	})),
	on(fromActions.table.success, fromActions.table.failure, (state) => ({
		...state,
		table: {
			...state.table,
			isLoading: false,
		},
	})),
	on(fromActions.table.success, (state, { data, pagination }) => ({
		...state,
		table: {
			...state.table,
			data: adapter.setAll(data, state.table.data),
			pagination,
			resetIndex: false,
		},
	})),
	on(fromActions.table.filter, (state, { filters }) => ({
		...state,
		table: {
			...state.table,
			filters,
			resetIndex: true,
		},
	})),
	on(fromActions.table.sort, (state, { active, direction }) => ({
		...state,
		table: {
			...state.table,
			sort: {
				active,
				direction,
			},
		},
	})),
	on(fromActions.table.paginate, (state, { pageIndex, pageSize }) => ({
		...state,
		table: {
			...state.table,
			pagination: {
				...state.table.pagination,
				pageIndex,
				pageSize,
			},
		},
	})),
	on(fromActions.table.clear, (state) => ({
		...initialState,
	})),
	on(fromActions.getSentToMeList.success, (state, { lists }) => ({
		...state,
		sentToMe: lists,
	})),
	on(fromActions.acceptSentToMeList.success, fromActions.rejectSentToMeList.success, (state, { id }) => ({
		...state,
		sentToMe: state.sentToMe.filter((l) => l.id !== id).slice(),
	})),
	on(fromActions.acceptSentToMeList.success, (state) => ({
		...state,
		table: {
			...state.table,
			filters: state.table.defaultFilters,
			resetIndex: true,
		},
	})),
	on(
		fromActions.deleteList.success,
		fromRecipientsForm.form.updateSuccess,
		fromRecipientsForm.form.createSuccess,
		(state) => ({
			...state,
			table: {
				...state.table,
				resetIndex: true,
			},
		})
	)
	// on(fromActions.updateCampaignsFilter, (state, { campaigns }) => ({
	// 	...state,
	// 	table: {
	// 		...state.table,
	// 		filters: {
	// 			...state.table.filters,
	// 			campaigns,
	// 		},
	// 		defaultFilters: {
	// 			...state.table.defaultFilters,
	// 			campaigns,
	// 		},
	// 	},
	// }))
);

export function reducer(state: State | undefined, action: Action) {
	return contentPageReducer(state, action);
}

// NEW WAY TABLE
export const getTableState = (state: State) => state.table;

export const getPagination = (table: TableState<RecipientListsTableModel, RecipientsTableFilters>) => table.pagination;
export const getSort = (table: TableState<RecipientListsTableModel, RecipientsTableFilters>) => table.sort;
export const getDefaultSort = (table: TableState<RecipientListsTableModel, RecipientsTableFilters>) =>
	table.defaultSort;
export const getFilters = (table: TableState<RecipientListsTableModel, RecipientsTableFilters>) => table.filters;
export const getDefaultFilters = (table: TableState<RecipientListsTableModel, RecipientsTableFilters>) =>
	table.defaultFilters;
export const getIsLoading = (table: TableState<RecipientListsTableModel, RecipientsTableFilters>) => table.isLoading;

export const getRequestData = ({
	pagination,
	sort,
	filters,
	resetIndex,
}: TableState<RecipientListsTableModel, RecipientsTableFilters>) => ({
	pageIndex: pagination.pageIndex,
	pageSize: pagination.pageSize,
	sort,
	filters: flattenFilters(filters),
	resetIndex,
});

export const getTableData = (table: TableState<RecipientListsTableModel, RecipientsTableFilters>) =>
	adapter.getSelectors().selectAll(table.data);

// Pagination fields
export const getPageSize = (pagination: Pagination) => pagination.pageSize;
export const getPageIndex = (pagination: Pagination) => pagination.pageIndex;
export const getTotalCount = (pagination: Pagination) => pagination.totalCount;

// Filters fields
export const getSearch = (filters: RecipientsTableFilters) => filters.search.value;

export const getEntitiesByIds = (
	entities: Dictionary<RecipientListsTableModel>,
	ids: string[]
): RecipientListsTableModel[] => ids.map((id) => entities[id]);

export const getSentToMe = (state: State) => state.sentToMe;
