import { Component, Inject, ViewChild } from '@angular/core';
import { NewsboardAuthService } from '@app/core/auth/services/newsboard-auth.service';
import { TickerFeed } from '@app/core/models';
import { BackButton } from '@app/root-store/ui/back-button';
import { RDS_DIALOG_DATA, RdsDialogRef, RdsTabsComponent } from '@rds/angular-components';

type ContentMode = 'desktop' | 'mobile';

export interface PreviewButtonState {
  visible: boolean;
  disabled: boolean;
}

export interface TickerPreviewButtons {
  editTicker?: PreviewButtonState
  canSaveTicker?: PreviewButtonState
}

export interface TickerPreviewDialogData  {
  ticker: TickerFeed;
  context: 'Editor' | 'Table';
  buttons?: TickerPreviewButtons
  backButton?: BackButton;
}

@Component({
  selector: 'rh-ticker-preview-dialog',
  templateUrl: './ticker-preview-dialog.component.html',
  styleUrls: ['./ticker-preview-dialog.component.scss']
})
export class TickerPreviewDialogComponent {
  @ViewChild('tabs') tabs: RdsTabsComponent;

  context: 'Editor' | 'Table';
  backButton: BackButton;
  buttons: TickerPreviewButtons;
  ticker: TickerFeed;
  contentMode: ContentMode = 'desktop';

  constructor(
    private auth: NewsboardAuthService,
    private dialogRef: RdsDialogRef<TickerPreviewDialogComponent>,
    @Inject(RDS_DIALOG_DATA) data: TickerPreviewDialogData
  ) {
    this.ticker = data.ticker;
    this.context = data?.context;
    this.backButton = data?.backButton;
    this.buttons = data?.buttons;
  }

  close(status?): void {
    this.dialogRef.close({status});
  }

  setContentMode(contentMode: ContentMode): void {
    this.contentMode = contentMode;
  }

  gotoLink(): void {
    window.open(this.ticker.link, '_blank');
  }

}
