import { createAction, props } from '@ngrx/store';

import { CategoryFlat } from '@core/models/category.model';

export const loadCategoriesFlat = createAction(
  '[CORE CATEGORIES FLAT - LOAD CATEGORIES FLAT REQUEST] Load Categories Flat'
);

export const getCategoriesFlatRequest = createAction(
  '[CORE CATEGORIES FLAT - GET CATEGORIES FLAT REQUEST] Get Categories Flat Request'
);

export const getCategoriesFlatSuccess = createAction(
  '[CORE CATEGORIES FLAT - GET CATEGORIES FLAT SUCCESS] Get Categories Flat Success',
  props<{ categoriesFlat: CategoryFlat[] }>()
);

export const getCategoriesFlatFailure = createAction(
  '[CORE CATEGORIES FLAT - GET CATEGORIES FLAT FAILURE] Get Categories Flat Failure',
  props<{ error: any }>()
);

export const categoriesFlatCollectionAddMany = createAction(
  '[STORED CATEGORIES FLAT - ADD MANY] Categories Flat collection add many.',
  props<{ categoriesFlat: CategoryFlat[] }>()
);
