import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { OembedPipe } from '@app/shared/oembed/oembed.pipe';

@Component({
  selector: 'rh-news-content',
  templateUrl: './news-content.component.html',
  styleUrls: ['./news-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsContentComponent {

  @Input() body: string;
  @Input() sanitizedBody: SafeHtml;

  get safeHTML() {
    const oembedHTML = this.oembedPipe.transform(this.body);
    return this.sanitizer.bypassSecurityTrustHtml(oembedHTML);
  }
  constructor(
    private oembedPipe: OembedPipe, private sanitizer: DomSanitizer) { }

}
