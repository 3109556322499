import {
	Component,
	EventEmitter,
	Host,
	HostBinding,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Optional,
	Output,
	SkipSelf,
} from '@angular/core';
import { RdsDialogService, RdsFileItem, RdsFileUploader } from '@rds/angular-components';
import { ControlContainer, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SubSink } from 'subsink';
import { NewsImage } from '@app/core/models/newsboard';
import { NewsboardAuthService } from '@app/core/auth/services/newsboard-auth.service';
import { CropImageDialogComponent } from '@app/shared/dialogs/crop-image-dialog/crop-image-dialog.component';
import { EndpointsService } from '@app/core/services/endpoints/endpoints.service';
import { MAX_FILE_UPLOAD_SIZE } from '@app/shared/utils/tokens';

@Component({
	selector: 'rh-upload-box',
	templateUrl: './upload-box.component.html',
	styleUrls: ['./upload-box.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: UploadBoxComponent,
		},
	],
})
export class UploadBoxComponent implements OnInit, OnDestroy {
	private subs: SubSink = new SubSink();
	file: NewsImage;

	@Input() size: 's' | 'm' | 'l' = 'l';
	@Input() disabled: boolean = false;
	@Input() showStepInformation: boolean = false;
	@Input() hasDropZone: boolean;
	@Input() aspectRatio = 16 / 9;
	@Input() formControl!: FormControl;
	@Input() formControlName!: string;
	uploader: RdsFileUploader;
	now: number = new Date().getTime();

	@HostBinding('class') class = `rds-upload-size-${this.size}`;

	@Output() uploadStarted: EventEmitter<RdsFileItem> = new EventEmitter();
	@Output() uploadEnded: EventEmitter<string> = new EventEmitter();
	@Output() uploadAborted: EventEmitter<RdsFileItem> = new EventEmitter();
	@Output() uploadFailed: EventEmitter<RdsFileItem> = new EventEmitter();

	lastUploadedFile: RdsFileItem;

	onChange = (file) => {};

	onTouched = () => {};

	touched = false;

	writeValue(file: NewsImage) {
		this.file = file;
	}

	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	// setDisabledState(disabled: boolean) {
	//   this.disabled = disabled;
	// }

	clearControl() {
		this.onChange(null);
		this.file = null;
		this.markAsTouched();
	}

	clearQueue(fileItem) {
		this.uploader.removeFromQueue(fileItem);
	}

	get control() {
		return this.formControl || this.controlContainer.control?.get(this.formControlName);
	}

	get hasError() {
		return this.control?.status === 'INVALID' && this.control?.touched;
	}

	constructor(
		@Inject(MAX_FILE_UPLOAD_SIZE) private maxFileSize: { [type: string]: number },
		private endpoints: EndpointsService,
		private auth: NewsboardAuthService,
		private httpClient: HttpClient,
		private dialogService: RdsDialogService,
		@Optional() @Host() @SkipSelf() private controlContainer: ControlContainer
	) {
		const headers: HttpHeaders = new HttpHeaders({ Authorization: `Bearer ${localStorage.getItem('jwtToken')}` });
		this.uploader = new RdsFileUploader(
			{
				autoUpload: true,
				url: `${this.endpoints.ENDPOINT.NEWSLETTER.POST_PHOTO}`,
				method: 'POST',
				headers: headers,
				removeAfterUpload: true,
				accept: ['image/*'],
			},
			this.httpClient
		);
	}

	ngOnInit(): void {
		this.subs.sink = this.uploader.fileItemError$.subscribe((res) => {
			this.uploadFailed.emit(res.item);
		});
		this.subs.sink = this.uploader.fileItemCanceled$.subscribe((res) => {
			this.uploadAborted.emit(res.item);
		});
		this.subs.sink = this.uploader.fileItemSuccess$.subscribe((res) => {
			const url = res.event.body as string;
			this.uploadEnded.emit(url);
			this.lastUploadedFile = res.item;
			this.file = {
				id: 'upload',
				url,
				name: res.item.file.name,
				size: res.item.file.size,
			};

			this.onChange(this.file);
		});
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	onFileSelected(event, skipCrop = false) {
		const file = event[0];

		this.uploader.updateConfig({
			accept: Object.keys(this.maxFileSize),
			maxFileSize: this.maxFileSize[file.type],
		});

		if (this.uploader.queue.length > 0) {
			this.uploader.queue.map((q) => this.uploader.removeFromQueue(q));
		}
		if (skipCrop || this.isGif(file.type) || !this.isImage(file.type) || file.size > this.maxFileSize[file.type]) {
			this.uploader.addToQueue([file]);
			this.uploadStarted.emit(file);
		} else {
			const dialogRef = this.dialogService.open(CropImageDialogComponent, {
				size: 'l',
				data: {
					aspectRatio: this.aspectRatio,
					file,
				},
			});
			this.subs.sink = dialogRef.afterClosed().subscribe((croppedImage) => {
				this.control.markAsTouched();
				if (!!croppedImage) {
					this.uploader.addToQueue([croppedImage]);
					this.uploadStarted.emit(croppedImage);
				}
			});
		}
	}

	isGif(type: string): boolean {
		return /(gif)/i.test(type);
	}

	isImage(type: string): boolean {
		return Object.keys(this.maxFileSize).includes(type);
	}
}
