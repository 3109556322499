import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ExpressLink} from '@app/layout/header/models';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import * as fromHeader from '../../store/header'

@Component({
  selector: 'rh-header-suggestions',
  templateUrl: './header-suggestions.component.html',
  styleUrls: ['./header-suggestions.component.scss']
})
export class HeaderSuggestionsComponent {
  @Input() set parentElement(value: HTMLElement) {
    this.width = `${value.clientWidth}px`;
  };

  @Output() selected: EventEmitter<any> = new EventEmitter();

  @Input() width = '0px';

  @Input() searchValue!: string;

  isLoading$: Observable<boolean> = this.store$.pipe(
    select(fromHeader.selectIsLoadingSuggestions)
  );

  expressLinks$: Observable<ExpressLink[]> = this.store$.pipe(
    select(fromHeader.selectExpressLinksForHeader)
  );

  autocompletes$: Observable<string[]> = this.store$.pipe(
    select(fromHeader.selectAutocompletesForHeader)
  );

  search(phrase: string) {
    this.store$.dispatch(fromHeader.setPhrase({phrase}));
    this.store$.dispatch(fromHeader.goToSearch({phrase}));
    this.selected.emit();
    // this.router.navigate([], { queryParams: {phrase: phrase}});
  }

  constructor(private store$: Store) {
  }
}
