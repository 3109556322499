import {Component, Input} from '@angular/core';
import { ComponentsLoaderService } from '../components-loader.service';

@Component({
  selector: 'atr-rect-loader',
  templateUrl: './rect-loader.component.html',
  styleUrls: ['./rect-loader.component.scss']
})
export class RectLoaderComponent {

  @Input() viewBox =  '0 0 291 32';

  get width() {
    return this.viewBox.split(' ')[2];
  }
  get height() {
    return this.viewBox.split(' ')[3];
  }
  constructor(public componentsLoaderService: ComponentsLoaderService) {}
}
