import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, Scroll } from '@angular/router';
import * as fromRouter from '@app/root-store/router';
import * as fromSitemap from '@app/root-store/ui/sitemap';
import { SiteMapRoute } from '@app/root-store/ui/sitemap';
import { Store, select } from '@ngrx/store';
import { RdsPopoverDirective } from '@rds/angular-components';
import { SubSink } from 'subsink';
@Component({
  selector: 'rh-sitemap-helper',
  templateUrl: './sitemap-helper.component.html',
  styleUrls: ['./sitemap-helper.component.scss']
})
export class SitemapHelperComponent implements OnInit, OnDestroy {
  @ViewChild(RdsPopoverDirective) popover: RdsPopoverDirective;
  private subs: SubSink = new SubSink();

  sitemapRoute: SiteMapRoute;
  url: string = '';
  constructor(
    private store$: Store<fromSitemap.State>,
    private router: Router,
    private route: ActivatedRoute,
    ) { }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
    
  ngOnInit(): void {
    this.subs.sink = this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd || (ev instanceof Scroll && ev.routerEvent instanceof NavigationEnd)) {
        this.url = '';
        this.getUrlPath(this.route.snapshot);
        this.subs.sink = this.store$.pipe(select(fromSitemap.selecRouteByUrl(this.url))).subscribe(sitemapRoute => {
          this.sitemapRoute = sitemapRoute;
        })
      }
    });
  }

  getUrlPath(route: ActivatedRouteSnapshot) {
    if (route.routeConfig.path.length) {
      this.url += '/' + route.routeConfig.path;
    }
    if (!!route.firstChild) {
      this.getUrlPath(route.firstChild);
    }
  }

  changeStatus(element: SiteMapRoute, status) {
    this.store$.dispatch(fromSitemap.updateStatus({url: element.url, status}))
  }

  addNote(element: SiteMapRoute, note) {
    this.store$.dispatch(fromSitemap.addNote({url: element.url, note}))
  }

  breadcrumbItemClicked(event) {
    if (event.title) {
      this.store$.dispatch(fromRouter.go({path: event.title, queryParams: {}}));
      this.popover.hide();
    }
  }

  shortenTitle(title: string) {
    return title.split(' - ')[1]
  }
}
