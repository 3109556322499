import { createSelector } from '@ngrx/store';

import * as fromDepartmentsFlat from '@app/core/core-store/departments-flat';
import * as fromFunctionsFlat from '@app/core/core-store/functions-flat';
import * as fromLocationsFlat from '@app/core/core-store/locations-flat';
import * as fromReducer from './form.reducer';
import * as fromChannels from '../channel.reducer';
import { ChannelCreation } from '@app/core/models/newsboard';

export const selectState = createSelector(
  fromChannels.selectState,
  fromChannels.getForm
);

export const selectForm = createSelector(
  selectState,
  fromReducer.getForm
);

export const selectFormForRequest = createSelector(
  selectForm,
  fromDepartmentsFlat.selectRoot,
  fromFunctionsFlat.selectRoot,
  fromLocationsFlat.selectRoot,
  (form, dRootId, fRootId, lRootId): ChannelCreation => form && !!dRootId && dRootId.length > 0 && !!fRootId && fRootId.length > 0 && !!lRootId && lRootId.length > 0 ? ({
    allowAutoAssign: form?.allowAutoAssign,
    description: form?.description,
    locations: form.locations.length > 0 ? form.locations : [lRootId[0].id],
    departments: form.departments.length > 0 ? form.departments : [dRootId[0].id],
    functions: form.functions.length > 0 ? form.functions : [fRootId[0].id],
    managers: form?.permissions?.managers,
    owners: form?.permissions?.owners,
    publishers: form?.permissions?.publishers,
    name: form?.name,
    id: form?.id,
    role: form?.role
  }) : null
);

export const selectInitialForm = createSelector(
  selectState,
  fromReducer.getInitialForm
);

export const selectId = createSelector(
  selectForm,
  fromReducer.getId
);

export const selectIsTouched = createSelector(
  selectState,
  fromReducer.getTouched
);

export const selectIsNameUnique = createSelector(
  selectState,
  fromReducer.getIsNameUnique
);
