import { Component, Input } from '@angular/core';

@Component({
  selector: 'rh-view-bar',
  templateUrl: './view-bar.component.html',
  styleUrls: ['./view-bar.component.scss']
})
export class ViewBarComponent {
  @Input() breadcrumbRoutes: Array<{label: string, route: string}> = [];
  @Input() hideTitle: boolean = false;
  @Input() hideAddNews: boolean = false;
}
