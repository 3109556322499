
<div class="row">
  <div class="col-8 offset-2 py-4">
    <div class="row">
      <div class="col">
        <h4 class="ui-heading-4">Personalization preferences</h4>
      </div>
    </div>
  </div>
</div>
  <div [formGroup]="form" class="col-8 offset-2 bg-white py-10 py-s-4">
    <div class="row">
      <div class="col px-11 px-s-5">
        <p *ngIf="!form.disabled" class="ui-label-s mb-9">This information is based on your Workday global-settings. In order to change it select from the dropdowns below.</p>
        <h4 class="ui-heading-4 mb-3">Locations</h4>
        <rh-dropdown
        *ngIf="!form.controls.locations.disabled"
        formControlName="locations"
        placeholder="Select locations"
        chipPlaceholder="Default: All locations"
        size="small"
        [closeOnClickOutside]="true"
        [isMultiple]="true"
        [optionsDictionary]="locationsDict$ | async"
        [optionsModel]="taxonomyOptModel"
      ></rh-dropdown>
      <rds-chip-list [disabled]="true" *ngIf="form.controls.locations.disabled">
        <rds-chip *ngIf="form.controls.locations.value.length === 0">
          Default: All locations
        </rds-chip>
        <rds-chip *ngFor="let location of form.controls.locations.value">
          {{location | dictionary:'location' | async}}
        </rds-chip>
      </rds-chip-list>
      <rds-divider class="my-5"></rds-divider>
      <h4 class="ui-heading-4 mb-3">Departments</h4>
      <rh-dropdown
        *ngIf="!form.controls.departments.disabled"
        formControlName="departments"
        placeholder="Select departments"
        chipPlaceholder="Default: All departments"
        size="small"
        [closeOnClickOutside]="true"
        [isMultiple]="true"
        [optionsDictionary]="departmentsDict$ | async"
        [optionsModel]="taxonomyOptModel"
      ></rh-dropdown>
      <rds-chip-list [disabled]="true" *ngIf="form.controls.departments.disabled">
        <rds-chip *ngIf="form.controls.departments.value.length === 0">
          Default: All departments
        </rds-chip>
        <rds-chip *ngFor="let department of form.controls.departments.value">
          {{department | dictionary:'department' | async}}
        </rds-chip>
      </rds-chip-list>
      <rds-divider class="my-5"></rds-divider>
      <h4 class="ui-heading-4 mb-3">Functions</h4>
      <rh-dropdown
        *ngIf="!form.controls.functions.disabled"
        formControlName="functions"
        placeholder="Select functions"
        chipPlaceholder="Default: All functions"
        size="small"
        [closeOnClickOutside]="true"
        [isMultiple]="true"
        [optionsDictionary]="functionsDict$ | async"
        [optionsModel]="taxonomyOptModel"
      ></rh-dropdown>
      <rds-chip-list [disabled]="true" *ngIf="form.controls.functions.disabled">
        <rds-chip *ngIf="form.controls.functions.value.length === 0">
          Default: All functions
        </rds-chip>
        <rds-chip *ngFor="let function of form.controls.functions.value">
          {{function | dictionary:'function' | async}}
        </rds-chip>
      </rds-chip-list>
      </div>
    </div>
  </div>
<div class="row">
  <div class="col-8 offset-2 py-5 mb-9">
    <div class="row row-centered">
      <div class="col-auto">
        <button rds-text-button (click)="reset()" size="m">
          Reset to default
        </button>
      </div>
      <div class="col-auto offset-auto">
        <button rds-secondary-button [disabled]="nothingChanged" (click)="cancel()" size="m">
          Cancel
        </button>
      </div>
      <div class="col-auto">
        <button rds-primary-button [disabled]="nothingChanged" (click)="save()" size="m">
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>

<!-- 

<div class="section-title">
  <h3>{{ 'user-profile.searching-preferences' | translate }}
    <rds-icon namespace="filled" icon="info" namespace="outlined"
              [rdsTooltip]="'This information is based on your Workday global-settings. In order to change it select from the dropdowns below.'"
              [rdsTooltipShowDelay]="300">
      >
    </rds-icon>
  </h3>
  <button rds-icon-button [disabled]="(preferencesEditMode$ | async) === true" (click)="setPreferencesEditMode()" size="m">
    <rds-icon icon="edit" namespace="filled"></rds-icon>
  </button>
</div>
<form
  [formGroup]="preferencesForm"
  id="preferencesForm"
>
  <div class="preferences lp-2">
    <div class="my-6 ui-body-l-bold">
      {{ 'user-profile.location' | translate }}
    </div>
    <rh-dropdown
      class="preferences__audience__dropdown"
      formControlName="locations"
      placeholder="Select location"
      chipPlaceholder="Default: All locations"
      [closeOnClickOutside]="true"
      [isMultiple]="true"
      [showChips]="true"
      [optionsDictionary]="locationsDict$ | async"
      [optionsModel]="taxonomyOptModel"
      [size]="(isMobile$ | async) ? 'small' : 'medium'"
      [disabled]="(preferencesEditMode$ | async) === false"
    ></rh-dropdown>

    <div class="my-6 ui-body-l-bold">
      {{ 'user-profile.department' | translate }}
    </div>
    <rh-dropdown
      class="preferences__audience__dropdown"
      formControlName="departments"
      placeholder="Select department"
      chipPlaceholder="Default: All departments"
      [closeOnClickOutside]="true"
      [isMultiple]="true"
      [showChips]="true"
      [optionsDictionary]="departmentsDict$ | async"
      [optionsModel]="taxonomyOptModel"
      [size]="(isMobile$ | async) ? 'small' : 'medium'"
      [disabled]="(preferencesEditMode$ | async) === false"
    ></rh-dropdown>

    <div class="my-6 ui-body-l-bold">
      {{ 'user-profile.function' | translate }}
    </div>
    <rh-dropdown
      class="preferences__audience__dropdown"
      formControlName="functions"
      placeholder="Select function"
      chipPlaceholder="Default: All functions"
      [closeOnClickOutside]="true"
      [isMultiple]="true"
      [showChips]="true"
      [optionsDictionary]="functionsDict$ | async"
      [optionsModel]="taxonomyOptModel"
      [size]="(isMobile$ | async) ? 'small' : 'medium'"
      [disabled]="(preferencesEditMode$ | async) === false"
    ></rh-dropdown>

    <div class="mt-l-4 btns" *ngIf="(preferencesEditMode$ | async) === true">
        <div style="flex:1;">
          <button rds-secondary-button (click)="withResetPreferenceClick()"
                  [disabled]="(isLoading$ | async) === true" size="l">
            Reset to default
          </button>
        </div>
        <div class="save-and-cancel-btns">
          <button rds-secondary-button (click)="withCancelPreferencesClick()"
                  [disabled]="(isLoading$ | async) === true" size="l">
            Cancel
          </button>

          <button rds-primary-button (click)="withSavePreferencesClick()"
                  [disabled]="(isLoading$ | async) === true" size="l">
            Save
          </button>
        </div>
    </div>
  </div>
</form> -->
