<h2 rds-dialog-header>
	<ng-container *ngIf="data.settings.event"> Edit Event </ng-container>
	<ng-container *ngIf="!data.settings.event">Select Event </ng-container>
</h2>
<div rds-dialog-content style="overflow-x: hidden; position: relative">
	<ng-container [formGroup]="eventForm">
		<div
			*ngIf="!data.settings.event && data.addNewAsCard && eventArray.length > 1"
			class="row bg-white"
			style="position: sticky; top: 0; z-index: 4"
		>
			<div class="col-4 mb-6">
				<p class="mt-5 mb-3 ui-label-l-bold">How to insert Event?</p>
				<rds-radio-group formControlName="insertOption" class="inline">
					<rds-radio-button [value]="0">All in one column</rds-radio-button>
					<rds-radio-button [value]="1">Distributed evenly in columns</rds-radio-button>
				</rds-radio-group>
			</div>
			<rds-divider></rds-divider>
		</div>
		<ng-container *ngFor="let event of eventArray.controls; let i = index" [formGroup]="event">
			<div class="row">
				<div class="col mt-5">
					<div *ngIf="!data.settings.event" class="row">
						<div class="col">
							<h4 class="ui-heading-4 mb-3">Event {{ i + 1 }}</h4>
						</div>
						<div class="col-auto">
							<button [disabled]="eventArray.length === 1" rds-icon-button (click)="removeEvent(i)">
								<rds-icon icon="delete" namespace="outlined"></rds-icon>
							</button>
						</div>
					</div>
					<div class="mb-5">
						<rds-radio-group formControlName="searchBy" class="inline">
							<rds-radio-button [value]="0">Search by date</rds-radio-button>
							<rds-radio-button [value]="1">Search by name</rds-radio-button>
						</rds-radio-group>
					</div>
					<ng-container *ngIf="event.controls.searchBy.value === 0">
						<div class="mb-5">
							<rds-form-field>
								<rds-control-label>Date range</rds-control-label>
								<rds-date-range-input
									#rangeInput
									[formGroup]="event.controls.range"
									[rangePicker]="rangeActionsPicker"
								>
									<input
										rdsStartDate
										placeholder="Start date"
										formControlName="start"
									/>
									<input
										rdsEndDate
										placeholder="End date"
										formControlName="end"
									/>
								</rds-date-range-input>
								<rds-datepicker-toggle rds-control-suffix [forDatepicker]="rangeActionsPicker"></rds-datepicker-toggle>
								<rds-date-range-picker [touchUi]="false" #rangeActionsPicker>
									<rds-datepicker-actions>
										<rh-datepicker-range-actions
											[datepicker]="rangeActionsPicker"
											[dateRangeInput]="rangeInput"
											[rangeFormGroup]="event.controls.range"
										></rh-datepicker-range-actions>
									</rds-datepicker-actions>
								</rds-date-range-picker>
							</rds-form-field>
						</div>
						<div class="mb-5">
							<rds-form-field>
								<rds-control-label>Choose an event from the selected range</rds-control-label>
								<rds-select
									#select
									[id]="'nl-select-event-' + i"
									formControlName="rangeEvent"
									[rhOverrideSelectLabel]="
										(event.controls.range.value | eventsForRange | async)?.length > 0
											? event.controls.rangeEvent.value?.title
											: null
									"
									(valueChange)="select.resetFilterValue(); eventChange($event, i)"
									[filterBy]="filterBy"
								>
									<rds-select-search-input
										*ngIf="(event.controls.range.value | eventsForRange | async)?.length > 0"
									></rds-select-search-input>
									<rds-select-option
										*ngIf="(event.controls.range.value | eventsForRange | async)?.length === 0"
										[disabled]="true"
									>
										No events for selected date range.
									</rds-select-option>
									<rds-select-option
										*ngFor="let eventItem of event.controls.range.value | eventsForRange | async"
										[value]="eventItem"
									>
										<div style="display: flex; gap: 24px; align-items: center">
											<img
												style="height: 36px; aspect-ratio: 16/9"
												[src]="eventItem.imageUrl || '/assets/images/news-placeholder.png'"
												alt=""
											/>
											<div style="display: flex; flex-direction: column; min-width: 0">
												<div style="display: flex; min-width: 0; gap: 8px">
													<rds-badge
														size="s"
														[label]="eventItem.date | date: 'dd LLL yyyy' | uppercase"
														type="minimal"
														color="purple"
													></rds-badge>
													<p class="ui-label-m-bold text-ellipsis">
														{{ eventItem.title }}
													</p>
												</div>
												<p class="ui-label-s text-ellipsis">
													{{ eventItem.description }}
												</p>
											</div>
										</div>
									</rds-select-option>
								</rds-select>
							</rds-form-field>
						</div>
					</ng-container>
					<ng-container *ngIf="event.controls.searchBy.value === 1">
						<div class="mb-5">
							<rh-autocomplete
								uniqueKey="id"
								displayKey="name"
								label="Enter event name"
								[options]="event.controls.autocompleteGuid.value | autocompletes"
								(search)="getAutocompletes(event.controls.autocompleteGuid.value, $event)"
								formControlName="phraseEvent"
								[multi]="false"
								[itemTemplate]="itemTemplate"
								[selectedTemplate]="selectedTemplate"
							>
								<ng-template #itemTemplate let-option="option" let-highlightPhrase="highlightPhrase">
									<rh-event-autocomplete-item [option]="option" [highlightPhrase]="highlightPhrase"></rh-event-autocomplete-item>
								</ng-template>
								<ng-template #selectedTemplate let-selectedOptions="selectedOptions" let-disabled="disabled" let-removeFn="removeFn" let-checkRemovePrevented="checkRemovePrevented">
									<rh-event-autocomplete-selected [selectedOptions]="selectedOptions" [disabled]="disabled" (removed)="removeFn($event)" [checkRemovePrevented]="checkRemovePrevented"></rh-event-autocomplete-selected>
								</ng-template>
							</rh-autocomplete>
						</div>
					</ng-container>
				</div>
			</div>
		</ng-container>
	</ng-container>
</div>
<div rds-dialog-actions-container align="end">
	<button *ngIf="!data.settings.event" rds-text-button (click)="addEvent()" style="align-self: center; margin-right: auto;">Add another Event</button>
	<button rds-secondary-button [rds-dialog-close]="null" size="m">Cancel</button>
	<button rds-primary-button [disabled]="eventForm.invalid" size="m" (click)="submit()">Save changes</button>
</div>
