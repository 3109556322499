<div style="display: flex;gap: 24px; align-items: center;">
    <img style="height: 36px; aspect-ratio: 16/9" [src]="option.imageUrl || '/assets/images/news-placeholder.png'" alt="">
    <div style="display: flex; flex-direction: column; min-width: 0;">
      <div style="display: flex; min-width: 0; gap: 8px;">
        <rds-badge size="s" [label]="option.date | date:'dd LLL yyyy' | uppercase" type="minimal" color="purple"></rds-badge>
        <p class="ui-label-m-bold text-ellipsis">
          {{option.title}}
        </p>
      </div>
      <p class="ui-label-s text-ellipsis">
        {{option.description}}
      </p>
    </div>
  </div>